/*******************************************************************************
  설  명 : 회원정보 - 메모내역
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { AuthService } from '@app/service/auth.service';
import { StockOut } from '@app/service/stockOut.service';

import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import { ModalDeliveryComponent } from '@app/page/stock/stock-out-management/modal-delivery/modal-delivery.component';

@Component({
  selector: 'app-order-out-delivery',
  templateUrl: './order-out-delivery.component.html',
  styleUrls: ['./order-out-delivery.component.scss']
})
export class OrderOutDeliberyComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() OrderSeq: any;
  @Input() dormant: any;
  @Input() memberNo: any;

  public search: any = {
    searchText: ''
  };

  public form: FormGroup;
  public formErrors = {};

  // 그리드 관련 선언
  memberMemoList: any = [];
  memberInfo: any = {
    hp: '',
    email: ''
  }

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  outColumnDefs: any;
  outGridApi: any;
  outGridColumnApi: any

  rowData: any = [];
  rowData1: any = [];

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public setMemberMemoSaveFunc: any;
  public setMemberHPEmailFunc: any;


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      type: ['1', [Validators.required]],
      type_data: ['', [Validators.required, Validators.maxLength(50)]],
      OrderSeq: ['', []],
      message: ['', [Validators.required] ],
      emailBlocked: ['', []],
      smsBlocked: ['', []],
      kakaoBlocked: ['', []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService,
    private modalService: NgbModal,
    private authService: AuthService,
    private stockOut: StockOut
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'mng_out_order_seq', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품번호', field: 'product_seq', width: 100, cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let productSeq = params.data.product_seq;
          let productPropertySeq = '(' + params.data.product_property_seq + ')';
          return productSeq + '<br/>' + productPropertySeq;
        }
      },
      { headerName: '제조사', field: 'brand_name', width: 150, cellClass: 'cp center cell-wrap-text',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'order_brand',
          providerClicked: () => { this.toastrService.warning('주문내역 탭에서 처리하시기 바랍니다.') },
          senaClicked: () => { this.toastrService.warning('주문내역 탭에서 처리하시기 바랍니다.') },
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'product_name', width: 400, cellClass: 'cp left cell-wrap-text',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'order_product_info',
          deliveryClicked: () => { this.toastrService.warning('주문내역 탭에서 처리하시기 바랍니다.') },
        }
      },
      { headerName: '이벤트', field: 'event_name', width: 120, cellClass: 'cp center cell-wrap-text' },
      { headerName: '출고지시수량', field: 'out_order_qty', width: 120, cellClass: 'cp center ag-cell80h' },
      { headerName: '출고수량', field: 'out_qty', width: 120, cellClass: 'cp center ag-cell80h' },
      { headerName: '미출고수량', field: 'incompleteOutQty', width: 120, cellClass: 'cp center ag-cell80h' },
    ];

    // ag grid 컬럼 선언
    this.outColumnDefs = [
      { headerName: '매입구분', field: 'mng_out_type_name', width: 80, cellClass: 'cp center' },
      { headerName: '출고지시번호', field: 'mng_out_seq', width: 100, cellClass: 'cp center' },
      { headerName: '출고지시일자', field: 'out_order_date', width: 100, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    this.rowData;

    this.rowData1;

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getOrderStock();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onOutGridReady(params) {
    this.outGridApi = params.api;
    this.outGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 데이터 선택시 상세 보여주기
  *******************************************************************************/
  onRowClicked($event) {
    this.rowData1 = $event.data.stockList;
    this.canYouSeeMe = () => $event.data['mng_out_type'] == '2000' ? true : false
  }

  getOrderStock() {
    this.orderService.getOrderStock(this.OrderSeq).then(response => {
      if( response.ResultCode ) {
        this.rowData = this.detailStock(response.data);
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  detailStock(data) {
    const detailStock = []
    data.forEach(e => {
      const stockList = {
        brand_name: e['brand_name'],
        category_name: e['category_name'],
        mng_out_order_seq : e['mng_out_order_seq'],
        product_name : e['product_name'],
        product_seq: e['product_seq'],
        product_property_seq: e['product_property_seq'],
        product_code: e['product_code'],
        color_name : e['color_name'],
        size_name : e['size_name'],
        event_name: e['event_name'],
        thumbnail_url : e['thumbnail_url'],
        out_order_qty : parseInt(e['out_order_qty']),
        out_qty : parseInt(e['out_qty']),
        incompleteOutQty: ( parseInt(e['incompleteOutQty']) < 0 ) ? 0 : parseInt(e['incompleteOutQty']),
        order_detail_seq : e['order_detail_seq'],
        mng_out_status: e['mng_out_status'],
        mng_out_order_status: e['mng_out_order_status'],
        display_color_name : e['display_color_name'],
        display_size_name : e['display_size_name'],
      }
      const idx = detailStock.findIndex(_e => _e['mng_out_seq'] == e['mng_out_seq'])
      idx < 0
      ? detailStock.push({
        mng_out_type : e['mng_out_type'],
        mng_out_type_name : e['mng_out_type_name'],
        mng_out_seq : e['mng_out_seq'],
        mng_out_order_status: e['mng_out_order_status'],
        mng_out_status_name : e['mng_out_status_name'],
        out_order_date : e['out_order_date'],
        out_date : e['out_date'],
        order_master_seq : e['order_master_seq'],
        display_color_name : e['display_color_name'],
        display_size_name : e['display_size_name'],        

        stockList : [stockList]
      })
      : detailStock[idx]['stockList'].push(stockList)
    })

    detailStock.forEach(e => {
      ['qty', 'out_order_qty', 'out_qty', 'incompleteOutQty'].forEach(_e => {
        e[_e] = e['stockList'].reduce((a,c) => a + c[_e], 0)
      })
    })

    return detailStock
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 입점업체 배송처리
  *******************************************************************************/
  setEnterDelivery() {
    const _SR = this.outGridApi.getSelectedRows()[0]
    if(_SR['mng_out_type'] != '2000') return
    else if(!confirm('입점 출고지시를 출고완료 상태로 변경합니다. \r\n실행하시겠습니까?')) return

    const options: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: '',
      centered: true,
      windowClass: 'modal-fadeInDown'
    }
    const modalRef = this.modalService.open(ModalDeliveryComponent, options)
    modalRef.componentInstance.mng_out_seq = _SR.mng_out_seq
    modalRef.result.then(result => {
      this.ngOnInit()
    }, (reason) => {})
  }

  /*******************************************************************************
    설  명 : 입점업체 배송처리 버튼 노출 여부
  *******************************************************************************/
  canYouSeeMe = () => false

  /*******************************************************************************
    설  명 : 출고지시취소
  *******************************************************************************/
  outException() {
    const mngOutData = this.outGridApi.getSelectedRows()[0];
    const mngOutOrderData = this.gridApi.getSelectedRows()[0];

    // if(mngOutData['mng_out_type'] == '3000') {
    //   this.toastrService.error('직발은 취소할 수 없습니다.')
    //   return
    // }

    if(mngOutOrderData['mng_out_order_status'] == '2000') {
      this.toastrService.error('출고완료된 상태입니다.');
      return;
    }

    if(!confirm('출고지시를 취소하시겠습니까?')) return;

    this.authService.getLoginInfo.subscribe(e => {
      this.stockOut.setOutException({
        mem_no : e['mem_no'],
        mng_out_seq : mngOutData['mng_out_seq'],
        order_master_seq : mngOutData['order_master_seq'],
        mng_out_order_seq : mngOutOrderData['mng_out_order_seq'],
        order_detail_seq : mngOutOrderData['order_detail_seq']
      }).then(response => {
        response.ResultCode ? this.toastrService.success(response.ResultMessage) : this.toastrService.error(response.ResultMessage);
        
        this.rowData = this.rowData1 = [];
        this.getOrderStock();
      })
    }).unsubscribe()
  }

}
