<section class="widget-div">
  <div class="fl widget cp" style="width:350px !important">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">총매출<span class="cnt">{{statistic.totalSale | number}}</span>원</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp" style="width:350px !important">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">누적점수<span class="cnt">{{statistic.totalScore | number}}</span>점</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp" style="width:350px !important">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">차감점수<span class="cnt">{{statistic.deductScore | number}}</span>점</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp" style="width:350px !important">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">가용점수<span class="cnt">{{statistic.availableSore | number}}</span>점</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<section id="member-company">

  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getSocietyList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="동호회명" />

    </div>
    <div class="cb"></div>
  </div>

  <as-split unit="percent" restrictMove="true" gutterSize="10" style="height: calc(100vh - 180px);background-color:#fff;">
    <as-split-area size="25" minSize="17" maxSize="57">
      <div class="mb10">
        <div class="fl w50p">
          <h4 class="mb0 fb">동호회</h4>
        </div>
        <div class="cb"></div>
      </div>
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:calc(100% - 35px);"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="societyList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (gridReady)="onGridReady($event)"
        (rowClicked)="onRowClicked($event)"
        >
      </ag-grid-angular>
    </as-split-area>
    <as-split-area size="75" minSize="43" maxSize="83">
      <div class="mb5">
        <div class="fl w50p">
          <h4 class="mb0 fb">동호회 점수이력</h4>
        </div>
        <div class="fr w50p right">
          <button type="button" class="btn btn-primary btn-small ml5" (click)="addSocietyHistory()">이력추가</button>
        </div>
        <div class="cb"></div>
      </div>
      <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 215px);background-color:#fff;">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDetailDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="historyList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (cellDoubleClicked)="onCellDoubleClicked($event)"
          (gridReady)="onGridDetailReady($event)"
          >
        </ag-grid-angular>
      </as-split>
    </as-split-area>
  </as-split>
</section>

