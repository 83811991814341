/*******************************************************************************
  설  명 : 재고관리 - 발주관리
  작성일 : 2020-09-05
  작성자 : 송영석

  수정일 : 2021.05.14
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import { StockOrder } from '@app/service/stockOrder.service';
import { AuthService } from '@app/service/auth.service';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { BasicService } from '@app/service/basic.service';

@Component({
  selector: 'app-stock-order-add',
  templateUrl: './stock-order-add.component.html',
  styleUrls: ['./stock-order-add.component.scss']
})

export class StockOrderAddComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  stockOrderDetailList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiProperty: any;
  gridColumnApiProperty: any;
  columnDefsProperty: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;
  firstColumn = {
    headerName: '',
    field: '',
    width: 50,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    sortable: false,
    filter: false,
    resizable: false,
    flex: 0
  }

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
    agGridImageComponent: AgGridImageComponent,
  };

  public components: any;

  optionsXXL: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xxl',
    centered: true,
    windowClass: 'modal-fadeInDown'
  };

  public customerList: any = [];

  rowSelectProduct = [];
  rowSelectProperty = [];
  nowRowSelect = '';

  warehouse_list = [];
  private getWH_forCellEditor = () => ({values: this.warehouse_list.map(e => e.name)});
  selectWarehouse = '';
  // private getWHL_forCellEditor = () => ({values: this.warehouse_list.find(e => e.name === this.selectWarehouse)['detail'].map(e => e['location_cd'])});
  private getWHL_forCellEditor = params => ({values: this.warehouse_list.find(e => e.name == params.data.warehouse_seq)['detail'].map(e => e['location_cd'])});

  public customerChangedFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      approval_seq: ['', [] ],
      approval_memno: ['', [] ],
      gbn: [1, [Validators.required] ],
      title: ['', [Validators.required] ],
      date: [null, [] ],
      memo: ['', [] ],
      customer_seq : ['', [Validators.required] ],
      productList : [[]],
      productListProperty : [[]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private agGridExComponent: AgGridExComponent,
    private stockOrder: StockOrder,
    private authService: AuthService,
    private modalService: NgbModal,
    private basicService: BasicService,
  ) {

    this.customerChangedFunc = this.customerChanged.bind(this);

    this.columnDefs = [
      this.firstColumn,
      {headerName: '이미지', field: 'thumbnail_url', width: 80, cellClass: 'cp ag-cell50h center', cellRenderer: 'agGridImageComponent' },
      {headerName: '카테고리', field: 'category_name', width: 190, cellClass: 'cp ag-cell50h left' },
      {headerName: '상품명', field: 'product_name', width: 270, cellClass: 'cp ag-cell50h left' },
      {headerName: '발주수량', field: 'qty', width: 90, cellClass: 'cp ag-cell50h right',
        valueGetter: (params) => {
          params.data.qty = params.data.product_property.reduce((a,c) => a + parseInt(c['purchase_cnt']), 0);
          return String(params.data.qty).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
      {headerName: '발주단가', field: 'unit_amt', width: 100, cellClass: 'cp ag-cell50h right ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent', cellEditor: "numericCellEditor",
        valueGetter: (params) => String(params.data.unit_amt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
      },
      {headerName: '구매금액', field: 'amt', width: 90, cellClass: 'cp ag-cell50h right',
        valueGetter: (params) => {
          params.data.amt = params.data.qty * params.data.unit_amt;
          return String(params.data.amt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        }
      },
    ];

    // 상품별 색상 및 사이즈
    this.columnDefsProperty = [
      this.firstColumn,
      { headerName: '색상', field: 'display_color_name', width: 100, cellClass: 'cp ag-cell50h' },
      { headerName: '사이즈', field: 'display_size_name', width: 100, cellClass: 'cp ag-cell50h' },
      { headerName: '입고창고', field: 'warehouse_seq', width: 120, cellClass: 'cp ag-cell50h ag-cell-edit', editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: this.getWH_forCellEditor },
      { headerName: '입고위치', field: 'warehouse_location_seq', width: 120, cellClass: 'cp ag-cell50h ag-cell-edit', editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: this.getWHL_forCellEditor },
      // { headerName: '입고예정일', field: 'in_date', width: 120, cellClass: 'cp ag-cell50h center' },
      { headerName: '발주수량', field: 'purchase_cnt', width: 120, cellClass: 'cp ag-cell50h right ag-cell-edit', editable: true, cellEditor: "numericCellEditor", cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => String(params.data.purchase_cnt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
      },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };
    this.rowSelection = "multiple";
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
    this.components = { numericCellEditor: this.agGridExComponent.getNumericCellEditor() };

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();
    this.getWarehouseList();
    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = () => 50;

  /*******************************************************************************
    설  명 : 발주 상품정보 ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 상품별 색상 사이즈 ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyProperty(params) {
    this.gridApiProperty = params.api;
    this.gridColumnApiProperty = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 목록으로
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/stock/order'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상품 추가
  *******************************************************************************/
  addProduct(result) {
    const productList = JSON.parse(JSON.stringify(this.form.value.productList));
    result.forEach(e => {
      const _index = productList.findIndex(_e => _e['product_seq'] == e['seq']);
      if(!e['property_seq']) {
        this.toastrService.warning('색상사이즈 코드가 없는 상품은 무시합니다.');
        return;
      }
      const productListProperty = {
        property_seq : e['property_seq'],
        color_name : e['color_name'],
        display_color_name : e['display_color_name'],
        size_name : e['size_name'],
        display_size_name : e['display_size_name'],

        // warehouse_seq : '',
        warehouse_seq: this.warehouse_list.find(e => e['name'] == '대구창고')['name'],
        warehouse_location_seq : '',
        // in_date : 'DB칼럼없음',
        purchase_cnt : 0
      };
      _index < 0
      ? productList.push({
          product_seq : e['seq'],
          thumbnail_url : e['thumbnail_url'],
          category_name : e['category_name'],
          product_name : e['product_name'],

          qty : 0,
          unit_amt : e['buy_price'],
          amt : 0,
          product_property : [productListProperty]
        })
      : productList[_index]['product_property'].push(productListProperty)
      ;
    });
    this.form.patchValue({
      productList : productList,
    });
  }

  /*******************************************************************************
    설  명 : 발주등록 - 저장하기
    입력값 : {
      // 발주마스터
      gbn,
      title,
      date,
      memo,
      mem_no,
      customer_seq,

      // 발주 상품 내역
      product : [{
        product_seq,
        qty,
        unit_amt,
        amt,

        // 발주 상품별 색상 및 사이즈
        product_property : [{
          product_property_seq,
          warehouse_seq,
          warehouse_location_seq,
          purchase_cnt,
        }],

      }],
    }
  *******************************************************************************/
  setStockOrder() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if(!this.form.valid) {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      return;
    }
    const warning = [];
    this.form.value['productList'].forEach((e,i) => {
      if(!e['unit_amt']) warning.push(`상품정보 ${i + 1}번째 - 발주단가 누락`);
      e['product_property'].forEach((_e, _i) => {
        if(!_e['warehouse_seq']) warning.push(`상품정보 ${i + 1}번째, 상세정보 ${_i + 1}번째 - 입고창고 누락.`);
        if(!_e['purchase_cnt'] || _e['purchase_cnt'] == '0') warning.push(`상품정보 ${i + 1}번째, 상세정보 ${_i + 1}번째 - 발주수량 누락.`);
      });
    });
    if(warning.length) {
      this.toastrService.error(`미입력 ${warning.length} 건 :: ${warning.shift()}`, '데이터 누락');
      return;
    }

    this.authService.getLoginInfo.subscribe(__e => {
      this.stockOrder.setStockOrder({
        gbn : this.form.value['gbn'],
        date : this.form.value['date'],
        title : this.form.value['title'],
        memo : this.form.value['memo'],
        mem_no : __e['mem_no'],
        customer_seq : this.form.value['customer_seq'],
        product : this.form.value['productList'].map(e => ({
          product_seq : e['product_seq'],
          qty : e['qty'],
          unit_amt : e['unit_amt'],
          amt : e['amt'],
          product_property : e['product_property'].map(_e => ({
            product_property_seq : _e['property_seq'],
            warehouse_seq : this.warehouse_list.find(__e => __e['name'] == _e['warehouse_seq'])['seq'],
            warehouse_location_seq :
            this.warehouse_list.find(__e => __e['name'] == _e['warehouse_seq'])
            ? this.warehouse_list.find(__e => __e['name'] == _e['warehouse_seq'])['detail'].find(__e => __e['location_cd'] == _e['warehouse_location_seq'])
            ? this.warehouse_list.find(__e => __e['name'] == _e['warehouse_seq'])['detail'].find(__e => __e['location_cd'] == _e['warehouse_location_seq'])['seq']
            : '' : '',
            purchase_cnt : _e['purchase_cnt']
          }))
        }))
      }).then(response => {
        if(response.ResultCode) {
          this.toastrService.success('저장하였습니다.');
          this.goList();
        } else this.toastrService.error(response.ResultMessage)
      });

    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 상품 추가 모달 띄우기
  *******************************************************************************/
  addProductModal() {
    if(!this.form.value.customer_seq) {
      this.toastrService.warning('거래처를 먼저 선택하세요.');
      return;
    }

    const modalRef = this.modalService.open(ProductFindPropertyComponent, this.optionsXXL);
    modalRef.componentInstance.customer_seq = this.form.value.customer_seq;
    modalRef.result.then((result) => {
      this.addProduct(result);
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 상품정보 클릭시 색상/사이즈 보여주기
  *******************************************************************************/
  onRowClicked($event) {
    this.form.patchValue({
      productListProperty : $event.data.product_property
    });
    this.nowRowSelect = $event.data.product_seq;
  }

  /*******************************************************************************
    설  명 : 색상/사이즈 값 변경시
  *******************************************************************************/
  onCellValueChanged($event) {
    if($event.colDef.field === 'warehouse_seq') this.selectWarehouse = $event.value;
    this.gridApi.refreshCells();
  }

  /*******************************************************************************
    설  명 : 상품정보 row 선택
  *******************************************************************************/
  onSelectionChanged_product($event) {
    this.rowSelectProduct = [];
    this.gridApi.getSelectedRows().forEach(e => {
      this.rowSelectProduct.push(e['product_seq']);
    });
  }
  /*******************************************************************************
    설  명 : 색상/사이즈 row 선택
  *******************************************************************************/
  onSelectionChanged_property($event) {
    this.rowSelectProperty = [];
    this.gridApi.getSelectedRows().forEach(e => {
      this.rowSelectProperty.push(e['property_seq']);
    });
    this.selectWarehouse = '';
  }
  /*******************************************************************************
    설  명 : 상품정보 row 삭제
  *******************************************************************************/
  delete_product() {
    const productList = JSON.parse(JSON.stringify(this.form.value.productList));
    this.rowSelectProduct.forEach(e => {
      productList.splice(productList.findIndex(_e => _e['product_seq'] === e['product_seq']), 1);
    });
    this.form.patchValue({
      productList : productList,
      productListProperty : []
    });
  }
  /*******************************************************************************
    설  명 : 색상/사이즈 row 삭제
  *******************************************************************************/
  delete_property() {
    const productList = JSON.parse(JSON.stringify(this.form.value.productList))
    const _index = productList.findIndex(e => e['product_seq'] == this.nowRowSelect)
    if(_index < 0) {
      this.toastrService.warning('상품을 선택하세요.')
      return
    }
    else if(!this.gridApiProperty.getSelectedRows().length) {
      this.toastrService.warning('상품상세를 선택하세요.')
      return
    }
    this.gridApiProperty.getSelectedRows().forEach(e => {
        productList[_index]['product_property'].splice(productList[_index]['product_property'].findIndex(_e => _e['property_seq'] == e['property_seq']), 1)
    })
    let _productListProperty = productList[_index]['product_property']
    if(!productList[_index]['product_property'].length) {
      productList.splice(_index, 1)
      _productListProperty = []
    }
    this.form.patchValue({
      productList : productList,
      productListProperty : _productListProperty
    })
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
  *******************************************************************************/
  getWarehouseList() {
    this.basicService.getWarehouseList({
      searchText: ''
    }).then( response => {
      if(response.ResultCode) {
        response.data.forEach(e => {
          this.basicService.getWarehouseDetail(e['seq']).then(_R => {
            if(response.ResultCode)
              this.warehouse_list.push({
                name : _R.data['warehouse_name'],
                seq : _R.data['seq'],
                detail : _R['location'].map(e => ({
                  location_cd : e['location_cd'],
                  location_name : e['location_name'],
                  seq : e['seq']
                }))
              });
          }, error => this.toastrService.error( error, ''));
        });
      }
    }, error => this.toastrService.error( error, ''));
  }

  /*******************************************************************************
    설  명 : 색상사이즈 셀 클릭시
  *******************************************************************************/
  cellClick_property($event) {
    if($event.colDef.field == 'warehouse_location_seq' && !$event.data.warehouse_seq) {
      alert('창고를 먼저 선택하세요.');
      return;
    }
  }

  /*******************************************************************************
    설  명 : 매입거래처 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  customerChanged( e: any ): void {
    if( e.id == '' || e.id == 0 ) {
      this.form.patchValue({
        customer_seq: ''
      });
    } else {
      this.form.patchValue({
        customer_seq: e.id
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 거래처 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerList() {
    this.basicService.getCustomerList( {search_use: 'Y'} ).then( response => {
      if( response.ResultCode ) {
        // this.customerList = response.data;
        const data: any = [];

        data.push({
          id: '',
          text: '선택'
        });

        for(let item of response.data ) {
          data.push({
            id: item.seq,
            text: item.comname + ' (' + item.customer_id + ')'
          });
        }

        this.customerList = data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }
  
}
