<div class="modal-header">
  <h4 class="modal-title fb mb0">빠른발주</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <table class="table table-bordered table-small mb0">
  <caption class="hidden">빠른발주</caption>

  <colgroup>
    <col style="width:25%;" />
    <col style="width:75%;" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="" class="control-label">상품코드(자식)</label></th>
    <td>
      <input type="text" class="form-control form-control-small" [value]="product_property_seq" (change)="changeCnt($event, 'product_property_seq')">
    </td>
  </tr>
  <tr>
    <!-- <i class="material-icons cred f11">star</i> -->
    <th scope="row"><label for="" class="control-label">수량</label></th>
    <td>
      <input type="number" class="form-control form-control-small" [value]="purchase_cnt" (change)="changeCnt($event, 'purchase_cnt')">
    </td>
  </tr>
  </tbody>
  </table>
</div>

<div class="modal-footer">
  <!-- <div class="fl w50p">
    <button type="button" class="btn btn-small btn-danger" (click)="delete()">삭제</button>
  </div> -->
  <div class="fr w50p right">
    <button type="button" class="btn btn-small blue-btn" (click)="submit()">저장</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
  </div>
</div>