import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class StockOut {

  constructor(
    private restful: RestfulService,
  ) {
  }

  
  // 출고지시
  setStockOut( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setStockOut'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 주문상세 가져오기
  getStockOut( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'getStockOut'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 출고지시서 가져오기
  getStockOutOrder(params): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'getStockOutOrder'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 당일출고지시서 가져오기
  getStockOutOrderToday( search: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'getStockOutOrderToday'
    }, search
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  //  완료처리 버튼 클릭
  setOutComplete( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setOutComplete'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 출고제외 버튼 클릭
  setOutException( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setOutException'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 출고취소 버튼 클릭
  setOutCancel( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setOutCancel'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 송장 엑셀 업로드
  setDelivery( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setDelivery'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 출고지시 프린트 출력여부 저장
  setIsPrintSave( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setIsPrintSave'
    }, {
      params: array
    }
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 송장 삭제
  deleteDelivery( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'deleteDelivery'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 매입/입점 변경
  setBuyState( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setBuyState'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 배송처리 : 택배사 리스트 가져오기
  getDeliveryCompany(): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'getDeliveryCompany'
    }, {}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 배송처리 : 저장하기
  setEnterDelivery( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setEnterDelivery'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 배송취소
  setCancelDelivery( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOut',
      version: '1.0',
      action: 'setCancelDelivery'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
}
