/*******************************************************************************
  설  명 : 관리자 쿠폰 지급
  작성일 : 2024-06-07
  작성자 : 권세창
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';
import { BasicService } from '@app/service/basic.service';


@Component({
  selector: 'app-manager-coupon',
  templateUrl: './manager-coupon.component.html',
  styleUrls: ['./manager-coupon.component.scss']
})
export class ManagerCouponComponent implements OnInit {
  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() orderSeq: any;
  @Input() orderDetailSeq: any;

  public couponList: any;

  private titleDef = {title: '지급할 쿠폰을 선택하세요.', value: ''};

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      mem_no: ['', [Validators.required] ],
      coupon_seq: ['', [Validators.required] ],
      reason: ['', [Validators.required, Validators.maxLength(100)] ],
      order_seq: ['', [] ],
      order_detail_seq: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService,
    private basicService: BasicService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      mem_no: this.memberNo,
      order_seq: this.orderSeq , 
      order_detail_seq: this.orderDetailSeq
    });
   this.getAvailableCouponList() ; 

  }

  /*******************************************************************************
    설  명 : 쿠폰리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getAvailableCouponList() {
      this.couponList = [];
      this.couponList.push(this.titleDef);
  
      this.basicService.getAvailableBamaCouponList().then( response => {
          console.log(response.data);

        for (const item of response.data) {
          this.couponList.push({
            title: item.coupon_name,
            value: item.seq
          });
        }
      });
  
    }

  /*******************************************************************************
    설  명 : 쿠폰지급
  *******************************************************************************/
  setManagerCoupon() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if( this.form.valid ) {
      this.memberService.setManagerCoupon( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success('관리자 쿠폰 지급에 성공하였습니다.');
          this.activeModal.close();
        } else {
          this.toastrService.error('관리자 쿠폰 지급에 실패하였습니다.');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
