  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{totalCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">이번달 전체 매출</p>
        <p class="subtxt"><span class="cnt">{{statistic?.totalAmt | number}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">대구점 이번달 매출</p>
        <p class="subtxt"><span class="cnt">{{statistic?.daeguMtotal | number}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">대구점 금일 매출</p>
        <p class="subtxt"><span class="cnt">{{statistic?.daeguDtotal | number}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">서울점 이번달 매출</p>
        <p class="subtxt"><span class="cnt">{{statistic?.seoulMtotal | number}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">서울점 금일 매출</p>
        <p class="subtxt"><span class="cnt">{{statistic?.seoulDtotal | number}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="fl w80p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getOrderStoreSalesList()">
      <i class="material-icons-outlined">search</i>
    </button>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.storeSeq==''}" (click)="search.storeSeq='';getOrderStoreSalesList()">전체</button>
      <button type="button" *ngFor="let item of storeList" class="btn btn-default btn-small" [ngClass]="{active: search.storeSeq==item.seq}" (click)="search.storeSeq=item.seq;getOrderStoreSalesList()">{{item.store_name}}</button>
    </div>

    <select id="searchField" [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
      <option value="seq">주문번호</option>
      <option value="product_seq">대표상품번호</option>
      <option value="product_name">상품명</option>
      <option value="o_name">주문자명</option>
      <option value="id">주문자아이디</option>
      <option value="r_name">수신자명</option>
    </select>

    <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="검색어" />

    <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
    <ng-template #footerTemplate1>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
    </ng-template>
    <span class="ml5 mr5">~</span>
    <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
    <ng-template #footerTemplate2>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
    </ng-template>

    <div class="btn-group ml5">
      <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value)">{{item.title}}</button>
    </div>

  </div>

  <div class="fr w20p right">
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="7f452c5b7ccb40fb8a2a071284a01212" [btnGroup]="1"></btn-ag-grid-save>
  </div>

  <div class="cb"></div>

  <div class="mt5" style="height:calc(100% - 135px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%; height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="orderList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
      >
    </ag-grid-angular>
    <section class="pagination mt10" *ngIf="totalCount">
      <ngb-pagination
        [(page)]="search.pageNo"
        [pageSize]="search.pageRow"
        [collectionSize]="totalCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
  </div>
