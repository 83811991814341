import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AgGridImageComponent } from '@app/components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { UtilService } from '@app/service/util.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { AccountService } from '@app/service/account.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup
  public formErrors = {}

  gridApi: any
  gridColumnApi: any
  columnDefs: any

  gridApi_A: any
  gridColumnApi_A: any
  columnDefs_A: any

  gridApi_B: any
  gridColumnApi_B: any
  columnDefs_B: any

  defaultColDef: any
  domLayout: any
  rowSelection: any
  noRowsTemplate: string
  rowClassRules: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  }
  components: any

  search = {
    index : 1,
    count : 0
  }

  type = ['기간별 쇼핑몰 판매 조회','일매출 조회','정산서 조회']

  customerNameForExcelTitle = ''

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private stockOrderComponent: StockOrderComponent,
    private accountService: AccountService,
  ) {
    this.columnDefs = [
      {headerName: '결제일', field: '결제일' },
      {headerName: '쇼핑몰현금', field: '쇼핑몰현금',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '쇼핑몰카드', field: '쇼핑몰카드',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: 'ARS', field: 'ARS' }, // 데이터 출력안함. 기존 양식 유지용
      {headerName: '사용마일리지', field: '사용마일리지',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '해외판매', field: '해외판매' }, // 데이터 출력안함. 기존 양식 유지용
      {headerName: '대구카드', field: '대구카드',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '대구현금', field: '대구현금',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '서울카드', field: '서울카드',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '서울현금', field: '서울현금',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '오픈마켓', field: '오픈마켓',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '역입점', field: '역입점' } // 데이터 출력안함. 기존 양식 유지용
    ]

    this.columnDefs_A = [
      { headerName: '주문일', field: 'order_date', cellClass: 'center', width: 100, 
        valueGetter: params => ( params.data.gbn == '1' ? params.data.order_date : '' )
      },
      {headerName: '주문번호', field: 'order_seq', cellClass: 'cp center', width: 90 },
      {headerName: '주문자', field: 'o_name', cellClass: 'center', width: 170 },
      {headerName: '제품', field: 'product_name', cellClass: '', width: 350 },
      {headerName: '수량', field: 'qty', cellClass: 'center', width: 60,
        valueGetter: params => this.noFilterRowData(params) || this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '판매가', field: 'total_amt', cellClass: 'right', width: 95,
        valueGetter: params => this.noFilterRowData(params) || this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '입고가', field: 'buy_price', cellClass: 'right', width: 95,
        valueGetter: params => this.noFilterRowData(params) || this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '배송료', field: 'delivery_price', cellClass: 'right', width: 90 },
      {headerName: '사용마일리지', field: 'mileage', cellClass: 'right', width: 110,
        valueGetter: params => this.noFilterRowData(params) || this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '공급가', field: 'supply_amt', cellClass: 'right', width: 95,
        valueGetter: params => this.noFilterRowData(params) || this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '부가세', field: 'vat_amt', cellClass: 'right', width: 95,
        valueGetter: params => this.noFilterRowData(params) || this.stockOrderComponent.valueGetter_num(params) },
      // {headerName: '입금은행', field: 'bank' },
      {headerName: '입금은행', field: 'bank_method', cellClass: 'left', width: 170 },
      {headerName: '실입금', field: 'real_pay_amt', cellClass: 'right', width: 95,
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) }
    ]

    this.columnDefs_B = [
      {headerName: '주문번호', field: 'order_master_seq' },
      {headerName: '정산키', field: '' },
      {headerName: '주문일', field: 'order_date' },
      {headerName: '배송일', field: 'consign_date' },
      {headerName: '주문자', field: 'o_name' },
      {headerName: '모델', field: 'product_name' },
      {headerName: '수량', field: 'qty',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '가격', field: 'total_amt',
        valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '수신자', field: 'r_name' },
      {headerName: '전화번호', field: 'r_hp' },
      {headerName: '우편번호', field: 'r_zipcode' },
      {headerName: '주소', field: 'r_address' },
      {headerName: '상세주소', field: 'r_address_detail' }
    ]

    this.defaultColDef = {...this.stockOrderComponent.defaultColDef}
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate
    this.rowSelection = this.stockOrderComponent.rowSelection
    this.components = this.stockOrderComponent.components

    this.rowClassRules = {
      'fb': function (params) {
        return params.data.gbn == '2' || params.data.gbn_total == '2';
      },
    };
  }

  noFilterRowData(params) {
    const _data = params.data[params.colDef.field]
    return _data == ' ' ? _data : false
  }

  ngOnInit(): void {
    this.buildForm()
    this.getList()
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      date : [this.stockOrderComponent.getToday(), [Validators.required] ],
      gbn : [1, [Validators.required] ],

      searchField : [''],
      searchText : [''],

      rowData : [[]]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors )
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReady_A(params) {
    this.gridApi_A = params.api;
    this.gridColumnApi_A = params.columnApi;
  }
  onGridReady_B(params) {
    this.gridApi_B = params.api;
    this.gridColumnApi_B = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field == 'order_seq' ) {
      let orderSeq = $event.data.order_seq;
      const url = '/order/detail?seq=' + orderSeq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 검색 조건 변경
  *******************************************************************************/
  setValue($event) {
    switch($event.target.id) {
      case 'searchField': this.form.patchValue({searchField : $event.target.value }); break
    }
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
  *******************************************************************************/
  searchList( $event ) {
    if( $event.key == 'Enter' ) this.getList()
  }

  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(which, value) {
    this.stockOrderComponent.selectDate(which, value, this)
    // this.btnClick( which, [value['year'], this.stockOrderComponent._2D(value['month'])].join('-') )
  }
  /*******************************************************************************
    설  명 : 검색조건 버튼 클릭
  *******************************************************************************/
  btnClick(which, value) {
    this.form.patchValue({[which] : value})
    this.getList()
  }
  /*******************************************************************************
    설  명 : 날짜선택 바로가기 버튼 클릭
  *******************************************************************************/
  quickDate(quickTime) {
    this.stockOrderComponent.quickDate(quickTime, this)
  }

  /*******************************************************************************
    설  명 : 목록 가져오기
  *******************************************************************************/
  getList() {
    this.form.patchValue({rowData : [] })

    switch(this.form.value['gbn']) {
      case 0:
        this.accountService.getMonthSales({
          date : this.form.value['date'].slice(0,7)
        }).then(response => {
          if(response.ResultCode) {
            this.form.patchValue({rowData : this.cooking_getMonthSales(response.data) })
          } else this.toastrService.error(response.ResultMessage)
        })
        break
      case 1:
        this.accountService.getDaySales({
          date : this.form.value['date']
        }).then(response => {
          if(response.ResultCode) {
            // this.form.patchValue({rowData : this.cooking_getDaySales(response.data) })
            this.form.patchValue({rowData : response.data })
          } else this.toastrService.error(response.ResultMessage)
        })
        break
      case 2:
        if(!this.form.value['searchField']) {
          this.toastrService.warning('정렬기준을 선택하세요.')
          return
        } else if(!this.form.value['searchText']) {
          this.toastrService.warning('SCM ID를 입력하세요.')
          return
        }
        this.accountService.getMonthEnterSales({
          date : this.form.value['date'].slice(0,7),
          searchField : this.form.value['searchField'],
          searchText : this.form.value['searchText']
        }).then(response => {
          if(response.ResultCode) {
            this.customerNameForExcelTitle = response.data[0].comname
            this.form.patchValue({rowData : response.data })
          } else this.toastrService.error(response.ResultMessage)
        })
        break
    }
  }

  getLastDay = _today => {
    _today = new Date(_today)
    return new Date(_today.getFullYear(), _today.getMonth()+1, 0).getDate()
  }

  cooking_getMonthSales(data) {
    const _today = new Date(this.form.value['date'])
    const _lastDay = new Date(_today.getFullYear(), _today.getMonth()+1, 0).getDate()
    const _A = []
    for(let i = 0; i < _lastDay; i++) _A.push({
      결제일 : `${this.form.value['date'].slice(0,7)}-${this.stockOrderComponent._2D(i+1)}`,
      쇼핑몰현금 : 0, 쇼핑몰카드 : 0, ARS : 0, 사용마일리지 : 0, 해외판매 : 0, 대구카드 : 0, 대구현금 : 0, 서울카드 : 0, 서울현금 : 0, 오픈마켓 : 0, 역입점 : 0
    })
    data.forEach(e => {
      const _index = parseInt(e['pay_date'].slice(-2)) - 1
      if(e.pay_method_name == 'openmarket') _A[_index]['오픈마켓'] += parseInt(e['pay_amt'])
      if( e.pay_method == '0002' && e.store_seq == '1' ) _A[_index]['쇼핑몰현금'] += parseInt(e['pay_amt'])
      if( e.pay_method == '0001' && e.store_seq == '1' ) _A[_index]['쇼핑몰카드'] += parseInt(e['pay_amt'])
      if( e.pay_method == '0006' ) _A[_index]['사용마일리지'] += parseInt(e['pay_amt'])
      if( e.pay_method == '0001' && e.store_seq == '2' ) _A[_index]['대구카드'] += parseInt(e['pay_amt'])
      if( e.pay_method == '0002' && e.store_seq == '2' ) _A[_index]['대구현금'] += parseInt(e['pay_amt'])
      if( e.pay_method == '0001' && e.store_seq == '3' ) _A[_index]['서울카드'] += parseInt(e['pay_amt'])
      if( e.pay_method == '0002' && e.store_seq == '3' ) _A[_index]['서울현금'] += parseInt(e['pay_amt'])
    })

    return _A
  }

  cooking_getDaySales(data) {
    const _set = new Set()
    const _A = data.map(e => {
      _set.add(e['bank'])
      return ({
        ...e,
        order_date : `${this.stockOrderComponent._2D(new Date(e['order_date']).getMonth()+1)}/${this.stockOrderComponent._2D(new Date(e['order_date']).getDate())}  [${e['order_status']}]`,
        // o_name : e['openmarket_yn'] ? e['shop_gbn_name'] : e['o_name'],
        bankAndPay : `${e['bank']}[${e['pay_method_name'] || ''}]`,
        product_name : `${e['product_name']}${e['sub_cnt'] > 1 ? ' 外' : ''}`,
        supply : parseInt(e['total_amt']) - Math.floor(parseInt(e['total_amt']) / 11),
        vat : Math.floor(parseInt(e['total_amt']) / 11),
        use_mileage : parseInt(e['use_mileage'] || 0),
        pay_amt : parseInt(e['pay_amt'] || 0),
      })
    })

    const _B = [];
    [..._set].forEach(e => {
      const _temp = _A.filter(_e => _e['bank'] == e)
      const _calc = field => _temp.reduce((a,c) => a + parseInt(c[field] || 0), 0)
      _temp.push({
        // order_date: `${e} 소계`, 
        // order_status_name: '', 
        // o_name: '', 
        // product_name: '',
        // qty: _calc('qty'),
        // total_amt: _calc('total_amt'),
        // buy_price: _calc('buy_price'),
        // use_mileage: _calc('use_mileage'),
        // supply: _calc('supply'),
        // vat: _calc('vat'),
        // bank: '',
        order_date: ' ', 
        order_status_name: ' ', 
        o_name: ' ', 
        product_name: ' ',
        qty: ' ',
        total_amt: ' ',
        buy_price: ' ',
        use_mileage: ' ',
        supply: ' ',
        vat: ' ',
        bank: ' ',
        bankAndPay: e,
        pay_amt: _calc('pay_amt'),
      })
      _B.push(..._temp)
    })

    const lastRow_fieldCalc = (pay_method_name, field) => _B.filter(e => e['order_date'] != ' ').reduce((a,c) => a + (c['pay_method_name'] == pay_method_name ? parseInt(c[field]) : 0), 0)
    const lastRow_allCalc = field => _B.filter(e => e['order_date'] != ' ').reduce((a,c) => a + parseInt(c[field]), 0)
    _B.push({
      order_date: ' ', 
      order_status_name: ' ', 
      o_name: ' ', 
      product_name: '현금계',
      qty: lastRow_fieldCalc('현금', 'qty'),
      total_amt: lastRow_fieldCalc('현금', 'total_amt'),
      buy_price: lastRow_fieldCalc('현금', 'buy_price'),
      use_mileage: lastRow_fieldCalc('현금', 'use_mileage'),
      supply: lastRow_fieldCalc('현금', 'supply'),
      vat: lastRow_fieldCalc('현금', 'vat'),
      bank: ' ',
      bankAndPay: '-',
      pay_amt: lastRow_fieldCalc('현금', 'pay_amt'),
    }, {
      order_date: ' ', 
      order_status_name: ' ', 
      o_name: ' ', 
      product_name: '카드계',
      qty: lastRow_fieldCalc('카드', 'qty'),
      total_amt: lastRow_fieldCalc('카드', 'total_amt'),
      buy_price: lastRow_fieldCalc('카드', 'buy_price'),
      use_mileage: lastRow_fieldCalc('카드', 'use_mileage'),
      supply: lastRow_fieldCalc('카드', 'supply'),
      vat: lastRow_fieldCalc('카드', 'vat'),
      bank: ' ',
      bankAndPay: '-',
      pay_amt: lastRow_fieldCalc('카드', 'pay_amt'),
    }, {
      order_date: ' ', 
      order_status_name: ' ', 
      o_name: ' ', 
      product_name: '총계',
      qty: lastRow_allCalc('qty'),
      total_amt: lastRow_allCalc('total_amt'),
      buy_price: lastRow_allCalc('buy_price'),
      use_mileage: lastRow_allCalc('use_mileage'),
      supply: lastRow_allCalc('supply'),
      vat: lastRow_allCalc('vat'),
      bank: ' ',
      bankAndPay: '-',
      pay_amt: lastRow_allCalc('pay_amt'),
    })

    return _B
  }

  /*******************************************************************************
    설  명 : 엑셀 출력
  *******************************************************************************/
  printExcel(_case = 0) {
    const contents = _case == 0 
    ? [`
      <table width="1020" border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <tr align="center"> 
          <td height="30" colspan="12"><strong>기간별 쇼핑몰 매출현황</strong></td>
        </tr>
        <tr align="center" bgcolor="#CCCCCC"> 
          <td width="100" height="30"><font color="#333333" size="2">결제일</font></td>
          <td width="80"><font color="#333333" size="2">쇼핑몰현금</font></td>
          <td width="80"><font color="#333333" size="2">쇼핑몰카드</font></td>
          <td width="80"><font color="#333333" size="2">ARS</font></td>
          <td width="100"><font color="#333333" size="2">사용<br>마일리지</font></td>
          <td width="80"><font color="#333333" size="2">해외판매</font></td>
          <td width="70"><font color="#333333" size="2">매장카드</font></td>
          <td width="60"><font color="#333333" size="2">매장현금</font></td>
          <td width="60"><font color="#333333" size="2">서울카드</font></td>
          <td width="80"><font color="#333333" size="2">서울현금</font></td>
          <td width="80"><font color="#333333" size="2">오픈마켓</font></td>
          <td width="80"><font color="#333333" size="2">역입점</font></td>
        </tr>
    `]
    : _case == 1
    ? [`
      <table width="1020" border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <tr align="center"> 
          <td height="30" colspan="13"><strong>${this.form.value.date} 쇼핑몰 매출현황</strong></td>
        </tr>
        <tr align="center" bgcolor="#CCCCCC"> 
          <td width="80" height="30"><font color="#333333" size="2">주문일 </font></td>
          <td width="80"><font color="#333333" size="2">주문번호 </font></td>
          <td width="80"><font color="#333333" size="2">주문자 </font></td>
          <td width="180"><font color="#333333" size="2">제품</font></td>
          <td width="40"><font color="#333333" size="2">수량 </font></td>
          <td width="80"><font color="#333333" size="2">판매가 </font></td>
          <td width="70"><font color="#333333" size="2">입고가 </font></td>
          <td width="60"><font color="#333333" size="2">배송료 </font></td>
          <td width="60"><font color="#333333" size="2">마일리지</font></td>
          <td width="80"><font color="#333333" size="2">공급가 </font></td>
          <td width="70"><font color="#333333" size="2">부가세 </font></td>
          <td width="120"><font color="#333333" size="2">입금은행 </font></td>
          <td width="80"><font color="#333333" size="2">실입금 </font></td>
        </tr>
    `]
    : [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <tr align="center"> 
          <td height="40" colspan="11" style="font-size:14pt;"><strong>${this.form.value.date} ${this.form.value.searchText} 주문내역서 ( 출력 : ${new Date().toLocaleString()} )</strong></td>
        </tr>
        <tr align="center" bgcolor="#CCCCCC"> 
          <td width="80" height="30">주문번호</td>
          <td width="80" height="30">정산키</td>
          <td width="80" height="30">주문일</td>
          <td width="90"><font color="#333333" size="2">주문자</font></td>
          <td width="500"><font color="#333333" size="2">모델</font></td>
          <td width="40"><font size="2">수량</font></td>
          <td width="60"><font size="2">가격</font></td>
          <td width="90"><font color="#333333" size="2">수신자</font></td>
          <td width="140">전화번호</td>
          <td width="120">우편번호</td>
          <td width="500">주소</td>
        </tr>
    `]

    let data = this.form.value.rowData
    if(!data.length) {
      this.toastrService.warning('현재 조회된 데이터가 없으므로 재검색합니다.')
      const _p = new Promise((resolve, reject) => {this.getList()})
      _p.then(val => {
        data = this.form.value.rowData
        if(!data.length) this.toastrService.warning('출력할 데이터가 없습니다.')
      }).catch(reason => this.toastrService.error(reason))
    }

    data.forEach(element => {

      let productName = ( element.product_name !== null ) ? element.product_name : '';
      if( productName != '' ) {
        productName = productName.replace(/<br>/g,'<br style="mso-data-placement:same-cell;">');
        productName = productName.replace(/<br\/>/g,'<br style="mso-data-placement:same-cell;">');
        productName = productName.replace(/<BR>/g,'<br style="mso-data-placement:same-cell;">');
        productName = productName.replace(/<BR\/>/g,'<br style="mso-data-placement:same-cell;">');
      }

      _case == 0 
      ? contents.push(`
        <tr>
          <td style="text-align:center;">${element['결제일']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['쇼핑몰현금']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['쇼핑몰카드']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['ARS']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['사용마일리지']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['해외판매']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['대구카드']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['대구현금']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['서울카드']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['서울현금']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['오픈마켓']}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element['역입점']}</td>
        </tr>
      `)
      : _case == 1 
      ? contents.push(`
        <tr style="height: 40px; background-color: ${
          (
            element['order_status'] == 3 ||
            element['order_status'] == 4 ||
            element['order_status'] == 5 ||
            element['order_status'] == 6 ||
            element['order_status'] == 7 ||
            element['order_status'] == 10
          ) ? '#FAD7E5' : ''
        }; ${element.gbn == '2' ? 'font-size: 12px; font-weight: bold;' : ''}">
          <td align="center"><font size="2">${element.gbn == '1' ? element.order_date : ''}</font></td>
          <td align="center"><font size="2">${element.order_seq}</font></td>
          <td align="center"><font size="2">${element.o_name}</font>${element.o_name == '상사회원' ? '<font color="red" size="1"><b>(상)</b></font>' : ''}</td>
          <td align="left"><font size="2">${ productName } </font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.qty !== null ? element.qty : 0}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.total_amt}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.buy_price !== null ? element.buy_price : 0}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.delivery_price}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.mileage}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2" color="#FF0000">${element.supply_amt}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2" color="#FF0000">${element.vat_amt}</font></td>
          <td align="left"><font size="2">${element.bank_method}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.real_pay_amt}</font></td>
        </tr>
      `)
      : contents.push(`
        <tr>
          <td width="80" align="center" height="22"><font size="2">${element.order_master_seq}</font></td>
          <td width="60" align="left" height="22"><font size="2"></font></td>	
          <td width="80" align="left" height="22"><font size="2">${element.order_date}</font></td>
          <td width="90" align="center"><font size="2">${element.o_name}</font></td>
          <td width="500" align="left" style="font-size:8pt;">${element.product_name} ${element.order_status_name}</td>
          <td width="40" align="right"><font size="2">${element.qty}</font></td>
          <td width="60" align="right"><font size="2">${element.total_amt}</font></td>
          <td width="90" align="center"><font size="2">${element.r_name}</font></td>
          <td width="140" align="right"><font size="2">${element.r_hp}</font></td>
          <td width="120" align="center"><font size="2">${element.r_zipcode}</font></td>
          <td width="500" style="font-size:8pt;">${element.r_address} ${element.r_address_detail}</td>
        </tr>
      `)
    })
    contents.push(`</table>`)

    _case == 1 
    ? contents.push(`
      <br><br>
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666" style="font-size: 12px">
        <tr><td>0</td><td>주문접수</td></tr>
        <tr><td>1</td><td>결제확인</td></tr>
        <tr><td>2</td><td>배송준비</td></tr>
        <tr><td>3</td><td>전체반품</td></tr>
        <tr><td>4</td><td>일부반품</td></tr>
        <tr><td>5</td><td>전체교환</td></tr>
        <tr><td>6</td><td>일부교환</td></tr>
        <tr><td>7</td><td>주문취소</td></tr>
        <tr><td>8</td><td>발송완료</td></tr>
        <tr><td>9</td><td>구매확정</td></tr>
        <tr><td>10</td><td>출고전취소</td></tr>
      </table>
      <br><br>
    `) : ''

    const _date = new Date()
    const fileName = _case == 0 
    ? `businessOrder_${[_date.getFullYear(), this.stockOrderComponent._2D(_date.getMonth()+1), this.stockOrderComponent._2D(_date.getDate())].join('')}`
    : _case == 1
    ? `dailyOrder_${this.stockOrderComponent.getToday()}`
    : `${this.customerNameForExcelTitle}${this.form.value.date.split('-').join('').slice(0,6)}`

    this.stockOrderComponent.printExcel(fileName, contents.join(''))
  }


}
