import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AgGridImageComponent } from '@app/components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@app/components/ag-grid-excomponent/ag-grid-excomponent';

import { UtilService } from '@app/service/util.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { InventoryService } from '@app/service/inventory.service';
import { InventorySchedulePostAndPutComponent } from './inventory-schedule-post-and-put/inventory-schedule-post-and-put.component';

@Component({
  selector: 'app-stock-inventory-check',
  templateUrl: './stock-inventory-check.component.html',
  styleUrls: ['./stock-inventory-check.component.scss']
})
export class StockInventoryCheckComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup
  public formErrors = {}

  gridApi_A: any
  gridColumnApi_A: any
  columnDefs_A: any
  gridApi_B: any
  gridColumnApi_B: any
  columnDefs_B: any

  defaultColDef: any
  domLayout: any
  rowSelection: any
  noRowsTemplate: string

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  }
  components: any

  rowHeight = 100

  selectedSchedule = {pi_title : ''}

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private stockOrderComponent: StockOrderComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private inventoryService: InventoryService,
    private modalService: NgbModal,
  ) {
      this.columnDefs_A = [
        {headerName: '제목', field: 'pi_title', width: 170, cellClass: 'cp left' },
        {headerName: '연도', field: 'pi_yyyy', width: 100, flex: 0, cellClass: 'cp center' },
        {headerName: '시작일', field: 'pi_sdate', width: 100, flex: 0, cellClass: 'cp' },
        {headerName: '종료일', field: 'pi_edate', width: 100, flex: 0, cellClass: 'cp' },
        {headerName: '메모', field: 'pi_memo', width: 100, flex: 0, cellClass: 'cp' },
      ]

      this.columnDefs_B = [
        {headerName: '실사등록일시', field: 'pi_date', cellClass: 'cp', width: 110 },
        {headerName: '창고', field: 'warehouse_name', cellClass: 'cp', width: 80 },
        {headerName: '위치', field: 'location', cellClass: 'cp', width: 70 },
        {headerName: '담당자', field: 'pi_person_name', cellClass: 'cp', width: 80 },
        {headerName: '부모번호', field: 'product_seq', cellClass: 'cp', width: 80 },
        {headerName: '자식번호', field: 'property_seq', cellClass: 'cp', width: 80 },
        {headerName: '상품명', field: 'product_name', cellClass: 'cp', width: 300, flex: 1 },
        {headerName: '색상', field: 'display_color_name', cellClass: 'cp', width: 100 },
        {headerName: '사이즈', field: 'display_size_name', cellClass: 'cp', width: 100 },
        {headerName: '바코드', field: 'barcode', cellClass: 'cp', width: 120 },
        {headerName: '수량', field: 'qty', cellClass: 'cp right', width: 70 },
        {headerName: '메모', field: 'memo', cellClass: 'cp', width: 100 }
      ]

      this.defaultColDef = this.stockOrderComponent.defaultColDef
      this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate
      this.rowSelection = this.stockOrderComponent.rowSelection
      this.components = this.stockOrderComponent.components
    }

  ngOnInit(): void {
    this.buildForm()
    this.getSchedule()
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      rowData_schedule : [[]],
      rowData_excute : [[]]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors )
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReady_A(params) {
    this.gridApi_A = params.api
    this.gridColumnApi_A = params.columnApi
  }
  onGridReady_B(params) {
    this.gridApi_B = params.api
    this.gridColumnApi_B = params.columnApi
  }

  /*******************************************************************************
    설  명 : row click
  *******************************************************************************/
  onRowClicked_A($event) {
    this.selectedSchedule = $event.data
    this.getInventoryData($event.data.seq)
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApi_A.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : cell click
  *******************************************************************************/
  cellClick_B($event) {
    // if( $event.colDef.field === 'master_seq' ) {
    //   let seq = $event.data.master_seq;
    //   const url = '/order/detail?seq=' + seq;
    //   window.open("about:blank").location.href = url;
    // }
  }

  /*******************************************************************************
    설  명 : 실사계획 가져오기
  *******************************************************************************/
  getSchedule() {
    this.inventoryService.getSchedule({}).then(response => {
        if(response.ResultCode) {
          if(!response.data.length) this.toastrService.warning('데이터가 없습니다.')

          this.form.patchValue({rowData_schedule : response.data || [] })
        } else this.toastrService.error(response.ResultMessage)
      })
  }

  /*******************************************************************************
    설  명 : 추가/수정 모달 띄우기
  *******************************************************************************/
  openPostAndPutScheduleModal(data = {}) {
    const modalRef = this.modalService.open(InventorySchedulePostAndPutComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'lg',
      centered: true,
      windowClass: 'modal-fadeInDown'
    })
    
    modalRef.componentInstance.data = data

    modalRef.result.then((result) => {
      if( result ) {
        this.softReset()
      }
    }, (reason) => {
    })
  }

  /*******************************************************************************
    설  명 : 실사계획 post
  *******************************************************************************/
  postSchedule() {
    this.openPostAndPutScheduleModal()
  }

  /*******************************************************************************
    설  명 : 실사계획 put
  *******************************************************************************/
  putSchedule() {
    const data = this.gridApi_A.getSelectedRows()
    if(!data.length) {
      this.toastrService.error('수정할 데이터를 선택하세요.')
      return
    }

    this.openPostAndPutScheduleModal(data[0])
  }
  
  /*******************************************************************************
    설  명 : 실사계획 delete
  *******************************************************************************/
  deleteSchedule() {
    const data = this.gridApi_A.getSelectedRows()
    if(!data.length) {
      this.toastrService.error('삭제할 데이터를 선택하세요.')
      return
    } else if(!confirm('삭제하시겠습니까?')) return

    this.inventoryService.deleteSchedule(data[0].seq).then(response => {
      if(response.ResultCode) {
        this.toastrService.success('성공')

        this.softReset()
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 실사계획 reset
  *******************************************************************************/
  softReset() {
    this.form.patchValue({rowData_excute : [] })
    this.getSchedule()
  }

  /*******************************************************************************
    설  명 : 실사결과 가져오기
  *******************************************************************************/
  getInventoryData(seq) {
    this.inventoryService.getInventoryData({schedule_seq: seq}).then(response => {
      if(response.ResultCode) {
        if(!response.data.length) this.toastrService.warning('데이터가 없습니다.')

        this.form.patchValue({rowData_excute : response.data || [] })
      } else this.toastrService.error(response.ResultMessage)
    })
  }

}
