<form name="form" id="form" form-error-focus novalidate>
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title bold">임시저장 결재문서 관리</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
  <!-- Modal header -->

  <!-- Modal body -->
  <div class="modal-body">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let tmp of approvalTmp">
        <div class="fl w85p">
          <div class="f12">{{tmp.write_date}}</div>
          <div class="f15 mt10 mb10">[{{tmp.title}}] 제목 {{tmp.subject}}</div>
          <div class="f13 mb5" *ngIf="tmp.approval !== ''"><span class="badge badge-primary mr5 f12">결재</span>{{tmp.approval}}</div>
          <div class="f13 mb5" *ngIf="tmp.agreement !== ''"><span class="badge badge-success mr5 f12">협조</span>{{tmp.agreement}}</div>
          <div class="f13" *ngIf="tmp.reference !== ''"><span class="badge badge-warning mr5 f12">참조</span>{{tmp.reference}}</div>
        </div>
        <div class="fr w15p right">
          <button type="button" class="btn btn-default btn-grid2" (click)="delApprovalTmp(tmp.seq)">
            <i class="material-icons micon-close f15">&#xe5cd;</i>
          </button>
        </div>
        <div class="cb"></div>
      </li>
    </ul>
  </div>
  <!-- Modal body -->

  <!-- Modal footer -->
  <div class="modal-footer clearfix">
    <button type="button" class="btn btn-default btn-small f15 fr" aria-label="Close" (click)="activeModal.dismiss()">닫기</button>
  </div>
  <!-- Modal footer -->
</form>

