import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteMenuService {

  constructor(
    public restful: RestfulService,
  ) { }

  // 메뉴 리스트 트리 조회
  getMenuList(): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'favoriteMenu',
      version: '1.0',
      action: 'getMenuList'
    }, {}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 나만의 메뉴 리스트 트리 조회
  getFavoriteMenuList(): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'favoriteMenu',
      version: '1.0',
      action: 'getFavoriteMenuList'
    }, {}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 나만의 메뉴 그룹 저장(추가 seq = 0 or '', 수정)
  setFavoriteMenuGroup( form ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'favoriteMenu',
      version: '1.0',
      action: 'setFavoriteMenuGroup'
    }, form.value
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 나만의 메뉴 삭제
  delFavoriteMenu( seq: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'favoriteMenu',
      version: '1.0',
      action: 'delFavoriteMenu'
    }, {
      seq: seq
    }).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  // 메뉴 그룹 삭제
  delFavoriteMenuGroup(form): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'favoriteMenu',
      version: '1.0',
      action: 'delFavoriteMenuGroup'
    }, form.value
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  setFavoriteMenu(menuList): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'favoriteMenu',
      version: '1.0',
      action: 'setFavoriteMenu'
    }, {params : menuList}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 나만의 메뉴 추가
  addFavoriteMenu(): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'favoriteMenu',
      version: '1.0',
      action: 'addFavoriteMenu'
    }, { }
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 나만의 메뉴 순서 변경 - 협의 필요
  sortFavoriteMenu( menuList: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'favoriteMenu',
      version: '1.0',
      action: 'sortFavoriteMenu'
    }, menuList
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

}
