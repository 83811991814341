import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '@app/service/event.service';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { EventConsonantAddComponent } from '@page/basic/event/event-consonant/add/event-consonant-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-event-consonant',
  templateUrl: './event-consonant.component.html',
  styleUrls: ['./event-consonant.component.scss']
})
export class EventConsonantComponent implements OnInit {

  public search: any = {
    searchText: '',
    event_quiz_seq: '',
  };

  public eventQuizList: any = [];
  public eventQuizAnswerList: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiAnswer: any;
  gridColumnApiAnswer: any;
  columnDefsAnswer: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  deleteColumnDefs: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };  

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private eventService: EventService,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', width: 70, cellClass: 'cp center',
        // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '문제', field: 'question', width: 130, cellClass: 'cp center' },
      { headerName: '정답', field: 'answer', width: 130, cellClass: 'cp center' },
      { headerName: '힌트', field: 'hint', width: 500, cellClass: 'cp center cell-wrap-text', 
        autoHeight: true, 
        cellRenderer: 'agGridHtmlComponent'
      },
      { headerName: '시작일', field: 'start_date', width: 110, cellClass: 'cp center' },
      { headerName: '종료일', field: 'end_date', width: 110, cellClass: 'cp center' }
    ];

    // ag grid 컬럼 선언
    this.columnDefsAnswer = [
      { headerName: '순번', field: 'rowIndex', width: 50, cellClass: 'cp center', 
        valueGetter: function(params) {
          let rowData = params.node.gridOptionsWrapper.gridOptions.rowData.length;
          let rowIndex = params.node.rowIndex;
          return parseInt(rowData) - rowIndex;
        }
        // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '회원명', field: 'name', width: 80, cellClass: 'cp center' },
      { headerName: '회원아이디', field: 'id', width: 115, cellClass: 'cp left' },
      { headerName: '마일리지', field: 'mileage', width: 80, cellClass: 'cp right', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
      { headerName: '디바이스', field: 'device', width: 80, cellClass: 'cp center',
        valueGetter: function(params) {
          let device: any;
          switch( params.data.device ) {
            case 'M':
              device = '모바일';
              break;
            case 'W':
              device = 'PC웹';
              break;
            case 'P':
              device = '포스';
              break;
            default:
              device = '';
          }

          return device;
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 화면 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getEventQuizList();
  }

  /*******************************************************************************
    설  명 : 검색 초기화
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
    };

    this.getEventQuizList();
  }

  /*******************************************************************************
    설  명 : 리스트 가져오기
  *******************************************************************************/
  getEventQuizList() {
    this.eventService.getEventQuizList(this.search).then(response => {
      if (response.ResultCode) {
        this.eventQuizList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 정답자 리스트 가져오기
  *******************************************************************************/
  getEventQuizAnswerList() {
    this.eventService.getEventQuizAnswerList(this.search).then(response => {
      if (response.ResultCode) {
        this.eventQuizAnswerList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 등록하기
  *******************************************************************************/
  setEventQuiz( type: boolean ) {
    let params = {};

    if( type === true ) {
      params = this.gridApi.getSelectedRows()[0];
    }

    const modalRef = this.modalService.open(EventConsonantAddComponent, options);

    modalRef.componentInstance.params = params;

    modalRef.result.then((result) => {
      if (result) {
        this.getEventQuizList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색어 input 엔터 키 입력 시
  *******************************************************************************/
  searchList(event) {
    if( event.key == 'Enter' ) {
      this.getEventQuizList();
      }
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 시 처리
  *******************************************************************************/
  onRowClicked(event: any) {
    this.search.event_quiz_seq = event.data.seq;

    this.getEventQuizAnswerList();
  }

  /*******************************************************************************
    설  명 : 그리드 초기화
  *******************************************************************************/
  onGridReadyAnswer(params) {
    this.gridApiAnswer = params.api;
    this.gridColumnApiAnswer = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApiAnswer.setQuickFilter(searchText.value);
  }
  
}
