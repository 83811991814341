import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICellRendererParams } from 'ag-grid-community';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as $ from 'jquery';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { BikeService } from '@app/service/bike.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { MatTooltipComponent } from '@components/mat-tooltip/mat-tooltip.component';

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-bike-advertisement',
  templateUrl: './bike-advertisement.component.html',
  styleUrls: ['./bike-advertisement.component.scss']
})
export class BikeAdvertisementComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    status: '',
    sdate: '',
    edate: '',
    searchText: ''
  };

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    processCount: 0,
    standbyCount: 0,
    completeCount: 0
  }

  // 그리드 관련 선언
  advertisementList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public form: FormGroup;
  public formErrors = {};

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public summernoteConfig: any = {...config.summernoteConfig, ...{disableDragAndDrop: true}};

  public baseURL = config.baseUrl;

  /*******************************************************************************
     설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      advertisement_name: ['', [Validators.required] ],
      advertisement_link: ['', [] ],
      advertisement_sdate: [null, [Validators.required] ],
      advertisement_edate: [null, [Validators.required] ],
      advertisement_summary: ['', [] ],
      advertisement_contents: ['', [] ],
      sort_order: ['', [] ],
      file: ['', [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private bikeService: BikeService,
    private restful: RestfulService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
  ) {
    this.summernoteConfig.height = 100;

    this.buildForm();
    
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '이동', field: '', width: 50, cellClass: 'cp center', rowDrag: true, filter: false, sortable: false },
      { headerName: '정렬순서', field: 'sort_order', width: 75, cellClass: 'cp center' },
      { headerName: '상태', field: 'status', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( params.data.status === '1' ) {
            return '<span class="badge badge-success f12">진행중</span>';
          } else if ( params.data.status === '2' ) {
            return '<span class="badge badge-primary f12">진행예정</span>';
          } else {
            return '<span class="badge badge-secondary f12">종료</span>';
          }
        }
      },
      { headerName: '제목', field: 'advertisement_name', width: 315, cellClass: 'cp' },
      { headerName: '시작기간', field: 'advertisement_sdate', width: 145, cellClass: 'cp center' },
      { headerName: '종료기간', field: 'advertisement_edate', width: 145, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 광고 리스트 가져오기
    this.getBikeAdvertisementList();

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        // this.toastrService.success( response.message, '');

        const files = this.form.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: response.thumbnail,
          thumbnail_org: response.thumbnail_org,
        });

        this.form.patchValue( {files: files} );

        if( this.form.controls.seq.value !== '' ) this.setBikeAdvertisementSave();

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any, index: any ) {
    if( upload.is_default == '1' ) {
      this.toastrService.error( '대표 이미지는 삭제할 수 없습니다.', '');
    } else {
      if( confirm("선택하신 이미지를 삭제하시겠습니까?") ) {
        this.bikeService.setBikeAdvertisementImageDelete( upload.seq ).then( response => {
          if( response.ResultCode ) {
            let uploadData = this.form.controls.upload.value;
            uploadData.splice( index, 1 );
            this.form.patchValue(uploadData);

            this.toastrService.success( response.ResultMessage, '');
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 대표 이미지로 설정
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  setUploadImageDefault( file ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      this.bikeService.setBikeAdvertisementImageDefault( file ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getBikeAdvertisementDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 대표 이미지로 설정
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  setFileImageDefault( file: any ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      const files = this.form.controls.files.value;
      files.forEach((item: any) => item.is_default = '0');

      file.is_default = '1';
    }
  }
  
  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      status: '',
      sdate: null,
      edate: null,
      searchText: '',
    };

    this.formInit();
  }

  /*******************************************************************************
    설  명 : 이벤트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBikeAdvertisementList() {
    this.bikeService.getBikeAdvertisementList(this.search).then( response => {
      if( response.ResultCode ) {
        this.advertisementList = response.data;
        this.statistic = response.statistic;
      } else {
        this.toastrService.error( response.ResultMessage, '이벤트리스트');

        this.statistic = {
          totalCount: 0,
          searchCount: 0,
          processCount: 0,
          standbyCount: 0,
          completeCount: 0
        }
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 상세 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBikeAdvertisementDetail() {
    this.form.patchValue({
      file: '',
      files: []
    });

    this.bikeService.getBikeAdvertisementDetail( this.form.controls.seq.value ).then( response => {
      if (response.ResultCode) {
        this.form.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.form.get('seq').setValue($event.data.seq);

    this.getBikeAdvertisementDetail();
  }

  /*******************************************************************************
    설  명 : 드래그앤 드랍 처리 - 순서 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDragEnd(event: any) {
    let params: any = [];

    this.gridApi.forEachNode((node: any) => {
      params.push(node.data.seq);
    });

    this.bikeService.setBikeAdvertisementSort(params).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, "순서 변경");

        this.getBikeAdvertisementList();
      } else {
        this.toastrService.error(response.ResultMessage, "순서 변경");
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 폼 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  formInit() {
    this.form.get('seq').setValue('');

    this.buildForm();

    this.getBikeAdvertisementList();
  }

  /*******************************************************************************
    설  명 : 이벤트 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  advertisementAdd() {
    this.formInit();

    $("#advertisement_name").focus();
  }

  /*******************************************************************************
    설  명 : 이벤트 데이터 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikeAdvertisementSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.bikeService.setBikeAdvertisementSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '이벤트 저장');

          if( this.form.controls.seq.value == '' ) this.form.get('seq').setValue(response.data.advertisementSeq);

          this.form.get('files').setValue([]);

          this.getBikeAdvertisementList();
          this.getBikeAdvertisementDetail();

        } else {
          this.toastrService.error( response.ResultMessage, '이벤트 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '이벤트 저장');
    }
  }
  
  /*******************************************************************************
    설  명 : 이벤트 데이터 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikeAdvertisementDelete() {
    if ( confirm('이벤트 정보를 삭제하시겠습니까?') ) {
      this.bikeService.setBikeAdvertisementDelete( this.form.controls.seq.value ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.formInit();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 상세보기 링크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail() {
    this.router.navigate(
    ['/bike/event/info'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = moment().format('YYYY-MM-DD');
    } else {
      this.search.edate = moment().format('YYYY-MM-DD');
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.formInit();
    }
  }
  
}
