<div class="modal-header">
  <h4 class="modal-title fb mb0">입점 출고지시 완료처리</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

  <table class="table table-bordered table-small mt50 mb50">
  <colgroup>
    <col style="width:25%" />
    <col style="width:75%" />
  </colgroup>

  <tbody>
    <tr>
      <th scope="row"><label for="consign_date" class="control-label">배송일</label></th>
      <td>
        <inputEx type="date" name="consign_date" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="delivery_company" class="control-label">택배사</label></th>
      <td>
        <inputEx type="select" name="delivery_company" [formGroup]="form" [formErrors]="formErrors" [data] = "delivery_company_list"></inputEx>
        <!-- <select id="delivery_company" [(ngModel)]="form.value.delivery_company" class="form-control form-control-small">
          <option value=""></option>
          <option *ngFor = "let item of delivery_company_list" value={{item.common_code}}>{{item.common_name}}</option>
        </select> -->
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="delivery_no" class="control-label">송장번호</label></th>
      <td>
        <inputEx type="text" name="delivery_no" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
  </tbody>
  </table>

  </form>


</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small mr5" (click)="submit()">저장하기</button>
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
  </div>
</div>

