  <section class="widget-div">
    <div class="fl widget cp" style="width: 340px;">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">조회시간 <span class="cnt">{{search_time}}</span></p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="mb5">
    <div class="fl form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getStatisticDaySales()">
        <i class="material-icons-outlined">search</i>
      </button> -->

      <input type="text" size="13" readonly="readonly" [value]="search.date" class="form-control form-control-small calendar-input ml5" placeholder="기준일자" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect)="selectDate($event)" />
      <!-- <input type="text" size="15" readonly="readonly" [(ngModel)]="form.value.date" class="form-control form-control-small calendar-input ml5" placeholder="검색일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template> -->
    </div>
    <!-- <div class="fr right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="5819693079d64526a6bf398d5c1c09f9" [btnGroup]="1"></btn-ag-grid-save>
    </div> -->
    <div class="cb"></div>
  </div>

  <div style="height:45%">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="dayList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowClassRules]="rowClassRules"
      [rowHeight]="rowHeight"

      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
      >
    </ag-grid-angular>
  </div>

  <div class="mt5 mb5">
    <div class="fl form-inline">
      <span class="fb pt5">프랜차이즈</span>
    </div>
    <!-- <div class="fr right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi_A" gridId="f972f705064d40cea2dc5f3319de7c28" [btnGroup]="1"></btn-ag-grid-save>
    </div> -->
    <div class="cb"></div>
  </div>

  <div style="height:calc(45% - 45px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="fraDayList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowClassRules]="rowClassRules"
      [rowHeight]="rowHeight"

      (gridReady)="onFraGridReady($event)"
      >
    </ag-grid-angular>
  </div>