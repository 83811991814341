<section id="auth-group">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="20" minSize="20" maxSize="40">
      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">권한그룹</h4>
        </div>
        <div class="fr w50p right">
          <button type="button" title="권한그룹 추가" class="btn btn-primary btn-small-icon ml5" (click)="addAuthGroup({seq:''})">
            <i class="material-icons-outlined">add</i>
          </button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <div class="treeview cp" *ngIf="authGroupList.length > 0">
          <ng-template #nodeTemplate let-node>
            <div class="menu content p10" [ngClass]="{active:selectedNode.seq==node.seq}">
              <span class="mr5" (click)="addAuthGroup(node)">
                <i class="material-icons-outlined f12">edit</i>
              </span>
              <span class="menu-title" (click)="selectedAuthGroup(node)">[{{node.member_level}}] {{node.name}}</span>
            </div>
          </ng-template>
          <ng-template #repeaterTemplate let-node>
            <ul pioneer-tree-repeater [nodes]="node.children">
              <li pioneer-tree-node *ngFor="let node of node.children" [nodeTemplate]="nodeTemplate"
                [repeaterTemplate]="repeaterTemplate" [node]="node">
              </li>
            </ul>
          </ng-template>
          <ul pioneer-tree #pt [configuration]="configuration" [nodes]="authGroupList">
            <li pioneer-tree-node
              *ngFor="let node of authGroupList"
              [nodeTemplate]="nodeTemplate"
              [repeaterTemplate]="repeaterTemplate"
              [node]="node">
            </li>
          </ul>
        </div>
        <div *ngIf="authGroupList.length < 1">
          <span>등록된 권한이 없습니다.</span>
        </div>
      </div>
    </as-split-area>

    <as-split-area size="80" minSize="60" maxSize="80">

      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">권한그룹 설정</h4>
        </div>
        <div class="fr w50p right">
          <button type="button" class="btn btn-default btn-small f12" (click)="selectAll(true)">전체선택</button>
          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="selectAll(false)">전체해제</button>
          <!-- <ng-container *ngIf="authService.currAuth['auth_modify']=='1'">
            <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="submit()">저장하기</button>
          </ng-container> -->
          <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="submit()">저장하기</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="authGroupInfo"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (cellClicked)="onCellClicked($event)"
          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged($event)"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
