/*
  상품 가격 계산
*/
export default function calcPrice(_consumerPrice: any, _isMy: any, _buyPrice = null, _brandSeq = null) {
//   상품관리 상사/협력점/프랜차이즈 계산 공식 변경

// - 자사 제품 할인율
// 1) 상사회원 : 20%  -> 소비자가 - (소비자가*0.2)
// 2) 협력점 : 30% -> 소비자가 - (소비자가 * 0.3) 
// 3) 프랜차이즈 : 35%  - 소비자가 - (소비자가*0.35)

// - 입점 제품 할인율
// 1) 상사회원 : 본사 마진*(1/3)  -> 소비자가 -( (소비자가-구매가) * (1/3))
// 2) 협력점 : 본사 마진*(1/2)  ->  소비자가 -( (소비자가-구매가) * (1/2))
// 3) 프랜차이즈 : 본사 마진*(8/10)  ->  소비자가 -( (소비자가-구매가) * (8/10))
// 홍진 프랜차이즈 마진 7 로 변경
// 맥슬러, 앱코 프랜차이즈 마진 7.5 로 변경

// - 세나
// 1) 상사회원 : 소비자가 * 0.9
// 2) 협력점 : 소비자가 * 0.9
// 3) 프랜차이즈 : 변경안함

  const trimNumber = (num: any) => parseInt(String(num).replace(/,/g, ''))
  const consumerPrice = trimNumber(_consumerPrice || 0)
  const buyPrice = _buyPrice ? trimNumber(_buyPrice || 0) : 0
  const isMy = (_isMy === true || _isMy == 1000) ? true : false

  const price = !isSena(_brandSeq) ? {
    com: isMy ? consumerPrice - consumerPrice * 0.2 : consumerPrice - (consumerPrice - buyPrice) * (1 / 3),
    b2b: isMy ? consumerPrice - consumerPrice * 0.3 : consumerPrice - (consumerPrice - buyPrice) * (1 / 2),
    fra: isMy ? 
          consumerPrice - consumerPrice * 0.35 : 
          (
            isHufs(_brandSeq) ? consumerPrice * 0.8 : 
            (
              consumerPrice - (consumerPrice - buyPrice) * ( 
                ( 
                  !isHjc(_brandSeq) && !isShoei(_brandSeq) ? ( 
                    ( isMaxler(_brandSeq) || isAbko(_brandSeq) ) ? 7.5 : 8 
                  ) : 7 
                ) / 10 
              )
            )
          )
  } : {
    com: consumerPrice * 0.9,
    b2b: consumerPrice * 0.9
  }

  for(const key in price) price[key] = Math.ceil(price[key] / 100) * 100
  return price
}

export function isSena(brandSeq: any) {
  return brandSeq == 2013
}

export function isHjc(brandSeq: any) {
  return brandSeq == 24
}

export function isMaxler(brandSeq: any) {
  return brandSeq == 927 || brandSeq == 3643
}

export function isAbko(brandSeq: any) {
  return brandSeq == 3644
}

export function isHufs(brandSeq: any) {
  return brandSeq == 3659
}

export function isShoei(brandSeq: any) {
  return brandSeq == 105
}