<div class="modal-header">
  <h4 class="modal-title fb mb0">창고 추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <div class="">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">창고 정보</h4>
    </div>
    <div class="fr w50p right">

    </div>
    <div class="cb"></div>
  </div>

  <form [formGroup]="form">

    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <table class="table table-bordered table-small">
    <caption class="none">창고정보</caption>
    <colgroup>
      <col style="width:15%;" />
      <col style="width:35%;" />
      <col style="width:15%;" />
      <col style="width:35%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="warehouse_name">창고명</label></th>
      <td><inputEx type="text" name="warehouse_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="stock_yn">재고반영</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.stock_yn.value=='Y'}" (click)="form.get('stock_yn').setValue('Y')">재고반영</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.stock_yn.value=='N'}" (click)="form.get('stock_yn').setValue('N')">반영안함</button>
        </div>
        <inputEx type="hidden" name="stock_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="wh_zipcode">우편번호</label></th>
      <td>
        <div class="input-group">
          <inputEx name="wh_zipcode" class="input_zipcode" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
          <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
        </div>
      </td>
      <th scope="row"><label for="wh_address">주소</label></th>
      <td><inputEx type="text" name="wh_address" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="wh_address_detail">상세주소</label></th>
      <td colspan="3"><inputEx type="text" name="wh_address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    </tbody>
    </table>

  </form>

</div>

<div class="modal-footer">
  <div class="fl w50p">

  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="setWarehouseSave()">저장하기</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
  </div>
</div>
