/*******************************************************************************
  설  명 : 창고관리
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { EmployeeService } from '@app/service/employee.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { WarehouseDetailComponent } from '@page/basic/warehouse/warehouse-detail/warehouse-detail.component';
import { WarehouseLocationComponent } from '@page/basic/warehouse/location/location.component';
import { WarehouseChargeAddComponent } from '@page/basic/warehouse/warehouse-charge-add/warehouse-charge-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
  };

  public form: FormGroup;
  public formErrors = {};

  // 그리드 관련 선언
  warehouseList: any = [];
  warehouseInfo: any = {};
  locationList: any = [];
  chargeList: any = [];
  originEmployeeList: any = [];
  employeeList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  rowSelection: any;

  gridApiCharge: any;
  gridColumnApiCharge: any;
  columnDefsCharge: any;
  rowSelectionCharge: any;

  gridApiLocation: any;
  gridColumnApiLocation: any;
  columnDefsLocation: any;
  rowSelectionLocation: any;

  defaultColDef: any;
  domLayout: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      warehouse_name: ['', [Validators.required] ],
      stock_yn: ['', [Validators.required] ],
      wh_zipcode: ['', [] ],
      wh_address: ['', [] ],
      wh_address_detail: ['', [] ],
      main_person_mem_no: [0, [] ],
      main_person_info: ['', [] ],
      sub_person_mem_no: [0, [] ],
      sub_person_info: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private basicService: BasicService,
    private employeeService: EmployeeService,
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '창고명', field: 'warehouse_name', width: 200, cellClass: 'cp center' },
      { headerName: '담당자', field: 'staff_name', width: 100, cellClass: 'cp center' },
      { headerName: '재고반영', field: 'stock_yn', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.stock_yn == 'Y' ) {
            return '<span class="badge badge-success f12">반영</span>';
          } else {
            return '<span class="badge badge-secondary f12">미반영</span>';
          }
        }
      },
    ];

    // 창고 위치 ag grid 컬럼 선언
    this.columnDefsLocation = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '위치코드', field: 'location_cd', width: 200, cellClass: 'cp center' },
      { headerName: '위치명', field: 'location_name', width: 200, cellClass: 'cp' },
      { headerName: '위치1', field: 'location1', width: 80, cellClass: 'cp center' },
      { headerName: '위치2', field: 'location2', width: 80, cellClass: 'cp center' },
      { headerName: '위치3', field: 'location3', width: 80, cellClass: 'cp center' },
      { headerName: '보관상품수', field: '', width: 100, cellClass: 'cp right' },
      { headerName: '보관수량', field: '', width: 100, cellClass: 'cp right' },
    ];

    // 담당자 ag grid 컬럼 선언
    this.columnDefsCharge = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '회원번호', field: 'mem_no', width: 100, cellClass: 'cp center' },
      { headerName: '구분', field: 'isDefault', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.isDefault == '1' ) {
            return '<span class="badge badge-success f12">주담당자</span>';
          } else {
            return '<span class="badge badge-secondary f12">부담당자</span>';
          }
        }
      },
      { headerName: '부서명', field: 'dept_name', width: 120, cellClass: 'cp' },
      { headerName: '담당자명', field: 'name', width: 120, cellClass: 'cp' },
      { headerName: '직위명', field: 'position_name', width: 120, cellClass: 'cp' },
      { headerName: '시작일', field: 'start_date', width: 100, cellClass: 'cp center' },
      { headerName: '종료일', field: 'end_date', width: 100, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';
    this.rowSelectionLocation = 'multiple';
    this.rowSelectionCharge = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getEmployeeList();
    this.getWarehouseList();
  }

  /*******************************************************************************
    설  명 : 직원 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployeeList() {
    this.employeeService.getEmployeeList().then( response => {
      if( response.ResultCode ) {
        this.originEmployeeList = response.data;

        this.employeeList.push({
          title: '선택',
          value: 0
        });

        this.originEmployeeList.forEach(item => {
          this.employeeList.push({
            title: item.name,
            value: item.mem_no
          });
        });

      } else {
        this.toastrService.error( response.ResultMessage, 'getEmployeeList');
      }
    });
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseList() {
    this.basicService.getWarehouseList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.warehouseList = response.data;

        if( this.warehouseList.length > 0  && !this.form.controls.seq.value ) {
          this.form.patchValue({
            seq: this.warehouseList[0].seq
          });

          this.getWarehouseDetail();

          let index = 0;
          setTimeout(() => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              // select the node
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }, 0);
        } else {
          const selectedSeq = this.form.controls.seq.value;
          setTimeout(() => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              // select the node
              if( node.data.seq == selectedSeq ) {
                node.setSelected(true);
              }
            });
          }, 0);
        }

      } else {
        this.toastrService.error( response.ResultMessage, 'getWarehouseList');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 창고 상세정보 및 로케이션 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWarehouseDetail() {
    this.basicService.getWarehouseDetail( this.form.controls.seq.value ).then( response => {
      if( response.ResultCode ) {

        this.warehouseInfo = response.data;
        this.locationList = response.location;
        this.chargeList = response.charge;

        this.form.patchValue( this.warehouseInfo );
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 창고 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setWarehouseSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getWarehouseList();
          this.getWarehouseDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 창고 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseDelete() {
    if ( confirm('삭제하시겠습니까?') ) {
      this.basicService.setWarehouseDelete( this.form.controls.seq.value ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.form.patchValue({
            seq: ''
          });

          this.getWarehouseList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 창고 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseAdd() {
    const modalRef = this.modalService.open(WarehouseDetailComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
        this.getWarehouseList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 위치 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseLocationAdd( locationSeq: number ) {
    const modalRef = this.modalService.open(WarehouseLocationComponent, options);

    modalRef.componentInstance.whSeq = this.form.controls.seq.value;
    modalRef.componentInstance.seq = locationSeq;

    modalRef.result.then((result) => {
      if( result ) {
        this.getWarehouseDetail();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 담당자 추가
    입력값 : chargeSeq = 담당자코드
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseChargeAdd( chargeSeq: number ) {
    const modalRef = this.modalService.open(WarehouseChargeAddComponent, options);

    modalRef.componentInstance.whSeq = this.form.controls.seq.value;
    modalRef.componentInstance.seq = chargeSeq;

    modalRef.result.then((result) => {
      if ( result ) {
        this.getWarehouseDetail();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 담당자 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseChargeDelete() {
    const nodes = this.gridApiCharge.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 담당자를 선택하세요', '' );
      return false;
    }

    if ( confirm('담당자를 삭제하시겠습니까?') ) {
      this.basicService.setWarehouseChargeDelete( nodes ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.getWarehouseDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 위치 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseLocationDelete() {
    const nodes = this.gridApiLocation.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 위치를 선택하세요', '' );
      return false;
    }

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data );
    }

    if ( confirm('삭제하시겠습니까?') ) {
      this.basicService.setWarehouseLocationDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getWarehouseDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked(event: any) {
    this.form.patchValue({
      seq: event.data.seq
    });

    this.getWarehouseDetail();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyLocation(params) {
    this.gridApiLocation = params.api;
    this.gridColumnApiLocation = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 위치관리 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClickedLocation( event: any ) {
    this.setWarehouseLocationAdd( event.data.seq );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCharge(params) {
    this.gridApiCharge = params.api;
    this.gridColumnApiCharge = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 위치관리 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClickedCharge( event: any ) {
    this.setWarehouseChargeAdd( event.data.seq );
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      wh_zipcode: data.zip,
      wh_address: data.addr
    });

    $("#wh_address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: ''
    };

    this.getWarehouseList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getWarehouseList();
    }
  }

}
