 <form [formGroup]="form">

  <ng-container *ngIf="form.controls.seq.value != '0'">
    <section class="mt15 mb15">
      <div class="mb5">
        <div class="fl fb pt5">상품 메모</div>
        <div class="fr">
        </div>
        <div class="cb"></div>
      </div>

      <table class="table table-bordered table-small1 mb0">
      <caption class="none">상품 메모</caption>
      <colgroup>
        <col style="width:8%;" />
        <col style="width:92%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="memo">메모</label></th>
        <td>
          <inputEx type="text" name="memo" [formGroup]="form" [formErrors]="formErrors" [addOn]="memoAddFunc" addOnText="메모추가" (keypress)="setKeyPressMemo($event)"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>

      <div style="height:200px" class="mt5">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsMemo"
          [defaultColDef]="defaultColDef"
          [rowData]="memoList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReadyMemo($event)"
          (cellClicked)="onCellClicked($event)"
          >
        </ag-grid-angular>
      </div>
    </section>
  </ng-container>

  <section class="mt15 mb15">
    <div class="mb5">
      <div class="fl fb pt5">
        <i class="material-icons cred f11 lh20">star</i> 색상 및 사이즈
        <span class="ml5 cred">(상품마진관리에서 새로운 가격을 일괄 등록이 가능하며, 변경한 가격이 같이 연동됩니다.)</span>
      </div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <btn-ag-grid-save class="ml15" [gridColumnsApi]="gridColumnApiProperty" gridId="6b94a285792b4c5893f2ad19ecfaa14a" [btnGroup]="1"></btn-ag-grid-save>
        <button type="button" class="btn btn-primary btn-small ml15" (click)="setProductSave()">저장하기</button>
        <button type="button" class="btn btn-info btn-small ml15" (click)="addBarcode()">바코드추가</button>
        <button type="button" class="btn btn-danger btn-small ml15" (click)="setPropertyDelete()">속성삭제</button>
        <button type="button" class="btn btn-warning btn-small ml5" (click)="setPropertyChange()">속성변경</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="propertyAdd()">속성추가</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height:300px">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefsProperty"
        [defaultColDef]="defaultColDef"
        [rowData]="form.controls.property.value"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [stopEditingWhenGridLosesFocus]="true"
        [singleClickEdit]="true"

        (gridReady)="onGridReadyProperty($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        >
      </ag-grid-angular>
    </div>
  </section>

  <section>
    <div class="mb5">
      <div class="fl fb pt5">상품 기본정보</div>
      <div class="fl">
        <button type="button" class="btn cbpurple btn-small ml10" (click)="setProductCopy()">상품복사</button>
      </div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setProductSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">상품 기본정보</caption>
    <colgroup>
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="seq">상품순번</label></th>
      <td>
        <inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="자동부여"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="customer_seq">매입거래처</label></th>
      <td>
        <inputEx type="select2" name="customer_seq" [formGroup]="form" [formErrors]="formErrors" [data]="customerList" [small]="true" [options]="{multiple: false}" [onChanged]="customerChangedFunc"></inputEx>
      </td>
      <th scope="row"><label for="provider_seq">공급업체</label></th>
      <td>
        <inputEx type="select2" name="provider_seq" [formGroup]="form" [formErrors]="formErrors" [data]="providerList" [small]="true" [options]="{multiple: false}" [onChanged]="providerChangedFunc"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="is_set_product">세트상품여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_set_product.value=='1'}" (click)="form.get('is_set_product').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_set_product.value=='0'}" (click)="form.get('is_set_product').setValue('0')">아니오</button>
        </div>
        <inputEx type="hidden" name="is_set_product" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="product_name">상품명(메인)</label></th>
      <td colspan="3">
        <inputEx type="text" name="product_name" [formGroup]="form" [formErrors]="formErrors" placeholder="핵심문구만 간단히, 법률에 위반되는 금칙어 입력제한"></inputEx>
      </td>
      <th scope="row"><label for="comment">상품홍보문구</label></th>
      <td colspan="3">
        <inputEx type="text" name="comment" [formGroup]="form" [formErrors]="formErrors" placeholder="상품홍보문구는 상품명 위에서 강조"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="product_overview">상품명(서브)</label></th>
      <td colspan="3">
        <inputEx type="text" name="product_overview" [formGroup]="form" [formErrors]="formErrors" placeholder="상품요약정보는 상품명 아래에서 강조"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="min_qty">최소구매수량</label></th>
      <td>
        <inputEx type="numeric" name="min_qty" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="max_qty">최대구매수량</label></th>
      <td>
        <inputEx type="numeric" name="max_qty" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>      
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="brand_name">브랜드/제조사</label></th>
      <td>
        <inputEx type="text" name="brand_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="검색버튼을 사용하세요." [addOn]="addBrandFunc" addOnText="검색"></inputEx>
        <inputEx type="hidden" name="brand_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="category_seq">주 카테고리</label></th>
      <td>
        <inputEx type="select2" name="category_seq" [formGroup]="form" [formErrors]="formErrors" [data]="categoryList" [small]="true" [options]="{multiple: false, closeOnSelect: true}" [onChanged]="mainCategoryChangedFunc"></inputEx>
      </td>
      <th scope="row"><label for="category_list">추가 카테고리</label></th>
      <td colspan="3">
        <inputEx type="select2" name="category_list" [formGroup]="form" [formErrors]="formErrors" [data]="addCategoryList" [small]="true" [options]="{multiple: true}" [onChanged]="subCategoryChangedFunc"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="use_yn">사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='1'}" (click)="form.get('use_yn').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='0'}" (click)="form.get('use_yn').setValue('0')">아니오</button>
        </div>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="is_soldout">품절여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_soldout.value=='1'}" (click)="form.get('is_soldout').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_soldout.value=='0'}" (click)="form.get('is_soldout').setValue('0')">아니오</button>
        </div>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="approval_yn">승인여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.approval_yn.value=='1'}" (click)="form.get('approval_yn').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.approval_yn.value=='0'}" (click)="form.get('approval_yn').setValue('0')">아니오</button>
        </div>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="is_display">쇼핑몰 표시 여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_display.value=='1'}" (click)="form.get('is_display').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_display.value=='0'}" (click)="form.get('is_display').setValue('0')">아니오</button>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="is_stock">재고 반영여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_stock.value=='1'}" (click)="form.get('is_stock').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_stock.value=='0'}" (click)="form.get('is_stock').setValue('0')">아니오</button>
        </div>
      </td>
      <th scope="row"><label for="is_review">구매후기 사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_review.value=='1'}" (click)="form.get('is_review').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_review.value=='0'}" (click)="form.get('is_review').setValue('0')">아니오</button>
        </div>
      </td>
      <th scope="row"><label for="is_delivery">공급사 직접발송여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_delivery.value=='1'}" (click)="form.get('is_delivery').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_delivery.value=='0'}" (click)="form.get('is_delivery').setValue('0')">아니오</button>
        </div>
      </td>
      <th scope="row"><label for="is_consignment">위탁상품 여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_consignment.value=='1'}" (click)="form.get('is_consignment').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_consignment.value=='0'}" (click)="form.get('is_consignment').setValue('0')">아니오</button>
        </div>
        <inputEx type="hidden" name="is_consignment" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="is_outside_view">외부노출 허용</label></th>
      <td colspan="3">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_outside_view.value=='1'}" (click)="form.get('is_outside_view').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_outside_view.value=='0'}" (click)="form.get('is_outside_view').setValue('0')">아니오</button>
        </div>
        <span class="ml5">'아니오'일 경우 바이크마트 외 허용불가(네이버 지식쇼핑 등)</span>
        <inputEx type="hidden" name="is_outside_view" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="each_delivery_price">개별 배송비</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.each_delivery_price.value=='1'}" (click)="form.get('each_delivery_price').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.each_delivery_price.value=='0'}" (click)="form.get('each_delivery_price').setValue('0')">아니오</button>
        </div>
        <inputEx type="hidden" name="each_delivery_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="writer">등록자</label></th>
      <td>
        <inputEx type="text" name="writer" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="is_mach">빠른배송 가능</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_mach.value=='1'}" (click)="form.get('is_mach').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_mach.value=='0'}" (click)="form.get('is_mach').setValue('0')">아니오</button>
        </div>
      </td>    
      <th scope="row"><label for="is_voucher">상품권 여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_voucher.value=='1'}" (click)="form.get('is_voucher').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_voucher.value=='0'}" (click)="form.get('is_voucher').setValue('0')">아니오</button>
        </div>
      </td>    
      <th scope="row"><label for="is_freeDelivery">무료배송 여부</label></th>
      <td colspan="3">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_freeDelivery.value=='1'}" (click)="form.get('is_freeDelivery').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_freeDelivery.value=='0'}" (click)="form.get('is_freeDelivery').setValue('0')">아니오</button>
        </div>
      </td>    
    </tr>    
    </tbody>
    </table>
  </section>

  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">
        상품 가격정보
        <span class="ml5 cred">(부모상품 가격정보로 변경 시 모든 색상/사이즈별 가격이 변경됩니다.)</span>
      </div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setProductSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">상품 가격정보</caption>
    <colgroup>
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="consumer_price">소비자 가격</label></th>
      <td [ngClass]="{'warning':buyPriceCompareCheck('consumer_price')}">
        <inputEx type="numeric2" name="consumer_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="buy_price">구매가격</label></th>
      <td>
        <inputEx type="numeric2" name="buy_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
      </td>
      <th scope="row"><label for="display_price">화면표시가격</label></th>
      <td [ngClass]="{'warning':buyPriceCompareCheck('display_price')}">
        <inputEx type="numeric2" name="display_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
      </td>
      <th scope="row"><label for="sale_price">판매가격</label></th>
      <td [ngClass]="{'warning':buyPriceCompareCheck('sale_price')}">
        <inputEx type="numeric2" name="sale_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="com_price">상사가격</label></th>
      <td [ngClass]="{'warning':buyPriceCompareCheck('com_price')}">
        <inputEx type="numeric2" name="com_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
      </td>
      <th scope="row"><label for="b2b_price"><i class="material-icons cred f11 lh20">star</i> 협력점가격</label></th>
      <td [ngClass]="{'warning':buyPriceCompareCheck('b2b_price')}">
        <inputEx type="numeric2" name="b2b_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="fra_price">프렌차이즈가격</label></th>
      <td [ngClass]="{'warning':buyPriceCompareCheck('fra_price')}">
        <inputEx type="numeric2" name="fra_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
      </td>
      <th scope="row"><label for="seller_price">역입점 공급가격</label></th>
      <td>
        <inputEx type="numeric2" name="seller_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <!-- <th scope="row"><label for="supersale_price">할인(슈퍼세일)가격</label></th>
      <td>
        <inputEx type="numeric2" name="supersale_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td> -->
    </tr>
    <tr>
      <th scope="row"><label for="mileage_price">마일리지 판매가격</label></th>
      <td [ngClass]="{'warning':buyPriceCompareCheck('mileage_price')}">
        <inputEx type="numeric2" name="mileage_price" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
      </td>
      <th scope="row"><label for="mileage">마일리지(포인트)</label></th>
      <td>
        <inputEx type="numeric2" name="mileage" [formGroup]="form" [formErrors]="formErrors" placeholder="일원단위 절사처리" (keyup)="onChangePrice($event)"></inputEx>
      </td>
      <th></th>
      <td></td>
      <th></th>
      <td></td>
    </tr>
    </tbody>
    </table>
  </section>
  
  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">KC 인증정보</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setProductSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">KC 인증정보</caption>
    <colgroup>
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="kc_number">인증번호</label></th>
      <td>
        <inputEx type="text" name="kc_number" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th></th>
      <td></td>
      <th></th>
      <td></td>
      <th></th>
      <td></td>
    </tr>
    </tbody>
    </table>
  </section>

  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">상품 이미지</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setProductSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small">
    <caption class="hidden">상품 이미지정보</caption>

    <colgroup>
      <col style="width:8%" />
      <col style="width:92%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row" class="active" rowspan="2">
        <i class="material-icons cred f11 lh20">star</i> <label for="file">이미지</label>
      </th>
      <td>
        <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
        <div class="progress mt5 mb5">
          <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
        </div>
        <div class="mt5">
       ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 50MB 제한,  <b style="background-color: red; color: #fff;">가로 * 세로 : 1000 * 1000 px (강력 권장)</b>
        </div>
        <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
          <ul class="file-ul">
            <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
              <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                <div *ngIf="checkImage(getExt(file.origin_filename))" class="upload-image" (click)="insertUploadImage(file)">
                  <img [src]="file.url" [alt]="file.origin_filename" />
                </div>
                <div *ngIf="!checkImage(getExt(file.origin_filename))" class="upload-image1">{{getExt(file.origin_filename)}}</div>
                <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin_filename}}">{{file.origin_filename}}</a></div>
                <div class="upload-size">
                  ({{getComma(file.filesize)}} KB)
                  <a class="cp ml5" (click)="setDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                </div>
              </div>
            </li>
            <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
              <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
                <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertFilesImage(file)">
                  <img [src]="file.url" [alt]="file.origin" />
                </div>
                <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                <div class="upload-size">
                  ({{getComma(file.size)}} KB)
                  <a class="cp ml5" (click)="deleteFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                </div>
              </div>
            </li>
            <li class="cb"></li>
          </ul>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <span class="cred f16">※ 빨간테두리가 있는 이미지가 대표이미지이며, 썸네일 이미지로 표시됩니다.<br/>※ 이미지 클릭 시 대표이미지로 설정할 수 있습니다.</span>
      </td>
    </tr>
    </tbody>
    </table>
  </section>

  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">상품 상세정보</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setProductSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">상품 상세정보</caption>
    <colgroup>
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
      <col style="width:8%;" />
      <col style="width:17%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="origin_country">원산지</label></th>
      <td>
        <inputEx type="text" name="origin_country" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="search_keyword">검색어</label></th>
      <td colspan="5">
        <inputEx type="text" name="search_keyword" [formGroup]="form" [formErrors]="formErrors" placeholder="영문 200자 한글100자"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="contents">제품소개</label></th>
      <td colspan="7">
        <inputEx type="summernote" name="contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"></th>
      <td colspan="7"><span class="cred f16">※ 에디터에 이미지를 첨부하려면 위에서 이미지 업로드 한 후에 첨부하려는 이미지를 드래그 해서 에디터 원하는곳에 놓아주세요.</span></td>
    </tr>
    <tr>
      <th scope="row"><label for="summary">제품소개 요약</label></th>
      <td colspan="7">
        <inputEx type="summernote" name="summary" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig2"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </section>

  <section class="mt15" *ngIf="form.controls.seq.value != '0'">
    <div class="mb5">
      <div class="fl fb pt5">사은품 구성</div>
      <div class="fr form-inline">
        <input type="text" [(ngModel)]="giftSdate" [ngModelOptions]="{standalone: true}" size="12" style="width:110px;" class="form-control form-control-small calendar-input ml5" readonly="readonly" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
        <ng-template #footerTemplate1>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
        </ng-template>
        <button type="button" class="btn btn-success btn-small f12 ml5" (click)="setGiftDate(true)">시작일자 일괄적용</button>
        
        <input type="text" [(ngModel)]="giftEdate" [ngModelOptions]="{standalone: true}" size="12" style="width:110px;" class="form-control form-control-small calendar-input ml5" readonly="readonly" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
        <ng-template #footerTemplate2>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
        </ng-template>
        <button type="button" class="btn btn-success btn-small f12 ml5" (click)="setGiftDate(false)">종료일자 일괄적용</button>

        <button type="button" class="btn btn-default btn-small ml10" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setProductGiftInfoSave()">사은품정보 저장</button>
        <button type="button" class="btn btn-danger btn-small ml5" (click)="setProductGiftDelete()">상품삭제</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="productGiftAdd()">상품추가</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height:200px">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefsGift"
        [defaultColDef]="defaultColDef"
        [rowData]="giftProductList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [stopEditingWhenGridLosesFocus]="true"
        [suppressRowClickSelection]="true"

        (gridReady)="onGridReadyGift($event)"
        >
      </ag-grid-angular>
    </div>
  </section>
<!-- 
  <section class="mt15" *ngIf="form.controls.seq.value != '0'">
    <div class="mb5">
      <div class="fl fb pt5">연관상품 구성</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button  type="button" class="btn btn-primary btn-small ml5" (click)="setProductRelationInfoSave()">연관상품정보 저장</button>
        <button  type="button" class="btn btn-danger btn-small ml5" (click)="setProductRelationDelete()">상품삭제</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="productRelationAdd()">상품추가</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height:300px">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefsRelation"
        [defaultColDef]="defaultColDef"
        [rowData]="relationProductList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [stopEditingWhenGridLosesFocus]="true"
        [suppressRowClickSelection]="true"

        (gridReady)="onGridReadyRelation($event)"
        >
      </ag-grid-angular>
    </div>
  </section> -->

  <section class="mt15 pb50" *ngIf="form.controls.seq.value != '0' && form.controls.is_set_product.value == '1'">
    <div class="mb5">
      <div class="fl fb pt5">세트상품 구성</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setProductSetInfoSave()">세트상품정보 저장</button>
        <button type="button" class="btn btn-danger btn-small ml5" (click)="setProductSetDelete()">상품삭제</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="productSetAdd()">상품추가</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height:300px">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefsSet"
        [defaultColDef]="defaultColDef"
        [rowData]="setProductList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [stopEditingWhenGridLosesFocus]="true"
        [suppressRowClickSelection]="true"

        (gridReady)="onGridReadySet($event)"
        >
      </ag-grid-angular>
    </div>
  </section>

</form>
