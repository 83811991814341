<section id="member-order">
  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberOrderList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="주문번호, 주문자, 수신자" />

      <input type="text" readonly="readonly" [(ngModel)]="search.sdate" size="13" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" readonly="readonly" [(ngModel)]="search.edate" size="13" class="form-control form-control-small calendar-input m15" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>

      <div class="btn-group ml5">
        <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value);getMemberOrderList();">{{item.title}}</button>
      </div>
  
    </div>
    <div class="fr w30p right">
      <ng-container *ngIf="dormant===false">
        <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="9e16e79a0d8c49e1b2faf046f1f5d95a" [btnGroup]="1"></btn-ag-grid-save>
      </ng-container>
    </div>
    <div class="cb"></div>
  </div>

  <div class="" style="height:calc(100% - 37px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="memberOrderList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (rowClicked)="onRowClicked($event)"
      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
      >
    </ag-grid-angular>
  </div>
</section>
