  <div class="modal-header">
    <h4 class="modal-title fb mb0">가격업로드</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <form [formGroup]="form">

    <table class="table table-bordered table-small1 mb50">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="col1" class="control-label">적용시작일</label></th>
      <td><inputEx type="date" name="date" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="col1" class="control-label">업로드파일</label></th>
      <td><inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect></inputEx></td>
    </tr>
    </tbody>
    </table>

    </form>


  </div>

  <div class="modal-footer">
    <div class="fr w50p right">
      <button type="button" class="btn btn-default btn-small mr5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
      <button type="button" class="btn btn-primary btn-small" (click)="priceChangeSave()">가격업로드</button>
    </div>
  </div>

