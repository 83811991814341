<section class="list-area">
  <div class="tree-view-header">
    <div class="fl" *ngIf="nodeData.length > 0">
      <span *ngIf="useSort">
        <button class="btn btn-default btn-small f12" type="button" (click)="setSort()">순서변경</button>
      </span>
    </div>
    <div class="fr">
      <a title="항목 펼침/닫힘" (click)="expandTree() ? ptComponent.pioneerTree.expandAllNodes() : ptComponent.pioneerTree.collapseAllNodes()">
        <i class="add-btn material-icons cp mr5">folder_open</i>
      </a>
      <span *ngIf="useAddNode">
        <a title="항목 추가" (click)="addNode();"><i class="add-btn material-icons cp">add_circle</i></a>
      </span>
    </div>

    <div class="cb"></div>
  </div>

  <div class="treeview">
    <ng-template #nodeTemplate let-node>
      <div class="menu-tree" [ngClass]="{active:selectedNode.seq==node.seq}" (click)="selectNode(node)">

        <ng-container *ngIf="node.key && node.key.length > 3">
          <ng-container *ngFor="let item of getTreeMiddleLine(node); index as i">
            <span class="tree-line" [ngClass]="{'tree-line-link': checkParentLastNode(node, i)==false}"></span>
          </ng-container>
        </ng-container>

        <span *ngIf="!checkLastNode(node)" class="tree-line tree-line-middle"></span>
        <span *ngIf="checkLastNode(node)" class="tree-line tree-line-last"></span>

        <span *ngIf="checkChildren(node)" pioneer-tree-collapse [node]="node" class="tree-icon">
          <i class="lh30 material-icons-outlined" [innerText]="this.node.pioneerTreeNode.isCollapsed() ? 'add_box' : 'indeterminate_check_box'"></i>
        </span>

        <!-- 사원 사진 표시 -->
        <span class="tree-page-name">
          <span *ngIf="picture===true && node.group==2" class="tree-picture">
            <img *ngIf="node.picture !== '' && node.picture !== null" [src]="node.picture" onerror="this.src='/assets/images/no_image.png'" />
            <img *ngIf="node.picture === '' || node.picture === null" src="/assets/images/no_image.png" />
          </span>
          {{node.name}}
        </span>

        <span class="rightButton" *ngIf="isSort" pioneer-tree-handle [node]="node">
          <i class="material-icons f16">drag_handle</i>
        </span>

        <span *ngIf="badge===true" class="treeview-badge">
          <span [innerHTML]="node.badge | safe: 'html'"></span>
        </span>
      </div>
    </ng-template>

    <ng-template #repeaterTemplate let-node>
      <ul pioneer-tree-repeater [nodes]="node.children">
        <li pioneer-tree-node *ngFor="let node of node.children" [nodeTemplate]="nodeTemplate" [repeaterTemplate]="repeaterTemplate" [node]="node" (nodeDropped)="onNodeDropped($event)">
        </li>
      </ul>
    </ng-template>
    <ul pioneer-tree #pt [configuration]="configuration" [nodes]="nodes">
      <li *ngFor="let node of nodes" pioneer-tree-node
        (nodeDropped)="onNodeDropped($event)"
        [nodeTemplate]="nodeTemplate"
        [repeaterTemplate]="repeaterTemplate"
        [node]="node">
      </li>
    </ul>
  </div>
</section>
