<section id="member-benefit">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">최종업데이트</p>
        <p class="subtxt">{{updateDate}}</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{totalCount | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{searchCount | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="mb5">
    <div class="fl w80p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberBenefitList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getMemberBenefitList()">
        <option value="25">25줄</option>
        <option value="100">100줄</option>
        <option value="500">500줄</option>
        <option value="1000">1000줄</option>
        <option value="5000">5000줄</option>
      </select>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.grade==''}" (click)="search.grade='';getMemberBenefitList()">전체</button>
        <button *ngFor="let item of gradeList" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.grade==item.common_code}" (click)="search.grade=item.common_code;getMemberBenefitList()">{{item.common_name}}</button>
      </div>

      <select class="form-control form-control-small ml5" placeholder="시/도검색" [(ngModel)]="search.state" (change)="getMemberBenefitList()">
        <option value="">전체</option>
        <option *ngFor="let item of stateList" [value]="item.common_code">{{item.common_name}}</option>
      </select>

      <select [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
        <option value="c.id">ID 검색</option>
        <option value="g.shop_name">회사명 검색</option>
        <option value="c.name">이름 검색</option>
      </select>

      <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="검색어 입력" />

    </div>
    <div class="fr w20p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="7d2fd9ec9d774c99a080dfb9ff82c4f9" [btnGroup]="1"></btn-ag-grid-save>

      <button type="button" title="수동 업데이트" class="btn btn-primary btn-small ml5" (click)="setMemberPurchaseUpdate()">수동 업데이트</button>
    </div>
    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 135px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="memberBenefitList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [pagination]="false"
      [paginationPageSize]="paginationPageSize"

      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
    <section class="pagination mt10" *ngIf="searchCount">
      <ngb-pagination
        [(page)]="search.pageNo"
        [pageSize]="search.pageRow"
        [collectionSize]="searchCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
  </div>
</section>
