import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { ProductService } from '@app/service/product.service';

import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-product-find-property',
  templateUrl: './product-find-property.component.html',
  styleUrls: ['./product-find-property.component.scss']
})
export class ProductFindPropertyComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() customer_seq: any;
  @Input() mem_no: any;
  @Input() rowData: any;

  public search: any = {
    searchText: '',
    category_code: '',
    brand_seq: ''
  };

  public categoryList: any = [];
  public brandList: any = [];
  public productList: any = [];
  public selectedList: any = [];

  public baseURL: any = config.baseUrl;

  leftGridApi: any;
  leftGridColumnApi: any;
  leftColumnDefs: any;

  rightGridApi: any;
  rightGridColumnApi: any;
  rightColumnDefs: any;

  defaultColDef: any;

  leftGridNoRowsTemplate: string;
  rightGridNoRowsTemplate: string;

  // 그리드 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private productService: ProductService,
  ) {
    this.leftColumnDefs = [
      { headerName: '상품번호', field: 'property_seq', cellClass: 'center ag-cell80h-br', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const productSeq = params.data.seq;
          const propertySeq = params.data.property_seq;

          return productSeq + '<br />' + propertySeq;
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', cellClass: 'center ag-cell80h', width: 75, cellRenderer: 'agGridImageComponent' },
      { headerName: '상품정보 ( 브랜드 / 상품명 )', field: 'product_info', cellClass: 'left ag-cell80h-br', width: 300, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const brandName = ( params.data.brand_name ) ? params.data.brand_name : '';
          const productName = ( params.data.product_name ) ? '<span class="fb cgreen">' + params.data.product_name + '</span>' : '';

          return brandName + '<br />' + productName;
        }
      },
      { headerName: '색상/사이즈', field: 'color_size', cellClass: 'center ag-cell80h-br', width: 120, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          // const colorName = ( params.data.color_name ) ? params.data.color_name : '-';
          const displayColorName = ( params.data.display_color_name ) ? params.data.display_color_name : '-';
          // const sizeName = ( params.data.size_name ) ? params.data.size_name : '-';
          const displaySizeName = ( params.data.display_size_name ) ? params.data.display_size_name : '-';

          return '<span class="fb">' + displayColorName + '<br/>' + displaySizeName + '</span>';
        }
      },
      { headerName: '품절여부', field: 'status', width: 105, cellClass: 'center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status == '0' ) {
            return '<span class="badge badge-success f14">판매중</span>';
          } else if ( params.data.status == '1' ) {
            return '<span class="badge badge-warning f14">일시품절</span>';
          } else if ( params.data.status == '2' ) {
            return '<span class="badge badge-danger f14">영구품절</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '선택', field: '', cellClass: 'center', width: 62, rowDrag: true, suppressMenu: true,
        rowDragText: function(params, dragItemCount) {
          if (dragItemCount > 1) {
              return dragItemCount + ' 상품';
          }
          return params.rowNode.data.product_name;
        }
      }
    ];

    this.rightColumnDefs = [
      { headerName: '상품번호', field: 'property_seq', cellClass: 'center ag-cell80h-br', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const productSeq = params.data.seq;
          const propertySeq = params.data.property_seq;

          return productSeq + '<br />' + propertySeq;
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', cellClass: 'center ag-cell80h', width: 75, cellRenderer: 'agGridImageComponent' },
      { headerName: '상품정보 ( 브랜드 / 상품명 )', field: 'product_info', cellClass: 'left ag-cell80h-br', width: 300, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const brandName = ( params.data.brand_name ) ? params.data.brand_name : '';
          const productName = ( params.data.product_name ) ? '<span class="fb cgreen">' + params.data.product_name + '</span>' : '';

          return brandName + '<br />' + productName;
        }
      },
      { headerName: '색상/사이즈', field: 'color_size', cellClass: 'center ag-cell80h-br', width: 120, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          // const colorName = ( params.data.color_name ) ? params.data.color_name : '-';
          const displayColorName = ( params.data.display_color_name ) ? params.data.display_color_name : '-';
          // const sizeName = ( params.data.size_name ) ? params.data.size_name : '-';
          const displaySizeName = ( params.data.display_size_name ) ? params.data.display_size_name : '-';

          return '<span class="fb">' + displayColorName + '<br/>' + displaySizeName + '</span>';
        }
      },
      { headerName: '품절여부', field: 'status', width: 105, cellClass: 'center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status == '0' ) {
            return '<span class="badge badge-success f14">판매중</span>';
          } else if ( params.data.status == '1' ) {
            return '<span class="badge badge-warning f14">일시품절</span>';
          } else if ( params.data.status == '2' ) {
            return '<span class="badge badge-danger f14">영구품절</span>';
          } else {
            return '';
          }
        }
      },
      {
        field: '삭제',
        width: 62,
        cellClass: 'ag-cell80h mt5',
        cellRenderer: function(params) {
          const button = document.createElement('i');

          button.addEventListener('click', function() {
              params.api.applyTransaction({ remove: [params.node.data] });
          });

          button.classList.add('material-icons');
          button.style.cursor = 'pointer';
          button.textContent = 'delete_forever';

          return button;
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    // 메시지 표시 선언
    this.leftGridNoRowsTemplate = '카테고리 또는 브랜드 선택하거나 검색어를 입력하시기 바랍니다.';
    this.rightGridNoRowsTemplate = '추가할 상품을 왼쪽에서 [선택]영역을 클릭해 드래그하거나 더블클릭 하시기 바랍니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCategoryList();
    this.getBrandList();

    if( this.customer_seq ) {
      this.toastrService.show(`설정된 거래처의 상품만 검색합니다.`);
    }
    
    // 오픈마켓 상품찾기
    if( this.rowData !== undefined ) {
      // this.search.searchText = this.rowData.model;
      this.search.searchText = this.rowData.p_code;

      this.getProductListForProperty();
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {

      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
        this.activeModal.close();
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {

      this.brandList = [];
      if( response.ResultCode ) {
        // this.brandList = response.data;

        this.brandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.brandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.brandList = [];
        this.activeModal.close();
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductListForProperty() {

    if( typeof this.search.brand_seq == 'object' ) {
      if( this.search.brand_seq.id != '0' ) {
        this.search.brand_seq = this.search.brand_seq.id;
      } else {
        this.search.brand_seq = '';
      }
    }

    this.productService.getProductListForProperty({
      ...this.search, 
      customer_seq: ( typeof this.customer_seq == 'object' ) ? this.customer_seq.id : this.customer_seq
    }).then( response => {
      if( response.ResultCode ) {
        this.productList = response.data;
      } else {
        this.productList = [];

        this.toastrService.error( response.ResultMessage, '상품 가져오기');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( '서버와의 통신에 실패하였습니다 : ' + error, '상품 가져오기');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onLeftGridReady(params) {
    this.leftGridApi = params.api;
    this.leftGridColumnApi = params.columnApi;
  }

  onRightGridReady(params) {
    this.rightGridApi = params.api;
    
    this.addGridDropZone();
  }

  // 처음 데이터 렌더링 시 처리
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // 그리드 노드 아이디 가져오기
  getRowNodeId = data => data.property_seq;

  // 그리드 드랍 시
  addGridDropZone() {
    const dropZoneParams = this.rightGridApi.getRowDropZoneParams({
      onDragStop: params => {
        var nodes = params.nodes;

        nodes.forEach(function(node) {
          node.setSelected(false);
        });
        // this.leftGridApi.applyTransaction({
        //   remove: nodes.map(function(node) { return node.data; })
        // });
      }
    });

    this.leftGridApi.addRowDropZone(dropZoneParams);
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( event: any ) {
    let newData: any = [];
    let duplicate: boolean = false;

    this.rightGridApi.forEachNodeAfterFilterAndSort(node => {
      if( event.data.property_seq == node.data.property_seq ) duplicate = true;

      newData.push(node.data);
    });

    if( duplicate === false ) {
      newData.push( event.data );
    }

    this.rightGridApi.setRowData( newData );
  }
  
  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.leftGridApi.setQuickFilter(searchText.value);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectBrandChanged( event: any ) {
    if( event.id != '0' ) {
      this.search.brand_seq = event.id;
    } else {
      this.search.brand_seq = '';
    }

    this.getProductListForProperty();
  }

  /*******************************************************************************
    설  명 : 상품 선택 시 결과 돌려주기
    입력값 : 상품 정보
    리턴값 : 없음
  *******************************************************************************/
  selectProduct() {
    const rowData = [];
    const seqArray = [];
    this.rightGridApi.forEachNode(node => {
      rowData.push(node.data);
      seqArray.push(node.data.property_seq);
    });

    // 선택된 상품에 한해서 가격정보 가져오기
    this.productService.getProductListForPropertyPrice( this.mem_no, seqArray ).then( response => {

      if( response.ResultCode ) {

        response.data.forEach(item => {
          rowData.forEach(row => {
            if( item.property_seq == row.property_seq ) {
              row.unit_price = item.unit_price;
              row.consumer_price = item.consumer_price;
              row.buy_price = item.buy_price;
              row.mileage = item.mileage;
              row.display_price = item.display_price;
              row.event_seq = item.event_seq;
              row.event_name = item.event_name;
            }
          });
        });

        this.activeModal.close( rowData );

      } else {
        this.toastrService.error( response.ResultMessage, '상품 가격정보 가져오기');
        this.activeModal.close();
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      category_code: '',
      brand_seq: {id: '0'}
    };
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductListForProperty();
    }
  }

  /*******************************************************************************
    설  명 : 창닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDismiss() {
    this.activeModal.dismiss();
  }

}
