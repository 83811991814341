import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SystemPositionService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 직위 리스트 가져오기
  getPositionList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'position',
        version: '1.0',
        action: 'getPositionList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 직위 정보 저장하기
  setPosition( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'position',
        version: '1.0',
        action: 'setPosition'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 직위 정보 저장하기
  updatePosition( jsonData ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'position',
        version: '1.0',
        action: 'updatePosition'
      }, {
        data: jsonData
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 직위 정보 삭제하기
  deletePosition( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'position',
        version: '1.0',
        action: 'deletePosition'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
