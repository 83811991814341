/*******************************************************************************
  설  명 : 조직관리
  작성일 : 2019-09-21
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { SystemGroupService } from '@app/service/system.group.service';

@Component({
  selector: 'app-system-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public groupList = [];

  public groupForm: FormGroup;
  public formErrors = {};

  /* 노드관련 변수 */
  public nodeData: any = [];
  public selectedNode = {
    seq: '',
    dept_cd: '',
    dept_name: '',
    memo: '',
    use_yn: '1',
    children: []
  };

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.groupForm = this.formBuilder.group({
      seq:["", [] ],
      dept_cd:["", [Validators.required, Validators.maxLength(9)] ],
      dept_name:["", [Validators.required, Validators.maxLength(45)] ],
      use_yn:["", [Validators.required] ],
    });

    this.groupForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.groupForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private systemGroupService: SystemGroupService,
    public authService: AuthService,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    // 카테고리 리스트 가져오기
    this.getGroupListN();
  }

  /*******************************************************************************
    설  명 : 카테고리 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.groupForm, this.formErrors);

    if(this.groupForm.valid) {
      this.systemGroupService.setGroup(this.groupForm).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getGroupListN();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기 (계층형)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupListN() {
    this.systemGroupService.getGroupListN().then( response => {
      if( response.resultCode ) {
        this.nodeData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteGroup() {
    if( this.groupForm.controls.seq.value == '' ) {
      this.toastrService.error( '삭제할 조직을 선택하세요.', '');

      return false;
    }

    if( confirm("선택한 조직을 삭제하시겠습니까?") ) {
      this.systemGroupService.deleteGroup( this.groupForm ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.groupForm.patchValue({
            seq: '',
            dept_cd: '',
            dept_name: '',
            memo: '',
            use_yn: '1',
            children: []
          });

          this.getGroupListN();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 조직도 삭제 할 수 있는지 검증
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkDeleteGroup() {
    let dept_cd = this.selectedNode.dept_cd;

    if( typeof this.selectedNode.children == 'undefined' ) {
      return true;
    } else {
      if( this.selectedNode.children.length > 0 ) {
        return false;
      } else {
        return true;
      }
    }
  }

  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {
    this.selectedNode.seq = $event.seq;
    this.selectedNode.dept_cd = $event.key;
    this.selectedNode.dept_name = $event.name;
    this.selectedNode.use_yn = $event.use_yn;
    this.selectedNode.children = $event.children;

    this.groupForm.patchValue( this.selectedNode );
  }

  /*******************************************************************************
    설  명 : 노드추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onAddNode($event) {
    this.groupForm.patchValue({
      seq: '',
      dept_cd: $event.key,
      dept_name: '',
      memo: '',
      use_yn: '1',
      children: []
    });
  }

  /*******************************************************************************
    설  명 : 노드순서변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onDropNode($event) {
    this.systemGroupService.setNodeSort( $event ).then( response => {
      if( response.ResultCode ) {
        this.getGroupListN();
      }
    }).catch( error => {
    });
  }
}
