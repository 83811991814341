<section class="apage-title">
  <div class="title-bar"></div>
  <h3>상품문의</h3>
</section>

<section class="adata-list-area">
  <div class="asub-title">
    <h4>답변달기</h4>
  </div>

  <form [formGroup]="form">
    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <!-- 1:1문의 게시판 -->
    <section class="data-list">
      <table class="table table-bordered table-small">
      <caption class="hidden">게시판 답변달기</caption>

      <colgroup>
        <col style="width:200px" />
        <col style="width:*" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><i class="material-icons cred f11">star</i> <label for="product_name">상품명</label></th>
        <td>
          <inputEx name="product_name" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11">star</i> <label for="comment">내용</label></th>
        <td>
          <inputEx name="comment" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
    </section>
    <!-- 1:1문의 게시판 -->

    <section>
      <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="answer"></div>
      <div *ngIf="formErrors.answer != ''" class="error-message">{{formErrors.answer}}</div>

      <div class="mt10 mb30 mr10">
        <div class="fr">
          <button class="btn abtn abasic-btn mr10" (click)="goList()">취소</button>
          <button type="button" class="btn abtn abasic-btn btn-silver" (click)="submit()">저장하기</button>
        </div>

        <div class="cb"></div>
      </div>
    </section>
  </form>

</section>
