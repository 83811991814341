<section id="customer-charge">
  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">담당자정보</h4>
    </div>
    <div class="fr w50p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="2116a120ff764b10865f976ad02280c4" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-danger btn-small ml5" (click)="setCustomerChargeDelete()">담당자삭제</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="setCustomerChargeAddOn()">담당자추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <div class="" style="height:calc(100% - 37px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="customerChargeList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (rowClicked)="onRowClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
