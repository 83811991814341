<button
  *ngIf="params.action=='barcode'"
  class="btn btn-default btn-xsmall f12 vai" 
  [ngClass]=""
  (click)="openModalBarcode()"
>
  {{params.btnName}}
</button>

<button 
  *ngIf="params.action=='delete'"
  type="button" 
  class="btn btn-default btn-grid2 mr5"
  (click)="params.listaction(params)">
  <i class="material-icons menu-icon fl micon-create f12 ml3">&#xe5cd;</i>
</button>

<button
  *ngIf="params.action=='modify'"
  type="button" 
  class="btn btn-default btn-grid2 mr5"
  (click)="params.listaction(params)">
  <i class="material-icons menu-icon fl micon-create f12 ml3">&#xe150;</i>
</button>

<button
  *ngIf="params.action=='process'"
  type="button"
  class="btn btn-default btn-small mr5 mb5" 
  (click)="params.clickEvent(params)"
>{{params.btnName}}</button>

<ng-container *ngIf="params.action=='openmarket'">
  <button
    type="button" 
    class="btn btn-default btn-grid-icon mr15"
    (click)="params.setOpenOrderProductFind(params)">
    <i class="material-icons menu-icon fl micon-create ml2">&#xe8b6;</i>
  </button>
  <!-- <button
    type="button" 
    class="btn btn-primary btn-grid-icon mr5"
    (click)="params.setOpenOrderSave(params)">
    <i class="material-icons menu-icon fl micon-create ml2">&#xe3c9;</i>
  </button> -->
  <button
    type="button" 
    class="btn btn-danger btn-grid-icon"
    (click)="params.setOpenOrderDelete(params)">
    <i class="material-icons menu-icon fl micon-create ml2">&#xe92b;</i>
  </button>
</ng-container>

<button
  *ngIf="params.action=='memberInfo' && params.data.id!=''"
  type="button"
  class="btn btn-default btn-xsmall mr5 mb5" 
  (click)="params.listaction(params)"
>
  {{params.btnName}}
</button>

<button
  *ngIf="params.action=='memberSearch' && params.data.id!=''"
  type="button"
  class="btn btn-default btn-xsmall mr5 mb5" 
  (click)="params.listaction(params)"
>
  {{params.btnName}}
</button>

<button
  *ngIf="params.action=='memberSearch' && params.data.id!=''"
  type="button"
  class="btn btn-default btn-xsmall mr5 mb5" 
  (click)="params.listaction(params)"
>
  {{params.btnName}}
</button>

<ng-container *ngIf="params.action=='download'">
  <a *ngFor="let row of params.data.images;let i=index;"
    [href]="baseURL + '/api/file/commonfile_download.php?application_seq=' + row.seq"
    target="_blank"><div class="file fl">''</div>
  </a>
</ng-container>

<ng-container *ngIf="params.action=='approval_download'">
  <div *ngFor="let row of params.data.upload;let i=index;">
    <a href="{{row.path}}" target="_blank">
      <button type="button" class="btn btn-default btn-grid2 mr5">
        <i class="material-icons menu-icon fl micon-vertical_align_bottom f12 ml3">&#xe258;</i>
        <span class="badge f12">{{row.upload_file}}</span>
     </button>
    </a>
  </div>
</ng-container>

<ng-container *ngIf="params.action=='order_product_seq'">
  {{params.data.product_seq}}<br/>
  {{params.data.product_property_seq}}<br/>
  <button type="button" class="btn btn-default btn-xsmall pd3" (click)="params.btnClicked(params)">상품관리</button>
</ng-container>

<ng-container *ngIf="params.action=='order_brand'">
  <ng-container *ngIf="params.data.provider_seq > 0">
    <button type="button" class="btn btn-success btn-xsmall" (click)="params.providerClicked(params)">공급자정보</button><br/>
  </ng-container>
  {{params.data.brand_name}}<br/>
  <span style="color:red;">{{params.data.category_name}}</span>
  <ng-container *ngIf="checkBrandSena(params.data.brand_name)">
    <br/><button type="button" class="btn btn-primary btn-xsmall" (click)="params.senaClicked(params)">세나처리</button>
  </ng-container>
</ng-container>

<ng-container *ngIf="params.action=='order_product_info'">
  <ng-container *ngIf="params.data.comment">
    <span [innerHTML]="params.data.comment"></span><br/>
  </ng-container>
  <span style="color:#007700;font-weight: bold;">{{params.data.product_name}}</span><br/>
  <span class="fb">{{ params.data.display_color_name ? params.data.display_color_name : '' }}</span>
  /
  <span class="fb">{{ params.data.display_size_name ? params.data.display_size_name : '' }}</span>
  <ng-container *ngIf="params.data.delivery_info">
    <ng-container *ngFor="let item of setArray(params.data.delivery_info, ',')">
      <br/><span style="color:green; font-weight: 600;">{{setSplit(item, '_', 0)}}</span>
      <br/><span class="mr5" style="color:green; font-weight: 600;">{{setSplit(item, '_', 1)}}</span>
      <button type="button" class="btn btn-default btn-xsmall mb5" (click)="params.deliveryClicked(item)">택배송장확인</button>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="params.action=='approval_modify'">
  <ng-container *ngIf="params.data.session_mem_no == '9'">
    <button type="button" class="btn btn-default btn-grid1 mr5" (click)="params.modifyClicked(params)">수정</button>
  </ng-container>

  <ng-container *ngIf="params.data.step_status == 'R' && params.data.writer == params.data.mem_no">
    <button type="button" class="btn btn-default btn-grid1 mr5" (click)="params.reApprovalClicked(params)">재작성</button>
  </ng-container>

  <ng-container *ngIf="params.data.approval_group == 'A' && params.data.status == '1000' && params.data.approval_type == 'ON' && params.data.step_index === params.data.curr_step_index">
    <button type="button" class="btn btn-default btn-grid1 mr5" (click)="params.approvalClicked(params)">결재</button>
  </ng-container>

  <ng-container *ngIf="params.data.approval_group == 'G' && params.data.status == '1000' && params.data.approval_type == 'ON' && params.data.step_index === params.data.curr_step_index">
    <button type="button" class="btn btn-default btn-grid1 mr5" (click)="params.approvalClicked(params)">협조</button>
  </ng-container>

  <ng-container *ngIf="params.data.session_mem_no == '9'">
    <button type="button" class="btn btn-danger btn-grid1 mr5" (click)="params.cancelClicked(params)">취소</button>
  </ng-container>

  <ng-container *ngIf="params.data.approval_type == 'OFF' && params.data.step_status == 'P' && params.data.writer == params.data.mem_no">
    <button type="button" class="btn btn-default btn-grid1 mr5" (click)="params.offClicked(params)">완료</button>
  </ng-container>

  <ng-container *ngIf="params.data.step_status == 'C' && params.data.step_status != 'R' || params.data.approval_type == 'OFF'">
    <button type="button" class="btn btn-default btn-grid1 mr5" (click)="params.printClicked(params)">인쇄</button>
  </ng-container>

  <ng-container *ngIf="params.data.document_type == '1001' && params.data.execute_number != null && params.data.step_status != 'R'">
    <button type="button" class="btn btn-default btn-grid1 mr5" (click)="params.approvalPrintClicked(params)">공문서</button>
  </ng-container>

  <ng-container *ngIf="params.data.document_type == '1001' && params.data.execute_number == null && params.data.step_status != 'R'">
    <button type="button" class="btn btn-default btn-grid1 mr5" (click)="params.executeClicked(params)">시행문</button>
  </ng-container>
</ng-container>

<ng-container *ngIf="params.action=='productMasterList'">
  <div class="mt15">
    <button
      type="button"
      class="btn btn-default btn-xsmall mr5 mb5 fl" 
      (click)="params.goDetailFunc(params)"
    >
      상세보기
    </button>
    <button
      *ngIf="!params.data.property_seq"
      type="button"
      class="btn btn-default btn-xsmall mr5 mb5 mt10 fl" 
      (click)="params.getPropertyListFunc(params)"
    >
      옵션보기
    </button>
    <button
      *ngIf="params.data.property_seq"
      type="button"
      class="btn btn-default btn-xsmall mr5 mb5 mt10 fl" 
      (click)="params.getProductListFunc(params)"
    >
      전체보기
    </button>
  </div>
</ng-container>
