import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { config } from '@app/service/config.service';

import { UtilService } from '@app/service/util.service';
import { CategoryService } from '@app/service/category.service';
import { CommonService } from '@app/service/common.service';
import { RestfulService } from '@app/service/restful.service';
import * as $ from 'jquery';

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  public typeList = [];
  public categoryList = [];

  public categoryForm: FormGroup;
  public formErrors = {};

  public imageList: any = [];

  public baseURL = config.baseUrl;

  public summernoteConfig: any = config.summernoteConfig;

  public uploadProgress: any = 0;

  public uploader: FileUploader;

  /* 노드관련 변수 */
  public nodeData: any = [];
  public selectedNode = {
    seq: '',
    category_code: '',
    category_name: '',
    mall_display: '1',
    memo: '',
    caution: '',
    category_type: '',
    use_yn: true,
    children: []
  };

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.categoryForm = this.formBuilder.group({
      seq: ['', [] ],
      category_code: ['', [Validators.required] ],
      category_name: ['', [Validators.required, Validators.maxLength(20)] ],
      mall_display: ['1', []],
      memo: ['', [] ],
      category_type: ['', [Validators.required, Validators.maxLength(11)] ],
      use_yn: [true, [Validators.required] ],
      caution: ['', [] ],
      file: ['', []],
      upload: [[], []],
      files: [[], []]
    });

    this.categoryForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.categoryForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private categoryService: CategoryService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private restful: RestfulService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 공통 코드 가져오기
    this.getCommonList().then( () =>
      // 카테고리 리스트 가져오기
      this.getCategoryListN()
    );

    this.summernoteConfig.height = 150;

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const files = this.categoryForm.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: this.baseURL + response.thumbnail,
          thumbnail_org: response.thumbnail_org,
          is_default: '0'
        });

        this.categoryForm.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

 /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }
  
  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }


  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
    deleteFile( file: any, index ) {
      if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
        let tmp = this.categoryForm.controls.files.value;
  
        tmp.splice( index, 1 );
  
        this.categoryForm.patchValue({files: tmp});
      }
    }
  
    /*******************************************************************************
      설  명 : 파일 삭제
      입력값 : 업로드 파일 정보
      리턴값 : 없음
    *******************************************************************************/
    setDeleteUploadFile( upload: any, index: any ) {
      if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
        this.categoryService.setDeleteUploadFile( upload.seq ).then( response => {
          if( response.ResultCode ) {
            let uploadData = this.categoryForm.controls.upload.value;
            uploadData.splice( index, 1 );
            this.categoryForm.patchValue(uploadData);
  
            this.toastrService.success( response.ResultMessage, '');
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  
    /*******************************************************************************
      설  명 : 업로드 된 파일 명이 이미지 인지 체크
      입력값 : 확장명
      리턴값 : true / false
    *******************************************************************************/
    checkImage( ext: string ) {
      let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
  
      if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
      else return true;
    }
  
    /*******************************************************************************
      설  명 : 이미지 서머노트에 삽입
      입력값 : 파일
      리턴값 : 없음
    *******************************************************************************/
    insertUploadImage( file ) {
      if( this.checkImage(this.getExt(file.origin_filename)) ) {
        let addHtml = this.categoryForm.controls.caution.value + '<br /><img src="' + file.url + '" />';

        this.categoryForm.patchValue( {caution: addHtml} );
      }
    }
  
    /*******************************************************************************
      설  명 : 이미지 서머노트에 삽입
      입력값 : 파일
      리턴값 : 없음
    *******************************************************************************/
    insertFilesImage( file: any ) {
      if( this.checkImage(this.getExt(file.origin)) ) {
        let addHtml = this.categoryForm.controls.caution.value + '<br /><img src="' + file.url + '" />';
  
        this.categoryForm.patchValue( {caution: addHtml} );
      }
    }

  /*******************************************************************************
    설  명 : 카테고리 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.categoryForm, this.formErrors);

    if(this.categoryForm.valid) {
      this.categoryService.setCategory(this.categoryForm).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getCategoryListN();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 - 거래처분류, 거래처종류 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    return this.commonService.getCommonList('CGT').then( response => {
      this.typeList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryListN() {
    this.categoryService.getCategoryListN().then( response => {
      if (response.resultCode) {
        this.nodeData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteCategory() {
    if( this.categoryForm.controls.seq.value == '' ) {
      this.toastrService.error( '삭제할 카테고리를 선택하세요.', '');

      return false;
    }

    if( confirm("선택한 카테고리를 삭제하시겠습니까?") ) {
      this.categoryService.deleteCategory( this.categoryForm ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.categoryForm.patchValue({
            seq: '',
            category_code: '',
            category_name: '',
            memo: '',
            caution: '',
            mall_display: '1',
            category_type: '',
            use_yn: true,
            children: []
          });

          this.getCategoryListN();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 삭제 할 수 있는지 검증 - 캐리어, 관리상품 카테고리는 삭제 불가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkDeleteCategory() {
    if( this.selectedNode.seq == '' ) return false;

    let category = this.selectedNode.category_code.substr(0, 3);

    if( category == '009' || category == '008' ) {
      return false;

    } else {
      if( typeof this.selectedNode.children == 'undefined' ) {
        return true;

      } else {
        if( this.selectedNode.children.length > 0 ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {
    // 선택 해제
    if( this.selectedNode.seq == $event.seq ) {
      this.selectedNode = {
        seq: '',
        category_code: '',
        category_name: '',
        mall_display: '1',
        memo: '',
        caution: '',
        category_type: '',
        use_yn: true,
        children: []
      };

    // 카테고리 선택
    } else {
      this.selectedNode.seq = $event.seq;
      this.selectedNode.category_code = $event.key;
      this.selectedNode.category_name = $event.name;
      this.selectedNode.use_yn = $event.use_yn;
      this.selectedNode.mall_display = $event.mall_display;
      this.selectedNode.caution = $event.caution;
      this.selectedNode.category_type = $event.category_type;
      this.selectedNode.memo = $event.memo;
    }

    this.categoryForm.patchValue( this.selectedNode );

    this.categoryService.getCategoryImage( $event.seq ).then( response => {
      if( response.ResultCode ) {
        this.categoryForm.patchValue({
          upload: response.data
        });
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  

  /*******************************************************************************
    설  명 : 노드추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onAddNode($event) {
    this.categoryForm.patchValue({
      seq: '',
      category_code: $event.key,
      category_name: '',
      memo: '',
      caution: '',
      use_yn: true,
      children: []
    });
  }

  /*******************************************************************************
    설  명 : 노드순서변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onDropNode($event) {
    this.categoryService.setNodeSort( $event ).then( response => {
      if( response.ResultCode ) {
        this.getCategoryListN();
      }
    }).catch( error => {
    });
  }
}
