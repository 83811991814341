<form name="form" id="form" form-error-focus novalidate>
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title bold">결재</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
  <!-- Modal header -->

  <!-- Modal body -->
  <div class="modal-body">

    <section id="print-contents">
      <!-- 타이틀 영역 //-->
      <div class="fl w60p">
        <img src="/assets/images/logo.png" alt="로고" height="26" />

        <div class="f32 bold fngex center pt30 center">
          연차/휴가 신청서
        </div>
      </div>

      <div class="cb"></div>

      <!-- 내용부분 //-->
      <div id="print-contents-body" class="pt20">
        <div class="document-select">
          <ul>
            <li>
              <a class="cp" [ngClass]="{active: type == '10'}" (click)="onClicked('10')">
                <span>연차: 1</span>
              </a>  
            </li>
            <li>
              <a class="cp" [ngClass]="{active: type == '20'}" (click)="onClicked('20')">
                <span>반차: 0.5</span>
              </a>  
            </li>   
            <li>
              <a class="cp" [ngClass]="{active: type == '60'}" (click)="onClicked('60')">
                <span>경조: 0</span>
              </a>  
            </li>   
            <li>
              <a class="cp" [ngClass]="{active: type == '70'}" (click)="onClicked('70')">
                <span>병가: 0</span>
              </a>  
            </li>
            <li>
              <a class="cp" [ngClass]="{active: type == '80'}" (click)="onClicked('80')">
                <span>외출: 0.25</span>
              </a>  
            </li>   
          </ul>
        </div>

        <div class="btn-group" *ngIf="type == '20'">
          <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.half_day.value=='1'}" (click)="form.patchValue({half_day: '1'})">오전 [09:00~13:00]</button>
          <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.half_day.value=='2'}" (click)="form.patchValue({half_day: '2'})">오후 [13:00~18:00]</button>
        </div>

        <div *ngIf="type=='80'">
          <div style="color: gray;">외출 시간</div>
          <table class="list-table table table-bordered mt10 ng-scope mr10">
          <colgroup>
            <col style="width:20%">
            <col style="width:80%">
          </colgroup>
          
          <tbody>
          <tr>
            <th class="active" scope="row">시작시간</th>
            <td>
              <inputEx type="text" name="stime" [formGroup]="form" [formErrors]="formErrors" placeholder="09:00"></inputEx>
            </td>
          </tr>
          <tr>
            <th class="active" scope="row">종료시간</th>
            <td>
              <inputEx type="text" readonly="true" name="etime" value="etime"  [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr>
          </tbody>
          </table>            
        </div>
      </div>

    </section>


  </div>
  <!-- Modal body -->

  <!-- Modal footer -->
  <div class="modal-footer clearfix">
    <div class="fl w30p left">
    </div>
    <div class="fr w70p right">
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="submit()">닫기</button>
    </div>
  </div>
  <!-- Modal footer -->
</form>