<ng-container *ngIf="! checkPrintPage">

<app-header></app-header>
<app-gnb></app-gnb>
<app-nav></app-nav>

<section class="contents-body">
  <router-outlet></router-outlet>
</section>

</ng-container>

<ng-container *ngIf="checkPrintPage">
   <div id="print-area">
     <router-outlet></router-outlet>
   </div>
</ng-container>