<form [formGroup]="form">
  <section class="search-condition mb5">
    <div class="fl form-inline asub-title">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="reset(this)">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getStockOrder()">
        <i class="material-icons-outlined">search</i>
      </button> -->

      <div class="btn-group ml5">
        <button *ngFor="let item of ['전체','매입','직발']; index as idx" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gbn.value == idx}" (click)="btnClick('gbn', idx, this)">{{item}}</button>
      </div>

      <div class="btn-group ml5">
        <button *ngFor="let item of ['전체','입고완료','미입고']; index as idx" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.in_warehouse.value == idx}" (click)="btnClick('in_warehouse', idx, this)">{{item}}</button>
      </div>

      <!-- <div class="btn-group ml5">
        <button *ngFor="let item of ['전체','결재','미결재']; index as idx" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.approval.value == idx}" (click)="btnClick('approval', idx, this)">{{item}}</button>
      </div> -->
      
      <div class="input-group ml5">
        <!-- <inputEx type="text" name="product_name" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx> -->
        <input type="text" size="27" class="form-control form-control-small" placeholder="주문번호, 거래처, 상품명" [value]="form.value.search" (input)="searchInput($event)" (keypress)="keypress($event)" />
        <div class="input-group-append">
          <button type="button" class="btn btn-default btn-small f12" (click)="softReset()">검색</button>
        </div>
      </div>

      <!-- <div class="input-group ml5">
        <input type="text" class="form-control form-control-small readonly" readonly="readonly" placeholder="거래처 검색" />
        <div class="input-group-append">
          <button type="button" class="btn btn-default btn-small f12">거래처찾기</button>
        </div>
      </div> -->

      <!-- <input type="text" style="width:110px;" class="form-control form-control-small calendar-input ml5" [readonly]="true" placeholder="검색 시작일" [value]="form.value.s_date"/>
      <span class="pl5 pr5">~</span>
      <input type="text" style="width:110px;" class="form-control form-control-small calendar-input " [readonly]="true" placeholder="검색 종료일" [value]="form.value.e_date" /> -->
      <input type="text" size="13" readonly="readonly" [value]="form.value.s_date" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect)="selectDate('s_date', $event, this)" />
      <span class="pl5 pr5">~</span>
      <input type="text" size="13" readonly="readonly" [value]="form.value.e_date" class="form-control form-control-small calendar-input " placeholder="검색 종료일" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect)="selectDate('e_date', $event, this)" />

      <div class="btn-group ml10">
        <button *ngFor="let obj of [
          {idx: 0, item: '오늘'}, {idx: 1, item: '어제'}, {idx: 3, item: '3일'}, {idx: 7, item: '7일'}, {idx: 15, item: '15일'}, {idx: 30, item: '30일'}, {idx: 90, item: '90일'}, {idx: 180, item: '180일'}
        ]" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_btn.value == obj.idx}" (click)="quickDate(obj.idx, this)">{{obj.item}}</button>
      </div>
    </div>
    <div class="fr">
      <button type="button" class="btn btn-success btn-small f12 mr5" (click)="quickPurchase()">빠른발주</button>
      <button type="button" class="btn btn-primary btn-small f12" (click)="addStockOrder()">발주등록</button>
    </div>
    <div class="cb"></div>
  </section>
</form>

  <hr class="db mt5 mb5" />

  <as-split unit="percent" restrictMove="true" gutterSize="10" style="height: calc(100% - 40px);">
    <as-split-area size="50" minSize="30" maxSize="70">
      <div class="mb5">
        <div class="fl pt5 fb">발주리스트</div>
        <div class="fr">
          <button type="button" class="btn btn-info btn-small f12 mr5" (click)="setPurchaseCombine()">발주서통합</button>
          <button type="button" class="btn btn-success btn-small f12 mr5" (click)="updatePurchaseComplete()">발주처리</button>
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="d2b83d8f10b64e2b8ca92caf9288fbdd" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-danger btn-small ml5" (click)="delete_purchase()">발주 삭제</button>
          <!-- <button type="button" class="btn btn-success btn-small f12 ml5">결재상신</button> -->
          <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="printPurchase()">발주서 출력</button>
          <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="printSpec()">명세서 출력</button>
        </div>
        <div class="cb"></div>
      </div>

      <ag-grid-angular
        #myGrid

        style="width: 100%;height:calc(100% - 40px);"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="form.value.stockOrderList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [rowClassRules]="rowClassRules"

        (gridReady)="onGridReady($event, this)"
        (rowClicked)="onRowClicked_purchase($event, this)"
        (selectionChanged)="onSelectionChanged_purchase($event)"
        >
      </ag-grid-angular>
    </as-split-area>
    <as-split-area size="50" minSize="30" maxSize="70">
      <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10">
        <as-split-area size="50" minSize="30" maxSize="70">
          <div class="mb5">
            <div class="fl pt5 fb">
              발주상세 내역
            </div>
            <div class="fr">
              <button type="button" class="btn btn-info btn-small mr5" (click)="setOrderMemo()">주문메모 등록</button>
              <button type="button" class="btn btn-danger btn-small mr5" (click)="delete_purchase_order()">선택 삭제</button>
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApiDetail" gridId="c5a86c8aae3b468283dede281bb2edc5" [btnGroup]="1"></btn-ag-grid-save>
            </div>
            <div class="cb"></div>
          </div>

          <ag-grid-angular
            #myGrid

            style="width: 100%;height:calc(100% - 40px);"
            class="ag-theme-balham"

            [columnDefs]="columnDefsDetail"
            [defaultColDef]="defaultColDef"
            [rowData]="form.value.stockOrderDetailList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"

            (gridReady)="onGridReadyDetail($event, this)"
            (cellClicked)="onCellClicked_product($event, this)"
            >
          </ag-grid-angular>
        </as-split-area>

        <as-split-area size="50" minSize="30" maxSize="70">
          <div class="mb5">
            <div class="fl pt5 fb">
              색상 및 사이즈 내역
            </div>
            <div class="fr">
              <button type="button" class="btn btn-danger btn-small f12 mr10" (click)="delete_purchase_order_property()">선택 삭제</button>
              <button type="button" class="btn btn-danger btn-small f12 mr5" (click)="setPurchaseQty()">발주수량변경</button>
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApiDetail" gridId="d7d721e0b08d45249409a8b4a441b5ef" [btnGroup]="1"></btn-ag-grid-save>
            </div>
            <div class="cb"></div>
          </div>

          <ag-grid-angular
            #myGrid

            style="width: 100%;height:calc(100% - 40px);"
            class="ag-theme-balham"

            [columnDefs]="columnDefsProperty"
            [defaultColDef]="defaultColDef"
            [rowData]="form.value.stockOrderProperyList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"

            (gridReady)="onGridReadyProperty($event, this)"
            >
          </ag-grid-angular>
        </as-split-area>
      </as-split>
    </as-split-area>
  </as-split>