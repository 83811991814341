import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistic-bike-app-release',
  templateUrl: './statistic-bike-app-release.component.html',
  styleUrls: ['./statistic-bike-app-release.component.scss']
})
export class StatisticBikeAppReleaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
