import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { Chart } from 'chart.js';
import { AgGridAngular } from 'ag-grid-angular';

import { NumeralPipe } from '@app/pipes/numeral.pipe';

import { RestfulService } from '@app/service/restful.service';

@Component({
  selector: 'app-member-mileage-statistic',
  templateUrl: './member-mileage-statistic.component.html',
  styleUrls: ['./member-mileage-statistic.component.scss']
})
export class MemberMileageStatisticComponent implements OnInit {
  @ViewChild('chart', {static: true} ) chartElement: any;
  @ViewChild('gridA', {static: true} ) gridA: AgGridAngular;
  @ViewChild('gridB', {static: true} ) gridB: AgGridAngular;

  private chart: any;

  public mileageTotalRow: any;

  // 그리드 관련 선언
  selectedRows: number = 0;
  exchangeList: any = [];

  columnDefs: any;
  defaultColDef: any;
  domLayout: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  constructor(
    private numeralPipe: NumeralPipe,
    private restfulService: RestfulService,
  ) {
  }

  currencyFormatter(parameters: any) {
    return this.numeralPipe.transform(parameters.value, '0,0');
  }

  ngOnInit(): void {
    this.chart = new Chart(this.chartElement.nativeElement, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {backgroundColor: '#86C7F3', data: [], label: '누적 마일리지'},
          {backgroundColor: '#FFA1B5', data: [], label: '사용 마일리지'},
          {backgroundColor: '#FFE29A', data: [], label: '가용 마일리지'},
        ],
      },
      options: {
        plugins: {
          datalabels: {
            formatter: (value: number) => {
              return this.numeralPipe.transform(value, '0,0');
            }
          }
        },
        responsive: true,
        scales: {
          yAxes: [ {
            ticks: {
              callback: (value: number) => {
                return this.numeralPipe.transform(value, '0,0');
              }
            }
          } ],
        },
      }
    } );

    this.columnDefs = [
      {headerName: '랭킹', field: 'rank', width: 80, type: 'numericColumn'},
      {headerName: '아이디', field: 'member_id', width: 120, cellClass: 'cp'},
      {headerName: '이름', field: 'member_name', width: 120, cellClass: 'cp'},
      {headerName: '마일리지', field: 'mileage_total', width: 120, type: 'numericColumn', valueFormatter: this.currencyFormatter.bind(this) },
      {headerName: '구매금액', field: 'purchase_total', width: 120, type: 'numericColumn', valueFormatter: this.currencyFormatter.bind(this) },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.restfulService.get( {
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getMileageTotal'
    }, {
    } ).then(response => {
      this.mileageTotalRow = response?.result;
    } );

    this.restfulService.get( {
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getMileageTotalByState'
    }, {
    } ).then(response => {
      response?.result.forEach( (row: any) => {
        this.chart.data.labels.push(row.address_state);
        this.chart.data.datasets[0].data.push(row.mileage_total);
        this.chart.data.datasets[1].data.push(row.mileage_used);
        this.chart.data.datasets[2].data.push(row.mileage_total - row.mileage_used);
      } );

      this.chart.update();
    } );

    this.restfulService.get( {
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getMileageTotalByMember'
    }, {
      sort_order: 'mileage_total'
    } ).then(response => {
      this.gridA.gridOptions.api.setRowData(response.result.map( (row: any, index: number) => {
        return {
          ...row,
          rank: index + 1,
          mileage_total: Number(row.mileage_total),
          purchase_total: Number(row.purchase_total),
        };
      } ) );
    } );

    this.restfulService.get( {
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getMileageTotalByMember'
    }, {
      sort_order: 'purchase_total'
    } ).then(response => {
      this.gridB.gridOptions.api.setRowData(response.result.map( (row: any, index: number) => {
        return {
          ...row,
          rank: index + 1,
          mileage_total: Number(row.mileage_total),
          purchase_total: Number(row.purchase_total),
        };
      } ) );
    } );
  }
}
