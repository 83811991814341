/*******************************************************************************
  설  명 : 이벤트관리 - 탭 이벤트 결과분석
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { EventService } from '@app/service/event.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

@Component({
  selector: 'app-event-statistic',
  templateUrl: './event-statistic.component.html',
  styleUrls: ['./event-statistic.component.scss']
})
export class EventStatisticComponent implements OnInit {

  @Input() eventSeq: any;

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    eventSeq: '',
    searchText: ''
  };

  // 그리드 관련 선언
  eventStatisticList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private eventService: EventService,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'rowIndex', width: 50, cellClass: 'cp center ag-cell50h', valueGetter: 'node.rowIndex + 1' },
      { headerName: '주문번호', field: 'order_seq', width: 80, cellClass: 'cp center ag-cell50h' },
      { headerName: '주문일자', field: 'order_date', width: 150, cellClass: 'cp center ag-cell50h' },
      { headerName: '주문자명', field: 'name', width: 120, cellClass: 'cp center ag-cell50h' },
      { headerName: '주문자전화번호', field: 'hp', hide: true },
      { headerName: '카테고리', field: 'category_name', width: 255, cellClass: 'cp left ag-cell50h' },
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'product_name', width: 350, cellClass: 'cp ag-cell50h' },
      { headerName: '브랜드', field: 'brand_name', width: 150, cellClass: 'cp center ag-cell50h' },
      { headerName: '수량', field: 'qty', width: 70, cellClass: 'cp right ag-cell50h', valueFormatter: currencyFormatter },
      { headerName: '판매가', field: 'amt', width: 90, cellClass: 'cp right ag-cell50h', valueFormatter: currencyFormatter },
      { headerName: '공급가', field: 'buy_price', width: 90, cellClass: 'cp right ag-cell50h', valueFormatter: currencyFormatter },
      { headerName: '공급자명', field: 'provider_name', width: 120, cellClass: 'cp center ag-cell50h' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 50;
  };
  
  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.search.eventSeq = this.eventSeq;

    this.getEventOrderList();
  }

  /*******************************************************************************
    설  명 : 이벤트 구매내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventOrderList() {
    this.eventService.getEventOrderList( this.search ).then( response => {
      if (response.ResultCode) {
        this.eventStatisticList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelBuyer() {
    this.eventService.getEventBuyerList( this.search ).then( response => {
      if (response.ResultCode) {
          const contents = [`
          <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
            <caption style="font-size:14pt;"><strong>구매자 내역</strong></caption>
            <colgroup>
              <col style="width:150px;" />
              <col style="width:150px;" />
              <col style="width:200px;" />
              <col style="width:200px;" />
              <col style="width:150px;" />
            </colgroup>
            <tbody>
            <tr align="center" bgColor="#f5f7f7">
              <td>이벤트명</td>
              <td>회원아이디</td>
              <td>회원명</td>
              <td>주문자명</td>
              <td>주문자휴대폰</td>
            </tr>
        `]
    
        let data = response.data;
    
        data.forEach(element => {    
          contents.push(`
            <tr>
              <td style="text-align:center;">${element.event_name}</td>
              <td style="text-align:center;">${element.id !== null ? element.id : ''}</td>
              <td style="text-align:center;">${element.name !== null ? element.name : ''}</td>
              <td style="text-align:center;">${element.o_name}</td>
              <td style="text-align:center;">${element.o_hp}</td>
            </tr>
            </tbody>
          `)
        })
        contents.push(`</table>`)
    
        let today = new Date();
    
        const fileName = `${response.data[0].event_name}_구매자리스트_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`
    
        this.printExcel(fileName, contents.join(''))
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 판매정보 다운로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    setExcelSale() {
      this.eventService.getEventSaleList( this.search ).then( response => {
        if (response.ResultCode) {
            const contents = [`
            <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
              <caption style="font-size:14pt;"><strong>판매내역 내역</strong></caption>
              <colgroup>
                <col style="width:150px;" />
                <col style="width:150px;" />
                <col style="width:100px;" />
                <col style="width:100px;" />
                <col style="width:50px;" />
                <col style="width:100px;" />
                <col style="width:150px;" />
                <col style="width:200px;" />
              </colgroup>
              <tbody>
              <tr align="center" bgColor="#f5f7f7">
                <td>주문자명</td>
                <td>상품명</td>
                <td>사이즈</td>
                <td>칼라</td>
                <td>수량</td>
                <td>금액</td>
                <td>연락처</td>
                <td>주소</td>
              </tr>
          `]
      
          let data = response.data;
      
          data.forEach(element => {    
            contents.push(`
              <tr>
                <td style="text-align:center;">${element.o_name}</td>
                <td style="text-align:center;">${element.product_name}</td>
                <td style="text-align:center;">${element.display_size_name}</td>
                <td style="text-align:center;">${element.display_color_name}</td>
                <td style="text-align:right;">${element.qty}</td>
                <td style="text-align:right;">${element.total_amt}</td>
                <td style="text-align:center;">${element.o_hp}</td>
                <td style="text-align:center;">${element.address}</td>
              </tr>
              </tbody>
            `)
          })
          contents.push(`</table>`)
      
          let today = new Date();
      
          const fileName = `${response.data[0].event_name}_판매리스트_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`
      
          this.printExcel(fileName, contents.join(''))
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  

  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApi.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked( event: any ) {
    if( event.colDef.field === 'order_seq' ) {
      const url = '/order/detail?seq=' + event.data.order_seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getEventOrderList();
    }
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
  *******************************************************************************/
  searchInit() {
    this.search.searchText = '';

    this.getEventOrderList()
  }
  
}
