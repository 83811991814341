import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';

@Component({
  selector: 'app-order-review-tab-info',
  templateUrl: './order-review-tab-info.component.html',
  styleUrls: ['./order-review-tab-info.component.scss']
})
export class OrderReviewTabInfoComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() data: any;
  @Input() emitList: any;

  public imageURL: any = null;
  public imageLink: any = null;
  
  public form: FormGroup;
  public formErrors: any = {};

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private orderService: OrderService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      best_yn: ['', []],
      img_link: ['', []],
      upload: [[], []]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };
  
  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }
  
  /*******************************************************************************
    설  명 : 데이터 변경 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnChanges(): void {
    this.imageURL = null;
    this.imageLink = null;
    this.buildForm();

    if( this.data !== undefined ) {
      this.form.patchValue( this.data );
      this.getOrderReviewImageList();
    }
  }

  /*******************************************************************************
    설  명 : 구매후기 이미지 리스트 가져오기
  *******************************************************************************/
  getOrderReviewImageList() {
    this.orderService.getOrderReviewImageList( this.data.seq ).then( response => {
      if ( response.ResultCode ) {
        this.form.patchValue({
          upload: response.data
        });
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 구매후기 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderReview() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    
    if ( this.form.valid ) {
      this.orderService.setOrderReview( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage );

          this.emitList();
        } else {
          this.toastrService.error( response.ResultMessage );
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 이미지 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setOrderReviewImageDelete( seq: any ) {
    if( confirm("선택하신 이미지를 삭제하시겠습니까?") ) {
      this.orderService.setOrderReviewImageDelete( seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getOrderReviewImageList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
}
