import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@app/service/util.service';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { EmailService } from '@app/service/email.service';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';

import { MemberEmailDetailComponent } from '@page/member/member-email/member-email-detail/member-email-detail.component';

const PAGEROW: number = 100;

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-email',
  templateUrl: './member-email.component.html',
  styleUrls: ['./member-email.component.scss']
})
export class MemberEmailComponent implements OnInit {

  public statistic: any = {
    total_count: 0,
    month_count: 0,
    search_count: 0
  };

  // 검색
  public search: any = {
    pageRow: PAGEROW,
    pageNo: 1,
    seq: '',
    searchText: '',
    sdate: '',
    edate: ''
  };

  public term: any = [
    {title: '이번달', value: this.getDays(0)},
    {title: '1개월', value: this.getDays(1)},
    {title: '2개월', value: this.getDays(2)},
    {title: '3개월', value: this.getDays(3)},
    {title: '6개월', value: this.getDays(6)}
  ];

  public searchTerm: any;

  public sendList: any = [];

  // grid
  private selectedRows = 0;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowSelection = 'multiple';
  public noRowsTemplate = '검색된 데이터가 없습니다.';

  public defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  public columnDefs = [
    {headerName: '선택', field: 'seq', width: 100,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    {headerName: '발송상태', field: 'status', cellClass: 'cp', hide: true },
    {headerName: '발송일시', field: 'writeDate', cellClass: 'cp', width: 140 },
    {headerName: '제목', field: 'subject', cellClass: 'cp left', width: 300 },
    {headerName: '내용', field: 'contents', cellClass: 'cp left', width: 700 },
    {headerName: '수신이메일', field: 'toEmail', cellClass: 'cp left', width: 300 },
    {headerName: '발송자', field: 'writer', cellClass: 'cp left', width: 100 },

  ];
  public frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private emailService: EmailService,
  ) { }

  ngOnInit(): void {
    this.getEmailStatistic();

    this.onSearchClick(this.getDays(0));
  }

  initSearch() {
    this.onSearchClick(this.getDays(0));
  }

  getDays(month: number) {
    const todaysDate = moment();
    let toDate;
    if (month === 0) {
      toDate = moment().startOf('month');
    } else {
      toDate = moment().add(month * -1, 'months');
    }

    return toDate.diff(todaysDate, 'days');
  }

  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }

  onSearchClick( val ) {
    this.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
    this.getEmailSendList();
  }

  getEmailSendList() {
    this.emailService.getEmailSendList(this.search).then(response => {
      if ( response.ResultCode ) {
        this.sendList = response.data;
        this.statistic.search_count = response.search_count;
      } else {
        this.sendList = [];
        this.statistic.search_count = 0;
      }
      }, error => {
      this.toastrService.error(error, 'Email발송내역') ;
    }).catch( error => {
      this.toastrService.error(error, 'Email발송내역') ;
    });
  }

  getEmailStatistic() {
    this.emailService.getEmailStatistic(this.search).then( response => {
      if ( response.ResultCode ) {
        this.statistic.total_count = response.data.total_count;
        this.statistic.month_count = response.data.month_count;
      } else {
        this.statistic = {
          total_count: 0,
          month_count: 0,
          search_count: 0
        };
      }
    }, error => {
      this.toastrService.error( '발송내역 통계를 가져오는데 실패하였습니다.', '발송내역');
    });
  }

  getComma( num ) {
    const str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getEmailSendList();
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked( event: any ) {
    this.viewEmail( event.data );
  }

  /*******************************************************************************
    설  명 : 거래처 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  viewEmail( data: any ) {
    const modalRef = this.modalService.open(MemberEmailDetailComponent, optionsXL);

    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }

}
