import { Component, OnInit, ViewChild } from '@angular/core';
import { IPioneerTreeConfiguration, IPioneerTreeComponent } from '@pioneer-code/pioneer-tree';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalFavoriteMenuGroupComponent } from '@page/layout/modal-favorite-menu-group/modal-favorite-menu-group.component';
import { FavoriteMenuService } from '@app/service/favorite-menu.service';
import { ToastrService } from 'ngx-toastr';

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass: 'modal-fadeInDown'
};
@Component({
  selector: 'app-modal-favorite-menu',
  templateUrl: './modal-favorite-menu.component.html',
  styleUrls: ['./modal-favorite-menu.component.scss']
})
export class ModalFavoriteMenuComponent implements OnInit {

  @ViewChild ( 'pt', {static: false} ) ptComponent: IPioneerTreeComponent;
  @ViewChild ( 'ptMenu', {static: false} ) ptMenuComponent: IPioneerTreeComponent;

  public scrollPage = '';
  public id = ['treeview', 'treeviewMenu'];

  /* 노드관련 변수 */
  public nodeData: any = [];
  public menuData: any = [];
  public selectedNode = {
    seq: '',
    name: '',
    page_cd: '',
    menu_seq: '',
    children: []
  };
  public selectedMenuNode = {
    seq: '',
    name: '',
    page_cd: '',
    menu_seq: '',
    children: []
  };

  public isSort = false;
  public isExpand = true;
  public nodes: any = [];
  configuration = {
    childPropertyName: 'children',
    sortPropertyName: 'key',
    collapseAllOnLoad: false
  } as IPioneerTreeConfiguration;

  configurationMenu = {
    childPropertyName: 'children',
    sortPropertyName: 'page_cd',
    collapseAllOnLoad: false
  } as IPioneerTreeConfiguration;

  private dragNode = {
    id: '',
    node: {},
  };

  constructor(
    public activeModal: NgbActiveModal,
    private favoriteMenuService: FavoriteMenuService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getMenuList().then( resolve => {
      this.getUserMenuList();

      if (this.scrollPage !== '') {
        setTimeout(() => {
          this.selectedNode.page_cd = this.scrollPage;
          const el = document.getElementById('node' + this.scrollPage);
          const treeview = document.getElementById('treeview');
          treeview.scrollTop = el.offsetTop - treeview.offsetTop;
        }, 200);
      }
    });
  }

  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {
    this.selectedNode.seq = $event.seq;
    this.selectedNode.name = $event.name;
    this.selectedNode.page_cd = $event.page_cd;
    this.selectedNode.menu_seq = $event.menu_seq;
    this.selectedNode.children = $event.children;
  }

  onSelectMenuNode($event) {
    this.selectedMenuNode.seq = $event.seq;
    this.selectedMenuNode.name = $event.name;
    this.selectedMenuNode.page_cd = $event.page_cd;
    this.selectedMenuNode.menu_seq = $event.menu_seq;
    this.selectedMenuNode.children = $event.children;
  }

  getMenuList(): Promise<any> {
    return this.favoriteMenuService.getMenuList().then( response => {
      if ( response.ResultCode ) {
        this.nodeData = response.data;
      }
    });
  }

  getUserMenuList() {
    this.favoriteMenuService.getFavoriteMenuList().then( response => {
      if ( response.ResultCode ) {
        this.menuData = response.data;
      }
    });
  }

  getTreeMiddleLine( node ) {
    if ( typeof node === 'undefined' || node === false ) {
      return [];
    } else {
      const maxlength = ( ( node.key.length - 3 ) / 3 );
      const tmp = [];
      for (let i = 0; i < maxlength; i++ ) {
        tmp.push(i);
      }

      return tmp;
    }
  }

  checkChildren(node) {
    if (node.children && node.children.length > 0 || node.key.length < 4) {
      return true;
    }
    return false;
  }

  /*******************************************************************************
    설  명 : lpad
    입력값 : str = 문자열, padLen = 채울 문자 수, padStr = 채울 문자
    리턴값 : 문자열
  *******************************************************************************/
  lpad(str, padLen, padStr) {
    if (padStr.length > padLen) {
      return str;
    }
    str += ''; // 문자로
    padStr += ''; // 문자로
    while (str.length < padLen) {
        str = padStr + str;
    }
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }

  findNode(nodes, key) {
    const node = nodes.find( e => e.key === key);
    if (typeof node === 'undefined' && typeof nodes.children !== 'undefined') {
      this.findNode(nodes.children, key);
    } else {
      return node;
    }
  }

  favoriteMenuGroup() {
    const modalRef = this.modalService.open(ModalFavoriteMenuGroupComponent, optionsXL);
    modalRef.componentInstance.menuData = this.menuData;
    modalRef.result.then((result) => {
      this.getMenuList();
      this.getUserMenuList();
    }, (reason) => {
    });
  }

  setFavoriteMenu() {
    const data = JSON.stringify(this.menuData, (key, value) => {
      if (key === 'pioneerTreeNode') {
        return undefined;
      }
      return value;
    });

    this.favoriteMenuService.setFavoriteMenu(data).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, '');
        this.activeModal.close();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
    .catch(response => {
      this.toastrService.error('등록에 실패하였습니다.', '');
    });
  }

  /* drag and drop */
  onDragStart($event, id, node) {
    if (this.checkChildren(node)) {
      return;
    }
    this.dragNode.id = $event.target.id;
    this.dragNode.node = node;
    $event.dataTransfer.setData('text', id);
  }

  onDragOver($event: DragEvent) {
    $event.preventDefault();
    return false;
  }

  onDrop($event, targetTreeView, targetNode) {
    $event.stopPropagation();
    $event.preventDefault();

    const srcTreeView = $event.dataTransfer.getData('text');
    const nodeData = this.nodeData.map(el => ({...el}));
    const menuData = this.menuData.map(el => ({...el}));

    function setPageCode(parentPage, nodes) {
      let index = 1;
      for (const node of nodes) {
        node.page_cd = parentPage + index.toString().padStart(3, '0');
        index++;
      }
    }

    function nodeAdd(depth, nodes, src, target?) {
      for (const node of nodes) {
        let index = -1;
        if (target) {
          // menu >>> myMenu
          const parentPage = target.page_cd.substring(0, depth * 3);
          index = nodes.findIndex( el => el.page_cd === parentPage);
          if (index > -1) {
            const childIndex = nodes[index].children.findIndex( el => el.page_cd === target.page_cd) + 1;
            nodes[index].children.splice(childIndex, 0, src);
            setPageCode(parentPage, nodes[index].children);
            return true;
          }
        } else {
          // menu <<< myMenu
          index = nodes.findIndex( el => el.key.length === src.key.length && parseInt(el.key, 10) > parseInt(src.key, 10));
          if (index > -1) {
            src.page_cd = src.key;
            nodes.splice(index, 0, src);
            return true;
          }
        }
        if (node.children.length > 0) {
          if (nodeAdd(depth++, node.children, src, target)) {
            return;
          }
        }
      }
    }

    function nodeRemove(nodes, src) {
      for (const node of nodes) {
        const index = nodes.indexOf(nodes.find( el => el.key === src.key ));
        if (index > -1) {
          nodes.splice(index, 1);
          return true;
        }
        if (node.children.length > 0) {
          nodeRemove(node.children, src);
        }
      }
    }

    if (srcTreeView === this.id[0] && targetTreeView === this.id[1] ) {
      // menu >>> Mymenu
      nodeRemove(nodeData, this.dragNode.node);
      nodeAdd(1, menuData, this.dragNode.node, targetNode);
    } else if (srcTreeView === this.id[1] && targetTreeView === this.id[0] ) {
      // menu <<< Mymenu
      nodeRemove(menuData, this.dragNode.node);
      nodeAdd(1, nodeData, this.dragNode.node);
    } else if (srcTreeView === this.id[1] && srcTreeView === targetTreeView ) {
      // Mymenu >>> Mymenu
      nodeRemove(menuData, this.dragNode.node);
      nodeAdd(1, menuData, this.dragNode.node, targetNode);
    }

    this.nodeData = nodeData;
    this.menuData = menuData;
    return false;
  }

  onDragEnd($event) {
    this.dragNode.id = '';
    this.dragNode.node = {};
  }
}
