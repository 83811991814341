<div class="modal-header">
  <h4 class="modal-title fb mb0">외상 대체</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

  <table class="table table-bordered table-small mt50 mb50">
  <colgroup>
    <col style="width:25%" />
    <col style="width:75%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="account_seq" class="control-label">입금은행</label></th>
    <td>
      <inputEx type="select" name="account_seq" [formGroup]="form" [formErrors]="formErrors" [data]="bankList"></inputEx>
    </td>
  </tr>
  <!-- <tr>
    <th scope="row"><label for="date_search" class="control-label">날짜조건</label></th>
    <td>
      <div class="btn-group form-control form-control-small">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '0'}" (click)="onSearchClick('0')">오늘</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '1'}" (click)="onSearchClick('1')">어제</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '2'}" (click)="onSearchClick('2')">2일</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '3'}" (click)="onSearchClick('3')">3일</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '-'}" (click)="onSearchClick('-')">비우기</button>
      </div>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="pay_date" class="control-label">결제일시</label></th>
    <td><dateTimePicker name="pay_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
  </tr> -->
  <tr>
    <th scope="row"><label for="comment">주문메모</label></th>
    <td>
      <inputEx type="text" name="comment" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr>
  </tbody>
  </table>

  </form>


</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small mr5" (click)="setOrderPayComplete()">저장하기</button>
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
  </div>
</div>

