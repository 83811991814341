<div class="modal-header">
  <h4 class="modal-title fb mb0">주문메모 등록</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

  <table class="table table-bordered table-small mt50 mb50">
  <colgroup>
    <col style="width:25%" />
    <col style="width:75%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="comment">주문메모</label></th>
    <td>
      <inputEx type="text" name="comment" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr>
  </tbody>
  </table>

  </form>


</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small mr5" (click)="setOrderMemoSave()">저장하기</button>
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
  </div>
</div>

