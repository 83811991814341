import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 27,
    sdate: '',
    edate: '',
    searchText: '',
    best_yn: ''
  };
  
  public reviewList: any = [];

  public reviewData: any = {};

  public totalCount: any = 0;
  public activeTab: any = 0;

  public baseURL = config.baseUrl;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public emitListFunc: any = this.getOrderReviewList.bind(this);

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private utilService: UtilService,
    private orderService: OrderService,
    private agGridExComponent: AgGridExComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 40, cellClass: 'cp left',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '순번', field: 'seq', width: 68, cellClass: 'cp left', sortable: false },
      { headerName: '주문번호', field: 'order_seq', width: 75, cellClass: 'cp center', sortable: false },
      { headerName: '상품번호', field: 'product_seq', width: 77, cellClass: 'cp center', sortable: false },
      { headerName: '상품명', field: 'product_name', width: 200, cellClass: 'cp left' },
      { headerName: '회원ID', field: 'id', width: 115, cellClass: 'cp left' },
      { headerName: '회원명', field: 'name', width: 80, cellClass: 'cp left' },
      { headerName: '별점', field: 'grade', width: 150, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let grade: any = params.data.grade;
          let width: any = `calc(90px * ${grade} / 5)`;

          return `
            <div class="review-grade">
              <div class="review-grade-active" style="width:${width};"></div>
              <div class="review-grade-back"></div>
              <div class="grade-cnt">${grade}</div>
            </div>
          `;
        }
      },
      { headerName: 'BEST', field: 'best_yn', width: 65, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.best_yn == 1 ) {
            return '<span class="badge badge-primary f12">BEST</span>';
          } else {
            return '<span class="badge badge-secondary f12">일반</span>';
          }
        }
      },
      { headerName: '이미지링크', field: 'img_link_yn', width: 85, cellClass: 'cp center'  , cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.img_link_yn === 'Y' ) {
            return '<span class="badge badge-primary f12">있음</span>';
          } else {
            return '<span class="badge badge-secondary f12">없음</span>';
          }
        }    
      },
      { headerName: '등록일시', field: 'write_date', width: 150, cellClass: 'cp right' },
      { headerName: '신고건수', field: 'reportCount', width: 90, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getOrderReviewList();
  }

  /*******************************************************************************
    설  명 : 구매후기 리스트 가져오기
  *******************************************************************************/
  getOrderReviewList() {
    this.orderService.getOrderReviewList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.reviewList = response.data;
        this.totalCount = response.total;

        if( this.reviewList.length > 0 ) {
          if( this.reviewData.seq === undefined ) {
            let index = 0;
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter(function(node) {
                if( index++ == 0 ) {
                  node.setSelected(true);
                }
              });
            }, 0);
            
            this.reviewData = this.reviewList[0];

          } else {
            const seq = this.reviewData.seq;
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter(function(node) {
                if( node.data.seq == seq ) {
                  node.setSelected(true);
                }
              });
            }, 0);
          }
        }

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked(event: any) {
    if( event.colDef.field === 'product_seq' ) {
      const url = this.baseURL + '/product/info?productSeq=' + event.data.product_seq;
      window.open("about:blank").location.href = url;

    } else if( event.colDef.field === 'order_seq' ) {
      const orderUrl = '/order/detail?seq=' + event.data.order_seq;
      window.open("about:blank").location.href = orderUrl;
      
    } else {
      this.reviewData = event.data;
    }
  }
  
  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabSetValue( value: any ) {
    this.activeTab = value;
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check ) {
    if( check == 'sdate' ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'edate')  {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 검색조건 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearch( field: any, value: any ) {
    this.search[field] = value;

    this.getOrderReviewList();
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 27,
      sdate: '',
      edate: '',
      searchText: '',
      best_yn: ''
    };

    this.reviewData = {};

    this.getOrderReviewList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getOrderReviewList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page: number ) {
    this.search.pageNo = page;

    this.getOrderReviewList();
  }
  
  /*******************************************************************************
    설  명 : 구매후기 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeleteOrderReview() {
    let rows: any = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error( '삭제할 구매후기를 선택하세요.', '' );
      return false;
    } else {
      let data: any = [];
      data = rows.map((obj: any) => obj.seq);

      if (confirm('구매후기 삭제 시 재작성 및 복구가 불가합니다.\n\n정말 삭제하시겠습니까?')) {
        this.orderService.setDeleteOrderReview( data ).then( response => {
          if ( response.ResultCode ){
            this.toastrService.success( response.ResultMessage );
            this.reviewData = {};
            this.getOrderReviewList();
          } else {
            this.toastrService.error( response.ResultMessage );
          }
        });
      }
    }
  }
  
}
