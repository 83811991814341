<section class="widget-div">
  <div class="fl widget cp" style="width: 270px;">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1"><span class="cnt">{{search.year}}년 {{search.month}}월</span> 일자별 매출실적</p>
    </div>
    
    <div class="cb"></div>
  </div>
  
  <div class="fr form-inline">
    <button type="button" class="btn btn-default btn-small mr5" (click)="printExcel()">엑셀 출력</button>
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <input type="text" size="13" readonly="readonly" [value]="search.date" class="form-control form-control-small calendar-input ml5" placeholder="기준일자" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect)="selectDate($event)" />
  </div>
  <div class="cb"></div>
</section>

<div style="height: calc(100% - 270px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%; height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="monthList"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [rowClassRules]="rowClassRules"

    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</div>

<section class="widget-div mt5">
  <div class="fl widget cp" style="width: 270px;">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1"><span class="cnt">{{search.year}}년 {{search.month}}월</span> 채널별 매출실적</p>
    </div>
    <div class="cb"></div>
  </div>
  <div class="cb"></div>
</section>

<div style="height: 150px;">
  <ag-grid-angular
    #myGrid

    style="width: 100%; height:100%;"
    class="ag-theme-balham"

    [columnDefs]="channelColumnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="channelMonthList"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (gridReady)="onChannelGridReady($event)"
    >
  </ag-grid-angular>
</div>