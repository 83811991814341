import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';

@Component({
  selector: 'app-basic-delivery-add',
  templateUrl: './basic-delivery-add.component.html',
  styleUrls: ['./basic-delivery-add.component.scss']
})
export class BasicDeliveryAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public item: any;
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    폼 빌드
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      address:["", [Validators.required, Validators.maxLength(255)] ],
      add_delivery_amt:["", [] ],
      zipcode:["", [Validators.maxLength(5)] ],
      sido:["", []],
      sigungu:["", [] ],
      eupmyeondong:["", [] ]
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public orderService: OrderService,
  ) { 
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 데이터가 넘어온 경우 데이터 설정
    if( this.item.seq !== 0 ) {
      this.formAdd.patchValue( this.item );
    }
  }

  ngAfterContentInit() {
    if( this.item.seq == 0 )
      this.title = '추가';
    else
      this.title = '수정';
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.orderService.setShipDeliverySave(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 삭제하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("선택하신 도선료 정보를 삭제하시겠습니까?") ) {
      this.orderService.setShipDeliveryDelete(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

}
