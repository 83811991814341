/*******************************************************************************
  설  명 : 게시판 내용 보기
  작성일 : 2019-10-10
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { BoardService } from '@app/service/board.service';
import { ViewUpdateComponent } from '@page/board/board/view/view-update/view-update.component';

import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};
@Component({
  selector: 'app-board-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ProductViewComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public boardData: any = {};
  public memberInfo: any = {};

  public form: FormGroup;
  public formErrors = {
    contents: ''
  };

  public params: any = {
    seq: '',
    status: '',
    group: 'comment',
    text: '',
    row: 15,
    no: 1,
  };

  public baseURL = config.baseUrl;

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: [ '0', [Validators.required] ],
      name: [ '', [Validators.required] ],
      comment: [ '', [Validators.required] ],
      product_name: [ '', [Validators.required] ],
      product_seq: [ '', [Validators.required] ],
      answer: ['', [Validators.required] ],
      question_date: [ '', [Validators.required] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private utilService: UtilService
  ) {
    this.buildForm();

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;
      this.params.status = ( typeof params.status == 'undefined' || params.status ) ? '' : params.status;

      if( this.params.seq == '' ) {
        this.toastrService.error( '게시물이 존재하지 않습니다.', '게시판' );

        this.router.navigate(
        ['/board/productQna'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

        return false;
      }

    }).unsubscribe();

    this.getProductQnaDetail();

  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  list() {
    this.router.navigate(
    ['/board/productQna'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 삭제 가능 여부 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authCheck() {
    // 관리자 권한일 경우 삭제 가능
    if( this.memberInfo.level >= 80 ) {
      return true;
    }

    // 작성자의 경우
    if( this.memberInfo.mem_no == this.boardData.writer ) return true;

    return false;
  }

  /*******************************************************************************
    설  명 : 상품문의 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductQnaDetail() {
    this.boardService.getProductQnaDetail( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.boardInfo = response.data;
        this.form.patchValue( this.boardInfo );

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시글 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("게시글을 삭제하시겠습니까?") ) {
      this.boardService.deleteProductQna( this.params.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '게시글 삭제' );

          this.router.navigate(
          ['/board/productQna'],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.params,
            queryParamsHandling: '', // remove to replace all query params by provided
          });

        } else {
          this.toastrService.error( response.ResultMessage, '게시글 삭제' );
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 글 답변 쓰기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  reply() {
    this.router.navigate(
    ['/board/productReply'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }


  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getSafeHtml( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
