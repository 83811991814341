  <div class="modal-header">
    <h4 class="modal-title fb mb0">지각추가</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <form>

    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="group_cd" class="control-label">사원명</label></th>
      <td>
        <select class="form-control form-control-small">
          <option>부서선택</option>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="code" class="control-label">기간</label></th>
      <td>
        <div class="form-inline">
		      <input type="text" readonly="readonly" id="sdate" size="12" class="form-control form-control-small calendar-input ml5" placeholder="2021-02-01" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
		      <ng-template #footerTemplate1>
		        <hr class="my-0">
		        <button type="button" class="btn btn-primary btn-small m-2 float-left">오늘</button>
		        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
		      </ng-template>
		      <span class="ml5 mr5">~</span>
		      <input type="text" readonly="readonly" id="edate" size="12" class="form-control form-control-small calendar-input m15" placeholder="2021-02-01" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
		      <ng-template #footerTemplate2>
		        <hr class="my-0">
		        <button type="button" class="btn btn-primary btn-small m-2 float-left">오늘</button>
		        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
		      </ng-template>
	      </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="name" class="control-label">사유</label></th>
      <td>
        <input type="text" name="name"  class="form-control form-control-small">
      </td>
    </tr>
    </tbody>
    </table>

    </form>


  </div>

  <div class="modal-footer">
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small mr5">저장하기</button>
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
  </div>
