<form [formGroup]="form">
  <header class="mt5 mb5">
    <div class="fl w50p">
      <h4 class="fb">이니시스 정보</h4>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="submit()">저장</button>
    </div>
    <div class="cb"></div>
  </header>  
  
  <section>
    <table class="table table-bordered table-small1 mb0">
    <caption class="none">이니시스 정보</caption>
    <colgroup>
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="payment_use_yn">전자결제 사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_use_yn.value=='1'}" (click)="form.patchValue({payment_use_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_use_yn.value=='0'}" (click)="form.patchValue({payment_use_yn: '0'})">사용안함</button>
        </div>
        <inputEx type="hidden" name="payment_use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="payment_id">이니시스 아이디</label></th>
      <td><inputEx type="text" name="payment_id" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="payment_pw">이니시스 비밀번호</label></th>
      <td><inputEx type="password" name="payment_pw" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="payment_card_yn">신용카드 사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_card_yn.value=='1'}" (click)="form.patchValue({payment_card_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_card_yn.value=='0'}" (click)="form.patchValue({payment_card_yn: '0'})">사용안함</button>
        </div>
        <inputEx type="hidden" name="payment_card_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="payment_bank_yn">실시간계좌이체 사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_bank_yn.value=='1'}" (click)="form.patchValue({payment_bank_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_bank_yn.value=='0'}" (click)="form.patchValue({payment_bank_yn: '0'})">사용안함</button>
        </div>
        <inputEx type="hidden" name="payment_bank_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="payment_phone_yn">휴대폰결제 사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_phone_yn.value=='1'}" (click)="form.patchValue({payment_phone_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_phone_yn.value=='0'}" (click)="form.patchValue({payment_phone_yn: '0'})">사용안함</button>
        </div>
        <inputEx type="hidden" name="payment_phone_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="payment_hashkey">해시키</label></th>
      <td><inputEx type="text" name="payment_hashkey" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="payment_return_url">리턴 URL</label></th>
      <td colspan="3"><inputEx type="text" name="payment_return_url" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    </tbody>
    </table>
  </section>

  <header class="mt10">
    <h4 class="fb">간편결제(네이버페이)</h4>
  </header>

  <section>
    <table class="table table-bordered table-small1 mb0">
    <caption class="none">간편결제(네이버페이)</caption>
    <colgroup>
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="payment_naver_useyn">사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_naver_useyn.value=='1'}" (click)="form.patchValue({payment_naver_useyn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_naver_useyn.value=='2'}" (click)="form.patchValue({payment_naver_useyn: '2'})">테스트</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.payment_naver_useyn.value=='0'}" (click)="form.patchValue({payment_naver_useyn: '0'})">사용안함</button>
        </div>  

        <inputEx type="hidden" name="payment_naver_useyn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="payment_naver_id">상점ID</label></th>
      <td><inputEx type="text" name="payment_naver_id" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
      <th scope="row"><label for="payment_naver_certkey">상점인증키</label></th>
      <td><inputEx type="text" name="payment_naver_certkey" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="payment_naver_btnkey">버튼인증키</label></th>
      <td><inputEx type="text" name="payment_naver_btnkey" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
      <th></th>
      <td></td>
      <th></th>
      <td></td>
    </tr>
    </tbody>
    </table>  
  </section>
  <header class="mt10">
    <h4 class="fb">현금영수증 자진발급 한도금액</h4>
  </header>
  <section>
    <table class="table table-bordered table-small1 mb0">
    <caption class="none">현금영수증 자진발급 한도금액</caption>
    <colgroup>
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="cash_auto_limit">한도금액</label></th>
      <td>
        <inputEx type="text" name="cash_auto_limit" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx>
      </td>
      <th></th>
      <td></td>
      <th></th>
      <td></td>
    </tr>
    </tbody>
    </table>  
  </section>


</form>