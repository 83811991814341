import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { StockOrder } from '@app/service/stockOrder.service';
import { AuthService } from '@app/service/auth.service';

@Component({
  selector: 'app-modal-purchase-complete',
  templateUrl: './modal-purchase-complete.component.html',
  styleUrls: ['./modal-purchase-complete.component.scss']
})
export class ModalPurchaseCompleteComponent implements OnInit {

  @Input() data
  @Input() getToday
  @Input() dateFormat
  date
  memo_enable

  constructor(
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private stockOrder: StockOrder,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.date = this.getToday()
  }

  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(value) {
    const _V = []
    for(const key in value) _V.push(value[key])
    this.date = this.dateFormat( new Date( _V.join('-') ) )
  }

  boxChange($event) {
    this.memo_enable = $event.target.value == 'on' ? true : false
  }

  /*******************************************************************************
    설  명 : 저장하기
  *******************************************************************************/
  submit() {
    this.authService.getLoginInfo.subscribe(e => {

    this.stockOrder.updatePurchaseComplete({
      mem_no : e['mem_no'],
      date : this.date,
      _list : this.data.map(e => ({
        purchase_seq : e['purchase_seq'],
        order_seq : e['title'].split(' ')[0] == '주문번호' ? e['title'].split(' ')[1] : ''
      })),
      memo_enable : this.memo_enable
    }).then(response => {
      if(response.ResultCode) {
        this.toastrService.success(response.ResultMessage)
        this.activeModal.close(true)
      } else this.toastrService.error(response.ResultMessage)
    })

    }).unsubscribe()
  }

  /*******************************************************************************
    설  명 : 날짜 삭제
  *******************************************************************************/
  delete() {
    this.date = ''
  }

}
