<div class="mt5 mb5">
  <div class="fl w70p form-inline">

  </div>
  <div class="fr w30p right">
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="c3e29b0eeeb84564aaaaba3c64b980a9" [btnGroup]="1"></btn-ag-grid-save>
  </div>
  <div class="cb"></div>
</div>

<div style="height:calc(100% - 40px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="eventStatisticList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    (filterChanged)="onFilterChanged($event)"
    >
  </ag-grid-angular>
</div>