<section id="customer">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="65" minSize="50" maxSize="70">
      <div class="mt5 mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>

          <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="문제, 정답 검색" />

          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getEventQuizList()">
            <i class="material-icons-outlined">search</i>
          </button>
        </div>
        <div class="fr w30p right">
          <ag-grid-excel [gridApi]="gridApi" selected="false" filename="초성퀴즈목록" title="초성퀴즈목록"></ag-grid-excel>
          <button type="button" class="btn btn-success btn-small ml10" (click)="setEventQuiz(true)">수정하기</button>
          <button type="button" class="btn btn-primary btn-small ml10" (click)="setEventQuiz(false)">등록하기</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 50px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="eventQuizList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event)"
          (rowClicked)="onRowClicked($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="35" minSize="30" maxSize="50">
      <div class="mt5 mb5">
        <div class="fl w70p form-inline">
          <input type="text" id="quickFilter" size="50" (input)="onQuickFilterChanged($event)" class="form-control form-control-small" placeholder="그리드 내 모든 필드 검색 필터링" />
        </div>
        <div class="fr w30p right">
          <ag-grid-excel [gridApi]="gridApiAnswer" selected="false" filename="정답자목록" title="정답자목록"></ag-grid-excel>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 50px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsAnswer"
          [defaultColDef]="defaultColDef"
          [rowData]="eventQuizAnswerList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReadyAnswer($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
