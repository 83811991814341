<section id="member-company">

  <div class="mb5">
    <div class="fl w50p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click) = "_getWorker()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
        <i class="material-icons-outlined">search</i>
      </button>

      <div class="form-inline">
	      <button type="button" title="이전달" class="btn btn-default btn-small-icon ml5" (click)="prevMonth()">
	        <i class="material-icons">keyboard_arrow_left</i>
	      </button>

        <span class="f14 bold pl10 pr10">{{calendarInfo.year}}년 {{calendarInfo.month}}월</span>

        <button type="button" title="다음달" class="btn btn-default btn-small-icon" (click)="nextMonth()">
          <i class="material-icons">keyboard_arrow_right</i>
        </button>

        <button class="btn btn-default btn-small ml5" (click)="currMonth()">이번달</button>
      </div>

    </div>
    <div class="fr">
      <button type="button" class="btn btn-success btn-small" (click)="printWorkArragement()">월당직근무표 출력</button>
      <!-- <button type="button" class="btn btn-primary btn-small ml5" (click)="arragementAdd()">근무자 추가</button> -->
    </div>
    <div class="cb"></div>
  </div>

  <div style="height: calc(100vh - 130px);">

	  <table class="table table-bordered calendar">
	  <caption class="sr-only">일정달력</caption>
	
	  <colgroup>
	    <col style="width:14%" />
	    <col style="width:14%" />
	    <col style="width:14%" />
	    <col style="width:14%" />
	    <col style="width:14%" />
	    <col style="width:14%" />
	    <col style="width:14%" />
	  </colgroup>
	
	  <thead>
	  <tr>
	    <th scope="col"><span class="cred">Sun</span></th>
	    <th scope="col">Mon</th>
	    <th scope="col">Tue</th>
	    <th scope="col">Wed</th>
	    <th scope="col">Thu</th>
	    <th scope="col">Fri</th>
	    <th scope="col"><span class="cblue">Sat</span></th>
	  </tr>
	  </thead>
	
	  <tbody>
	  <tr *ngFor="let item of range(6);let i1 = index">
	    <td *ngFor="let subitem of range(7);let i2 = index;">
				<button type="button" class="btn btn-small ml5" style="position: absolute; z-index: 1; font-size: 11px !important; margin-top: 5px; background-color: #eee;" (click)="_postWorker(getYMD(i1, i2))">추가</button>
	      <div class="day" [ngClass]="{sunday:i2==0, saturday:i2==6, prevMonth:i1==0}">
	        <span>{{getCurrDay(i1, i2)}}</span>
          <!-- <p class="f12">홍길동 <i class="material-icons-outlined fr">clear</i></p>
          <p class="f12">박길동 <i class="material-icons-outlined fr">clear</i></p> 	 -->
					<p *ngFor = "let item of _getDayWorker(i1, i2)" class="f12">{{item.name}} 
						<i class="material-icons-outlined fr" (click) = "_deleteWorker(item.seq)">clear</i> 
						<i class="fr mr5" style="background-color: limegreen; font-style: normal; font-size: 10px; padding: 0 5px;" (click) = "_putWorker(item.seq)">교체</i>
					</p>
	      </div>
	    </td>
	  </tr>
	  </tbody>
	  </table>

  </div>


</section>

