<form [formGroup]="form" (ngSubmit)="submit()">
  <inputEx type="hidden" name="group_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-group-add">공통코드그룹 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="code" class="control-label">그룹코드</label></th>
      <td>
        <inputEx type="text" readonly="true" name="code" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="name" class="control-label">그룹코드명</label></th>
      <td>
        <inputEx type="text" readonly="true" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="common_code" class="control-label">공통코드</label></th>
      <td>
        <inputEx type="text" name="common_code" [formGroup]="form" [formErrors]="formErrors" placeholder="숫자 4자리 입력" autofocus="true"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="common_name" class="control-label">공통코드(국문)</label></th>
      <td>
        <inputEx type="text" name="common_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="common_ename" class="control-label">공통코드(영문)</label></th>
      <td>
        <inputEx type="text" name="common_ename" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr *ngIf="selectedItem.type == 'DLC'">
      <th scope="row"><label for="c1" class="control-label">사방넷코드</label></th>
      <td>
        <inputEx type="text" name="c1" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr *ngIf="selectedItem.type == 'SHP'">
      <th scope="row"><label for="c2" class="control-label">사방넷코드</label></th>
      <td>
        <inputEx type="text" name="c2" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr *ngIf="selectedItem.type == 'SHP'">
      <th scope="row"><label for="c3" class="control-label">오픈마켓여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.c3.value==1}" (click)="form.patchValue({c3:1})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.c3.value==0}" (click)="form.patchValue({c3:0})">사용안함</button>
        </div>
        <inputEx type="hidden" name="use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="use_yn" class="control-label">사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.use_yn.value==1}" (click)="form.patchValue({use_yn:1})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.use_yn.value==0}" (click)="form.patchValue({use_yn:0})">사용안함</button>
        </div>
        <inputEx type="hidden" name="use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <!-- <ng-container *ngIf="authService.currAuth['auth_delete']=='1'"> -->
      <ng-container>
        <button *ngIf="form.controls.seq.value!==0" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
      </ng-container>
    </div>
    <div class="fr w50p right ml0 mr0">
      <!-- <ng-container *ngIf="(form.controls.seq.value!=0 && form.controls.seq.value!='') && authService.currAuth['auth_modify']=='1'"> -->
      <ng-container *ngIf="(form.controls.seq.value!=0 && form.controls.seq.value!='')">
        <button type="submit" class="btn btn-primary btn-small f15">저장하기</button>
      </ng-container>
      <!-- <ng-container *ngIf="(form.controls.seq.value==0 || form.controls.seq.value=='') && authService.currAuth['auth_write']=='1'"> -->
      <ng-container *ngIf="(form.controls.seq.value==0 || form.controls.seq.value=='')">
        <button type="submit" class="btn btn-primary btn-small f15">저장하기</button>
      </ng-container>
      <button type="button" class="btn btn-default btn-small ml5 f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
