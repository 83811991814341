import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  public form: FormGroup;
  public formErrors: any = {};

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  buildForm(): void {
    this.form = this.formBuilder.group({
      mid: ['', [Validators.maxLength(45)]],
      sign_key: ['', [Validators.maxLength(45)]],
      consultant_id: ["", [Validators.maxLength(45)] ],
      consultant_send: ['0', [Validators.maxLength(45)] ],
      charge_id: ["", [Validators.maxLength(45)] ],
      charge_send: ['0', [Validators.maxLength(45)]],
      reply_sms: ['0', [Validators.maxLength(45)]],
      company_member_count:["0", [Validators.maxLength(45)] ],
      dormant_member_day:["356", [Validators.maxLength(45)] ],
      dormant_member_expire_day:["30", [Validators.maxLength(45)] ],
      dormant_member_send:["0", [Validators.maxLength(45)] ],
      dormant_member_expire_send:["0", [Validators.maxLength(45)] ],
    });

    // this.form.valueChanges.subscribe(data => {
    //   this.utilService.updateFormErrors( this.form, this.formErrors );
    // });
  };


  ngOnInit(): void {
  }

}
