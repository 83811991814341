import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { EventService } from '@app/service/event.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    status: '',
    sdate: '',
    edate: '',
    eventName: '',
    eventSeq: '',
    eventType: ''
  };

  public title: string = '';

  public eventInfo: any = {};

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private eventService: EventService,
  ) {
    this.activatedRoute.queryParams.subscribe( params => {
      this.search = {
        status: params.status,
        sdate: params.sdate,
        edate: params.edate,
        eventName: params.eventName,
        eventSeq: (params.eventSeq === undefined) ? '' : params.eventSeq,
        eventType: (params.eventType === undefined) ? '' : params.eventType
      }

      switch( this.search.eventType ) {
        case '1000':
          this.title = '슈퍼세일';
          break;
        case '1001':
          this.title = '보상세일';
          break;
        case '2000':
          this.title = '이벤트';
          break;
        case '3000':
          this.title = '쿠폰이벤트';
          break;
        default:
          this.title = '';
      }
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    if( this.search.eventSeq !== '' ) this.getEventDetail();
  }

  /*******************************************************************************
    설  명 : 이벤트 상세 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventDetail() {
    this.eventService.getEventDetail(this.search.eventSeq).then( response => {
      if (response.ResultCode) {
        this.eventInfo = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 목록으로
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/basic/event'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
}
