import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private restful: RestfulService,
  ) {
  }

  getSchedule( searchText ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'inventory',
      version: '1.0',
      action: 'getSchedule'
    }, {searchText : searchText}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  putSchedule( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'inventory',
      version: '1.0',
      action: 'putSchedule'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  postSchedule( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'inventory',
      version: '1.0',
      action: 'postSchedule'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  deleteSchedule( seq ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'inventory',
      version: '1.0',
      action: 'deleteSchedule'
    }, {seq : seq}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  
  getInventoryData( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'inventory',
      version: '1.0',
      action: 'getInventoryData'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  putInventoryData( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'inventory',
      version: '1.0',
      action: 'putInventoryData'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  postInventoryData( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'inventory',
      version: '1.0',
      action: 'postInventoryData'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  deleteInventoryData( seq ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'inventory',
      version: '1.0',
      action: 'deleteInventoryData'
    }, {seq : seq}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  
}
