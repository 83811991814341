/*******************************************************************************
  설  명 : 상품별 || 매장별 판매현황
  작성일 : 2021-08-19
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { StatisticService } from '@app/service/statistic.service';
import { Router, ActivatedRoute } from '@angular/router';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import * as moment from 'moment';
import { ExportExcelComponent } from '@app/components/export-excel/export-excel.component';

@Component({
  selector: 'app-statistic-sales',
  templateUrl: './statistic-sales.component.html',
  styleUrls: ['./statistic-sales.component.scss']
})
export class StatisticSalesComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 99999999,
    storeSeq: '',
    sdate: this.utilService.getDate(moment().subtract(90, 'day')),
    edate: this.utilService.getDate(''),
    searchField: 'product_name',
    searchText: '',
    searchTerm: this.getDays(90),
    group: 'product',
    category_select: '',
    brand_select: '',
    sort_order: 'amt'
  };

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
    {title: '150일', value: this.getDays(150)},
    {title: '200일', value: this.getDays(200)},
    {title: '365일', value: this.getDays(365)},
  ];

  public storeList: any = [];
  public salesStatusList: any = [];
  public selectCategoryList: any = [];
  public selectBrandList: any = [];

  public activeTab: any = 0;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  productColumnDefs: any;
  optionColumnDefs: any;
  brandColumnDefs: any;
  rowSelection: any;
  defaultColDef: any;
  domLayout: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public totalCount: number = 0;

  pinnedBottomRowData = []
  public orderMasterDiscountAmount = 0

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private basicService: BasicService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private statisticService: StatisticService,
    private exportExcelComponent: ExportExcelComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,

  ) {
    // ag grid 컬럼 선언
    this.productColumnDefs = [
      { headerName: '순위', field: 'rowIndex', width: 80, cellClass: 'cp center', valueGetter: 'node.rowIndex + 1' },
      // { headerName: '매장', field: 'store_name', width: 100, cellClass: 'cp center' },
      { headerName: '카테고리', field: 'category_name', width: 150, cellClass: 'cp left' },
      { headerName: '브랜드', field: 'brand_name', width: 150, cellClass: 'cp left' },
      { headerName: '상품번호', field: 'product_seq', width: 100, cellClass: 'cp center' },
      { headerName: '상품명', field: 'product_name', width: 500, cellClass: 'cp left' },
      { headerName: '입고수량', field: 'input_qty', width: 80, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '재고수량', field: 'stock_qty', width: 80, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '판매수량', field: 'qty', width: 80, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '판매금액', field: 'amt', width: 130, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '구매금액', field: 'buy_price', width: 130, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '마진', field: '', width: 130, cellClass: 'cp right', valueFormatter: currencyFormatter, valueGetter: params => params.data.amt - params.data.buy_price },
      { headerName: '마진율', field: '', width: 110, cellClass: 'cp right', valueGetter: params => this.getMarginRate(params.data) },
    ];

    this.optionColumnDefs = [
      { headerName: '순위', field: 'rowIndex', width: 80, cellClass: 'cp center', valueGetter: 'node.rowIndex + 1' },
      // { headerName: '매장', field: 'store_name', width: 100, cellClass: 'cp center' },
      { headerName: '카테고리', field: 'category_name', width: 150, cellClass: 'cp left' },
      { headerName: '브랜드', field: 'brand_name', width: 150, cellClass: 'cp left' },
      { headerName: '상품명', field: 'product_name', width: 425, cellClass: 'cp left' },
      { headerName: 'color', field: 'display_color_name', width: 130, cellClass: 'cp left' },
      { headerName: 'size', field: 'display_size_name', width: 130, cellClass: 'cp left' },
      { headerName: '판매수량', field: 'qty', width: 80, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '판매금액', field: 'amt', width: 120, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '구매금액', field: 'buy_price', width: 120, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '마진', field: '', width: 120, cellClass: 'cp right', valueFormatter: currencyFormatter, valueGetter: params => params.data.amt - params.data.buy_price },
      { headerName: '마진율', field: '', width: 110, cellClass: 'cp right', valueGetter: params => this.getMarginRate(params.data) },
    ];

    this.brandColumnDefs = [
      { headerName: '순위', field: 'rowIndex', width: 80, cellClass: 'cp center', valueGetter: 'node.rowIndex + 1' },
      // { headerName: '매장', field: 'store_name', width: 100, cellClass: 'cp center' },
      { headerName: '브랜드', field: 'brand_name', width: 300, cellClass: 'cp left' },
      { headerName: '판매수량', field: 'qty', width: 200, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '판매금액', field: 'amt', width: 200, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '구매금액', field: 'buy_price', width: 200, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '마진', field: '', width: 200, cellClass: 'cp right', valueFormatter: currencyFormatter, valueGetter: params => params.data.amt - params.data.buy_price },
      { headerName: '마진율', field: '', width: 150, cellClass: 'cp right', valueGetter: params => this.getMarginRate(params.data) },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getStoreList();
    this.getCategoryList();
    this.getBrandList();
    this.getSalesStatusList();
  }

  /*******************************************************************************
    설  명 : 매장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getStoreList() {
    await this.basicService.getStoreList({ store_gbn: 'D' }).then( response => {
      if( response.ResultCode ) {
        this.storeList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 판매 현황 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSalesStatusList() {
    this.statisticService.getSalesStatusList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.salesStatusList = response.data;
        this.totalCount = response.count;

        this.getGridSum(response.data)
        this.getOrderMasterDiscountAmount()
      }
    });
  }


  /*******************************************************************************
    설  명 : category 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      this.selectCategoryList = [];
      if (response.ResultCode) {
        this.selectCategoryList.push({id: '0', text: '카테고리 선택'});

        response.data.forEach( row => {
          this.selectCategoryList.push({
            id: row.seq,
            text: row.category_name,
            code: row.category_code
          });
        });
      } else {
        this.selectCategoryList = [];
      }
    });
  };

  /*******************************************************************************
    설  명 : select 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      this.selectBrandList = [];
      if( response.ResultCode ) {
        this.selectBrandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.selectBrandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.selectBrandList = [];
      }
    });
  };

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check ) {
    if( check == 'sdate' ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'edate')  {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }

    // this.getcreditList();
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onTabChange(value: any) {
    this.activeTab = value;
    if ( value == 0 ) {
      this.search.group = 'product';
      this.getSalesStatusList();
    } else if ( value == 1 ) {
      this.search.group = 'option';
      this.getSalesStatusList();
    } else if ( value == 2 ) {
      this.search.group = 'brand';
      this.getSalesStatusList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 1000,
      storeSeq: '',
      sdate: this.utilService.getDate(moment().subtract(90, 'day')),
      edate: this.utilService.getDate(''),
      searchField: 'product_name',
      searchText: '',
      searchTerm: this.getDays(90),
      group: 'product',
      category_select: '',
      brand_select: '',
      sort_order: 'amt'
    };

    // 90일 검색 디폴트
    this.onSearchClick(-90);

    this.getSalesStatusList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getSalesStatusList();
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 카테고리 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectCategoryChanged( event: any ) {
    if( event.id != '0' ) {
      this.search.category_select = event;
    } else {
      this.search.category_select = '';
    }

    this.getSalesStatusList();
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectBrandChanged( event: any ) {
    if( event.id != '0' ) {
      this.search.brand_select = event;
    } else {
      this.search.brand_select = '';
    }

    this.getSalesStatusList();
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;
    this.getSalesStatusList();
  }

  /*******************************************************************************
    설  명 : 정렬기준 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  sortChanged( event: any ) {
    this.search.sort_order = event;
    this.getSalesStatusList();
  }

  excelDownload() {
        const data = this.activeTab == 0 ? this.salesStatusList.map((e,i) => ({
          순위: i+1,
          카테고리: e.category_name,
          브랜드: e.brand_name,
          상품번호: e.product_seq,
          상품명: e.product_name,
          입고수량: this.stringToNumber(e.input_qty || 0),
          재고수량: this.stringToNumber(e.stock_qty || 0),
          판매수량: this.stringToNumber(e.qty || 0),
          판매금액: this.stringToNumber(e.amt || 0),
          구매금액: this.stringToNumber(e.buy_price || 0),
          마진: this.getMargin(e),
          마진율: this.getMarginRate(e)
        })) : this.activeTab == 1 ? this.salesStatusList.map((e,i) => ({
          순위: i+1,
          카테고리: e.category_name,
          브랜드: e.brand_name,
          상품명: e.product_name,
          색상: e.display_color_name,
          사이즈: e.display_size_name,
          판매수량: this.stringToNumber(e.qty || 0),
          판매금액: this.stringToNumber(e.amt || 0),
          구매금액: this.stringToNumber(e.buy_price || 0),
          마진: this.getMargin(e),
          마진율: this.getMarginRate(e)
        })) : this.salesStatusList.map((e,i) => ({
          순위: i+1,
          브랜드: e.brand_name,
          판매수량: this.stringToNumber(e.qty || 0),
          판매금액: this.stringToNumber(e.amt || 0),
          구매금액: this.stringToNumber(e.buy_price || 0),
          마진: this.getMargin(e),
          마진율: this.getMarginRate(e)
        }))

        this.exportExcelComponent.exportAsExcelFileWithWidth(
          data, '매장별상품별판매현황', 'sheet1',
          (this.activeTab == 0 ? this.productColumnDefs : this.activeTab == 1 ? this.optionColumnDefs : this.brandColumnDefs).map(e => e.width),
          {title: '매장별/상품별 판매현황', space: true}
        )
  }

  moveSimplePage() {
    this.router.navigate(['/statistic/simple'], {
      relativeTo: this.activatedRoute,
      queryParams: { type : 4 },
    })
  }

  /*******************************************************************************
    설  명 : 마진, 마진율, 각각의 합계를 구한다.
             엑셀 숫자 서식이 필요한 판매수량, 판매금액, 구매금액은 데이터타입을 숫자로 해야함을 유의할 것. -서기정
  *******************************************************************************/
  stringToNumber = (data: any) => parseInt(data)
  getMargin = (data: any) => data.amt - data.buy_price
  getMarginRate = (data: any) => `${parseInt(data.buy_price) ? ((1 - (data.buy_price / data.amt)) * 100).toFixed(2) : 0} %`
  getGridSum = (data: any) => this.pinnedBottomRowData = [{
    input_qty: data.reduce((a: any, c: any) => a + parseInt(c.input_qty), 0),
    stock_qty: data.reduce((a: any, c: any) => a + parseInt(c.stock_qty), 0),
    qty: data.reduce((a: any, c: any) => a + parseInt(c.qty), 0),
    amt: data.reduce((a: any, c: any) => a + parseInt(c.amt), 0),
    buy_price: data.reduce((a: any, c: any) => a + parseInt(c.buy_price), 0),
  }]

  getOrderMasterDiscountAmount() {
    this.statisticService.getOrderMasterDiscountAmount(this.search).then(res => {
      this.orderMasterDiscountAmount = res.ResultCode ? res.data : 0
    })
  }
}
