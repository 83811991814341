import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-returnreq-detail-img',
  templateUrl: './order-returnreq-detail-img.component.html',
  styleUrls: ['./order-returnreq-detail-img.component.scss']
})
export class OrderReturnreqDetailImgComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() imageData:any ;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
