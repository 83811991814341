import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private restful: RestfulService,
  ) {
  }

  
  // 정산/회계관리 - 반품내역조회
  getReturnProduct( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getReturnProduct'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 입점업체정산 - 업체목록 가져오기
  getEnterList( params ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getEnterList'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 입점업체정산 - 입점업체정산내역 가져오기
  getEnterAccountList( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getEnterAccountList'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 정산/회계관리 - 회계정보조회/출력 : 월별쇼핑몰매출현황
  getMonthSales( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getMonthSales'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 정산/회계관리 - 회계정보조회/출력 : 일별쇼핑몰매출현황
  getDaySales( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getDaySales'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 정산/회계관리 - 회계정보조회/출력 : 월별업체정산서
  getMonthEnterSales( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getMonthEnterSales'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 정산/회계관리 - 오픈마켓정산검증
  getAccountOpenmarketList( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getAccountOpenmarketList'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 정산/회계관리 - 오픈마켓정산검증 엑셀업로드
  uploadAccountOpenmarketList( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'uploadAccountOpenmarketList'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 쿠폰실적정산 - 쿠폰목록 가져오기
  getAccountCouponCodeList(search: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getAccountCouponCodeList'
    }, search
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 쿠폰실적정산 - 쿠폰 적용 주문목록 가져오기
  getAccountCouponOrderList(search: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'getAccountCouponOrderList'
    }, search
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 쿠폰실적정산 - 정산 처리
  setAccountCouponOrderCalc( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'setAccountCouponOrderCalc'
    }, form.value
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 쿠폰실적정산 - 정산 취소
  setAccountCouponCalcCancel( seq: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'setAccountCouponCalcCancel'
    }, {
      seq: seq
    }
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 입점업체정산 메모 저장
  setEnterAccountMemo( params: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'account',
      version: '1.0',
      action: 'setEnterAccountMemo'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
}
