/*******************************************************************************
  설  명 : 상품관리 - 메이커별 기준할인율 관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { BrandService } from '@app/service/brand.service';

import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-brand-base-discount',
  templateUrl: './brand-base-discount.component.html',
  styleUrls: ['./brand-base-discount.component.scss']
})
export class BrandBaseDiscountComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  // 그리드 관련 선언
  brandList: any = [];

  search: any = {
    searchText: '',
    use_yn: '1',
    purchasing_agent_yn: ''
  };

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;
  components: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private agGridExComponent: AgGridExComponent,
    private toastrService: ToastrService,
    private brandService: BrandService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', width: 100, cellClass: 'cp left',
        //headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '사용여부', field: '', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1') return '<span class="badge badge-success f12 fn">사용</span>';
          else return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      },
      { headerName: '구매대행 브랜드 여부', field: '', width: 200, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.purchasing_agent_yn == '1') return '<span class="badge badge-primary f12 fn">구매대행</span>';
          else return '<span class="badge badge-secondary f12 fn">대행아님</span>';
        }
      },
      { headerName: '브랜드(국문)', field: 'brand_name', width: 300, cellClass: 'cp' },
      { headerName: '브랜드(영문)', field: 'brand_ename', width: 300, cellClass: 'cp' },
      { headerName: '할인율(%)', field: 'discount_rate', width: 150, cellClass: 'cp right ag-cell-edit', editable: true,
        cellEditor: 'numericCellEditor',
        cellStyle: params => parseInt(params.value) > 0 ? { color: 'red' } : null
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor()
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getBrandBaseDiscountList();
  }

  /*******************************************************************************
    설  명 : 브랜드별 기준할인율 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandBaseDiscountList() {
    this.brandService.getBrandBaseDiscountList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.brandList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드별 기준할인율 일괄 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBrandBaseDiscountSave() {
    const params = [];
    this.gridApi.forEachNode((rowNode, index) => {
      params.push({
        seq: rowNode.data.seq,
        brand_seq: rowNode.data.brand_seq,
        discount_rate: rowNode.data.discount_rate
      });
    });

    this.brandService.setBrandBaseDiscountSave( params ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');

        this.getBrandBaseDiscountList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      use_yn: '1',
      purchasing_agent_yn: ''
    };

    this.getBrandBaseDiscountList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getBrandBaseDiscountList();
    }
  }

}
