import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { CompanyService } from '@app/service/company.service';
import { CommonService } from '@app/service/common.service';
import { MemberService } from '@app/service/member.service';


import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { MemberSocietyHistoryAddComponent } from '@page/member/society/member-society-history/member-society-history-add/member-society-history-add.component';


const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-society-history',
  templateUrl: './member-society-history.component.html',
  styleUrls: ['./member-society-history.component.scss']
})
export class MemberSocietyHistoryComponent implements OnInit {


  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
  };

  public month: any = moment().format('MM');

  public typeList: any = [];
  public societyList: any = [];
  public historyList: any = [];
  public memoList: any = [];



  public societySelected: any = {};

  public selectedSeq: any = null;

  public statistic: any = {
    totalSale: 0,
    totalScore: 0,
    deductScore: 0,
    availableSore: 0
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridDetailApi: any;
  gridDetailColumnApi: any;
  columnDetailDefs: any;

  gridMemoApi: any;
  gridMemoColumnApi: any;
  columnMemoDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private companyService: CompanyService,
    private commonService: CommonService,
    private agGridExComponent: AgGridExComponent,
    private modalService: NgbModal,
    private memberService: MemberService  
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'rowIndex', width: 50, cellClass: 'cp center', 
      valueGetter: function(params) {
        let rowData = params.node.gridOptionsWrapper.gridOptions.rowData.length;
        let rowIndex = params.node.rowIndex;
        return parseInt(rowData) - rowIndex;
      }
      // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },      
//      { headerName: '순번', field: 'seq', width: 68, cellClass: 'cp center' },
      { headerName: '동호회명', field: 'society_name', width: 200, cellClass: 'cp left' },
      { headerName: '대표자', field: 'rep_name', width: 120, cellClass: 'cp left' },
    ];

    this.columnDetailDefs = [
   //   { headerName: '순번', field: 'seq', width: 50, cellClass: 'cp center' },
      { headerName: '순번', field: 'rowIndex', width: 50, cellClass: 'cp center', 
      valueGetter: function(params) {
        let rowData = params.node.gridOptionsWrapper.gridOptions.rowData.length;
        let rowIndex = params.node.rowIndex;
        return parseInt(rowData) - rowIndex;
      }
      // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },      
      { headerName: '구분', field: 'gbn_name', width: 100, cellClass: 'cp left' },
      { headerName: '형태', field: 'type_name', width: 110, cellClass: 'cp left' },
      { headerName: '사유', field: 'reason_name', width: 160, cellClass: 'cp left' },
      { headerName: '등록일자', field: 'reg_date', width: 100, cellClass: 'cp right' },
      { headerName: '회원번호', field: 'mem_no', width: 100, cellClass: 'cp center' },
      { headerName: '성명', field: 'member_name', width: 120, cellClass: 'cp left' },
      { headerName: '주문번호', field: 'order_seq', width: 110, cellClass: 'cp left' },
      { headerName: '매출금액', field: 'sale_amt', width: 110, cellClass: 'cp left' , valueFormatter: this.agGridExComponent.currencyFormatter},
      { headerName: '점수', field: 'margin_amt', width: 110, cellClass: 'cp left' , valueFormatter: this.agGridExComponent.currencyFormatter},
      { headerName: '등록자', field: 'writer_name', width: 110, cellClass: 'cp left'},
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true
    };

    this.rowSelection = 'single';
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  getRowHeight = () => 380;

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getSocietyHistoryWidget()
    this.getSocietyList();

    
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridDetailReady(params) {
    this.gridDetailApi = params.api;
    this.gridDetailColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridMemoReady(params) {
    this.gridMemoApi = params.api;
    this.gridMemoColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked(event: any) {
      this.getHistoryList(event.data.seq);
      this.getSocietyHistoryWidget();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
    };

    this.getSocietyList();
  }

  getSocietyList() {
    this.memberService.getSocietyList({...this.search}).then( response => {
      if( response.ResultCode ) {
        this.societyList = response.data;
        // 데이터가 있으면 첫번째 열의 디테일을 뿌린다. 
        if (this.societyList.length > 0 ) {

        // 리스트 첫번째 행 선택
          setTimeout( () => {
            var index = 0;
            this.gridApi.forEachNode( (node) => {
              if( this.selectedSeq == null && index == 0 ) {
                node.setSelected(true);
                this.selectedSeq = node.data.seq;
                this.gridApi.ensureIndexVisible( index, 'top' );

              } else if( this.selectedSeq !== null && node.data.seq == this.selectedSeq ) {
                node.setSelected(true);
                this.selectedSeq = node.data.seq;
                this.gridApi.ensureIndexVisible( index, 'middle' );
              }
              index++;
            });

            this.getHistoryList(this.societyList[0].seq); 
          }, 100);
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  getHistoryList(seq :any) {
      this.memberService.getSocietyHistoryList(seq).then( response => {
        if( response.ResultCode ) {
          this.historyList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getSocietyList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 조건 버튼 클릭 시
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  setSearchValue( field: any, value: any ) {
    this.search[ field ] = value;

    this.societySelected = {};

    this.getSocietyList();
  }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  delContact() {
    if( this.societySelected === undefined || this.societySelected.seq === undefined ) {
      this.toastrService.error('삭제할 내역을 선택하시기 바랍니다.', '삭제');
    } else {

      if( !confirm("선택한 내역을 삭제하시겠습니까?") ) return;

      let params: any = {
        seq: this.societySelected.seq
      };

      this.companyService.delContact(params).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.societySelected = {};

          this.getSocietyList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 셀 에디트 변경 시
  *******************************************************************************/
  onCellEditingStopped(event: any) {
    if( event.data.memo !== undefined ) {
      if( this.societySelected.memo != event.data.memo ) {
        this.companyService.setContactMemo( event.data ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.getSocietyList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 동호회이력 추가
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  addSocietyHistory(seq: any= null) {

    let nodes = this.gridApi.getSelectedRows();
    if( nodes.length < 1 ) {
      this.toastrService.info( '이력을 등록할 동호회를 선택해 주세요.', '');
    } else {
    const modalRef = this.modalService.open(MemberSocietyHistoryAddComponent, options);
    modalRef.componentInstance.societySeq = nodes[0].seq;
    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      if( result ) {
        this.getHistoryList(nodes[0].seq);

        this.getSocietyHistoryWidget();

      }
    }, (reason) => {
    });
    }
  }  

  /*******************************************************************************
    설  명 : 동호회 점수관리 위젯 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getSocietyHistoryWidget() {
      this.memberService.getSocietyHistoryWidget().then( response => {
        if( response.ResultCode ) {
          this.statistic.totalSale = response.data.totalSale;
          this.statistic.totalScore  = response.data.totalScore;
          this.statistic.deductScore  = response.data.deductScore;
          this.statistic.availableSore  = response.data.availableSore;
        } else {
          this.toastrService.error( response.ResultMessage, '');
          this.statistic.totalSale = "0";
          this.statistic.totalScore  = "0";
          this.statistic.deductScore  = "0";
          this.statistic.availableSore  = "0";          
        }
      }, error => {
        this.toastrService.error( error, '');
        this.statistic.totalSale = "0";
        this.statistic.totalScore  = "0";
        this.statistic.deductScore  = "0";
        this.statistic.availableSore  = "0";           
      });
    }  

/*******************************************************************************
    설  명 : ag grid 행 더블 클릭 시 처리 - 동호회 정보수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellDoubleClicked($event) {
        this.addSocietyHistory( $event.data.seq);
    }

}
