import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IPioneerTreeConfiguration, IPioneerTreeComponent } from '@pioneer-code/pioneer-tree';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit, OnChanges {
  @ViewChild ( 'pt', {static: false} ) ptComponent: IPioneerTreeComponent;
  @Input() useSort = false;
  @Input() useAddNode = false;
  @Input() nodeData: [
    {
      key: '',
      seq: '',
      name: '',
      use_yn: false,
      data: {},
      children: [],
    }
  ];
  @Input() picture = false;
  @Input() badge = false;

  @Output() onSelected = new EventEmitter<object>();
  @Output() onAddNode = new EventEmitter<object>();
  @Output() onDropNode = new EventEmitter<object>();

  public isSort = false;
  public isExpand = true;
  public selectedNode: any = {};
  public nodes: any = [];
  configuration = {
    childPropertyName: 'children',
    sortPropertyName: 'key',
    collapseAllOnLoad: false
  } as IPioneerTreeConfiguration;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor() { }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnChanges() {
    this.nodes = [];
    this.selectedNode = {};   // 수정필요 : 노드추가 후 selectnode 정보를 갱신해야함

    this.nodes = this.nodeData.filter(e => e['name'])
  }

  /*******************************************************************************
    설  명 : 트리 라인 갯수 배열로 리턴
    입력값 : node
    리턴값 : array
  *******************************************************************************/
  getTreeMiddleLine( node ) {
    if ( typeof node == 'undefined' || node == false ) {
      return [];
    } else {
      let maxlength = ( ( node.key.length - 3 ) / 3 );
      let tmp = [];
      for (let i = 0; i < maxlength; i++ ) {
        tmp.push(i);
      }

      return tmp;
    }
  }

  /*******************************************************************************
    설  명 : 상위 노드가 마지막일 경우 이전 라인을 표시 하지 않는다.
    입력값 : node
    리턴값 : true / false
  *******************************************************************************/
  checkParentLastNode( node, i ) {
    // 상위 노드가 없을 경우 그냥 표시
    if (typeof node.pioneerTreeNode.parentNode === 'undefined') {
      return false;

    } else {
      // 상위 노드 점검
      const tmp = this.getTreeMiddleLine( node );

      let rootNode = node;
      for( let index = i; index < tmp.length; index++ ) {
        rootNode = rootNode.pioneerTreeNode.parentNode;
      }

      if (typeof rootNode === 'undefined' || typeof rootNode.pioneerTreeNode === 'undefined') {
        return false;
      }

      let sortIndex = rootNode.pioneerTreeNode.sortIndex;
      sortIndex = sortIndex.length > 3 ? sortIndex.slice(-3) : sortIndex;

      if (rootNode.pioneerTreeNode.nodesInCollection == parseInt(sortIndex)) {
        return true;
      } else {
        return false;
      }
    }
  }

  /*******************************************************************************
    설  명 : 마지막 노드인지 체크한다.
    입력값 : node
    리턴값 : true / false
  *******************************************************************************/
  checkLastNode( node ) {
    let type = ( node.pioneerTreeNode.parentNode == undefined ) ? 'treeRootNodes' : 'parentNode';
    let obj = ( node.pioneerTreeNode.parentNode == undefined ) ? node.pioneerTreeNode.treeRootNodes : node.pioneerTreeNode.parentNode.children;

    const currentNode = node.pioneerTreeNode.currentNode;
    let index = 0;
    for( let item of obj ) {
      if( item.seq == currentNode.seq ) break;
      index++;
    }

    if( obj.length - 1 == index ) return true;
    else return false;
  }

  checkChildren(node) {
    if (node.children && node.children.length > 0) {
      return true;
    }
    return false;
  }

  /*******************************************************************************
    설  명 : 메뉴 노드 선택 시 처리
    입력값 : node = 현재 선택된 노드
    리턴값 : 없음
  *******************************************************************************/
  selectNode(node) {
    if( this.selectedNode.seq==node.seq ) {
      this.selectedNode = [];
      this.onSelected.emit(node);
    } else {
      this.selectedNode = node;
      this.onSelected.emit(node);
    }
  }

  /*******************************************************************************
    설  명 : 순서변경 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSort() {
    const dropzone = document.querySelector('div.pioneer-tree-dropzone');
    if( this.isSort ) {
      dropzone.classList.remove('on');
    } else {
      dropzone.classList.add('on');
    }

    this.isSort = ! this.isSort;
  }

  /*******************************************************************************
    설  명 : 노드 펼침/닫힘
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  expandTree() {
    return this.isExpand = !this.isExpand;
  }

  /*******************************************************************************
    설  명 : 노드 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addNode() {
    let key_cd = '';
    let check = false;
    let parent_seq = '';
    let parent_key_name = '';
    let index = 0;

    // 선택된 메뉴가 없을 경우
    if (this.nodeData.length < 1) {
      key_cd = '001';
    } else {
      if( typeof this.selectedNode.seq === 'undefined' ) {
        index = parseInt(this.nodes[this.nodes.length - 1].key) + 1;
        key_cd = index = this.lpad( index, 3, '0' );

      } else if( this.selectedNode.seq === '' ) {
        index = parseInt(this.nodes[this.nodes.length - 1].key) + 1;
        key_cd = index = this.lpad( index, 3, '0' );

      // 선택된 메뉴가 있을 경우
      } else {
        if( typeof this.selectedNode.children == 'undefined' ) {
          key_cd = this.selectedNode.key + '001';

        } else if( this.selectedNode.children.length < 1 ) {
          key_cd = this.selectedNode.key + '001';

        } else {
          index = parseInt( this.selectedNode.children[ this.selectedNode.children.length - 1 ].key.substr(-3) ) + 1;
          index = this.lpad( index, 3, '0' );
          key_cd = this.selectedNode.key + index;
        }

        check = true;
      }
    }

    // 상위 노드가 있을 경우
    if ( check ) {
      parent_seq = this.selectedNode.seq;
      parent_key_name = this.selectedNode.key_name;
    }

    const result = {
      parent_seq: parent_seq,
      parent_key_name: parent_key_name,
      key: key_cd,
      selectedNode: this.selectedNode,
    };
    this.onAddNode.emit(result);
  }

  findNode(nodes, key) {
    const node = nodes.find( e => e.key === key);
    if (typeof node === 'undefined' && typeof nodes.children !== 'undefined') {
      this.findNode(nodes.children, key);
    } else {
      return node;
    }
  }

  onNodeDropped($event: any): void {
    const parentNode = $event.pioneerTreeNode.treeDropService.currentDragNode.pioneerTreeNode.parentNode;
    const currentNode = $event.pioneerTreeNode.treeDropService.currentDragNode;
    const currentNodeKey = currentNode.key.slice(0, -3);
    const prevParentNode = this.findNode(this.ptComponent['nodes'], currentNodeKey);
    const orderNode = [];

    // 현재 노드 Sort Key 생성
    let nodeKey = currentNode.pioneerTreeNode.sortIndex + 1;
    if (typeof parentNode.key === 'undefined') {
      nodeKey = this.lpad( nodeKey, 3, '0' );
    } else {
      nodeKey = parentNode.key + this.lpad( nodeKey, 3, '0' );
    }
    orderNode.push({
      seq: currentNode.seq,
      key: nodeKey,
      name: currentNode.name,
    });

    // 현재 노드 children Sort Key 생성
    let index = 1;
    if ( typeof currentNode.children !== 'undefined' && currentNode.children.length > 0 ) {
      for (const node of currentNode.children) {
        orderNode.push({
          seq: node.seq,
          key: currentNode.key + this.lpad( index, 3, '0' ),
          name: node.name,
        });
        index++;
      }
    }

    // 이전 노드 Sort Key 생성
    if (typeof prevParentNode !== 'undefined') {
      let prevKey = 1;
      for (const node of prevParentNode.children) {
        orderNode.push({
          seq: node.seq,
          key: prevParentNode.key + this.lpad( prevKey++, 3, '0' ),
          name: node.name,
        });
      }
    }

    this.onDropNode.emit(orderNode);
  }

  /*******************************************************************************
    설  명 : lpad
    입력값 : str = 문자열, padLen = 채울 문자 수, padStr = 채울 문자
    리턴값 : 문자열
  *******************************************************************************/
  lpad(str, padLen, padStr) {
    if (padStr.length > padLen) {
      return str;
    }
    str += ""; // 문자로
    padStr += ""; // 문자로
    while (str.length < padLen) {
        str = padStr + str;
    }
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }
}
