/*******************************************************************************
  설  명 : 기초관리 > 기본정보설정 > 배송비 관리 탭
  작성일 : 2021-05-17
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { CustomValidator } from '@app/service/custom.validators';
import { UtilService } from '@app/service/util.service';
import { SiteConfigService } from '@app/service/site.config.service';
import { OrderService } from '@app/service/order.service';

import { ToastrService } from 'ngx-toastr';
import { BasicDeliveryAddComponent } from '@page/basic/company-info/basic-delivery/basic-delivery-add/basic-delivery-add.component';

const CONFIG_KEY = 'DELIVERY';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};
@Component({
  selector: 'app-basic-delivery',
  templateUrl: './basic-delivery.component.html',
  styleUrls: ['./basic-delivery.component.scss']
})
export class BasicDeliveryComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  shipDeliveryList = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  public search: any = {
    zipcode: '',
    address: '',
    add_delivery_amt: '',
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      delivery_use_yn: ['', []],
      delivery_amt: ['', [CustomValidator.numeric]],
      delivery_fra_amt: ['', [CustomValidator.numeric]],
      delivery_overcharge: ['', [CustomValidator.numeric]],
      delivery_free_condition: ['', [CustomValidator.numeric]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService,
    private siteConfigService: SiteConfigService,
  ) {
    this.buildForm();

    // 그리드 컬럼 선언
    this.columnDefs = [
      {headerName: '순번', field: 'seq', width: 60 },
      {headerName: '현우편번호', field: 'zipcode', width: 120, cellClass: 'cp center'},
      {headerName: '도로명주소', field: 'address', width: 400, cellClass: 'cp left' },
      {headerName: '도선료', field: 'add_delivery_amt', width: 120, cellClass: 'cp right', valueFormatter: currencyFormatter},
      {headerName: '시도', field: 'sido', width: 120, cellClass: 'cp left'},
      {headerName: '시군구', field: 'sigungu', width: 120, cellClass: 'cp left'},
      {headerName: '읍면동', field: 'eupmyeondong', width: 120, cellClass: 'cp left' }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getConfig();

    // 도선료 정보 가져오기
    this.getShipDeliveryList();
  }

  /*******************************************************************************
    설  명 : 도선료 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getShipDeliveryList() {
    this.orderService.getShipDeliveryList(this.search).then( response => {
      if( response.ResultCode ) {
        this.shipDeliveryList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 환경설정 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          delivery_use_yn: response.data.delivery_use_yn || '',
          delivery_amt: response.data.delivery_amt || '',
          delivery_overcharge: response.data.delivery_overcharge || '',
          delivery_fra_amt: response.data.delivery_fra_amt || '',
          delivery_free_condition: response.data.delivery_free_condition || '',
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    console.log(CONFIG_KEY);
    console.log(this.form);

    if (this.form.valid) {
      this.siteConfigService.setConfig(CONFIG_KEY, this.form).then( response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '배송비 저장');
        else this.toastrService.error( response.ResultMessage, '배송비 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '배송비 저장');
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( event: any ) {
    this.shipDeliveryAdd( event.data );
  }

  /*******************************************************************************
    설  명 : 도선료 정보 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  shipDeliveryAdd( item: any ) {
    const modalRef = this.modalService.open(BasicDeliveryAddComponent, optionsLG);

    modalRef.componentInstance.item = item;

    modalRef.result.then((result) => {
      this.getShipDeliveryList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      zipcode: '',
      address: '',
      add_delivery_amt: '',
    };

    this.getShipDeliveryList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getShipDeliveryList();
    }
  }
}