/*******************************************************************************
  설  명 : 기초관리 > 기본정보설정 > 계좌정보 탭
  작성일 : 2021-05-17
  작성자 : 송영석
  접속URL : /basic/company/info
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { BasicService } from '@app/service/basic.service';
import { SiteConfigService } from '@app/service/site.config.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import { BasicAccountAddComponent } from '@page/basic/company-info/basic-account/basic-account-add/basic-account-add.component';

const CONFIG_KEY = 'account';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-basic-account',
  templateUrl: './basic-account.component.html',
  styleUrls: ['./basic-account.component.scss']
})
export class BasicAccountComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  accountList: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
    agGridImageComponent: AgGridImageComponent,
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      auto_account_yn: ['', [] ],
      auto_account_certkey: ['', [Validators.maxLength(50)] ],
      auto_account_id: ['', [Validators.maxLength(50)] ],
      auto_account_sync: ['', [Validators.maxLength(50)] ],
      auto_account_no: ['', [Validators.maxLength(50)] ],
      auto_account_bank: ['', [Validators.maxLength(50)] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private basicService: BasicService,
    private siteConfigService: SiteConfigService
  ) {
    // 폼 생성
    this.buildForm();

    // 그리드 컬럼 선언
    this.columnDefs = [
      {headerName: '계좌코드', field: 'seq', hide: true },
      {headerName: '대표계좌', field: 'default', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.default == '1' ) return '<span class="badge badge-primary f12 fn">대표계좌</span>';
          else return '<span class="badge badge-secondary f12 fn">일반계좌</span>';
        }
      },
      {headerName: '은행명', field: 'bank_name', cellClass: 'cp center' },
      {headerName: '계좌번호', field: 'bank_account', cellClass: 'cp center' },
      {headerName: '계좌주', field: 'account_owner', cellClass: 'cp center' },
      {headerName: '은행로고', field: 'logo_url', cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      {headerName: '파일명', field: 'logo_origin', cellClass: 'cp' }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 기본정보 데이터 가져오기
    this.getConfig();

    // 계좌정보 리스트 가져오기
    this.getCompanyAccountList();
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          auto_account_yn:      response.data.auto_account_yn || '',
          auto_account_certkey: response.data.auto_account_certkey || '',
          auto_account_id:      response.data.auto_account_id || '',
          auto_account_sync:    response.data.auto_account_sync || '',
          auto_account_no:      response.data.auto_account_no || '',
          auto_account_bank:    response.data.auto_account_bank || '',
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.siteConfigService.setConfig(CONFIG_KEY, this.form).then( response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '계좌정보 저장');
        else this.toastrService.error( response.ResultMessage, '계좌정보 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '계좌정보 저장');
    }
  }

  /*******************************************************************************
    설  명 : 계좌 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCompanyAccountList() {
    await this.basicService.getCompanyAccountList().then( response => {
      if( response.ResultCode ) {
        this.accountList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 계좌정보 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  accountAdd( item: any ) {
    const modalRef = this.modalService.open(BasicAccountAddComponent, optionsLG);

    modalRef.componentInstance.item = item;

    modalRef.result.then((result) => {
      if( result ) {
        this.getCompanyAccountList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( event: any ) {
    this.accountAdd( event.data );
  }

  /*******************************************************************************
    설  명 : 처음 데이터 렌더링 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
}
