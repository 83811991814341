<form [formGroup]="formAdd">
  <input type="hidden" id="seq" formControlName="seq" />

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">계좌정보 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="hidden">계좌정보 추가/수정</caption>

    <colgroup>
      <col style="width:30%;" />
      <col style="width:70%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="default" class="control-label">대표계좌 여부</label></th>
      <td>
        <input type="hidden" name="default" id="default" formControlName="default" />
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.default.value==true}" (click)="setDefault(true)">대표계좌</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.default.value==false}" (click)="setDefault(false)">일반계좌</button>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="bank_name" class="control-label">은행명</label></th>
      <td><inputEx type="text" name="bank_name" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="bank_account" class="control-label">계좌번호</label></th>
      <td><inputEx type="text" name="bank_account" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="account_owner" class="control-label">계좌주</label></th>
      <td><inputEx type="text" name="account_owner" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="banklogo" class="control-label">은행로고</label></th>
      <td>
        <input type="file" name="banklogo" id="banklogo" formControlName="banklogo" ng2FileSelect (change)="handleUploadFileChanged($event)" [disabled]="!uploader.getNotUploadedItems().length" />
        <input type="hidden" name="logo_url" id="logo_url" formControlName="logo_url" />
        <input type="hidden" name="logo_origin" id="logo_origin" formControlName="logo_origin" />
        <div *ngIf="formAdd.controls.logo_origin.value">
          <img [src]="formAdd.controls.logo_url.value" style="max-width:250px;max-height:150px;" />
          <p>{{formAdd.controls.logo_origin.value}}</p>
        </div>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="item.seq!==0" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="button" class="btn btn-primary btn-small mr10 f15" (click)="submit()">저장하기</button>
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>