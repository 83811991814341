/*******************************************************************************
  설  명 : 발주서 검색 컴포넌트
  작성일 : 2021.05.26
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';

@Component({
  selector: 'app-purchase-find',
  templateUrl: './purchase-find.component.html',
  styleUrls: ['./purchase-find.component.scss']
})
export class PurchaseFindComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiProperty: any;
  gridColumnApiProperty: any;
  columnDefsProperty: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;
  rowClassRules

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };
  
  rowSelectPurchase = [];

  valueGetter_num = params => parseInt(String(params.data[params.colDef.field] || 0)).toLocaleString();

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private stockOrderComponent: StockOrderComponent,
  ) {
    this.columnDefs = this.stockOrderComponent.columnDefs;
    // this.columnDefsDetail = this.stockOrderComponent.columnDefsDetail;

    this.columnDefsDetail = [
      {
        headerName: '',
        field: '',
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        sortable: false,
        filter: false,
        resizable: false,
        flex: 0
      },
      { headerName: '주문번호', field: 'order_seq', cellClass: 'cp', width: 92, flex: 0 },
      { headerName: '상품코드', field: 'product_seq', cellClass: 'cp', width: 92, flex: 0 },
      { headerName: '카테고리', field: 'category_name', width: 122, cellClass: 'cp' },
      { headerName: '상품명', field: 'product_name', width: 880, cellClass: 'cp' },
      // { headerName: '거래처', field: 'comname', width: 150, cellClass: 'cp' },
      { headerName: '발주수량', field: 'qty', width: 92, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '금액', field: 'amt', width: 99, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '입고수량', field: 'complete_inQty_product', width: 92, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
    ];

    this.defaultColDef = {...this.stockOrderComponent.defaultColDef};
    this.rowSelection = this.stockOrderComponent.rowSelection;
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate;
    this.rowClassRules = this.stockOrderComponent.rowClassRules
  }

  /*******************************************************************************
    설  명 : 데이터 로드
  *******************************************************************************/
  ngOnInit() {
    this.buildForm();
    this.getStockOrder();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.stockOrderComponent.buildForm(this);
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.stockOrderComponent.onGridReady(params, this);
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.stockOrderComponent.onGridReadyDetail(params, this);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  // getComma = ( num ) => String(num).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');

  /*******************************************************************************
    설  명 : 발주서 선택
  *******************************************************************************/
  select() {
    const nodes = this.gridApi.getSelectedNodes();
    const rows = this.gridApi.getSelectedRows()
    if( !nodes.length ) {
      this.toastrService.error( "발주서를 선택하세요.", '발주서 선택');
      return;
    } else {
      if (rows[0]['progress_status_name'] == '입고완료' && !confirm('입고완료 상태입니다. \r\n그래도 진행하시겠습니까?')) return
      this.activeModal.close( nodes[0].data )
    }
  }

  /*******************************************************************************
    설  명 : 검색조건 버튼 클릭
  *******************************************************************************/
  btnClick(which, value) {
    this.stockOrderComponent.btnClick(which, value, this);
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
  *******************************************************************************/
    keypress( $event, _this = this ) {
      if( $event.key == 'Enter' ) _this.softReset()
    }
  
    searchInput($event) {
      this.form.patchValue({
        search : $event.target.value
      })
    }
  

    softReset() {
      this.getStockOrder();
    }    

  /*******************************************************************************
    설  명 : 발주서 리스트 가져오기
  *******************************************************************************/
  getStockOrder() {
    this.stockOrderComponent.getStockOrder(this);
  }

  /*******************************************************************************
    설  명 : 발주리스트 row 클릭시 상세내역 보여주기
  *******************************************************************************/
  onRowClicked_purchase($event) {
    this.stockOrderComponent.onRowClicked_purchase($event, this);
  }

  /*******************************************************************************
    설  명 : 발주상세 row 클릭시 색상사이즈 보여주기
  *******************************************************************************/
  onCellClicked_product($event) {
    this.stockOrderComponent.onCellClicked_product($event, this);
  }
  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(which, value) {
    this.stockOrderComponent.selectDate(which, value, this);
  }
  /*******************************************************************************
    설  명 : 검색조건 초기화
  *******************************************************************************/
  reset() {
    this.stockOrderComponent.reset(this);
  }

}
