import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class StockStatistic {

  constructor(
    private restful: RestfulService,
  ) {
  }

  
  // 공통 현황 가져오기
  getStockStatistic(): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'stockStatistic',
      version: '1.0',
      action: 'getStockStatistic'
    }, {}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 현재고현황 리스트 가져오기
  getStockStatistic_A( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockStatistic',
      version: '1.0',
      action: 'getStockStatistic_A'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 재고수불내역 리스트 가져오기
  getStockStatistic_B( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockStatistic',
      version: '1.0',
      action: 'getStockStatistic_B'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 창고별현황 리스트 가져오기
  getStockStatistic_C( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockStatistic',
      version: '1.0',
      action: 'getStockStatistic_C'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 상품별현황 리스트 가져오기
  getStockStatistic_D( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockStatistic',
      version: '1.0',
      action: 'getStockStatistic_D'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

   // 브랜드별 재고현황 리스트 가져오기
   getStockStatistic_E( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockStatistic',
      version: '1.0',
      action: 'getStockStatistic_E'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
 
  
}
