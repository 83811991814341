<form [formGroup]="formPurchase">
  <input type="hidden" id="seq" formControlName="seq" />

  <div class="modal-header">
    <h4 class="modal-title fb mb0">구매입고</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="hidden">구매입고</caption>

    <colgroup>
      <col style="width:15%;" />
      <col style="width:35%;" />
      <col style="width:15%;" />
      <col style="width:35%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="purchase_order_seq" class="control-label">발주서</label></th>
      <td>
        <inputEx type="text" name="purchase_order_seq" [formGroup]="formPurchase" [formErrors]="formErrors" readonly="true" [addOn]="addPurchaseFunc" addOnText="발주서검색"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="gbn_name" class="control-label">발주구분</label></th>
      <td>
        <inputEx type="text" name="gbn_name" [formGroup]="formPurchase" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="date" class="control-label">발주일자</label></th>
      <td>
        <inputEx type="date" name="date" [formGroup]="formPurchase" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="customer_name" class="control-label">거래처</label></th>
      <td>
        <inputEx type="text" name="customer_name" [formGroup]="formPurchase" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="memo" class="control-label">메모</label></th>
      <td colspan="3">
        <inputEx type="textarea" name="memo" [formGroup]="formPurchase" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>

    <div class="mt10">
      <div class="fl">
        <div class="f14 pt8">발주서 상품정보</div>
      </div>
      <div class="fr right">
        <div class="form-inline">
          <select name="purchase_seq" class="mr5 form-control form-control-small">
            <option value="">창고선택</option>
          </select>
          <button type="button" class="btn btn-default btn-small f12 mr5">창고일괄선택</button>
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="c63c340372c248b0a0e2c7346e5f2d34" [btnGroup]="1"></btn-ag-grid-save>
        </div>
      </div>
      <div class="cb"></div>
    </div>
    <div class="mt5">
      <ag-grid-angular
        #myGrid

        style="width:100%;height:300px;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="purchaseDetailList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"

        (gridReady)="onGridReady($event)"
        >
      </ag-grid-angular>
    </div>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="seq != 0" type="button" class="btn modal-btn btn-danger">삭제</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small">저장</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
    </div>
  </div>
</form>