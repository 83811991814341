<form [formGroup]="form" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-group-add">공통코드그룹 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="group_cd" class="control-label">그룹분류</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.group_cd.value=='S'}" (click)="form.patchValue({group_cd:'S'})">시스템코드</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.group_cd.value=='U'}" (click)="form.patchValue({group_cd:'U'})">사용자코드</button>
        </div>
        <inputEx type="hidden" name="group_cd" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="code" class="control-label">그룹코드</label></th>
      <td>
        <inputEx type="text" name="code" [formGroup]="form" [formErrors]="formErrors" placeholder="영문대문자 1~3자리 입력"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="name" class="control-label">그룹코드명</label></th>
      <td>
        <inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="use_yn" class="control-label">사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.use_yn.value==true}" (click)="form.patchValue({use_yn:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.use_yn.value==false}" (click)="form.patchValue({use_yn:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>

  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="selectedItem.seq!==0" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
    </div>
    <div class="fr w50p right ml0 mr0">
      <!-- <ng-container *ngIf="(form.controls.seq.value!=0 && form.controls.seq.value!='') && authService.currAuth['auth_modify']=='1'"> -->
      <ng-container *ngIf="(form.controls.seq.value!=0 && form.controls.seq.value!='')">
        <button type="submit" class="btn btn-primary btn-small f15">저장하기</button>
      </ng-container>
      <!-- <ng-container *ngIf="(form.controls.seq.value==0 || form.controls.seq.value=='') && authService.currAuth['auth_write']=='1'"> -->
      <ng-container *ngIf="(form.controls.seq.value==0 || form.controls.seq.value=='')">
        <button type="submit" class="btn btn-primary btn-small f15">저장하기</button>
      </ng-container>
      <button type="button" class="btn btn-default btn-small ml5 f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
