/*******************************************************************************
  설  명 : SENA 정품등록관리
  작성일 : 
  작성자 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ProductRecommendkeywordAddComponent } from '@page/product/product-recommendkeyword/product-recommendkeyword-add/product-recommendkeyword-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};


@Component({
  selector: 'app-sena-registration',
  templateUrl: './sena-registration.component.html',
  styleUrls: ['./sena-registration.component.scss']
})
export class SenaRegistrationComponent implements OnInit {

/*******************************************************************************
  전역 선언부
*******************************************************************************/
public registrationSenaList: any = [];

// 그리드 관련 선언
gridApi: any;
gridColumnApi: any;
columnDefs: any;

defaultColDef: any;
domLayout: any;
rowSelection: any;
paginationPageSize: any = 100;
selectedRows: number = 0;

noRowsTemplate: string;

// 그리드 이미지 처리
frameworkComponents = {
  agGridHtmlComponent: AgGridHtmlComponent
};

search: any = {
  searchText: '',
  isRegistration: ''
}

/*******************************************************************************
  설  명 : 생성자
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
constructor(
  private modalService: NgbModal,
  private toastrService: ToastrService,
  private productService: ProductService
) {
  // ag grid 컬럼 선언
  this.columnDefs = [
    { headerName: '', field: '', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, checkboxSelection: true },
    { headerName: '순번', field: 'seq', width: 80, cellClass: 'cp left' },
    { headerName: '등록일자', field: 'write_date', width: 200, cellClass: 'cp left' },
    { headerName: '성명', field: 'registerName', width: 100, cellClass: 'cp left' },
    { headerName: '연락처', field: 'registerHp', width: 200, cellClass: 'cp center' },
    { headerName: '이메일', field: 'registerEmail', width: 350, cellClass: 'cp center' },
    { headerName: '구매일자', field: 'buyDate', width: 120, cellClass: 'cp center' },
    { headerName: '세나시리얼', field: 'serialNo', width: 200, cellClass: 'cp center' },
    { headerName: '등록여부', field: 'isRegistration', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      valueGetter: function(params) {
        if( params.data.isRegistration == '1') return '<span class="badge badge-success f12 fn">등록</span>';
        else return '<span class="badge badge-secondary f12 fn">미등록</span>';
      }
    }, 
  ];

  // default 컬럼 옵션
  this.defaultColDef = {
    sortable: true,
    filter: false,
    resizable: true
  };

  this.rowSelection = 'multiple';

  // 메시지 표시 선언
  this.noRowsTemplate = '검색된 데이터가 없습니다.';
}

/*******************************************************************************
  설  명 : 데이터 로딩
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
ngOnInit(): void {
  this.searchInit();
}

/*******************************************************************************
  설  명 : ag grid ready 시 처리
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
onGridReady(params) {
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
}

/*******************************************************************************
  설  명 : ag grid filter 변경 시 처리
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
onFilterChanged($event) {
  let filteredRowCount = 0;
  this.gridApi.forEachNodeAfterFilter( function(node) {
    filteredRowCount++;
  });
  this.selectedRows = filteredRowCount;
}


/*******************************************************************************
  설  명 : 검색 초기화 버튼 처리
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
searchInit() {
  this.search = {
    searchText: '',
    isRegistration: ''
  }

  this.getRegistrationSenaList();
}

/*******************************************************************************
  설  명 : 검색 input에서 엔터키 입력 시 검색 처리
  입력값 : $event
  리턴값 : 없음
*******************************************************************************/
searchList( event ) {
  if( event.key == 'Enter' ) {
    this.getRegistrationSenaList();
  }
}

/*******************************************************************************
  설  명 : 세나정품등록 리스트를 가져온다.
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
getRegistrationSenaList() {
  this.productService.getRegistrationSenaList( this.search ).then( response => {
    if( response.ResultCode ) {
      this.registrationSenaList = response.data;
    } else {
      this.registrationSenaList = [];
    }
  });
}


/*******************************************************************************
  설  명 : 세나정품등록 정보 삭제
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
  setRegistrationSenaDelete() {
    
    const nodes = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 자료를 선택하세요', '' );
      return false;
    }

    const data: any = [];
    for(let node of nodes) {
      data.push( node.seq );
    }
    
    if ( confirm('선택한 자료를 삭제하시겠습니까?') ) {
      this.productService.deleteRegistrationSena( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getRegistrationSenaList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

/*******************************************************************************
  설  명 : 세나정품 등록 취소
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
setUnregistrationSena() {
  const nodes = this.gridApi.getSelectedRows();

  if( nodes.length < 1 ) {
    this.toastrService.error( '등록 취소할 자료를 선택하세요', '' );
    return false;
  }

  const  data: any = [];
  for(let node of nodes) {
    data.push( node.seq );
  }

  if ( confirm('선택한 자료를 등록취소 하시겠습니까?') ) {
    this.productService.setUnregistrationSena( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');

        this.getRegistrationSenaList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
}

/*******************************************************************************
  설  명 : 세나정품 등록 
  리턴값 : 없음
*******************************************************************************/
setRegistrationSena() {
  const nodes = this.gridApi.getSelectedRows();

  if( nodes.length < 1 ) {
    this.toastrService.error( '등록 할 자료를 선택하세요', '' );
    return false;
  }

  const  data: any = [];
  for(let node of nodes) {
    data.push( node.seq );
  }

  if ( confirm('선택한 자료를 등록 하시겠습니까?') ) {
    this.productService.setRegistrationSena( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');

        this.getRegistrationSenaList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
}


/*******************************************************************************
  설  명 : 등록여부 검색
  입력값 : isRegistration
  리턴값 : 없음
*******************************************************************************/
searchIsRegistration( isRegistration ) {
  this.search.isRegistration = isRegistration;

  this.getRegistrationSenaList();
}

}
