<section id="exchange-rate">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">동기화일자</p>
        <p class="subtxt">{{latestExchangeRatesData?.modified_time}}</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">금일 달러<span class="cnt">{{latestExchangeRatesData?.er_usd | Numeral: "0,0.00"}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">금일 엔화<span class="cnt">{{latestExchangeRatesData?.er_jpy | Numeral: "0,0.00"}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">금일 유로화<span class="cnt">{{latestExchangeRatesData?.er_eur | Numeral: "0,0.00"}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon5">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">금일 위안화<span class="cnt">{{latestExchangeRatesData?.er_cny | Numeral: "0,0.00"}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <section class="search form-inline">
    <div class="fl w70p">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="retrieve()">
        <i class="material-icons-outlined">search</i>
      </button>
      <input type="text" readonly="readonly" id="er_date_a" [(ngModel)]="retrieveData.er_date_a" size="13" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="retrieveData.er_date_a = today()">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" readonly="readonly" id="er_date_b" [(ngModel)]="retrieveData.er_date_b" size="13" class="form-control form-control-small calendar-input m15" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="retrieveData.er_date_b = today()">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>
      <div class="btn-group ml5">
        <button *ngFor="let item of erdateTerms" type="button" class="btn btn-default btn-small" [ngClass]="{active:retrieveData.er_date_term == item.value}" (click)="set_er_dates(item.value)">{{item.title}}</button>
      </div>
    </div>

    <div class="fr w30p right">
      <button type="button" class="btn btn-primary btn-small" (click)="collect()">환율 동기화</button>
    </div>

    <div class="cb"></div>
  </section>

  <section class="chart">
    <mat-tab-group>
      <mat-tab label="달러">
        <ng-container *ngIf="chartDatasets.length > 0">
          <canvas baseChart [datasets]="chartDatasets[0]" [labels]="chartLabels" [options]="chartOptions" [legend]="chartLegend" [chartType]="chartType" height="60" class="mt10"></canvas>
        </ng-container>
      </mat-tab>
      <mat-tab label="유로화">
        <ng-container *ngIf="chartDatasets.length > 0">
          <canvas baseChart [datasets]="chartDatasets[1]" [labels]="chartLabels" [options]="chartOptions" [legend]="chartLegend" [chartType]="chartType" height="60" class="mt10"></canvas>
        </ng-container>
      </mat-tab>
      <mat-tab label="위안화">
        <ng-container *ngIf="chartDatasets.length > 0">
          <canvas baseChart [datasets]="chartDatasets[2]" [labels]="chartLabels" [options]="chartOptions" [legend]="chartLegend" [chartType]="chartType" height="60" class="mt10"></canvas>
        </ng-container>
      </mat-tab>
      <mat-tab label="엔화">
        <ng-container *ngIf="chartDatasets.length > 0">
          <canvas baseChart [datasets]="chartDatasets[3]" [labels]="chartLabels" [options]="chartOptions" [legend]="chartLegend" [chartType]="chartType" height="60" class="mt10"></canvas>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </section>

  <section class="mt10" style="height:calc(50% - 100px);">
    <div>
      <h4 class="fb mb5">일자별 환율 정보</h4>
    </div>

    <ng-container *ngIf="gridRowData">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="gridColumnDefs"
        [defaultColDef]="gridDefaultColDef"
        [rowData]="gridRowData"
        [pagination]="false"
        [domLayout]="gridDomLayout"
        [overlayNoRowsTemplate]="gridNoRowsTemplate"
        [rowSelection]="gridRowSelection"
      >
      </ag-grid-angular>
    </ng-container>
  </section>
</section>
