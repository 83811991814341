/*******************************************************************************
  설  명 : 외상거래 리스트
  작성일 : 2021-08-17
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '@app/service/order.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { UtilService } from '@app/service/util.service';

import * as moment from 'moment';

@Component({
  selector: 'app-order-creditlist',
  templateUrl: './order-creditlist.component.html',
  styleUrls: ['./order-creditlist.component.scss']
})
export class OrderCreditlistComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public creditList: any = [];
  public creditMasterList: any = [];

  public search: any = {
    pageNo: 1,
    pageRow: 1000,
    storeSeq: '',
    sdate: '',
    edate: '',
    searchField: 'seq',
    searchText: '',
    searchTerm: 'A',
    group: '0007'
  };

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
    {title: '150일', value: this.getDays(150)},
    {title: '200일', value: this.getDays(200)},
    {title: '365일', value: this.getDays(365)},
  ];

  public groupList: any = [
    {title: '프렌차이즈', value: '0007'},
    {title: '협력점', value: '0004'},
    {title: '기타', value: '0001'},
  ];

  public totalCount: number = 0;
  public totalBalance: number = 0;
  public todayCredit: number = 0;
  public franchise: number = 0;
  public cooperation: number = 0;
  public etc: number = 0;



  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  rowSelection: any;
  defaultColDef: any;
  domLayout: any;

  masterColumnDefs: any;
  masterGridApi: any;
  masterGridColumnApi: any;
  masterRowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private orderService: OrderService,
    private utilService: UtilService,

  ) {
       // ag grid 컬럼 선언
       this.masterColumnDefs = [
        { headerName: '아이디', field: 'id', width: 120, cellClass: 'cp center' },
        { headerName: '고객명', field: 'name', width: 150, cellClass: 'cp center' },
        { headerName: '당일외상금액', field: 'creditToday', width: 180, cellClass: 'cp right', valueFormatter: currencyFormatter },
        { headerName: '당월외상금액', field: 'creditMonth', width: 200, cellClass: 'cp right', valueFormatter: currencyFormatter },
        { headerName: '총외상금액', field: 'creditTotal', width: 230, cellClass: 'cp right', valueFormatter: currencyFormatter },
      ];

     // ag grid 컬럼 선언
     this.columnDefs = [
      { headerName: '주문번호', field: 'order_seq', width: 85, cellClass: 'cp center' },
      { headerName: '주문일자', field: 'order_date', width: 105, cellClass: 'cp center' },
      { headerName: '주문자명', field: 'o_name', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent' },
      { headerName: '수신자명', field: 'r_name', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent' },
      { headerName: '외상결제일', field: 'pay_date', width: 105, cellClass: 'cp center' },
      { headerName: '외상금액', field: 'pay_amt', width: 115, cellClass: 'cp right', valueFormatter: currencyFormatter},
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';
    this.masterRowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }


  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCreditMasterList();
  }


  /*******************************************************************************
    설  명 : 외상거래 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCreditMasterList() {
    this.orderService.getCreditMasterList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.creditMasterList = response.data;
        this.totalBalance = response.totalBalance;
        this.todayCredit = response.todayCredit;
        this.franchise = response.franchise;
        this.cooperation = response.cooperation;
        this.etc = response.etc;

        this.creditList = [];

      } else {

      }
    });
  }


  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check ) {
    if( check == 'sdate' ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'edate')  {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }

    this.getCreditMasterList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridMasterReady(params) {
    this.masterGridApi = params.api;
    this.masterGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 마스터 row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.getcreditList($event.data.mem_no)
  }


  /*******************************************************************************
    설  명 : 외상거래 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getcreditList(memNo) {
    let params = {
      memNo: memNo,
      sdate: this.search.sdate,
      edate: this.search.edate
    }

    this.orderService.getCreditList( params ).then( response => {

      if( response.ResultCode ) {
        this.creditList = response.data;
      } else {

      }
    });
  }
  

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field === 'order_seq' ) {
      this.goDetail( $event.data.order_seq );
    }
  }

  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( seq: any ) {
    const url = '/order/detail?seq=' + seq;
    window.open("about:blank").location.href = url;
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 1000,
      storeSeq: '',
      orderSdate: '',
      orderEdate: '',
      searchField: 'seq',
      searchText: '',
      searchTerm: 'A',
      group: '0007'
    };

    // 200일 검색 디폴트
    this.onSearchClick('A');

    this.getCreditMasterList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCreditMasterList();
    }
  }
}
