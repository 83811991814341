/*******************************************************************************
  설  명 : 현황관리 - 오픈마켓별 매출현황
  작성일 : 2020-12-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { StatisticService } from '@app/service/statistic.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { UtilService } from '@app/service/util.service';
import { Router, ActivatedRoute } from '@angular/router';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-statistic-openmarket',
  templateUrl: './statistic-openmarket.component.html',
  styleUrls: ['./statistic-openmarket.component.scss']
})
export class StatisticOpenmarketComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search = {
    store: '',
    year: '',
    category_select: '',
    category: '',
    brand_select: '',
    brand: '',
    chartData: false
  };

  public storeList = [
    {code: '', name: '전체'},
    {code: '11번가', name: '11번가'},
    {code: '스토어팜', name: '스토어팜'},
    {code: '옥션', name: '옥션'},
    {code: '위메프', name: '위메프'},
    {code: '이베이', name: '이베이'},
    {code: '지마켓', name: '지마켓'},
    {code: '체크아웃', name: '체크아웃'},
    {code: '쿠팡', name: '쿠팡'},
    {code: '톡스토어', name: '톡스토어'},
    {code: '로켓배송', name: '로켓배송'}
  ];

  public optionsCategory: any = {
    multiple: false,
    placeholder: '카테고리 검색'
  }

  public optionsBrand: any = {
    multiple: false,
    placeholder: '브랜드 검색'
  }

  public years: any = [];
  public selectCategoryList: any = [];
  public selectBrandList: any = [];
  public categoryList: any = [];
  public brandList: any = [];

  // 차트 관련 변수
  public barChartData: any = [];
  public chartType = 'bar';
  public chartLabel: string[] = [];

  // 차트 옵션
  public chartOptions = {
    tooltips: {
      display: true,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: 'chocolate',
      bodyFontColor: '#000',
      bodyFontSize: 12,
      displayColors: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipItem.value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') + ' 원';
        }
      }
    },
    scaleShowVerticalLines: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad'
    },
    responsive: true,
    legend: {
      display: true
    },
    hover: {
      mode: 'arest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data Value'
        },
        ticks: {
          beginAtZero: true,
          callback : value => value.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        }
      }]
    }
  };

  // 그리드 관련 선언
  public dataList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private statisticService: StatisticService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private utilService: UtilService,
    private agGridExComponent: AgGridExComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.getCategoryList2();
    this.getBrandList();
    this.getData();

    this.statisticService.getYearsSelect().then( response => {
      if ( response.ResultCode ) {
        this.years = response.data;
      } else {
        this.years = [];
      }
    });

    this.categoryService.getTopCategory().then( response => {
      if (response.ResultCode) {
        this.categoryList = [{category_code: '', title: '전체'}, ...response.data];
      };
    });

    this.brandService.getTopBrand().then( response => {
      if (response.ResultCode) {
        this.brandList = [{key: '', title: '전체'}, ...response.data];
      };
    });

  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // chart
    this.storeList.map(data => {
      if (data.code !== '') {
        const item = {type: 'line', data: [], label: data.name, fill: false, lineTension: 0};
        this.barChartData.push(item);
        return item;
      };
    });

    // grid
    this.initGridColumn();

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };
    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  initGridColumn() {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '구분', field: 'o_name', width: 120, cellClass: 'cp center' },
    ];

    if (this.search.year === '') {
      for( let i = 0; i < 10; i++ ) {
        this.columnDefs.push({
          headerName: parseInt( moment().format('YYYY'), 10 ) - 9 + i + '년',
          field: (parseInt( moment().format('YYYY'), 10 ) - 9 + i).toString(),
          width: 130,
          cellClass: 'cp right',
          valueFormatter: this.agGridExComponent.currencyFormatter
        });
        this.chartLabel[i] = parseInt( moment().format('YYYY'), 10 ) - 9 + i + '년';
      };
    } else {
      for( let i = 0; i < 12; i++ ) {
        const month = (i + 1).toString();
        this.columnDefs.push({
          headerName: month + '월',
          field: this.search.year + '-' + ('0' + month).slice(-2),
          width: 116,
          cellClass: 'cp right',
          valueFormatter: this.agGridExComponent.currencyFormatter
        });
        this.chartLabel[i] = month + '월';
      };
    };

    this.columnDefs.push({
    headerName: '합계',
      field: 'total',
      width: 140,
      cellClass: 'cp right',
      valueFormatter: this.agGridExComponent.currencyFormatter
    });
  }

  searchInit() {
    this.search = {
      store: '',
      year: '',
      category_select: '0',
      category: '',
      brand_select: '0',
      brand: '',
      chartData: false
    };

    this.initGridColumn();
    this.getData();
  };

  /*******************************************************************************
    설  명 : 오픈마켓 선택 시
    입력값 : search.store
    리턴값 : 없음
  *******************************************************************************/
  setSearchType( code: string ) {
    this.search.store = code;
    this.getData();
  };

  /*******************************************************************************
    설  명 : select 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList2() {
    this.categoryService.getCategoryList2().then( response => {
      this.selectCategoryList = [];
      if (response.ResultCode) {
        this.selectCategoryList.push({id: '0', text: '카테고리 선택'});

        response.data.forEach( row => {
          this.selectCategoryList.push({
            id: row.seq,
            text: row.category_name
          });
        });
      } else {
        this.selectCategoryList = [];
      }
    });
  };

  /*******************************************************************************
    설  명 : select 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      this.selectBrandList = [];
      if( response.ResultCode ) {
        this.selectBrandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.selectBrandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.selectBrandList = [];
      };
    });
  };

  // 년도변경
  changeYear($event) {
    this.search.year = $event.target.value;
    this.initGridColumn();
    this.getData();
  };

  /*******************************************************************************
    설  명 : select2 카테고리 검색 이벤트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedCategory( $event, item ) {
    const key = $event.id || (item.category_code === '' ? '0' : item.category_code);

    if ( key != '0') {
      const product = this.categoryList.find(f => f.category_code == key) || { category_code: '0' };
      this.search.category_select = key;
      this.search.category = product.category_code;
      this.search.brand_select = '0';
      this.search.brand = '';
    } else {
      this.search.category_select = '0';
      this.search.category = '';
    };

    this.getData();
  };

  /*******************************************************************************
    설  명 : select 브랜드 검색 이벤트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedBrand( $event, item ) {
    const key = $event.id || (item.key === '' ? '0' : item.key);

    if ( key != '0') {
      const brand = this.brandList.find(f => f.key == key) || { key: '0' };
      this.search.brand_select = key;
      this.search.brand = brand.key;
      this.search.category_select = '0';
      this.search.category = '';
    } else {
      this.search.brand_select = '0';
      this.search.brand = '';
    };

    this.getData();
  };

  // 데이터 피벗
  dataPivot(title, group, value, data) {
    const obj = data.reduce((acc, cur) => {
      acc[cur[title]] = acc[cur[title]] || [];
      acc[cur[title]].push({[cur[group]]: cur[value]});
      return acc;
    }, {});

    return Object.keys(obj).map(data => {
      return {[title]: data, [group]: Object.assign.apply({}, obj[data])};
    });
  };

  getData() {
    this.dataList = [];
    this.barChartData.map(chart => {
      return chart.data = [];
    });

    this.statisticService.getSalesOpenMarket({...this.search, ...{chartData: true}}).then(response => {
      if (response.ResultCode) {

        if (this.search.year === '') {
          const list = this.dataPivot('o_name', 'date', 'income', response.data);

          // grid
          this.dataList = list.map((data: any) => {
            return {
              o_name: data.o_name,
              ...data.date,
              total: Object.values(data.date).reduce((acc: any, cur: any) => {return parseInt(acc) + parseInt(cur)}, 0)}
          });

          // chart
          // 데이터 없는 빈 연도 채우기
          const max = new Date().getFullYear();
          const min = max - 9;
          const arr = [];
          for (let i = max; i >= min; i--) {
            arr.push(i)
          };
          const years = arr.reduce((acc, cur) => {
            acc[cur] = '0';
            return acc;
          }, {});

          let i = 0;
          this.storeList.map((data, index) => {
            if (data.code !== '') {
              const store = list.find((f: any) => f.o_name === data.name);
              if( store !== '' ) {
                const chartData = Object.values(Object.assign({...years}, (store as any).date));
                this.barChartData[i].data = chartData;
              }
              i++;
            }
          });
        } else {
          const list = this.dataPivot('o_name', 'date', 'income', response.data);

          // grid
          this.dataList = list.map((data: any) => {
            return {
              o_name: data.o_name,
              ...data.date,
              total: Object.values(data.date).reduce((acc: any, cur: any) => {return parseInt(acc) + parseInt(cur)}, 0)}
          });

          // chart
          // 데이터 없는 빈 월 채우기
          const arr = [];
          for (let i = 1; i <= 12; i++) {
            arr.push( this.search.year + '-' + ('0' + i).slice(-2) );
          };
          const month = arr.reduce((acc, cur) => {
            acc[cur] = '0';
            return acc;
          }, {});

          let i = 0;
          this.storeList.map((data, index) => {
            if (data.code !== '') {
              const store = list.find((f: any) => f.o_name === data.name);
              if (store) {
                const chartData = Object.values(Object.assign({...month}, (store as any).date));
                this.barChartData[i].data = chartData;
              }
              i++;
            }
          });
        };

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  moveSimplePage() {
    this.router.navigate(['/statistic/simple'], {
      relativeTo: this.activatedRoute,
      queryParams: { type : 3 },
    })
  }
}
