<form [formGroup]="form" class="h100">
  <section class="h100">
    <header class="mb5">
      <div class="fl">
        <h4 class="modal-title fb mb0 pt3">발주 등록</h4>
      </div>
      <div class="fr right">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click) = "setStockOrder()">저장하기</button>
      </div>
      <div class="cb"></div>
    </header>

    <section>
      <table class="table table-bordered table-small">
      <caption class="hidden">발주 기본정보</caption>

      <colgroup>
        <col style="width:10%" />
        <col style="width:40%" />
        <col style="width:10%" />
        <col style="width:40%" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="gbn">발주구분</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small f12" [ngClass] = "{active: form.controls.gbn.value=='1'}" (click) = "form.get('gbn').setValue('1')">매입</button>
            <button type="button" class="btn btn-default btn-small f12" [ngClass] = "{active: form.controls.gbn.value=='2'}" (click) = "form.get('gbn').setValue('2')">직발</button>
          </div>
          <inputEx type="hidden" name="gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row" class="active"><label for="date">발주일자</label></th>
        <td>
          <inputEx type="date" name="date" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><i class="material-icons cred f11">star</i>  <label for="title">제목</label></th>
        <td>
          <inputEx name="title" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="customer_seq">거래처</label></th>
        <td>
          <inputEx type="select2" name="customer_seq" [formGroup]="form" [formErrors]="formErrors" [data]="customerList" [small]="true" [options]="{multiple: false, height: 400}" [onChanged]="customerChangedFunc"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="memo">메모</label></th>
        <td colspan="3">
          <inputEx name="memo" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
    </section>

    <section class="contents-body" style="height:calc(100% - 110px)">
      <as-split unit="percent" restrictMove="true" gutterSize="10">
        <!-- 발주 상품 정보 -->
        <as-split-area size="55" minSize="30" maxSize="70">
          <section class="search-condition mb5">
            <div class="fl form-inline asub-title">
              <h4 class="fl sub-title pt3 fb mb0">발주 상품정보 : {{form.value.productList.length}} 건</h4>
            </div>
            <div class="fr">
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="9c33943a7f1a4d4999a8b9cf96d067a0" [btnGroup]="1"></btn-ag-grid-save>
              <button type="button" class="ml5 btn btn-danger btn-small f12" (click) = "delete_product()">상품삭제</button>
              <button type="button" class="ml5 btn btn-primary btn-small f12" (click) = "addProductModal()">상품추가</button>
            </div>
            <div class="cb"></div>
          </section>

          <ag-grid-angular
            #myGrid

            style="width: 100%;height:calc(100% - 45px);"
            class="ag-theme-balham ag-vertical-align"

            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="form.value.productList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [getRowHeight]="getRowHeight"
            [components]="components"
            [singleClickEdit]="true"
            [stopEditingWhenGridLosesFocus]="true"

            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked($event)"
            (selectionChanged) = "onSelectionChanged_product($event)"
            >
          </ag-grid-angular>
        </as-split-area>
        <!-- 발주 상품 정보 -->

        <!-- 발주 상품별 색상 사이즈 정보 -->
        <as-split-area size="45" minSize="30" maxSize="70" style="height:100%;">
          <section class="search-condition mb5">
            <div class="fl form-inline asub-title">
              <h4 class="fl sub-title pt3 fb mb0">상품별 색상 사이즈 : {{form.value.productListProperty.length}} 건</h4>
            </div>
            <div class="fr">
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApiProperty" gridId="7c9cb9c492ef4a969810d7e044b09eb4" [btnGroup]="1"></btn-ag-grid-save>
              <button type="button" class="ml5 btn btn-danger btn-small f12" (click) = "delete_property()">선택삭제</button>
              <!-- <button type="button" class="ml5 btn btn-primary btn-small f12">색상사이즈 추가</button> -->
            </div>
            <div class="cb"></div>
          </section>

          <ag-grid-angular
            #myGrid

            style="width: 100%;height:calc(100% - 45px);"
            class="ag-theme-balham ag-vertical-align"

            [columnDefs]="columnDefsProperty"
            [defaultColDef]="defaultColDef"
            [rowData]="form.value.productListProperty"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [getRowHeight]="getRowHeight"
            [components]="components"
            [singleClickEdit]="true"
            [stopEditingWhenGridLosesFocus]="true"

            (gridReady)="onGridReadyProperty($event)"
            (cellValueChanged) = "onCellValueChanged($event)"
            (selectionChanged) = "onSelectionChanged_property($event)"
            (cellClicked) = "cellClick_property($event)"
            >
          </ag-grid-angular>
        </as-split-area>
        <!-- 발주 상품별 색상 사이즈 정보 -->
      </as-split>

    </section>
  </section>
</form>