import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { OpenmarketService } from '@app/service/openmarket.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import * as $ from 'jquery';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-order-sabangnet-delivery-upload',
  templateUrl: './order-sabangnet-delivery-upload.component.html',
  styleUrls: ['./order-sabangnet-delivery-upload.component.scss']
})
export class OrderSabangnetDeliveryUploadComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public type: any;

  @Input() sabangnetList
  excelData = []
  extractData = []

  public marketList = []

  public baseURL = config.baseUrl;


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private utilService: UtilService,
    private restful: RestfulService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private openmarketService: OpenmarketService
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    console.log(this.sabangnetList);
  }


  /*******************************************************************************
    설  명 : 저장하기
  *******************************************************************************/
  async setOpenmarketConsignSave() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <colgroup>
          <col style="width:150px;" />
          <col style="width:150px;" />
          <col style="width:200px;" />
          <col style="width:200px;" />
          <col style="width:150px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>사방넷주문번호</td>
          <td>운송장번호</td>
          <td>상품코드</td>
          <td>배송희망일(공백허용)</td>
          <td>사방넷택배사코드(3자리)</td>
        </tr>
    `]
    
        let data = this.sabangnetList;
    
        data.forEach(element => {    
          contents.push(`
            <tr>
              <td style="text-align:center;">${element.F}</td>
              <td style="text-align:center;">${element.EE}</td>
              <td style="text-align:center;">${element.G}</td>
              <td style="text-align:center;"></td>
              <td style="text-align:center;">${element.FF !== null ? element.FF : ''}</td>
            </tr>
            </tbody>
          `)
        })
        contents.push(`</table>`)
    
        let today = new Date();
    
        const fileName = `사방넷배송목록_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`
    
        this.printExcel(fileName, contents.join(''))
  
  }

    /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    printExcel(fileName, contents) {
      const exportContents = `
        <html xmlns:x="urn:schemas-microsoft-com:office:excel">
        <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
        <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
        <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
        </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
        <body>${contents}</body></html>
      `
      const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
      const elem = document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `${fileName}.xls`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    }

}
