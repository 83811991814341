import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class OpenmarketService {

  constructor(
    private restful: RestfulService,
  ) {
  }
  // 주문 리스트 가져오기
  getOpenmarketList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getOpenmarketList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

    // 주문 리스트 가져오기
    getSabangnetList( search: any ): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'openmarket',
          version: '1.0',
          action: 'getSabangnetList'
        }, search
      ).then(this.restful.extractData)
       .catch(this.restful.handleErrorPromise);
    }

  // 지마켓/옥션 출력 리스트 가져오기
  getPrintGmarketAuctionList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintGmarketAuctionList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 네이버 출력 리스트 가져오기
  getPrintNaverList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintNaverList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 스토어팜 출력 리스트 가져오기
  getPrintFarmList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintFarmList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 11번가 출력 리스트 가져오기
  getPrint11stList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrint11stList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 쿠팡 출력 리스트 가져오기
  getPrintCoupangList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintCoupangList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 톡스토어 출력 리스트 가져오기
  getPrintTalkList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'getPrintTalkList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 주문정보 저장
  setOpenmarketOrderRowSave( rowData: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setOpenmarketOrderRowSave'
      }, rowData
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문정보 삭제
  setOpenmarketOrderRowDelete( rowData: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setOpenmarketOrderRowDelete'
      }, rowData
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 지마켓/옥션 주문목록 저장
  setGmarketAuctionOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setGmarketAuctionOrderSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 네이버 주문목록 저장
  setNaverOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setNaverOrderSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 스토어팜 주문목록 저장
  setFarmOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setFarmOrderSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 11번가 주문목록 저장
  set11stOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'set11stOrderSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 쿠팡 주문목록 저장
  setCoupangOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setCoupangOrderSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 톡스토어 주문목록 저장
  setTalkOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setTalkOrderSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 바마 주문으로 저장
  setOrderAdd( type: any, rows: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setOrderAdd'
      }, {
        marketType: type,
        params: rows
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사방넷 바마 주문으로 저장
  setSabangnetOrderAdd( rows: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setSabangnetOrderAdd'
      }, {
        params: rows
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 사방넷 주문목록 저장
  setSabangnetOrderSave( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setSabangnetOrderSave'
      }, params
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 배송목록 저장
  setOpenmarketConsignSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setOpenmarketConsignSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사방넷 주문정보 저장
  setSabangnetOrderRowSave( rowData: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setSabangnetOrderRowSave'
      }, rowData
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사방넷 주문정보 삭제
  setSabangnetOrderRowDelete( rowData: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'openmarket',
        version: '1.0',
        action: 'setSabangnetOrderRowDelete'
      }, rowData
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
