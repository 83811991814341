/*******************************************************************************
  설  명 : 거래처관리 - 담당자
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { BasicService } from '@app/service/basic.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { MemberFindComponent } from '@components/member-find/member-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-customer-tab-charge',
  templateUrl: './customer-tab-charge.component.html',
  styleUrls: ['./customer-tab-charge.component.scss']
})
export class CustomerTabChargeComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public seq: any;

  @Input() set customerSeq(value: number) {
    this.seq = value;

    if( typeof this.seq != 'undefined' ) {
      this.getCustomerChargeList();
    }
  }

  // 그리드 관련 선언
  customerChargeList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private basicService: BasicService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '회원상태', field: 'status', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == 'Y' ) {
            return '<span class="badge badge-success f12">정상회원</span>';
          } else {
            return '<span class="badge badge-secondary f12">탈퇴회원</span>';
          }
        }
      },
      { headerName: '부서', field: '', width: 100, cellClass: 'cp center' },
      { headerName: '직책', field: '', width: 100, cellClass: 'cp center' },
      { headerName: '담당자명', field: 'name', width: 80, cellClass: 'cp center' },
      { headerName: '연락처', field: 'hp', width: 110, cellClass: 'cp center' },
      { headerName: '이메일', field: 'email', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 담당자 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerChargeList() {
    this.basicService.getCustomerChargeList( this.seq ).then( response => {
      if( response.ResultCode ) {
        this.customerChargeList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
     설  명 : 담당자 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCustomerChargeAddOn() {
    const modalRef = this.modalService.open(MemberFindComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
        result.data.customerSeq = this.seq;

        this.basicService.setCustomerChargeSave( result.data ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getCustomerChargeList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 담당자 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCustomerChargeDelete() {
    const nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 담당자를 선택하세요', '' );
      return false;
    }

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data );
    }

    if ( confirm('삭제하시겠습니까?') ) {
      this.basicService.setCustomerChargeDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getCustomerChargeList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

}
