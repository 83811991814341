<div class="modal-header">
  <h4 class="modal-title fb mb0">근무자추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <app-tree-view
    [useSort] = 'false'
    [useAddNode] = 'false' 
    [nodeData] = 'nodeData'
    [picture] = 'true'
    [badge] = 'true'
    (onSelected) = 'setNode($event)'
  >
  </app-tree-view>

  <!-- <form [formGroup]="form">

  <table class="table table-bordered table-small mb0">
  <colgroup>
    <col style="width:25%" />
    <col style="width:75%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i><label class="control-label">사원검색</label></th>
    <td>
      <inputEx type="select" name="mem_no" [formGroup]="form" [formErrors]="formErrors" [data]="employeeList" (change) = "setEmployee($event)"></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><label class="control-label">부서</label></th>
    <td>
      <inputEx type="text" name="dept_name" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><label class="control-label">직급</label></th>
    <td>
      <inputEx type="text" name="position_name" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
    </td>
  </tr>
  </tbody>
  </table>

  </form>
</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small mr5" (click) = "submit()">저장하기</button>
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
  </div> -->
</div>

