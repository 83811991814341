import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { StockOrder } from '@app/service/stockOrder.service';

@Component({
  selector: 'app-modal-purchase-qty',
  templateUrl: './modal-purchase-qty.component.html',
  styleUrls: ['./modal-purchase-qty.component.scss']
})
export class ModalPurchaseQtyComponent implements OnInit {

  @Input() data
  @Input() getToday
  @Input() getUser
  @Input() dateFormat

  cnt

  constructor(
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private stockOrder: StockOrder
  ) { }

  ngOnInit(): void {
    this.cnt = this.data[0]['purchase_cnt']
  }

  /*******************************************************************************
    설  명 : 저장하기
  *******************************************************************************/
  submit() {
    this.stockOrder.setPurchaseQty({
      mem_no : this.getUser,
      purchase_cnt : parseInt(this.cnt),
      purchase_property_seq : this.data[0]['purchase_property_seq'],
      purchase_product_seq : this.data[0]['purchase_product_seq']
    }).then(response => {
      if(response.ResultCode) {
        this.toastrService.success(response.ResultMessage)
        this.activeModal.close(true)
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 수량변경 이벤트
  *******************************************************************************/
  changeCnt($event) {
    this.cnt = $event.target.value
  }

}
