/*******************************************************************************
  설  명 : 대표상품관리
  작성일 : 2022-08-26
  작성자 : 인덕교
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { CategoryService } from '@app/service/category.service';
import { BasicService } from '@app/service/basic.service';

import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-product-master',
  templateUrl: './product-master.component.html',
  styleUrls: ['./product-master.component.scss']
})
export class ProductMasterComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  private subscription: Subscription = new Subscription;
  
  public statistic: any = {
    total: 0,
    sales: 0,
    standby: 0,
    soldout: 0,
    notcolor: 0,
    notsize: 0
  };

  public productList: any = [];
  public categoryList: any = [];
  public soldoutList: any = [];
  public customerList: any = [];

  public baseURL = config.baseUrl;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  components: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public params: any = {
    seq: '',
    pageNo: 1,
    pageRow: 20,
    totalCount: 0,
    opn: '',
    otc: 0,
    searchField: 'seq',
    searchText: '',
    status: '',
    sdate: '',
    edate: '',
    is_display: '1',
    category_code: '',
    customer_seq: '',
    safe_stock: '',
    approval_yn: '1',
    referrer: ''
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private basicService: BasicService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 40, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
        { headerName: '상품번호/옵션번호', field: 'seq', width: 85, cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let productSeq = params.data.seq;
          let propertySeq = params.data.property_seq;

          return productSeq + ( propertySeq !== undefined ? '<br/>' + propertySeq : '' );
        }
      },
      { headerName: '승인여부(상품/옵션)', field: 'approval_yn', width: 87, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let approvalYn = params.data.approval_yn;
          let propertyApprovalYn = params.data.property_approval_yn;
          let approvalBadge = ( approvalYn == 1 ) ? '<span class="badge badge-success f12">승인</span>' : '<span class="badge badge-danger f12">미승인</span>';
          let propertyApprovalBadge = ( propertyApprovalYn == 1 ) ? '<span class="badge badge-success f12 mt5">승인</span>' : '<span class="badge badge-danger f12 mt5">미승인</span>';

          return approvalBadge + ( propertyApprovalYn !== undefined ? '<br/>' + propertyApprovalBadge : '' );
        }
      },
      { headerName: '품절여부(상품/옵션)', field: 'status', width: 87, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let parentStatus: any = params.data.parent_status;
          let childStatus: any = params.data.status;
          let parentBadge: any;
          let childBadge: any;

          if ( parentStatus == '0' ) parentBadge = '<span class="badge badge-success f12">판매중</span>';
          else if ( parentStatus == '1' ) parentBadge = '<span class="badge badge-warning f12">일시품절</span>';
          else if ( parentStatus == '2' ) parentBadge = '<span class="badge badge-danger f12">영구품절</span>';
          else parentBadge = '';

          if ( childStatus == '0' ) childBadge = '<span class="badge badge-success f12 mt5">판매중</span>';
          else if ( childStatus == '1' ) childBadge = '<span class="badge badge-warning f12 mt5">일시품절</span>';
          else if ( childStatus == '2' ) childBadge = '<span class="badge badge-danger f12 mt5">영구품절</span>';
          else childBadge = '';

          return parentBadge + ( childStatus !== undefined ? '<br/>' + childBadge : '' );
        }
      },
      { headerName: '표시여부(상품/옵션)', field: 'is_display', width: 87, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let isDisplay = params.data.is_display;
          let propertyIsDisplay = params.data.property_is_display;
          let displayBadge = ( isDisplay == 1 ) ? '<span class="badge badge-success f12">표시</span>' : '<span class="badge badge-danger f12">미표시</span>';
          let propertyDisplayBadge = ( propertyIsDisplay == 1 ) ? '<span class="badge badge-success f12 mt5">표시</span>' : '<span class="badge badge-danger f12 mt5">미표시</span>';

          return displayBadge + ( propertyIsDisplay !== undefined ? '<br/>' + propertyDisplayBadge : '' );
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 140, cellClass: 'cp center ag-cell130h', cellRenderer: 'agGridImageComponent' },
      { headerName: '카테고리/브랜드/상품명', field: '', width: 360, cellClass: 'ag-cell130h-br cell-wrap-text', autoHeight: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let category_name = ( params.data.category_name ) ? params.data.category_name + '<br/>' : '';
          let brand_name = ( params.data.brand_name ) ? '[ ' + params.data.brand_name + ' ]<br/>' : '';
          let product_name = ( params.data.product_name ) ? params.data.product_name : '';

          return category_name + brand_name + product_name;
        }
      },
      { headerName: '색상/사이즈', field: '', width: 125, cellClass: 'left ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          // let color_name = ( params.data.color_name ) ? params.data.color_name : '';
          let display_color_name = ( params.data.display_color_name ) ? '<span class="fb">' + params.data.display_color_name + '</span>' : '';
          // let size_name = ( params.data.size_name ) ? params.data.size_name : '';
          let display_size_name = ( params.data.display_size_name ) ? '<span class="fb">' + params.data.display_size_name + '</span>' : '';

          if( params.data.property_seq === undefined )
            return '<span class="fb">옵션보기 시 표시</span>';
          else
            return display_color_name + ' <br/> ' + display_size_name;
        }
      },
      // { headerName: '공급업체명', field: 'provider_name', width: 100, cellClass: 'left ag-cell130h-br cell-wrap-text', autoHeight: true },
      { headerName: '매입거래처명/공급업체명', field: 'customer_name', width: 110, cellClass: 'left ag-cell130h-br cell-wrap-text', 
        cellRenderer: 'agGridHtmlComponent', 
        autoHeight: true,
        valueGetter(params: any) {
          let customerName: any = ( params.data.customer_name !== null ) ? params.data.customer_name : '';
          let providerName: any = ( params.data.provider_name !== null ) ? params.data.provider_name : '';

          return customerName + '/<br/>' + providerName;
        }
      },
      { headerName: '재고적용', field: 'is_stock', width: 86, cellClass: 'center ag-cell130h-br', 
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let isStock: any = '';
          let daeguStockQty: any = ( params.data.daegu_stock_qty !== null ) ? params.data.daegu_stock_qty : 0;
          let seoulStockQty: any = ( params.data.seoul_stock_qty !== null ) ? params.data.seoul_stock_qty : 0;

          if ( params.data.is_stock == '0' ) {
            isStock = '<span class="badge badge-secondary f12">적용안함</span>';
          } else {
            isStock = '<span class="badge badge-success f12">재고적용</span>';
          }

          return isStock + ( daeguStockQty !== undefined ? `<br/>대구 : ${daeguStockQty}` : '' ) + ( daeguStockQty !== undefined ? `<br/>서울 : ${seoulStockQty}` : '' );
        }
      },
      // { headerName: '안전재고/재입고기간', field: 'safe_stock', width: 88, cellClass: 'center ag-cell130h-br',
      //   cellRenderer: 'agGridHtmlComponent',
      //   valueGetter(params: any) {
      //     let safeStock: any = params.data.safe_stock;
      //     let stockImportDay: any = params.data.stock_import_day;

      //     return safeStock + ' / ' + stockImportDay;
      //   }
      // },
      { headerName: '판매/상사/협력/프랜/구매', field: '', width: 165, cellClass: 'right ag-cell130h-br', 
        cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let consumer_price = getComma(params.data.consumer_price);
          let com_price = getComma(params.data.com_price);
          let b2b_price = getComma(params.data.b2b_price);
          let fra_price = getComma(params.data.fra_price);
          let buy_price = getComma(params.data.buy_price);
          return consumer_price + '<br />' + com_price + '<br />' + b2b_price + '<br />' + fra_price + '<br />' + buy_price;
        }
      },
      { headerName: '마일리지', field: 'mileage', width: 75, cellClass: 'right ag-cell130h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '등록일', field: 'write_date', width: 105, cellClass: 'center ag-cell130h-br cell-wrap-text', autoHeight: true },
      {
        field: '관리',
        width: 90,
        cellClass: 'center ag-cell80h',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'productMasterList',
          goDetailFunc: this.goDetail.bind(this),
          getPropertyListFunc: this.getPropertyList.bind(this),
          getProductListFunc: this.getProductList.bind(this)
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

    function getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 130;
  };

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }
  
  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 위젯 데이터 불러오기
    this.getProductMasterWidget();

    // 거래처 리스트가져오기
    this.getCustomerList();

    // 검색 카테고리 리스트가져오기
    this.getCategoryList2();

    this.subscription = this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;
        this.params.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
        this.params.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow;
        this.params.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? '' : params.searchField;
        this.params.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
        this.params.status = ( typeof params.status == 'undefined' || params.status == '' ) ? '' : params.status;
        this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
        this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
        this.params.is_display = ( typeof params.is_display == 'undefined' || params.is_display == '') ? '' : params.is_display;
        this.params.category_code = ( typeof params.category_code == 'undefined' ) ? '' : params.category_code;
        this.params.customer_seq = ( typeof params.customer_seq == 'undefined' ) ? '' : params.customer_seq;
        this.params.approval_yn = ( typeof params.approval_yn == 'undefined' ) ? '1' : params.approval_yn;
        this.params.safe_stock = ( typeof params.safe_stock == 'undefined' ) ? '' : params.safe_stock;
        this.params.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;
        this.params.opn = ( typeof params.opn == 'undefined' || params.opn == '' ) ? '' : params.opn;
        this.params.otc = ( typeof params.otc == 'undefined' || params.otc == '' ) ? '0' : params.otc;
      }

      // 상품 데이터 불러오기
      this.getProductMasterList();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 거래처 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerList() {
    return this.basicService.getCustomerList({}).then( response => {
      if( response.ResultCode ) {
        // this.customerList = response.data;
        const data: any = [];

        data.push({
          id: '',
          text: '거래처 선택'
        });

        for(let item of response.data ) {
          data.push({
            id: item.seq,
            text: item.comname + ' (' + item.customer_id + ')' + (item.use_yn == 'Y' ? '' : ' (X)'),
            data: item
          });
        }

        this.customerList = data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 검색조건 거래처 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedCustomerSeq(event: any) {
    this.params.customer_seq = event.id;

    this.getProductMasterList();
  }
  
  /*******************************************************************************
    설  명 : select 전용 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList2() {
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setHeaderHeight(48);
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field == 'seq' ) {
      this.productAdd( $event.data.seq );
    }
    
    if( $event.colDef.field === 'thumbnail_url' ) {
      if( $event.data.parent_status == '0' && $event.data.use_yn == '1' ) {
        const url = this.baseURL + '/product/info?productSeq=' + $event.data.seq;
        window.open("about:blank").location.href = url;
      } else {
        this.toastrService.warning('품절 상태에서는 이동할 수 없습니다.');
      }
    }
  }

  /*******************************************************************************
    설  명 : 상품등록
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  productAdd( seq: any ) {
    this.params.seq = seq;
    this.params.referrer = 'master';

    this.router.navigate(
      ['/product/list/add'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 위젯 통계 가져오기
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  getProductMasterWidget() {
    this.productService.getProductMasterWidget().then( response => {
      this.statistic = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductMasterList() {
    this.productService.getProductMasterList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.productList = response.data;
        this.params.totalCount = response.count;
      } else {
        this.toastrService.error(response.ResultMessage);
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.params = {
      pageNo: 1,
      pageRow: 20,
      totalCount: 0,
      opn: '',
      otc: 0,
      seq: '',
      searchField: 'seq',
      searchText: '',
      status: '',
      sdate: '',
      edate: '',
      is_display: '1',
      category_code: '',
      customer_seq: '',
      safe_stock: '',
      approval_yn: '1',
      referrer: ''
    };

    this.getProductMasterList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductMasterList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼에서 클릭 시 처리
    입력값 : key = this.params 키 값, value = 적용 값
    리턴값 : 없음
  *******************************************************************************/
  searchParams( key, value ) {
    this.params[key] = value;

    this.getProductMasterList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.params.sdate = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.params.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteProductList() {
    let nodes = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error('삭제할 상품을 선택하시기 바랍니다.', '상품삭제');
      return false;
    }

    if( ! confirm('선택하신 상품을 삭제하시겠습니까?' ) ) {
      return false;
    }

    let data: any = [];
    for( let node of nodes ) {
      data.push( node.seq );
    }

    this.productService.deleteProductList( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, '상품삭제');

        this.getProductMasterWidget();
        this.getProductMasterList();
      } else {
        this.toastrService.error(response.ResultMessage, '상품삭제');
      }
    });

  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onRowClicked($event) {
      this.soldoutList = $event.data.seq;
    }


  /*******************************************************************************
    설  명 : 품절 체크페이지 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goProductSoldOut() {
    const rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('상품을 선택해주세요.');
    } else if( rows.length > 1 ) {
      this.toastrService.error('상품을 하나만 선택해주세요.');
    } else {
      this.params.seq = parseInt(rows[0].seq);

      const url = '/product/list/soldout?seq=' + this.params.seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 판매현황 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goProductSalesStatus() {
    const rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('상품을 선택해주세요.');
    } else if( rows.length > 1 ) {
      this.toastrService.error('상품을 하나만 선택해주세요.');
    } else {
      this.params.seq = parseInt(rows[0].seq);

      const url = '/product/salesstatus?seq=' + this.params.seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 상품마진관리 페이지 이동
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  goProductMargin() {
    const rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('상품을 선택해주세요.');
    } else if( rows.length > 1 ) {
      this.toastrService.error('상품을 하나만 선택해주세요.');
    } else {
      this.params.seq = parseInt(rows[0].seq);

      const url = '/product/margin?searchText=' + this.params.seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    // this.params.pageNo = page;
    this.getProductMasterList();
  }
  
  /*******************************************************************************
    설  명 : 상품등록
  *******************************************************************************/
  goDetail( params: any ) {
    this.params.seq = params.data.seq;
    this.params.referrer = 'master';

    this.router.navigate(
      ['/product/list/add'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 등록상품 리스트 가져오기
  *******************************************************************************/
  getProductList( params: any ) {
    this.params.seq = '';
    this.params.pageNo = this.params.opn;
    this.params.totalCount = this.params.otc;
    this.params.opn = '';
    this.params.otc = 0;

    this.router.navigate(
      ['/product/master'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }
  
  /*******************************************************************************
    설  명 : 등록상품 리스트 가져오기
  *******************************************************************************/
  getPropertyList( params: any ) {
    this.params.seq = params.data.seq;
    this.params.opn = this.params.pageNo;
    this.params.otc = this.params.totalCount;
    this.params.pageNo = 1;
    this.params.totalCount = 0;

    this.router.navigate(
      ['/product/master'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }
  
}
