/*******************************************************************************
  설  명 : 전자결제
  작성일 : 2021-05-17
  작성자 : 송영석 
  접속URL : /basic/company/info
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { SiteConfigService } from '@app/service/site.config.service';

import { ToastrService } from 'ngx-toastr';

const CONFIG_KEY = 'PAYMENT';

@Component({
  selector: 'app-basic-payment',
  templateUrl: './basic-payment.component.html',
  styleUrls: ['./basic-payment.component.scss']
})
export class BasicPaymentComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};
  
  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      payment_use_yn: ['', [] ],
      payment_id: ['', [Validators.maxLength(20)] ],
      payment_pw: ['', [Validators.maxLength(20)] ],
      payment_card_yn: ['', [] ],
      payment_bank_yn: ['', [] ],
      payment_phone_yn: ['', [] ],
      payment_hashkey: ['', [Validators.maxLength(50)] ],
      payment_return_url: ['', [Validators.maxLength(50)] ],
      payment_naver_useyn: ['', [] ],
      payment_naver_id: ['', [Validators.maxLength(20)] ],
      payment_naver_certkey: ['', [Validators.maxLength(50)] ],
      payment_naver_btnkey: ['', [Validators.maxLength(50)] ],
      cash_auto_limit: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private siteConfigService: SiteConfigService,
    private toastrService: ToastrService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getConfig();
  }

  /*******************************************************************************
    설  명 : 환경설정 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          payment_use_yn:       response.data.payment_use_yn || '',
          payment_id:           response.data.payment_id || '',
          payment_pw:           response.data.payment_pw || '',
          payment_card_yn:      response.data.payment_card_yn || '',
          payment_bank_yn:      response.data.payment_bank_yn || '',
          payment_phone_yn:     response.data.payment_phone_yn || '',
          payment_hashkey:      response.data.payment_hashkey || '',
          payment_return_url:   response.data.payment_return_url || '',
          payment_naver_useyn:  response.data.payment_naver_useyn || '',
          payment_naver_id:     response.data.payment_naver_id || '',
          payment_naver_certkey:response.data.payment_naver_certkey || '',
          payment_naver_btnkey: response.data.payment_naver_btnkey || '',
          cash_auto_limit: response.data.cash_auto_limit || '',
        });
      }
    });
  }  

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.siteConfigService.setConfig(CONFIG_KEY, this.form).then( response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '결제정보 저장');
        else this.toastrService.error( response.ResultMessage, '결제정보 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '결제정보 저장');
    }
  }

}
