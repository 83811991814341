<section class="apage-title">
  <div class="title-bar"></div>
  <h3>{{boardInfo.board_name}}</h3>
</section>

<section class="adata-list-area">
  <div class="asub-title">
    <h4>답변달기</h4>
  </div>

  <form [formGroup]="form">
    <inputEx type="hidden" name="board_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    <inputEx type="hidden" name="board_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    <inputEx type="hidden" name="status" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <!-- 1:1문의 게시판 -->
    <section *ngIf="boardInfo.board_type == 'qna'" class="data-list">
      <table class="table table-bordered table-small">
      <caption class="hidden">게시판 답변달기</caption>

      <colgroup>
        <col style="width:200px" />
        <col style="width:*" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><i class="material-icons cred f11">star</i> <label for="subject">제목</label></th>
        <td>
          <inputEx name="subject" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="p10">문의그룹</th>
        <td class="p10">
          {{form.controls.group_id.value}}
          <inputEx type="hidden" name="group_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr *ngIf="params.board_id == 'question'">
        <th scope="row" class="p10">SMS 발송여부</th>
        <td class="p10">
          <div *ngIf="isSms == 0">SMS미발송 상태</div>
          <div *ngIf="isSms == 1">SMS발송 상태</div>
        </td>
      </tr>
      </tbody>
      </table>
    </section>
    <!-- 1:1문의 게시판 -->

    <section>
      <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="contents"></div>
      <div *ngIf="formErrors.contents != ''" class="error-message">{{formErrors.contents}}</div>

      <div class="mt10 mb30 mr10">
        <div class="fr">
          <button class="btn abtn abasic-btn mr10" (click)="goList()">취소</button>
          <button type="button" class="btn abtn abasic-btn btn-silver" (click)="submit()">저장하기</button>
        </div>

        <div class="cb"></div>
      </div>
    </section>
  </form>

</section>
