/*******************************************************************************
  설  명 : 이벤트관리 - 탭 이벤트 정보
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as $ from 'jquery';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { EventService } from '@app/service/event.service';
import { CommonService } from '@app/service/common.service';

const URL = config.apiImageUploadUrl;


@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss']
})
export class EventInfoComponent implements OnInit {

  @Input() eventSeq: any;

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public eventTypeList: any = [];
  public eventDisplayTypeList: any = [];
  public eventSupersaleList: any = [];
  public targetList: any = [];
  public couponTypeList: any = [];
  public eventCouponOrgList: any = [];
  public eventCouponList: any = [];

  public search: any = {
    sdate: '',
    edate: ''
  };

  public form: FormGroup;
  public formErrors = {};

  public uploader: FileUploader;
  public mUploader: FileUploader;
  public uploadProgress: any = 0;

  public summernoteConfig: any = {...config.summernoteConfig, ...{disableDragAndDrop: true}};

  public baseURL = config.baseUrl;

  public onChangedTargetFunc: any;
  public onChangedDisplayFunc: any;
  public onChangedSupersaleFunc: any;
  public onChangedCouponFunc: any;

  /*******************************************************************************
     설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      isDisplay: ['1', [Validators.required] ],
      is_memberDiscount: ['1', [Validators.required] ],
      event_name: ['', [Validators.required] ],
      supersale_seq: ['', [] ],
      coupon_type: ['', [] ],
      coupon_seq: ['', [] ],
      event_link: ['', [] ],
      coupon_URL: ['', [] ],
      test_start_date: [null, [] ],
      test_end_date: [null, [] ],
      start_date: [null, [Validators.required] ],
      end_date: [null, [Validators.required] ],
      event_type: ['2000', [Validators.required] ],
      event_display_type: [[], [] ],
      test_id: ['', [Validators.maxLength(20)] ],
      keyword: ['', [Validators.maxLength(255)] ],
      target: [[], [Validators.required] ],
      memo: ['', [] ],
      contents: ['', [] ],
      sort_order: ['', [] ],
      file: ['', [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
      isRecommend: [ '0', [] ],
      
      mFile: ['', [] ],
      mUpload: [ [], [] ],
      mFiles: [ [], [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private restful: RestfulService,
    private eventService: EventService,
    private commonService: CommonService,
    private toastrService: ToastrService,
  ) {

    this.summernoteConfig = {
      ...this.summernoteConfig,  // 기존 설정 유지
      dialogsInBody: true, // 이 옵션이 모달을 body 밖에 렌더링하게 합니다.
      callbacks: {
        onInit: () => {
          // 모달 백드롭 제거
          $('.note-modal').on('show.bs.modal', function () {
            $('.modal-backdrop').remove();
          });
        }
      }
    };
    this.summernoteConfig.height = 250;
    this.buildForm();

    this.onChangedTargetFunc = this.onChangedTarget.bind(this);
    this.onChangedDisplayFunc = this.onChangedDisplay.bind(this);
    this.onChangedSupersaleFunc = this.onChangedSupersale.bind(this);
    this.onChangedCouponFunc = this.onChangedCoupon.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 가져오기
    this.getCommonList();

    // 이벤트 정보 가져오기
    if (this.eventSeq !== '') {
      this.getEventDetail();
    }

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const files = [];

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: response.thumbnail,
          thumbnail_org: response.thumbnail_org,
        });

        this.form.patchValue( {files: files} );

        this.setEventSave();

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };


    // 모바일 업로더 생성
    this.mUploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.mUploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.mUploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.mUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const files = [];

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: response.thumbnail,
          thumbnail_org: response.thumbnail_org,
        });

        this.form.patchValue( {mFiles: files} );

        this.setEventSave();

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }
  
  /*******************************************************************************
    설  명 : 모바일 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  mHandleUploadFileChanged(event) {
    if(this.form.value.mFiles.length || this.form.value.mUpload.length) {
      this.toastrService.error('모바일 이미지는 최대 1개까지만 등록이 가능합니다.')
      return
    }

    this.mUploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.mUploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.mUploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }
  
  /*******************************************************************************
    설  명 : 모바일 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  mDeleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.mFiles.value;

      tmp.splice( index, 1 );

      this.form.patchValue({mFiles: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 이미지를 삭제하시겠습니까?") ) {
      this.eventService.setEventImageDelete( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.form.controls.upload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 모바일 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  mSetDeleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 이미지를 삭제하시겠습니까?") ) {
      this.eventService.setEventImageDelete( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.form.controls.mUpload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertUploadImage( file ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      this.eventService.setEventImageDefault( file ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getEventDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 대표이미지 설정
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertFilesImage( file: any ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      file.is_default = '1';
    }
  }
  
  /*******************************************************************************
    설  명 : 이벤트대상 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangedTarget( event: any ): void {
    this.form.patchValue({
      target: event
    });
  }

  /*******************************************************************************
    설  명 : 이벤트표시 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangedDisplay( event: any ): void {
    this.form.patchValue({
      event_display_type: event
    });
  }

  /*******************************************************************************
    설  명 : 이벤트타입 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEventType( value: any ) {
    this.form.get('event_type').setValue(value);
    
    if( value == '1001' ) {
      // 슈퍼세일 이벤트 리스트 가져오기
      if( this.eventSupersaleList.length < 1 ) this.getSupersaleList();

      this.form.controls.supersale_seq.setValidators( [Validators.required] );
      this.form.controls.supersale_seq.updateValueAndValidity();
      this.form.controls.coupon_seq.clearValidators();
      this.form.controls.coupon_seq.updateValueAndValidity();
    } else if( value == '3000' ) {
      // 쿠폰 리스트 데이터 가져오기
      if( this.eventCouponList.length < 1 ) this.getEventCouponList();

      this.form.controls.coupon_seq.setValidators( [Validators.required] );
      this.form.controls.coupon_seq.updateValueAndValidity();
      this.form.controls.supersale_seq.clearValidators();
      this.form.controls.supersale_seq.updateValueAndValidity();
    } else {
      this.form.controls.supersale_seq.clearValidators();
      this.form.controls.supersale_seq.updateValueAndValidity();
      this.form.controls.coupon_seq.clearValidators();
      this.form.controls.coupon_seq.updateValueAndValidity();
    }
  }

  /*******************************************************************************
    설  명 : 쿠폰타입 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCouponType( value: any ) {
    this.form.get('coupon_type').setValue(value);

    const data: any = [];
    data.push({
      id: '',
      text: '쿠폰 선택'
    });

    this.eventCouponOrgList.filter((obj: any) => obj.coupon_type == value).map((item: any) => {
      data.push({
        id: item.seq,
        text: `[ ${item.seq} ] [ ${item.coupon_type_name} ]${item.name !== null ? ` [ ${item.name}(${item.id}) ]` : ``} ${item.coupon_code}`
      });
    })

    this.eventCouponList = data;
  }
  
  /*******************************************************************************
    설  명 : 슈퍼세일 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangedSupersale( event: any ): void {
    this.form.patchValue({
      supersale_seq: ( typeof event === 'object' ) ? event.id : event
    });
  }
  
  /*******************************************************************************
    설  명 : 쿠폰 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangedCoupon( event: any ): void {
    this.form.patchValue({
      coupon_seq: ( typeof event === 'object' ) ? event.id : event
    });
  }
  
  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 이벤트 타입
    this.commonService.getCommonListCode('EVT').then( response => {
      if ( response.ResultCode ) {
        this.eventTypeList = response.data;
      } else {
        this.eventTypeList = [];
      }
    });

    // 이벤트 표시 타입
    this.commonService.getCommonListCode('EVD').then( response => {
      if ( response.ResultCode ) {
        const data: any = [];

        for(let item of response.data ) {
          data.push({
            id: item.common_code,
            text: item.common_name
          });
        }

        this.eventDisplayTypeList = data;
      } else {
        this.eventDisplayTypeList = [];
      }
    });

    // 이벤트 대상
    this.commonService.getEventCommonListCode('MBG').then( response => {
      if ( response.ResultCode ) {
        const data: any = [];

        for(let item of response.data ) {
          data.push({
            id: item.common_code,
            text: item.common_name
          });
        }

        this.targetList = data;
      } else {
        this.targetList = [];
      }
    });

    // 쿠폰 종류
    this.commonService.getCommonListCode('CPT').then( response => {
      if ( response.ResultCode ) {
        this.couponTypeList = response.data;
      } else {
        this.couponTypeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 상세 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventDetail() {
    this.eventService.getEventDetail(this.eventSeq).then( response => {
      if (response.ResultCode) {

        if( response.data.test_start_date !== null && response.data.test_start_date == '0000-00-00 00:00:00' )
          response.data.test_start_date = moment('1970-01-01').format('YYYY-MM-DD HH:mm:ss');

        if( response.data.test_end_date !== null && response.data.test_end_date == '0000-00-00 00:00:00' ) 
          response.data.test_end_date = moment('1970-01-01').format('YYYY-MM-DD HH:mm:ss');

        const upload = response.data.upload.filter((item: any) => item.isMobile != 1)
        const mUpload = response.data.upload.filter((item: any) => item.isMobile == 1)

        this.form.patchValue( {
          ...response.data,
          upload: upload,
          mUpload: mUpload
        } );

        if( response.data !== null && typeof response.data.target !== 'undefined' ) {
          const data: any = [];

          for(let item of response.data.target ) {
            data.push({
              id: item.target_code,
              text: item.target_name
            });
          }

          this.form.patchValue({
            target: data
          });
        }

        if( response.data !== null && typeof response.data.display !== 'undefined' ) {
          const data: any = [];

          for(let item of response.data.display ) {
            data.push({
              id: item.event_display_type,
              text: item.event_display_type_name
            });
          }

          this.form.patchValue({
            event_display_type: data
          });
        }

        if( response.data !== null && response.data.event_type == '1001' ) {
          // 슈퍼세일 이벤트 리스트 가져오기
          if( this.eventSupersaleList.length < 1 ) this.getSupersaleList();

          this.form.controls.supersale_seq.setValidators( [Validators.required] );
          this.form.controls.supersale_seq.updateValueAndValidity();
        }

        if( response.data !== null && response.data.event_type == '3000' ) {
          // 쿠폰 리스트 데이터 가져오기
          if( this.eventCouponList.length < 1 ) this.getEventCouponList();

          this.form.controls.coupon_seq.setValidators( [Validators.required] );
          this.form.controls.coupon_seq.updateValueAndValidity();
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 이벤트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSupersaleList() {
    this.eventService.getSupersaleList(this.search).then( response => {
      if (response.ResultCode) {
        const data: any = [];
        
        data.push({
          id: '',
          text: '이벤트 선택'
        });

        for(let item of response.data ) {
          data.push({
            id: item.seq,
            text: item.event_name
          });
        }

        this.eventSupersaleList = data;
      } else {
        this.toastrService.error( response.ResultMessage, '이벤트리스트');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 이벤트 쿠폰 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventCouponList() {
    this.eventService.getEventCouponList(this.search).then( response => {
      if (response.ResultCode) {
        this.eventCouponOrgList = response.data;

        const data: any = [];
        data.push({
          id: '',
          text: '쿠폰 선택'
        });

        for(let item of this.eventCouponOrgList ) {
          data.push({
            id: item.seq,
            text: `[ ${item.seq} ] [ ${item.coupon_type_name} ]${item.name !== null ? ` [ ${item.name}(${item.id}) ]` : ``} ${item.coupon_code}`
          });
        }

        this.eventCouponList = data;
      } else {
        this.toastrService.error( response.ResultMessage, '쿠폰 리스트');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 이벤트 데이터 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEventDelete() {
    if ( confirm('이벤트 정보를 삭제하시겠습니까?') ) {
      this.eventService.setEventDelete( this.eventSeq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.goList();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 이벤트 데이터 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEventSave() {
    if(this.form.value.event_display_type.filter((item: any) => item.id >= 4020 && 4022 >= item.id).length && !this.form.value.event_link) {
      this.toastrService.error('유튜브 이벤트는 이벤트링크에 유튜브 영상 아이디를 입력해야합니다.');
      return
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.eventService.setEventSave({value: {
        ...this.form.value,
        files: this.form.value.files.concat(this.form.value.mFiles.map((item: any) => ({...item, isMobile: 1})))
      }}).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '이벤트 저장');
          // this.goList();
          this.refresh(response.seq)
        } else {
          this.toastrService.error( response.ResultMessage, '이벤트 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '이벤트 저장');
    }
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/basic/event'],
      {
        relativeTo: this.activatedRoute,
        queryParams: { },
        queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 새로고침
  *******************************************************************************/
  refresh(seq: any) {
    setTimeout(() => this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {eventSeq: seq, eventType: this.form.value.event_type},
      queryParamsHandling: 'merge'
    }).then(() => location.reload()))
  }

}
