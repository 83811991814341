 
  <div>
    <div class="form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit();">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductList()">
        <i class="material-icons-outlined">search</i>
      </button>      

      <select [(ngModel)]="params.category_code" class="form-control form-control-small ml5" (change)="getProductList()">
        <option value="">카테고리 전체</option>
        <option *ngFor="let item of categoryList" [value]="item.category_code">{{item.category_nm}}</option>
      </select>      
  
      <l-select2
        [data]="brandList"
        (ngModelChange)="onBrandChanged($event)"
        [(ngModel)]="search.brand_seq"
        class="form-control form-control-small ml5"
        [options]="{multiple: false}"
        style="width: 200px; padding:0px !important;border:solid 0px #fff !important;"
        ></l-select2>

      <input type="text" [(ngModel)]="params.searchText" size="50" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="상품명, 대표상품번호" />

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == ''}" (click)="searchParams('is_display', '')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == '1'}" (click)="searchParams('is_display', '1')">표시</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == '0'}" (click)="searchParams('is_display', '0')">미표시</button>
      </div>
  
      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == ''}" (click)="searchParams('approval_yn', '')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == '1'}" (click)="searchParams('approval_yn', '1')">승인</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == '0'}" (click)="searchParams('approval_yn', '0')">미승인</button>
      </div>

			<div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == ''}" (click)="searchParams('status', '')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '0'}" (click)="searchParams('status', '0')">판매중</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '1'}" (click)="searchParams('status', '1')">임시품절</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '2'}" (click)="searchParams('status', '2')">영구품절</button>
      </div>
  
    </div>
  
    <div class="fl form-inline mt5">
			<input type="text" [(ngModel)]="params.searchText" size="50" (keypress)="searchList($event)" class="form-control form-control-small"  style="width: 600px;" placeholder="키워드" />
			<button type="button" class="btn btn-primary btn-small ml5">키워드추가</button>
			<button type="button" class="btn btn-default btn-small ml5">키워드변경</button>
  
      <!-- <button type="button" class="btn btn-info btn-small ml5" (click)="setProductApproveSave()">승인처리</button>
      <button type="button" class="btn btn-secondary btn-small ml5" (click)="setProductUnApproveSave()">미승인처리</button> -->
    </div>
    <div class="fr right mt5">
      <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi" gridId="59f6a2a1aeb14ab4aee2c46ed0144d5a" [btnGroup]="1"></btn-ag-grid-save>
  
      <ng-container *ngIf="authService.currAuth['auth_excel']=='1'">
        <ag-grid-excel [gridApi]="gridApi" selected="true" filename="상품리스트" title="상품리스트"></ag-grid-excel>
        <ag-grid-excel [gridApi]="gridApi" selected="false" filename="상품리스트" title="상품리스트"></ag-grid-excel>
      </ng-container>
  
      <!-- <ng-container *ngIf="authService.currAuth['auth_delete']=='1'">
        <button type="button" class="btn btn-danger btn-small ml5" (click)="deleteProductList()">상품삭제</button>
      </ng-container>
  
      <ng-container *ngIf="authService.currAuth['auth_write']=='1'">
        <button type="button" class="btn btn-primary btn-small ml5" (click)="productAdd(0)">상품등록</button>
      </ng-container> -->
    </div>
    <div class="cb"></div>
  </div>
  
  <div class="mt5" style="height:calc(100% - 80px)">
    <ag-grid-angular
      #myGrid
  
      style="width: 100%;height:100%;"
      class="ag-theme-balham"
  
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="productList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [getRowHeight]="getRowHeight"
      [pagination]="false"
      [rowMultiSelectWithClick]="true"
  
      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      (rowClicked)="onRowClicked($event)"
      >
    </ag-grid-angular>
    <section class="pagination mt10" *ngIf="params.totalCount">
      <ngb-pagination
        [(page)]="params.pageNo"
        [pageSize]="params.pageRow"
        [collectionSize]="params.totalCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
  </div>