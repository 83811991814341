import { Injectable } from '@angular/core';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 휴먼회원 정보 가져오기
  getBasicMemberDormant(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'getBasicMemberDormant'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  // 휴먼회원 설정 저장하기
  setBasicMemberDormant( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'setBasicMemberDormant'
      }, form.value
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }
  
  // 가맹/입점문의 가져오기
  getContact(search: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'getContact'
      }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 가맹/입점문의 관리자메모 저장
  setContactMemo( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'setContactMemo'
      }, data
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 가맹/입점문의 상태 저장
  setContactStatus( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'setContactStatus'
      }, data
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 가맹/입점문의 삭제
  delContact( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'company',
        version: '1.0',
        action: 'delContact'
      }, data
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

}
