/*******************************************************************************
  설  명 : 거래처관리 - 거래처정보
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbModalOptions, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import { CustomValidator } from '@app/service/custom.validators';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { BasicService } from '@app/service/basic.service';
import { BrandService } from '@app/service/brand.service';
import { EmployeeService } from '@app/service/employee.service';

import { MemberFindComponent } from '@components/member-find/member-find.component';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-customer-tab-info',
  templateUrl: './customer-tab-info.component.html',
  styleUrls: ['./customer-tab-info.component.scss']
})
export class CustomerTabInfoComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};
  public modal: any;

  public seq: any;
  public customerInfo: any = {};

  public addMemberFunc: any;

  public dealTypeList: any = [];
  public deliveryList: any = [];
  public productGbnList: any = [];
  public originEmployeeList: any = [];
  public employeeList: any = [];
  public discountList: any = [
    '3+1=0',
    '3+1=5',
    '3+1=10',
    'supersale=0',
    'supersale=5',
    'supersale=10'
  ];
  public brandList: any = [];

  private dateModel: NgbDateStruct;

  public changeWorkerFunc: any;   // 담당자 변경시

  @Input() set customerSeq(value: number) {
    this.seq = value;

    if( typeof this.seq != 'undefined' ) {
      this.getCustomerDetail();
    }
  }

  @Output() dataSave = new EventEmitter();

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public select2Options: any = {
    multiple: true
  };

  public summernoteConfig = {
    placeholder: '',
    tabsize: 2,
    height: 150,
    uploadImagePath: '',
    toolbar: [
        // [groupName, [list of button]]
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'link', 'video', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  public onChangedFunc: any;


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      nation_gbn: ['D', [Validators.required] ],
      customer_id: ['', [CustomValidator.blank] ],
      password: ['', [] ],
      confirmPassword: ['', [] ],
      comname: ['', [Validators.required] ],
      product_gbn: ['2000', [Validators.required] ],
      brand: [[], [] ],
      ceoname: ['', [Validators.required] ],
      ownerhp: ['', [] ],
      worker_mem_no: ['', [] ],
      worker: ['', [] ],
      workerhp: ['', [] ],
      tel: ['', [] ],
      fax: ['', [] ],
      zipcode: ['', [] ],
      address: ['', [] ],
      address_detail: ['', [] ],
      homepage: ['', [] ],
      get_page: ['', [] ],
      use_yn: ['', [] ],
      descr: ['', [] ],
      customer_type: ['', [] ],
      consign_type: ['', [] ],
      consign_fee: ['', [] ],
      consign_limit_amt: ['', [] ],
      consign_com: ['', [] ],
      consign_com_name: ['', [] ],
      consign_com_ename: ['', [] ],
      product_upload: ['', [] ],
      jungsan_date: [null, [] ],
      margin: ['', [] ],
      account_holder: ['', [] ],
      event_discount: ['', [] ],
      key_index: ['', [] ],
      busi_num: ['', [] ],
      order_sms: ['', [] ],
      bank: ['', [] ],
      bank_account: ['', [] ],
      contact_date: [null, [] ],
      contact_emp: ['', [] ],
      contact_margin_rate: ['', [] ],
      contact_shipping_com: ['', [] ],
      contact_shipping_fee: ['', [] ],
      contact_calc_date: [null, [] ],
      contact_exchange_cond: ['', [] ],
      contact_return_cond: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private basicService: BasicService,
    private employeeService: EmployeeService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private brandService: BrandService,
  ) {
    this.buildForm();

    this.addMemberFunc = this.searchMember.bind(this);
    this.onChangedFunc = this.onChanged.bind(this);

  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();
    this.getEmployeeList();
    this.getBrandList();

    //모달 창일 경우 정보 바인딩
    if ( this.seq ) {
      this.getCustomerDetail();
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 거래형태
    this.commonService.getCommonListCode('DLT').then( response => {
      if ( response.ResultCode ) {
        this.dealTypeList = response.data;
      } else {
        this.dealTypeList = [];
      }
    });

    // 배송업체
    this.commonService.getCommonListCode('DLC').then( response => {
      if ( response.ResultCode ) {
        this.deliveryList.push({value: '', title: '배송업체를 선택하세요.'});

        response.data.forEach( row => {
          this.deliveryList.push({
            value: row.common_code,
            title: row.common_name,
            etitle: row.common_ename
          });
        });

      } else {
        this.deliveryList = [];
      }
    });

    // 매입상품구분
    this.commonService.getCommonListCode('PPG').then( response => {
      if ( response.ResultCode ) {
        this.productGbnList = response.data;
      } else {
        this.productGbnList = [];
      }
    });
  }

  changeDelivery() {
    const delivery = this.deliveryList.filter(item => item.value === this.form.value.consign_com);

    if( delivery[0].value ) {
      this.form.patchValue({
        consign_com_name: delivery[0].title,
        consign_com_ename: delivery[0].etitle
      });
    }
  }

  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        mem_no: result.data.mem_no,
        customer_id: result.data.id,
        zipcode: result.data.zipcode,
        address: result.data.address,
        address_detail: result.data.address_detail
      });

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 직원 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployeeList() {
    this.employeeService.getEmployeeList().then( response => {
      if( response.ResultCode ) {
        this.originEmployeeList = response.data;

        this.employeeList.push({
          title: '선택',
          value: 0
        });

        this.originEmployeeList.forEach(item => {
          this.employeeList.push({
            title: item.name,
            value: item.mem_no
          });
        });

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    let search = {};
    this.brandService.getBrandListForComponent( search ).then( response => {
      if ( response.ResultCode ) {
        const data: any = [];

        for(let item of response.data ) {
          data.push({
            id: item.seq,
            text: item.brand_name
          });
        }

        this.brandList = data;
      } else {
        this.brandList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 매장 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerDetail() {
    this.basicService.getCustomerDetail( this.seq ).then( response => {
      if( response.ResultCode ) {
        this.customerInfo = response.data;

        // SCM 아이디 공백제거
        this.customerInfo.customer_id = response.data.customer_id.trim();

        // 계약일자
        if( this.customerInfo.contact_date != '' && this.customerInfo.contact_date != null ) {
          if( typeof this.customerInfo.contact_date != 'object' ) {
            this.dateModel = {
              year: parseInt( this.customerInfo.contact_date.substr( 0, 4 ) ),
              month: parseInt( this.customerInfo.contact_date.substr( 5, 2 ) ),
              day: parseInt( this.customerInfo.contact_date.substr( 8, 2 ) )
            };

            this.customerInfo.contact_date = this.dateModel;
          }
        } else {
          this.customerInfo.contact_date = null;
        }

        // 정산일자
        if( this.customerInfo.contact_calc_date != '' && this.customerInfo.contact_calc_date != null ) {
          if( typeof this.customerInfo.contact_calc_date != 'object' ) {
            this.dateModel = {
              year: parseInt( this.customerInfo.contact_calc_date.substr( 0, 4 ) ),
              month: parseInt( this.customerInfo.contact_calc_date.substr( 5, 2 ) ),
              day: parseInt( this.customerInfo.contact_calc_date.substr( 8, 2 ) )
            };

            this.customerInfo.contact_calc_date = this.dateModel;
          }
        } else {
          this.customerInfo.contact_calc_date = null;
        }

        this.form.patchValue( this.customerInfo );

        // 브랜드 셋팅
        this.form.patchValue({
          brand: response.brand
        });

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChanged( event: any ): void {
    this.form.patchValue({
      brand: event
    });
  }

  /*******************************************************************************
    설  명 : 거래처 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCustomerInfoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setCustomerInfoSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.dataSave.emit();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

}