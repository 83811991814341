import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approval-comment',
  templateUrl: './approval-comment.component.html',
  styleUrls: ['./approval-comment.component.scss']
})
export class ApprovalCommentComponent implements OnInit {

  public selectedItem: any;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
