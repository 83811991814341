<section class="mt5" style="height: calc(50% - 10px);">
      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">공통코드 - {{selectedItem.name}}</h4>
        </div>
        <div class="fr w50p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApiCommon" gridId="7eea0259bcca42fa9b8f69daf4cdf8cf" [btnGroup]="1" class="mr5"></btn-ag-grid-save>
            <button type="button" class="btn btn-primary btn-small" (click)="addCommon({seq:0}, 'DLC')">공통코드 추가</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="commonList"
          [pagination]="false"
          [domLayout]="domLayoutCommon"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (rowClicked)="onRowClickedCommon($event, 'DLC')"
          (gridReady)="onGridReadyCommon($event)"
        >
        </ag-grid-angular>
      </div>
</section>

<section class="mt5" style="height: calc(50% - 10px);">
  <div class="">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">공통코드 - {{selectedItemShop.name}}</h4>
    </div>
    <div class="fr w50p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApiCommonShop" gridId="7eea0259bcca42fa9b8f69daf4cdf8cf" [btnGroup]="1" class="mr5"></btn-ag-grid-save>
        <button type="button" class="btn btn-primary btn-small" (click)="addCommon({seq:0}, 'SHP')">공통코드 추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 35px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs1"
      [defaultColDef]="defaultColDef1"
      [rowData]="commonListShop"
      [pagination]="false"
      [domLayout]="domLayoutCommonShop"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"

      (rowClicked)="onRowClickedCommon($event, 'SHP')"
      (gridReady)="onGridReadyCommonShop($event)"
    >
    </ag-grid-angular>
  </div>
</section>
