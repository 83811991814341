/*******************************************************************************
  설  명 : 직원관리
  작성일 : 2019-09-22
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { SystemGroupService } from '@app/service/system.group.service';
import * as $ from 'jquery';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';

import { SystemPositionService } from '@app/service/position.service';
import { SystemAuthGroupService } from '@app/service/auth.group.service';
import { EmployeeService } from '@app/service/employee.service';

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit, AfterViewInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public baseURL = config.baseUrl;

  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });

  // public employeePositionList = [];
  public groupList: any = [];
  public positionList: any = [];
  public authGroupList: any = [];

  public form: FormGroup;
  public formErrors = {};

  private dateModel: NgbDateStruct;
  public uploadProgress: any = 0;

  public member_info = {
    mem_no: '',
    id: '',
    level: '',
    password: '',
    name: '',
    email: '',
    birthdate: null,
    birth_sec: 'S',
    joindate: null,
    leavedate: null,
    dept_seq: '',
    dept_cd: '',
    position_seq: '',
    tel: '',
    hp: '',
    zipcode: '',
    address: '',
    address_detail: '',
    extension_number: '',
    emergency_tel: '',
    auth_group_seq: '',
    picture: '',
    picture_url: '/assets/images/no_image.png',
    picture_origin: '',
  };

  /* 노드관련 변수 */
  public nodeData: any = [];
  public selectedNode = {
    seq: '',
    dept_cd: '',
    dept_name: '',
    memo: '',
    type: '',
    use_yn: true,
    data: {},
    children: []
  };
  public selectedTree:any = {};

  public funcMemberFind:any;

  isAddCheck:any = 0;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      mem_no: [ '', [] ],
      id: [ '', [Validators.required, Validators.maxLength(20)] ],
      level: [ '', [] ],
      password: [ '', [Validators.required] ],
      name: [ '', [Validators.required, Validators.maxLength(50)] ],
      email: [ '', [Validators.maxLength(100)] ],
      birthdate: [ null, [] ],
      birth_sec: [ 'S', [] ],
      joindate: [ null, [] ],
      leavedate: [ null, [] ],
      dept_seq: [ '', [Validators.required] ],
      position_seq: [ '', [] ],
      dept_cd: '',
      tel: [ '', [Validators.maxLength(20)] ],
      hp: [ '', [Validators.maxLength(20)] ],
      extension_number: [ '', [Validators.maxLength(20)] ],
      emergency_tel: [ '', [Validators.maxLength(100)] ],
      auth_group_seq: [ '', [Validators.required] ],
      zipcode: [ '', [] ],
      address: [ '', [Validators.maxLength(255)] ],
      address_detail: [ '', [Validators.maxLength(255)] ],
      picture: [ '', [] ],
      picture_url: ['/assets/images/no_image.png', [] ],
      picture_origin: [ '', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 사용자정보 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  init_member_info() {
    this.member_info = {
      mem_no: '',
      id: '',
      level: '',
      password: '',
      name: '',
      email: '',
      birthdate: null,
      birth_sec: 'S',
      joindate: null,
      leavedate: null,
      dept_seq: '',
      position_seq: '',
      dept_cd: '',
      tel: '',
      hp: '',
      zipcode: '',
      address: '',
      address_detail: '',
      extension_number: '',
      emergency_tel: '',
      auth_group_seq: '',
      picture: '',
      picture_url: '/assets/images/no_image.png',
      picture_origin: '',
    };
  }

  /*******************************************************************************
    설  명 : 이미지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteImage() {
    this.form.patchValue({
      picture_url: '/assets/images/no_image.png',
      picture_origin: ''
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private employeeService: EmployeeService,
    private systemPositionService: SystemPositionService,
    private systemAuthGroupService: SystemAuthGroupService,
    public authService: AuthService,
    private systemGroupService: SystemGroupService,

  ) {
    this.funcMemberFind = this.memberFind.bind(this);

    this.init_member_info();

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

    // 업로드 허용 파일 설정
    const filetype: string[] = ['image'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 5 * (1024 * 1024), // 최대 업로드 허용 5MB 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if ( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if ( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if ( response.result ) {
        this.toastrService.success( response.message, '');

        this.form.patchValue( {
          picture_url: this.baseURL + response.url,
          picture_origin: response.origin_filename
        });

      } else {
        this.toastrService.error( response.message, '');
      }
    };
  }

  /*******************************************************************************
    설  명 : 기존 회원 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  memberFind() {
    if( this.form.controls.id.value == '' ) {
      this.toastrService.error( "검색할 회원의 아이디를 입력하세요", "회원검색" );
      return false;
    }

    this.employeeService.getEmployeeInfoId( this.form.controls.id.value ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          mem_no: response.data.mem_no,
          id: response.data.id,
          level: response.data.level,
          password: response.data.password,
          name: response.data.name,
          email: response.data.email,
          birthdate: this.utilService.getDate(response.data.birthdate),
          joindate: this.utilService.getDate(response.data.joindate),
          dept_seq: response.data.dept_seq,
          position_seq: response.data.position_seq,
          tel: response.data.tel,
          hp: response.data.hp,
          extension_number: response.data.extension_number,
          emergency_tel: response.data.emergency_tel,
          picture: response.data.picture,
          picture_url: response.data.picture_url,
          picture_origin: response.data.picture_origin
        });

      } else {
        this.toastrService.error( response.ResultMessage, "회원검색" );
      }
    });
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for (const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;
    this.uploader.addToQueue(filteredFiles, options, filters);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngAfterViewInit() {
    // 조직도 가져오기
    this.getGroupList();

    // 직위 가져오기
    this.getPositionList();

    // 권한그룹 가져오기
    this.getAuthGroupList();

    // 사원 조직도 리스트 가져오기
    this.getGroupEmployeeList();
  }

  /*******************************************************************************
    설  명 : 사원정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
      if (this.form.valid) {
      this.employeeService.setEmployee(this.form).then( async response => {

        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '사원정보 저장');

          this.getGroupEmployeeList();
        } else {
          this.toastrService.error( response.ResultMessage, '사원정보 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '사원정보 저장');
    }
  }

  /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기 (TreeView)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeList() {
    this.systemGroupService.getGroupEmployeeList().then( response => {
      if ( response.ResultCode ) {
        this.nodeData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupList() {
    this.employeeService.getGroupList().then( response => {
      if (response.ResultCode) {
        response.data.forEach( row => {
          this.groupList.push({
            title: row.select_name,
            value: row.seq,
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 직위 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPositionList() {
    this.systemPositionService.getPositionList().then( response => {
      if (response.ResultCode) {
        response.data.forEach( row => {
          this.positionList.push({
            title: row.name,
            value: row.seq
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAuthGroupList() {
    this.systemAuthGroupService.getAuthGroupList().then( response => {
      if (response.ResultCode) {
        response.data.forEach( row => {
          this.authGroupList.push({
            title: row.name,
            value: row.seq
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 직원 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployeeInfo( seq ) {
    this.employeeService.getEmployeeInfo( seq ).then( response => {
      this.member_info = response.data;

      if ( this.member_info.birthdate !== null ) {
        this.dateModel = {
          year: parseInt( this.member_info.birthdate.substr( 0, 4 ), 10 ),
          month: parseInt( this.member_info.birthdate.substr( 5, 2 ), 10 ),
          day: parseInt( this.member_info.birthdate.substr( 8, 2 ), 10 )
        };

        this.member_info.birthdate = this.dateModel;
      }

      if ( this.member_info.joindate !== null ) {
        this.dateModel = {
          year: parseInt( this.member_info.joindate.substr( 0, 4 ), 10 ),
          month: parseInt( this.member_info.joindate.substr( 5, 2 ), 10 ),
          day: parseInt( this.member_info.joindate.substr( 8, 2 ), 10 )
        };

        this.member_info.joindate = this.dateModel;
      }

      if ( this.member_info.leavedate !== null ) {
        this.dateModel = {
          year: parseInt( this.member_info.leavedate.substr( 0, 4 ), 10 ),
          month: parseInt( this.member_info.leavedate.substr( 5, 2 ), 10 ),
          day: parseInt( this.member_info.leavedate.substr( 8, 2 ), 10 )
        };

        this.member_info.leavedate = this.dateModel;
      }

      this.form.patchValue( this.member_info );
    });
  }

  /*******************************************************************************
    설  명 : 직원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addEmployee() {
    this.init_member_info();

    this.form.patchValue( this.member_info );
  }

  /*******************************************************************************
    설  명 : 직원 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteEmployee() {
    if( this.form.controls.mem_no.value == '' ) {
      this.toastrService.error( '삭제할 직원을 선택하세요.', '');

      return false;
    }

    if( confirm("선택한 직원를 삭제하시겠습니까?") ) {
      this.employeeService.deleteEmployee( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.init_member_info();

          this.form.patchValue( this.member_info );

          this.getGroupEmployeeList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 다음 주소 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $('#address_detail').focus();
  }

  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async onSelectNode($event) {

    this.selectedTree = $event;

    // 사원 선택 시 처리
    if ( $event.mem_no != null ) {
      await this.getEmployeeInfo( this.selectedTree.mem_no );

      this.isAddCheck = 1;

    // 부서 클릭 시 처리
    } else {
      this.init_member_info();

      this.member_info.dept_seq = this.selectedTree.seq;

      this.form.patchValue( this.member_info );

      this.isAddCheck = 2;
    }
  }

  /*******************************************************************************
    설  명 : 노드추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onAddNode($event) {
    this.init_member_info();
    const selectedNode = $event.selectedNode;

    if( selectedNode.type == undefined && selectedNode.type !== 'd' ) {
      this.toastrService.error("사원을 추가할 부서를 선택하시기 바랍니다.", "사원추가");

      return false;
    }

    this.member_info.dept_seq = selectedNode.type === 'd' ? selectedNode.seq : selectedNode.dept_seq;
    this.member_info.dept_cd = selectedNode.dept_cd;

    this.form.patchValue(this.member_info);
  }
}

