import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';

import { MemberService } from '@app/service/member.service';
import { UtilService } from '@app/service/util.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-find',
  templateUrl: './member-find.component.html',
  styleUrls: ['./member-find.component.scss']
})
export class MemberFindComponent implements OnInit {

  // 그리드 변수 선언
  domLayout: any;
  memberList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;

  noRowsTemplate: string;

  // 폼 변수 선언
  public form: FormGroup;
  public formErrors = {
    searchText: ''
  };

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  buildForm(): void {
    this.form = this.formBuilder.group({
      searchText: ['', [Validators.required, Validators.maxLength(50)]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  constructor(
    private formBuilder: FormBuilder,
    private memberService: MemberService,
    public activeModal: NgbActiveModal,
    private utilService: UtilService,
  ) {
    this.buildForm();

    // 그리드 컬럼 선언
    this.columnDefs = [
      { headerName: '회원번호', field: 'mem_no', cellClass: 'cp center', width: 100 },
      { headerName: '구분', field: 'level', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.level == '0' ) return '<span class="badge badge-secondary f12">비회원</span>';
          else return '<span class="badge badge-success f12">일반회원</span>';
        }
      },
      { headerName: '이름', field: 'name', cellClass: 'cp center', width: 130 },
      { headerName: '아이디', field: 'id', cellClass: 'cp center', width: 130 },
      { headerName: '성별', field: 'gender', cellClass: 'cp center', width: 80,
        valueGetter: function(params) {
          if( params.data.gender == 'M' ) return '남성';
          else return '여성';
        }
      },
      { headerName: '휴대전화', field: 'hp', cellClass: 'cp center', width: 130 },
      { headerName: '이메일', field: 'email', cellClass: 'cp center', width: 200 },
    ];

    // 컬럼 디폴트 값 선언
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: false
    };

    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    $("#searchText").focus();
  }

  // 그리드 준비 시 처리
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  // 그리드 row 클릭 시 처리
  onRowClicked($event) {
    this.activeModal.close( $event );
  }

  // 처음 데이터 렌더링 시 처리
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // 회원검색
  getACMemberList() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.memberService.getACMemberList( this.form.value.searchText ).then( response => {
        this.memberList = response.data;
      });
    }
  }
}
