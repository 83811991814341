<form [formGroup]="form">
  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">기본정보</h4>
    </div>
    <div class="fr w50p right">
      <button type="submit" class="btn btn-primary btn-small" (click)="submit()">저장하기</button>
    </div>
    <div class="cb"></div>
  </div>  
  
  <table class="table table-bordered table-small1 mb0">
  <caption class="none">기본정보</caption>
  <colgroup>
    <col style="width:10%" />
    <col style="width:23%" />
    <col style="width:10%" />
    <col style="width:23%" />
    <col style="width:10%" />
    <col style="width:23%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="company_name">회사명(국문)</label></th>
    <td><inputEx type="text" name="company_name" size="40" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
    <th scope="row"><label for="company_ename">회사명(영문)</label></th>
    <td><inputEx type="text" name="company_ename" size="40" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
    <th scope="row"><label for="domain">대표도메인</label></th>
    <td><inputEx type="text" name="domain" [formGroup]="form" [formErrors]="formErrors" maxlength="50" placeholder="http:// or https://"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="homepage">홈페이지주소</label></th>
    <td><inputEx type="text" name="homepage" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
    <th scope="row"><label for="admin_page">관리자도메인</label></th>
    <td><inputEx type="text" name="admin_page" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
    <th scope="row"><label for="scm_page">SCM도메인</label></th>
    <td><inputEx type="text" name="scm_page" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
  </tr>
  </tbody>
  </table>

  <h4 class="fb mt10 mb5">사업자정보</h4>

  <table class="table table-bordered table-small1 mb0">
  <caption class="none">사업자정보</caption>
  <colgroup>
    <col style="width:10%" />
    <col style="width:23%" />
    <col style="width:10%" />
    <col style="width:23%" />
    <col style="width:10%" />
    <col style="width:23%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="shop_name">쇼핑몰명</label></th>
    <td><inputEx type="text" name="shop_name" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
    <th scope="row"><label for="ceo_name">대표자명</label></th>
    <td><inputEx type="text" name="ceo_name" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
    <th scope="row"><label for="trans_bizno">통신판매업신고번호</label></th>
    <td><inputEx type="text" name="trans_bizno" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="biz_no">사업자번호</label></th>
    <td><inputEx type="text" name="biz_no" [formGroup]="form" [formErrors]="formErrors" maxlength="13"></inputEx></td>
    <th scope="row"><label for="biz_type">업태</label></th>
    <td>
      <inputEx type="text" name="biz_type" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx>
    </td>
    <th scope="row"><label for="biz_category">종목</label></th>
    <td>
      <inputEx type="text" name="biz_category" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="">우편번호</label></th>
    <td>
	    <div class="input-group">
	      <inputEx name="zipcode" class="input_zipcode" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="우편번호 찾기를 이용하세요"></inputEx>
	      <btn-daum-address [options]="daumAddressOptions" (result)="setDaumAddressApi($event)"></btn-daum-address>
	    </div>
	  </td>
    <th scope="row"><label for="address">주소</label></th>
    <td><inputEx type="text" name="address" [formGroup]="form" [formErrors]="formErrors" readonly="true" placeholder="우편번호 찾기를 이용하세요"></inputEx></td>
    <th scope="row"><label for="address_detail">상세주소</label></th>
    <td><inputEx type="text" name="address_detail" [formGroup]="form" [formErrors]="formErrors" maxlength="255"></inputEx></td>
	</tr>
  <tr>
    <th scope="row"><label for="tel">대표전화</label></th>
    <td><inputEx type="text" name="tel" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
    <th scope="row"><label for="fax">대표팩스</label></th>
    <td><inputEx type="text" name="fax" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
    <th scope="row"><label for="email">대표이메일</label></th>
    <td><inputEx type="text" name="email" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
  </tr>	
  <tr>
    <th scope="row"><label for="cs_tel1">고객센터 연락처1</label></th>
    <td><inputEx type="text" name="cs_tel1" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
    <th scope="row"><label for="cs_tel2">고객센터 연락처2</label></th>
    <td><inputEx type="text" name="cs_tel2" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
    <th scope="row"><label for="contant_manager">CONTACT 담당자연락처</label></th>
    <td><inputEx type="text" name="contant_manager" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
  </tr> 
  </tbody>
  </table>

  <h4 class="fb mt10 mb5">개인정보보호</h4>

  <table class="table table-bordered table-small1 mb0">
  <caption class="none">개인정보보호</caption>
  <colgroup>
    <col style="width:10%" />
    <col style="width:23%" />
    <col style="width:10%" />
    <col style="width:23%" />
    <col style="width:10%" />
    <col style="width:23%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="privacy_manager">개인정보보호책임자</label></th>
    <td><inputEx type="text" name="privacy_manager" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
    <th scope="row"><label for="privacy_manager_tel">개인정보보호책임자 연락처</label></th>
    <td><inputEx type="text" name="privacy_manager_tel" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
    <th scope="row"><label for="privacy_manager_email">개인정보보호책임자 이메일</label></th>
    <td><inputEx type="text" name="privacy_manager_email" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="privacy_charge">개인정보보호담당자</label></th>
    <td><inputEx type="text" name="privacy_charge" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
    <th scope="row"><label for="privacy_charge_tel">개인정보보호담당자 연락처</label></th>
    <td><inputEx type="text" name="privacy_charge_tel" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
    <th scope="row"><label for="privacy_charge_email">개인정보보호담당자 이메일</label></th>
    <td><inputEx type="text" name="privacy_charge_email" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
  </tr>
  </tbody>
  </table>
  
  <h4 class="fb mt10 mb5">거래명세서 및 견적서</h4>

  
  <table class="table table-bordered table-small1 mb0">
  <caption class="none">거래명세서 및 견적서</caption>
  <colgroup>
    <col style="width:10%" />
    <col style="width:23%" />
    <col style="width:10%" />
    <col style="width:23%" />
    <col style="width:10%" />
    <col style="width:23%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="">인감이미지</label></th>
    <td colspan="5">
      <ng-container *ngIf="isLoading">
        <div class="fl mr10" style="width:100px;">
          <img *ngIf="form.controls.stamp_url.value != null && form.controls.stamp_url.value != ''" [src]="form.controls.stamp_url.value" style="width:80px;height:80px;" />
          <img *ngIf="form.controls.stamp_url.value == null || form.controls.stamp_url.value == ''" src="/assets/images/no_image.png" style="width:80px;height:80px;" />
          <div *ngIf="form.controls.stamp_url.value != '/assets/images/no_image.png' && form.controls.stamp_url.value != null && form.controls.stamp_url.value != ''" class="mt5">
            <button type="button" class="btn btn-danger btn-small" (click)="deleteImage();">삭제</button>
          </div>
        </div>

        <div class="fl">
          <input type="file" name="stamp" id="stamp" formControlName="stamp" ng2FileSelect (change)="handleUploadFileChanged($event)" />

          <input type="hidden" name="stamp_url" id="stamp_url" formControlName="stamp_url" />
          <input type="hidden" name="stamp_origin" id="stamp_origin" formControlName="stamp_origin" />
        </div>

        <div class="cb"></div>

        <div class="progress mt5 mb5">
          <div class="progress-bar"></div>
        </div>
        <div class="mt5">파일형식 jpg, gif, png</div>
      </ng-container>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="">견적서</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.estimate.value=='1'}" (click)="form.patchValue({estimate: '1'})">사용함</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.estimate.value=='0'}" (click)="form.patchValue({estimate: '0'})">사용안함</button>
      </div>    

      <inputEx type="hidden" name="estimate" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
    <th scope="row"><label for="">거래명세서</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.spectifications.value=='1'}" (click)="form.patchValue({spectifications: '1'})">사용함</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.spectifications.value=='0'}" (click)="form.patchValue({spectifications: '0'})">사용안함</button>
      </div>    

      <inputEx type="hidden" name="spectifications" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
    <th scope="row"></th>
    <td></td>
  </tr>
  </tbody>
  </table>  

</form>
