<section id="sms">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="16" minSize="6" maxSize="26">
      <div style="height:calc(100% - 75px)">
        <div class="sms-image-div">
          <form [formGroup]="form" class="h100">
            <div class="phone-image">
              <div class="phone-title">
                SMS 발송양식
              </div>
              <div class="pl20 pt30 pr20 pb10">
                <inputEx name="title" [autofocus]="true" [formGroup]="form" [formErrors]="formErrors" placeholder="SMS 양식 제목"></inputEx>
              </div>
              <div class="pl20 pr20">
                <inputEx type="select" class="pt10" name="auto_seq" [formGroup]="form" [formErrors]="formErrors" [data]="autoList"></inputEx>
              </div>
              <div>
                <textarea style='height: 80;' formControlName="contents" rows="10" cols="20"></textarea>
                <div *ngIf="formErrors.contents" class="error-message">{{formErrors.contents}}</div>
                <div class="pl20 pr20 pt10 f12">{{getTextLength(form.controls.contents.value)}} / 80 글자</div>
              </div>
              <div class="pl20 pr20 pt10">
                <button type="button" class="btn btn-primary btn-small w100p" (click)="submit();">저장하기</button>
              </div>
              <div *ngIf="form.controls.seq.value != ''" class="pl20 pr20 pt10">
                <button type="button" class="btn btn-danger btn-small w100p" (click)="delSMSForm();">삭제하기</button>
              </div>
            </div>
          </form>
        </div>
        <!-- <div class="sms-explain-div">
          <h4>사용가능 변수</h4>
          <p>#고객명</p>
          <p>#오늘날짜</p>
          <p>#결제일</p>
          <p>#신청일</p>
          <p>#아이디</p>
          <p>#결제금액</p>
        </div> -->
      </div>
    </as-split-area>

    <as-split-area size="84" minSize="74" maxSize="94">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" (click)="searchInit()" class="btn btn-default btn-small-icon">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" (click)="getSMSFormList()" class="btn btn-default btn-small-icon ml5">
            <i class="material-icons-outlined">search</i>
          </button>
          <input type="text" [(ngModel)]="search.searchText" size="20" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="제목 or 내용 검색" />
        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="096d6c11d10f4b4bbe891149805b7775" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="addSMSForm()">등록하기</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="smsList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [rowSelection]="rowSelection"

          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged($event)"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
