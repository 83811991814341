<ng-container *ngIf="type=='SMS'">
  <button type="button" class="ml5 btn btn-success btn-small f12" (click)="sendSMS()">SMS발송</button>
</ng-container>
<ng-container *ngIf="type=='EMAIL'">
  <button type="button" class="ml5 btn btn-success btn-small f12" (click)="sendEmail()">Email발송</button>
</ng-container>
<ng-container *ngIf="type=='PUSH'">
  <button type="button" class="ml5 btn btn-success btn-small f12" (click)="sendPush()">PUSH발송</button>
</ng-container>
<ng-container *ngIf="type=='orderSMS'">
  <button type="button" class="ml5 btn btn-success btn-small f12" (click)="sendSMS()">자유메시지 SMS발송</button>
</ng-container>