<div class="modal-header">
    <h4 class="modal-title fb mb0">바마쿠폰 추가</h4>
    <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
  </div>
  
  <div class="modal-body">
  
    <div class="">
      <div class="fl w50p">
        <h4 class="fb mt5 mb6">바마쿠폰 정보</h4>
      </div>
      <div class="fr w50p right">
  
      </div>
      <div class="cb"></div>
    </div>
  
    <form [formGroup]="form">
  
      <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
  
      <table class="table table-bordered table-small">
      <caption class="none">바마쿠폰</caption>
      <colgroup>
        <col style="width:20%;" />
        <col style="width:30%;" />
        <col style="width:20%;" />
        <col style="width:30%;" />
      </colgroup>
  
      <tbody>
        <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="coupon_name">쿠폰명</label></th>
            <td>
              <inputEx type="text" name="coupon_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="coupon_kind">쿠폰종류</label></th>
            <td>
              <inputEx type="select" name="coupon_kind" [formGroup]="form" [formErrors]="formErrors" [data]="couponKindList" (change)="changeCouponKind()"></inputEx>
            </td>
          </tr>
          <tr>
              <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="give_sdate">쿠폰지급시작일</label></th>
              <td><inputEx type="date" name="give_sdate" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
              <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="give_edate">쿠폰지급종료일</label></th>
              <td><inputEx type="date" name="give_edate" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
          </tr>
          <tr>
              <th scope="row"><label for="give_type">지급적용구분</label></th>
              <td>
                  <inputEx type="select" name="give_type" [formGroup]="form" [formErrors]="formErrors" [data]="giveTypeList" (change)="changeGiveType()"></inputEx>
              </td>
              <th scope="row"><label for="sale_type">판매적용구분</label></th>
              <td>
                  <inputEx type="select" name="sale_type" [formGroup]="form" [formErrors]="formErrors" [data]="saleTypeList" (change)="changeSaleType()"></inputEx>
              </td>
          </tr>
          <tr>
              <th scope="row"><label for="apply_type">할인적용</label></th>
              <td>
                  <div class="btn-group" style="float:left">
                      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.apply_type.value=='RATE'}" (click)="form.get('apply_type').setValue('RATE')">%</button>
                      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.apply_type.value=='AMOUNT'}" (click)="form.get('apply_type').setValue('AMOUNT')">금액</button>
                  </div>
                  <inputEx type="text" style="float:left;margin-left:10px;width:100px" name="apply_number" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              </td> 
              <th scope="row"><label for="sale_type">유효개월수</label></th>
              <td>
                  <inputEx type="text" name="valid_month" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              </td>
          </tr>
          <tr>
              <th scope="row"><label for="use_yn">사용여부</label></th>
              <td>
                  <div class="btn-group">
                      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='1'}" (click)="form.get('use_yn').setValue('1')">사용</button>
                      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='0'}" (click)="form.get('use_yn').setValue('0')">미사용</button>
                  </div>
              </td>
              <th scope="row"><label for=""></label></th>
              <td>
              </td>
          </tr>
  </tbody>
      </table>
  
    </form>
  
  </div>
  
  <div class="modal-footer">
    <div class="fl w50p">
  
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="setBamaCouponSave()">저장하기</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
    </div>
  </div>
  