<section class="contents">
  <header>
    <div class="logo-div">
      <a class="logo" routerLink="/admin">bikemart</a>
    </div>

    <div class="header">
      <a class="top-btn btn-as" href="https://gw.o2oz.net" target="_blank">
        <span class="material-icons-outlined">headset_mic</span>
      </a>

      <a class="top-btn btn-home" href="http://www.bikemart.co.kr" target="_blank">
        <span class="material-icons-outlined">home</span>
      </a>
    </div>
  </header>

  <section class="login-form">
    <div class="center mt30 mb30">
      <img src="/assets/images/logo_big.png" alt="BIKEMART" />
    </div>

    <hr />

    <div class="mt20 mb20">
      <p class="title">BIKEMART 통합운영관리 시스템</p>
      <p class="subtitle">관리자가 아니신 분은 해당 브라우저를 종료해주십시요.</p>
    </div>

    <hr />

    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="mt20">
        <inputEx type="text" name="id" [formGroup]="form" [formErrors]="formErrors" [small]="false" placeholder="아이디를 입력하세요" [autofocus]="'true'"></inputEx>
      </div>

      <div class="mt10">
        <inputEx type="password" name="pw" [formGroup]="form" [formErrors]="formErrors" [small]="false" placeholder="비밀번호를 입력하세요"></inputEx>
      </div>

      <div class="mt10">
        <label><input type="checkbox" name="saveId" formControlName="saveId" value="true" [checked]="form.controls.saveId.value"> 아이디저장</label>
      </div>

      <input type="submit" value="로그인" class="btn btn-primary form-control mt30" />

    </form>
  </section>
</section>
