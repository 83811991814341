/*******************************************************************************
  설  명 : 기초/기타입고
  작성일 : 2019-12-07
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';

import * as moment from 'moment';

@Component({
  selector: 'app-stock-import',
  templateUrl: './stock-import.component.html',
  styleUrls: ['./stock-import.component.scss']
})
export class StockImportComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number; // 0 = 추가, else = 수정
  public product_seq: number;
  public product_name: string = '';

  public title: string = '추가';

  public formImport: FormGroup;
  public formErrors = {};

  public addProductFunc: any;         // 상품검색
  public addCustomerFunc: any;         // 거래처검색

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let date: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.formImport = this.formBuilder.group({
      seq:["", [] ],
      inout_gbn:["", [Validators.required] ],
      inout_code: ["", [Validators.required] ],
      warehouse_seq_in:["", [Validators.required] ],
      product_seq: ["", []],
      product_name:["", [Validators.required, Validators.maxLength(50)] ],
      inout_date:[date, [Validators.required] ],
      inout_qty:["", [Validators.required, Validators.maxLength(50)] ],
      customer_seq:["", [] ],
      customer_name:["", [Validators.maxLength(255)] ],
      unit_amt:["", [Validators.maxLength(255)] ],
      amt:["", [Validators.maxLength(255)] ],
      memo:["", [Validators.maxLength(255)] ],
    });

    this.formImport.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formImport, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();

    this.addProductFunc = this.searchProduct.bind(this);
    this.addCustomerFunc = this.searchCustomer.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 상품 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProduct() {
    /*
    const modalRef = this.modalService.open(ACProductFindComponent, options);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {
      this.formImport.patchValue({
        product_seq: result.seq,
        product_name: result.name
      });
    }, (reason) => {
    });
    */
  }

  /*******************************************************************************
    설  명 : 거래처 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCustomer() {
    /*
    const modalRef = this.modalService.open(ACCustomerFindComponent, optionsLG);

    modalRef.result.then((result) => {
      this.formImport.patchValue({
        customer_seq: result.seq,
        customer_name: result.name
      });
    }, (reason) => {
    });
    */
  }

}
