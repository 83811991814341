/*******************************************************************************
  설  명 : 기초관리 > 기본정보설정 > 환율관리
  작성일 : 2020-10-29
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { SiteConfigService } from '@app/service/site.config.service';

import { ToastrService } from 'ngx-toastr';

const CONFIG_KEY = 'EXCHANGE';

@Component({
  selector: 'app-basic-exchange',
  templateUrl: './basic-exchange.component.html',
  styleUrls: ['./basic-exchange.component.scss']
})
export class BasicExchangeComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      sync_count: ['', [] ],
      sync_time: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private siteConfigService: SiteConfigService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getConfig();
  }

  /*******************************************************************************
    설  명 : 환경설정 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          sync_count: response.data.sync_count || '',
          sync_time:  response.data.sync_time || '',
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.siteConfigService.setConfig(CONFIG_KEY, this.form).then( response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '환율정보 저장');
        else this.toastrService.error( response.ResultMessage, '환율정보 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '환율정보 저장');
    }
  }
}
