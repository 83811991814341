import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { CustomValidator } from '@app/service/custom.validators';
import { UtilService } from '@app/service/util.service';
import { BikeService } from '@app/service/bike.service';

@Component({
  selector: 'app-bike-payhelper-detail-calc',
  templateUrl: './bike-payhelper-detail-calc.component.html',
  styleUrls: ['./bike-payhelper-detail-calc.component.scss']
})
export class BikePayhelperDetailCalcComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() helperSeq: any;
  @Input() helperInfo: any;

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private bikeService: BikeService,
    private toastrService: ToastrService,
  ) {
    this.form = this.formBuilder.group({
      seq: [0, [Validators.required]],
      sale_price: [0, [Validators.required]],
      cash_fee: ['', [Validators.required, CustomValidator.numeric]],
      card_fee: ['', [Validators.required, CustomValidator.numeric]],
      total_fee: ['', []],
      calculate_price: ['', []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      seq: this.helperSeq,
      sale_price: this.helperInfo.sale_price,
      cash_fee: this.utilService.getComma(this.helperInfo.cash_fee),
      card_fee: this.utilService.getComma(this.helperInfo.card_fee),
      total_fee: this.utilService.getComma(this.helperInfo.total_fee),
      calculate_price: this.utilService.getComma(this.helperInfo.calculate_price),
    });
  }

  /*******************************************************************************
    설  명 : 창 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikePayHelperCalcEdit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
        this.bikeService.setBikePayHelperCalcEdit(this.form).then( response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage);
          this.activeModal.close(true);
        } else {
          this.toastrService.error(response.ResultMessage);
        };
      });
    }
  }

  /*******************************************************************************
    설  명 : 가격 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangePrice( event: any ): void {
    let id: any = event.target.id;
    let value: any = event.target.value;

    const cashElement: any = document.getElementById( 'cash_fee' );
    const cardElement: any = document.getElementById( 'card_fee' );

    const cashValue: any = this.utilService.deleteComma(cashElement.value);
    const cardValue: any = this.utilService.deleteComma(cardElement.value);
    
    const totalFee: any = parseInt(cashValue) + parseInt(cardValue);
    const calculatePrice: any = parseInt(this.form.controls.sale_price.value) - totalFee;

    this.form.patchValue({
      total_fee: this.utilService.getComma(totalFee),
      calculate_price: this.utilService.getComma(calculatePrice)
    });
  }

}
