<form>
  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">검색엔진 수집정보</h4>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small">저장</button>
    </div>
    <div class="cb"></div>
  </div>  
  
  <table class="table table-bordered table-small1 mb0">
  <caption class="none">검색엔진 수집정보</caption>
  <colgroup>
    <col style="width:15%" />
    <col style="width:85%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="">타이틀</label></th>
    <td><inputEx type="text" name="" size="40" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">제작자</label></th>
    <td><inputEx type="text" name="" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">설명</label></th>
    <td><inputEx type="text" name="" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">키워드</label></th>
    <td><inputEx type="text" name="" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  </tbody>
  </table>
  
  <h4 class="fb mt10 mb5">오픈 그래프 태그</h4>
  <table class="table table-bordered table-small1 mb0">
  <caption class="none">오픈 그래프 태그</caption>
  <colgroup>
    <col style="width:15%" />
    <col style="width:15%" />
    <col style="width:70%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="">로고</label></th>
    <td colspan="2">
      <div class="input-group">
        <div class="stamp-img">
          <img src="/assets/images/samp.jpg">
          <button>X</button>
        </div>
      </div>

       <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect></inputEx>
       <div class="progress mt5 mb5">
         <div class="progress-bar"></div>
       </div>
       <div class="mt5">* 이미지 가로사이즈 400px 이상</div>
    </td>
  </tr>
  <tr>
    <th scope="row" rowspan="2"><label for="">메타태그</label><br/><button class="btn btn-small btn-default">등록</button></th>
    <th scope="row"><label for="">설명</label></th>
    <td><inputEx type="text" name="" [formGroup]="form" [formErrors]="formErrors" placeholder="검색엔진에서 수집할 설명을 입력하세요"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">키워드</label></th>
    <td><inputEx type="text" name="" [formGroup]="form" [formErrors]="formErrors" placeholder="검색엔진에서 수집할 사이트의 키워드(콤마구분)을 입력하세요. 예) 여성의류, 캐주얼 패션, 청소년의류"></inputEx></td>
  </tr>
  </tbody>
  </table>  
  
  <h4 class="fb mt10 mb5">검색로봇 접근 권한 설정</h4>
  <table class="table table-bordered table-small1 mb0">
  <caption class="none">검색로봇 접근 권한 설정</caption>
  <colgroup>
    <col style="width:15%" />
    <col style="width:85%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="">robots.txt 등록</label></th>
    <td><inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect></inputEx></td>
  </tr>
  </tbody>
  </table>    
</form>  