import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { MatTooltipComponent } from '@components/mat-tooltip/mat-tooltip.component';

@Component({
  selector: 'app-order-review-tab-history',
  templateUrl: './order-review-tab-history.component.html',
  styleUrls: ['./order-review-tab-history.component.scss']
})
export class OrderReviewTabHistoryComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() data: any;

  public search: any = {
    pageNo: 1,
    pageRow: 9999,
    review_seq: ''
  };

  public reportList: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private utilService: UtilService,
    private orderService: OrderService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', width: 80, cellClass: 'cp' },
      { headerName: '신고사유', field: 'reason_name', width: 120, cellClass: 'cp center' },
      { headerName: '신고내용', field: 'comment', width: 250, cellClass: 'cp left',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data      
      },
      { headerName: '신고자', field: 'name', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return `${params.data.name} (${params.data.id})`;
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';
  
    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }
  
  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 데이터 변경 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnChanges(): void {
    if( this.data !== undefined ) {
      this.search.review_seq = this.data.seq;
      this.getOrderReviewReportList();
    }
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 구매후기 신고 리스트 가져오기
  *******************************************************************************/
  getOrderReviewReportList() {
    this.orderService.getOrderReviewReportList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.reportList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
}
