/*******************************************************************************
  설  명 : 재고관리 - 재고현황
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { StockStatistic } from '@app/service/stockStatistic.service';
import { ExportExcelComponent } from '@components/export-excel/export-excel.component'

@Component({
  selector: 'app-stock-statistic',
  templateUrl: './stock-statistic.component.html',
  styleUrls: ['./stock-statistic.component.scss']
})
export class StockStatisticComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  statistic: Array<Object>
  search = { 
    index : 1,
    count : 1,
    brand_name : '', 
    category_name : '', 
    color_name : '', 
    size_name : '', 
    product_name : '', 
    warehouse_name : '',
    product_seq: '',
    product_property_seq: ''
  };

  search_A = { ...this.search }
  search_B = { ...this.search }
  search_C = { ...this.search }
  search_D = { ...this.search }
  search_E = { ...this.search }

  stock_A: any
  stock_B: any
  stock_C: any
  stock_D: any
  stock_E: any

  columnDefs_A: any;
  columnDefs_B: any;
  columnDefs_C: any;
  columnDefs_D: any;
  columnDefs_E: any;

  gridApi_A: any;
  gridColumnApi_A: any;
  gridApi_B: any;
  gridColumnApi_B: any;
  gridApi_C: any;
  gridColumnApi_C: any;
  gridApi_D: any;
  gridColumnApi_D: any;
  gridApi_E: any;
  gridColumnApi_E: any;

  gridApi_fake: any;
  gridColumnApi_fake: any;
  columnDefs_fake: any;
  stock_fake: Array<Object>

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private stockOrderComponent: StockOrderComponent,
    private stockStatistic: StockStatistic,
    private exportExcelComponent: ExportExcelComponent
  ) {
    this.columnDefs_A = [
      { headerName: '창고', field: 'warehouse_name', width: 80, cellClass: 'center' },
      // { headerName: '상품번호', field: 'product_property_seq' },
      { headerName: '카테고리', field: 'category_name', width: 120, cellClass: 'center' },
      { headerName: '브랜드', field: 'brand_name', width: 120, cellClass: 'center' },
      { headerName: '상품명', field: 'product_name', width: 500, cellClass: 'left', flex: 0 },
      { headerName: '색상', field: 'color_name', width: 110, cellClass: 'center' },
      { headerName: '사이즈', field: 'size_name', width: 140, cellClass: 'center' },
      { headerName: '재고수량', field: 'stock', width: 80, cellClass: 'right', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '재고금액', field: 'stock_amt', width: 100, cellClass: 'right', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '입고수량', field: 'in_stock', width: 80, cellClass: 'right', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '입고금액', field: 'in_stock_amt', width: 100, cellClass: 'right', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '출고수량', field: 'out_stock', width: 80, cellClass: 'right', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '출고금액', field: 'out_stock_amt', width: 100, cellClass: 'right', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) }
    ];

    this.columnDefs_B = [
      { headerName: '창고', field: 'warehouse_name' },
      { headerName: '카테고리', field: 'category_name' },
      { headerName: '브랜드', field: 'brand_name' },
      { headerName: '상품명', field: 'product_name', width: 200, flex: 0 },
      { headerName: '색상', field: 'color_name' },
      { headerName: '사이즈', field: 'size_name' },
      { headerName: '이월기준', field: 'close_month' },
      { headerName: '이월수량', field: 'close_stock', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '이월금액', field: 'close_amt', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '입고수량', field: 'close_in_stock', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '입고금액', field: 'close_in_stock_amt', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '출고수량', field: 'close_out_stock', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '출고금액', field: 'close_out_stock_amt', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '현재고수량', field: 'stock', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '현재고금액', field: 'stock_amt', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
    ];

    this.columnDefs_C = [
      // { headerName: '창고코드', field: 'warehouse_seq', width: 70, flex: 0 },
      // { headerName: '창고상세코드', field: 'warehouse_location_seq', width: 70, flex: 0 },
      { headerName: '창고', field: 'warehouse_name' },
      // { headerName: '창고상세명', field: 'location_cd' },
      { headerName: '재고수량', field: 'stock', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '재고금액', field: 'stock_amt', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) }
    ];

    this.columnDefs_D = [
      // { headerName: '창고코드', field: 'warehouse_seq', width: 70, flex: 0 },
      // { headerName: '창고상세코드', field: 'warehouse_location_seq', width: 70, flex: 0 },
      { headerName: '창고', field: 'warehouse_name' },
      { headerName: '창고상세위치', field: 'location_cd' },
      { headerName: '창고상세이름', field: 'location_name' },
      { headerName: '카테고리', field: 'category_name' },
      { headerName: '브랜드', field: 'brand_name' },
      { headerName: '상품명', field: 'product_name', width: 300, flex: 0 },
      // { headerName: '색상', field: 'color_name' },
      // { headerName: '사이즈', field: 'size_name' },
      { headerName: '재고수량', field: 'stock', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '재고금액', field: 'stock_amt', valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) }
    ];

    this.columnDefs_E = [
      { headerName: '브랜드순번', field: 'brand_seq' , width: 100},
      { headerName: '브랜드명', field: 'brand_name', width: 150, flex: 0 },
      { headerName: '재고수량', field: 'nowStockCount' , cellClass: 'right' ,valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params)},
      { headerName: '재고금액', field: 'nowStockAmt' , cellClass: 'right' ,  valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params)},
      { headerName: '재고비율(%)', field: 'stockAvg' , cellClass: 'right' },
      { headerName: '전월대비', field: 'lastVsNow', cellClass: 'right' , valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params)},
    ];


    this.defaultColDef = {...this.stockOrderComponent.defaultColDef};
    this.rowSelection = this.stockOrderComponent.rowSelection
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate;
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getStockStatistic()
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params, sel) {
    switch(sel) {
      case 'A':
        this.gridApi_A = params.api
        this.gridColumnApi_A = params.columnApi
        this.getStockStatistic_A()
        break;

      case 'B':
        this.gridApi_B = params.api
        this.gridColumnApi_B = params.columnApi
        this.getStockStatistic_B()
        break;

      case 'C':
        this.gridApi_C = params.api
        this.gridColumnApi_C = params.columnApi
        this.getStockStatistic_C()
        break;

      case 'D':
        this.gridApi_D = params.api
        this.gridColumnApi_D = params.columnApi
        this.getStockStatistic_D()
        break;

      case 'E':
        this.gridApi_E = params.api
        this.gridColumnApi_E = params.columnApi
        this.getStockStatistic_E()
        break;
  

      case 'fake':
        this.gridApi_fake = params.api
        this.gridColumnApi_fake = params.columnApi
        break;
    }
  }

  /*******************************************************************************
    설  명 : 공통 현황 가져오기
  *******************************************************************************/
  getStockStatistic() {
    this.stockStatistic.getStockStatistic().then(response => {
      if(response.ResultCode) {
        this.statistic = [{
          stock : response.data.reduce((a,c) => a + parseInt(c['stock']), 0),
          amt : response.data.reduce((a,c) => a + parseInt(c['amt']), 0),
          warehouse_name : '전체창고'
        }, ...response.data]
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 숫자형 변환
  *******************************************************************************/
  toNumber = (any: any) => parseInt(String(any)) || 0

  /*******************************************************************************
    설  명 : 현재고현황 가져오기
  *******************************************************************************/
  getStockStatistic_A(index = 0) {
    this.stockStatistic.getStockStatistic_A({
      e_date : '',
      index : index,
      brand_name : this.search_A['brand_name'],
      category_name : this.search_A['category_name'],
      color_name : this.search_A['color_name'],
      size_name : this.search_A['size_name'],
      product_name : this.search_A['product_name'],
      warehouse_name : this.search_A['warehouse_name'],
      product_seq : this.search_A['product_seq'],
      product_property_seq : this.search_A['product_property_seq']
    }).then(response => {
      if(response.ResultCode) {
        this.stock_A = this.cooking(response.data)
        this.gridApi_A.refreshCells()
        this.search_A['count'] = response['count']
      } else this.toastrService.error(response.ResultMessage)
    })
  }
  cooking = data => data.map(e => ({
    ...e,
    stock : this.toNumber(e['stock']),
    stock_amt : this.toNumber(e['stock_amt']),
    in_stock : this.toNumber(e['in_stock']),
    in_stock_amt : this.toNumber(e['in_stock_amt']),
    out_stock : this.toNumber(e['out_stock']),
    out_stock_amt : this.toNumber(e['out_stock_amt'])
  }))

  /*******************************************************************************
    설  명 : 재고수불내역 가져오기
  *******************************************************************************/
  getStockStatistic_B(index = 0) {
    this.stockStatistic.getStockStatistic_B({
      e_date : '',
      index : index,
      brand_name : this.search_B['brand_name'],
      category_name : this.search_B['category_name'],
      color_name : this.search_B['color_name'],
      size_name : this.search_B['size_name'],
      product_name : this.search_B['product_name'],
      warehouse_name : this.search_B['warehouse_name']
    }).then(response => {
      if(response.ResultCode) {
        this.stock_B = response.data.map(e => ({
          ...e,
          stock : this.toNumber(e['stock']),
          stock_amt : this.toNumber(e['stock_amt']),
          close_in_stock : this.toNumber(e['close_in_stock']),
          close_in_stock_amt : this.toNumber(e['close_in_stock_amt']),
          close_out_stock : this.toNumber(e['close_out_stock']),
          close_out_stock_amt : this.toNumber(e['close_out_stock_amt']),
          close_stock : this.toNumber(e['close_stock']),
          close_amt : this.toNumber(e['close_amt'])
        }))
        this.gridApi_B.refreshCells()
        this.search_B['count'] = response['count']
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 창고별재고현황 가져오기
  *******************************************************************************/
  getStockStatistic_C(index = 0) {
    this.stockStatistic.getStockStatistic_C({
      e_date : '',
      index : index,
      brand_name : this.search_C['brand_name'],
      category_name : this.search_C['category_name'],
      color_name : this.search_C['color_name'],
      size_name : this.search_C['size_name'],
      product_name : this.search_C['product_name'],
      warehouse_name : this.search_C['warehouse_name'],
      group : 'warehouse'
    }).then(response => {
      if(response.ResultCode) {
        // this.stock_C = response.data.map(e => ({
        //   ...e,
        //   stock : this.toNumber(e['stock']),
        //   stock_amt : this.toNumber(e['stock_amt'])
        // }))
        this.stock_C = this.cooking(response.data)
        this.gridApi_C.refreshCells()
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 상품별재고현황 가져오기
  *******************************************************************************/
  getStockStatistic_D(index = 0) {
    this.stockStatistic.getStockStatistic_D({
      e_date : '',
      index : index,
      brand_name : this.search_D['brand_name'],
      category_name : this.search_D['category_name'],
      color_name : this.search_D['color_name'],
      size_name : this.search_D['size_name'],
      product_name : this.search_D['product_name'],
      warehouse_name : this.search_D['warehouse_name'],
      group : 'product'
    }).then(response => {
      if(response.ResultCode) {
        this.stock_D = this.cooking(response.data)
        this.gridApi_D.refreshCells()
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 브랜드별 재고현황 가져오기
  *******************************************************************************/
    getStockStatistic_E(index = 0) {
      this.stockStatistic.getStockStatistic_E({
      }).then(response => {
        if(response.ResultCode) {
          this.stock_E = this.cooking(response.data)
          this.gridApi_E.refreshCells()
        } else this.toastrService.error(response.ResultMessage)
      })
    }
  

  /*******************************************************************************
    설  명 : 인덱스 변경
  *******************************************************************************/
  pageChange(which, $event) {
    switch(which) {
      case 'A': this.getStockStatistic_A(--$event); break
      case 'B': this.getStockStatistic_B(--$event); break
      case 'C': this.getStockStatistic_C(); break
      case 'D': this.getStockStatistic_D(); break
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화
  *******************************************************************************/
  searchInit(which) {
    switch(which) {
      case 'A': this.search_A = { ...this.search }; this.getStockStatistic_A(); break
      case 'B': this.search_B = { ...this.search }; this.getStockStatistic_B(); break
      case 'C': this.search_C = { ...this.search }; this.getStockStatistic_C(); break
      case 'D': this.search_D = { ...this.search }; this.getStockStatistic_D(); break
    }
  }

  /*******************************************************************************
    설  명 : input 키보드 입력 처리
  *******************************************************************************/
  keypress($event, which) {
    if($event.key == 'Enter') {
      switch(which) {
        case 'A': this.getStockStatistic_A(); break
        case 'B': this.getStockStatistic_B(); break
      }
    }
  }

  /*******************************************************************************
    설  명 : 엑셀다운
  *******************************************************************************/
  allDataExcelExport(which = 'A') {
    if(!confirm('이 작업은 검색조건을 반영합니다. \r\n진행하시겠습니까?')) return

    this.stockStatistic[which == 'A' ? 'getStockStatistic_A' : 'getStockStatistic_B']({
      brand_name : this.search_A['brand_name'],
      category_name : this.search_A['category_name'],
      color_name : this.search_A['color_name'],
      size_name : this.search_A['size_name'],
      product_name : this.search_A['product_name'],
      warehouse_name : this.search_A['warehouse_name'],
      product_seq : which == 'A' ? this.search_A['product_seq'] : null,
      product_property_seq : which == 'A' ? this.search_A['product_property_seq'] : null,
      unlimit : true
    }).then(response => {
      if(response.ResultCode) {
        this.toastrService.show('엑셀 다운로드 중..')
        this.stock_fake = this.cooking(response.data)
        this.gridApi_fake.refreshCells()
        this.exportExcelComponent.exportAsExcelFile(this.stock_fake, which == 'A' ? '현재고현황' : '재고수불내역')
        this.toastrService.success('엑셀 다운로드 완료')
      } else this.toastrService.error(response.ResultMessage)
    })
  }

}