/*******************************************************************************
  설  명 : 매장관리
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { MemberFindComponent } from '@components/member-find/member-find.component';
import { StoreDetailComponent } from '@page/basic/store/store-detail/store-detail.component';
import { StoreChargeAddComponent } from '@page/basic/store/store-charge-add/store-charge-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
  };

  public form: FormGroup;
  public formErrors = {};

  // 그리드 관련 선언
  warehouseList: any = [];
  storeList: any = [];
  storeInfo: any = {};
  chargeList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiCharge: any;
  gridColumnApiCharge: any;
  columnDefsCharge: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public summernoteConfigDefault = config.summernoteConfig;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      store_name: ['', [Validators.required] ],
      onoff_gbn: ['', [Validators.required] ],
      store_gbn: ['', [Validators.required] ],
      wh_basic: ['', [] ],
      wh_output: ['', [] ],
      wh_return: ['', [] ],
      wh_RMA: ['', [] ],
      store_zipcode: ['', [] ],
      store_address: ['', [] ],
      store_address_detail: ['', [] ],
      store_contents: ['', [] ],
      store_info: ['', [] ],
      latitude: ['', [] ],
      longitude: ['', [] ],
      main_person_mem_no: ['', [] ],
      id: ['', [] ],
      name: ['', [] ],
      tel: ['', [] ],
      hp: ['', [] ],
      email: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private basicService: BasicService,
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'storeSeq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '사업장구분', field: 'store_gbn', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.store_gbn == 'D' ) {
            return '<span class="f12">직영점</span>';
          } else if( params.data.store_gbn == 'F') {
            return '<span class="f12">가맹점</span>';
          } else {
            return '<span class="f12">대리점</span>';
          }
        }
      },
      { headerName: '매장구분', field: 'onoff_gbn', width: 130, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.onoff_gbn == 'ONLINE' ) {
            return '<span class="badge badge-success f12">온라인매장</span>';
          } else {
            return '<span class="badge badge-secondary f12">오프라인매장</span>';
          }
        }
      },
      { headerName: '매장명', field: 'store_name', width: 100, cellClass: 'cp center' },
      { headerName: '기본창고', field: 'wh_basic', width: 80, cellClass: 'cp center' },
      { headerName: '출고창고', field: 'wh_output', width: 80, cellClass: 'cp center' },
      { headerName: '반품창고', field: 'wh_return', width: 80, cellClass: 'cp center' },
      { headerName: 'RMA창고', field: 'wh_RMA', width: 80, cellClass: 'cp center' },
    ];

    // 담당자 ag grid 컬럼 선언
    this.columnDefsCharge = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '담당자명', field: 'charge_name', width: 120, cellClass: 'cp' },
      { headerName: '직위명', field: 'position', width: 100, cellClass: 'cp' },
      { headerName: '전화번호', field: 'tel', width: 120, cellClass: 'cp center' },
      { headerName: '휴대전화', field: 'hp', width: 120, cellClass: 'cp center' },
      { headerName: '이메일', field: 'email', width: 160, cellClass: 'cp center' },
      { headerName: '시작일', field: 'start_date', width: 100, cellClass: 'cp center' },
      { headerName: '종료일', field: 'end_date', width: 100, cellClass: 'cp center' },
    ];


    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    await this.getWarehouseList();
    await this.getStoreList();
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getWarehouseList() {
    await this.basicService.getWarehouseList({}).then( response => {
      if( response.ResultCode ) {

        this.warehouseList.push({
          title: '선택',
          value: 0
        });

        for(const item of response.data ) {
          const data = {
            title: item.warehouse_name,
            value: item.seq
          };

          this.warehouseList.push( data );
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 매장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
 async getStoreList() {
    await this.basicService.getStoreList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.storeList = response.data;

        if( this.storeList.length > 0 && !this.form.controls.seq.value ) {
          this.form.patchValue({
            seq: this.storeList[0].seq
          });

          this.getStoreDetail();

          let index = 0;
          setTimeout(() => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }, 0);
        } else {
          const selectedSeq = this.form.controls.seq.value;
          setTimeout(() => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              if( node.data.seq == selectedSeq ) {
                node.setSelected(true);
              }
            });
          }, 0);
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 매장 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStoreDetail() {
    this.basicService.getStoreDetail( this.form.controls.seq.value ).then( response => {
      if( response.ResultCode ) {
        this.storeInfo = response.data;
        this.chargeList = response.charge;

        this.form.patchValue( this.storeInfo );
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 매장 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setStoreSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setStoreSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getStoreList();
          this.getStoreDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 매장 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setStoreDelete() {
    if ( confirm('삭제하시겠습니까?') ) {
      this.basicService.setStoreDelete( this.form.controls.seq.value ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.form.patchValue({
            seq: ''
          });

          this.getStoreList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 매장 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setStoreAdd() {
    const modalRef = this.modalService.open(StoreDetailComponent, optionsXL);

    modalRef.result.then((result) => {
      if( result ) {
        this.getStoreList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
     설  명 : 회원 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  memberFindAddOn() {
    const modalRef = this.modalService.open(MemberFindComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
        this.form.patchValue({
          main_person_mem_no: result.data.mem_no,
          id: result.data.id,
          name: result.data.name,
          tel: result.data.tel,
          hp: result.data.hp,
          email: result.data.email,
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked(event: any) {
    this.form.patchValue({
      seq: event.data.seq
    });

    this.getStoreDetail();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCharge(params) {
    this.gridApiCharge = params.api;
    this.gridColumnApiCharge = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClickedCharge(event: any) {
    /*
    this.form.patchValue({
      seq: event.data.seq
    });

    this.getStoreDetail();
    */
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      store_zipcode: data.zip,
      store_address: data.addr,
      latitude: data.latitude,
      longitude: data.longitude
    });

    $("#store_address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: ''
    };

    this.getStoreList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getStoreList();
    }
  }

  /*******************************************************************************
    설  명 : 담당자 추가
    입력값 : chargeSeq = 담당자코드
    리턴값 : 없음
  *******************************************************************************/
  setStoreChargeAdd( chargeSeq: number ) {

    if (this.form.controls.seq.value === '') {
      this.toastrService.error('매장을 선택하세요.', '');
      return;
    }

    const modalRef = this.modalService.open(StoreChargeAddComponent, options);
    modalRef.componentInstance.storeSeq = this.form.controls.seq.value;
    modalRef.componentInstance.seq = chargeSeq;

    modalRef.result.then((result) => {
      if ( result ) {
        this.getStoreDetail();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 담당자 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setStoreChargeDelete() {
    const nodes = this.gridApiCharge.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 담당자를 선택하세요', '' );
      return false;
    }

    if ( confirm('담당자를 삭제하시겠습니까?') ) {
      this.basicService.setStoreChargeDelete( nodes ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.getStoreDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

}
