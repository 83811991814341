<as-split direction="vertical" unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
  <as-split-area size="50" minSize="40" maxSize="60">
    <section class="search-condition mb5 mt5">
      <div class="fl form-inline asub-title">
        <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="reset()">
          <i class="material-icons-outlined">refresh</i>
        </button>
        <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
          <i class="material-icons-outlined">search</i>
        </button> -->

        <div class="input-group ml5">
          <div class="btn-group mr5">
            <button *ngFor="let obj of [
              {idx: 0, item: '전체'}, {idx: 1, item: '매입'}, {idx: 2, item: '입점'}
              ]" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.value.buyType == obj.idx}" (click)="btnClick('buyType', obj.idx)">{{obj.item}}</button>
          </div>
          <input type="text" size="27" class="form-control form-control-small" placeholder="주문번호, 주문자, 수신자, 상품명" [(ngModel)]="form.value.search" (keypress)="keypress($event)" />
          <div class="input-group-append">
            <button type="button" class="btn btn-default btn-small f12" (click)="getOrderList()">검색</button>
          </div>
        </div>


        <input type="text" size="13" readonly="readonly" [value]="form.value.s_date" class="form-control form-control-small calendar-input ml5" placeholder="주문 시작일" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect)="selectDate('s_date', $event)" />
        <span class="pl5 pr5">~</span>
        <input type="text" size="13" readonly="readonly" [value]="form.value.e_date" class="form-control form-control-small calendar-input " placeholder="주문 종료일" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect)="selectDate('e_date', $event)" />
  
        <div class="btn-group ml15">
          <button *ngFor="let obj of [
            {idx: -1, item: '전체'}, {idx: 0, item: '오늘'}, {idx: 7, item: '7일'}, {idx: 15, item: '15일'}, {idx: 30, item: '30일'}, {idx: 60, item: '60일'}
          ]" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_btn.value == obj.idx}" (click)="quickDate(obj.idx)">{{obj.item}}</button>
        </div>
        <!-- <input type="text" style="width:110px;" class="form-control form-control-small calendar-input ml5" [readonly]="true" placeholder="주문 시작일" />
        <span class="pl5 pr5">~</span>
        <input type="text" style="width:110px;" class="form-control form-control-small calendar-input" [readonly]="true" placeholder="주문 종료일" />

        <div class="btn-group ml5">
          <button type="button" class="btn btn-default btn-small">전체</button>
          <button type="button" class="btn btn-default btn-small">오늘</button>
          <button type="button" class="btn btn-default btn-small">7일</button>
          <button type="button" class="btn btn-default btn-small">15일</button>
          <button type="button" class="btn btn-default btn-small">1개월</button>
          <button type="button" class="btn btn-default btn-small">2개월</button>
        </div> -->
      </div>
      <div class="fr">
        <!-- <button type="button" class="btn btn-primary btn-small ml5" (click)="setStockOut()">출고지시</button> -->
      </div>
      <div class="cb"></div>
    </section>

    <hr class="db mt5 mb5" />

    <div class="mt5 mb5">
      <div class="fl fb pt5">
        미출고 주문리스트
      </div>
      <div class="fr right">
        <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi" gridId="a3002969d1134bc895d728fe1539c6dd" [btnGroup]="1"></btn-ag-grid-save>
        <ag-grid-excel [gridApi]="gridApi" selected="true" filename="미출고주문리스트" title="미출고주문리스트"></ag-grid-excel>
        <ag-grid-excel [gridApi]="gridApi" selected="false" filename="미출고주문리스트" title="미출고주문리스트"></ag-grid-excel>        
      </div>
      <div class="cb"></div>
    </div>

    <div style="height: calc(100% - 85px);">
      <ag-grid-angular
        #myGrid

        style="width:100%;height:100%"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="form.value.orderList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [rowClassRules]="rowClassRules"

        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked_order($event)"
        >
      </ag-grid-angular>
    </div>
  </as-split-area>
  <as-split-area size="50" minSize="40" maxSize="60">
    <div class="mb5">
      <div class="fl fb pt5">
        주문상세 정보
      </div>
      <div class="fr right">
        <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi" gridId="a1beb7b8db184d5784fa6909b4502e85" [btnGroup]="1"></btn-ag-grid-save>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="changeType()">매입/입점 변경</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="changeType(1)">직발 처리</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setStockOut()">출고지시</button>
      </div>
      <div class="cb"></div>
    </div>

    <div style="height: calc(100% - 35px);">
      <ag-grid-angular
        #myGrid

        style="width:100%;height:100%"
        class="ag-theme-balham"

        [columnDefs]="columnDefsDetail"
        [defaultColDef]="defaultColDef"
        [rowData]="form.value.orderDetail"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [components]="components"
        [stopEditingWhenGridLosesFocus]="true"
        [singleClickEdit]="true"

        (gridReady)="onGridReady_detail($event)"
        (cellClicked)="cellClick_detail($event)"
        >
      </ag-grid-angular>
    </div>
  </as-split-area>
</as-split>