/*******************************************************************************
  설  명 : 상품관리 - 상품 순서관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import * as $ from 'jquery';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { EventService } from '@app/service/event.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { ExportExcelComponent } from '@app/components/export-excel/export-excel.component';

import { OrderIniorderPriceComponent } from '@page/order/order-iniorder/order-iniorder-price/order-iniorder-price.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-event-supersale-product',
  templateUrl: './event-supersale-product.component.html',
  styleUrls: ['./event-supersale-product.component.scss']
})
export class EventSupersaleProductComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() eventSeq: any;

  public form: FormGroup;
  public formErrors = {};

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public baseURL = config.baseUrl;

  public productList: any = [];
  public categoryList: any = [];
  public brandList: any = [];
  public searchList: any = [];
  public excelData: any = [];

  public eventInfo: any = {};

  public params: any = {
    event_seq: '',
    category_code: '',
    brand_seq: '',
    isEventSave: '',
    searchText: '',
    gridSearchText: '',
    category_status: 'W',
    brand_status: 'W',
  };

  public saleType: any = {
    discount: true,
    rate: false,
    value: ''
  };

  public sortOrder: any = '';

  public activeTab: number = 0;

  // 그리드 관련 선언
  gridApiCategory: any;
  gridColumnApiCategory: any;
  columnDefsCategory: any;

  gridApiBrand: any;
  gridColumnApiBrand: any;
  columnDefsBrand: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionProduct: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;
  components: any;
  rowClassRules: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public statistic: any = {
    event_name: '',
    start_date: '',
    end_date: '',
    productCnt : 0,
    waitCnt: 0,
    completeCnt: 0,
    rate: 0
  };

  file: any;
  arrayBuffer: any;
  filelist: any[];

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      file: ['', []],
      files: [[], []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private restful: RestfulService,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private agGridExComponent: AgGridExComponent,
    private exportExcelComponent: ExportExcelComponent
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefsCategory = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', checkboxSelection: true },
      { headerName: '카테고리명', field: 'category_name', width: 225, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 65, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '완료수', field: 'complete_total', width: 65, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let fb = ( params.data.complete_total > 0 ) ? 'fb' : '';

          return `<span class="${fb}">${params.data.complete_total}</span>`;
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefsBrand = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', checkboxSelection: true },

      { headerName: '브랜드명', field: 'brand_name', width: 203, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 76, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '완료수', field: 'complete_total', width: 76, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let fb = ( params.data.complete_total > 0 ) ? 'fb' : '';

          return `<span class="${fb}">${params.data.complete_total}</span>`;
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '이동', field: '', cellClass: 'center ag-cell80h', width: 50, suppressMenu: true,
        rowDrag(params: any) {
          if( params.data.seq === null ) {
            return false;
          } else {
            return true;
          }
        }
      },
      { headerName: '순서', field: 'sort_order', cellClass: 'cp center ag-cell80h ag-cell-edit', width: 70, editable: true,
        valueGetter: this.agGridExComponent.numberGetter
      },
      { headerName: '', field: '', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '상태', field: 'seq', width: 70, cellClass: 'center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.seq === null ) {
            return '<span class="badge badge-secondary f12">대기</span>';
          } else {
            return '<span class="badge badge-primary f11">완료</span>';
          }
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 105, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품정보', field: 'category_name', width: 265, cellClass: 'ag-cell80h-br cell-wrap-text', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.category_name + '<br />[' + params.data.brand_name + ']<br />' + params.data.product_name;
        }
      },
      { headerName: '상품가격', field: '', width: 100, cellClass: 'right ag-cell80h-br', sortable: false,
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let eventPrice: number = params.data.event_price;
          let consumerPrice: number = params.data.consumer_price;
          let comPrice: number = params.data.com_price;
          let b2bPrice: number = params.data.b2b_price;
          let fraPrice: number = params.data.fra_price;

          return `
            <font class="${ eventPrice == consumerPrice ? 'fb' : ''}">소 : ${getComma(consumerPrice)}</font><br/>
            <font class="${ eventPrice == comPrice ? 'fb' : ''}">상 : ${getComma(comPrice)}</font><br/>
            <font class="${ eventPrice == b2bPrice ? 'fb' : ''}">협 : ${getComma(b2bPrice)}</font><br/>
            <font class="${ eventPrice == fraPrice ? 'fb' : ''}">프 : ${getComma(fraPrice)}</font>
          `;
        }
      },
      { headerName: '이벤트가격', field: 'event_price', width: 90, cellClass: 'right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '구매가격', field: 'buy_price', width: 90, cellClass: 'cp right ag-cell80h ag-cell-edit', cellEditor: 'numericCellEditor', editable: true,
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: this.agGridExComponent.numberGetter
      },
      { headerName: '할인가격', field: 'discount_amt', width: 85, cellClass: 'cp right ag-cell80h ag-cell-edit', cellEditor: 'numericCellEditor', editable: true,
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: this.agGridExComponent.numberGetter
      },
      { headerName: '할인율', field: 'discount_rate', width: 75, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true },
      { headerName: '적립금', field: 'mileage_amt', width: 85, cellClass: 'cp right ag-cell80h ag-cell-edit' , cellEditor: 'numericCellEditor', editable: true,
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: this.agGridExComponent.numberGetter
      },
      { headerName: '적립율', field: 'mileage_rate', width: 75, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    this.rowSelection = 'single';
    this.rowSelectionProduct = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor()
    };

    this.rowClassRules = {
      'status8': function (params) {
        return params.data.seq !== null;
      },
    };

    // 콤마 넣기
    function getComma( num: any ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.params.event_seq = this.eventSeq;

    // 이벤트 정보 가져오기
    if (this.eventSeq !== '') {
      this.getEventDetail();
    }

    this.getEventSuperSaleWidget();
    this.getEventSuperSaleCategoryList();
    this.getEventSuperSaleBrandList();

    // 업로드 허용 파일 설정(application/image/video/audio/pdf/compress/doc/xls/ppt)
    var filetype: string[] = ['xls'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        let files = this.form.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          url: this.baseURL + response.url,
          thumbnail_org: response.thumbnail_org,
        });

        this.form.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 업로드 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event: any) {
    const files: any = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(files);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary"});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.filelist = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      
      let params: any = [];
      this.filelist.map(obj => {
        params.push({
          sort_order: obj.sort_order,
          seq: obj.product_seq
        });
      });

      if( params.length < 1 ) {
        this.toastrService.error( '업로드 데이터를 다시 확인하시기 바랍니다.', '');
        this.file = '';
      } else {
        this.eventService.setSuperSaleMainSort( params, this.params.event_seq ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.getEventSuperSaleBrandList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : getRowNodeId 설정
  *******************************************************************************/
  getRowNodeId = data => data.seq;

  /*******************************************************************************
    설  명 : 이벤트 상세 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventDetail() {
    this.eventService.getEventDetail(this.eventSeq).then( response => {
      if (response.ResultCode) {
        this.eventInfo = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 슈퍼세일 위젯 정보 가져오기
  *******************************************************************************/
  getEventSuperSaleWidget() {
    this.eventService.getEventSuperSaleWidget( this.params.event_seq ).then( response => {
      if (response.ResultCode) {
        this.statistic.event_name = response.data.event_name;
        this.statistic.start_date = response.data.start_date;
        this.statistic.end_date = response.data.end_date;
        this.statistic.productCnt = response.data.productCnt;
        this.statistic.waitCnt = response.data.productCnt - response.data.completeCnt;
        this.statistic.completeCnt = response.data.completeCnt;
        this.statistic.rate = parseInt(response.data.completeCnt, 10) / parseInt(response.data.productCnt, 10) * 100;
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 카테고리 리스트 가져오기
  *******************************************************************************/
  getEventSuperSaleCategoryList() {
    this.eventService.getEventSuperSaleCategoryList( this.params ).then( response => {
      if (response.resultCode) {
        const dataList = [];
        const setList = (tabChar, currentNode: any) => {
          dataList.push({
            seq: currentNode.seq,
            status: currentNode.status,
            category_code: currentNode.category_code,
            category_name: tabChar + currentNode.name,
            product_total: currentNode.product_total,
            complete_total: currentNode.complete_total
          });

          if (typeof currentNode.children === undefined) {
            return;
          } else {
            for (const children of currentNode.children) {
              let tab = ( children.category_code.length / 3 == 3 ) ? '　　' : '　';
              setList(tab, children);
            }
          }
        };

        for (const item of response.data) {
          setList('', item);
        }

        this.categoryList = dataList;

        // setTimeout( () => {
        //   let index = 0;
        //   this.gridApiCategory.forEachNode( (node) => {
        //     if( index == 0 ) {
        //       node.setSelected(true);
        //       this.gridApi.ensureIndexVisible( index, 'top' );

        //       this.params.category_code = node.data.category_code;
        //       this.getEventSuperSaleCategoryProductList();
        //     }

        //     index++;
        //   });
        // }, 100);

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }

    });
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 슈퍼세일 카테고리 저장하기
  *******************************************************************************/
  setEventSuperSaleCategorySave() {
    const params = {
      event_seq: this.params.event_seq,
      status: this.params.category_status,
      nodes: this.gridApiCategory.getSelectedRows()
    };

    if ( params.nodes.length < 1 ) {
      this.toastrService.error( '저장할 항목을 선택하세요', '' );
      return false;
    }

    this.eventService.setEventSuperSaleCategorySave( params ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getEventSuperSaleCategoryList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 선택한 카테고리의 상품 리스트 가져오기
  *******************************************************************************/
  getEventSuperSaleCategoryProductList() {
    this.eventService.getEventSuperSaleCategoryProductList( this.params ).then( response => {
      if (response.ResultCode) {
        this.productList = response.data;
      } else {
        this.productList = [];
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 브랜드 리스트 가져오기
  *******************************************************************************/
  getEventSuperSaleBrandList() {
    this.eventService.getEventSuperSaleBrandList( this.params ).then( response => {
      if ( response.ResultCode ) {
        this.brandList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 브랜드 저장하기
  *******************************************************************************/
  setEventSuperSaleBrandSave() {
    const params = {
      event_seq: this.params.event_seq,
      status: this.params.brand_status,
      nodes: this.gridApiBrand.getSelectedRows()
    };

    if ( params.nodes.length < 1 ) {
      this.toastrService.error( '변경할 항목을 선택하세요', '' );
      return false;
    }

    this.eventService.setEventSuperSaleBrandSave( params ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getEventSuperSaleBrandList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 상품 가격 저장
  *******************************************************************************/
  setEventProductDiscountSave() {
    const nodes = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error( '할인정보를 저장할 상품을 선택하시기 바랍니다.', '');
    } else {
      const params = [];
      this.gridApi.forEachNode( (node) => {
        if ( node.selected ) {
          params.push({
            event_seq: this.params.event_seq,
            seq: node.data.seq ?? '0',
            product_seq: node.data.product_seq,
            event_price: node.data.event_price,
            discount_amt: node.data.discount_amt,
            discount_rate: node.data.discount_rate,
            mileage_amt: node.data.mileage_amt,
            mileage_rate: node.data.mileage_rate,
            buy_price: node.data.buy_price,
          });
        }
      });

      this.eventService.setEventProductDiscountSave( JSON.stringify(params) ) .then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.getEventSuperSaleWidget();

          if( this.activeTab === 0 ) {
            const categoryNodes = this.gridApiCategory.getSelectedRows();

            const params = {
              event_seq:  this.params.event_seq,
              category_seq: categoryNodes[0].seq
            };

            this.eventService.getEventSuperSaleCategoryList(params).then( response => {
              if (response.resultCode) {
                var rowNode = this.gridApiCategory.getRowNode( categoryNodes[0].seq );

                rowNode.setDataValue('complete_total', response.data[0]['complete_total']);

                this.getEventSuperSaleCategoryProductList();
              } else {
                this.toastrService.error( response.ResultMessage, '');
              }

            });
          } else if( this.activeTab === 1 ) {
            const brandNodes = this.gridApiBrand.getSelectedRows();

            const params = {
              event_seq:  this.params.event_seq,
              brand_seq: brandNodes[0].seq
            };

            this.eventService.getEventSuperSaleBrandList(params).then( response => {
              if ( response.ResultCode ) {
                var rowNode = this.gridApiBrand.getRowNode( brandNodes[0].seq );

                rowNode.setDataValue('complete_total', response.data[0]['complete_total']);

                this.getEventSuperSaleBrandProductList();
              } else {
                this.toastrService.error( response.ResultMessage, '');
              }
            });
          } else if( this.activeTab === 2 ) {
            this.getEventSuperSaleSearchProductList();
          }
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 상품 할인정보 삭제
  *******************************************************************************/
  setEventProductDiscountDelete() {
    const rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error( '삭제할 상품을 선택하시기 바랍니다.', '');
    } else {
      if( confirm('삭제하시겠습니까?') ) {
        const params = [];
        let check: boolean;
        rows.forEach(item => {
          if(item.seq) check = true;

          params.push(item.seq);
        });

        if( check === true ) {
          this.eventService.setEventProductDiscountDelete( JSON.stringify(params) ) .then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');
              this.getEventSuperSaleWidget();

              if( this.activeTab === 0 ) {
                const categoryNodes = this.gridApiCategory.getSelectedRows();
    
                const params = {
                  event_seq:  this.params.event_seq,
                  category_seq: categoryNodes[0].seq
                };
    
                this.eventService.getEventSuperSaleCategoryList(params).then( response => {
                  if (response.resultCode) {
                    var rowNode = this.gridApiCategory.getRowNode( categoryNodes[0].seq );
    
                    rowNode.setDataValue('complete_total', response.data[0]['complete_total']);
    
                    this.getEventSuperSaleCategoryProductList();
                  } else {
                    this.toastrService.error( response.ResultMessage, '');
                  }
    
                });
              } else if( this.activeTab === 1 ) {
                const brandNodes = this.gridApiBrand.getSelectedRows();
    
                const params = {
                  event_seq:  this.params.event_seq,
                  brand_seq: brandNodes[0].seq
                };
    
                this.eventService.getEventSuperSaleBrandList(params).then( response => {
                  if ( response.ResultCode ) {
                    var rowNode = this.gridApiBrand.getRowNode( brandNodes[0].seq );
    
                    rowNode.setDataValue('complete_total', response.data[0]['complete_total']);
    
                    this.getEventSuperSaleBrandProductList();
                  } else {
                    this.toastrService.error( response.ResultMessage, '');
                  }
                });
              } else if( this.activeTab === 2 ) {
                this.getEventSuperSaleSearchProductList();
              }
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });
        }
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 이벤트 상품 순서 저장
  *******************************************************************************/
  setEventProductSortSave() {
    const data: any = [];
    this.gridApi.forEachNodeAfterFilterAndSort(node => {
      if( node.data.seq !== null ) {
        data.push({
          seq: node.data.seq,
          sort_order: node.data.sort_order
        });
      }
    });

    if( data.length < 1 ) {
      this.toastrService.error( '저장할 상품리스트가 없습니다.', '');
    } else {
      if( confirm( '순서를 저장하시겠습니까?') ) {
        this.eventService.setEventProductSortSave( JSON.stringify(data) ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            // this.getEventSuperSaleProductList();
            if( this.activeTab === 2 ) {
              this.getEventSuperSaleSearchProductList();
            }
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 선택한 브랜드의 상품 리스트 가져오기
  *******************************************************************************/
  getEventSuperSaleBrandProductList() {
    this.eventService.getEventSuperSaleBrandProductList( this.params ).then( response => {
      if (response.ResultCode) {
        this.productList = response.data;
      } else {
        this.productList = [];
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 검색한 상품 리스트 가져오기
  *******************************************************************************/
  getEventSuperSaleSearchProductList() {
    this.productList = [];

    this.eventService.getEventSuperSaleSearchProductList( this.params ).then( response => {
      if (response.ResultCode) {
        this.productList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 현재 탭에 맞는 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventSuperSaleProductList() {
    if( this.activeTab === 0 ) {
      this.getEventSuperSaleCategoryProductList();
    } else if( this.activeTab === 1 ) {
      this.getEventSuperSaleBrandProductList();
    } else if( this.activeTab === 2 ) {
      this.getEventSuperSaleSearchProductList();
    } else if( this.activeTab === 3 ) {
      this.getEventSuperSaleSearchProductList();
    }
  }

  /*******************************************************************************
    설  명 : 할인정보 일괄적용
  *******************************************************************************/
  applyAll() {
    const nodes = this.gridApi.getSelectedRows();

    if ( this.saleType.value === '') {
      this.toastrService.error( '금액 or 비율을 입력하세요.', '' );
      return false;
    }

    this.gridApi.forEachNode( function(node) {
      if ( nodes.length < 1 || node.selected ) {
        const inputValue = this.saleType.value;
        const type = this.saleType;
        const originPrice = node.data.event_price;
        const result = {
          discount_amt: (type.discount && !type.rate) ? inputValue : !type.discount ? 0 : this.amountCalc(originPrice, inputValue),
          discount_rate: (type.discount && type.rate) ? inputValue : !type.discount ? 0 : this.rateCalc(originPrice, inputValue),
          mileage_amt: (!type.discount && !type.rate) ? inputValue : type.discount ? 0 : this.mileageCalc(originPrice, inputValue),
          mileage_rate: (!type.discount && type.rate) ? inputValue : type.discount ? 0 : this.rateCalc(originPrice, inputValue),
        };

        for (const [key, value] of Object.entries(result)) {
          if (value > 0) {
            node.setDataValue( key, value );
          }
        }

      }
    }.bind(this));
  }

  /*******************************************************************************
    설  명 : 홍진, 아라이 전용으로 소비자가-협력가를 뺀 금액을 마일리지로 지급
  *******************************************************************************/
  applyAllMileageDiff() {
    const nodes = this.gridApi.getSelectedRows();

    this.saleType = {
      discount: false,
      rate: false,
    };

    if( confirm('HJC/아라이 전용으로 마일리지 적립금을 자동 계산합니다.\n계속하시겠습니까?') ) {
      this.gridApi.forEachNode( function(node) {
        if ( nodes.length < 1 || node.selected ) {
          const inputValue = node.data.consumer_price - node.data.b2b_price;
          const type = this.saleType;
          const originPrice = node.data.event_price;
          const result = {
            mileage_amt: (!type.discount && !type.rate) ? inputValue : type.discount ? 0 : this.mileageCalc(originPrice, inputValue),
            mileage_rate: (!type.discount && type.rate) ? inputValue : type.discount ? 0 : this.rateCalc(originPrice, inputValue),
          };

          for (const [key, value] of Object.entries(result)) {
            if (value > 0) {
              node.setDataValue( key, value );
            }
          }

        }
      }.bind(this));
    }
  }
  
  /*******************************************************************************
    설  명 : 순서번호 일괄적용
  *******************************************************************************/
  sortApplyAll( type: any ) {
    const nodes = this.gridApi.getSelectedRows();

    if( type == '' && this.sortOrder === '' ) {
      this.toastrService.error( '순서 번호를 입력하세요.', '' );
      return false;
    }

    let inputValue: any;
    let index: number = 0;
    this.gridApi.forEachNode( function(node: any) {
      if ( nodes.length < 1 || node.selected ) {
        if( type == 'desc' ) {
          inputValue = ( nodes.length < 1 ? this.productList.length : nodes.length ) - index;
        } else {
          inputValue = parseInt(this.sortOrder);
        }

        node.setDataValue( 'sort_order', inputValue );

        index++;
      }
    }.bind(this));
  }
  
  /*******************************************************************************
    설  명 : 판매가격 일괄변경
    입력값 : 없음
    리턴값 : 없음
  ******************************f*************************************************/
  setEventPriceChange() {
    const nodes = this.gridApi.getSelectedRows();

    const data: any = [];
    this.gridApi.forEachNodeAfterFilterAndSort((node, index) => {
      if ( nodes.length < 1 || node.selected ) {
        data.push({
          index: index,
          node: node
        });
      }
    });

    if( data.length < 1 ) {
      this.toastrService.error('변경할 상품리스트가 없습니다.');
    } else {
      if( confirm( data.length + ' 건 상품들의 금액을 일괄 변경하시겠습니까?') ) {
        const modalRef = this.modalService.open(OrderIniorderPriceComponent, options);
        modalRef.result.then((result) => {
          let method = result.value.method;
          let price_kind = result.value.price_kind;
          let price = result.value.price;
          let price_field = '';

          if( method == '1' ) {
            switch( price_kind ) {
              case '1':
                price_field = 'com_price';
                break;
              case '2':
                price_field = 'fra_price';
                break;
              case '3':
                price_field = 'b2b_price';
                break;
              case '4':
                price_field = 'consumer_price';
                break;
              default:
            }

            data.forEach(item => {
              this.productList[item.index].event_price = this.productList[item.index][ price_field ];

              item.node.setDataValue( 'event_price', this.productList[item.index].event_price );
            });
          } else {
            data.forEach(item => {
              this.productList[item.index].event_price = parseInt(price);

              item.node.setDataValue( 'event_price', this.productList[item.index].event_price );
            });
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 검색 조건 선택 시
  *******************************************************************************/
  setIsEventSave( value: any ) {
    this.params.isEventSave = value;

    this.getEventSuperSaleProductList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setSearchList( event ) {
    if( event.key == 'Enter' ) {
      this.getEventSuperSaleProductList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 조건 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initSearch() {
    // 검색 필드
    this.params = {
      isEventSave: '',
      searchText: ''
    };

    this.getEventSuperSaleProductList();
  }

  /*******************************************************************************
    설  명 : 금액 계산
  *******************************************************************************/
  amountCalc(originPrice, inputValue) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? Math.floor((originPrice * (inputValue / 100)) / 100) * 100 : 0;
  }

  /*******************************************************************************
    설  명 : 마일리지 계산
  *******************************************************************************/
  mileageCalc(originPrice, inputValue) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? Math.floor(((originPrice * (inputValue / 100)) + 4) / 10) * 10 : 0;
  }
  
  /*******************************************************************************
    설  명 : 비율 계산
  *******************************************************************************/
  rateCalc(originPrice, inputValue) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? (inputValue / originPrice * 100).toFixed(2) : 0;
  }

  /*******************************************************************************
    설  명 : 숫자만 입력
  *******************************************************************************/
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if( charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시
  *******************************************************************************/
  onTabChange( event: any ) {
    this.activeTab = event;

    this.productList = [];

    this.params.searchText = ''

    if( event === 0 ) {
      this.params.brand_seq = '';
      this.getEventSuperSaleCategoryList();
      // this.getEventSuperSaleCategoryProductList();
    } else if( event === 1 ) {
      this.getEventSuperSaleBrandList();
      // this.getEventSuperSaleBrandProductList();
    } else if( event === 2 ) {
      this.params.brand_seq = '';
      this.getEventSuperSaleSearchProductList();
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyCategory( params ) {
    this.gridApiCategory = params.api;
    this.gridColumnApiCategory = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 카테고리 그리드 행 클릭 시
  *******************************************************************************/
  onCategoryGridRowClicked( $event ) {
    this.params.category_code = $event.data.category_code;
    this.getEventSuperSaleCategoryProductList();
  }

  /*******************************************************************************
    설  명 : 카테고리 그리드 검색
  *******************************************************************************/
  onCategoryQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApiCategory.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyBrand( params ) {
    this.gridApiBrand = params.api;
    this.gridColumnApiBrand = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 브랜드 그리드 행 클릭 시
  *******************************************************************************/
  onBrandGridRowClicked( $event ) {
    this.params.brand_seq = $event.data.seq;
    this.getEventSuperSaleBrandProductList();
  }

  /*******************************************************************************
    설  명 : 브랜드 그리드 검색
  *******************************************************************************/
  onBrandQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApiBrand.setQuickFilter(searchText.value);
  }

  /*******************************************************************************
    설  명 : 셀 리사이즈 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onColumnResized(params) {
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 0);
  }

  onColumnVisible(params) {
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 0);
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady( params ) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    let product_seq = $event.data.product_seq;
    
    if( $event.colDef.field === 'thumbnail_url' ) {
      const url = '/product/list/add?seq=' + product_seq;
      window.open("about:blank").location.href = url;
    }
  }
  
  /*******************************************************************************
    설  명 : 셀 에디트 변경 시
  *******************************************************************************/
  onCellEditingStopped($event) {
    const originPrice = $event.node.data.event_price;
    switch ($event.colDef.field) {
      case 'discount_amt':
        $event.node.setDataValue( 'discount_rate', this.rateCalc(originPrice, $event.value) );
        break;
      case 'discount_rate':
        $event.node.setDataValue( 'discount_amt', this.amountCalc(originPrice, $event.value) );
        break;
      case 'mileage_amt':
        $event.node.setDataValue( 'mileage_rate', this.rateCalc(originPrice, $event.value) );
        break;
      case 'mileage_rate':
        $event.node.setDataValue( 'mileage_amt', this.mileageCalc(originPrice, $event.value) );
        break;
    }
  }

}
