/*******************************************************************************
  설  명 : POS 관리
  작성일 : 2020-09-03
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

import { UtilService } from '@app/service/util.service';
import { PosService } from '@app/service/pos.service';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { PosAddComponent } from '@app/page/system/pos-management/add/add.component';
import { PosAuthAddComponent } from '@app/page/system/pos-management/auth-add/auth-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-pos-management',
  templateUrl: './pos-management.component.html',
  styleUrls: ['./pos-management.component.scss']
})
export class PosManagementComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    sdate: '',
    edate: ''
  }

  pos_id = '';

  // 그리드 관련 선언
  posList: any = [];
  posMemberList: any = [];
  selectedNode: any = {
    pos_id: '',
    pos_auth_id: '',
    auth_group_id: '',
    auth_group_name: '',
    mem_no: '',
    mem_name: '',
    use_yn: '',
  };

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  selectedRows: number = 0;

  gridApiMember: any;
  gridColumnApiMember: any;
  columnDefsMember: any;
  selectedRowsMember: number = 0;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;


  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    public posService: PosService,
    private modalService: NgbModal,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'checkrow', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '순번', field: 'pos_id', hide: true },
      {headerName: '포스번호', field: 'pos_num', width: 80, cellClass: 'cp' },
      {headerName: '매장코드', field: 'store_id', hide: true },
      {headerName: '매장명', field: 'store_name', width: 120, cellClass: 'cp' },
      {headerName: '포스명', field: 'pos_name', width: 120, cellClass: 'cp' },
      {headerName: '사용여부', field: 'use_yn', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1' )
            return '<span class="badge badge-primary f12 fn">사용</span>';
          else
            return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefsMember = [
      {headerName: '', field: 'pos_auth_id', width: 50, cellClass: 'cp center'},
      {headerName: '권한그룹코드', field: 'auth_group_id', hide: true },
      {headerName: '권한그룹명', field: 'auth_group_name', width: 120, cellClass: 'cp' },
      {headerName: '아이디', field: 'mem_id', width: 100, cellClass: 'cp' },
      {headerName: '사용자명', field: 'mem_name', width: 100, cellClass: 'cp' },
      {headerName: '사용여부', field: 'use_yn', width: 80, cellClass: 'cp center' },
      {headerName: '비고', field: 'remark', width: 200, cellClass: 'cp' },
      {headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
      {headerName: '등록자', field: 'writer', width: 80, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getPosList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.selectedNode = $event.data;
    this.getPosAuthMemberList( $event.data.pos_id );
  }

  onRowDoubleClicked($event) {
    this.addPos( $event.data );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyMember(params) {
    this.gridApiMember = params.api;
    this.gridColumnApiMember = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedMember($event) {
    let filteredRowCount = 0;
    this.gridApiMember.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRowsMember = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 더블 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClickedMember($event) {
    this.selectedNode.pos_auth_id = $event.data.pos_auth_id;
    this.selectedNode.auth_group_id = $event.data.auth_group_id;
    this.selectedNode.auth_group_name = $event.data.auth_group_name;
    this.selectedNode.mem_no = $event.data.mem_no;
    this.selectedNode.mem_name = $event.data.mem_name;
    this.selectedNode.use_yn = $event.data.use_yn;
    this.addAuthPos();
  }

  /*******************************************************************************
    설  명 : POS 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPosList() {
    this.posService.getPosList().then( response => {
      if( response.ResultCode ) {

        this.posList = response.data;

        if( this.posList.length > 0 ) {

          this.selectedNode.pos_id = this.posList[0].pos_id;
          this.getPosAuthMemberList( this.posList[0].pos_id );
        }

      }
    });
  }

  getPosAuthMemberList(pos_id: string) {
    this.posService.getPosAuthMemberList(pos_id).then( response => {
      if( response.ResultCode ) {

        this.posMemberList = response.data;

        // Grid Selected
        let node = this.gridApi.getSelectedNodes();

        if( node.length < 1 ) {
          let index = 0;
          this.gridApi.forEachNode(function(node) {
            if (index++ == 0 ) {
              node.setSelected(true);
            }
          });
        }

      }
    });
  }

  /*******************************************************************************
    설  명 : pos 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addPos(item) {
    const modalRef = this.modalService.open(PosAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      this.getPosList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : POS 권한그룹 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addAuthPos() {
    const modalRef = this.modalService.open(PosAuthAddComponent, options);

    modalRef.componentInstance.selectedItem = this.selectedNode;

    modalRef.result.then((result) => {
      this.getPosList();
    }, (reason) => {
    });
  }

}
