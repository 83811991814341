<section class="mt10" style="height:725px;">
  <div class="mb5 form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="retrieve()">
      <i class="material-icons-outlined">search</i>
    </button>
    <select class="form-control form-control-small ml5">
      <option value="">상사 검색</option>
    </select>
    <input type="text" class="form-control form-control-small ml5" [(ngModel)]="retrieveOptions.partnerName" (keyup.enter)="retrieve()" placeholder="상사명 검색" />
  </div>
  <ag-grid-angular #grid class="ag-theme-balham" style="width: 100%;height:calc(100% - 35px);" [gridOptions]="gridOptions"></ag-grid-angular>
</section>
