<section class="mt5 print-none" id="print">
  <header>
    <div class="fl pt5 form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="reset()">
        <i class="material-icons-outlined">refresh</i>
      </button>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isPrint == ''}" (click)="search.isPrint='';getStockOutOrderToday();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isPrint == '0'}" (click)="search.isPrint='0';getStockOutOrderToday();">미출력</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isPrint == '1'}" (click)="search.isPrint='1';getStockOutOrderToday();">출력완료</button>
      </div>

      <input type="text" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="15" class="form-control form-control-small ml5" placeholder="주문번호 검색" />

      <!-- <input type="text" size="13" readonly="readonly" [value]="search.date" class="form-control form-control-small calendar-input ml5" placeholder="출고지시일" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate($event)" /> -->
      <span class="ml5">주문 수량 : {{form.value.order_cnt | number}} 건, 출고 상품 수량 : {{form.value.detail_cnt | number}} 건</span>
    </div>
    <div class="fr">
      <!-- <button type="button" class="btn btn-success btn-small ml5">결재상신</button> -->
      <button type="button" class="btn btn-danger btn-small ml5" (click) = "deleteDeliveryAll()">송장번호 전체삭제</button>
      <!-- <button type="button" class="btn btn-default btn-small ml5">송장업로드용<ag-grid-excel [gridApi]="gridApi" selected="false" filename="consign_cj"></ag-grid-excel></button> -->
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="consign_cj"></ag-grid-excel>
      <!-- <button type="button" class="btn btn-default btn-small ml5">엑셀출력</button> -->
      <button type="button" class="btn btn-default btn-small ml5">송장업로드 (.csv 형식)　<input type='file' (change)="fileChanged($event)" accept=".csv"></button>
      <!-- <button type="button" class="btn btn-danger btn-small ml5">합포장</button>
      <button type="button" class="btn btn-danger btn-small ml5">분리포장</button> -->
      <button type="button" class="btn btn-primary btn-small ml5" (click) = "printOutOrder()">프린트출력</button>
    </div>
    <div class="cb"></div>
  </header>

  <section class="mt5">
    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:5%" />
      <col style="width:14%" />
      <!-- <col style="width:7%" /> -->
      <col style="width:35%" />
      <col style="width:8%" />
      <col style="width:7%" />
      <col style="width:7%" />
      <col style="width:7%" />
      <col style="width:10%" />
      <col style="width:7%" />
    </colgroup>

    <thead>
    <tr>
      <th scope="col" style="vertical-align: middle;">
        <input type="checkbox" class="form-control form-control-small mr5" [(ngModel)]="isCheckedAll" [ngModelOptions]="{standalone: true}" (change)="setChecked($event)">
      </th>
      <!-- <th scope="col">구분</th> -->
      <th scope="col">상품이미지</th>
      <th scope="col">상품정보</th>
      <th scope="col">출고지시<br/>수량</th>
      <th scope="col">재고수량</th>
      <th scope="col">단가</th>
      <th scope="col">소계</th>
      <th scope="col">
        최근 입고 창고<br />
        최근 입고 위치
      </th>
      <th scope="col">처리</th>
    </tr>
    </thead>

    <tbody *ngFor = "let item of (form.value.stockOutOrderTodayList);">
    <tr>
      <td style="vertical-align: middle;">
        <div class="form-inline data-no">
          <label>
            <input type="checkbox" class="form-control form-control-small mr5" [(ngModel)]="item.isChecked" [ngModelOptions]="{standalone: true}">
          </label>
        </div>
      </td>
      <td>
        <ngx-barcode [bc-value]="item.mng_out_seq" [bc-display-value]="true" [bc-height]=50></ngx-barcode>
        <p *ngIf="item.grade != '0000' && item.grade != '0001'" class="mt20">
          <span class="badge badge-info f18">{{item.grade_name}}</span>
        </p>
      </td>
      <td colspan="6" class="left">
        <div class="fl">
          <p>
            <span class="badge badge-secondary f12" *ngIf="item.is_print == '0'">미출력</span>
            <span class="badge badge-primary f12" *ngIf="item.is_print == '1'">출력완료</span>
            <span class="badge badge-secondary f12 ml5" *ngIf="!item.shipping_number">송장미발행</span>
            <span class="badge badge-success f12 ml5" *ngIf="item.shipping_number">{{item.shipping_number}}</span>
            <button type="button" class="btn btn-default btn-xsmall ml5" (click) = "deleteDelivery(item.mng_out_delivery_seq)" *ngIf = "item.mng_out_delivery_seq">송장번호 삭제</button>
          </p>
          <p style="font-size:17px !important">주문번호 : {{item.order_seq}} / 주문시각 : {{item.order_date}} / 보내는사람 : {{item.o_name}} / 받는사람 : {{item.r_name}} <br>주소 : {{(item.r_address).slice(0,9)+" ***** "}}</p>
          <p [ngClass]="{cbgreenyellow: item.r_comment}">요청사항 : {{item.r_comment}}</p>
          <p>결제금액 : {{ (item.pay_amt || 0) | number}} 원 <span class="badge badge-success f12">{{item.pay_name}}</span> </p>
        </div>

        <div class="cb"></div>
      </td>

      <td>
        <button type="button" class="btn btn-warning btn-default"
          (click) = "outExceptionAll(item)">출고지시<br/>전체취소</button>
      </td>

    </tr>
    <tr *ngFor = "let sub_item of item._detail; index as idx">
      <td style="vertical-align: middle;">
        <div class="form-inline data-no">
          <label>
            <!-- <input type="checkbox" class="form-control form-control-small mr5" /> -->
            <span class="ml5 f14">{{idx + 1}}</span>
          </label>
        </div>
      </td>
      <!-- <td>{{sub_item.product_gbn}}</td> -->
      <td>
        <img style="height: 100px; width: 100px;" src="{{sub_item.thumbnail_url}}">
        <p *ngIf="sub_item.is_mach != '0'" class="mt5">
          <span class="badge badge-danger f15">오늘출발</span>
        </p> 
      </td>
      <td class="left">
        <div style="height: 20px;">상품/옵션번호 : {{sub_item.product_seq}} / {{sub_item.product_property_seq}}</div>
        <div style="height: 20px;">카테고리 : {{sub_item.category_name}}</div>
        <div style="height: 40px;">상품명 : {{sub_item.product_name}}</div>
        <div style="height: 20px;">색상 : {{sub_item.color_name}} - {{sub_item.display_color_name}}</div>
        <div style="height: 20px;">사이즈 : {{sub_item.size_name}} - {{sub_item.display_size_name}}</div>
      </td>
      <td class="right"><div>{{sub_item.out_order_qty | number}}</div></td>
      <td class="left"><div>대구: {{sub_item.daegu_stock | number}}<br />서울: {{sub_item.seoul_stock | number}}</div></td>
      <!-- <td class="right"><div>{{(sub_item.amt - sub_item.discount_amt) | number}}</div></td> -->
      <td class="right"><div>{{sub_item.unit_price | number}}</div></td>
      <td class="right">
        <!-- <div>{{sub_item.total_amt | number}}</div>
        <div>({{sub_item.discount_amt | number}})</div> -->
        <div>{{sub_item.total_amt | number}}</div>
      </td>
      <!-- <td class="right"><div>{{sub_item.pay_amt | number}}</div></td> -->
      <td><div>{{sub_item.warehouse_name}}</div><div>{{sub_item.location_cd}}</div></td>
      <td *ngIf = "sub_item.status == 1000">
        <!-- <div>
          <button type="button" class="btn btn-default btn-xsmall" (click) = "outComplete(sub_item)">완료처리</button>
        </div> -->
        <div class="mt5">
          <button type="button" class="btn btn-danger btn-xsmall"
            (click) = "outException({
              mng_out_seq : item['mng_out_seq'],
              order_master_seq : item['order_master_seq'],
              mng_out_order_seq : sub_item['mng_out_order_seq'],
              order_detail_seq : sub_item['order_detail_seq']
            })">출고지시취소</button>
        </div>
      </td>
    </tr>
    </tbody>

    </table>
  </section>
</section>

<div *ngIf = "!form.value.stockOutOrderTodayList.length">
  <p style="text-align: center; line-height: 50vh;">데이터가 없습니다.</p>
</div>

<div style="display: none;">
  <ag-grid-angular
    #myGrid

    style="width:100%;height: 100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="gridData"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</div>
