/*******************************************************************************
  설  명 : 회원관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { CommonService } from '@app/service/common.service';
import { MemberService } from '@app/service/member.service';
import { EmailService } from '@app/service/email.service';
import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { MemberInfoComponent } from '@page/member/member-info/member-info.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const pageRow: number = 100;

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: pageRow,
    level: '',
    searchText: '',
    searchText2: '',
    gender: '',
    email_yn: '',
    sms_yn: '',
    grade: '',
    enteredStatus: '',
    status: '',
    dormant: '',
    id: '1',
    sales: '',
    searchWordStart: '',
    searchWordEnd: '',
    userclass: '',
    state: '' ,
    lastLogin:'', 
  };

  public userClass: any = [
    {id: '', text: '회원등급선택'},
    {id: '1', text: '1등급'},
    {id: '2', text: '2등급'},
    {id: '3', text: '3등급'},
    {id: '4', text: '4등급'},
    {id: '5', text: '5등급'},
    {id: '6', text: '6등급'},
    {id: '7', text: '7등급'},
    {id: '8', text: '8등급'},
    {id: '9', text: '9등급'},
    {id: '10', text: '10등급'},
    {id: '11', text: '11등급'},
    {id: '12', text: '12등급'},
    {id: '13', text: '13등급'},
    {id: '14', text: '14등급'},
    {id: '15', text: '15등급'},
  ];

  public select2Options: any = {
    multiple: true,
    placeholder: '회원등급 검색'
  };

  public selectedSeq: any;

  public statistic: any = {
    date: '',
    total_count: 0,
    normal_count: 0,
    non_count: 0,
    dormant_count: 0,
    month_count: 0,
    today_count: 0,
    change_date: ''
  };

  public totalCount: any;

  public gradeList: any = [];
  public memberList: any = [];
  public stateList: any = [];
  public enteredStatusList: any = [];

  // 테스트용 
  public dormantMemberList: any = [];

  public searchWord: any = [
    {start: '가', end: '나'},
    {start: '나', end: '다'},
    {start: '다', end: '라'},
    {start: '라', end: '마'},
    {start: '마', end: '바'},
    {start: '바', end: '사'},
    {start: '사', end: '아'},
    {start: '아', end: '자'},
    {start: '자', end: '차'},
    {start: '차', end: '카'},
    {start: '카', end: '파'},
    {start: '파', end: '타'},
    {start: '타', end: '하'},
    {start: '하', end: ''},
    {start: 'A', end: 'Z'},
  ]

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApi1: any;
  gridColumnApi1: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public sendList: any = [];
  public dormantList: any = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private agGridExComponent: AgGridExComponent,
    private memberService: MemberService,
    private emailService: EmailService,
    private utilService: UtilService,
    
    
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '회원번호', field: 'mem_no', width: 120, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '회원상태', field: 'status', width: 83, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '1' ) {
            return '<span class="badge badge-primary f12">정상회원</span>';
          } else {
            return '<span class="badge badge-secondary f12">탈퇴회원</span>';
          }
        }
      },
      { headerName: '회원유형', field: 'grade_name', width: 94, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.grade == '0001' ) {
            return '<span class="badge badge-secondary f12">' + params.data.grade_name + '</span>';
          } else if( params.data.grade == '0002' ) {
            return '<span class="badge badge-warning f12">' + params.data.grade_name + '</span>';
          } else if( params.data.grade == '0003' ) {
            return '<span class="badge badge-primary f12">' + params.data.grade_name + '</span>';
          } else if( params.data.grade == '0004' ) {
            return '<span class="badge badge-success f12">' + params.data.grade_name + '</span>';
          } else if( params.data.grade == '0005' ) {
            return '<span class="badge badge-light f12">' + params.data.grade_name + '</span>';
          } else if( params.data.grade == '0006' ) {
            return '<span class="badge badge-dark f12">' + params.data.grade_name + '</span>';
          } else if( params.data.grade == '0007' ) {
            return '<span class="badge badge-danger f12">' + params.data.grade_name + '</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '업체구분', field: 'entered_status', width: 82, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.entered_status == '1000' ) {
            return '<span class="badge badge-primary f12">' + params.data.entered_status_name + '</span>';
          } else if( params.data.entered_status == '9000' ) {
            return '<span class="badge badge-secondary f12">' + params.data.entered_status_name + '</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '이름', field: 'name', width: 118, cellClass: 'cp center' },
      { headerName: 'ID', field: 'id', width: 130, cellClass: 'cp' },
      { headerName: '휴대폰번호', field: 'hp', width: 112, cellClass: 'cp center' },
      { headerName: '이메일', field: 'email', width: 180, cellClass: 'cp ' },
      { headerName: '이메일수신', field: 'email_yn', width: 95, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.email_yn == '1' ) {
            return '<span class="badge badge-success f12">이메일수신</span>';
          } else {
            return '<span class="badge badge-secondary f12">수신안함</span>';
          }
        }
      },
      { headerName: 'SMS수신', field: 'sms_yn', width: 84, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.sms_yn == '1' ) {
            return '<span class="badge badge-success f12">SMS수신</span>';
          } else {
            return '<span class="badge badge-secondary f12">수신안함</span>';
          }
        }
      },
      { headerName: '지역', field: 'state_name', width: 70, cellClass: 'cp center' },
      { headerName: '가입일시', field: 'joindate', width: 142, cellClass: 'cp center' },
      { headerName: '마지막접속', field: 'last_logindate', width: 142, cellClass: 'cp center' },
      // { headerName: '구매등급', field: 'buyclass', width: 92, cellClass: 'cp right' },
      { headerName: '구매등급', field: 'newUserClass', width: 92, cellClass: 'cp center' },
      { headerName: '구매횟수', field: 'buy_count', width: 92, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '구매액', field: 'buy_sum', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '누적마일리지', field: 'total_mileage', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '사용마일리지', field: 'used_mileage', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '가용마일리지', field: 'remainder_mileage', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
    ];



    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    this.getMemberStatistic();
    this.getMemberList();
    this.getDormantList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 회원등급
    this.commonService.getCommonListCode('MBG').then( response => {
      if ( response.ResultCode ) {
        this.gradeList = response.data;
      } else {
        this.gradeList = [];
      }
    });

    this.memberService.getMemberGradeList()
    .then(response => this.userClass = response.ResultCode ? 
      [{id: '', text: '회원등급선택'}].concat(response.data.map((item: any) => ({id: item.seq, text: item.grade_name}))) : [])

    // 시/도
    this.commonService.getCommonListCode('ADDR').then( response => {
      if ( response.ResultCode ) {
        this.stateList = response.data;
      } else {
        this.stateList = [];
      }
    });

    // 입점상태
    this.commonService.getCommonListCode('ETS').then( response => {
      if ( response.ResultCode ) {
        this.enteredStatusList = response.data;
      } else {
        this.enteredStatusList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberStatistic() {
    this.memberService.getMemberStatistic().then( response => {
      if( response.ResultCode ) {
        this.statistic = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }


  /*******************************************************************************
    설  명 : 휴면예정 회원리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getDormantList() {
    this.memberService.getMemberDormantScheduleList().then( response => {
      if( response.ResultCode ) {
        this.dormantList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '' );
    })
  }

  /*******************************************************************************
    설  명 : 회원리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberList() {
    this.memberService.getMemberList( {
      ...this.search, userclass: null, newUserClass: this.search.userclass?.length ? this.search.userclass.map((item: any) => item.id) : null
    } ).then( response => {
      if( response.ResultCode ) {
        this.memberList = response.data;
        this.totalCount = response.total;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.selectedSeq = $event.data.mem_no;
    this.addMember( $event.data.mem_no );
  }

  /*******************************************************************************
    설  명 : 정렬 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSortChanged($event) {
    var sortState = this.gridApi.getSortModel();
    if (sortState.length == 0) {
    } else {
      for (var i = 0; i < sortState.length; i++) {
        var item = sortState[i];
      }
    }
  }

  /*******************************************************************************
    설  명 : 회원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addMember( memNo: number ) {
    const modalRef = this.modalService.open(MemberInfoComponent, optionsLG);

    modalRef.componentInstance.memberNo = memNo;
    modalRef.componentInstance.dormant = false;

    modalRef.result.then((result) => {
      // 수정 / 추가 시
      if( result ) {
        this.getMemberList();

      // 삭제 시
      } else if( result == false ) {
        this.selectedSeq = null;
        this.getMemberList();
      }
    }, (reason) => {


    });


  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: pageRow,
      level: '',
      searchText: '',
      searchText2: '',
      gender: '',
      email_yn: '',
      sms_yn: '',
      grade: '',
      enteredStatus: '',
      status: '',
      dormant: '',
      id: '1',
      sales: '',
      searchWordStart: '',
      searchWordEnd: '',
      userclass: '',
      state: '' ,
      lastLogin: '' 
    };

    this.getMemberList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getMemberList();
  }

  /*******************************************************************************
    설  명 : 회원등급 검색 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setUserclassChange( event: any ) {
  }

  /*******************************************************************************
    설  명 : Ag Grid 선택시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowSelected(event) {
    event.node.isSelected()
    ? this.sendList.push(event.node.data)
    : this.sendList.splice(this.sendList.findIndex(_e => _e.mem_no === event.node.data.mem_no), 1);
  }

  /*******************************************************************************
    설  명 : 이메일 테스트 발송하기 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    sendEmailDirectSend(auto = false) {

      this.memberService.getMemberDormantScheduleListEmail().then( response => {
        if( response.ResultCode ) {
          console.log(`메일발송데이터 체크:${JSON.stringify(response.data)}`);
          this.dormantMemberList = response.data;
          const params = {
            subject : '휴면 예정 계정 안내',
            contents : '',
            from : '',
            to :JSON.stringify(this.dormantMemberList),
            mem_no : '' , 
            template : 49
          };
    
          this.emailService.setSendEmail_directSend(params).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage );
            } else {
              this.toastrService.error( response.ResultMessage );
            }
          });

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '' );
      })





  
    }  

}
