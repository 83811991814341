import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'Moment'
})
export class MomentPipe implements PipeTransform {
  constructor() {
    moment.locale('ko');
  }

  public transform(value: moment.MomentInput, format: string): string {
    return value ? moment(value).format(format) : null;
  }
}
