/*******************************************************************************
  설  명 : 현황관리 - 전체 매출현황 (심플버전)
  작성일 : 2021.11.10
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { StatisticService } from '@app/service/statistic.service';

@Component({
  selector: 'app-statistic-simple',
  templateUrl: './statistic-simple.component.html',
  styleUrls: ['./statistic-simple.component.scss']
})
export class StatisticSimpleComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  // 차트 관련
  public chartType = 'bar';
  public chartOptions = {
    tooltips: {
      display: true,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: 'chocolate',
      bodyFontColor: '#000',
      bodyFontSize: 12,
      displayColors: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipItem.value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') + ' 원';
        }
      }
    },
    scaleShowVerticalLines: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad'
    },
    responsive: true,
    legend: {
      display: true
    },
    hover: {
      mode: 'arest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data Value'
        },
        ticks: {
          beginAtZero: true,
          callback : value => value.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        }
      }]
    }
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs = []
  rowData = []
  defaultColDef: any = {
    sortable: true,
    filter: false,
    resizable: true
  };
  domLayout: any;
  rowSelection: any = 'single'
  noRowsTemplate: string = '검색된 데이터가 없습니다.'
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search_protoType = {
    yearCnt: 5,
    memberType: 0
  }
  search = this.search_protoType

  barChartData_protoType = {type: 'bar', data: [], label: '', fill: false, lineTension: 0}
  barChartData = [this.barChartData_protoType]
  chartLabel = []
  

  superSimpleMode = false
  dataMode: any = {}
  gridApi_superSimple: any;
  gridColumnApi_superSimple: any;
  columnDefs_superSimple: any = []
  rowData_superSimple = []

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private agGridExComponent: AgGridExComponent,
    private statisticService: StatisticService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( async params => {
      this.setChartLabelSimple()
      const type = parseInt(params.type)
      const option = {brand: params.brand, category: params.category, memberType: params.memberType || this.search_protoType.memberType, yearCnt: params.yearCnt || this.search_protoType.yearCnt}
      if(!type) {this.superSimpleMode = false; this.getSaleTotalSimple(0, option)} 
      else {this.superSimpleMode = true; this.getSaleSuperSimple(type, option)}
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReady_superSimple(params) {
    this.gridApi_superSimple = params.api;
    this.gridColumnApi_superSimple = params.columnApi;
  }

  changeYearCnt($event) {
    this.router.navigate(['/statistic/simple'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...this.dataMode,
        yearCnt: $event.target.value
      }
    })
  }
  changeMemberType($event) {
    this.router.navigate(['/statistic/simple'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...this.dataMode,
        memberType: $event.target.value
      }
    })
  }

  setChartLabelSimple() {
    this.chartLabel = []
    const todayYear = new Date().getFullYear()
    const yearCnt = this.search.yearCnt
    this.columnDefs = [{ headerName: '구분', field: 'type', width: 120, cellClass: 'cp center', flex: 0 }]
    for(let i = yearCnt - 1; i > -1; i--) {
      const thisYear = todayYear - i
      this.chartLabel.push(thisYear)
      this.columnDefs.push({ 
        headerName: `${thisYear}년`, field: `${thisYear}`, cellClass: 'cp right', flex: 1, valueFormatter: this.agGridExComponent.currencyFormatter
      })
    }
  }

  loadMessage(isFinish = false) {
    isFinish 
    ? this.toastrService.success('데이터를 불러왔습니다.')
    : this.toastrService.info('데이터를 불러오고 있습니다. 성공 또는 실패 메시지가 뜰 때까지 기다려주십시오.')
  }

  getSaleTotalSimple(type = 0, option: any = {}) {
    this.loadMessage()
    this.dataMode = {type: type, ...option}

    this.statisticService.getSaleTotalSimple({
      s_year: this.chartLabel[0],
      e_year: this.chartLabel[this.chartLabel.length - 1],
      memberType: option.memberType
    }).then(res => {
      if(res.ResultCode) {
        this.loadMessage(true)
        
        this.barChartData = []
        const keyArray = []
        for (const key in res.data[0]) if(key != 'year') {
          this.barChartData.push({type: (key == '전체주문' || key == '판매마진') ? 'bar' : 'line', data: res.data.map(item => item[key]), label: key, fill: false, lineTension: 0})
          keyArray.push(key)
        }

        this.rowData = res.data.map((e,i,a) => {
          let data = {type: keyArray[i]}
          for(let _i = 0; _i < keyArray.length; _i++) data = {...data, [this.chartLabel[_i]]: a[_i][keyArray[i]]}
          return data
        })

      } else this.toastrService.error(res.ResultMessage)
    }).catch(e => this.toastrService.error(e))
  }
  
  getSaleSuperSimple(type = 0, option: any = {}) {
    this.loadMessage()
    this.dataMode = {type: type, ...option}

    const s_year = this.chartLabel[0]
    const e_year = this.chartLabel[this.chartLabel.length - 1]
    
    this.statisticService[
      type == 1 
      ? 'getSaleCategorySimple' 
      : (
        type == 2 
        ? 'getSaleBrandSimple' 
        : (
          type == 3 
          ? 'getSaleOpenmarketSimple'
          : 'getSaleBusinessSimple'
          )
        )
    ]({
      s_year: s_year,
      e_year: e_year,
      memberType: option.memberType,
      category_code: option.category,
      brand_seq: option.brand
    }).then(res => {
      if(res.ResultCode) {
        this.loadMessage(true)

        const keyArray = []
        for (const key in res.data[0]) if(!RegExp('[a-zA-Z]').test(key)) keyArray.push(key)
        const keySort = ['전체주문', '판매수량', '상품평균가', '마진율', '판매마진'] // 출력 순서 고정
        
        this.columnDefs_superSimple = [{
          headerName: '구분', field: 'type', width: 200, flex: 0, cellClass: 'left', cellRenderer: 'agGridHtmlComponent', 
          valueGetter: (params: any) => `<b style="color: green">${params.data.title}</b><br>${keySort[1]}<br>${keySort[2]}<br>${keySort[3]}<br>${keySort[4]}`, 
          valueFormatter: this.agGridExComponent.currencyFormatter
        }]
        const set3 = (any: any) => parseFloat(String(any || 0)).toLocaleString()
        this.chartLabel.forEach(e => {
          this.columnDefs_superSimple.push({ 
            headerName: `${e}년`, field: '', width: 300, flex: 1, cellClass: 'right', cellRenderer: 'agGridHtmlComponent', 
            valueGetter: (params: any) => `${set3(params.data[e]?.[keySort[0]])} 원<br>${set3(params.data[e]?.[keySort[1]])} 개<br>${set3(params.data[e]?.[keySort[2]])} 원<br>${set3(params.data[e]?.[keySort[3]])} %<br>${set3(params.data[e]?.[keySort[4]])} 원`,
            valueFormatter: this.agGridExComponent.currencyFormatter
          })
        })

        const rowData_superSimple_temp = []
        
        const 정렬기준 = '전체주문'
        const 제목 = (type == 1 && !option.category) || (type == 2 && option.brand && !option.category) ? 'category_code' : ((type == 2 && !option.brand) || (type == 1 && option.category && !option.brand) ? 'brand_seq' : ((type == 2 && option.category && option.brand) || (type == 1 && option.category && option.brand) ? 'product_seq' : 'shop_name'))
        const 코드 = type == 1 ? 'category_code' : (type == 2 ? 'brand_seq' : (type == 3 ? 'shop_gbn' : 'shop_name'))
        res.data.filter((e,i,a) => a.findIndex(_e => _e[제목] == e[제목]) == i).sort((a,b) => b[정렬기준] - a[정렬기준]).map(e => e[제목]).forEach(e => {
          const tempObject = {type: e, code: res.data.find(_e => _e[제목] == e)[코드], title: res.data.find(_e => _e[제목] == e)[제목 == 'category_code' ? 'category_name' : (제목 == 'brand_seq' ? 'brand_name' : (제목 == 'product_seq' ? 'product_name' : 'shop_name'))]}
          this.chartLabel.forEach(_e => {
            const data = res.data.find(__e => __e[제목] == e && __e.year == _e)
            const blankData = {}
            keyArray.forEach(e => blankData[e] = 0)
            tempObject[_e] = data || blankData
          })
          rowData_superSimple_temp.push(tempObject)
        })

        // const 복사기준 = 'year'
        // rowData_superSimple_temp.forEach(item => {
        //   for(let i = 0; i < this.chartLabel.length; i++) {
        //     if(!item[this.chartLabel[i]][복사기준]) {
        //       let original = {}
        //       for (const key in item) if(typeof(item[key]) == 'object' && item[key][복사기준]) {
        //         for (const sub_key in item[key]) if (RegExp('[a-zA-Z]').test(key)) original[sub_key] = item[key][sub_key]
        //         break
        //       }
        //       item[this.chartLabel[i]] = {...original, ...item[this.chartLabel[i]]}
        //     }
        //   }
        // })

        this.rowData_superSimple = rowData_superSimple_temp.sort((a,b) => b[e_year][정렬기준] - a[e_year][정렬기준])

      } else this.toastrService.error(res.ResultMessage)
    }).catch(e => this.toastrService.error(e))
  }

  cellClicked($event) {
    // if($event.colDef.field == 'type')

    const seq: any = {}
    for(let i = 0; i < this.chartLabel.length; i++) {
      seq.brand = seq.brand || $event.data[this.chartLabel[i]]['brand_seq']
      seq.category = seq.category || $event.data[this.chartLabel[i]]['category_code']
      seq.product = seq.product || $event.data[this.chartLabel[i]]['product_seq']
    }

    if(this.dataMode.brand && this.dataMode.category) {

      const url = '/product/salesstatus?seq=' + seq.product;
      window.open("about:blank").location.href = url;

    } else {

      const searchParams: any = {type: this.dataMode.type}
      if(this.dataMode.type == 1 && !this.dataMode.category && !this.dataMode.brand) {
        searchParams.category = seq.category
        searchParams.brand = ''
      } else if(this.dataMode.type == 1 && this.dataMode.category && !this.dataMode.brand) {
        searchParams.category = seq.category
        searchParams.brand = seq.brand
      } if(this.dataMode.type == 2 && !this.dataMode.category && !this.dataMode.brand) {
        searchParams.category = ''
        searchParams.brand = seq.brand
      } else if(this.dataMode.type == 2 && !this.dataMode.category && this.dataMode.brand) {
        searchParams.category = seq.category
        searchParams.brand = seq.brand
      }

      this.router.navigate(['/statistic/simple'], {
        relativeTo: this.activatedRoute,
        queryParams: searchParams
      })

    }

  }
};
