import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal , NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '@app/service/common.service';
import * as $ from 'jquery';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { MemberService } from '@app/service/member.service';
import { MemberFindComponent } from '@components/member-find/member-find.component';
import * as moment from 'moment';


const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-member-society-add',
  templateUrl: './member-society-add.component.html',
  styleUrls: ['./member-society-add.component.scss']
})
export class MemberSocietyAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public shopSeq: any;
  public seq: any;
  public groupUpdate: any = '0';

  public title: any = '추가';
  public regionList: any = [];

  public form: FormGroup;
  public formErrors = {};

  public searchMemberFunc: any;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public baseURL = config.baseUrl;

  public imageList: any = [];


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {

    this.form = this.formBuilder.group({
      seq: ['', [] ],
      society_name: ['', [Validators.required] ],
      rep_mem_no: ['', [Validators.required] ],
      searchName: ['', [Validators.maxLength(50)] ],
      application_date: ['', [Validators.required] ],
      is_approval: ['', [] ],
      approval_date: ['', [] ],
      memberCount: ['0', [] ],
      society_link: ['', [] ],
      region_cd: ['', [] ],
      region_name: ['', [] ],
      society_description: ['', [] ],
      update_all: [false, []],
      file: ['', []],
      upload: [[], []],
      files: [[], []] , 
      file_count: ['0', [] ]

    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private restful: RestfulService,    
    private toastrService: ToastrService,
    private memberService: MemberService ,
    private modalService: NgbModal,
    private commonService: CommonService,
  ) {
    this.buildForm();
    this.searchMemberFunc = this.searchMember.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      seq: this.seq
    });
    if( this.seq == 0 || this.seq == ""  ) {
      this.title = '추가';

    } else {
      this.title = '수정';
      // 동호회 정보 가져오기
      this.getSocietyInfo( this.seq );
    }
    this.form.patchValue({
      update_all: this.groupUpdate
    });


    this.getCommonList();
    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 10 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(10MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }
      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });      
  
    }
    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const files = this.form.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: this.baseURL + response.thumbnail,
          thumbnail_org: response.thumbnail_org,
          is_default: '0'
        });

        this.form.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '');
      }
      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
    
  }

  /*******************************************************************************
    설  명 : 동호회 정보 가져오기
    입력값 : seq = 동호회 코드
    리턴값 : 없음
  *******************************************************************************/
  getSocietyInfo( seq: any ) {
    this.memberService.getSocietyInfo( seq ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          seq: response.data[0].seq,
          society_name:response.data[0].society_name , 
          rep_mem_no: response.data[0].rep_mem_no,
          searchName: response.data[0].rep_name,
          application_date: this.utilService.getDate(response.data[0].application_date),
          is_approval: response.data[0].is_approval,
          approval_date: this.utilService.getDate(response.data[0].approval_date),
          memberCount: response.data[0].memberCount,
          society_link: response.data[0].society_link,
          region_cd: response.data[0].region_cd,
          region_name: response.data[0].region_name,
          society_description: response.data[0].society_description,
          upload:response.data[0].upload,
          file_count:response.data[0].upload.length
        });

      } else {
        this.toastrService.error( "동호회 정보를 가져오는데 실패하였습니다.", "동호회 수정" );

        this.activeModal.close(true);
      }
    });
  }


  
  /*******************************************************************************
    설  명 : 회원 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        rep_mem_no: result.data.mem_no,
        searchName: result.data.name,
      });

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 동호회 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    const required = {
      society_name: '동호회명',
      rep_mem_no: '대표자회원번호',
      appliction_date: '신청일자',
    }
    for(const key in this.form.value) {
      if(!this.form.value[key] && required[key]) return this.toastrService.warning(required[key] + '을(를) 입력하세요.')
    }
    this.memberService.setSocietyInfo(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '동호회 정보 저장');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '동호회 저장');
        }
    });
  }

    /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getCommonList() {
      // 지역구분
      this.commonService.getCommonListCode('ADDR').then( response => {
        if ( response.ResultCode ) {
          response.data.forEach( row => {
            this.regionList.push({
              value: row.common_code,
              title: row.common_name,
            });
          });
        } else {
          this.regionList = [];
        }
      });
  
    }

  /*******************************************************************************
    설  명 : 지역변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeRegion() {
    const region = this.regionList.filter(item => item.value === this.form.value.region_cd);
    console.log(region);
    if( region[0].value ) {
      this.form.patchValue({
        region_name: region[0].title,
      });
    }
  }


  /*******************************************************************************
    설  명 : 동호회 정보 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delSociety() {
    if( confirm("선택하신 동호회를 삭제하시겠습니까?") ) {
      this.memberService.delSociety(this.seq).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '동호회 삭제');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '동호회 삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 승인여부 변경 시 승인 일시 세팅하기 .
    입력값 : code
    리턴값 : 없음
  *******************************************************************************/
    changeApproval() {
      console.log(this.form.value.is_approval);
      if (this.form.value.is_approval == "1")
      {
        this.form.patchValue({
          approval_date: this.utilService.getDate(moment()),
        });        
      } else {
        this.form.patchValue({
          approval_date: null,
        });        
      }
  
    } 
    
  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    handleUploadFileChanged(event) {
      this.uploader.clearQueue();
  
      // 파일 없을 경우 return false;
      if( event.target.files.length < 1 ) return false;

      // 전체 허용 용량 검증
      const files: File[] = event.target.files;
      const filteredFiles: File[] = [];
      for(const f of files) {
        filteredFiles.push(f);
      }
  
      const options = null;
      const filters = null;
  
      this.uploader.addToQueue(filteredFiles, options, filters);
  
      // indicator 표시
      this.restful.indicator.next(true);
  
      this.uploader.uploadAll();
      this.form.patchValue({file_count:1 });
    } 
    
  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
    checkImage( ext: string ) {
      let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
  
      if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
      else return true;
    }  
    
  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
    getExt( filename ) {
      if( typeof filename == 'undefined' ) return '';
      else return filename.substr(filename.lastIndexOf('.') + 1);
    }    

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
    getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
    setDeleteUploadFile( upload: any, index: any ) {
      if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
        
        this.memberService.setDeleteUploadFile( upload.seq ).then( response => {
          if( response.ResultCode ) {
            let uploadData = this.form.controls.upload.value;
            uploadData.splice( index, 1 );
            this.form.patchValue(uploadData);

            this.form.patchValue({file_count:0 });
    
  
            this.toastrService.success( response.ResultMessage, '');
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }    

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
    deleteFile( file: any, index ) {
      if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
        let tmp = this.form.controls.files.value;
  
        tmp.splice( index, 1 );
  
        this.form.patchValue({files: tmp});
        this.form.patchValue({file_count:0 });
      }
    }

}
