<section id="email">
  <div class="mb5">
    <div class="fr w30p right">
      <button type="button" class="btn btn-default btn-small" (click)="list()">목록으로</button>
      <button *ngIf="form.controls.seq.value!==''" type="button" class="btn btn-danger btn-small ml5" (click)="delete()">삭제하기</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="submit()">저장하기</button>
    </div>
    <div class="cb"></div>
  </div>

  <section class="contents-body" style="height:auto !important">
    <form [formGroup]="form">
      <table class="table table-bordered table-small">
      <caption class="hidden">기본정보</caption>

      <colgroup>
        <col style="width:15%" />
        <col style="width:85%" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="title">제목</label></th>
        <td>
          <inputEx type="text" name="title" [formGroup]="form" [formErrors]="formErrors" [autofocus]="true"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="autoName">자동발송</label></th>
        <td>
          <div class="form-inline">
            <inputEx type="select" name="auto_seq" [formGroup]="form" [formErrors]="formErrors" [data]="autoList"></inputEx>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active pt10" style="vertical-align:top;">
          <div class="pt10">발송양식</div>

          <div class="pt10">
            #회원명<br />
            #아이디<br />
            #임시비밀번호<br />
            #일자<br />
            #시간<br />
          </div>
        </th>
        <td>
          <inputEx type="summernote" name="contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
    </form>
  </section>
</section>
