import { Component, Injectable, OnInit } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as moment from 'moment';

import { RestfulService } from '@app/service/restful.service';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<Date> {
  fromModel(value: Date | null): NgbDateStruct | null {
    return value ? {year: value.getFullYear(), month: value.getMonth() + 1, day: value.getDate() } : null;
  }

  toModel(value: NgbDateStruct | null): Date | null {
    return value ? new Date(value.year, value.month - 1, value.day) : null;
  }
}

@Component( {
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.scss'],
  providers: [ {provide: NgbDateAdapter, useClass: CustomAdapter} ],
} )
export class ExchangeRateComponent implements OnInit {
  public erdateTerms: any = [
    {title: '오늘', value: 0},
    {title: '어제', value: 1},
    {title: '3일', value: 3},
    {title: '7일', value: 7},
    {title: '15일', value: 15},
    {title: '1개월', value: 30},
    {title: '3개월', value: 60},
    {title: '6개월', value: 90},
  ];

  public retrieveData: any = {
    er_date_a: Date,
    er_date_b: Date,
    er_date_term: 0,
  };

  public chartKeyData: any = [
    {key: 'er_usd', name: '달러', color: '#e25f5f'},
    {key: 'er_eur', name: '유로화', color: '#36A2EB'},
    {key: 'er_cny', name: '위안화', color: '#ffcd56'},
    {key: 'er_jpy', name: '엔화', color: '#4bc0c0'},
  ];

  public latestExchangeRatesData: any;
  public exchangeRatesData: any;

  public chartLabels: Label[] = [];
  public chartType: ChartType = 'line';
  public chartLegend = true;
  public chartDatasets: any = [];
  public chartOptions: ChartOptions = {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => tooltipItem.yLabel.toString()
      }
    },
    responsive: true,
  };

  public gridRowData: any = [];
  public gridColumnDefs: any;
  public gridDefaultColDef: any;
  public gridDomLayout: any;
  public gridRowSelection: any;
  public gridNoRowsTemplate: string;

  constructor(
    private restfulService: RestfulService,
  ) {
  }

  ngOnInit(): void {
    this.gridColumnDefs = [
      {
        headerName: '선택',
        field: 'storeSeq',
        width: 80,
        cellClass: 'cp center',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {headerName: '일자', field: 'er_date', cellClass: 'cp center'},
      {headerName: '달러', field: 'er_usd', cellClass: 'cp right'},
      {headerName: '엔화', field: 'er_jpy', cellClass: 'cp right'},
      {headerName: '위안화', field: 'er_cny', cellClass: 'cp right'},
      {headerName: '유로화', field: 'er_eur', cellClass: 'cp right'},
      {headerName: '동기화 일시', field: 'modified_time', cellClass: 'cp center'},
    ];

    this.gridDefaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.gridRowSelection = 'multiple';
    this.gridNoRowsTemplate = '검색된 데이터가 없습니다.';

    this.restfulService.get( {
      program: 'admin',
      service: 'basic',
      version: '1.0',
      action: 'getLatestExchangeRates'
    }, {
    } ).then(response => {
      if (response.resultCode) {
        this.latestExchangeRatesData = response.data;
      }
    } );

    this.set_er_dates(30);
  }

  today(): Date {
    return new Date();
  }

  set_er_dates(value: number): void {
    this.retrieveData.er_date_a = moment(this.today() ).subtract(value, 'days').toDate();
    this.retrieveData.er_date_b = this.today();
    this.retrieveData.er_date_term = value;

    this.retrieve();
  }

  retrieve(): void {
    this.restfulService.get( {
      program: 'admin',
      service: 'basic',
      version: '1.0',
      action: 'getExchangeRates'
    }, {
      er_date_a: moment(this.retrieveData.er_date_a).format('YYYY-MM-DD'),
      er_date_b: moment(this.retrieveData.er_date_b).format('YYYY-MM-DD'),
    } ).then(response => {
      if (response.resultCode) {
        this.chartLabels = response.data.map(row => row.er_date);

        for(let i = 0; i < this.chartKeyData.length; i++ ) {
          this.chartDatasets[i] = [{
            label: this.chartKeyData[i].name,
            data: response.data.map(row => row[this.chartKeyData[i].key] ),
            fill: false,
            borderColor: this.chartKeyData[i].color
          }];
        }

        this.gridRowData = response.data;
      }
    } );
  }

  collect(): void {
    this.restfulService.get( {
      program: 'admin',
      service: 'basic',
      version: '1.0',
      action: 'collectExchangeRates'
    }, {
    } ).then(response => {
      if (response.resultCode) {
        this.retrieve();
      }
    } );
  }
}
