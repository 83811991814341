<div class="modal-header">
  <h4 class="modal-title fb mb0">슈퍼세일 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <table class="table table-bordered table-small mb0">
    <caption class="none">슈퍼세일 정보</caption>
    <colgroup>
      <col style="width:20%;" />
      <col style="width:30%;" />
      <col style="width:20%;" />
      <col style="width:30%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="supersale_name">슈퍼세일명</label></th>
      <td colspan="3"><inputEx type="text" name="supersale_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="test_mem_no">테스트아이디</label></th>
      <td colspan="3"><inputEx type="text" name="test_mem_no" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="sDate">시작일시</label></th>
      <td><dateTimePicker name="start_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="end_date">종료일시</label></th>
      <td><dateTimePicker name="end_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
    </tr>
    <tr>
      <th scope="row"><label for="start_date_test">테스트 시작일시</label></th>
      <td><dateTimePicker name="start_date_test" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
      <th scope="row"><label for="end_date_test">테스트 종료일시</label></th>
      <td><dateTimePicker name="end_date_test" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
    </tr>
    </tbody>
    </table>

  </form>

</div>

<div class="modal-footer">
  <div class="fl w50p">
    <button *ngIf="seq!=0" type="button" class="btn btn-danger btn-small" (click)="setSuperSaleDelete()">삭제하기</button>
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    <button type="button" class="btn btn-primary btn-small ml5" (click)="setSuperSaleSave()">저장하기</button>
  </div>
</div>
