import { Component, OnInit } from '@angular/core';
import { ApprovalService } from '@app/service/approval.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-approval-temp',
  templateUrl: './approval-temp.component.html',
  styleUrls: ['./approval-temp.component.scss']
})
export class ApprovalTempComponent implements OnInit {

  public approvalTmp: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private approvalService: ApprovalService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getApprovalTmpList();
  }

  /*******************************************************************************
    설  명 : 결재 임시저장 리스트 가져오기
    입력값 : code
    리턴값 : 없음
  *******************************************************************************/
  getApprovalTmpList() {
    this.approvalService.getApprovalTmpList().then(response => {
      if( response.ResultCode ) {
        this.approvalTmp = response.data;
      } else {
        this.approvalTmp = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 결재 임시저장 리스트 가져오기
    입력값 : code
    리턴값 : 없음
  *******************************************************************************/
  delApprovalTmp(seq) {
    this.approvalService.delApprovalTmp(seq).then(response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '삭제하였습니다.');
        this.getApprovalTmpList();

      } else {
        this.toastrService.success( response.ResultMessage, '삭제하였습니다.');

      }
    });
  }

}
