<div class="modal-header">
  <h4 *ngIf="seq!=0" class="modal-title fb mb0">담당자 상세정보</h4>
  <h4 *ngIf="seq==0" class="modal-title fb mb0">담당자 추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    <inputEx type="hidden" name="whSeq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">담당자 정보</caption>
    <colgroup>
      <col style="width:10%;" />
      <col style="width:23%;" />
      <col style="width:10%;" />
      <col style="width:23%;" />
      <col style="width:10%;" />
      <col style="width:23%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="location_name">사원검색</label></th>
      <td>
        <select formControlName="mem_no" [ngClass]="{formErrors:formErrors['mem_no']}" class="form-control form-control-small" (change)="searchMember($event)" >
          <option *ngFor="let item of employeeList" value="{{item.mem_no}}">{{item.name}}</option>
        </select>
      </td>
      <th scope="row"><label for="location_name">사원명</label></th>
      <td><inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="사원검색을 하세요."></inputEx></td>
      <th scope="row"><label for="location_name">부서/직급</label></th>
      <td><inputEx type="text" name="dept_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="사원검색을 하세요."></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="location1">구분</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.isDefault.value=='1'}" (click)="form.patchValue({isDefault: '1'})">주담당자</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.isDefault.value=='0'}" (click)="form.patchValue({isDefault: '0'})">부담당자</button>
        </div>
      </td>
      <th scope="row"><label for="sdate">담당시작일</label></th>
      <td>
        <input type="text" readonly="readonly" id="start_date" formControlName="start_date"
          [ngClass]="{formErrors:formErrors['start_date']}"
          class="form-control form-control-small calendar-input mr5" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
        <ng-template #footerTemplate1>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
        </ng-template>
      </td>
      <th scope="row"><label for="edate">담당종료일</label></th>
      <td>
        <input type="text" readonly="readonly" id="end_date" formControlName="end_date"
          [ngClass]="{formErrors:formErrors['end_date']}"
          class="form-control form-control-small calendar-input mr5" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
        <ng-template #footerTemplate2>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
        </ng-template>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="memo">메모</label></th>
      <td colspan="5"><inputEx type="textarea" name="memo" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    </tbody>
    </table>

  </form>

</div>

<div class="modal-footer">
  <div class="fl w50p">
    <button *ngIf="seq!=0" type="button" class="btn btn-danger btn-small" (click)="setWarehouseChargeDelete()">삭제하기</button>
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="setWarehouseChargeSave()">저장하기</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
  </div>
</div>
