import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApprovalService } from '@app/service/approval.service';
import { SystemGroupService } from '@app/service/system.group.service';
import { AuthService } from '@app/service/auth.service';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { UtilService } from '@app/service/util.service';
@Component({
  selector: 'app-approval-line-add',
  templateUrl: './approval-line-add.component.html',
  styleUrls: ['./approval-line-add.component.scss']
})
export class ApprovalLineAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
    public activeTab: any = 0;
    public num: number;
    public seq: any = 0;
    public subject: any = '';
    public document_type: any = '';
    public title: any = '추가';
  
    public type: any = false;
    public approvalMember: any;
    
    // 폼 변수 선언
    public form: FormGroup;
    public formErrors = {};
  
    public rowData: any = [];
    
    // 그리드 관련 선언
    gridApi: any;
    gridColumnApi: any;
  
    gridApi1: any;
    gridColumnApi1: any;
  
    gridApi2: any;
    gridColumnApi2: any;
  
    columnDefs: any;
    columnDefs1: any;
    columnDefs2: any;
  
    defaultColDef: any;
    domLayout: any;
    rowSelection: any;
  
    selectedRows = 0;
  
    noRowsTemplate: string;
  
    public member = {};
  
    /* 노드관련 변수 */
    public nodeData: any = [];
  
    public isExpand: boolean = true;
  
    public selectedNode: any = {};
  
    // 자동반영 결재 양식
    public documentList: any = [{title: '자동반영 안함', value: ''}];

  buildForm(): void {
    this.form = this.formBuilder.group({
      subject: ['', [Validators.required, Validators.maxLength(50)]],
      document_type: ['', []],
      approval: [[], []],
      agreement: [[], []],
      reference: [[], []],
      seq: ['', []],
      sort_order: ['', [] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  public mem_no: any = '';

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,  
    private approvalService: ApprovalService,
    private systemGroupService: SystemGroupService,
    public authService: AuthService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
  
  ) { 
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순서', field: '', width: 80, cellClass: 'cp center', rowDrag: true, filter: false },
      { headerName: '부서', field: 'dept_name', width: 110, cellClass: 'cp' },
      { headerName: '직급', field: 'position', width: 110, cellClass: 'cp' },
      { headerName: '이름', field: 'name', width: 110, cellClass: 'cp' },        
      { headerName: '삭제', field: 'delete', width: 80, cellClass: 'cp center',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'delete',  
          btnName: 'row 삭제', 
          listaction: this.deleteRow.bind(this)
        }
      },
    ];

    this.columnDefs1 = [
      { headerName: '순서', field: 'a', width: 80, cellClass: 'cp center', rowDrag: true },
      { headerName: '부서', field: 'dept_name', width: 110, cellClass: 'cp' },
      { headerName: '직급', field: 'position', width: 110, cellClass: 'cp' },
      { headerName: '이름', field: 'name', width: 110, cellClass: 'cp' },
      { headerName: '삭제', field: 'delete', width: 80, cellClass: 'cp center', 
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'delete',  
          btnName: 'row 삭제', 
          listaction: this.deleteRow.bind(this)
        }
      },
    ];

    this.columnDefs2 = [
      { headerName: '순서', field: 'a', width: 80, cellClass: 'cp center', rowDrag: true },
      { headerName: '부서', field: 'dept_name', width: 110, cellClass: 'cp' },
      { headerName: '직급', field: 'position', width: 110, cellClass: 'cp' },
      { headerName: '이름', field: 'name', width: 110, cellClass: 'cp' },
      { headerName: '삭제', field: 'delete', width: 80, cellClass: 'cp center',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'delete',  
          btnName: 'row 삭제', 
          listaction: this.deleteRow.bind(this)
        }
      },   
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';    
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    if( this.type && this.approvalMember.length != '0' ) {
      this.form.patchValue(this.approvalMember)
    } else {
      if( this.seq !== 0 ) {
        this.title = '수정';
        this.getApprovalStepDetail();
      } else {
        this.getMemberApprovalInfo();
      }
    }
    // 결재 문서 양식 리스트 가져오기
    this.getDocumentList();

    //사원 포함 조직도 리스트 가져오기 (TreeView)
    this.getGroupEmployeeList();
  }

  getMemberApprovalInfo() {
    let tmp = [];
    this.approvalService.getMemberApprovalInfo().then(response => {
      if( response.ResultCode ) {
        tmp.push({
          sort_order:response.data.sort_order,
          seq: response.data.seq,
          mem_no: response.data.mem_no,
          name: response.data.username,
          dept_name: response.data.dept_name,
          position: response.data.position,
          position_name: response.data.position,
          user_dept: response.data.name
        })

        this.form.patchValue({
          approval: tmp
        })
        // this.form.patchValue({
        //   approval: response.data
        // });
      }
    });

  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabSetValue( value: any ) {
    this.activeTab = value;
  }

  /*******************************************************************************
    설  명 : 결제단계 결재양식 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onValueSelectChange($event) {
      this.form.patchValue({
        document_type: $event.target.value
      });
    }
  
  /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeList() {
    this.systemGroupService.getGroupEmployeeList().then( response => {
      if ( response.ResultCode ) {
        this.nodeData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 결재 문서 양식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getDocumentList() {
    this.approvalService.getApprovalDocument().then( response => {
      if( response.ResultCode ) {
        for( let item of response.data ) {
          this.documentList.push({
            title: item.common_name,
            value: item.common_code,
          });
        }
      } else {
        this.documentList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {
    this.selectedNode = {
      sort_order: $event.sort_order,
      seq: $event.seq,
      mem_no: $event.mem_no,
      name: $event.username,
      dept_name: $event.dept_name,
      position: $event.position,
      position_name: $event.position,
      user_dept: $event.user_dept
    };


    // 사원을 클릭한 경우 결재단계에 추가한다.
    if ( this.selectedNode.mem_no !== null ) {
      let obj: any;
      let gridApi: any;

      switch( this.activeTab ) {
        case 0:
          obj = this.form.value.approval;
          gridApi = this.gridApi;
          break;
        case 1:
          obj = this.form.value.agreement;
          gridApi = this.gridApi1;
          break;
        default:
          obj = this.form.value.reference;
          gridApi = this.gridApi2;
          break;
      }

      let result = obj.find( item => item.mem_no === this.selectedNode.mem_no );

      if( result !== undefined ) return false;
      else {
        obj.push( this.selectedNode );
      }

      gridApi.setRowData(obj);
    } 
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }


  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged1($event) {
    let filteredRowCount = 0;
    this.gridApi1.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady2(params) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged2($event) {
    let filteredRowCount = 0;
    this.gridApi2.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : row 한줄 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteRow( params ) {
    let obj: any;

    if ( this.activeTab === 0 ) {
      if( this.form.value.approval[0].mem_no != params.data.mem_no ) {
        obj = this.form.value.approval;
  
        obj = obj.filter(row => row.mem_no !== params.data.mem_no);
  
        this.form.patchValue({ approval: obj });
      } else {
        this.toastrService.error('본인은 삭제하실 수 없습니다.', '결재단계');
      }
    } else if ( this.activeTab === 1 ) {
      obj = this.form.value.agreement;

      obj = obj.filter(row => row.mem_no !== params.data.mem_no);

      this.form.patchValue({ agreement: obj });
    } else if ( this.activeTab === 2 ) {
      obj = this.form.value.reference;

      obj = obj.filter(row => row.mem_no !== params.data.mem_no);

      this.form.patchValue({ reference: obj });
    }
    

  }

  /*******************************************************************************
    설  명 : 수정시 params 바인딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getApprovalStepDetail() {
    this.form.patchValue({
      subject: this.subject,
      document_type: this.document_type
    });

    this.approvalService.getApprovalStepDetail( this.seq ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          approval: response.data.approval,
          agreement: response.data.agreement,
          reference: response.data.reference
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : submit
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.controls.approval.value !== null ) {

      if (this.form.valid) {
        if ( this.seq === 0 ){
          this.approvalService.addApprovalStep( this.form ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '저장하였습니다.');
              this.activeModal.close(true);
            } else {
              this.toastrService.error( response.ResultMessage, '저장 오류');
            }
          });
        } else {
          this.form.patchValue({
            seq: this.seq 
          });
          this.approvalService.editApprovalStep( this.form ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '저장하였습니다.');
              this.activeModal.close(true);
            } else {
              this.toastrService.error( response.ResultMessage, '저장 오류');
            }
          });
        }
      } else {
        this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      }

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  addApproval() {
    this.activeModal.close(this.form.value)
  }

}