  <div class="modal-header">
    <h4 class="modal-title fb mb0">담당자 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <form [formGroup]="form">
      <table class="table table-bordered table-small1 mb0">
      <caption class="none">회원정보</caption>
      <colgroup>
        <col style="width:20%;" />
        <col style="width:80%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="hp">주담당자</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_manager.value=='1'}" (click)="form.patchValue({is_manager: '1'})">주담당자</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_manager.value=='0'}" (click)="form.patchValue({is_manager: '0'})">일반</button>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="searchName">회원검색</label></th>
        <td>
          <inputEx type="text" name="searchName" [formGroup]="form" [formErrors]="formErrors" placeholder="회원아이디 입력" [addOn]="searchMemberFunc" addOnText="회원 검색"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">담당자 이름</label></th>
        <td>
          <inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="hp">담당자 성별</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gender.value=='M'}" (click)="form.patchValue({gender: 'M'})">남자</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gender.value=='F'}" (click)="form.patchValue({gender: 'F'})">여자</button>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="email">담당자 이메일</label></th>
        <td>
          <inputEx type="text" name="email" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="tel">담당자 연락처</label></th>
        <td>
          <inputEx type="text" name="tel" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="hp">담당자 휴대폰</label></th>
        <td>
          <inputEx type="text" name="hp" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
    </form>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="seq != '0'" type="button" class="btn btn-danger btn-small" (click)="delMemberShopCharge()">담당자 삭제</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="submit()">담당자 저장</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
  </div>
