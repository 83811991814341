import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-margin-price',
  templateUrl: './product-margin-price.component.html',
  styleUrls: ['./product-margin-price.component.scss']
})
export class ProductMarginPriceComponent implements OnInit {

  public search = {}
  public productList: any = [];

  public form: FormGroup;
  public formErrors = {};


  buildForm(): void {
    this.form = this.formBuilder.group({
      date: [null, [Validators.required] ],
      file: ['', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService
  ) {
    this.buildForm();
   }

  ngOnInit(): void {
  }

  priceChangeSave() {
    if( this.form.value.price_kind == '' && this.form.value.price == '' ) {
      this.toastrService.error("금액을 입력해주세요.");

    } else {
      this.activeModal.close(this.form);
    }
  }
}
