import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import * as moment from 'moment';
import * as numeral from 'numeral';

import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';

@Component( {
  selector: 'app-member-sis-statistic-tab4',
  templateUrl: './member-sis-statistic-tab4.component.html',
  styleUrls: ['./member-sis-statistic-tab4.component.scss']
} )
export class MemberSisStatisticTab4Component implements OnInit {
  public retrieveOptions: any = {
    partnerName: null,
    startDate: null,
    endDate: null,
  };

  public gridOptions: any;

  public form: FormGroup = this.formBuilder.group({
    year:[moment().format('YYYY'), [] ],
  });
  public formErrors = {};

  public yearData = [
    {title: '2022', value: 2022},
    {title: '2021', value: 2021},
    {title: '2020', value: 2020}
  ];

  constructor(
    private restfulService: RestfulService,
    private formBuilder: FormBuilder,
    private utilService: UtilService
  ) {
    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });

    this.yearData = [];
    for(let i = parseInt(moment().format('YYYY')); i >= 2004; i-- ) {
      this.yearData.push({
        title: i.toString(),
        value: i
      });
    }
  }

  changeYear($event) {
    this.retrieveOptions.startDate = moment($event.target.value + '-01-01 00:00:00').toDate();
    this.retrieveOptions.endDate = moment($event.target.value + '-12-31 23:59:59').toDate();

    this.retrieve();
  }

  ngOnInit(): void {
    function currencyFormatter(parameters: any) {
      return parameters.value ? numeral(parameters.value).format('0,0') : null;
    }

    function percentFormatter(parameters: any) {
      return parameters.value ? numeral(parameters.value).format('0,0.00%') : null;
    }

    //this.retrieveOptions.startDate = moment().subtract(11, 'months').startOf('month').toDate();
    //this.retrieveOptions.endDate = moment().endOf('month').toDate();
    this.changeYear({target: {value: moment().format('YYYY')}});

    //// gridA
    this.gridOptions = {
      columnDefs: [
        {
          field: 'brand_name',
          headerName: '브랜드',
          width: 160
        },
        { field: 'type_description', headerName: '항목', width: 120 },
        ...( () => {
          const result = [];
          const date = moment(this.retrieveOptions.startDate);

          while (date.isBefore(this.retrieveOptions.endDate) ) {
            result.push( {
              field: 'total_' + moment(date).format('MM-DD'),
              headerName: moment(date).format('MM') + '월',
              type: 'numericColumn',
              valueFormatter: (parameters: any) => parameters.data.type === 'D' ? percentFormatter(parameters) : currencyFormatter(parameters),
              width: 96,
            } );

            date.add(1, 'months');
          }

          return result;
        } )(),
        {
          field: 'total',
          headerName: '합계',
          type: 'numericColumn',
          valueFormatter: (parameters: any) => parameters.data.type === 'D' ? percentFormatter(parameters) : currencyFormatter(parameters),
          width: 96
        },
      ],
      defaultColDef: { filter: false, resizable: true, sortable: false },
      overlayNoRowsTemplate: '데이터가 없습니다.',
      rowData: [],
      rowSelection: 'single',
      suppressCellSelection: true,
    };

    this.retrieve();
  }

  retrieve() {
    //// 브랜드별 협력사 매출
    this.restfulService.get( {
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getPartnerSalesTotalPerBrand'
    }, {
      shop: '0',
      grade: '0004',
      type: 'B',
      partner_name: this.retrieveOptions.partnerName,
      order_date_a: moment(this.retrieveOptions.startDate).format('YYYY-MM-DD'),
      order_date_b: moment(this.retrieveOptions.endDate).format('YYYY-MM-DD'),
    } ).then(response => {
      if (response.result) {
        let count: number;

        this.gridOptions.api.setRowData(
          response.result.reduce( (result: any, row: any) => {
            let newRow: any;

            if (result.length && (result[result.length - 1][0].brand_id === row.brand_id) ) {
              newRow = result[result.length - 1];
            }
            else {
              result.push(
                newRow = [
                  { brand_id: row.brand_id, brand_name: row.brand_name, type: 'A', type_description: '매출', total: 0 },
                  { type: 'B', type_description: '구입원가', total: 0 },
                  { type: 'C', type_description: '판매마진', total: 0 },
                  { type: 'D', type_description: '마진율', total: 0 },
                ]
              );

              count = 0;
            }

            if (row.sales_total) {
              const key = `total_${moment(row.order_date).format('MM-DD')}`;

              newRow[0][key] = Number(row.sales_total);
              newRow[0].total += Number(row.sales_total);
              newRow[1][key] = Number(row.cost_total);
              newRow[1].total += Number(row.cost_total);
              newRow[2][key] = Number(row.sales_total) - Number(row.cost_total);
              newRow[2].total += Number(row.sales_total) - Number(row.cost_total);

              if (Number(row.sales_total) !== 0) {
                const value: number = 1 - row.cost_total / row.sales_total;

                count++;

                newRow[3][key] = value;
                newRow[3].total = (newRow[3].total * (count - 1) + value) / count;
              }
              else {
                newRow[3][key] = 0;
              }
            }

            return result;
          }, [] ).flat()
        );
      }
    } );
  }
}
