<section>
  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">신고내역</h4>
    </div>
    <div class="fr w50p right">
    </div>
    <div class="cb"></div>
  </div>

  <div class="" style="height:calc(100vh - 180px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="reportList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"

      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</section>

