<form [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-group-add">POS {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="auth_group" class="control-label">권한그룹</label></th>
      <td>
        <select name="auth_group_id" formControlName="auth_group_id" class="form-control form-control-small" [ngClass]="{formErrors: formErrors['auth_group_id'] != ''}">
          <ng-container *ngFor="let item of posAuthGroupList">
            <option [value]="item.auth_group_id">{{item.name}}</option>
          </ng-container>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="dept" class="control-label">부서</label></th>
      <td>
        <select name="dept_seq" class="form-control form-control-small" (change)="onChangeDept($event.target.value)">
          <option value="">전체</option>
          <option *ngFor="let dept of departmentList" [value]="dept.seq">{{dept.dept_name}}</option>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="member" class="control-label">사용자</label></th>
      <td>
        <select name="mem_no" formControlName="mem_no" class="form-control form-control-small" [ngClass]="{formErrors: formErrors['mem_no'] != ''}">
          <ng-container *ngFor="let member of memberList">
            <option [value]="member.mem_no">{{member.mem_name}}</option>
          </ng-container>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="use_yn" class="control-label">사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.use_yn.value==true}" (click)="form.patchValue({use_yn:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.use_yn.value==false}" (click)="form.patchValue({use_yn:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>

    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="form.controls.pos_auth_id.value !== ''" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="button" class="btn btn-primary btn-small mr10 f15" (click)="submit()">저장하기</button>
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
