<div class="modal-header">
  <h4 class="modal-title bold" id="modal-common-code">발주서 검색</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
  <div>
    <div class="fl">
      <div class="form-inline">
        <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="reset()"><i class="material-icons-outlined">refresh</i></button>
        <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click) = "getPurchase()"><i class="material-icons-outlined">search</i></button> -->

        <div class="btn-group ml5">
          <button *ngFor = "let item of ['전체','입고완료','미입고']; index as idx" type="button" class="btn btn-default btn-small" [ngClass] = "{active: form.controls.in_warehouse.value == idx}" (click) = "btnClick('in_warehouse', idx)">{{item}}</button>
        </div>
        <div class="btn-group ml5">
          <button *ngFor = "let item of ['전체','매입','직발']; index as idx" type="button" class="btn btn-default btn-small" [ngClass] = "{active: form.controls.gbn.value == idx}" (click) = "btnClick('gbn', idx)">{{item}}</button>
        </div>

        <div class="input-group ml5">
          <input type="text" size="27" class="form-control form-control-small" placeholder="주문번호, 거래처, 상품명" [value]="form.value.search" (input)="searchInput($event)" (keypress)="keypress($event)" />
          <div class="input-group-append">
            <button type="button" class="btn btn-default btn-small f12" (click)="getStockOrder()">검색</button>
          </div>
        </div>        

        <input type="text" size="13" readonly="readonly" [value]="form.value.s_date" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate('s_date', $event)" />
        <span class="pl5 pr5">~</span>
        <input type="text" size="13" readonly="readonly" [value]="form.value.e_date" class="form-control form-control-small calendar-input " placeholder="검색 종료일" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect) = "selectDate('e_date', $event)" />
  
      </div>
    </div>
    <div class="fr right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="fc35898276e1409a94a8f98797803467" [btnGroup]="1"></btn-ag-grid-save>
    </div>
    <div class="cb"></div>
  </div>
  </form>

  <div class="mt5">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:300px"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="form.value.stockOrderList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowClassRules]="rowClassRules"

      (gridReady)="onGridReady($event)"
      (rowClicked)="onRowClicked_purchase($event)"
      >
    </ag-grid-angular>
  </div>

  <!-- <div class="mt10">
    <div class="fl w50p">
      <h4 class="f14 pt5">발주 제품정보</h4>
    </div>
    <div class="fr w50p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="a3de761b376442f98d0550281e1f7c8a" [btnGroup]="1"></btn-ag-grid-save>
    </div>
    <div class="cb"></div>
  </div> -->

  <div class="mt10">
    <ag-grid-angular
      #myGrid

      style="width:100%;height:300px;"
      class="ag-theme-balham"

      [columnDefs]="columnDefsDetail"
      [defaultColDef]="defaultColDef"
      [rowData]="form.value.stockOrderDetailList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReadyDetail($event)"
      (cellClicked)="onCellClicked_product($event)"
      >
    </ag-grid-angular>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-small f15 mr10" (click)="select()">선택하기</button>
  <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
</div>

