<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">금일 외상금액</p>
      <p class="subtxt"><span class="cnt">{{totalBalance == null? '0': totalBalance | number}}</span>원</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">프렌차이즈 외상금액</p>
      <p class="subtxt"><span class="cnt">{{franchise == null? '0': franchise | number}}</span>원</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">협력점 외상금액</p>
      <p class="subtxt"><span class="cnt">{{cooperation == null? '0': cooperation | number}}</span>원</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon5">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">기타 외상금액</p>
      <p class="subtxt"><span class="cnt">{{etc == null? '0': etc | number}}</span>원</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">총 외상잔액</p>
      <p class="subtxt"><span class="cnt">{{todayCredit == null? '0': todayCredit | number}}</span>원</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<div class="fl w80p form-inline">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
    <i class="material-icons-outlined">refresh</i>
  </button>
  <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getCreditMasterList()">
    <i class="material-icons-outlined">search</i>
  </button>

  <div class="btn-group ml5">
    <button *ngFor="let item of groupList" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.group==item.value}" (click)="search.group = item.value; getCreditMasterList();">{{item.title}}</button>
  </div>

  <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
  <ng-template #footerTemplate1>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 'sdate')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
  </ng-template>
  <span class="ml5 mr5">~</span>
  <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
  <ng-template #footerTemplate2>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 'edate')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
  </ng-template>

  <div class="btn-group ml5">
    <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value)">{{item.title}}</button>
  </div>

  <!-- <select id="searchField" [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
    <option value="r_name">수신자</option>
    <option value="o_name">주문자</option>
    <option value="seq">주문번호</option>
    <option value="o_hp">주문자폰</option>
    <option value="r_hp">수신자폰</option>
  </select>

  <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="검색어" /> -->

</div>

<div class="fr w20p right">
  <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="99ffa68b06c64020ae15406a7a7c6872" [btnGroup]="1"></btn-ag-grid-save>
</div>

<div class="cb"></div>

<as-split unit="percent" restrictMove="true" gutterSize="10" style="height: calc(100vh - 185px);background-color:#fff;">
  <as-split-area size="55" minSize="48" maxSize="60">
    <div class="mt5" style="height:calc(100% - 20px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [rowSelection]="'single'"
        [columnDefs]="masterColumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="creditMasterList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"

        (rowClicked)="onRowClicked($event)"
        (gridReady)="onGridMasterReady($event)"
      >
      </ag-grid-angular>
    </div>
  </as-split-area>
  <as-split-area size="45" minSize="40" maxSize="52">
    <div class="mt5" style="height:calc(100% - 20px)">
      <ag-grid-angular
        #myGrid
    
        style="width: 100%; height:100%;"
        class="ag-theme-balham"
    
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="creditList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
    
        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked($event)"
        >
      </ag-grid-angular>
    </div>
    
  </as-split-area>
</as-split>