<section id="member-refusal">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{statistic?.total_count | number}}</span>건</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic?.search_count | number}}</span>건</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">SMS<span class="cnt">{{statistic?.sms_count | number}}</span>건</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">EMAIL<span class="cnt">{{statistic?.email_count | number}}</span>건</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>


  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberRefusalList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="이름,휴대폰,이메일,메모" />

      <input type="text" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" size="10" class="form-control form-control-small calendar-input ml5"
        placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" readonly="readonly" id="edate" [(ngModel)]="search.edate" size="10" class="form-control form-control-small calendar-input m15"
        placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>

      <div class="btn-group ml5">
        <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value)">{{item.title}}</button>
      </div>
    </div>
    <div class="fr w30p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="2be1acdebc41467d9732479800873a27" [btnGroup]="1"></btn-ag-grid-save>

      <ag-grid-excel [gridApi]="gridApi" selected="true" filename="수신거부_리스트" title="수신거부 리스트"></ag-grid-excel>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="수신거부_리스트" title="수신거부 리스트"></ag-grid-excel>

      <button type="button" title="등록" class="btn btn-primary btn-small ml5" (click)="addRefusal(0)">수신거부 등록</button>
    </div>
    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 90px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="memberRefusalList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [components]="components"

      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
