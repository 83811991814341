<as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10">
  <as-split-area size="80" minSize="80" maxSize="80">

    <as-split unit="percent" restrictMove="true" gutterSize="10">
      <as-split-area size="50" minSize="50" maxSize="70" direction="horizontal">
        <section class="mt15">
          <div class="mb5">
            <div class="fl fb pt5">매물 이미지</div>
            <div class="fr">
              <!-- <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button> -->
            </div>
            <div class="cb"></div>
          </div>

          <table class="table table-bordered table-small">
          <caption class="hidden">매물 이미지정보</caption>

          <colgroup>
            <col style="width:100%" />
          </colgroup>

          <tbody>
          <tr class="center">
            <img class="main-img" [src]="uploadImageView.url" [alt]="uploadImageView.origin" />
          </tr>
          <tr>
            <td>
              <div *ngIf="sellImageList.length > 0" class="file-div">
                <ul class="file-ul" [ngClass]="getWidth( sellImageList )">
                  <li *ngFor="let file of sellImageList; let i = index" class="fl" (click)="setUploadImageView(file)">
                    <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.url == uploadImageView.url}">
                      <div *ngIf="checkImage(getExt(file.origin))" class="upload-image">
                        <img [src]="file.url" [alt]="file.origin" />
                      </div>
                      <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                      <div class="upload-filename">{{file.origin}}</div>
                      <div class="upload-size">
                        ({{getComma(file.size)}} KB)
                        <a class="cp ml5" (click)="setDeleteUploadFile( file )"><img src="/assets/images/del_ico.png" /></a>
                      </div>
                    </div>
                  </li>
                  <li class="cb"></li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
          </table>
        </section>
      </as-split-area>
      <as-split-area size="50" minSize="30" maxSize="50" direction="horizontal">
        <section class="mt15">
          <div class="mb5">
            <div class="fl fb pt5">
              매물 정보
            </div>
            <div class="fr">
              <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
              <button type="button" class="btn btn-default btn-small ml10" (click)="print()">거래내역서 출력</button>
            </div>
            <div class="cb"></div>
          </div>

          <table class="table table-bordered table-small1 mb0">
          <caption class="none">매물 정보</caption>
          <colgroup>
            <col style="width:16%;" />
            <col style="width:34%;" />
            <col style="width:16%;" />
            <col style="width:34%;" />
          </colgroup>
      
          <tbody>
          <tr>
            <th scope="row">거래구분</th>
            <td colspan="3">
              <span *ngIf="helperInfo.sell_gbn == '1000'" class="badge badge-success f12">{{helperInfo.sell_gbn_name}}</span>
              <span *ngIf="helperInfo.sell_gbn == '2000'" class="badge cborange f12">{{helperInfo.sell_gbn_name}}</span>
              <span *ngIf="helperInfo.sell_gbn == '3000'" class="badge cbpurple f12">{{helperInfo.sell_gbn_name}}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">구분</th>
            <td colspan="3">
              <span *ngIf="helperInfo.old_new_gbn == 'OLD'" class="f12 fb">중고차</span>
              <span *ngIf="helperInfo.old_new_gbn == 'NEW'" class="f12 fb">신차</span>
            </td>
          </tr>          
          <tr>
            <th scope="row">매물제목</th>
            <td colspan="3">{{helperInfo.title}}</td>
          </tr>
          <tr>
            <th scope="row">브랜드</th>
            <td>
              {{helperInfo.brand_name}}
            </td>
            <th scope="row">모델</th>
            <td>
              {{helperInfo.model}}
            </td>
          </tr>
          <tr>
            <th scope="row">종류</th>
            <td>
              {{helperInfo.bike_style_name}}
            </td>
            <th scope="row">연식</th>
            <td>
              {{helperInfo.manufacture_year}}
            </td>
          </tr>
          <tr>
            <th scope="row">주행거리</th>
            <td>
              {{helperInfo.mileage | number}}
            </td>
            <th scope="row">배기량</th>
            <td>
              {{helperInfo.piston | number}}
            </td>
          </tr>
          <tr>
            <th scope="row">판매지역</th>
            <td>
              {{helperInfo.region1}} {{helperInfo.region2}}
            </td>
            <th scope="row">튜닝여부</th>
            <td>
              {{ helperInfo.is_tuning == '1' ? '튜닝함' : '튜닝안함' }}
            </td>
          </tr>
          <tr>
            <th scope="row">판매자</th>
            <td>
              {{helperInfo.seller_name}}
            </td>
            <th scope="row">판매자 연락처</th>
            <td>
              {{setHyphen(helperInfo.hp)}}
            </td>
          </tr>
          <tr>
            <th scope="row">판매자 이메일</th>
            <td colspan="3">
              {{helperInfo.email}}
            </td>
          </tr>
          <tr>
            <th scope="row">구매자</th>
            <td>
              {{helperInfo.buyer_name}}
            </td>
            <th scope="row">구매자 연락처</th>
            <td>
              {{setHyphen(helperInfo.buyer_hp)}}
            </td>
          </tr>
          <tr>
            <th scope="row">구매자 이메일</th>
            <td colspan="3">
              {{helperInfo.buyer_email}}
            </td>
          </tr>
          <tr>
            <th scope="row">판매가격</th>
            <td colspan="3">
              <span class="f24 fb lh148">{{helperInfo.sale_price | number}} 원</span>
            </td>
          </tr>
          </tbody>
          </table>
        </section>
      </as-split-area>
    </as-split>

  </as-split-area>
  <as-split-area size="20" minSize="20" maxSize="20">
    <section class="mt15">
      <div class="mb5">
        <div class="fl fb pt5">
          진행 상태
        </div>
        <div class="fr">
          <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
          <button type="button" class="btn btn-danger btn-small ml10" (click)="setBikePayhelperCancle()">거래취소</button>
          <button type="button" class="btn btn-dark btn-small ml10" (click)="setBikePayhelperComplete()">거래완료</button>
          <button type="button" class="btn btn-primary btn-small ml10" (click)="setBikePayhelperConfirm()">결제확인</button>
        </div>
        <div class="cb"></div>
      </div>

      <table class="table table-bordered table-small1 mb0">
      <caption class="none">진행 상태</caption>
      <colgroup>
        <col />
        <col />
        <!-- <col /> -->
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
  
      <thead>
        <tr class="center">
          <ng-container *ngFor="let row of helperStatusDateList">
            <th scope="row" [ngClass]="{cbcyan: row.common_code == helperInfo.status}">{{row.common_name}}</th>
          </ng-container>
        </tr>
      </thead>

      <tbody>
        <tr class="center">
          <ng-container *ngFor="let row of helperStatusDateList">
            <td scope="row" [ngClass]="{cbcyan: row.common_code == helperInfo.status}">
              {{row.app_date}}
              <ng-container *ngIf="row.common_code > '4000' && row.common_code < '8000'">
                <button type="button" class="btn btn-default btn-icon ml5" (click)="openBikePayhelperStatusChange(row)">
                  <i class="material-icons menu-icon fl micon-create f18">&#xe150;</i>
                </button>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </tbody>
      </table>
    </section>
  </as-split-area>
</as-split>

<as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10">
  <as-split-area size="30" minSize="30" maxSize="70">

    <as-split unit="percent" restrictMove="true" gutterSize="10">
      <as-split-area size="70" minSize="60" maxSize="80" direction="horizontal">

        <section class="">
          <div class="">
            <div class="fl">
              <h4 class="fb mt5 mb6 mr10">결제 정보</h4>
            </div>
          </div>
          <div class="cb"></div>
        </section>
        
        <div style="height: 210px;">
          <ag-grid-angular
            #myGrid
      
            style="width: 100%;height:100%;"
            class="ag-theme-balham"
      
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="helperPayList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [stopEditingWhenGridLosesFocus]="true"
      
            (gridReady)="onGridReady($event)"
            (cellClicked)="onCellClicked($event)"
            >
          </ag-grid-angular>
        </div>
      </as-split-area>
      <as-split-area size="30" minSize="20" maxSize="40" direction="horizontal">
        <section class="">
          <div class="mb5">
            <div class="fl fb pt5">
              정산 정보
            </div>
            <div class="fr">
              <button type="button" class="btn cbpurple btn-small mr10" (click)="openPayHelperCalculateDate()">정산예정일 저장</button>
              <button type="button" class="btn cborange btn-small mr10" (click)="setBikePayHelperCalc()">수수료 재정산</button>
              <button type="button" class="btn btn-success btn-small" (click)="openBikePayHelperCalcEdit()">수수료 수정</button>
            </div>
            <div class="cb"></div>
          </div>

          <table class="table table-bordered table-small1 mb0">
          <caption class="none">정산 정보</caption>
          <colgroup>
            <col style="width:32%;" />
            <col style="width:68%;" />
          </colgroup>
      
          <tbody>
          <tr>
            <th scope="row">현금수수료</th>
            <td class="right">{{(helperInfo.cash_fee | number) || 0}} 원</td>
          </tr>
          <tr>            
            <th scope="row">카드수수료</th>
            <td class="right">{{(helperInfo.card_fee | number) || 0}} 원</td>
          </tr>
          <tr>
            <th scope="row">수수료합계</th>
            <td class="right">{{(helperInfo.total_fee | number) || 0}} 원</td>
          </tr>
          <tr>
            <th scope="row">정산금액</th>
            <td class="right">{{(helperInfo.calculate_price | number) || 0}} 원</td>
          </tr>
          <tr>
            <th scope="row">정산예정일</th>
            <td class="right">{{helperInfo.calculate_date || '-'}}</td>
          </tr>
          </tbody>
          </table>
        </section>
      </as-split-area>
    </as-split>

  </as-split-area>
  <as-split-area size="70" minSize="30" maxSize="70">

    <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
      <mat-tab label="코멘트">

        <section>
          <form [formGroup]="form">
            <div class="mt5">
              <div class="mb5 form-inline">
                <div class="btn-group mr5">
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.category.value=='A'}" (click)="form.patchValue({category: 'A'})">관리용</button>
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.category.value=='N'}" (click)="form.patchValue({category: 'N'})">공지용</button>
                </div>
              </div>
      
              <inputEx
                type="textarea"
                name="comment"
                [formGroup]="form"
                [formErrors]="formErrors"
                placeholder="코멘트내역 작성"
                rows="2"
                [addOn]="setBikeHelperCommentSaveFunc"
                addOnText="코멘트 등록"
              ></inputEx>
            </div>
          </form>

          <div class="mt5">
            <div class="fl w70p form-inline">
              <button type="button" class="btn btn-danger btn-small" (click)="setBikeHelperCommentDelete()">선택 코멘트 삭제</button>
            </div>
            <div class="fr w30p right">
            </div>
            <div class="cb"></div>
          </div>

          <div class="mt5" style="height: 400px;">
            <ag-grid-angular
              #myGrid
        
              style="width: 100%;height:100%;"
              class="ag-theme-balham"
        
              [columnDefs]="memoColumnDefs"
              [defaultColDef]="defaultColDef"
              [rowData]="helperCommentList"
              [pagination]="false"
              [domLayout]="domLayout"
              [overlayNoRowsTemplate]="noRowsTemplate"
              [frameworkComponents]="frameworkComponents"
              [rowSelection]="rowSelection"
        
              (gridReady)="onMemoGridReady($event)"
              >
            </ag-grid-angular>
          </div>
        </section>
        
      </mat-tab>
      <mat-tab label="매물상세정보">
        <div class="p20" [innerHTML]="helperInfo.comment | safe : 'html'"></div>
      </mat-tab>
      <mat-tab label="튜닝정보">
        <div class="p20" [innerHTML]="helperInfo.tuning_memo | safe : 'html'"></div>
      </mat-tab>
    </mat-tab-group>

  </as-split-area>
</as-split>