import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';

import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { config } from '@app/service/config.service';
import { ToastrService } from 'ngx-toastr';
import { RestfulService } from '@app/service/restful.service';
import { EmployeeService } from '@app/service/employee.service';
import { ApprovalService } from '@app/service/approval.service';

import * as $ from 'jquery';

const URL = config.apiFileUploadUrl;

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass:'modal-admin'
};

@Component({
  selector: 'app-approval-offline',
  templateUrl: './approval-offline.component.html',
  styleUrls: ['./approval-offline.component.scss']
})
export class ApprovalOfflineComponent implements OnInit {
  public seq ='';
  public form: FormGroup;
  public formErrors = {};
  public employeeChangedFunc: any;
  
  public employeeList: any = [];

  public approvalOff = [];

  public type = '1';

  public uploader: FileUploader;

  public baseURL = config.baseUrl;

  public upLoadData: any = [];

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      sign_date: ['', [] ],
      add_note: ['', [] ],
      file: ['', [] ],
      upload: [[], [] ],
      files: [[], [Validators.required] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private employeeService: EmployeeService,
    private approvalService: ApprovalService,
    private toastrService: ToastrService,
    private restful: RestfulService
  ) {
    this.buildForm();

    this.employeeChangedFunc = this.employeeChanged.bind(this);
  }

  ngOnInit(): void {
    this.getEmployee();
    this.getApprovalStepList();

    if( this.seq != '' ){
      this.form.patchValue({
        seq: this.seq
      })
    }

    var filetype: string[] = ['image', 'doc', 'pdf', 'xls', 'ppt'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김d
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        let files = this.form.controls.files.value;

        files.push({
          path: this.baseURL + response.url,
          upload_file: response.origin_filename,
          file_size: response.size,
          org_upload_file: response.filename
        });

        this.form.patchValue( {files: files});

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

   /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    handleUploadFileChanged(event) {
      this.uploader.clearQueue();
  
      // 파일 없을 경우 return false;
      if( event.target.files.length < 1 ) return false;
  
  
      // 전체 허용 용량 검증
      const files:File[] = event.target.files;
      const filteredFiles:File[] = [];
      for(const f of files) {
        filteredFiles.push(f);
      }
  
      const options = null;
      const filters = null;
  
      this.uploader.addToQueue(filteredFiles, options, filters);
  
      // indicator 표시
      this.restful.indicator.next(true);
  
      this.uploader.uploadAll();
    }
  
  
    /*******************************************************************************
      설  명 : 확장명 가져오기
      입력값 : filename
      리턴값 : ext
    *******************************************************************************/
    getExt( filename ) {
      if( typeof filename == 'undefined' ) return '';
      else return filename.substr(filename.lastIndexOf('.') + 1);
    }
  
  
    /*******************************************************************************
      설  명 : 파일 삭제
      입력값 : 파일
      리턴값 : 없음
    *******************************************************************************/
    deleteFile( file: any, index ) {
      if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
        let tmp = this.form.controls.files.value;
  
        tmp.splice( index, 1 );
  
        this.form.patchValue({files: tmp});
      }
    }
  
    /*******************************************************************************
      설  명 : 파일 삭제
      입력값 : 업로드 파일 정보
      리턴값 : 없음
    *******************************************************************************/
    setDeleteUploadFile( upload: any, index: any ) {
      if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
        
      }
    }
  
    /*******************************************************************************
      설  명 : 콤마 표시
      입력값 : 숫자
      리턴값 : 콤마 숫자
    *******************************************************************************/
    getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }

   /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployee() {
    this.employeeService.getGroupEmployeeListForSelect().then( response => {
      if( response.ResultCode ) {
        // this.employeeList = response.data;

        this.employeeList.push({
          id: '',
          text: '담당자'
        });

        response.data.forEach( row => {
          this.employeeList.push({
            id: row.mem_no,
            text: row.name,
          });
        });

      } else {
        this.employeeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  employeeChanged( e: any ): void {
    this.form.patchValue({
      mem_no: e
    });
  }

  getApprovalStepList() {
    this.approvalService.getApprovalStepList(this.seq).then(response => {
      if( response.ResultCode ) {
        this.approvalOff = response.data;
      }
    })
  }

  onChange(e) {
    for( let offLine of this.approvalOff ) {
      if( offLine.mem_no == e.target.name ) {
        offLine.add_note = e.target.value
      }
    }
  }


  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    let params = [];
    for(let i of this.approvalOff ) {
      params.push({
        add_note: i.add_note,
        approval_seq:  i.approval_seq,
        seq: i.seq,
      })
    }
    if( this.form.valid ) {
      this.approvalService.saveOfflineComplete( params, this.form.value.files ).then(response => {
        if( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage, '수기결재');
          this.activeModal.close(true);

        } else {
          this.toastrService.error(response.ResultMessage, '수기결재');
          
        }
      })
    } else {
      this.toastrService.error('스캔파일 첨부파일은 필수항목입니다.', '결재문서작성');
    }
  
  }

}
