/*******************************************************************************
  설  명 : 상품관리 - 상품사이즈 관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { ProductService } from '@app/service/product.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { BarcodeInputComponent } from '@components/barcode-input/barcode-input.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-product-barcode',
  templateUrl: './product-barcode.component.html',
  styleUrls: ['./product-barcode.component.scss']
})
export class ProductBarcodeComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 100,
    searchText: '',
    fid: '',
    category_code: '',
    brand: '',
    property_count_view: false,
    barcode_count_view: true
  };

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    completeCount: 0,
    uncompleteCount: 0
  }

  public categoryList: any = [];
  public brandList: any = [];
  public productList: any = [];
  public productPropertyList: any = [];
  public barcodeList: any = [];  

  public productSeqSelected: any = '';
  public propertySeqSelected: any = '';

  public totalCount: number = 0;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  propertyGridApi: any;
  propertyGridColumnApi: any;
  propertyColumnDefs: any;

  gridApiBarcode: any;
  gridColumnApiBarcode: any;
  columnDefsBarcode: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private productService: ProductService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '상품번호', field: 'product_seq', width: 120, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '카테고리', field: 'category_name', width: 270, cellClass: 'cp' },
      { headerName: '브랜드', field: 'brand_name', width: 120, cellClass: 'cp center' },
      { headerName: '상품명', field: 'product_name', width: 570, cellClass: 'cp cell-wrap-text', cellRenderer: 'agGridHtmlComponent', autoHeight: true },
    ];

    // ag grid 컬럼 선언
    this.propertyColumnDefs = [
      { headerName: '순번', field: 'rowIndex', width: 90, cellClass: 'cp center', valueGetter: 'node.rowIndex + 1' },
      { headerName: '색상', field: 'display_color_name', width: 150, cellClass: 'cp center' },
      { headerName: '사이즈', field: 'display_size_name', width: 100, cellClass: 'cp center' },
      { headerName: '정렬순서', field: 'sort_order', width: 90, cellClass: 'cp right' },
      { headerName: '바코드 개수', field: 'barcode_cnt', width: 90, cellClass: 'cp right' },
    ];

    this.columnDefsBarcode = [
      { headerName: '순번', field: 'seq', width: 120, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '바코드', field: 'barcode', width: 200, cellClass: 'cp center' },
      { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCategoryList();
    this.getBrandList();

    // 상품 데이터 불러오기
    // this.getProductList();
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      this.brandList = [];
      if( response.ResultCode ) {
        // this.brandList = response.data;

        this.brandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.brandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.brandList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductList() {
    this.productService.getProductListForSort( this.search ).then( response => {
      if( response.ResultCode ) {
        this.productList = response.data;
        this.totalCount = response.total;
        this.statistic.searchCount = this.totalCount;

        if( this.productList.length > 0 ) {
          this.productSeqSelected = this.productList[0].seq;

          let index = 0;
          setTimeout(() => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }, 0);

          // 선택 상품의 색상 사이즈 가져오기
          this.getProductPropertyList();

          // 위젯 통계 가져오기
          this.getProductPropertyStatistic();
        }
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 속성 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductPropertyList() {
    this.productService.getProductPropertyList( this.productSeqSelected ).then( response => {
      if( response.ResultCode ) {
        this.productPropertyList = response.data;

        // 바코드 리스트 가져오기
        if( this.productPropertyList.length > 0 ) {
          this.propertySeqSelected = this.productPropertyList[0].seq;

          let index = 0;
          setTimeout(() => {
            this.propertyGridApi.forEachNodeAfterFilter(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }, 0);

          this.getProductBarcodeList();
        }

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 바코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductBarcodeList() {
    this.productService.getProductBarcodeList( this.productSeqSelected, this.propertySeqSelected ).then( response => {
      if( response.ResultCode ) {
        this.barcodeList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 속성 통계 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductPropertyStatistic() {
    this.productService.getProductPropertyStatistic().then( response => {
      if( response.ResultCode ) {
        this.statistic.totalCount = response.statistic.totalCount;
        this.statistic.completeCount = response.statistic.completeCount;
        this.statistic.uncompleteCount = response.statistic.uncompleteCount;
      } else {
        this.toastrService.error(response.ResultMessage);

        this.statistic.totalCount = 0;
        this.statistic.completeCount = 0;
        this.statistic.uncompleteCount = 0;
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPropertyGridReady(params) {
    this.propertyGridApi = params.api;
    this.propertyGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyBarcode(params) {
    this.gridApiBarcode = params.api;
    this.gridColumnApiBarcode = params.columnApi;
  }

  onFirstDataRenderedBarcode(params) {
    params.api.sizeColumnsToFit();
  }
  
  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked( event: any ) {
    this.productSeqSelected = event.data.seq;
    this.propertySeqSelected = '';

    this.getProductPropertyList();
    // this.getProductBarcodeList();
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPropertyRowClicked( event: any ) {
    this.propertySeqSelected = event.data.seq;

    this.getProductBarcodeList();
  }

  /*******************************************************************************
    설  명 : ag grid 페이징 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPaginationChanged( event: any ) {
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onBrandChanged( event: any ) {
    this.search.brand = event;
    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 바코드 입력 모달창
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addBarcode() {
    const nodes = this.gridApi.getSelectedNodes();
    const propertyNodes = this.propertyGridApi.getSelectedNodes();
    
    if( nodes.length < 1 ) {
      this.toastrService.error( '상품을 선택하시기 바랍니다.' );
      return false;
    } else if ( nodes.length > 0 && propertyNodes.length < 1 ) {
      this.toastrService.error( '상품 속성을 선택하시기 바랍니다.' );
      return false;
    }

    const modalRef = this.modalService.open(BarcodeInputComponent, optionsLG);
    
    modalRef.componentInstance.product_seq = this.productSeqSelected;
    modalRef.componentInstance.property_seq = this.propertySeqSelected;
    modalRef.componentInstance.product_code = propertyNodes[0].data.product_code;
    modalRef.componentInstance.product_name = nodes[0]?.data.product_name;
    modalRef.componentInstance.color_seq = propertyNodes[0]?.data.color_seq;
    modalRef.componentInstance.color_name = propertyNodes[0]?.data.color_name;
    modalRef.componentInstance.size_seq = propertyNodes[0]?.data.size_seq;
    modalRef.componentInstance.size_name = propertyNodes[0]?.data.size_name;
    modalRef.componentInstance.barcode = '';

    modalRef.result.then((result) => {
      if( typeof result !== 'undefined' ) {

        const barcode: any = result.barcode.split('\n');

        const newSet = new Set(barcode);
        
        const newBarcode = [...newSet];

        let params = {
          product_seq: this.productSeqSelected,
          property_seq: this.propertySeqSelected,
          barcodes: newBarcode
        };
        
        // 바코드 저장
        this.productService.setBarcodeSave( params ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
  
            this.getProductBarcodeList();

            if( this.propertySeqSelected ) {
              const new_barcode_cnt = parseInt(propertyNodes[0].data.barcode_cnt) + newBarcode.length;
              propertyNodes[0].setDataValue('barcode_cnt', new_barcode_cnt);
            } else {
              const new_barcode_cnt = parseInt(nodes[0].data.barcode_cnt) + newBarcode.length;
              nodes[0].setDataValue('barcode_cnt', new_barcode_cnt);
            }

          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });        
      }
    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 바코드 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBarcodeDelete() {
    const nodes = this.gridApiBarcode.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 바코드를 선택하세요', '' );
      return false;
    }

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data );
    }

    if ( confirm('선택한 바코드를 삭제하시겠습니까?') ) {
      this.productService.setBarcodeDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductPropertyList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 100,
      searchText: '',
      fid: '',
      category_code: '',
      brand: {id: '0'},
      property_count_view: false,
      barcode_count_view: true
    };

    this.productList = [];
    this.productPropertyList = [];
    this.barcodeList = [];  

    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getProductList();
  }

}
