<section class="h100 mt10">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;height: 100%;">
    <as-split-area size="17" minSize="10" maxSize="30">
      <div class="right mt5">
        <!-- <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="9873ec9caf9041e1bba20d0dd4653d9a" [btnGroup]="1"></btn-ag-grid-save> -->
      </div>

      <div class="mt5" style="height:calc(100% - 50px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          rowSelection="single"
          [columnDefs]="columnDefsCategory"
          [defaultColDef]="defaultColDef"
          [rowData]="categoryList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (gridReady)="onGridReadyCategory($event)"
          (rowClicked)="onCategoryGridRowClicked($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="83" minSize="70" maxSize="90">
      <div class="right mt5">
        <div class="fl w80p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductRelationList()">
            <i class="material-icons-outlined">search</i>
          </button>

          <select id="searchField" [(ngModel)]="params.searchField" class="form-control form-control-small ml5">
            <option value="category_name">카테고리</option>
            <option value="brand_name">메이커(브랜드)</option>
            <option value="product_name">모델명</option>
          </select>

          <input type="text" id="searchText" [(ngModel)]="params.searchText" size="30" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="검색어" />
        </div>

        <div class="fr w20p right">
          <!-- <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="a762c241da88476582b78c3662edb51e" [btnGroup]="1"></btn-ag-grid-save> -->
          <button type="button" class="btn btn-success btn-small" (click)="setRelationSortOrderSaveAll()">순서저장</button>
          <button type="button" class="btn btn-danger btn-small ml10" (click)="setProductRelationDelete()">상품삭제</button>
          <button type="button" class="btn btn-primary btn-small ml10" (click)="productRelationAdd()">상품추가</button>
        </div>
        <div class="cb"></div>
      </div>

      <div class="mt5" style="height:calc(100% - 50px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          rowSelection="multiple"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="relationProductList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowDragManaged]="true"
          [enableMultiRowDragging]="true"
          [animateRows]="true"
          [components]="components"
          [stopEditingWhenGridLosesFocus]="true"

          (gridReady)="onGridReady($event)"
          (cellEditingStarted)="onCellEditingStarted($event)"
          (cellEditingStopped)="onCellEditingStopped($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
