<form [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-group-add">POS권한명 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="name" class="control-label">권한명</label></th>
      <td>
        <inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors" autofocus="true"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="form.controls.auth_group_id.value !== ''" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="button" class="btn btn-primary btn-small mr10 f15" (click)="submit()">저장하기</button>
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
