<table class="list-table">
<colgroup>
  <col style="width:300px" />
  <col />
 </colgroup>

<thead>
<tr>
   <th scope="col">이미지</th>
   <th scope="col">제목/내용</th>
</tr>
</thead>

<tbody>
<tr *ngFor="let row of data" class="cp" (click)="view(row)">
  <td class="board-img">
    <img *ngIf="!row.upload_file" src="/assets/admin/images/board_img.png">
    <img *ngIf="row.upload_file" [src]="baseURL + row.thumbnail">
  </td>
  <td class="tl">
    <div class="title">
      <p class="fl form-inline">
        <span [innerHTML]="row.subject"></span>
        <i *ngIf="row.uploadCount > 0" class="material-icons file-ico fl">&#xe226;</i>
        <span class="ico-firstfiber_new new-ico"></span>
      </p>
      <p class="fr mt5">{{row.writer_name}} {{row.write_date}} 조회수 : {{row.hit}}</p>
      <div class="cb"></div>
    </div>
    <div class="board-text">
      {{row.contents}}
    </div>
  </td>
</tr>
</tbody>
</table>
