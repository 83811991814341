/*******************************************************************************
  설  명 : 이벤트관리 - 탭 이벤트 결과분석
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '@app/service/event.service';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-event-day-statistic',
  templateUrl: './event-day-statistic.component.html',
  styleUrls: ['./event-day-statistic.component.scss']
})
export class EventDayStatisticComponent implements OnInit {

  @Input() eventSeq: any;

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    sdate: '',
    edate: ''
  }

  // 그리드 관련 선언
  eventStatisticList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  selectedRows: number = 0;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private eventService: EventService,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'seq', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '일자', field: 'date', width: 120, cellClass: 'cp right' },
      {headerName: '판매금액', field: 'sale', width: 140, cellClass: 'cp right', valueFormatter: currencyFormatter },
      {headerName: '구매금액', field: 'buy', width: 140, cellClass: 'cp right', valueFormatter: currencyFormatter },
      {headerName: '수량', field: 'qty', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      {headerName: '마진', field: 'margin', width: 140, cellClass: 'cp right', valueFormatter: currencyFormatter  },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
   *
   *function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.eventService.getEventOrderDayList(this.eventSeq).then( response => {
      if (response.ResultCode) {
        this.eventStatisticList = response.data;
      }else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {

  }

}
