<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-header">
    <h4 class="modal-title bold">{{title}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="list-table">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="code">결재 제목</label></th>
      <td>
        <inputEx type="text" readonly="true" name="subject" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <inputEx type="textarea" name="add_note" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fr w50p right ml0 mr0">
      <button type="submit" class="btn btn-primary btn-small mr10 f15">{{title}}</button>
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>