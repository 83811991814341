import { Component, Injectable, OnInit } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import * as numeral from 'numeral';

import { RestfulService } from '@app/service/restful.service';

@Injectable()
export class NgbDateAdapterDate extends NgbDateAdapter<Date> {
  fromModel(value: Date | null): NgbDateStruct | null {
    return value ? {year: value.getFullYear(), month: value.getMonth() + 1, day: value.getDate() } : null;
  }

  toModel(value: NgbDateStruct | null): Date | null {
    return value ? new Date(value.year, value.month - 1, value.day) : null;
  }
}

@Component( {
  selector: 'app-hermes-sales',
  templateUrl: './hermes-sales.component.html',
  styleUrls: ['./hermes-sales.component.scss'],
  providers: [ {provide: NgbDateAdapter, useClass: NgbDateAdapterDate} ],
} )
export class HermesSalesComponent implements OnInit {
  public stores: any = [
    { store_name: '(전체)' },
    { store_id: 6, store_name: '대구직영점' },
    { store_id: 7, store_name: '쇼핑몰매출' },
    { store_id: 22, store_name: '서울직영점' },
  ];

  public retrieveOptions: any = {
    store_id: null,
    sale_date_a: null,
    sale_date_b: null,
  };

  public summary: any = {
  };

  public gridAOptions: any;
  public gridBOptions: any;

  constructor(
    private restfulService: RestfulService,
  ) {
  }

  ngOnInit(): void {
    function currencyFormatter(parameters: any) {
      return parameters.value ? numeral(parameters.value).format('0,0') : null;
    };

    this.gridAOptions = {
      columnDefs: [
        { field: 'store_name', headerName: '매장명', width: 120 },
        { field: 'tddate', headerName: '영업일자', width: 104 },
        { field: 'tdtime', headerName: '판매시간', width: 160 },
        { field: 'salemny', headerName: '판매합계', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'dcmny', headerName: '할인금액', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'payment_amount', headerName: '실매출액', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'cardmny', headerName: '카드매출', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'cashmny', headerName: '현금매출', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'getmny', headerName: '현금영수증', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
      ],
      defaultColDef: { filter: false, resizable: true, sortable: true },
      overlayNoRowsTemplate: '데이터가 없습니다.',
      rowData: [],
      rowSelection: 'single',
      suppressCellSelection: true,
      onSelectionChanged: (event: any) => {
        this.retrieve_detail();
      },
    };

    this.gridBOptions = {
      columnDefs: [
        { field: 'itemcode', headerName: '품번', width: 120 },
        { field: 'itemname', headerName: '품명', width: 200 },
        { field: 'colorname', headerName: '색상', width: 160 },
        { field: 'sizename', headerName: '사이즈', width: 160 },
        { field: 'brand_name', headerName: '브랜드', width: 160 },
        { field: 'baseprice', headerName: '출하가', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: '', headerName: '소비자가', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'tdprice', headerName: '실판매가', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'qty', headerName: '수량', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'total_amount', headerName: '금액', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
      ],
      defaultColDef: { filter: false, resizable: true, sortable: true },
      overlayNoRowsTemplate: '데이터가 없습니다.',
      rowData: [],
      rowSelection: 'single',
      suppressCellSelection: true,
    };

    this.restfulService.get( {
      program: 'admin',
      service: 'statistic',
      version: '1.0',
      action: 'getHermesSalesSummary'
    }, {
    } ).then(response => {
      if (response.result) {
        this.summary = response.result;

        this.retrieveOptions = {
          ...this.retrieveOptions,
          sale_date_a: moment(response.result.sale_date_b).startOf('month').toDate(),
          sale_date_b: moment(response.result.sale_date_b).endOf('month').toDate(),
          sale_date_a_old: moment(response.result.sale_date_b).startOf('month').toDate(),
          sale_date_b_old: moment(response.result.sale_date_b).endOf('month').toDate(),
        }

        this.retrieve();
      }
    } );
  }

  clear() {
    this.retrieveOptions = {
      ...this.retrieveOptions,
      store_id: null,
      sale_date_a: this.retrieveOptions.sale_date_a_old,
      sale_date_b: this.retrieveOptions.sale_date_b_old,
    };

    this.retrieve();
  }

  retrieve() {
    this.gridAOptions.api.setRowData( [] );
    this.gridBOptions.api.setRowData( [] );

    this.restfulService.get( {
      program: 'admin',
      service: 'statistic',
      version: '1.0',
      action: 'getHermesMaster'
    }, {
      store_id: this.retrieveOptions.store_id,
      sale_date_a: moment(this.retrieveOptions.sale_date_a).format('YYYY-MM-DD'),
      sale_date_b: moment(this.retrieveOptions.sale_date_b).format('YYYY-MM-DD'),
    } ).then(response => {
      if (response.result) {
        this.gridAOptions.api.setRowData(response.result);

        if (response.result.length > 0) {
          this.gridAOptions.api.getRowNode(0).setSelected(true);
        }

        this.summary = {
          ...this.summary,
          found: response.result.length,
        }
      }
    } );
  }

  retrieve_detail() {
    this.gridBOptions.api.setRowData( [] );

    this.restfulService.get( {
      program: 'admin',
      service: 'statistic',
      version: '1.0',
      action: 'getHermesDetail'
    }, {
      sale_id: this.gridAOptions.api.getSelectedRows()[0].tdid,
    } ).then(response => {
      if (response.result) {
        this.gridBOptions.api.setRowData(response.result);
      }
    } );
  }
}
