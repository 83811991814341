/*******************************************************************************
  설  명 : 현황관리 - 일자별매출실적
  작성일 : 2021.07.21
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import 'moment/locale/ko';

import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { StatisticService } from '@app/service/statistic.service';
import { StockOrderComponent } from '@app/page/stock/stock-order/stock-order.component';

@Component({
  selector: 'app-statistic-month',
  templateUrl: './statistic-month.component.html',
  styleUrls: ['./statistic-month.component.scss']
})
export class StatisticMonthComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    date: moment().format('YYYY-MM-DD'),
    year: moment().format('YYYY'),
    month: moment().format('MM'),
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  channelGridApi: any;
  channelGridColumnApi: any;
  channelColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  monthList: any = [];
  channelMonthList: any = [];
  rowClassRules: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private statisticService: StatisticService,
    private stockOrderComponent: StockOrderComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '구분', field: 'pay_date', width: 115, cellClass: 'cp center', sortable: true },
      { headerName: '쇼핑몰', field: '', cellClass: 'cp center',
       children:[
          {headerName: '매출', field: 'mall_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
          {headerName: '환불', field: 'mall_return', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
        ]
      },
      { headerName: 'NPAY', field: 'NAVER_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '대구매장', field: '', cellClass: 'cp center',
       children:[
          {headerName: '매출', field: 'daegu_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
          {headerName: '환불', field: 'daegu_return', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
        ]
      },
      { headerName: '서울매장', field: '', cellClass: 'cp center',
       children:[
          {headerName: '매출', field: 'seoul_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
          {headerName: '환불', field: 'seoul_return', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
        ]
      },
      { headerName: '협력점', field: 'ali_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '프랜차이즈', field: 'fra_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '스토어팜', field: 'FARM_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '쿠팡', field: 'COUPON_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '11번가', field: '11st_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '지마켓', field: 'GMARKET_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '옥션', field: 'AUCTION_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '톡스토어', field: 'TALK_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '로켓배송', field: 'ROCKET_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '현대,롯데,배민', field: 'HDGF_sale', width: 102, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '해외', field: 'IMPORT_sale', width: 86, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '합계', field: 'TOTAL_sale', width: 96, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '누계', field: 'cumulative', width: 96, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      // { headerNameng serve: '전년합계', field: '전년합계', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
    ];

    this.channelColumnDefs = [
      { headerName: '구분', field: '구분', width: 180, cellClass: 'cp center' },
      { headerName: '쇼핑몰', field: '쇼핑몰', width: 180, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      valueGetter: (params: any) => params.data['구분'].replace(/(달성률).{0,}/, '') ? 
      this.utilService.getComma(params.data['쇼핑몰']) : params.data['쇼핑몰'] },
      { headerName: '대구매장', field: '대구점', width: 180, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      valueGetter: (params: any) => params.data['구분'].replace(/(달성률).{0,}/, '') ? 
      this.utilService.getComma(params.data['대구점']) : params.data['대구점'] },
      { headerName: '서울매장', field: '서울점', width: 180, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      valueGetter: (params: any) => params.data['구분'].replace(/(달성률).{0,}/, '') ? 
      this.utilService.getComma(params.data['서울점']) : params.data['서울점'] },
      { headerName: '협력점', field: '협력점', width: 180, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      valueGetter: (params: any) => params.data['구분'].replace(/(달성률).{0,}/, '') ? 
      this.utilService.getComma(params.data['협력점']) : params.data['협력점'] },
      { headerName: '프랜차이즈', field: '프랜차이즈', width: 180, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      valueGetter: (params: any) => params.data['구분'].replace(/(달성률).{0,}/, '') ? 
      this.utilService.getComma(params.data['프랜차이즈']) : params.data['프랜차이즈'] },
      { headerName: '국내오픈마켓', field: '국내오픈마켓', width: 180, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      valueGetter: (params: any) => params.data['구분'].replace(/(달성률).{0,}/, '') ? 
      this.utilService.getComma(params.data['국내오픈마켓']) : params.data['국내오픈마켓'] },
      { headerName: '해외', field: '해외', width: 180, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      valueGetter: (params: any) => params.data['구분'].replace(/(달성률).{0,}/, '') ? 
      this.utilService.getComma(params.data['해외']) : params.data['해외'] },
      { headerName: '합계', field: '합계', width: 180, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      valueGetter: (params: any) => params.data['구분'].replace(/(달성률).{0,}/, '') ? 
      this.utilService.getComma(params.data['합계']) : params.data['합계'] },
    ];

    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true,
    };

    this.rowSelection = this.stockOrderComponent.rowSelection
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate
    this.rowClassRules = {
      'status8': params => params.data.pay_date.indexOf('토') > -1,
      'status1': params => params.data.pay_date.indexOf('일') > -1
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getStatisticMonthSales();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onChannelGridReady(params) {
    this.channelGridApi = params.api;
    this.channelGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 검색 초기화
  *******************************************************************************/
  searchInit() {
    this.search = {
      date: moment().format('YYYY-MM-DD'),
      year: moment().format('YYYY'),
      month: moment().format('MM'),
    };

    this.getStatisticMonthSales();
  }

  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate( value: any ) {
    this.search.date = this.utilService.getDateStr(value);
    this.search.year = moment(this.search.date).format('YYYY');
    this.search.month = moment(this.search.date).format('MM');
    this.getStatisticMonthSales();
  }

  /*******************************************************************************
    설  명 : 일자별매출실적 가져오기
  *******************************************************************************/
  getStatisticMonthSales() {
    this.statisticService.getStatisticMonthSales({
      year : this.search.year,
      month : this.search.month
    }).then(response => {
      if(response.ResultCode) {
        if( response.data.length > 0 ) {
          let cumulative: any = 0;
          response.data.forEach(item => {
            item.pay_date = moment(item.pay_date).format('YYYY-MM-DD(ddd)');
            
            cumulative += parseInt(item.TOTAL_sale);
            item.cumulative = cumulative;
          });
        }

        if( response.channel_data.length > 0 ) {
          let row1: any = {};
          let row2: any = {};
          let row3: any = {};

          row1 = response.channel_data.filter(item => item['구분'] == '매출실적')[0];
          row2 = response.channel_data.filter(item => item['구분'] == '매출목표')[0];
          row3 = response.channel_data.filter((item: any) => item['구분'] == '전년실적')[0];

          function setData(type: string) {
            const data = Math.round((row1[type] - row3[type]) / row3[type] * 100)
            return `${
              row2[type] > 0 ? Math.round(row1[type] / row2[type] * 100) : 0
            }<span style="color: ${0 > data ? 'red' : 'blue'}">(${row3[type] > 0 ? data : 0})</span>`
          }

          response.channel_data.push({
            '구분': '달성률(전년대비)',
            '국내오픈마켓': setData('국내오픈마켓'),
            '대구점': setData('대구점'),
            '서울점': setData('서울점'),
            '쇼핑몰': setData('쇼핑몰'),
            '프랜차이즈': setData('프랜차이즈'),
            '합계': setData('합계'),
            '해외': setData('해외'),
            '해외오픈마켓': setData('해외오픈마켓'),
            '협력점': setData('협력점'),
          });

          let tmp = response.channel_data[2];
          response.channel_data[2] = response.channel_data[3];
          response.channel_data[3] = tmp;
        }

        this.monthList = response.data;
        this.channelMonthList = response.channel_data;
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  printExcel() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <tr align="center"> 
          <td height="40" colspan="18" style="font-size:14pt;"><strong>${this.search.year}년 ${this.search.month}월 일자별 매출실적</strong></td>
        </tr>
        <tr align="center" bgcolor="#CCCCCC">
          <td width="200" height="30"></td>
          <td width="160" height="30" colspan="2">쇼핑몰</td>
          <td width="160" height="30" colspan="2">대구매장</td>
          <td width="160" height="30" colspan="2">서울매장</td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
          <td width="80" height="30"></td>
        </tr>
        <tr align="center" bgcolor="#CCCCCC">
          <td height="30">구분</td>
          <td height="30">매출</td>
          <td height="30">환불</td>
          <td height="30">매출</td>
          <td height="30">환불</td>
          <td height="30">매출</td>
          <td height="30">환불</td>
          <td height="30">협력점</td>
          <td height="30">프랜차이즈</td>
          <td height="30">11번가</td>
          <td height="30">지마켓</td>
          <td height="30">옥션</td>
          <td height="30">스토어팜</td>
          <td height="30">NPAY</td>
          <td height="30">쿠팡</td>
          <td height="30">톡스토어</td>
          <td height="30">로켓배송</td>
          <td height="30">현대,롯데,배민</td>
          <td height="30">해외</td>
          <td height="30">합계</td>
        </tr>
    `]

    this.monthList.forEach(element => {
      contents.push(`
        <tr bgcolor="${element.pay_date.indexOf('토') > -1 ? '#f0f0ff' : element.pay_date.indexOf('일') > -1 ? '#ffecfc' : 'inherit'}">
          <td align="center" height="22"><font size="2">${element.pay_date}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.mall_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.mall_return}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.daegu_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.daegu_return}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.seoul_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.seoul_return}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.ali_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.fra_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element['11st_sale']}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.GMARKET_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.AUCTION_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.FARM_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.NAVER_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.COUPON_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.TALK_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.ROCKET_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.HDGF_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.IMPORT_sale}</font></td>
          <td align="right" style="mso-number-format:'\#\,\#\#0';"><font size="2">${element.TOTAL_sale}</font></td>
        </tr>
      `)
    })
    contents.push(`</table>`)

    const fileName = `${this.search.year}년 ${this.search.month}월 일자별 매출실적`
    this.stockOrderComponent.printExcel(fileName, contents.join(''))
  }

}
