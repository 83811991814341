
<div class="modal-header">
  <h4 class="modal-title fb mb0">매장 추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <div class="">
      <div class="fl w50p">
        <h4 class="fb mt5 mb6">매장 정보</h4>
      </div>
      <div class="fr w50p right">
        <button type="button" class="btn btn-primary btn-small" (click)="setStoreSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small">
    <caption class="none">매장정보</caption>
    <colgroup>
      <col style="width:15%;" />
      <col style="width:35%;" />
      <col style="width:15%;" />
      <col style="width:35%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="store_name">매장명</label></th>
      <td><inputEx type="text" name="store_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="onoff_gbn">매장구분</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.onoff_gbn.value=='ONLINE'}" (click)="form.get('onoff_gbn').setValue('ONLINE')">온라인매장</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.onoff_gbn.value=='OFFLINE'}" (click)="form.get('onoff_gbn').setValue('OFFLINE')">오프라인매장</button>
        </div>
        <inputEx type="hidden" name="onoff_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="store_gbn">사업장구분</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.store_gbn.value=='D'}" (click)="form.get('store_gbn').setValue('D')">직영점</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.store_gbn.value=='F'}" (click)="form.get('store_gbn').setValue('F')">가맹점</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.store_gbn.value=='A'}" (click)="form.get('store_gbn').setValue('A')">대리점</button>
        </div>
        <inputEx type="hidden" name="store_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="store_zipcode">우편번호</label></th>
      <td>
        <div class="input-group">
          <inputEx name="store_zipcode" class="" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
          <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="store_address">주소</label></th>
      <td><inputEx type="text" name="store_address" [formGroup]="form" [formErrors]="formErrors" readonly="true" placeholder="우편번호 찾기를 이용하세요"></inputEx></td>
      <th scope="row"><label for="store_address_detail">상세주소</label></th>
      <td><inputEx type="text" name="store_address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="latitude">위도</label></th>
      <td><inputEx type="text" name="latitude" [formGroup]="form" [formErrors]="formErrors" placeholder="우편번호 찾기를 이용하세요" readonly="true"></inputEx></td>
      <th scope="row"><label for="longitude">경도</label></th>
      <td><inputEx type="text" name="longitude" [formGroup]="form" [formErrors]="formErrors" placeholder="우편번호 찾기를 이용하세요" readonly="true"></inputEx></td>
    </tr>
    </tbody>
    </table>

    <div class="">
      <h4 class="fb mt5 mb6">사용창고 정보 정보</h4>
    </div>

    <table class="table table-bordered table-small">
    <caption class="none">담당자 정보</caption>
    <colgroup>
      <col style="width:15%;" />
      <col style="width:35%;" />
      <col style="width:15%;" />
      <col style="width:35%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="wh_basic">기본창고</label></th>
      <td>
        <inputEx type="select" name="wh_basic" [formGroup]="form" [formErrors]="formErrors" [data]="warehouseList"></inputEx>
      </td>
      <th scope="row"><label for="wh_output">출고창고</label></th>
      <td>
        <inputEx type="select" name="wh_output" [formGroup]="form" [formErrors]="formErrors" [data]="warehouseList"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="wh_return">반품창고</label></th>
      <td>
        <inputEx type="select" name="wh_return" [formGroup]="form" [formErrors]="formErrors" [data]="warehouseList"></inputEx>
      </td>
      <th scope="row"><label for="wh_RMA">RMA창고</label></th>
      <td>
        <inputEx type="select" name="wh_RMA" [formGroup]="form" [formErrors]="formErrors" [data]="warehouseList"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>

    <div class="">
      <h4 class="fb mt5 mb6">매장 기본 정보</h4>
    </div>

    <table class="table table-bordered table-small">
    <caption class="none">매장 기본 정보</caption>
    <colgroup>
      <col style="width:100%;" />
    </colgroup>

    <tbody>
    <tr>
      <td>
        <inputEx type="textarea" name="store_info" [rows]="4" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>

    <div class="">
      <h4 class="fb mt5 mb6">매장 컨텐츠 정보</h4>
    </div>

    <div id="summernote" #summernote [ngxSummernote]="summernoteConfigDefault" formControlName="store_contents"></div>

  </form>

</div>

<div class="modal-footer">
  <div class="fl w50p">

  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="setStoreSave()">저장하기</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
  </div>
</div>
