<section class="mt10">
  <h4 class="fb">월별 상사 매출(단위 천원)</h4>
  <canvas #canvasA height="60"></canvas>
  <div style="height:150px;" class="mt10">
    <ag-grid-angular #gridA class="ag-theme-balham" style="width: 100%;height:100%;" [gridOptions]="gridAOptions"></ag-grid-angular>
  </div>
</section>
<section class="mt20 mb20">
  <h4 class="fb">상품군별 최근 1개월 판매비중(단위 천원)</h4>
  <div class="fl w30p" style="height:500px;">
    <canvas #canvasB height="300"></canvas>
  </div>
  <div class="fr w70p" style="height:500px;">
    <ag-grid-angular #gridB class="ag-theme-balham" style="width: 100%;height:100%;" [gridOptions]="gridBOptions"></ag-grid-angular>
  </div>
  <div class="cb"></div>
</section>
