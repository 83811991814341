<section id="member-company">

  <div class="mb5">
    <div class="fl w50p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getAnnualEmployeeList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select class="form-control form-control-small ml5" (change)="setYear($event)">
        <option value="2022">2022</option>
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>        
      </select>

    </div>
    <div class="fr">
      <button type="button" class="btn btn-default btn-small">엑셀다운</button>
      <button type="button" class="btn btn-default btn-small ml5" (click)="onBtnClick()">연차/휴가신청</button>
      <button type="button" class="btn btn-danger btn-small ml5" (click)="latenessAdd()">지각추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <as-split unit="percent" restrictMove="true" gutterSize="10" style="height: calc(100vh - 115px);background-color:#fff;">
    <as-split-area size="20" minSize="20" maxSize="40">

      <div style="height:calc(100vh - 132px)">
      <app-tree-view
        [useSort]='false'
        [useAddNode]='false'
        [nodeData]='nodeData'
        (onSelected)='onSelectNode($event)'
      >
      </app-tree-view>
      </div>
    </as-split-area>

    <as-split-area size="80" minSize="60" maxSize="80">

      <table class="table table-bordered table-condensed table-hover mb10">
      <colgroup>
        <col style="width:9%" />
        <col style="width:9%" />
        <col style="width:10%" />
        <col style="width:9%" />
        <col style="width:9%" />
        <col style="width:9%" />
        <col style="width:9%" />
        <col style="width:9%" />
        <col style="width:9%" />
        <col style="width:9%" />
        <col style="width:9%" />
      </colgroup>

      <thead>
      <tr class="active">
        <th scope="col" colspan="3" style="background-color:#f5f5f5;border:solid 1px #ddd;">사용가능일수</th>
        <th scope="col" colspan="8" style="background-color:#f5f5f5;border:solid 1px #ddd;">{{search.year}}년 근태집계표</th>
      </tr>
      <tr class="active">
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">{{search.year -2}}년</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">{{search.year -1}}년</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">{{search.year}}년</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">연차</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">반차</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">조퇴</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">지각</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">경조</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">병가</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">외출</th>
        <th scope="col" style="background-color:#f5f5f5;border:solid 1px #ddd;">잔여일수</th>
      </tr>
      </thead>

      <tbody class="tbody-center">
      <tr>
        <td style="border:solid 1px #ddd;">{{vacationDay.pp_vacation_day}}</td>
        <td style="border:solid 1px #ddd;">{{vacationDay.p_vacation_day}}</td>
        <td style="border:solid 1px #ddd;">{{vacationDay.vacation_day}}</td>
        <td style="border:solid 1px #ddd;">{{this.term.term1}}</td>
        <td style="border:solid 1px #ddd;">{{this.term.term2}}</td>
        <td style="border:solid 1px #ddd;">{{this.term.term3}}</td>
        <td style="border:solid 1px #ddd;">{{this.term.term4}}</td>
        <td style="border:solid 1px #ddd;">{{this.term.term5}}</td>
        <td style="border:solid 1px #ddd;">{{this.term.term6}}</td>
        <td style="border:solid 1px #ddd;">{{this.term.term7}}</td>
        <td style="border:solid 1px #ddd;">{{this.term.total}}</td>
      </tr>
      </tbody>
      </table>

      <div style="height:calc(calc(100vh - 235px))">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="vacationList"
          [pagination]="false"
          [domLayout]="domLayout"
          [rowSelection]="rowSelection"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>

</section>

