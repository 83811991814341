<section id="sms">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{statistic?.total_count | number}}</span>건</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic?.search_count | number}}</span>건</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">이번달<span class="cnt">{{statistic?.month_count | number}}</span>건</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" (click)="initSearch()" class="btn btn-default btn-small-icon">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" (click)="getSMSSendList()" class="btn btn-default btn-small-icon ml5">
        <i class="material-icons-outlined">search</i>
      </button>

      <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getSMSSendList()">
        <option value="25">25줄</option>
        <option value="100">100줄</option>
        <option value="500">500줄</option>
        <option value="1000">1000줄</option>
        <option value="5000">5000줄</option>
      </select>

      <input type="text" [(ngModel)]="search.searchText" size="20" class="form-control form-control-small ml5" (keypress)="searchList($event)" placeholder="수신 휴대폰 번호 검색" />

      <span id='searchDate'>
        <input type="text" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" size="10" class="form-control form-control-small calendar-input ml5"
          placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
        <ng-template #footerTemplate1>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
        </ng-template>
        <span class="ml5 mr5">~</span>
        <input type="text" readonly="readonly" id="edate" [(ngModel)]="search.edate" size="10" class="form-control form-control-small calendar-input m15"
          placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
        <ng-template #footerTemplate2>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
        </ng-template>
      </span>

      <div class="btn-group ml5">
        <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:searchTerm==item.value}" (click)="onSearchClick(item.value)">{{item.title}}</button>
      </div>
    </div>

    <div class="fl w30p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="328716ceb1c843f5ade4cf47ff8f22af" [btnGroup]="1"></btn-ag-grid-save>
      <ag-grid-excel [gridApi]="gridApi" selected="true" filename="SMS발송내역" title="SMS발송내역"></ag-grid-excel>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="SMS발송내역" title="SMS발송내역"></ag-grid-excel>
    </div>
    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 135px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="sendList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
    >
    </ag-grid-angular>
  </div>

  <section class="pagination mt10" *ngIf="statistic.search_count">
    <ngb-pagination
      [(page)]="search.pageNo"
      [pageSize]="search.pageRow"
      [collectionSize]="statistic.search_count"
      [maxSize]="20"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>

</section>
