/*******************************************************************************
  설  명 : 재고관리 > 입고관리 > 발주입고 모달
  작성일 : 2021-05-18
  작성자 : 송영석
  접속URL : /stock/in/management

  수정일 : 2021.05.27
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { StockIn } from '@app/service/stockIn.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { PurchaseFindComponent } from '@components/purchase-find/purchase-find.component';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';

@Component({
  selector: 'app-stock-in-purchase',
  templateUrl: './stock-in-purchase.component.html',
  styleUrls: ['./stock-in-purchase.component.scss']
})
export class StockInPurchaseComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  productList: any = [];
  productPropertyList: any = [];

  public stockInDate: any = null;
  public stockInDate2: any = null;

  public addPurchaseFunc: any;         // 발주서 검색

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiProperty: any;
  gridColumnApiProperty: any;
  columnDefsProperty: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  firstColumn = this.stockOrderComponent.firstColumn;
  valueGetter_num = (params) => this.stockOrderComponent.valueGetter_num(params);

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      purchase_seq : ['', [Validators.required] ],
      gbn : ['', [Validators.required] ],
      date : ['', [Validators.required] ],
      customer_name : [''],
      customer_seq : [''],
      qty : [''],
      amt : [''],
      title : [''],
      productList : [[]],
      propertyList : [[]],

      memo : ['']
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public utilService: UtilService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private stockOrderComponent: StockOrderComponent,
    private stockIn: StockIn,
    private authService: AuthService,
  ) {

    this.addPurchaseFunc = this.searchPurchase.bind(this);

    this.columnDefs = [
      // this.firstColumn,
      { headerName: '카테고리', field: 'category_name', width: 120, cellClass: 'cp left' },
      { headerName: '품절여부', field: 'parent_status', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.parent_status == '0' ) return '<span class="badge badge-success f12">판매중</span>';
          else if ( params.data.parent_status == '1' ) return '<span class="badge badge-warning f12">일시품절</span>';
          else if ( params.data.parent_status == '2' ) return '<span class="badge badge-danger f12">영구품절</span>';
          else return '';
        }
      },
      { headerName: '상품명', field: 'product_name', width: 220, cellClass: 'cp left'},
      { headerName: '거래처', field: 'comname', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent'},
      { headerName: '발주수량', field: 'qty', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params) },
      { headerName: '입고단가', field: 'unit_amt', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, cellEditor: "numericCellEditor", cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => this.valueGetter_num(params) },
      { headerName: '발주금액', field: 'amt', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params) },
    ];

    this.columnDefsProperty = [
      { headerName: '', field: '', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '품절여부', field: 'status', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status == '0' ) return '<span class="badge badge-success f12">판매중</span>';
          else if ( params.data.status == '1' ) return '<span class="badge badge-warning f12">일시품절</span>';
          else if ( params.data.status == '2' ) return '<span class="badge badge-danger f12">영구품절</span>';
          else return '';
        }
      },
      { headerName: '색상', field: 'display_color_name', width: 100, cellClass: 'cp left' },
      { headerName: '사이즈', field: 'display_size_name', width: 100, cellClass: 'cp left' },
      { headerName: '입고창고', field: 'warehouse_name', width: 100, cellClass: 'cp center' },
      { headerName: '입고위치', field: 'location_cd', width: 80, cellClass: 'cp right' },
      { headerName: '발주수량', field: 'purchase_cnt', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '입고완료', field: 'complete_inQty', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '입고수량', field: 'in_qty', width: 80, cellClass: 'cp right ag-cell-edit', 
        editable: true, 
        cellEditor: "numericCellEditor", 
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '입고일자', field: 'in_date', width: 100, cellClass: 'cp center ag-cell-edit', cellRenderer: 'agGridHtmlComponent' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };
  }

  optionsXXL: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xxl',
    centered: true,
    windowClass: 'modal-fadeInDown'
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyProperty(params) {
    this.gridApiProperty = params.api;
    this.gridColumnApiProperty = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 발주서 검색 addOn
  *******************************************************************************/
  searchPurchase() {
    this.searchPurchaseModal();
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/stock/in/management'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 발주서 검색 모달 띄우기
  *******************************************************************************/
  searchPurchaseModal() {
    const modalRef = this.modalService.open(PurchaseFindComponent, this.optionsXXL);
    modalRef.result.then((result) => {
      this.bindingPurchase(result);
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 발주서 검색 결과 바인딩
  *******************************************************************************/
  bindingPurchase(data) {
    this.form.patchValue({
      purchase_seq : data['purchase_seq'],
      gbn : data['gbn'] == 1 ? '매입' : '직발',
      date : data['purchase_date'],
      qty : data['purchase_qty'].toLocaleString(),
      title : data['title'],
      amt : data['purchase_amt'].toLocaleString(),
      productList : data['_product'].map(e => ({
        ...e,
        comname : data['comname'],
        _property : e['_property'].map(e => ({
          ...e,
          in_qty : 0,
          in_date : ''
        }))
      })),
      propertyList : [],
      memo : ''
    });
  }

  /*******************************************************************************
    설  명 : 상품정보 클릭시 색상/사이즈 보여주기
  *******************************************************************************/
  onRowClicked_product($event) {
    this.form.patchValue({
      propertyList : $event.data._property
    });
  }

  /*******************************************************************************
    설  명 : 셀 변경 종료 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStopped( event: any ) {
    const unitAmt = event.data.unit_amt;
    switch (event.colDef.field) {
      case 'unit_amt':
        event.node.setDataValue( 'amt', unitAmt * event.data.qty );
        break;
    }
  }
  
  /*******************************************************************************
    설  명 : 저장하기
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if(!this.form.valid) {
      this.toastrService.warning('필수 입력항목을 확인하시기 바랍니다.', '');
      return;
    }
    const warning = [];
    this.form.value.productList.forEach((e,i) => {
      e['_property'].forEach((_e,_i) => {
        if( (_e['in_qty'] && !_e['in_date']) || (!_e['in_qty'] && _e['in_date']) || (_e['purchase_cnt'] - _e['complete_inQty'] < _e['in_qty']) )
          warning.push(`상품정보 ${i + 1}번째, 상세정보 ${_i + 1}번쨰 항목을 확인하십시오.`);
      });
    });
    if(warning.length) {
      this.toastrService.warning(`잘못된 입력 ${warning.length} 건 : ${warning.shift()}`, '');
      return;
    }

    const _FV = this.form.value;
    this.authService.getLoginInfo.subscribe(__e => {
      this.stockIn.setStockInPurchase({
        mem_no : __e['mem_no'],
        memo : _FV['memo'],
        purchase_seq : _FV['purchase_seq'],

        _product : JSON.stringify(
          _FV['productList'].map(e => ({
            purchase_product_seq : e['purchase_product_seq'],
            product_seq : e['product_seq'],
            qty : e['qty'],
            unit_amt : e['unit_amt'],

            _property : e['_property'].map(_e => ({
              purchase_property_seq : _e['purchase_property_seq'],
              product_property_seq : _e['product_property_seq'],
              customer_seq : _e['customer_seq'],
              in_qty : _e['in_qty'],
              // amt : e['amt'],
              amt : parseInt(e['unit_amt']) * parseInt(_e['in_qty']),
              in_date : _e['in_date'],
              warehouse_seq : _e['warehouse_seq'],
              warehouse_location_seq : _e['warehouse_location_seq'],
            }))
          }))
        )
      }).then(response => {
        if(response.ResultCode) {
          this.toastrService.success(response.ResultMessage);
          this.goList();
        } else this.toastrService.error(response.ResultMessage);
      });

    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 입고수량 자동입력 버튼 클릭
  *******************************************************************************/
  autoInput() {
    this.form.value.productList.forEach((e,i) => {
      e['_property'].forEach((_e,_i) => {
        _e['in_qty'] = _e['purchase_cnt'] - (_e['complete_inQty'] || 0);
        // _e['in_date'] = this.stockOrderComponent.dateFormat(new Date());
      });
    });

    this.gridApiProperty.refreshCells();
  }

  /*******************************************************************************
    설  명 : 입고일자 일괄적용
  *******************************************************************************/
  autoInDate() {
    if( this.stockInDate2 == '' || this.stockInDate2 == null ) {
      this.toastrService.warning( "일괄로 적용할 입고일자를 선택하세요" );
      return false;
    }

    this.form.value.productList.forEach((e,i) => {
      e['_property'].forEach((_e,_i) => {
        _e['in_date'] = this.utilService.getDateStr( this.stockInDate2 );
      });
    });

    this.gridApiProperty.refreshCells();
  }
  
  /*******************************************************************************
    설  명 : 입고일자 선택적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInDate() {
    if( this.stockInDate == '' || this.stockInDate == null ) {
      this.toastrService.warning( "적용할 입고일자를 선택하세요" );
      return false;
    }

    let nodes: any = this.gridApiProperty.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm("입고정보 모든 내역에 입고일자를 적용하시겠습니까?") ) {
        this.gridApiProperty.selectAll();
      } else {
        this.toastrService.warning( "입고일자를 적용할 데이터를 선택하세요" );
        return false;
      }
    } else {
      if( ! confirm("선택하신 내역에 입고일자를 적용하시겠습니까?" ) ) return false;
    }

    this.gridApiProperty.forEachNode(function(node, index) {
      if( node.selected ) {
        node.data.in_date = this.utilService.getDateStr( this.stockInDate );

        this.gridApiProperty.applyTransaction({ update: [node.data] });
      }
    }.bind(this));
  }
  
  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.stockInDate = this.utilService.getDate(moment().format('YYYY-MM-DD'));
    } else {
      this.stockInDate = this.utilService.getDate(moment().format('YYYY-MM-DD'));
    }

    obj.close();
  }

  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday2( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.stockInDate2 = this.utilService.getDate(moment().format('YYYY-MM-DD'));
    } else {
      this.stockInDate2 = this.utilService.getDate(moment().format('YYYY-MM-DD'));
    }

    obj.close();
  }
}