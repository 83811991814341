import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@app/service/util.service';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { PushService } from '@app/service/push.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

const PAGEROW = 100;

@Component({
  selector: 'app-member_push',
  templateUrl: './member-push.component.html',
  styleUrls: ['./member-push.component.scss']
})
export class MemberPushComponent implements OnInit {

  public statistic: any = {
    total_count: 0,
    search_count: 0,
    month_count: 0
  };

  // 검색
  public search: any = {
    pageNo: 1,
    pageRow: PAGEROW,
    seq: '',
    searchText: '',
    sdate: '',
    edate: ''
  };

  public term: any = [
    {title: '이번달', value: this.getDays(0)},
    {title: '1개월', value: this.getDays(1)},
    {title: '2개월', value: this.getDays(2)},
    {title: '3개월', value: this.getDays(3)},
    {title: '6개월', value: this.getDays(6)},
  ];

  public searchTerm: any;

  public sendList: any = [];

  // grid
  private selectedRows = 0;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowSelection = 'multiple';
  public noRowsTemplate = '검색된 데이터가 없습니다.';

  public defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  public columnDefs = [
    {headerName: '선택', field: 'seq', width:90, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    {headerName: '발송일시', field: 'send_date', cellClass: 'cp', width: 160 },
    {headerName: '결과값', field: 'result', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
      valueGetter: function(params: { data: { result: string; sendCount: number; successCount: number; }; }) {
        if( params.data.result == '1' && params.data.sendCount == params.data.successCount ) return '<span class="badge badge-success f12">성공</span>';
        else return '<span class="badge badge-secondary f12">실패</span>';
      }
    },
    {headerName: '제목', field: 'title', cellClass: 'cp', width: 150 },
    {headerName: '내용', field: 'msg', cellClass: 'cp', width: 550 },
    {headerName: '수신회원', field: 'id', cellClass: 'cp', width: 300 },
    {headerName: '발송갯수', field: 'send_count', cellClass: 'cp right', width: 100 },
    {headerName: '성공갯수', field: 'success_count', cellClass: 'cp right', width: 100 },
    {headerName: '실패갯수', field: 'fail_count', cellClass: 'cp right', width: 100 },
  ];

  public frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  constructor(
    private toastrService: ToastrService,
    private utilService: UtilService,
    private pushService: PushService,
  ) {
  }

  ngOnInit(): void {
    this.onSearchClick(this.getDays(0));
  }

  initSearch() {
    // 검색
    this.search = {
      pageNo: 1,
      pageRow: PAGEROW,
      seq: '',
      searchText: '',
      sdate: '',
      edate: ''
    };

    this.search.searchText = '';

    this.onSearchClick(this.getDays(0));
  }

  getDays(month: number) {
    const todaysDate = moment();
    let toDate;
    if (month === 0) {
      toDate = moment().startOf('month');
    } else {
      toDate = moment().add(month * -1, 'months');
    }

    return toDate.diff(todaysDate, 'days');
  }

  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }

  onSearchClick( val ) {
    this.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
    this.getPushSendList();
  }

  getPushSendList() {
    this.pushService.getPushSendList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.sendList = response.data;
        this.statistic = response.statistic;
      } else {
        this.sendList = [];
      }
    }).catch( error => {
    });
  }

  searchList( event ) {
    if ( event.key === 'Enter' ) {
      this.getPushSendList();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getPushSendList();
  }
}
