import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions , NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { CommonService } from '@app/service/common.service';

import * as $ from 'jquery';

import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import { BamaCouponAddComponent } from '@page/basic/bama-coupon/bama-coupon-add/bama-coupon-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};
const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-bama-coupon',
  templateUrl: './bama-coupon.component.html',
  styleUrls: ['./bama-coupon.component.scss']
})
export class BamaCouponComponent implements OnInit {
 
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
    public search: any = {
      searchText: '',
    };

    public form: FormGroup;
    public formErrors = {};

    bamaCouponList: any = [];
    bamaCouponInfo: any = {};  
    couponKindList: any = [];
    giveTypeList: any = [];
    saleTypeList: any = [];


    public productList: any = [];
    public categoryList: any = [];
    public brandList: any = [];

    public productList1: any = [];
    public categoryList1: any = [];
    public brandList1: any = [];

    public params: any = {
      coupon_seq: '',
      category_code: '',
      brand_seq: '',
      isEventSave: '',
      searchText: '',
      gridSearchText: '',
      category_status: 'W',
      brand_status: 'W',
    };    


    public activeMasterTab: number = 0;
    public activeDetailTab: number = 0;

    public sortOrder: any = '';

    private dateModel: NgbDateStruct;

    // 그리드관련 설정 
    gridApi: any;
    gridColumnApi: any;
    columnDefs: any;
    rowSelection: any;   

    gridApiCategory: any;
    gridColumnApiCategory: any;
    columnDefsCategory: any;

    gridApiBrand: any;
    gridColumnApiBrand: any;
    columnDefsBrand: any;  

    gridApiProduct: any;
    gridColumnApiProduct: any;
    columnDefsProduct: any;
    rowSelectionProduct: any;

    gridApiCategory1: any;
    gridColumnApiCategory1: any;
    columnDefsCategory1: any;

    gridApiBrand1: any;
    gridColumnApiBrand1: any;
    columnDefsBrand1: any;  

    gridApiProduct1: any;
    gridColumnApiProduct1: any;
    columnDefsProduct1: any;    
    rowSelectionProduct1: any;
    
    defaultColDef: any;
    domLayout: any;  

    noRowsTemplate: string;
    components: any;
    rowClassRules: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };    

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
    buildForm(): void {
      this.form = this.formBuilder.group({
        seq: ['', [] ],
        coupon_name: ['', [Validators.required] ],
        coupon_kind: ['', [Validators.required] ],
        coupon_kind_name: ['', [] ],
        give_sdate: ['', [] ],
        give_edate: ['', [] ],
        valid_month: [1, [Validators.required] ],
        give_type: ['', [] ],
        give_type_name: ['', [] ],
        sale_type: ['', [] ],
        sale_type_name: ['', [] ],
        apply_type: ['RATE', [] ],
        apply_type_name: ['', [] ],
        apply_number: [0, [] ],
        use_yn: [1, [] ],
      });
  
      this.form.valueChanges.subscribe(data => {
        this.utilService.updateFormErrors( this.form, this.formErrors );
      });
    }
  
  /*******************************************************************************
      설  명 : 생성자
      입력값 : 없음
      리턴값 : 없음
  *******************************************************************************/  

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private basicService: BasicService,
    private commonService: CommonService,
    private agGridExComponent: AgGridExComponent,

  ) { 
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '쿠폰명', field: 'coupon_name', width: 200, cellClass: 'cp left' },
      { headerName: '쿠폰종류', field: 'coupon_kind_name', width: 100, cellClass: 'cp left' },
      { headerName: '적용구분', field: 'apply_type_name', width: 80, cellClass: 'cp center' },
      { headerName: '적용금액(비율)', field: 'apply_number', width: 120, cellClass: 'cp right' ,
        valueFormatter: this.agGridExComponent.currencyFormatter},
      { headerName: '사용여부', field: 'use_yn', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1' ) {
            return '<span class="badge badge-success f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
    ];    

    // ag grid 컬럼 선언
    this.columnDefsCategory = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', checkboxSelection: true },
      { headerName: '카테고리명', field: 'category_name', width: 200, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 65, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '완료수', field: 'complete_total', width: 65, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let fb = ( params.data.complete_total > 0 ) ? 'fb' : '';

          return `<span class="${fb}">${params.data.complete_total}</span>`;
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefsCategory1 = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', checkboxSelection: true },
      { headerName: '카테고리명', field: 'category_name', width: 200, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 65, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '완료수', field: 'complete_total', width: 65, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let fb = ( params.data.complete_total > 0 ) ? 'fb' : '';

          return `<span class="${fb}">${params.data.complete_total}</span>`;
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefsBrand = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', checkboxSelection: true },
      { headerName: '브랜드명', field: 'brand_name', width: 180, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 76, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '완료수', field: 'complete_total', width: 76, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let fb = ( params.data.complete_total > 0 ) ? 'fb' : '';

          return `<span class="${fb}">${params.data.complete_total}</span>`;
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefsBrand1 = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center', checkboxSelection: true },
      { headerName: '브랜드명', field: 'brand_name', width: 180, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 76, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '완료수', field: 'complete_total', width: 76, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let fb = ( params.data.complete_total > 0 ) ? 'fb' : '';

          return `<span class="${fb}">${params.data.complete_total}</span>`;
        }
      },
    ];

    this.columnDefsProduct = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '상태', field: 'seq', width: 70, cellClass: 'center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.seq === null ) {
            return '<span class="badge badge-secondary f12">대기</span>';
          } else {
            return '<span class="badge badge-primary f11">완료</span>';
          }
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 105, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품정보', field: 'category_name', width: 265, cellClass: 'ag-cell80h-br cell-wrap-text', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.category_name + '<br />[' + params.data.brand_name + ']<br />' + params.data.product_name;
        }
      },
    ];

    this.columnDefsProduct1 = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '상태', field: 'seq', width: 70, cellClass: 'center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.seq === null ) {
            return '<span class="badge badge-secondary f12">대기</span>';
          } else {
            return '<span class="badge badge-primary f11">완료</span>';
          }
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 105, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품정보', field: 'category_name', width: 265, cellClass: 'ag-cell80h-br cell-wrap-text', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.category_name + '<br />[' + params.data.brand_name + ']<br />' + params.data.product_name;
        }
      }
    ];


    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true
    };

    this.rowSelection = 'single';
    this.rowSelectionProduct = 'multiple';
    this.rowSelectionProduct1 = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';   

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor()
    };

    this.rowClassRules = {
      'status8': function (params) {
        return params.data.seq !== null;
      },
    };
    

    // 콤마 넣기
    function getComma( num: any ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }    

  }

  ngOnInit(): void {
     this.getCommonList();
     this.getBamaCouponList();
     
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

    }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onRowClicked(event: any) {
      this.form.patchValue({
        seq: event.data.seq
      });
      
      this.params.coupon_seq = event.data.seq;
  
      this.getBamaCouponDetail();
    }  

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    searchInit() {
      
      this.search = {
        searchText: ''
      };
  
      this.getBamaCouponList();
      
    }  


   /*******************************************************************************
    설  명 : 쿠폰 상세 정보를 초기화 한다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    initCouponDetail() {

      this.activeMasterTab = 0;
      this.activeDetailTab = 0;

      this.getCouponGiveCategoryProductList(); 
    }   

  /*******************************************************************************
    설  명 : 바마쿠폰 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getBamaCouponList() {
      
      this.basicService.getBamaCouponList( this.search ).then( response => {
        if( response.ResultCode ) {
          this.bamaCouponList = response.data;
  
          if( this.bamaCouponList.length > 0  && (!this.form.controls.seq.value || this.form.controls.seq.value =='') ) {
            this.form.patchValue({
              seq: this.bamaCouponList[0].seq
            });
            this.params.coupon_seq =  this.bamaCouponList[0].seq;
  
            this.getBamaCouponDetail();
  
            let index = 0;
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter(function(node) {
                // select the node
                if( index++ == 0 ) {
                  node.setSelected(true);
                }
              });
            }, 0);

            
          } else {
            const selectedSeq = this.form.controls.seq.value;
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter(function(node) {
                // select the node
                if( node.data.seq == selectedSeq ) {
                  node.setSelected(true);
                }
              });
            }, 0);
          }
  
        } else {
          this.toastrService.error( response.ResultMessage, 'getBamaCouponList');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
      
    }    

  /*******************************************************************************
    설  명 : 바마쿠폰 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getBamaCouponDetail() {

      this.basicService.getBamaCouponDetail( this.form.controls.seq.value ).then( response => {
        console.log(response.ResultCode);
        if( response.ResultCode ) {
  
          this.bamaCouponInfo = response.data;


          this.form.patchValue( {
               seq: this.bamaCouponInfo[0].seq , 
               coupon_name: this.bamaCouponInfo[0].coupon_name , 
               coupon_kind: this.bamaCouponInfo[0].coupon_kind , 
               give_sdate:  this.utilService.getDate(this.bamaCouponInfo[0].give_sdate) , 
               give_edate: this.utilService.getDate(this.bamaCouponInfo[0].give_edate) , 
               valid_month: this.bamaCouponInfo[0].valid_month , 
               give_type: this.bamaCouponInfo[0].give_type , 
               sale_type: this.bamaCouponInfo[0].sale_type , 
               apply_type: this.bamaCouponInfo[0].apply_type , 
               apply_number: this.bamaCouponInfo[0].apply_number , 
               use_yn: this.bamaCouponInfo[0].use_yn , 
          } );

          // 쿠폰 상세 정보를 초기화 한다. 
          this.initCouponDetail(); 
          this.getCouponGiveCategoryList();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }    

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
    searchList( event ) {
     
      if( event.key == 'Enter' ) {
        this.getBamaCouponList();
      }
      
    }    

  /*******************************************************************************
    설  명 : 바마쿠폰 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    setBamaCouponAdd() {

      const modalRef = this.modalService.open(BamaCouponAddComponent, options);
  
      modalRef.result.then((result) => {
        if( result ) {
          this.getBamaCouponList();
        }
      }, (reason) => {
      });
    }    

  /*******************************************************************************
    설  명 : 바마쿠폰 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    setBamaCouponSave() {
      this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
  
      if (this.form.valid) {
        this.basicService.setBamaCouponSave(this.form).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
  
            this.getBamaCouponList();
            this.getBamaCouponDetail();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      } else {
        this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      }
    }
  
    /*******************************************************************************
      설  명 : 바마쿠폰 삭제
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    setBamaCouponDelete() {
      if ( confirm('삭제하시겠습니까?') ) {
        this.basicService.setWarehouseDelete( this.form.controls.seq.value ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
  
            this.form.patchValue({
              seq: ''
            });
  
            this.getBamaCouponList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }    

  /*******************************************************************************
    설  명 : 쿠폰 종류 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    changeCouponKind() {
      const couponKind = this.couponKindList.filter(item => item.value === this.form.value.coupon_kind);
  
      if( couponKind[0].value ) {
        this.form.patchValue({
          coupon_kind_name: couponKind[0].title,
        });
      }
    }

   /*******************************************************************************
    설  명 : 지급적용 방법 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    changeGiveType() {
      const giveType = this.giveTypeList.filter(item => item.value === this.form.value.give_type);
  
      if( giveType[0].value ) {
        this.form.patchValue({
          gice_type_name: giveType[0].title,
        });
      }
    }  

   /*******************************************************************************
    설  명 : 판매적용방법 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    changeSaleType() {
      const saleType = this.saleTypeList.filter(item => item.value === this.form.value.sale_type);
  
      if( saleType[0].value ) {
        this.form.patchValue({
          sale_type_name: saleType[0].title,
        });
      }
    }  


  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getCommonList() {
      // 쿠폰종류
      this.commonService.getCommonListCode('BCK').then( response => {
        if ( response.ResultCode ) {
          response.data.forEach( row => {
            this.couponKindList.push({
              value: row.common_code,
              title: row.common_name
            });
          });
        } else {
          this.couponKindList = [];
        }
      });

      // 지급적용구분
      this.commonService.getCommonListCode('BCV').then( response => {
        if ( response.ResultCode ) {
          response.data.forEach( row => {
            this.giveTypeList.push({
              value: row.common_code,
              title: row.common_name
            });
          });
        } else {
          this.giveTypeList = [];
        }
      });
  
      // 판매적용구분
      this.commonService.getCommonListCode('BCV').then( response => {
        if ( response.ResultCode ) {
          response.data.forEach( row => {
            this.saleTypeList.push({
              value: row.common_code,
              title: row.common_name
            });
          });
        } else {
          this.saleTypeList = [];
        }
      });
    }

  /*******************************************************************************
    설  명 : 마스터 탭(지급적용상품설정 , 판매적용상품설정) 클릭 시
  *******************************************************************************/
    onMasterTabChange( event: any ) {
      this.activeMasterTab = event;

      this.activeDetailTab = 0;
      if (this.activeMasterTab === 0 ) {
        this.productList = [];
  
        this.params.searchText = ''
    
        this.params.brand_seq = '';
        this.getCouponGiveCategoryList();
          
      } else {
        this.productList1 = [];
  
        this.params.searchText = ''
    
        this.params.brand_seq = '';
        this.getCouponSaleCategoryList();

      }

    }  
    
  /*******************************************************************************
    설  명 :  디테일탭 (카테고리, 브랜드, 전체상품 ) 클릭 시
  *******************************************************************************/
    onDetailTabChange( event: any ) {
      this.activeDetailTab = event;

      if (this.activeMasterTab === 0 ) {
        this.productList = [];
  
        this.params.searchText = ''
    
        if( event === 0 ) {
          this.params.brand_seq = '';
          this.getCouponGiveCategoryList();
        } else if( event === 1 ) {
          this.getCouponGiveBrandList();
        } else if( event === 2 ) {
          this.params.brand_seq = '';
          this.getCouponGiveSearchProductList();
        }
      } else {
          this.productList1 = [];
  
          this.params.searchText = ''
      
          if( event === 0 ) {
            this.params.brand_seq = '';
            this.getCouponSaleCategoryList();
          } else if( event === 1 ) {
            this.getCouponSaleBrandList();
          } else if( event === 2 ) {
            this.params.brand_seq = '';
            this.getCouponSaleSearchProductList();
          }
    
      }
  
    }       

  /*******************************************************************************
    설  명 : getRowNodeId 설정
  *******************************************************************************/
    getRowNodeId = data => data.seq;    
      
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
    onGridReadyCategory( params ) {
      this.gridApiCategory = params.api;
      this.gridColumnApiCategory = params.columnApi;
    }
    onGridReadyBrand( params ) {
      this.gridApiBrand = params.api;
      this.gridColumnApiBrand = params.columnApi;
    }
    onGridReadyProduct( params ) {
      this.gridApiProduct = params.api;
      this.gridColumnApiProduct = params.columnApi;
    }
    onGridReadyCategory1( params ) {
      this.gridApiCategory1 = params.api;
      this.gridColumnApiCategory1 = params.columnApi;
    }
    onGridReadyBrand1( params ) {
      this.gridApiBrand1 = params.api;
      this.gridColumnApiBrand1 = params.columnApi;
    }
    onGridReadyProduct1( params ) {
      this.gridApiProduct1 = params.api;
      this.gridColumnApiProduct1 = params.columnApi;
    }

  /*******************************************************************************
    설  명 : 카테고리 그리드 행 클릭 시(지급적용)
  *******************************************************************************/
    onCategoryGiveGridRowClicked( $event ) {
      this.params.category_code = $event.data.category_code;
      this.getCouponGiveCategoryProductList();
    }    

  /*******************************************************************************
    설  명 : 카테고리 그리드 행 클릭 시(판매적용)
  *******************************************************************************/
    onCategorySaleGridRowClicked( $event ) {
      this.params.category_code = $event.data.category_code;
      this.getCouponSaleCategoryProductList();
    }    


  /*******************************************************************************
    설  명 : 카테고리 그리드 검색(지급적용상품설정)
  *******************************************************************************/
    onCategoryQuickFilterChanged( event: any ) {
      const searchText: any = document.getElementById( event.target.id );
      this.gridApiCategory.setQuickFilter(searchText.value);
    }    

  /*******************************************************************************
    설  명 : 카테고리 그리드 검색(판매적용상품설정)
  *******************************************************************************/
    onCategoryQuickFilterChanged1( event: any ) {
      const searchText: any = document.getElementById( event.target.id );
      this.gridApiCategory1.setQuickFilter(searchText.value);
    }    


  /*******************************************************************************
    설  명 : 브랜드 그리드 행 클릭 시 (지급적용상품설정)
  *******************************************************************************/
    onBrandGiveGridRowClicked( $event ) {
      this.params.brand_seq = $event.data.seq;
      this.getBrandGiveProductList();
    }
  /*******************************************************************************
    설  명 : 브랜드 그리드 행 클릭 시(판매적용상품설정)
  *******************************************************************************/
    onBrandSaleGridRowClicked( $event ) {
      this.params.brand_seq = $event.data.seq;
      this.getBrandSaleProductList();
    }s
  
    /*******************************************************************************
      설  명 : 브랜드 그리드 검색(지급적용상품설정)
    *******************************************************************************/
    onBrandQuickFilterChanged( event: any ) {
      const searchText: any = document.getElementById( event.target.id );
      this.gridApiBrand.setQuickFilter(searchText.value);
    }

    /*******************************************************************************
      설  명 : 브랜드 그리드 검색(판매적용상품설정)
    *******************************************************************************/
      onBrandQuickFilterChanged1( event: any ) {
        const searchText: any = document.getElementById( event.target.id );
        this.gridApiBrand1.setQuickFilter(searchText.value);
      }
  
   /*******************************************************************************
    설  명 : 브랜드의 상품 리스트 가져오기(지급적용상품설정)
  *******************************************************************************/
    getBrandGiveProductList() {
      this.basicService.getCouponGiveBrandProductList( this.params ).then( response => {
        if (response.ResultCode) {
          this.productList = response.data;
        } else {
          this.productList = [];
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }  
   /*******************************************************************************
    설  명 : 브랜드의 상품 리스트 가져오기(판매적용상품설정)
  *******************************************************************************/
    getBrandSaleProductList() {
      this.basicService.getCouponSaleBrandProductList( this.params ).then( response => {
        if (response.ResultCode) {
          this.productList1 = response.data;
        } else {
          this.productList1 = [];
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }         

  /*******************************************************************************
    설  명 :선택한 카테고리의 상품 리스트 가져오기(지급적용상품설정)
  *******************************************************************************/
    getCouponGiveCategoryProductList() {
      this.basicService.getCouponGiveCategoryProductList( this.params ).then( response => {
        if (response.ResultCode) {
          this.productList = response.data;
        } else {
          this.productList = [];
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }

  /*******************************************************************************
    설  명 :선택한 카테고리의 상품 리스트 가져오기(판매적용상품설정)
  *******************************************************************************/
    getCouponSaleCategoryProductList() {
      this.basicService.getCouponSaleCategoryProductList( this.params ).then( response => {
        if (response.ResultCode) {
          this.productList1 = response.data;
        } else {
          this.productList = [];
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }


  /*******************************************************************************
    설  명 : 숫자만 입력
  *******************************************************************************/
    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if( charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
    getRowHeight = function(params) {
      return 80;
    };    

  /*******************************************************************************
    설  명 : 행 클릭시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellClicked($event) {
      let product_seq = $event.data.product_seq;
      
      if( $event.colDef.field === 'thumbnail_url' ) {
        const url = '/product/list/add?seq=' + product_seq;
        window.open("about:blank").location.href = url;
      }
    }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기(지급적용)
  *******************************************************************************/
    getCouponGiveCategoryList() {
      this.basicService.getCouponGiveCategoryList( this.params ).then( response => {
        if (response.resultCode) {
          const dataList = [];
          const setList = (tabChar, currentNode: any) => {
            dataList.push({
              seq: currentNode.seq,
              status: currentNode.status,
              category_code: currentNode.category_code,
              category_name: tabChar + currentNode.name,
              product_total: currentNode.product_total,
              complete_total: currentNode.complete_total
            });
  
            if (typeof currentNode.children === undefined) {
              return;
            } else {
              for (const children of currentNode.children) {
                let tab = ( children.category_code.length / 3 == 3 ) ? '　　' : '　';
                setList(tab, children);
              }
            }
          };
  
          for (const item of response.data) {
            setList('', item);
          }
  
          this.categoryList = dataList;
  
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
  
      });
    }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기(판매적용)
  *******************************************************************************/
    getCouponSaleCategoryList() {
      this.basicService.getCouponSaleCategoryList( this.params ).then( response => {
        if (response.resultCode) {
          const dataList = [];
          const setList = (tabChar, currentNode: any) => {
            dataList.push({
              seq: currentNode.seq,
              status: currentNode.status,
              category_code: currentNode.category_code,
              category_name: tabChar + currentNode.name,
              product_total: currentNode.product_total,
              complete_total: currentNode.complete_total
            });
  
            if (typeof currentNode.children === undefined) {
              return;
            } else {
              for (const children of currentNode.children) {
                let tab = ( children.category_code.length / 3 == 3 ) ? '　　' : '　';
                setList(tab, children);
              }
            }
          };
  
          for (const item of response.data) {
            setList('', item);
          }
  
          this.categoryList1 = dataList;
  
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
  
      });
    }


  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기(지급적용)
  *******************************************************************************/
    getCouponGiveBrandList() {
      this.basicService.getCouponGiveBrandList( this.params ).then( response => {
        if ( response.ResultCode ) {
          this.brandList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기(판매적용)
  *******************************************************************************/
    getCouponSaleBrandList() {
      this.basicService.getCouponSaleBrandList( this.params ).then( response => {
        if ( response.ResultCode ) {
          this.brandList1 = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }


  /*******************************************************************************
    설  명 : 검색한 상품 리스트 가져오기(지급적용상품설정)
  *******************************************************************************/
    getCouponGiveSearchProductList() {
      this.productList = [];
  
      this.basicService.getCouponGiveSearchProductList( this.params ).then( response => {
        if (response.ResultCode) {
          this.productList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }    

  /*******************************************************************************
    설  명 : 검색한 상품 리스트 가져오기(판매적용상품설정)
  *******************************************************************************/
    getCouponSaleSearchProductList() {
      this.productList1 = [];
  
      this.basicService.getCouponSaleSearchProductList( this.params ).then( response => {
        if (response.ResultCode) {
          this.productList1 = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }     

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
    setSearchList( event ) {
      if( event.key == 'Enter' ) {
        if (this.activeMasterTab === 0 ) {
          this.getCouponGiveSearchProductList();
        } else {
          this.getCouponSaleSearchProductList();

        }  
      }
    }    

  /*******************************************************************************
    설  명 : 지급적용 상품 설정 저장 
  *******************************************************************************/
    setBamaCouponGiveProductSave() {
      const nodes = this.gridApiProduct.getSelectedRows();
      if( nodes.length < 1 ) {
        this.toastrService.error( '등록할 제품을 선택하시기 바랍니다.', '');
      } else {
        const params = [];
        this.gridApiProduct.forEachNode( (node) => {
          if ( node.selected ) {
            params.push({
              event_seq: this.params.coupon_seq,
              seq: node.data.seq ?? '0',
              product_seq: node.data.product_seq,
            });
          }
        });
  
        this.basicService.setBamaCouponGiveProductSave( JSON.stringify(params) ) .then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
  
               this.getCouponGiveSearchProductList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  
    /*******************************************************************************
      설  명 : 지급적용  상품설정 삭제
    *******************************************************************************/
    setBamaCouponGiveProductDelete() {

      const nodes = this.gridApiProduct.getSelectedRows();
      if( nodes.length < 1 ) {
        this.toastrService.error( '삭제할 제품을 선택하시기 바랍니다.', '');
      } else {
        const params = [];
        this.gridApiProduct.forEachNode( (node) => {
          if ( node.selected ) {
            params.push({
              seq: node.data.seq ?? '0',
            });
          }
        });
        console.log(JSON.stringify(params));
      this.basicService.setBamaCouponGiveProductDelete( JSON.stringify(params) ) .then( response => {
              if ( response.ResultCode ) {
                this.toastrService.success( response.ResultMessage, '');

                  this.getCouponGiveSearchProductList();
              } else {
                this.toastrService.error( response.ResultMessage, '');
              }
       });

      }  

    }
   
  
  /*******************************************************************************
    설  명 : 판매적용 상품 설정 저장 
  *******************************************************************************/
    setBamaCouponSaleProductSave() {
      const nodes = this.gridApiProduct1.getSelectedRows();
      if( nodes.length < 1 ) {
        this.toastrService.error( '등록할 제품을 선택하시기 바랍니다.', '');
      } else {
        const params = [];
        this.gridApiProduct1.forEachNode( (node) => {
          if ( node.selected ) {
            params.push({
              event_seq: this.params.coupon_seq,
              seq: node.data.seq ?? '0',
              product_seq: node.data.product_seq,
            });
          }
        });
  
        this.basicService.setBamaCouponSaleProductSave( JSON.stringify(params) ) .then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

  
             this.getCouponSaleSearchProductList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  
    /*******************************************************************************
      설  명 : 판매 적용  상품설정 삭제
    *******************************************************************************/
    setBamaCouponSaleProductDelete() {

      const nodes = this.gridApiProduct1.getSelectedRows();
      if( nodes.length < 1 ) {
        this.toastrService.error( '삭제할 제품을 선택하시기 바랍니다.', '');
      } else {
        const params = [];
        this.gridApiProduct1.forEachNode( (node) => {
          if ( node.selected ) {
            params.push({
              seq: node.data.seq ?? '0',
            });
          }
        });
      console.log(JSON.stringify(params));
      this.basicService.setBamaCouponSaleProductDelete( JSON.stringify(params) ) .then( response => {
              if ( response.ResultCode ) {
                this.toastrService.success( response.ResultMessage, '');

                  this.getCouponSaleSearchProductList();
              } else {
                this.toastrService.error( response.ResultMessage, '');
              }
       });

      }  

   
    }


}
