import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class StockManagement {

  constructor(
    private restful: RestfulService,
  ) {
  }

  
  // 재고관리 - 재고관리 : 재고현황 get
  getStockManagement( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockManagement',
      version: '1.0',
      action: 'getStockManagement'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 재고관리 - 재고관리 : 각각의 재고이력 get
  getStockManagement_byHistory( params ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'stockManagement',
      version: '1.0',
      action: 'getStockManagement_byHistory'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 재고관리 - 재고관리 : 각각의 창고별재고 get
  getStockManagement_byWarehouse( params ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'stockManagement',
      version: '1.0',
      action: 'getStockManagement_byWarehouse'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 창고이동
  setStockMove( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockManagement',
      version: '1.0',
      action: 'setStockMove'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 폐기출고, 분실출고
  setStockLoss( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockManagement',
      version: '1.0',
      action: 'setStockLoss'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

}
