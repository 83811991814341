<form [formGroup]="form"></form>
<section class="h100">
  <header>
    <h4 class="modal-title fb mb0">기초/기타 입고</h4>
  </header>

  <section class="search-condition mb5">
    <div class="fl form-inline asub-title">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
        <i class="material-icons-outlined">search</i>
      </button>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small">전체</button>
        <button type="button" class="btn btn-default btn-small">매입상품</button>
        <button type="button" class="btn btn-default btn-small">직발상품</button>
      </div> -->

      <div class="input-group ml5" (click) = "addProductModal()">
        <input type="text" class="form-control form-control-small" placeholder="상품 검색" readonly/>
        <div class="input-group-append">
          <button type="button" class="btn btn-default btn-small f12">상품찾기</button>
        </div>
      </div>

      <!-- <div class="input-group ml5">
        <input type="text" class="form-control form-control-small" placeholder="거래처 검색" />
        <div class="input-group-append">
          <button type="button" class="btn btn-default btn-small f12">거래처찾기</button>
        </div>
      </div> -->
    </div>
    <div class="fr right">
      <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click) = "setStockIn()">저장하기</button>
    </div>
    <div class="cb"></div>
  </section>

  <hr class="db mt5 mb5" />

  <section class="contents-body" style="height:calc(100% - 60px)">
    <as-split unit="percent" restrictMove="true" gutterSize="10">
      <!-- 기초/기타 상품 정보 -->
      <as-split-area size="45" minSize="30" maxSize="70">
        <section class="search-condition mb5">
          <div class="fl form-inline asub-title">
            <h4 class="fl sub-title pt3 fb mb0">상품정보 : {{form.value.productList.length}} 건</h4>
          </div>
          <div class="fr">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="ddeb1af315194c4eb214a11b0736769a" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </section>

        <ag-grid-angular
          #myGrid

          style="width: 100%;height:calc(100% - 45px);"
          class="ag-theme-balham ag-vertical-align"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="form.value.productList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
          [getRowHeight]="getRowHeight"
          [components]="components"

          (gridReady)="onGridReady($event)"
          (rowClicked) = "onRowClicked($event)"
          >
        </ag-grid-angular>
      </as-split-area>
      <!-- 기초/기타 상품 정보 -->

      <!-- 상품별 색상 사이즈 정보 -->
      <as-split-area size="55" minSize="30" maxSize="70" style="height:100%;">
        <section class="search-condition mb5">
          <div class="fl form-inline asub-title">
            <h4 class="fl sub-title pt3 fb mb0">상품별 색상 사이즈 : {{form.value.productListProperty.length}} 건</h4>
          </div>
          <div class="fr">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApiProperty" gridId="2a91305777084c2b90617c03649600bf" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </section>

        <ag-grid-angular
          #myGrid

          style="width: 100%;height:calc(100% - 45px);"
          class="ag-theme-balham ag-vertical-align"

          [columnDefs]="columnDefsProperty"
          [defaultColDef]="defaultColDef"
          [rowData]="form.value.productListProperty"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
          [components]="components"
          [stopEditingWhenGridLosesFocus]="true"
          [singleClickEdit]="true"

          (gridReady)="onGridReadyProperty($event)"
          (cellClicked) = "cellClick_property($event)"
          (selectionChanged) = "onSelectionChanged_property($event)"
          (cellValueChanged) = "onCellValueChanged($event)"
          >
        </ag-grid-angular>
      </as-split-area>
      <!-- 상품별 색상 사이즈 정보 -->
    </as-split>

  </section>
</section>