<section id="warehouse">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="30" minSize="25" maxSize="40">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getWarehouseList()">
            <i class="material-icons-outlined">search</i>
          </button>

          <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="창고명 검색" />
        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="6a3bfefb83b040ae9ac6c45ce2fcda37" [btnGroup]="1"></btn-ag-grid-save>

          <button type="button" title="창고추가" class="btn btn-primary btn-small-icon ml5" (click)="setWarehouseAdd()">
            <i class="material-icons-outlined">add</i>
          </button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="warehouseList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="70" minSize="60" maxSize="75">
      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">창고 정보 - {{warehouseInfo.warehouse_name}}</h4>
        </div>
        <div class="fr w50p right">
          <button *ngIf="form.controls.seq.value!=null" type="button" class="btn btn-danger btn-small mr5" (click)="setWarehouseDelete()">삭제하기</button>
          <button type="button" class="btn btn-primary btn-small" (click)="setWarehouseSave()">저장하기</button>
        </div>
        <div class="cb"></div>
      </div>

      <form [formGroup]="form">

        <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

        <table class="table table-bordered table-small mb0">
        <caption class="none">창고정보</caption>
        <colgroup>
          <col style="width:15%;" />
          <col style="width:35%;" />
          <col style="width:15%;" />
          <col style="width:35%;" />
        </colgroup>

        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="warehouse_name">창고명</label></th>
          <td><inputEx type="text" name="warehouse_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="stock_yn">재고반영</label></th>
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.stock_yn.value=='Y'}" (click)="form.get('stock_yn').setValue('Y')">재고반영</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.stock_yn.value=='N'}" (click)="form.get('stock_yn').setValue('N')">반영안함</button>
            </div>
            <inputEx type="hidden" name="stock_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="wh_zipcode">우편번호</label></th>
          <td>
            <div class="input-group">
              <inputEx name="wh_zipcode" class="mr3" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
              <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
            </div>
          </td>
          <th scope="row"><label for="wh_address">주소</label></th>
          <td><inputEx type="text" name="wh_address" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
        </tr>
        <tr>
          <th scope="row"><label for="wh_address_detail">상세주소</label></th>
          <td colspan="3"><inputEx type="text" name="wh_address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        </tbody>
        </table>

      </form>

      <div class="mt10">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">담당자 정보</h4>
        </div>
        <div class="fr w50p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApiCharge" gridId="22d4934792a040a08ad5bb4ee35208c1" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-danger btn-small ml5" (click)="setWarehouseChargeDelete()">담당자삭제</button>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="setWarehouseChargeAdd(0)">담당자추가</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:200px">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsCharge"
          [defaultColDef]="defaultColDef"
          [rowData]="chargeList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelectionCharge"

          (rowDoubleClicked)="setWarehouseChargeAdd($event.data.seq)"
          (gridReady)="onGridReadyCharge($event)"
          >
        </ag-grid-angular>
      </div>

      <div class="mt10">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">창고 위치관리</h4>
        </div>
        <div class="fr w50p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApiLocation" gridId="ce05aaec08d74a719225714c0813cf78" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-danger btn-small ml5" (click)="setWarehouseLocationDelete()">위치삭제</button>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="setWarehouseLocationAdd(0)">위치추가</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height: 50%">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsLocation"
          [defaultColDef]="defaultColDef"
          [rowData]="locationList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelectionLocation"

          (rowDoubleClicked)="onRowDoubleClickedLocation($event)"
          (gridReady)="onGridReadyLocation($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
