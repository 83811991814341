  <div class="modal-header">
    <h4 class="modal-title fb mb0">발주처리</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="hidden">발주처리</caption>

    <colgroup>
      <col style="width:25%;" />
      <col style="width:75%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="" class="control-label">발주일자</label></th>
      <td>
        <input type="text" size="13" readonly="readonly" [value]="date" class="form-control form-control-small calendar-input " placeholder="" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate($event)" />
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="" class="control-label">메모하기</label></th>
      <td><input type="checkbox" (change)="boxChange($event)"><div style="float: right; width: calc(100% - 20px); margin-top: 3px; font-size: 0.9em; font-weight: 300;">이 주문에 메모를 남깁니다. <br>자동삭제는 되지 않으며, 중복될 수 있습니다.</div></td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button type="button" class="btn btn-small btn-danger" (click)="delete()">삭제</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-small blue-btn" (click)="submit()">저장</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
    </div>
  </div>