  <section class="" style="height:100%;">
    <as-split unit="percent" restrictMove="true" gutterSize="10">
      <as-split-area size="30" minSize="20" maxSize="40">
        <div class="mb5">
          <div class="fl">
            <div class="f14 pt5 fb">창고리스트</div>
          </div>
          <div class="fr">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApiWarehouse" gridId="e2b525af519d42e8ad07bcdde827df53" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <div style="height:calc(100% - 40px)">
          <ag-grid-angular
            #myGrid

            style="width:100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefsWarehouse"
            [defaultColDef]="defaultColDef"
            [rowData]="warehouseList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"

            (gridReady)="onGridReadyWarehouse($event)"
            >
          </ag-grid-angular>
        </div>
      </as-split-area>
      <as-split-area size="70" minSize="60" maxSize="80">
        <div class="mb5">
          <div class="fl">
            <div class="form-inline">
              <select class="form-control form-control-small">
                <option value="">입고창고</option>
              </select>
              <button type="button" class="btn btn-success btn-small f12 ml5">입고창고 일괄선택</button>

              <select class="form-control form-control-small ml5">
                <option value="">이동창고</option>
              </select>
              <button type="button" class="btn btn-success btn-small f12 ml5">이동창고 일괄선택</button>

              <input type="text" readonly="readonly" id="date" style="width:110px;" class="form-control form-control-small calendar-input ml5" placeholder="이동일자" />

              <button type="button" class="btn btn-success btn-small f12 ml5">이동일자 일괄변경</button>
            </div>
          </div>
          <div class="fr">
            <div class="form-inline">
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="44be7f65080147f59a2849a4bf8391ef" [btnGroup]="1"></btn-ag-grid-save>
              <button type="button" class="btn btn-primary btn-small f12 ml5">창고이동</button>
            </div>
          </div>
          <div class="cb"></div>
        </div>

        <section class="contents-body" style="height:calc(100% - 40px);">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="stockList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [rowSelection]="rowSelection"
            [frameworkComponents]="frameworkComponents"

            (gridReady)="onGridReady($event)"
          >
          </ag-grid-angular>
        </section>
      </as-split-area>
    </as-split>
  </section>