import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { CompanyService } from '@app/service/company.service';
import { CommonService } from '@app/service/common.service';
import { MemberService } from '@app/service/member.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';


@Component({
  selector: 'app-member-society-statistic',
  templateUrl: './member-society-statistic.component.html',
  styleUrls: ['./member-society-statistic.component.scss']
})
export class MemberSocietyStatisticComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
    rankType: '1001',
  };

  public month: any = moment().format('MM');

  public rankList: any = [];
  public historyList: any = [];

  public rankSelected: any = {};

  public selectedSeq: any = null;

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    associateCount: 0,
    enteredCount: 0
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridDetailApi: any;
  gridDetailColumnApi: any;
  columnDetailDefs: any;

  gridMemoApi: any;
  gridMemoColumnApi: any;
  columnMemoDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  search_time: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private companyService: CompanyService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private memberService: MemberService,
    private agGridExComponent: AgGridExComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순위', field: 'listRank', width: 68, cellClass: 'cp center' },
      // { headerName: '동호회순번', field: 'society_seq', width: 80, cellClass: 'cp left' },
      { headerName: '동호회명', field: 'society_name', width: 200, cellClass: 'cp left' },
      { headerName: '대표자', field: 'rep_name', width: 100, cellClass: 'cp left' },
      { headerName: '총 매출금액', field: 'totalSaleAmt', width: 130, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '총 누적점수', field: 'accumulationScore', width: 130, cellClass: 'cp right' , valueFormatter: this.agGridExComponent.currencyFormatter},
      { headerName: '총 사용점수', field: 'deductScore', width: 130, cellClass: 'cp right' , valueFormatter: this.agGridExComponent.currencyFormatter},
      { headerName: '가용점수', field: 'availableScore', width: 130, cellClass: 'cp right' , valueFormatter: this.agGridExComponent.currencyFormatter},
    ];

    this.columnDetailDefs = [
      { headerName: '연월', field: 'regMonth', width: 80, cellClass: 'cp center' },
      { headerName: '매출누적', field: 'totalSaleAmt', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter},
      { headerName: '점수누적', field: 'accumulationScore', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '사용점수', field: 'deductScore', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '가용점수', field: 'availableScore', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true
    };

    this.rowSelection = 'single';
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  getRowHeight = () => 380;

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getSocietyHisotryRankList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridDetailReady(params) {
    this.gridDetailApi = params.api;
    this.gridDetailColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridMemoReady(params) {
    this.gridMemoApi = params.api;
    this.gridMemoColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked(event: any) {
    console.log('데이터확인');
    console.log(event.data);

    this.rankSelected = event.data;
    this.getSocietyHisotryMonthList(event.data.society_seq);
  }

  /*******************************************************************************
    설  명 : 동호회 이력 순위 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSocietyHisotryRankList() {
    this.memberService.getSocietyHisotryRankList({...this.search}).then( response => {
      if( response.ResultCode ) {
        this.search_time = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
        this.rankList = response.data;
        // 데이터가 있으면 첫번째 열의 디테일을 뿌린다. 
        if (this.rankList.length > 0 ) {
          // 리스트 첫번째 행 선택
          setTimeout( () => {
            var index = 0;
            this.gridApi.forEachNode( (node) => {
              if( this.selectedSeq == null && index == 0 ) {
                node.setSelected(true);
                this.selectedSeq = node.data.seq;
                this.gridApi.ensureIndexVisible( index, 'top' );

              } else if( this.selectedSeq !== null && node.data.seq == this.selectedSeq ) {
                node.setSelected(true);
                this.selectedSeq = node.data.seq;
                this.gridApi.ensureIndexVisible( index, 'middle' );
              }
              index++;
            });

            this.getSocietyHisotryMonthList(this.rankList[0].society_seq); 
          }, 100);
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });


  }

  /*******************************************************************************
    설  명 : 동호회별 월 이력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getSocietyHisotryMonthList(seq:any) {
      this.memberService.getSocietyHisotryMonthList(seq).then( response => {
        if( response.ResultCode ) {
          this.historyList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
  
  
    }
  

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      rankType: '',
    };

    this.getSocietyHisotryRankList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getSocietyHisotryRankList();
    }
  }

  /*******************************************************************************
    설  명 : 순위타입 변경시 처리 
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  setSearchValue( field: any, value: any ) {
    this.search[ field ] = value;

    this.getSocietyHisotryRankList();
  }

  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }


}
