<div class="modal-header">
  <h4 class="modal-title fb mb0">모델 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <div class="">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">모델정보</h4>
    </div>
    <div class="fr w50p right">

    </div>
    <div class="cb"></div>
  </div>

  <form [formGroup]="form">
    <table class="table table-bordered table-small1 mb0">
      <caption class="none">모델정보</caption>
      <colgroup>
        <col style="width:20%;" />
        <col style="width:80%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="brand_seq"><i class="material-icons cred f11">star</i> 브랜드</label></th>
        <td>
          <inputEx type="text" name="brand_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="검색버튼을 사용하세요" [addOn]="addBrandFunc" addOnText="검색"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="model"><i class="material-icons cred f11">star</i> 모델명</label></th>
        <td>
          <inputEx type="text" name="model" [formGroup]="form" [formErrors]="formErrors" [autofocus]="true"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="p_code">제품코드</label></th>
        <td>
          <inputEx type="text" name="p_code" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="piston">배기량</label></th>
        <td>
          <inputEx type="numeric2" name="piston" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="engine">엔진형식</label></th>
        <td>
          <inputEx type="text" name="engine" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="color">색상</label></th>
        <td>
          <inputEx type="text" name="color" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
    </form>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="form.controls.seq.value !== 0" type="button" class="btn btn-danger btn-small ml5" (click)="setBikeProductDelete()">삭제하기</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="submit()">저장하기</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
    </div>
  </div>
