/*******************************************************************************
  설  명 : 게시판
  작성일 : 2019-10-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { AuthService } from '@app/service/auth.service';
import { BoardService } from '@app/service/board.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  private subscription: Subscription = new Subscription;

  public boardInfo: any = {};
  public memberInfo: any = {};

  public params: any = {
    type: '',
    board_id: 'notice',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: '',
    status: '',
    year: '' , 
    is_approval:''
  };

  public linecnt: any = [];
  public groupTab: any = [];

  public yearList: any = [];
  public boardList = [];

  public totalCount: number;

  public totalInfo: any = {
    total: 0,
    complete: 0,
    process: 0
  };

  public maxSize: any = 10;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService,
    private authService: AuthService,
  ) {

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    this.subscription = this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;
      this.params.year = ( typeof params.year == 'undefined' || params.year == '' ) ? '' : params.year;
      this.params.is_approval = ( typeof params.is_approval == 'undefined' || params.is_approval == '' ) ? '' : params.is_approval;

      // 게시판 정보 가져오기
      this.getBoardInfo( this.params.board_id );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.boardService.getBoardInfoId( board_id ).then( response => {
      if( response.data == null ) {
        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/dashboard'] );
        return false;
      }

      this.boardInfo = response.data;


      if( this.params.type == '' ) {
        switch( this.boardInfo.lvt_default ) {
          case 'A': this.params.type = 'album'; break;
          case 'G': this.params.type = 'gallery'; break;
          case 'C': this.params.type = 'calendar'; break;
          default: case 'L': this.params.type = 'list';
        }
      }

      if( this.params.type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');
      else if( this.params.type == 'album' ) this.linecnt = this.boardInfo.linecnt_album.split('/');
      else if( this.params.type == 'gallery' ) this.linecnt = this.boardInfo.linecnt_gallery.split('/');

      if( this.boardInfo.lvt_group == '1' ) {
        this.boardInfo.group = '전체|' + this.boardInfo.group;
        this.groupTab = this.boardInfo.group.split('|');
      }

      // 검색연도 리스트 가져오기
      this.getBoardYearList();
    });
  }

  /*******************************************************************************
    설  명 : 검색연도 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardYearList() {
    this.boardService.getBoardYearList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.yearList = response.data;

        if( this.boardInfo.board_type == 'qna' ) {
          if( this.yearList.length > 0 && this.params.year == '' ) {
            this.params.year = this.yearList[0].year;
          }
        }

        // 게시판 리스트 정보 가져오기
        this.getBoardList();

      } else {
        this.yearList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.boardService.getBoardList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.boardList = response.data;
        this.boardInfo.total_count = response.total;
        this.totalCount = parseInt( response.total );
      } else {
        this.boardList = [];
        this.boardInfo.total_count = 0;
        this.totalCount = 0;
      }

      // 1:1 문의 경우 통계 가져오기
      if( this.boardInfo.board_type == 'qna' ) {
        this.getBoardQnaInfo();
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 타입 변경
    입력값 : type = L, A, C, G
    리턴값 : 없음
  *******************************************************************************/
  viewChange( type ) {
    this.params.type = type;

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 글 쓰기 권한
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  authWriteCheck(): boolean {
    if( this.boardInfo.auth_write <= this.memberInfo.level ) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 글 저장/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  write() {
    this.router.navigate(
    ['/board/add'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.params.no = page;

    const path = location.pathname;

    if( path == '/board/list' ) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
    }

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadList( group_id ) {
    this.params.group_id = group_id;

    const path = location.pathname;

    if( path == '/board/list' ) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
    }

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 1:1문의 경우 통계 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardQnaInfo() {
    this.boardService.getBoardQnaInfo( this.params ).then( response => {
      if( response.ResultCode ) {
        this.totalInfo = response.data;
      }
    }, error => {
      this.toastrService.error( error, "게시판" );
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 주소 검색 input enter 키 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchService( $event ) {
    if( $event.key == 'Enter' ) {
      this.getBoardList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key, value ) {
    this.params[key] = value;

    this.getBoardList();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.params = {
      type: 'list',
      board_id: this.params.board_id,
      group_id: '전체',
      row: 10,
      no: 1,
      group: 'subject',
      text: '',
      status: '',
      year: ''
    };

    this.getBoardList();
  }
  
}
