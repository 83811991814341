<!-- Modal header -->
<div class="modal-header">
  <h4 class="modal-title bold">결재</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>
<!-- Modal header -->

<!-- Modal body -->
<div class="modal-body">
  <section id="print_area">
    <div class="fr" *ngIf="type == '' && item.approval_type === 'ON'">
      <button type="button" class="btn btn-danger btn-small f15 mr5"
        *ngIf="item.step_index === item.curr_step_index && item.step_status == 'P'"
        (click)="getBtnApproval('2')">
        반려하기
      </button>
      <button type="button" class="btn btn-default btn-small f15 mr5"
        *ngIf="item.curr_mem_no == item.mem_no && item.step_status == 'P' && item.approval_group == 'A'"
        (click)="getBtnApproval('3')">
        전결처리
      </button>
      <button type="button" class="btn btn-primary btn-small f15"
        *ngIf="item.status == '1000' && item.approval_group == 'A' && item.step_index === item.curr_step_index"
        (click)="getBtnApproval('0')">
        결재하기
      </button>
      <button type="button" class="btn btn-primary btn-small f15"
        *ngIf="item.curr_mem_no != item.mem_no && item.status == '1000' && item.step_status == 'P' && item.approval_group == 'A'"
        (click)="getBtnApproval('4')">
        후결처리
      </button>
      <button type="button" class="btn btn-primary btn-small f15"
        *ngIf="item.status == '1000' && item.approval_group == 'G' && item.step_index === item.curr_step_index"
        (click)="getBtnApproval('1')">
        협조하기
      </button>
    </div>
    <img src="/assets/images/logo.png" alt="로고" height="26" *ngIf="type != '2'"/>
    <div class="mb10">
      <div class="company-ceo">바이크마트</div>
      <div class="f15 mt20">
        대구광역시 동구 신천동 152-3번지 (도로명주소 : 대구광역시 동구 장등로 27)&nbsp;&nbsp; /
        &nbsp;&nbsp; 전화 : 1544-5022 &nbsp;&nbsp; / &nbsp;&nbsp; 전송 053-289-3354&nbsp;&nbsp; /
        <br />담당 : {{selectedItem.approval[0].user_dept}}
      </div>
      <div class="mt10" style="border-top:solid 5px #000"></div>
    </div>

    <div class="cb mb10"></div>
    <section id="print-contents">
      <!-- 타이틀 영역 //-->
      <div class="company-ceo" *ngIf="type == '2'">한국에너지기기산업진흥회</div>
      <div class="fl w56p">
        <div class="f32 bold fngex center pt30 center" *ngIf="type != '2' && selectedItem.documentType !== '1001'">
          {{selectedItem.title}}
        </div>

        <div class="document-title" [ngClass]="{approval_mt80: type != '2' && selectedItem.documentType != '1001'}">
          <div class="fl w18p" *ngIf="item.reference != null && type != '2'">
            <span class="f15 bold lh200">참&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조</span>
          </div>
          <div class="fl w82p f14 lh200" *ngIf="item.reference != null && type != '2'">
            {{item.reference}}
          </div>
          <div class="cb mb30" *ngIf="item.reference != null && type != '2'"></div>

          <div class="fl w18p" *ngIf="selectedItem.documentType == '1001'">
            <span class="f15 bold lh200">문&nbsp;서&nbsp;번&nbsp;호</span>
          </div>
          <div class="fl w82p f14 lh200" *ngIf="selectedItem.documentType == '1001'">
            {{selectedItem.documentNumber}}
          </div>
          <div class="cb mb30" *ngIf="selectedItem.documentType == '1001'"></div>

          <div class="fl w18p" *ngIf="selectedItem.documentType == '1001'">
            <span class="f15 bold lh200">기&nbsp;안&nbsp;일&nbsp;자</span>
          </div>
          <div class="fl w82p f14 lh200" *ngIf="selectedItem.documentType == '1001'">
            {{selectedItem.enforcement_date}}
          </div>
          <div class="cb mb30" *ngIf="selectedItem.documentType == '1001'"></div>

          <div class="fl w18p" *ngIf="selectedItem.documentType == '1001'">
            <span class="f15 bold lh200">수&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;신</span>
          </div>
          <div class="fl w82p f14 lh200" *ngIf="selectedItem.documentType == '1001'">
            {{selectedItem.receive}}
          </div>
          <div class="cb mb30" *ngIf="selectedItem.documentType == '1001'"></div>

          <div class="fl w18p" *ngIf="selectedItem.documentType == '1001' && selectedItem.via != ''">
            <span class="f15 bold lh200">(경&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;유)</span>
          </div>
          <div class="fl w82p f14 lh200" *ngIf="selectedItem.documentType == '1001' && selectedItem.via != ''">
            {{selectedItem.via}}
          </div>
          <div class="cb mb30" *ngIf="selectedItem.documentType == '1001' && selectedItem.via != ''"></div>
          <div class="fl w18p">
            <span class="f15 bold lh200">제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</span>
          </div>
          <div class="fl w82p f14 lh200">
            {{selectedItem.subject}}
          </div>
          <div class="cb mb10"></div>
        </div>
      </div>

      <!-- 결재라인 영역 //-->
      <div id="print-approval-step" class="fr" *ngIf="type != '2'">
        <div style="text-align:right;width:100%;">
          <table class="approval-table fr table table-bordered table-color-black mb5">
          <thead>
            <tr>
              <th *ngFor="let member of selectedItem.approval">
                <span>{{member.mem_no == selectedItem.writer ? '기안자' :  member.position_name}}</span>
              </th>
            </tr> 
          </thead>
          <tfoot>
          <tr>
            <td *ngFor="let member of selectedItem.approval" class="center p5 f11" style="height:20px">
              {{member.sign_date}}
            </td>
          </tr>
          </tfoot>
          <tbody>
          <tr>
            <td *ngFor="let member of selectedItem.approval" style="height:60px;width:65px;padding:0px;" class="f12 center cgrey">
              <div class="approval-sign" *ngIf="member.status == '1001' || member.status == '1002'">
                <img [src]="member.sign" style="max-width:49px;max-height:44px;" /> 
              </div>
              <div class="approval-sign" *ngIf="member.status == '1003'">
                <span class="badge badge-danger f12">반려</span>
              </div>
              <div class="approval-sign" *ngIf="member.status == '1005'">
                <span class="badge badge-primary f12">후결</span>
              </div>
              <div class="approval-sign" *ngIf="member.status == '1000' && selectedItem.status == 'C'">
                <span class="badge badge-success f12">전결</span>
              </div>
            </td>
          </tr>
          </tbody>
          </table>
          <div class="cb"></div>
          <table class="approval-table fr table table-bordered table-color-black mb5">
            <thead>
              <tr>
                <th *ngFor="let member of selectedItem.agreement">
                  <span>{{member.position_name}}</span>
                </th>
              </tr> 
            </thead>
            <tfoot>
            <tr>
              <td *ngFor="let member of selectedItem.agreement" class="center p5 f11" style="height:20px">
                {{member.sign_date}}
              </td>
            </tr>
            </tfoot>
            <tbody>
            <tr>
              <td *ngFor="let member of selectedItem.agreement" style="height:60px;width:65px;padding:0px;" class="f12 center cgrey">
                <div class="approval-sign" *ngIf="member.status == '1001'">
                  <img [src]="member.sign" style="max-width:49px;max-height:44px;" /> 
                </div>
                <div class="approval-sign" *ngIf="member.status == '1003'">
                  <span class="badge badge-danger f12">반려</span>
                </div>
                <div class="approval-sign" *ngIf="member.status == '1005'">
                  <span class="badge badge-primary f12">후결</span>
                </div>
                <div class="approval-sign" *ngIf="member.status == '1000' && selectedItem.status == 'c'">
                  <span class="badge badge-success f12">전결</span>
                </div>
              </td>
            </tr>
            </tbody>
            </table>
        </div>
      </div>

      <div class="cb"></div>

      <!-- 내용부분 //-->
      <div id="print-contents-body" class="pt20">
        <div [innerHtml]="selectedItem.contents | safe : 'html'"></div>

        <div class="cb"></div>

        <hr *ngIf="selectedItem.upload > 0" class="db" />
        
        <div class="tl" *ngIf="type != '2'">
          <div class="mt5" style="font-size: 12px;" *ngIf="selectedItem.upload.length > 0">
            <ul class="lstn mb3 pl10 f12">
              <li class="pt3" *ngFor="let file of selectedItem.upload; let i = index">
                {{i+1}} : <a href="{{file.subfolder}}" target="_blank" title="{{file.filename}}">{{file.filename}} ({{getComma(file.filesize)}} KB)</a>
                <button type="button" class="btn btn-default btn-grid2">
                  <i class="material-icons micon-close f12">&#xe5cd;</i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <hr *ngIf="selectedItem.upload > 0" class="db" />

      <hr class="db" *ngIf="selectedItem.approval_type == 'OFF' && type != '2'"/>

      <div class="f18 bold fngex mb10 mt20" *ngIf="selectedItem.approval_type == 'OFF' && type != '2'">
        결재문서 스캔본 업로드
      </div>
      
      <hr class="db" *ngIf="selectedItem.approval_type == 'OFF' && type != '2'" />

      <div class="tl" *ngIf="selectedItem.approval_type == 'OFF' && type != '2'">
        <div class="mt5" style="font-size: 12px;" *ngIf="selectedItem.files.length > 0">
          <ul class="lstn mb3 pl10 f12">
            <li class="pt3" *ngFor="let file of selectedItem.files; let i = index">
              {{i+1}} : <a href="{{file.subfolder}}" target="_blank" title="{{file.filename}}">{{file.filename}} ({{getComma(file.filesize)}} KB)</a>
              <button type="button" class="btn btn-default btn-grid2">
                <i class="material-icons micon-close f12">&#xe5cd;</i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
</div>
<!-- Modal body -->

<!-- Modal footer -->
<div class="modal-footer clearfix">
  <div class="fl w30p left">
    <button type="button" class="btn btn-default btn-small f15" (click)="openComment();">첨언보기</button>
  </div>
  <div class="fr w70p right">
    <button type="button" *ngIf="type == ''" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">닫기</button>
    <button type="button" *ngIf="type != ''" class="btn btn-default btn-small f15" aria-label="Close" (click)="print()">출력</button>
  </div>
</div>
<!-- Modal footer -->