<section>
  <form [formGroup]="form">
    <div class="mt5">
      <div class="fl w50p">
        <h4 class="fb mt5 mb6">구매후기 상세보기</h4>
      </div>
      <div class="fr w50p right">
        <button type="button" class="btn btn-primary btn-small" (click)="setOrderReview()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">회원정보</caption>
    <colgroup>
      <col style="width:80px;" />
      <col />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="">베스트리뷰</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active:form.controls.best_yn.value == 1}" (click)="form.patchValue({best_yn: 1})">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active:form.controls.best_yn.value == 0}" (click)="form.patchValue({best_yn: 0})">아니오</button>
        </div>
      </td>
    </tr>    
    <tr>
      <th scope="row"><label for="comment">내용</label></th>
      <td>
        <inputEx type="textarea" name="comment" [rows]="4" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="">이미지</label></th>
      <td>
        <div class="file-div">
          <ul class="file-ul">
            <li class="fl" *ngFor="let image of form.controls.upload.value; let i = index" (click)="imageURL = image.url">
              <div class="upload-item">
                <div class="upload-image" style="background: #ddd">
                  <img *ngIf="image.thumbnail_url" [src]="image.thumbnail_url" />
                  <img *ngIf="!image.thumbnail_url" [src]="image.url" />
                </div>
                <div class="upload-filename">
                  <a [href]="image.url" target="_blank" [title]="image.origin_filename">{{image.origin_filename}}</a>
                </div>
                <div class="upload-size">
                  ({{image.filesize}} KB)
                  <a class="cp ml5" ><img src="/assets/images/del_ico.png" (click)="setOrderReviewImageDelete(image.seq)" /></a>
                </div>
              </div>
            </li>
            <li class="cb"></li>
          </ul>
        </div>
        <div class="mt5 mb5 cred">* 이미지는 삭제 시 복구가 되지 않으니 주의하세요.</div>
        <div class="big-img">
          <img *ngIf="imageURL" [src]="imageURL" />
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="">이미지링크</label></th>
      <inputEx type="text" name="img_link" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </tr>
    <tr>
      <th scope="row"><label for="">링크이미지</label></th>
      <td>
        <div class="img-link">
          <img *ngIf="form.controls.img_link.value" [src]="form.controls.img_link.value" />
        </div>
      </td>
  </tr>
    </tbody>
    </table>
  </form>
</section>
