<div class="mb5">
  <div class="fl w70p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getStatisticGoal()">
      <i class="material-icons-outlined">search</i>
    </button>

    <select [(ngModel)]="search.yearSelect" class="form-control form-control-small ml5" (change)="selectOption($event)">
      <option *ngFor="let item of years" value="{{item.years}}">{{item.years}}</option>
    </select>


  </div>
  <div class="fr w30p right">
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="a509c01ded9f452591f349ab39c7960f" [btnGroup]="1"></btn-ag-grid-save>
    <button type="button" class="btn btn-primary btn-small ml5" (click)="setStatisticGoal()">데이터 저장</button>
  </div>
  <div class="cb"></div>
</div>

<div style="height:calc(100% - 35px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-alpine"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="dataList"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [pagination]="false"
    [domLayout]="domLayout"
    [suppressRowTransform]="true"
    [stopEditingWhenGridLosesFocus]="true"   
    [frameworkComponents]="frameworkComponents"
    [singleClickEdit]="true"
    
    (gridReady)="onGridReady($event)"
    >
    
  </ag-grid-angular>
</div>