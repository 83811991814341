import { Component, OnInit, Input } from '@angular/core';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ToastrService } from 'ngx-toastr';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = 'xlsx';

@Component({
  selector: 'app-print-excel',
  templateUrl: './print-excel.component.html',
  styleUrls: ['./print-excel.component.scss']
})
export class PrintExcelComponent implements OnInit {

  @Input() dataList: any;
  @Input() filename: string;
  @Input() title: string;

  private excelFileName: string;

  constructor(
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
  }

  excelExport() {
    if( this.dataList.length < 1 ) {
      this.toastrService.error( '엑셀로 출력할 데이터가 없습니다.', '' );
      return false;
    } else {

      this.excelFileName = this.filename + '.' + EXCEL_EXTENSION;

      let title = [ [ this.title ], [] ];
      var worksheet: XLSX.WorkSheet;

      // 제목 추가
      //worksheet = XLSX.utils.sheet_add_aoa( worksheet, title );

      // 색상변경 등은 프로 유료 버전에서만 지원
      //worksheet['A1'] = {v: this.title, t: "s", s: { alignment: 'center', font: {size: 14, bold: true, color: '#FF00FF' }}};

      /*
        s: { alignment: {textRotation: 90 },
        font: {sz: 14, bold: true, color: #FF00FF }
      */

      let index = 0;
      for(let item of this.dataList ) {
        for(let key in item ) {
          if( this.isNumeric( item[key] ) ) {
            this.dataList[index][key] = parseInt(this.dataList[index][key].replace(',',''));
          }
        }

        index++;
      }

      // 데이터 추가
      worksheet = XLSX.utils.sheet_add_json( worksheet, this.dataList, {skipHeader: true, origin: "A1"} );

      var col_D = XLSX.utils.decode_col("D");
      var col_E = XLSX.utils.decode_col("E");
      var fmt = '0';

      this.formatColumn(worksheet, col_D, fmt);
      this.formatColumn(worksheet, col_E, fmt);

      // 워크북 생성
      var workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

      const autoFitColumns = data => {
        const columnWidths = [];

        for(const property in data[0]) {
          columnWidths.push({
            wch: Math.max(
              property ? property.toString().length + 5 : 0,
              ...data.map(obj => obj[property] ? obj[property].toString().length + 5 : 0)
            )
          });
        }

        return columnWidths;
      };

      // 컬럼 사이즈 지정
      worksheet["!cols"] = autoFitColumns(this.dataList);

      /*
      열 머지 처리
      worksheet['!merges'] = [
        {s:{r:0,c:0},e:{r:0,c: header.length } },
        {s:{r:1,c:0},e:{r:1,c: header.length } }
      ];
      */

      // 엑셀 버퍼로 보내기
      var excelBuffer: any = XLSX.write(workbook, { bookType: EXCEL_EXTENSION, type: 'array' });

      // 파일로 저장
      this.saveAsExcelFile(excelBuffer, this.excelFileName);

      return true;
    }
  }

  formatColumn(worksheet, col, fmt) {
    var range = XLSX.utils.decode_range(worksheet['!ref']);
    for(var i = range.s.r + 1; i <= range.e.r; ++i) {
      /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
      var ref = XLSX.utils.encode_cell({r:i, c:col});
      /* if the particular row did not contain data for the column, the cell will not be generated */
      if(!worksheet[ref]) continue;
      /* `.t == "n"` for number cells */
      if(worksheet[ref].t != 'n') continue;
      /* assign the `.z` number format */
      worksheet[ref].z = fmt;
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});

     FileSaver.saveAs( data, fileName );
  }


  isNumeric(num, opt = null){
    // 좌우 trim(공백제거)을 해준다.
    num = String(num).replace(/^\s+|\s+$/g, "");

    // 모든 10진수 (부호 선택, 자릿수구분기호 선택, 소수점 선택)
    if(typeof opt == "undefined" || opt == null || opt == "1") {
      var regex = /^[+\-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;

    // 부호 미사용, 자릿수구분기호 선택, 소수점 선택
    } else if(opt == "2") {
      var regex = /^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;

    // 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
    } else if(opt == "3") {
      var regex = /^[0-9]+(\.[0-9]+)?$/g;

    // only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
    } else {
      var regex = /^[0-9]$/g;
    }

    if( regex.test(num) ) {
      num = num.replace(/,/g, "");
      return isNaN(num) ? false : true;
    } else {
      return false;
    }
  }

}
