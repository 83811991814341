import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { BikeService } from '@app/service/bike.service';

import { BrandFindBikeComponent } from '@components/brand-find-bike/brand-find-bike.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-bike-model-add',
  templateUrl: './bike-model-add.component.html',
  styleUrls: ['./bike-model-add.component.scss']
})
export class BikeModelAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() data: any;

  public form: FormGroup;
  public formErrors = {};

  public title: string;

  public addBrandFunc: any; // 브랜드 검색

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private bikeService: BikeService,
    private toastrService: ToastrService,
  ) {
    this.addBrandFunc = this.brandAdd.bind(this);

    this.form = this.formBuilder.group({
      seq: [0, [Validators.required]],
      brand_seq: ['', [Validators.required]],
      brand_name: ['', [Validators.required]],
      model: ['', [Validators.required]],
      p_code: ['', []],
      piston: ['', []],
      engine: ['', []],
      color: ['', []]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    if( this.data === undefined || this.data === null ) {
      this.title = '등록';
    } else {
      this.title = '수정';

      this.form.patchValue( this.data );
    }
  }

  /*******************************************************************************
    설  명 : 창 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
        this.bikeService.setBikeProductSave(this.form).then( response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage);
          this.activeModal.close(true);
        } else {
          this.toastrService.error(response.ResultMessage);
        };
      });
    }
  }

  /*******************************************************************************
    설  명 : 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikeProductDelete() {
    this.bikeService.setBikeProductDelete(this.form.controls.seq.value).then( response => {
      if (response.ResultCode) {
        this.toastrService.success(response.ResultMessage);
        this.activeModal.close(true);
      } else {
        this.toastrService.error(response.ResultMessage);
      };
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  brandAdd() {
    const modalRef = this.modalService.open(BrandFindBikeComponent, optionsLG);

    modalRef.result.then((result) => {
      if( result ) {
        this.form.patchValue({
          brand_seq: result.data.seq,
          brand_name: result.data.brand_name
        });
      }
    }, (reason) => {
    });
  }

}
