import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '@app/service/employee.service'
import { UtilService } from '@app/service/util.service';
import { WorkArrangement } from '@app/service/work-arrangement.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/service/auth.service';
import { SystemGroupService } from '@app/service/system.group.service';

@Component({
  selector: 'app-workmanagement-workarragement-add',
  templateUrl: './workmanagement-workarragement-add.component.html',
  styleUrls: ['./workmanagement-workarragement-add.component.scss']
})
export class WorkmanagementWorkarragementAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수
  *******************************************************************************/
  @Input() DAY = 'YYYY-MM-DD';
  @Input() SEQ = 0;

  public form: FormGroup;
  public formErrors = {};

  employeeList = [];
  nodeData = [];

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    public employeeService: EmployeeService,
    public formBuilder: FormBuilder,
    public utilService: UtilService,
    public workArrangement: WorkArrangement,
    public toastrService: ToastrService,
    public authService: AuthService,
    public systemGroupService: SystemGroupService,
  ) { }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();
    this._getEmployeeList();
    this.getGroupEmployeeList();
  }

  /*******************************************************************************
    설  명 : 폼생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      mem_no: ['', [Validators.required] ],
      name: [''],
      dept_name: [''],
      position_name: ['']
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 직원 리스트 가져오기
  *******************************************************************************/
  _getEmployeeList() {
    this.employeeService.getEmployeeList().then(response => {
      if(response.ResultCode)
        this.employeeList = response.data.sort((a, b) => a.name.charCodeAt() - b.name.charCodeAt()).map(e => ({...e, title: e.name, value: e.mem_no}));
    })
  }

  /*******************************************************************************
    설  명 : 직원 선택하기
  *******************************************************************************/
  setEmployee(e) {
    const _D = e.target.value;
    const _P = this.employeeList.find(e => e.mem_no == _D);
    this.form.patchValue({
      dept_name: _P.dept_name || '-',
      position_name: _P.position_name || '-'
    })
  }

  /*******************************************************************************
    설  명 : 근무자 선택 제출
  *******************************************************************************/
  submit(mem_no = '') {
    if(!mem_no) mem_no = this.form.value.mem_no;

    this.authService.getLoginInfo.subscribe(e => {

      (
        !this.SEQ
        ? (
          this.workArrangement.postWorker({
            work_date: this.DAY,
            mem_no: mem_no,
            writer: e['mem_no'],
          })
        )
        : (
          this.workArrangement.putWorker({
            seq: this.SEQ,
            mem_no: mem_no,
            writer: e['mem_no'],
          })
        )
      ).then(response => {
        if(response.ResultCode) this.toastrService.success( response.ResultMessage );
        else this.toastrService.error( response.ResultMessage );
        this.activeModal.close(true);
      });

    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeList() {
    this.systemGroupService.getGroupEmployeeList().then( response => {
      if ( response.ResultCode ) this.nodeData = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 조직도 노드 선택
  *******************************************************************************/
  setNode(e) {
    if(e.mem_no) this.submit(e.mem_no);
  }
}
