<section class="search-condition mb5">
  <div class="fl form-inline asub-title">
    
    <input type="text" size="13" readonly="readonly" [value]="form.value.date" class="form-control form-control-small calendar-input ml5" placeholder="기준년월" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate('date', $event)" />
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getEnterAccountList()">
      <i class="material-icons-outlined">search</i>
    </button>

  </div>
  <div class="fr">
  </div>
  <div class="cb"></div>
</section>

<hr class="db mt5 mb5" />

<section class="contents-body" style="height:calc(100% - 40px);">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="18" minSize="5" maxSize="25">
      <div class="mb5">
        <div class="fl">
          <h4 class="f14 pt5 mb0 fb">업체목록</h4>
        </div>
        <div class="fr">
          <input type="text" id="quickFilter" size="20" (input)="onQuickFilterChanged($event)" class="form-control form-control-small" placeholder="업체명/아이디 검색" />
        </div>
        <div class="cb"></div>
      </div>

      <div style="height: calc(100% - 40px);">
        <ag-grid-angular
          #myGrid
  
          style="width:100%; height:100%"
          class="ag-theme-balham"
  
          [columnDefs]="columnDefs_A"
          [defaultColDef]="defaultColDef"
          [rowData]="form.value.rowData_enter"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
  
          (gridReady)="onGridReady_A($event)"
          (rowClicked) = "onRowClicked_A($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="82" minSize="50" maxSize="95">
      
      <div class="mb5">
        <div class="fl form-inline asub-title">
          <span class="pr5">주문 개수</span><input type="text" class="form-control form-control-small" readonly [value] = "statistic.cnt_order.toLocaleString()" />
          <span class="pl10 pr5">품목 개수</span><input type="text" class="form-control form-control-small" readonly [value] = "statistic.cnt_product.toLocaleString()" />
          <span class="pl10 pr5">금액 합계</span><input type="text" class="form-control form-control-small" readonly [value] = "statistic.sum_price.toLocaleString()" />
          <span class="pl10 pr5">배송비 합계</span><input type="text" class="form-control form-control-small" readonly [value] = "statistic.sum_delivery.toLocaleString()" />
        </div>
        <div class="fr">
          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="setExcelOrder()"><span>엑셀다운</span></button>
          <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi_B" gridId="71db6d42301f4b22982133860412950a" [btnGroup]="1"></btn-ag-grid-save>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height: calc(100% - 40px);">
        <ag-grid-angular
          #myGrid
  
          style="width:100%; height:100%"
          class="ag-theme-balham"
  
          [columnDefs]="columnDefs_B"
          [defaultColDef]="defaultColDef"
          [rowData]="form.value.rowData_order"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [getRowHeight]="getRowHeight"
          [rowSelection]="rowSelection"
          [components] = "components"
  
          (gridReady)="onGridReady_B($event)"
          (cellValueChanged)="onCellValueChanged($event)"
          (cellClicked) = "cellClick_B($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>