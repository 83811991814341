import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import * as moment from 'moment';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { CommonService } from '@app/service/common.service';
import { BikeService } from '@app/service/bike.service';

import { MemberFindComponent } from '@components/member-find/member-find.component';
import { BrandFindBikeComponent } from '@components/brand-find-bike/brand-find-bike.component';
import { ShopService } from '@app/service/shop.service';

const URL = config.apiImageUploadUrl;

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-bike-detail-supplies',
  templateUrl: './bike-detail-supplies.component.html',
  styleUrls: ['./bike-detail-supplies.component.scss']
})
export class BikeDetailSuppliesComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public baseURL = config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public productInfo: any = {};
  public memberInfo: any = {};

  public sellGbnList: any = [];
  public bikeStyleList: any = [];
  public orgBikeStyleList: any = [];
  public bikeProductList: any = [];
  public orgBikeProductList: any = [];
  public regionMasterList: any = [];
  public orgRegionDetailList: any = [];
  public regionDetailList: any = [];
  public methodList: any = [
    {value: 'card', title: '카드'},
    {value: 'halbu', title: '할부'},
    {value: 'mixed', title: '복합결제'}
  ];
  public doc1List: any = [
    {id: '양도', text: '양도증명서'},
    {id: '인감', text: '인감증명서'},
  ];
  public doc2List: any = [
    {id: '사용', text: '사용신고필증'},
    {id: '사폐', text: '사용폐지증명서'}
  ];

  public summernoteConfig: any = {...config.summernoteConfig, ...{disableDragAndDrop: true}};

  public params: any = {
    pageNo: 1,
    pageRow: 20,
    totalCount: 0,
    seq: '',
    searchText: '',
    status: '',
    sdate: '',
    edate: '',
    searchTerm: '',
    sellGbn: '',
    bikeStyle: ''
  };

  public bikeProductChangedFunc: any;   // 바이크제품 검색
  public bikeStyleChangedFunc: any;   // 바이크종류 검색
  public addMemberFunc: any;          // 회원 검색
  public addBrandFunc: any;           // 브랜드 검색
  public onChangedUserdoc1Func: any;
  public onChangedUserdoc2Func: any;
  category_code_ChangedFunc: any = this.category_code_Changed.bind(this)

  categoryList = []

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [Validators.required] ],
      // sell_gbn: ['', [Validators.required] ],
      // bike_product_add: [false, [] ],
      // product_seq: ['', [Validators.required] ],
      // p_code: ['', [] ],
      // model: ['', [] ],
      // bike_style: ['', [] ],
      // old_new_gbn: ['', [Validators.required] ],
      title: ['', [Validators.required] ],
      // qty: ['1', [Validators.required] ],
      // brand_seq: ['', [Validators.required] ],
      // brand_name: ['', [] ],
      seller: ['', [Validators.required] ],
      name: ['', [] ],
      // piston: ['', [] ],
      mileage: ['', [] ],
      // manufacture_year: ['', [] ],
      // made_year: [[], [] ],
      // is_tuning: ['0', [] ],
      // tuning_memo: ['', [] ],
      // nego_yn: ['0', [] ],
      // userdoc1: ['', [] ],
      // userdoc2: ['', [] ],
      region_master_seq: ['0', [] ],
      region1: ['', [] ],
      region_seq: ['0', [] ],
      region2: ['', [] ],
      // gps_lat: ['', [] ],
      // gps_lon: ['', [] ],
      hp: ['', [] ],
      // contact_time_s: ['', [] ],
      // contact_time_e: ['', [] ],
      email: ['', [] ],
      trade_helper: ['0', [] ],
      // trade_method: ['', [] ],
      // start_date: ['', [] ],
      // end_date: ['', [] ],
      hp_open_yn: ['0', [] ],
      org_price: ['', [Validators.required] ],
      // // sale_price: ['', [] ],
      // s_price: ['', [] ],
      // e_price: ['', [] ],
      // s_mileage: ['', [] ],
      // e_mileage: ['', [] ],
      // s_year: ['', [] ],
      // e_year: ['', [] ],
      comment: ['', [] ],
      file: ['', [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
      category_code: ['']
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private restful: RestfulService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private bikeService: BikeService,
    private ShopService: ShopService,
  ) {
    this.buildForm();

    this.summernoteConfig.height = 200;

    this.bikeProductChangedFunc = this.bikeProductChanged.bind(this);
    this.bikeStyleChangedFunc = this.bikeStyleChanged.bind(this);
    this.addMemberFunc = this.searchMember.bind(this);
    this.addBrandFunc = this.brandAdd.bind(this);
    this.onChangedUserdoc1Func = this.onChangedUserdoc1.bind(this);
    this.onChangedUserdoc2Func = this.onChangedUserdoc2.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 지역 리스트 가져오기
    this.getMngRegionList();

    // 오토바이 제품 리스트 가져오기
    this.getBikeProductList();

    // 입력 파라미터 처리
    this.activatedRoute.queryParams.subscribe( async params => {
      this.params.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
      this.params.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow;
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '0' : params.seq;
      this.params.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? '' : params.searchField;
      this.params.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.params.searchTerm = ( typeof params.searchTerm == 'undefined' || params.searchTerm == '' ) ? '' : params.searchTerm;
      this.params.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;
      this.params.sellGbn = ( typeof params.sellGbn == 'undefined' ) ? '' : params.sellGbn;
      this.params.bikeStyle = ( typeof params.bikeStyle == 'undefined' ) ? '' : params.bikeStyle;
      this.params.status = ( typeof params.status == 'undefined' || params.status == '' ) ? '' : params.status;

      this.form.patchValue({
        seq: this.params.seq
      });

      if( this.params.seq !== '0' ) {
        // 상세정보 가져오기
        this.getBikeSellDetail();
      }
    }).unsubscribe();

    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const files = this.form.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: this.baseURL + response.thumbnail,
          thumbnail_org: response.thumbnail_org,
          is_default: '0'
        });

        this.form.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };

    this.getHeaderCategoryList()
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      // this.getImageWidth(f)
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  async getImageWidth(file) {
    await this.getBase64(file).then(base64 => {
      const img = new Image();
      img.onload = () => {
        if( 800 > img.width || 800 > img.height ) {
          this.toastrService.warning('800 * 800 보다 작은 이미지는 쇼핑몰에서 정상적으로 보이지 않을 수 있습니다.')
          this.toastrService.warning('대표이미지는 1000 * 1000 사이즈를 강력 권장합니다.')
        }
      }
      img.src = base64.toString();
    })
  }
  async getBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  deleteComma( value: any ) {
    let str = String(value);
    return str.replace(/,/g, '');
  }
  
  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setBikeSellImageDelete( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.bikeService.setBikeSellImageDelete( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.form.controls.upload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 대표 이미지로 설정
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  setBikeSellImageDefault( file ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      this.bikeService.setBikeSellImageDefault( file ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getBikeSellDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 대표 이미지로 설정
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertFilesImage( file: any ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      file.is_default = '1';
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 거래구분
    this.commonService.getCommonListCode('SLG').then( response => {
      if ( response.ResultCode ) {
        this.sellGbnList = response.data;
      } else {
        this.sellGbnList = [];
      }
    });

    // 바이크종류
    this.commonService.getCommonListCode('BKS').then( response => {
      if ( response.ResultCode ) {
        this.orgBikeStyleList = response.data;

        let data: any = [];
        data.push({
          id: '0',
          text: '선택'
        });

        for(let item of this.orgBikeStyleList ) {
          data.push({
            id: item.common_code,
            text: item.common_name
          });
        }

        this.bikeStyleList = data;
      } else {
        this.bikeStyleList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBikeSellDetail() {
    this.bikeService.getBikeSellDetail( this.form.controls.seq.value ).then( response => {
      if( response.ResultCode ) {
        this.productInfo = response.data;

        let userdoc: any = ( typeof response.data.userdoc !== undefined ) ? response.data.userdoc.split('|') : [];
        let userdoc1: any = [];
        let userdoc2: any = [];
        if( userdoc.length > 0 ) {
          userdoc.forEach(row1 => {
            this.doc1List.forEach(row2 => {
              if( row1 == row2.id ) userdoc1.push({id: row2.id, text: row2.text});
            });

            this.doc2List.forEach(row2 => {
              if( row1 == row2.id ) userdoc2.push({id: row2.id, text: row2.text});
            });
          });
        }
        this.productInfo.userdoc1 = userdoc1;
        this.productInfo.userdoc2 = userdoc2;

        this.form.patchValue( this.productInfo );
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 지역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMngRegionList() {
    this.bikeService.getMngRegionList( {} ).then( response => {
      if( response.ResultCode ) {
        this.orgRegionDetailList = response.data_d;

        let tmp1 = [];
        tmp1.push({
          title: '선택',
          value: 0
        });

        for(const item of response.data_m ) {
          tmp1.push({
            title: item.sido_name,
            value: item.seq
          });
        }

        this.regionMasterList = tmp1;

        let tmp2 = [];
        tmp2.push({
          title: '선택',
          value: 0
        });

        for(const item of response.data_d ) {
          tmp2.push({
            title: item.sigungu_name,
            value: item.seq
          });
        }
    
        this.regionDetailList = tmp2;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 오토바이 제품 목록 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBikeProductList() {
    this.bikeService.getBikeProductList( {} ).then( response => {
      if( response.ResultCode ) {
        this.orgBikeProductList = response.data;

        let data: any = [];
        data.push(
          {
            id: '0',
            text: '선택'
          },
          {
            id: 'add',
            text: '직접입력'
          }
        );

        for(let item of this.orgBikeProductList ) {
          data.push({
            id: item.seq,
            text: `${item.bike_style_name ? item.bike_style_name : '-'} | ${item.brand_name ? item.brand_name : '-'} | ${item.model ? item.model : '-'} | ${item.piston ? item.piston + 'cc' : '-'}`
          });
        }

        this.bikeProductList = data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 매물 등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikeSellSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    // let productSeq = this.form.controls.product_seq.value;
    // productSeq = typeof productSeq == 'object' ? productSeq.id : productSeq;

    // if( productSeq == '' || productSeq == 0 ) {
    //   this.toastrService.error('제품을 선택하시기 바랍니다.', '');
    //   return false;
    // }

    // let bikeStyle = this.form.controls.bike_style.value;
    // bikeStyle = typeof bikeStyle == 'object' ? bikeStyle.id : bikeStyle;

    // if( bikeStyle == '' || bikeStyle == 0 ) {
    //   this.toastrService.error('바이크종류를 선택하시기 바랍니다.', '');
    //   return false;
    // }

    if( this.form.valid ) {
      this.bikeService.setBikeSellSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          if( this.params.seq == '0' ) {
            this.params.seq = response.bikeSellSeq;

            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = "reload";

            this.router.navigate(
              ['/bike/sell/detail'],
              { relativeTo: this.activatedRoute,
                queryParams: this.params,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              }
            );
          } else {
            this.form.get('files').setValue([]);
            this.getBikeSellDetail();
          }
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 매물 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikeSellDelete() {
    if( confirm("해당 매물을 삭제하시겠습니까?") ) {
      this.bikeService.setBikeSellDelete( this.params.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          
          this.goList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 리스트로 돌아가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/bike/sell'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 바이크제품 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  bikeProductChanged( e: any ): void {
    if( e.id == 'add' ) {
      this.form.patchValue({
        bike_product_add: true,
        product_seq: '',
        model: '',
        bike_style: { id: '0', text: '선택' },
        brand_seq: '',
        brand_name: '',
        piston: ''
      });

      this.form.controls.model.setValidators( [Validators.required] );
      this.form.controls.model.updateValueAndValidity();
    } else if( e.id == '' || e.id == 0 ) {
      this.form.patchValue({
        bike_product_add: false,
        product_seq: '',
        model: ''
      });

      this.form.controls.model.clearValidators();
      this.form.controls.model.updateValueAndValidity();   
    } else {
      const data = this.orgBikeProductList.filter(item => item.seq === e.id);

      this.form.patchValue({
        bike_product_add: false,
        product_seq: e.id,
        p_code: data[0].p_code,
        model: data[0].model,
        bike_style: {
          id: data[0].bike_style,
          text: data[0].bike_style_name
        },
        brand_seq: data[0].brand_seq,
        brand_name: data[0].brand_name,
        piston: data[0].piston
      });

      this.form.controls.model.clearValidators();
      this.form.controls.model.updateValueAndValidity();   
    }
  }

  /*******************************************************************************
    설  명 : 바이크종류 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  bikeStyleChanged( e: any ): void {
    if( e.id == '' || e.id == 0 ) {
      this.form.patchValue({
        bike_style: ''
      });
    } else {
      this.form.patchValue({
        bike_style: e.id
      });
    }
  }

  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        seller: result.data.mem_no,
        name: result.data.name,
        hp: result.data.hp,
        email: result.data.email
      });

    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 브랜드 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  brandAdd() {
    const modalRef = this.modalService.open(BrandFindBikeComponent, optionsLG);

    modalRef.result.then((result) => {
      if( result ) {
        this.form.patchValue({
          brand_seq: result.data.seq,
          brand_name: result.data.brand_name
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 준비서류 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangedUserdoc1( event: any ): void {
    this.form.patchValue({
      userdoc1: event.map(row => row.id).join('|')
    });
  }
  
  /*******************************************************************************
    설  명 : 준비서류 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangedUserdoc2( event: any ): void {
    this.form.patchValue({
      userdoc2: event.id
    });
  }
  
  /*******************************************************************************
    설  명 : 지역(시/도) 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setChangeRegionMaster() {
    const master = this.regionMasterList.filter(item => item.value === this.form.controls.region_master_seq.value);
    const detail = this.orgRegionDetailList.filter(item => item.master_seq === this.form.controls.region_master_seq.value);

    this.form.patchValue({
      region1: master[0].title,
      region_seq: '',
      region2: ''
    });

    let tmp = [];
    tmp.push({
      title: '선택',
      value: 0
    });

    for( const item of detail ) {
      tmp.push({
        title: item.sigungu_name,
        value: item.seq
      });
    }

    this.regionDetailList = tmp;
  }

  /*******************************************************************************
    설  명 : 지역(구/군) 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setChangeRegionDetail() {
    const detail = this.orgRegionDetailList.filter(item => item.seq === this.form.controls.region_seq.value);

    this.form.patchValue({
      region2: detail[0].sigungu_name
    });
  }
  
  /****************************************************************************
    설명 : 카테고리 가져오기 (shop)
  ****************************************************************************/
  getHeaderCategoryList() {
    this.ShopService.getHeaderCategoryList()
    .then((res: any) => this.categoryList = res.data.category
    .map((item: any) => ({
      text: item.category_name,
      id: item.category_code
    })))
  }
  
  category_code_Changed( e: any ): void {
    if( e.id == '' || e.id == 0 ) {
      this.form.patchValue({
        category_code: ''
      });
    } else {
      this.form.patchValue({
        category_code: e.id
      });
    }
  }
  
}
