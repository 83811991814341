/*******************************************************************************
  설  명 : 헤더
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { IPioneerTreeConfiguration } from '@pioneer-code/pioneer-tree';
import { NotifierService } from 'angular-notifier';
import { Subscription, Observable } from 'rxjs';

import { MemberService } from '@app/service/member.service';
import { SystemGroupService } from '@app/service/system.group.service';
import { FavoriteMenuService } from '@app/service/favorite-menu.service';
import { EmployeeService } from '@app/service/employee.service';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { BasicService } from '@app/service/basic.service';

import { ModalFavoriteMenuComponent } from '@page/layout/modal-favorite-menu/modal-favorite-menu.component';

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('customNotification', { static: true }) customNotificationTmpl;

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  private subscription: Subscription = new Subscription;

  public messageCnt: any = 0;

  public isAlarm: boolean = false; // 알림창 표시
  public isOrganization: boolean = false // 조직도 표시
  public employeePositionList = []; // 사원포함한 조직도
  public selectedMemberInfo = [];
  private notifier: NotifierService;

  public topSearchText: any;

  public menuData: any = [];
  public messageList: any = [];

  /* 노드관련 변수 */
  public nodeData: any = [];

  public selectedTree:any = {};

  public member_info:any = {
    mem_no: '',
    id: '',
    level: '',
    password: '',
    name: '',
    email: '',
    birthdate: null,
    birth_sec: 'S',
    joindate: null,
    leavedate: null,
    dept_seq: '',
    dept_name: '',
    dept_cd: '',
    position_seq: '',
    position_name: '',
    tel: '',
    hp: '',
    extension_number: '',
    emergency_tel: '',
    auth_group_seq: '',
    picture: '',
    picture_url: '/assets/images/no_image.png',
    picture_origin: '',
    badge: '',
    sdate: '',
    edate: ''
  };

  public configuration = {
    "childPropertyName" : "children",
    "sortPropertyName" : "id",
    "collapseAllOnLoad" : false
  } as IPioneerTreeConfiguration;

  /*******************************************************************************
    설  명 : 사용자정보 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  init_member_info() {
    this.member_info = {
      mem_no: '',
      id: '',
      level: '',
      password: '',
      name: '',
      email: '',
      birthdate: null,
      birth_sec: 'S',
      joindate: null,
      leavedate: null,
      dept_seq: '',
      dept_name: '',
      position_seq: '',
      position_name: '',
      dept_cd: '',
      tel: '',
      hp: '',
      extension_number: '',
      emergency_tel: '',
      auth_group_seq: '',
      picture: '',
      picture_url: '/assets/images/no_image.png',
      picture_origin: '',
      badge: '',
      sdate: '',
      edate: ''
    };
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private memberService: MemberService,
    private systemGroupService: SystemGroupService,
    private favoriteMenuService: FavoriteMenuService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private restfulService: RestfulService,
    private authService: AuthService,
    private basicService: BasicService,
    public utilService: UtilService,
    public notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    this.init_member_info();

    // 알림 총 카운트 가져오기
    this.subscription = this.basicService.getMessageCnt.subscribe(data => {
      this.messageCnt = data;

      if( this.messageCnt > 0 ) {
        // if( this.isAlarm === false ) this.openSnackBar();
      } else {
        this.isAlarm = false;
      }      
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getGroupEmployeeList();
    this.getUserMenuList();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 로그아웃
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  logout() {
    this.memberService.logout().then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '로그아웃' );
        this.authService.logout();
        // this.router.navigate(
        //   ['/login'],
        //   {
        //     queryParams: [],
        //     relativeTo: this.activatedRoute,
        //     queryParamsHandling: '', // remove to replace all query params by provided
        //   }
        // );
        window.location.href = '/';
      } else {
        this.toastrService.error( response.ResultMessage, '로그아웃' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 공지 기능
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openSnackBar() {
    this.isAlarm = ! this.isAlarm;

    if( this.isAlarm ) {
      // 알림 데이터 가져오기
      this.basicService.getMessageList().then( response => {
        if ( response.ResultCode ) {
          this.messageList = response.data;

          response.data.map((row: any) => {
            this.notifier.show({
              id: row.seq,
              type: ( row.common_ename || 'default' ),
              message: row.contents,
              template: this.customNotificationTmpl
            });
          });
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    } else {
      this.notifier.hideAll();
    }
  }

  /*******************************************************************************
    설  명 : 공지 제거 버튼 기능
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  hideNotifier(event: any) {
    // 알림 숨김 처리
    this.notifier.hide(event.id);

    // 알림 확인 처리
    this.setMessageView(event.id);
  }

  /*******************************************************************************
    설  명 : 공지 링크 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  linkNotifier(event: any) {
    let message: any = this.messageList.filter((row: any) => row.seq == event.id);
    if( message.length > 0 ) {
      const url = message[0].link;

      if( url ) {
        // window.open("about:blank").location.href = url;
        const urlArray: any = url.split('?');

        const uri: any = urlArray[0];

        const queryParams: any = {};
        if( urlArray[1] ) {
          let params = urlArray[1].split('&');
          params.forEach((item: any) => {
            let data = item.split('=');
            queryParams[ data[0] ] = data[1];
          });
        }

        if( uri == location.pathname ) {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
        }

        this.router.navigate(
          [uri],
          {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: '', // remove to replace all query params by provided
          });

        // 알림 숨김 처리
        this.notifier.hide(message[0].seq);

        // 알림 확인 처리
        this.setMessageView(message[0].seq);

      } else this.toastrService.warning('링크URL이 설정되지 않았습니다.', '알림 바로가기');
    }
  }

  /*******************************************************************************
    설  명 : 알림 확인 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMessageView(seq: any) {
    // 알림 확인 처리
    this.basicService.setMessageView(seq).then( response => {
      if( response.ResultCode ) {
        // this.toastrService.success( response.ResultMessage, '알림 확인' );

        // 남은 알림 카운트 보여주기
        this.basicService.setMessageCnt(response.data);
      } else {
        this.toastrService.error( response.ResultMessage, '알림 확인' );
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeList() {
    this.systemGroupService.getGroupEmployeeList().then( response => {
      if ( response.ResultCode ) {
        this.nodeData = response.data;
      } else {
        this.nodeData = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 노드 선택시 이벤트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {

    this.selectedTree = $event;

    if ( $event.mem_no != null ) {
      this.getEmployeeInfo( this.selectedTree.mem_no );

    } else {
      this.init_member_info();
    }

  }


  /*******************************************************************************
    설  명 :
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getUserMenuList() {
    this.favoriteMenuService.getFavoriteMenuList().then( response => {
      if ( response.ResultCode ) {
        this.menuData = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 직원 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployeeInfo( seq ) {
    this.restfulService.isIndicator = false;

    this.employeeService.getEmployeeInfo( seq ).then( response => {
      this.member_info = response.data;

      this.restfulService.isIndicator = true;

    }).catch( e => {
      this.restfulService.isIndicator = true;
    });
  }

  /*******************************************************************************
    설  명 : 나만의 메뉴
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  favoriteMenu() {
    const modalRef = this.modalService.open(ModalFavoriteMenuComponent, optionsXL);
    modalRef.result.then((result) => {
      this.getUserMenuList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 헤더 검색 - 키보드 입력 후 엔터 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  topSearchEnter( event ) {
    if( event.key == 'Enter' ) {
      this.topSearchOrder();
    }
  }

  /*******************************************************************************
    설  명 : 헤더 검색 - 주문관리 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  topSearchOrder() {
    this.router.navigate(
    ['/order/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: { topSearchText: this.topSearchText },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
}
