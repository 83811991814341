/*******************************************************************************
  설  명 : 거래처관리
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '@app/service/common.service';
import { EtcAccountService } from '@app/service/etc-account.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { EtcaddComponent } from '@page/basic/etc-account/add/etcAccount-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-etc-account',
  templateUrl: './etc-account.component.html',
  styleUrls: ['./etc-account.component.scss']
})
export class EtcAccountComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
    account_type: ''
  };

  public commonType: any = [];
  public etcAccountList: any = [];

  selectedRows: number = 0;
  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  deleteColumnDefs: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private etcAccountService: EtcAccountService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '계정순번', field: 'seq', width: 100, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '계정명', field: 'account_name', width: 300, cellClass: 'cp' },
      { headerName: '계정구분', field: 'account_type', width: 120, cellClass: 'cp', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.account_type == '1000' ) {
            return '<span class="badge badge-success f12">입금</span>';
          } else {
            return '<span class="badge badge-danger f12">출금</span>';
          }
        }
      },
      { headerName: '사용여부', field: 'use_yn', width: 120, cellClass: 'cp', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1' ) {
            return '<span class="badge badge-success f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">미사용</span>';
          }
        }
      },
      { headerName: '등록자', field: 'writer_name', width: 170, cellClass: 'cp' },
      { headerName: '수정자', field: 'changer_name', width: 170, cellClass: 'cp' },
      { headerName: '등록일시', field: 'write_date', width: 200, cellClass: 'cp' },
      { headerName: '수정일시', field: 'change_date', width: 200, cellClass: 'cp ' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    this.getEtcAccountList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.commonService.getCommonListCode('EAC').then( response => {
      if ( response.ResultCode ) {
        this.commonType = response.data;
      } else {
        this.commonType = [];
      }
    });
  }




  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellClicked($event) {
      if( $event.colDef.field !== 'seq' ) {
        this.addAccount( $event.data );
      }
    }



  /*******************************************************************************
    설  명 : 기타 입출금 계정 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    addAccount( item: any ) {
      const modalRef = this.modalService.open(EtcaddComponent, options);

      modalRef.componentInstance.selectedItem = item;

      modalRef.result.then((result) => {
        if( result ) {
          this.getEtcAccountList();
        }
      }, (reason) => {
      });
    }


  /*******************************************************************************
    설  명 : 기타 입출금 계정 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getEtcAccountList() {
      this.etcAccountService.getEtcAccountList( this.search ).then( response => {
        if( response.ResultCode ) {
          this.etcAccountList = response.data;
        } else {
          this.etcAccountList = [];
        }
      });
    }

   /*******************************************************************************
    설  명 : 기타 입출금 계정 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    // delete() {
    //   if( confirm("선택하신 입출금 계정 항목을 삭제하시겠습니까?") ) {
    //     this.deleteColumnDefs = this.gridApi.getSelectedRows();
    //     this.etcAccountService.delAccount( this.deleteColumnDefs ).then( response => {
    //       if( response.ResultCode ) {
    //         this.toastrService.success( response.ResultMessage, '');
    //       } else {
    //         this.toastrService.error( response.ResultMessage, '');
    //       }
    //     });
    //   }
    // }



  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
    searchList( event ) {
      if( event.key == 'Enter' ) {
        this.getEtcAccountList();
      }
    }



  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      account_type: ''
    };

    this.getEtcAccountList();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
    searchBtn( key, value ) {
      this.search[key] = value;
      this.getEtcAccountList();
    }

}
