<!-- <section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체<span class="cnt">{{statistic?.productCnt | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">검색<span class="cnt">{{statistic?.searchCnt | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>
  <div class="cb"></div>
</section> -->

  <div class="fl form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
    <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductMarginList()">
      <i class="material-icons-outlined">search</i>
    </button>

    <!-- <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getProductMarginList()">
      <option value="10">10줄</option>
      <option value="20">20줄</option>
      <option value="30">30줄</option>
      <option value="50">50줄</option>
      <option value="100">100줄</option>
    </select> -->

    <input type="text" [(ngModel)]="search.productSeq" (keypress)="searchList($event)" size="30" class="form-control form-control-small ml5" placeholder="상품번호 검색" />

    <input type="text" [(ngModel)]="search.propertySeq" (keypress)="searchList($event)" size="30" class="form-control form-control-small ml5" placeholder="옵션번호 검색" />

    <!-- <input type="text" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="30" class="form-control form-control-small ml5" placeholder="상품통합번호, 상품번호, 상품명 검색" /> -->

    <!-- <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.display == ''}" (click)="search.display = '';getProductMarginList()">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.display == '1'}" (click)="search.display = '1';getProductMarginList()">판매중</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.display == '0'}" (click)="search.display = '0';getProductMarginList()">대기중</button>
    </div> -->

    <!-- <div class="btn-group">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.soldout == ''}" (click)="search.soldout = '';getProductMarginList()">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.soldout == '1'}" (click)="search.soldout = '1';getProductMarginList()">품절</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.soldout == '0'}" (click)="search.soldout = '0';getProductMarginList()">품절제외</button>
    </div> -->

    <!-- <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.productType == ''}" (click)="search.productType = '';getProductMarginList()">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.productType == '1000'}" (click)="search.productType = '1000';getProductMarginList()">매입</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.productType == '2000'}" (click)="search.productType = '2000';getProductMarginList()">입점</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.productType == '3000'}" (click)="search.productType = '3000';getProductMarginList()">매입 또는 입점</button>
    </div> -->

    <select [(ngModel)]="search.category_seq" class="form-control form-control-small ml5" (change)="getProductMarginList()">
      <option value="">카테고리 전체</option>
      <option *ngFor="let item of categoryList" value="{{item.seq}}">{{item.category_nm}}</option>
    </select>

    <l-select2
      [data]="brandList"
      (ngModelChange)="onBrandChanged($event)"
      [(ngModel)]="search.brand_seq"
      class="form-control form-control-small ml5"
      [options]="{multiple: false}"
      style="width: 200px; padding:0px !important;border:solid 0px #fff !important;"
    ></l-select2>

    <div class="btn-group ml5">
      <!-- <button type="button" class="btn btn-{{search.listType == 'margin' ? 'primary' : 'default'}} btn-small" (click)="search.listType = 'margin';getProductMarginList()">마진 리스트</button> -->
      <button type="button" class="btn btn-{{search.listType ? 'danger' : 'default'}} btn-small" (click)="setListTypeChange()">전체 역마진 리스트 {{search.listType ? '조회 취소' : '조회'}}</button>
    </div>
  </div>

<!-- <div class="fr w30p right">
  <button type="button" class="btn btn-{{search.listType == 'margin' ? 'primary' : 'default'}} btn-small ml5" (click)="setListTypeChange('margin')">마진 리스트</button>
  <button type="button" class="btn btn-{{search.listType == 'reverse' ? 'danger' : 'default'}} btn-small ml5" (click)="setListTypeChange('reverse')">역마진 리스트</button>
  <button type="button" class="btn btn-{{search.listType == 'mileage' ? 'success' : 'default'}} btn-small ml5" (click)="setListTypeChange('mileage')">마일리지 리스트</button>
</div> -->

  <div class="fr right">
    <!-- <button type="button" class="btn btn-primary btn-small ml5" (click)="addPriceUpload()">가격업로드</button> -->
    <ag-grid-excel [gridApi]="gridApi" selected="false" filename="마진관리_상품리스트" title="마진관리_상품리스트"></ag-grid-excel>
    <button type="button" class="btn btn-default btn-small ml5"><input type='file' (change)="fileChanged($event)" accept=".xlsx"></button>
    <button type="button" class="btn btn-primary btn-small ml5" (click)="addPrice()">가격 등록</button>
  </div>

  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 120px);background-color:#fff;">
    <as-split-area size="67" minSize="40" maxSize="80">
    <div class="cb"></div>
    <div class="mt5" style="height:calc(100% - 21px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="marginList"
        [domLayout]="domLayout"
        [singleClickEdit]="true"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [getRowHeight]="getRowHeight"
        [pagination]="false"
        [suppressRowTransform]="true"
        [stopEditingWhenGridLosesFocus]="true"
        [paginationPageSize]="paginationPageSize"
        [components]="components"

        (cellClicked)="onCellClicked($event)"
        (gridReady)="onGridReady($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        >
      </ag-grid-angular>
      <!-- <section class="pagination mt10" *ngIf="pageCount">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="pageCount"
          [maxSize]="20"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </section> -->
    </div>
  </as-split-area>
  <as-split-area size="33" minSize="20" maxSize="60">
    <div class="mb5">
      <div class="fl w50p">
        <h4 class="mt5 mb0 fb">상품가격 히스토리</h4>
      </div>
      <div class="fr w50p right">
        <ag-grid-excel [gridApi]="gridHistoryApi" selected="false" filename="마진관리_상품가격히스토리" title="마진관리_상품가격히스토리"></ag-grid-excel>
        <button type="button" class="btn btn-danger btn-small ml5" (click)="delPrice()">적용예정인 가격 삭제</button>
      </div>
      <div class="cb"></div>
    </div>
    <div style="height:calc(calc(100% - 55px))">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="historycolumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="historyList"
        [stopEditingWhenGridLosesFocus]="true"  
        [pagination]="false"
        [components]="components"
        [domLayout]="domLayout"
        [rowSelection]="'single'"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"

        (gridReady)="onGridHistoryReady($event)"
      >
      </ag-grid-angular>
    </div>
  </as-split-area>
  </as-split>