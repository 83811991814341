<section id="order-change">
  <div class="mt10">
    <div class="fl w30p">
      <div class="fb mt5 mb6">출고지시</div>
    </div>
    <div class="fr">
      <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="setEnterDelivery()" *ngIf="canYouSeeMe()">입점업체 배송처리</button>
      <button type="button" class="btn btn-secondary btn-small f12 ml5 disabled" *ngIf="!canYouSeeMe()" style="cursor: no-drop;">입점업체 배송처리</button>
      <button type="button" class="btn btn-danger btn-small f12 ml5" (click)="outException()">출고지시취소</button>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl" style="height:calc(100% - 35px); width:calc(20% - 10px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="outColumnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="rowData"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onOutGridReady($event)"
      (rowClicked)="onRowClicked($event)"
      >
    </ag-grid-angular>
  </div>

  <div class="fr" style="height:calc(100% - 35px); width:calc(80% - 10px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="rowData1"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowHeight]="getRowHeight"

      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
