/*******************************************************************************
  설  명 : 주문관리 - 오픈마켓 주문관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateStruct, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OpenmarketService } from '@app/service/openmarket.service';

import * as moment from 'moment';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { OrderOpenmarketUploadComponent } from '@page/order/order-openmarket/order-openmarket-upload/order-openmarket-upload.component';
import { OrderOpenmarketDeliveryUploadComponent } from '@page/order/order-openmarket/order-openmarket-delivery-upload/order-openmarket-delivery-upload.component';
import { ProductFindOpenmarketComponent } from '@components/product-find-openmarket/product-find-openmarket.component';
import { ExportExcelComponent } from '@app/components/export-excel/export-excel.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-openmarket',
  templateUrl: './order-openmarket.component.html',
  styleUrls: ['./order-openmarket.component.scss']
})
export class OrderOpenmarketComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public typeList: any = ['지마켓/옥션', '네이버', '스토어팜', '11번가', '쿠팡', '톡스토어'];

  public openmarketList: any = [];
  public printDataList: any = [];

  public fileName: any = 'GeneralDelivery_' + moment().format('YYYY-MM-DD HH:mm');

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    standbyCount: 0,
    naverAllFileTime: ''
  }

  public search: any = {
    pageNo: 1,
    pageRow: 100,
    type: '0',
    deliveryYN: '',
    searchGroup: '',
    searchText: '',
    sdate: '',
    edate: '',
    searchTerm: 'A',
  };

  public keyField: any = [
    // 지마켓/옥션
    [
      {key: 'a9', title: '수령인'},
      {key: 'a5', title: '모델'},
      {key: 'p_serial', title: '마켓상품코드'},
      {key: 'order_serial', title: '마켓주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 네이버
    [
      {key: 'g', title: '수령인'},
      {key: 'p', title: '모델'},
      {key: 'c', title: '상품주문번호'},
      {key: 'd', title: '마켓주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 스토어팜
    [
      {key: 'k', title: '수령인'},
      {key: 'q', title: '모델'},
      {key: 'p', title: '마켓상품코드'},
      {key: 'a', title: '마켓주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 11번가
    [
      {key: 'm', title: '수령인'},
      {key: 'g', title: '모델'},
      {key: 'al', title: '마켓상품코드'},
      {key: 'c', title: '마켓주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 쿠팡
    [
      {key: 'ab', title: '수령인'},
      {key: 'k', title: '모델'},
      {key: 'n', title: '마켓상품코드'},
      {key: 'c', title: '마켓주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 톡스토어
    [
      {key: 'l', title: '수령인'},
      {key: 'e', title: '모델'},
      {key: 'd', title: '마켓상품코드'},
      {key: 'c', title: '마켓주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ]
  ];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  printGridApi: any;
  printGridColumnApi: any;
  printColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  isRowSelectable: any;
  noRowsTemplate: string;
  rowHeight: any;
  rowClassRules: any;

  subscribe: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
    agGridImageComponent: AgGridImageComponent
  };

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
  ];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,    
    private modalService: NgbModal,
    private utilService: UtilService,
    private openmarketService: OpenmarketService,
    private agGridExComponent: AgGridExComponent,
    private toastrService: ToastrService,
    private exportExcelComponent: ExportExcelComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection(params: any) {
          if( params.data.order_seq == '0' && params.data.product_seq != '0' && params.data.property_seq != '0' ) return true;
          else return false;
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      { headerName: '마켓 모델명/색상&사이즈', field: '', width: 225, cellClass: 'cp ag-cell80h-br cell-wrap-text', autoHeight: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.model + '<br />' + params.data.size_color;
        }
      },
      { headerName: '판매단가/할인/배송비', field: '', width: 150, cellClass: 'cp right ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          return getComma(params.data.sale_price) + '<br />' + getComma(params.data.discount_price) + '<br />' + getComma(params.data.delivery_price);
        }
      },
      { headerName: '수량', field: 'num', width: 60, cellClass: 'cp right ag-cell80h',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '수령인', field: 'r_name', width: 105, cellClass: 'cp ag-cell80h' },
      { headerName: '택배사/송장번호', field: '', width: 150, cellClass: 'cp left ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const consignCo = ( params.data.consign_co ) ? params.data.consign_co : '';
          const consignNum = ( params.data.consign_num ) ? params.data.consign_num.replace(/-/g, '') : '';
          const consignDate = ( params.data.consign_date ) ? params.data.consign_date : '';

          return consignCo + '<br />' + consignNum + '<br />' + consignDate;
        }
      },
      { headerName: '마켓주문번호/마켓상품번호', field: '', width: 110, cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.order_serial + '<br />' + params.data.p_serial;
        }
      },
      { headerName: '주문일시', field: 'order_date', width: 150, cellClass: 'cp center ag-cell80h' },
      { headerName: '상품코드(구)', field: 'p_code', width: 95, cellClass: 'cp right ag-cell80h' },
      { headerName: '상품부모번호/상품자식번호', field: '', width: 105, cellClass: 'cp right ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.product_seq + '<br />' + params.data.property_seq;
        }
      },
      { headerName: '바마 색상&사이즈', field: '', width: 120, cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          // const colorName = ( params.data.color_name ) ? params.data.color_name : '-';
          const displayColorName = ( params.data.display_color_name ) ? params.data.display_color_name : '-';
          // const sizeName = ( params.data.size_name ) ? params.data.size_name : '-';
          const displaySizeName = ( params.data.display_size_name ) ? params.data.display_size_name : '-';

          return '<span class="fb">' + displayColorName + ' <br/> ' + displaySizeName + '</span>';
        }
      },
      { headerName: '주문번호', field: 'order_seq', width: 95, cellClass: 'cp right ag-cell80h' },
      { headerName: '처리', field: '', width: 100, cellClass: 'cp center ag-cell80h',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'openmarket',
          setOpenOrderProductFind: this.setOpenOrderProductFind.bind(this),
          setOpenOrderDelete: this.setOpenmarketOrderRowDelete.bind(this),
          setOpenOrderSave: this.setOpenmarketOrderRowSave.bind(this),
        }
      },
    ];

    // ag grid 컬럼 선언
    this.printColumnDefs = [
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    this.rowSelection = 'multiple';
    this.rowHeight = 80;

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.isRowSelectable = function(rowNode) {
      return rowNode.data ? (rowNode.data.order_seq == '0' && rowNode.data.product_seq != '0' && rowNode.data.property_seq != '0' ) : false;
    };

    this.rowClassRules = {
      'status8': function (params) {
        return params.data.order_seq == '0' && params.data.product_seq != '0' && params.data.property_seq != '0';
      },
      'status7': function (params) {
        return params.data.order_seq == '0' && (params.data.product_seq == '0' || params.data.property_seq == '0');
      },
    };

    // 콤마 넣기
    function getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.subscribe = this.activatedRoute.queryParams.subscribe( async params => {
      this.search.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '0' : params.type;

      this.search.searchGroup = this.keyField[ this.search.type ][0].key;

      switch( this.search.type ) {
        case '0':   // 지마켓/옥션
          this.getPrintGmarketAuctionList();
          this.fileName = 'GeneralDelivery_' + moment().format('YYYY-MM-DD hh:mm');
          break;
        case '1':   // 네이버
          this.getPrintNaverList();
          this.fileName = '발송처리';
          break;
        case '2':   // 스토어팜
          this.getPrintFarmList();
          this.fileName = '발송처리';
          break;
        case '3':   // 11번가
          this.getPrint11stList();
          this.fileName = '11st_list_' + moment().format('YYYY-MM-DD');
          break;
        case '4':   // 쿠팡
          this.getPrintCoupangList();
          this.fileName = 'coupang_list_' + moment().format('YYYY-MM-DD');
          break;
        case '5':   // 톡스토어
          this.getPrintTalkList();
          this.fileName = 'talk_list_' + moment().format('YYYY-MM-DD');
          break;
        default:
      }

      this.getOpenmarketList();
    });
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : cell 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field === 'order_seq' ) {
      let seq = $event.data.order_seq;
      const url = '/order/detail?seq=' + seq;
      window.open("about:blank").location.href = url;
    }
  }
  
  /*******************************************************************************
    설  명 : 셀 리사이즈 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onColumnResized(params) {
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 0);
  }

  onColumnVisible(params) {
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 0);
  }
  
  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getOpenmarketList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }

    this.getOpenmarketList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getOpenmarketList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search.pageNo = 1;
    this.search.pageRow = 100;
    this.search.deliveryYN = '';
    this.search.searchGroup = this.keyField[ this.search.type ][0].key;
    this.search.searchText = '';
    this.search.sdate = '';
    this.search.edate = '';
    this.search.searchTerm = 'A';

    this.getOpenmarketList();
  }

  /*******************************************************************************
    설  명 : 배송목록 업로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openOpenmarketDeliveryUpload() {
    const modalRef = this.modalService.open(OrderOpenmarketDeliveryUploadComponent, optionsLG);

    modalRef.componentInstance.type = this.search.type;
    modalRef.componentInstance.exportExcelComponent = this.exportExcelComponent
    modalRef.componentInstance.openmarketTypeList = this.typeList

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 주문목록 업로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openOpenmarketUpload() {
    const modalRef = this.modalService.open(OrderOpenmarketUploadComponent, optionsLG);

    modalRef.componentInstance.type = this.search.type;

    modalRef.result.then((result) => {
      if( result === true ) {
        this.getOpenmarketList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 바마 주문으로 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderAdd() {
    const rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error( '주문을 생성할 내역을 선택하시기 바랍니다.', '');
    } else {
      if( confirm("주문을 생성하시겠습니까?") ) {
        const tmp: any = [];
        rows.forEach(item => {
          tmp.push(item.uid);
        });

        this.openmarketService.setOrderAdd( this.search.type, JSON.stringify(tmp) ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getOpenmarketList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 검색 타입 종류 선택 시
    입력값 : search.type
    리턴값 : 없음
  *******************************************************************************/
  setSearchType( value: any ) {
    this.search.type = value;

    this.router.navigate(
      ['/order/openmarket'],
      {
        relativeTo: this.activatedRoute,
        queryParams: { type: this.search.type },
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }
  
  /*******************************************************************************
    설  명 : 오픈마켓 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOpenmarketList() {
    this.openmarketService.getOpenmarketList(this.search).then( response => {
      if( response.ResultCode ) {
        this.openmarketList = response.data;
        this.statistic = response.statistic;
      } else {
        this.openmarketList = [];
        this.statistic = {
          totalCount: 0,
          searchCount: 0,
          standbyCount: 0,
          naverAllFileTime: ''
        }
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 지마켓/옥션 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintGmarketAuctionList() {
    this.openmarketService.getPrintGmarketAuctionList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 네이버 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintNaverList() {
    this.openmarketService.getPrintNaverList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 스토어팜 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintFarmList() {
    this.openmarketService.getPrintFarmList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 11번가 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrint11stList() {
    this.openmarketService.getPrint11stList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 쿠팡 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintCoupangList() {
    this.openmarketService.getPrintCoupangList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 톡스토어 출력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPrintTalkList() {
    this.openmarketService.getPrintTalkList().then( response => {
      if( response.ResultCode ) {
        this.printDataList = response.data;
      } else {
        this.printDataList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품찾기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenOrderProductFind( params: any ) {
    const modalRef = this.modalService.open(ProductFindOpenmarketComponent, optionsXXL);

    modalRef.componentInstance.rowData = params.data;

    modalRef.result.then((result) => {
      if( typeof result !== 'undefined' ) {
        if( result.length > 1 ) {
          this.toastrService.error( '상품을 하나만 선택하시기 바랍니다.', '');
        } else {
          params.data.product_seq = result[0].product_seq;
          params.data.property_seq = result[0].property_seq;
          params.data.p_code = result[0].product_code;

          // 오픈마켓 타입 파라미터 추가
          params.data.type = this.search.type;

          // 상품정보 수정
          this.openmarketService.setOpenmarketOrderRowSave( params.data ).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');

              params.data.thumbnail_url = response.data.thumbnail_url;
              params.data.filepath = response.data.filepath;
              params.data.display_color_name = response.data.display_color_name;
              params.data.display_size_name = response.data.display_size_name;

              this.gridApi.applyTransaction({ update: [params.data] });
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });
        }
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 내역 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenmarketOrderRowDelete( params: any ) {
    if( confirm("삭제하시겠습니까?") ) {

      // 오픈마켓 타입 파라미터 추가
      params.data.type = this.search.type;

      this.openmarketService.setOpenmarketOrderRowDelete( params.data ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getOpenmarketList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 내역 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenmarketOrderRowSave( params: any ) {
    if( !params.data.p_code || params.data.p_code == '' ) {
      this.toastrService.error( '찾기를 이용해 상품코드를 입력하시기 바랍니다.', '');
    } else {
      if( confirm("수정하시겠습니까?") ) {
        
        // 오픈마켓 타입 파라미터 추가
        params.data.type = this.search.type;

        this.openmarketService.setOpenmarketOrderRowSave( params.data ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getOpenmarketList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

}
