import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonService } from '@app/service/common.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  public currMenu$: Observable<{}>;

  constructor(
    public commonService: CommonService
  ) {
    this.currMenu$ = this.commonService.isCurrMenu;
  }

  ngOnInit(): void {
  }

}
