import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class RefundService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 환불관리 리스트 가져오기
  getRefundList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'refund',
        version: '1.0',
        action: 'getRefundList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  // 환불관리 마스터 리스트 가져오기
  getRefundMasterList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'refund',
        version: '1.0',
        action: 'getRefundMasterList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  // 환불관리 상세 리스트 가져오기
  getRefundDetailList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'refund',
        version: '1.0',
        action: 'getRefundDetailList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  // 환불관리 저장하기
  setRefundSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'refund',
        version: '1.0',
        action: 'setRefundSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
