import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-member-email-detail',
  templateUrl: './member-email-detail.component.html',
  styleUrls: ['./member-email-detail.component.scss']
})
export class MemberEmailDetailComponent implements OnInit {

  public data: any = {
    subject: '',
    contents: '',
    toEmail: '',
    fromEmail: '',
    writer: '',
    write_date: ''
  };

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

}
