<section id="bamaCoupon">
    <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
      <as-split-area size="40" minSize="35" maxSize="50">
        <div class="mb5">
            <div class="fl w70p form-inline">
              <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
                <i class="material-icons-outlined">refresh</i>
              </button>
              <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getBamaCouponList()">
                <i class="material-icons-outlined">search</i>
              </button>
    
              <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="쿠폰명 검색" />
            </div>
            <div class="fr w30p right">
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="6a3bfefb83b040ae9ac6c45ce2fcda37" [btnGroup]="1"></btn-ag-grid-save>
    
              <button type="button" title="쿠폰추가" class="btn btn-primary btn-small-icon ml5" (click)="setBamaCouponAdd()">
                <i class="material-icons-outlined">add</i>
              </button>
            </div>
            <div class="cb"></div>
          </div>
    
          <div style="height:calc(100% - 35px)">
            <ag-grid-angular
              #myGrid
    
              style="width: 100%;height:100%;"
              class="ag-theme-balham"
    
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              [rowData]="bamaCouponList"
              [pagination]="false"
              [domLayout]="domLayout"
              [overlayNoRowsTemplate]="noRowsTemplate"
              [frameworkComponents]="frameworkComponents"
              [rowSelection]="rowSelection"
    
              (rowClicked)="onRowClicked($event)"
              (gridReady)="onGridReady($event)"
              >
            </ag-grid-angular>
          </div>
    
      </as-split-area>  
      <as-split-area size="60" minSize="50" maxSize="65" style="height:100%;">
        <div class="">
            <div class="fl w50p">
              <h4 class="fb mt5 mb6">쿠폰정보</h4>
            </div>
            <div class="fr w50p right">
              <button *ngIf="form.controls.seq.value!=null" type="button" class="btn btn-danger btn-small mr5" (click)="setBamaCouponDelete()">삭제하기</button>
              <button type="button" class="btn btn-primary btn-small" (click)="setBamaCouponSave()">저장하기</button>
            </div>
            <div class="cb"></div>
        </div>
        <form [formGroup]="form">

            <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    
            <table class="table table-bordered table-small mb0">
            <caption class="none">쿠폰정보</caption>
            <colgroup>
              <col style="width:15%;" />
              <col style="width:35%;" />
              <col style="width:15%;" />
              <col style="width:35%;" />
            </colgroup>
    
            <tbody>
            <tr>
              <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="coupon_name">쿠폰명</label></th>
              <td>
                <inputEx type="text" name="coupon_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              </td>
              <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="coupon_kind">쿠폰종류</label></th>
              <td>
                <inputEx type="select" name="coupon_kind" [formGroup]="form" [formErrors]="formErrors" [data]="couponKindList" (change)="changeCouponKind()"></inputEx>
              </td>
            </tr>
            <tr>
                <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="give_sdate">쿠폰지급시작일</label></th>
                <td><inputEx type="date" name="give_sdate" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
                <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="give_edate">쿠폰지급종료일</label></th>
                <td><inputEx type="date" name="give_edate" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
            </tr>
            <tr>
                <th scope="row"><label for="give_type">지급적용구분</label></th>
                <td>
                    <inputEx type="select" name="give_type" [formGroup]="form" [formErrors]="formErrors" [data]="giveTypeList" (change)="changeGiveType()"></inputEx>
                </td>
                <th scope="row"><label for="sale_type">판매적용구분</label></th>
                <td>
                    <inputEx type="select" name="sale_type" [formGroup]="form" [formErrors]="formErrors" [data]="saleTypeList" (change)="changeSaleType()"></inputEx>
                </td>
            </tr>
            <tr>
                <th scope="row"><label for="apply_type">할인적용</label></th>
                <td>
                    <div class="btn-group" style="float:left">
                        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.apply_type.value=='RATE'}" (click)="form.get('apply_type').setValue('RATE')">%</button>
                        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.apply_type.value=='AMOUNT'}" (click)="form.get('apply_type').setValue('AMOUNT')">금액</button>
                    </div>
                    <inputEx type="text" style="float:left;margin-left:10px;" name="apply_number" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
                <th scope="row"><label for="sale_type">유효개월수</label></th>
                <td>
                    <inputEx type="text" name="valid_month" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
            </tr>
            <tr>
                <th scope="row"><label for="use_yn">사용여부</label></th>
                <td>
                    <div class="btn-group">
                        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='1'}" (click)="form.get('use_yn').setValue('1')">사용</button>
                        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='0'}" (click)="form.get('use_yn').setValue('0')">미사용</button>
                    </div>
                </td>
                <th scope="row"><label for=""></label></th>
                <td>
                </td>
            </tr>
            </tbody>
            </table>
    
        </form>
        <div class="w50p">
          <h4 class="fb mt5 mb6">쿠폰상세정보</h4>
        </div>  
        <mat-tab-group [selectedIndex]="activeMasterTab" (selectedIndexChange)="onMasterTabChange($event)" style="height: calc(100% - 228px);">
            <mat-tab label="지급적용상품설정">
                <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;height: 100%;">
                    <as-split-area size="40" minSize="35" maxSize="50">
                      <mat-tab-group [selectedIndex]="activeDetailTab" (selectedIndexChange)="onDetailTabChange($event)">
                        <mat-tab label="카테고리">
                          <div class="mt5">
                            <div class="fl w70p">
                              <input type="text" id="categoryQuickFilter" size="30" (input)="onCategoryQuickFilterChanged($event)" class="form-control form-control-small ml5" placeholder="모든 필드 검색 필터링" />
                            </div>
                            <div class="cb"></div>
                          </div>
                  
                          <div class="mt5" style="height:calc(100% - 40px)">
                            <ag-grid-angular
                              #myGrid
                  
                              style="width: 100%;height:100%;"
                              class="ag-theme-balham"
                  
                              [columnDefs]="columnDefsCategory"
                              [defaultColDef]="defaultColDef"
                              [rowData]="categoryList"
                              [pagination]="false"
                              [domLayout]="domLayout"
                              [overlayNoRowsTemplate]="noRowsTemplate"
                              [frameworkComponents]="frameworkComponents"
                              [rowSelection]="rowSelection"
                              [getRowNodeId]="getRowNodeId"
                  
                              (gridReady)="onGridReadyCategory($event)"
                              (rowClicked)="onCategoryGiveGridRowClicked($event)"
                              >
                            </ag-grid-angular>
                          </div>
                        </mat-tab>
                        <mat-tab label="브랜드">
                          <div class="mt5">
                            <div class="fl w70p">
                              <input type="text" id="brandQuickFilter" size="30" (input)="onBrandQuickFilterChanged($event)" class="form-control form-control-small ml5" placeholder="모든 필드 검색 필터링" />
                            </div>
                            <div class="cb"></div>
                          </div>
                  
                          <div class="mt5" style="height:calc(100% - 50px)">
                            <ag-grid-angular
                              #myGrid
                  
                              style="width: 100%;height:100%;"
                              class="ag-theme-balham"
                  
                              [columnDefs]="columnDefsBrand"
                              [defaultColDef]="defaultColDef"
                              [rowData]="brandList"
                              [pagination]="false"
                              [domLayout]="domLayout"
                              [overlayNoRowsTemplate]="noRowsTemplate"
                              [frameworkComponents]="frameworkComponents"
                              [rowSelection]="rowSelection"
                              [getRowNodeId]="getRowNodeId"
                  
                              (gridReady)="onGridReadyBrand($event)"
                              (rowClicked)="onBrandGiveGridRowClicked($event)"
                              >
                            </ag-grid-angular>
                          </div>
                        </mat-tab>
                        <mat-tab label="전체상품">
                        </mat-tab>
                      </mat-tab-group>
                    </as-split-area>
                    <as-split-area size="60" minSize="55" maxSize="75">
                      <div class="right mt5">
                        <div class="fl form-inline">
                  
                          <input type="text" size="35" [(ngModel)]="params.searchText" (keypress)="setSearchList($event)" class="form-control form-control-small ml5" placeholder="상품번호, 상품명, 카테고리, 브랜드 검색" />
                  
                          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="getCouponGiveSearchProductList()">검색</button>
                          <button type="button" class="btn btn-danger btn-small ml5 fr" (click)="setBamaCouponGiveProductDelete()">제품설정 삭제</button>
                          <button type="button" class="btn btn-primary btn-small ml5 fr" (click)="setBamaCouponGiveProductSave()">제품설정 저장</button>                  
                        </div>
                        <div class="cb"></div>
                      </div>
                  
                      <div class="mt5" style="height:calc(100% - 50px)">
                        <ag-grid-angular
                          #myGrid
                  
                          style="width: 100%;height:100%;"
                          class="ag-theme-balham"
                  
                          [columnDefs]="columnDefsProduct"
                          [defaultColDef]="defaultColDef"
                          [rowData]="productList"
                          [pagination]="false"
                          [domLayout]="domLayout"
                          [overlayNoRowsTemplate]="noRowsTemplate"
                          [frameworkComponents]="frameworkComponents"
                          [rowSelection]="rowSelectionProduct"
                          [rowDragManaged]="true"
                          [enableMultiRowDragging]="true"
                          [animateRows]="true"
                          [components]="components"
                          [rowHeight]="getRowHeight"
                          [singleClickEdit]="true"
                          [stopEditingWhenGridLosesFocus]="true"
                          [rowClassRules]="rowClassRules"
                  
                          (gridReady)="onGridReadyProduct($event)"
                          (cellClicked)="onCellClicked($event)"
                          >
                        </ag-grid-angular>
                      </div>
                    </as-split-area>
                </as-split>
            </mat-tab>
            <mat-tab label="판매적용상품설정">
                <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;height: 100%;">
                    <as-split-area size="40" minSize="35" maxSize="50">
                      <mat-tab-group [selectedIndex]="activeDetailTab" (selectedIndexChange)="onDetailTabChange($event)">
                        <mat-tab label="카테고리">
                          <div class="mt5">
                            <div class="fl w70p">
                              <input type="text" id="categoryQuickFilter" size="30" (input)="onCategoryQuickFilterChanged1($event)" class="form-control form-control-small ml5" placeholder="모든 필드 검색 필터링" />
                            </div>
                            <div class="cb"></div>
                          </div>
                  
                          <div class="mt5" style="height:calc(100% - 50px)">
                            <ag-grid-angular
                              #myGrid
                  
                              style="width: 100%;height:100%;"
                              class="ag-theme-balham"
                  
                              [columnDefs]="columnDefsCategory1"
                              [defaultColDef]="defaultColDef"
                              [rowData]="categoryList1"
                              [pagination]="false"
                              [domLayout]="domLayout"
                              [overlayNoRowsTemplate]="noRowsTemplate"
                              [frameworkComponents]="frameworkComponents"
                              [rowSelection]="rowSelection"
                              [getRowNodeId]="getRowNodeId"
                  
                              (gridReady)="onGridReadyCategory1($event)"
                              (rowClicked)="onCategorySaleGridRowClicked($event)"
                              >
                            </ag-grid-angular>
                          </div>
                        </mat-tab>
                        <mat-tab label="브랜드">
                          <div class="mt5">
                            <div class="fl w70p">
                              <input type="text" id="brandQuickFilter" size="30" (input)="onBrandQuickFilterChanged1($event)" class="form-control form-control-small ml5" placeholder="모든 필드 검색 필터링" />
                            </div>
                            <div class="cb"></div>
                          </div>
                  
                          <div class="mt5" style="height:calc(100% - 40px)">
                            <ag-grid-angular
                              #myGrid
                  
                              style="width: 100%;height:100%;"
                              class="ag-theme-balham"
                  
                              [columnDefs]="columnDefsBrand1"
                              [defaultColDef]="defaultColDef"
                              [rowData]="brandList1"
                              [pagination]="false"
                              [domLayout]="domLayout"
                              [overlayNoRowsTemplate]="noRowsTemplate"
                              [frameworkComponents]="frameworkComponents"
                              [rowSelection]="rowSelection"
                              [getRowNodeId]="getRowNodeId"
                  
                              (gridReady)="onGridReadyBrand1($event)"
                              (rowClicked)="onBrandSaleGridRowClicked($event)"
                              >
                            </ag-grid-angular>
                          </div>
                        </mat-tab>
                        <mat-tab label="전체상품">
                        </mat-tab>
                      </mat-tab-group>
                    </as-split-area>
                    <as-split-area size="60" minSize="55" maxSize="75">
                      <div class="right mt5">
                        <div class="fl form-inline">
                  
                          <input type="text" size="35" [(ngModel)]="params.searchText" (keypress)="setSearchList($event)" class="form-control form-control-small ml5" placeholder="상품번호, 상품명, 카테고리, 브랜드 검색" />
                  
                          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="getCouponSaleSearchProductList()">검색</button>
                          <button type="button" class="btn btn-danger btn-small ml5 fr" (click)="setBamaCouponSaleProductDelete()">제품설정 삭제</button>
                          <button type="button" class="btn btn-primary btn-small ml5 fr" (click)="setBamaCouponSaleProductSave()">제품설정 저장</button>                  
                  
                        </div>
                        <div class="cb"></div>
                      </div>
                  
                      <div class="mt5" style="height:calc(100% - 40px)">
                        <ag-grid-angular
                          #myGrid
                  
                          style="width: 100%;height:100%;"
                          class="ag-theme-balham"
                  
                          [columnDefs]="columnDefsProduct1"
                          [defaultColDef]="defaultColDef"
                          [rowData]="productList1"
                          [pagination]="false"
                          [domLayout]="domLayout"
                          [overlayNoRowsTemplate]="noRowsTemplate"
                          [frameworkComponents]="frameworkComponents"
                          [rowSelection]="rowSelectionProduct1"
                          [rowDragManaged]="true"
                          [enableMultiRowDragging]="true"
                          [animateRows]="true"
                          [components]="components"
                          [rowHeight]="getRowHeight"
                          [singleClickEdit]="true"
                          [stopEditingWhenGridLosesFocus]="true"
                          [rowClassRules]="rowClassRules"
                  
                          (gridReady)="onGridReadyProduct1($event)"
                          (cellClicked)="onCellClicked($event)"
                          >
                        </ag-grid-angular>
                      </div>
                    </as-split-area>
                </as-split>
            </mat-tab>
        </mat-tab-group>          
      </as-split-area>  
    </as-split>

</section>    
