/*******************************************************************************
  설  명 : 상품 채널별월별판매실적
  작성일 : 2022-07-13
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import { ProductService } from '@app/service/product.service';

@Component({
  selector: 'app-product-sales-status-channel',
  templateUrl: './product-sales-status-channel.component.html',
  styleUrls: ['./product-sales-status-channel.component.scss']
})
export class ProductSalesStatusChannelComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public productSalesStatusList: any = [];

  public params: any = {
    pageNo: 1,
    pageRow: 1000,
    seq: ''
  };

  public fileName: any = '';

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  grid: any;
  components: any;
  rowHeight: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private agGridExComponent: AgGridExComponent,
    private productService: ProductService,
  ) {

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '년월', field: 'order_Month', width: 80, cellClass: 'cp center ag-cell80h' },
      { headerName: '대구매장', field: 'daegu_sale', width: 120, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.daegu_sale);
        }
      },
      { headerName: '서울매장', field: 'seoul_sale', width: 120, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.seoul_sale);
        }
      },
      { headerName: '옥션', field: 'AUCTION_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.AUCTION_sale);
        }
      },
      { headerName: '지마켓', field: 'GMARKET_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.GMARKET_sale);
        }
      },
      { headerName: '11번가', field: 'st11_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.st11_sale);
        }
      },
      { headerName: '스토어팜', field: 'FARM_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.FARM_sale);
        }
      },
      { headerName: '톡스토어', field: 'TALK_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.TALK_sale);
        }
      },
      { headerName: '쿠팡', field: 'COUPON_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.COUPON_sale);
        }
      },
      { headerName: '로켓배송', field: 'ROCKET_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.ROCKET_sale);
        }
      },
      { headerName: '현대,롯데,배민', field: 'HDGF_sale', width: 110, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.HDGF_sale);
        }
      },
      { headerName: '협력점', field: 'ali_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.ali_sale);
        }
      },
      { headerName: '프렌차이즈', field: 'fra_sale', width: 100, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.fra_sale);
        }
      },
      { headerName: 'Npay', field: 'NAVER_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.NAVER_sale);
        }
      },
      { headerName: '바마몰', field: 'mall_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.mall_sale);
        }
      },
      { headerName: '수출', field: 'IMPORT_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.IMPORT_sale);
        }
      },
      { headerName: '소계', field: 'TOTAL_sale', width: 90, cellClass: 'cp right ag-cell80h',
        valueGetter(params: any) {
          return getComma(params.data.TOTAL_sale);
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      selectCellEditor: this.agGridExComponent.getSelectEditor()
    };

    // 콤마 넣기
    function getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }  
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( async params => {
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '0' : params.seq;

      this.fileName = `${this.params.seq}_판매현황_${moment().format('YYYYMMDD')}`;

      if( this.params.seq != 0 && this.params.seq != '' ) {
        this.getProductSalesChannelList();
      }

    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  
  /*******************************************************************************
    설  명 : 판매현황 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductSalesChannelList() {
    this.productService.getProductSalesChannelList( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.productSalesStatusList = response.data;
      } else {
        this.productSalesStatusList = [];
      }
    });
  }

}
