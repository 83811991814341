import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class StockIn {

  constructor(
    private restful: RestfulService,
  ) {
  }

  // 발주관리 get
  getStockIn( params ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'stockIn',
      version: '1.0',
      action: 'getStockIn'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 발주관리 post
  setStockIn( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockIn',
      version: '1.0',
      action: 'setStockIn'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 발주관리 post
  setStockInPurchase( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockIn',
      version: '1.0',
      action: 'setStockInPurchase'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 기초관리 - 정산거래처관리 - 최근발주입고내역
  getPurchaseStockHistory( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockIn',
      version: '1.0',
      action: 'getPurchaseStockHistory'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }


}
