<section id="member-company">

  <div class="mb5">
    <div class="fl w50p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getTNAStatisticsList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select [(ngModel)]="search.year" class="form-control form-control-small ml5" (change)="setYear($event)">
        <option *ngFor="let item of yearList" [value]="item.year" [selected]="true">{{item.year}}</option>
      </select>
      
      <select [(ngModel)]="search.mem_no" class="form-control form-control-small ml5 dept" (change)="setMember($event)">
        <option value="">사원 선택</option>
        <option *ngFor="let item of employeeList" [value]="item.mem_no">{{item.name}}</option>
      </select>
    </div>
    <div class="fr">
      <ag-grid-excel [gridApi]="gridApi" selected="true" filename="근태현황집계표" title="근태현황집계표"></ag-grid-excel>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="근태현황집계표" title="근태현황집계표"></ag-grid-excel>
    </div>
    <div class="cb"></div>
  </div>

  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 130px);background-color:#fff;">
    <as-split-area size="60" minSize="40" maxSize="80">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="TNAStatisticsList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (gridReady)="onGridReady($event)"
        >
      </ag-grid-angular>
    </as-split-area>

  </as-split>
</section>
