<form [formGroup]="form">

  <section>
    <div class="mb5">
      <div class="fl fb pt5">매물 기본정보</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-danger btn-small ml10" (click)="setBikeSellDelete()">삭제하기</button>
        <button type="button" class="btn btn-primary btn-small ml10" (click)="setBikeSellSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">매물 기본정보</caption>
    <colgroup>
      <col style="width:12%;" />
      <col style="width:38%;" />
      <col style="width:12%;" />
      <col style="width:38%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="seq">매물순번</label></th>
      <td>
        <inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="자동부여"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="sell_gbn">거래구분</label></th>
      <td>
        <div class="btn-group">
          <button *ngFor="let row of sellGbnList" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sell_gbn.value==row.common_code}" (click)="form.get('sell_gbn').setValue(row.common_code)">{{row.common_name}}</button>
        </div>
        <inputEx type="hidden" name="sell_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="product_seq">제품선택</label></th>
      <td>
        <inputEx type="select2" name="product_seq" [formGroup]="form" [formErrors]="formErrors" [data]="bikeProductList" [small]="true" [options]="{multiple: false}" [onChanged]="bikeProductChangedFunc" [readonly]="form.controls.bike_product_add.value"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="old_new_gbn">신차여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.old_new_gbn.value=='NEW'}" (click)="form.get('old_new_gbn').setValue('NEW')">신차</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.old_new_gbn.value=='OLD'}" (click)="form.get('old_new_gbn').setValue('OLD')">중고차</button>
        </div>
        <inputEx type="hidden" name="old_new_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr *ngIf="form.controls.bike_product_add.value">
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="model">제품명</label></th>
      <td colspan="3">
        <inputEx type="text" name="model" [formGroup]="form" [formErrors]="formErrors" placeholder="새로 추가할 제품명을 입력하세요"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="title">매물제목</label></th>
      <td colspan="3">
        <inputEx type="text" name="title" [formGroup]="form" [formErrors]="formErrors" placeholder="핵심문구만 간단히, 법률에 위반되는 금칙어 입력제한"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="bike_style">바이크종류</label></th>
      <td>
        <inputEx type="select2" name="bike_style" [formGroup]="form" [formErrors]="formErrors" [data]="bikeStyleList" [small]="true" [options]="{multiple: false}" [onChanged]="bikeStyleChangedFunc"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="qty">수량</label></th>
      <td>
        <inputEx type="text" name="qty" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="brand_name">브랜드</label></th>
      <td>
        <inputEx type="text" name="brand_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="검색버튼을 사용하세요" [addOn]="addBrandFunc" addOnText="검색"></inputEx>
        <inputEx type="hidden" name="brand_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">판매자명</label></th>
      <td>
        <inputEx name="name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" placeholder="검색버튼을 사용하세요" [addOn]="addMemberFunc" addOnText="회원검색"></inputEx>
        <inputEx type="hidden" name="seller" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="org_price">최초등록가격</label></th>
      <td>
        <inputEx type="numeric2" name="org_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="trade_method">거래 시 결제수단</label></th>
      <td>
        <inputEx type="select" name="trade_method" [formGroup]="form" [formErrors]="formErrors" [data]="methodList"></inputEx>
      </td>
      <!-- <th scope="row"><label for="sale_price">판매확정가격</label></th>
      <td>
        <inputEx type="numeric2" name="sale_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td> -->
    </tr>
    <tr>
      <th scope="row"><label for="hp">판매자 핸드폰번호</label></th>
      <td>
        <inputEx type="text" name="hp" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="email">판매자 이메일</label></th>
      <td>
        <inputEx type="text" name="email" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="mileage">주행거리</label></th>
      <td>
        <inputEx type="text" name="mileage" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="piston">배기량</label></th>
      <td>
        <inputEx type="text" name="piston" [formGroup]="form" [formErrors]="formErrors" placeholder=""></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="made_year">제조년도</label></th>
      <td>
        <inputEx type="text" name="made_year" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="manufacture_year">제조사기준연식</label></th>
      <td>
        <inputEx type="text" name="manufacture_year" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="nego_yn">가격절충가능여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.nego_yn.value=='1'}" (click)="form.get('nego_yn').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.nego_yn.value=='0'}" (click)="form.get('nego_yn').setValue('0')">아니오</button>
        </div>
        <inputEx type="hidden" name="nego_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="trade_helper">결제도우미거래여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.trade_helper.value=='1'}" (click)="form.get('trade_helper').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.trade_helper.value=='0'}" (click)="form.get('trade_helper').setValue('0')">아니오</button>
        </div>
        <inputEx type="hidden" name="trade_helper" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="region_master_seq">지역(시/도)</label></th>
      <td>
        <inputEx type="select" name="region_master_seq" [formGroup]="form" [formErrors]="formErrors" [data]="regionMasterList" (change)="setChangeRegionMaster()"></inputEx>
      </td>
      <th scope="row"><label for="region_seq">지역(구/군)</label></th>
      <td>
        <inputEx type="select" name="region_seq" [formGroup]="form" [formErrors]="formErrors" [data]="regionDetailList" (change)="setChangeRegionDetail()"></inputEx>
      </td>
    </tr>
    <!-- <tr>
      <th scope="row"><label for="gps_lat">위치 위도값</label></th>
      <td>
        <inputEx type="text" name="gps_lat" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="gps_lon">위치 경도값</label></th>
      <td>
        <inputEx type="text" name="gps_lon" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr> -->
    <tr>
      <th scope="row"><label for="contact_time_s">연락가능시작시간</label></th>
      <td>
        <inputEx type="time" name="contact_time_s" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="contact_time_e">연락가능종료시간</label></th>
      <td>
        <inputEx type="time" name="contact_time_e" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="userdoc1">거래 시 준비서류1</label></th>
      <td>
        <inputEx type="select2" name="userdoc1" [formGroup]="form" [formErrors]="formErrors" [data]="doc1List" [options]="{multiple: true}" [onChanged]="onChangedUserdoc1Func"></inputEx>
      </td>
      <th scope="row"><label for="userdoc2">거래 시 준비서류2</label></th>
      <td>
        <inputEx type="select2" name="userdoc2" [formGroup]="form" [formErrors]="formErrors" [data]="doc2List" [options]="{multiple: false}" [onChanged]="onChangedUserdoc2Func"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="is_tuning">튜닝여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_tuning.value=='1'}" (click)="form.get('is_tuning').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_tuning.value=='0'}" (click)="form.get('is_tuning').setValue('0')">아니오</button>
        </div>
        <inputEx type="hidden" name="is_tuning" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="hp_open_yn">구매자에게 핸드폰번호 공개여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.hp_open_yn.value=='1'}" (click)="form.get('hp_open_yn').setValue('1')">예</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.hp_open_yn.value=='0'}" (click)="form.get('hp_open_yn').setValue('0')">아니오</button>
        </div>
        <inputEx type="hidden" name="hp_open_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr *ngIf="form.controls.is_tuning.value == '1'">
      <th scope="row"><label for="tuning_memo">튜닝설명</label></th>
      <td colspan="3">
        <inputEx type="textarea" name="tuning_memo" [rows]="5" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </section>

  <section *ngIf="form.controls.sell_gbn.value == '2000' || form.controls.sell_gbn.value == '3000'" class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">
        경매/역경매 정보
      </div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setBikeSellSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">경매/역경매 정보</caption>
    <colgroup>
      <col style="width:12%;" />
      <col style="width:38%;" />
      <col style="width:12%;" />
      <col style="width:38%;" />
    </colgroup>

    <tbody>
      <tr>
        <th scope="row"><label for="start_date">경매/역경매 시작일시</label></th>
        <td>
          <!-- <inputEx type="date" name="start_date" [formGroup]="form" [formErrors]="formErrors"></inputEx> -->
          <dateTimePicker name="start_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker>
        </td>
        <th scope="row"><label for="end_date">경매/역경매 종료일시</label></th>
        <td>
          <!-- <inputEx type="date" name="end_date" [formGroup]="form" [formErrors]="formErrors"></inputEx> -->
          <dateTimePicker name="end_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker>
        </td>
      </tr>
      <ng-container *ngIf="form.controls.sell_gbn.value == '3000'">
      <tr>
        <th scope="row"><label for="s_price">역경매희망시작가</label></th>
        <td>
          <inputEx type="numeric2" name="s_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="e_price">역경매희망종료가</label></th>
        <td>
          <inputEx type="numeric2" name="e_price" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="s_mileage">역경매용 주행거리 시작값</label></th>
        <td>
          <inputEx type="text" name="s_mileage" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="e_mileage">역경매용 주행거리 종료값</label></th>
        <td>
          <inputEx type="text" name="e_mileage" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="s_year">역경매용 연식 시작값</label></th>
        <td>
          <inputEx type="text" name="s_year" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="e_year">역경매용 연식 종료값</label></th>
        <td>
          <inputEx type="text" name="e_year" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      </ng-container>
    </tbody>
    </table>
  </section>

  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">매물 이미지</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setBikeSellSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small">
    <caption class="hidden">매물 이미지정보</caption>

    <colgroup>
      <col style="width:12%" />
      <col style="width:88%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row" class="active" rowspan="2">
        <label for="file">이미지</label>
      </th>
      <td>
        <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
        <div class="progress mt5 mb5">
          <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
        </div>
        <div class="mt5">
          <!-- ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 50MB 제한 -->
        </div>
        <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
          <ul class="file-ul">
            <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
              <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                <div *ngIf="checkImage(getExt(file.origin_filename))" class="upload-image" (click)="setBikeSellImageDefault(file)">
                  <img [src]="file.url" [alt]="file.origin_filename" />
                </div>
                <div *ngIf="!checkImage(getExt(file.origin_filename))" class="upload-image1">{{getExt(file.origin_filename)}}</div>
                <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin_filename}}">{{file.origin_filename}}</a></div>
                <div class="upload-size">
                  ({{getComma(file.filesize)}} KB)
                  <a class="cp ml5" (click)="setBikeSellImageDelete( file, i )"><img src="/assets/images/del_ico.png" /></a>
                </div>
              </div>
            </li>
            <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
              <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
                <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertFilesImage(file)">
                  <img [src]="file.url" [alt]="file.origin" />
                </div>
                <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                <div class="upload-size">
                  ({{getComma(file.size)}} KB)
                  <a class="cp ml5" (click)="deleteFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                </div>
              </div>
            </li>
            <li class="cb"></li>
          </ul>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <span class="cred f16">※ 빨간테두리가 있는 이미지가 대표이미지이며, 썸네일 이미지로 표시됩니다.<br/>※ 이미지 클릭 시 대표이미지로 설정할 수 있습니다.</span>
      </td>
    </tr>
    </tbody>
    </table>
  </section>

  <section class="mt15">
    <div class="mb5">
      <div class="fl fb pt5">매물 상세정보</div>
      <div class="fr">
        <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setBikeSellSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">매물 상세정보</caption>
    <colgroup>
      <col style="width:12%;" />
      <col style="width:88%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="comment">매물 상세설명</label></th>
      <td>
        <inputEx type="summernote" name="comment" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"></th>
      <td><span class="cred f16">※ 에디터에 이미지를 첨부하려면 위에서 이미지 업로드 한 후에 첨부하려는 이미지를 드래그 해서 에디터 원하는곳에 놓아주세요.</span></td>
    </tr>
    </tbody>
    </table>
  </section>

</form>
