<section id="customer-memo">
  <div class="mt5">
    <div class="fl w50p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getCustomerMemoList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="내용 검색" />
    </div>
    <div class="fr w50p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="b20dc8323d084c81ad4fe6568d6f8348" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-danger btn-small ml5" (click)="setCustomerMemoDelete()">메모삭제</button>
    </div>
    <div class="cb"></div>
  </div>

  <hr class="db mt5 mb5" />

  <form [formGroup]="form">
    <div class="mt5">
      <inputEx type="text" name="memo" [formGroup]="form" [formErrors]="formErrors" [addOn]="setCustomerMemoSaveFunc" addOnText="메모등록" placeholder="내용"></inputEx>
    </div>
  </form>

  <div class="mt5" style="height:calc(100% - 73px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="customerMemoList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (rowClicked)="onRowClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
