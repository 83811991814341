<section id="customer-ingoods">
  <div class="mt5">
    <div class="fl w50p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="reset()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getPurchaseStockHistory()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" class="form-control form-control-small ml5" placeholder="상품명" [(ngModel)] = "search.product_name"/>
    </div>
    <div class="fr w50p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="9033419694664b28b78c77f2704c63dd" [btnGroup]="1"></btn-ag-grid-save>
    </div>
    <div class="cb"></div>
  </div>

  <div class="mt5" style="height:calc(100% - 40px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="form.value.rowData"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
      >
    </ag-grid-angular>
  </div>
</section>