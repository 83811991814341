<form name="form" id="form" form-error-focus novalidate>
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title bold">결재 첨언</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
  <!-- Modal header -->

  <!-- Modal body -->
  <div class="modal-body">
    <ul>
      <li *ngFor="let item of selectedItem.approval" [ngClass]="{line: item.step_index <= selectedItem.step_index && item.add_note != '' || selectedItem.approval_type == 'OFF'}">
        <div *ngIf="item.step_index <= selectedItem.step_index && item.add_note != '' || selectedItem.approval_type == 'OFF'">
          <div>
            <span class="f15 badge badge-primary mr5">{{selectedItem.writer == item.mem_no ? '기안' : '결재'}}</span>
            <span class="f12 mr5">{{item.user_dept}}</span>
          </div>
          <div class="mt10" *ngIf="item.add_note != ''">
            {{item.add_note}}
        </div>
      </div>
      </li>
      <li *ngFor="let item of selectedItem.agreement" [ngClass]="{line: item.step_index <= selectedItem.step_index && item.add_note != '' || selectedItem.approval_type == 'OFF'}">
        <div *ngIf="item.step_index <= selectedItem.step_index && item.add_note != '' || selectedItem.approval_type == 'OFF'">
          <div>
            <span class="f15 badge badge-success mr5">협조</span>
            <span class="f12 mr5">{{item.user_dept}}</span>
          </div>
          <div class="mt10" *ngIf="item.add_note != ''">
            {{item.add_note}}
          </div>
        </div>
      </li>
    </ul>
  </div>
  <!-- Modal body -->

  <!-- Modal footer -->
  <div class="modal-footer clearfix">
    <button type="button" class="btn btn-default btn-small f15 fr" aria-label="Close" (click)="activeModal.dismiss()">닫기</button>
  </div>
  <!-- Modal footer -->
</form>