/*******************************************************************************
  설  명 : 협력점 관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal,  NgbModalOptions, NgbInputDatepicker  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import * as moment from 'moment';


import { MemberService } from '@app/service/member.service';
import { CommonService } from '@app/service/common.service';
import { UtilService } from '@app/service/util.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { ExportExcelComponent } from '@components/export-excel/export-excel.component';

import { MemberInfoComponent } from '@page/member/member-info/member-info.component';


const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};


@Component({
  selector: 'app-member-sis',
  templateUrl: './member-sis.component.html',
  styleUrls: ['./member-sis.component.scss']
})


export class MemberSisComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchField: 'b.id',
    searchText: '',
    state: '',
    status: '' , 
    grade:'' , 
    sdate: this.utilService.getDate(moment().subtract(90, 'day')),
    edate: this.utilService.getDate(''),
    seq:''
  };

  public statistic: any = {
    total_count: 0,
    search_count: 0,
    company_count: 0,
    vendor_count: 0,
    bom_count: 0,
    agency_count: 0
  }

  public memberSisList: any = [];
  public orderList: any = [];
  public stateList: any = [];

  public memberNo: any;
  public activeTab: any = 0;

  public excelSearchDate: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM')
  };

  private selectedSeq: any = null;
  private selectedShopName: any = '';

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiOrder: any;
  gridColumnApiOrder: any;
  columnDefsOrder: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /* 협력점 최근 매출 차트 */
  public chartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public chartLabels: Label[] = [];
  public chartType: ChartType = 'bar';
  public chartLegend = true;
  public chartPlugins = [pluginDataLabels];
  public chartColors: any[] = [
    { backgroundColor: "#86C7F3" },
    { backgroundColor: "#FFA1B5" },
  ];

  public chartData: ChartDataSets[] = [
    { data: [], fill: false, label: '매출' },
    { data: [], fill: false, label: '마진' },
  ];

  /* 협력점 월별 판매마진율 추이 차트 */
  public chartOptionsTab2: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public chartLabelsTab2: Label[] = [];
  public chartTypeTab2: ChartType = 'line';
  public chartLegendTab2 = true;
  public chartPluginsTab2 = [pluginDataLabels];

  public chartDataTab2: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], fill: false, label: '마진율' },
  ];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private utilService: UtilService,
    private agGridExComponent: AgGridExComponent,
    private exportExcelComponent: ExportExcelComponent,
    private memberService: MemberService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '수정', field: 'button', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return '<button type="button" class="btn btn-danger btn-small" style="line-height:0.8">수정</button>';
        }
      },
      { headerName: '회원유형', field: 'grade', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.grade == '0004' )
            return '<span class="f12">협력점</span>';
          else if( params.data.grade == '0007')
            return '<span class="f12">프렌차이즈</span>';
        }
      },
      { headerName: '계약상태', field: 'status', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '1' )
            return '<span class="badge badge-success f11">계약중</span>';
          else
            return '<span class="badge badge-secondary f11">계약만료</span>';
        }
      },
      { headerName: '아이디', field: 'id', width: 100, cellClass: 'cp' },
      { headerName: '이름', field: 'name', width: 150, cellClass: 'cp' },
      { headerName: '회사명', field: 'shop_name', width: 200, cellClass: 'cp' },
      { headerName: '할인요율', field: 'discount_rate', width: 100, cellClass: 'cp' },
      { headerName: '나이', field: 'age', width: 60, cellClass: 'cp center' },
      { headerName: '지역', field: 'state_name', width: 100, cellClass: 'cp center' },
      { headerName: '가입일자', field: 'joindate', width: 140, cellClass: 'cp center' },
      { headerName: '계약일자', field: 'ali_date', width: 100, cellClass: 'cp center' },
      { headerName: '만료일자', field: 'ali_end_date', width: 100, cellClass: 'cp center' },
      { headerName: '전체 매출', field: 'buy_sum', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: moment().format('DD') + '일 매출', field: 'today_sales', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: moment().format('MM') + '월 매출', field: 'month_sales', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: moment().format('MM') + '월 마진', field: 'month_margin', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: moment().format('MM') + '월 마진율(%)', field: 'month_margin_rate', width: 120, cellClass: 'cp right' },
      { headerName: moment().format('YYYY') + '년 매출', field: 'year_sales', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: moment().format('YYYY') + '년 마진', field: 'year_margin', width: 120, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: moment().format('YYYY') + '년 마진율(%)', field: 'year_margin_rate', width: 140, cellClass: 'cp right' },
    ];

    // ag grid 컬럼 선언
    this.columnDefsOrder = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center ag-cell35h', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '주문번호', field: 'order_seq', width: 100, cellClass: 'cp ag-cell35h' },
      { headerName: '구매일자', field: 'order_date', width: 100, cellClass: 'cp ag-cell35h' },
      { headerName: '수신자', field: 'r_name', width: 150, cellClass: 'cp ag-cell35h' },
      { headerName: '상품명', field: 'product_name', width: 400, cellClass: 'cp ag-cell35h' },
      { headerName: '색상', field: 'display_color_name', width: 100, cellClass: 'cp ag-cell35h' },
      { headerName: '사이즈', field: 'display_sixe_name', width: 100, cellClass: 'cp ag-cell35h' },
      { headerName: '소비자단가', field: 'sale_price', width: 100, cellClass: 'cp right ag-cell35h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '업체공급가', field: 'amt', width: 100, cellClass: 'cp right ag-cell35h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '수량', field: 'qty', width: 80, cellClass: 'cp right ag-cell35h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '합계', field: 'total_amt', width: 100, cellClass: 'cp right ag-cell35h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '배송비', field: 'displayDeliveryCharge', width: 100, cellClass: 'cp right ag-cell35h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '마일리지', field: 'displayUseMileage', width: 100, cellClass: 'cp right ag-cell35h', valueFormatter: this.agGridExComponent.currencyFormatter },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 35;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 협력점 리스트 가져오기
    this.getMemberSisList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 시/도
    this.commonService.getCommonListCode('ADDR').then( response => {
      if ( response.ResultCode ) {
        this.stateList = response.data;
      } else {
        this.stateList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 협력점 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberSisList() {
    this.memberService.getMemberSisList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.memberSisList = response.data;
        this.statistic = response.statistic;

        setTimeout( () => {
          var index = 0;

          this.gridApi.forEachNode( (node) => {
            if( this.selectedSeq == null && index == 0 ) {
              node.setSelected(true);
              this.selectedSeq = node.data.seq;
              this.search.seq =  this.selectedSeq ; 
              this.selectedShopName = node.data.shop_name;
              this.gridApi.ensureIndexVisible( index, 'top' );

            } else if( this.selectedSeq !== null && node.data.seq == this.selectedSeq ) {
              node.setSelected(true);
              this.selectedSeq = node.data.seq;
              this.search.seq =  this.selectedSeq ;
              this.selectedShopName = node.data.shop_name;
              this.gridApi.ensureIndexVisible( index, 'middle' );
            }
            index++;
          });

          if( this.memberSisList.length > 0 ) this.getMemberSisDetail( this.memberSisList[0].seq );
        }, 100);

      } else {
        this.toastrService.error( response.ResultMessage, '협력점 리스트');
      }
    }, error => {
      this.toastrService.error( error, '협력점 리스트');
    });
  }

  /*******************************************************************************
    설  명 : 협력점 상세정보 가져오기
    입력값 : seq = 협력점 코드
    리턴값 : 없음
  *******************************************************************************/
  getMemberSisDetail( seq: any ) {
    this.memberService.getMemberSisDetail( seq ).then( response => {
      if( response.ResultCode ) {
        this.orderList = this.processOrderList(response.data);


        this.chartData[0].data = [];
        this.chartData[1].data = [];
        this.chartLabels = [];
        this.chartLabelsTab2 = [];

        this.chartDataTab2[0].data = [];

        response.sales.map( data => {
          this.chartData[0].data.push( data.total_amt );
          this.chartData[1].data.push( data.margin_amt );

          this.chartDataTab2[0].data.push( data.margin_rate );

          this.chartLabels.push( data.yyyymm + '월');
          this.chartLabelsTab2.push( data.yyyymm + '월');
        });
      } else {
        this.toastrService.error( response.ResultMessage, '협력점 상세');
      }
    }, error => {
      this.toastrService.error( error, '협력점 상세');
    });
  }

  /*******************************************************************************
    설  명 : orderList에서 주문번호가 같으면 배송비를 한번만 표시되게 배열을 가공한다.  
    입력값 : 업슴
    리턴값 : 없음
  *******************************************************************************/
    processOrderList(orderList: any[]): any[] {
      return orderList.map((item, index, array) => {
        // 그룹 내 첫 번째 항목 확인
        const isFirstInGroup = index === 0 || item.order_seq !== array[index - 1].order_seq;
    
        // 배송비 표시 여부 처리
        return {
          ...item,
          displayDeliveryCharge: isFirstInGroup ? item.delivery_charge : '0', // 중복 제거
          displayUseMileage: isFirstInGroup ? item.use_mileage : '0', // 중복 제거
        };
      });
    }
  /*******************************************************************************
    설  명 : 협력점 상세정보 검색조건에 따라 가져오기
    입력값 : 업슴
    리턴값 : 없음
  *******************************************************************************/
    getMemberSisDetailResearch() {
      
      this.memberService.getMemberSisDetailResearch( this.search).then( response => {
        if( response.ResultCode ) {
          this.orderList = this.processOrderList(response.data);
  
          this.chartData[0].data = [];
          this.chartData[1].data = [];
          this.chartLabels = [];
          this.chartLabelsTab2 = [];
  
          this.chartDataTab2[0].data = [];
  
          response.sales.map( data => {
            this.chartData[0].data.push( data.total_amt );
            this.chartData[1].data.push( data.margin_amt );
  
            this.chartDataTab2[0].data.push( data.margin_rate );
  
            this.chartLabels.push( data.yyyymm + '월');
            this.chartLabelsTab2.push( data.yyyymm + '월');
          });
        } else {
          this.toastrService.error( response.ResultMessage, '협력점 상세');
        }
      }, error => {
        this.toastrService.error( error, '협력점 상세');
      });
      
    }
 
  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getToday( obj: NgbInputDatepicker, check ) {
      if( check == 'sdate' ) {
        this.search.sdate = this.utilService.getDate( '' );
        obj.close();
      } else if( check == 'edate')  {
        this.search.edate = this.utilService.getDate( '' );
        obj.close();
      } else if( check == 'paydate') {
        this.search.paydate = this.utilService.getDate( '' );
        obj.close();
      }
    }    

  /*******************************************************************************
    설  명 :구매상품 엑셀 다운로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/

  setExcelDown() {

    const formatDate = (date) => {
      if (!date) return ''; // 빈 값 처리
      return `${date.year}-${date.month}-${date.day}`;
    };
   
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
      <caption style="font-size:14pt;"><strong>${this.selectedShopName} 주문내역서 (${formatDate(this.search.sdate)} ~ ${formatDate(this.search.edate)})</strong></caption>        <colgroup>
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:150px;" />
          <col style="width:350px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>주문번호</td>
          <td>구매일자</td>
          <td>수신자</td>
          <td>상품명</td>
          <td>색상</td>
          <td>사이즈</td>
          <td>소비자단가</td>
          <td>업체공급가</td>
          <td>수량</td>
          <td>합계</td>
          <td>배송비</td>
          <td>마일리지</td>
        </tr>
    `]

    let data = this.orderList

    data.forEach(element => {

     contents.push(`
        <tr>
          <td style="text-align:center;">${element.order_seq}</td>
          <td style="text-align:center;">${element.order_date}</td>
          <td style="text-align:center;">${element.r_name}</td>
          <td style="text-align:left;">${element.product_name}</td>
          <td style="text-align:left;">${element.display_color_name}</td>
          <td style="text-align:left;">${element.display_size_name}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.sale_amt}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.amt}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.qty}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.total_amt}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.displayDeliveryCharge}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.displayUseMileage}</td>
        </tr>
        </tbody>
      `)
    })
    contents.push(`</table>`)

    let today = new Date();

    const fileName = `bikemart_orderList_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`

    this.printExcel(fileName, contents.join(''))
    
  }
    /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    printExcel(fileName, contents) {
      const exportContents = `
        <html xmlns:x="urn:schemas-microsoft-com:office:excel">
        <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
        <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
        <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
        </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
        <body>${contents}</body></html>
      `
      const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
      const elem = document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `${fileName}.xls`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    }


  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.selectedSeq = $event.data.seq;
    this.search.seq = this.selectedSeq;
    this.selectedShopName = $event.data.shop_name;

    // 협력점 상세정보 가져오기
    if( $event.colDef.field == 'button' ) {
      this.addMember( $event.data.mem_no );
    } else {
      this.getMemberSisDetail( $event.data.seq );
    }
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.addMember( $event.data.mem_no );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyOrder(params) {
    this.gridApiOrder = params.api;
    this.gridColumnApiOrder = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedOrder(event: any) {
    if( event.colDef.field === 'order_seq' ) {
      const url = '/order/detail?seq=' + event.data.order_seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 일반회원 전환
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberNormalChange() {
    const nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '전환할 회원을 선택하세요.', '' );
      return false;
    }

    const data: any = [];
    nodes.forEach(item => {
      data.push(item.data);
    });

    if ( confirm('일반회원으로 전환하시겠습니까?') ) {
      this.memberService.setMemberNormalChange( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getMemberSisList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 회원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addMember( memNo: number ) {
    const modalRef = this.modalService.open(MemberInfoComponent, optionsLG);

    modalRef.componentInstance.memberNo = memNo;
    modalRef.componentInstance.memberGrade = '0004';

    modalRef.result.then((result) => {
      // 수정 / 추가 시
      if( result ) {
        this.getMemberSisList();

      // 삭제 시
      } else if( result == false ) {
        this.selectedSeq = null;
        this.selectedShopName = '';
        this.getMemberSisList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchField: 'b.id',
      searchText: '',
      state: '',
      status: ''
    };

    this.getMemberSisList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberSisList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getMemberSisList();
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabSetValue( value: any ) {
    this.activeTab = value;
  }
  
  /*******************************************************************************
    설  명 : 월별 출고 상품목록 엑셀 다운로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  excelDownOutOrderProductMonth() {
    if( !this.excelSearchDate.year || this.excelSearchDate.year == 0 ) {
      this.toastrService.warning( '연도를 입력하시기 바랍니다.', '엑셀 다운로드');
    } else if( this.excelSearchDate.year.toString().length != 4 ) {
      this.toastrService.warning( '연도는 4자리로 입력하시기 바랍니다.', '엑셀 다운로드');
    } else {
      this.memberService.getMemberSisOutOrderProductMonth( this.selectedSeq, this.excelSearchDate.year ).then( response => {
        if( response.ResultCode ) {

          if( response.data.length > 0 ) {
            const data: any = [];
            const outOrderProductList: any = response.data.map((obj: any, i: number) => ({
                주문일자: obj.order_date,
                상품번호: obj.product_seq,
                상품코드: obj.product_code,
                상품명: obj.product_name,
                // 옵션코드: obj.product_property_seq,
                수량: parseInt(obj.qty),
                판매단가: parseInt(obj.unit_price),
                판매금액: parseInt(obj.amt),
                할인금액: parseInt(obj.discount_amt),
                총금액: parseInt(obj.total_amt),
                구매단가: parseInt(obj.buy_price),
                마일리지: parseInt(obj.mileage),
                주문상태: obj.order_status_name
              })
            );          

            // 주문일자 년월 추출하기
            let month: any = [...new Set(outOrderProductList.map((obj: any) => obj['주문일자']))];
            if( month.length > 0 ) {
              month.map((value: any, index: number) => {
                data[value] = [];
                // 년월별로 데이터 분리하기
                outOrderProductList.filter((row: any) => {
                  if( row['주문일자'] === value ) {
                    data[value].push(row);
                  }
                });
              });
            }

            // 엑셀 다운로드 처리
            this.exportExcelComponent.exportAsExcelFileMultipleSheet(data, `${this.selectedShopName} ${this.excelSearchDate.year}년도 월별 출고 상품목록`, month);

            this.toastrService.success( response.ResultMessage, '엑셀 다운로드');
          } else {
            this.toastrService.info( '다운로드 할 데이터가 없습니다.', '엑셀 다운로드');
          }
        } else {
          this.toastrService.error( response.ResultMessage, '엑셀 다운로드');
        }
      });
    }
  }
  
}
