/*******************************************************************************
 설  명 : 권한그룹 추가/수정
 작성일 : 2019-09-27
 작성자 : 송영석
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { ApiResponse } from '@app/service/api-response';

import { PosService } from '@app/service/pos.service';
import { EmployeeService } from '@app/service/employee.service';

@Component({
  selector: 'app-pos-auth-add',
  templateUrl: './auth-add.component.html',
  styleUrls: ['./auth-add.component.scss']
})
export class PosAuthAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public selectedItem: any = {
    pos_id: '',
    pos_auth_id: '',
    auth_group_id: '',
    auth_group_name: '',
    mem_no: '',
    mem_name: '',
    use_yn: '',
  };

  public title: string = '추가';
  public form: FormGroup;

  posAuthGroupList: any = [];
  departmentList: any = [];
  memberList: any = [];
  formErrors = {};
  errorResponse: ApiResponse;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      pos_auth_id: ["", []],
      pos_id: ["", [Validators.required]],
      auth_group_id: ["", [Validators.required]],
      mem_no: ["", [Validators.required]],
      use_yn: ["", [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private posService: PosService,
    private employeeService: EmployeeService,
    private toastrService: ToastrService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    if( this.selectedItem.pos_id == '' ) {
      this.title = '추가';
    } else {
      this.title = '수정';
    }
    this.getPosAuthGroupList();
    this.getDepartmentList();
    this.getMemberList('');
    this.form.patchValue( this.selectedItem );
  }

  /*******************************************************************************
    설  명 : 권한 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPosAuthGroupList() {
    this.posService.getPosAuthGroupList().then( response => {

      if( response.ResultCode ) {
        this.posAuthGroupList = response.data;
      } else {
        this.posAuthGroupList = [];
      }
    });
  }

  /* 부서 리스트 */
  getDepartmentList() {
    this.employeeService.getDepartmentList().then( response => {

      if( response.ResultCode ) {
        this.departmentList = response.data.filter( item =>
          item.dept_cd.length > 3
      );
      } else {
        this.departmentList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 사용자 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberList(dept_seq: string) {
    this.employeeService.getMemberList().then( response => {

      if( response.ResultCode ) {
        if (dept_seq === '') {
          this.memberList = response.data;
        } else {
          this.memberList = response.data.filter( item => item.dept_seq === dept_seq);
        }
      } else {
        this.memberList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid){
      this.posService.savePosAuthMember( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success('정상적으로 등록되었습니다.', '');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      })
      .catch(response => {
        this.toastrService.error('등록에 실패하였습니다.', '');
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    } else {
      this.toastrService.error('등록에 실패하였습니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 권한그룹 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    this.posService.setPosAuthMemberDelete( this.form ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, 'POS 권한삭제');
        this.activeModal.close();

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
    .catch(response => {
      this.toastrService.error('POS 권한삭제에 실패하였습니다.', '');
      this.errorResponse = response;
      this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
    });
  }

  onChangeDept(dept_seq) {
    this.getMemberList(dept_seq);
  }
}
