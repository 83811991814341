import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { MemberService } from '@app/service/member.service';

@Component({
  selector: 'app-member-company-application-process1',
  templateUrl: './member-company-application-process1.component.html',
  styleUrls: ['./member-company-application-process1.component.scss']
})
export class MemberCompanyApplicationProcess1Component implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public data: any;

  public stateList: any = [];

  public form: FormGroup;
  public formErrors = {};

  public summernoteConfig: any = config.summernoteConfig;

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [Validators.required] ],
      application_gbn: ['', [Validators.required] ],
      mem_no: ['', [Validators.required] ],
      agency_seq: ['', [] ],
      agency_name: ['', [Validators.required, Validators.maxLength(50)] ],
      agency_phone: ['', [Validators.maxLength(20)] ],
      agency_hphone1: ['', [Validators.maxLength(20)] ],
      agency_display: ['1', [] ],
      agency_ali_date: [null, [] ],
      agency_ali_end_date: [null, [] ],
      agency_state: ['', [] ],
      agency_zipcode: ['', [Validators.maxLength(5)] ],
      agency_addr: ['', [Validators.maxLength(100)] ],
      agency_addr_detail: ['', [Validators.maxLength(100)] ],
      agency_coordinate: ['', [] ],
      agency_sale: ['0', [Validators.maxLength(10)] ],
      agency_opentime: ['', [Validators.maxLength(255)] ],
      agency_howtocounsel: ['', [Validators.maxLength(255)] ],
      agency_howtoorder: ['', [Validators.maxLength(255)] ],
      agency_info: ['', [] ],
      agency_content: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    this.buildForm();

    this.summernoteConfig.height = 150;
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();

    this.form.patchValue({
      seq: this.data.seq,
      application_gbn: this.data.application_gbn,
      mem_no: this.data.mem_no,
      agency_name: this.data.company_name
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 주소
    this.commonService.getCommonListCode('ADDR').then( response => {
      if ( response.ResultCode ) {
        this.stateList = [];
        for(let item of response.data ) {
          this.stateList.push({
            value: item.common_code,
            title: item.common_name
          });
        }
      } else {
        this.stateList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 승인하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberTransitionApplicationApprove() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.memberService.setMemberTransitionApplicationApprove( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '승인하기');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '승인하기');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '승인하기');
    }
  }

  /*******************************************************************************
    설  명 : 거절하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberTransitionApplicationRefuse() {
    if ( confirm('신청을 거절하시겠습니까?') ) {
      this.memberService.setMemberTransitionApplicationRefuse( this.data.seq, this.data.mem_no ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '거절하기');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '거절하기');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApiAgency(data) {

    let state_name = data.addr.split( ' ' );
    let state = '';
    for(let item of this.stateList ) {
      if( state_name[0].indexOf(item.title) > -1 ) {
        state = item.value;
        break;
      }
    }

    // 여기로 주소값이 반환
    this.form.patchValue({
      agency_zipcode: data.zip,
      agency_addr: data.addr,
      agency_coordinate: data.latitude + ',' + data.longitude,
      agency_state: state
    });

    $("#agency_addr_detail").focus();
  }

}
