<!-- <div class="modal-header">
  <h4 class="modal-title fl">거래명세서</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="transaction-header clearfix">
    <div class="left-area">
      <div>(주)오투오즈 담당자 귀하</div>
      <div>주문일: 2020년 8월 26일</div>
      <div>내용:</div>
    </div>
    <table>
    <colgroup>
      <col style="width: 20%">
      <col style="width: 30%">
      <col style="width: 20%">
      <col style="width: 30%">      
    </colgroup>    
    <tbody>
    <tr>
      <th>등록번호</th>
      <td colspan="3" class="business-no"> 504-81-64470</td>
    </tr>
    <tr>
      <th>상호</th>
      <td>(주)바이크마트</td>
      <th>대표자</th>
      <td>최한용</td>
    </tr>
    <tr>
      <th>주소</th>
      <td colspan="3">대구광역시 동구 신천동 152-3번지 </td>
    </tr>
    <tr>
      <th>TEL</th>
      <td>1544-5022</td>
      <th>FAX</th>
      <td>053-289-3354</td>
    </tr>
    <tr>
      <th>업태</th>
      <td></td>
      <th>종목</th>
      <td></td>
    </tr>
    </tbody>
    </table>  
  </div>

  <table class="list">
  <colgroup>
    <col style="width: 10%">
    <col style="width: 25%">
    <col style="width: 10%">
    <col style="width: 20%">      
    <col style="width: 20%">
    <col style="width: 15%">    
  </colgroup>  
  <thead>
  <tr>
    <th colspan="2">합계금액(공급가액+세액)</th>
    <th colspan="4">49,500원</th>
  </tr>
  <tr>
    <th>구분</th>
    <th>품목 및 규격</th>
    <th>수량</th>
    <th>단가</th>
    <th>공급가액</th>
    <th>세액</th>
  </tr>
  </thead>  
  <tbody>
  <tr>
    <td>구분</td>
    <td>품목</td>
    <td>1</td>
    <td>45,000</td>
    <td>45,000</td>
    <td>4,500</td>
  </tr>
  </tbody>
  <tfoot>
  <tr class="sum1">
    <th colspan="4">소계</th>
    <td>45,000</td>
    <td>4,500</td>
  </tr>
  <tr class="sum2">
    <th colspan="4">합계금액</th>
    <td>45,000</td>
    <td>4,500</td>
  </tr>
  </tfoot>
  </table>  

  <div class="btn-area">
   <button class="btn1" (click)="activeModal.dismiss()">닫기</button>
  </div>
</div> -->


<div class="modal-header">
  <h4 class="modal-title fl">거래명세서</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <section id="print_area">

    <h1 style="text-align: center;">거래명세서</h1>

  <div class="transaction-header clearfix">
    <div class="left-area">
      <div>{{data.comname}} 귀하</div>
      <div>주문일 : {{data.purchase_date}}</div>
      <div>제목 : {{data.title}}</div>
    </div>
    <table>
    <colgroup>
      <col style="width: 20%">
      <col style="width: 30%">
      <col style="width: 20%">
      <col style="width: 30%">      
    </colgroup>    
    <tbody>
    <tr>
      <th>등록번호</th>
      <td colspan="3" class="business-no">{{company_info.biz_no}}</td>
    </tr>
    <tr>
      <th>상호</th>
      <td>{{company_info.company_name}}</td>
      <th>대표자</th>
      <td>{{company_info.ceo_name}}</td>
    </tr>
    <tr>
      <th>주소</th>
      <td colspan="3">{{company_info.address}}</td>
    </tr>
    <tr>
      <th>TEL</th>
      <td>{{company_info.cs_tel1}}</td>
      <th>FAX</th>
      <td>{{company_info.fax}}</td>
    </tr>
    <tr>
      <th>업태</th>
      <td>{{company_info.biz_type}}</td>
      <th>종목</th>
      <td>{{company_info.biz_category}}</td>
    </tr>
    </tbody>
    </table>  
  </div>

  <table class="list">
  <colgroup>
    <!-- <col style="width: 10%"> -->
    <col style="width: 35%">
    <col style="width: 10%">
    <col style="width: 20%">      
    <col style="width: 20%">
    <col style="width: 15%">    
  </colgroup>  
  <thead>
  <tr>
    <th colspan="2">합계금액 (공급가액+세액)</th>
    <th colspan="4">{{data.purchase_amt | number}}원</th>
  </tr>
  <tr>
    <!-- <th>구분</th> -->
    <th>품목 및 규격</th>
    <th>수량</th>
    <th>단가</th>
    <th>공급가액</th>
    <th>세액</th>
  </tr>
  </thead>  
  <tbody>
  <tr *ngFor = "let item of data._product">
    <!-- <td>{{item.ms_index}}</td> -->
    <td>{{item.product_name}}</td>
    <td>{{item.qty | number}}</td>
    <td>{{item.unit_amt | number}}</td>
    <td>{{item.supply | number}}</td>
    <td>{{item.tax | number}}</td>
  </tr>
  </tbody>
  <tfoot>
  <tr class="sum1">
    <th colspan="3">소계</th>
    <td>{{data.supply | number}}</td>
    <td>{{data.tax | number}}</td>
  </tr>
  <tr class="sum2">
    <th colspan="3">합계금액</th>
    <td>{{data.supply | number}}</td>
    <td>{{data.tax | number}}</td>
  </tr>
  </tfoot>
  </table>

  </section>

  <div class="btn-area">
    <button class="btn1 mr5" (click)="_print()">출력</button>
    <button class="btn1" (click)="activeModal.dismiss()">닫기</button>
  </div>
</div>



