
<form [formGroup]="form" (ngSubmit)="setOrderPayComplete()">

<div class="modal-header">
  <h4 class="modal-title fb mb0">결제 확인</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <table class="table table-bordered table-small mt50 mb50">
  <colgroup>
    <col style="width:25%" />
    <col style="width:75%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="col1" class="control-label">변경방법</label></th>
    <td>
      <div class="btn-group form-control form-control-small">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_method.value == '0002'}" (click)="form.get('pay_method').setValue('0002')">현금</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_method.value == '0007'}" (click)="form.get('pay_method').setValue('0007')">외상</button>
      </div>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="col1" class="control-label">날짜조건</label></th>
    <td>
      <div class="btn-group form-control form-control-small">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '0'}" (click)="onSearchClick('0')">오늘</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '1'}" (click)="onSearchClick('1')">어제</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '2'}" (click)="onSearchClick('2')">2일</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '3'}" (click)="onSearchClick('3')">3일</button>
      </div>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="col1" class="control-label">결제일시</label></th>
    <td><dateTimePicker name="pay_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
  </tr>
  <tr>
    <th scope="row"><label for="sms_yn">SMS 발송</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value == '1'}" (click)="form.get('sms_yn').setValue('1')">YES</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value == '0'}"(click)="form.get('sms_yn').setValue('0')">NO</button>
    </div>
    </td>
  </tr>
  </tbody>
  </table>
</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="submit" class="btn btn-primary btn-small mr5">저장하기</button>
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
  </div>
</div>

</form>