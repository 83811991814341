<form [formGroup]="form" (ngSubmit)="setOrderPaySave()">

<div class="modal-header">
  <h4 class="modal-title fb mb0">결제내역 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
  <span>사용가능한 마일리지 - {{useAbleMileage | number}}</span>
  <table class="table table-bordered table-small mb0">
  <caption class="none">결제내역 정보</caption>
  <colgroup>
    <col style="width:20%;" />
    <col style="width:30%;" />
    <col style="width:20%;" />
    <col style="width:30%;" />
  </colgroup>
  <tbody>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="pay_method">결제수단</label></th>
    <td>
      <inputEx type="select" name="pay_method" [formGroup]="form" [formErrors]="formErrors" [data]="payMethodList" (change)="changePayMethod()"></inputEx>
    </td>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="pay_amt">결제금액</label></th>
    <td><inputEx type="text" name="pay_amt" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <!-- <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="app_gbn">결제상태</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.app_gbn.value=='0'}" (click)="form.get('app_gbn').setValue('0')">대기</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.app_gbn.value=='1'}" (click)="form.get('app_gbn').setValue('1')">승인</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.app_gbn.value=='2'}" (click)="form.get('app_gbn').setValue('2')">취소</button>
      </div>
      <inputEx type="hidden" name="app_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td> -->
  </tr>
  <tr>
    <th scope="row"><label for="date_search">날짜조건</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '0'}" (click)="onSearchClick('0')">오늘</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '1'}" (click)="onSearchClick('1')">어제</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '2'}" (click)="onSearchClick('2')">2일</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.date_search.value == '3'}" (click)="onSearchClick('3')">3일</button>
      </div>
    </td>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="pay_date">결제일시</label></th>
    <td><dateTimePicker name="pay_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
  </tr>
  <tr>
    <th scope="row"><label for="writer_name">담당자</label></th>
    <td><inputEx type="text" name="writer_name" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
    <th scope="row"><label for="date_search">SMS 발송</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value == '1'}" (click)="form.get('sms_yn').setValue('1')">YES</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value == '0'}"(click)="form.get('sms_yn').setValue('0')">NO</button>
      </div>
    </td>      
  </tr>
  <tr>
    <th scope="row"><label for="pay_gbn">결제구분</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_gbn.value=='PG'}" (click)="form.get('pay_gbn').setValue('PG')">PG</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_gbn.value=='VAN'}" (click)="form.get('pay_gbn').setValue('VAN')">VAN</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_gbn.value=='OFF'}" (click)="form.get('pay_gbn').setValue('OFF')">OFFLINE</button>
      </div>
      <inputEx type="hidden" name="pay_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
    <th scope="row"><label for="pg_group">PG그룹사</label></th>
    <td><inputEx type="select" name="pg_group" [formGroup]="form" [formErrors]="formErrors" [data]="pgGroupList" (change)="changePgGroup()"></inputEx></td>
    <inputEx type="hidden" name="writer" [formGroup]="form" [formErrors]="formErrors"></inputEx>
  </tr>
  <tr>
    <th scope="row"><label for="mid">상점아이디</label></th>
    <td><inputEx type="text" name="mid" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="app_no">승인번호</label></th>
    <td><inputEx type="text" name="app_no" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="app_date">승인일자</label></th>
    <td><inputEx type="text" name="app_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="app_time">승인시간</label></th>
    <td><inputEx type="text" name="app_time" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="oid">주문번호</label></th>
    <td><inputEx type="text" name="oid" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="tid">TID</label></th>
    <td><inputEx type="text" name="tid" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="card_no">카드번호</label></th>
    <td><inputEx type="text" name="card_no" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="installment_period">할부기간</label></th>
    <td><inputEx type="text" name="installment_period" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="card_code1">카드종류1</label></th>
    <td><inputEx type="select" name="card_code1" [formGroup]="form" [formErrors]="formErrors" [data]="cardCodeList" (change)="changeCardCode1()"></inputEx></td>
    <th scope="row"><label for="card_code2">카드종류2</label></th>
    <td><inputEx type="select" name="card_code2" [formGroup]="form" [formErrors]="formErrors" [data]="cardCodeList" (change)="changeCardCode2()"></inputEx></td>
  </tr>
  </tbody>
  </table>
</div>

<div class="modal-footer">
  <div class="fl w50p">
    <!-- <button *ngIf="form.controls.seq.value!=0" type="button" class="btn btn-danger btn-small">삭제하기</button> -->
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    <button type="submit" class="btn btn-primary btn-small ml5">저장하기</button>
  </div>
</div>

</form>