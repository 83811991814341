/*******************************************************************************
  설  명 : 재고관리 > 입고관리 > 기초/기타 입고
  작성일 : 2021-05-18
  작성자 : 송영석
  접속URL : /stock/in/base

  수정일 : 2021.05.25
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import { AuthService } from '@app/service/auth.service';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { BasicService } from '@app/service/basic.service';
import { StockIn } from '@app/service/stockIn.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';

@Component({
  selector: 'app-stock-in-base',
  templateUrl: './stock-in-base.component.html',
  styleUrls: ['./stock-in-base.component.scss']
})
export class StockInBaseComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  productList: any = [];
  productPropertyList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiProperty: any;
  gridColumnApiProperty: any;
  columnDefsProperty: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;
  firstColumn = {
    headerName: '',
    field: '',
    width: 50,
    cellClass: 'cp center',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    sortable: false,
    filter: false,
    resizable: false,
    flex: 0
  }

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
    agGridImageComponent: AgGridImageComponent,
  };

  public components: any;

  optionsXXL: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xxl',
    centered: true,
    windowClass: 'modal-fadeInDown'
  };

  warehouse_list = [];
  private getWH_forCellEditor = () => ({values: this.warehouse_list.map(e => e.name)});
  // selectWarehouse = '';
  // private getWHL_forCellEditor = () => ({values: this.warehouse_list.find(e => e.name === this.selectWarehouse)['detail'].map(e => e['location_cd'])});
  private getWHL_forCellEditor = (params) => ({values: this.warehouse_list.find(e => e.name == params.data.warehouse_seq)['detail'].map(e => e['location_cd'])})

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private agGridExComponent: AgGridExComponent,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private basicService: BasicService,
    private authService: AuthService,
    private stockIn: StockIn,
    private stockOrderComponent: StockOrderComponent,

  ) {
    // 발주서별 대표상품
    this.columnDefs = [
      this.firstColumn,
      { headerName: '이미지', field: 'thumbnail_url', width: 150, cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridImageComponent' },
      { headerName: '카테고리', field: 'category_name', width: 150, cellClass: 'cp ag-cell50h' },
      { headerName: '상품명', field: 'product_name', width: 150, cellClass: 'cp ag-cell50h' },
      { headerName: '색상개수', field: 'color_cnt', width: 100, cellClass: 'cp right ag-cell50h',
        valueGetter: (params) => params.data._property.filter(e => e['color_name']).length.toLocaleString()
      },
      { headerName: '사이즈개수', field: 'size_cnt', width: 100, cellClass: 'cp right ag-cell50h',
        valueGetter: (params) => params.data._property.filter(e => e['size_name']).length.toLocaleString()
      },
    ];

    // 상품별 색상 및 사이즈
    // 색상 사이즈가 없는 상품의 경우 없음으로 표시하고 이 그리드에서 수정하여 저장
    this.columnDefsProperty = [
      this.firstColumn,
      { headerName: '색상', field: 'color_name', width: 150, cellClass: 'cp' },
      { headerName: '사이즈', field: 'size_name', width: 150, cellClass: 'cp' },
      // { headerName: '재고수량', field: 'stock', width: 100, cellClass: 'cp right',
      //   valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
      { headerName: '입고창고', field: 'warehouse_seq', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: this.getWH_forCellEditor },
      { headerName: '입고위치', field: 'warehouse_location_seq', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, cellEditor: "agSelectCellEditor", cellEditorParams: this.getWHL_forCellEditor },
      { headerName: '입고수량', field: 'in_qty', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, cellEditor: "numericCellEditor", cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)
      },
      { headerName: '입고일자', field: 'in_date', width: 120, cellClass: 'cp center ag-cell-edit', editable: true, cellEditor: "dateCellEditor" },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };
    this.rowSelection = 'single';
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      dateCellEditor: this.agGridExComponent.getDatePicker()
    };
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      productList : [[]],
      productListProperty : [[]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 50;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();
    this.getWarehouseList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyProperty(params) {
    this.gridApiProperty = params.api;
    this.gridColumnApiProperty = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/stock/in/management'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상품 추가 모달 띄우기
  *******************************************************************************/
  addProductModal() {
    const modalRef = this.modalService.open(ProductFindPropertyComponent, this.optionsXXL);
    modalRef.result.then((result) => {
      this.addProduct(result);
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 상품 추가
  *******************************************************************************/
  // dateFormat(date) {
  //   const _2 = number => (`0${String(number)}`).slice(-2);
  //   try {date.getFullYear()} catch {return null}
  //   return [
  //     [
  //       date.getFullYear(),
  //       _2(date.getMonth() + 1),
  //       _2(date.getDate())
  //     ].join('-'),
  //     [
  //       date.getHours(),
  //       date.getMinutes(),
  //       date.getSeconds()
  //     ].join(':')
  //   ].join(' ')
  // }
  addProduct(result) {
    const productList = JSON.parse(JSON.stringify(this.form.value.productList));
    result.forEach(e => {
      const _index = productList.findIndex(_e => _e['product_seq'] == e['seq']);
      if(!e['property_seq']) {
        this.toastrService.warning('색상사이즈 코드가 없는 상품은 무시합니다.', '');
        return;
      }
      const productListProperty = {
        product_property_seq : e['property_seq'],
        color_name : e['color_name'],
        size_name : e['size_name'],

        // stock : '데이터없음',
        warehouse_seq : '',
        warehouse_location_seq : '',
        in_qty : 0,
        in_date : this.stockOrderComponent.dateFormat(new Date())
      };
      _index < 0
      ? productList.push({
          product_seq : e['seq'],
          thumbnail_url : e['thumbnail_url'],
          category_name : e['category_name'],
          product_name : e['product_name'],

          color_cnt : 0,
          size_cnt : 0,
          _property : [productListProperty]
        })
      : productList[_index]['_property'].push(productListProperty)
      ;
    });
    this.form.patchValue({
      productList : productList,
    });
  }
  /*******************************************************************************
    설  명 : 입고 - 저장하기
    입력값 : {
      mem_no,

      _product : [{
        product_seq,

        _property : [{
          in_date,
          warehouse_seq,
          warehouse_location_seq,
          product_property_seq,
          in_qty
        }]

      }]
    }
    리턴값 : 없음
  *******************************************************************************/
  setStockIn() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if(!this.form.valid) {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      return;
    }
    let _err = 0;
    this.form.value['productList'].forEach(e => {
      e['_property'].forEach(e => {
        if(!e['warehouse_seq']) _err++;
        if(!e['in_qty']) _err++;
        if(!e['in_date']) _err++;
      });
    });
    if(_err) {
      this.toastrService.error(`값이 없는 항목이 ${_err} 개 있습니다.`, '');
      return;
    }

    this.authService.getLoginInfo.subscribe(__e => {

      this.stockIn.setStockIn({
        mem_no : __e['mem_no'],
        _product : this.form.value['productList'].map(e => ({
          product_seq : e['product_seq'],
          _property : e['_property'].map(_e => ({
            product_property_seq : _e['product_property_seq'],
            warehouse_seq : this.warehouse_list.find(__e => __e['name'] == _e['warehouse_seq'])['seq'],
            warehouse_location_seq :
              this.warehouse_list.find(__e => __e['name'] == _e['warehouse_seq'])
              ? this.warehouse_list.find(__e => __e['name'] == _e['warehouse_seq'])['detail'].find(__e => __e['location_cd'] == _e['warehouse_location_seq'])
              ? this.warehouse_list.find(__e => __e['name'] == _e['warehouse_seq'])['detail'].find(__e => __e['location_cd'] == _e['warehouse_location_seq'])['seq']
              : '' : '',
            in_date : _e['in_date'],
            in_qty : _e['in_qty']
          }))
        }))
      }).then(response => {
        if(response.ResultCode) {
          this.toastrService.success('저장하였습니다.', '');
          this.goList();
        }
      });

    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
  *******************************************************************************/
  getWarehouseList() {
    this.basicService.getWarehouseList({
      searchText: ''
    }).then( response => {
      if(response.ResultCode) {
        response.data.forEach(e => {
          this.basicService.getWarehouseDetail(e['seq']).then(_R => {
            if(response.ResultCode)
              this.warehouse_list.push({
                name : _R.data['warehouse_name'],
                seq : _R.data['seq'],
                detail : _R['location'].map(e => ({
                  location_cd : e['location_cd'],
                  location_name : e['location_name'],
                  seq : e['seq']
                }))
              });
          }, error => this.toastrService.error( error, ''));
        });
      }
    }, error => this.toastrService.error( error, ''));
  }

  /*******************************************************************************
    설  명 : 상품정보 클릭시 색상사이즈 보여주기
  *******************************************************************************/
  onRowClicked($event) {
    this.form.patchValue({
      productListProperty : $event.data._property
    });
  }
  /*******************************************************************************
    설  명 : 색상사이즈 row 선택
  *******************************************************************************/
  onSelectionChanged_property($event) {
    // this.selectWarehouse = '';
  }
  /*******************************************************************************
    설  명 : 색상사이즈 값 변경시
  *******************************************************************************/
  onCellValueChanged($event) {
    // if($event.colDef.field == 'warehouse_seq') this.selectWarehouse = $event.value;
    // this.gridApi.refreshCells();
  }
  /*******************************************************************************
    설  명 : 색상사이즈 셀 클릭시
  *******************************************************************************/
  cellClick_property($event) {
    if($event.colDef.field == 'warehouse_location_seq' && !$event.data.warehouse_seq) {
      this.toastrService.warning('창고를 먼저 선택하세요.')
      return;
    }
  }

}
