<section class="widget-div">
  <div class="fl widget cp" style="width: 250px;">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">{{search.type}} <span class="cnt">{{search.date}}</span></p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<div style="height: calc(100% - 70px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [rowClassRules]="rowClassRules"

    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</div>