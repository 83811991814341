<div class="search-condition mb5">
  <div>
    <div class="btn-group">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.approval_group == ''}" (click)="getApprovalGroup('')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.approval_group == 'A'}" (click)="getApprovalGroup('A')">결재</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.approval_group == 'G'}" (click)="getApprovalGroup('G')">협조</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.approval_group == 'R'}" (click)="getApprovalGroup('R')">참조</button>
    </div>    
    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.step_status == ''}" (click)="getStepStatus('')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.step_status == 'P'}" (click)="getStepStatus('P')">진행중</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.step_status == 'C'}" (click)="getStepStatus('C')">완료</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.step_status == 'R'}" (click)="getStepStatus('R')">반려</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.step_status == 'CA'}" (click)="getStepStatus('CA')">취소</button>
    </div>   
  </div>
  
  <div>
	  <div class="fl mt5 form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined f14">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getApprovalList()">
        <i class="material-icons-outlined f14">search</i>
      </button>
      <input type="text" id="sdate" [(ngModel)]="search.sdate" size="12" style="width:110px;" class="form-control form-control-small calendar-input ml5" readonly="readonly" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="pl5 pr5">~</span>
      <input type="text" id="edate" [(ngModel)]="search.edate" size="12" style="width:110px;" class="form-control form-control-small calendar-input" readonly="readonly" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>
      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.writer == ''}" (click)="getWriter('')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.writer == '1'}" (click)="getWriter('1')">작성자</button>
      </div>
      <select class="form-control form-control-small ml5 dept" (change)="setMember($event)">
        <option value="">사원 선택</option>
        <option *ngFor="let item of employeeList" [value]="item.mem_no">{{item.name}}</option>
      </select>
      <select class="form-control form-control-small ml5" (change)="getChange($event)">
        <option *ngFor="let tmp of approvalDocument" [value]="tmp.value">{{tmp.title}}</option>
      </select> 
      <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.subject" (keypress)="searchList($event)" placeholder="제목을 입력하세요." />

      <div class="ml5 f14">검색 : <span class="search-cnt">{{count}}</span> 개</div>
    </div>
    
    <div class="fr right">
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="내결재관리리스트" title="내결재관리리스트"></ag-grid-excel>
      <button type="button" class="btn btn-primary btn-small ml5" routerLink="/approval">신규작성</button>
    </div>

    <div class="cb"></div>	  
  </div>
</div>

<div class="mt5" style="height:calc(100vh - 160px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="approvalList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [rowHeight]="80"
    
    (cellClicked)="openList($event)"
    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</div>
