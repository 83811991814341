import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { RefundService } from '@app/service/refund.service';
import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { OrderRefundEditComponent } from '@page/order/order-refund/order-refund-edit/order-refund-edit.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-refund2',
  templateUrl: './order-refund2.component.html',
  styleUrls: ['./order-refund2.component.scss']
})
export class OrderRefund2Component implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  statistic: any = {
    totalCount: 0,
    searchCount: 0,
    standbyCount: 0
  }

  public refundList: any = [];
  public refundMasterList: any = [];

  public refundSeq: any;

  public search: any = {
    pageNo: 1,
    pageRow: 1000,
    write_date: '',
    return_date: '',
    sort: 'date'
  };

  public params: any = {
    master_seq: '',
    write_date: '',
    process_status: ''
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  masterGridApi: any;
  masterGridColumnApi: any;
  masterColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private refundService: RefundService
  ) {
    // ag grid 컬럼 선언
    this.masterColumnDefs = [
      { headerName: '진행상태', field: 'process_status', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.process_status == '33' ) {
            return '<span class="badge badge-secondary f12">환불요청</span>';
          } else if ( params.data.process_status == '55' ) {
            return '<span class="badge badge-primary f12">결재요청</span>';
          } else if ( params.data.process_status == '77' ) {
            return '<span class="badge badge-danger f12">지급요청</span>';
          } else if ( params.data.process_status == '99' ) {
            return '<span class="badge badge-success f12">환불완료</span>';
          } else {
            return ''
          }
        }
      },
      { headerName: '환불방법', field: 'pay_method_name', width: 80, cellClass: 'cp center' },
      { headerName: '주문번호', field: 'master_seq', width: 80, cellClass: 'cp center' },
      { headerName: '주문자', field: 'mem_name', width: 140, cellClass: 'cp center' },
      { headerName: '등록일', field: 'write_date', width: 100, cellClass: 'cp center' },
      { headerName: '완료일', field: 'end_date', width: 100, cellClass: 'cp center' },
      { headerName: '수량', field: 'return_qty', width: 65, cellClass: 'cp right' },
      { headerName: '환불금액', field: 'return_amt', width: 95, cellClass: 'cp right', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '환불사유', field: 'common_name', width: 150, cellClass: 'cp center' },
      { headerName: '처리', field: 'modify', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<button type="button" class="btn btn-default btn-grid vai mr5">수정</button>`
        }
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefs = [
      // { headerName: '', field: 'seq', width: 40, cellClass: 'cp center ag-cell50h',
      //   headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      // },
      // { headerName: '주문번호', field: 'master_seq', width: 80, cellClass: 'cp center ag-cell50h' },
      { headerName: '진행상태', field: 'process_status', width: 85, cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.process_status == '33' ) {
            return '<span class="badge badge-secondary f12">환불요청</span>';
          } else if ( params.data.process_status == '55' ) {
            return '<span class="badge badge-primary f12">결재요청</span>';
          } else if ( params.data.process_status == '77' ) {
            return '<span class="badge badge-danger f12">지급요청</span>';
          } else if ( params.data.process_status == '99' ) {
            return '<span class="badge badge-success f12">환불완료</span>';
          } else {
            return ''
          }
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'product_name', width: 250, cellClass: 'cp left cell-wrap-text', cellRenderer: 'agGridHtmlComponent', },
      // { headerName: '주문자', field: 'mem_name', width: 85, cellClass: 'cp center ag-cell50h' },
      // { headerName: '등록일', field: 'write_date', width: 100, cellClass: 'cp center ag-cell50h' },
      // { headerName: '요청일', field: 'return_date', width: 100, cellClass: 'cp center ag-cell50h' },
      // { headerName: '환불사유', field: 'common_name', width: 150, cellClass: 'cp center ag-cell50h' },
      { headerName: '수량', field: 'return_qty', width: 70, cellClass: 'cp right ag-cell50h' },
      { headerName: '환불금액', field: 'return_amt', width: 95, cellClass: 'cp right ag-cell50h', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '동봉배송비', field: 'get_consign_pay', width: 95, cellClass: 'cp right ag-cell50h', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      // { headerName: '환불방법', field: 'pay_method_name', width: 80, cellClass: 'cp center ag-cell50h' },
      // { headerName: '처리', field: 'modify', width: 85, cellClass: 'cp center ag-cell50h', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: function(params) {
      //     return `<button type="button" class="btn btn-default btn-grid mr5">수정</button>`
      //   }
      // },
      // { headerName: '완료일', field: 'end_date', width: 100, cellClass: 'cp center ag-cell50h' }, // 버튼
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getRefundMasterList();
    // this.getRefundList();
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check ) {
    if( check == 'sdate' ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else if (check == 'edate' ){
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    } else if (check == 'return_date' ) {
      this.search.return_date = this.utilService.getDate( '' );
      obj.close();
    } else if (check == 'write_date' ) {
      this.search.write_date = this.utilService.getDate( '' );
      obj.close();
    }

    this.getRefundMasterList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onMasterGridReady(params) {
    this.masterGridApi = params.api;
    this.masterGridColumnApi = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.getRefundMasterList();
  }

  /*******************************************************************************
    설  명 : cell 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onMasterCellClicked($event: any) {
    if( $event.colDef.field === 'modify') {
      this.refundEdit($event.data);
    } else {
      this.params.master_seq = $event.data.master_seq;
      this.params.write_date = $event.data.write_date;
      this.params.process_status = $event.data.process_status;

      this.getRefundList();

      if( $event.colDef.field === 'master_seq' ) {
        let seq = $event.data.master_seq;
        const url = '/order/detail?seq=' + seq;
        window.open("about:blank").location.href = url;
      }
    }
  }
  
  /*******************************************************************************
    설  명 : cell 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field === 'master_seq' ) {
      let seq = $event.data.master_seq;
      const url = '/order/detail?seq=' + seq;
      window.open("about:blank").location.href = url;
    } else if( $event.colDef.field === 'modify') {
      this.refundEdit($event.data);
    }
  }

  /*******************************************************************************
    설  명 : 환불관리 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRefundMasterList() {
    this.refundList = [];

    this.refundService.getRefundMasterList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.refundMasterList = response.data;
        this.statistic = response.statistic;
        // this.search.totalCount = response.statistic.searchCount;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 환불관리 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRefundList() {
    this.refundService.getRefundDetailList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.refundList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  onRowClicked(event: any) {
    this.refundEdit(event.data);
  }

  /*******************************************************************************
    설  명 : 환불신청 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  refundEdit( item ) {
    this.toastrService.info('작업중입니다.');
    // const modalRef = this.modalService.open(OrderRefundEditComponent, optionsLG);

    // modalRef.componentInstance.selectedItem = item;

    // modalRef.result.then((result) => {
    //   if( result ) {
    //     this.getRefundMasterList();
    //   }
    // }, (reason) => {
    // });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 1000,
      write_date: '',
      return_date: '',
      sort: 'date'
    };

    this.getRefundMasterList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getRefundMasterList();
    }
  }

  /*******************************************************************************
    설  명 : 데이터 정렬 조건 선택
  *******************************************************************************/
  setSearchSort( value: any ) {
    this.search.sort = value;
    
    this.getRefundMasterList();
  }
  
  /*******************************************************************************
    설  명 : 날짜 선택
  *******************************************************************************/
  selectDate($event) {
    this.getRefundMasterList();
  }
  
  /*******************************************************************************
    설  명 : 주문내역 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelOrder() {
    if( this.search.return_date === '' && this.search.write_date === '' ) {
      this.toastrService.error('등록일자 또는 결제일자를 선택하시기 바랍니다.');
    } else {
      const contents = [`
        <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
          <caption></caption>
          <colgroup>
            <col style="width:80px;" />
            <col style="width:80px;" />
            <col style="width:150px;" />
            <col style="width:200px;" />
            <col style="width:150px;" />
            <col style="width:100px;" />
            <col style="width:100px;" />
            <col style="width:150px;" />
            <col style="width:80px;" />
            <col style="width:80px;" />
            <col style="width:80px;" />
            <col style="width:80px;" />
            <col style="width:100px;" />
          </colgroup>
          <tbody>
          <tr style="height:200px; vertical-align:bottom;">
            <td colspan="13" style="font-size:14pt;text-align:center;vertical-align:bottom;"><strong>환불내역서</strong></td>
          </tr>
          <tr align="center" bgColor="#f5f7f7">
            <td>주문번호</td>
            <td>진행상태</td>
            <td>이미지</td>
            <td>상품명</td>
            <td>주문자</td>
            <td>등록일</td>
            <td>요청일</td>
            <td>환불사유</td>
            <td>동봉배송비</td>
            <td>수량</td>
            <td>환불금액</td>
            <td>환불방법</td>
            <td>완료일</td>
          </tr>
      `];
      
      this.refundService.getRefundList( this.search ).then( response => {
        if( response.ResultCode ) {
          let data = response.data;

          data.forEach(element => {

            let process_status = '';
            if ( element.process_status == '33' ) {
              process_status = '<span class="badge badge-secondary f12">환불요청</span>';
            } else if ( element.process_status == '55' ) {
              process_status = '<span class="badge badge-primary f12">결재요청</span>';
            } else if ( element.process_status == '77' ) {
              process_status = '<span class="badge badge-danger f12">지급요청</span>';
            } else if ( element.process_status == '99' ) {
              process_status = '<span class="badge badge-success f12">환불완료</span>';
            } else if( element.process_status == '0' ) {
              process_status = '';
            }
      
            let img = '';
            img = `<img src="${element.thumbnail_url}" height="100" />`;
      
            let productName = element.product_name;
            productName = productName.replace(/<br>/g,'<br style="mso-data-placement:same-cell;">');
            productName = productName.replace(/<br\/>/g,'<br style="mso-data-placement:same-cell;">');
            productName = productName.replace(/<BR>/g,'<br style="mso-data-placement:same-cell;">');
            productName = productName.replace(/<BR\/>/g,'<br style="mso-data-placement:same-cell;">');
      
            contents.push(`
              <tr style="height:100px;">
                <td style="text-align:center;">${element.master_seq}</td>
                <td style="text-align:center;">${process_status}</td>
                <td style="text-align:center;">${img}</td>
                <td style="text-align:center;">${productName}</td>
                <td style="text-align:center;">${element.mem_name}</td>
                <td style="text-align:center;">${element.write_date}</td>
                <td style="text-align:center;">${element.return_date !== null ? element.return_date : ''}</td>
                <td style="text-align:center;">${element.common_name}</td>
                <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.get_consign_pay !== null ? element.get_consign_pay : ''}</td>
                <td style="text-align:center; mso-number-format:'\#\,\#\#0';">${element.return_qty}</td>
                <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.return_amt}</td>
                <td style="text-align:center;">${element.pay_method_name}</td>
                <td style="text-align:center;">${element.end_date !== null ? element.end_date : ''}</td>
              </tr>
              </tbody>
            `)
          })
          contents.push(`</table>`);
      
          let today = new Date();

          const fileName = `bikemart_refundList_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`;
    
          this.printExcel(fileName, contents.join(''));
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
  
}
