/*******************************************************************************
  설  명 : 재고관리 > 출고관리
  작성일 : 2020-08-14
  작성자 : 송영석
  접속URL : /stock/out/management
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-out-management',
  templateUrl: './stock-out-management.component.html',
  styleUrls: ['./stock-out-management.component.scss']
})
export class StockOutManagementComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public activeTab: any = 0;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabSetValue( value: any ) {
    this.activeTab = value;
  }
  
}
