import { Component } from '@angular/core';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { RestfulService } from '@app/service/restful.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public location: any = '';

  constructor(
    private ngbDatepickerConfig: NgbDatepickerConfig,
    public restful: RestfulService
  ) {
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저
    
    this.location = location.hostname;
  }
}
