/*******************************************************************************
  설  명 : POS 권한 관리
  작성일 : 2020-09-03
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridHeaderCheckboxComponent } from '@app/components/ag-grid-header-checkbox/ag-grid-header-checkbox.component';
import { ToastrService } from 'ngx-toastr';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { PosGroupAddComponent } from '@page/system/pos-authgroup/add/add.component';
import { PosService } from '@app/service/pos.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-pos-authgroup',
  templateUrl: './pos-authgroup.component.html',
  styleUrls: ['./pos-authgroup.component.scss']
})
export class PosAuthgroupComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    sdate: '',
    edate: ''
  }

  auth_group_id = '';

  // 그리드 관련 선언
  posAuthGroupList: any = [];
  posAuthGroupDetail: any = [];
  selectedNode: any = {};

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  selectedRows: number = 0;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;
  selectedRowsDetail: number = 0;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
    agGridHeaderCheckboxComponent: AgGridHeaderCheckboxComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private modalService: NgbModal,
    private posService: PosService,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'auth_group_id', hide: true },
      {headerName: '권한명', field: 'name', width: 130, cellClass: 'cp' },
      {headerName: '사용자수', field: 'userCount', width: 80, cellClass: 'cp right' },
      {headerName: '등록일', field: 'write_date', width: 140, cellClass: 'cp' },
      {headerName: '등록자', field: 'writer', width: 80, cellClass: 'cp left' },
    ];

    // ag grid 컬럼 선언
    this.columnDefsDetail = [
      {headerName: '선택', field: 'checkrow', cellClass: 'cp center', width:80, cellRenderer: 'agGridHtmlComponent', suppressMenu: true,
        valueGetter: function(params) {
          let checked = ( params.data.checkrow ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.checkrow ) ? 'ag-hidden' : '';

          return `<div class="ag-selection-checkbox auth_group_id auth_group_id${params.data.menu_id}" unselectable="on">
                    <div class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></div>
                    <div class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></div>
                  </div>`;
        }
      },
      {headerName: '메뉴명', field: 'page_menu_name', cellClass: 'cp', width:300, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let l = params.data.page_cd.length;
          let len = ( l / 3 - 1 ) * 16;
          return `<span style="padding-left:${len}px">${params.data.page_menu_name}</span>`;
        }
      },
      {headerName: '조회', field: 'auth_view', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_view ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_view ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_view auth_view${params.data.menu_id}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_view" }
      },
      {headerName: '등록', field: 'auth_write', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_write ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_write ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_write auth_write${params.data.menu_id}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_write" }
      },
      {headerName: '수정', field: 'auth_modify', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_modify ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_modify ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_modify auth_modify${params.data.menu_id}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_modify" }
      },
      {headerName: '삭제', field: 'auth_delete', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_delete ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_delete ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_delete auth_delete${params.data.menu_id}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_delete" }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getPosAuthGroupList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.selectedNode = $event.data;
    this.getPosAuthList( $event.data.auth_group_id );
  }

  /*******************************************************************************
    설  명 : ag grid 행 더블클릭 시 처리 - 공통코드 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( $event ) {
    this.addPosAuthGroup( $event.data );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedDetail($event) {
    let filteredRowCount = 0;
    this.gridApiDetail.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRowsDetail = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {
    if( $event.colDef.field == 'checkrow' ) {
      this.rowChecked( $event );

    } else if( $event.colDef.field == 'page_menu_name' ) {

    } else {
      const parent = document.getElementsByClassName($event.colDef.field + $event.data.menu_id)[0];
      const checked = parent.getElementsByClassName('ag-icon-checkbox-checked')[0];
      // let unchecked = parent.getElementsByClassName('ag-icon-checkbox-unchecked')[0];

      if( checked.classList.contains('ag-hidden') ) {
        $event.node.setDataValue( $event.colDef.field, true);
      } else {
        $event.node.setDataValue( $event.colDef.field, false);
      }
    }
  }

  /*******************************************************************************
    설  명 : ag-grid checkbox 행 선택/해제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  rowChecked( $event ) {
    const data = [ 'checkrow', 'auth_view', 'auth_write', 'auth_modify', 'auth_delete' ];
    const parent = document.getElementsByClassName('auth_group_id' + $event.data.menu_id)[0];
    const checked = parent.getElementsByClassName('ag-icon-checkbox-checked')[0];
    // let unchecked = parent.getElementsByClassName('ag-icon-checkbox-unchecked')[0];

    if( checked.classList.contains('ag-hidden') ) {
      data.forEach( val => {
        $event.node.setDataValue( val, true);
      });

    } else {
      data.forEach( val => {
        $event.node.setDataValue( val, false);
      });
    }
  }

  /*******************************************************************************
    설  명 : 권한 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPosAuthGroupList() {
    this.posService.getPosAuthGroupList().then( response => {

      if( response.ResultCode ) {
        this.posAuthGroupList = response.data;
        if( this.posAuthGroupList.length > 0 ) {

          this.selectedNode.auth_group_id = this.posAuthGroupList[0].auth_group_id;
          this.getPosAuthList( this.posAuthGroupList[0].auth_group_id );
        }
      } else {
        this.posAuthGroupList = [];
        this.selectedNode.auth_group_id = null;
      }
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 노드 선택시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPosAuthList( auth_group_id ) {
    this.auth_group_id = auth_group_id;
    this.posService.getPosAuthList(auth_group_id, '9999').then( response => {

      this.posAuthGroupDetail = response.data;

      response.data.forEach( row => {
        row.checkrow = false;
        row.auth_group_id = this.selectedNode.auth_group_id;
        row.auth_view = ( row.auth_view == '1' ) ? true : false;
        row.auth_write = ( row.auth_write == '1' ) ? true : false;
        row.auth_modify = ( row.auth_modify == '1' ) ? true : false;
        row.auth_delete = ( row.auth_delete == '1' ) ? true : false;
        row.auth_excel = ( row.auth_excel == '1' ) ? true : false;
        row.auth_sms = ( row.auth_sms == '1' ) ? true : false;
        row.auth_email = ( row.auth_email == '1' ) ? true : false;
      });

      // Grid Selected
      let node = this.gridApi.getSelectedNodes();

      if( node.length < 1 ) {
        let index = 0;
        this.gridApi.forEachNode(function(node) {
          if (index++ == 0 ) {
            node.setSelected(true);
          }
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addPosAuthGroup(item) {
    const modalRef = this.modalService.open(PosGroupAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      // 권한그룹 리스트 가져오기
      this.getPosAuthGroupList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 전체 선택/해제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectAll( checked ) {
    this.gridApiDetail.forEachNode( node => {
      node.setDataValue( 'checkrow', checked );
      node.setDataValue( 'auth_view', checked );
      node.setDataValue( 'auth_write', checked );
      node.setDataValue( 'auth_modify', checked );
      node.setDataValue( 'auth_delete', checked );
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    const menu_auth = {
      "auth_group_id": this.auth_group_id,
      "menu_auths": this.posAuthGroupDetail
    }

    const data = JSON.stringify( menu_auth );

    // const data = JSON.stringify( this.posAuthGroupDetail );

    this.posService.savePosMenuAuths({ data: data }).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, 'POS 권한 저장');

        // this.getPosAuthList( this.selectedNode.auth_group_id );

      } else {
        this.toastrService.error( response.ResultMessage, 'POS 권한그룹 저장');
      }
    });
  }

}
