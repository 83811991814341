/*******************************************************************************
  설  명 : 거래처관리
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from '@app/service/common.service';
import { BasicService } from '@app/service/basic.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { ExportExcelComponent } from '@components/export-excel/export-excel.component'

import { CustomerAddComponent } from '@page/basic/customer/customer-add/customer-add.component';

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    search_nation: '',
    search_jungsan: '',
    search_text: '',
    search_use: '',
    product_gbn: ''
  };

  public dealTypeList: any = [];
  public customerList: any = [];

  public customerSeq: any;
  public activeTab: any = 0;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private basicService: BasicService,
    private exportExcelComponent: ExportExcelComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq_sort', width: 90, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '사용여부', field: 'use_yn', width: 72, cellClass: 'cp center', sortalbe: false,
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == 'Y' ) {
            return '<span class="badge badge-success f12">사용</span>';
          } else if( params.data.use_yn == 'N' ) {
            return '<span class="badge badge-secondary f12">미사용</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '구분', field: 'nation_gbn', width: 60, cellClass: 'cp center', sortalbe: false,
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.nation_gbn == 'D' ) {
            return '<span class="badge badge-success f12">국내</span>';
          } else {
            return '<span class="badge badge-secondary f12">국외</span>';
          }
        }
      },
      { headerName: '매입상품구분', field: 'product_gbn', width: 72, cellClass: 'cp center', sortalbe: false,
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.product_gbn == '1000' ) {
            return '<span class="badge badge-success f12">자사</span>';
          } else {
            return '<span class="badge badge-secondary f12">타사</span>';
          }
        }
      },
      { headerName: '거래형태', field: 'jungsan_name', width: 100, cellClass: 'cp center' },
      { headerName: '아이디', field: 'customer_id', width: 120, cellClass: 'cp' },
      { headerName: '회사명', field: 'comname', width: 150, cellClass: 'cp' },
      { headerName: '브랜드', field: 'brand', width: 150, cellClass: 'cp', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let array = params.data.brand.map(rows => {
            return rows.text;
          });
          return array;
        } 
      },
      { headerName: '마진', field: 'contact_margin_rate', width: 60, cellClass: 'cp center' },
      { headerName: '상품등록', field: 'product_upload', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.product_upload == '1' ) {
            return '<span class="badge badge-success f12">당사등록</span>';
          } else if( params.data.product_upload == '2' ) {
            return '<span class="badge badge-secondary f12">업체등록</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '배송비부담', field: 'consign_type', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.consign_type == '1' ) {
            return '당사부담';
          } else if( params.data.consign_type == '0' ) {
            return '업체부담';
          } else {
            return '';
          }
        }
      },      
      { headerName: '대표자', field: 'ceoname', width: 80, cellClass: 'cp' },
      { headerName: '대표자전화', field: 'ownerhp', width: 120, cellClass: 'cp' },
      { headerName: '담당자명', field: 'worker', width: 80, cellClass: 'cp' },
      { headerName: '담당자전화', field: 'workerhp', width: 120, cellClass: 'cp ' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 거래형태
    this.commonService.getCommonListCode('DLT').then( response => {
      if ( response.ResultCode ) {
        this.dealTypeList = response.data;
      } else {
        this.dealTypeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 거래처 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCustomerList() {
    await this.basicService.getCustomerList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.customerList = response.data;

        if( this.customerList.length > 0 ) {
          this.customerSeq = this.customerList[0].seq;

          let index = 0;
          setTimeout(() => {
            this.gridApi.forEachNodeAfterFilter(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }, 0);
        }
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabSetValue( value: any ) {
    this.activeTab = value;
  }

  /*******************************************************************************
    설  명 : 거래처 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCustomer() {
    const modalRef = this.modalService.open(CustomerAddComponent, optionsXL);

    modalRef.result.then((result) => {
      if( result ) {
        this.getCustomerList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 거래처내역 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelCustomer() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>정산거래처 목록</strong></caption>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tr align="center" bgColor="#f5f7f7">
          <td>순번</td>
          <td>사용여부</td>
          <td>구분</td>
          <td>거래형태</td>
          <td>아이디</td>
          <td>회사명</td>
          <td>브랜드</td>
          <td>마진</td>
          <td>상품등록</td>
          <td>배송비부담</td>
          <td>대표자</td>
          <td>대표자전화</td>
          <td>담당자명</td>
          <td>담당자전화</td>
        </tr>
    `]

    let data = this.customerList;

    data.forEach(element => {

      let colorGreen = 'green';
      let colorDark = 'black';

      let useYn = '';
      if ( element.use_yn == 'Y' ) {
        useYn = '<font style="color:' + colorGreen + ';">사용</font>';
      } else if ( element.use_yn == 'N' ) {
        useYn = '<font style="color:' + colorDark + ';">미사용</font>';
      } else {
        useYn = '';
      }

      let nationGbn = '';
      if ( element.nation_gbn == 'D' ) {
        nationGbn = '<font style="color:' + colorGreen + ';">국내</font>';
      } else if ( element.nation_gbn == 'O' ) {
        nationGbn = '<font style="color:' + colorDark + ';">국외</font>';
      } else {
        nationGbn = '';
      }
      
      let brand = element.brand.map(rows => {
        return rows.text;
      });

      let productUpload = '';
      if ( element.product_upload == '1' ) {
        productUpload = '<font style="color:' + colorGreen + ';">당사등록</font>';
      } else if ( element.product_upload == '2' ) {
        productUpload = '<font style="color:' + colorDark + ';">업체등록</font>';
      } else {
        productUpload = '';
      }

      let consignType = '';
      if ( element.consign_type == '1' ) {
        consignType = '<font style="color:' + colorGreen + ';">당사부담</font>';
      } else if ( element.consign_type == '0' ) {
        consignType = '<font style="color:' + colorDark + ';">업체부담</font>';
      } else {
        consignType = '';
      }

      contents.push(`
        <tr style="height:20px;">
          <td style="text-align:center;">${element.seq}</td>
          <td style="text-align:center;">${useYn}</td>
          <td style="text-align:center;">${nationGbn}</td>
          <td style="text-align:center;">${element.jungsan_name !== null ? element.jungsan_name : ''}</td>
          <td style="text-align:center;">${element.customer_id !== null ? element.customer_id : ''}</td>
          <td style="text-align:center;">${element.comname !== null ? element.comname : ''}</td>
          <td style="text-align:center;">${brand !== null ? brand : ''}</td>
          <td style="text-align:center;">${element.contact_margin_rate !== null ? element.contact_margin_rate : ''}</td>
          <td style="text-align:center;">${productUpload}</td>
          <td style="text-align:center;">${consignType}</td>
          <td style="text-align:center;">${element.ceoname !== null ? element.ceoname : ''}</td>
          <td style="text-align:center;">${element.ownerhp !== null ? element.ownerhp : ''}</td>
          <td style="text-align:center;">${element.worker !== null ? element.worker : ''}</td>
          <td style="text-align:center;">${element.workerhp !== null ? element.workerhp : ''}</td>
        </tr>
        </tbody>
      `)
    })
    contents.push(`</table>`);

    let today = new Date();

    const fileName = `바이크마트_정산거래처목록_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`;

    this.printExcel(fileName, contents.join(''));
  }
  
  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  /*******************************************************************************
    설  명 : 거래처 정보 저장 시 refresh 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangeCustomer() {
    this.getCustomerList();
  }


  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked( event: any ) {
    this.customerSeq = event.data.seq;
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      search_nation: '',
      search_jungsan: '',
      search_text: '',
      search_use: '',
      product_gbn: ''
    };

    this.getCustomerList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCustomerList();
    }
  }

}
