/*******************************************************************************
  설  명 : 배송 교환/반품/취소 모달
  작성일 : 2021-06-11
  작성자 : 박희정
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbDateStruct, NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { OrderService } from '@app/service/order.service';
import { SiteConfigService } from '@app/service/site.config.service';
import { CustomValidator } from '@app/service/custom.validators';

import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { ProductFindOpenmarketComponent } from '@components/product-find-openmarket/product-find-openmarket.component';

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const CONFIG_KEY = 'DELIVERY';

@Component({
  selector: 'app-order-status-change',
  templateUrl: './order-status-change.component.html',
  styleUrls: ['./order-status-change.component.scss']
})
export class OrderStatusChangeComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() order_mem_no: any;
  @Input() item: any = {};
  @Input() memberInfo: any = {};
  @Input() isPurchase: any;

  private date: NgbDateStruct = this.utilService.getDate('');

  public form: FormGroup;
  public formErrors = {};

  public pay: any = {};

  public changeRefList: any = [];

  public ableQty: number = 0;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      qty: ['', [Validators.required, CustomValidator.numeric] ],
      changeRef: ['', [Validators.required] ],
      changeRef_name: ['', [] ],
      pay_method: ['', [] ],
      reg_gbn: ['4000', [] ],
      sms_yn: [ '0', [] ],
      mem_no: ['', [] ],
      o_name: ['', [] ],
      zipcode: ['', [] ],
      address: ['', [] ],
      address_detail: ['', [] ],
      change_detail: [[], [] ],
      account_seq: ['', [] ],
      return_method: ['', [] ],
      date: [this.date, [Validators.required] ],
      delivery_price: ['', [] ],
      delivery_amt: ['', [] ],
      delivery_overcharge: ['', [] ],
      delivery_free_condition: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private commonService: CommonService,
    private orderService: OrderService,
    private toastrService: ToastrService,
    private siteConfigService: SiteConfigService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();

    this.form.patchValue({
      seq: this.item.seq,
      mem_no: this.item.mem_no,
      zipcode: this.memberInfo.r_zipcode,
      address : this.memberInfo.r_address,
      address_detail: this.memberInfo.r_address_detail,
      o_name: this.memberInfo.o_name,
      account_seq: this.memberInfo.account_seq
    });

 //    console.log(this.item);
 //    console.log(this.isPurchase);
    // refundQty는 음수로 넘어옴
//    this.ableQty = parseInt(this.item.qty) + parseInt(this.item.refundQty || 0);

   if (this.isPurchase === false){
      this.ableQty = parseInt(this.item.qty) + parseInt(this.item.refundQty || 0);
      if ( this.item.out_qty > 0 ) {
        this.ableQty -= ( parseInt(this.item.qty) + parseInt(this.item.refundQty || 0) != this.item.out_qty ) ? parseInt(this.item.out_qty) : 0;
      }

   } else {
     this.ableQty = parseInt(this.item.processable_qty|| 0);
   }
    

    /* // out_qty는 양수로 넘어옴
     if( this.isPurchase === true && this.item.incompleteOutQty > 0 ) {
       this.ableQty -= parseInt(this.item.out_qty) ;
     }

    if( this.isPurchase === false && this.item.out_qty > 0 ) {
      this.ableQty -= ( parseInt(this.item.qty) + parseInt(this.item.refundQty || 0) != this.item.out_qty ) ? parseInt(this.item.out_qty) : 0;
    }
*/
    if( this.ableQty < 0 ) this.ableQty = 0;

    this.getConfig();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.commonService.getCommonListCode('CRK').then( response => {
      if ( response.ResultCode ) {

        this.changeRefList.push({value: '', title: '선택'});

        response.data.forEach( row => {

          if( this.isPurchase == true ) {
            if( row.c1 == 'C' || row.c1 == null ) {
              this.changeRefList.push({
                value: row.common_code,
                title: row.common_name,
                type: row.c1
              });
            }
          } else {
            if( row.c1 == 'R' || row.c1 == 'E' || row.c1 == null ) {
              this.changeRefList.push({
                value: row.common_code,
                title: row.common_name,
                type: row.c1
              });
            }
          }
        });

      } else {
        this.changeRefList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 환경설정 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          delivery_amt: response.data.delivery_amt,
          delivery_overcharge: response.data.delivery_overcharge,
          delivery_free_condition: response.data.delivery_free_condition,
        })
      }
    });
  }

  /*******************************************************************************
    설  명 : 분류 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeSelect() {
    const changeRefund = this.changeRefList.filter(item => item.value === this.form.controls.changeRef.value);

    if( changeRefund[0].value ) {

      let regGbn: any = '';
      switch( changeRefund[0].type ) {
        case 'R': regGbn = '2000'; break;
        case 'E': regGbn = '3000'; break;
        case 'C': regGbn = '4000'; break;
      }
  
      this.form.patchValue({
        reg_gbn: regGbn,
        changeRef_name: changeRefund[0].title
      });
    }
  }


  /*******************************************************************************
    설  명 : 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productAdd() {
    const modalRef = this.modalService.open(ProductFindOpenmarketComponent, optionsXXL);

    modalRef.componentInstance.mem_no = this.order_mem_no;
    modalRef.componentInstance.rowData = {p_code: this.item.product_code};

    modalRef.result.then((result) => {
      if( typeof result != 'undefined' ) {

        let tmp = this.form.controls.change_detail.value;

        const newResult: any = [...tmp];
        result.forEach(obj => {
          const duplicate = tmp.filter(function (item) {
            return (item.product_code === obj.product_code && item.product_property_seq === obj.property_seq );
          });

          if( duplicate.length < 1 ) {
            obj.order_status = 0;
            obj.qty = 1;
            obj.unit_price = obj.consumer_price;
            obj.discount_amt = 0;
            obj.buy_price = obj.buy_price;
            obj.amt = obj.unit_price;
            obj.total_amt = obj.amt;
            obj.product_seq = obj.seq;
            obj.product_property_seq = obj.property_seq;
            obj.seq = null;

            newResult.push(obj);
          }
        });

        this.form.patchValue({ change_detail: newResult });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  clickQty( product: any, value: any ) {
    let calcCount = parseInt(product.qty) + parseInt(value);
    product.qty = calcCount;
  }


  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeQty( product: any, event: any ) {
    if( event.target.value < 1 ) event.target.value = 1;

    if( event.target.value > this.ableQty ) event.target.value = this.ableQty;

    product.qty = event.target.value;
  }

   /*******************************************************************************
    설  명 : 주문정보 상품리스트 선택 삭제
  *******************************************************************************/
  deleteProduct(index) {
    const set: any = this.form.controls.change_detail.value;

    set.splice(index, 1);
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderDetailRefund() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      if( parseInt(this.form.controls.qty.value) === 0 || !this.form.controls.qty.value ) {
        this.toastrService.error('수량을 입력하시기 바랍니다.', '');
      } else if( parseInt(this.form.controls.qty.value) > this.ableQty ) {
        this.toastrService.error('처리 가능한 수량을 초과하였습니다.', '');
      } else {
        if( this.isPurchase == true ) {
          this.orderService.setOrderDetailRefund(this.form).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');
              this.activeModal.close( true );
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });
        } else {
          this.orderService.setOrderDetailReturn(this.form).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');
              this.activeModal.close( true );
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });
        }
      }
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 환불데이터 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCreateOrderRefund() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      if( confirm("환불데이터를 생성하시겠습니까?") ) {
        this.orderService.setCreateOrderRefund(this.form).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.activeModal.close( true );
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
