import { Component, OnInit } from '@angular/core';

import * as FileSaver from 'file-saver';  
import * as XLSX from 'xlsx';  

import { ToastrService } from 'ngx-toastr';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';  

@Component({
  selector: 'app-export-excel',
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.scss']
})
export class ExportExcelComponent implements OnInit {

  constructor(
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  public exportAsExcelFile(json: any[], excelFileName: string, excelSheetName: string = 'sheet1'): void {  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);  
    const workbook: XLSX.WorkBook = { Sheets: { [excelSheetName]: worksheet }, SheetNames: [excelSheetName] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    this.saveAsExcelFile(excelBuffer, excelFileName);  
  }
  public exportAsExcelFileMultipleSheet(json: any[], excelFileName: string, excelSheetName: any[]): void {
    const workbook = XLSX.utils.book_new();
    excelSheetName.map((sheetName: any, index: number) => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[sheetName]);  
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      // const workbook: XLSX.WorkBook = { Sheets: { sheetName: worksheet }, SheetNames: sheetName };  
    });
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);  
  }
  public exportAsExcelFileWithWidth(json: any[], excelFileName: string, excelSheetName: string = 'sheet1', colWidth = [], head = {title: '', space: true}): void {  
    let workSheet: XLSX.WorkSheet
    if(head.title) workSheet = XLSX.utils.sheet_add_aoa( workSheet, [[ head.title ], [] ] );
    workSheet = XLSX.utils.sheet_add_json( workSheet, json, {origin: head.title && head.space ? "A3" : head.title && !head.space ? "A2" : "A1"} );
    if(colWidth.length) workSheet['!cols'] = colWidth.map(e => ({ wpx: e }));
    const workbook: XLSX.WorkBook = { Sheets: { [excelSheetName]: workSheet }, SheetNames: [excelSheetName] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    this.saveAsExcelFile(excelBuffer, excelFileName);  
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {  
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});  
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);  
  }

  public excelToJson($event) {
    const target: DataTransfer = <DataTransfer>($event.target)
    const reader: FileReader = new FileReader()
    return new Promise((res, rej) => {
      reader.onload = async (e: any) => {
        const bstr: string = e.target.result
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'array'})
        const wsname: string = wb.SheetNames[0]
        const ws: XLSX.WorkSheet = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, {header: 1})
        
        const dataParsing = JSON.parse( JSON.stringify(data) )
        // const header = dataParsing.shift()
        res(dataParsing as Array<any>)
      }
      // reader.readAsBinaryString(target.files[0])
      reader.readAsArrayBuffer(target.files[0])
    })
  }

  public excelExportUseAgGrid(gridApi, selected, filename, title) {
    function saveAsExcelFile(buffer: any, fileName: string): void {
       const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
       FileSaver.saveAs( data, fileName );
    }
    function isNumeric(num, opt = null) {
      // 좌우 trim(공백제거)을 해준다.
      num = String(num).replace(/^\s+|\s+$/g, "");
  
      // 모든 10진수 (부호 선택, 자릿수구분기호 선택, 소수점 선택)
      if(typeof opt == "undefined" || opt == null || opt == "1") {
        var regex = /^[+\-]?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
  
      // 부호 미사용, 자릿수구분기호 선택, 소수점 선택
      } else if(opt == "2") {
        var regex = /^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+){1}(\.[0-9]+)?$/g;
  
      // 부호 미사용, 자릿수구분기호 미사용, 소수점 선택
      } else if(opt == "3") {
        var regex = /^[0-9]+(\.[0-9]+)?$/g;
  
      // only 숫자만(부호 미사용, 자릿수구분기호 미사용, 소수점 미사용)
      } else {
        var regex = /^[0-9]$/g;
      }
  
      if( regex.test(num) ) {
        num = num.replace(/,/g, "");
        return isNaN(num) ? false : true;
      } else {
        return false;
      }
    }
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileName: string = filename + '.xlsx';
    const dataList = [];
    
    let selectCheck = ( selected == 'true' ) ? true : false;
    let obj = gridApi.rowModel.nodeManager.allNodesMap;
    let header = gridApi.columnController.gridColumns;
    let columnWidth = [];

    for( const index in obj ) {
      let addCheck = false;

      if( selectCheck ) {
        if( obj[index].selected ) addCheck = true;
      } else {
        addCheck = true;
      }

      if( addCheck ) {
        let tmp = {};
        for( let i in header ) {
          tmp[header[i].colDef.headerName] = obj[index].data[ header[i].colDef.field ];
          columnWidth.push( { wpx: header[i].actualWidth } );
        }
        dataList.push( tmp );
      }
    }

    if(dataList.length < 1) {
      this.toastrService.error( '엑셀로 출력할 데이터가 없습니다.', '' );
      return false;
    } else {
      var worksheet: XLSX.WorkSheet;

      // 제목 추가
      worksheet = XLSX.utils.sheet_add_aoa( worksheet, [ [ title ], [] ] );

      let index = 0;
      for(let item of dataList ) {
        for(let key in item ) {
          if( isNumeric( item[key] ) ) {
            dataList[index][key] = parseInt(String(dataList[index][key]).replace(',',''));
          }
        }
        index++;
      }

      // 데이터 추가
      worksheet = XLSX.utils.sheet_add_json( worksheet, dataList, {origin: "A3"} );
      // 워크북 생성
      var workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      // 컬럼 사이즈 지정
      worksheet['!cols'] = columnWidth;
      // 엑셀 버퍼로 보내기
      var excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      // 파일로 저장
      saveAsExcelFile(excelBuffer, excelFileName);

      return true;
    }
  }



}
