<form [formGroup]="form">

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-group-add">댓글 수정</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="code" class="control-label">댓글</label></th>
      <td>
        <inputEx type="textarea" name="contents" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <ng-container>
        <button type="textarea" class="btn btn-warning btn-small f15" row="5" (click)="updateComment()">수정하기</button>
      </ng-container>
    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="button" class="btn btn-default btn-small ml5 f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
