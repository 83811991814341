import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';

@Component({
  selector: 'app-barcode-input',
  templateUrl: './barcode-input.component.html',
  styleUrls: ['./barcode-input.component.scss']
})
export class BarcodeInputComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public product_seq: number;
  public property_seq: number;
  public product_code: number;
  public product_name: any;
  public color_seq: any;
  public color_name: any;
  public size_seq: any;
  public size_name: any;
  public barcode: any;

  public formBarcode: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formBarcode = this.formBuilder.group({
      product_seq:["", [] ],
      property_seq:["", [] ],
      product_name:["", [] ],
      color_name:["", [] ],
      size_name:["", [] ],
      barcode:["", [] ],
    });

    this.formBarcode.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formBarcode, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    public authService: AuthService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
  }

  /*******************************************************************************
    설  명 : 뷰 표시 완료 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    this.formBarcode.patchValue({
      product_seq: this.product_seq,
      property_seq: this.property_seq,
      product_name: this.product_name,
      color_seq: this.color_seq,
      color_name: this.color_name,
      size_seq: this.size_seq,
      size_name: this.size_name,
      barcode: this.barcode,
    });
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.activeModal.close( this.formBarcode.value );
  }

  /*******************************************************************************
    설  명 : 엔터키 시 별표 제거 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  checkAsterisk( $event ) {
    if( $event.key == 'Enter' ) {
      const tmp = this.formBarcode.controls.barcode.value;
      const barcode = tmp.replace(/\*/g, '');

      this.formBarcode.patchValue({
        barcode: barcode
      });
    }
  }

  /*******************************************************************************
    설  명 : 바코드 생성
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setNewBarcode() {
    if( !this.product_code || this.product_code === null ) {
      this.toastrService.error('상품코드가 없어 생성이 불가합니다.');
      return false;
    }

    let odd_sum: any = 0;
    let evan_sum: any = 0;
    let last_num: any = 0;
    let num: any;
    let new_barcode: any;
    
    num = new_barcode = '8' + this.utilService.lpad(this.product_code, 11, '0');  // 앞에 8을 붙여 12자리 생성

    for( var i = 1; i <= 12; i++ ) {
      if( i % 2 == 0 ) {
        evan_sum += num % 10;
      } else {
        odd_sum += num % 10;
      }
      
      num = parseInt(num) / 10;
    }

    last_num = 10 - ( (parseInt(evan_sum) + parseInt(odd_sum) * 3) % 10 );

    if( last_num >= 10 ) last_num = 0;

    new_barcode = new_barcode + "" + last_num;

    if( new_barcode.length > 13 ) {
      this.toastrService.error('신규 바코드 생성에 실패했습니다.');
      return false;
    }

    this.formBarcode.patchValue({
      barcode: new_barcode
    });

    // 여러개 생성 시
    // let tmp: any = [];
    // let barcode: any = this.formBarcode.controls.barcode.value;
    
    // if( barcode.length > 0 ) tmp.push(barcode);

    // tmp.push(new_barcode);
    // this.formBarcode.get('barcode').setValue( tmp.join('\n') );
  }
  
}
