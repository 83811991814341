/*******************************************************************************
  설  명 : 관리자 마일리지 지급
  작성일 : 2021-07-16
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';


@Component({
  selector: 'app-manager-mileage',
  templateUrl: './manager-mileage.component.html',
  styleUrls: ['./manager-mileage.component.scss']
})
export class ManagerMileageComponent implements OnInit {
  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() orderSeq: any;

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      mem_no: ['', [Validators.required] ],
      mileage: ['', [Validators.required] ],
      reason: ['', [Validators.required, Validators.maxLength(100)] ],
      order_seq: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      mem_no: this.memberNo,
      order_seq: this.orderSeq
    });
  }

  /*******************************************************************************
    설  명 : 마일리지 적립
  *******************************************************************************/
  setManagerMileage() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if( this.form.valid ) {
      this.memberService.setManagerMileage( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success('마일리지 적립에 성공하였습니다.');
          this.activeModal.close();
        } else {
          this.toastrService.error('마일리지 적립에 실패하였습니다.');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
