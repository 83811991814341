import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { CustomValidator } from '@app/service/custom.validators';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { BasicService } from '@app/service/basic.service';
import { BrandService } from '@app/service/brand.service';

import { MemberFindComponent } from '@components/member-find/member-find.component';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss']
})
export class CustomerAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public dealTypeList: any = [];
  public originEmployeeList: any = [];
  public employeeList: any = [];
  public deliveryList: any = [];
  public brandList: any = [];
  public productGbnList: any = [];

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public summernoteConfig = {
    placeholder: '',
    tabsize: 2,
    height: 150,
    uploadImagePath: '',
    toolbar: [
        // [groupName, [list of button]]
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'link', 'video', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  public changeWorkerFunc: any;   // 담당자 변경시
  public addMemberFunc: any;
  public onChangedFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      nation_gbn: ['D', [Validators.required] ],
      customer_id: ['', [Validators.required, CustomValidator.blank] ],
      password: ['', [] ],
      confirmPassword: ['', [] ],
      comname: ['', [Validators.required] ],
      product_gbn: ['2000', [Validators.required] ],
      brand: [[], [] ],
      ceoname: ['', [Validators.required] ],
      ownerhp: ['', [] ],
      worker_mem_no: [0, [] ],
      worker: ['', [] ],
      workerhp: ['', [] ],
      tel: ['', [] ],
      fax: ['', [] ],
      zipcode: ['', [] ],
      address: ['', [] ],
      address_detail: ['', [] ],
      homepage: ['', [] ],
      get_page: ['', [] ],
      use_yn: ['', [] ],
      descr: ['', [] ],
      jungsan: ['', [] ],
      customer_type: ['', [] ],
      consign_type: ['', [] ],
      consign_fee: ['', [] ],
      consign_limit_amt: ['', [] ],
      consign_com: ['', [] ],
      consign_com_name: ['', [] ],
      consign_com_ename: ['', [] ],
      product_upload: ['', [] ],
      jungsan_date: [null, [] ],
      margin: ['', [] ],
      account_holder: ['', [] ],
      event_discount: ['', [] ],
      key_index: ['', [] ],
      busi_num: ['', [] ],
      order_sms: ['', [] ],
      bank: ['', [] ],
      bank_account: ['', [] ],
      contact_date: [null, [] ],
      contact_emp: ['', [] ],
      contact_margin_rate: ['', [] ],
      contact_shipping_com: ['', [] ],
      contact_shipping_fee: ['', [] ],
      contact_calc_date: [null, [] ],
      contact_exchange_cond: ['', [] ],
      contact_return_cond: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private basicService: BasicService,
    private brandService: BrandService,
    private modalService: NgbModal,
  ) {
    this.buildForm();

    this.addMemberFunc = this.searchMember.bind(this);
    this.onChangedFunc = this.onChanged.bind(this);

    // this.changeWorkerFunc = this.onChangeWorkerFunc.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    this.getBrandList();
    // this.getEmployeeList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 거래형태
    this.commonService.getCommonListCode('DLT').then( response => {
      if ( response.ResultCode ) {
        this.dealTypeList = response.data;
      } else {
        this.dealTypeList = [];
      }
    });

    // 배송업체
    this.commonService.getCommonListCode('DLC').then( response => {
      if ( response.ResultCode ) {
        this.deliveryList.push({value: '', title: '배송업체를 선택하세요.'});

        response.data.forEach( row => {
          this.deliveryList.push({
            value: row.common_code,
            title: row.common_name,
            etitle: row.common_ename
          });
        });

      } else {
        this.deliveryList = [];
      }
    });

    // 매입상품구분
    this.commonService.getCommonListCode('PPG').then( response => {
      if ( response.ResultCode ) {
        this.productGbnList = response.data;
      } else {
        this.productGbnList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    let search = {};
    this.brandService.getBrandList( search ).then( response => {
      if ( response.ResultCode ) {
        const data: any = [];

        for(let item of response.data ) {
          data.push({
            id: item.seq,
            text: item.brand_name
          });
        }

        this.brandList = data;
      } else {
        this.brandList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        mem_no: result.data.mem_no,
        customer_id: result.data.id,
        zipcode: result.data.zipcode,
        address: result.data.address,
        address_detail: result.data.address_detail
      });

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChanged( event: any ): void {
    this.form.patchValue({
      brand: event
    });
  }
  
  /*******************************************************************************
    설  명 : 배송업체 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeDelivery() {
    const delivery = this.deliveryList.filter(item => item.value === this.form.value.consign_com);

    if( delivery[0].value ) {
      this.form.patchValue({
        consign_com_name: delivery[0].title,
        consign_com_ename: delivery[0].etitle
      });
    }
  }

  /*******************************************************************************
    설  명 : 직원 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // getEmployeeList() {
  //   this.employeeService.getEmployeeList().then( response => {
  //     if( response.ResultCode ) {
  //       this.originEmployeeList = response.data;

  //       this.employeeList.push({
  //         title: '선택',
  //         value: 0
  //       });

  //       this.originEmployeeList.forEach(item => {
  //         this.employeeList.push({
  //           title: item.name,
  //           value: item.mem_no
  //         });
  //       });

  //     } else {
  //       this.toastrService.error( response.ResultMessage, '');
  //     }
  //   });
  // }

  /*******************************************************************************
    설  명 : 거래처 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCustomerInfoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setCustomerInfoSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  // /*******************************************************************************
  //   설  명 : 담당자 변경 시 처리
  //   입력값 : 없음
  //   리턴값 : 없음
  // *******************************************************************************/
  // onChangeWorkerFunc() {
  //   if( this.form.controls.worker_mem_no.value != 0 ) {
  //     const memNo = this.form.controls.worker_mem_no.value;
  //     const selected = this.originEmployeeList.filter(function (item) {
  //       return item.mem_no === memNo;
  //     });

  //     this.form.patchValue({
  //       worker: selected[0].name,
  //       workerhp: selected[0].hp
  //     });
  //   } else {
  //     this.form.patchValue({
  //       worker: '',
  //       workerhp: ''
  //     });
  //   }
  // }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

}