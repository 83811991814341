<div class="fl w100p form-inline">
  <div class="btn-group">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.sena_status==''}" (click)="search.sena_status='';getProductSenaList();">전체</button>
    <button *ngFor="let item of statusList" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.sena_status==item.common_code}" (click)="search.sena_status=item.common_code;getProductSenaList();">{{item.common_name}}</button>
  </div>
</div>

<div class="cb"></div>

<div class="fl w70p form-inline mt5">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
    <i class="material-icons-outlined">refresh</i>
  </button>
  <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductSenaList()">
    <i class="material-icons-outlined">search</i>
  </button>

  <select [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
    <option value="sn1">시리얼넘버1</option>
    <option value="sn2">시리얼넘버2</option>
    <option value="buyer_id">구매자아이디</option>
    <option value="buyer_name">구매자명</option>
    <option value="product_seq">상품번호</option>
    <option value="product_name">상품명</option>
  </select>

  <input type="text" size="30" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="검색어 입력" />

  <span class="ml5">- 검색 : {{search.totalCount | number}} 건</span>
</div>

<div class="fr w30p right">
  <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="343de5e42e834652afa8ffd4bce63f43" [btnGroup]="1"></btn-ag-grid-save>
  <ag-grid-excel [gridApi]="gridApi" selected="true" filename="세나관리내역" title="세나관리내역"></ag-grid-excel>
  <ag-grid-excel [gridApi]="gridApi" selected="false" filename="세나관리내역" title="세나관리내역"></ag-grid-excel>
</div>

<div class="cb"></div>

<div class="mt5" style="height:calc(100% - 110px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="senaList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [getRowHeight]="getRowHeight"
    [suppressRowClickSelection]="true"

    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
  <section class="pagination mt10" *ngIf="search.totalCount">
    <ngb-pagination
      [(page)]="search.pageNo"
      [pageSize]="search.pageRow"
      [collectionSize]="search.totalCount"
      [maxSize]="20"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>
</div>
