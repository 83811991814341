<div class="fl w70p form-inline">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
    <i class="material-icons-outlined">refresh</i>
  </button>
  <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getRegistrationSenaList()">
    <i class="material-icons-outlined">search</i>
  </button>

  <input type="text" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="세나시리얼" />

  <div class="btn-group ml5">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isRegistration==''}" (click)="searchIsRegistration('')">전체</button>
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isRegistration=='1'}" (click)="searchIsRegistration('1')">등록</button>
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isRegistration=='0'}" (click)="searchIsRegistration('0')">미등록</button>
  </div>

</div>

<div class="fr w30p right">
  <button type="button" class="btn btn-danger btn-small ml5" (click)="setRegistrationSenaDelete()">삭제</button>
  <button type="button" class="btn btn-primary btn-small ml5" (click)="setUnregistrationSena()">등록취소</button>
  <button type="button" class="btn btn-primary btn-small ml5" (click)="setRegistrationSena()">등록처리</button>
</div>

<div class="cb"></div>

<div class="mt5" style="height:calc(100% - 35px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="registrationSenaList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (gridReady)="onGridReady($event)"
    (filterChanged)="onFilterChanged($event)"
    >
  </ag-grid-angular>
</div>
