<form [formGroup]="form">
  <section>
    <header class="mt5">
      <div class="fl w50p">
        <h4 class="fb mt5 mb6">SNS연동</h4>
      </div>
      <div class="fr w50p right">
        <button type="button" class="btn btn-primary btn-small" (click)="submit()">저장</button>
      </div>
      <div class="cb"></div>
    </header>  
  
    <!-- 네이버 -->
    <section>
      <h4 class="mb5 fb">네이버 로그인 연동</h4>

      <table class="table table-bordered table-small1 mb0">
      <caption class="none">네이버 로그인 연동</caption>
      <colgroup>
        <col style="width:10%" />
        <col style="width:23%" />
        <col style="width:10%" />
        <col style="width:23%" />
        <col style="width:10%" />
        <col style="width:23%" />
      </colgroup>
    
      <tbody>
      <tr>
        <th scope="row"><label for="naver_use_yn">네이버 연동 사용여부</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.naver_use_yn.value == '1'}" (click)="form.patchValue({naver_use_yn: '1'})">사용</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.naver_use_yn.value == '0'}" (click)="form.patchValue({naver_use_yn: '0'})">사용안함</button>
          </div>
          <inputEx type="hidden" name="naver_use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="naver_client_id">네이버 아이디</label></th>
        <td><inputEx type="text" name="naver_client_id" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
        <th scope="row"><label for="naver_client_secret">네이버 secret key</label></th>
        <td><inputEx type="text" name="naver_client_secret" [formGroup]="form" [formErrors]="formErrors" maxlength="100"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="naver_callback_url">네이버 콜백URL</label></th>
        <td colspan="5"><inputEx type="text" name="naver_callback_url" [formGroup]="form" [formErrors]="formErrors" maxlength="100"></inputEx></td>
      </tr>
      </tbody>
      </table>
    </section>  
    <!-- 네이버 -->

    <!-- 카카오 -->
    <section class="mt10">
      <h4 class="mb5 fb">카카오 로그인 연동</h4>

      <table class="table table-bordered table-small1 mb0">
      <caption class="none">카카오 로그인 연동</caption>
      <colgroup>
        <col style="width:10%" />
        <col style="width:23%" />
        <col style="width:10%" />
        <col style="width:23%" />
        <col style="width:10%" />
        <col style="width:23%" />
      </colgroup>
    
      <tbody>
      <tr>
        <th scope="row"><label for="kakao_use_yn">카카오 연동 사용여부</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.kakao_use_yn.value == '1'}" (click)="form.patchValue({kakao_use_yn: '1'})">사용</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.kakao_use_yn.value == '0'}" (click)="form.patchValue({kakao_use_yn: '0'})">사용안함</button>
          </div>
          <inputEx type="hidden" name="kakao_use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="kakao_id">카카오 아이디</label></th>
        <td><inputEx type="text" name="kakao_id" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
        <th scope="row"><label for="kakao_app_key">카카오 앱 키</label></th>
        <td><inputEx type="text" name="kakao_app_key" [formGroup]="form" [formErrors]="formErrors" maxlength="100"></inputEx></td>
      </tr>
      </tbody>
      </table>
    </section>  
    <!-- 카카오 -->

    <!-- 페이스북 -->
    <section class="mt10">
      <h4 class="mb5 fb">페이스북 로그인 연동</h4>

      <table class="table table-bordered table-small1 mb0">
      <caption class="none">페이스북 로그인 연동</caption>
      <colgroup>
        <col style="width:10%" />
        <col style="width:23%" />
        <col style="width:10%" />
        <col style="width:23%" />
        <col style="width:10%" />
        <col style="width:23%" />
      </colgroup>
    
      <tbody>
      <tr>
        <th scope="row"><label for="facebook_use_yn">페이스북 연동 사용여부</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.facebook_use_yn.value == '1'}" (click)="form.patchValue({facebook_use_yn: '1'})">사용</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.facebook_use_yn.value == '0'}" (click)="form.patchValue({facebook_use_yn: '0'})">사용안함</button>
          </div>
          <inputEx type="hidden" name="facebook_use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="facebook_id">페이스북 앱 아이디</label></th>
        <td><inputEx type="text" name="facebook_id" [formGroup]="form" [formErrors]="formErrors" maxlength="50"></inputEx></td>
        <th scope="row"><label for="facebook_secret_key">페이스북 시크릿 코드</label></th>
        <td><inputEx type="text" name="facebook_secret_key" [formGroup]="form" [formErrors]="formErrors" maxlength="100"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="facebook_version">페이스북 앱 버전</label></th>
        <td><inputEx type="text" name="facebook_version" [formGroup]="form" [formErrors]="formErrors" maxlength="20"></inputEx></td>
        <th></th>
        <td></td>
        <th></th>
        <td></td>
      </tr>
      </tbody>
      </table>
    </section>  
    <!-- 페이스북 -->

    <!-- 구글(앱) -->
    <section class="mt10">
      <h4 class="mb5 fb">안드로이드 앱(구글) 로그인 연동</h4>
    </section>
    <!-- 구글(앱) -->

    <!-- 애플(앱) -->
    <section class="mt10">
      <h4 class="mb5 fb">iOS 앱(애플) 로그인 연동</h4>
    </section>
    <!-- 애플(앱) -->
  </section>
</form>
  
