/*******************************************************************************
  설  명 : 게시판 답변달기
  작성일 : 2020-02-07
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';

import { BoardService } from '@app/service/board.service';

import { ToastrService } from 'ngx-toastr';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-board-reply',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.scss']
})
export class ProductReplyComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public boardData: any = {};
  public memberInfo: any = {};
  public isSms:any = '0'; 

  public params: any = {
    seq: '',
    status: '',
    group: 'comment',
    text: '',
    row: 15,
    no: 1,
  };

  public form: FormGroup;
  public formErrors = {
    answer: ''
  };

  public baseURL = config.baseUrl;

  public summernoteConfig: any = config.summernoteConfig;

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', []],
      comment:["", [Validators.required, Validators.maxLength(255)] ],
      product_name:["", [Validators.required] ],
      name: ["", [Validators.required] ],
      answer: ["", [Validators.required] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService,
    private formBuilder: FormBuilder,
    private authService: AuthService

  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    ngOnInit() {
      this.authService.getLoginInfo.subscribe(data => {
        this.memberInfo = data;
      }).unsubscribe();
  
      this.activatedRoute.queryParams.subscribe(params => {
        this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;
        this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
        this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
        this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
        this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;
        this.params.status = ( typeof params.status == 'undefined' || params.status ) ? '' : params.status;
      }).unsubscribe();

      this.getProductQnaDetail();
    }



    /*******************************************************************************
      설  명 : 상품문의 상세정보 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
      getProductQnaDetail() {
        this.boardService.getProductQnaDetail( this.params.seq ).then( response => {
          if( response.ResultCode ) {
            this.boardInfo = response.data;
            this.form.patchValue( this.boardInfo );

            if( this.form.value.answer !== null ) {
              this.form.patchValue({
                answer: this.form.value.answer
              })
            } else {
              this.form.patchValue({
                answer: '<br />= [답변] =======================================================================================================================<br /><br />바이크마트와 함께해 주셔서 감사합니다.<br /><br />즐거운 하루 보내십시오.'
              });
            }

          } else {
            this.toastrService.error(response.ResultMessage);
          }
        });
      }
  
  
    /*******************************************************************************
      설  명 : 게시글 저장
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    submit() {
      this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
  
      if(this.form.valid) {
        this.boardService.productReply(this.form).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
           
            this.router.navigate(
            ['/board/productQuestion'],
            {
              relativeTo: this.activatedRoute,
              queryParams: this.params,
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
  
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      } else {
        this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      }
    }
  
  
    /*******************************************************************************
      설  명 : 목록으로 되돌아가기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    goList() {
      this.router.navigate(
      ['/board/productQna'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }
  