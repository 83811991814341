/*******************************************************************************
  설  명 : 구매입고
  작성일 : 2019-12-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';

import * as moment from 'moment';

import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@app/components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-stock-purchase',
  templateUrl: './stock-purchase.component.html',
  styleUrls: ['./stock-purchase.component.scss']
})
export class StockPurchaseComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number; // 0 = 추가, else = 수정
  public check: any;  // 'S' = stock_history.seq, 'P' = purchase_order.seq

  public title: string = '추가';

  public formPurchase: FormGroup;
  public formErrors = {};

  purchaseDetailList: any = [];
  public warehouseList: any = [];

  public addPurchaseFunc: any;         // 발주서 검색

  selectedRows: number = 0;

  public wareHouse_seq: any = '';

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  public components: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let date: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.formPurchase = this.formBuilder.group({
      seq:["", [] ],
      purchase_order_seq:["", [Validators.required] ],
      gbn_name:["", [Validators.required] ],
      date:["", [Validators.required] ],
      customer_name:["", [Validators.required] ],
      customer_seq:["", [] ],
      memo:["", [] ],
    });

    this.formPurchase.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formPurchase, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private agGridExComponent: AgGridExComponent,
  ) {
    this.buildForm();

    this.addPurchaseFunc = this.searchPurchase.bind(this);

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 부모창에서 보낸 창고 리스트를 표시하기 위해서 consturctor 가 아닌 ngOnInit에서 컬럼 선언
    this.columnDefs = [
      {headerName: '선택', field: 'seq', hide: true },
      {headerName: '카테고리', field: 'parent_category_name', width: 100, cellClass: 'cp left' },
      {headerName: '상품코드', field: 'product_seq', hide:true },
      {headerName: '상품명', field: 'product_name', width: 120, cellClass: 'cp left'},
      {headerName: '수량', field: 'qty', width: 80, cellClass: 'cp right' },
      {headerName: '입고', field: 'in_qty', width: 80, cellClass: 'cp right' },
      {headerName: '입고수량', field: 'now_qty', width: 80, cellClass: 'cp right ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.now_qty);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
        cellEditor: "numericCellEditor",
        cellStyle: function(params) {
          if (params.value > parseInt( params.data.qty ) - parseInt( params.data.in_qty ) ) {
              //mark police cells as red
              return {color: 'red', backgroundColor: '#fff2f2'};
          } else {
              return {color: 'black', backgroundColor: '#fff'};
          }
        }
      },
      {headerName: '입고일자', field: 'in_date', width: 120, cellClass: 'cp center ag-cell-edit', editable: true, cellEditor: "datePicker" },
      {headerName: '입고창고', field: 'warehouse_in_seq', width: 120, cellClass: 'cp center ag-cell-edit', editable: true,
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.warehouseList, // 부모창에서 보낸 창고 리스트
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter: function(params) {

          let selected: any = null;
          for( let item of params.column.colDef.cellEditorParams.values ) {
            if( item.seq == params.data.warehouse_in_seq ) selected = item;
          }

          return (selected == null ) ? '' : selected.name;
        },
      },
      {headerName: '입고위치', field: 'in_date', width: 120, cellClass: 'cp center ag-cell-edit', editable: true, cellEditor: "datePicker" },

    ];
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 발주서 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchPurchase() {
  }

}
