<section class="mt10" style="height:745px;">
  <form [formGroup]="form">
    <div class="fl pt10">
      <b>협력점 월별매출 상세</b>
    </div>
    <div class="fr form-inline">
      <label for="year">년도</label>
      <inputEx type="select" name="year" class="ml5" [formGroup]="form" [formErrors]="formErrors" [data]="yearData" (change)="changeYear($event)"></inputEx>
      <button type="submit" class="btn btn-primary btn-small ml5">검색</button>
    </div>
    <div class="cb"></div>
  </form>

  <ag-grid-angular #grid class="ag-theme-balham" style="margin-top:10px; width: 100%;height: calc(100% - 40px);" [gridOptions]="gridOptions"></ag-grid-angular>
</section>
