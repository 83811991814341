/*******************************************************************************
  설  명 : 현황관리 - 매출목표 & 데이터 관리
  작성일 : 2020-12-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { StatisticService } from '@app/service/statistic.service';
import * as moment from 'moment';

@Component({
  selector: 'app-statistic-sales-goal',
  templateUrl: './statistic-sales-goal.component.html',
  styleUrls: ['./statistic-sales-goal.component.scss']
})
export class StatisticSalesGoalComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    yearSelect: moment().format('YYYY')
  };

  public years: [];

  public dataList: any = [];
  public commonType: any = [];


  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  grid: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private statisticService: StatisticService
  ) {

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: this.search.yearSelect + '년도', field: 'stm_name', colId:'yearname', width: 200, cellClass: 'cp center',
        rowSpan: rowSpan, cellClassRules: { 'show-cell': 'value !== undefined' },
        valueGetter: function(params) {
          // if( params.data.stg_code == '1000' ) return params.data.stm_name;
          if( params.data.stg_code == '1000' ) return `<div style="width: 100%; height: 100%; overflow: hidden;"><div style="width: 50%; float: left; border-right: 1px solid gray; text-align: center;">${params.data.stm_name}</div><div style="width: 50%; float: left;"><div style="border-bottom: 1px solid gray; text-align: center; height: 50%; line-height: initial;">매출목표</div><div style="text-align: center; height: 50%; line-height: initial;">전년실적</div></div><div>`
          else return '';
        },
        cellRenderer: 'agGridHtmlComponent',
      },
      { headerName: '1월', field: 'jan', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '2월', field: 'feb', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '3월', field: 'mar', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '4월', field: 'apr', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '5월', field: 'may', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '6월', field: 'jun', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '7월', field: 'jul', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '8월', field: 'aug', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '9월', field: 'sep', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '10월', field: 'oct', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '11월', field: 'nov', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '12월', field: 'dece', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, editable: true },
      { headerName: '합계', field: 'total', width: 118, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter }
    ];


    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };


    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getStatisticGoal();
    this.getYearsSelect();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }




  /*******************************************************************************
    설  명 : 매출 목표 & 데이터 관리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStatisticGoal() {
    this.statisticService.getStatisticGoal( this.search ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
        
        this.dataList.push(...[1000, 3000].map((item: any) => ({stg_code: item, stm_name: '합계'})).map((item: any) => {
          for(const key in Array(...response.data).shift()) if(key.replace(/^(stg|stm)_(code|name)$/, '')) item[key] = null
          for(const key in item) item[key] = item[key] || ((stgCode: number, momth: string) => 
          response.data.reduce((a: number, c: any) => a + (c.stg_code == stgCode ? parseInt(c[momth] || 0) : 0), 0))(item.stg_code, key)
          return item
        }))

      } else {
        this.dataList = [];
      }
    });

  }

  /*******************************************************************************
    설  명 : 년도 선택박스 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectOption(event) {
    if( this.search.yearSelect = event.target.value ) {
      const columnDefs = this.columnDefs;
      columnDefs[0].headerName = this.search.yearSelect + '년도';
      this.gridApi.setColumnDefs(columnDefs);
      this.getStatisticGoal();
    }
  }


  /*******************************************************************************
    설  명 : 년도 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getYearsSelect() {
    this.statisticService.getYearsSelect().then( response => {
      if( response.ResultCode ) {
        this.years = response.data;

      } else {
        this.search.yearSelect = [];
      }
    })
  }




  /*******************************************************************************
    설  명 : 매출 목표 & 데이터 관리 수정하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setStatisticGoal() {

    const data: any = [];
    this.gridApi.forEachNode( node => data.push(node.data));

    this.statisticService.setStatisticGoal( data ).then ( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '매출 목표 & 데이터 관리');
        this.getStatisticGoal();
      } else {
        this.toastrService.error( response.ResultMessage, '매출 목표 & 데이터 관리');
      }

    });
  }


  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      yearSelect:moment().format('YYYY')
    };
    this.getStatisticGoal();
  }

}


function rowSpan(params) {
  if( params.data.stg_code == '1000' )
    return 2;
  else
    return 0;
}
