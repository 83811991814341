      <form name="form" id="form" [formGroup]="form" form-error-focus novalidate>
        <!-- Modal header -->
        <div class="modal-header">
          <h4 class="modal-title bold">결재단계 추가</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
        </div>
        <!-- Modal header -->

        <!-- Modal body -->
        <div class="modal-body">
          <div *ngIf="type == false">
            <div class="fl w15p pt5 f13">
              <label for="subject">결재단계 제목</label> :
            </div>
            <div class="fl w85p">
              <inputEx type="text" name="subject" id="subject"
                maxlength="50"  placeholder="결재단계 제목을 입력하세요."
                [formGroup]="form" [formErrors]="formErrors"
              >
              </inputEx>
            </div>
            <div class="cb"></div>
          </div>

          <div class="pb5" *ngIf="type == false">
            <div class="fl w15p pt5 f13">
              <label for="subject">자동반영 결재양식</label> :
            </div>
            <div class="fl w85p">
              <inputEx 
                type="select" 
                name="document_type"
                [formGroup]="form" 
                [formErrors]="formErrors" 
                placeholder="자동반영 안함" 
                [data]="documentList" 
                (change)="onValueSelectChange($event)"
              ></inputEx>
            </div>
            <div class="cb"></div>
          </div>

          <!-- 부서/사원리스트 //-->
          <div class="fl w30p" style="height:474px;">
            <app-tree-view
              [useSort]='false'
              [nodeData]='nodeData'
              [useAddNode]='false'
              (onSelected)='onSelectNode($event)'
            ></app-tree-view>
          </div>
          <!-- 부서/사원리스트 //-->

          <div class="fr w70p pl10">
          
			      <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
			        <mat-tab label="결재">
			          <div *ngIf="activeTab==0" class="mt5" style="height: 420px">
								  <ag-grid-angular
								    #myGrid
								
								    style="width: 100%;height:100%;"
								    class="ag-theme-balham"
								
								    [columnDefs]="columnDefs"
								    [defaultColDef]="defaultColDef"
								    [rowData]="form.value.approval"
								    [pagination]="false"
                    [rowDragManaged]="true"
                    [animateRows]="true"
                    [enableMultiRowDragging]="true"
								    [domLayout]="domLayout"
								    [overlayNoRowsTemplate]="noRowsTemplate"
								    [frameworkComponents]="frameworkComponents"
								    [rowSelection]="rowSelection"
								    
                    (gridReady)="onGridReady($event)"
                    (filterChanged)="onFilterChanged($event)"
								    >		
								  </ag-grid-angular>	          
			          </div>
			        </mat-tab>
			        <mat-tab label="협조">
                <div *ngIf="activeTab==1" class="mt5" style="height: 420px">
                  <ag-grid-angular
                    #myGrid
                
                    style="width: 100%;height:100%;"
                    class="ag-theme-balham"
                
                    [columnDefs]="columnDefs1"
                    [defaultColDef]="defaultColDef"
                    [rowData]="form.value.agreement"
                    [pagination]="false"
                    [rowDragManaged]="true"
                    [animateRows]="true"
                    [enableMultiRowDragging]="true"
                    [domLayout]="domLayout"
                    [overlayNoRowsTemplate]="noRowsTemplate"
                    [frameworkComponents]="frameworkComponents"
                    [rowSelection]="rowSelection"
                    
                    (gridReady)="onGridReady1($event)"
                    (filterChanged)="onFilterChanged1($event)"
                    > 
                  </ag-grid-angular>                
                </div>
			        </mat-tab>
			        <mat-tab label="참조">
                <div *ngIf="activeTab==2" class="mt5" style="height: 420px">
                  <ag-grid-angular
                    #myGrid
                
                    style="width: 100%;height:calc(100% - 50px);"
                    class="ag-theme-balham"
                
                    [columnDefs]="columnDefs2"
                    [defaultColDef]="defaultColDef"
                    [rowData]="form.value.reference"
                    [pagination]="false"
                    [domLayout]="domLayout"
                    [rowDragManaged]="true"
                    [animateRows]="true"
                    [enableMultiRowDragging]="true"
                    [overlayNoRowsTemplate]="noRowsTemplate"
                    [frameworkComponents]="frameworkComponents"
                    [rowSelection]="rowSelection"

                    (gridReady)="onGridReady2($event)"
                    (filterChanged)="onFilterChanged1($event)"                    
                    >  
                  </ag-grid-angular>
                  <p class="mt10 mb0 f13">※ 참조단계는 순서와 상관없이 결재완료 후 참조됩니다.</p>               
                </div>
			        </mat-tab>
			      </mat-tab-group>          
          </div>

          <div class="cb"></div>

        </div>
        <!-- Modal body -->

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" *ngIf="type == false" class="btn btn-primary btn-small f15 mr4" (click)="submit()">저장</button>
          <button type="button" *ngIf="type == true" class="btn btn-primary btn-small f15 mr4" (click)="addApproval()">저장</button>
          <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
        </div>
        <!-- Modal footer -->
      </form>
