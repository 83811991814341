<div class="modal-header">
  <h4 class="modal-title fb mb0">초성이벤트 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <div class="">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">항목정보</h4>
    </div>
    <div class="fr w50p right">

    </div>
    <div class="cb"></div>
  </div>

  <form [formGroup]="form">
    <table class="table table-bordered table-small1 mb0">
      <caption class="none">항목정보</caption>
      <colgroup>
        <col style="width:15%;" />
        <col style="width:35%;" />
        <col style="width:15%;" />
        <col style="width:35%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="question">문제</label></th>
        <td>
          <inputEx type="text" name="question" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          <div *ngIf="form.controls.question.errors && form.controls.question.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
        </td>
        <th scope="row"><label for="answer">정답</label></th>
        <td>
          <inputEx type="text" name="answer" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          <div *ngIf="form.controls.answer.errors && form.controls.answer.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="hint">힌트</label></th>
        <td colspan="3">
          <inputEx type="textarea" name="hint" [rows]="10" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="start_date">시작일</label></th>
        <td>
          <input type="text" readonly="readonly" [(ngModel)]="startDate" [ngModelOptions]="{standalone: true}" size="13" class="form-control form-control-small calendar-input m15" placeholder="시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
          <ng-template #footerTemplate1>
            <hr class="my-0">
            <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
            <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
          </ng-template>
        </td>
        <th scope="row"><label for="end_date">종료일</label></th>
        <td>
          <input type="text" readonly="readonly" [(ngModel)]="endDate" [ngModelOptions]="{standalone: true}" size="13" class="form-control form-control-small calendar-input m15" placeholder="종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
          <ng-template #footerTemplate2>
            <hr class="my-0">
            <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
            <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
          </ng-template>
        </td>
      </tr>
      </tbody>
      </table>
    </form>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">

    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="submit()">저장하기</button>
      <button type="button" class="btn btn-danger btn-small ml5" *ngIf="params.seq || '' !== ''" (click)="delete()">삭제하기</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
    </div>
  </div>
