  <mat-tab-group>
    <mat-tab style="min-width:100px;" label="기본정보">
      <app-basic-company></app-basic-company>
    </mat-tab>
    <mat-tab label="계좌정보">
      <app-basic-account></app-basic-account>
    </mat-tab>
    <mat-tab label="SNS 연동">
      <app-basic-sns></app-basic-sns>
    </mat-tab>
    <mat-tab style="min-width:100px;" label="알림관리">
      <app-basic-alarmmanager></app-basic-alarmmanager>
    </mat-tab>
    <mat-tab label="운영방식">
    </mat-tab>
    <mat-tab label="전자결제">
      <app-basic-payment></app-basic-payment>
    </mat-tab>
    <!--mat-tab label="무통장">
      <app-basic-bankbook></app-basic-bankbook>
    </mat-tab//-->
    <mat-tab label="회원">
      <app-basic-member></app-basic-member>
    </mat-tab>
    <mat-tab label="주문">
    </mat-tab>
    <mat-tab label="마일리지">
    </mat-tab>
    <mat-tab label="배송비">
      <app-basic-delivery></app-basic-delivery>
    </mat-tab>
    <mat-tab label="사방넷연동세팅">
      <app-basic-deliverycompany></app-basic-deliverycompany>
    </mat-tab>
    <mat-tab label="보안">
    </mat-tab>
    <mat-tab label="환율">
      <app-basic-exchange></app-basic-exchange>
    </mat-tab>
  </mat-tab-group>