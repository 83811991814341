<section id="sms">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="30" minSize="20" maxSize="40">
      <div style="height:calc(100% - 75px)">
        <div class="sms-image-div">
          <form [formGroup]="form" class="h100">
            <div class="phone-image">
              <div class="phone-title">
                PUSH 발송양식
              </div>
              <div class="pl20 pt20 pr20 pb5">
                <inputEx name="title" [autofocus]="true" [formGroup]="form" [formErrors]="formErrors" placeholder="PUSH 양식 제목"></inputEx>
              </div>
              <div class="pl20 pr20">
                <inputEx type="select" class="pt5" name="auto_seq" [formGroup]="form" [formErrors]="formErrors" [data]="autoList"></inputEx>
              </div>
              <div>
                <textarea style="height: 185px;" formControlName="contents" rows="10" cols="20"></textarea>
                <div *ngIf="formErrors.contents" class="error-message">{{formErrors.contents}}</div>
                <div class="pl20 pr20 pt10 f12">{{getTextLength(form.controls.contents.value)}} / 80 글자</div>
              </div>
              <div class="pl20 pr20 mt5">
                <inputEx type="text" class="pt5" name="link" [formGroup]="form" [formErrors]="formErrors" placeholder="푸시메시지 링크"></inputEx>
              </div>
              <div class="pl20 pr20 mt5">
                <inputEx type="text" class="pt5" name="query_params" [formGroup]="form" [formErrors]="formErrors" placeholder="링크시 추가 옵션"></inputEx>
              </div>
              <div class="pl20 pr20 pt5">
                <button type="button" class="btn btn-primary btn-small w100p" (click)="submit();">저장하기</button>
              </div>
              <div *ngIf="form.controls.seq.value != ''" class="pl20 pr20 pt5">
                <button type="button" class="btn btn-danger btn-small w100p" (click)="delPushForm();">삭제하기</button>
              </div>
            </div>
          </form>
        </div>
        <div class="sms-explain-div">
          <h4>사용가능 변수</h4>
          <!-- <p>#고객명</p>
          <p>#오늘날짜</p>
          <p>#결제일</p>
          <p>#신청일</p>
          <p>#아이디</p>
          <p>#결제금액</p> -->
        </div>
      </div>
    </as-split-area>

    <as-split-area size="70" minSize="60" maxSize="80">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" (click)="searchInit()" class="btn btn-default btn-small-icon">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" (click)="getBikePushFormList()" class="btn btn-default btn-small-icon ml5">
            <i class="material-icons-outlined">search</i>
          </button>
          <input type="text" [(ngModel)]="search.searchText" size="20" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="제목 or 내용 검색" />
        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="049575c0273f4dfd87fc56bf8449839e" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="addSMSForm()">등록하기</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="pushList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [rowSelection]="rowSelection"

          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
