import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal,NgbActiveModal , NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';
import { MemberFindComponent } from '@components/member-find/member-find.component';
import { CommonService } from '@app/service/common.service';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};


@Component({
  selector: 'app-member-society-history-add',
  templateUrl: './member-society-history-add.component.html',
  styleUrls: ['./member-society-history-add.component.scss']
})
export class MemberSocietyHistoryAddComponent implements OnInit {

  
  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public societySeq: any;
  public seq: any;

  public title: any = '추가';

  public form: FormGroup;
  public formErrors = {};

  public searchMemberFunc: any;

  public gbnList :any = [] ;
  public typeList :any = [] ;
  public reasonList :any = [] ;


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq:['', [Validators.required] ],
      society_seq:['', [Validators.required] ],
      gbn: ['', [Validators.required] ],
      type: ['', [Validators.required] ],
      reason: ['', [] ],
      memo: ['', [] ],
      reg_date: ['', [] ],
      mem_no: ['', [] ],
      member_name: ['', [Validators.maxLength(100)] ],
      order_seq: ['', [] ],
      order_detail_seq: ['', [] ],
      sale_amt: ['', [] ],
      margin_amt: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService , 
    private commonService: CommonService,
    private modalService: NgbModal,
  ) {
    this.buildForm();

    this.searchMemberFunc = this.searchMember.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList('CGR');

   if (this.seq === undefined || this.seq === null) {
    this.seq = ""; 
   } 
   console.log('점검');
   console.log(this.seq);

    this.form.patchValue({
      society_seq: this.societySeq,
      seq: this.seq
    });

    if( this.seq == 0 || this.seq == "") {
      this.title = '추가';

      this.form.patchValue({
        reg_date: this.utilService.getDate(moment().format('YYYY-MM-DD  HH:mm:ss')),
      });

    } else {
      this.title = '수정';
      // 동호회 이력 가져오기
      this.getSocietyHistoryInfo( this.seq );
    }
  }

  /*******************************************************************************
    설  명 : 동호회이력정보 가져오기
    입력값 : seq 동호회이력순번
    리턴값 : 없음
  *******************************************************************************/
  getSocietyHistoryInfo( seq: any ) {
    this.memberService.getSocietyHistoryInfo( seq ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          seq: response.data[0].seq,
          society_seq: response.data[0].society_seq,
          gbn: response.data[0].gbn,
          type: response.data[0].type,
          reason: response.data[0].reason,
          memo: response.data[0].memo,
          reg_date: this.utilService.getDate(response.data[0].reg_date),
          mem_no: response.data[0].mem_no,
          member_name: response.data[0].member_name,
          order_seq: response.data[0].order_seq,
          order_detail_seq: response.data[0].order_detail_seq,
          sale_amt: response.data[0].sale_amt,
          margin_amt: response.data[0].margin_amt
        });

      } else {
        this.toastrService.error( "동호회이력 정보를 가져오는데 실패하였습니다.", "동호회이력 수정" );

        this.activeModal.close(true);
      }
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getCommonList(groupCode:any) {
      // 이벤트 타입
      this.commonService.getCommonListCode(groupCode).then( response => {
        if ( response.ResultCode ) {

            for(const item of response.data ) {
              const data = {
                title: item.common_name,
                value: item.common_code
              };
              this.reasonList.push( data );            
            }
          
        } else {
            this.reasonList.push( [] );          
        }
      });
    }
  
  /*******************************************************************************
    설  명 : 회원 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        mem_no: result.data.mem_no,
        member_name: result.data.name,
      });

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 동호회 이력 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    const required = {
      society_seq: '동호회순번',
      gbn: '구분',
      type: '형태',
      margine_amt: '점수',
    }
    for(const key in this.form.value) {
      if(!this.form.value[key] && required[key]) return this.toastrService.warning(required[key] + '을(를) 입력하세요.')
    }

     this.memberService.setSocietyHistoryInfo(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '동호회 이력 저장');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '동호회 이력 저장');
        }
      });
  }

  /*******************************************************************************
    설  명 : 상사 담당자 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delMemberShopCharge() {
    if( confirm("선택하신 담당자를 삭제하시겠습니까?") ) {
      this.memberService.delMemberShopCharge(this.seq).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '상사 담당자 삭제');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '상사 담당자 삭제');
        }
      });
    }
  }

}
  