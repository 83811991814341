import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateStruct, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { BikeService } from '@app/service/bike.service';
import { CommonService } from '@app/service/common.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';

import * as moment from 'moment';
import { config } from '@app/service/config.service';

import { OrderPaymentComponent } from '@app/page/order/order-payment/order-payment.component';
import { OrderCreditReplaceComponent } from '@page/order/order-credit-replace/order-credit-replace.component';
import { MemberInfoComponent } from '@page/member/member-info/member-info.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-bike-sell',
  templateUrl: './bike-sell.component.html',
  styleUrls: ['./bike-sell.component.scss']
})
export class BikeSellComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 20,
    seq: '',
    searchField: '',
    searchText: '',
    sdate: this.utilService.getDate(moment().subtract(90, 'day')),
    edate: this.utilService.getDate(''),
    searchTerm: this.getDays(90),
    totalCount: 0,
    sellGbn: '',
    bikeStyle: '',
    status: '',
    product_gbn: '',
  };

  public baseURL = config.baseUrl;

  public statistic: any = {
    searchCount: 0,
    directCount: 0,
    auctionCount: 0,
    reverseAuctionCount: 0
  }

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
    {title: '150일', value: this.getDays(150)},
    {title: '200일', value: this.getDays(200)},
    {title: '365일', value: this.getDays(365)},
  ];

  public sellList: any = [];
  public sellGbnList: any = [];
  public bikeStyleList: any = [];
  public sellStatusList: any = [];
  product_gbn_list = [{value: '', label: '전체'}, {value: 'BIKE', label: '오토바이'}, {value: 'SUPPLIES', label: '오토바이용품'}]

  public totalCount: number = 0;

  // 그리드 관련 선언
  public components: any;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;
  isRowSelectable: any;
  rowClassRules: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private bikeService: BikeService,
    private stockOrderComponent: StockOrderComponent,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '매물번호', field: 'seq', width: 92, cellClass: 'cp center' },
      // { headerName: '거래구분', field: 'sell_gbn', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter(params: any) {
      //     let className: any = '';
      //     switch( params.data.sell_gbn ) {
      //       case '1000':
      //         className = 'badge badge-success f12';
      //         break;
      //       case '2000':
      //         className = 'badge cborange f12';
      //         break;
      //       case '3000':
      //         className = 'badge cbpurple f12';
      //         break;
      //       default:
      //         className = '';
      //     }

      //     return '<span class="' + className + '">' + params.data.sell_gbn_name + '</span>';
      //   }
      // },
      { headerName: '상품종류', field: 'product_gbn', width: 110, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.product_gbn == 'SUPPLIES' ? 
          '<span class="badge cborange f12">오토바이용품</span>' : 
          '<span class="badge badge-success f12">오토바이</span>'
        }
      },
      { headerName: '제목', field: 'title', width: 360, cellClass: 'cp' },
      { headerName: '바이크종류', field: 'bike_style_name', width: 100, cellClass: 'cp center' },
      { headerName: '브랜드명', field: 'brand_name', width: 100, cellClass: 'cp center' },
      { headerName: '모델명', field: 'model', width: 190, cellClass: 'cp left' },
      { headerName: '최초가격', field: 'org_price', width: 95, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '판매가격', field: 'sale_price', width: 95, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '수량', field: 'qty', width: 70, cellClass: 'cp center', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '튜닝여부', field: 'is_tuning', width: 95, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if( params.data.is_tuning === '1' ) {
            return '<span class="badge badge-info f12">튜닝함</span>';
          } else {
            return '<span class="badge badge-secondary f12">튜닝안함</span>';
          }
        }
      },
      { headerName: '시/도', field: 'region1', width: 109, cellClass: 'cp center' },
      { headerName: '구/군', field: 'region2', width: 96, cellClass: 'cp center' },
      { headerName: '결제도우미', field: 'trade_helper', width: 105, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if( params.data.trade_helper === '1' ) {
            return '<span class="badge badge-primary f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
      { headerName: '상태', field: 'status_name', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let className: any = '';
          switch( params.data.status ) {
            case '1000':
              className = 'badge badge-secondary f12';
              break;
            case '2000':
              className = 'badge badge-success f12';
              break;
            case '2110':
            case '2120':
              className = 'badge cborange f12';
              break;
            case '2210':
            case '2220':
              className = 'badge cbpurple f12';
              break;
            case '3000':
              className = 'badge badge-primary f12';
              break;
            case '9000':
            case '9010':
              className = 'badge badge-danger f12';
              break;
            default:
              className = '';
          }

          return '<span class="' + className + '">' + params.data.status_name + '</span>';
        }
      },
      { headerName: '갱신일자', field: 'renew_date', width: 155, cellClass: 'cp center' },
      { headerName: '등록일자', field: 'write_date', width: 155, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.isRowSelectable = function(rowNode) {
      // return rowNode.data ? ( rowNode.data.order_status != '9' ) : false;
      return rowNode.data ? true : false;
    };

    this.rowClassRules = {
      'status1': function (params) {
        return params.data.order_status == '1';
      },
      'status2': function (params) {
        return params.data.order_status == '2';
      },
      'status3': function (params) {
        return params.data.order_status == '3' || params.data.order_status == '4' || params.data.order_status == '5' || params.data.order_status == '6' || params.data.order_status == '10';
      },
      'status4': function (params) {
        return params.data.order_status == '7';
      },
      'status8': function (params) {
        return params.data.order_status == '8' || params.data.order_status == '9';
      },
      'gridBorderBottom': function (params) {
        return params.data.order_status != null;
      }
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.search.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? 1 : params.pageNo;
        this.search.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? 10 : params.pageRow;
        this.search.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? '' : params.searchField;
        this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
        this.search.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? this.utilService.getDate(moment().subtract(90, 'day')) : params.sdate;
        this.search.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? this.utilService.getDate('') : params.edate;
        this.search.searchTerm = ( typeof params.searchTerm == 'undefined' ) ? this.getDays(90) : params.searchTerm;
        this.search.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;
        this.search.sellGbn = ( typeof params.sellGbn == 'undefined' ) ? '' : params.sellGbn;
        this.search.bikeStyle = ( typeof params.bikeStyle == 'undefined' ) ? '' : params.bikeStyle;
        this.search.status = ( typeof params.status == 'undefined' ) ? '' : params.status;

        if( this.search.sdate != '' ) this.search.sdate = this.utilService.getDate(this.search.sdate);
        if( this.search.edate != '' ) this.search.edate = this.utilService.getDate(this.search.edate);
      }

      if( this.search.pageNo == 1 ) this.getBikeSellList();
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }
  
  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 거래구분
    this.commonService.getCommonListCode('SLG').then( response => {
      if ( response.ResultCode ) {
        this.sellGbnList = response.data;
      } else {
        this.sellGbnList = [];
      }
    });

    // 바이크종류
    this.commonService.getCommonListCode('BKS').then( response => {
      if ( response.ResultCode ) {
        this.bikeStyleList = response.data;
      } else {
        this.bikeStyleList = [];
      }
    });

    // 상태
    this.commonService.getCommonListCode('BSS').then( response => {
      if ( response.ResultCode ) {
        this.sellStatusList = response.data;
      } else {
        this.sellStatusList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBikeSellList() {
    this.bikeService.getBikeSellList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.sellList = response.data;
        this.statistic = response.statistic;
        this.search.totalCount = response.statistic.searchCount;
      } else {
        this.toastrService.error(response.ResultMessage);
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 주문내역 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelOrder() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>주문내역서</strong></caption>
        <colgroup>
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
          <col style="width:140px;" />
          <col style="width:90px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:140px;" />
          <col style="width:140px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:140px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>A/S</td>
          <td>주문번호</td>
          <td>회원/비회원</td>
          <td>주문자</td>
          <td>샵구분</td>
          <td>입금은행</td>
          <td>최초주문</td>
          <td>현재결제</td>
          <td>수신자</td>
          <td>주문일자</td>
          <td>입금일자</td>
          <td>적립</td>
          <td>사용</td>
          <td>송장번호</td>
        </tr>
    `]

    let data = this.sellList

    data.forEach(element => {
      let color = '';
      if(element.order_status == '1') {
        color = "#ffecfc";
      } else if(element.order_status == '2') {
        color = "#ebffe1";
      } else if(element.order_status == '3' || element.order_status == '4' || element.order_status == '5' || element.order_status == '6' || element.order_status == '10') {
        color = "#ffc7ac";
      } else if(element.order_status == '7') {
        color = "#ffedc8";
      } else if(element.order_status == '8' || element.order_status == '9') {
        color = "#f0f0ff";
      }

      if ( element.shop_gbn ) {
        var shop = '<font style="color:' + element.shop_color + '">' + element.shop_name + '</font>';
      } else {
        var shop = '<font style="color:#0000ff;">&nbsp;</font>';
      }

      if( element.store_name ) {
        var store = `${shop} (${element.store_name})`;
      } else {
        var store = shop;
      }

      contents.push(`
        <tr bgColor="${color}">
          <td style="text-align:center;">${element.order_status_name}</td>
          <td style="text-align:center;">${element.seq}</td>
          <td style="text-align:center;">${element.id ? '회원' : '비회원'}</td>
          <td style="text-align:center;">${element.o_name}</td>
          <td style="text-align:center;">${store}</td>
          <td style="text-align:center;">${element.bank}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.org_order_amt !== null ? element.org_order_amt : 0}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.pay_amt !== null ? element.pay_amt : 0}</td>
          <td style="text-align:center;">${element.r_name}</td>
          <td style="text-align:center;">${element.order_date ? element.order_date : ''}</td>
          <td style="text-align:center;">${element.pay_date ? element.pay_date : ''}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.in_mileage}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.out_mileage ? element.out_mileage : 0}</td>
          <td style="text-align:center; mso-number-format:'\#\,\#\#0';">${element.send_letter}</td>
        </tr>
        </tbody>
      `)
    })
    contents.push(`</table>`)

    let today = new Date();

    const fileName = `bikemart_sellList_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`

    this.printExcel(fileName, contents.join(''))
  }


  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    //this.search.pageNo = page;
    this.getBikeSellList();
  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field === 'seq' ) {
      this.goDetail($event.data.seq, $event.data.product_gbn);
    }
  }

  /*******************************************************************************
    설  명 : 주문 취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderCancel() {
    // const rows = this.gridApi.getSelectedRows();
    // if( rows.length < 1 ) {
    //   this.toastrService.error('주문 취소할 내역을 선택하시기 바랍니다.');
    // } else {
    //   const params = [];

    //   let check: boolean;
    //   rows.forEach(item => {
    //     if(item.order_status !== '0') check = true;

    //     params.push(item.seq);
    //   });

    //   if( check === true ) {
    //     this.toastrService.error('주문접수 상태가 아닌 내역이 있습니다.');
    //   } else {
    //     if ( confirm('선택하신 주문을 취소하시겠습니까?') ) {
    //       this.bikeService.setOrderCancel( JSON.stringify(params) ).then( response => {
    //         if ( response.ResultCode ) {
    //           this.toastrService.success(response.ResultMessage);

    //           this.getBikeSellList();
    //         } else {
    //           this.toastrService.error(response.ResultMessage);
    //         }
    //       });
    //     }
    //   }
    // }
  }

  /*******************************************************************************
    설  명 : 회원정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goMemberInfo(params) {
    const modalRef = this.modalService.open(MemberInfoComponent, optionsLG);

    modalRef.componentInstance.memberNo = params.data.mem_no;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.goDetail( $event.data.seq, $event.data.product_gbn );
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check ) {
    if( check == 'sdate' ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'edate')  {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'paydate') {
      this.search.paydate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 20,
      seq: '',
      searchField: '',
      searchText: '',
      sdate: this.utilService.getDate(moment().subtract(90, 'day')),
      edate: this.utilService.getDate(''),
      searchTerm: this.getDays(90),
      totalCount: 0,
      sellGbn: '',
      bikeStyle: '',
      status: '',
    };

    this.getBikeSellList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getBikeSellList();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
  }

  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( seq: any, product_gbn: any ) {
    this.search.seq = seq;

    this.search.sdate = this.utilService.getDateStr(this.search.sdate);
    this.search.edate = this.utilService.getDateStr(this.search.edate);

    this.router.navigate(
      product_gbn == 'SUPPLIES' ? ['/bike/detail-supplies'] :
    ['/bike/sell/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
  
}
