<div class="modal-header">
  <h4 class="modal-title bold" id="modal-common-code">브랜드 검색</h4>
  <button type="button" class="close" aria-label="Close" (click)="setDismiss()">&times;</button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="input-group">
      <input type="text" id="searchText" name="searchText" formControlName="searchText" [ngClass]="{formErrors:formErrors.searchText}" class="form-control form-control-small f12" placeholder="브랜드명 검색" />
      <div class="input-group-append">
        <button type="submit" class="btn btn-outline-secondary" (click)="getBikeBrandListForComponent()">검색</button>
      </div>
    </div>
    <div *ngIf="formErrors.searchText" class="error-message1">{{formErrors.searchText}}</div>
  </form>

  <div class="mt10">
    <ag-grid-angular
      style="width: 100%;height:400px;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="brandList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"

      (rowClicked)="onRowClicked($event)"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
      >
    </ag-grid-angular>
  </div>
</div>

<div class="modal-footer">
  <!--
  <button type="submit" class="btn btn-primary btn-small mr10 f15">선택하기</button>
  -->
  <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="setDismiss()">창닫기</button>
</div>
