<div class="modal-header">
  <h4 class="modal-title fb mb0">발주수량변경</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <table class="table table-bordered table-small mb0">
  <caption class="hidden">발주수량변경</caption>

  <colgroup>
    <col style="width:25%;" />
    <col style="width:75%;" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><i class="material-icons cred f11">star</i> <label for="" class="control-label">수량</label></th>
    <td>
      <!-- <input type="text" size="13" readonly="readonly" [value]="date" class="form-control form-control-small calendar-input " placeholder="" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate($event)" /> -->
      <input type="number" class="form-control form-control-small" [value]="cnt" (change)="changeCnt($event)">
    </td>
  </tr>
  </tbody>
  </table>
</div>

<div class="modal-footer">
  <!-- <div class="fl w50p">
    <button type="button" class="btn btn-small btn-danger" (click)="delete()">삭제</button>
  </div> -->
  <div class="fr w50p right">
    <button type="button" class="btn btn-small blue-btn" (click)="submit()">저장</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
  </div>
</div>