<section id="member-sis">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{statistic?.total_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic?.search_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">협력점<span class="cnt">{{statistic?.company_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">프랜차이즈<span class="cnt">{{statistic?.fra_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <!-- <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">입점업체<span class="cnt">{{statistic?.vendor_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div> -->

    <!-- <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">BOM<span class="cnt">{{statistic?.bom_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div> -->

    <!-- <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">대리점+BOM<span class="cnt">{{statistic?.agency_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div> -->

    <div class="cb"></div>
  </section>

  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberSisList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select class="form-control form-control-small ml5" placeholder="시/도검색" [(ngModel)]="search.state" (change)="getMemberSisList()">
        <option value="">지역 전체</option>
        <option *ngFor="let item of stateList" [value]="item.common_code">{{item.common_name}}</option>
      </select>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status==''}" (click)="search.status='';getMemberSisList()">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='1'}" (click)="search.status='1';getMemberSisList()">계약중</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='0'}" (click)="search.status='0';getMemberSisList()">계약만료</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.grade==''}" (click)="search.grade='';getMemberSisList()">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.grade=='0004'}" (click)="search.grade='0004';getMemberSisList()">협력점</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.grade=='0007'}" (click)="search.grade='0007';getMemberSisList()">프렌차이즈</button>
      </div>


      <select [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
        <option value="b.id">ID 검색</option>
        <option value="a.name">회사명 검색</option>
        <option value="b.name">이름 검색</option>
        <option value="a.addr">주소 검색</option>
        <option value="a.phone">연락처 검색</option>
      </select>

      <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="검색어 입력" />
    </div>
    <div class="fl w30p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="c8328bdfd35e4541b78a69ced95fd836" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-danger btn-small ml5" (click)="setMemberNormalChange()">일반전환</button>
      <button type="button" title="협력점추가" class="btn btn-primary btn-small ml5" (click)="addMember(0)">협력점추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="background-color:#fff;height: calc(100% - 90px);">
    <as-split-area size="50" minSize="40" maxSize="60">
      <div style="height:100%;">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="memberSisList"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (cellClicked)="onCellClicked($event)"
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="50" minSize="40" maxSize="60">
      <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
        <mat-tab label="최근 매출">
          <ng-container *ngIf="activeTab==0">
            <canvas baseChart
              [datasets]="chartData"
              [labels]="chartLabels"
              [options]="chartOptions"
              [plugins]="chartPlugins"
              [legend]="chartLegend"
              [chartType]="chartType"
              [colors]="chartColors"
              height="57"
            >
            </canvas>
          </ng-container>
        </mat-tab>
        <mat-tab label="월별 판매마진율">
          <ng-container *ngIf="activeTab==1">
            <canvas baseChart
              [datasets]="chartDataTab2"
              [labels]="chartLabelsTab2"
              [options]="chartOptionsTab2"
              [plugins]="chartPluginsTab2"
              [legend]="chartLegendTab2"
              [chartType]="chartTypeTab2"
              height="57"
            >
            </canvas>
          </ng-container>
        </mat-tab>
        <mat-tab label="주문내역">
          <ng-container *ngIf="activeTab==2">
            <div class="mt5 mb5">
              <div class="fl pt10">
                <span class="">총 {{orderList.length | number}}건</span>
              </div>
              <div class="fr right">
                <div class="fl form-inline mr15 mt2">
                  <input type="text" size="15" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" placement="bottom" />
                  <ng-template #footerTemplate1>
                    <hr class="my-0">
                    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 'sdate')">오늘</button>
                    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
                  </ng-template>
                  <span class="ml5 mr5">~</span>
                  <input type="text" size="15" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" placement="bottom"/>
                  <ng-template #footerTemplate2>
                    <hr class="my-0">
                    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 'edate')">오늘</button>
                    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
                  </ng-template>
                </div>      
                  <button type="button" title="검색" class="btn btn-default btn-small mr10" style="background-color: #d1eff9;" (click)="getMemberSisDetailResearch()">검색</button>                
                <button type="button" class="btn btn-default btn-small mr5" (click)="setExcelDown()">엑셀다운</button>
                <btn-ag-grid-save [gridColumnsApi]="gridColumnApiOrder" gridId="f586583cd95c48759bf112ea8be43404" [btnGroup]="1"></btn-ag-grid-save>
              </div>

              <div class="cb"></div>
            </div>

            <ag-grid-angular
              #myGrid1

              style="width: 100%;height:calc(100% - 40px);"
              class="ag-theme-balham"

              [columnDefs]="columnDefsOrder"
              [defaultColDef]="defaultColDef"
              [rowData]="orderList"
              [pagination]="false"
              [domLayout]="domLayout"
              [overlayNoRowsTemplate]="noRowsTemplate"
              [frameworkComponents]="frameworkComponents"
              [rowSelection]="rowSelection"
              [getRowHeight]="getRowHeight"

              (cellClicked)="onCellClickedOrder($event)"
              (gridReady)="onGridReadyOrder($event)"
              >
            </ag-grid-angular>
          </ng-container>
        </mat-tab>
        <mat-tab label="엑셀 자료 다운로드">
          <div class="fl form-inline p20">
            <input type="text" class="form-control form-control-small mr10" style="width: 120px;" [(ngModel)]="excelSearchDate.year" placeholder="연도(YYYY) 입력" />
            <button type="button" class="btn btn-default btn-small" (click)="excelDownOutOrderProductMonth()">월별 출고 상품목록 다운로드</button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </as-split-area>
  </as-split>
</section>
