<section class="h100">
  <mat-tab-group style="height: 100%" [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
    <!-- 주문리스트 -->
    <mat-tab style="min-width:100px;" label="주문리스트">
      <app-stock-out-order *ngIf="activeTab == 0"></app-stock-out-order>
    </mat-tab>
    <!-- 주문리스트 -->

    <mat-tab style="min-width:100px;" label="당일 출고지시서">
      <app-stock-out-today *ngIf="activeTab == 1"></app-stock-out-today>
    </mat-tab>

    <!-- 출고지시서 -->
    <mat-tab style="min-width:100px;" label="출고지시서">
      <app-stock-out-delivery-order *ngIf="activeTab == 2"></app-stock-out-delivery-order>
    </mat-tab>
  </mat-tab-group>
</section>