/*******************************************************************************
  설  명 : 상품관리 - 상품추가
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { pairwise } from 'rxjs/operators';
import * as $ from 'jquery';
import * as moment from 'moment';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { CategoryService } from '@app/service/category.service';
import { MemberService } from '@app/service/member.service';
import { BasicService } from '@app/service/basic.service';
import { ProductService } from '@app/service/product.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { MatTooltipComponent } from '@components/mat-tooltip/mat-tooltip.component';
import { BrandFindComponent } from '@components/brand-find/brand-find.component';
import { ProductFindComponent } from '@components/product-find/product-find.component';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { BarcodeInputComponent } from '@components/barcode-input/barcode-input.component';

import { ProductPropertyAddComponent } from '@page/product/product-property/product-property-add/product-property-add.component';
import calcPrice from '@components/calcPrice';

const URL = config.apiImageUploadUrl;

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public baseURL = config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public productInfo: any = {};
  public memberInfo: any = {};

  public providerList: any = [];
  public customerList: any = [];
  public memoList: any = [];
  public setProductList: any = [];
  public giftProductList: any = [];
  // public relationProductList: any = [];
  public categoryList: any = [];
  public addCategoryList: any = [];
  public brandList: any = [];

  public soldoutSelect: any = [
    {seq: '0', name: '판매중'},
    {seq: '1', name: '일시품절'},
    {seq: '2', name: '영구품절'}
  ];

  public displaySelect: any = [
    {seq: '0', name: '미표시'},
    {seq: '1', name: '표시'}
  ];

  public approvalSelect: any = [
    {seq: '0', name: '미승인'},
    {seq: '1', name: '승인'}
  ];

  public components: any;
  public summernoteConfig: any = {...config.summernoteConfig, ...{disableDragAndDrop: true}};
  public summernoteConfig2: any = {...config.summernoteConfig, ...{disableDragAndDrop: true}};
  public content: any;

  public giftSdate: any = '';
  public giftEdate: any = '';

  gridApiMemo: any;
  gridColumnApiMemo: any;
  columnDefsMemo: any;

  gridApiProperty: any;
  gridColumnApiProperty: any;
  columnDefsProperty: any;

  gridApiGift: any;
  gridColumnApiGift: any;
  columnDefsGift: any;

  // gridApiRelation: any;
  // gridColumnApiRelation: any;
  // columnDefsRelation: any;

  gridApiSet: any;
  gridColumnApiSet: any;
  columnDefsSet: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public select2OptionsBrand = {
    multiple: false
  };

  public params: any = {
    pageNo: 1,
    pageRow: 20,
    totalCount: 0,
    seq: '',
    searchText: '',
    status: '',
    sdate: '',
    edate: '',
    is_display: '',
    category_code: '',
    is_card_installment: '',
    approval_yn: '',
    mileage: '',
    safe_stock: '',
    referrer: '',
    is_mach:''
  };

  public memoAddFunc: any;
  public addBrandFunc: any;
  public providerChangedFunc: any;
  public customerChangedFunc: any;
  public mainCategoryChangedFunc: any;
  public subCategoryChangedFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [Validators.required] ],
      provider_seq: ['', [] ],
      customer_seq: ['', [Validators.required] ],
      comment: ['', [Validators.maxLength(255)] ],
      product_name: ['', [Validators.required, Validators.maxLength(255)] ],
      product_overview: ['', [Validators.maxLength(255)] ],
      is_set_product: ['', [Validators.required] ],
      gift_product_name: ['', [Validators.maxLength(255)] ],
      category_seq: ['', [] ],
      isChangeCategorySeq: ['0', [] ],
      category_list: [[], [] ],
      isChangeCategoryList: ['0', [] ],
      writer: ['', [] ],
      brand_seq: ['', [Validators.required] ],
      brand_name: ['', [] ],
      is_outside_view: ['1', [] ],
      is_consignment: ['0', [] ],
      min_qty: ['1', [] ],
      max_qty: ['', [] ],
      is_soldout: ['0', [] ],
      is_stock: ['1', [] ],
      is_delivery: ['1', [] ],
      is_display: ['1', [] ],
      approval_yn: ['0', [] ],
      use_yn: ['1', [] ],
      is_review: ['1', [] ],
      display_price: ['', [] ],
      consumer_price: ['', [Validators.required] ],
      sale_price: ['', [] ],
      buy_price: ['', [Validators.required] ],
      supersale_price: ['', [] ],
      supersale_rate: ['', [] ],
      b2b_price: ['', [Validators.required] ],
      com_price: ['', [Validators.required] ],
      seller_price: ['', [] ],
      mileage_price: ['', [] ],
      fra_price: ['', [Validators.required] ],
      mileage: ['', [] ],
      each_delivery_price: ['0', [] ],
      origin_country: ['', [] ],
      search_keyword: ['', [Validators.required] ],
      contents: ['', [] ],
      summary: ['', [] ],
      file: ['', [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
      memo: ['', [] ],
      property: [ [], [Validators.required] ],
      json_property: [ [], [] ],
      // freegift: [ [], [] ],
      // relation: [ [], [] ],
      // set: [ [], [] ],
      customer_product_gbn: [''],
      kc_number: [''],
      is_mach: ['0', [] ],
      is_voucher: ['0', [] ],
      is_freeDelivery: ['0', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });

    // 주 카테고리 변경 여부 체크
    this.form.get('category_seq')
      .valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if( prev != next ) this.form.get('isChangeCategorySeq').setValue('1');
        if( this.productInfo.category_seq == next ) this.form.get('isChangeCategorySeq').setValue('0');
      });

    // 추가 카테고리 변경 여부 체크
    this.form.get('category_list')
    .valueChanges
    .pipe(pairwise())
    .subscribe(([prev, next]: [any, any]) => {
      if( prev.length != next.length ) this.form.get('isChangeCategoryList').setValue('1');
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private restful: RestfulService,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private memberService: MemberService,
    private basicService: BasicService,
    private productService: ProductService,
    private agGridExComponent: AgGridExComponent,
  ) {
    this.buildForm();

    this.summernoteConfig.height = 500;
    this.summernoteConfig2.height = 150;

    this.memoAddFunc = this.memoAdd.bind(this);
    this.addBrandFunc = this.brandAdd.bind(this);
    this.providerChangedFunc = this.providerChanged.bind(this);
    this.customerChangedFunc = this.customerChanged.bind(this);
    this.mainCategoryChangedFunc = this.mainCategoryChanged.bind(this);
    this.subCategoryChangedFunc = this.subCategoryChanged.bind(this);

    this.columnDefsMemo = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '메모', field: 'memo', width: 1000, cellClass: 'cp' },
      { headerName: '등록자', field: 'writer_name', width: 150, cellClass: 'cp' },
      { headerName: '등록일시', field: 'write_date', width: 150, cellClass: 'cp center' },
      { headerName: '메모삭제', field: '', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent', 
      valueGetter: () => `<button type="button" class="btn btn-default btn-small" style="height: 22px; line-height: 11px;">삭제하기</button>`
      },
    ];

    // ag grid 컬럼 선언
    this.columnDefsProperty = [
      { headerName: '', field: '', width: 40, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '번호', field: 'seq', width: 70, cellClass: 'cp center' },
      { headerName: '색상', field: 'color_name', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.color != null ) {
            return `<span style="display:inline-block;margin-top:6px;width:16px;height:16px;border:solid 1px #ccc;line-height: 12px;background-color:${params.data.color}">&nbsp;</span> ${params.data.color_name}`;
          } else {
            return '';
          }
        },
      },
      { headerName: '표시할 색상', field: 'display_color_name', width: 115, cellClass: 'cp left ag-cell-edit', editable: true, 
        valueGetter: valueCheckUndefined,
      },
      { headerName: '사이즈', field: 'size_name', width: 80, cellClass: 'cp center',
      },
      { headerName: '표시할 사이즈', field: 'display_size_name', width: 115, cellClass: 'cp left ag-cell-edit', editable: true, 
        valueGetter: valueCheckUndefined,
      },
      { headerName: '표시가', field: 'display_price', width: 88, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? 'cp right ag-cell-edit' : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '소비자가', field: 'consumer_price', width: 88, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? ( parseInt(params.data.consumer_price) <= parseInt(params.data.buy_price) ? 'cp right cborangeB ag-cell-edit' : 'cp right ag-cell-edit' ) : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '판매가', field: 'sale_price', width: 88, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? ( parseInt(params.data.sale_price) <= parseInt(params.data.buy_price) ? 'cp right cborangeB ag-cell-edit' : 'cp right ag-cell-edit' ) : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '마일리지가', field: 'mileage_price', width: 90, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? ( parseInt(params.data.mileage_price) <= parseInt(params.data.buy_price) ? 'cp right cborangeB ag-cell-edit' : 'cp right ag-cell-edit' ) : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '상사가', field: 'com_price', width: 88, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? ( parseInt(params.data.com_price) <= parseInt(params.data.buy_price) ? 'cp right cborangeB ag-cell-edit' : 'cp right ag-cell-edit' ) : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '협력점가', field: 'b2b_price', width: 88, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? ( parseInt(params.data.b2b_price) <= parseInt(params.data.buy_price) ? 'cp right cborangeB ag-cell-edit' : 'cp right ag-cell-edit' ) : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '프렌차이즈가', field: 'fra_price', width: 96, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? ( parseInt(params.data.fra_price) <= parseInt(params.data.buy_price) ? 'cp right cborangeB ag-cell-edit' : 'cp right ag-cell-edit' ) : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '구매가', field: 'buy_price', width: 88, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? 'cp right ag-cell-edit' : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '마일리지', field: 'mileage', width: 80, 
        cellClass: (params: any) => ( parseInt(params.data.seq) > 0 ) ? 'cp right ag-cell-edit' : 'cp right', 
        editable: (params: any) => ( parseInt(params.data.seq) > 0 ) ? true : false,
        valueFormatter: currencyFormatter,
        valueParser: numberParser
      },
      { headerName: '품절여부', field: 'is_soldout', width: 100, 
        cellClass: function(params) {
          if( parseInt(params.data.seq) > 0 ) return 'cp center ag-cell-edit';
          else return 'cp center';
        }, 
        editable: function(params) {
          if( parseInt(params.data.seq) > 0 ) return true;
          else return false;
        }, 
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.soldoutSelect,
          cellRenderer: 'agGridHtmlComponent'
        },      
        valueGetter(params: any) {
          if ( params.data.is_soldout == '0' ) {
            return '<span class="badge badge-success f12">판매중</span>';
          } else if ( params.data.is_soldout == '1' ) {
            return '<span class="badge badge-warning f12">일시품절</span>';
          } else if ( params.data.is_soldout == '2' ) {
            return '<span class="badge badge-danger f12">영구품절</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '표시여부', field: 'is_display', width: 80, 
        cellClass: function(params) {
          if( parseInt(params.data.seq) > 0 ) return 'cp center ag-cell-edit';
          else return 'cp center';
        }, 
        editable: function(params) {
          if( parseInt(params.data.seq) > 0 ) return true;
          else return false;
        },
        cellRenderer: 'agGridHtmlComponent',
        cellEditor: "selectCellEditor",
        cellEditorParams: {
          values: this.displaySelect,
          cellRenderer: 'agGridHtmlComponent'
        }, 
        valueGetter(params: any) {
          if ( params.data.is_display == '1' ) {
            return '<span class="badge badge-success f12">표시</span>';
          } else {
            return '<span class="badge badge-danger f12">미표시</span>';
          }
        }
      },
      { headerName: '승인여부', field: 'approval_yn', width: 75, cellClass: 'cp center',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.approval_yn == '1' ) {
            return '<span class="badge badge-success f12">승인</span>';
          } else {
            return '<span class="badge badge-danger f12">미승인</span>';
          }
        }
      },
      { headerName: '바코드', field: 'barcode', width: 80, cellClass: 'cp left',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data
      },
    ];

    this.columnDefsGift = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품번호', field: 'product_property_seq', width: 100, cellClass: 'cp center' },
      { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center', width: 100, cellRenderer: 'agGridImageComponent' },
      { headerName: '색상', field: 'color_name', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.color != null ) {
            return `<span style="display:inline-block;margin-top:6px;width:16px;height:16px;border:solid 1px #ccc;line-height: 12px;background-color:${params.data.color}">&nbsp;</span> ${params.data.color_name}`;
          } else {
            return '';
          }
        }
      },
      { headerName: '표시할 색상', field: 'display_color_name', width: 150, cellClass: 'cp left',
        valueGetter: valueCheckUndefined,
      },
      { headerName: '사이즈', field: 'size_name', width: 100, cellClass: 'cp center',
      },
      { headerName: '표시할 사이즈', field: 'display_size_name', width: 150, cellClass: 'cp left',
        valueGetter: valueCheckUndefined,
      },
      { headerName: '상품명', field: 'product_freegift_name', width: 300, cellClass: 'cp' },
      { headerName: '제한수량', field: 'max_count', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
        cellEditor: 'numericCellEditor',
        valueFormatter: currencyFormatter,
        valueParser: numberParser,
      },
      { headerName: '시작일자', field: 'sdate', width: 140, cellClass: 'cp center ag-cell-edit' },
      { headerName: '종료일자', field: 'edate', width: 140, cellClass: 'cp center ag-cell-edit' },
      { headerName: '메모', field: 'memo', width: 200, cellClass: 'cp ag-cell-edit', editable: true, singleClickEdit: true },
      // { headerName: '등록일', field: 'write_date', width: 140, cellClass: 'cp center' },
    ];

    // this.columnDefsRelation = [
    //   { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
    //     headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    //   { headerName: '상품번호', field: 'product_seq', width: 100, cellClass: 'cp center' },
    //   { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center', width: 150, cellRenderer: 'agGridImageComponent' },
    //   { headerName: '상품명', field: 'relation_product_name', width: 400, cellClass: 'cp' },
    //   { headerName: '할인금액', field: 'discount_amt', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
    //     cellEditor: 'numericCellEditor',
    //     valueFormatter: currencyFormatter,
    //     valueParser: numberParser,
    //   },
    //   { headerName: '할인율', field: 'discount_rate', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
    //     cellEditor: 'numericCellEditor',
    //     valueFormatter: currencyFormatter,
    //     valueParser: numberParser,
    //   },
    //   { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
    // ];

    this.columnDefsSet = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품코드', field: 'part_product_code', width: 70, cellClass: 'cp center' },
      { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center', width: 100, cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'part_product_name', width: 400, cellClass: 'cp' },
      { headerName: '할인금액', field: 'discount_amt', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
        cellEditor: 'numericCellEditor',
        valueFormatter: currencyFormatter,
        valueParser: numberParser,
      },
      { headerName: '할인율', field: 'discount_rate', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, singleClickEdit: true,
        cellEditor: 'numericCellEditor',
        valueFormatter: currencyFormatter,
        valueParser: numberParser,
      },
      { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor(),
      btnCellRenderer: this.agGridExComponent.getBtnCellRenderer(),
      singleClickEditRenderer: this.agGridExComponent.getsingleClickEditRenderer()
    };

    function currencyFormatter(params: any) {
      const str = String(params.data[ params.column.colId ]);
      if( parseInt(params.data.seq) > 0 ) return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
      else return '';
    }

    function valueCheckUndefined(params: any) {
      if( typeof params.data[ params.column.colId ] == 'undefined' || params.data[ params.column.colId ] === null ) {
        return '';
      } else {
        return params.data[ params.column.colId ];
      }
    }

    function numberParser(params) {
      if( parseInt(params.data.seq) > 0 ) return Number(params.newValue);
      else return '';
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // select2 용 카테고리 가져오기
    this.getCategoryList();

    // 공급업체 리스트 가져오기
    // this.getMemberListForSearch();

    // 매입거래처 리스트 가져오기
    this.getCustomerList().then(() => {

    // 입력 파라미터 처리
    this.activatedRoute.queryParams.subscribe( async params => {
      this.params.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
      this.params.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow;
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '0' : params.seq;
      this.params.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? 'product_seq' : params.searchField;
      this.params.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.params.status = ( typeof params.status == 'undefined' || params.status == '' ) ? '' : params.status;
      this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.params.is_display = ( typeof params.is_display == 'undefined' || params.is_display == '') ? '' : params.is_display;
      this.params.is_mach = ( typeof params.is_mach == 'undefined' || params.is_mach == '') ? '0' : params.is_mach;
      this.params.is_voucher = ( typeof params.is_voucher == 'undefined' || params.is_voucher == '') ? '0' : params.is_voucher;
      this.params.category_code = ( typeof params.category_code == 'undefined' ) ? '' : params.category_code;
      this.params.is_card_installment = ( typeof params.is_card_installment == 'undefined' ) ? '' : params.is_card_installment;
      this.params.approval_yn = ( typeof params.approval_yn == 'undefined' ) ? '1' : params.approval_yn;
      this.params.mileage = ( typeof params.mileage == 'undefined' ) ? '' : params.mileage;
      this.params.safe_stock = ( typeof params.safe_stock == 'undefined' ) ? '' : params.safe_stock;
      this.params.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;
      this.params.opn = ( typeof params.opn == 'undefined' || params.opn == '' ) ? '' : params.opn;
      this.params.otc = ( typeof params.otc == 'undefined' || params.otc == '' ) ? '0' : params.otc;
      this.params.referrer = ( typeof params.referrer == 'undefined' || params.referrer == '' ) ? '0' : params.referrer;

      this.form.patchValue({seq: this.params.seq});

      if( this.params.seq !== '0' ) {
        // 상품 상세정보 가져오기
        this.getProductDetail();

        // 메모 리스트 가져오기
        this.getProductMemoList();

        // 사은품 리스트 가져오기
        this.getProductGiftList();

        // 연관상품 리스트 가져오기
        // this.getProductRelationList();

        // 세트상품 리스트 가져오기
        this.getProductSetList();
      }
    }).unsubscribe();

    })

    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    this.form.patchValue({
      writer: this.memberInfo.name
    });

    // 업로드 허용 파일 설정( application, image, video, audio, pdf, compress, doc, xls, ppt )
    var filetype: string[] = ['image', 'video'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const files = this.form.controls.files.value;
        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: this.baseURL + response.thumbnail,
          thumbnail_org: response.thumbnail_org,
          is_default: '0'
        });

        this.form.patchValue({ files: files });

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      this.getImageWidth(f)
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  async getImageWidth(file) {
    await this.getBase64(file).then(base64 => {
      const img = new Image();
      img.onload = () => {
        if( 800 > img.width || 800 > img.height ) {
          this.toastrService.warning('800 * 800 보다 작은 이미지는 쇼핑몰에서 정상적으로 보이지 않을 수 있습니다.')
          this.toastrService.warning('대표이미지는 1000 * 1000 사이즈를 강력 권장합니다.')
        }
      }
      img.src = base64.toString();
    })
  }
  async getBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 콤마 제거
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  deleteComma( value: any ) {
    let str = String(value);
    return str.replace(/,/g, '');
  }
  
  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.productService.setDeleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.form.controls.upload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertUploadImage( file ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      this.productService.setProductImageDefault( file ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertFilesImage( file: any ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      let files: any = this.form.controls.files.value;
      files.map((obj: any) => obj.is_default = '0');
      
      file.is_default = '1';
    }
  }

  /*******************************************************************************
    설  명 : select 전용 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) {
        let data: any = [];
        let data1: any = [];

        for(let item of response.data ) {
          data.push({
            id: item.seq,
            text: item.category_nm,
            category_code: item.category_code
          });

          data1.push({
            id: item.seq,
            text: item.category_nm,
            category_code: item.category_code,
            disabled: ( item.category_code.length <= 6 ) ? true : false
          });
        }

        this.categoryList = data;
        this.addCategoryList = data1;
      } else {
        this.categoryList = [];
        this.addCategoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 공급업체 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberListForSearch() {
    this.memberService.getMemberListForSearch( {grade: '0003'} ).then( response => {
      if( response.ResultCode ) {
        // this.providerList = response.data;
        const data: any = [];

        data.push({
          id: '',
          text: '선택'
        });

        for(let item of response.data ) {
          data.push({
            id: item.mem_no,
            text: item.name + ' (' + item.id + ')'
          });
        }

        this.providerList = data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 거래처 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerList() {
    return this.basicService.getCustomerList({}).then( response => {
      if( response.ResultCode ) {
        // this.customerList = response.data;
        const data: any = [];

        data.push({
          id: '',
          text: '선택'
        });

        for(let item of response.data ) {
          data.push({
            id: item.seq,
            text: item.comname + ' (' + item.customer_id + ')' + (item.use_yn == 'Y' ? '' : ' (X)'),
            data: item
          });
        }

        this.customerList = data;
        this.providerList = data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 상품 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductDetail() {
    this.productService.getProductDetail( this.form.controls.seq.value ).then( response => {
      if( response.ResultCode ) {
        this.productInfo = response.data;

        this.form.patchValue({
          ...this.productInfo,
          customer_product_gbn: this.customerList.find((item: any) => item.data?.seq == response.data.customer_seq)?.data?.product_gbn || ''
        });

        let data: any = [];
        for(let item of this.addCategoryList ) {
          data.push({
            ...item,
            disabled: ( item.category_code.length <= 6 || item.id == this.getSelect2Value(this.form.controls.category_seq.value) ) ? true : false
          });
        }
    
        this.addCategoryList = data;

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 메모 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductMemoList() {
    this.productService.getProductMemoList( this.form.controls.seq.value ).then( response => {
      if( response.ResultCode ) {
        this.memoList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
4
  /*******************************************************************************
    설  명 : 사은품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductGiftList() {
    this.productService.getProductGiftList( this.form.controls.seq.value ).then( response => {
      if( response.ResultCode ) {
        this.giftProductList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 연관상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // getProductRelationList() {
  //   this.productService.getProductRelationList( this.form.controls.seq.value ).then( response => {
  //     if( response.ResultCode ) {
  //       this.relationProductList = response.data;
  //     } else {
  //       this.toastrService.error(response.ResultMessage);
  //     }
  //   });
  // }

  /*******************************************************************************
    설  명 : 세트상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductSetList() {
    this.productService.getProductSetList( this.form.controls.seq.value ).then( response => {
      if( response.ResultCode ) {
        this.setProductList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductSave() {
    if( !this.form.controls.memo.valid && this.form.controls.memo.value == '' ) {
      this.form.controls.memo.clearValidators();
      this.form.controls.memo.updateValueAndValidity();
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      if( this.form.controls.isChangeCategorySeq.value == '1' ) {
        if( ! confirm('주 카테고리 변경 시\n주 카테고리에 설정된 순서(추천순위)가 초기화 될 수 있습니다.\n저장하시겠습니까?') ) return;
      }

      if( this.form.controls.isChangeCategoryList.value == '1' ) {
        if( ! confirm('추가 카테고리 변경 시\n추가 카테고리에 설정된 순서(추천순위)가 초기화 될 수 있습니다.\n저장하시겠습니까?') ) return;
      }

      let property: any = [];
      property = this.form.controls.property.value;
      
      this.form.patchValue({
        json_property: JSON.stringify(property),
        property: []
      });

      this.productService.setProductSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          if( this.params.seq == '0' ) {
            this.params.seq = response.ProductSeq;

            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = "reload";

            this.router.navigate(
              ['/product/list/add'],
              { relativeTo: this.activatedRoute,
                queryParams: this.params,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              }
            );
          } else {
            this.form.patchValue({
              json_property: [],
              files: []
            });

            this.getProductDetail();
          }
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 사은품 세부정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductGiftInfoSave() {
    if( confirm('수정한 내용을 저장하시겠습니까?') ) {
      const gridData = this.gridApiGift.gridOptionsWrapper.gridOptions.rowData;

      if( gridData.length < 1 ) {
        this.toastrService.error( '저장할 데이터가 없습니다.', '');
        return false;
      }

      this.productService.setProductGiftInfoSave( gridData ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductGiftList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 연관상품 세부정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // setProductRelationInfoSave() {
  //   if( confirm('수정한 내용을 저장하시겠습니까?') ) {
  //     const gridData = this.gridApiRelation.gridOptionsWrapper.gridOptions.rowData;

  //     if( gridData.length < 1 ) {
  //       this.toastrService.error( '저장할 데이터가 없습니다.', '');
  //       return false;
  //     }

  //     this.productService.setProductRelationInfoSave( gridData ).then( response => {
  //       if( response.ResultCode ) {
  //         this.toastrService.success( response.ResultMessage, '');

  //         this.getProductRelationList();
  //       } else {
  //         this.toastrService.error( response.ResultMessage, '');
  //       }
  //     }, error => {
  //       this.toastrService.error( error, '');
  //     });
  //   }
  // }

  /*******************************************************************************
    설  명 : 세트상품 세부정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductSetInfoSave() {
    if( confirm('수정한 내용을 저장하시겠습니까?') ) {
      const gridData = this.gridApiSet.gridOptionsWrapper.gridOptions.rowData;

      if( gridData.length < 1 ) {
        this.toastrService.error( '저장할 데이터가 없습니다.', '');
        return false;
      }

      this.productService.setProductSetInfoSave( gridData ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductSetList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 사은품 일자 일괄적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setGiftDate( type: boolean ) {
    let date: any = ( type === true ) ? '시작' : '종료';

    if( this.giftSdate == '' && this.giftEdate == '' ) {
      this.toastrService.error( `일괄로 적용할 ${date}일자를 선택하세요` );
      return false;
    }

    let nodes: any = this.gridApiGift.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm(`전체 데이터에 ${date}일자를 일괄로 적용하시겠습니까?`) ) {
        this.gridApiGift.selectAll();
      } else {
        this.toastrService.error( `${date}일자를 적용할 데이터를 선택하세요` );
        return false;
      }
    } else {
      if( ! confirm(`선택하신 일자로 ${date}일자를 적용 하시겠습니까?` ) ) return false;
    }

    this.gridApiGift.forEachNode(function(node, index) {
      if( node.selected ) {
        if( type === true )
          node.data.sdate = this.utilService.getDateStr( this.giftSdate );
        else
          node.data.edate = this.utilService.getDateStr( this.giftEdate );

        this.gridApiGift.applyTransaction({ update: [node.data] });
      }
    }.bind(this));
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyMemo(params) {
    this.gridApiMemo = params.api;
    this.gridColumnApiMemo = params.columnApi;
  }

  onFirstDataRenderedMemo(params) {
    params.api.sizeColumnsToFit();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyProperty(params) {
    this.gridApiProperty = params.api;
    this.gridColumnApiProperty = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 색상 및 사이즈 변경
  *******************************************************************************/
  setPropertyChange() {
    const rows = this.gridApiProperty.getSelectedRows();
    if( rows.length < 1 ) {
      this.toastrService.error('변경할 내역을 선택하시기 바랍니다.', '');
    } else {
      const modalRef = this.modalService.open(ProductPropertyAddComponent, optionsXL);

      let category_seq = this.form.controls.category_seq.value;

      modalRef.componentInstance.categorySeq = ( typeof category_seq == 'object' ) ? category_seq.id : category_seq;
      modalRef.componentInstance.isEdit = true;

      modalRef.result.then((result) => {
        if( typeof result !== 'undefined' ) {
          let colorNodes = result.colorNodes;
          let sizeNodes = result.sizeNodes;

          rows.forEach(item => {
            if( colorNodes.length > 0 ) {
              item.color = colorNodes[0].color;
              item.color_seq = colorNodes[0].seq;
              item.color_name = colorNodes[0].color_name;
            }

            if( sizeNodes.length > 0 ) {
              item.size_seq = sizeNodes[0].seq;
              item.size_name = sizeNodes[0].size_name;
            }

            this.gridApiProperty.applyTransaction({ update: [item] });
          });

          this.setProductSave();
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyGift(params) {
    this.gridApiGift = params.api;
    this.gridColumnApiGift = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // onGridReadyRelation(params) {
  //   this.gridApiRelation = params.api;
  //   this.gridColumnApiRelation = params.columnApi;
  // }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadySet(params) {
    this.gridApiSet = params.api;
    this.gridColumnApiSet = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 메모 input에서 엔터키 입력 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setKeyPressMemo( event ) {
    if( event.key == 'Enter' ) {
      this.memoAdd();
    }
  }

  /*******************************************************************************
    설  명 : 메모 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  memoAdd() {
    this.form.controls.memo.setValidators([Validators.required]);
    this.form.controls.memo.updateValueAndValidity();

    this.utilService.makeFieldDirtyAndUpdateErrors(this.form, this.formErrors, 'memo');

    if( this.form.controls.memo.valid ) {
      this.productService.setProductMemoSave( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.form.controls.memo.setValue('');
          this.form.controls.memo.clearValidators();
          this.form.controls.memo.updateValueAndValidity();

          this.getProductMemoList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 메모 삭제
  *******************************************************************************/
  deleteMemo(seq) {
    if(!confirm('이 메모를 삭제할까요?')) return
    this.productService.deleteProductMemo( seq ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '')
        this.getProductMemoList()
      } else this.toastrService.error( response.ResultMessage, '')
    })
  }

  onCellClicked($event) {
    if($event.colDef.headerName == '메모삭제') this.deleteMemo($event.data.seq)
  }

  /*******************************************************************************
    설  명 : 브랜드 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  brandAdd() {
    const modalRef = this.modalService.open(BrandFindComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
        this.form.patchValue({
          brand_seq: result.data.seq,
          brand_name: result.data.brand_name
        });
        this.brandChanged()
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 속성 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  propertyAdd() {
    const modalRef = this.modalService.open(ProductPropertyAddComponent, optionsXL);

    let category_seq = this.form.controls.category_seq.value;

    modalRef.componentInstance.categorySeq = ( typeof category_seq == 'object' ) ? category_seq.id : category_seq;

    modalRef.result.then((result) => {

      if( typeof result !== 'undefined' ) {
        let property = this.form.controls.property.value;

        let colorNodes = result.colorNodes;
        let sizeNodes = result.sizeNodes;

        let tmp: any = [...property];
        if( colorNodes.length > 0 && sizeNodes.length < 1 ) {
          colorNodes.forEach(node => {
            tmp.push({
              seq: '',
              color_seq: node.seq,
              color: node.color,
              color_name: node.color_name,
              display_color_name: node.color_name,
              size_name: '',
              display_size_name: '',
              display_price: this.deleteComma(this.form.controls.display_price.value),
              consumer_price: this.deleteComma(this.form.controls.consumer_price.value),
              sale_price: this.deleteComma(this.form.controls.sale_price.value),
              mileage_price: this.deleteComma(this.form.controls.mileage_price.value),
              fra_price: this.deleteComma(this.form.controls.fra_price.value),
              b2b_price: this.deleteComma(this.form.controls.b2b_price.value),
              com_price: this.deleteComma(this.form.controls.com_price.value),
              buy_price: this.deleteComma(this.form.controls.buy_price.value),
              mileage: this.deleteComma(this.form.controls.mileage.value),
              is_soldout: 0,
              is_display: 1,
              approval_yn: 0,
              barcode: ''
            });
          });
        } else if( colorNodes.length < 1 && sizeNodes.length > 0 ) {
          sizeNodes.forEach(node => {
            tmp.push({
              seq: '',
              size_seq: node.seq,
              color: '',
              color_name: '',
              display_color_name: '',
              size_name: node.size_name,
              display_size_name: node.size_name,
              display_price: this.deleteComma(this.form.controls.display_price.value),
              consumer_price: this.deleteComma(this.form.controls.consumer_price.value),
              sale_price: this.deleteComma(this.form.controls.sale_price.value),
              mileage_price: this.deleteComma(this.form.controls.mileage_price.value),
              fra_price: this.deleteComma(this.form.controls.fra_price.value),
              b2b_price: this.deleteComma(this.form.controls.b2b_price.value),
              com_price: this.deleteComma(this.form.controls.com_price.value),
              buy_price: this.deleteComma(this.form.controls.buy_price.value),
              mileage: this.deleteComma(this.form.controls.mileage.value),
              is_soldout: 0,
              is_display: 1,
              approval_yn: 0,
              barcode: ''
            });
          });
        } else {
          if( colorNodes.length > 0 && sizeNodes.length > 0 ) {
            colorNodes.forEach(node1 => {
              sizeNodes.forEach(node2 => {

                const duplicate = property.filter( (item: any) => {
                  return item.color_seq === node1.seq && item.size_seq === node2.seq;
                });

                if( duplicate.length < 1 ) {
                  tmp.push({
                    seq: '',
                    color_seq: node1.seq,
                    size_seq: node2.seq,
                    color: node1.color,
                    color_name: node1.color_name,
                    display_color_name: node1.color_name,
                    size_name: node2.size_name,
                    display_size_name: node2.size_name,
                    display_price: this.deleteComma(this.form.controls.display_price.value),
                    consumer_price: this.deleteComma(this.form.controls.consumer_price.value),
                    sale_price: this.deleteComma(this.form.controls.sale_price.value),
                    mileage_price: this.deleteComma(this.form.controls.mileage_price.value),
                    fra_price: this.deleteComma(this.form.controls.fra_price.value),
                    b2b_price: this.deleteComma(this.form.controls.b2b_price.value),
                    com_price: this.deleteComma(this.form.controls.com_price.value),
                    buy_price: this.deleteComma(this.form.controls.buy_price.value),
                    mileage: this.deleteComma(this.form.controls.mileage.value),
                    is_soldout: 0,
                    is_display: 1,
                    approval_yn: 0,
                    barcode: ''
                  });
                }
              });
            });
          }
        }

        this.form.patchValue({ property: tmp });
      }

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 속성 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPropertyDelete() {
    // const nodes = this.gridApiProperty.getSelectedNodes();
    const selectedRowData = this.gridApiProperty.getSelectedRows();

    if( selectedRowData.length < 1 ) {
      this.toastrService.error( '삭제할 속성을 선택하시기 바랍니다.', '' );
    } else {
      if ( confirm('삭제하시겠습니까?') ) {
        let rowData = this.form.controls.property.value;

        const data: any = [];
        selectedRowData.forEach(item => {
          if( item.seq !== '' ) {
            data.push( item );
          }

          const index = rowData.findIndex(row => row.color_seq === item.color_seq && row.size_seq === item.size_seq);
          rowData.splice(index, 1);
        });

        this.gridApiProperty.applyTransaction({ remove: selectedRowData });
        this.form.get('property').setValue( rowData );

        if( data.length > 0 ) {
          this.productService.setPropertyDelete( data ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');

              this.getProductDetail();
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 바코드 입력 모달창
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addBarcode() {
    const propertyRows = this.gridApiProperty.getSelectedRows();

    if( propertyRows.length < 1 ) {
      this.toastrService.error( '바코드를 추가할 속성을 선택하시기 바랍니다.' );
      return false;
    } else if( propertyRows.length > 1 ) {
      this.toastrService.error( '한 개의 속성만 선택하시기 바랍니다.' );
      return false;
    } else {
      const modalRef = this.modalService.open(BarcodeInputComponent, options);
      
      modalRef.componentInstance.product_seq = this.params.seq;
      modalRef.componentInstance.property_seq = propertyRows[0].seq;
      modalRef.componentInstance.product_code = propertyRows[0].product_code;
      modalRef.componentInstance.product_name = this.productInfo.product_name;
      modalRef.componentInstance.color_seq = propertyRows[0].color_seq;
      modalRef.componentInstance.color_name = propertyRows[0].color_name;
      modalRef.componentInstance.size_seq = propertyRows[0].size_seq;
      modalRef.componentInstance.size_name = propertyRows[0].size_name;
      modalRef.componentInstance.barcode = propertyRows[0].barcode;

      modalRef.result.then((result) => {
        if( typeof result !== 'undefined' ) {

          const barcode: any = result.barcode.split('\n');

          const newSet = new Set(barcode);
          
          const newBarcode = [...newSet];

          let params = {
            product_seq: this.params.seq,
            property_seq: propertyRows[0].seq,
            barcodes: newBarcode
          };
          
          // 바코드 저장
          this.productService.setBarcodeSave( params ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');
    
              this.getProductDetail();
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });        
        }
      }, (reason) => {
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 사은품 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productGiftAdd() {
    const modalRef = this.modalService.open(ProductFindPropertyComponent, optionsXXL);

    // modalRef.componentInstance.seqArray = seqArray;

    modalRef.result.then((result) => {
      if( typeof result != 'undefined' ) {

        const newResult: any = [];
        result.forEach(obj => {
          const duplicate = this.giftProductList.filter(function (item) {
            return item.product_freegift_seq === obj.seq;
          });

          if( duplicate.length < 1 ) {
            newResult.push(obj);
          }
        });

        this.productService.setProductGiftSave( this.params.seq, newResult ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getProductGiftList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 사은품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductGiftDelete() {
    const nodes = this.gridApiGift.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 사은품을 선택하세요', '' );
      return false;
    }

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data );
    }

    if ( confirm('삭제하시겠습니까?') ) {
      this.productService.setProductGiftDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductGiftList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 연관 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // productRelationAdd() {
  //   const modalRef = this.modalService.open(ProductFindComponent, optionsXXL);

  //   // modalRef.componentInstance.seqArray = seqArray;

  //   modalRef.result.then((result) => {

  //     if( typeof result != 'undefined' ) {

  //       const newResult: any = [];
  //       result.forEach(obj => {
  //         const duplicate = this.relationProductList.filter(function (item) {
  //           return item.part_product_seq === obj.seq;
  //         });

  //         if( duplicate.length < 1 ) {
  //           newResult.push(obj);
  //         }
  //       });

  //       this.productService.setProductRelationSave( this.params.seq, newResult ).then( response => {
  //         if ( response.ResultCode ) {
  //           this.toastrService.success( response.ResultMessage, '');

  //           this.getProductRelationList();
  //         } else {
  //           this.toastrService.error( response.ResultMessage, '');
  //         }
  //       });
  //     }
  //   }, (reason) => {
  //   });
  // }

  /*******************************************************************************
    설  명 : 세트 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productSetAdd() {
    const modalRef = this.modalService.open(ProductFindComponent, optionsXXL);

    // modalRef.componentInstance.seqArray = seqArray;

    modalRef.result.then((result) => {

      if( typeof result != 'undefined' ) {

        const newResult: any = [];
        result.forEach(obj => {
          const duplicate = this.setProductList.filter(function (item) {
            return item.part_product_seq === obj.seq;
          });

          if( duplicate.length < 1 ) {
            newResult.push(obj);
          }
        });

        this.productService.setProductSetSave( this.params.seq, newResult ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getProductSetList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 연관상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // setProductRelationDelete() {
  //   const nodes = this.gridApiRelation.getSelectedNodes();

  //   if( nodes.length < 1 ) {
  //     this.toastrService.error( '삭제할 연관상품을 선택하세요', '' );
  //     return false;
  //   }

  //   const data: any = [];
  //   for(let node of nodes) {
  //     data.push( node.data );
  //   }

  //   if ( confirm('삭제하시겠습니까?') ) {
  //     this.productService.setProductRelationDelete( data ).then( response => {
  //       if( response.ResultCode ) {
  //         this.toastrService.success( response.ResultMessage, '');

  //         this.getProductRelationList();
  //       } else {
  //         this.toastrService.error( response.ResultMessage, '');
  //       }
  //     });
  //   }
  // }

  /*******************************************************************************
    설  명 : 세트상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductSetDelete() {
    const nodes = this.gridApiSet.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 세트상품을 선택하세요', '' );
      return false;
    }

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data );
    }

    if ( confirm('삭제하시겠습니까?') ) {
      this.productService.setProductSetDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductSetList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 상품 복사
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  setProductCopy() {
    if( confirm("해당 상품을 복사하시겠습니까?") ) {
      this.productService.setProductCopy( this.params.seq ).then( response => {
        if( response.ResultCode ) {
          // this.toastrService.success( response.ResultMessage, '');

          if( confirm( response.ResultMessage + "\n\n복사된 상품으로 이동하시겠습니까?") ) {
            this.params.seq = response.data;
            
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = "reload";

            this.router.navigate(
              ['/product/list/add'],
              { relativeTo: this.activatedRoute,
                queryParams: this.params,
                queryParamsHandling: '', // remove to replace all query params by provided
              }
            );
          }
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 리스트로 돌아가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.params.seq = '';

    let returnUrl: any = ( this.params.referrer == 'master' ) ? '/product/master' : '/product/list';

    this.router.navigate(
      [ returnUrl ],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 소비자 가격 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangePrice( event: any ): void {
    let id: any = event.target.id;
    let value: any = event.target.value;

    const consumerElement: any = document.getElementById( 'consumer_price' );
    const buyElement: any = document.getElementById( 'buy_price' );
    const fraElement: any = document.getElementById( 'fra_price' );

    const consumerValue: any = this.deleteComma(consumerElement.value);
    const buyValue: any = this.deleteComma(buyElement.value);
    const fraValue: any = this.deleteComma(fraElement.value);

    const price = calcPrice(consumerValue, this.form.value.customer_product_gbn, buyValue, this.form.value.brand_seq)

    if( id == 'consumer_price' || id == 'buy_price' ) {
      this.form.patchValue({
        display_price: this.getComma(consumerValue),
        sale_price: this.getComma(consumerValue),
        // com_price: this.getComma(this.comCalc(consumerValue, buyValue)),
        // b2b_price: this.getComma(this.b2bCalc(consumerValue, buyValue)),
        mileage_price: this.getComma(consumerValue),
        mileage: this.getComma(this.mileageCalc(consumerValue)),
        seller_price: this.getComma(this.b2bCalc(consumerValue, buyValue)),

        com_price: this.getComma(price.com),
        b2b_price: this.getComma(price.b2b),
      });
      
      if(price.fra) this.form.patchValue({fra_price: this.getComma(price.fra)})

      let comPrice = this.deleteComma(this.form.controls.com_price.value);
      let b2bPrice = this.deleteComma(this.form.controls.b2b_price.value);
      let sellerPrice = this.deleteComma(this.form.controls.seller_price.value);
      let mileage = this.deleteComma(this.form.controls.mileage.value);

      this.gridApiProperty.forEachNode( function(node) {
        node.data.consumer_price = consumerValue;
        node.data.buy_price = buyValue;
        node.data.display_price = consumerValue;
        node.data.sale_price = consumerValue;
        
        node.data.com_price = price.com
        node.data.b2b_price = price.b2b
        if(price.fra) node.data.fra_price = price.fra

        node.data.seller_price = sellerPrice;
        node.data.mileage_price = consumerValue;
        node.data.mileage = mileage;

        node.setData(node.data);
      });
    } else {
      this.gridApiProperty.forEachNode( function(node) {
        node.setDataValue( id, value );
      });
    }
  }

  mileageCalc(price: any) {
    price = parseInt(price.replace(/,/g, ''), 10);
    return price > 0 ? price * 0.01 : 0;
  }

  comCalc(price: any, buy: any) {
    price = parseInt(price.replace(/,/g, ''), 10);
    return price > 0 ? Math.ceil((price - ((price - buy) * 0.3)) / 100) * 100 : 0;
  }

  b2bCalc(price: any, buy: any) {
    price = parseInt(price.replace(/,/g, ''), 10);
    return price > 0 ? Math.ceil((price - ((price - buy) * 0.5)) / 100) * 100 : 0;
  }

  /*******************************************************************************
    설  명 : 공급업체 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  providerChanged( e: any ): void {
    if( e.id == '' || e.id == 0 ) {
      this.form.patchValue({
        provider_seq: ''
      });
    } else {
      this.form.patchValue({
        provider_seq: e.id
      });
    }
  }

  /*******************************************************************************
    설  명 : 매입거래처 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  customerChanged( e: any ): void {
    if( e.id == '' || e.id == 0 ) {
      this.form.patchValue({
        customer_seq: '',
        customer_product_gbn: ''
      });
    } else {
      this.form.patchValue({
        customer_seq: e.id,
        customer_product_gbn: e.data.product_gbn
      });
      
      const price = calcPrice(this.form.value.consumer_price, e.data.product_gbn, this.form.value.buy_price, this.form.value.brand_seq)

      this.form.patchValue({
        com_price: this.getComma(price.com),
        b2b_price: this.getComma(price.b2b),
      })
      
      if(price.fra) this.form.patchValue({fra_price: this.getComma(price.fra)})

      this.gridApiProperty.forEachNode(function(node: any) {
        node.data.com_price = price.com
        node.data.b2b_price = price.b2b
        if(price.fra) node.data.fra_price = price.fra

        node.setData(node.data)
      })
    }
    
  }

  /*******************************************************************************
    설  명 : 카테고리 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  mainCategoryChanged( e: any ): void {
    if( e.id == '' || e.id == 0 ) {
      this.form.patchValue({
        category_seq: ''
      });
    } else {
      this.form.patchValue({
        category_seq: e.id
      });
    }

    let categorySeq = this.getSelect2Value(this.form.controls.category_seq.value);

    let data: any = [];
    for(let item of this.addCategoryList ) {
      data.push({
        ...item,
        disabled: ( item.category_code.length <= 6 || item.id == categorySeq ) ? true : false
      });
    }

    this.addCategoryList = data;

    let category_list: any = this.form.controls.category_list.value;
    let dupIndex = category_list.findIndex((obj: any) => obj.id == categorySeq);

    if( dupIndex > -1 ) {
      category_list.splice(dupIndex, 1);

      this.form.patchValue({
        category_list: category_list
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 카테고리 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  subCategoryChanged( e: any ): void {
    this.form.patchValue({
      category_list: e
    });
  }
  
  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.giftSdate = this.utilService.getDate(moment().format('YYYY-MM-DD'));
    } else {
      this.giftEdate = this.utilService.getDate(moment().format('YYYY-MM-DD'));
    }

    obj.close();
  }

  /*******************************************************************************
    설  명 : 구매가 보다 금액이 적은지 체크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buyPriceCompareCheck( name: any ) {
    let targetPrice = this.form.get( name ).value;
    let buyPrice = this.form.get('buy_price').value;

    targetPrice = this.deleteComma(targetPrice);
    buyPrice = this.deleteComma(buyPrice);

    if( parseInt(targetPrice) <= parseInt(buyPrice) )
      return true;
    else
      return false;
  }

  
  onCellEditingStopped($event) {
    const buyPrice = $event.node.data.buy_price;
    const consumerPrice = $event.node.data.consumer_price;
    const price = calcPrice(consumerPrice, this.form.value.product_gbn, buyPrice, this.form.value.brand_seq)

    switch ($event.colDef.field) {
      case 'consumer_price':
      case 'buy_price':
        $event.node.setDataValue('com_price', price.com)
        $event.node.setDataValue('b2b_price', price.b2b)
        if(price.fra) $event.node.setDataValue('fra_price', price.fra)
        break
    }
  }
  
  /*******************************************************************************
    설  명 : 브랜드 변경시 처리
  *******************************************************************************/
  brandChanged(): void {
    const price = calcPrice(this.form.value.consumer_price, this.form.value.product_gbn, this.form.value.buy_price, this.form.value.brand_seq)

    this.form.patchValue({
      com_price: this.getComma(price.com),
      b2b_price: this.getComma(price.b2b),
    })
    
    if(price.fra) this.form.patchValue({fra_price: this.getComma(price.fra)})

    this.gridApiProperty.forEachNode(function(node: any) {
      node.data.com_price = price.com
      node.data.b2b_price = price.b2b
      if(price.fra) node.data.fra_price = price.fra

      node.setData(node.data)
    })
  }

  /*******************************************************************************
    설  명 : select2 값 추출
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSelect2Value(value: any) {
    if( value !== undefined && value !== null && value !== '' && Array.isArray(value) && value.length > 0 ) return value[0].value;
    else if( value !== undefined && value !== null && value !== '' && typeof value === 'object' ) return value.value;
    return value;
  }
}
