<section id="customer">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="58" minSize="50" maxSize="70">
      <div class="mb5">
        <div class="fl w82p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getCustomerList()">
            <i class="material-icons-outlined">search</i>
          </button>

          <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="search.search_text" (keypress)="searchList($event)" placeholder="통합검색" />

          <div class="btn-group ml5">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.search_nation==''}" (click)="search.search_nation='';getCustomerList();">전체</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.search_nation=='D'}" (click)="search.search_nation='D';getCustomerList();">국내</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.search_nation=='O'}" (click)="search.search_nation='O';getCustomerList();">국외</button>
          </div>

          <div class="btn-group ml5">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.search_use==''}" (click)="search.search_use='';getCustomerList();">전체</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.search_use=='Y'}" (click)="search.search_use='Y';getCustomerList();">사용</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.search_use=='N'}" (click)="search.search_use='N';getCustomerList();">미사용</button>
          </div>

          <div class="btn-group ml5">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.product_gbn==''}" (click)="search.product_gbn='';getCustomerList();">전체</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.product_gbn=='1000'}" (click)="search.product_gbn='1000';getCustomerList();">자사</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.product_gbn=='2000'}" (click)="search.product_gbn='2000';getCustomerList();">타사</button>
          </div>

          <select [(ngModel)]="search.search_jungsan" class="form-control form-control-small ml5" (change)="getCustomerList();">
            <option value="">전체</option>
            <option *ngFor="let item of dealTypeList" value="{{item.common_code}}">{{item.common_name}}</option>
          </select>
        </div>
        <div class="fr w18p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="8fbf4531267b4f33a5bf7f2a160687fa" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" title="엑셀다운" class="btn btn-default btn-small-icon ml5" (click)="setExcelCustomer()">
            <i class="material-icons-outlined">download</i>
          </button>
          <button type="button" title="창고추가" class="btn btn-primary btn-small-icon ml5" (click)="addCustomer()">
            <i class="material-icons-outlined">add</i>
          </button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="customerList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="42" minSize="30" maxSize="50">
      <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
        <mat-tab label="거래처정보">
          <app-customer-tab-info *ngIf="activeTab==0" [customerSeq]="customerSeq" (dataSave)="onChangeCustomer()"></app-customer-tab-info>
        </mat-tab>
        <mat-tab label="담당자">
          <app-customer-tab-charge *ngIf="activeTab==1" [customerSeq]="customerSeq"></app-customer-tab-charge>
        </mat-tab>
        <mat-tab label="메모">
          <app-customer-tab-memo *ngIf="activeTab==2" [customerSeq]="customerSeq"></app-customer-tab-memo>
        </mat-tab>
        <mat-tab label="최근입고내역">
          <app-customer-tab-ingoods *ngIf="activeTab==3" [customerSeq]="customerSeq"></app-customer-tab-ingoods>
        </mat-tab>
      </mat-tab-group>
    </as-split-area>
  </as-split>
</section>
