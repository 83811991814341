<div class="modal-header">
  <h4 class="modal-title fb mb0">세나제품 입고/출고 관리</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

  <table class="table table-bordered table-small1 mb50">
  <colgroup>
    <col style="width:10%" />
    <col style="width:40%" />
    <col style="width:10%" />
    <col style="width:40%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="sena_status" class="control-label">처리할 상태</label></th>
    <td colspan="3">
      <div class="btn-group db">
        <button type="button" *ngFor="let item of statusList" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sena_status.value == item.common_code}" (click)="setSenaStatus(item.common_code)">{{item.common_name}}</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sena_status.value == 'modify'}" (click)="setSenaStatus('modify')">변경</button>
      </div>
    </td>
  </tr>
  <tr *ngIf="trViewCheck('1')">
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="product_seq" class="control-label">상품번호</label></th>
    <td><inputEx type="text" name="product_seq" [formGroup]="form" [formErrors]="formErrors" [addOn]="searchProductFunc" addOnText="상품검색"></inputEx></td>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="product_name" class="control-label">상품명</label></th>
    <td><inputEx type="text" name="product_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr *ngIf="trViewCheck('2')">
    <th scope="row">
      <label for="" class="control-label">시리얼 입력</label><br/>
      <button type="button" class="btn btn-success btn-small" (click)="addSerialRows(10)">열 줄씩 추가</button>
    </th>
    <td colspan="3" style="height: 320px;">
      <div class="h100">
        <ag-grid-angular
          #myGrid
    
          style="width: 100%; height:100%;"
          class="ag-theme-balham"
    
          [defaultColDef]="defaultColDef"
          [columnDefs]="columnDefs"
          [rowData]="form.controls.serial.value"
          [singleClickEdit]="true"
          [stopEditingWhenGridLosesFocus]="true"
    
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </td>
  </tr>
  <tr *ngIf="trViewCheck('7')">
    <th scope="row"><label for="store_seq" class="control-label">판매처</label></th>
    <td colspan="3">
      <div class="btn-group db">
        <button type="button" *ngFor="let item of storeList" class="btn btn-default btn-small" [ngClass]="{active: form.controls.store_seq.value == item.seq}" (click)="form.get('store_seq').setValue(item.seq)">{{item.store_name}}</button>
        <span class="ml5">기존데이터 : {{form.controls.shop.value}}</span>
      </div>
      <inputEx type="hidden" name="store_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr>
  <tr *ngIf="trViewCheck('4')">
    <th scope="row"><label for="sn1" class="control-label">SN1</label></th>
    <td><inputEx type="text" name="sn1" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="sn2" class="control-label">SN2</label></th>
    <td><inputEx type="text" name="sn2" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr *ngIf="trViewCheck('8')">
    <th scope="row"><label for="as_sn1" class="control-label">반품입고된 SN1</label></th>
    <td><inputEx type="text" name="as_sn1" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="as_sn2" class="control-label">반품입고된 SN2</label></th>
    <td><inputEx type="text" name="as_sn2" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr *ngIf="trViewCheck('5')">
    <th scope="row"><label for="order_seq" class="control-label">주문번호</label></th>
    <td><inputEx type="text" name="order_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="buyer_name" class="control-label">구매자명</label></th>
    <td><inputEx type="text" name="buyer_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr *ngIf="trViewCheck('6')">
    <th scope="row"><label for="buyer_hphone" class="control-label">연락처</label></th>
    <td><inputEx type="text" name="buyer_hphone" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="buyer_id" class="control-label">구매자 아이디</label></th>
    <td><inputEx type="text" name="buyer_id" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr *ngIf="trViewCheck('3')">
    <th scope="row"><label for="memo">메모</label></th>
    <td colspan="3"><inputEx type="textarea" name="memo" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  </tbody>
  </table>

  </form>
</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="button" class="btn btn-default btn-small mr5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    <button type="button" class="btn btn-primary btn-small" (click)="setProductSenaProcess()">저장하기</button>
  </div>
</div>

