import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { OrderService } from '@app/service/order.service';

@Component({
  selector: 'app-order-delivery-charge-add',
  templateUrl: './order-delivery-charge-add.component.html',
  styleUrls: ['./order-delivery-charge-add.component.scss']
})
export class OrderDeliveryChargeAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() order_seq: any;
  @Input() data: any;

  public title: any = '추가';

  public customerList: any = [];

  public form: FormGroup;
  public formErrors = {};

  public customerChangedFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      order_seq: ['', [Validators.required] ],
      customer_seq: ['', [Validators.required] ],
      delivery_charge: ['3000', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private basicService: BasicService,
    private orderService: OrderService
  ) {
    this.buildForm();

    this.customerChangedFunc = this.customerChanged.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 거래처 리스트 가져오기
    this.getCustomerList();

    this.form.patchValue({
      order_seq: this.order_seq
    });

    if( this.data !== undefined ) {
      this.title = '수정';
      this.form.patchValue( this.data );
    }
  }

  /*******************************************************************************
    설  명 : 거래처 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerList() {
    this.basicService.getCustomerList({search_use: 'Y'}).then( response => {
      if( response.ResultCode ) {
        const data: any = [];
        data.push({
          id: '',
          text: '선택',
          value: ''
        });

        for(let item of response.data ) {
          data.push({
            id: item.seq,
            text: item.comname + ' (' + item.customer_id + ')',
            value: item.seq
          });
        }

        this.customerList = data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }
  
  /*******************************************************************************
    설  명 : 매입거래처 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  customerChanged( e: any ): void {
    if( e.id == '' || e.id == 0 ) {
      this.form.patchValue({
        customer_seq: ''
      });
    } else {
      this.form.patchValue({
        customer_seq: e.id
      });
    }
  }
  
  /*******************************************************************************
    설  명 : select2 값 추출
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSelect2Value(value: any) {
    if( value !== undefined && value !== null && value !== '' && Array.isArray(value) && value.length > 0 ) return value[0].value;
    else if( value !== undefined && value !== null && value !== '' && typeof value === 'object' ) return value.value;
    return value;
  }
  
  /*******************************************************************************
    설  명 : 배송비 내역 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeliveryChargeSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if( this.form.valid ) {

      let data = JSON.parse(JSON.stringify(this.form.value));

      data.customer_seq = this.getSelect2Value( data.customer_seq );

      this.orderService.setDeliveryChargeSave( data ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
