<div class="modal-header">
  <h4 *ngIf="seq!=0" class="modal-title fb mb0">SMS/이메일 수신거부 상세정보</h4>
  <h4 *ngIf="seq==0" class="modal-title fb mb0">SMS/이메일 수신거부 추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    <inputEx type="hidden" name="mem_no" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">회원정보</caption>
    <colgroup>
      <col style="width:15%;" />
      <col style="width:35%;" />
      <col style="width:15%;" />
      <col style="width:35%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="id">아이디</label></th>
      <td><inputEx type="text" [readonly]="true" name="id" [formGroup]="form" [formErrors]="formErrors" [addOn]="addOnMemberFindFunc" addOnText="회원검색"></inputEx></td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">이름</label></th>
      <td><inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="email">이메일</label></th>
      <td><inputEx type="text" name="email" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="hp">휴대폰</label></th>
      <td><inputEx type="text" name="hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="memo">메모</label></th>
      <td colspan="3"><inputEx type="textarea" name="memo" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    </tbody>
    </table>

  </form>

</div>

<div class="modal-footer">
  <div class="fl w50p">
    <button *ngIf="seq!=0" type="button" class="btn btn-danger btn-small" (click)="setMemberRefusalDelete()">삭제하기</button>
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="setMemberRefusalSave()">저장하기</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
  </div>
</div>
