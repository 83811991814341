<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체 <span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">승인 <span class="cnt">{{statistic?.approvalCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>
  <div class="cb"></div>
</section>

<section id="member-society">

  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="동호회명, 동호회회원명" />

      <div class="btn-group ml10">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isApproval==''}" (click)="setSearchValue('isApproval','')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isApproval=='1'}" (click)="setSearchValue('isApproval','1')">승인</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isApprovale=='0'}" (click)="setSearchValue('isApproval','0')">미승인</button>
      </div>
      <select class="form-control form-control-small ml5" placeholder="지역검색"  (change)="setRegionValue($event)">
        <option value="">전체</option>
        <option *ngFor="let item of regionList" [value]="item.common_code">{{item.common_name}}</option>
      </select>      
      

    </div>

    <div class="cb"></div>
  </div>

  <section>
    <as-split unit="percent" restrictMove="true" gutterSize="10" style="height: calc(100vh - 480px);background-color:#fff;">
      <as-split-area size="63" minSize="43" maxSize="83">
        <div class="mb5">
          <div class="fl w50p">
            <h4 class="mb0 fb">동호회</h4>
          </div>
          <div class="fr w50p right">
            <button type="button" class="btn btn-primary btn-small ml5" (click)="addSociety(null)">동호회 추가</button>
          </div>
          <div class="cb"></div>
        </div>
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:calc(100% - 35px);"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="societyList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
          (cellDoubleClicked)="onCellDoubleClicked($event)"
          (cellClicked)="onCellClicked($event)"
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </as-split-area>
      <as-split-area size="37" minSize="17" maxSize="57">
        <div class="mb5">
          <div class="fl w50p">
            <h4 class="mb0 fb">동호회 회원</h4>
          </div>
          <div class="fr w50p right">
            <!-- <button type="button" class="btn btn-primary btn-small ml5" (click)="appSocietyMember()">승인처리</button> -->
            <button type="button" class="btn btn-danger btn-small ml5" (click)="delSocietyMember()">회원삭제</button>
          </div>
          <div class="cb"></div>
        </div>
        <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 515px);background-color:#fff;">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDetailDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="societyMemberList"
            [pagination]="false"
            [rowSelection]="rowSelection"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"

            (gridReady)="onGridDetailReady($event)"
            >
          </ag-grid-angular>
        </as-split>
      </as-split-area>
    </as-split>
  </section>
  <section class="mt10">
    <div class="mb5">
      <div class="fl w50p">
        <h4 class="mb0 fb">동호회 상담</h4>
      </div>
      <div class="fr w50p right">
        <!-- <button type="button" class="btn btn-primary btn-small ml5" (click)="addSociety(null)">저장하기</button> -->
      </div>
      <div class="cb"></div>
    </div>
    <as-split unit="percent" restrictMove="true" gutterSize="10" style="height:calc(100vh - 655px);background-color:#fff;">
      <as-split-area size="30" minSize="30" maxSize="50">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnMemoDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="applicationMemo"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (gridReady)="onGridMemoReady($event)"
          >
        </ag-grid-angular>
      </as-split-area>
      <as-split-area size="70" minSize="50" maxSize="70">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnMemo1Defs"
          [defaultColDef]="defaultColDef"
          [rowData]="counselingMemo"
          [pagination]="false"
          [domLayout]="domLayout"
          [getRowHeight]="getRowHeight"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [stopEditingWhenGridLosesFocus]="true"

          (gridReady)="onGridMemo1Ready($event)"
          (cellEditingStopped)="onCellEditingStopped($event)"
          >
        </ag-grid-angular>
      </as-split-area>

    </as-split>
  </section>


</section>

