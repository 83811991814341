import { Component, OnInit } from '@angular/core';

import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SmsService } from '@app/service/sms.service';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { OrderReturnreqDetailComponent } from '@page/order/order-returnreq/order-returnreq-detail/order-returnreq-detail.component';
import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import * as moment from 'moment';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-returnreq',
  templateUrl: './order-returnreq.component.html',
  styleUrls: ['./order-returnreq.component.scss']
})
export class OrderReturnreqComponent implements OnInit {
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 26,
    reg_gbn: '',
    approval_yn: '',
    searchField: 'seq',
    isCancel: '',
    sdate: this.utilService.getDate(moment().subtract(90, 'day')),
    edate: this.utilService.getDate(''),
  };
  public cancelList: any = [];
  public components: any;
  public form: FormGroup;
  public formErrors = {};
  public totalCount: any;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      message: ['', []]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private agGridExComponent: AgGridExComponent,
    private orderService: OrderService,
    private toastrService: ToastrService,
    private smsService:SmsService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      {
        headerName: '번호',
        field: 'req_seq',
        width: 90,
        cellClass: 'cp right',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      { headerName: '구분', field: 'reg_gbn', width: 60, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.reg_gbn == '2000' ) {
            return '<span class="badge badge-success f12">반품</span>';
          } else if ( params.data.reg_gbn == '3000' ) {
            return '<span class="badge badge-primary f12">교환</span>';
          }
        }
      },
      { headerName: '승인상태', field: 'approval_yn', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.approval_yn == '1' && params.data.is_cancel == '0' ) {
            return '<span class="badge badge-primary f12">승인</span>';
          } else if ( params.data.approval_yn == '2' && params.data.is_cancel == '0' ) {
            return '<span class="badge badge-danger f12">거절</span>';
          } else if ( params.data.is_cancel == '1' ) {
            return '<span class="badge badge-secondary f12">철회</span>';
          } else  if ( params.data.approval_yn == '3' ) {
            return '<span class="badge badge-info f12">완료</span>';
          } else {
            return '<span class="badge badge-success f12">승인요청</span>';
          }
        }
      },
      { headerName: '요청일자', field: 'req_date', width: 100, cellClass: 'cp center' },
      { headerName: '주문번호', field: 'order_seq', width: 90, cellClass: 'cp center' },
      { headerName: '주문일자', field: 'order_date', width: 100, cellClass: 'cp center' },
      { headerName: '고객명', field: 'o_name', width: 80, cellClass: 'cp center' },
      { headerName: '연락처', field: 'o_hp', width: 120, cellClass: 'cp center' },
      { headerName: '메세지', field: 'comment', width: 150, cellClass: 'cp cell-wrap-text' },
      { headerName: '요청사유', field: 'req_reason', width: 90, cellClass: 'cp right',
        valueGetter: function(params) {
          if( params.data.req_reason == '1' ) {
            return '배송오류';
          } else if ( params.data.req_reason == '2' ) {
            return '상품불량';
          } else if ( params.data.req_reason == '3' ) {
            return '단순변심';
          } else if ( params.data.req_reason == '4' ) {
            return '기타';
          }
        }
      },
      { headerName: '요청개수', field: 'all_req_qty', width: 75, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '차감금액', field: 'deduction_amt', width: 80, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '동봉배송비', field: 'enclose_amt', width: 90, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '환불금액', field: 'real_ret_amt', width: 90, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '처리방식', field: '', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.req_type == '1000' ) {
            return '<span class="badge badge-primary f12">' + params.data.req_type_name + '</span>';
          } else if ( params.data.req_type == '2000' ) {
            return '<span class="badge badge-success f12">' + params.data.req_type_name + '</span>';
          }
        }
      },
      { headerName: '배송비지급', field: '', width: 135, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.req_pay_type == '1000' ) {
            return '<span class="badge badge-primary f12">' + params.data.req_pay_type_name + '</span>';
          } else if ( params.data.req_pay_type == '2000' ) {
            return '<span class="badge badge-success f12">' + params.data.req_pay_type_name + '</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '', field: '', width: 90, cellClass: 'cp center',
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          label: '상세보기',
          btnClass: 'btn btn-primary btn-grid1 f12',
          clicked: this.orderDetail.bind(this),
        },
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      btnCellRenderer: this.agGridExComponent.getBtnCellRenderer()
    };
  }

  ngOnInit(): void {
    this.getCancelOrder();
  }

  /*******************************************************************************
    설  명 : 반품, 교환 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCancelOrder() {
    this.orderService.getCancelOrder( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.cancelList = response.data;
        this.totalCount = response.total;
      }
    });
  }

  /*******************************************************************************
    설  명 : 상세보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderDetail(data: any) {
    const modalRef = this.modalService.open(OrderReturnreqDetailComponent, options);
    modalRef.componentInstance.cancelData = data;

    modalRef.result.then((result) => {
      this.getCancelOrder();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getCancelOrder();
  }

  /*******************************************************************************
    설  명 : 반품/교환 탭 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  stateSelect(reg_gbn) {
    this.search.reg_gbn = reg_gbn;

    this.getCancelOrder();
  }

  /*******************************************************************************
    설  명 : 승인여부 탭 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  approvalSelect(approval_yn) {
    this.search.isCancel = '';
    this.search.approval_yn = approval_yn;

    this.getCancelOrder();
  }

  /*******************************************************************************
    설  명 : 철회 탭 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  cancelSelect(num) {
    this.search.approval_yn = '4';
    this.search.isCancel = num;

    this.getCancelOrder();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 클릭
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getList() {
    this.getCancelOrder();

  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 26,
      reg_gbn: '',
      approval_yn: '',
      searchField: 'seq'
    };

    this.getCancelOrder();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCancelOrder();
    }
  }

  /*******************************************************************************
    설  명 : 선택 승인/미승인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  approval( num ) {
    const nodes: any = this.gridApi.getSelectedRows();
    let seq = [];
    let tmp = '0';

    nodes.map( rows => {
      seq.push( rows.req_seq );
    });

    nodes.map( rows => {
      if ( rows.is_cancel == '1' ) {
        tmp = '1'
      }
    });

    if ( tmp == '1' ) {
      this.toastrService.error('철회된 신청은 승인/미승인 할 수 없습니다.');
    } else {
      this.orderService.setApprovalSave( seq, num, this.form ).then( response => {
        if ( response.ResultCode ) {
          // sms 자동발송
          let params: any = [];

          nodes.map( rows => {
            params.push({
              seq: rows.req_seq,
              hp: rows.o_hp
            });
          })

          this.smsService.send_smsConfirmExchange( params ).then( response => {
            params = [];
            if ( response.data.ResultCode ) {
              this.toastrService.success(response.data.ResultMessage);
            } else {
              this.toastrService.error(response.data.ResultMessage);
            }
          });

          this.toastrService.success('변경사항을 저장하였습니다.');
          this.getCancelOrder();
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : ag-grid api
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 선택 철회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  cancel() {
    const nodes: any = this.gridApi.getSelectedRows();
    let seq = [];

    nodes.map( rows => {
      seq.push( rows.req_seq );
    });

    this.orderService.setCancelCancel( seq ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success('성공적으로 철회하였습니다.');
        this.getCancelOrder();
      }
    });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check ) {
    if( check == 'sdate' ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'edate')  {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'paydate') {
      this.search.paydate = this.utilService.getDate( '' );
      obj.close();
    }
  }
}
