  <section class="widget-div">


    <div class="fl widget cp" *ngFor = "let item of statistic; index as idx" style="height: 70px; width: 24%; margin-bottom: 5px;">
      <div class="fl widget-icon widget-icon{{ (idx % 4) + 1 }}">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p>{{item.warehouse_name}}</p>
        <p class="txt1">재고 <span class="cnt">{{item.stock | number}}</span>개 / 금액 <span class="cnt">{{item.amt | number}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>


  <mat-tab-group style="height:80% !important">
    <mat-tab style="min-width:100px;" label="브랜드별재고현황">
      <ng-template matTabContent>
        <div class="mt10 mb5">
        
          <div class="fr right">
            <ag-grid-excel [gridApi]="gridApi_E" selected="false" filename="브랜드별재고현황" title="브랜드별재고현황"></ag-grid-excel>
            <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi_E" gridId="a53de7a7d7b247b8b7a522060acfb0eb" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>


        <ag-grid-angular
          #myGrid

          style="width:100%;height:90%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs_E"
          [defaultColDef]="defaultColDef"
          [rowData]="stock_E"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event, 'E')"
          >
        </ag-grid-angular>
      </ng-template>
    </mat-tab>

    <mat-tab style="min-width:100px;" label="현재고현황">
      <div class="mt10 mb5">

        <div class="fl form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit('A')">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <div class="input-group ml5" *ngFor = "let item of [
            {name : '창고', value : 'warehouse_name'},
            {name : '카테고리', value : 'category_name'},
            {name : '브랜드', value : 'brand_name'}, 
            {name : '상품명', value : 'product_name'},
            {name : '색상', value : 'color_name'},
            {name : '사이즈', value : 'size_name'},
            {name : '대표상품번호', value : 'product_seq'},
            {name : '자식상품번호', value : 'product_property_seq'}
          ]">
            <input type="text" size="14" class="form-control form-control-small" placeholder="{{item.name}} 검색" [(ngModel)] = "search_A[item.value]" (keypress)="keypress($event, 'A')" />
            <div class="input-group-append">
              <button type="button" class="btn btn-default btn-small f12" (click) = "getStockStatistic_A()">검색</button>
            </div>
          </div>
        </div>

        <div class="fr right">
          <!-- <ag-grid-excel [gridApi]="gridApi_fake" selected="false" filename="현재고현황" title="현재고현황"></ag-grid-excel> -->
          <ng-container>
            <button type="button" class="btn btn-default btn-small f12 ml5" (click) = "allDataExcelExport()"><span>전체엑셀다운</span></button>
          </ng-container>
          <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi_A" gridId="497520f7f350449287155a99e59f58b1" [btnGroup]="1"></btn-ag-grid-save>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 90px)">
        <ag-grid-angular
          #myGrid

          style="width:100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs_A"
          [defaultColDef]="defaultColDef"
          [rowData]="stock_A"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event, 'A')"
          >
        </ag-grid-angular>
      </div>

      <section class="pagination mt10">
        <ngb-pagination
          [(page)] = "search_A.index"
          [pageSize] = "100"
          [collectionSize] = "search_A.count"
          [maxSize] = "10"
          [rotate] = "true"
          [boundaryLinks] = "true"
          (pageChange) = "pageChange('A', $event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </section>

    </mat-tab>

    <mat-tab style="min-width:100px;" label="재고수불내역">
      <ng-template matTabContent>
      
        <div class="mt10 mb5">

          <div class="fl form-inline">
            <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit('B')">
              <i class="material-icons-outlined">refresh</i>
            </button>
            <div class="input-group ml5" *ngFor = "let item of [
              {name : '창고', value : 'warehouse_name'},
              {name : '카테고리', value : 'category_name'},
              {name : '브랜드', value : 'brand_name'}, 
              {name : '상품명', value : 'product_name'},
              {name : '색상', value : 'color_name'},
              {name : '사이즈', value : 'size_name'}
            ]">
              <input type="text" class="form-control form-control-small" placeholder="{{item.name}} 검색" [(ngModel)] = "search_B[item.value]" (keypress)="keypress($event, 'B')" />
              <div class="input-group-append">
                <button type="button" class="btn btn-default btn-small f12" (click) = "getStockStatistic_B()">검색</button>
              </div>
            </div>
          </div>

          <div class="fr right">
            <!-- <ag-grid-excel [gridApi]="gridApi_B" selected="false" filename="재고수불내역" title="재고수불내역"></ag-grid-excel> -->
          <ng-container>
            <button type="button" class="btn btn-default btn-small f12 ml5" (click) = "allDataExcelExport('B')"><span>전체엑셀다운</span></button>
          </ng-container>
            <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi_B" gridId="915c4025d6b84dad896e4e3ab30b5296" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <ag-grid-angular
          #myGrid

          style="width:100%;height:calc(100% - 90px);"
          class="ag-theme-balham"

          [columnDefs]="columnDefs_B"
          [defaultColDef]="defaultColDef"
          [rowData]="stock_B"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event, 'B')"
          >
        </ag-grid-angular>
        
        <section class="pagination mt10">
          <ngb-pagination
            [(page)] = "search_B.index"
            [pageSize] = "100"
            [collectionSize] = "search_B.count"
            [maxSize] = "10"
            [rotate] = "true"
            [boundaryLinks] = "true"
            (pageChange) = "pageChange('B', $event)"
            >
            <ng-template ngbPaginationFirst>처음</ng-template>
            <ng-template ngbPaginationLast>마지막</ng-template>
            <ng-template ngbPaginationPrevious>이전</ng-template>
            <ng-template ngbPaginationNext>다음</ng-template>
          </ngb-pagination>
        </section>

      </ng-template>
    </mat-tab>

    <mat-tab style="min-width:100px;" label="창고별재고현황">
      <ng-template matTabContent>
        <div class="mt10 mb5">
          
          <div class="fr right">
            <ag-grid-excel [gridApi]="gridApi_C" selected="false" filename="창고별재고현황" title="창고별재고현황"></ag-grid-excel>
            <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi_C" gridId="d2f3c0c6d3fc4675a5478ee656783329" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <ag-grid-angular
          #myGrid

          style="width:100%;height:calc(100% - 90px);"
          class="ag-theme-balham"

          [columnDefs]="columnDefs_C"
          [defaultColDef]="defaultColDef"
          [rowData]="stock_C"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event, 'C')"
          >
        </ag-grid-angular>
      </ng-template>
    </mat-tab>

    <mat-tab style="min-width:100px;" label="상품별재고현황">
      <ng-template matTabContent>
        <div class="mt10 mb5">
        
          <div class="fr right">
            <ag-grid-excel [gridApi]="gridApi_D" selected="false" filename="상품별재고현황" title="상품별재고현황"></ag-grid-excel>
            <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi_D" gridId="a53de7a7d7b247b8b7a522060acfb0eb" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <ag-grid-angular
          #myGrid

          style="width:100%;height:calc(100% - 90px);"
          class="ag-theme-balham"

          [columnDefs]="columnDefs_D"
          [defaultColDef]="defaultColDef"
          [rowData]="stock_D"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event, 'D')"
          >
        </ag-grid-angular>
      </ng-template>
    </mat-tab>


  </mat-tab-group>

  <ag-grid-angular
    #myGrid

    style="display: none;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs_fake"
    [defaultColDef]="defaultColDef"
    [rowData]="stock_fake"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (gridReady)="onGridReady($event, 'fake')"
    >
  </ag-grid-angular>