<section id="event-management">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">진행중<span class="cnt">{{statistic?.processCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">진행예정<span class="cnt">{{statistic?.standbyCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon5">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">종료<span class="cnt">{{statistic?.completeCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>


  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getEventList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status==''}" (click)="search.status='';getEventList()">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='1'}" (click)="search.status='1';getEventList()">진행중</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='2'}" (click)="search.status='2';getEventList()">진행예정</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='0'}" (click)="search.status='0';getEventList()">종료</button>
      </div>

      <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.eventName" (keypress)="searchList($event)" placeholder="이벤트명 검색" />

      <input type="text" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" size="12" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" readonly="readonly" id="edate" [(ngModel)]="search.edate" size="12" class="form-control form-control-small calendar-input m15" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>
    </div>
    <div class="fr w30p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="8aaf742050bc4cc985ca594dd971e4e8" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-success btn-small ml5" (click)="setEventSortSave()">순서 저장</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="eventAdd()">이벤트 추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 90px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="eventList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowDragManaged]="true"
      [enableMultiRowDragging]="true"
      [animateRows]="true"

      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
