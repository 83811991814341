<div class="modal-header">
  <h4 class="modal-title fb mb0">동호회 이력 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <table class="table table-bordered table-small1 mb0">
    <caption class="none">동호회이력정보</caption>
    <colgroup>
      <col style="width:15%;" />
      <col style="width:20%;" />
      <col style="width:15%;" />
      <col style="width:40%;" />
    </colgroup>

    <tbody>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="gbn">구분</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gbn.value =='1000'}" (click)="form.patchValue({gbn: '1000'})">누적</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gbn.value =='2000'}" (click)="form.patchValue({gbn: '2000'})">차감</button>
          </div>

        </td>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="type">형태</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.type.value =='1010'}" (click)="form.patchValue({type: '1010'})">주문</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.type.value =='1020'}" (click)="form.patchValue({type: '1020'})">관리자지급</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.type.value =='2010'}" (click)="form.patchValue({type: '2010'})">주문환불</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.type.value =='2020'}" (click)="form.patchValue({type: '2020'})">지원금사용차감</button>
          </div>
        </td>
      </tr>      
      <tr>
        <th scope="row"><label for="reason">사유</label></th>
        <td>
          <inputEx type="select" name="reason" [formGroup]="form" [formErrors]="formErrors" [data]="reasonList"></inputEx>
          <!-- <select id="field16" name="reason" [formGroup]="form" >
            <option *ngFor="let item of reasonList" value="{{item.reason}}">{{item.reason_name}}</option>
          </select> -->
        </td>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="reg_date">등록일자</label></th>
        <td>
          <inputEx type="date" name="reg_date"  [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>        
      </tr>      
      <tr>
        <th scope="row"><label for="mem_no">회원번호</label></th>
        <td>
          <inputEx type="text" name="mem_no" [formGroup]="form" [formErrors]="formErrors" [addOn]="searchMemberFunc" addOnText="회원 검색"></inputEx>
        </td>
        <th scope="row"><label for="member_name">회원명</label></th>
        <td>
          <inputEx type="text" name="member_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="order_seq">주문번호</label></th>
        <td>
          <inputEx type="text" name="order_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="order_detail_seq">주문상세번호</label></th>
        <td>
          <inputEx type="text" name="order_detail_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="sale_amt">매출금액</label></th>
        <td>
          <inputEx type="text" name="sale_amt" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i><label for="margin_amt">이력점수</label></th>
        <td>
          <inputEx type="text" name="margin_amt" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="memo">메모</label></th>
        <td colspan="3">
          <inputEx type="text" name="memo" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>      
    </tbody>
    </table>
  </form>
</div>

<div class="modal-footer">
  <div class="fl w50p">
    <button *ngIf="seq != '0'" type="button" class="btn btn-danger btn-small">삭제</button>
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="submit()">저장</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
  </div>
</div>

