      <form name="formCommonIns" id="formCommonIns" class="form-horizontal" role="form" data-toggle="validator" ng-submit="formSubmit();">
        <div class="modal-header">
          <h4 class="modal-title bold">공문서시행 {{title}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
        </div>

        <div class="modal-body">
          <table class="table table-bordered table-small">
          <colgroup>
            <col style="width:20%" />
            <col style="width:80%" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row"><label for="authorization_date">결재일자</label></th>
            <td>
              <inputEx type="text" id="authorization_date" name="authorization_date" [formGroup]="form" [formErrors]="formErrors" readonly="readonly"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="title">제목</label></th>
            <td>
              <inputEx type="text" id="title" name="title" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              <div *ngIf="form.controls.title.errors && form.controls.title.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="execution_date">시행일자</label></th>
            <td>
              <inputEx type="text" id="execution_date" name="execution_date" [formGroup]="form" [formErrors]="formErrors" readonly="readonly"></inputEx>
            </td>
          </tr>

          <tr>
            <th scope="row"><label for="receiving_company">수신처</label></th>
            <td>
              <inputEx type="text" id="receiving_company" name="receiving_company" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              <div *ngIf="form.controls.receiving_company.errors && form.controls.receiving_company.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="mem_no">확인자</label></th>
            <td>
              <inputEx type="select" name="mem_no" [formGroup]="form" [formErrors]="formErrors" [data]="employeeList"></inputEx>
            </td>
          </tr>
          </tbody>
          </table>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary btn-small mr4" (click)="editClick()">저장</button>
          <button type="button" class="btn btn-default btn-small f15" aria-label="Close">취소</button>
        </div>
        <!-- Modal footer -->

      </form>