<section id="member-coupon-list">
    <div class="mb5">
      <div class="fl w70p form-inline">
        <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
          <i class="material-icons-outlined">refresh</i>
        </button>
        <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberCouponList()">
          <i class="material-icons-outlined">search</i>
        </button>
  
        <div class="btn-group ml5">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.is_use == ''}" (click)="search.is_use = '';getMemberCouponList()">전체</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.is_use == '0'}" (click)="search.is_use = '0';getMemberCouponList()">미사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.is_use == '1'}" (click)="search.is_use = '1';getMemberCouponList()">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.is_use == '2'}" (click)="search.is_use = '2';getMemberCouponList()">페기</button>
        </div>
  
        <input type="text" readonly="readonly" [(ngModel)]="search.sdate" size="13" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
        <ng-template #footerTemplate1>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
        </ng-template>
        <span class="ml5 mr5">~</span>
        <input type="text" readonly="readonly" [(ngModel)]="search.edate" size="13" class="form-control form-control-small calendar-input m15" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
        <ng-template #footerTemplate2>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
        </ng-template>
  
        <input type="text"  [(ngModel)]="search.order_seq" size="13" class="form-control form-control-small ml5 left" placeholder="오더번호" />
  
      </div>
      
      <div class="fr w30p right">
        <button type="button" class="btn btn-danger btn-small f12 mr5" (click)="couponDelete()">쿠폰삭제 삭제</button>
        <button type="button" class="btn btn-success btn-small f12 mr5" (click)="managerCoupon()">관리자 쿠폰 지급</button>
        <button type="button" class="btn btn-default btn-small f12 mr5" (click)="setExcelOrder()"><span>엑셀다운</span></button>
        <ng-container *ngIf="dormant===false">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="3e593250cfce4b3d8e0d0f49f820dd0b" [btnGroup]="1"></btn-ag-grid-save>
        </ng-container>
      </div>
      <div class="cb"></div>
    </div>
  
    <div class="" style="height:calc(100% - 37px)">
      <ag-grid-angular
        #myGrid
  
        style="width: 100%;height:100%;"
        class="ag-theme-balham"
  
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="memberCouponList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
  
        (rowClicked)="onRowClicked($event)"
        (gridReady)="onGridReady($event)"
        >
      </ag-grid-angular>
    </div>
  </section>
  