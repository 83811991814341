<div class="mt5 mb5">
  <div class="fl w70p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>

    <input type="text" size="50" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="주문자명, 상품명, 브랜드, 공급자명 검색" />

    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getEventOrderList()">
      <i class="material-icons-outlined">search</i>
    </button>

    <input type="text" id="quickFilter" size="50" (input)="onQuickFilterChanged($event)" class="form-control form-control-small ml5" placeholder="그리드 내 모든 필드 검색 필터링" />
  </div>
  <div class="fr w30p right">
    <button type="button" class="btn btn-default btn-small mr5" (click)="setExcelSale()">판매정보 다운로드</button>
    <button type="button" class="btn btn-default btn-small mr5" (click)="setExcelBuyer()">구매자정보 다운로드</button>
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="727c036ca5ed4cffa6b527af47ce268b" [btnGroup]="1"></btn-ag-grid-save>
  </div>
  <div class="cb"></div>
</div>

<div style="height:calc(100% - 40px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="eventStatisticList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [getRowHeight]="getRowHeight"
    [rowSelection]="rowSelection"

    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</div>
