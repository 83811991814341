<div class="fl w100p form-inline">
  <div class="btn-group mr15">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.bikeStyle == ''}" (click)="search.bikeStyle='';getBikeProductList();">전체</button>
    <button *ngFor="let item of bikeStyleList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.bikeStyle == item.common_code}" (click)="search.bikeStyle = item.common_code;getBikeProductList();">{{item.common_name}}</button>
  </div>
</div>

<div class="cb"></div>

<hr class="db mt5 mb5" />

<div class="fl w65p form-inline">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small" (click)="searchInit()">
    <!-- <i class="material-icons-outlined">refresh</i> -->
    초기화
  </button>

  <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getBikeProductList()">
    <option value="10">10줄</option>
    <option value="25">25줄</option>
    <option value="500">50줄</option>
    <option value="100">100줄</option>
    <option value="200">200줄</option>
    <option value="500">500줄</option>
  </select>
  
  <!-- <select id="searchField" [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
    <option value=""></option>
  </select> -->

  <input type="text" id="searchText" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="50" class="form-control form-control-small ml5" placeholder="브랜드명, 모델명 검색" />

  <button type="button" title="검색" class="btn btn-default btn-small ml5" (click)="getBikeProductList()">
    <!-- <i class="material-icons-outlined">search</i> -->
    검색
  </button>

</div>

<div class="fr w35p right">
  <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="8b3edbef3ffb425ca6605c4dae178876" [btnGroup]="1"></btn-ag-grid-save>
  <button type="button" class="btn btn-default btn-small ml10" (click)="setExcelOrder()">엑셀 다운로드</button>
  <button type="button" class="btn btn-primary btn-small ml10" (click)="openBikecModelAdd(null)">모델등록</button>
</div>

<div class="cb"></div>

<div class="mt5" style="height:calc(100% - 150px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="modelList"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [pagination]="false"
    [stopEditingWhenGridLosesFocus]="true"

    (rowDoubleClicked)="onRowDoubleClicked($event)"
    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>

  <section class="pagination mt10">
    <ngb-pagination
      [(page)]="search.pageNo"
      [pageSize]="search.pageRow"
      [collectionSize]="search.totalCount"
      [maxSize]="20"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>
</div>
