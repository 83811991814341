
<form [formGroup]="form" (ngSubmit)="setPayHelperCalculateDate()">

  <div class="modal-header">
    <h4 class="modal-title fb mb0">정산예정일 저장</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
  
  <div class="modal-body">
  
    <table class="table table-bordered table-small mt50 mb50">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>
  
    <tbody>
    <tr>
      <th scope="row"><label for="calculate_date" class="control-label">정산예정일</label></th>
      <td><inputEx type="date" name="calculate_date" [formGroup]="form" [formErrors]="formErrors" placeholder="날짜를 선택하세요"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="sms_yn">SMS 발송</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value == '1'}" (click)="form.get('sms_yn').setValue('1')">YES</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value == '0'}"(click)="form.get('sms_yn').setValue('0')">NO</button>
      </div>
      </td>
    </tr>
    </tbody>
    </table>
  </div>
  
  <div class="modal-footer">
    <div class="fr w50p right">
      <button type="submit" class="btn btn-primary btn-small mr5">저장하기</button>
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
  </div>
  
  </form>