import { Component, OnInit } from '@angular/core';
import { ApprovalService } from '@app/service/approval.service';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ApprovalCommentComponent } from '@page/approval/approval-detail/approval-comment/approval-comment.component';
import { ToastrService } from 'ngx-toastr';
import { ApprovalNoteComponent } from '../approval-note/approval-note.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-approval-detail',
  templateUrl: './approval-detail.component.html',
  styleUrls: ['./approval-detail.component.scss']
})
export class ApprovalDetailComponent implements OnInit {

  public selectedItem: any = {};
  public item: any = {};
  public type: any = '';

  public member: any;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private approvalService: ApprovalService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getMemberApprovalInfo();

    if( this.type != '' ) {
      setTimeout(() => {
        this.print();
        this.activeModal.dismiss();
      }, 400)
    }
  }

  print() {
    let mode = 'portrait';
    const Maindiv = document.createElement('div');
    Maindiv.style.cssText = 'width: 100%; height: auto; position: absolute; z-index: 9999; top: 0; left: 0; background-color: #fff; padding: 10px;';

    const item = document.getElementById('print_area').cloneNode(true)
    Maindiv.appendChild(item)
    const app_root = document.querySelector<HTMLElement>('app-root') // as HTMLCollectionOf<HTMLElement>
    app_root.style.cssText = "display: none;"
    const modal_dialog = document.querySelector<HTMLElement>('.modal-dialog')
    modal_dialog.style.cssText = "display: none;"

    document.body.prepend(Maindiv);
    window.onafterprint = () => {
      document.body.removeChild(Maindiv)
      app_root.style.cssText = "display: initial;"
      modal_dialog.style.cssText = "display: flex;"
    }

    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';
    style.appendChild(document.createTextNode(`@page { size: ${mode}; }`));
    head.appendChild(style);
    window.print()
  }
  

  getMemberApprovalInfo() {
    this.approvalService.getMemberApprovalInfo().then(response => {
      if( response.ResultCode ) {
        this.member = response.data;
      }
    })
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 첨언보기
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  openComment() {
    const modalRef = this.modalService.open(ApprovalCommentComponent, options);
    modalRef.componentInstance.selectedItem = this.selectedItem;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  } 


  getBtnApproval(type) {

    let params = {
      seq: this.selectedItem.seq,
      type: type,
      step_seq: this.item.step_seq,
      add_note: this.item.add_note
    }

    let title = '';

    switch( type ) {
      case '0': title = '결재'; break;
      case '1': title = '협조'; break;
      case '2': title = '반려'; break;
      case '3': title = '전결'; break;
      case '4': title = '후결'; break;
      default: return false;
    }

    const modalRef = this.modalService.open(ApprovalNoteComponent, options);
    modalRef.componentInstance.subject = this.selectedItem.subject;
    modalRef.componentInstance.title = title;

    modalRef.result.then((result) => {
      if ( result ) {
        params.add_note = result.add_note;
        this.approvalService.approvalProcess(params).then(response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '결재처리');
            this.activeModal.close(true);
          } else {
            this.toastrService.error( response.ResultMessage, '결재처리');
          }
        });
      }
    }, (reason) => {
    });
    
  }
}