
      <form name="formCommonIns" id="formCommonIns" class="form-horizontal" role="form" data-toggle="validator" ng-submit="formSubmit();">
        <div class="modal-header">
          <h4 class="modal-title bold">공문서접수 {{title}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
        </div>

        <div class="modal-body">
          <table class="table table-bordered table-small">
          <colgroup>
            <col style="width:20%" />
            <col style="width:80%" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row"><label for="receiving_no">접수번호</label></th>
            <td>
              <inputEx type="text" name="receiving_no" [formGroup]="form" [formErrors]="formErrors" placeholder="자동으로 부여받습니다" readonly="readonly"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="receiving_date">접수일자</label></th>
            <td>
              <inputEx type="text" name="receiving_date" [formGroup]="form" [formErrors]="formErrors" size="15" readonly="readonly"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="outgoing_company">발신처</label></th>
            <td>
              <inputEx type="text" name="outgoing_company" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              <div *ngIf="form.controls.outgoing_company.errors && form.controls.outgoing_company.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="document_no">문서번호</label></th>
            <td>
              <inputEx type="text" name="document_no" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              <div *ngIf="form.controls.document_no.errors && form.controls.document_no.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="title">제목</label></th>
            <td>
              <inputEx type="text" name="title" [formGroup]="form" [formErrors]="formErrors"></inputEx>
              <div *ngIf="form.controls.title.errors && form.controls.title.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="mem_no">담당자</label></th>
            <td>
              <inputEx type="select" name="mem_no" [formGroup]="form" [formErrors]="formErrors" [data]="employeeList"></inputEx>
              <div *ngIf="form.controls.mem_no.errors && form.controls.mem_no.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="file">첨부파일</label></th>
            <td class="t1">
              <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="">파일목록</label></th>
            <td class="tl">
              <div class="mt5" style="font-size: 12px;">
                ※ 파일별 50MB 까지, 최대 3개까지 첨부 가능, 전체 첨부용량 50MB 제한
              </div>
              <div class="mt5" style="font-size: 12px;" *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
                <ul class="lstn mb3 pl10 f12">
                  <li class="pt3" *ngFor="let file of form.controls.upload.value; let i = index">
                    {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                    <button type="button" class="btn btn-default btn-grid2" (click)="setDeleteUploadFile(file, i)">
                      <i class="material-icons micon-close f12">&#xe5cd;</i>
                    </button>
                  </li>
                  <li class="pt3" *ngFor="let file of form.controls.files.value; let i = index">
                    {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                    <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                      <i class="material-icons micon-close f12">&#xe5cd;</i>
                    </button>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
          </table>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer clearfix">
          <button type="button" class="btn btn-default btn-small f15 fr" aria-label="Close" (click)="setModalDismiss()">취소</button>
          <button type="submit" class="btn btn-primary btn-small fr" *ngIf="form.controls.uid.value == ''" (click)="submit()">저장</button>
          <button type="submit" class="btn btn-primary btn-small fr" *ngIf="form.controls.uid.value != ''" (click)="editClick()">수정</button>  
        </div>
        <!-- Modal footer -->

      </form>

