<section class="apage-title">
  <div class="title-bar"></div>
  <h3>{{boardInfo.board_name}}</h3>
</section>

<section class="adata-list-area">
  <!--div class="asub-title">
    <h4>글보기</h4>
  </div-->

  <section class="data-list">

    <table class="list-table">
    <colgroup>
      <col style="width:10%" />
      <col style="width:70%" />
      <col style="width:20%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="col">제목</th>
      <td [innerHTML]="boardData.subject"></td>
      <td>
        {{boardData.writer_name}}
        <span class="f10">({{boardData.write_date}})</span>
      </td>
    </tr>
    <tr *ngIf="boardInfo.board_type == 'popup'">
      <th scope="col">팝업정보</th>
      <td colspan="2">
        <span class="mr30">{{boardData.layer_popup == '1' ? '레이어팝업' : '새창팝업'}}</span>
        <span class="mr30">팝업표시 : {{boardData.popup_yn == '1' ? '표시' : '표시안함'}}</span>
        <span class="mr30">표시위치(왼쪽 / 위) : {{boardData.screen_x}} / {{boardData.screen_y}}</span>
        <span class="mr30">크기(가로 / 세로) : {{boardData.popup_width}} / {{boardData.popup_height}}</span>
        <span class="mr30">게시기간 : {{boardData.notice_sdate}} ~ {{boardData.notice_edate}}</span>
        <span class="mr30">팝업링크 : {{boardData.popup_link}}</span>
      </td>
    </tr>
    <tr>
      <th>첨부파일</th>
      <td colspan="2">
        <div class="fl mr10" *ngFor="let item of boardData.upload">
          <span *ngIf="item.file_type == 'IMAGE' && boardInfo.upload_image_down == '1'">
            <i class="material-icons">attach_file</i>
            <a [href]="baseURL + '/api/file/file_download.php?seq=' + item.seq" target="_blank">{{item.origin}}</a>
          </span>
          <span *ngIf="item.file_type == 'FILE'">
            <i class="material-icons">attach_file</i>
            <a [href]="baseURL + '/api/file/file_download.php?seq=' + item.seq" target="_blank">{{item.origin}}</a>
          </span>
         </div>
      </td>
    </tr>
    <tr style="border-bottom:none;">
      <td colspan="3">
        <div class="board-contents" [innerHTML]="boardData.contents_nl2br | safe : 'html'"></div>
        <!-- <div *ngFor="let item of boardData.upload">
          <img [src]="item.url" [alt]="item.origin" style="max-width: 100%;" />
        </div> -->
      </td>
    </tr>
    <tr style="border-top:none;">
      <td colspan="3" style="border:none;"><span *ngIf="boardData.tag != null">#</span>{{boardData.tag}}</td>
    </tr>
    <ng-container *ngIf="boardInfo.board_id == 'notice'">
      <tr class="comment-tr">
        <td>댓글입력</td>
        <td>
          <inputEx type="textarea" name="contents" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <td>
          <button type="button" class="btn btn-default" (click)="setComment()">입력</button>
        </td>
      </tr>
      <tr *ngFor="let row of replyList;">
        <td>{{row.user_name}}<br>{{row.write_date}}</td>
        <td style="text-align: left;">{{row.contents}}</td>
        <td>
          <button type="button" class="btn btn-warning btn-small mr10" (click)="updateComment(row)">수정</button>
          <button type="button" class="btn btn-danger btn-small" (click)="deleteComment(row)">삭제</button>
        </td>
      </tr>
    </ng-container>
    </tbody>
    </table>

    <div class="mt10 mb10">
      <div class="fl">
        <a class="btn btn-default" (click)="list()">목록</a>
      </div>

      <div class="fr">
        <button *ngIf="authCheck()" type="button" class="btn btn-danger mr10" (click)="delete()">삭제</button>
        <button *ngIf="authCheck()" class="btn btn-default mr10" (click)="write()">수정</button>
        <button *ngIf="authCheck() && boardInfo.board_type=='popup'" class="btn btn-default mr10" (click)="popup_preview()">미리보기</button>
        <button *ngIf="authReplyCheck() && (boardInfo.board_id=='question' || boardInfo.board_id=='as')" class="btn btn-default" (click)="reply()">답변쓰기</button>
      </div>

      <div class="cb"></div>
    </div>
  </section>

</section>

<!-- 팝업 미리보기 //-->
<section *ngIf="is_popup_preview" class="popup-layer" [ngStyle]="{'width':boardData.popup_width+'px', 'height':(boardData.popup_height+60)+'px', 'left':boardData.screen_x+'px', 'top':boardData.screen_y+'px'}">
  <div class="popup-preview-contents" [innerHTML]="boardData.contents | safe : 'html'"></div>

  <!-- 오늘 더 이상 열지 않기 처리 //-->
  <div class="popup-button-area">
    <div class="fl">
      <label>
        <input type="checkbox" name="checkBtn" /> 오늘 더 이상 보지 않음
      </label>
    </div>
    <div class="fr">
      <button type="button" class="btn btn-default" (click)="is_popup_preview=false">창닫기</button>
    </div>
    <div class="cb"></div>
  </div>
</section>