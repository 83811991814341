import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'dateTimePicker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
  @Input() name: string;
  @Input() formGroup: any;
  @Input() formErrors: any;

  public datetime: any = null;
  public date: any = null;
  public time: any = null;

  public value = '';

  constructor() {
  }

  ngOnInit(): void {
    this.setDateFormat();

    this.formGroup.controls[this.name].valueChanges.subscribe((data: any) => {
      this.setDateFormat();
    });

    if( this.date !== null ) this.getDatetime();
  }

  setDateFormat() {
    const datetime: any = this.formGroup.controls[this.name].value;
    if( datetime === null ) return;

    this.date = {
      year: parseInt( moment(datetime).format('YYYY'), 10 ),
      month: parseInt( moment(datetime).format('MM'), 10 ),
      day: parseInt( moment(datetime).format('DD'), 10 )
    };

    this.time = {
      hour: parseInt( moment(datetime).format('HH'), 10 ),
      minute: parseInt( moment(datetime).format('mm'), 10 ),
      second: parseInt( moment(datetime).format('ss'), 10 )
    };
  }

  getDatetime() {
    const tmpDate = {
      year: this.date.year,
      month: this.date.month - 1,
      day: this.date.day
    };
    const datetime = Object.assign(tmpDate, this.time);
    this.value = moment(datetime).format('YYYY-MM-DD HH:mm:ss');

    const data: any = {};
    data[this.name] = this.value;
    this.formGroup.patchValue(data);
  }
}
