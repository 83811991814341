import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { MemberFindComponent } from '@components/member-find/member-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-refusal-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class MemberRefusalAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public seq: any;

  public refusalInfo: any = {};

  public form: FormGroup;
  public formErrors = {};

  public addOnMemberFindFunc: any;  // 회원검색

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
     설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      mem_no: ['', [] ],
      name: ['', [Validators.required] ],
      id: ['', [] ],
      email: ['', [Validators.maxLength(100)] ],
      hp: ['', [Validators.maxLength(50)] ],
      memo: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
     설  명 : 생성자
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    this.buildForm();

    this.addOnMemberFindFunc = this.memberFindAddOn.bind(this);
  }

  /*******************************************************************************
     설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      seq: this.seq
    });

    if( this.seq !== 0 ) {
      this.getMemberRefusalDetail();
    }
  }

  /*******************************************************************************
     설  명 : 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberRefusalDetail() {
    this.memberService.getMemberRefusalDetail( this.seq ).then( response => {
      if( response.ResultCode ) {
        this.refusalInfo = response.data;

        this.form.patchValue( this.refusalInfo );

      } else {
        this.refusalInfo = {};
      }
    });
  }

  /*******************************************************************************
     설  명 : 회원 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  memberFindAddOn() {
    const modalRef = this.modalService.open(MemberFindComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
        this.form.patchValue({
          mem_no: result.data.mem_no,
          id: result.data.id,
          name: result.data.name,
          hp: result.data.hp,
          email: result.data.email,
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
     설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberRefusalSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.memberService.setMemberRefusalSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, 'SMS/이메일 수신거부 저장');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, 'SMS/이메일 수신거부 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', 'SMS/이메일 수신거부 저장');
    }
  }

  /*******************************************************************************
     설  명 : 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberRefusalDelete() {
    if ( confirm('삭제하시겠습니까?') ) {
      this.memberService.setMemberRefusalDelete( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, 'SMS/이메일 수신거부 삭제');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, 'SMS/이메일 수신거부 삭제');
        }
      });
    }
  }

  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
 }

}
