  <div class="print">

    <div class="title">2021년 2월 당직 근무편성표</div>
    <table class="table table-bordered calendar">
    <caption class="sr-only">일정달력</caption>
  
    <colgroup>
      <col style="width:14%" />
      <col style="width:14%" />
      <col style="width:14%" />
      <col style="width:14%" />
      <col style="width:14%" />
      <col style="width:14%" />
      <col style="width:14%" />
    </colgroup>
  
    <thead>
    <tr>
      <th scope="col"><span class="cred">Sun</span></th>
      <th scope="col">Mon</th>
      <th scope="col">Tue</th>
      <th scope="col">Wed</th>
      <th scope="col">Thu</th>
      <th scope="col">Fri</th>
      <th scope="col"><span class="cblue">Sat</span></th>
    </tr>
    </thead>
  
    <tbody>
     <tr *ngFor="let item of range(6);let i1 = index">
      <td *ngFor="let subitem of range(7);let i2 = index;">
        <div class="day" [ngClass]="{sunday:i2==0, saturday:i2==6, prevMonth:i1==0}">
          <span>{{getCurrDay(i1, i2)}}</span>
          <p class="f12">홍길동</p>
          <p class="f12">박길동</p>  
        </div>
      </td>
    </tr>
    </tbody>
    </table>

  </div>