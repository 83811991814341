/*******************************************************************************
  설  명 : 정산/회계관리 - 오픈마켓 정산검증
  작성일 : 2021.08.03
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateStruct, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OpenmarketService } from '@app/service/openmarket.service';

import * as moment from 'moment';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { OrderOpenmarketUploadComponent } from '@page/order/order-openmarket/order-openmarket-upload/order-openmarket-upload.component';
import { OrderOpenmarketDeliveryUploadComponent } from '@page/order/order-openmarket/order-openmarket-delivery-upload/order-openmarket-delivery-upload.component';
import { OrderOpenmarketFindComponent } from '@page/order/order-openmarket/order-openmarket-find/order-openmarket-find.component';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { StockOrderComponent } from '@app/page/stock/stock-order/stock-order.component';
import { AccountService } from '@app/service/account.service';

import * as XLSX from 'xlsx'

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-account-openmarket',
  templateUrl: './account-openmarket.component.html',
  styleUrls: ['./account-openmarket.component.scss']
})
export class AccountOpenmarketComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public typeList: any = ['지마켓', '옥션', '네이버페이', '네이버스토어팜', '11번가', '쿠팡', '카카오톡스토어'];
  
  public openmarketList: any = [];

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    standbyCount: 0,
  }

  public keyField: any = [
    // 지마켓
    [
      {key: 'a3', title: '구매자명'},
      {key: 'a5', title: '상품명'},
      {key: 'p_serial', title: '판매처 상품번호'},
      {key: 'order_serial', title: '판매처 주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 옥션
    [
      {key: 'a3', title: '구매자명'},
      {key: 'a5', title: '상품명'},
      {key: 'p_serial', title: '판매처 상품번호'},
      {key: 'order_serial', title: '판매처 주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 네이버
    [
      {key: 'e', title: '구매자명'},
      {key: 'p', title: '상품명'},
      {key: 'd', title: '주문번호'},
      {key: 'c', title: '상품주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 스토어팜
    [
      {key: 'i', title: '구매자명'},
      {key: 'q', title: '상품명'},
      {key: 'b', title: '주문번호'},
      {key: 'a', title: '상품주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 11번가
    [
      {key: 'm', title: '수취인'},
      {key: 'g', title: '상품명'},
      {key: 'am', title: '상품번호'},
      {key: 'c', title: '주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 쿠팡
    [
      {key: 'aa', title: '수취인이름'},
      {key: 'm', title: '노출상품명(옵션명)'},
      {key: 'n', title: '노출상품ID'},
      {key: 'o', title: '옵션ID'},
      {key: 'c', title: '주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ],
    // 톡스토어
    [
      {key: 'k', title: '수령인명'},
      {key: 'e', title: '상품명'},
      {key: 'd', title: '채널상품번호'},
      {key: 'c', title: '주문번호'},
      {key: 'shipping_num', title: '송장번호'}
    ]
  ]

  public search_protoType: any = {
    pageNo: 1,
    pageRow: 100,
    type: '0',
    searchField: this.keyField[0][0].key,
    searchText: '',
    date : this.stockOrderComponent.getToday(),
    deliveryYN: ''
  }
  search = this.search_protoType

  excel_openmarketType = 0

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  isRowSelectable: any;
  noRowsTemplate: string;
  rowClassRules: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
    agGridImageComponent: AgGridImageComponent
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private utilService: UtilService,
    private openmarketService: OpenmarketService,
    private agGridExComponent: AgGridExComponent,
    private toastrService: ToastrService,
    private stockOrderComponent: StockOrderComponent,
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '이미지', field: 'thumbnail_url', width: '100', cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      { headerName: '모델명/색상&사이즈', field: '', width: '350', cellClass: 'cp ag-cell80h-br cell-wrap-text', autoHeight: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.model + '<br />' + params.data.size_color;
        }
      },
      { headerName: '판매단가/할인', field: '', width: '120', cellClass: 'cp right ag-cell80h-br', cellRenderer: 'agGridHtmlComponent', 
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          return getComma(params.data.sale_price) + '<br />' + getComma(params.data.discount_price);
        }
      },
      { headerName: '수량', field: 'num', width: '70', cellClass: 'cp right ag-cell80h', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '판매금액', field: 'total_amt', width: '100', cellClass: 'cp right ag-cell80h',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '수령인', field: 'r_name', width: '90', cellClass: 'cp ag-cell80h' },
      { headerName: '택배사/발송일', field: '', width: '125', cellClass: 'cp left ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const consignCo = ( params.data.consign_co ) ? params.data.consign_co : '';
          const consignDate = ( params.data.consign_date ) ? params.data.consign_date : '';
          
          return consignCo + '<br />' + consignDate;
        }
      },
      { headerName: '송장번호', field: 'consign_num', width: '150',  cellClass: 'cp left ag-cell80h' },
      { headerName: '마켓주문번호/마켓상품번호', field: '', width: '200', cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.order_serial + '<br />' + params.data.p_serial;
        }
      },
      { headerName: '주문번호', field: 'order_seq', width: '100', cellClass: 'cp ag-cell80h' },
      { headerName: '주문일', field: 'order_date', width: '150', cellClass: 'cp center ag-cell80h' },
      // { headerName: '처리', field: '', width: 140, cellClass: 'cp center ag-cell80h',
      //   cellRendererFramework: AgGridButtonComponent,
      //   cellRendererParams: {
      //     action: 'openmarket',
      //     setOpenOrderProductFind: this.setOpenOrderProductFind.bind(this),
      //     setOpenOrderDelete: this.setOpenmarketOrderRowDelete.bind(this),
      //     setOpenOrderSave: this.setOpenmarketOrderRowSave.bind(this),
      //   }
      // },
      { headerName: '정산결과', field: 'order_status_name', width: '100', cellClass: 'cp ag-cell80h' },
      { headerName: '정산금액', field: 'account_price', width: '100', cellClass: 'cp right ag-cell80h', 
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: (params) => String(params.data.account_price || 0).replace(',','') },
    ];

    this.defaultColDef = {...this.stockOrderComponent.defaultColDef}
    this.rowSelection = this.stockOrderComponent.rowSelection
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate

    this.rowClassRules = {
      // 'status1':
      // 'status2':
      // 'status3':
      // 'status4':
      'status8': params => params.data.order_status_name == '정산완료'
    }

    this.isRowSelectable = rowNode => true

    // 콤마 넣기
    function getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }  
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.searchInit();
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = () => 80

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page
    this.getOpenmarketList()
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) this.getOpenmarketList()
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
  *******************************************************************************/
  searchInit() {
    this.search = {...this.search_protoType}
    this.softReset()
  }
  softReset() {
    this.getOpenmarketList()
  }
  
  /*******************************************************************************
    설  명 : 정산엑셀 업로드
  *******************************************************************************/
  fileChanged($event) {
    if(!$event.target?.files?.[0]) return
    else if(!confirm(`${this.typeList[this.excel_openmarketType]} 업로드를 진행할까요?`)) return
    
    const target: DataTransfer = <DataTransfer>($event.target)
    const reader: FileReader = new FileReader()
    reader.onload = (e: any) => {
      const bstr: string = e.target.result
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'})
      const wsname: string = wb.SheetNames[0]
      const ws: XLSX.WorkSheet = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws, {header: 1})
      
      const dataParsing = JSON.parse( JSON.stringify(data) )
      const header = dataParsing.shift()

      let index_A: any, index_B: any, optionData: any
      switch(this.typeList[this.excel_openmarketType]) {
        default:
        case '지마켓':
        case '옥션':
        case '11번가':
          index_A = header.findIndex((e: any) => e == '주문번호')
          index_B = header.findIndex((e: any) => e == '상품번호')
          break

        case '쿠팡':
          index_A = header.findIndex((e: any) => e == '주문번호')
          index_B = header.findIndex((e: any) => e == 'Option ID')
          optionData = header.findIndex((e: any) => e == '정산금액')
          break

        case '네이버페이':
        case '네이버스토어팜':
          index_A = header.findIndex((e: any) => e == '주문번호')
          index_B = header.findIndex((e: any) => e == '상품주문번호')
          break

        case '카카오톡스토어':
          index_A = header.findIndex((e: any) => e == '주문번호')
          index_B = header.findIndex((e: any) => e == '채널상품번호')
          optionData = header.findIndex((e: any) => e == '판매정산금액')
          break
      }

      if(index_A < 0 && index_B < 0) {
        this.toastrService.error('엑셀 필드명이 잘못되었습니다. 타이틀, 안내문구를 제거하거나 파일이 올바른지 확인하십시오.')
        return
      }

      const dataList = dataParsing.map((e: any) => ({
        index_A : e[index_A],
        index_B : e[index_B],
        optionData: e[optionData]
      })).filter((item: any) => item.index_A && item.index_B)

      const max = 300
      Promise.all(
        [...Array( Math.ceil(dataList.length / max) ).keys()]
        .map((item: any) => this.accountService.uploadAccountOpenmarketList({
        type : this.excel_openmarketType,
        dataList : dataList.slice(item * max, item * max + max)
      }))).then(res => {
        const result = res.filter((item: any) => !item.ResultCode).length ? false : true
        const message = `${this.typeList[this.excel_openmarketType]} 업로드`
        if(result) {
          this.toastrService.success(`${message} 성공`)
          this.softReset()
        } else this.toastrService.error(`${message} 실패`)
      })
    }

    reader.readAsBinaryString(target.files[0])
  }
  
  /*******************************************************************************
    설  명 : 검색 타입 종류 선택 시
  *******************************************************************************/
  setSearchType( value: any ) {
    this.search.type = value
    this.excel_openmarketType = value
    this.resetSearch()
    this.getOpenmarketList()
  }

  resetSearch() {
    this.search.searchText = ''
    this.search.searchField = this.keyField[this.search.type][0].key
  }
  
  /*******************************************************************************
    설  명 : 오픈마켓 리스트 가져오기
  *******************************************************************************/
  getOpenmarketList() {
    this.accountService.getAccountOpenmarketList({
      ...this.search, 
      pageNo : this.search.pageNo - 1
    }).then(response => {
      this.openmarketList = []
      if( response.ResultCode ) {
        this.openmarketList = response.data
        this.statistic = response.statistic
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 날짜 선택
  *******************************************************************************/
  dateSelect($event) {
    this.search.date = `${$event.year}-${this.stockOrderComponent._2D($event.month)}-${this.stockOrderComponent._2D($event.day)}`
    this.getOpenmarketList()
  }

  /*******************************************************************************
    설  명 : 셀클릭 이벤트
  *******************************************************************************/
  onCellClicked($event) {
    if($event.colDef.field == 'order_seq') {
      // this.router.navigate(['/order/detail'], {relativeTo: this.activatedRoute, queryParams: {seq: $event.data.order_seq}})
      let orderSeq = $event.data.order_seq;
      const url = '/order/detail?seq=' + orderSeq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 전체엑셀다운
  *******************************************************************************/
  setExcelOrderAll() {
    this.accountService.getAccountOpenmarketList({
      ...this.search, 
      pageNo : 0,
      pageRow: 999999999
    }).then(response => {
      this.setExcelOrder(response.data)
    })
  }

  /*******************************************************************************
    설  명 : 엑셀다운
  *******************************************************************************/
  setExcelOrder(list = null) {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>오픈마켓정산검증</strong></caption>
        <colgroup>
          <col style="width:100px;" />
          <col style="width:300px;" />
          <col style="width:120px;" />
          <col style="width:70px;" />
          <col style="width:150px;" />
          <col style="width:200px;" />
          <col style="width:150px;" />
          <col style="width:200px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>이미지</td>
          <td>모델명/색상&사이즈</td>
          <td>판매단가/할인</td>
          <td>수량</td>
          <td>수령인</td>
          <td>택배사/발송일</td>
          <td>송장번호</td>
          <td>마켓주문번호/마켓상품번호</td>
          <td>주문번호</td>
          <td>정산결과</td>
          <td>정산금액</td>
        </tr>
    `];

    (list || this.openmarketList).forEach((element: any) => {
      const color = element.order_status_name == '정산완료' ? '#f0f0ff' : ''
      contents.push(`
        <tr bgColor="${color}">
          <td style="text-align:center;"><img src='${element.thumbnail_url}' width=50 height=50></td>
          <td style="text-align:center;">${element.model + '<br />' + element.size_color}</td>
          <td style="text-align:center; mso-number-format:'\#\,\#\#0';">${element.sale_price + '<br />' + element.discount_price}</td>
          <td style="text-align:center; mso-number-format:'\#\,\#\#0';">${element.num}</td>
          <td style="text-align:center;">${element.r_name}</td>
          <td style="text-align:center;">${element.consign_co + '<br />' + element.consign_date}</td>
          <td style="text-align:center;">${element.consign_num}</td>
          <td style="text-align:center;">${element.order_serial + '<br />' + element.p_serial}</td>
          <td style="text-align:center;">${element.order_seq}</td>
          <td style="text-align:center;">${element.order_status_name}</td>
          <td style="text-align:center; mso-number-format:'\#\,\#\#0';">${element.account_price}</td>
        </tr>
        </tbody>
      `)
    })

    contents.push(`</table>`)
    const today = new Date();
    this.printExcel(`bikemart_accountOpenmarketList_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`, contents.join(''))
  }

  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
}
