/*******************************************************************************
  설  명 : 거래처관리 - 메모
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-customer-tab-memo',
  templateUrl: './customer-tab-memo.component.html',
  styleUrls: ['./customer-tab-memo.component.scss']
})
export class CustomerTabMemoComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************1************************************/
  public search: any = {
    customerSeq: '',
    searchText: ''
  };

  public form: FormGroup;
  public formErrors = {};

  @Input() set customerSeq(value: number) {
    this.search.customerSeq = value;

    this.form.patchValue({
      customerSeq: this.search.customerSeq
    });

    if( this.search.customerSeq != '' ) {
      this.getCustomerMemoList();
    }
  }

  // 그리드 관련 선언
  customerMemoList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public setCustomerMemoSaveFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      customerSeq: ['', [] ],
      memo: ['', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private basicService: BasicService,
  ) {

    this.setCustomerMemoSaveFunc = this.setCustomerMemoSave.bind(this);

    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '번호', field: 'seq', width: 90, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '내용', field: 'memo', width: 380, cellClass: 'cp' },
      { headerName: '등록자', field: 'writer_name', width: 80, cellClass: 'cp center' },
      { headerName: '등록일자', field: 'write_date', width: 140, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 메모 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerMemoList() {
    this.basicService.getCustomerMemoList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.customerMemoList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 거래처 메모 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCustomerMemoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setCustomerMemoSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '메모등록');

          this.form.reset();
          this.form.patchValue({ customerSeq: this.search.customerSeq });

          this.getCustomerMemoList();
        } else {
          this.toastrService.error( response.ResultMessage, '메모등록');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 메모 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCustomerMemoDelete() {
    const nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 메모를 선택하세요', '' );
      return false;
    }

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data );
    }

    if ( confirm('삭제하시겠습니까?') ) {
      this.basicService.setCustomerMemoDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getCustomerMemoList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search.searchText = '';

    this.getCustomerMemoList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCustomerMemoList();
    }
  }

}
