/*******************************************************************************
  설  명 : 상품별 매출현황
  작성일 : 2021-08-13
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-sales-status',
  templateUrl: './product-sales-status.component.html',
  styleUrls: ['./product-sales-status.component.scss']
})
export class ProductSalesStatusComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
}
