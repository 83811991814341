<div class="fl w70p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getRecommendKeywordList()">
      <i class="material-icons-outlined">search</i>
    </button>

    <input type="text" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="키워드" />

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn==''}" (click)="searchUseYn('')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='1'}" (click)="searchUseYn('1')">사용</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='0'}" (click)="searchUseYn('0')">사용안함</button>
    </div>

  </div>

  <div class="fr w30p right">
    <button type="button" class="btn btn-primary btn-small ml5" (click)="setRecommendKeywordSortOrderSave()">순서저장</button>
    <button type="button" class="btn btn-danger btn-small ml5" (click)="setRecommendKeywordDelete()">키워드삭제</button>
    <button type="button" class="btn btn-primary btn-small ml5" (click)="addRecommendKeyword(0)">키워드추가</button>
  </div>

  <div class="cb"></div>

  <div class="mt5" style="height:calc(100% - 35px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="colorList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowDragManaged]="true"
      [enableMultiRowDragging]="true"
      [animateRows]="true"        


      (cellDoubleClicked)="onCellDoubleClicked($event)"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
      >
    </ag-grid-angular>
  </div>
