<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체<span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">검색<span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <!-- <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">미발송<span class="cnt">{{statistic?.standbyCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div> -->

  <div class="cb"></div>
</section>

<section style="overflow: hidden;">
<div class="fl form-inline">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon mr5" (click)="searchInit()">
    <i class="material-icons-outlined">refresh</i>
  </button>
  <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small mr5" (change)="getOpenmarketList()">
    <option value="10">10줄</option>
    <option value="20">20줄</option>
    <option value="50">50줄</option>
    <option value="100">100줄</option>
    <option value="500">500줄</option>
  </select>
  <select name="keyfield" size="1" class="form-control form-control-small" [(ngModel)]="search.searchField">
    <option *ngFor="let item of keyField[search.type]" [value]="item.key">{{item.title}}</option>
  </select>
  <input type="text" size="15" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="검색어" />
  <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getOpenmarketList()">
    <i class="material-icons-outlined">search</i>
  </button>

  <div class="btn-group ml5">
    <button *ngFor="let item of typeList; index as i" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.type==i}" (click)="setSearchType(i)">{{item}}</button>
  </div>

</div>

<div class="fr form-inline">
  <input type="text" size="15" readonly="readonly" [value]="search.date" (dateSelect)="dateSelect($event)" class="form-control form-control-small calendar-input ml5" placeholder="정산일자" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" />
  <div class="btn-group ml5">
    <!-- <button type="button" class="btn btn-primary btn-small" (click) = "getOpenmarketList(true);">미정산 보기</button> -->
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.deliveryYN==''}" (click)="search.deliveryYN='';getOpenmarketList();">전체</button>
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.deliveryYN=='Y'}" (click)="search.deliveryYN='Y';getOpenmarketList();">미정산</button>
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.deliveryYN=='N'}" (click)="search.deliveryYN='N';getOpenmarketList();">정산완료</button>
  </div>
</div>

</section>

<hr class="db mt5 mb5" />

<div class="fl form-inline">
  <select size="1" class="form-control form-control-small mr5" [(ngModel)]="excel_openmarketType">
    <option *ngFor="let item of typeList; index as idx" [value]="idx">{{item}}</option>
  </select>
  <button type="button" class="btn btn-default btn-small mr5">정산엑셀 업로드 (.xlsx 형식)　<input type='file' (change)="fileChanged($event)" accept=".xlsx"></button>
</div>
<div class="fr right">
  <button type="button" class="btn btn-default btn-small mr5" (click)="setExcelOrderAll()">전체엑셀다운</button>
  <button type="button" class="btn btn-default btn-small mr5" (click)="setExcelOrder()">엑셀다운</button>
  <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="20eb07c2b47a47f88e7e963c7ebebc80" [btnGroup]="1"></btn-ag-grid-save>
  <!-- <app-print-excel [dataList]="printDataList" [filename]="fileName" title="배송목록 출력"></app-print-excel>
  <button type="button" class="btn btn-primary btn-small ml5" (click)="openOpenmarketDeliveryUpload()">{{typeList[search.type]}} 배송목록 업로드</button>
  <button type="button" class="btn btn-success btn-small ml5" (click)="setOrderAdd()">바마주문 생성</button>
  <button type="button" class="btn btn-primary btn-small ml5" (click)="openOpenmarketUpload()">{{typeList[search.type]}} 주문목록 업로드</button> -->
</div>


<!-- <hr class="db mt5 mb5" />

<div class="fl w50p form-inline">

  <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
  <ng-template #footerTemplate1>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
  </ng-template>
  <span class="ml5 mr5">~</span>
  <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
  <ng-template #footerTemplate2>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
  </ng-template>

  <div class="btn-group ml5">
    <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value)">{{item.title}}</button>
  </div>
</div> -->


<div class="cb"></div>

<div class="mt5" style="height:calc(100% - 181px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="openmarketList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [isRowSelectable]="isRowSelectable"
    [getRowHeight]="getRowHeight"
    [stopEditingWhenGridLosesFocus]="true"
    [rowClassRules]="rowClassRules"

    (gridReady)="onGridReady($event)"
    (cellClicked)="onCellClicked($event)"
    >
  </ag-grid-angular>
</div>

<section class="pagination mt10" *ngIf="statistic.searchCount">
  <ngb-pagination
    [(page)]="search.pageNo"
    [pageSize]="search.pageRow"
    [collectionSize]="statistic.searchCount"
    [maxSize]="20"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="loadPage($event)"
    >
    <ng-template ngbPaginationFirst>처음</ng-template>
    <ng-template ngbPaginationLast>마지막</ng-template>
    <ng-template ngbPaginationPrevious>이전</ng-template>
    <ng-template ngbPaginationNext>다음</ng-template>
  </ngb-pagination>
</section>
