<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체 <span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">검색 <span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">가맹문의 접수 <span class="cnt">{{statistic?.associateCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">입점문의 접수 <span class="cnt">{{statistic?.enteredCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<section id="member-company">

  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getContact()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="이름, 지역, 연락처" />

      <div class="btn-group ml10">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.type==''}" (click)="setSearchValue('type','')">전체</button>
        <button type="button" *ngFor="let item of typeList" class="btn btn-default btn-small" [ngClass]="{active: search.type==item.common_code}" (click)="setSearchValue('type',item.common_code)">{{item.common_name}}</button>
      </div>

      <div class="btn-group ml10">
        <!-- <button type="button" class="btn btn-primary btn-small" (click) = "getOpenmarketList(true);">미정산 보기</button> -->
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.associate_type==''}" (click)="setSearchValue('associate_type','')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.associate_type=='1001'}" (click)="setSearchValue('associate_type','1001')">협력점</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.associate_type=='2001'}" (click)="setSearchValue('associate_type','2001')">프랜차이즈</button>
      </div>

      <div class="btn-group ml10">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status==''}" (click)="setSearchValue('status','')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='0'}" (click)="setSearchValue('status','0')">신청</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='1'}" (click)="setSearchValue('status','1')">협의중</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='2'}" (click)="setSearchValue('status','2')">선정</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='3'}" (click)="setSearchValue('status','3')">보류</button>
      </div>

      <input type="text" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" size="12" class="form-control form-control-small calendar-input ml10" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" readonly="readonly" id="edate" [(ngModel)]="search.edate" size="12" class="form-control form-control-small calendar-input m15" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>
    </div>
    <div class="fr w30p right">
      <button *ngIf="authService.currAuth['auth_delete']=='1'" type="button" class="btn btn-danger btn-small mr10" (click)="delContact()">선택 삭제</button>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="가맹/입점문의현황" title="가맹/입점문의현황"></ag-grid-excel>
    </div>
    <div class="cb"></div>
  </div>

  <as-split unit="percent" restrictMove="true" gutterSize="10" style="height: calc(100vh - 180px);background-color:#fff;">
    <as-split-area size="63" minSize="43" maxSize="83">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="contactList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (gridReady)="onGridReady($event)"
        (rowClicked)="onRowClicked($event)"
        >
      </ag-grid-angular>
    </as-split-area>
    <as-split-area size="37" minSize="17" maxSize="57">
      <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 180px);background-color:#fff;">
        <as-split-area size="5" minSize="5" maxSize="5">

          <table class="table table-bordered table-small1 mb0">
            <caption class="none">상태정보</caption>
            <colgroup>
              <col style="width:20%;" />
              <col style="width:80%;" />
            </colgroup>
  
            <tbody>
            <tr>
              <th scope="row"><label for="">문의 상태</label></th>
              <td>
                <div class="btn-group ml10">
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{'status-active': contactStatus=='0'}" (click)="setStatusChange('0')">신청</button>
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{'status-active': contactStatus=='1'}" (click)="setStatusChange('1')">협의중</button>
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{'status-active': contactStatus=='2'}" (click)="setStatusChange('2')">선정</button>
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{'status-active': contactStatus=='3'}" (click)="setStatusChange('3')">보류</button>
                </div>
                <span class="ml10 cred">※ 클릭 시 상태가 자동으로 변경됩니다.</span>
              </td>
            </tr>
            </tbody>
          </table>
 
        </as-split-area>
        <as-split-area size="45" minSize="45" maxSize="60">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDetailDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="contentList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"

            (gridReady)="onGridDetailReady($event)"
            >
          </ag-grid-angular>
        </as-split-area>
        <as-split-area size="50" minSize="40" maxSize="60">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnMemoDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="memoList"
            [pagination]="false"
            [domLayout]="domLayout"
            [getRowHeight]="getRowHeight"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [stopEditingWhenGridLosesFocus]="true"

            (gridReady)="onGridMemoReady($event)"
            (cellEditingStopped)="onCellEditingStopped($event)"
            >
          </ag-grid-angular>
        </as-split-area>
      </as-split>
    </as-split-area>
  </as-split>
</section>
