/*******************************************************************************
  설  명 : 조직관리
  작성일 : 2019-09-21
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { MenuService } from '@app/service/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public groupList = [];

  public groupForm: FormGroup;
  public formErrors = {};

  /* 노드관련 변수 */
  public nodeData: any = [];
  public selectedNode = {
    seq: '',
    page_cd: '',
    page_menu_name: '',
    memo: '',
    tag: '',
    children: []
  };

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.groupForm = this.formBuilder.group({
      seq:["", [] ],
      page_cd:["", [Validators.required, Validators.maxLength(9)] ],
      page_menu_name:["", [Validators.required, Validators.maxLength(45)] ],
      tag:["", [Validators.required, Validators.maxLength(45)] ],
    });

    this.groupForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.groupForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private menuService: MenuService,
    public authService: AuthService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 메뉴 리스트 가져오기
    this.getMenuListN();
  }

  /*******************************************************************************
    설  명 : 메뉴 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.groupForm, this.formErrors);

    if(this.groupForm.valid) {
      this.menuService.setMenu(this.groupForm).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '메뉴저장');

          this.getMenuListN();
        } else {
          this.toastrService.error( response.ResultMessage, '메뉴저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '메뉴저장');
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기 (계층형)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getMenuListN() {
    this.menuService.getMenuListN().then( response => {
      if( response.ResultCode ) {
        this.nodeData = response.data;
      }
    });
  }



  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode($event) {
    this.selectedNode.seq = $event.seq;
    this.selectedNode.page_cd = $event.key;
    this.selectedNode.page_menu_name = $event.name;
    this.selectedNode.tag = $event.tag;
    this.selectedNode.children = $event.children;

    this.groupForm.patchValue( this.selectedNode );
  }
}