import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { CommonService } from '@app/service/common.service';
import { MemberService } from '@app/service/member.service';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { MemberCompanyApplicationProcess1Component } from '@page/member/member-company-application/member-company-application-process1/member-company-application-process1.component';
import { MemberCompanyApplicationProcess2Component } from '@page/member/member-company-application/member-company-application-process2/member-company-application-process2.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-company-application',
  templateUrl: './member-company-application.component.html',
  styleUrls: ['./member-company-application.component.scss']
})
export class MemberCompanyApplicationComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
    status: ''
  };

  public statusList: any = [];
  public applicationList: any = [];

  public baseURL = config.baseUrl;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private memberService: MemberService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', width: 80, cellClass: 'cp center' },
      { headerName: '신청일자', field: 'application_date', width: 120, cellClass: 'cp center' },
      { headerName: '신청구분', field: 'application_gbn', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.application_gbn == '0007' ) {
            return '<span class="badge badge-primary f12 w80px">프렌차이즈</span>';
          } else {
            return '<span class="badge badge-success f12 w80px">상사</span>';
          }
        }
      },
      { headerName: '신청자ID', field: 'id', width: 100, cellClass: 'cp' },
      { headerName: '신청자명', field: 'name', width: 100, cellClass: 'cp center' },
      { headerName: '상호', field: 'company_name', width: 300, cellClass: 'cp' },
      { headerName: '사업자번호', field: 'biz_no', width: 200, cellClass: 'cp center' },
      { headerName: '처리상태', field: 'status_name', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.proc_status == '1000' ) {
            return `<span class="badge badge-success f12">${params.data.status_name}</span>`;
          } else if( params.data.proc_status == '2000' ) {
            return `<span class="badge badge-primary f12">${params.data.status_name}</span>`;
          } else {
            return `<span class="badge badge-danger f12">${params.data.status_name}</span>`;
          }
        }
      },
      { headerName: '승인일자', field: 'application_date', width: 120, cellClass: 'cp center' },
      // { headerName: '첨부파일', field: 'filepath', width: 300, cellClass: 'cp center',
      //   cellRenderer: function(params) {
      //     let tmp: any = [];
      //     if( params.data.images ) {
      //       params.data.images.map( rows => {
      //         tmp.push(`<a href="http://shop.o2oz.net/api/file/commonfile_download.php?seq=${rows.seq}" target="_blank"><div class="file fl">''</div></a>`);
      //       });
      //       return tmp.join('');
      //     } else {
      //       return '';
      //     }
      //   }
      // }
      { headerName: '첨부파일', field: 'filepath', width: 300, cellClass: 'cp center',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'download'
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    this.getMemberTransitionApplicationList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 처리상태
    this.commonService.getCommonListCode('TAS').then( response => {
      if ( response.ResultCode ) {
        this.statusList = response.data;
      } else {
        this.statusList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberTransitionApplicationList() {
    this.memberService.getMemberTransitionApplicationList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.applicationList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 첫 로딩 시 컬럼사이즈 자동 조절
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    // if( $event.data.proc_status === '1000' ) {
      this.modalApplicationProcess($event.data)
    // } else {
    //   this.toastrService.error( '승인 또는 거절 상태의 내역은 신청처리가 불가합니다.', '' );
    // }
  }

  /*******************************************************************************
    설  명 : 신청처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  modalApplicationProcess( data: any ) {
    let modalRef: any;

    if( data.application_gbn == '0007' ) {
      modalRef = this.modalService.open(MemberCompanyApplicationProcess1Component, options);  // 프렌차이즈
    } else {
      modalRef = this.modalService.open(MemberCompanyApplicationProcess2Component, options);  // 상사
    }

    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if( result ) {
        this.getMemberTransitionApplicationList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      status: '',
    };

    this.getMemberTransitionApplicationList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberTransitionApplicationList();
    }
  }


}
