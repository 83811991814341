import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';


import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { RestfulService } from '@app/service/restful.service';
import { BrandService } from '@app/service/brand.service';

const URL = config.apiImageUploadUrl;

@Component({
  selector: 'app-product-brand-management-add',
  templateUrl: './product-brand-management-add.component.html',
  styleUrls: ['./product-brand-management-add.component.scss']
})
export class ProductBrandManagementAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: any;
  public customer_brand_seq: any;
  public groupUpdate: any = '0';

  public title: string = '추가';

  public baseURL = config.baseUrl;

  public summernoteConfig: any = {...config.summernoteConfig, ...{disableDragAndDrop: true}};

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public formAdd: FormGroup;
  public formErrors = {};

  public imageList: any = [];


  /*******************************************************************************
     설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq: ['', [] ],
      customer_name: ['', [] ],
      brand_name: ['', [Validators.required, Validators.maxLength(100)] ],
      brand_ename: ['', [Validators.maxLength(100)] ],
      contents: ['', [] ],
      use_yn: [true, [Validators.required] ],
      purchasing_agent_yn: [false, [Validators.required] ],
      rma_yn: [false, [Validators.required] ],
      subsidiary_yn: [false, [Validators.required] ],
      atock_mng_yn: [false, [Validators.required] ],
      caution_position: ['', []],
      caution: ['', []],
      customer_brand_seq: ['', []],
      update_all: [false, []],
      file: ['', []],
      upload: [[], []],
      isRecommend: ['0', []],
      isPremium: ['0', []],
      files: [[], []]
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
     설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private restful: RestfulService,
    private brandService: BrandService,
    public authService: AuthService,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
     설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 데이터가 넘어온 경우 데이터 설정
    if ( this.seq !== 0 ) {
      this.title = '수정';
      
      this.getBrandInfo();
    } else if ( this.seq !== 0 ) {
      this.title = '수정';
    }

    this.formAdd.patchValue({
      update_all: this.groupUpdate
    });

    this.summernoteConfig.height = 150;

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const files = this.formAdd.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: this.baseURL + response.thumbnail,
          thumbnail_org: response.thumbnail_org,
          is_default: '0'
        });

        this.formAdd.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;
   

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.formAdd.controls.files.value;

      tmp.splice( index, 1 );

      this.formAdd.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.brandService.setDeleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.formAdd.controls.upload.value;
          uploadData.splice( index, 1 );
          this.formAdd.patchValue(uploadData);

          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }
  
  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertUploadImage( file ) {
    if( this.checkImage(this.getExt(file.origin_filename)) ) {
      let addHtml = this.formAdd.controls.caution.value + '<br /><img src="' + file.url + '" />';

      this.formAdd.patchValue( {caution: addHtml} );
    }
  }

  /*******************************************************************************
    설  명 : 이미지 서머노트에 삽입
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  insertFilesImage( file: any ) {
    if( this.checkImage(this.getExt(file.origin)) ) {
      let addHtml = this.formAdd.controls.caution.value + '<br /><img src="' + file.url + '" />';

      this.formAdd.patchValue( {caution: addHtml} );
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    let title = ( this.seq != 0 ) ? '수정' : '추가';

    if(this.formAdd.valid) {
      this.brandService.setBrandSave(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '브랜드 ' + title);

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '브랜드 ' + title);
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '브랜드 ' + title);
    }
  }

  /*******************************************************************************
     설  명 : 브랜드 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandInfo() {
    this.brandService.getBrandInfo( this.seq, this.customer_brand_seq ).then( response => {
      if( response.ResultCode ) {
        this.formAdd.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

}
