import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { ApprovalLineAddComponent } from '@page/approval/approval-line/approval-line-add/approval-line-add.component';
import { ApprovalService } from '@app/service/approval.service';
import { ToastrService } from 'ngx-toastr';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-830'
};

@Component({
  selector: 'app-approval-line',
  templateUrl: './approval-line.component.html',
  styleUrls: ['./approval-line.component.scss']
})
export class ApprovalLineComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public rowData: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private approvalService: ApprovalService,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순서', field: 'seq', width: 100, cellClass: 'cp', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
      { headerName: '제목', field: 'subject', width: 250, cellClass: 'cp cell-wrap-text center' },
      { headerName: '결재', field: 'approval', width: 400, cellClass: 'cp cell-wrap-text' },
      { headerName: '협조', field: 'agreement', width: 400, cellClass: 'cp cell-wrap-text' },
      { headerName: '참조', field: 'reference', width: 400, cellClass: 'cp cell-wrap-text' }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  ngOnInit(): void {
    this.getApprovalStep();
  }


  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.getApprovalStep();
  }

  /*******************************************************************************
    설  명 : 결제단계 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getApprovalStep() {
    this.approvalService.getApprovalStep().then( response => {
      if( response.ResultCode ) {
          this.rowData = response.data;
      } else {
        this.rowData = [];
        // this.toastrService.error( response.ResultMessage, 'getCommonListCode');
      }

    });
  }

  /*******************************************************************************
    설  명 : 결재단계추가
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  addLine( num: number ) {
    const modalRef = this.modalService.open(ApprovalLineAddComponent, options);

    modalRef.componentInstance.num = num;

    modalRef.result.then((result) => {
      if( result ) {
        this.getApprovalStep();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : row 한줄 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteRow() {
    const nodes: any = this.gridApi.getSelectedRows();
    const seq = nodes.map( row => row.seq );

    if ( nodes.length < 1 ) {
      this.toastrService.error('삭제할 결재단계를 선택하세요.');

      return false;
    }

    if ( confirm('선택하신 결재단계를 삭제하시겠습니까?') ) {
      this.approvalService.delApprovalStep( seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '삭제하였습니다.');
          this.getApprovalStep();
        } else {
          this.toastrService.error( response.ResultMessage, '삭제 오류');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : row 한줄 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  modifyRow() {
    const nodes: any = this.gridApi.getSelectedRows();

    const modalRef = this.modalService.open(ApprovalLineAddComponent, options);

    modalRef.componentInstance.seq = nodes[0].seq;
    modalRef.componentInstance.subject = nodes[0].subject;
    modalRef.componentInstance.document_type = nodes[0].document_type;

    modalRef.result.then((result) => {
      if( result ) {
        this.getApprovalStep();
      }
    }, (reason) => {
    });

  }

  /*******************************************************************************
    설  명 : ag-grid api
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
