<section id="member-company">

  <div class="mb5">
    <div class="fl form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="orderMileageList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" id="searchText" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="40" class="form-control form-control-small ml5" placeholder="주문번호, 주문자명, 아이디, 사유 검색" />

      <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>

      <div class="btn-group ml5">
        <button *ngFor="let item of termList" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.term==item.value}" (click)="setSearchDate(item.value, 'days')">{{item.title}}</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.type==''}" (click)="setSearch('type', '')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.type=='1000'}" (click)="setSearch('type', '1000')">주문</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.type=='2000'}" (click)="setSearch('type', '2000')">관리자</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.gbn==''}" (click)="setSearch('gbn', '')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.gbn=='1000'}" (click)="setSearch('gbn', '1000')">적립</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.gbn=='2000'}" (click)="setSearch('gbn', '2000')">사용</button>
      </div>
      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.memberGbn==''}" (click)="setSearch('memberGbn', '')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.memberGbn=='0007'}" (click)="setSearch('memberGbn', '0007')">프렌차이즈</button>
      </div>

    </div>
    <div class="fr right">
    </div>    
    <div class="cb"></div>
  </div>

  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 220px);background-color:#fff;">
    <as-split-area size="60" minSize="40" maxSize="80">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="orderMileageList"
        [singleClickEdit]="true"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [stopEditingWhenGridLosesFocus]="true"

        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked($event)"
        >
      </ag-grid-angular>
    </as-split-area>
  </as-split>
</section>
<section class="pagination mt10">
  <ngb-pagination
    [(page)]="search.pageNo"
    [pageSize]="search.pageRow"
    [collectionSize]="totalCount"
    [maxSize]="20"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="loadPage($event)"
    >
    <ng-template ngbPaginationFirst>처음</ng-template>
    <ng-template ngbPaginationLast>마지막</ng-template>
    <ng-template ngbPaginationPrevious>이전</ng-template>
    <ng-template ngbPaginationNext>다음</ng-template>
  </ngb-pagination>
</section>


