import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApprovalDocumentService } from '@app/service/approval-documents.service';
import { EmployeeService } from '@app/service/employee.service';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';

@Component({
  selector: 'app-document-execute-add',
  templateUrl: './document-execute-add.component.html',
  styleUrls: ['./document-execute-add.component.scss']
})
export class DocumentExecuteAddComponent implements OnInit {

  public form: FormGroup;
  public formErrors = {};

  public selectedItem: any = {
    uid: "",
  };

  public execution_date: any;

  public title: string = '추가';

  public employeeList: any = [];


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
    buildForm(): void {
      this.form = this.formBuilder.group({
        uid: ['', [] ],
        authorization_date: ['', [] ],
        title: ['', [Validators.required] ],
        execution_date: ['', [] ],
        receiving_company: ['', [Validators.required] ],
        mem_no: ['', [Validators.required] ],
        department: ['', [] ],
        execute_number: ['', [] ],
      });

      this.form.valueChanges.subscribe(data => {
        this.utilService.updateFormErrors( this.form, this.formErrors );
      });
    }

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private approvalDocService: ApprovalDocumentService,
    private employeeService: EmployeeService,
    private utilService: UtilService,
    private toastrService: ToastrService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.getEmployee();
  }


  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.selectedItem == 0 ) {
      this.title = '추가';
      this.form.controls.execution_date.setValue(moment().format('YYYY-MM-DD'));
    } else {
      this.title = '수정';
      this.form.controls.execution_date.setValue(moment().format('YYYY-MM-DD'));
      // 데이터 로딩 처리
      this.form.patchValue( this.selectedItem );
    }

  }



  /*******************************************************************************
    설  명 : 공문서시행 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  editClick() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.approvalDocService.setEditDocumentExcute(this.form).then( response => {

        if( response.ResultCode ) {
          this.toastrService.success( '공문서접수대장을 성공적으로 저장하였습니다.', '');
          this.activeModal.close(true);

        } else {
          this.toastrService.error( '공문서접수대장을 저장하는데 실패하였습니다.', '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 담당자 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployee() {
    this.employeeService.getGroupEmployeeListForSelect().then( response => {
      if( response.ResultCode ) {

        this.employeeList.push({
          title: '확인자',
          value: ''
        });

        response.data.forEach( row => {
          this.employeeList.push({
            title: row.name,
            value: row.mem_no,
          });
        });


      } else {
        this.employeeList = [];
      }
    });
  }

  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }
}
