import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { OpenmarketService } from '@app/service/openmarket.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import * as $ from 'jquery';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-order-sabangnet-upload',
  templateUrl: './order-sabangnet-upload.component.html',
  styleUrls: ['./order-sabangnet-upload.component.scss']
})
export class OrderSabangnetUploadComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public type: any;

  public marketList: any = ['지마켓/옥션', '네이버', '스토어팜', '11번가', '쿠팡', '톡스토어'];
  public typeList: any = [];

  public baseURL = config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public form: FormGroup;
  public formErrors = {};

  public sabangnetList = [];

  arrayBuffer: any;
  filelist: any[];

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      type: ['0', [] ],
      file: ['', []],
      files: [[], []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private utilService: UtilService,
    private restful: RestfulService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private openmarketService: OpenmarketService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      type: this.type
    });
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    const files: any = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(files);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary", cellDates: true, dateNF: 'YYYY-MM-DD'});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.filelist = XLSX.utils.sheet_to_json(worksheet,{raw:true});

      this.filelist.map((obj, i) => {
        if( obj['쇼핑몰CODE'] !== undefined ) {
          this.sabangnetList.push({
            A: obj['쇼핑몰CODE'] ? obj['쇼핑몰CODE'] : '',
            B: obj['쇼핑몰명(1)'] ? obj['쇼핑몰명(1)'] : '',
            C: obj['주문일시(YYYY-MM-DD HH:MM)'] ? moment(obj['주문일시(YYYY-MM-DD HH:MM)']).format('YYYY-MM-DD HH:MM') : '',
            D: obj['수집일시(YYYY-MM-DD HH:MM)'] ? moment(obj['수집일시(YYYY-MM-DD HH:MM)']).format('YYYY-MM-DD HH:MM') : '',
            E: obj['주문번호(쇼핑몰)'] ? obj['주문번호(쇼핑몰)'] : '',
            F: obj['주문번호(사방넷)'] ? obj['주문번호(사방넷)'] : '',
            G: obj['상품코드(사방넷)'] ? obj['상품코드(사방넷)'] : '',
            H: obj['상품명(수집)'] ? obj['상품명(수집)'] : '',
            I: obj['옵션(수집)'] ? obj['옵션(수집)'] : '',
            J: obj['상품명(확정)'] ? obj['상품명(확정)'] : '',
            K: obj['옵션(확정)'] ? obj['옵션(확정)'] : '',
            L: obj['상품약어'] ? obj['상품약어'] : '',
            M: obj['자사몰 자식번호'] ? obj['자사몰 자식번호'] : '',
            N: obj['수량'] ? obj['수량'] : '',
            O: obj['EA(상품)'] ? obj['EA(상품)'] : '',
            P: obj['주문자명'] ? obj['주문자명'] : '',
            Q: obj['주문자전화번호1'] ? obj['주문자전화번호1'] : '',
            R: obj['주문자전화번호2'] ? obj['주문자전화번호2'] : '',
            S: obj['수취인명'] ? obj['수취인명'] : '',
            T: obj['수취인전화번호1'] ? obj['수취인전화번호1'] : '',
            U: obj['수취인전화번호2'] ? obj['수취인전화번호2'] : '',
            V: obj['수취인우편번호(1)'] ? obj['수취인우편번호(1)'] : '',
            W: obj['수취인주소(4)'] ? obj['수취인주소(4)'] : '',
            X: obj['배송메세지'] ? obj['배송메세지'] : '',
            Y: obj['배송구분(수집)'] ? obj['배송구분(수집)'] : '',
            Z: obj['배송비(수집)'] ? obj['배송비(수집)'] : '0',
            AA: obj['판매가(수집)'] ? obj['판매가(수집)'] : '0',
            BB: obj['공급단가'] ? obj['공급단가'] : '0',
            CC: obj['결제금액'] ? obj['결제금액'] : '0',
            DD: obj['택배사'] ? obj['택배사'] : '',
            EE: obj['송장번호'] ? obj['송장번호'] : ''
          });
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenmarketOrderSave() {
    console.log(this.form.value)
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.openmarketService.setSabangnetOrderSave({params: this.sabangnetList}).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '주문목록 저장');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '주문목록 저장');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
