import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { BrandService } from '@app/service/brand.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ProductBrandManagementAddComponent } from '@page/product/product-brand-management/product-brand-management-add/product-brand-management-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-product-brand-management',
  templateUrl: './product-brand-management.component.html',
  styleUrls: ['./product-brand-management.component.scss']
})
export class ProductBrandManagementComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public brandList: any = [];

  public totalCount: number = 0;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;
  selectedRows: number = 0;

  noRowsTemplate: string;

  public search: any = {
    seq: '',
    searchText: '',
    use_yn: '1',
    purchasing_agent_yn: '',
    rma_yn: '',
    subsidiary_yn: '',
    isRecommend: '',
    isPremium: '',
    atock_mng_yn: ''
  };

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private brandService: BrandService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, checkboxSelection: true },
      { headerName: '순번', field: 'seq', width: 80, cellClass: 'cp center' },
      { headerName: '사용여부', field: '', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1') return '<span class="badge badge-success f12 fn">사용</span>';
          else return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      },
      { headerName: '자사/타사 여부', field: '', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.subsidiary_yn == '1') return '<span class="badge badge-danger f12 fn">자사</span>';
          else return '<span class="badge badge-primary f12 fn">타사</span>';
        }
      },
      { headerName: '구매대행 브랜드 여부', field: '', width: 180, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.purchasing_agent_yn == '1') return '<span class="badge badge-primary f12 fn">구매대행</span>';
          else return '<span class="badge badge-secondary f12 fn">대행아님</span>';
        }
      },
      { headerName: 'RMA관리 여부', field: '', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.rma_yn == '1') return '<span class="badge badge-primary f12 fn">관리함</span>';
          else return '<span class="badge badge-secondary f12 fn">관리안함</span>';
        }
      },
      { headerName: '재고자동반영 여부', field: '', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.atock_mng_yn == '1') return '<span class="badge badge-primary f12 fn">반영함</span>';
          else return '<span class="badge badge-secondary f12 fn">반영안함</span>';
        }
      },
      { headerName: '추천브랜드', field: 'isRecommend', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( params.data.isRecommend === '1' ) {
            return '<span class="badge badge-success f12">추천</span>';
          } else {
            return '<span class="badge badge-secondary f12">비추천</span>';
          }
        }
      },
      { headerName: '프리미엄브랜드', field: 'isPremium', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( params.data.isPremium === '1' ) {
            return '<span class="badge badge-success f12">프리미엄</span>';
          } else {
            return '<span class="badge badge-secondary f12">일반</span>';
          }
        }
      },
      { headerName: '브랜드명', field: 'brand_name', width: 150, cellClass: 'cp' },
      { headerName: '브랜드명(영문)', field: 'brand_ename', width: 150, cellClass: 'cp' },
      { headerName: '거래처명', field: 'customer_name', width: 200, cellClass: 'cp' },
      { headerName: '설명', field: 'contents', width: 200, cellClass: 'cp' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
   }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getBrandList();
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.brandList = response.data;
        this.totalCount = response.total;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 추가
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  brandAdd( seq: number, customer_brand_seq: number ) {
    const modalRef = this.modalService.open(ProductBrandManagementAddComponent, options);

    modalRef.componentInstance.seq = seq;
    modalRef.componentInstance.customer_brand_seq = customer_brand_seq;

    modalRef.result.then((result) => {
      this.getBrandList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 첫 로딩 시 컬럼사이즈 자동 조절
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  /*******************************************************************************
    설  명 : ag grid 행 더블 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.brandAdd( $event.data.seq, $event.data.customer_brand_seq );
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      seq: '',
      searchText: '',
      use_yn: '1',
      purchasing_agent_yn: '',
      rma_yn: '',
      subsidiary_yn: ''
    }

    this.getBrandList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getBrandList();
    }
  }

  /*******************************************************************************
    설  명 : 거래처별 일괄수정
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setCustomerBrandAll() {
    const rows = this.gridApi.getSelectedRows();
    if( rows.length < 1 ) {
      this.toastrService.error('일괄수정할 브랜드를 선택하시기 바랍니다.');
    } else if( !rows[0].customer_brand_seq ) {
      this.toastrService.error('등록된 거래처가 없는 브랜드입니다.');
    } else {
      const modalRef = this.modalService.open(ProductBrandManagementAddComponent, options);

      modalRef.componentInstance.seq = rows[0].seq;
      modalRef.componentInstance.customer_brand_seq = rows[0].customer_brand_seq;
      modalRef.componentInstance.groupUpdate = '1';

      modalRef.result.then((result) => {
        this.getBrandList();
      }, (reason) => {
      });
    }
  }

}
