/*******************************************************************************
  설  명 : 창고이동
  작성일 : 2020-08-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-stock-warehouse-move',
  templateUrl: './stock-warehouse-move.component.html',
  styleUrls: ['./stock-warehouse-move.component.scss']
})
export class StockWarehouseMoveComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public warehouseList: any = [];
  public stockList: any = [];

  selectedRows: number = 0;

  gridApi: any;
  gridColumnApi: any;

  gridApiWarehouse: any;
  gridColumnApiWarehouse: any;

  columnDefs: any;
  columnDefsWarehouse: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public calendar: NgbCalendar,
  ) {

    this.columnDefsWarehouse = [
      {headerName: '선택', field: 'seq', width: 50, hide: true },
      {headerName: '창고', field: 'name', width: 140, cellClass: 'cp' },
      {headerName: '타입', field: 'type_name', width: 65, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return ( params.value == '임시창고' ) ? '<span class="badge badge-success">임시창고</span>' : '<span class="badge badge-primary">상시창고</span>';
        }
      },
      {headerName: '주소', field: 'address', width: 200, cellClass: 'cp' },
      {headerName: '개요', field: 'outline', width: 150, cellClass: 'cp' },
    ];

    this.columnDefs = [
      {headerName: '선택', field: 'seq', cellClass: 'cp center', width:80, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '카테고리', field: 'parent_category_name', width: 200, cellClass: 'cp left' },
      {headerName: '상품명', field: 'name', width: 200, cellClass: 'cp left'},
      {headerName: '재고수량', field: 'total_stock', width: 100, cellClass: 'cp right' },
      {headerName: '이동수량', field: 'out_qty', width: 100, cellClass: 'cp right ag-cell-edit', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          var str = String(params.data.total_stock);
          return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
        },
      },
      {headerName: '이동일자', field: 'in_date', width: 120, cellClass: 'cp center ag-cell-edit' },
      {headerName: '이동창고', field: 'warehouse_in_seq', width: 120, cellClass: 'cp center ag-cell-edit',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let selected: any = null;
          for( let item of params.column.colDef.cellEditorParams.values ) {
            if( item.seq == params.data.warehouse_in_seq ) selected = item;
          }

          return (selected == null ) ? '' : selected.name;
        },
      },
      {headerName: '보관위치', field: 'warehouse_in_seq', width: 120, cellClass: 'cp center' }
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyWarehouse(params) {
    this.gridApiWarehouse = params.api;
    this.gridColumnApiWarehouse = params.columnApi;
  }
}
