/*******************************************************************************
  설  명 : 주문관리 - 환불관리 수정
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RefundService } from '@app/service/refund.service';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { AuthService } from '@app/service/auth.service';

@Component({
  selector: 'app-order-refund-edit',
  templateUrl: './order-refund-edit.component.html',
  styleUrls: ['./order-refund-edit.component.scss']
})
export class OrderRefundEditComponent implements OnInit {
  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public selectedItem: any = {}

  private date: NgbDateStruct = this.utilService.getDate('');

  public employeeList: any = [];
  public returnList: any = [];
  public payMethodList: any = [];

  public memberInfo: any = {};

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      order_seq: ['', [] ],
      id:['', [] ],
      reg_gbn: ['', [] ],
      product_name: ['', [] ],
      pay_method: ['', [] ],
      manager_id: ['', [] ],
      ask_mem_no: ['', [Validators.required] ],
      first_mem_no: ['', [Validators.required] ],
      return_mem_no: ['', [Validators.required] ],
      ask_id: ['', [] ],
      first_id: ['', [] ],
      return_id: ['', [] ],
      mem_name: ['', [Validators.required] ],
      name: ['', [] ],
      org_return_amt: ['', [] ],
      return_amt: ['', [Validators.required] ],
      origin_amt: ['', [] ],
      write_date: ['', [] ],
      return_date: [null, [] ],
      common_name: ['', [] ],
      return_status: ['', [] ],
      return_invoice: ['', [] ],
      consign_pay: ['', [] ],
      get_consign_pay: ['', [] ],
      return_qty: ['', [] ],
      memo: ['', [] ],
      approve: ['0', [] ],
      diary: ['1', [] ],
      pay_result: ['0', [] ],
      process_status: ['', [] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private authService: AuthService,
    private refundService: RefundService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {

    this.form.patchValue(this.selectedItem);

    if( this.form.value.return_date != '' ) {
      this.form.patchValue({
        return_date: this.date
      });
    }

    if( this.form.value.process_status == '99' ) {
      this.form.patchValue({
        manager_id: this.form.value.first_id + ' ' + this.form.value.return_id
      });
    }

    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    const consignPay = this.form.value.consign_pay ? this.form.value.consign_pay : 0;
    const getConsignPay = this.form.value.get_consign_pay ? this.form.value.get_consign_pay : 0;

    this.form.patchValue({
      ask_id: this.memberInfo.id,
      first_id: this.memberInfo.id,
      return_id: this.memberInfo.id,
      org_return_amt: parseInt(this.form.value.return_amt) - parseInt(consignPay) + parseInt(getConsignPay)
    });

    this.getCommonList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 주문상태
    this.commonService.getCommonListCode('CRK').then( response => {
      if ( response.ResultCode ) {

        this.returnList.push({value: '', title: '환불사유를 선택하세요'});

        response.data.forEach( row => {
          this.returnList.push({
            value: row.common_code,
            title: row.common_name
          });
        });

      } else {
        this.returnList = [];
      }
    });

    // 결제수단
    this.commonService.getCommonListCode('PMD').then( response => {
      if ( response.ResultCode ) {

        this.payMethodList.push({value: '', title: '결제수단 선택'});

        response.data.forEach( row => {
          this.payMethodList.push({
            value: row.common_code,
            title: row.common_name,
            etitle: row.common_ename
          });
        });

      } else {
        this.payMethodList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 환불금액 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRefundChanged() {
    let total = 0;
    const consignPay = this.form.value.consign_pay ? this.form.value.consign_pay : 0;
    const getConsignPay = this.form.value.get_consign_pay ? this.form.value.get_consign_pay : 0;

    total = parseInt(this.form.value.org_return_amt) + parseInt(consignPay) - parseInt(getConsignPay);

    this.form.patchValue({
      return_amt: total
    });
  }

  /*******************************************************************************
    설  명 : 환불관리 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRefundSave() {
    this.refundService.setRefundSave( this.form ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.activeModal.close(true);
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

}
