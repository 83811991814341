/*******************************************************************************
  설  명 : 매출수동계산
  작성일 : 2021-11-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { StatisticService } from '@app/service/statistic.service';
import { UtilService } from '@app/service/util.service';
import { ApiResponse } from '@app/service/api-response';

import * as moment from 'moment';

import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-system-statistic',
  templateUrl: './system-statistic.component.html',
  styleUrls: ['./system-statistic.component.scss']
})
export class SystemStatisticComponent implements OnInit {
  
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  formErrors = {};
  errorResponse: ApiResponse;

  public calcData: any = [];

  // 그리드 관련 선언
  selectedRows: number = 0;
  customerList: any = [];
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate = "검색된 데이터가 없습니다.";

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
  };
  
  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      sdate: ['', [Validators.required]],
      edate: ['', [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private statisticService: StatisticService
  ) { 
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '번호', field: 'index', width: 100, cellClass: 'cp center' },
      {headerName: '날짜', field: 'date', width: 150, cellClass: 'cp center' },
      {headerName: '매출처리', field: 'result', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent', 
        valueGetter: function(params) {
          if( params.data.result == '진행중') 
            return '<span class="badge badge-secondary">진행중</span>';
          else if( params.data.result == '성공') 
            return '<span class="badge badge-success">완료</span>';
          else
            return '<span class="badge badge-danger">실패</span>';
        }
      },
      {headerName: '브랜드처리', field: 'result1', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent', 
        valueGetter: function(params) {
          if( params.data.result == '진행중') 
            return '<span class="badge badge-secondary">진행중</span>';
          else if( params.data.result == '성공') 
            return '<span class="badge badge-success">완료</span>';
          else
            return '<span class="badge badge-danger">실패</span>';
        }
      },
      {headerName: '카테고리처리', field: 'result2', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent', 
        valueGetter: function(params) {
          if( params.data.result == '진행중') 
            return '<span class="badge badge-secondary">진행중</span>';
          else if( params.data.result == '성공') 
            return '<span class="badge badge-success">완료</span>';
          else
            return '<span class="badge badge-danger">실패</span>';
        }
      },
    ];
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 매출 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      if( ! confirm("오랜 시간이 걸릴 수 있습니다. 계산이 완료될 때까지 브라우저를 종료하지 마세요.") ) return false;

      let sdate = this.utilService.getDateStr(this.form.controls.sdate.value);
      let edate = this.utilService.getDateStr(this.form.controls.edate.value);
      edate = moment(edate).add(1, 'days').format('YYYY-MM-DD');

      this.calcData = [];
      let index = 0;
      let data = [];

      for (var m = moment(sdate); m.isBefore(edate); m.add(1, 'days')) {
        data.push({
          index: index++,
          date: m.format('YYYY-MM-DD'),
          result: '진행중'
        });
      }

      data.push({
        index: index++,
        date: m.format('YYYY') + '년도 계산',
        result: '진행중'
      });

      this.calcData = data;
      index = 0;

      for (var m = moment(sdate); m.isBefore(edate); m.add(1, 'days')) {
        await this.statisticService.calcStatistic(m.format('YYYY-MM-DD'), index).then( async response => {
          var rowNode = this.gridApi.getRowNode(parseInt(response.index));

          if( response.ResultCode ) {
            rowNode.setDataValue('result', '성공');
          } else {
            rowNode.setDataValue('result', '실패');
          }
        });

        index++;
      }

      // 년도 계산
      await this.statisticService.calcStatisticYear(m.format('YYYY'), index).then( async response => {
        var rowNode = this.gridApi.getRowNode(parseInt(response.index));

        if( response.ResultCode ) {
          rowNode.setDataValue('result', '성공');
        } else {
          rowNode.setDataValue('result', '실패');
        }
      });

    } else {
      this.toastrService.error("시작일과 종료일을 선택하시기 바랍니다.");
    }
        
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }
}
