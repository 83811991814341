import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { CommonService } from '@app/service/common.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-order-pay',
  templateUrl: './order-pay.component.html',
  styleUrls: ['./order-pay.component.scss']
})
export class OrderPayComponent implements OnInit {

 /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  orderPayList: any = [];
  payMethodList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  totalCount: any = 0;

  search: any = {
    pageNo: 1,
    pageRow: 25,
    sdate: '',
    edate: '',
    searchText: '',
    app_gbn: '',
    pay_gbn: 'PG',
    pay_method: '',
    dateType: 'days',
    term: '30'
  };

  public termList: any = [
    {title: '전체', value: ''},
    {title: '오늘', value: '0'},
    {title: '어제', value: '1'},
    {title: '2일', value: '2'},
    {title: '3일', value: '3'},
    {title: '7일', value: '7'},
    {title: '15일', value: '15'},
    {title: '30일', value: '30'},
    {title: '60일', value: '60'},
    {title: '90일', value: '90'},
    {title: '120일', value: '120'},
    {title: '150일', value: '150'},
    {title: '180일', value: '180'},
  ];

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  private dateModel: NgbDateStruct;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private commonService: CommonService,
    private calendar: NgbCalendar,
    private agGridExComponent: AgGridExComponent,
    public authService: AuthService,
  ) {
    this.columnDefs = [
      { headerName: '순번', field: 'seq', cellClass: 'cp center', width: 100 },
      { headerName: '주문번호', field: 'order_seq', cellClass: 'cp center', width: 100 },
      { headerName: '주문자명', field: 'name', cellClass: 'cp center', width: 120 },
      { headerName: '상태', field: 'app_gbn', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.app_gbn == '1' )
            return '<span class="badge badge-primary f12 fn">정상</span>';
          else
            return '<span class="badge badge-danger f12 fn">취소</span>';
        }
      },      
      { headerName: '결제구분', field: 'pay_gbn', cellClass: 'cp center', width: 80 },
      { headerName: 'PG사', field: 'pg_group_name', cellClass: 'cp center', width: 80 },
      { headerName: '결제수단', field: 'pay_method_name', cellClass: 'cp center', width: 100, 
        // cellRenderer: 'agGridHtmlComponent',
        // valueGetter: function(params) {
        // }
      },
      { headerName: '승인번호', field: 'app_no', cellClass: 'cp center', width: 100, editable: true, singleClickEdit: true },
      // { headerName: '승인일시', field: 'app_datetime', cellClass: 'cp center', width: 150 },
      { headerName: '결제일시', field: 'pay_date', cellClass: 'cp center', width: 150 },
      { headerName: '결제금액', field: 'pay_amt', cellClass: 'cp right',  width: 100, valueFormatter: this.agGridExComponent.currencyFormatter },      
      // { headerName: '취소일자', field: 'cnl_date', cellClass: 'cp center', width: 100 },
      { headerName: '상점아이디', field: 'mid', cellClass: 'cp center', width: 100 },
      { headerName: 'OID', field: 'oid', cellClass: 'cp center', width: 190, editable: true, singleClickEdit: true },
      { headerName: 'TID', field: 'tid', cellClass: 'cp center', width: 310, editable: true, singleClickEdit: true },
      { headerName: '카드(계좌)번호', field: 'card_no', cellClass: 'cp center', width: 150 },
      { headerName: '카드(계좌)명', field: 'card_name', cellClass: 'cp center', width: 120 },
      { headerName: '할부기간', field: 'installment_period', cellClass: 'cp', width: 80 },
      { headerName: '등록자', field: 'writer_name', cellClass: 'cp', width: 80 },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 검색날짜 초기값 설정 후 데이터 로딩
    this.setSearchDate( this.search.term, this.search.dateType );    
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 결제수단
    this.commonService.getCommonListCode('PMD').then( response => {
      if ( response.ResultCode ) {
        this.payMethodList = response.data;
      } else {
        this.payMethodList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : cell 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked( event: any ) {
    if( event.colDef.field === 'order_seq' ) {
      const url = '/order/detail?seq=' + event.data.order_seq;
      window.open("about:blank").location.href = url;
    }
  }
  
  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 25,
      sdate: '',
      edate: '',
      searchText: '',
      app_gbn: '',
      pay_gbn: 'PG',
      pay_method: '',
      dateType: 'days',
      term: '30'
    };

    this.setSearchDate( this.search.term, this.search.dateType );

    this.getOrderPayList();
  }

  /*******************************************************************************
    설  명 : 결제내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderPayList() {
    this.orderService.getOrderPayList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.orderPayList = response.data;
        this.totalCount = response.total;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getOrderPayList();
    }
  }

  /*******************************************************************************
    설  명 : 검색날짜 지정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( term: any, dateType: any ) {
    if( term != '' ) {
      this.search.term = term;
      this.search.dateType = dateType;

      this.dateModel = {
        year: parseInt( moment().subtract(term, dateType).format('YYYY') ),
        month: parseInt( moment().subtract(term, dateType).format('MM') ),
        day: parseInt( moment().subtract(term, dateType).format('DD') )
      };

      this.search.sdate = this.dateModel;
      this.search.edate = this.calendar.getToday();
    }

    this.getOrderPayList();
  }

  /*******************************************************************************
    설  명 : 결제취소하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderCancel() {
    const rowSelection = this.gridApi.getSelectedRows()[0];

    if(  typeof rowSelection === 'undefined' ) {
      this.toastrService.error( '취소할 내역을 선택하시기 바랍니다.', '');
      return false;
    } else if( rowSelection.status != '1000' ) {
      this.toastrService.error( '결제상태가 정상 승인이 아닙니다.', '');
      return false;
    } else {

      if( confirm('주문 및 결제를 취소 하시겠습니까?') ) {
        const params: any = {
          seq: rowSelection.om_seq,
          status_code: '3000'
        };

        // this.aApplicationService.setOrderCancel( params ).then( response => {
        //   if( response.ResultCode ) {
        //     this.toastrService.success( response.ResultMessage, '' );
        //     this.getOrderPayList();
        //   } else {
        //     this.toastrService.error( response.ResultMessage, '' );
        //   }
        // }, error => {
        //   this.toastrService.error( error, '' );
        // });
      }

    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getOrderPayList();
  }

  /*******************************************************************************
    설  명 : 검색조건 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearch( target: any, value: any ) {
    this.search[ target ] = value;

    this.getOrderPayList();
  }
}
  