/*******************************************************************************
  설  명 : 거래처관리 - 최근입고내역
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';

import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { StockIn } from '@app/service/stockIn.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StockOrderComponent } from '@app/page/stock/stock-order/stock-order.component';

@Component({
  selector: 'app-customer-tab-ingoods',
  templateUrl: './customer-tab-ingoods.component.html',
  styleUrls: ['./customer-tab-ingoods.component.scss']
})
export class CustomerTabIngoodsComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() set customerSeq(value: number) {
    this.customer_seq = value
    this.getPurchaseStockHistory()
  }

  customer_seq

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  selectedRows: number = 0;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public form: FormGroup
  public formErrors = {}

  search = {
    product_name : ''
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private stockIn: StockIn,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private stockOrderComponent: StockOrderComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '발주일시', field: 'purchase_date', width: 130, cellClass: 'cp center' },
      {headerName: '브랜드', field: 'brand_name', width: 150, cellClass: 'cp' },
      {headerName: '상품명', field: 'product_name', width: 200, cellClass: 'cp' },
      {headerName: '사이즈', field: 'size_name', width: 100, cellClass: 'cp' },
      {headerName: '컬러', field: 'color_name', width: 100, cellClass: 'cp' },
      {headerName: '발주수량', field: 'purchase_cnt', width: 100, cellClass: 'cp right',
      valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '발주단가', field: 'unit_amt', width: 100, cellClass: 'cp right',
      valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '발주금액', field: 'purchase_amt', width: 100, cellClass: 'cp right',
      valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '입고수량', field: 'inout_qty', width: 100, cellClass: 'cp right',
      valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '입고금액', field: 'inout_amt', width: 100, cellClass: 'cp right',
      valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '입고일', field: 'inout_date', width: 100, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm()
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      rowData : [[]]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors )
    });
  }
  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
  }

  reset() {
    this.search.product_name = ''
    this.getPurchaseStockHistory()
  }

  /*******************************************************************************
    설  명 : 기초관리 - 정산거래처관리 - 최근발주입고내역
  *******************************************************************************/
  getPurchaseStockHistory() {
    if(!this.customer_seq) return

    this.stockIn.getPurchaseStockHistory({
      product_name : this.search.product_name,
      customer_seq : this.customer_seq
    }).then(response => {
      if(response.ResultCode) this.form.patchValue({rowData : response.data})
      else this.toastrService.error(response.ResultMessage)
    })
  }
}
