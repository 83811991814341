import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-member-address-add',
  templateUrl: './member-address-add.component.html',
  styleUrls: ['./member-address-add.component.scss']
})
export class MemberAddressAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() seq: any;

  public addressInfo: any = {};

  public form: FormGroup;
  public formErrors = {};

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      mem_no: ['', [Validators.required] ],
      isDefault: [false, [] ],
      zipcode: ['', [Validators.required] ],
      address: ['', [Validators.required] ],
      address_detail: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private activeModal: NgbActiveModal,
    private memberService: MemberService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      mem_no: this.memberNo,
      seq: this.seq
    });

    if( this.seq !== 0 ) {
      this.getMemberAddressDetail();
    }
  }

  /*******************************************************************************
    설  명 : 회원 주소 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
   getMemberAddressDetail() {
    this.memberService.getMemberAddressDetail( this.seq ).then( response => {
      if( response.ResultCode ) {
        this.addressInfo = response.data;

        if( this.addressInfo.isDefault !== null ) {
          if( this.addressInfo.isDefault == '1' ) {
            this.addressInfo.isDefault = true;
          } else {
            this.addressInfo.isDefault = false;
          }
        }

        this.form.patchValue( this.addressInfo );

      } else {
        this.addressInfo = {};
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원 주소정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberAddressSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.memberService.setMemberAddressSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 회원 주소정보 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberAddressDelete() {
    if ( confirm('삭제하시겠습니까?') ) {
      this.memberService.setMemberAddressDelete( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

}
