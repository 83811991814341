/*******************************************************************************
  설  명 : 회원정보 - 메모내역
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-memo',
  templateUrl: './member-memo.component.html',
  styleUrls: ['./member-memo.component.scss']
})
export class MemberMemoComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() dormant: any;

  public search: any = {
    searchText: ''
  };

  public form: FormGroup;
  public formErrors = {};

  // 그리드 관련 선언
  memberMemoList: any = [];
  memberInfo: any = {
    hp: '',
    email: ''
  }

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public setMemberMemoSaveFunc: any;
  public setMemberHPEmailFunc: any;


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      // type: ['1', [Validators.required]],
      // type_data: ['', [Validators.required, Validators.maxLength(50)]],
      memberNo: ['', [Validators.required]],
      message: ['', [Validators.required] ],
      // emailBlocked: ['', []],
      // smsBlocked: ['', []],
      // kakaoBlocked: ['', []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    this.buildForm();

    this.setMemberMemoSaveFunc = this.setMemberMemoSave.bind(this);
    this.setMemberHPEmailFunc = this.setMemberHPEmail.bind(this);

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      // { headerName: '접수구분', field: 'type', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: function(params) {
      //     if( params.data.type == '1' ) {
      //       return '<span class="badge badge-success f12">전화접수</span>';
      //     } else {
      //       return '<span class="badge badge-primary f12">이메일접수</span>';
      //     }
      //   }
      // },
      // { headerName: '연락처', field: 'type_data', width: 120, cellClass: 'cp' },
      { headerName: '내용', field: 'message', width: 1300, cellClass: 'cp' },
      // { headerName: '이메일', field: 'email_blocked', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: function(params) {
      //     if( params.data.email_blocked == '1' ) {
      //       return '<span class="badge badge-danger f12">수신거부</span>';
      //     } else {
      //       return '';
      //     }
      //   }
      // },
      // { headerName: 'SMS', field: 'sms_blocked', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: function(params) {
      //     if( params.data.sms_blocked == '1' ) {
      //       return '<span class="badge badge-danger f12">수신거부</span>';
      //     } else {
      //       return '';
      //     }
      //   }
      // },
      // { headerName: 'KAKAO', field: 'kakao_blocked', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: function(params) {
      //     if( params.data.kakao_blocked == '1' ) {
      //       return '<span class="badge badge-danger f12">수신거부</span>';
      //     } else {
      //       return '';
      //     }
      //   }
      // },
      { headerName: '등록자', field: 'name', width: 100, cellClass: 'cp center' },
      { headerName: '등록일시', field: 'write_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.search.memberNo = this.memberNo;

    this.form.patchValue({
      memberNo: this.memberNo
    });

    this.getMemberMemoList();
  }

  /*******************************************************************************
    설  명 : 상담내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberMemoList() {
    this.memberService.getMemberMemoList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.memberMemoList = response.data;
        this.memberInfo = response.memberInfo;

        this.form.patchValue({type_data: response.memberInfo.hp});
      } else {
        this.toastrService.error( response.ResultMessage, '상담내역 오류');
        this.memberInfo = {
          hp: '',
          email: ''
        }
      }
    }, error => {
      this.toastrService.error( error, '상담내역 오류');
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 메모 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberMemoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.memberService.setMemberMemoSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '메모저장');

          this.form.reset();

          this.form.patchValue({ memberNo: this.memberNo });

          this.getMemberMemoList();
        } else {
          this.toastrService.error( response.ResultMessage, '메모저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '메모저장');
    }
  }

  /*******************************************************************************
    설  명 : 회원 메모 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberMemoDelete() {
    const nodes = this.gridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 메모를 선택하세요.', '메모삭제' );
      return false;
    }

    const data: any = [];
    nodes.forEach(item => {
      data.push(item.data);
    });

    if ( confirm('삭제하시겠습니까?') ) {
      this.memberService.setMemberMemoDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '메모삭제');

          this.getMemberMemoList();
        } else {
          this.toastrService.error( response.ResultMessage, '메모삭제');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      memberNo: this.memberNo,
      searchText: ''
    };

    this.getMemberMemoList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberMemoList();
    }
  }

  /*******************************************************************************
    설  명 : 회원 휴대폰, 이메일 주소 입력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberHPEmail() {
    if( this.form.controls.type.value == '1' ) {
      this.form.patchValue({type_data: this.memberInfo['hp']});
    } else {
      this.form.patchValue({type_data: this.memberInfo['email']});
    }

  }

}
