  <div class="modal-header">
    <h4 class="modal-title fb mb0">관리자 마일리지</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <form [formGroup]="form">
    <table class="table table-bordered table-small1 mb0">
      <caption class="none">회원정보</caption>
      <colgroup>
        <col style="width:20%;" />
        <col style="width:80%;" />
      </colgroup>
      
      <tbody>
        <tr>
          <th scope="row"><label>마일리지</label></th>
          <td>
            <inputEx type="text" name="mileage" [formGroup]="form" [formErrors]="formErrors" placeholder="마일리지 입력" ></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label>사유</label></th>
          <td>
            <inputEx type="text" name="reason" [formGroup]="form" [formErrors]="formErrors" placeholder="사유 입력(100자 이내)" ></inputEx>
          </td>
        </tr>
      </tbody>
    </table>
    </form>
  </div>

  <div class="modal-footer">
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="setManagerMileage()">마일리지 적립</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
  </div>
