<form [formGroup]="form">
  <header class="mt10 mb5">
    <div class="fl w50p">
      <h4 class="fb mb0">환율정보 설정</h4>
    </div>
    <div class="fr w50p right">
      <button type="submit" class="btn btn-primary btn-small" (click)="submit()">저장</button>
    </div>
    <div class="cb"></div>
  </header> 

  <section>
    <table class="table table-bordered table-small1 mt5 mb0">
    <caption class="none">환율정보</caption>
    <colgroup>
      <col style="width:10%;" />
      <col style="width:23%;" />
      <col style="width:10%;" />
      <col style="width:23%;" />
      <col style="width:10%;" />
      <col style="width:23%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="sync_count">일일 동기화 횟수</label></th>
      <td><inputEx type="numeric" name="sync_count" [formGroup]="form" [formErrors]="formErrors" max="12"></inputEx></td>
      <th scope="row"><label for="sync_time">동기화 시간</label></th>
      <td><inputEx type="text" name="sync_time" [formGroup]="form" [formErrors]="formErrors" maxlength="5" placeholder="00:00"></inputEx></td>
      <th scope="row"></th>
      <td></td>
    </tr>
    </tbody>
    </table>
  </section>
</form>
