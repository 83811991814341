import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';

@Component({
  selector: 'app-member-company-application-process2',
  templateUrl: './member-company-application-process2.component.html',
  styleUrls: ['./member-company-application-process2.component.scss']
})
export class MemberCompanyApplicationProcess2Component implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public data: any;

  public form: FormGroup;
  public formErrors = {};

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [Validators.required] ],
      application_gbn: ['', [Validators.required] ],
      mem_no: ['', [Validators.required] ],
      shop_seq: ['', [] ],
      shop_zipcode: ['', [Validators.maxLength(5)] ],
      shop_address: ['', [Validators.maxLength(100)] ],
      shop_address_detail: ['', [Validators.maxLength(100)] ],
      shop_name: ['', [Validators.required, Validators.maxLength(60)] ],
      shop_phone: ['', [Validators.maxLength(20)] ],
      shop_hp: ['', [Validators.maxLength(20)] ],
      shop_email: ['', [Validators.maxLength(50)] ],
      shop_fax: ['', [Validators.maxLength(20)] ],
      shop_homepage: ['', [Validators.maxLength(60)] ],
      license: ['', [Validators.required, Validators.maxLength(10)] ],
      depositor: ['', [] ],
      bank: ['', [] ],
      account: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      seq: this.data.seq,
      application_gbn: this.data.application_gbn,
      mem_no: this.data.mem_no,
      shop_name: this.data.company_name,
      license: this.data.biz_no,
      shop_zipcode: this.data.zipcode,
      shop_address: this.data.address,
      shop_address_detail: this.data.address_detail,
      shop_phone: this.data.hp,
      shop_hp: this.data.hp,
      shop_email: this.data.email
    });
  }

  /*******************************************************************************
    설  명 : 승인하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberTransitionApplicationApprove() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.memberService.setMemberTransitionApplicationApprove( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '승인하기');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '승인하기');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '승인하기');
    }
  }

  /*******************************************************************************
    설  명 : 거절하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberTransitionApplicationRefuse() {
    if ( confirm('신청을 거절하시겠습니까?') ) {
      this.memberService.setMemberTransitionApplicationRefuse( this.data.seq, this.data.mem_no ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '거절하기');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '거절하기');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      shop_zipcode: data.zip,
      shop_address: data.addr
    });

    $("#shop_address_detail").focus();
  }

}
