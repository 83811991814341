  <div class="modal-header">
    <h4 class="modal-title fb mb0">이메일 발송 내용보기</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body p10">
    <table class="table table-bordered table-small1 mb0">
    <caption class="none">메일내용</caption>
    <colgroup>
      <col style="width:10%;" />
      <col style="width:40%;" />
      <col style="width:10%;" />
      <col style="width:40%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row">제목</th>
      <td colspan="3" class="pl10"><span [innerHTML]="data?.subject | safe : 'html'"></span></td>
    </tr>
    <tr>
      <th scope="row">발신</th>
      <td class="pl10">{{data?.fromEmail}}</td>
      <th scope="row">발신일시</th>
      <td class="pl10">{{data?.writeDate}}</td>
    </tr>
    <tr>
      <th scope="row">수신</th>
      <td class="pl10" colspan="3">
        <div class="from-email">
        {{data?.toEmail}}
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="4">
        <div class="contents" [innerHTML]="data?.contents | safe : 'html'"></div>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
  </div>
