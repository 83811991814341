import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { OpenmarketService } from '@app/service/openmarket.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import * as $ from 'jquery';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-order-openmarket-upload',
  templateUrl: './order-openmarket-upload.component.html',
  styleUrls: ['./order-openmarket-upload.component.scss']
})
export class OrderOpenmarketUploadComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public type: any;

  public marketList: any = ['지마켓/옥션', '네이버', '스토어팜', '11번가', '쿠팡', '톡스토어'];
  public typeList: any = [];

  public baseURL = config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      type: ['0', [Validators.required] ],
      file: ['', []],
      files: [[], []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private utilService: UtilService,
    private restful: RestfulService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private openmarketService: OpenmarketService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      type: this.type
    });

    const tmp = [];
    this.marketList.forEach((data, i) => {
      tmp.push({
        value: i,
        title: data
      });
    });

    this.typeList = tmp;

    // 업로드 허용 파일 설정(application/image/video/audio/pdf/compress/doc/xls/ppt)
    var filetype: string[] = ['doc'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        // this.toastrService.success( response.message, '');

        let files = this.form.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          url: this.baseURL + response.url,
          thumbnail_org: response.thumbnail_org,
        });

        this.form.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenmarketOrderSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {

      switch( parseInt(this.form.controls.type.value) ) {
        case 0:   // 지마켓/옥션
          this.openmarketService.setGmarketAuctionOrderSave(this.form).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '주문목록 저장');

              this.activeModal.close(true);
            } else {
              this.toastrService.error( response.ResultMessage, '주문목록 저장');
            }
          });
          break;
        case 1:   // 네이버
          this.openmarketService.setNaverOrderSave(this.form).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '주문목록 저장');

              this.activeModal.close(true);
            } else {
              this.toastrService.error( response.ResultMessage, '주문목록 저장');
            }
          });
          break;
        case 2:   // 스토어팜
          this.openmarketService.setFarmOrderSave(this.form).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '주문목록 저장');

              this.activeModal.close(true);
            } else {
              this.toastrService.error( response.ResultMessage, '주문목록 저장');
            }
          });
          break;
        case 3:   // 11번가
          this.openmarketService.set11stOrderSave(this.form).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '주문목록 저장');

              this.activeModal.close(true);
            } else {
              this.toastrService.error( response.ResultMessage, '주문목록 저장');
            }
          });
          break;
        case 4:   // 쿠팡
          this.openmarketService.setCoupangOrderSave(this.form).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '주문목록 저장');

              this.activeModal.close(true);
            } else {
              this.toastrService.error( response.ResultMessage, '주문목록 저장');
            }
          });
          break;
        case 5:   // 톡스토어
          this.openmarketService.setTalkOrderSave(this.form).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '주문목록 저장');

              this.activeModal.close(true);
            } else {
              this.toastrService.error( response.ResultMessage, '주문목록 저장');
            }
          });
          break;
        default:
          this.toastrService.error( '오픈마켓 타입이 잘못되었습니다.', '주문목록 저장');
      }

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
