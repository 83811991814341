import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { BikeService } from '@app/service/bike.service';

@Component({
  selector: 'app-bike-payhelper-detail-status',
  templateUrl: './bike-payhelper-detail-status.component.html',
  styleUrls: ['./bike-payhelper-detail-status.component.scss']
})
export class BikePayhelperDetailStatusComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() status_seq: any;
  @Input() helper_seq: any;
  @Input() statusInfo: any;

  public form: FormGroup;
  public formErrors = {};
  
  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      helper_seq: ['', [] ],
      status: ['', [Validators.required] ],
      status_name: ['', [] ],
      app_date: [ null, [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private bikeService: BikeService,
  ) {
    this.buildForm();
   }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    let app_date: any = ( this.statusInfo !== undefined ) ? this.statusInfo.app_date : moment().format('YYYY-MM-DD');
    
    this.form.patchValue({
      seq: this.status_seq,
      helper_seq: this.helper_seq,
      status: this.statusInfo.common_code,
      status_name: this.statusInfo.common_name,
      app_date: this.utilService.getDate(app_date),
    });
  }

  /*******************************************************************************
    설  명 : 결제 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikePayhelperStatusChange() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.bikeService.setBikePayhelperStatusChange(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
