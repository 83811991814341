import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 이벤트 조회
  getEventList( search: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'getEventList'
    }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 이벤트 조회
  getSupersaleList( search: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'getSupersaleList'
    }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 조회
  getEventDetail( seq: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'getEventDetail'
    }, {
      seq: seq
    }).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 순서 저장
  setEventSortSave( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventSortSave'
    }, {
      params: array
    }
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 이벤트 등록/수정
  setEventSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventSave'
    }, form.value
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 이벤트 품목조회
  getEventProductList( params: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventProductList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 이벤트 품목 저장
  setEventProductSave( params: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventProductSave'
    }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 품목 할인정보 저장
  setEventProductDiscountSave( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventProductDiscountSave'
    }, {
      params: array
    }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 품목 할인정보 삭제
  setEventProductDiscountDelete( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventProductDiscountDelete'
    }, {
      params: array
    }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 품목 순서 저장
  setEventProductSortSave( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventProductSortSave'
    }, {
      params: array
    }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 할인금액 저장
  setEventProductAmountSave( params: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventProductAmountSave'
    }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 삭제
  setEventDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'setEventDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 이벤트 이미지 삭제
  setEventImageDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'setEventImageDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 이벤트 품목 삭제
  setEventProductDelete(params): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventProductDelete'
    }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 일자별 분석
  getEventOrderDayList( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventOrderDayList'
      }, {
        eventSeq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 이벤트 구매내역
  getEventOrderList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventOrderList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 이벤트 구매자 내역
  getEventBuyerList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventBuyerList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 이벤트 판매리스트 내역
  getEventSaleList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventSaleList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }  

  // 슈퍼세일 위젯
  getEventSuperSaleWidget(seq: string): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'getEventSuperSaleWidget'
    }, {
      seq: seq
    }).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 카테고리 리스트
  getEventSuperSaleCategoryList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventSuperSaleCategoryList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 카테고리 품목 리스트
  getEventSuperSaleCategoryProductList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventSuperSaleCategoryProductList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 브랜드 리스트
  getEventSuperSaleBrandList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventSuperSaleBrandList'
      }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 슈퍼세일 브랜드 품목 리스트
  getEventSuperSaleBrandProductList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventSuperSaleBrandProductList'
      }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 슈퍼세일 상품검색 리스트
  getEventSuperSaleSearchList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventSuperSaleSearchList'
      }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 슈퍼세일 검색한 품목 리스트
  getEventSuperSaleSearchProductList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getEventSuperSaleSearchProductList'
      }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 슈퍼세일 카테고리 등록/수정
  setEventSuperSaleCategorySave(params): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventSuperSaleCategorySave'
    }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 브랜드 등록/수정
  setEventSuperSaleBrandSave(params): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventSuperSaleBrandSave'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 대표이미지 설정
  setEventImageDefault( file: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'setEventImageDefault'
      }, file
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 초성퀴즈 목록
  getEventQuizList(search: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'getEventQuizList'
    }, search
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 초성퀴즈 정답자 목록
  getEventQuizAnswerList(search: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'getEventQuizAnswerList'
    }, search
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 퀴즈 등록/수정
  setEventQuiz(seq: any, start_date: string, end_date: string, form: any): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventQuiz'
    }, {seq: seq, start_date: start_date, end_date: end_date, ...form.value}
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 퀴즈 삭제
  setEventQuizDelete(seq: any): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventQuizDelete'
    }, {seq: seq}
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 슈퍼세일 메인표시순서 업로드 처리
  setSuperSaleMainSort( params: any, event_seq: number ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setSuperSaleMainSort'
    }, {
      params: params,
      event_seq: event_seq
    }
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 쿠폰 관리 리스트 가져오기
  getEventCouponList( search: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'getEventCouponList'
    }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

   // 배민쿠폰코드 리스트 가져오기
   getBaminCodeList( eventCouponSeq: any ): Promise<any> {
      return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'getBaminCodeList'
      }, {eventCouponSeq:eventCouponSeq}
      ).then(this.extractData)
      .catch(this.handleErrorPromise);
    }

  // 쿠폰 등록/수정
  setEventCouponSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventCouponSave'
    }, form.value
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 쿠폰 삭제
  setEventCouponDelete( seq: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setEventCouponDelete'
    }, {
      seq: seq
    }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

    // 배민 쿠폰 코드를 생성한다.
    setBaminCode(seq: string): Promise<any> {
      return this.restful.get({
        program: 'admin',
        service: 'event',
        version: '1.0',
        action: 'setBaminCode'
      }, {seq:seq} 
      ).then(this.extractData)
      .catch(this.handleErrorPromise);
    }

  // 쿠폰 삭제
  setBaminCodeDelete( seq: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'event',
      version: '1.0',
      action: 'setBaminCodeDelete'
    }, {
      seq: seq
    }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }    

}
