import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-workmanagement-usevacation-lateness',
  templateUrl: './workmanagement-usevacation-lateness.component.html',
  styleUrls: ['./workmanagement-usevacation-lateness.component.scss']
})
export class WorkmanagementUsevacationLatenessComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
