<form [formGroup]="form" (ngSubmit)="setDeliveryChargeSave()">

  <div class="modal-header">
    <h4 class="modal-title fb mb0">배송비 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
  
  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="none">배송비 정보</caption>
    <colgroup>
      <col style="width:20%;" />
      <col style="width:80%;" />
    </colgroup>
    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="pay_method">매입처</label></th>
      <td>
        <inputEx type="select2" name="customer_seq" [formGroup]="form" [formErrors]="formErrors" [data]="customerList" [options]="{multiple: false}" [onChanged]="customerChangedFunc"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="pay_amt">배송비</label></th>
      <td><inputEx type="text" name="delivery_charge" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    </tbody>
    </table>
  </div>
  
  <div class="modal-footer">
    <div class="fl w50p">
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
      <button type="submit" class="btn btn-primary btn-small ml5">저장하기</button>
    </div>
  </div>
  
  </form>