<form [formGroup]="form">
  <!-- <input type="hidden" id="seq" formControlName="seq" /> -->

  <div class="modal-header">
    <h4 class="modal-title fb mb0">{{loss_mode ? (loss_mode == 1 ? '폐기출고' : '분실출고') : '창고이동'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="hidden">{{loss_mode ? (loss_mode == 1 ? '폐기출고' : '분실출고') : '창고이동'}}</caption>

    <colgroup>
      <col style="width:25%;" />
      <col style="width:75%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="product_name" class="control-label">상품</label></th>
      <td>
        <!-- <inputEx type="text" name="product_name" [formGroup]="form" [formErrors]="formErrors" readonly="true" [addOn]="addProductFunc" addOnText="상품검색"></inputEx>
        <div class="mt5">
          ※ 상품을 선택해야 출고/입고 창고를 선택할 수 있습니다.
        </div> -->
        <inputEx type="text" name="product_name" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label class="control-label">출고창고</label></th>
      <td>
        <!-- <inputEx type="select" name="warehouse_seq_out" [formGroup]="form" [formErrors]="formErrors" [data]="out_warehouse"></inputEx> -->
        <select id="out_warehouse_seq" class="form-control form-control-small" [value] = "form.value.out_warehouse_seq" (change) = "selectChange($event)">
          <option value=""></option>
          <option *ngFor = "let item of data_warehouse" value="{{item.warehouse_seq}}" selected="{{form.value.out_warehouse_seq == item.warehouse_seq}}">{{item.warehouse_name}}</option>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"> <label class="control-label">출고위치</label></th>
      <td>
        <select id="out_warehouse_location_seq" class="form-control form-control-small" [value] = "form.value.out_warehouse_location_seq" (change) = "selectChange($event)">
          <option value=""></option>
          <option *ngFor = "let item of out_WH_location" value="{{item.seq}}" selected="{{form.value.out_warehouse_location_seq == item.seq}}">{{item.location_cd}}</option>
        </select>
      </td>
    </tr>
    <tr *ngIf = "!loss_mode">
      <th scope="row"><i class="material-icons cred f11">star</i> <label class="control-label">입고창고</label></th>
      <td>
        <select id="in_warehouse_seq" class="form-control form-control-small" [value] = "form.value.in_warehouse_seq" (change) = "selectChange($event)">
          <option value=""></option>
          <option *ngFor = "let item of data_warehouse_all" value="{{item.warehouse_seq}}">{{item.warehouse_name}}</option>
        </select>
      </td>
    </tr>
    <tr *ngIf = "!loss_mode">
      <th scope="row"> <label class="control-label">입고위치</label></th>
      <td>
        <select id="in_warehouse_location_seq" class="form-control form-control-small" [value] = "form.value.in_warehouse_location_seq" (change) = "selectChange($event)">
          <option value=""></option>
          <option *ngFor = "let item of in_WH_location" value="{{item.seq}}">{{item.location_cd}}</option>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="inout_date" class="control-label">일자</label></th>
      <td>
        <inputEx type="date" name="inout_date" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="max_qty" class="control-label">가능수량</label></th>
      <td>
        <inputEx type="number" name="max_qty" [formGroup]="form" [formErrors]="formErrors" readonly="true" placeholder="창고를 선택하세요."></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="inout_qty" class="control-label">수량</label></th>
      <td>
        <inputEx type="number" name="inout_qty" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="memo" class="control-label">메모</label></th>
      <td colspan="3">
        <textarea formControlName="memo" class="form-control form-control-small"></textarea>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <!-- <div class="fl w50p">
      <button type="button" class="btn btn-small btn-danger">삭제</button>
    </div> -->
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click) = "submit()">저장</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
    </div>
  </div>
</form>