  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{summary?.sale_count | number}}</span>개</p>
      </div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{summary?.found | number}}</span>개</p>
      </div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">시작일자<span class="cnt">{{summary?.sale_date_a | Moment: "YYYY-MM-DD"}}</span></p>
      </div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">마지막일자<span class="cnt">{{summary?.sale_date_b | Moment: "YYYY-MM-DD"}}</span></p>
      </div>
    </div>
    <div class="cb"></div>
  </section>
  <div class="mt5">
    <div class="fl form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="clear()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="retrieve()">
        <i class="material-icons-outlined">search</i>
      </button>
      <div class="btn-group ml5">
        <button *ngFor="let store of stores" type="button" class="btn btn-default btn-small" [ngClass]="{ active:retrieveOptions?.store_id == store?.store_id }" (click)="retrieveOptions.store_id = store.store_id; retrieve();">{{ store.store_name }}</button>
      </div>
      <input ngbDatepicker type="text" class="form-control form-control-small calendar-input ml5" size="15" readonly="readonly" placeholder="시작일자" #saleDateA="ngbDatepicker" [(ngModel)]="retrieveOptions.sale_date_a" [footerTemplate]="saleDateAFooter" (ngModelChange)="retrieve()" (click)="saleDateA.toggle()" />
      <ng-template #saleDateAFooter>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="retrieveOptions.sale_date_a = retrieveOptions.today; saleDateA.close()">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="saleDateA.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input ngbDatepicker type="text" class="form-control form-control-small calendar-input" size="15" readonly="readonly" placeholder="종료일자" #saleDateB="ngbDatepicker" [(ngModel)]="retrieveOptions.sale_date_b" [footerTemplate]="saleDateBFooter" (ngModelChange)="retrieve()" (click)="saleDateB.toggle()" />
      <ng-template #saleDateBFooter>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="retrieveOptions.sale_date_b = retrieveOptions.today; saleDateB.close()">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="saleDateB.close()">닫기</button>
      </ng-template>
    </div>
    <div class="fr right">
      <btn-ag-grid-save [gridColumnsApi]="gridAOptions.api" gridId="75029444db2d41a9987afed616e6777e" [btnGroup]="1"></btn-ag-grid-save>
    </div>
    <div class="cb"></div>
  </div>
  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" class="mt5" style="height: calc(100% - 90px);background-color:#fff;">
    <as-split-area size="60" minSize="40" maxSize="80">
      <ag-grid-angular #gridA class="ag-theme-balham" style="width: 100%; height: 100%" [gridOptions]="gridAOptions"></ag-grid-angular>
    </as-split-area>
    <as-split-area size="40" minSize="20" maxSize="60">
      <div class="mb5">
        <div class="fl w50p">
          <h4 class="mb0 fb">상세 매출내역</h4>
        </div>
        <div class="fr w50p right">
          <btn-ag-grid-save [gridColumnsApi]="gridBOptions.api" gridId="96e1d2ee83ae4e4c935c4b2fe4b289a2" [btnGroup]="1"></btn-ag-grid-save>
        </div>
        <div class="cb"></div>
      </div>
      <ag-grid-angular #gridB class="ag-theme-balham" style="width: 100%; height:calc(100% - 35px)" [gridOptions]="gridBOptions"></ag-grid-angular>
    </as-split-area>
  </as-split>
