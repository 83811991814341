/*******************************************************************************
  설  명 : 이벤트관리
  작성일 : 2020-09-02
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Injectable } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { EventService } from '@app/service/event.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { MatTooltipComponent } from '@components/mat-tooltip/mat-tooltip.component';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  fromModel(value: string | null): NgbDateStruct | null {
    const date = value ? value.split('-') : null;
    return date ? { year: parseInt( date[0], 10 ), month: parseInt( date[1], 10 ), day: parseInt( date[2], 10 ) } : null;
  }

  toModel(value: NgbDateStruct | null): string | null {
    return value ?
      value.year.toString() + '-' + (value.month).toString().padStart(2, '0') + '-' + value.day.toString().padStart(2, '0') : null;
  }
}

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [ {provide: NgbDateAdapter, useClass: CustomAdapter} ],
})
export class EventComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    status: '',
    sdate: '',
    edate: '',
    eventName: '',
    eventSeq: '',
    eventType: ''
  };

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    processCount: 0,
    standbyCount: 0,
    completeCount: 0
  }

  // 그리드 관련 선언
  eventList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '이동', field: '', width: 50, cellClass: 'cp center', filter: false,
        rowDrag: (params) => {
          if( params.data.banner_use > 0 && params.data.isDisplay == '1' ) return true;
          else return false;
        }
      },
      { headerName: '배너순서', field: 'sort_order', width: 75, cellClass: 'cp center' },
      { headerName: '상태', field: 'status', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( params.data.status === '1' ) {
            return '<span class="badge badge-success f12">진행중</span>';
          } else if ( params.data.status === '2' ) {
            return '<span class="badge badge-primary f12">진행예정</span>';
          } else {
            return '<span class="badge badge-secondary f12">종료</span>';
          }
        }
      },
      { headerName: '추천', field: 'isRecommend', width: 85, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( params.data.isRecommend === '1' ) {
            return '<span class="badge badge-success f12">추천</span>';
          } else {
            return '<span class="badge badge-secondary f12">비추천</span>';
          }
        }
      },
      { headerName: '표시', field: 'isDisplay', width: 60, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( params.data.isDisplay === '1' ) {
            return '<span class="badge badge-success f12">표시</span>';
          } else {
            return '<span class="badge badge-secondary f12">숨김</span>';
          }
        }
      },
      { headerName: '가격설정여부', field: 'is_memberDiscount', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( params.data.is_memberDiscount === '1' ) {
            return '<span class="badge badge-secondary f12">설정안함</span>';
          } else {
            return '<span class="badge badge-danger f12">설정함</span>';
          }
        }
      },
      { headerName: '타입', field: 'event_type', width: 82, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          let color: any;
          if ( params.data.event_type === '1000' ) {
            color = 'danger';
          } else if ( params.data.event_type === '1001' ) {
            color = 'warning';
          } else if ( params.data.event_type === '2000' ) {
            color = 'primary';
          } else if ( params.data.event_type === '3000' ) {
            color = 'info';
          } else {
            color = '';
          }

          return `<span class="badge badge-${color} f12">${params.data.event_type_name}</span>`;
        }
      },
      { headerName: '제목', field: 'event_name', width: 240, cellClass: 'cp',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data
      },
      { headerName: '이벤트대상', field: 'target_group_concat', width: 200, cellClass: 'cp left' },
      { headerName: '상품수', field: 'productCount', width: 70, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '시작기간', field: 'start_date', width: 145, cellClass: 'cp center',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data
      },
      { headerName: '종료기간', field: 'end_date', width: 145, cellClass: 'cp center',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data
      },
      { headerName: '테스트시작', field: 'test_start_date', width: 145, cellClass: 'cp center',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data
      },
      { headerName: '테스트종료', field: 'test_end_date', width: 145, cellClass: 'cp center',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data
      },
      { headerName: '테스트ID', field: 'test_id', width: 100, cellClass: 'cp center' },
      // { headerName: '메모', field: 'memo', width: 100, cellClass: 'cp',
      //   cellRendererFramework: MatTooltipComponent,
      //   cellRendererParams: (params: ICellRendererParams) => params.data
      // },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe( search => {

      this.search = {
        status: search.status,
        sdate: search.sdate,
        edate: search.edate,
        eventName: search.eventName,
        eventSeq: search.eventSeq,
        eventType: search.eventType
      };

      this.search.status = search.status === undefined ? '' : search.status;
      // this.search.sdate = search.sdate === undefined ?  moment().format('YYYY-MM-01') : search.sdate;
      // this.search.edate = search.edate === undefined ? moment().add(1, 'months').format('YYYY-MM-DD') : search.edate;
      this.search.sdate = search.sdate === undefined ?  null : search.sdate;
      this.search.edate = search.edate === undefined ? null : search.edate;
      this.search.eventName = search.eventName === undefined ? '' : search.eventName;

      this.getEventList();
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      status: '',
      // sdate: moment().format('YYYY-MM-01'),
      // edate: moment().add(1, 'months').format('YYYY-MM-DD'),
      sdate: null,
      edate: null,
      eventName: '',
    };

    this.getEventList();
  }

  /*******************************************************************************
    설  명 : 이벤트 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventList() {
    this.eventService.getEventList(this.search).then( response => {
      if (response.ResultCode) {
        this.eventList = response.data;
        this.statistic = response.statistic;
      } else {
        this.toastrService.error( response.ResultMessage, '이벤트리스트');

        this.statistic = {
          totalCount: 0,
          searchCount: 0,
          processCount: 0,
          standbyCount: 0,
          completeCount: 0
        }
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.search.eventSeq = $event.data.seq;
    this.search.eventType = $event.data.event_type;

    this.goDetail();
  }

  /*******************************************************************************
    설  명 : 이벤트 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  eventAdd() {
    this.search.eventSeq = '';
    this.search.eventType = '';

    this.goDetail();
  }

  /*******************************************************************************
    설  명 : 순서 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEventSortSave() {
    const data: any = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(node) {
      data.push({
        seq: node.data.seq,
        banner_use: node.data.banner_use
      });
    });

    if ( confirm('해당 순서로 저장하시겠습니까?') ) {
      this.eventService.setEventSortSave( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getEventList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 상세보기 링크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail() {
    this.router.navigate(
    ['/basic/event/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = moment().format('YYYY-MM-DD');
    } else {
      this.search.edate = moment().format('YYYY-MM-DD');
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getEventList();
    }
  }

}
