import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    const body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // PUSH 발송양식 리스트 가져오기
  getPushFormList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'push',
        version: '1.0',
        action: 'getPushFormList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // PUSH 발송양식 저장
  setPushForm( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'push',
        version: '1.0',
        action: 'setPushForm'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // PUSH 발송양식 정보 삭제하기
  delPushForm( form: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'push',
        version: '1.0',
        action: 'delPushForm'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // PUSH 발송 내역 가져오기
  getPushSendList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'push',
        version: '1.0',
        action: 'getPushSendList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 푸시 메시지 보내기
  sendPushMessage( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'push',
        version: '1.0',
        action: 'sendPushMessage'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
