<div style="position: absolute; top: 16; z-index: 8; top: 90px; right: 10px;">
  <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
</div>

<mat-tab-group>
  <mat-tab label="{{title}} 정보">
    <app-event-info [eventSeq]="search.eventSeq"></app-event-info>
  </mat-tab>
  <ng-container *ngIf="search.eventSeq !== ''">
    <ng-container *ngIf="eventInfo.is_memberDiscount == '0'">
      <ng-container *ngIf="eventInfo.event_type !== '3000'; else eventCouponProduct;">
        <mat-tab label="{{title}} 품목">
          <app-event-supersale-product [eventSeq]="search.eventSeq"></app-event-supersale-product>
        </mat-tab>
      </ng-container>
      <ng-template #eventCouponProduct>
        <mat-tab label="{{title}} 품목">
          <app-event-coupon-product [eventSeq]="search.eventSeq"></app-event-coupon-product>
        </mat-tab>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="eventInfo.is_memberDiscount == '1'">
      <mat-tab label="{{title}} 품목">
        <app-event-product [eventSeq]="search.eventSeq"></app-event-product>
      </mat-tab>
    </ng-container>
    <mat-tab label="일자별분석">
      <app-event-day-statistic [eventSeq]="search.eventSeq"></app-event-day-statistic>
    </mat-tab>
    <mat-tab label="{{title}} 구매내역">
      <app-event-statistic [eventSeq]="search.eventSeq"></app-event-statistic>
    </mat-tab>
  </ng-container>
</mat-tab-group>
