<div class="modal-header">
  <h4 *ngIf="isPurchase==true" class="modal-title bold">주문 환불 및 취소 처리</h4>
  <h4 *ngIf="isPurchase==false" class="modal-title bold">주문 반품 및 교환 처리</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <section>
    <div class="mt5">
      <div class="fl">
        <h4 class="fb mt5 mb6" *ngIf="isPurchase==true">※ <span class="cred">반품 및 교환</span>은 POS에서 처리하시기 바랍니다.</h4>
        <h4 class="fb mt5 mb6" *ngIf="isPurchase==false">※ <span class="cred">입점 업체</span> 반품 및 교환 처리 화면입니다.</h4>
      </div>
      <div class="fr w30p right">
        <button *ngIf="ableQty === 0" type="button" class="btn cborange btn-small" (click)="setCreateOrderRefund()">환불데이터 생성</button>
        <button type="button" class="btn btn-success btn-small ml15" (click)="setOrderDetailRefund()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <div>
      <table class="table table-bordered table-small1 mt5 mb50">
        <caption class="none">거래처정보</caption>
        <!-- <colgroup>
          <col style="width:15%;" />
          <col style="width:85%;" />
        </colgroup> -->

        <colgroup>
          <col style="width:20%;" />
          <col style="width:30%;" />
          <col style="width:20%;" />
          <col style="width:30%;" />
        </colgroup>
  
        <tbody>
          <tr>
            <th scope="row"><label for="date">처리일자</label></th>
            <td colspan="3"><inputEx type="date" name="date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="">수량</label></th>
            <td colspan="3">
              <label class="f16 form-inline amt cred">
                <inputEx type="text" name="qty" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                <div class="ml5"> 처리 가능한 수량 : {{this.ableQty}}</div>
              </label>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="">분류선택</label></th>
            <td>
              <inputEx type="select" name="changeRef" [formGroup]="form" [formErrors]="formErrors" [data]="changeRefList" (change)="changeSelect()"></inputEx>
            </td>
            <th scope="row"><label for="">SMS</label></th>
            <td colspan="3">
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value=='1'}" (click)="form.get('sms_yn').setValue('1')">발송함</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value=='0'}" (click)="form.get('sms_yn').setValue('0')">발송안함</button>
              </div>
              <!-- <inputEx type="hidden" name="consign_fee" [formGroup]="form" [formErrors]="formErrors"></inputEx> -->
            </td>
          </tr>
          <tr *ngIf="form.value.reg_gbn == '3000'">
            <th scope="row"><label for="change_detail">교환 상품 선택</label></th>
            <td colspan="3">
              <button *ngIf="form.value.change_detail.length < 1" type="button" class="btn btn-default btn-small ml5 fr" (click)="productAdd()">상품 추가</button>
              <div *ngIf="form.value.change_detail != '' || form.value.change_detail != null">
                <ul *ngFor="let product of form.value.change_detail; let i = index">
                  <li>
                    <div class="name">{{product.product_name}} / {{product.color_name}}-{{product.display_color_name}} / {{product.size_name}}-{{product.display_size_name}} / <span>{{product.amt * product.qty | number}}원</span>
                      <input type="number" class="qty-input ml5" [value]="product.qty" [(ngModel)]="product.qty" (keyup)="changeQty(product, $event)" (change)="changeQty(product, $event)" />
                      <button class="btn-del" (click)="deleteProduct(i)"></button>
                    </div>
                  </li>
                </ul>
              </div>
            <inputEx type="hidden" name="change_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </section>

</div>