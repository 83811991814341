/*******************************************************************************
  설  명 : 기초관리 > 기본정보설정 > 회원관리
  작성일 : 2021-05-13
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { SiteConfigService } from '@app/service/site.config.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

const CONFIG_KEY = 'MEMBER';

@Component({
  selector: 'app-basic-member',
  templateUrl: './basic-member.component.html',
  styleUrls: ['./basic-member.component.scss']
})
export class BasicMemberComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public commonType: any = [];
  public memberGradeList: any = [];
  public basicMember: any;

  public useYnSelect: any = [
    {seq: '1', name: '사용함'},
    {seq: '0', name: '사용안함'},
  ];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;
  components: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      member_dormant_useyn: ['', []],
      member_expire_day: ['', [Validators.maxLength(4)]],
      member_expire_send: ['', []],
      member_dormant_expect_useyn: ['', []],
      member_expire_schedule_day: ['', [Validators.maxLength(4)]],
      member_expire_schedule_send: ['', []],
      member_level_max: ['', [Validators.maxLength(2)]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private siteConfigService: SiteConfigService,
    private agGridExComponent: AgGridExComponent,
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '등급코드', field: 'grade_code', width: 100, cellClass: 'center'},
      { headerName: '등급명', field: 'grade_name', width: 200, cellClass: 'center' },
      { headerName: '할인율', field: 'discount_rate', width: 120, cellClass: 'center', 
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `${params.data.discount_rate} %`;
        }
      },
      { headerName: '실제 표시되는 할인율', field: 'display_dc_rate', width: 240, cellClass: 'cp right ag-cell-edit', editable: true, 
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '적용시작금액', field: 'start_amt', width: 240, cellClass: 'cp right ag-cell-edit', editable: true, 
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '적용종료금액', field: 'end_amt', width: 240, cellClass: 'cp right ag-cell-edit', editable: true, 
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '사용여부', field: 'use_yn', width: 150, cellClass: 'cp center ag-cell-edit', editable: true, 
        cellRenderer: 'agGridHtmlComponent', 
        cellEditor: 'selectCellEditor',
        cellEditorParams: {
          values: this.useYnSelect,
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter(params: any) {
          if ( params.data.use_yn == '1' ) {
            return '<span class="badge badge-success f12">사용함</span>';
          } else {
            return '<span class="badge badge-secondary f12">사용안함</span>';
          }
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      resizable: true
    };

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      selectCellEditor: this.agGridExComponent.getSelectEditor()
    };

    function currencyFormatter(params: any) {
      const str = String(params.data[ params.column.colId ]);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
    
  /*******************************************************************************
    설  명 : 셀 변경 종료 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStopped(event: any) {
    this.siteConfigService.setConfigMemberGrade(event.data).then( response => {
      if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '');
      else this.toastrService.error( response.ResultMessage, '');
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getConfig();
    this.getConfigMemberGrade();
  }

  /*******************************************************************************
    설  명 : 환경설정 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          member_dormant_useyn:       response.data.member_dormant_useyn || '',
          member_expire_day:          response.data.member_expire_day || '',
          member_expire_send:         response.data.member_expire_send || '',
          member_dormant_expect_useyn:response.data.member_dormant_expect_useyn || '',
          member_expire_schedule_day: response.data.member_expire_schedule_day || '',
          member_expire_schedule_send:response.data.member_expire_schedule_send || '',
          member_level_max:           response.data.member_level_max || '',
        });
      }
    });
  }  

  /*******************************************************************************
    설  명 : 회원등급 설정 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfigMemberGrade() {
    this.siteConfigService.getConfigMemberGrade().then( response => {
      if (response.ResultCode) {
        this.memberGradeList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.siteConfigService.setConfig(CONFIG_KEY, this.form).then( response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '결제정보 저장');
        else this.toastrService.error( response.ResultMessage, '결제정보 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '결제정보 저장');
    }
  }
  
}
