<section class="mt5 mb3 right">
  <form [formGroup]="form">
    <div class="fl pt10">
      <b>프렌차이즈별 월별매출 상세</b>
    </div>
    <div class="fr form-inline">
      <label for="year">년도</label>
      <inputEx type="select" name="year" class="ml5" [formGroup]="form" [formErrors]="formErrors" [data]="yearData" (change)="changeYear($event)"></inputEx>
      <button type="submit" class="btn btn-primary btn-small ml5">검색</button>

      <ag-grid-excel [gridApi]="gridOptions.api" selected="false" class="ml5" filename="프렌차이즈별 월별매출상세" title="프렌차이즈별 월별매출상세"></ag-grid-excel>
    </div>
    <div class="cb"></div>
  </form>
</section>
<section style="height:725px;">
  <ag-grid-angular #grid class="ag-theme-balham" style="width: 100%;height:100%;" [gridOptions]="gridOptions"></ag-grid-angular>
</section>
