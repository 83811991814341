import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { EtcAccountService } from '@app/service/etc-account.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-etcAccount-add',
  templateUrl: './etcAccount-add.component.html',
  styleUrls: ['./etcAccount-add.component.scss']
})
export class EtcaddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public selectedItem: any = {
    seq: ""
  };
  public title: string = '추가';

  public commonType: any = [];


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      account_type: ['1000', [Validators.required] ],
      account_name: ['', [Validators.required] ],
      use_yn: ['1', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private etcAccountService: EtcAccountService,
  ) {
    this.buildForm();

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
  }



  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    ngAfterContentInit() {
      if( this.selectedItem == 0 ) {
        this.title = '추가';
      } else {
        this.title = '수정';
        // 데이터 로딩 처리
        this.form.patchValue( this.selectedItem );
      }

    }

 


  /*******************************************************************************
    설  명 : 입출금 계정 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    submit() {
      this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
  
      if(this.form.valid) {
        this.etcAccountService.setEtcAccount(this.form).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.activeModal.close(true);
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
  
      } else {
        this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      }
    }


  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getCommonList() {
      // 거래형태
      this.commonService.getCommonListCode('EAC').then( response => {
        if ( response.ResultCode ) {
          this.commonType = response.data;
        } else {
          this.commonType = [];
        }
      });
    }



  /*******************************************************************************
    설  명 : 기타 입출금 계정 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    delete() {
      if( confirm("선택하신 입출금 계정 항목을 삭제하시겠습니까?") ) {
        this.etcAccountService.delAccount( this.form.controls.seq.value ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.activeModal.close(true);
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
    



  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

}