import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '@app/service/email.service';
import { CommonService } from '@app/service/common.service';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss']
})
export class EmailFormComponent implements OnInit {

  // 검색
  public search: any = {
    searchText: '',
    seq: '',
    auto_seq: '',
  };

  private titleDef = {title: '전체', value: ''};
  public autoList: any = [];
  public emailList: any = [];

  // grid
  private selectedRows = 0;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowSelection = 'single';
  public noRowsTemplate = '검색된 데이터가 없습니다.';
  public defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };
  public columnDefs = [
    {headerName: '번호', field: 'seq', cellClass: 'cp center', width: 70 },
    {headerName: '자동발송', field: 'autoName', cellClass: 'cp left', width: 250 },
    {headerName: '제목', field: 'title', cellClass: 'cp', width: 400 },
    {headerName: '발송내용', field: 'contents', cellClass: 'cp', width: 700 },
    {headerName: '등록일', field: 'writeDate', cellClass: 'cp center', width: 150 },
    {headerName: '자동발송 코드', field: 'auto_seq', hide: true },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private emailService: EmailService,
  ) { }

  ngOnInit(): void {
    // 공통코드 가져오기
    this.getAutoEmail();

    this.activatedRoute.queryParams.subscribe( search => {
      this.search = {
        searchText: search.searchText,
        auto_seq: search.auto_seq,
      };

      // DropDown Selected 처리
      this.search.auto_seq = search.auto_seq === undefined ? '' : search.auto_seq;

      // 양식 리스트 가져오기
      this.getEmailFormList();
    });
  }

  /*******************************************************************************
    설  명 : 자동 발송 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAutoEmail() {
    this.autoList = [];
    this.autoList.push(this.titleDef);

    this.commonService.getCommonListCode('mail').then( response => {
      /* 2020-05-20 송영석 주석처리 */

      for (const item of response.data) {
        this.autoList.push({
          title: item.common_name,
          value: item.seq,
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : eMail 발송양식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmailFormList() {
    this.emailList = [];
    this.emailService.getEmailFormList( this.search ).then( response => {
      if (response.ResultCode) {
        this.emailList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : Email 발송양식 등록 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addEmailForm() {
    this.router.navigate(
      ['/basic/email/add'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 검색 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search.searchText = '';
    this.search.auto_seq = '';

    this.getEmailFormList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if ( event.key === 'Enter' ) {
      this.getEmailFormList();
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.search.seq = $event.data.seq;

    this.router.navigate(
      ['/basic/email/add'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.search,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

}
