/*******************************************************************************
  설  명 : 현황관리 - 전체 매출현황 inframe
  작성일 : 2020-12-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-statistic-home-detail',
  templateUrl: './statistic-home-detail.component.html',
  styleUrls: ['./statistic-home-detail.component.scss']
})
export class StatisticHomeDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() data: any = '';

   // 차트 관련 변수
  public barChartData: any = [];
  public chartType = 'bar';
  public chartLabel: string[] = [];

  // 차트 옵션
  public chartOptions = {
    tooltips: {
      display: true,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: 'chocolate',
      bodyFontColor: '#000',
      bodyFontSize: 12,
      displayColors: false,
    },
    scaleShowVerticalLines: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad'
    },
    responsive: true,
    legend: {
      display: true
    },
    hover: {
      mode: 'arest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data Value'
        },
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  // 그리드 관련 선언
  public dataList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private agGridExComponent: AgGridExComponent
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '구분', field: 'mem_no', width: 120, cellClass: 'cp center' },
      { headerName: '2009년', field: 'remainder_mileage', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
    ];

    var tmp = [
      {type: 'bar', data: [], label: '매출목표'},
      {type: 'bar', data: [], label: '매출'},
      {type: 'line', data: [], label: '마진', fill: false}
    ];

    for( let i = 0; i <= 12; i++ ) {
      this.columnDefs.push({ headerName: 2008 + i + '년', field: 'yyyy' + i, width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter });

      this.chartLabel[i] = 2008 + i + '년';

      tmp[0].data.push( Math.floor(Math.random() * (600000 - 10000 + 1)) + 10000 );
      tmp[1].data.push( Math.floor(Math.random() * (600000 - 10000 + 1)) + 10000 );
      tmp[2].data.push( Math.floor(Math.random() * (600000 - 10000 + 1)) + 10000 );
    }

    this.columnDefs.push({ headerName: '합계', field: 'remainder_mileage', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter });

    this.barChartData.push(tmp[0]);
    this.barChartData.push(tmp[1]);
    this.barChartData.push(tmp[2]);

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
