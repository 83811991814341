import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public redirectURL: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private member: MemberService,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.redirectURL = state.url;

    let result = await this.member.getMemberInfo( 'admin' ).then( response => {
      if( response['ResultCode'] == true ) {
        this.authService.login(response);
        return true;

      } else {
        this.toastrService.error(response['ResultMessage'], '회원로그인');
        this.authService.logout();
        this.router.navigate(
          ['/login'],
          {
            queryParams: {},
            relativeTo: this.activatedRoute,
            queryParamsHandling: '', // remove to replace all query params by provided
          }
        );
        return false;
      }
    })
    .catch(response => {
      return false;
    });

    return result;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(next, state);
  }
}