<form [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-group-add">POS {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <colgroup>
      <col style="width:25%" />
      <col style="width:75%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="store_name" class="control-label">매장명</label></th>
      <td>
        <select name="store_id" formControlName="store_id" class="form-control form-control-small" [ngClass]="{formErrors: formErrors['store_id'] != ''}">
          <ng-container *ngFor="let item of storeList">
            <option [value]="item.seq">{{item.store_name}}</option>
          </ng-container>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="pos_num" class="control-label">포스번호</label></th>
      <td>
        <inputEx type="text" name="pos_num" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="pos_name" class="control-label">포스명</label></th>
      <td>
        <inputEx type="text" name="pos_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="use_yn" class="control-label">사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.use_yn.value==true}" (click)="form.patchValue({use_yn:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.use_yn.value==false}" (click)="form.patchValue({use_yn:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>

    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="form.controls.pos_id.value !== ''" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="button" class="btn btn-primary btn-small mr10 f15" (click)="submit()">저장하기</button>
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
