<form [formGroup]="formAdd">
  <input type="hidden" id="seq" formControlName="seq" />

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">도선료정보 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="hidden">도선료정보 추가/수정</caption>

    <colgroup>
      <col style="width:30%;" />
      <col style="width:70%;" />
    </colgroup>

    <tbody>
      <tr>
        <th scope="row"><i class="material-icons cred f11">star</i> <label for="zipcode" class="control-label">현우편주소</label></th>
        <td><inputEx type="text" name="zipcode" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11">star</i> <label for="address" class="control-label">도로명주소</label></th>
        <td><inputEx type="text" name="address" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="bank_account" class="control-label">도선료</label></th>
        <td><inputEx type="text" name="add_delivery_amt" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="account_owner" class="control-label">시도</label></th>
        <td><inputEx type="text" name="sido" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="account_owner" class="control-label">시군구</label></th>
        <td><inputEx type="text" name="sigungu" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="account_owner" class="control-label">읍면동</label></th>
        <td><inputEx type="text" name="eupmyeondong" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="item.seq!==0" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="button" class="btn btn-primary btn-small mr10 f15" (click)="submit()">저장하기</button>
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>