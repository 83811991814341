import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { PushService } from '@app/service/push.service';

import { ToastrService } from 'ngx-toastr';

//import { MemberFindComponent } from '@page/member/member-find/member-find.component';

@Component({
  selector: 'app-modal-push',
  templateUrl: './modal-push.component.html',
  styleUrls: ['./modal-push.component.scss']
})
export class ModalPushComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 발송 휴대폰 리스트
  public sendList: any;
  public contents: any;

  public autoList: any = [];

  public form: FormGroup;
  public formErrors = {};

  // 그리드 관련 선언
  selectedRows = 0;
  selectedRowsMember = 0;

  pushList: any = [];
  memberList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  gridApiMember: any;
  gridColumnApiMember: any;
  columnDefsMember: any;
  rowSelectionMember: any;

  public MAXIMUM = 1000; // 문자 동시 발송 최대치

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      contents: [ '', [Validators.required, Validators.maxLength(80)] ],
      title: [ '', [Validators.required, Validators.maxLength(100)] ],
      receipt_tel: [ '', [Validators.required] ],
      link: [ '', [Validators.maxLength(255)]],
      query_params: [ '', [Validators.maxLength(255)]],
      isSendAll: [ '0', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private pushService: PushService,
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefsMember = [
      {
        headerName: '', field: 'mem_no', cellClass: 'cp right', width: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {headerName: '회원', field: 'name', cellClass: 'cp', width: 70, flex: 1 },
      {headerName: '휴대폰', field: 'hp', cellClass: 'cp', width: 100, flex: 1 },
      {headerName: 'PUSH 토큰', field: 'fcm_token', cellClass: 'cp', width: 80, flex: 1 },
      // {headerName: '회사명', field: 'companyName', cellClass: 'cp', width: 80 },
      // {headerName: '구분', field: 'memberType', cellClass: 'cp', width: 80 },
      // {headerName: '만료일', field: 'eDay', hide: true },
    ];

    this.columnDefs = [
      { headerName: '번호', field: 'seq', cellClass: 'cp center', width: 85,
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
      },
      {headerName: '자동발송', field: 'common_name', cellClass: 'cp', width: 90 },
      {headerName: '제목', field: 'title', cellClass: 'cp', width: 100 },
      {headerName: '발송내용', field: 'contents', cellClass: 'cp', width: 150 },
      {headerName: '자동발송 코드', field: 'auto_seq', hide: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true
    };

    this.rowSelectionMember = 'multiple';
    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }


  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // SMS 발송 양식 가져오기
    this.getPushFormList();

    setTimeout(() => {
      this.getPushFormList().then(() => {
        const tmp: any = [];
        this.sendList.forEach( row => {
          if( row.fcm_token !== null ) {
            tmp.push({
              mem_no: row.mem_no,
              name: row.name,
              hp: row.hp,
              fcm_token: row.fcm_token
            });
          }
        });

        this.memberList = tmp;

        this.form.patchValue({
          receipt_tel: this.memberList
        });
      });
    });

    if( this.sendList.length < 1 ) {
      this.toastrService.error( 'PUSH를 발송할 회원이 없습니다.', 'PUSH발송' );
      this.activeModal.close();

      return false;
    }

    if( this.contents != undefined ) {
      this.form.patchValue({
        contents: this.contents
      });
    }
  }

  /*******************************************************************************
    설  명 : PUSH 발송양식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPushFormList() {
    return this.pushService.getPushFormList({searchText: ''}).then( response => {
      if ( response.ResultCode ) {
        this.pushList = response.data;
      } else {
        this.toastrService.error( response.resultMessage );
      }
    } );
  }

  /*******************************************************************************
    설  명 : 한글 포함 문자열 갯수 처리
    입력값 : 문자열
    리턴값 : 문자수
  *******************************************************************************/
  getTextLength(str) {
    let len = 0;

    for (let i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length === 6) {
            len++;
        }
        len++;
    }
    return len;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.form.patchValue({
      title: $event.data.title,
      contents: $event.data.contents
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyMember(params) {
    this.gridApiMember = params.api;
    this.gridColumnApiMember = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedMember($event) {
    let filteredRowCount = 0;
    this.gridApiMember.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRowsMember = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 선택된 발송 회원 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteMember(auto = false) {
    const nodes: any = this.gridApiMember.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.error('삭제할 휴대폰 번호를 선택하세요.');

      return false;
    }

    if ( !auto && ! confirm('선택하신 휴대폰 번호를 삭제하시겠습니까?') ) {
      return false;
    }

    const removedRows: any = [];

    for (let node of nodes ) {
      removedRows.push( node );
      this.gridApiMember.updateRowData({remove: [node]});

      const idx = this.sendList.indexOf(node);
      if (idx > -1) {
        this.sendList.splice(idx, 1);
      }
    }
  }

  /*******************************************************************************
    설  명 : PUSH 발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    submit() {
      this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
  
      const params = {
        title: this.form.controls.title.value,
        message: this.form.controls.contents.value,
        members: this.gridApiMember.getSelectedRows(),
        link: this.form.controls.link.value,
        query_params: this.form.controls.query_params.value,
        isSendAll: this.form.controls.isSendAll.value
      };
  
      if( this.form.valid ) {
        this.pushService.sendPushMessage( params ).then( response => {
          if( response.resultCode ) {
            this.toastrService.success(response.resultMessage, 'Push 발송');
  
          } else {
            this.toastrService.error('Push 메시지를 발송하는데 실패하였습니다.', 'Push 발송');
          }
        });
      } else {
        this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      }
    }
  }