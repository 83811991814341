<form [formGroup]="form">
  <header class="mt10 mb5">
    <div class="fl w50p">
      <h4 class="fb mb0">휴면회원 설정</h4>
    </div>
    <div class="fr w50p right">
      <button type="submit" class="btn btn-primary btn-small" (click)="submit()">저장</button>
    </div>
    <div class="cb"></div>
  </header>  
  
  <section>
    <table class="table table-bordered table-small1 mb0">
    <caption class="none">휴면회원 설정</caption>
    <colgroup>
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="member_dormant_useyn">휴면회원 사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_dormant_useyn.value=='1'}" (click)="form.get('member_dormant_useyn').setValue('1')" >사용</button>
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_dormant_useyn.value=='0'}" (click)="form.get('member_dormant_useyn').setValue('0')">사용안함</button>
        </div>
        <inputEx type="hidden" name="member_dormant_useyn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="member_expire_send">만료시 발송 방법</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_expire_send.value==''}" (click)="form.get('member_expire_send').setValue('')" >발송안함</button>
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_expire_send.value=='EMAIL'}" (click)="form.get('member_expire_send').setValue('EMAIL')">이메일</button>
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_expire_send.value=='SMS'}" (click)="form.get('member_expire_send').setValue('SMS')">SMS</button>
        </div>

        <inputEx type="hidden" name="member_expire_send" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="member_expire_day">휴면회원 만료기간(일)</label></th>
      <td><inputEx type="number" name="member_expire_day" [formGroup]="form" [formErrors]="formErrors" maxlength="4"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="member_dormant_expect_useyn">휴면회원 예정 사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_dormant_expect_useyn.value=='1'}" (click)="form.get('member_dormant_expect_useyn').setValue('1')" >사용</button>
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_dormant_expect_useyn.value=='0'}" (click)="form.get('member_dormant_expect_useyn').setValue('0')">사용안함</button>
        </div>
        <inputEx type="hidden" name="member_dormant_expect_useyn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="member_expire_schedule_send">만료 예정 시 발송 방법</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_expire_schedule_send.value==''}" (click)="form.get('member_expire_schedule_send').setValue('')" >발송안함</button>
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_expire_schedule_send.value=='EMAIL'}" (click)="form.get('member_expire_schedule_send').setValue('EMAIL')" >이메일</button>
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.member_expire_schedule_send.value=='SMS'}" (click)="form.get('member_expire_schedule_send').setValue('SMS')" >SMS</button>
        </div>

        <inputEx type="hidden" name="member_expire_send" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="member_expire_schedule_day">휴면회원 만료예정 기간(일)</label></th>
      <td><inputEx type="text" name="member_expire_schedule_day" [formGroup]="form" [formErrors]="formErrors" maxlength="4"></inputEx></td>
    </tr>
    </tbody>
    </table>
  </section>

  <header class="mt10 mb5">
    <h4 class="fb mb0">회원 등급(혜택제) 설정</h4>
  </header>  

  <section>
    <table class="table table-bordered table-small1 mb0">
      <caption class="none">회원 등급 설정</caption>
      <colgroup>
        <col style="width:10%" />
        <col style="width:23%" />
        <col style="width:10%" />
        <col style="width:23%" />
        <col style="width:10%" />
        <col style="width:23%" />
      </colgroup>
  
      <tbody>
      <tr>
        <th scope="row"><label for="member_level_max">회원 등급(최대)</label></th>
        <td>
          <inputEx type="number" name="member_level_max" [formGroup]="form" [formErrors]="formErrors" max="99"></inputEx>
        </td>
        <th></th>
        <td></td>
        <th></th>
        <td></td>
      </tr>
      </tbody>
    </table>
  </section>

  <header class="mt10 mb5">
    <h4 class="fb mb0">NEW 회원 등급(혜택제) 설정</h4>
  </header>  

  <section>
    <div style="height: 220px;">
      <ag-grid-angular
        #myGrid
    
        style="width: 100%;height:100%;"
        class="ag-theme-balham"
    
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="memberGradeList"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [pagination]="false"
        [domLayout]="domLayout"
        [suppressRowTransform]="true"
        [stopEditingWhenGridLosesFocus]="true"   
        [frameworkComponents]="frameworkComponents"
        [components]="components"
        [singleClickEdit]="true"
        
        (gridReady)="onGridReady($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
      >
      </ag-grid-angular>
    </div>
  </section>
</form>
