/*******************************************************************************
  설  명 : 회원정보 - 마일리지 사용내역
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbInputDatepicker, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';
import { OrderService } from '@app/service/order.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ManagerMileageComponent } from '@page/member/member-info/member-mileage/manager-mileage/manager-mileage.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-mileage',
  templateUrl: './member-mileage.component.html',
  styleUrls: ['./member-mileage.component.scss']
})
export class MemberMileageComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() dormant: any;
  @Input() orderSeq: any;

  public orderInfo: any = {};

  public search: any = {
    memberNo: '',
    sdate: '',
    edate: '',
    mileage: '' , 
    order_seq: '' , 
  };

  public mileage: any = {
    remined: 0,
    used: 0,
    available: 0
  };

  // 그리드 관련 선언
  public memberMileageList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService,
    private orderService: OrderService,
    private modalService: NgbModal,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '구분', field: 'type', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.type == '1000' ) {
            return '<span class="badge badge-success f12">' + params.data.type_name + '</span>';
          } else {
            return '<span class="badge badge-warning f12">' + params.data.type_name + '</span>';
          }
        }
      },
      { headerName: '매장', field: 'store_name', width: 90, cellClass: 'cp center' },
      { headerName: '주문번호', field: 'master_seq', width: 90, cellClass: 'cp center',
        valueGetter: function(params) {
          return ( parseInt(params.data.master_seq) !== 0 && params.data.master_seq !== null ) ? params.data.master_seq : '';
        }
      },
      { headerName: '주문일시', field: 'order_date', width: 150, cellClass: 'cp center' },
      { headerName: '구매(사용)금액', field: 'order_amt', width: 130, cellClass: 'cp right', valueFormatter: currencyFormatter,
        valueGetter: function(params) {
          return ( parseInt(params.data.order_amt) > 0 ) ? params.data.order_amt : '';
        }
      },
      { headerName: '적립/사용', field: 'gbn', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.gbn == '1000' ) {
            return '<span class="badge badge-primary f12">적립</span>';
          } else if( params.data.gbn == '2000' ) {
            return '<span class="badge badge-danger f12 red">사용</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: '적립마일리지', field: 'mileage', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter,
        valueGetter: function(params) {
          return ( params.data.gbn == '1000' ) ? params.data.mileage : '';
        }
      },
      { headerName: '사용마일리지', field: 'mileage', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter,
        valueGetter: function(params) {
          return ( params.data.gbn == '2000' ) ? params.data.mileage : '';
        }
      },
      { headerName: '주문정보', field: 'product_info', width: 450, cellClass: 'cp left', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.type == '2000' ) {
            return params.data.reason;
          } else {
            if( params.data.product_info == undefined ) {
              return '';
            } else {
              return params.data.product_info;
            }
          }
        }
      },
      { headerName: '등록자', field: 'writer_name', width: 100, cellClass: 'cp center' },
      { headerName: '등록일시', field: 'write_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.search.memberNo = this.memberNo;

    this.getMemberMileageList();
    this.getSumRemainedMileage();

    if( this.orderSeq ) this.getOrderDetail();
  }

  /*******************************************************************************
    설  명 : 마일리지 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberMileageList() {
    this.memberService.getMemberMileageList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.memberMileageList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 마이페이지 대시보드 남은 마일리지 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSumRemainedMileage() {
    this.memberService.getSumRemainedMileage( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.mileage = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderDetail() {
    this.orderService.getOrderDetail( this.orderSeq ).then( response => {
      if( response.ResultCode ) {
        this.orderInfo = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      memberNo: this.memberNo,
      sdate: '',
      edate: '',
      mileage: ''
    };

    this.getMemberMileageList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberMileageList();
    }
  }

  /*******************************************************************************
    설  명 : 관리자 마일리지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  mileageDelete() {
    const nodes: any = this.gridApi.getSelectedRows();

    const data: any = [];
    for(let node of nodes) {
      data.push( node );
    }

    if( nodes.length < 1 ) {
      this.toastrService.error( '삭제할 마일리지를 선택하세요', '' );
      return false;
    } else {
      if( confirm('선택한 마일리지를 삭제하시겠습니까?') ) {
        this.memberService.setMileageDelete( data ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '마일리지 삭제');
            this.getMemberMileageList();
            this.getSumRemainedMileage();
          } else {
            this.toastrService.error( response.ResultMessage, '마일리지 삭제');
          }
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 관리자 마일리지 적립
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  managerMileage() {
    const modalRef = this.modalService.open(ManagerMileageComponent, options);

    modalRef.componentInstance.memberNo = this.memberNo;
    modalRef.componentInstance.orderSeq = this.orderSeq;

    modalRef.result.then((result) => {
      this.getMemberMileageList();
      this.getSumRemainedMileage();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelOrder() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>${this.orderInfo.o_name} 마일리지 내역</strong></caption>
        <colgroup>
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:130px;" />
          <col style="width:100px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:700px;" />
          <col style="width:100px;" />
          <col style="width:130px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>구분</td>
          <td>주문번호</td>
          <td>주문일시</td>
          <td>구매(사용)금액</td>
          <td>적립/사용</td>
          <td>적립마일리지</td>
          <td>사용마일리지</td>
          <td>주문정보</td>
          <td>등록자</td>
          <td>등록일시</td>
        </tr>
    `]

    let data = this.memberMileageList;

    data.forEach(element => {

      let typeName = '';
      if ( element.type == '1000' ) {
        typeName = '<font style="color:#28a745">' + element.type_name + '</font>';
      } else {
        typeName = '<font style="color:#ffc107">' + element.type_name + '</font>';
      }

      let gbnName = '';
      if ( element.gbn == '1000' ) {
        gbnName = '<font style="color:#007bff">적립</font>';
      } else {
        gbnName = '<font style="color:#dc3545">사용</font>';
      }

      let productInfo = element.product_info;
      productInfo = ( productInfo !== null ) ? productInfo.replace(/<br>/g,'<br style="mso-data-placement:same-cell;">') : '';
      productInfo = ( productInfo !== null ) ? productInfo.replace(/<br\/>/g,'<br style="mso-data-placement:same-cell;">') : '';
      productInfo = ( productInfo !== null ) ? productInfo.replace(/<BR>/g,'<br style="mso-data-placement:same-cell;">') : '';
      productInfo = ( productInfo !== null ) ? productInfo.replace(/<BR\/>/g,'<br style="mso-data-placement:same-cell;">') : '';

      contents.push(`
        <tr>
          <td style="text-align:center;">${typeName}</td>
          <td style="text-align:center;">${element.master_seq}</td>
          <td style="text-align:center;">${element.order_date}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';"">${element.order_amt > 0 ? element.order_amt : 0}</td>
          <td style="text-align:center;">${gbnName}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.gbn == '1000' ? element.mileage : ''}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.gbn == '2000' ? element.mileage : ''}</td>
          <td style="text-align:center;">${productInfo}</td>
          <td style="text-align:center;">${element.writer_name}</td>
          <td style="text-align:center;">${element.write_date}</td>
        </tr>
        </tbody>
      `)
    });
    contents.push(`</table>`);

    const fileName = `bikemart_mileage(${this.memberNo})_${moment().format('YYYYmmddHHmmss')}`;

    this.printExcel(fileName, contents.join(''));
  }

  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
  
}
