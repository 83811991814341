
      <form name="form" id="form" form-error-focus novalidate>
        <!-- Modal header -->
        <div class="modal-header">
          <h4 class="modal-title bold">수기결재 완료처리</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()"></button>
        </div>
        <!-- Modal header -->

        <!-- Modal body -->
        <div class="modal-body">

          <section id="print-contents">
            <!-- 타이틀 영역 //-->
              <div class="f15 bold fngex">
                결재일 입력
              </div>
  
            <div class="cb"></div>
  
            <!-- 내용부분 //-->
            <div id="print-contents-body">
              <table class="list-table table table-bordered ng-scope mt10">
              <colgroup>
                <col style="width:10%">
                <col style="width:30%">
                <col style="width:20%">
                <col style="width:40%">
              </colgroup>
              
              <tbody>
                <tr>
                  <th class="active" scope="row">결재구분</th>
                  <th class="active" scope="row">결재자</th>
                  <th class="active" scope="row">결재일</th>
                  <th class="active" scope="row">첨언</th>
                </tr>
              <tr *ngFor="let offList of approvalOff; let i = index;">
                <td class="center">
                  <!-- <span
                    class="f15 badge"
                    [ngClass]="{
                      'badge-primary': offList.approval_group === 'A',
                      'badge-success': offList.approval_group === 'G',
                      'badge-secondary': offList.approval_group === 'R'
                    }" >
                  {{offList.approval_group == 'A' ? '결재' : 
                  offList.approval_group == 'G' ? '협조' : ''
                  }}</span> -->
                </td>
                <td>
                  {{offList.dept_name}} {{offList.name}} 
                </td>
                <td>
                   <!-- <input type="text" [value]="offList.sign_date" [ngClass]="{readonly:readonly}" readonly="readonly" id="sdate" size="10" class="form-control form-control-small"  /> -->
                </td>
                <td>
                  <input type="text" [value]="approvalOff[i].add_note" [name]="approvalOff[i].mem_no" id="add_note" size="10" class="form-control form-control-small" (change)="onChange($event)" />
                </td>
              </tr>
              </tbody>
              </table>            
            
            </div>
            <div class="f15 bold fngex mb10">
              결재문서 스캔본 업로드
            </div>

            <div class="cb"></div>
            <div class="append-file">
              <label for="file" class="fl">첨부파일 :</label>
              <input
                [value]="form.value.file"
                type="file" name="file" formControlName="file" id="file" size="50" class="fl ml20"
                ng2FileSelect (change)="handleUploadFileChanged($event)"
              />
              <div class="cb"></div>
          
            </div>
            <div class="tl">
              <div class="mt5" style="font-size: 12px;" *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
                <ul class="lstn mb3 pl10 f12">
                  <li class="pt3" *ngFor="let file of form.controls.upload.value; let i = index">
                    {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                    <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                      <i class="material-icons micon-close f12">&#xe5cd;</i>
                    </button>
                  </li>
                  <li class="pt3" *ngFor="let file of form.controls.files.value; let i = index">
                    {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                    <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                      <i class="material-icons micon-close f12">&#xe5cd;</i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            
            <!-- 내용부분 //-->
  
          </section>


        </div>
        <!-- Modal body -->

        <!-- Modal footer -->
        <div class="modal-footer clearfix">
          <div class="fl w30p left">
          </div>
          <div class="fr w70p right">
            <button type="submit" class="btn btn-primary btn-small f15 mr5" (click)="submit()">완료처리</button>
            <button type="submit" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">닫기</button>
          </div>
        </div>
        <!-- Modal footer -->
      </form>

