import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { CompanyService } from '@app/service/company.service';
import { CommonService } from '@app/service/common.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-join-inquiry',
  templateUrl: './member-join-inquiry.component.html',
  styleUrls: ['./member-join-inquiry.component.scss']
})
export class MemberJoinInquiryComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
    type: '',
    associate_type: '',
    status: '',
    sdate: null,
    edate: null
  };

  public month: any = moment().format('MM');

  public typeList: any = [];
  public contactList: any = [];
  public contentList: any = [];
  public memoList: any = [];

  public contactSelected: any = {};

  public contactStatus: any = '';

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    associateCount: 0,
    enteredCount: 0
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridDetailApi: any;
  gridDetailColumnApi: any;
  columnDetailDefs: any;

  gridMemoApi: any;
  gridMemoColumnApi: any;
  columnMemoDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private companyService: CompanyService,
    private commonService: CommonService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', width: 68, cellClass: 'cp center' },
      { headerName: '문의구분', field: 'type', width: 95, cellClass: 'cp center', 
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.type == '1001' ) {
            return `<span class="badge badge-primary f12">${params.data.type_name}</span>`;
          } else {
            return `<span class="badge badge-success f12">${params.data.type_name}</span>`;
          }
        }
      },
      { headerName: '가맹구분', field: 'associate_type', width: 100, cellClass: 'cp center',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.associate_type == '1001' ) {
            return `<span class="badge cbpurple f12">협력점</span>`;
          } else if( params.data.associate_type == '2001' ) {
            return `<span class="badge badge-primary f12">프랜차이즈</span>`;
          } else {
            return '';
          }
        }
      },
      { headerName: '상태', field: 'status', width: 70, cellClass: 'cp center',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.status == '0' ) {
            return `<span class="badge badge-secondary f12">신청</span>`;
          } else if( params.data.status == '1' ) {
            return `<span class="badge badge-warning f12">협의중</span>`;
          } else if( params.data.status == '2' ) {
            return `<span class="badge badge-success f12">완료</span>`;
          } else {
            return '';
          }
        }
      },
      { headerName: '이름', field: 'name', width: 85, cellClass: 'cp left' },
      { headerName: '연락처', field: 'tel', width: 110, cellClass: 'cp left' },
      { headerName: '이메일', field: 'email', width: 175, cellClass: 'cp left' },
      { headerName: '창업희망지역', field: 'location', width: 170, cellClass: 'cp left' },
      { headerName: '예상창업일', field: 'openday', width: 150, cellClass: 'cp left cell-wrap-text', hide: true },
      { headerName: '예상창업비용', field: 'price', width: 120, cellClass: 'cp left cell-wrap-text', hide: true },
      { headerName: '작성일', field: 'write_date', width: 145, cellClass: 'cp center' }
    ];

    this.columnDetailDefs = [
      { headerName: '문의내용', field: 'content', width: 590, cellClass: 'cp left cell-wrap-text', cellRenderer: 'agGridHtmlComponent' },
    ];

    this.columnMemoDefs = [
      { headerName: '관리자메모 (최대 1000자, 더블클릭 = 수정모드, Enter Key 입력 = 자동 저장, 줄바꿈 = Shift + Enter Key)', field: 'memo', width: 590, cellClass: 'cp left ag-cell-edit cell-wrap-text', 
        autoHeight: true, 
        editable: true, 
        // singleClickEdit: true,
        // cellEditorPopup: true,
        cellEditor: 'agLargeTextCellEditor' ,
        cellEditorParams: function(params: any) {
          let memo = params.data.memo;
          return {
            cols: 90,
            rows: 20,
            maxLength: 1000,
            value : memo ? memo.replace(/<br>/g, '\n') : ''
          }
        },
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params: any) {
          let memo = params.data.memo;
          return memo ? memo.replace(/\n/g, '<br>') : '';
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true
    };

    this.rowSelection = 'single';
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  getRowHeight = () => 380;

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    this.getContact();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
  *******************************************************************************/
  getCommonList() {
    // 문의타입
    this.commonService.getCommonListCode('CTT').then( response => {
      if ( response.ResultCode ) {
        this.typeList = response.data;
      } else {
        this.typeList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 가맹문의 가져오기
  *******************************************************************************/
  getContact() {
    this.companyService.getContact(this.search).then( response => {
      if(response.ResultCode) {
        this.contactList = response.data;
        this.statistic = response.statistic;

        this.contentList = [];
        this.memoList = [];

        if( this.contactList.length > 0 ) {
          if( this.contactSelected.seq === undefined ) {
            let index = 0;
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter(function(node: any) {
                // select the node
                if( index++ == 0 ) {
                  node.setSelected(true);
                }
              });
            }, 0);

            this.contactSelected = this.contactList[0];

            this.contentList = [{
              content: this.contactList[0].content
            }];
            
            this.memoList = [{
              seq: this.contactList[0].seq,
              memo: this.contactList[0].memo
            }];

            this.contactStatus = this.contactList[0].status;
          } else {
            const selectedSeq = this.contactSelected.seq;
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter(function(node: any) {
                // select the node
                if( node.data.seq == selectedSeq ) {
                  node.setSelected(true);
                }
              });
            }, 0);

            this.contactSelected = this.contactList.filter((obj: any) => obj.seq === this.contactSelected.seq)[0];
            if( this.contactSelected.length > 0 ) {
              this.contentList = [{
                content: this.contactSelected.content
              }];
              
              this.memoList = [{
                seq: this.contactSelected.seq,
                memo: this.contactSelected.memo
              }];

              this.contactStatus = this.contactSelected.status;
            }
          }
        }
      } else {
        this.toastrService.error(response.ResultMessage || '가맹문의 가져오기 에러');
      }
    }).catch(e => this.toastrService.error(e));
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridDetailReady(params) {
    this.gridDetailApi = params.api;
    this.gridDetailColumnApi = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridMemoReady(params) {
    this.gridMemoApi = params.api;
    this.gridMemoColumnApi = params.columnApi;
  }
    
  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked(event: any) {
    this.contactSelected = event.data;

    this.contentList = [];
    this.memoList = [];

    this.contentList.push({
      content: event.data.content
    });
    
    this.memoList.push({
      seq: event.data.seq,
      memo: event.data.memo
    });

    this.contactStatus = event.data.status;
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      type: '',
      associate_type: '',
      status: '',
      sdate: null,
      edate: null,
    };

    this.getContact();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getContact();
    }
  }

  /*******************************************************************************
    설  명 : 검색 조건 버튼 클릭 시
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  setSearchValue( field: any, value: any ) {
    this.search[ field ] = value;

    this.contactSelected = {};

    this.getContact();
  }

  /*******************************************************************************
    설  명 : 버튼 클릭 시 상태값 변경 처리
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  setStatusChange( value: any ) {
    this.contactStatus = value;

    let params: any = {
      seq: this.contactSelected.seq,
      status: value
    };

    this.companyService.setContactStatus(params).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getContact();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  delContact() {
    if( this.contactSelected === undefined || this.contactSelected.seq === undefined ) {
      this.toastrService.error('삭제할 내역을 선택하시기 바랍니다.', '삭제');
    } else {
      
      if( !confirm("선택한 내역을 삭제하시겠습니까?") ) return;

      let params: any = {
        seq: this.contactSelected.seq
      };

      this.companyService.delContact(params).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.contactSelected = {};

          this.getContact();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }
  
  /*******************************************************************************
    설  명 : 셀 에디트 변경 시
  *******************************************************************************/
  onCellEditingStopped(event: any) {
    if( event.data.memo !== undefined ) {
      if( this.contactSelected.memo != event.data.memo ) {
        this.companyService.setContactMemo( event.data ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.getContact();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }
  
}
