<ng-container *ngIf="boardInfo.board_type == 'qna'">
  <section class="widget-div fl w50p">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">문의<span class="cnt">{{getComma(totalInfo.total)}}</span>건</p>
      </div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">완료<span class="cnt">{{getComma(totalInfo.complete)}}</span>건</p>
      </div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">미완료<span class="cnt">{{getComma(totalInfo.process)}}</span>건</p>
      </div>
    </div>
  </section>

  <div class="cb"></div>
</ng-container>

<section class="adata-list-area mt5">
  <section class="search-condition mb5 form-inline">
    <div class="fl w70p">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>

      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getBoardList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <div class="btn-group ml5 mr10">
        <button *ngIf="boardInfo.lvt_list == '1'" type="button" class="btn btn-default btn-small" [ngClass]="{active:params.type=='list'}" (click)="viewChange('list')">리스트</button>
        <button *ngIf="boardInfo.lvt_album == '1'" type="button" class="btn btn-default btn-small" [ngClass]="{active:params.type=='album'}" (click)="viewChange('album')">앨범</button>
        <button *ngIf="boardInfo.lvt_gallery == '1'" type="button" class="btn btn-default btn-small" [ngClass]="{active:params.type=='gallery'}" (click)="viewChange('gallery')">갤러리</button>
        <button *ngIf="boardInfo.lvt_calendar == '1'" type="button" class="btn btn-default btn-small" [ngClass]="{active:params.type=='calendar'}" (click)="viewChange('calendar')">달력</button>
      </div>

      <select [(ngModel)]="params.row" class="page-row form-control form-control-small mr10">
        <option *ngFor="let item of linecnt" [value]="item">{{item}}줄</option>
      </select>

      <select [(ngModel)]="params.year" class="page-row form-control form-control-small mr5" (change)="getBoardList()">
        <option value="">전체 연도</option>
        <option *ngFor="let item of yearList" [value]="item.year">{{item.year}} 년</option>
      </select>

      <ng-container *ngIf="boardInfo.board_type=='qna'">
        <div class="btn-group mr5">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status===''}" (click)="searchBtn('status','')">전체</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status===0}" (click)="searchBtn('status',0)">진행</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status===1}" (click)="searchBtn('status',1)">완료</button>
        </div>
      </ng-container>

      <select [(ngModel)]="params.group" class="page-row form-control form-control-small">
        <option value="subject" [selected]="params.group==='subject'">제목</option>
        <option value="contents" [selected]="params.group==='contents'">내용</option>
        <option *ngIf="boardInfo.board_type=='normal'" value="tag" [selected]="params.group==='tag'">태그</option>
        <option *ngIf="boardInfo.board_type=='qna'" value="id" [selected]="params.group==='id'">아이디</option>
        <option *ngIf="boardInfo.board_type=='qna'" value="name" [selected]="params.group==='name'">작성자</option>
      </select>

      <input type="text" [(ngModel)]="params.text" class="form-control form-control-small search-input ml5" (keyup)="searchService($event)" />

      <span class="search-cnt ml10 pt8">
        총 <span class="search-cnt">{{boardInfo.total_count}}</span> 개의 글
      </span>
      <ng-container *ngIf="boardInfo.is_approval > 0 ">
        <div class="btn-group mr5">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_approval===''}" (click)="searchBtn('is_approval','')">전체</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_approval==='1'}" (click)="searchBtn('is_approval','1')">승인</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_approval==='0'}" (click)="searchBtn('is_approval','0')">미승인</button>
        </div>
      </ng-container>


    </div>

    <div class="fr w30p right">
      <a *ngIf="authWriteCheck()" class="btn btn-default btn-small" (click)="write()">글쓰기</a>
    </div>

    <div class="cb"></div>
  </section>

  <!-- Group 질문 tab -->
  <section *ngIf="boardInfo.lvt_group=='1'" class="qna-tab">
    <div role="tabpnael">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item mr2" *ngFor="let item of groupTab">
          <a data-toggle="tab" class="nav-link cp" [ngClass]="{active:params.group_id==item}" (click)="loadList(item)">{{item}}</a>
        </li>
      </ul>
    </div>
  </section>
  <!-- Group tab -->

  <section class="data-list">
    <div *ngIf="params.type=='list'" class="list">
      <app-board-list [boardInfo]="boardInfo" [data]="boardList" [params]="params"></app-board-list>
    </div>
    <div *ngIf="params.type=='album'" class="album">
      <app-board-album [boardInfo]="boardInfo" [data]="boardList" [params]="params"></app-board-album>
    </div>
    <div *ngIf="params.type=='gallery'" class="gallery">
      <app-board-gallery [boardInfo]="boardInfo" [data]="boardList" [params]="params"></app-board-gallery>
    </div>
  </section>

  <section class="pagination mt30" *ngIf="totalCount">
    <ngb-pagination
      [(page)]="params.no"
      [pageSize]="params.row"
      [maxSize]="maxSize"
      [collectionSize]="totalCount"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>
</section>
