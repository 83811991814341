<section class="widget-div mt5">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">{{statistic.supersale_name}}</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">{{statistic.start_date}} 부터</p>
      <p class="txt4">{{statistic.end_date}} 까지</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">판매중인 상품수</p>
      <p class="txt4"><span class="cnt">{{statistic?.productCnt | Numeral: "0,0"}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">금액설정 대기중</p>
      <p class="txt4"><span class="cnt">{{statistic?.waitCnt | Numeral: "0,0"}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">금액설정 완료</p>
      <p class="txt4"><span class="cnt">{{statistic?.completeCnt | Numeral: "0,0"}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon5">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">진행률<span class="cnt">{{statistic?.rate | Numeral: "0.00"}}</span>%</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;height: calc(100% - 60px);">
  <as-split-area size="25" minSize="15" maxSize="35">
    <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="onTabChange($event)">
      <mat-tab style="min-width:100px;" label="카테고리(제품군)">
        <div class="mt5">
          <div class="fl w70p">
            <span class="">선택항목을 : </span>
            <div class="btn-group">
              <button type="button" [ngClass]="{active: params.category_status=='W'}" (click)="params.category_status = 'W'" class="btn btn-default f12">대기</button>
              <button type="button" [ngClass]="{active: params.category_status=='P'}" (click)="params.category_status = 'P'" class="btn btn-default f12">진행중</button>
              <button type="button" [ngClass]="{active: params.category_status=='C'}" (click)="params.category_status = 'C'" class="btn btn-default f12">완료</button>
            </div>
            <button type="button" class="btn btn-primary btn-small ml5" (click)="setSuperSaleCategorySave()">저장</button>
          </div>
          <div class="fr w30p right">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApiCategory" gridId="d559b8bc986443d7888620696b312e8b" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <div class="mt5" style="height:calc(100% - 50px)">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefsCategory"
            [defaultColDef]="defaultColDef"
            [rowData]="categoryList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"

            (gridReady)="onGridReadyCategory($event)"
            (rowClicked)="onCategoryGridRowClicked($event)"
            >
          </ag-grid-angular>
        </div>
      </mat-tab>
      <mat-tab style="min-width:100px;" label="브랜드">
        <div class="mt5">
          <div class="fl w70p">
            <span class="">선택항목을 : </span>
            <div class="btn-group">
              <button type="button" [ngClass]="{active: params.brand_status=='W'}" (click)="params.brand_status = 'W'" class="btn btn-default f12">대기</button>
              <button type="button" [ngClass]="{active: params.brand_status=='P'}" (click)="params.brand_status = 'P'" class="btn btn-default f12">진행중</button>
              <button type="button" [ngClass]="{active: params.brand_status=='C'}" (click)="params.brand_status = 'C'" class="btn btn-default f12">완료</button>
            </div>
            <button type="button" class="btn btn-primary btn-small ml5" (click)="setSuperSaleBrandSave()">저장</button>
          </div>
          <div class="fr w30p right">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApiBrand" gridId="56fb0b6199dd4cc392ea50e34cf25623" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <div class="mt5" style="height:calc(100% - 50px)">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefsBrand"
            [defaultColDef]="defaultColDef"
            [rowData]="brandList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"

            (gridReady)="onGridReadyBrand($event)"
            (rowClicked)="onBrandGridRowClicked($event)"
            >
          </ag-grid-angular>
        </div>
      </mat-tab>
    </mat-tab-group>
  </as-split-area>
  <as-split-area size="75" minSize="65" maxSize="85">
    <div class="right mt5">
      <div class="fl form-inline">
        <div class="btn-group">
          <button type="button" [ngClass]="{active: saleType.discount}" (click)="saleType.discount = true" class="btn btn-default btn-small">할인</button>
          <button type="button" [ngClass]="{active: !saleType.discount}" (click)="saleType.discount = false" class="btn btn-default btn-small">적립</button>
        </div>

        <div class="btn-group ml5">
          <button type="button" [ngClass]="{active: !saleType.rate}" (click)="saleType.rate = false" class="btn btn-default btn-small">금액</button>
          <button type="button" [ngClass]="{active: saleType.rate}" (click)="saleType.rate = true" class="btn btn-default btn-small">비율</button>
        </div>

        <input type="text" [(ngModel)]="saleType.value" (keypress)="numberOnly($event)" class="form-control form-control-small ml5" placeholder="금액 or 비율" />

        <button type="button" class="btn btn-success btn-small ml5" (click)="applyAll()" >일괄적용</button>
      </div>
      <div class="fr right">
        <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="8df2d28bbed34af5a69da44d5b5dcec9" [btnGroup]="1"></btn-ag-grid-save>
        <button type="button" class="btn btn-default btn-small ml5" (click)="goList()">목록으로</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setSuperSaleProductSave()" >상품가격 저장</button>
      </div>
      <div class="cb"></div>
    </div>

    <div class="mt5" style="height:calc(100% - 50px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="productList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [rowDragManaged]="true"
        [enableMultiRowDragging]="true"
        [animateRows]="true"
        [components]="components"
        [getRowHeight]="getRowHeight"

        (gridReady)="onGridReady($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        >
      </ag-grid-angular>
    </div>
  </as-split-area>
</as-split>
