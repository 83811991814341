<hr class="db mt5 mb5" />

<section class="contents-body" style="height:calc(100% - 20px);">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="18" minSize="5" maxSize="25">
      <div class="mb5">
        <div class="fl">
          <h4 class="f14 pt5 mb0 fb">실사계획</h4>
        </div>
        <div class="cb"></div>
        <div class="fr">
          <input type="text" id="quickFilter" (input)="onQuickFilterChanged($event)" class="form-control form-control-small fl" placeholder="제목 검색" style="width: calc(100% - 147px);"/>
          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="postSchedule()"><span>추가</span></button>
          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="putSchedule()"><span>수정</span></button>
          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="deleteSchedule()"><span>삭제</span></button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height: calc(100% - 70px);">
        <ag-grid-angular
          #myGrid
  
          style="width:100%; height:100%"
          class="ag-theme-balham"
  
          [columnDefs]="columnDefs_A"
          [defaultColDef]="defaultColDef"
          [rowData]="form.value.rowData_schedule"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
  
          (gridReady)="onGridReady_A($event)"
          (rowClicked) = "onRowClicked_A($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="82" minSize="50" maxSize="95">
      
      <div class="mb5">
        <!-- <div class="fl form-inline asub-title">
          <span class="pl10 pr5">품목 개수</span><input type="text" class="form-control form-control-small" readonly [value] = "statistic.cnt_product.toLocaleString()" />
        </div> -->
        <div class="fr">
          <!-- <button type="button" class="btn btn-default btn-small f12 ml5" (click)="setExcelOrder()"><span>엑셀다운</span></button> -->
          <btn-ag-grid-save class="mr5" [gridColumnsApi]="gridColumnApi_B" gridId="b1f3d906d1fc4d419a7a1720a3438035" [btnGroup]="1"></btn-ag-grid-save>
          <ag-grid-excel [gridApi]="gridApi_B" selected="false" filename="{{selectedSchedule.pi_title}} 재고실사" title="{{selectedSchedule.pi_title}} 재고실사"></ag-grid-excel> 
        </div>
        <div class="cb"></div>
      </div>

      <div style="height: calc(100% - 40px);">
        <ag-grid-angular
          #myGrid
  
          style="width:100%; height:100%"
          class="ag-theme-balham"
  
          [columnDefs]="columnDefs_B"
          [defaultColDef]="defaultColDef"
          [rowData]="form.value.rowData_excute"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
          [components] = "components"
  
          (gridReady)="onGridReady_B($event)"
          (cellClicked) = "cellClick_B($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>