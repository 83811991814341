<div class="modal-header pb10">
  <h4 class="modal-title fb" id="modal-common-code">상품 찾기</h4>
  <button type="button" class="close" aria-label="Close" (click)="setDismiss()">&times;</button>
</div>

<div class="modal-body p10 mb5">
  <div class="header">
    <div class="fl w50p pt5">
      <p *ngIf="rowData !== undefined" class="f14 ml5 fb">{{rowData.p_code}}<br/>{{rowData.model}}<br/>{{rowData.size_color}}<br/>{{rowData.display_color_name}} / {{rowData.display_size_name}}<br/>{{rowData.sale_price | number}} 원<br/>{{rowData.num | number}} 개</p>
      <span class="f12">전체 갯수 : {{getComma(productList.length)}} 건</span>
    </div>
    <div class="fr w50p right">
    </div>
    <div class="cb"></div>
  </div>

  <div class="h100">
    <div style="height: 100%;">

      <div class="form-inline mb5">
        <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
          <i class="material-icons-outlined">refresh</i>
        </button>

        <input type="text" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="50" class="form-control form-control-small ml5" placeholder="대표상품번호, 상품명, 기존상품코드 검색" />

        <input type="text" id="quickFilter" size="50" (input)="onQuickFilterChanged($event)" class="form-control form-control-small ml5" placeholder="그리드 내 모든 필드 검색 필터링" />
      </div>

      <ag-grid-angular
        #myGrid

        style="width: 50%; height: 500px; float: left;"
        class="ag-theme-alpine"

        rowSelection="single"
        [defaultColDef]="defaultColDef"
        [columnDefs]="leftColumnDefs"
        [rowData]="productList"
        [overlayNoRowsTemplate]="leftGridNoRowsTemplate"
        [suppressRowClickSelection]="false"
        [frameworkComponents]="frameworkComponents"

        (gridReady)="onLeftGridReady($event)"
        (rowClicked)="onRowClicked($event)"
        >
      </ag-grid-angular>
    </div>

    <div style="height: 100%;">
      <ag-grid-angular
        #myGrid

        style="width: 50%; height: 500px; float: left;"
        class="ag-theme-alpine"
      
        rowSelection="single"
        [defaultColDef]="defaultColDef"
        [columnDefs]="rightColumnDefs"
        [rowData]="propertyList"
        [overlayNoRowsTemplate]="rightGridNoRowsTemplate"
        [frameworkComponents]="frameworkComponents"

        (gridReady)="onRightGridReady($event)"
        >
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="modal-footer p10">
  <button type="button" class="btn btn-default btn-small f15 mr10" aria-label="Close" (click)="setDismiss()">창닫기</button>
  <button type="button" class="btn btn-primary btn-small f15" (click)="selectProduct()">추가하기</button>
</div>
