<section id="employee">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="20" minSize="20" maxSize="40">
      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">조직도</h4>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 40px);">
        <app-tree-view
          [useSort]='true'
          [nodeData]='nodeData'
          [useAddNode]='true'
          (onSelected)='onSelectNode($event)'
          (onAddNode)='onAddNode($event)'
        ></app-tree-view>
      </div>
    </as-split-area>

    <as-split-area size="80" minSize="60" maxSize="80">
      <ng-container *ngIf="isAddCheck===0">
        <div class="alert-message">
          사원이나 부서를 클릭하시기 바랍니다.
        </div>
      </ng-container>

      <ng-container *ngIf="isAddCheck!==0">
        <ng-container *ngIf="isAddCheck===1">
          <div class="alert-message1">
            <span class="cred">사원정보 수정</span>입니다. 신중하게 수정하시기 바랍니다.
          </div>
        </ng-container>

        <ng-container *ngIf="isAddCheck===2">
          <div class="alert-message1">
            <span class="cred">사원정보 추가</span>입니다.
          </div>
        </ng-container>

        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="">
            <div class="fl w50p">
              <h4 class="fb mt5 mb6">사용자 정보 - 선택 사용자</h4>
            </div>
            <div class="fr w50p right">
              <button *ngIf="form.controls.mem_no.value != ''" type="button" class="btn btn-danger btn-small ml5" (click)="deleteEmployee()">삭제</button>
              <button type="submit" class="btn btn-primary btn-small ml5">저장하기</button>
            </div>
            <div class="cb"></div>
          </div>

          <div style="height:calc(100% - 35px)">
            <div class="">
              <table class="table table-bordered table-small">
              <colgroup>
                <col style="width:15%" />
                <col style="width:35%" />
                <col style="width:15%" />
                <col style="width:35%" />
              </colgroup>

              <tbody>
              <tr>
                <th scope="row" class="active"><label for="picture">사원 이미지</label></th>
                <td colspan="3">
                  <div class="fl mr10" style="width:50px;">
                    <img *ngIf="form.controls.picture_url.value != null" [src]="form.controls.picture_url.value" style="width:40px;height:40px;border-radius:100%;" />
                    <img *ngIf="form.controls.picture_url.value == null" src="/assets/images/no_image.png" style="width:40px;height:40px;border-radius:100%;" />
                    <div *ngIf="form.controls.picture_url.value != '/assets/images/no_image.png' && form.controls.picture_url.value != null" class="mt5">
                      <button type="button" class="btn btn-danger btn-small" (click)="deleteImage();">삭제</button>
                    </div>
                  </div>

                  <div class="fl">
                    <input type="file" name="picture" id="picture" formControlName="picture" ng2FileSelect (change)="handleUploadFileChanged($event)" />

                    <input type="hidden" name="picture_url" id="picture_url" formControlName="picture_url" />
                    <input type="hidden" name="picture_origin" id="picture_origin" formControlName="picture_origin" />
                  </div>

                  <div class="cb"></div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="id">사원아이디</label></th>
                <td>
                  <inputEx type="text" name="id" [formGroup]="form" [formErrors]="formErrors" [addOn]="funcMemberFind" addOnText="회원검색"></inputEx>
                </td>
                <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="password">비밀번호</label></th>
                <td>
                  <inputEx type="password" name="password" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="name">사원명</label></th>
                <td>
                  <inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
                <th scope="row" class="active"><label for="email">이메일</label></th>
                <td>
                  <inputEx type="text" name="email" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="birthdate">생년월일</label></th>
                <td>
                  <inputEx type="date" name="birthdate" [formGroup]="form" [formErrors]="formErrors" placeholder="생년월일을 선택하세요"></inputEx>
                </td>
                <th scope="row" class="active"><label for="birth_sec">양력/음력</label></th>
                <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.birth_sec.value=='S'}" (click)="form.patchValue({birth_sec:'S'})">양력</button>
                    <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:form.controls.birth_sec.value=='M'}" (click)="form.patchValue({birth_sec:'M'})">음력</button>
                  </div>
                  <inputEx type="hidden" name="birth_sec" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="joindate">입사일</label></th>
                <td>
                  <inputEx type="date" name="joindate" [formGroup]="form" [formErrors]="formErrors" placeholder="입사일을 선택하세요"></inputEx>
                </td>
                <th scope="row" class="active"><label for="leavedate">퇴사일</label></th>
                <td>
                  <inputEx type="date" name="leavedate" [formGroup]="form" [formErrors]="formErrors" placeholder="퇴사일을 선택하세요"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="dept_seq">부서</label></th>
                <td>
                  <inputEx type="select" name="dept_seq" [formGroup]="form" [formErrors]="formErrors" [data]="groupList"></inputEx>
                </td>
                <th scope="row" class="active"><label for="position_seq">직위</label></th>
                <td>
                  <inputEx type="select" name="position_seq" [formGroup]="form" [formErrors]="formErrors" [data]="positionList"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="tel">전화</label></th>
                <td>
                  <inputEx type="text" name="tel" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
                <th scope="row" class="active"><label for="hp">휴대폰</label></th>
                <td>
                  <inputEx type="text" name="hp" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="extension_number">내선번호</label></th>
                <td>
                  <inputEx type="text" name="extension_number" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
                <th scope="row" class="active"><label for="emergency_tel">비상연락망</label></th>
                <td>
                  <inputEx type="text" name="emergency_tel" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="zipcode">우편번호</label></th>
                <td>
                  <div class="form-inline">
                    <inputEx type="text" name="zipcode" readonly="true" class="mr5" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                    <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
                  </div>
                </td>
                <th scope="row" class="active"><i class="material-icons cred f11">star</i> <label for="auth_group_seq">권한그룹</label></th>
                <td>
                  <inputEx type="select" name="auth_group_seq" [formGroup]="form" [formErrors]="formErrors" [data]="authGroupList"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="address">주소</label></th>
                <td>
                  <inputEx type="text" name="address" readonly="true" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
                <th scope="row" class="active"><label for="address_detail">상세주소</label></th>
                <td>
                  <inputEx type="text" name="address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              </tbody>
              </table>

            </div>
            <div class="cb"></div>
          </div>
        </form>
      </ng-container>
    </as-split-area>

  </as-split>
</section>
