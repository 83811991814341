import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { CommonService } from '@app/service/common.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bama-coupon-add',
  templateUrl: './bama-coupon-add.component.html',
  styleUrls: ['./bama-coupon-add.component.scss']
})
export class BamaCouponAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  bamaCouponInfo: any = {};  
  couponKindList: any = [];
  giveTypeList: any = [];
  saleTypeList: any = [];  

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
        seq: ['', [] ],
        coupon_name: ['', [Validators.required] ],
        coupon_kind: ['', [Validators.required] ],
        coupon_kind_name: ['', [] ],
        give_sdate: ['', [Validators.required] ],
        give_edate: ['', [Validators.required] ],
        valid_month: [1, [Validators.required] ],
        give_type: ['', [] ],
        give_type_name: ['', [] ],
        sale_type: ['', [] ],
        sale_type_name: ['', [] ],
        apply_type: ['RATE', [] ],
        apply_type_name: ['', [] ],
        apply_number: [0, [] ],
        use_yn: [1, [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private basicService: BasicService,
    private commonService: CommonService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList()
    this.addInit();
  }

  setDate(date?: Date) {
    return date || new Date();
  }


  /*******************************************************************************
    설  명 : 바마쿠폰 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    addInit() {
          this.form.patchValue( {
               coupon_name: '' , 
               coupon_kind: '' ,
               give_sdate:  this.utilService.getDate(moment().toDate()) , 
               give_edate: this.utilService.getDate(moment().toDate()) , 
               valid_month: 1 , 
               give_type: '1000' , 
               sale_type: '1000' , 
               apply_type: 'RATE' , 
               apply_number: 0 , 
               use_yn: 1 , 
          } );

    }      


  /*******************************************************************************
    설  명 : 창고 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBamaCouponSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setBamaCouponSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      wh_zipcode: data.zip,
      wh_address: data.addr
    });

    $("#wh_address_detail").focus();
  }

  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }
  /*******************************************************************************
    설  명 : 쿠폰 종류 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    changeCouponKind() {
      const couponKind = this.couponKindList.filter(item => item.value === this.form.value.coupon_kind);
  
      if( couponKind[0].value ) {
        this.form.patchValue({
          coupon_kind_name: couponKind[0].title,
        });
      }
    }

   /*******************************************************************************
    설  명 : 지급적용 방법 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    changeGiveType() {
      const giveType = this.giveTypeList.filter(item => item.value === this.form.value.give_type);
  
      if( giveType[0].value ) {
        this.form.patchValue({
          gice_type_name: giveType[0].title,
        });
      }
    }  

   /*******************************************************************************
    설  명 : 판매적용방법 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    changeSaleType() {
      const saleType = this.saleTypeList.filter(item => item.value === this.form.value.sale_type);
  
      if( saleType[0].value ) {
        this.form.patchValue({
          sale_type_name: saleType[0].title,
        });
      }
    }  


  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getCommonList() {
      // 쿠폰종류
      this.commonService.getCommonListCode('BCK').then( response => {
        if ( response.ResultCode ) {
          response.data.forEach( row => {
            this.couponKindList.push({
              value: row.common_code,
              title: row.common_name
            });
          });
        } else {
          this.couponKindList = [];
        }
      });

      // 지급적용구분
      this.commonService.getCommonListCode('BCV').then( response => {
        if ( response.ResultCode ) {
          response.data.forEach( row => {
            this.giveTypeList.push({
              value: row.common_code,
              title: row.common_name
            });
          });
        } else {
          this.giveTypeList = [];
        }
      });
  
      // 판매적용구분
      this.commonService.getCommonListCode('BCV').then( response => {
        if ( response.ResultCode ) {
          response.data.forEach( row => {
            this.saleTypeList.push({
              value: row.common_code,
              title: row.common_name
            });
          });
        } else {
          this.saleTypeList = [];
        }
      });
    }
  

}


