import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { EmployeeService } from '@app/service/employee.service';

import { MemberService } from '@app/service/member.service' ;
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  fromModel(value: string | null): NgbDateStruct | null {
    const date = value.split('-');
    return value ? { year: parseInt(date[0], 10), month: parseInt(date[1], 10), day: parseInt(date[2], 10) } : null;
  }

  toModel(value: NgbDateStruct | null): string | null {
    return value ? (value.year).toString()
      + '-' + (value.month).toString().padStart(2, '0')
      + '-' + (value.day).toString().padStart(2, '0') : null;
  }
}

@Component({
  selector: 'app-warehouse-charge-add',
  templateUrl: './warehouse-charge-add.component.html',
  styleUrls: ['./warehouse-charge-add.component.scss'],
  providers: [ {provide: NgbDateAdapter, useClass: CustomAdapter} ],
})
export class WarehouseChargeAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public whSeq: any;
  public seq: any;

  public locationInfo: any = {};
  public employeeList: any = [];

  public form: FormGroup;
  public formErrors = {};

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
     설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      whSeq: ['', [Validators.required] ],
      mem_no: ['', [Validators.required] ],
      name: ['', [Validators.required] ],
      position_name: ['', [] ],
      dept_seq: ['', [] ],
      dept_name: ['', [] ],
      isDefault: ['1', [Validators.required] ],
      start_date: ['', [Validators.required] ],
      end_date: ['', [Validators.required] ],
      memo: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
     설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private basicService: BasicService,
    private memberService: MemberService,
    private employeeService: EmployeeService,
    private toastrService: ToastrService,

  ) {
    this.buildForm();
  }

  /*******************************************************************************
     설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {

    this.form.patchValue({
      seq: this.seq,
      whSeq: this.whSeq,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    });

    if ( this.seq !== 0 ) {
      this.getWarehouseChargeDetail();
    }

    this.employeeService.getEmployeeList().then( response => {
      if ( response.ResultCode ) {
        this.employeeList = response.data;
      }
    });
  }

  today(): string {
    return moment().format('YYYY-MM-DD');
  }

  searchMember($event) {
    this.getMemberFindInfo($event.target.value);
  }

  getWarehouseChargeDetail() {
    this.basicService.getWarehouseChargeDetail(this.seq).then( response => {
      if ( response.ResultCode ) {
        this.form.patchValue({
          mem_no : response.data.mem_no,
          name : response.data.name,
          dept_seq : response.data.dept_seq ?? '',
          dept_name : response.data.dept_name ?? '',
          isDefault: response.data.isDefault,
          start_date: response.data.start_date,
          end_date: response.data.end_date,
          memo: response.data.memo,
        });
      }
    });
  }

  getMemberFindInfo(member: string) {
    this.memberService.getMemberFindInfo( member ).then( response => {
      if ( response.ResultCode ) {
        this.form.patchValue({
          mem_no : response.data.mem_no,
          name : response.data.name,
          dept_seq : response.data.dept_seq ?? '',
          dept_name : response.data.dept_name ?? ''
        });
      } else {
        this.form.patchValue({
          mem_no : '',
          name : '',
          dept_seq : '',
          dept_name : ''
        });
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
     설  명 : 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseChargeSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setWarehouseChargeSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
     설  명 : 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseChargeDelete() {
    if ( confirm('담당자를 삭제하시겠습니까?') ) {
      const param = [{seq: this.seq, isDefault: this.form.value.isDefault}];

      this.basicService.setWarehouseChargeDelete( param ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
}
