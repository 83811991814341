<div class="modal-header">
  <h4 class="modal-title fb mb0">항목 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <div class="">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">항목정보</h4>
    </div>
    <div class="fr w50p right">

    </div>
    <div class="cb"></div>
  </div>

  <form [formGroup]="form">

    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <table class="table table-bordered table-small1 mb0">
      <caption class="none">항목정보</caption>
      <colgroup>
        <col style="width:15%;" />
        <col style="width:35%;" />
        <col style="width:15%;" />
        <col style="width:35%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="account_type">계정구분</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.account_type.value=='1000'}" (click)="form.get('account_type').setValue('1000')">입금계정</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.account_type.value=='2000'}" (click)="form.get('account_type').setValue('2000')">출금계정</button>
          </div>
          <inputEx type="hidden" name="account_type" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="account_name">계정명</label></th>
        <td>
          <inputEx type="text" name="account_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          <div *ngIf="form.controls.account_name.errors && form.controls.account_name.errors['blank']" class="error-message">공백이 포함되어 있습니다.</div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="use_yn">사용여부</label></th>
        <td colspan="3">
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='1'}" (click)="form.get('use_yn').setValue('1')">사용함</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='0'}" (click)="form.get('use_yn').setValue('0')">사용안함</button>
          </div>
          <inputEx type="hidden" name="use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
    </form>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">

    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="submit()">저장하기</button>
      <button type="button" class="btn btn-danger btn-small ml5" *ngIf="form.controls.seq.value != ''" (click)="delete()">삭제하기</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
    </div>
  </div>
