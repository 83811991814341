  <div class="modal-header">
    <h4 class="modal-title fb mb0">상사 신청정보</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <form [formGroup]="form">
    <inputEx type="hidden" name="shop_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
  
    <table class="table table-bordered table-small1 mb80">
    <caption class="none">상사정보</caption>
    <colgroup>
      <col style="width:12%;" />
      <col style="width:38%;" />
      <col style="width:12;" />
      <col style="width:38%;" />
    </colgroup>
  
    <tbody>
    <tr>
      <th scope="row"><label for="shop_name"><i class="material-icons cred f11 lh20">star</i> 상사명</label></th>
      <td><inputEx type="text" name="shop_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="license"><i class="material-icons cred f11 lh20">star</i> 사업자번호</label></th>
      <td><inputEx type="text" name="license" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="shop_homepage">홈페이지주소</label></th>
      <td colspan="3"><inputEx type="text" name="shop_homepage" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="shop_phone">상사전화</label></th>
      <td><inputEx type="text" name="shop_phone" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="shop_hp">상사휴대폰</label></th>
      <td><inputEx type="text" name="shop_hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="shop_email">상사이메일</label></th>
      <td><inputEx type="text" name="shop_email" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="shop_fax">상사팩스</label></th>
      <td><inputEx type="text" name="shop_fax" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="shop_zipcode">우편번호</label></th>
      <td>
        <div class="input-group">
          <inputEx name="shop_zipcode" class="mr3" style="width:60%" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
          <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
        </div>
      </td>
      <th scope="row"><label for="shop_address">주소</label></th>
      <td><inputEx type="text" name="shop_address" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="shop_address_detail">상세주소</label></th>
      <td colspan="3"><inputEx type="text" name="shop_address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="bank">은행</label></th>
      <td><inputEx type="text" name="bank" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="depositor">예금주</label></th>
      <td><inputEx type="text" name="depositor" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="account">계좌번호</label></th>
      <td><inputEx type="text" name="account" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th colspan="2"></th>
    </tr>
  
    </tbody>
    </table>
  
    </form>

  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="data.proc_status=='1000'" type="button" class="btn btn-danger btn-small" (click)="setMemberTransitionApplicationRefuse()">거절하기</button>
    </div>
    <div class="fr w50p right">
      <button *ngIf="data.proc_status=='1000'" type="button" class="btn btn-primary btn-small mr5" (click)="setMemberTransitionApplicationApprove()">승인하기</button>
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
  </div>
