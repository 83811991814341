
<form [formGroup]="form" (ngSubmit)="setBikePayHelperCalcEdit()">
  <div class="modal-header">
    <h4 class="modal-title fb mb0">수수료 수정</h4>
    <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small1 mb0">
      <caption class="none">수수료 정보</caption>
      <colgroup>
        <col style="width:30%;" />
        <col style="width:70%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="cash_fee"><i class="material-icons cred f11">star</i> 현금수수료</label></th>
        <td>
          <inputEx type="numeric2" name="cash_fee" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="card_fee"><i class="material-icons cred f11">star</i> 카드수수료</label></th>
        <td>
          <inputEx type="numeric2" name="card_fee" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangePrice($event)"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="total_fee"><i class="material-icons cred f11">star</i> 수수료합계</label></th>
        <td>
          <inputEx type="numeric2" name="total_fee" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="calculate_price"><i class="material-icons cred f11">star</i> 정산금액</label></th>
        <td>
          <inputEx type="numeric2" name="calculate_price" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
    </div>
    <div class="fr w50p right">
      <button type="submit" class="btn btn-primary btn-small">저장하기</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
    </div>
  </div>
</form>