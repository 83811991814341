import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { EventService } from '@app/service/event.service';

import { MemberFindComponent } from '@components/member-find/member-find.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-coupon-add',
  templateUrl: './coupon-add.component.html',
  styleUrls: ['./coupon-add.component.scss']
})
export class CouponAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() data: any;

  public title: any;

  public couponTypeList: any = [];

  public form: FormGroup;
  public formErrors = {};

  public addMemberFunc: any;  // 회원검색

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      coupon_type: ['1000', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')] ],
      coupon_code: ['', [Validators.minLength(1), Validators.maxLength(20), Validators.pattern('^[0-9a-zA-Z]*$')] ],
      coupon_count: ['0', [] ],
      mem_no: ['', [Validators.required] ],
      name: ['', [Validators.required] ],
      id: ['', [Validators.required] ],
      use_yn: ['1', [Validators.required] ],
      start_date: [null, [Validators.required] ],
      end_date: [null, [Validators.required] ],
    });

    // Set up conditional validation
    this.setupConditionalValidation();    

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 배민쿠폰과 일반 쿠폰에 따라 적용값 체크 추가
  *******************************************************************************/

  setupConditionalValidation(): void {
    this.form.get('coupon_type').valueChanges.subscribe((value) => {
      const couponCodeControl = this.form.get('coupon_code');
      const couponCountControl = this.form.get('coupon_count');

      if (value === '4000') {
        couponCodeControl.clearValidators();
        couponCodeControl.updateValueAndValidity();

        couponCountControl.setValidators([Validators.required, Validators.min(1)]);
        couponCountControl.updateValueAndValidity();
      } else {
        couponCodeControl.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(20), Validators.pattern('^[0-9a-zA-Z]*$')]);
        couponCodeControl.updateValueAndValidity();

        couponCountControl.setValidators([Validators.required, Validators.min(0), Validators.max(0)]);
        couponCountControl.updateValueAndValidity();
      }
    });

    // Trigger the validation setup on initialization in case the initial value of coupon_type requires it
    this.form.get('coupon_type').updateValueAndValidity();
  }
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private eventService: EventService
  ) {
    this.buildForm();

    this.addMemberFunc = this.searchMember.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    if( this.data === undefined || this.data === null ) {
      this.title = '등록';
    } else {
      this.title = '수정';

      if( this.data.start_date !== null && this.data.start_date == '0000-00-00 00:00:00' )
        this.data.start_date = null;

      if( this.data.end_date !== null && this.data.end_date == '0000-00-00 00:00:00' ) 
        this.data.end_date = null;

      this.form.patchValue( this.data );
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.commonService.getCommonListCode('CPT').then( response => {
      if ( response.ResultCode ) {
        this.couponTypeList = response.data;
      } else {
        this.couponTypeList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        mem_no: result.data.mem_no,
        id: result.data.id,
        name: result.data.name
      });

    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 쿠폰 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEventCouponSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.eventService.setEventCouponSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, `쿠폰 ${this.title}`);
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, `쿠폰 ${this.title}`);
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', `쿠폰 ${this.title}`);
    }
  }
  
  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

}
