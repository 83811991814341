import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutoFocusDirective implements AfterContentInit {

  @Input() autofocus: any;

  public constructor(private el: ElementRef) {
  }

  public ngAfterContentInit() {
    if( this.autofocus ) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 500);
    }
  }

}
