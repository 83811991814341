import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component'
import { StockOut } from '@app/service/stockOut.service';

@Component({
  selector: 'app-modal-delivery',
  templateUrl: './modal-delivery.component.html',
  styleUrls: ['./modal-delivery.component.scss']
})
export class ModalDeliveryComponent implements OnInit {

  @Input() mng_out_seq
  @Input() order_master_seq
  @Input() mng_out_order_seq
  @Input() mng_out_delivery_seq
  public form: FormGroup
  public formErrors = {}
  // public date = parseInt(moment().format('YYYY-MM-DD'));

  delivery_company_list = []

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private stockOrderComponent: StockOrderComponent,
    private stockOut: StockOut,
  ) { }

  ngOnInit(): void {
    this.buildForm()
    this.getDeliveryCompany()
  }

  buildForm(): void {
    const _date = new Date()
    this.form = this.formBuilder.group({
      mng_out_seq : [this.mng_out_seq, [Validators.required] ],
      consign_date : [{year: _date.getFullYear(), month: (_date.getMonth() + 1), day: _date.getDate()}, [Validators.required] ],
      delivery_company : ['', [Validators.required] ],
      delivery_no : ['', [Validators.required] ]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    })
  }

  /*******************************************************************************
    설  명 : 배송처리
  *******************************************************************************/
  submit() {
    if(!confirm('입점 출고지시를 출고완료 상태로 변경합니다. \r\n실행하시겠습니까?')) return

    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if(this.form.valid) {
      this.stockOut.setEnterDelivery({
        mem_no : this.stockOrderComponent.getUser(),
        consign_date : this.form.value['consign_date'],
        delivery_company : this.form.value['delivery_company'],
        delivery_no : this.form.value['delivery_no'],
        mng_out_seq : this.mng_out_seq,
        order_master_seq : this.order_master_seq,
        mng_out_order_seq : this.mng_out_order_seq,
        mng_out_delivery_seq : this.mng_out_delivery_seq
      }).then(response => {
        if(response.ResultCode) {
          this.toastrService.success(response.ResultMessage)
          this.activeModal.close(this.form.value)
        } else this.toastrService.error(response.ResultMessage)
      })
    } else this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.')
  }

  /*******************************************************************************
    설  명 : 택배사 가져오기
  *******************************************************************************/
  getDeliveryCompany() {
    this.stockOut.getDeliveryCompany().then(response => {
      if(response.ResultCode) {
        this.delivery_company_list = response.data.map(e => ({
          title : e['common_name'],
          value : e['common_code']
        }))
      } else this.toastrService.error(response.ResultMessage)
    })
  }

}

