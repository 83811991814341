<div class="modal-header">
  <h4 *ngIf="seq!=0" class="modal-title fb mb0">위치 상세정보</h4>
  <h4 *ngIf="seq==0" class="modal-title fb mb0">위치 추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    <inputEx type="hidden" name="whSeq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">위치정보</caption>
    <colgroup>
      <col style="width:10%;" />
      <col style="width:23%;" />
      <col style="width:10%;" />
      <col style="width:23%;" />
      <col style="width:10%;" />
      <col style="width:23%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="location_name">위치명</label></th>
      <td colspan="5"><inputEx type="text" name="location_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="location1">위치1</label></th>
      <td><inputEx type="text" name="location1" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="location2">위치2</label></th>
      <td><inputEx type="text" name="location2" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="location3">위치3</label></th>
      <td><inputEx type="text" name="location3" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="etc">메모</label></th>
      <td colspan="5"><inputEx type="textarea" name="location_memo" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    </tbody>
    </table>

  </form>

</div>

<div class="modal-footer">
  <div class="fl w50p">
    <button *ngIf="seq!=0" type="button" class="btn btn-danger btn-small" (click)="setWarehouseLocationDelete()">삭제하기</button>
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="setWarehouseLocationSave()">저장하기</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
  </div>
</div>
