<section id="member-address">
  <div class="mb5">
    <div class="fl w50p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberAddressList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="주소, 상세주소" />
    </div>
    <div class="fr w50p right">
      <ng-container *ngIf="dormant===false">
        <button type="button" class="btn btn-primary btn-small mr5" (click)="addMemberAddress(0)">주소추가</button>
        <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="90ad640eb123451291ea96beb52bc132" [btnGroup]="1"></btn-ag-grid-save>
      </ng-container>
    </div>
    <div class="cb"></div>
  </div>

  <div class="" style="height:calc(100% - 37px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="memberAddressList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
