<table class="list-table">
<colgroup>
  <!-- <col style="width:10%" />
  <col *ngIf="boardInfo.board_type!=='qna'" style="width:50%" />
  <col *ngIf="boardInfo.board_type=='qna'" style="width:10%" />
  <col *ngIf="boardInfo.board_type=='qna'" style="width:40%" />
  <col style="width:15%" />
  <col style="width:15%" />
  <col style="width:10%" /> -->
  <col style="width:5%" />
  <col *ngIf="boardInfo.board_type!=='qna'" style="width:50%" />
  <col *ngIf="boardInfo.board_type=='qna'" style="width:10%" />
  <col *ngIf="boardInfo.board_type=='qna'" style="width:40%" />
  <col style="width:15%" />
  <col style="width:15%" />
  <col style="width:5%" />
  <col style="width:10%" />
 </colgroup>

<thead>
<tr>
   <th scope="col">번호</th>
   <th *ngIf="boardInfo.board_type=='qna'" scope="col">진행사항</th>
   <th scope="col">제목</th>
   <th scope="col">작성자</th>
   <th scope="col">등록일</th>
   <th scope="col">조회수</th>
   <th scope="col">마지막으로내가본날짜</th>
</tr>
</thead>

<tbody>
<tr *ngFor="let row of data">
  <td class="cp" (click)="view(row)">
    <span *ngIf="row.is_notice==true" class="fb text-primary">[공지]</span>
    <span *ngIf="row.is_notice==false">{{row.board_seq}}</span>
  </td>
  <td *ngIf="boardInfo.board_type=='qna'">
    <span class="badge badge-success" *ngIf="row.status == false">진행</span>
    <span class="badge badge-primary" *ngIf="row.status == true">완료</span>
  </td>
  <td>
    <div class="tl form-inline cp" (click)="view(row)">
      <i *ngIf="row.is_pw=='1'" class="material-icons">lock</i>
      <span *ngIf="row.depth=='1' && (row.board_id=='question' || row.board_id=='as')">└&nbsp;</span>
      <span *ngIf="row.depth=='2' && (row.board_id=='question' || row.board_id=='as')">　└&nbsp;</span>
      <span *ngIf="row.depth=='3' && (row.board_id=='question' || row.board_id=='as')">　　└&nbsp;</span>
      <span *ngIf="row.depth=='4' && (row.board_id=='question' || row.board_id=='as')">　　　└&nbsp;</span>
      <span *ngIf="row.depth=='5' && (row.board_id=='question' || row.board_id=='as')">　　　　└&nbsp;</span>
      <a class="fl title" [ngClass]="{fb:row.is_notice==true, 'text-primary':row.is_notice==true}" [innerHTML]="row.subject" [style]="row.last_read_date ? {color: 'red'} : {}"></a>
      <i *ngIf="row.uploadCount > 0" class="material-icons file-ico fl">&#xe226;</i>
      <span *ngIf="row.new_check_date <= boardInfo.new_date" class="ico-firstfiber_new new-ico"></span>
    </div>
  </td>
  <td>{{row.writer_name}}({{row.writer_id}})</td>
  <td>{{row.write_date}}</td>
  <td>{{row.hit}}</td>
  <td>{{row.last_read_date}}</td>
</tr>
<tr *ngIf="data.length < 1">
  <td colspan="5" class="center">검색된 내용이 없습니다.</td>
</tr>
</tbody>
</table>
