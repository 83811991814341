<form [formGroup]="formLoss">
  <input type="hidden" id="seq" formControlName="seq" />

  <div class="modal-header">
    <h4 class="modal-title fb mb0">폐기/분실</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small">
    <caption class="hidden">폐기/분실</caption>

    <colgroup>
      <col style="width:35%;" />
      <col style="width:65%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="inout_gbn" class="control-label">입출고구분</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12">폐기출고</button>
          <button type="button" class="btn btn-default btn-small f12">분실출고</button>
        </div>
        <inputEx type="hidden" name="inout_gbn" [formGroup]="formLoss" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="sp_seq" class="control-label">상품</label></th>
      <td>
        <inputEx type="text" name="product_name" [formGroup]="formLoss" [formErrors]="formErrors" readonly="true" [addOn]="addProductFunc" addOnText="상품검색"></inputEx>

        <div class="mt5">
          ※ 상품을 선택해야 출고 창고를 선택할 수 있습니다.
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="warehouse_seq_out" class="control-label">출고창고</label></th>
      <td>
        <inputEx type="select" name="warehouse_seq_out" [formGroup]="formLoss" [formErrors]="formErrors" [data]="out_warehouse"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="inout_date" class="control-label">처리일자</label></th>
      <td>
        <inputEx type="date" name="inout_date" [formGroup]="formLoss" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="max_qty" class="control-label">폐기/분실 가능수량</label></th>
      <td>
        <inputEx type="number" name="max_qty" [formGroup]="formLoss" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="input_qty" class="control-label">수량</label></th>
      <td>
        <inputEx type="number" name="input_qty" [formGroup]="formLoss" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="memo" class="control-label">메모</label></th>
      <td colspan="3">
        <textarea formControlName="memo" class="form-control form-control-small"></textarea>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button type="button" class="btn btn-danger btn-small">삭제</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small">저장</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
    </div>
  </div>
</form>