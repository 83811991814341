<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체<span class="cnt">{{statistic?.total | number}}</span>개</p>
      <!-- <p class="txt1" *ngIf="params.searchText != ''">검색<span class="cnt">{{params.totalCount | number}}</span>개</p> -->
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">판매중<span class="cnt">{{statistic?.sales | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">대기중<span class="cnt">{{statistic?.standby | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">품절<span class="cnt">{{statistic?.soldout | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<div class="">
  <div class="fl w70p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit();">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductMasterList()">
      <i class="material-icons-outlined">search</i>
    </button> -->

    <select name="searchField" id="searchField" [(ngModel)]="params.searchField" class="form-control form-control-small ml5">
      <option value="seq">상품번호</option>
      <option value="product_name">상품명</option>
      <option value="brand_name">브랜드</option>
      <option value="category_name">카테고리</option>
      <option value="customer_name">매입업체명</option>
      <option value="provider_name">공급업체명</option>
      <option value="search_keyword">검색키</option>
      <option value="comment">설명</option>
      <option value="name">등록자</option>
    </select>

    <input type="text" [(ngModel)]="params.searchText" size="50" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="검색어를 입력하세요" />

    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5 f12" (click)="getProductMasterList()">검색</button>

    <l-select2
      [data]="customerList"
      [(ngModel)]="params.customer_seq"
      class="form-control form-control-small ml5"
      [ngModelOptions]="{standalone: true}"
      [options]="{multiple: false}"
      (ngModelChange)="changedCustomerSeq($event)"
      style="width: 300px; padding:0px !important; border:solid 0px #fff !important;"
    ></l-select2>

    <select [(ngModel)]="params.category_code" class="form-control form-control-small ml5" (change)="getProductMasterList()">
      <option value="">카테고리 선택</option>
      <option *ngFor="let item of categoryList" [value]="item.category_code">{{item.category_nm}}</option>
    </select>
  </div>
  <div class="fr w30p right">
    <button type="button" class="btn btn-success btn-small ml5" (click)="goProductMargin()">상품마진 관리</button>
    <!-- <button type="button" class="btn btn-success btn-small ml5" (click)="goLink('/product/property')">상품색상 및 사이즈 관리</button> -->
    <button type="button" class="btn btn-success btn-small ml5" (click)="goProductSalesStatus()">판매현황</button>
    <ng-container *ngIf="authService.currAuth['auth_modify']=='1'">
      <button type="button" class="btn btn-success btn-small ml5" (click)="goProductSoldOut()">품절체크</button>
    </ng-container>
  </div>
  <div class="cb"></div>

  <div class="fl form-inline mt5">
    <select name="pageRow" [(ngModel)]="params.pageRow" class="form-control form-control-small" (change)="getProductMasterList()">
      <option value="10">10줄</option>
      <option value="20">20줄</option>
      <option value="50">50줄</option>
      <option value="100">100줄</option>
      <option value="200">200줄</option>
      <option value="500">500줄</option>
    </select>

    <input type="text" size="13" readonly="readonly" id="sdate" [(ngModel)]="params.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
    <ng-template #footerTemplate1>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
    </ng-template>
    <span class="pr5 pl5">~</span>
    <input type="text" size="13" readonly="readonly" id="edate" [(ngModel)]="params.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
    <ng-template #footerTemplate2>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
    </ng-template>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.safe_stock == ''}" (click)="searchParams('safe_stock', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.safe_stock == '1'}" (click)="searchParams('safe_stock', '1')">안전재고위험</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == ''}" (click)="searchParams('is_display', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == '1'}" (click)="searchParams('is_display', '1')">표시</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == '0'}" (click)="searchParams('is_display', '0')">미표시</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == ''}" (click)="searchParams('approval_yn', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == '1'}" (click)="searchParams('approval_yn', '1')">승인</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == '0'}" (click)="searchParams('approval_yn', '0')">미승인</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == ''}" (click)="searchParams('status', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '0'}" (click)="searchParams('status', '0')">판매중</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '1'}" (click)="searchParams('status', '1')">임시품절</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '2'}" (click)="searchParams('status', '2')">영구품절</button>
    </div>

  </div>
  <div class="fr right mt5">
    <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi" gridId="e2a0dd7f58bf474381b8db02938b4108" [btnGroup]="1"></btn-ag-grid-save>

    <ng-container *ngIf="authService.currAuth['auth_excel']=='1'">
      <ag-grid-excel [gridApi]="gridApi" selected="true" filename="대표상품리스트" title="대표상품리스트"></ag-grid-excel>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="대표상품리스트" title="대표상품리스트"></ag-grid-excel>
    </ng-container>

    <ng-container *ngIf="authService.currAuth['auth_delete']=='1'">
      <button type="button" class="btn btn-danger btn-small ml5" (click)="deleteProductList()">상품삭제</button>
    </ng-container>

    <ng-container *ngIf="authService.currAuth['auth_write']=='1'">
      <button type="button" class="btn btn-primary btn-small ml5" (click)="productAdd(0)">상품등록</button>
    </ng-container>
  </div>
  <div class="cb"></div>
</div>

<div class="mt5" style="height:calc(100% - 170px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="productList"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [getRowHeight]="getRowHeight"
    [pagination]="false"
    [rowMultiSelectWithClick]="true"

    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)"
    >
  </ag-grid-angular>
  <section class="pagination mt10" *ngIf="params.totalCount">
    <ngb-pagination
      [(page)]="params.pageNo"
      [pageSize]="params.pageRow"
      [collectionSize]="params.totalCount"
      [maxSize]="20"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>
</div>
