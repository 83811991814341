import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mat-menu-trigger',
  templateUrl: './mat-menu-trigger.component.html',
  styleUrls: ['./mat-menu-trigger.component.scss']
})
export class MatMenuTriggerComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() menuData: any;

  private enteredButton: boolean = false;
  private isMatMenuOpen: boolean = false;
  private isMatMenu2Open: boolean = false;
  private prevButtonTrigger: any;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,    
    private ren: Renderer2
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 메뉴 마우스 엔터 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  /*******************************************************************************
    설  명 : 메뉴 마우스 아웃 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');

      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  /*******************************************************************************
    설  명 : 메뉴 마우스 엔터 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  menu2enter() {
    this.isMatMenu2Open = true;
  }

  /*******************************************************************************
    설  명 : 메뉴 마우스 아웃 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  menu2Leave(trigger1, trigger2, button) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');

      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100)
  }

  /*******************************************************************************
    설  명 : 메뉴 버튼 마우스 엔터 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buttonEnter(trigger) {
    setTimeout(() => {
      if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu()
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    }, 0)
  }

  /*******************************************************************************
    설  명 : 메뉴 버튼 마우스 아웃 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 50);
  }

  /*******************************************************************************
    설  명 : 나만의 메뉴 클릭시 링크 이동
    입력값 : url
    리턴값 : 없음
  *******************************************************************************/
  onMenuClick( url: any ) {
    this.router.navigate(
      [url],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }
}
