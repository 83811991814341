<div class="mt10 mb5">
    
    <div class="fl form-inline asub-title">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click) = "reset()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click) = "getStockOrder()">
        <i class="material-icons-outlined">search</i>
      </button> -->

      <div class="btn-group ml5">
        <button *ngFor = "let item of ['전체','매입','입점','직발']; index as idx" type="button" class="btn btn-default btn-small" [ngClass] = "{active: form.controls.buyType.value == idx}" (click) = "btnClick('buyType', idx)">{{item}}</button>
      </div>
      <div class="btn-group ml5">
        <button *ngFor = "let item of ['전체','출고대기','출고완료']; index as idx" type="button" class="btn btn-default btn-small" [ngClass] = "{active: form.controls.statusType.value == idx}" (click) = "btnClick('statusType', idx)">{{item}}</button>
      </div>
      <div class="input-group ml5">
        <input type="text" size="40" class="form-control form-control-small" placeholder="출고번호, 주문번호, 주문자, 수신자, 상품명" [(ngModel)] = "form.value.search" (keypress)="keypress($event)" />
        <div class="input-group-append">
          <button type="button" class="btn btn-default btn-small f12" (click) = "softReset()">검색</button>
        </div>
      </div>

      <input type="text" size="13" readonly="readonly" [value]="form.value.s_date" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate('s_date', $event)" />
      <span class="pl5 pr5">~</span>
      <input type="text" size="13" readonly="readonly" [value]="form.value.e_date" class="form-control form-control-small calendar-input " placeholder="검색 종료일" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect) = "selectDate('e_date', $event)" />

      <div class="btn-group ml10">
        <button *ngFor = "let obj of [
          {idx: 0, item: '오늘'}, {idx: 1, item: '어제'}, {idx: 3, item: '3일'}, {idx: 7, item: '7일'}, {idx: 15, item: '15일'}, {idx: 30, item: '30일'}, {idx: 90, item: '90일'}, {idx: 180, item: '180일'}
        ]" type="button" class="btn btn-default btn-small" [ngClass] = "{active: form.controls.date_btn.value == obj.idx}" (click) = "quickDate(obj.idx)">{{obj.item}}</button>
      </div>
    </div>
    
  <div class="fr right">
    <button type="button" class="btn btn-danger btn-small mr5" (click)="setCancelDelivery()">출고취소(입점)</button>
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="3f2373c085474d2d970c590ef154bc53" [btnGroup]="1"></btn-ag-grid-save>
    <!-- <ag-grid-excel [gridApi]="gridApi" selected="true" filename="출고지시서" title="출고지시서"></ag-grid-excel> -->
    <ag-grid-excel [gridApi]="gridApi" selected="false" filename="출고지시서" title="출고지시서"></ag-grid-excel>
    <!-- <button type="button" class="btn btn-success btn-small ml5">결재상신</button> -->
    <!-- <button type="button" class="btn btn-primary btn-small ml5">출고지시서 출력</button> -->
  </div>
  <div class="cb"></div>
  
  <!-- <div class="fl fb pt5">
    출고리스트
  </div>
  <div class="cb"></div> -->
</div>

<div style="height: calc(100% - 50px);">
<div style="height: calc(50% - 30px);">
  <ag-grid-angular
    #myGrid

    style="width:100%;height: 100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="form.value.stockOutOrderList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)"
    (cellClicked)="cellClick($event)"
    >
  </ag-grid-angular>
</div>

<div style="margin-top: 5px; overflow: hidden;">
  <div style="float: right;">
    <btn-ag-grid-save class="" [gridColumnsApi]="gridColumnApi" gridId="8415f69897544c6ea64f87751a8a305a" [btnGroup]="1"></btn-ag-grid-save>
    <ag-grid-excel [gridApi]="gridApiDetail" selected="true" filename="출고지시서" title="출고지시서 상세리스트"></ag-grid-excel>
    <ag-grid-excel [gridApi]="gridApiDetail" selected="false" filename="출고지시서" title="출고지시서 상세리스트"></ag-grid-excel>
  </div>
</div>

<div class="mt5" style="height: calc(50% - 5px);">
  <ag-grid-angular
    #myGrid

    style="width:100%;height: 100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefsDetail"
    [defaultColDef]="defaultColDef"
    [rowData]="form.value.stockOutOrderDetailList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (gridReady)="onGridReadyDetail($event)"
    (cellClicked) = "cellClick_detail($event)"
    >
  </ag-grid-angular>
</div>
</div>