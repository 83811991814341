/*******************************************************************************
  설  명 : 거래처관리 - 회원정보
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { MemberService } from '@app/service/member.service';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-customer-tab-member',
  templateUrl: './customer-tab-member.component.html',
  styleUrls: ['./customer-tab-member.component.scss']
})
export class CustomerTabMemberComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public form2: FormGroup;
  public formErrors2 = {};

  public seq: any;
  public memberInfo: any = {};

  private dateModel: NgbDateStruct;

  @Input() set customerSeq(value: number) {
    this.seq = value;

    if( typeof this.seq != 'undefined' ) {
      this.getCustomerMemberInfo();
    }
  }

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  // 그리드 관련 선언
  memberMemoList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      mem_no: ['', [] ],
      level: ['1', [Validators.required] ],
      grade: ['0001', [Validators.required] ],
      name: ['', [Validators.required] ],
      id: ['', [Validators.required] ],
      password: ['', [Validators.required] ],
      birthdate: [null, [Validators.required] ],
      birth_sec: ['S', [] ],
      email: ['', [Validators.required] ],
      email_yn: ['1', [] ],
      email_reject_date: [null, [] ],
      gender: ['', [] ],
      hp: ['', [Validators.required] ],
      sms_yn: ['1', [] ],
      sms_reject_date: [null, [] ],
      zipcode: ['', [] ],
      address: ['', [] ],
      address_detail: ['', [] ],
      shop_name: ['', [] ],
      shop_tel: ['', [] ],
      shop_fax: ['', [] ],
      homepage: ['', [] ],
      business_no: ['', [] ],
      bank_account: ['', [] ],
      write_date: ['', [] ],
      write_ip: ['', [] ],
      last_logindate: ['', [] ],
      buyclass: ['', [] ],
      member_group: ['', [] ],
      mileage: ['', [] ],
      total_buy: ['', [] ],
      buy_sum: ['', [] ],
      margin_sum: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm2(): void {
    this.form2 = this.formBuilder.group({
      memberNo: ['', []],
      message: ['', [Validators.required] ],
    });

    this.form2.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form2, this.formErrors2 );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private basicService: BasicService,
    private memberService: MemberService
  ) {
    this.buildForm();
    this.buildForm2();

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '문의내용', field: 'message', width: 350, cellClass: 'cp' },
      { headerName: '작성자', field: 'name', width: 100, cellClass: 'cp center' },
      { headerName: '작성일시', field: 'write_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 거래처 회원정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCustomerMemberInfo() {
    this.basicService.getCustomerMemberInfo( this.seq ).then( response => {
      if( response.ResultCode ) {
        this.memberInfo = response.data;

        if( this.memberInfo !== null ) {
          // 생년월일
          if( this.memberInfo.birthdate != '' && this.memberInfo.birthdate != null ) {
            if( typeof this.memberInfo.birthdate != 'object' ) {
              this.dateModel = {
                year: parseInt( this.memberInfo.birthdate.substr( 0, 4 ) ),
                month: parseInt( this.memberInfo.birthdate.substr( 5, 2 ) ),
                day: parseInt( this.memberInfo.birthdate.substr( 8, 2 ) )
              };

              this.memberInfo.birthdate = this.dateModel;
            }
          } else {
            this.memberInfo.birthdate = null;
          }

          this.form.patchValue( this.memberInfo );
          this.form2.patchValue({ memberNo: this.memberInfo.mem_no });

          this.getMemberMemoList();

        } else {
          this.toastrService.error( '해당 아이디에 대한 회원정보가 존재하지 않습니다.', '' );
        }
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 상담내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberMemoList() {
    this.memberService.getMemberMemoList({ memberNo: this.memberInfo.mem_no }).then( response => {
      if( response.ResultCode ) {
        this.memberMemoList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 거래처 회원정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCustomerMemberInfoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setCustomerMemberInfoSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 메모 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberMemoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form2, this.formErrors2);

    if (this.form2.valid) {
      this.memberService.setMemberMemoSave(this.form2).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.form2.reset();
          this.getMemberMemoList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

}
