<div class="modal-header">
  <h4 class="modal-title fb mb0">나만의 메뉴</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <section class="list-area">
    <div class="fl" style="height:500px; width: 50%;">
      <h4 class="fb mt5 mb6">메뉴</h4>
      <div class="treeview" id="{{id[0]}}">
        <ng-template #nodeTemplate let-node>
          <div class="menu-tree" [ngClass]="{active:selectedNode.page_cd==node.page_cd}"
            id="node{{node.key}}"
            draggable="true"
            (click)="onSelectNode(node)"
            (dragstart)="onDragStart($event, id[0], node)"
            (dragover)="onDragOver($event)"
            (dragend)="onDragEnd($event)"
            (drop)="onDrop($event, id[0], node)">

            <ng-container *ngIf="node.key.length > 3">
              <ng-container *ngFor="let item of getTreeMiddleLine(node); index as i">
                <span style="padding-left: 24px;"></span>
              </ng-container>
            </ng-container>

            <span *ngIf="checkChildren(node)" pioneer-tree-collapse [node]="node" class="tree-icon">
              <i class="material-icons-outlined" [innerText]="this.node.pioneerTreeNode.isCollapsed() ? 'add_box' : 'indeterminate_check_box'"></i>
            </span>

            <span class="tree-page-name">
              {{node.name}}
            </span>
          </div>
        </ng-template>

        <ng-template #repeaterTemplate let-node>
          <ul pioneer-tree-repeater [nodes]="node.children">
            <li pioneer-tree-node *ngFor="let node of node.children"
              [nodeTemplate]="nodeTemplate"
              [repeaterTemplate]="repeaterTemplate"
              [node]="node">
            </li>
          </ul>
        </ng-template>
        <ul pioneer-tree #pt [configuration]="configuration" [nodes]="nodeData">
          <li *ngFor="let node of nodeData" pioneer-tree-node
            [nodeTemplate]="nodeTemplate"
            [repeaterTemplate]="repeaterTemplate"
            [node]="node">
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="list-area">
    <div class="fl" style="height:500px; width: 50%;">
      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">나만의 메뉴</h4>
        </div>
        <div class="fr w50p right">
          <button type="button" class="btn btn-primary btn-small" (click)="favoriteMenuGroup()">메뉴그룹관리</button>
        </div>
        <div class="cb"></div>
      </div>
      <div class="treeview" id="{{id[1]}}">
        <ng-template #nodeTemplateMenu let-node>
          <div class="menu-tree" [ngClass]="{active:selectedMenuNode.seq==node.seq}"
            id="menu{{node.key}}"
            draggable="true"
            (click)="onSelectMenuNode(node)"
            (dragstart)="onDragStart($event, id[1], node)"
            (dragover)="onDragOver($event)"
            (dragend)="onDragEnd($event)"
            (drop)="onDrop($event, id[1], node)">

            <ng-container *ngIf="node.key.length > 3">
              <ng-container *ngFor="let item of getTreeMiddleLine(node); index as i">
                <span style="padding-left: 24px;"></span>
              </ng-container>
            </ng-container>

            <span *ngIf="checkChildren(node)" pioneer-tree-collapse [node]="node" class="tree-icon">
              <i class="material-icons-outlined" [innerText]="this.node.pioneerTreeNode.isCollapsed() ? 'add_box' : 'indeterminate_check_box'"></i>
            </span>

            <span class="tree-page-name">
              {{node.name}}
            </span>
          </div>
        </ng-template>

        <ng-template #repeaterTemplateMenu let-node>
          <ul pioneer-tree-repeater [nodes]="node.children">
            <li pioneer-tree-node *ngFor="let node of node.children"
              [nodeTemplate]="nodeTemplateMenu"
              [repeaterTemplate]="repeaterTemplateMenu"
              [node]="node">
            </li>
          </ul>
        </ng-template>
        <ul pioneer-tree #ptMenu [configuration]="configurationMenu" [nodes]="menuData">
          <li *ngFor="let node of menuData" pioneer-tree-node
            [nodeTemplate]="nodeTemplateMenu"
            [repeaterTemplate]="repeaterTemplateMenu"
            [node]="node"
            >
          </li>
        </ul>
      </div>
    </div>
  </section>

</div>

<div class="modal-footer" style="clear: both;">
  <div class="fl w50p">

  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="setFavoriteMenu()">저장하기</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
  </div>
</div>
