import { Component, OnInit } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';

import { BarcodeInputComponent } from '@components/barcode-input/barcode-input.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-ag-grid-button',
  templateUrl: './ag-grid-button.component.html',
  styleUrls: ['./ag-grid-button.component.scss']
})
export class AgGridButtonComponent implements OnInit {

  public params: any;

  public baseURL = config.baseUrl;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
  }

  /*******************************************************************************
    설  명 : 바코드 입력 모달창
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openModalBarcode() {
    let rowNode = this.params.node;

    // if( rowNode.data.barcode_yn != '1' ) {
    //   this.toastrService.error( "바코드 상품만 입력이 가능합니다.", '바코드 입력');
    //   return false;
    // } else {
      const modalRef = this.modalService.open(BarcodeInputComponent, optionsLG);

      modalRef.componentInstance.product_seq = rowNode.data.product_seq;
      modalRef.componentInstance.property_seq = rowNode.data.property_seq;
      modalRef.componentInstance.barcode = rowNode.data.barcode;

      modalRef.result.then((result) => {
        if( typeof result !== 'undefined' ) {

          //let barcodeLines: any = barcode.replace(/\s/gi, "").split('\n');
          const barcodeLines: any = result.barcode.split('\n');

          // let lineCount: number = 0;
          // barcodeLines.forEach(line => {
          //   if( line != '' ) lineCount++;
          // });

          rowNode.data.barcode = result.barcode;

          rowNode.setData( rowNode.data );
        }
      }, (reason) => {
      });
    // }
  }

  /*******************************************************************************
    설  명 : 브랜드가 세나인지 체크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkBrandSena( brand: any ) {
    if( brand && ( brand == '세나' || brand.indexOf('SENA') > -1 || brand.indexOf('CHATTERBOX') > -1 ) ) return true;
    else return false;
  }

  /*******************************************************************************
    설  명 : 배송정보 배열 변환
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setArray( value: any, glue: any ) {
    return value.split( glue );
  }

  /*******************************************************************************
    설  명 : 배송정보 줄바꿈 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSplit( value: any, glue: any, index: any ) {
    return value.split(glue)[index];
  }
}