<section class="mb5">
  <div class="fl form-inline">
   <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
     <i class="material-icons-outlined">refresh</i>
   </button>
   <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
     <i class="material-icons-outlined">search</i>
   </button>     
    <div class="ml5">조회수 : <span class="search-cnt">{{rowData.length}}</span> 개</div>
  </div>
  
  <div class="fr right">
    <button type="button" class="btn btn-primary btn-small" (click)="addLine(0)">추가</button>
    <button type="button" class="btn btn-danger btn-small ml5" (click)="deleteRow()">삭제</button>
  </div>    
  
  <div class="cb"></div>  
</section>

<div class="mt5" style="height:calc(100vh - 130px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [pagination]="false"
    [rowDragManaged]="true"
    [animateRows]="true"
    [enableMultiRowDragging]="true"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    
    (gridReady)="onGridReady($event)"
    (cellDoubleClicked)="modifyRow()"
    >
  </ag-grid-angular>
</div>
