/*******************************************************************************
  설  명 : 회원혜택제
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { MemberService } from '@app/service/member.service';
import { CommonService } from '@app/service/common.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { MemberInfoComponent } from '@page/member/member-info/member-info.component';

const pageRow: number = 100;

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-benefit',
  templateUrl: './member-benefit.component.html',
  styleUrls: ['./member-benefit.component.scss']
})
export class MemberBenefitComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: pageRow,
    grade: '',
    state: '',
    searchField: 'c.id',
    searchText: ''
  };

  public totalCount: any = 0;
  public searchCount: any = 0;
  public updateDate: any;

  // 그리드 관련 선언
  memberBenefitList: any = [];
  gradeList: any = [];
  stateList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private memberService: MemberService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순위', field: 'rowIndex', width: 100, cellClass: 'cp',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
        valueGetter: function(params) {
          return ( ( params.data.pageNo - 1 ) * params.data.pageRow ) + params.node.rowIndex + 1;
        }
      },
      { headerName: '회원구분', field: 'grade_name', width: 100, cellClass: 'cp left' },
      { headerName: '구매등급', field: 'buyclass', width: 100, cellClass: 'cp right' },
      { headerName: '아이디', field: 'id', width: 120, cellClass: 'cp left' },
      { headerName: '이름', field: 'name', width: 120, cellClass: 'cp left' },
      { headerName: '지역', field: 'state_name', width: 80, cellClass: 'cp left' },
      { headerName: '전화', field: 'hp', width: 120, cellClass: 'cp left' },
      { headerName: '이메일', field: 'email', width: 150, cellClass: 'cp left' },
      { headerName: '회사명', field: 'shop_name', width: 120, cellClass: 'cp' },
      { headerName: '나이', field: 'age', width: 80, cellClass: 'cp center' },
      { headerName: '가입일자', field: 'join_day', width: 140, cellClass: 'cp center' },
      { headerName: '최초구매', field: 'first_buy_day', width: 140, cellClass: 'cp center' },
      { headerName: '최종구매', field: 'last_buy_day', width: 140, cellClass: 'cp center' },
      { headerName: '최종접속', field: 'last_access_day', width: 140, cellClass: 'cp center' },
      { headerName: '총구매액', field: 'buy_sum', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '총마진', field: 'margin_sum', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '누적마일리지', field: 'total_mileage', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '사용마일리지', field: 'used_mileage', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '협력점계약일', field: 'sis_date', width: 110, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();

    this.getMemberBenefitList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 회원등급
    this.commonService.getCommonListCode('MBG').then( response => {
      if ( response.ResultCode ) {
        this.gradeList = response.data;
      } else {
        this.gradeList = [];
      }
    });

    // 시/도
    this.commonService.getCommonListCode('ADDR').then( response => {
      if ( response.ResultCode ) {
        this.stateList = response.data;
      } else {
        this.stateList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원혜택제 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberBenefitList() {
    this.memberService.getMemberBenefitList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.memberBenefitList = response.data;
        this.searchCount = response.search;
        this.totalCount = response.total;
        this.updateDate = response.update;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 회원 혜택 등급 업데이트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberPurchaseUpdate() {
    if ( confirm('수동 업데이트 하시겠습니까?') ) {
      this.memberService.setMemberPurchaseUpdate().then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.addMember( $event.data.mem_no );
  }

  /*******************************************************************************
    설  명 : 회원 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addMember( memNo: number ) {
    const modalRef = this.modalService.open(MemberInfoComponent, optionsLG);

    modalRef.componentInstance.memberNo = memNo;

    modalRef.result.then((result) => {
      if( result ) {
        this.getMemberBenefitList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: pageRow,
      searchField: 'c.id',
      searchText: '',
      grade: '',
      state: ''
    };

    this.getMemberBenefitList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberBenefitList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getMemberBenefitList();
  }

}
