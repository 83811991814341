/*******************************************************************************
  설  명 : 현황관리 - 매출목표 & 데이터 관리
  작성일 : 2020-12-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import { ProductService } from '@app/service/product.service';

@Component({
  selector: 'app-product-soldout',
  templateUrl: './product-soldout.component.html',
  styleUrls: ['./product-soldout.component.scss']
})
export class ProductSoldoutComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/

  public soldOutCheckList: any = [];
  public commonType: any = [];

  public params: any = {
    pageNo: 1,
    pageRow: 1000,
    seq: '',
    searchText: '',
    status: '',
    sdate: '',
    edate: '',
    is_display: '',
    category_code: '',
    is_card_installment: '',
    mileage: '',
    safe_stock: ''
  };

  public soldoutSelect: any = [
    {seq: '0', name: '판매중'},
    {seq: '1', name: '일시품절'},
    {seq: '2', name: '영구품절'}
  ];

  public displaySelect: any = [
    {seq: '0', name: '미표시'},
    {seq: '1', name: '표시'},
  ];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  grid: any;
  components: any;
  rowHeight: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private productService: ProductService
  ) {

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '상품번호', field: 'seq', width: 80, 
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h'
        }
      },
      { headerName: '중분류', field: 'category_name', width: 100, 
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h'
        }
      },
      { headerName: '브랜드', field: 'brand_name', width: 100, 
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h'
        }
      },
      { headerName: '상품명', field: 'product_name', width: 250, cellRenderer: 'agGridHtmlComponent',
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp left ag-cell130h-br';
          else return 'cp left ag-cell50h'
        }      
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 200, cellRenderer: 'agGridImageComponent',
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h'
        }
      },
      { headerName: '색상', field: 'color', width: 70, cellRenderer: 'agGridHtmlComponent',
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h'
        },
        valueGetter: function(params) {
          if( params.data.color ) {
            return `<span style="display:inline-block;margin-top:15px;width:16px;height:16px;border:solid 1px #ccc;background-color:${params.data.color}">&nbsp;</span>`;
          } else {
            return '';
          }
        }
      },
      { headerName: '색상명(표시)', field: '', width: 200, cellRenderer: 'agGridHtmlComponent',
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp left ag-cell130h';
          else return 'cp left ag-cell50h'
        },
        valueGetter: function(params) {
          let colorName: any = params.data.color_name;
          let displayColorName: any = ( params.data.display_color_name ) ? ' ( <span class="fb">' + params.data.display_color_name + '</span> )' : '';

          return colorName + displayColorName ;
        }
      },
      { headerName: '사이즈명(표시)', field: '', width: 130, cellRenderer: 'agGridHtmlComponent',
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp left ag-cell130h';
          else return 'cp left ag-cell50h'
        },
        valueGetter: function(params) {
          let sizeName: any = params.data.size_name;
          let displaySizeName: any = ( params.data.display_size_name ) ? ' ( <span class="fb">' + params.data.display_size_name + '</span> )' : '';

          return sizeName + displaySizeName;
        }
      },
      { headerName: '판매가격', field: 'consumer_price', width: 100, valueFormatter: this.agGridExComponent.currencyFormatter,
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h'
        },
      },
      { headerName: '구매가격', field: 'buy_price', width: 100, valueFormatter: this.agGridExComponent.currencyFormatter,
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h'
        },
      },
      { headerName: '등록일', field: 'write_date', width: 110,
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h'
        },
      },
      { headerName: '품절관리', field: 'is_soldout', width: 110, cellRenderer: 'agGridHtmlComponent', cellEditor: "selectCellEditor",
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h ag-cell-edit'
        },
        editable: function(params) {
          if ( params.data.isDefault == '1' ) return false;
          else return true;
        },
        cellEditorParams: {
          values: this.soldoutSelect,
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter(params: any) {
          if ( params.data.is_soldout == '0' ) {
            return '<span class="badge badge-success f12">판매중</span>';

          } else if ( params.data.is_soldout == '1' ) {
            return '<span class="badge badge-warning f12">일시품절</span>';

          } else if( params.data.is_soldout == '2') {
            return '<span class="badge badge-danger f12">영구품절</span>';
          }
        }
      },
      { headerName: '표시여부', field: 'is_display', width: 90, cellRenderer: 'agGridHtmlComponent', cellEditor: "selectCellEditor",
        cellClass: function(params) {
          if( params.data.isDefault == '1' ) return 'cp center ag-cell130h';
          else return 'cp center ag-cell50h ag-cell-edit'
        },
        editable: function(params) {
          if ( params.data.isDefault == '1' ) return false;
          else return true;
        },
        cellEditorParams: {
          values: this.displaySelect,
          cellRenderer: 'agGridHtmlComponent'
        },
        valueGetter(params: any) {
          if ( params.data.is_display == '0' ) {
            return '<span class="badge badge-danger f12">미표시</span>';
          } else {
            return '<span class="badge badge-success f12">표시</span>';
          }
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      resizable: true
    };

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      selectCellEditor: this.agGridExComponent.getSelectEditor()
    };
  }

   /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    if( params.data.isDefault == '1' ) {
      return 130;
    } else {
      return 50;
    }
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( async params => {
      this.params.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
      this.params.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '1000' : params.pageRow;
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '0' : params.seq;
      this.params.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.params.status = ( typeof params.status == 'undefined' || params.status == '' ) ? '' : params.status;
      this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.params.is_display = ( typeof params.is_display == 'undefined' ) ? '' : params.is_display;
      this.params.category_code = ( typeof params.category_code == 'undefined' ) ? '' : params.cateogry_code;
      this.params.is_card_installment = ( typeof params.is_card_installment == 'undefined' ) ? '' : params.is_card_installment;
      this.params.mileage = ( typeof params.mileage == 'undefined' ) ? '' : params.mileage;
      this.params.safe_stock = ( typeof params.safe_stock == 'undefined' ) ? '' : params.safe_stock;

      if( this.params.seq != 0 && this.params.seq != '' ) {
        this.getProductSoldOutCheckList();
      }
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }

  /*******************************************************************************
    설  명 : 품절처리할 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductSoldOutCheckList() {
    this.productService.getProductSoldOutCheckList( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.soldOutCheckList = response.data;
      } else {
        this.soldOutCheckList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 품절 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductSoldOutSave() {
    if( confirm("품절 상태를 저장하시겠습니까?") ) {
      const data: any = [];
      this.gridApi.forEachNode(node => {
        data.push({
          seq: node.data.seq,
          is_soldout: node.data.is_soldout,
          is_display: node.data.is_display
        });
      });

      this.productService.setProductSoldOutSave( JSON.stringify(data) ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);

          this.getProductSoldOutCheckList();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 리스트 페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  list() {
    this.router.navigate(
      ['/product/list'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

}