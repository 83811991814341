/*******************************************************************************
  설  명 : 현황관리 - 제품 매출현황
  작성일 : 2020-12-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { CategoryService } from '@app/service/category.service';
import { StatisticService } from '@app/service/statistic.service';
import { UtilService } from '@app/service/util.service';
import { Router, ActivatedRoute } from '@angular/router';

import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-statistic-product',
  templateUrl: './statistic-product.component.html',
  styleUrls: ['./statistic-product.component.scss']
})
export class StatisticProductComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    year: moment().format('YYYY'),
    category_select: '0',
    category: '',
  };

  public optionsCategory: any = {
    multiple: false,
    placeholder: '카테고리 검색'
  }

  public years: any = [];
  public categoryDataList: any = [];
  public selectCategoryList: any = [];

  public categoryList: any = [];

  // 차트 관련 변수
  public barChartData: any = [];
  public chartType = 'bar';
  public chartLabel: string[] = [];

  // 차트 옵션
  public chartOptions = {
    tooltips: {
      display: true,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: 'chocolate',
      bodyFontColor: '#000',
      bodyFontSize: 12,
      displayColors: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipItem.value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') + ' 원';
        }
      }
    },
    scaleShowVerticalLines: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad'
    },
    responsive: true,
    legend: {
      display: true
    },
    hover: {
      mode: 'arest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data Value'
        },
        ticks: {
          beginAtZero: true,
          callback : value => value.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        }
      }]
    }
  };

  // 그리드 관련 선언
  public dataList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private utilService: UtilService,
    private statisticService: StatisticService,
    private categoryService: CategoryService,
    private agGridExComponent: AgGridExComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    // 전체 매출현황 page에서 링크로 년도값이 들어 올 수 있음
    this.getCategoryList2();

    this.categoryService.getTopCategory().then( response => {
      if ( response.ResultCode ) {
        this.categoryList = [{category_code: '', title: '전체'}, ...response.data];
      }
    });

    this.statisticService.getYearsSelect().then( response => {
      if ( response.ResultCode ) {
        this.years = response.data;
      } else {
        this.years = [];
      }
    });
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // chart 
    var tmp = [
      {type: 'bar', data: [], label: '전년매출', fill: false, lineTension: 0},
      {type: 'bar', data: [], label: '매출', fill: false, lineTension: 0},
      {type: 'line', data: [], label: '마진', fill: false, lineTension: 0}
    ];

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '구분', field: 'common_name', width: 120, cellClass: 'cp center' },
    ];

    for( let i = 0; i < 10; i++ ) {
      this.columnDefs.push({
        headerName: parseInt( moment().format('YYYY'), 10 ) - 9 + i + '년',
        field: (parseInt( moment().format('YYYY'), 10 ) - 9 + i).toString(),
        width: 120,
        cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter 
      });
      this.chartLabel[i] = parseInt( moment().format('YYYY'), 10 ) - 9 + i + '년';
    };

    this.columnDefs.push({
      headerName: '합계',
      field: 'total',
      width: 140,
      cellClass: 'cp right',
      valueFormatter: this.agGridExComponent.currencyFormatter 
    });

    this.barChartData.push(tmp[0]);
    this.barChartData.push(tmp[1]);
    this.barChartData.push(tmp[2]);

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
    this.getData();
  };

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  searchInit() {
    this.search = {
      year: moment().format('YYYY'),
      category_select: '0',
      category: '',
    };

    this.getData();
  };

  /*******************************************************************************
    설  명 : category 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList2() {
    this.categoryService.getCategoryList2().then( response => {
      this.selectCategoryList = [];
      if (response.ResultCode) {
        this.selectCategoryList.push({id: '0', text: '카테고리 선택'});

        response.data.forEach( row => {
          this.selectCategoryList.push({
            id: row.seq,
            text: row.category_name
          });
        });
      } else {
        this.selectCategoryList = [];
      }
    });
  };

  /*******************************************************************************
    설  명 : select2 카테고리 검색 이벤트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedCategory( event: any, item: any ) {
    const key = event.id || (item.category_code === '' ? '0' : item.category_code);
    
    if ( key != '0' ) {
      const product = this.categoryList.find(f => f.category_code == key) || { category_code: '0' };

      this.search.category_select = key;
      this.search.category = product.category_code;
    } else {
      this.search.category_select = '0';
      this.search.category = '';
    };

    this.getData();
  };

  // 데이터 조회
  getData() {
    this.getSalesCategoryYear();
  };  
  
  // 전체 매출현황 (카테고리)
  getTotalSalesCategory() {
    const category = this.search.category_select !== '0' ? this.search.category_select : this.search.category;

    this.statisticService.getTotalSalesCategory(category).then(response => {
      const data = [];
      if (response.ResultCode && response.data.length > 0) {
        data.push(this.utilService.saleDataPivot('1000', '목표액', 'target', 'period', response.data));
        data.push(this.utilService.saleDataPivot('2000', '매출액', 'income', 'period', response.data));
        data.push(this.utilService.saleDataPivot('3000', '전년매출액', 'previous', 'period', response.data));
        data.push(this.utilService.saleDataPivot('4000', '매출마진', 'margin', 'period', response.data));
      } else {
        this.toastrService.error(response.ResultMessage);
      }

      this.dataList = data;
    });    
  };

  // 제품군 매출현황 (년도별)
  getSalesCategoryYear() {
    const category = this.search.category_select !== '0' ? this.search.category_select : this.search.category;

    this.statisticService.getSalesCategoryYear({year: this.search.year, category_code: category}).then(response => {
      const data = [];
      this.barChartData.map( chart => {
        return chart.data = [];
      });
      
      if (response.ResultCode) {
        this.chartLabel = response.data.map((data, idx) => {
          if (response.data.length > 1) {
            return [(idx + 1) + '위', data.category_name]
          };
          return data.category_name;
        });
        this.barChartData[0].data = response.data.map( data => data.previous);
        this.barChartData[1].data = response.data.map( data => data.income);
        this.barChartData[2].data = response.data.map( data => data.margin);

        this.getTotalSalesCategory();
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  };
  
  moveSimplePage() {
    this.router.navigate(['/statistic/simple'], {
      relativeTo: this.activatedRoute,
      queryParams: { type : 1 },
    })
  }
};
