/*******************************************************************************
  설  명 : 직위관리
  작성일 : 2019-09-22
  작성자 : 송영석
*******************************************************************************/
import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPioneerTreeConfiguration, IPioneerTreeComponent } from '@pioneer-code/pioneer-tree';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { SystemPositionService } from '@app/service/position.service';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})
export class PositionComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @ViewChild ( 'pt', {static: false} ) pt: IPioneerTreeComponent ;

  public positionList = [];

  public positionForm: FormGroup;
  public formErrors = {};

  public selectedNode = {
    seq: '',
    sort_order: '',
    name: '',
    use_yn: '1'
  };

  public configuration = {
    "childPropertyName" : "children",
    "sortPropertyName" : "id",
    "collapseAllOnLoad" : false
  } as IPioneerTreeConfiguration;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.positionForm = this.formBuilder.group({
      seq:["", [] ],
      sort_order:["", [] ],
      name:["", [Validators.required, Validators.maxLength(50)] ],
      use_yn:['1', [Validators.required] ],
    });

    this.positionForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.positionForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private systemPositionService: SystemPositionService,
    public authService: AuthService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 카테고리 리스트 가져오기
    this.getPositionList();
  }

  /*******************************************************************************
    설  명 : 카테고리 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.positionForm, this.formErrors);

    if(this.positionForm.valid) {
      this.systemPositionService.setPosition(this.positionForm).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getPositionList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getPositionList() {
    await this.systemPositionService.getPositionList().then( response => {
      if( response.ResultCode ) {
        this.positionList = response.data;
      } else {
        this.positionList = [];
      }
    });

    this.configuration = { "childPropertyName" : "children", "sortPropertyName" : "id", "collapseAllOnLoad" : false };

  }

  /*******************************************************************************
    설  명 : 카테고리 노드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedPosition( node ) {

    if( typeof this.selectedNode.seq == 'undefined' || this.selectedNode.seq == node.seq ) {
      this.selectedNode = {
       seq: '',
        sort_order: '',
        name: '',
        use_yn: '1'
      }
    } else {
      this.selectedNode = node;
    }

    this.positionForm.patchValue( this.selectedNode );
  }

  /*******************************************************************************
    설  명 : lpad
    입력값 : str = 문자열, padLen = 채울 문자 수, padStr = 채울 문자
    리턴값 : 문자열
  *******************************************************************************/
  lpad(str, padLen, padStr) {
    if (padStr.length > padLen) {
        return str;
    }
    str += ""; // 문자로
    padStr += ""; // 문자로
    while (str.length < padLen)
        str = padStr + str;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }

  /*******************************************************************************
    설  명 : 카테고리 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addPosition() {
    this.positionForm.patchValue({
      seq: '',
      sort_order: '',
      name: '',
      use_yn: '1'
    });
  }

  /*******************************************************************************
    설  명 : 트리노드 변경시 처리
    입력값 : event
    리턴값 : 없음
  *******************************************************************************/
  async onNodeDropped( event ) {
    // 루트 노드 가져오기
    let rootNode = this.getTreeNodeRoot( event.pioneerTreeNode );

    // 현재 노드 가져오기
    if( typeof rootNode.currentNode == 'undefined' ) rootNode = rootNode.pioneerTreeNode.currentNode;
    else rootNode = rootNode.currentNode;

    // 레벨 가져오기
    rootNode.level = 0;
    let level = this.getTreeNodeLevel( rootNode, rootNode.level, rootNode.level );

    // 1단계 이상은 오류 처리
    if( level > 1 ) {
      this.getPositionList();

      this.toastrService.error( '1단계 이상으로 노드를 확장할 수 없습니다.', '');

      return false;

    // 정상 이동일 경우 처리
    } else {
      // 노드  생성
      let key1 = rootNode.sort_order;
      let index = 0;

      let nodeArray = [];

      rootNode.pioneerTreeNode.treeRootNodes.forEach( function( node ) {
        node.sort_order = index++;

        nodeArray.push({
          seq: node.seq,
          sort_order: node.sort_order
        });

      }.bind(this));

      this.systemPositionService.updatePosition( JSON.stringify( nodeArray ) ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getPositionList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 트리노드 단계를 가져온다.
    입력값 : node, 현재 레벨, 맥스 레벨
    리턴값 : 최대 레벨 값
  *******************************************************************************/
  getTreeNodeLevel( node, currLevel, maxLevel ) {
    if( typeof node.children == 'undefined' ) {
      if( maxLevel < currLevel+1 ) return currLevel+1;
      else return maxLevel;
    }

    node.children.forEach( function(c) {
      maxLevel = this.getTreeNodeLevel(c, currLevel + 1, maxLevel);
    }.bind(this));

    return maxLevel;
  }

  /*******************************************************************************
    설  명 : 트리노드 루트 노드 값을 가져온다
    입력값 : node
    리턴값 : rootNode
  *******************************************************************************/
  getTreeNodeRoot( node ) {
    if( typeof node.parentNode !== 'undefined' ) {
      return this.getTreeNodeRoot( node.parentNode );

    } else if( typeof node.pioneerTreeNode !== 'undefined' ) {
      if( typeof node.pioneerTreeNode.parentNode !== 'undefined' ) {
        return this.getTreeNodeRoot( node.pioneerTreeNode.parentNode );
      } else {
        return node;
      }

    } else {
      return node;
    }
  }

  /*******************************************************************************
    설  명 : 트리노드 단계를 무시하고 변경할 리스트를 배열로 리턴
    입력값 : node
    리턴값 : 배열
  *******************************************************************************/
  getTreeNodeArray( node, nodeArray ) {
    if( typeof node.children == 'undefined' ) {
      nodeArray.push({
        seq: node.seq,
        dept_cd: node.dept_cd
      });

    } else {
      if( node.dept_cd.substr( 3, 6 ) !== '000000' )
        nodeArray.push({
          seq: node.seq,
          dept_cd: node.dept_cd
        });

      node.children.forEach( function(c) {
        this.getTreeNodeArray( c, nodeArray );
      }.bind(this));
    }
  }

  /*******************************************************************************
    설  명 : 카테고리 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deletePosition() {
    if( this.positionForm.controls.seq.value == '' ) {
      this.toastrService.error( '삭제할 직위를 선택하세요.', '');

      return false;
    }

    if( confirm("선택한 직위를 삭제하시겠습니까?") ) {
      this.systemPositionService.deletePosition( this.positionForm ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.positionForm.patchValue({
            seq: '',
            sort_order: '',
            name: '',
            use_yn: '1'
          });

          this.getPositionList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

}
