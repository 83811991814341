import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { CategoryService } from '@app/service/category.service';
import { ProductService } from '@app/service/product.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { ProductFindComponent } from '@components/product-find/product-find.component';

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-relation',
  templateUrl: './product-relation.component.html',
  styleUrls: ['./product-relation.component.scss']
})
export class ProductRelationComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public relationProductList: any = [];
  public categoryList: any = [];

  public params: any = {
    categorySeq: '',
    searchField: 'product_name',
    searchText: '',
    // status: '',
  };

  public activeTab: any;
  public components: any;

  // 그리드 관련 선언
  gridApiCategory: any;
  gridColumnApiCategory: any;
  columnDefsCategory: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private agGridExComponent: AgGridExComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefsCategory = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '카테고리명', field: 'category_nm', width: 200, cellClass: 'cp' },
    ];

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '이동', field: '', width: 50, cellClass: 'cp center', rowDrag: true, filter: false },
      { headerName: '추천(순위)', field: 'sort_order', width: 80, cellClass: 'cp center ag-cell-edit', editable: true, singleClickEdit: true, cellEditor: "numericCellEditor" },
      { headerName: '이미지', field: 'thumbnail_url', width: 80, cellClass: 'cp center', cellRenderer: 'agGridImageComponent',
        cellRendererParams: {
          isTooltip: true
        }
      // cellRendererFramework: ToolTipComponent,
      // cellRendererParams: (params: ICellRendererParams) => this.formatToolTip(params.data)
      },
      // { headerName: '이미지', field: 'thumbnail_url', width: 140, cellClass: 'cp center' },
      { headerName: '카테고리', field: 'category_name', width: 200, cellClass: 'cp center' },
      { headerName: '브랜드', field: 'brand_name', width: 110, cellClass: 'cp center' },
      { headerName: '모델명', field: 'product_name', width: 700, cellClass: 'cp' },
      { headerName: '상태', field: 'status', width: 110, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status == '0' ) {
            return '<span class="badge badge-success f12">판매중</span>';
          } else if ( params.data.status == '1' ) {
            return '<span class="badge badge-warning f12">임시품절</span>';
          } else if ( params.data.status == '2' ) {
            return '<span class="badge badge-danger f12">영구품절</span>';
          } else {
            return '';
          }
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor()
    };

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function numberGetter(params: any) {
      const value = params.data[ params.column.colId ];
      return ( value ) ? parseInt( value ) : value;
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCategoryList();
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductRelationList() {
    this.productService.getProductRelationList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.relationProductList = response.data;
      } else {
        this.relationProductList = [];
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 정렬번호 변경 시 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSortOrderSave( data: any ) {
    this.productService.setSortOrderSave( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');

        this.getProductRelationList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 순서 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRelationSortOrderSaveAll() {
    const data: any = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(node) {
      data.push( node.data.seq );
    });
    if ( confirm('해당 순서로 저장하시겠습니까?') ) {
      this.productService.setRelationSortOrderSaveAll( JSON.stringify(data) ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductRelationList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductRelationDelete() {
    let rows = this.gridApi.getSelectedRows();
    if( rows.length < 1 ) {
      this.toastrService.warning( '삭제할 상품을 선택하시기 바랍니다.', '');
      return;
    }

    let data = rows.map((obj: any) => {
      return {
        seq: obj.seq
      }
    });
    
    if ( confirm('선택한 상품을 삭제하시겠습니까?') ) {
      this.productService.setProductRelationDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getProductRelationList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 연관 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productRelationAdd() {
    const modalRef = this.modalService.open(ProductFindComponent, optionsXXL);

    // modalRef.componentInstance.seqArray = seqArray;

    modalRef.result.then((result) => {

      if( typeof result != 'undefined' ) {

        const newResult: any = [];
        result.forEach(obj => {
          const duplicate = this.relationProductList.filter(function (item) {
            return item.product_seq === obj.seq;
          });

          if( duplicate.length < 1 ) {
            newResult.push(obj);
          }
        });

        this.productService.setProductRelationSave( this.params.categorySeq, newResult ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getProductRelationList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCategory(params) {
    this.gridApiCategory = params.api;
    this.gridColumnApiCategory = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 공통코드 조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCategoryGridRowClicked(event: any) {
    this.params.categorySeq = event.data.seq;

    this.getProductRelationList();
  }

  /*******************************************************************************
    설  명 : ag grid 셀 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStarted( event: any ) {
    event.data['old_sort_order'] = event.value;
  }

  onCellEditingStopped( event: any ) {
    if( event.value != event.data.old_sort_order ) {
      this.setSortOrderSave( event.data );
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.params.searchField = 'product_name';
    this.params.searchText = '';
    // this.params.status = '';

    this.getProductRelationList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductRelationList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼에서 클릭 시 처리
    입력값 : key = this.params 키 값, value = 적용 값
    리턴값 : 없음
  *******************************************************************************/
  searchParams( key, value ) {
    this.params[key] = value;

    this.getProductRelationList();
  }

}
