import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  constructor(
    public restful: RestfulService,
  ) { }

  // 결재 문서 리스트 가져오기
  getApprovalStep(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalStep'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결재 문서 리스트 가져오기
  getApprovalDocument(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalDocument'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 결제 단계 저장하기
  addApprovalStep( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'addApprovalStep'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결제 단계 수정하기
  editApprovalStep( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'editApprovalStep'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결재 문서 리스트 삭제하기
  delApprovalStep( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'delApprovalStep'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결재 문서 리스트 상세
  getApprovalStepDetail( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalStepDetail'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }


  // 결제관리 리스트 가져오기
  getApprovalList(search: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalList'
      }, search
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 임시저장 리스트 가져오기
  getApprovalTmpList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalTmpList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 임시저장 리스트 삭제하기
  delApprovalTmp( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'delApprovalTmp'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결재단계 가져오기
  getApprovalStepInfo( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalStepInfo'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 임시저장 결재문서 가져오기
  getDocumentTmp( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getDocumentTmp'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결재 저장
  approvalSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'approvalSave'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 임시 결재 저장
  approvalTmpSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'approvalTmpSave'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 내 결재 정보 가져오기
  getApprovalInfo( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalInfo'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  cancelApproval( params ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'cancelApproval'
      }, params
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  updateApproval( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'updateApproval'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  getMemberApprovalInfo(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getMemberApprovalInfo'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  approvalProcess(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'approvalProcess'
      }, params
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  getApprovalSelf(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalSelf'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  getApprovalWriter(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalWriter'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  setApprovalView(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'setApprovalView'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  documentExecute(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'documentExecute'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  getApprovalStepList(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'getApprovalStepList'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  saveOfflineComplete( array: any, files: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval',
        version: '1.0',
        action: 'saveOfflineComplete'
      }, {
        params: array,
        files: files
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
