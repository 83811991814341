<div class="mb5 mt5">
  <div class="fl w30p form-inline">
    <div class="fb mt5"><i class="material-icons cred f11 lh20">star</i>판매현황</div>
    <input type="text" id="quickFilter" size="50" (input)="onQuickFilterChanged($event)" class="form-control form-control-small ml30" placeholder="그리드 내 모든 필드 검색 필터링" />
  </div>
  <div class="fr w30p right">
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="4982b667a97849ae85504fc77adfe5a4" [btnGroup]="1"></btn-ag-grid-save>
    <ag-grid-excel [gridApi]="gridApi" selected="false" filename="{{fileName}}" title="{{fileName}}"></ag-grid-excel> 
  </div>
  <div class="cb"></div>
</div>

<div style="height:calc(100% - 45px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="productSalesStatusList"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [pagination]="false"
    [getRowHeight]="getRowHeight"
    [domLayout]="domLayout"
    [suppressRowTransform]="true"
    [stopEditingWhenGridLosesFocus]="true"   
    [frameworkComponents]="frameworkComponents"
    [components]="components"
    [singleClickEdit]="true"
    
    (columnResized)="onColumnResized($event)"
    (columnVisible)="onColumnVisible($event)"
    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    >
    
  </ag-grid-angular>
</div>
