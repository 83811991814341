<form [formGroup]="formImport">
  <input type="hidden" id="seq" formControlName="seq" />

  <div class="modal-header">
    <h4 class="modal-title fb mb0">상품입고</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="hidden">상품입고</caption>

    <colgroup>
      <col style="width:25%;" />
      <col style="width:75%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="inout_gbn" class="control-label">입출고구분</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12">기초재고</button>
          <button type="button" class="btn btn-default btn-small f12">기타입고</button>
        </div>
        <inputEx type="hidden" name="inout_gbn" [formGroup]="formImport" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="warehouse_seq_in" class="control-label">창고</label></th>
      <td>
        <inputEx type="select" name="warehouse_seq_in" [formGroup]="formImport" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="warehouse_seq_in" class="control-label">입고위치</label></th>
      <td>
        <inputEx type="select" name="warehouse_seq_in" [formGroup]="formImport" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="product_name" class="control-label">상품</label></th>
      <td>
        <inputEx type="text" name="product_name" [formGroup]="formImport" [formErrors]="formErrors" readonly="true" [addOn]="addProductFunc" addOnText="상품검색"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="inout_date" class="control-label">입고일자</label></th>
      <td>
        <inputEx type="date" name="inout_date" [formGroup]="formImport" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="inout_qty" class="control-label">입고수량</label></th>
      <td>
        <inputEx type="number" name="inout_qty" [formGroup]="formImport" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="customer_name" class="control-label">구매거래처</label></th>
      <td>
        <inputEx type="text" name="customer_name" [formGroup]="formImport" [formErrors]="formErrors" readonly="true" [addOn]="addCustomerFunc" addOnText="거래처검색"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="unit_amt" class="control-label">구매단가(원)</label></th>
      <td>
        <inputEx type="number" name="unit_amt" [formGroup]="formImport" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="amt" class="control-label">구매금액(원)</label></th>
      <td>
        <inputEx type="number" name="amt" [formGroup]="formImport" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="memo" class="control-label">메모</label></th>
      <td colspan="3">
        <inputEx type="textarea" name="memo" [formGroup]="formImport" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button type="button" class="btn btn-small btn-danger">삭제</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-small blue-btn">저장</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
    </div>
  </div>
</form>