<section id="supersale-brand">
  <div class="mt5 mb5">
    <div class="fl w75p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" class="form-control form-control-small ml5" placeholder="브랜드명" />

      <input type="text" class="form-control form-control-small ml5" placeholder="할인율/적립율 일괄적용" />
      <button type="button" class="btn btn-success btn-small ml5">할인율 일괄적용</button>
      <button type="button" class="btn btn-success btn-small ml5">적립율 일괄적용</button>
    </div>
    <div class="fr w25p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="b308bbe48b6149b3bc0a20845244fc1c" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-primary btn-small ml5">할인율 저장</button>
    </div>
    <div class="cb"></div>
  </div>

  <div class="" style="height:calc(100% - 35px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="superSaleBrandList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
      >
    </ag-grid-angular>
  </div>
</section>