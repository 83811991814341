<section id="common">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="20" minSize="20" maxSize="40">
      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">공통코드 그룹</h4>
        </div>
        <div class="fr w50p right">
          <button type="button" title="포스추가" class="btn btn-primary btn-small-icon ml5" (click)="addCommonGroup({seq:0})">
            <i class="material-icons-outlined">add</i>
          </button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="commonGroupList"
          [pagination]="false"
          [domLayout]="domLayout"
          [rowSelection]="rowSelection"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (cellClicked)="onCellClicked($event)"
          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged($event)"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>

    <as-split-area size="80" minSize="60" maxSize="80">

      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">공통코드 - {{selectedItem.name}}</h4>
        </div>
        <div class="fr w50p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApiCommon" gridId="a9c641f7e95145caa0a7f7c8265e98e8" [btnGroup]="1" class="mr5"></btn-ag-grid-save>
            <button type="button" class="btn btn-primary btn-small" (click)="addCommon({seq:0})">공통코드 추가</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsCommon"
          [defaultColDef]="defaultColDefCommon"
          [rowData]="commonList"
          [pagination]="false"
          [domLayout]="domLayoutCommon"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (rowClicked)="onRowClickedCommon($event)"
          (gridReady)="onGridReadyCommon($event)"
          (filterChanged)="onFilterChangedCommon($event)"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
