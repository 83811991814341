<div class="modal-header">
  <h4 class="modal-title fb mb0">오픈마켓 주문건 등록</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">
    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <div class="mt10">
      <div class="fl w100p">
        <h4 class="fb mt5 mb6">주문 상품 정보</h4>
      </div>
      <div class="cb"></div>
    </div>

    <div class="mt5" style="height:200px">
      <ag-grid-angular
        #myGrid
  
        style="width: 100%;height:100%;"
        class="ag-theme-balham"
  
        [columnDefs]="orderColumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="form.controls.detail.value"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [isRowSelectable]="isRowSelectable"
        [pagination]="false"
        [getRowHeight]="getRowHeight"
        [singleClickEdit]="true"
        [stopEditingWhenGridLosesFocus]="true"    
        [suppressRowClickSelection]="true"
  
        (gridReady)="onOrderGridReady($event)"
        (rowDataChanged)="onRowDataChanged($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        >
      </ag-grid-angular>
    </div>
  
    <div class="cb"></div>

    <div class="" style="height:65px">
      <ag-grid-angular
        #myGrid
  
        style="width: 100%;height:100%;"
        class="ag-theme-balham"
  
        [columnDefs]="orderSumColumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="form.controls.sum.value"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
  
        (gridReady)="onOrderSumGridReady($event)"
        >
      </ag-grid-angular>
    </div>
  
    <div class="mt10">
      <div class="fl w100p">
        <h4 class="fb mt5 mb6">주문자 정보</h4>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">주문자 정보</caption>
    <colgroup>
      <col style="width:7%;" />
      <col style="width:18%;" />
      <col style="width:7%;" />
      <col style="width:18%;" />
      <col style="width:7%;" />
      <col style="width:18%;" />
      <col style="width:7%;" />
      <col style="width:18%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="order_status">주문상태</label></th>
      <td><inputEx type="select" name="order_status" [formGroup]="form" [formErrors]="formErrors" [data]="orderStatusList"></inputEx></td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="order_date">주문일자</label></th>
      <td><inputEx type="date" name="order_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="o_name">주문자</label></th>
      <td><inputEx type="text" name="o_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="id">아이디</label></th>
      <td><inputEx name="id" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" [addOn]="addMemberFunc" addOnText="회원검색"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="grade_name">회원구분</label></th>
      <td><inputEx type="text" name="grade_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
      <th scope="row"><label for="o_email">이메일</label></th>
      <td><inputEx type="text" name="o_email" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="o_hp">휴대폰</label></th>
      <td><inputEx type="text" name="o_hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="o_hp2">전화</label></th>
      <td><inputEx type="text" name="o_hp2" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="bank">송금하실 은행</label></th>
      <td><inputEx type="select" name="bank" [formGroup]="form" [formErrors]="formErrors" [data]="bankList"></inputEx></td>
      <th scope="row"><label for="receipt_name">입금자명</label></th>
      <td><inputEx type="text" name="receipt_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="saler">판매처</label></th>
      <td><inputEx type="select" name="saler" [formGroup]="form" [formErrors]="formErrors" [data]="salerList"></inputEx></td>
      <th scope="row"><label for="shop_gbn">SHOP</label></th>
      <td><inputEx type="select" name="shop_gbn" [formGroup]="form" [formErrors]="formErrors" [data]="shopList"></inputEx></td>
    </tr>
    </tbody>
    </table>

    <div class="mt10">
      <div class="fl w100p">
        <h4 class="fb mt5 mb6">상품 수취인 정보</h4>
      </div>
      <div class="cb"></div>
    </div>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">상품 수취인 정보</caption>
    <colgroup>
      <col style="width:7%;" />
      <col style="width:18%;" />
      <col style="width:7%;" />
      <col style="width:18%;" />
      <col style="width:7%;" />
      <col style="width:18%;" />
      <col style="width:7%;" />
      <col style="width:18%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="send_letter">등기번호</label></th>
      <td><inputEx type="text" name="send_letter" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="r_name">수취인 성명</label></th>
      <td><inputEx type="text" name="r_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="r_hp">수취인 전화</label></th>
      <td><inputEx type="text" name="r_hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="r_hp2">휴대폰</label></th>
      <td><inputEx type="text" name="r_hp2" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="unde_flag">주문상태</label></th>
      <td>
        <div class="btn-group">
          <button type="class" class="btn btn-default btn-small" [ngClass]="{active: form.controls.unde_flag.value=='1'}" (click)="form.get('unde_flag').setValue('1')">완결</button>
          <button type="class" class="btn btn-default btn-small" [ngClass]="{active: form.controls.unde_flag.value=='2'}" (click)="form.get('unde_flag').setValue('2')">미결</button>
        </div>
        <inputEx type="hidden" name="unde_flag" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="r_zipcode">우편번호</label></th>
      <td>
        <div class="input-group">
          <inputEx name="r_zipcode" class="mr3" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
          <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
        </div>
      </td>
      <th scope="row"><label for="r_address">주소</label></th>
      <td><inputEx type="text" name="r_address" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
      <th scope="row"><label for="r_address_detail">상세주소</label></th>
      <td><inputEx type="text" name="r_address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row">
        <label for="r_comment">상품관련<br />세부 선택사항</label>
      </th>
      <td colspan="7"><inputEx type="summernote" name="r_comment" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx></td>
    </tr>
    </tbody>
    </table>

  </form>

</div>

<div class="modal-footer">
  <div class="fl w50p">
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    <button type="button" class="btn btn-primary btn-small ml5">저장하기</button>
  </div>
</div>
