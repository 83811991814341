import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { SmsService } from '@app/service/sms.service';
import { CommonService } from '@app/service/common.service';
import { ToastrService } from 'ngx-toastr';

//import { MemberFindComponent } from '@page/member/member-find/member-find.component';

@Component({
  selector: 'app-modal-sms',
  templateUrl: './modal-sms.component.html',
  styleUrls: ['./modal-sms.component.scss']
})
export class ModalSmsComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 발송 휴대폰 리스트
  public sendList: any;
  public contents: any;

  public senderList: any = [];
  private titleDef = {title: '발신번호를 선택하세요', value: ''};

  public formSMS: FormGroup;
  public formErrors = {};

  // 그리드 관련 선언
  selectedRows = 0;
  selectedRowsMember = 0;

  smsList: any = [];
  memberList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  gridApiMember: any;
  gridColumnApiMember: any;
  columnDefsMember: any;
  rowSelectionMember: any;

  public MAXIMUM = 1000; // 문자 동시 발송 최대치

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formSMS = this.formBuilder.group({
      contents: [ '', [Validators.required] ],
      title: [ 'SMS발송', [] ],
      hp: [[], [Validators.required]],
      sender: ['', [Validators.required]]
    });

    this.formSMS.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formSMS, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private smsService: SmsService,
    private commonService: CommonService
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefsMember = [
      {
        headerName: '', field: 'mem_no', cellClass: 'cp right', width: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {headerName: '회원', field: 'name', cellClass: 'cp', width: 70, flex: 1 },
      {headerName: '휴대폰', field: 'hp', cellClass: 'cp', width: 100, flex: 1 },
      {headerName: '아이디', field: 'id', cellClass: 'cp', width: 80, flex: 1 },
      // {headerName: '회사명', field: 'companyName', cellClass: 'cp', width: 80 },
      // {headerName: '구분', field: 'memberType', cellClass: 'cp', width: 80 },
      // {headerName: '만료일', field: 'eDay', hide: true },
    ];

    this.columnDefs = [
      { headerName: '번호', field: 'seq', cellClass: 'cp center', width: 85,
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
      },
      {headerName: '자동발송', field: 'autoName', cellClass: 'cp', width: 90 },
      {headerName: '제목', field: 'title', cellClass: 'cp', width: 100 },
      {headerName: '발송내용', field: 'contents', cellClass: 'cp', width: 150 },
      {headerName: '자동발송 코드', field: 'auto_seq', hide: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true
    };

    this.rowSelectionMember = 'multiple';
    this.rowSelection = 'single';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }


  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // SMS 발송 양식 가져오기
    this.getSMSFormList();

    // SMS 발송번호 가져오기
    this.getSenderSMS();

    // if( this.sendList.length < 1 ) {
    //   this.toastrService.error( 'SMS를 발송할 회원이 없습니다.', 'SMS발송' );
    //   this.activeModal.close();

    //   return false;
    // }

    if( this.contents != undefined ) {
      this.formSMS.patchValue({
        contents: this.contents
      });
    }
  }

  /*******************************************************************************
    설  명 : 발송번호 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSenderSMS() {
    this.senderList = [];
    this.senderList.push(this.titleDef);

    this.commonService.getCommonListCode('SPN').then( response => {
      for (const item of response.data) {
        this.senderList.push({
          title: item.common_name,
          value: item.common_name
        });
      }
    });

  }

  /*******************************************************************************
    설  명 : SMS 발송양식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSMSFormList() {
    return this.smsService.getSMSFormList({searchText: ''}).then( response => {
      if ( response.ResultCode ) {
        this.smsList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage );
      }
    });
  }

  /*******************************************************************************
    설  명 : 한글 포함 문자열 갯수 처리
    입력값 : 문자열
    리턴값 : 문자수
  *******************************************************************************/
  getTextLength(str) {
    let len = 0;

    for (let i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length === 6) {
            len++;
        }
        len++;
    }
    return len;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.formSMS.patchValue({
      title: $event.data.title,
      contents: $event.data.contents
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyMember(params) {
    this.gridApiMember = params.api;
    this.gridColumnApiMember = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedMember($event) {
    let filteredRowCount = 0;
    this.gridApiMember.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRowsMember = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 선택된 발송 회원 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteMember(auto = false) {
    const nodes: any = this.gridApiMember.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.error('삭제할 휴대폰 번호를 선택하세요.');

      return false;
    }

    if ( !auto && ! confirm('선택하신 휴대폰 번호를 삭제하시겠습니까?') ) {
      return false;
    }

    const removedRows: any = [];

    for (let node of nodes ) {
      removedRows.push( node );
      this.gridApiMember.updateRowData({remove: [node]});

      const idx = this.sendList.indexOf(node);
      if (idx > -1) {
        this.sendList.splice(idx, 1);
      }
    }
  }

  /*******************************************************************************
    설  명 : 대량 자동 발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  autoSend() {
    if(!confirm('Warning! 회원 전체에게 문자를 보냅니다.')) return;
    this.gridApiMember.selectAll();
    this.submit(true);
  }

  /*******************************************************************************
    설  명 : directsend 문자발송
  *******************************************************************************/
  submit(auto = false) {
    if(this.gridApiMember.getSelectedRows().length > this.MAXIMUM) {
      this.toastrService.info(`${this.MAXIMUM} 건씩 순차적으로 발송합니다.`);
      this.gridApiMember.forEachNode((e,i) => {
        e.setSelected(i < this.MAXIMUM);
      });
    }

    const smsMembers = this.gridApiMember.getSelectedRows();
    let hpList = [];

    if( this.formSMS.controls.hp.value.length > 0 ) {
      hpList = [...[this.formSMS.controls.hp.value]];
    }

    smsMembers.forEach(item => {
      hpList.push(item.hp.replace(/-/g,''));
    });

    this.formSMS.patchValue({
      hp: hpList
    });

    this.utilService.makeFormDirtyAndUpdateErrors(this.formSMS, this.formErrors);
    if(!this.formSMS.valid) {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.');
      return;
    }

    this.smsService.sendSMS_directsend( this.formSMS ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage);
        this.activeModal.close();
      } else {
        this.formSMS.reset({
          hp: '',
          contents: this.formSMS.value.contents,
          title: this.formSMS.value.title,
          sender: this.formSMS.value.sender
        });
        this.toastrService.error(response.ResultMessage);
        this.activeModal.close();
      }
      
      if( auto == true && smsMembers.length > 0 ) this.deleteMember(true);

      if( auto == true ) {
        this.gridApiMember.selectAll();
        this.submit(this.gridApiMember.getSelectedRows().length);
      }
    });
  }

}
