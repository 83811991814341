/*******************************************************************************
  설  명 : 회원정보 - 주문내역
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-order',
  templateUrl: './member-order.component.html',
  styleUrls: ['./member-order.component.scss']
})
export class MemberOrderComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() dormant: any;

  public search: any = {
    memberNo: '',
    searchText: '',
    sdate: this.utilService.getDate(moment().subtract(90, 'day')),
    edate: this.utilService.getDate(''),
    searchTerm: this.getDays(90),
  };

  public term: any = [
    // {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
    {title: '150일', value: this.getDays(150)},
    {title: '200일', value: this.getDays(200)},
    {title: '365일', value: this.getDays(365)},
  ];

  // 그리드 관련 선언
  memberOrderList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '주문번호', field: 'master_seq', width: 115, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '주문자', field: 'o_name', width: 150, cellClass: 'cp center' },
      { headerName: '샵구분', field: 'shop_gbn', width: 200, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.shop_gbn ) {
            var shop = '<font style="color:' + params.data.shop_color + '">' + params.data.shop_name + '</font>';
          } else {
            var shop = '<font style="color:#0000ff;">&nbsp;</font>';
          }

          if( params.data.store_name ) {
            return `${shop} (${params.data.store_name})`;
          } else {
            return shop;
          }
        },
      },
      { headerName: '입금은행', field: 'bank', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          var pcmobile = '';
          var my_card_co = '';

          if ( params.data.saler == 'mobile' ) {
            pcmobile = '<font style="color:red;">m</font>';
          }

          if( params.data.shop_gbn == "bikemart" && params.data.saler != "mobile" && ( params.data.card_co == "inicis" || !params.data.card_co ) ) {
            my_card_co = '[i]';
          }

          return `${pcmobile} ${params.data.bank} ${my_card_co}`;
        }
      },
      { headerName: '최초주문', field: 'org_order_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '현재결제', field: 'pay_amt', width: 100, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '수신자', field: 'r_name', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          var my_r_name = params.data.r_name;

          if ( params.data.shop_gbn == 'bikemart' && params.data.id != '' ) {
            if( params.data.grade == '0004' && params.data.signdate >= params.data.ali_date ) {
              my_r_name = '협력점';
            }
          }

          if( my_r_name == '상사회원' || my_r_name == '협력점' ) {
            return `<font style="color:red;">${my_r_name}</font>`;
          } else {
            return my_r_name;
          }
        }
      },
      { headerName: '주문일자', field: 'order_date', width: 150, cellClass: 'cp center' },
      { headerName: '입금일자', field: 'pay_date', width: 150, cellClass: 'cp center' },
      { headerName: '적립', field: 'in_mileage', width: 70, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '사용', field: 'out_mileage', width: 70, cellClass: 'cp right', valueFormatter: currencyFormatter },
      { headerName: '송장번호', field: 'delivery_no', width: 140, cellClass: 'cp left', editable: true, singleClickEdit: true,
        valueGetter(params: any) {
          let deliveryNo = ( params.data.delivery_no ) ? params.data.delivery_no : '';

          return deliveryNo.replace(/-/g, '');
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.search.memberNo = this.memberNo;

    this.getMemberOrderList();
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellClicked($event) {
      if( $event.colDef.field == 'seq' ) {
        let productSeq = $event.data.seq;
        const url = '/order/detail?seq=' + productSeq;
        window.open("about:blank").location.href = url;
      }
    }

  /*******************************************************************************
    설  명 : 주문내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberOrderList() {
    this.memberService.getMemberOrderList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.memberOrderList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      memberNo: this.memberNo,
      searchText: '',
      sdate: this.utilService.getDate(moment().subtract(90, 'day')),
      edate: this.utilService.getDate(''),
      searchTerm: this.getDays(90),
    };

    this.getMemberOrderList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberOrderList();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }
  
  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
  }
  
}
