import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { BasicService } from '@app/service/basic.service';

import { MemberFindComponent } from '@components/member-find/member-find.component';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';

import * as $ from 'jquery';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order-openmarket-find',
  templateUrl: './order-openmarket-find.component.html',
  styleUrls: ['./order-openmarket-find.component.scss']
})
export class OrderOpenmarketFindComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public orderStatusList: any = [];
  public bankList: any = [];
  public salerList: any = [];
  public shopList: any = [];

  public summernoteConfig: any = config.summernoteConfig;

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public addMemberFunc: any;  // 회원검색

  // 그리드 관련 선언
  orderGridApi: any;
  orderGridColumnApi: any;
  orderColumnDefs: any;

  orderSumGridApi: any;
  orderSumGridColumnApi: any;
  orderSumColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  isRowSelectable: any;
  noRowsTemplate: string;

  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      order_status: ['', [Validators.required] ],
      order_date: [null, [Validators.required] ],
      mem_no: ['', [Validators.required] ],
      o_name: ['', [Validators.required] ],
      id: ['', [Validators.required] ],
      grade_name: ['', [] ],
      o_email: ['', [] ],
      o_hp: ['', [] ],
      o_hp2: ['', [] ],
      bank: ['', [] ],
      receipt_name: ['', [] ],
      saler: ['', [] ],
      shop_gbn: ['', [] ],
      send_letter: ['', [] ],
      r_name: ['', [] ],
      r_hp: ['', [] ],
      r_hp2: ['', [] ],
      unde_flag: ['', [] ],
      r_zipcode: ['', [] ],
      r_address: ['', [] ],
      r_address_detail: ['', [] ],
      r_comment: ['', [] ],
      detail: [[], [Validators.required] ],
      sum: [[], [] ],
      pay: [[], [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private basicService: BasicService,
    private agGridExComponent: AgGridExComponent
  ) {
    this.buildForm();

    this.summernoteConfig.height = 150;

    this.addMemberFunc = this.searchMember.bind(this);

     // ag grid 컬럼 선언
     this.orderColumnDefs = [
      { headerName: '', field: '', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품코드', field: 'product_code', width: 80, cellClass: 'cp center ag-cell80h',  },
      { headerName: '제조사', field: 'brand_name', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          var provider = '';
          var categoryName = '';

          if ( params.data.provider_code ) {
            provider = '<span class="badge badge-light f12">공급자연락처</span><br/>';
          }

          if( params.data.category_name ) {
            categoryName = '<br/><font style="color:red;">' + params.data.category_name + '</font>';
          }

          return `${provider}${params.data.brand_name}${categoryName}`;
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '모델', field: 'product_name', width: 220, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          // 상품홍보문구
          let comment = ( params.data.comment ? params.data.comment : '' );

          return  comment + params.data.product_name;
        }
      },
      { headerName: '이벤트', field: 'event_name', width: 70, cellClass: 'cp right ag-cell80h'
      },
      { headerName: '색상', field: 'color', cellClass: 'cp center ag-cell80h', width: 50, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<span style="display:inline-block;margin-top:32px;width:16px;height:16px;border:solid 1px #ccc;background-color:${params.data.color}">&nbsp;</span>`;
        }
      },
      { headerName: '색상명', field: 'color_name', cellClass: 'cp center ag-cell80h', width: 100,
        valueGetter: function(params) {
          return params.data.color_name + ( params.data.display_color_name ? '<br/>(' + params.data.display_color_name + ')' : '' );
        }
      },
      { headerName: '사이즈', field: 'size_name', cellClass: 'cp center ag-cell80h', width: 70 },
      { headerName: '수량', field: 'qty', width: 70, cellClass: 'cp right ag-cell80h ag-cell-edit',
        editable: function(params) {
          if ( params.data.master_order_status == '0' ) {
            return true;
          } else {
            return false;
          }
        },
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '판매', field: 'amt', width: 100, cellClass: 'cp right ag-cell80h ag-cell-edit',
        editable: function(params) {
          if ( params.data.master_order_status == '0' ) {
            return true;
          } else {
            return false;
          }
        },
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '할인', field: 'discount_amt', width: 100, cellClass: 'cp right ag-cell80h ag-cell-edit',
        editable: function(params) {
          if ( params.data.master_order_status == '0' ) {
            return true;
          } else {
            return false;
          }
        },
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '구매', field: 'buy_price', width: 100, cellClass: 'cp right ag-cell80h ag-cell-edit',
        editable: function(params) {
          if ( params.data.master_order_status == '0' ) {
            return true;
          } else {
            return false;
          }
        },
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '마일리지', field: 'mileage', width: 100, cellClass: 'cp right ag-cell80h ag-cell-edit',
        editable: function(params) {
          if ( params.data.master_order_status == '0' ) {
            return true;
          } else {
            return false;
          }
        },
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '소계', field: 'total_amt', width: 100, cellClass: 'cp right ag-cell80h',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '주문상태', field: 'order_status', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.order_status == '0' ) {
            return '<span class="badge badge-secondary f12">주문접수</span>';
          } else if ( params.data.order_status == '1' ) {
            return '<span class="badge badge-secondary f12">결제확인</span>';
          } else if ( params.data.order_status == '2' ) {
            return '<span class="badge badge-success f12">배송준비</span>';
          } else if ( params.data.order_status == '3' ) {
            return '<span class="badge badge-danger f12">전체반품</span>';
          } else if ( params.data.order_status == '4' ) {
            return '<span class="badge badge-warning f12">일부반품</span>';
          } else if ( params.data.order_status == '5' ) {
            return '<span class="badge badge-danger f12">전체교환</span>';
          } else if ( params.data.order_status == '6' ) {
            return '<span class="badge badge-warning f12">일부교환</span>';
          } else if ( params.data.order_status == '7' ) {
            return '<span class="badge badge-danger f12">주문취소</span>';
          } else if ( params.data.order_status == '8' ) {
            return '<span class="badge badge-primary f12">발송완료</span>';
          } else if ( params.data.order_status == '9' ) {
            return '<span class="badge badge-info f12">주문완료</span>';
          } else {
            return '<span class="badge badge-light f12">문의</span>';
          }
        }
      },
    ];

     // ag grid 컬럼 선언
     this.orderSumColumnDefs = [
      { headerName: '', field: '', width: 960, cellClass: 'cp center' },
      { headerName: '수량합계', field: 'sum_qty', width: 100, cellClass: 'cp center', hide: true },
      { headerName: '판매합계', field: 'sum_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '할인합계', field: 'sum_discount_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '구매합계', field: 'sum_buy_price', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '마일리지합계', field: 'sum_mileage', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '합계', field: 'sum_total_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '', field: '', width: 100, cellClass: 'cp center' }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 가져오기
    this.getCommonList();

    // 은행 계좌 리스트 가져오기
    this.getCompanyAccountList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 주문상태
    this.commonService.getCommonListCode('ORS').then( response => {
      if ( response.ResultCode ) {

        this.orderStatusList.push({value: '', title: '주문상태를 선택하세요'});

        response.data.forEach( row => {
          this.orderStatusList.push({
            value: row.common_code,
            title: row.common_name
          });
        });

      } else {
        this.orderStatusList = [];
      }
    });

    // 판매처
    this.commonService.getCommonListCode('SLC').then( response => {
      if ( response.ResultCode ) {

        this.salerList.push({value: '', title: '판매경로를 선택하세요'});

        response.data.forEach( row => {
          this.salerList.push({
            value: row.common_code,
            title: row.common_name
          });
        });

      } else {
        this.salerList = [];
      }
    });

    // SHOP
    this.commonService.getCommonListCode('SHP').then( response => {
      if ( response.ResultCode ) {

        this.shopList.push({value: '', title: 'SHOP을 선택하세요'});

        response.data.forEach( row => {
          this.shopList.push({
            value: row.common_code,
            title: row.common_name
          });
        });

      } else {
        this.shopList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 은행 계좌 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyAccountList() {
    this.basicService.getCompanyAccountList().then( response => {
      if ( response.ResultCode ) {

        this.bankList.push({value: '', title: '입금하실 은행 또는 카드를 선택하세요'});

        response.data.forEach( row => {
          this.bankList.push({
            value: row.seq,
            title: row.bank_name + ( row.bank_account ? ' : ' + row.bank_account : '' )
          });
        });

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 오픈마켓 주문 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOpenmarketOrderSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      // this.openmarketService.setOrderMemoSave(this.form).then( response => {
      //   if ( response.ResultCode ) {
      //     this.toastrService.success( response.ResultMessage, '주문 저장');
      //   } else {
      //     this.toastrService.error( response.ResultMessage, '주문 저장 ');
      //   }
      // });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '주문 저장');
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onOrderGridReady(params) {
    this.orderGridApi = params.api;
    this.orderGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onOrderSumGridReady(params) {
    this.orderSumGridApi = params.api;
    this.orderSumGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 셀 변경 종료 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStopped( event: any ) {
    let rowData = event.data;

    rowData.total_amt = ( rowData.amt - rowData.discount_amt ) * rowData.qty;
    rowData.mileage = rowData.total_amt / 100;

    event.node.setData( rowData );

    this.onRowDataChanged( event );
  }

  /*******************************************************************************
    설  명 : 주문내역 총 합계 구하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDataChanged( event: any ) {

    let sumQty = 0;
    let sumAmt = 0;
    let sumDiscountAmt = 0;
    let sumBuyPrice = 0;
    let sumMileage = 0;
    let sumTotalAmt = 0;

    event.api.forEachNodeAfterFilter( function(node) {
      sumQty += parseInt(node.data.qty);
      sumAmt += parseInt(node.data.amt);
      sumDiscountAmt += parseInt(node.data.discount_amt);
      sumBuyPrice += parseInt(node.data.buy_price);
      sumMileage += parseInt(node.data.mileage);
      sumTotalAmt += parseInt(node.data.total_amt);
    });

    let tmp: any = [];
    tmp.push({
      sum_qty: sumQty,
      sum_amt: sumAmt,
      sum_discount_amt: sumDiscountAmt,
      sum_buy_price: sumBuyPrice,
      sum_mileage: sumMileage,
      sum_total_amt: sumTotalAmt,
    });

    this.form.patchValue({ sum: tmp });
  }

  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        mem_no: result.data.mem_no,
        id: result.data.id,
        o_name: result.data.name,
        o_hp2: result.data.tel,
        o_hp: result.data.hp,
        o_email: result.data.email,
        grade_name: result.data.grade_name
      });

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      r_zipcode: data.zip,
      r_address: data.addr
    });

    $("#r_address_detail").focus();
  }

}
