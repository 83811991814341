/*******************************************************************************
  설  명 : 권한그룹 추가/수정
  작성일 : 2019-09-27
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SystemAuthGroupService } from '@app/service/auth.group.service';
import { UtilService } from '@app/service/util.service';
import { ApiResponse } from '@app/service/api-response';

@Component({
  selector: 'app-auth-group-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AuthGroupAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public selectedItem: any = {
    seq: '',
    name: ''
  };

  public title: string = '추가';

  public form: FormGroup;
  formErrors = {};
  errorResponse: ApiResponse;

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ["", []],
      name:["", [Validators.required]],
      member_level:["", [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private systemAuthGroupService: SystemAuthGroupService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    if( this.selectedItem.seq == 0 ) {
      this.title = '추가';
    } else {
      this.title = '수정';
    }

    this.form.patchValue( this.selectedItem );
  }

  /*******************************************************************************
    설  명 : 권한그룹 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid){
      this.systemAuthGroupService.setAuthGroup( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success('정상적으로 등록되었습니다.', '');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      })
      .catch(response => {
        this.toastrService.error('등록에 실패하였습니다.', '');
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 권한그룹 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    this.systemAuthGroupService.deleteAuthGroup( this.form ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '권한그룹 저장');
        this.activeModal.close();

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
    .catch(response => {
      this.toastrService.error('삭제에 실패하였습니다.', '');
      this.errorResponse = response;
      this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
    });
  }
}
