<div class="modal-header">
  <h4 *ngIf="seq!=0" class="modal-title fb mb0">주소 상세정보</h4>
  <h4 *ngIf="seq==0" class="modal-title fb mb0">주소 추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <div id="member-info" class="p10">

    <form [formGroup]="form">
    <inputEx type="hidden" name="mem_no" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">주소정보</caption>
    <colgroup>
      <col style="width:30%;" />
      <col style="width:70%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="isDefault">기본주소여부</label></th>
      <td>
        <div class="form-inline">
          <label for="isDefault" class="ml5">
            <input type="checkbox" id="isDefault" formControlName="isDefault" value="true" [checked]="form.controls.isDefault.value" />&nbsp;기본주소로 지정
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="zipcode">우편번호</label></th>
      <td>
        <div class="input-group">
          <inputEx name="zipcode" class="mr3" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
          <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="address">주소</label></th>
      <td><inputEx type="text" name="address" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="address_detail">상세주소</label></th>
      <td><inputEx type="text" name="address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>

    </tbody>
    </table>

    </form>

  </div>

</div>

<div class="modal-footer">
  <div class="fl w50p">
    <button *ngIf="seq!=0" type="button" class="btn btn-danger btn-small" (click)="setMemberAddressDelete()">주소정보 삭제</button>
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="setMemberAddressSave()">저장하기</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
  </div>
</div>
