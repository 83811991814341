import { Injectable } from '@angular/core';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalDocumentService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    return Promise.reject(error.message || error);
  }


  // 공문서접수 리스트 가져오기
  getReceiveList(search: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'approval_document',
        version: '1.0',
        action: 'getReceiveList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공문서접수대장 저장
  setAddDocumentReceive( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval_document',
        version: '1.0',
        action: 'setAddDocumentReceive'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공문서접수대장 수정
  setEditDocumentReceive( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval_document',
        version: '1.0',
        action: 'setEditDocumentReceive'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 공문서접수대장 삭제
  setDeleteDocumentReceive(uid: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'approval_document',
      version: '1.0',
      action: 'setDeleteDocumentReceive'
      }, {
        uid: uid
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공문서접수대장 첨부파일 삭제
  setDeleteUploadFile(seq: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'approval_document',
      version: '1.0',
      action: 'setDeleteUploadFile'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

   // 공문서시행대장 삭제
   setDeleteDocumentExcute(uid: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'approval_document',
      version: '1.0',
      action: 'setDeleteDocumentExcute'
      }, {
        uid: uid
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공문서시행대장 리스트
  getExcuteList(search: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'approval_document',
      version: '1.0',
      action: 'getExcuteList'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공문서시행대장 수정
  setEditDocumentExcute( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'approval_document',
        version: '1.0',
        action: 'setEditDocumentExcute'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


} 