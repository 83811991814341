import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { CommonService } from '@app/service/common.service';
import { BikeService } from '@app/service/bike.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { BikePayhelperDetailStatusComponent } from '@page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail-status/bike-payhelper-detail-status.component';
import { BikePayhelperDetailCalcComponent } from '@page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail-calc/bike-payhelper-detail-calc.component';
import { BikePayhelperDetailPrintComponent } from '@page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail-print/bike-payhelper-detail-print.component';
import { BikePayhelperDetailDateComponent } from '@page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail-date/bike-payhelper-detail-date.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'md',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-bike-payhelper-detail',
  templateUrl: './bike-payhelper-detail.component.html',
  styleUrls: ['./bike-payhelper-detail.component.scss']
})
export class BikePayhelperDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};
  
  public helperInfo: any = {};
  public uploadImageView: any = {
    url: '',
    origin: ''
  };

  public sellImageList: any = [];
  public helperStatusDateList: any = [];
  public helperPayList: any = [];
  public helperCommentList: any = [];

  public activeTab: any = 0;

  public params: any = {
    pageNo: 1,
    pageRow: 20,
    totalCount: 0,
    seq: '',
    searchText: '',
    status: '',
    sdate: '',
    edate: '',
    is_display: '',
    category_code: '',
    is_card_installment: '',
    approval_yn: '',
    mileage: '',
    safe_stock: ''
  };

  // 그리드 관련 선언
  payhelperList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  memoGridApi: any;
  memoGridColumnApi: any;
  memoColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public setBikeHelperCommentSaveFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      helper_seq: ['', [Validators.required]],
      category: ['A', [Validators.required]],
      comment: ['', [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  
  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private bikeService: BikeService,
    private commonService: CommonService,
    private agGridExComponent: AgGridExComponent
  ) {
    this.buildForm();

    this.setBikeHelperCommentSaveFunc = this.setBikeHelperCommentSave.bind(this);

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', cellClass: 'cp center', width: 68 },
      { headerName: '상태', field: 'app_gbn', cellClass: 'cp center', width: 68, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.app_gbn == '1' )
            return '<span class="badge badge-primary f12 fn">정상</span>';
          else
            return '<span class="badge badge-danger f12 fn">취소</span>';
        }
      },      
      { headerName: '결제구분', field: 'pay_gbn', cellClass: 'cp center', width: 80 },
      { headerName: 'PG사', field: 'pg_group_name', cellClass: 'cp center', width: 80 },
      { headerName: '결제수단', field: 'pay_method_name', cellClass: 'cp center', width: 100 },
      { headerName: '승인번호', field: 'app_no', cellClass: 'cp center', width: 100, editable: true, singleClickEdit: true },
      // { headerName: '승인일시', field: 'app_datetime', cellClass: 'cp center', width: 150 },
      { headerName: '결제일시', field: 'pay_date', cellClass: 'cp center', width: 150 },
      { headerName: '결제금액', field: 'pay_amt', cellClass: 'cp right',  width: 100, 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      // { headerName: '취소일자', field: 'cnl_date', cellClass: 'cp center', width: 100 },
      { headerName: '상점아이디', field: 'mid', cellClass: 'cp center', width: 100 },
      { headerName: 'OID', field: 'oid', cellClass: 'cp center', width: 190, editable: true, singleClickEdit: true },
      { headerName: 'TID', field: 'tid', cellClass: 'cp center', width: 310, editable: true, singleClickEdit: true },
      { headerName: '카드(계좌)번호', field: 'card_no', cellClass: 'cp center', width: 150 },
      { headerName: '카드(계좌)명', field: 'card_name', cellClass: 'cp center', width: 120 },
      { headerName: '할부기간', field: 'installment_period', cellClass: 'cp', width: 80 },
      { headerName: '등록자', field: 'writer_name', cellClass: 'cp', width: 80 },
    ];

    // ag grid 컬럼 선언
    this.memoColumnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '카테고리', field: 'category', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.category == 'A' ) {
            return '<span class="badge badge-secondary f12">관리용</span>';
          } else {
            return '<span class="badge badge-warning f12">공지용</span>';
          }
        }
      },
      { headerName: '작성자구분', field: 'writer_gubun', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.writer_gubun == 'admin' ) {
            return '<span class="badge badge-danger f12">관리자</span>';
          } else if( params.data.writer_gubun == 'seller' ) {
            return '<span class="badge badge-primary f12">판매자</span>';
          } else {
            return '<span class="badge badge-success f12">구매자</span>';
          }
        }
      },
      { headerName: '내용', field: 'comment', width: 1000, cellClass: 'cp' },
      { headerName: '등록자', field: 'writer_name', width: 100, cellClass: 'cp center' },
      { headerName: '등록일시', field: 'write_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    // this.getCommonListCode();

    // 입력 파라미터 처리
    this.activatedRoute.queryParams.subscribe( async params => {
      this.params.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
      this.params.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow;
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '0' : params.seq;
      this.params.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? 'sell_seq' : params.searchField;
      this.params.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.params.status = ( typeof params.status == 'undefined' || params.status == '' ) ? '' : params.status;
      this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.params.searchTerm = ( typeof params.searchTerm == 'undefined' || params.searchTerm == '') ? '' : params.searchTerm;
      this.params.status = ( typeof params.status == 'undefined' ) ? '' : params.status;
      this.params.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;

      this.form.patchValue({
        helper_seq: this.params.seq
      });

      if( this.params.seq !== '0' ) {
        // 상품 상세정보 가져오기
        this.getBikePayhelperDetail();
      }
    }).unsubscribe();

  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonListCode() {
    // 결제도우미 상태
    // this.commonService.getCommonListCode('BHS').then( response => {
    //   if ( response.ResultCode ) {
    //     this.helperStatusList = response.data;
    //   } else {
    //     this.helperStatusList = [];
    //   }
    // });
  }
  
  /*******************************************************************************
    설  명 : 결제도우미 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBikePayhelperDetail() {
    this.bikeService.getBikePayhelperDetail( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.helperInfo = response.data;
        this.helperStatusDateList = response.status;
        this.helperCommentList = response.comment;
        this.sellImageList = response.image;
        this.helperPayList = response.pay;

        if( this.sellImageList.length > 0 ) this.setUploadImageView( this.sellImageList[0] );
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 이미지 크게 보기
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  setUploadImageView( file: any ) {
    this.uploadImageView.url = file.url;
    this.uploadImageView.origin = file.origin;
  }

  /*******************************************************************************
    설  명 : 이미지 목록 길이 클래스
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  getWidth( array: any ) {
    let cnt: any = Math.floor( array.length / 4 );

    return `w${cnt}00`;
  }
  
  /*******************************************************************************
    설  명 : 대표이미지 설정
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  setUploadImageDefault( file: any ) {
    if( confirm("대표이미지로 설정하시겠습니까?") ) {
      this.bikeService.setUploadImageDefault( file ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getBikePayhelperDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.bikeService.setDeleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getBikePayhelperDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 결제확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikePayhelperConfirm() {
    if( this.helperInfo.status !== '3000' ) {
      this.toastrService.info('[결제확인중] 상태에서만 처리가 가능합니다.');
    } else {
      if ( confirm('결제확인 처리하시겠습니까?') ) {
        this.bikeService.setBikePayhelperConfirm( JSON.stringify([this.params.seq]) ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success(response.ResultMessage);

            this.getBikePayhelperDetail();
          } else {
            this.toastrService.error(response.ResultMessage);
          }
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 진행상태 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openBikePayhelperStatusChange(row: any) {
    let index: any = this.helperStatusDateList.findIndex((obj: any) => obj.common_code == row.common_code);
    const currentStatus = this.helperStatusDateList[index];

    let prevIndex: any = this.helperStatusDateList.findIndex((obj: any) => obj.common_code.substr(0,1) == (parseInt(currentStatus.common_code.substr(0,1)) - 1));
    const prevStatus = this.helperStatusDateList[prevIndex];    

    if( ( row.app_date === null && this.helperInfo.status !== currentStatus.common_code ) && this.helperInfo.status !== prevStatus.common_code ) {
      this.toastrService.info(`[${prevStatus.common_name}] 상태일 때 처리가 가능합니다.`);
    } else {
      const modalRef = this.modalService.open(BikePayhelperDetailStatusComponent, options);
  
      modalRef.componentInstance.status_seq = row.seq;
      modalRef.componentInstance.helper_seq = this.params.seq;
      modalRef.componentInstance.statusInfo = currentStatus;
  
      modalRef.result.then((result) => {
        if( result ) this.getBikePayhelperDetail();
      }, (reason) => {
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 거래완료
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikePayhelperComplete() {
    if( this.helperInfo.status !== '7000' && this.helperInfo.status !== '7010' ) {
      this.toastrService.info('[정산승인] 또는 [정산대기] 상태에서만 거래완료 처리가 가능합니다.');
    } else {
      if ( confirm('거래완료 처리하시겠습니까?') ) {
        this.bikeService.setBikePayhelperComplete( this.params.seq ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success(response.ResultMessage);

            this.getBikePayhelperDetail();
          } else {
            this.toastrService.error(response.ResultMessage);
          }
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 거래취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikePayhelperCancle() {
    if( this.helperInfo.status >= '8000' ) {
      this.toastrService.info('거래완료 이후에는 취소하실 수 없습니다.');
    } else {
      if ( confirm('거래취소 하시겠습니까?') ) {
        this.bikeService.setBikePayhelperCancle( this.params.seq ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success(response.ResultMessage);

            this.getBikePayhelperDetail();
          } else {
            this.toastrService.error(response.ResultMessage);
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 코멘트 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikeHelperCommentSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.bikeService.setBikeHelperCommentSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.form.reset();

          this.form.patchValue({
            helper_seq: this.params.seq
          });

          this.getBikePayhelperDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.warning('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
  
  /*******************************************************************************
    설  명 : 코멘트 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikeHelperCommentDelete() {
    const nodes = this.memoGridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.info( '삭제할 코멘트를 선택하세요.', '' );
      return false;
    }

    const data: any = [];
    nodes.forEach(item => {
      data.push(item.data);
    });

    if ( confirm('선택한 코멘트를 삭제하시겠습니까?') ) {
      this.bikeService.setBikeHelperCommentDelete( data ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getBikePayhelperDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 수수료 재정산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikePayHelperCalc() {
    if ( confirm('정말로 수수료를 재정산하시겠습니까?') ) {
      this.bikeService.setBikePayHelperCalc( this.params.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getBikePayhelperDetail();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 수수료 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openBikePayHelperCalcEdit() {
    if( this.helperInfo.status >= '8000' ) {
      this.toastrService.info('거래완료 이후에는 수정하실 수 없습니다.');
    } else {
      const modalRef = this.modalService.open(BikePayhelperDetailCalcComponent, options);
  
      modalRef.componentInstance.helperSeq = this.params.seq;
      modalRef.componentInstance.helperInfo = this.helperInfo;
  
      modalRef.result.then((result) => {
        if( result ) this.getBikePayhelperDetail();
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 정산예정일 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openPayHelperCalculateDate() {
    if( this.helperInfo.status != '7000' ) {
      this.toastrService.info('[정산승인] 상태에서만 저장할 수 있습니다.');
    } else {
      const modalRef = this.modalService.open(BikePayhelperDetailDateComponent, options);
  
      modalRef.componentInstance.helperSeq = this.params.seq;
  
      modalRef.result.then((result) => {
        if( result ) this.getBikePayhelperDetail();
      }, (reason) => {
      });
    }
  }
  
  
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {

  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onMemoGridReady(params) {
    this.memoGridApi = params.api;
    this.memoGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabSetValue( value: any ) {
    this.activeTab = value;
  }

  /*******************************************************************************
    설  명 : 출력 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  print() {
    const modalRef = this.modalService.open(BikePayhelperDetailPrintComponent, optionsLG);

    modalRef.componentInstance.helperInfo = this.helperInfo;
    modalRef.componentInstance.helperPayList = this.helperPayList;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 리스트로 돌아가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.params.seq = '';

    this.router.navigate(
      ['/bike/payhelper'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }
  
  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }
  
  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }
  
  /*******************************************************************************
    설  명 : 휴대폰번호 하이픈 추가
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  setHyphen(value: any) {
    if( !value || value === null ) return null;
    if( value.indexOf('-') > -1 ) return value;

    return `${value.substr(0,3)}-${value.substr(3,4)}-${value.substr(7,4)}`;
  }

}
