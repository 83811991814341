<ul class="gallery-ul clearfix">
  <li *ngFor="let row of data" class="cp" (click)="view(row)">
    <div class="img">
      <img *ngIf="!row.upload_file" src="/assets/admin/images/board_img.png">
      <img *ngIf="row.thumbnail; else uploadFileTemplate" [src]="baseURL + row.thumbnail">
      <ng-template #uploadFileTemplate>
        <img [src]="row.upload_file">
      </ng-template>
    </div>
    <div class="title">
      <div class="clearfix">
        <p class="fl">{{row.write_date}}    {{row.writer_name}}</p>
        <p class="fr">조회수 : {{row.hit}}</p>
      </div>
      <p class="title1 clearfix">
        <span class="fl" [innerHTML]="row.subject"></span>
        <i *ngIf="row.uploadCount > 0" class="material-icons file-ico fl">&#xe226;</i>
        <span class="ico-firstfiber_new new-ico"></span>
      </p>
    </div>
  </li>
</ul>
