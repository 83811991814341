<section class="fl code-list-area">
  <div class="page-title">
    <h3 class="fl">직위리스트</h3>
    <div class="fr pt5">
      <ng-container *ngIf="authService.currAuth['auth_write']=='1'">
        <a title="직위 추가" (click)="addPosition()"><i class="add-btn material-icons cp">add_circle</i></a>
      </ng-container>
    </div>

    <div class="cb"></div>
  </div>

  <div class="treeview cp" *ngIf="positionList?.length > 0">
    <ng-template #nodeTemplate let-node>
      <div class="menu content p10" [ngClass]="{active:selectedNode.seq==node.seq}" (click)="selectedPosition(node)">
        <span pioneer-tree-collapse [node]="node" class="mr5">
          <i class="material-icons f12" [innerText]="this.node.pioneerTreeNode.isCollapsed() ? 'folder' : 'folder_open'"></i>
        </span>
        <span>{{node.name}}</span>
        <span pioneer-tree-handle [node]="node" class="fr">
          <i class="material-icons f14">drag_handle</i>
        </span>
      </div>
    </ng-template>
    <ng-template #repeaterTemplate let-node>
      <ul pioneer-tree-repeater [nodes]="node.children">
        <li pioneer-tree-node *ngFor="let node of node.children" (nodeDropped)="onNodeDropped($event)" [nodeTemplate]="nodeTemplate"
          [repeaterTemplate]="repeaterTemplate" [node]="node">
        </li>
      </ul>
    </ng-template>
    <ul pioneer-tree #pt [configuration]="configuration" [nodes]="positionList">
      <li pioneer-tree-node
        *ngFor="let node of positionList"
        (nodeDropped)="onNodeDropped($event)"
        [nodeTemplate]="nodeTemplate"
        [repeaterTemplate]="repeaterTemplate"
        [node]="node">
      </li>
    </ul>
   </div>
  <div class="treeview" *ngIf="positionList?.length < 1">
    <span>등록된 직위가 없습니다.</span>
  </div>

</section>

<section class="fr code-area">

  <section class="code-head">
    <div>
      <h3 class="code-title">선택된 직위 정보</h3>
    </div>
  </section>

  <form [formGroup]="positionForm" (ngSubmit)="submit()">
    <inputEx type="hidden" name="seq" [formGroup]="positionForm" [formErrors]="formErrors"></inputEx>
    <inputEx type="hidden" name="sort_order" [formGroup]="positionForm" [formErrors]="formErrors"></inputEx>

    <div class="mb5">
      <table class="table table-bordered table-small mb0">
      <colgroup>
        <col style="width:20%" />
        <col style="width:80%" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row" class="active"><label for="name">직위명</label></th>
        <td>
          <inputEx type="text" name="name" [formGroup]="positionForm" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row" class="active"><label for="use_yn">사용여부</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:positionForm.controls.use_yn.value=='1'}" (click)="positionForm.patchValue({use_yn:'1'})">사용</button>
            <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:positionForm.controls.use_yn.value=='0'}" (click)="positionForm.patchValue({use_yn:'0'})">사용안함</button>
          </div>
          <inputEx type="hidden" name="use_yn" [formGroup]="positionForm" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
    </div>

    <div class="fl w50p">
      <ng-container *ngIf="authService.currAuth['auth_delete']=='1'">
        <button type="button" class="btn btn-danger btn-small cwhite" (click)="deletePosition()">직위삭제</button>
      </ng-container>
    </div>

    <div class="fr w50p right">
      <ng-container *ngIf="(positionForm.controls.seq.value!=0 && positionForm.controls.seq.value!='') && authService.currAuth['auth_modify']=='1'">
        <button type="submit" class="btn btn-primary btn-small">저장하기</button>
      </ng-container>
      <ng-container *ngIf="(positionForm.controls.seq.value==0 || positionForm.controls.seq.value=='') && authService.currAuth['auth_write']=='1'">
        <button type="submit" class="btn btn-primary btn-small">저장하기</button>
      </ng-container>
    </div>
    <div class="cb"></div>

  </form>

</section>