/*******************************************************************************
  설  명 : 재고관리 - 입고관리
  작성일 : 2021-05-18
  작성자 : 송영석
  접속URL : /stock/in/management

  수정일 : 2021.05.25
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { StockIn } from '@app/service/stockIn.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';

@Component({
  selector: 'app-stock-in-management',
  templateUrl: './stock-in-management.component.html',
  styleUrls: ['./stock-in-management.component.scss']
})
export class StockInManagementComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  stockOrderList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };
  optionsXXL: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xxl',
    centered: true,
    windowClass: 'modal-fadeInDown'
  };
  rowClassRules

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private stockIn: StockIn,
    private modalService: NgbModal,
    private stockOrderComponent: StockOrderComponent,
  ) {
    // 입고리스트 grid
    this.columnDefs = [
      this.stockOrderComponent.firstColumn,
      { headerName: '입고구분', field: 'inout_gbn_name', width: 80, cellClass: 'cp center',
        // valueGetter: (params) => {
        //   switch( parseInt(params.data.inout_gbn) ) {
        //     case 1000: return '기초재고';
        //     case 1010: return '구매입고';
        //     case 1020: return '이동입고';
        //     case 1030: return '반품입고';
        //     case 1040: return '기타입고';
        //     default: return params.data.inout_gbn;
        //   }
        // }
      }, // 발주입고, 기초/기타 입고
      { headerName: '결재', field: 'approval', width: 80, cellClass: 'cp center' }, // 미결재, 결재상신, 결재완료
      { headerName: '상품명', field: 'product_name', width: 150, cellClass: 'cp center' },
      { headerName: '색상', field: 'display_color_name', width: 80, cellClass: 'cp center' },
      { headerName: '사이즈', field: 'display_size_name', width: 80, cellClass: 'cp center' },
      { headerName: '발주구분', field: 'purchase_gbn', width: 80, cellClass: 'cp center',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) == '1' ? '발주입고' : '기타입고'
      }, // 발주입고, 기초입고
      { headerName: '발주일자', field: 'purchase_date', width: 90, cellClass: 'cp center' },
      { headerName: '발주수량', field: 'purchase_cnt', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)
      },
      { headerName: '발주단가', field: 'unit_amt', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)
      },
      { headerName: '발주금액', field: 'amt', width: 90, cellClass: 'cp right',
        valueGetter: (params) => ((params.data.purchase_cnt * params.data.unit_amt) || 0).toLocaleString()
      },
      { headerName: '입고수량', field: 'inout_qty', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)
      },
      { headerName: '입고단가', field: 'stock_history_unit_amt', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)
      },
      { headerName: '입고금액', field: 'stock_history_amt', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)
      },
      { headerName: '입고일자', field: 'inout_date', width: 150, cellClass: 'cp',
        valueGetter: (params) => (params.data.inout_date != '0000-00-00 00:00:00' ? params.data.inout_date : '')
      },
      { headerName: '입고창고', field: 'warehouse_name', width: 80, cellClass: 'cp' },
      { headerName: '입고위치', field: 'location_cd', width: 80, cellClass: 'cp' },
      { headerName: '비고', field: 'memo', width: 150, cellClass: 'cp' },
      { headerName: '결재상신일', field: 'approval_date', width: 120, cellClass: 'cp center' },
      { headerName: '결재상신자', field: 'approvaler', width: 90, cellClass: 'cp center' },
      { headerName: '등록자', field: 'writer', width: 80, cellClass: 'cp center' },
      { headerName: '등록일시', field: 'write_date', width: 120, cellClass: 'cp center' },
      { headerName: '수정자', field: 'changer', width: 80, cellClass: 'cp center' },
      { headerName: '수정일시', field: 'change_date', width: 120, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = this.stockOrderComponent.defaultColDef;
    this.rowSelection = this.stockOrderComponent.rowSelection;
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate;
    this.rowClassRules = {
      'status4': params => params.data.inout_gbn_name == '기초재고' || params.data.inout_gbn_name == '재고조정입고' || params.data.inout_gbn_name == '실사조정입고',
      'status2': params => params.data.inout_gbn_name == '구매입고',
      'status1': params => params.data.inout_gbn_name == '반품입고' || params.data.inout_gbn_name == 'AS입고',
      'status8': params => params.data.inout_gbn_name == '교환입고' || params.data.inout_gbn_name == '이동입고'
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();
    this.getStockIn();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  // dateFormat(date) {
  //   const _2 = number => (`0${String(number)}`).slice(-2);
  //   try {date.getFullYear()} catch {return null}
  //   return ({
  //     year : date.getFullYear(),
  //     month : date.getMonth() + 1,
  //     day : date.getDate()
  //   });
  // }
  buildForm(): void {
    this.form = this.formBuilder.group({
      inout_gbn : [0],
      approval : [0],
      product_seq : [[]],
      dealer : [''],
      s_date : [this.stockOrderComponent.dateFormat( new Date(new Date().getTime() - 2592000000) )],
      e_date : [this.stockOrderComponent.dateFormat(new Date())],
      stockOrderList : [[]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 기초/기타 입고
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  stockInBase() {
    this.router.navigate(
      ['/stock/in/management/base'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 발주입고
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  stockInPurchase() {
    this.router.navigate(
      ['/stock/in/management/purchase'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 입고 목록 가져오기
  *******************************************************************************/
  getStockIn() {
    const _FV = this.form.value;
    this.stockIn.getStockIn({
      inout_gbn : _FV['inout_gbn'],
      approval : _FV['approval'],
      product_seq : _FV['product_seq'],
      dealer : _FV['dealer'],
      s_date : _FV['s_date'],
      e_date : _FV['e_date']
    }).then(response => {
      if(response.ResultCode) {
        if(!response.data.length) this.toastrService.warning('데이터가 없습니다.');
        this.form.patchValue({
          stockOrderList : response.data
        });
      }
    });
  }
  /*******************************************************************************
    설  명 : 검색조건 버튼 클릭
  *******************************************************************************/
  btnClick(which, value) {
    this.form.patchValue({[which] : value});
    this.getStockIn();
  }

  /*******************************************************************************
    설  명 : 결재상신 버튼 클릭
  *******************************************************************************/
  approval() {
    const _R = this.gridApi.getSelectedRows();
    if(!_R.length) {
      this.toastrService.warning('선택된 데이터가 없습니다.', '');
      return;
    }
    alert('미구현');
  }

  /*******************************************************************************
    설  명 : 상품 검색 모달 띄우기
  *******************************************************************************/
  addProductModal() {
    const modalRef = this.modalService.open(ProductFindPropertyComponent, this.optionsXXL);
    modalRef.result.then((result) => {
      this.searchProduct(result);
    }, (reason) => {
    });
  }
  /*******************************************************************************
    설  명 : 상품검색에 따른 검색 실행
  *******************************************************************************/
  searchProduct(data) {
    this.form.patchValue({
      product_seq : data.map(e => e['seq'])
    });
    this.getStockIn();
  }
  /*******************************************************************************
    설  명 : 검색조건 초기화
  *******************************************************************************/
  reset() {
    this.ngOnInit();
  }
  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(which, value) {
    const _V = [];
    for(const key in value) _V.push(value[key]);
    this.btnClick( which, this.stockOrderComponent.dateFormat( new Date( _V.join('-') ) ) );
  }

}
