<section id="member-memo">
  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberMemoList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="내용 검색" />

      <input type="text" readonly="readonly" [(ngModel)]="search.sdate" size="13" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" readonly="readonly" [(ngModel)]="search.edate" size="13" class="form-control form-control-small calendar-input m15" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>

    </div>
    <div class="fr w30p right">
      <ng-container *ngIf="dormant===false">
        <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="365db99b63344fd1975560fe8c6715b6" [btnGroup]="1"></btn-ag-grid-save>
        <button type="button" class="btn btn-danger btn-small ml5" (click)="setMemberMemoDelete()">선택 메모 삭제</button>
      </ng-container>
    </div>
    <div class="cb"></div>
  </div>

  <hr class="db mt5 mb5" />

  <ng-container *ngIf="dormant===false">
    <form [formGroup]="form">
      <div class="mb5">
        <!-- <div class="mb5 form-inline">
          <div class="btn-group mr5">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.type.value=='1'}" (click)="form.patchValue({type: '1'})">전화접수</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.type.value=='0'}" (click)="form.patchValue({type: '0'})">이메일접수</button>
          </div>

          <inputEx type="text" name="type_data" [formGroup]="form" [formErrors]="formErrors" placeholder="전화번호 or 이메일주소" [addOn]="setMemberHPEmailFunc" addOnText="회원정보입력"></inputEx>

          <label class="checkbox ml5">
            <input type="checkbox" name="emailBlocked" formControlName="emailBlocked" value="1" />
            &nbsp;이메일수신거부
          </label>
          <label class="checkbox ml5">
            <input type="checkbox" name="smsBlocked" formControlName="smsBlocked" value="1" />
            &nbsp;SMS수신거부
          </label>
          <label class="checkbox ml5">
            <input type="checkbox" name="kakaoBlocked" formControlName="kakaoBlocked" value="1" />
            &nbsp;카카오수신거부
          </label>
        </div> -->

        <inputEx
          type="textarea"
          name="message"
          [formGroup]="form"
          [formErrors]="formErrors"
          placeholder="메모내역 작성"
          rows="2"
          [addOn]="setMemberMemoSaveFunc"
          addOnText="메모등록"
        ></inputEx>
      </div>
    </form>
  </ng-container>

  <div class="" style="height:calc(100% - 170px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="memberMemoList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (rowClicked)="onRowClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
