import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { SmsService } from '@app/service/sms.service';
import { OrderService } from '@app/service/order.service';

@Component({
  selector: 'app-order-returnreq-detail',
  templateUrl: './order-returnreq-detail.component.html',
  styleUrls: ['./order-returnreq-detail.component.scss']
})
export class OrderReturnreqDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() cancelData: any;
  public reg_gbn: any;
  public form: FormGroup;
  public formErrors = {};

  public baseURL = config.baseUrl;

  public title = '';
  public cancelList: any = [];
  public canceldetail: any = [];

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      message: ['', [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private orderService: OrderService,
    private toastrService: ToastrService,
    private smsService:SmsService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCancelDetailOrder();

    this.form.patchValue({
      message: this.cancelData.message
    });
  }

  /*******************************************************************************
    설  명 : 기초설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setTitle() {
    this.reg_gbn = this.cancelList.reg_gbn;
    if (this.reg_gbn == '2000') {
      this.title = '반품';
    } else if (this.reg_gbn == '3000') {
      this.title = '교환';
    }
  }

  /*******************************************************************************
    설  명 : 반품, 교환 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCancelDetailOrder() {
    this.orderService.getCancelDetailOrder( this.cancelData.req_seq ).then( response => {
      if ( response.ResultCode ) {
        this.cancelList = response.data[0];
        this.canceldetail = this.cancelList.detail;
        this.setTitle();
      }
    });
  }

  /*******************************************************************************
    설  명 : 선택 승인/미승인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  approval( num ) {
    if ( num == '2' ) {
      this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

      if ( this.form.valid ) {
        this.orderService.setApprovalSave( this.cancelData.req_seq, num, this.form).then( response => {
          if ( response.ResultCode ) {
            // sms 자동발송
            let params: any = [
              {
                seq: this.cancelData.req_seq,
                hp: this.cancelData.o_hp
              }
            ];

            this.smsService.send_smsConfirmExchange( params ).then( response => {
              params = [];
              if ( response.data.ResultCode ) {
                this.toastrService.success(response.data.ResultMessage);
              } else {
                this.toastrService.error(response.data.ResultMessage);
              }
            });

            this.toastrService.success('변경사항을 저장하였습니다.');
            this.activeModal.close();
          }
        });
      } else {
        this.toastrService.error('사유를 입력해 주세요.');
      }
    } else {
      this.orderService.setApprovalSave( this.cancelData.req_seq, num, this.form).then( response => {
        if ( response.ResultCode ) {
          // sms 자동발송
          let params: any = [
            {
              seq: this.cancelData.req_seq,
              hp: this.cancelData.o_hp
            }
          ];

          this.smsService.send_smsConfirmExchange( params ).then( response => {
            params = [];
            if ( response.data.ResultCode ) {
              this.toastrService.success(response.data.ResultMessage);
            } else {
              this.toastrService.error(response.data.ResultMessage);
            }
          });

          this.toastrService.success('변경사항을 저장하였습니다.');
          this.activeModal.close();
        }
      });
    }

  }

  /*******************************************************************************
    설  명 : 철회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  cancel() {
    this.orderService.setCancelCancel( this.cancelData.req_seq ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success('성공적으로 철회하였습니다.');
        this.activeModal.close();
      }
    });
  }

  /*******************************************************************************
    설  명 : 교환/반품 관리자 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeReq_gbn() {
    this.orderService.setReqChange( this.cancelData.req_seq, this.reg_gbn ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success('변경사항을 저장하였습니다.');
        this.getCancelDetailOrder();
      } else {
        this.toastrService.error('변경사항 저장에 실패했습니다.');
      }
    });
  }
}
