
      <form name="form" id="form" form-error-focus novalidate>
        <!-- Modal header -->
        <div class="modal-header">
          <h4 class="modal-title bold">결재</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()"></button>
        </div>
        <!-- Modal header -->
  
        <!-- Modal body -->
        <div class="modal-body">
  
          <section id="print-contents">
            <!-- 타이틀 영역 //-->
            <div class="fl w60p">
              <img src="/assets/images/logo.png" alt="로고" height="26" />
  
              <div class="f32 bold fngex center pt30 center">
                  회의실 사용신청서
              </div>
            </div>
  
            <div class="cb"></div>
  
            <!-- 내용부분 //-->
            <div id="print-contents-body" class="pt20">
              <table class="list-table table table-bordered mt30 ng-scope">
              <colgroup>
                <col style="width:15%">
                <col style="width:35%">
                <col style="width:15%">
                <col style="width:35%">
              </colgroup>
              
              <tbody>
              <tr>
                <th class="active" scope="row">회의제목</th>
                <td colspan="3" id="d1008-type">
                  <input />               
                </td>
              </tr>
              <tr>
                <th class="active" scope="row">회의실</th>
                <td id="d1008-type">
                  <div class="radio">
                    <label>연차
                      <input type="radio" name="radio" /> 
                      <span class="checkmark"></span>
                    </label>
                    <label>반차
                      <input type="radio" name="radio" checked /> 
                      <span class="checkmark"></span>
                    </label>
                    <label>경조
                      <input type="radio" name="radio" /> 
                      <span class="checkmark"></span>
                    </label>                                                         
                  </div>                
                </td>
                <th class="active" scope="row">회의일자</th>
                <td class="dept_name1008"></td>
              </tr>
              <tr>
                <th class="active" scope="row">시작시간</th>
                <td class="position1008">
                  <input type="text" />
                </td>
                <th class="active" scope="row">종료시간</th>
                <td class="position1008">
                  <input type="text" />
                </td>
              </tr>
              <tr>
                <th class="active" scope="row">참석인원</th>
                <input />
              </tr>
              </tbody>
              </table>            
            
            </div>
            
            <!-- 내용부분 //-->
  
          </section>
  
  
        </div>
        <!-- Modal body -->
  
        <!-- Modal footer -->
        <div class="modal-footer clearfix">
          <div class="fl w30p left">
          </div>
          <div class="fr w70p right">
            <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">닫기</button>
          </div>
        </div>
        <!-- Modal footer -->
      </form>
  
  