<section class="form-inline">

  <button type="button" title="검색" class="btn btn-default btn-small-icon" (click)="getSaleTotalSimple()">
    <i class="material-icons-outlined">search</i>
  </button>
  
  <!-- <select class="form-control form-control-small ml5" [(ngModel)]="dataMode.yearCnt" (change)="changeYearCnt($event)">
    <option *ngFor="let item of [5,4,3,2,1]" [value]="item">최근 {{item}}년</option>
  </select> -->
  
  <select class="form-control form-control-small ml5" [(ngModel)]="dataMode.memberType" (change)="changeMemberType($event)">
    <option *ngFor="let item of ['전체','개인회원','협력점','프랜차이즈','상사']; index as index" [value]="index">{{item}}</option>
  </select>

</section>


<article *ngIf="!superSimpleMode" class="mt5" style="height: calc(100% - 50px);">

<section class="chart">
  <canvas
    *ngIf="barChartData.length > 0" width="100%" height="27" baseChart
    [datasets]="barChartData"
    [labels]="chartLabel"
    [chartType]="chartType"
    [options]="chartOptions"
  ></canvas>
</section>

<section style="height:calc(35% - 20px);" class="mt20">
  <ag-grid-angular
    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</section>

</article>
<article *ngIf="superSimpleMode" class="mt5" style="height: calc(100% - 50px);">

  <section style="height: 100%;">
    <ag-grid-angular
      style="width: 100%;height:100%;"
      class="ag-theme-balham"
  
      [columnDefs]="columnDefs_superSimple"
      [defaultColDef]="defaultColDef"
      [rowData]="rowData_superSimple"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowHeight] = "130"
  
      (gridReady)="onGridReady_superSimple($event)"
      (cellClicked)="cellClicked($event)"
    >
    </ag-grid-angular>
  </section>

</article>