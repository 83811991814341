<section id="pos-management">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="30" minSize="20" maxSize="40">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
            <i class="material-icons-outlined">search</i>
          </button>

          <input type="text" class="form-control form-control-small ml5" placeholder="매장명, 포스명 검색" />
        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="fcf2342eafc94ebb9eaf8e0e2480c15b" [btnGroup]="1"></btn-ag-grid-save>

          <button type="button" title="포스추가" class="btn btn-primary btn-small-icon ml5" (click)="addPos({pos_id:''})">
            <i class="material-icons-outlined">add</i>
          </button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="posList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (cellClicked)="onCellClicked($event)"
          (rowDoubleClicked)="onRowDoubleClicked($event)"
          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="70" minSize="60" maxSize="80">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
            <i class="material-icons-outlined">search</i>
          </button>

          <input type="text" class="form-control form-control-small ml5" placeholder="아이디, 사용자명 검색" />

          <div class="btn-group ml5">
            <button type="button" class="btn btn-default btn-small active">전체</button>
            <button type="button" class="btn btn-default btn-small">사용</button>
            <button type="button" class="btn btn-default btn-small">사용안함</button>
          </div>
        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApiMember" gridId="caf67657942c4fb8a98a9a2a6f2ef6c2" [btnGroup]="1"></btn-ag-grid-save>

          <button type="button" title="추가" class="btn btn-primary btn-small-icon ml5" (click)="addAuthPos()">
            <i class="material-icons-outlined">add</i>
          </button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsMember"
          [defaultColDef]="defaultColDef"
          [rowData]="posMemberList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (cellDoubleClicked)="onCellDoubleClickedMember($event)"
          (gridReady)="onGridReadyMember($event)"
          (filterChanged)="onFilterChangedMember($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
