import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICellRendererParams } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { SmsService } from '@app/service/sms.service';
import { CommonService } from '@app/service/common.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { MatTooltipComponent } from '@components/mat-tooltip/mat-tooltip.component';

@Component({
  selector: 'app-order-review-report',
  templateUrl: './order-review-report.component.html',
  styleUrls: ['./order-review-report.component.scss']
})
export class OrderReviewReportComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 26,
    reason: '',
    sdate: '',
    edate: '',
  };
  
  public baseURL = config.baseUrl;

  public form: FormGroup;
  public formErrors = {};
  
  public reportList: any = [];
  public commonList: any = [];

  public totalCount: any;

  // 그리드 관련 선언
  public gridApi: any;
  public gridColumnApi: any;
  public columnDefs: any;

  public defaultColDef: any;
  public domLayout: any;
  public rowSelection: any;

  public noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      message: ['', []]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private orderService: OrderService,
    private toastrService: ToastrService,
    private smsService:SmsService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      {
        headerName: '순번',
        field: 'seq',
        width: 80,
        cellClass: 'cp right',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      { headerName: '신고사유', field: 'reason_name', width: 120, cellClass: 'cp center' },
      { headerName: '신고내용', field: 'comment', width: 360, cellClass: 'cp left',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data      
      },
      { headerName: '신고자', field: 'name', width: 200, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return `${params.data.name} (${params.data.id})`;
        }
      },
      { headerName: '후기내용', field: 'review_comment', width: 300, cellClass: 'cp left',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data      
      },

      { headerName: '후기상태', field: 'review_del_yn', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.review_del_yn == null ) {
            return '<span class="badge badge-success f12">정상</span>';
          } else {
            return '<span class="badge badge-danger f12">삭제</span>';
          }
        }
      },
      { headerName: '상품번호', field: 'product_seq', width: 100, cellClass: 'cp center' },
      { headerName: '상품명', field: 'product_name', width: 400, cellClass: 'cp left',
        cellRendererFramework: MatTooltipComponent,
        cellRendererParams: (params: ICellRendererParams) => params.data            
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = '';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    this.getOrderReviewReportList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 신고사유
    this.commonService.getCommonListCode('RVR').then( response => {
      if ( response.ResultCode ) {
        this.commonList = response.data;
      } else {
        this.commonList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 구매후기 신고 리스트 가져오기
  *******************************************************************************/
  getOrderReviewReportList() {
    this.orderService.getOrderReviewReportList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.reportList = response.data;
        this.totalCount = response.total;
      }
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getOrderReviewReportList();
  }

  /*******************************************************************************
    설  명 : 신고사유 검색조건 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setReason( value: any ) {
    this.search.reason = value;

    this.getOrderReviewReportList();
  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 26,
      reason: ''
    };

    this.getOrderReviewReportList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getOrderReviewReportList();
    }
  }

  /*******************************************************************************
    설  명 : ag-grid api
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked( event: any ) {
    if( event.colDef.field === 'product_seq' ) {
      const url = this.baseURL + '/product/info?productSeq=' + event.data.product_seq;
      window.open("about:blank").location.href = url;
    }
  }
  
  /*******************************************************************************
    설  명 : 구매후기 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeleteOrderReview() {
    const nodes: any = this.gridApi.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.error('삭제처리할 구매후기 신고내역을 선택하시기 바랍니다.');
      return false;
    } else {
      let seq = [];

      nodes.map( rows => {
        seq.push( rows.review_seq );
      });

      this.orderService.setDeleteOrderReview( seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);
          this.getOrderReviewReportList();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check ) {
    if( check == 'sdate' ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'edate')  {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }
}
