import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { BasicService } from '@app/service/basic.service';

@Component({
  selector: 'app-order-credit-replace',
  templateUrl: './order-credit-replace.component.html',
  styleUrls: ['./order-credit-replace.component.scss']
})
export class OrderCreditReplaceComponent implements OnInit {

  @Input() rows: any;

  public search: any = {};
  public productList: any = [];
  public bankList: any = [];

  public form: FormGroup;
  public formErrors = {};
  
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: [[], [] ],
      account_seq: ['', [Validators.required] ],
      date_search: ['', [] ],
      pay_date: [ null, [] ],
      comment: ['', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService,
    private basicService: BasicService,
  ) {
    this.buildForm();
   }

  ngOnInit(): void {
    this.getCompanyAccountList();

    const tmp: any = [];
    this.rows.forEach(data => {
      tmp.push(data.seq);
    });

    this.form.patchValue({
      seq: tmp
    });
  }

  /*******************************************************************************
    설  명 : 은행 계좌 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyAccountList() {
    this.basicService.getCompanyAccountList().then( response => {
      if ( response.ResultCode ) {

        this.bankList.push({value: '', title: '입금하실 은행 또는 카드를 선택하세요'});

        response.data.forEach( row => {
          this.bankList.push({
            value: row.seq,
            title: row.bank_name + ( row.bank_account ? ' : ' + row.bank_account : '' )
          });
        });

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( value: any ) {
    let dateString: any;

    if( value === '-' )
      dateString = null;
    else
      dateString = moment().subtract( value, 'day' ).format('YYYY-MM-DD HH:mm:ss');

    this.form.patchValue({
      date_search: value,
      pay_date: dateString
    });
  }

  /*******************************************************************************
    설  명 : 결제 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderPayComplete() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.orderService.setOrderPayCreditReplace( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);

          this.activeModal.close();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
