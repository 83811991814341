
<form [formGroup]="form" (ngSubmit)="setBikePayhelperStatusChange()">

  <div class="modal-header">
    <h4 class="modal-title fb mb0">진행 상태 변경</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
  
  <div class="modal-body">
  
    <table class="table table-bordered table-small mt50 mb50">
    <colgroup>
      <col style="width:30%" />
      <col style="width:70%" />
    </colgroup>
  
    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="status_name" class="control-label">상태</label></th>
      <td><inputEx type="text" name="status_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="app_date" class="control-label">처리일자</label></th>
      <td><inputEx type="date" name="app_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    </tbody>
    </table>
  </div>
  
  <div class="modal-footer">
    <div class="fr w50p right">
      <button type="submit" class="btn btn-primary btn-small mr5">저장하기</button>
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
  </div>
  
  </form>