  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">금일결제<span class="cnt">{{statistic?.todayPayCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">미처리<span class="cnt">{{statistic?.standbyCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">환불요청<span class="cnt">{{statistic?.refundCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="fl w75p form-inline">
    <div class="btn-group">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.order_status == ''}" (click)="search.order_status='';getOrderList();">전체</button>
      <button *ngFor="let item of orderTypeList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.order_status == item.common_code}" (click)="search.order_status = item.common_code;getOrderList();">{{item.common_name}}</button>
    </div>
    <select id="searchField" [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
      <option value="year">연도</option>
      <option value="month">월</option>
      <option value="date1">일자</option>
    </select>
    <span class="ml5 mr5">별 입금조회</span>
    <input type="text" size="15" readonly="readonly" id="paydate" [(ngModel)]="search.paydate" class="form-control form-control-small calendar-input ml5" placeholder="날짜 선택" ngbDatepicker [footerTemplate]="footerTemplate3" #d3="ngbDatepicker" (click)="d3.toggle()" (dateSelect)="getOrderList()"/>
    <ng-template #footerTemplate3>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d3, 'paydate')">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d3.close()">닫기</button>
    </ng-template>
  </div>
  <div class="fr w25p right">
    <button *ngIf="authService.currAuth['auth_excel']=='1'" type="button" class="btn btn-default btn-small mr5" (click)="setExcelOrderAll()">전체엑셀다운</button>
    <button *ngIf="authService.currAuth['auth_excel']=='1'" type="button" class="btn btn-default btn-small mr5" (click)="setExcelOrder()">엑셀다운</button>
    <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="a234fee884a342b3891f34eb789d5ace" [btnGroup]="1"></btn-ag-grid-save>
  </div>

  <div class="cb"></div>

  <hr class="db mt5 mb5" />

  <div class="fl w100p form-inline">
    <div class="btn-group">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.shop_gbn == ''}" (click)="search.shop_gbn='';getOrderList();">전체</button>
      <button *ngFor="let item of orderShopList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.shop_gbn == item.common_code}" (click)="search.shop_gbn = item.common_code;getOrderList();">{{item.common_name}}</button>
    </div>
  </div>

  <div class="cb"></div>

  <hr class="db mt5 mb5" />

  <div class="fl w100p form-inline">
    <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
    <ng-template #footerTemplate1>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 'sdate')">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
    </ng-template>
    <span class="ml5 mr5">~</span>
    <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
    <ng-template #footerTemplate2>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 'edate')">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
    </ng-template>

    <div class="btn-group ml5">
      <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value);getOrderList();">{{item.title}}</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.pay_method == ''}" (click)="search.pay_method='';getOrderList();">전체</button>
      <button *ngFor="let item of payMethodList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.pay_method == item.common_code}" (click)="search.pay_method = item.common_code;getOrderList();">{{item.common_name}}</button>
    </div>    

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.suspense == ''}" (click)="search.suspense='';getOrderList();">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.suspense == '1'}" [ngStyle]="{'background-color': search.suspense=='1' ? '#539bff' : '#d1eff9'}" (click)="search.suspense='1';getSuspenseList();">우리 미결</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.suspense == '2'}" [ngStyle]="{'background-color': search.suspense=='2' ? '#539bff' : '#d1eff9'}" (click)="search.suspense='2';getComSuspenseList();">업체 미결</button>
    </div>
  </div>

  <div class="cb"></div>

  <hr class="db mt5 mb5" />

  <div class="fl w65p form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-big-icon" style="background-color: #d1eff9;" (click)="searchInit()">
      <!-- <i class="material-icons-outlined">refresh</i> -->
      초기화
    </button>

    <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control ml5" (change)="getOrderList()">
      <option value="10">10줄</option>
      <option value="20">20줄</option>
      <option value="500">50줄</option>
      <option value="100">100줄</option>
      <option value="200">200줄</option>
      <option value="500">500줄</option>
    </select>

    <input type="text" id="searchText" #search_text [(ngModel)]="searchText" (keypress)="searchList($event)" size="50" class="form-control ml5" placeholder="수신자, 주문자, 주문번호, (구)주문번호, 주문자폰, 수신자폰" />

    <select id="searchField2" [(ngModel)]="search.searchField2" class="form-control ml5">
      <option value="r_name">수신자</option>
      <option value="o_name">주문자</option>
      <option value="id">주문자 ID</option>
      <option value="r_address">주소</option>
      <option value="send_letter">배송번호</option>
      <option value="o_hp">주문자 휴대폰</option>
      <option value="r_hp">수신자 휴대폰</option>
      <option value="org_order_amt">금액</option>
      <option value="bank">입금은행</option>
      <option value="receipt_name">입금자명</option>
      <option value="pay_method">입금방법</option>
      <option value="saler">판매처</option>
      <option value="seq">주문번호</option>
      <option value="brand_name">메이커</option>
      <option value="product_code">상품코드</option>
    </select>
    <input type="text" id="searchText2" [(ngModel)]="search.searchText2" (keypress)="searchList($event)" size="15" class="form-control ml5" placeholder="검색어" />

    <button type="button" title="검색" class="btn btn-default btn-big-icon ml5" style="background-color: #d1eff9;" (click)="getOrderList()">
      <!-- <i class="material-icons-outlined">search</i> -->
      검색
    </button>

  </div>

  <div class="fr w35p right">
    <button *ngIf="authService.currAuth['auth_delete']=='1'" type="button" class="btn btn-warning btn-small ml5" (click)="setOrderCancel()">주문취소</button>
    <button *ngIf="authService.currAuth['auth_modify']=='1'" type="button" class="btn btn-info btn-small ml5" (click)="setOrderCombine()">주문통합</button>
    <button *ngIf="authService.currAuth['auth_delete']=='1'" type="button" class="btn btn-success btn-small ml5" (click)="orderPayment()">결제확인</button>
    <button *ngIf="authService.currAuth['auth_modify']=='1'" type="button" class="btn btn-success btn-small ml5" (click)="setOrderPayCreditReplace()">외상대체</button>
    <button *ngIf="authService.currAuth['auth_write']=='1'" type="button" class="btn btn-primary btn-small ml5" (click)="goDetail(0)">관리자주문</button>
  </div>

  <div class="cb"></div>

  <div class="mt5" style="height:calc(100% - 290px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="orderList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [isRowSelectable]="isRowSelectable"
      [rowClassRules]="rowClassRules"
      [pagination]="false"
      [stopEditingWhenGridLosesFocus]="true"

      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>

    <table class="table table-bordered table-small1 mb0" style="height: 40px;" *ngIf="search.paydate">
      <caption class="none">주문내역 합계</caption>
      <colgroup>
        <col style="width:51%;" />
        <col style="width:7%;" />
        <col style="width:7%;" />
        <col style="width:7%;" />
        <col style="width:7%;" />
        <col style="width:7%;" />
        <col style="width:7%;" />
        <col style="width:7%;" />
      </colgroup>
      <tbody>
      <tr>
        <th scope="row"><label>최초주문 합계</label></th>
        <td class="f14 fb" style="padding-top: 10px; text-align: center;">{{TotalAmt.orgOrderAmt | number}}</td>
        <th scope="row"><label>현재결제 합계</label></th>
        <td class="f14 fb" style="padding-top: 10px; text-align: center;">{{TotalAmt.payAmt | number}}</td>
        <th scope="row"><label>마일리지적립 합계</label></th>
        <td class="f14 fb" style="padding-top: 10px; text-align: center;">{{TotalAmt.inMileageAmt | number}}</td>
        <th scope="row"><label>마일리지사용 합계</label></th>
        <td class="f14 fb" style="padding-top: 10px; text-align: center;">{{TotalAmt.outMileageAmt | number}}</td>        
      </tr>
      </tbody>
    </table>
    <section class="pagination mt10">
      <ngb-pagination
        [(page)]="search.pageNo"
        [pageSize]="search.pageRow"
        [collectionSize]="search.totalCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
  </div>
