import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AgGridImageComponent } from '@app/components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { UtilService } from '@app/service/util.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { AccountService } from '@app/service/account.service';

@Component({
  selector: 'app-account-back',
  templateUrl: './account-back.component.html',
  styleUrls: ['./account-back.component.scss']
})
export class AccountBackComponent implements OnInit {

  options: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg',
    centered: true,
    windowClass: 'modal-fadeInDown'
  };

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
    public form: FormGroup
    public formErrors = {}

    gridApi: any
    gridColumnApi: any
    columnDefs: any

    defaultColDef: any
    domLayout: any
    rowSelection: any
    noRowsTemplate: string

    // 그리드 이미지 처리
    frameworkComponents = {
      agGridImageComponent: AgGridImageComponent,
      agGridHtmlComponent: AgGridHtmlComponent
    }
    components: any

    search = {
      index : 1,
      count : 0
    }

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private stockOrderComponent: StockOrderComponent,
    private accountService: AccountService,
    ) {
      this.columnDefs = [
        {headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp ag-cell80h center', cellRenderer: 'agGridImageComponent' },
        {headerName: '상품명', field: 'product_name', cellClass: 'cp ag-cell80h left' },
        {headerName: '수량', field: 'qty', cellClass: 'cp ag-cell80h right',
          valueGetter: params => this.stockOrderComponent.valueGetter_num(params)
        },
        {headerName: '주문자', field: 'mem_name', cellClass: 'cp ag-cell80h left' },
        {headerName: '등록일', field: 'write_date', cellClass: 'cp ag-cell80h left' },
        {headerName: '반품일', field: 'return_date', cellClass: 'cp ag-cell80h left' },
        {headerName: '수령송장', field: 'return_invoice', cellClass: 'cp ag-cell80h left' },
        {headerName: '반품원인', field: 'return_status_name', cellClass: 'cp ag-cell80h left' },
        {headerName: '반납일', field: 'send_date', cellClass: 'cp ag-cell80h left' },
        {headerName: '반납송장', field: 'send_invoice', cellClass: 'cp ag-cell80h left' },
        {headerName: '수령배송비', field: 'consign_pay', cellClass: 'cp ag-cell80h right',
          valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
        {headerName: '정산배송비', field: 'consign_pay', cellClass: 'cp ag-cell80h right',
          valueGetter: params => this.stockOrderComponent.valueGetter_num(params) },
        {headerName: '공급자명', field: 'provider_name', cellClass: 'cp ag-cell80h left' },
        // { headerName: '', field: '', minWidth: 100, maxWidth: 100, flex: 0, cellClass: 'cp ag-cell80h center',
        //   cellRendererFramework: AgGridButtonComponent,
        //   cellRendererParams: {
        //     action: 'memberInfo',
        //     btnName: '수정',
        //     listaction: $event => {this.toastrService.warning('미구현')}
        //   }
        // },

        // {headerName: '', field: '', cellClass: 'cp ag-cell80h right ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent', cellEditor: "numericCellEditor",
        //   valueGetter: (params) => String(params.data.unit_amt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        // },
      ]

      this.defaultColDef = {...this.stockOrderComponent.defaultColDef}
      this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate
      this.rowSelection = this.stockOrderComponent.rowSelection
      this.components = this.stockOrderComponent.components
     }

  ngOnInit(): void {
    this.buildForm()
    this.getReturnProduct()
    this.toastrService.info('목록을 클릭하면 주문정보로 이동합니다.')
  }

  getRowHeight = () => 80

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      // date : [''],
      why : [''],
      searchField : [''],
      searchText : [''],

      rowData : [[]]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors )
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
  *******************************************************************************/
  onCellClicked($event) {
    if($event.colDef.field) {
      this.router.navigate(
        ['/order/detail'], {
          relativeTo: this.activatedRoute,
          queryParams: { seq : $event.data.order_seq },
          queryParamsHandling: '' // remove to replace all query params by provided
        })
    }
  }

  /*******************************************************************************
    설  명 :
  *******************************************************************************/
  addAccount( item: any ) {
    // const modalRef = this.modalService.open(EtcaddComponent, this.options);
    // modalRef.componentInstance.selectedItem = item;

    // modalRef.result.then((result) => {
    //   if( result ) {}
    // }, (reason) => {
    // });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) this.getReturnProduct()
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
  *******************************************************************************/
  searchInit() {
    this.search.index = 1
    this.ngOnInit()
  }

  /*******************************************************************************
    설  명 : 반품내역조회
  *******************************************************************************/
  getReturnProduct() {
    if(this.form.value['searchField'] && !this.form.value['searchText']) {this.toastrService.warning('검색어를 입력하세요.'); return}

    const _getValue = field => this.form.value['searchField'] == field ? this.form.value['searchText'] : ''
    this.accountService.getReturnProduct({
      return_status : this.form.value.why,
      mem_name : _getValue('mem_name'),
      product_name : _getValue('product_name'),
      id : _getValue('id'),
      send_invoice : _getValue('send_invoice'),
      return_invoice : _getValue('return_invoice'),

      unlimit : false,
      index : this.search.index - 1
    }).then(response => {
      if(response.ResultCode) {
        this.form.patchValue({rowData : response.data })
        this.search.count = response.count
      } else {
        this.form.patchValue({rowData : [] })
        this.search.count = 0
        this.toastrService.error(response.ResultMessage)
      }
    })
  }

  /*******************************************************************************
    설  명 : 인덱스 변경
  *******************************************************************************/
  pageChange($event) {
    this.search.index = $event
    this.getReturnProduct()
  }

  /*******************************************************************************
    설  명 : 검색 조건 변경
  *******************************************************************************/
  setValue($event) {
    switch($event.target.id) {
      case 'why': this.form.patchValue({why : $event.target.value }); break
      case 'searchField': this.form.patchValue({searchField : $event.target.value }); break
    }
  }

}
