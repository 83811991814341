import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { OrderService } from '@app/service/order.service';
import { CustomValidator } from '@app/service/custom.validators';

@Component({
  selector: 'app-order-amt-edit',
  templateUrl: './order-amt-edit.component.html',
  styleUrls: ['./order-amt-edit.component.scss']
})
export class OrderAmtEditComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() order_seq: any;
  @Input() data: any;

  public orderStatusList: any = [];

  public form: FormGroup;
  public formErrors: any = {};

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private orderService: OrderService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      order_seq: ['', [Validators.required] ],
      seq: ['', [Validators.required] ],
      qty: [ '', [Validators.required, CustomValidator.numeric] ],
      buy_price: [ '', [Validators.required, CustomValidator.numeric] ],
      discount_amt: [ '', [Validators.required, CustomValidator.numeric] ],
      amt: [ '', [Validators.required, CustomValidator.numeric] ],
      calc_amt: [ '', [Validators.required, CustomValidator.numeric] ],
      total_amt: [ '', [Validators.required, CustomValidator.numeric] ],
      mileage: [ '', [Validators.required, CustomValidator.numeric] ],
      order_status: [ '', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  
  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();

    this.form.get('order_seq').setValue(this.order_seq);

    this.data.calc_amt = parseInt(this.data.amt) - parseInt(this.data.discount_amt);

    this.form.patchValue( this.data );

    let modalContent: any = $('.modal-content');
    let modalHeader = $('.modal-header');
    modalHeader.addClass('cas');
    modalContent.draggable({
      handle: '.modal-header',
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 주문상태
    this.commonService.getCommonListCode('ORS').then( response => {
      if ( response.ResultCode ) {

        this.orderStatusList.push({value: '', title: '주문상태를 선택하세요'});

        response.data.forEach( row => {
          this.orderStatusList.push({
            value: row.common_code,
            title: row.common_name
          });
        });

      } else {
        this.orderStatusList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderAmtSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if( this.form.valid ) {
      this.orderService.setOrderAmtSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 소비자 가격 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangeAmt( event: any ): void {
    let id: any = event.target.id;
    let value: any = event.target.value;

    const qtyElement: any = document.getElementById( 'qty' );
    const amtElement: any = document.getElementById( 'amt' );
    const discountAmtElement: any = document.getElementById( 'discount_amt' );

    const qty: any = qtyElement.value;
    const amt: any = amtElement.value;
    const discountAmt: any = discountAmtElement.value || 0;

    const calc_amt = parseInt(amt) - parseInt(discountAmt);
    const total_amt = calc_amt * qty;
    const mileage = total_amt / 100;

    this.form.patchValue({
      calc_amt: calc_amt,
      total_amt: total_amt,
      mileage: mileage
    });
  }
  
}
