/*******************************************************************************
  설  명 : 알림관리
  작성일 : 2021-05-17
  작성자 : 김종현
  수정자 : 송영석 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { CommonService } from '@app/service/common.service';
import { SiteConfigService } from '@app/service/site.config.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AlarmmanagerAddComponent } from '@page/basic/company-info/basic-alarmmanager/alarmmanager-add/alarmmanager-add.component';

const CONFIG_KEY = 'ALARM';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-basic-alarmmanager',
  templateUrl: './basic-alarmmanager.component.html',
  styleUrls: ['./basic-alarmmanager.component.scss']
})
export class BasicAlarmmanagerComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public search: any = {
    searchText: '',
  };

  public msgList: any = [];
  public days: any = '';
  public messageType: any = '0';

  // 그리드 관련 선언
  chargeList: any = [];
  originEmployeeList: any = [];
  employeeList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  rowSelection: any;

  gridApiCharge: any;
  gridColumnApiCharge: any;
  columnDefsCharge: any;
  rowSelectionCharge: any;

  defaultColDef: any;
  domLayout: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      alarm_over_day: ['', [Validators.maxLength(3)] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private siteConfigService: SiteConfigService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private basicService: BasicService,
    private commonService: CommonService,
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'seq', width: 50, cellClass: 'cp center', headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '코드', width: 80, field: 'common_code', cellClass: 'cp left' },
      {headerName: '코드명', width: 150, field: 'common_name', cellClass: 'cp left'},
      {headerName: '코드명(영문)', width: 150, field: 'common_ename', cellClass: 'cp left'},
      {headerName: '사용여부', width: 150, field: 'use_yn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent', hide: true,
        valueGetter: function(params) {
          if( params.data.use_yn == 1 )
            return '<span class="badge badge-primary f12 fn">사용</span>';
          else
            return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      },
      {headerName: '작성자', width: 100, field: 'writer', cellClass: 'cp left', hide: true},
      {headerName: '작성일시', width: 200, field: 'write_date', cellClass: 'cp left', hide: true},
      {headerName: '코드', field: 'seq', hide: true },
      {headerName: '그룹코드', field: 'code', hide: true },
      {headerName: '그룹코드명', field: 'name', hide: true },
    ];

    // 담당자 ag grid 컬럼 선언
    this.columnDefsCharge = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '아이디', field: 'id', width: 120, cellClass: 'cp' },
      { headerName: '담당자명', field: 'name', width: 120, cellClass: 'cp' },
      { headerName: '부서명', field: 'dept_name', width: 180, cellClass: 'cp' },
      { headerName: '직위명', field: 'position_name', width: 120, cellClass: 'cp' },
      { headerName: '연락처', field: 'hp', width: 180, cellClass: 'cp center' },
      { headerName: '이메일', field: 'email', width: 300, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'single';
    this.rowSelectionCharge = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 환경설정 가져오기
    this.getConfig();

    this.getCommonListCode();
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          alarm_over_day:      response.data.alarm_over_day || '',
        });
      }
    });
  }  

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.siteConfigService.setConfig(CONFIG_KEY, this.form).then( response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '알림정보 저장');
        else this.toastrService.error( response.ResultMessage, '알림정보 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '알림정보 저장');
    }
  }

  /*******************************************************************************
    설  명 : 알림코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonListCode() {
    this.commonService.getCommonListCode('MSG').then( response => {
      if( response.ResultCode ) {
        this.msgList = response.data.filter( row => row.common_code !== '9999' );
      } else {
        this.toastrService.error( response.ResultMessage, 'getCommonListCode');
      }

    });
  }

  /*******************************************************************************
    설  명 : 담당자 추가
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
  setAdd() {
    if ( this.messageType === '0' ) {
      this.toastrService.error( '코드를 선택해야 합니다.', '');
    } else {
      const modalRef = this.modalService.open(AlarmmanagerAddComponent, options);
      modalRef.componentInstance.messageType = this.messageType;

      modalRef.result.then((result) => {
        if( result ) {
          this.getMessageChargeList(this.messageType);
        }
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : days 설정
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
  onValueChange($event) {
    this.days = $event.target.value;
  }

  /*******************************************************************************
    설  명 : 전체 담당자 설정
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
  setMessageChargeAll() {
    this.basicService.setMessageChargeAll(this.messageType).then( response => {
      if (response.ResultCode) {
        this.toastrService.success(response.ResultMessage, '전체담당자 설정 완료');
        
        this.getMessageChargeList(this.messageType);

      } else {
        this.toastrService.error(response.ResultMessage, '전체담당자 설정 실패');
      }
    });
  }

  /*******************************************************************************
    설  명 : 선택 담당자 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMessageChargeDelete() {
    const tmp = this.gridApiCharge.getSelectedRows();
    const params = tmp.map( row => row.mem_no );

    if ( confirm('삭제하시겠습니까?') ) {
      this.basicService.setMessageChargeDelete( this.messageType, params).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '삭제하였습니다.');

          this.getMessageChargeList(this.messageType);

        } else {
          this.toastrService.error( response.ResultMessage, '삭제 오류');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked(event: any) {
    this.messageType = event.data.common_code;

    this.getMessageChargeList(this.messageType);
  }

  
  /*******************************************************************************
    설  명 : 코드에 따른 담당자 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMessageChargeList(messageType) {
    this.basicService.getMessageChargeList(messageType).then( response => {
      if( response.ResultCode ) {
        this.chargeList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '불러오기 실패');
      }
      
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCharge(params) {
    this.gridApiCharge = params.api;
    this.gridColumnApiCharge = params.columnApi;
  }

}

