/*******************************************************************************
  설  명 : 게시판 테이블추가
  작성일 : 2019-10-03
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';

import { BoardService } from '@app/service/board.service';

@Component({
  selector: 'app-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public type: string = 'BOARD';
  public title: string = '';

  public formTable: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formTable = this.formBuilder.group({
      type:["", []],
      table_name:["", [Validators.required, Validators.maxLength(50)] ],
    });

    this.formTable.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formTable, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private boardService: BoardService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    if( this.type ) {
      this.title = '게시판 테이블';
    } else {
      this.title = '게시판 의견 테이블';
    }

    this.formTable.patchValue( {type: this.type} );
  }

  /*******************************************************************************
    설  명 : 테이블 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formTable, this.formErrors);

    if(this.formTable.valid) {
      this.boardService.setBoardTableName(this.formTable).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

}
