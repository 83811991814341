<div class="modal-header">
  <h4 class="modal-title fb mb0">쿠폰 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

    <table class="table table-bordered table-small1 mb0">
      <caption class="none">쿠폰정보</caption>
      <colgroup>
        <col style="width:30%;" />
        <col style="width:70%;" />
      </colgroup>

      <tbody>
        <tr>
          <th scope="row"><label for="coupon_type"><i class="material-icons cred f11">star</i> 쿠폰종류</label></th>
          <td>
            <div class="btn-group">
              <ng-container *ngFor="let item of couponTypeList">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active:form.controls.coupon_type.value==item.common_code}" (click)="form.get('coupon_type').setValue(item.common_code)">{{item.common_name}}</button>
              </ng-container>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="coupon_code">쿠폰코드</label></th>
          <td>
            <inputEx type="text" name="coupon_code" [formGroup]="form" [formErrors]="formErrors" [autofocus]="true"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="coupon_count">쿠폰수량</label></th>
          <td>
            <inputEx type="text" name="coupon_count" [formGroup]="form" [formErrors]="formErrors" [autofocus]="true" ></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="id"><i class="material-icons cred f11">star</i> 쿠폰관리회원</label></th>
          <td>
            <inputEx type="text" name="id" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" [addOn]="addMemberFunc" addOnText="회원검색"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="start_date"><i class="material-icons cred f11">star</i> 시작일자</label></th>
          <td>
            <dateTimePicker name="start_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="end_date"><i class="material-icons cred f11">star</i> 종료일자</label></th>
          <td>
            <dateTimePicker name="end_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker>
          </td>
        </tr>        
        <tr>
          <th scope="row"><label for="use_yn"><i class="material-icons cred f11">star</i> 사용여부</label></th>
          <td>
            <div class="btn-group">
              <button type="class" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='1'}" (click)="form.get('use_yn').setValue('1')">사용</button>
              <button type="class" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='0'}" (click)="form.get('use_yn').setValue('0')">미사용</button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
           <span>*배민쿠폰은 쿠폰코드는 입력하지 않고, 수량을 반드시 입력해야하며, 나머지 쿠폰은 쿠폰 코드를 반드시 입력해야합니다.</span>
          </td>
        </tr>        
      </tbody>
      </table>
    </form>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">

    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="setEventCouponSave()">저장하기</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
    </div>
  </div>

