  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="20" minSize="20" maxSize="40">
      <app-tree-view
        [useSort]='false'
        [useAddNode]='false'
        [nodeData]='nodeData'
        (onSelected)='onSelectNode($event)'
      >
      </app-tree-view>
    </as-split-area>

    <as-split-area size="80" minSize="60" maxSize="80">
      <div class="fl w70p form-inline mb5">
        <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
          <i class="material-icons-outlined">refresh</i>
        </button>
        <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getSizeList()">
          <i class="material-icons-outlined">search</i>
        </button>

        <div class="btn-group ml5">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn==''}" (click)="searchUseYn('')">전체</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='1'}" (click)="searchUseYn('1')">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='0'}" (click)="searchUseYn('0')">사용안함</button>
        </div>

        <select [(ngModel)]="search.category_seq" class="form-control form-control-small ml5">
          <option value="">카테고리 선택</option>
          <option *ngFor="let item of categoryList" [value]="item.seq">{{item.category_nm}}</option>
        </select>

        <button type="button" class="btn btn-success btn-small ml5" (click)="copySize()">복사하기</button>

        <span class="ml5">
          ※ 사이즈가 없을 경우 상위 카테고리 사이즈가 적용됩니다.
        </span>
      </div>

      <div class="fr w30p right">
        <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="3c723b91d7ae43ec82edb70b5fedd7c9" [btnGroup]="1"></btn-ag-grid-save>
        <button type="button" class="btn btn-success btn-small ml5" (click)="setSizeSortOrderSave()">순서저장</button>
        <button type="button" class="btn btn-danger btn-small ml5" (click)="deleteSize()">사이즈삭제</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="addSize(0)">사이즈추가</button>
      </div>

      <div class="cb"></div>

      <ag-grid-angular
        #myGrid

        style="width: 100%;height:calc(100% - 35px);"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="sizeList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        [rowDragManaged]="true"
        [enableMultiRowDragging]="true"
        [animateRows]="true"        

        (cellDoubleClicked)="onCellDoubleClicked($event)"
        (gridReady)="onGridReady($event)"
        (filterChanged)="onFilterChanged($event)"
        >
      </ag-grid-angular>
    </as-split-area>
  </as-split>