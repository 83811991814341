/*******************************************************************************
  설  명 : 창고이동
  작성일 : 2019-12-12
  작성자 : 송영석

  수정일 : 2021.06.11
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@app/service/util.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { StockManagement } from '@app/service/stockManagement.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-stock-move',
  templateUrl: './stock-move.component.html',
  styleUrls: ['./stock-move.component.scss']
})
export class StockMoveComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number; // 0 = 추가, else = 수정
  public product_seq: number;
  public product_name: string = '';

  private warehouseList: any = [];
  public in_warehouse: any = []; // 입고창고
  public out_warehouse: any = []; // 출고창고

  public title: string = '추가';

  public form: FormGroup;
  public formErrors = {};

  public addProductFunc: any;         // 상품검색
  public changeOutWarehouseFunc: any;     // 출고창고 변경시 실행 함수


  @Input() data_productProperty
  @Input() data_warehouse
  @Input() data_warehouse_all
  @Input() loss_mode
  @Input() last_history
  out_WH_location: any
  in_WH_location: any

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    const date: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.form = this.formBuilder.group({
      product_seq : [this.data_productProperty['product_seq'], [Validators.required]],
      product_property_seq : [this.data_productProperty['product_property_seq'], [Validators.required]],
      product_name : [this.data_productProperty['product_name']],
      out_warehouse_seq : [this.last_history.warehouse_seq || '', [Validators.required] ],
      out_warehouse_location_seq : [this.last_history.warehouse_location_seq || ''],
      in_warehouse_seq : ['', this.loss_mode ? [] : [Validators.required] ],
      in_warehouse_location_seq : [''],
      inout_date : [date, [Validators.required] ],
      inout_qty : [0, [Validators.required] ],
      max_qty : [''],
      memo : [''],
      unit_amt : [this.data_productProperty['buy_price']]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private stockManagement: StockManagement,
  ) {

    this.addProductFunc = this.searchProduct.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 처리
  *******************************************************************************/
  ngOnInit() {
    this.buildForm()
    this.selectChange({
      target : {
        id : 'out_warehouse_seq',
        value : this.form.value['out_warehouse_seq']
      }
    })
  }

  /*******************************************************************************
    설  명 : 상품 검색 addOn
  *******************************************************************************/
  searchProduct() {
    /*
    const modalRef = this.modalService.open(ACProductFindComponent, options);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {
      this.form.patchValue({
        product_seq: result.product_seq,
        product_name: result.name
      });

      // 상품 선택 후 입고창고, 출고창고 정보를 표시
      this.in_warehouse = this.warehouseList;
      this.out_warehouse = this.warehouseList;

    }, (reason) => {
    });
    */
  }

  /*******************************************************************************
    설  명 : select change
  *******************************************************************************/
  selectChange($event) {
    this.form.patchValue({
      [$event.target.id] : $event.target.value
    })

    if($event.target.id == 'out_warehouse_seq') {
      this.form.patchValue({
        max_qty : this.data_warehouse.find(e => e['warehouse_seq'] == $event.target.value)['stock']
      })
      this.out_WH_location = this.data_warehouse_all.find(e => e['warehouse_seq'] == $event.target.value)['_detail']
    }
    if($event.target.id == 'in_warehouse_seq') {
      this.in_WH_location = this.data_warehouse_all.find(e => e['warehouse_seq'] == $event.target.value)['_detail']
    }
  }

  /*******************************************************************************
    설  명 : submit
  *******************************************************************************/
  submit() {
    if(!this.form.valid) {
      this.toastrService.error('필수입력값을 확인하세요.')
      return
    // } else if(this.form.value['in_warehouse_seq'] == this.form.value['out_warehouse_seq']) {
    //   this.toastrService.error('출고하는 창고와 입고하는 창고가 같습니다.')
    //   return
    } else if(this.form.value['inout_qty'] < 1) {
      this.toastrService.error('수량을 입력하세요.')
      return
    } else if(parseInt(this.form.value['inout_qty']) > parseInt(this.form.value['max_qty'])) {
      this.toastrService.error('가능수량을 초과하였습니다.')
      return
    }

    this.authService.getLoginInfo.subscribe(__e => {

      const params = {
        ...this.form.value,
        mem_no : __e['mem_no'],
        amt : this.form.value['unit_amt'] * this.form.value['inout_qty'],
        loss_mode : this.loss_mode
      };
      (this.loss_mode
        ? this.stockManagement.setStockLoss(params)
        : this.stockManagement.setStockMove(params)
      ).then(response => {
        if(response.ResultCode) {
          this.toastrService.success(response.ResultMessage)
          this.activeModal.close()
        } else this.toastrService.error(response.ResultMessage)
      })

    }).unsubscribe()
  }


}
