<form [formGroup]="form">
  <table class="table table-bordered table-small mb0">
  <colgroup>
    <col style="width:150px" />
    <col style="width:auto" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="sdate" class="control-label">statistic_day 테이블</label></th>
    <td>
      <div class="form-inline">
        <inputEx type="date" name="sdate" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        <span class="ml5 mr5">~</span>
        <inputEx type="date" name="edate" [formGroup]="form" [formErrors]="formErrors"></inputEx>

        <button type="button" class="btn btn-primary btn-small ml10" (click)="submit()">계산하기</button>
      </div>
    </td>
  </tr>
  </tbody>
  </table>

  <div style="height: 500px" class="mt10">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="calcData"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"

      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
    >
    </ag-grid-angular>
  </div>
</form>
