<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체<span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">검색<span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">미처리<span class="cnt">{{statistic?.standbyCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<div class="fl w70p form-inline">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
    <i class="material-icons-outlined">refresh</i>
  </button>
  
  <!-- <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getRefundMasterList()">
    <option value="10">10줄</option>
    <option value="20">20줄</option>
    <option value="50">50줄</option>
    <option value="100">100줄</option>
    <option value="500">500줄</option>
  </select>

  <select name="search_field" id="search_field" [(ngModel)]="search.search_field" class="form-control form-control-small ml5">
    <option value="mem_name">주문자</option>
    <option value="id">회원아이디</option>
    <option value="memo">메모</option>
    <option value="product_name">상품명</option>
    <option value="return_amt">환불금액</option>
  </select>

  <input type="text" size="30" class="form-control form-control-small ml5" id="searchText" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="검색어" />

  <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
  <ng-template #footerTemplate1>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 'sdate')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
  </ng-template>
  <span class="ml5 mr5">~</span>
  <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
  <ng-template #footerTemplate2>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 'edate')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
  </ng-template> -->

  <div class="btn-group ml5">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.sort=='date'}" (click)="setSearchSort('date')">등록일</button>
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.sort=='order'}" (click)="setSearchSort('order')">주문번호</button>
  </div>

  <input type="text" size="15" readonly="readonly" id="write_date" [(ngModel)]="search.write_date" class="form-control form-control-small calendar-input ml5" placeholder="등록일자" ngbDatepicker [footerTemplate]="footerTemplate3" #d3="ngbDatepicker" (click)="d3.toggle()"  (dateSelect)="selectDate($event)" />
  <ng-template #footerTemplate3>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d3, 'write_date')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d3.close()">닫기</button>
  </ng-template>
  
  <input type="text" size="15" readonly="readonly" id="return_date" [(ngModel)]="search.return_date" class="form-control form-control-small calendar-input ml5" placeholder="결제일자" ngbDatepicker [footerTemplate]="footerTemplate4" #d4="ngbDatepicker" (click)="d4.toggle()"  (dateSelect)="selectDate($event)" />
  <ng-template #footerTemplate4>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d4, 'return_date')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d4.close()">닫기</button>
  </ng-template>
  
  <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getRefundMasterList()">
    <i class="material-icons-outlined">search</i>
  </button>
</div>

<div class="fr w30p right">
  <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="933f03046291451c858fe67eb7e3a1e0" [btnGroup]="1"></btn-ag-grid-save>
  <button type="button" class="btn btn-default btn-small ml5" (click)="setExcelOrder()">엑셀 다운로드</button>
  <!-- <button type="button" class="btn btn-danger btn-small ml5" (click)="refundEdit(0)">수정(향후삭제)</button> -->
</div>

<div class="cb"></div>

<as-split unit="percent" restrictMove="true" gutterSize="10">
  <as-split-area size="62" minSize="62" maxSize="62">
    <div class="mt5" style="height:calc(100% - 150px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [rowSelection]="'single'"
        [columnDefs]="masterColumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="refundMasterList"
        [pagination]="false"
        [paginationPageSize]="paginationPageSize"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"

        (gridReady)="onMasterGridReady($event)"
        (cellClicked)="onMasterCellClicked($event)"
        >
      </ag-grid-angular>
    </div>
  </as-split-area>
  <as-split-area size="38" minSize="38" maxSize="38">
    <div class="mt5" style="height:calc(100% - 150px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="refundList"
        [pagination]="false"
        [paginationPageSize]="paginationPageSize"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [getRowHeight]="getRowHeight"

        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked($event)"
        >
      </ag-grid-angular>
      <!-- <section class="pagination mt10">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="search.totalCount"
          [maxSize]="20"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </section> -->
    </div>
  </as-split-area>
</as-split>