/*******************************************************************************
  설  명 : 상사회원 담당자 추가/수정/삭제
  작성일 : 2020-12-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';

@Component({
  selector: 'app-member-company-add',
  templateUrl: './member-company-add.component.html',
  styleUrls: ['./member-company-add.component.scss']
})
export class MemberCompanyAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public shopSeq: any;
  public seq: any;

  public title: any = '추가';

  public form: FormGroup;
  public formErrors = {};

  public searchMemberFunc: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      shop_seq: ['', [] ],
      seq: ['', [] ],
      mem_no: ['', [] ],
      searchName: ['', [Validators.maxLength(50)] ],
      name: ['', [Validators.required, Validators.maxLength(20)] ],
      email: ['', [Validators.maxLength(100)] ],
      hp: ['', [Validators.maxLength(20)] ],
      tel: ['', [Validators.maxLength(20)] ],
      gender: ['M', [] ],
      is_manager: ['0', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    this.buildForm();

    this.searchMemberFunc = this.searchMember.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      shop_seq: this.shopSeq,
      seq: this.seq
    });

    if( this.seq == 0 ) {
      this.title = '추가';

    } else {
      this.title = '수정';

      // 담당자 정보 가져오기
      this.getMemberShopChargeInfo( this.seq );
    }
  }

  /*******************************************************************************
    설  명 : 담당자 정보 가져오기
    입력값 : seq = 담당자 코드
    리턴값 : 없음
  *******************************************************************************/
  getMemberShopChargeInfo( seq: any ) {
    this.memberService.getMemberShopChargeInfo( seq ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          shop_seq: response.data.shop_seq,
          seq: response.data.seq,
          mem_no: response.data.mem_no,
          searchName: '',
          name: response.data.name,
          email: response.data.email,
          hp: response.data.hp,
          tel: response.data.tel,
          gender: response.data.gender,
          is_manager: response.data.is_manager
        });

      } else {
        this.toastrService.error( "담당자 정보를 가져오는데 실패하였습니다.", "담당자 수정" );

        this.activeModal.close(true);
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    if( this.form.controls.searchName.value == '' ) {
      this.toastrService.error( "검색할 회원아이디를 입력하세요.", "회원검색" );
      return false;
    }

    this.memberService.getMemberDetailID( this.form.controls.searchName.value ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          mem_no: response.data.mem_no,
          name: response.data.name,
          email: response.data.email,
          tel: response.data.tel,
          hp: response.data.hp,
          gender: response.data.gender
        });

      } else {
        this.toastrService.error( response.ResultMessage, "회원검색" );
      }
    });
  }

  /*******************************************************************************
    설  명 : 상사 담당자 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.memberService.setMemberShopCharge(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '상사 담당자 저장');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '상사 담당자 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '상사 담당자 저장');
    }
  }

  /*******************************************************************************
    설  명 : 상사 담당자 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delMemberShopCharge() {
    if( confirm("선택하신 담당자를 삭제하시겠습니까?") ) {
      this.memberService.delMemberShopCharge(this.seq).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '상사 담당자 삭제');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '상사 담당자 삭제');
        }
      });
    }
  }

}
