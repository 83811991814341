<form [formGroup]="form" (ngSubmit)="setOrderAmtSave()">

  <div class="modal-header">
    <h4 class="modal-title fb mb0">주문금액 수정</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
  
  <div class="modal-body">
    <table class="table table-bordered table-small mb0">
    <caption class="none">주문금액 정보</caption>
    <colgroup>
      <col style="width:30%;" />
      <col style="width:70%;" />
    </colgroup>
    <tbody>
      <tr>
        <th scope="row"><label for="qty">수량</label></th>
        <td><inputEx type="text" name="qty" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="amt">구매단가</label></th>
        <td><inputEx type="text" name="buy_price" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>      
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="amt">판매금액</label></th>
        <td><inputEx type="text" name="amt" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangeAmt($event)"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="discount_amt">할인금액</label></th>
        <td><inputEx type="text" name="discount_amt" [formGroup]="form" [formErrors]="formErrors" (keyup)="onChangeAmt($event)"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="calc_amt">할인 후 판매금액</label></th>
        <td><inputEx type="text" name="calc_amt" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="total_amt">소계</label></th>
        <td><inputEx type="text" name="total_amt" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="mileage">마일리지</label></th>
        <td><inputEx type="text" name="mileage" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="order_status">상태</label></th>
        <td><inputEx type="select" name="order_status" [formGroup]="form" [formErrors]="formErrors" [data]="orderStatusList"></inputEx></td>
      </tr>
    </tbody>
    </table>
  </div>
  
  <div class="modal-footer">
    <div class="fl w50p">
      <!-- <button *ngIf="form.controls.seq.value!=0" type="button" class="btn btn-danger btn-small">삭제하기</button> -->
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
      <button type="submit" class="btn btn-primary btn-small ml5">저장하기</button>
    </div>
  </div>
  
  </form>