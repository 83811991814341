/*******************************************************************************
  설  명 : 상품마진 관리
  작성일 : 2020-09-24
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ProductService } from '@app/service/product.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { CommonService } from '@app/service/common.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { ProductSellGraphComponent } from '@page/product/product-sell-graph/product-sell-graph.component';
import { ProductMarginPriceComponent } from '@page/product/product-margin/product-margin-price/product-margin-price.component';
import { ProductMarginAddComponent } from '@page/product/product-margin/margin-price-add/margin-price-add.component';
import * as moment from 'moment';
import calcPrice from '@components/calcPrice';
import * as XLSX from 'xlsx'

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXS: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-margin',
  templateUrl: './product-margin.component.html',
  styleUrls: ['./product-margin.component.scss']
})
export class ProductMarginComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  private subscription: Subscription = new Subscription;

  public search: any = {
    // pageNo: 1,
    // pageRow: 10,
    listType: 0,
    // searchText: '',
    category_seq: '',
    brand_seq: '',
    // soldout: '',
    // productType: '',
    // display: ''
    productSeq: '',
    propertySeq: ''
  };

  // public statistic: any = {
  //   // productCnt: 0,
  //   searchCnt: 0,
  //   waitCnt: 0,
  //   completeCnt: 0,
  // }

  public categoryList: any = [];
  public brandList: any = [];
  public marginList: any = [];
  public historyList: any = [];

  public dealTypeList: any = [];

  public priceSeq: any;

  public productPriceList: any;

  public totalCount: number = 0;

  // public pageCount: number = 0;

  public components: any;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridHistoryApi: any;
  gridHistoryColumnApi: any;
  historycolumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private productService: ProductService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private agGridExComponent: AgGridExComponent,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 40, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품번호', field: 'product_seq', width: 80, cellClass: 'cp'},
      { headerName: '옵션번호', field: 'property_seq', width: 80, cellClass: 'cp'},
      { headerName: '가격번호', field: 'price_seq', width: 80, cellClass: 'cp', hide: true},
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'product_name', width: 230, cellClass: 'left ag-cell80h-br cell-wrap-text', autoHeight: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let categoryName = ( params.data.category_name ) ? params.data.category_name + '<br/>' : '';
          let brandName = ( params.data.brand_name ) ? '[' + params.data.brand_name + ']<br/>' : '';

          return categoryName + brandName + params.data.product_name;
        }
      },
      { headerName: '색상/사이즈', field: '', width: 100, cellClass: 'center ag-cell130h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let color_name = ( params.data.color_name ) ? params.data.color_name : '';
          let display_color_name = ( params.data.display_color_name ) ? '(' + params.data.display_color_name + ')' : '';
          let size_name = ( params.data.size_name ) ? params.data.size_name : '';
          let display_size_name = ( params.data.display_size_name ) ? '(' + params.data.display_size_name + ')' : '';

          return color_name +  display_color_name + '<br/>' + size_name + display_size_name;
        }
      },
      { headerName: '공급자', field: 'provider_name', cellClass: 'left ag-cell80h-br cell-wrap-text', autoHeight: true, width: 120 },
      { headerName: '마진율', field: 'margin_rate', cellClass: 'center ag-cell80h', width: 65, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.margin_rate < 10 ) {
            return `<span style="color:red;">${params.data.margin_rate} %</span>`;
          } else {
            return `${params.data.margin_rate} %`;
          }
        }
      },
      { headerName: '표시가격', field: 'display_price', width: 90, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '소비자가격', field: 'consumer_price', width: 90, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '판매가격', field: 'sale_price', width: 90, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '상사가격', field: 'com_price', width: 90, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '협력점가격', field: 'b2b_price', width: 90, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '프랜차이즈가격', field: 'fra_price', width: 110, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '구매가격', field: 'buy_price', width: 90, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '마일리지', field: 'mileage', width: 90, cellClass: 'cp right ag-cell80h ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter,
        cellEditor: 'numericCellEditor'
      },
      { headerName: '판매현황', field: '', width: 80, cellClass: 'center ag-cell80h',
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          label: '보기',
          btnClass: 'btn btn-default btn-small',
          clicked: this.onProductSellGraphView.bind(this),
        },
      },
      { headerName: '처리', field: '', width: 70, cellClass: 'center ag-cell80h',
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          label: '수정',
          btnClass: 'btn btn-default btn-small',
          clicked: this.setProductMarginSave.bind(this),
        },
      },
    ];

    this.historycolumnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, 
        checkboxSelection: true },
      { headerName: '적용시작일자', field: 'app_date', cellClass: 'cp center', width: 170 },
      { headerName: '표시가격', field: 'display_price', cellClass: 'cp right', width: 170, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter
      },
      { headerName: '소비자가격', field: 'consumer_price', cellClass: 'cp right', width: 170, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter
      },
      { headerName: '판매가격', field: 'sale_price', cellClass: 'cp right', width: 170,cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter
      },
      { headerName: '상사가격', field: 'com_price', cellClass: 'cp right', width: 170, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter
      },
      { headerName: '협력점가격', field: 'b2b_price', cellClass: 'cp right', width: 170, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter
      },
      { headerName: '프랜차이즈가격', field: 'fra_price', cellClass: 'cp right', width: 170, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter
      },
      { headerName: '구매가격', field: 'buy_price', cellClass: 'cp right', width: 170, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter
      },
      { headerName: '마일리지', field: 'mileage', cellClass: 'cp right', width: 170, cellRenderer: 'agGridHtmlComponent',
        valueGetter: currencyFormatter
      }
    ]

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor(),
      btnCellRenderer: this.agGridExComponent.getBtnCellRenderer()
    };

    function currencyFormatter(params: any) {
      const str = String(params.data[ params.column.colId ]);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCategoryList();
    this.getBrandList();
    this.getCommonList();

    this.subscription = this.activatedRoute.queryParams.subscribe( async params => {
      // this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.search.productSeq = ( typeof params.productSeq == 'undefined' || params.productSeq == '' ) ? '' : params.productSeq;
      this.search.propertySeq = ( typeof params.propertySeq == 'undefined' || params.propertySeq == '' ) ? '' : params.propertySeq;

      // 상품마진 리스트 가져오기
      if( this.search.productSeq !== '' || this.search.propertySeq !== '' ) {
        this.getProductMarginList();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 거래형태
    this.commonService.getCommonListCode('PDT').then( response => {
      if ( response.ResultCode ) {
        this.dealTypeList = response.data;
      } else {
        this.dealTypeList = [];
      }
    });
  }


  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandListForComponent({}).then( response => {
      this.brandList = [];
      if( response.ResultCode ) {
        // this.brandList = response.data;

        this.brandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.brandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.brandList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품마진 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductMarginList() {
    this.productService.getProductMarginList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.marginList = response.data;
        // this.statistic = response.statistic;
        // this.totalCount = this.statistic.productCnt;
        // this.pageCount = this.statistic.searchCnt;

        let nodes = '';
        setTimeout(() => {
          nodes = this.gridApi.getSelectedNodes();

          if( nodes.length < 1 ) {
            let index = 0;
            this.gridApi.forEachNode(function(node) {
              if( index++ == 0 ) {
                node.setSelected(true);
              }
            });
          }
        }, 0);

        if( this.marginList.length > 0 ) {
          this.priceSeq = this.marginList[0].property_seq;
          this.getPriceHistory();
        }
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  getPriceHistory() {
    this.productService.getPriceHistory( this.priceSeq ).then( response => {
      if( response.ResultCode ) {
        this.historyList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    })
  }

  /*******************************************************************************
    설  명 : 상품마진 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductMarginSave( data: any ) {
    this.productService.setProductMarginSave( data ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getPriceHistory();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }


  /*******************************************************************************
    설  명 : 가격업로드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addPriceUpload() {
    const modalRef = this.modalService.open(ProductMarginPriceComponent, optionsXS);

    modalRef.result.then((result) => {
      if( result ) {
        this.getProductMarginList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 가격등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addPrice() {
    const nodes = this.gridApi.getSelectedNodes();

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data );
    }
    if(data.length < 1) {
      this.toastrService.error('등록할 상품을 선택해 주세요.');
    } else {
      const modalRef = this.modalService.open(ProductMarginAddComponent, optionsXS);
      modalRef.componentInstance.selectedItem = data;
      modalRef.result.then((result) => {
        if( result ) {
          this.getProductMarginList();
        }
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 가격삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delPrice() {
    const rows = this.gridHistoryApi.getSelectedRows();

    if(rows.length < 1) {
      this.toastrService.error('삭제할 가격을 선택해 주세요.');
    } else if( rows[0].app_date <= moment().format('YYYY-MM-DD') ) {
      this.toastrService.error('아직 적용되지 않은 미적용 상태일 때 삭제가 가능합니다.');
    } else {
      if( confirm("선택하신 가격을 삭제하시겠습니까?") ) {
        this.productService.setProductMarginDelete( rows[0].seq ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getProductMarginList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridHistoryReady(params) {
    this.gridHistoryApi = params.api;
    this.gridHistoryColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.priceSeq = $event.data.property_seq;

    if( !$event.colDef.editable ) {
      this.getPriceHistory();
    }
  }


  onCellEditingStopped($event) {
    const buyPrice = $event.node.data.buy_price;
    const consumerPrice = $event.node.data.consumer_price;
    const price = calcPrice(consumerPrice, $event.node.data.product_gbn, buyPrice, $event.node.data.brand_seq)
    switch ($event.colDef.field) {
      case 'consumer_price':
        $event.node.setDataValue( 'margin_rate', this.rateCalc(consumerPrice, buyPrice) );
        $event.node.setDataValue( 'mileage', this.mileCalc(consumerPrice) );
        $event.node.setDataValue( 'display_price', consumerPrice );
        $event.node.setDataValue( 'sale_price', consumerPrice );
        // $event.node.setDataValue( 'com_price', this.comCalc(consumerPrice, buyPrice) );
        // $event.node.setDataValue( 'b2b_price', this.b2bCalc(consumerPrice, buyPrice) );
        // $event.node.setDataValue( 'fra_price', this.fraCalc(consumerPrice) );
        
        $event.node.setDataValue('com_price', price.com)
        $event.node.setDataValue('b2b_price', price.b2b)
        if(price.fra) $event.node.setDataValue('fra_price', price.fra)

        break;
      case 'buy_price':
        $event.node.setDataValue( 'margin_rate', this.rateCalc(consumerPrice, buyPrice) );
        // $event.node.setDataValue( 'com_price', this.comCalc(consumerPrice, buyPrice) );
        // $event.node.setDataValue( 'b2b_price', this.b2bCalc(consumerPrice, buyPrice) );
        // $event.node.setDataValue( 'fra_price', this.fraCalc(consumerPrice) );

        $event.node.setDataValue('com_price', price.com)
        $event.node.setDataValue('b2b_price', price.b2b)
        if(price.fra) $event.node.setDataValue('fra_price', price.fra)
        break;
    }
  }

  rateCalc(originPrice, originPrice1) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? (((originPrice - originPrice1)/originPrice) * 100).toFixed(0) : 0;
  }

  mileCalc(originPrice) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? originPrice * 0.01 : 0;
  }

  comCalc(originPrice, originPrice1) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? Math.ceil((originPrice - ((originPrice - originPrice1) * 0.3)) / 100) * 100 : 0;
  }

  b2bCalc(originPrice, originPrice1) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? Math.ceil((originPrice - ((originPrice - originPrice1) * 0.5)) / 100) * 100 : 0;
  }

  fraCalc(originPrice) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? (originPrice - (originPrice * 0.35)).toFixed(0) : 0;
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onBrandChanged( event: any ) {
    this.search.brand_seq = event;
    this.getProductMarginList();
  }

  /*******************************************************************************
    설  명 : 각 행의 수정버튼 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowBtnClicked( event: any ) {
  }

  /*******************************************************************************
    설  명 : 리스트 타입 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setListTypeChange() {
    this.search = {
      listType: !this.search.listType,
      category_seq: '',
      brand_seq: '',
      productSeq: '',
      propertySeq: ''
    };

    this.marginList = [];

    this.getProductMarginList();
  }

  /*******************************************************************************
    설  명 : 판매현황보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onProductSellGraphView( data: any ) {
    const modalRef = this.modalService.open(ProductSellGraphComponent, options);

    modalRef.componentInstance.productSeq = data.seq;
    modalRef.componentInstance.productName = data.product_name;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      // pageNo: 1,
      // pageRow: 10,
      listType: 0,
      // searchText: '',
      category_seq: '',
      brand_seq: {id: '0'},
      // soldout: '',
      // productType: '',
      // display: ''
      productSeq: '',
      propertySeq: ''
    };

    this.marginList = [];
    this.historyList = [];

    this.getProductMarginList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductMarginList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getProductMarginList();
  }
  
  /*******************************************************************************
    설  명 : 가격 엑셀 업로드
  *******************************************************************************/
  fileChanged($event: any) {
    if(!$event.target?.files?.[0]) return
    else if(!confirm('가격 업로드를 진행할까요?')) return
    
    const target: DataTransfer = <DataTransfer>($event.target)
    const reader: FileReader = new FileReader()
    reader.onload = (e: any) => {
      const bstr: string = e.target.result
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'})
      const wsname: string = wb.SheetNames[0]
      const ws: XLSX.WorkSheet = wb.Sheets[wsname]
      const data: any = XLSX.utils.sheet_to_json(ws, {header: 1})
      const dataParsing = data.filter((item: any) => item.length)
      dataParsing.shift()
      const header = dataParsing.shift()

      const columnHeaderArray = ['price_seq', 'display_price', 'consumer_price', 'sale_price', 'com_price', 'b2b_price', 'fra_price', 'buy_price', 'mileage']
      const indexArray = ['가격번호', '표시가격', '소비자가격', '판매가격', '상사가격', '협력점가격', '프랜차이즈가격', '구매가격', '마일리지'].map((item: any) => header.findIndex((e: any) => e == item))

      if(indexArray.filter((item: any) => 0 > item).length) return this.toastrService.error('파일이 올바른지 확인하십시오.', '필드 오류')

      const dataList = dataParsing.map((e: any) => {
        const object = {}
        columnHeaderArray.forEach((item: any, index: any) => object[item] = e[indexArray[index]])
        return object
      })

      const max = 300
      Promise.all(
        [...Array( Math.ceil(dataList.length / max) ).keys()]
        .map((item: any) => this.productService.uploadProductMarginList({
        dataList : dataList.slice(item * max, item * max + max)
      }))).then(res => {
        const result = res.filter((item: any) => !item.ResultCode).length ? false : true
        const message = '가격 업로드'
        if(result) {
          this.toastrService.success(`${message} 성공`)
          this.getProductMarginList()
        } else this.toastrService.error(`${message} 실패`)
      })
    }

    reader.readAsBinaryString(target.files[0])
  }

}
