import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { BikeService } from '@app/service/bike.service';
import { CommonService } from '@app/service/common.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { BikeModelAddComponent } from '@page/bike/bike-model/bike-model-add/bike-model-add.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-bike-model',
  templateUrl: './bike-model.component.html',
  styleUrls: ['./bike-model.component.scss']
})
export class BikeModelComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 25,
    seq: '',
    searchText: '',
    totalCount: 0,
    bikeStyle: '',
  };

  public baseURL = config.baseUrl;

  public modelList: any = [];
  public bikeStyleList: any = [];

  // 그리드 관련 선언
  components: any;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private bikeService: BikeService,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '순번', field: 'seq', width: 68, cellClass: 'cp center' },
      { headerName: '바이크종류', field: 'bike_style_name', width: 120, cellClass: 'cp center' },
      { headerName: '브랜드명', field: 'brand_name', width: 200, cellClass: 'cp left' },
      { headerName: '모델명', field: 'model', width: 330, cellClass: 'cp left' },
      { headerName: '제품코드', field: 'p_code', width: 120, cellClass: 'cp left' },
      { headerName: '배기량(cc)', field: 'piston', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '엔진형식', field: 'engine', width: 250, cellClass: 'cp left' },
      { headerName: '색상', field: 'color', width: 150, cellClass: 'cp left' },
      // { headerName: '최초가격', field: 'org_price', width: 95, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      // { headerName: '판매가격', field: 'sale_price', width: 95, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      // { headerName: '수량', field: 'qty', width: 70, cellClass: 'cp center', valueFormatter: this.agGridExComponent.currencyFormatter },
      // { headerName: '튜닝여부', field: 'is_tuning', width: 95, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter(params: any) {
      //     if( params.data.is_tuning === '1' ) {
      //       return '<span class="badge badge-info f12">튜닝함</span>';
      //     } else {
      //       return '<span class="badge badge-secondary f12">튜닝안함</span>';
      //     }
      //   }
      // },
      // { headerName: '시/도', field: 'region1', width: 109, cellClass: 'cp center' },
      // { headerName: '구/군', field: 'region2', width: 96, cellClass: 'cp center' },
      // { headerName: '결제도우미', field: 'trade_helper', width: 105, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter(params: any) {
      //     if( params.data.trade_helper === '1' ) {
      //       return '<span class="badge badge-primary f12">사용</span>';
      //     } else {
      //       return '<span class="badge badge-secondary f12">사용안함</span>';
      //     }
      //   }
      // },
      { headerName: '등록자', field: 'writer_name', width: 100, cellClass: 'cp center' },
      { headerName: '등록일자', field: 'write_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.search.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? 1 : params.pageNo;
        this.search.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? 10 : params.pageRow;
        this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
        this.search.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;
        this.search.bikeStyle = ( typeof params.bikeStyle == 'undefined' ) ? '' : params.bikeStyle;
      }

      if( this.search.pageNo == 1 ) this.getBikeProductList();
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }
  
  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 바이크종류
    this.commonService.getCommonListCode('BKS').then( response => {
      if ( response.ResultCode ) {
        this.bikeStyleList = response.data;
      } else {
        this.bikeStyleList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 모델 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBikeProductList() {
    this.bikeService.getBikeProductList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.modelList = response.data;
        this.search.totalCount = response.total;
      } else {
        this.toastrService.error(response.ResultMessage);
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 주문내역 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelOrder() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>오토바이 모델 목록</strong></caption>
        <colgroup>
          <col style="width:80px;" />
          <col style="width:100px;" />
          <col style="width:200px;" />
          <col style="width:300px;" />
          <col style="width:100px;" />
          <col style="width:80px;" />
          <col style="width:450px;" />
          <col style="width:150px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>순번</td>
          <td>바이크종류</td>
          <td>브랜드명</td>
          <td>모델명</td>
          <td>제품코드</td>
          <td>배기량</td>
          <td>엔진형식</td>
          <td>색상</td>
          <td>등록자</td>
          <td>등록일자</td>
        </tr>
    `]

    let params = {
      searchText: this.search.searchText,
      bikeStyle: this.search.bikeStyle,
    };

    this.bikeService.getBikeProductList( params ).then( response => {
      if( response.ResultCode ) {

        let data = response.data;

        data.forEach(element => {
          contents.push(`
            <tr>
              <td style="text-align:center;">${element.seq}</td>
              <td style="text-align:center;">${element.bike_style_name}</td>
              <td style="text-align:left;">${element.brand_name}</td>
              <td style="text-align:left;">${element.model}</td>
              <td style="text-align:center;">${element.p_code ? element.p_code : ''}</td>
              <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.piston !== null ? element.piston : 0}</td>
              <td style="text-align:left;">${element.engine ? element.engine : ''}</td>
              <td style="text-align:left;">${element.color ? element.color : ''}</td>
              <td style="text-align:center;">${element.writer_name}</td>
              <td style="text-align:center;">${element.write_date}</td>
            </tr>
            </tbody>
          `)
        })
        contents.push(`</table>`)
    
        let today = new Date();
    
        const fileName = `bikemart_bike_model_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`
    
        this.printExcel(fileName, contents.join(''));

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }


  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    //this.search.pageNo = page;
    this.getBikeProductList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : row 더블클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked(event: any) {
    this.openBikecModelAdd(event.data);
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 25,
      seq: '',
      searchText: '',
      totalCount: 0,
      bikeStyle: '',
    };

    this.getBikeProductList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getBikeProductList();
    }
  }

  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openBikecModelAdd( data: any ) {
    const modalRef = this.modalService.open(BikeModelAddComponent, optionsLG);

    modalRef.componentInstance.data = data;
  
    modalRef.result.then((result) => {
      if( result ) {
        this.getBikeProductList();
      }
    }, (reason) => {
    });
  }
  
}
