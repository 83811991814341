import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardService } from '@app/service/board.service';
import { config } from '@app/service/config.service';

@Component({
  selector: 'app-board-product',
  templateUrl: './board-product.component.html',
  styleUrls: ['./board-product.component.scss']
})
export class BoardProductComponent implements OnInit {

  public baseURL = config.baseUrl;

  public productQna: any = [];
  public totalCount: number;

  public maxSize: any = 10;

  public params: any = {
    seq: '',
    status: '',
    group: 'comment',
    text: '',
    row: 15,
    no: 1,
  };


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;
      this.params.status = ( typeof params.status == 'undefined' || params.status ) ? '' : params.status;
    }).unsubscribe();

    this.getProductQuestion();
  }

  getProductQuestion() {
    this.boardService.getProductQuestion(this.params).then(response => {
      if(response.ResultCode) {
        this.productQna = response.data
        this.totalCount = response.count
      } else {
        this.productQna = [];
      }
    });
  }

  view( row: any ) {
    this.params.seq = row.seq
    this.router.navigate(
    ['/board/productView'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  loadPage( page ) {
    this.params.pageNo = page
    this.getProductQuestion()
  }


   /*******************************************************************************
    설  명 : 주소 검색 input enter 키 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    searchService( $event ) {
      if( $event.key == 'Enter' ) {
        this.getProductQuestion();
      }
    }

   /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
    searchBtn( key, value ) {
      this.params[key] = value;
  
      this.getProductQuestion();
    }

    /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.params = {
      type: 'list',
      status: '',
      group: 'comment',
      text: '',
      row: 15,
      no: 1,
    };

    this.getProductQuestion();
  }

  productDetail(row) {
    const url = this.baseURL + '/product/info?productSeq=' + row;
    window.open("about:blank").location.href = url;
  }
}
