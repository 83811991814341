/*******************************************************************************
  설  명 : 게시판정보 추가
  작성일 : 2019-09-30
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';

import { BoardService } from '@app/service/board.service';
import { CommonService } from '@app/service/common.service';
import { AddTableComponent } from '@app/page/board/management/add-table/add-table.component';
import { SystemAuthGroupService } from '@app/service/auth.group.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-amanagement-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class BoardInfoAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  // 게시판 테이블이름
  public boardTableList: any = [];
  public commentTableList: any = [];
  public uploadTableList: any = [];
  public recommendTableList: any = [];
  public oppositeTableList: any = [];

  // 권한그룹
  public authGroupList: any = [];

  // 게시판 타입
  public boardTypeList: any = [];

  // 게시판코드
  public seq: any = 0;

  public addTableFunc: any;           // 게시판 테이블 추가 function
  public addTableCommentFunc: any;    // 게시판 의견 테이블 추가 function
  public addTableUploadFunc: any;     // 게시판 업로드 테이블 추가 function
  public addTableRecommendFunc: any;  // 게시판 추천 테이블 추가 function
  public addTableOppositeFunc: any;   // 게시판 반대 테이블 추가 function

  public config = {
    placeholder: '',
    tabsize: 2,
    height: 100,
    uploadImagePath: '',
    toolbar: [
        // [groupName, [list of button]]
        ['misc', ['codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'picture', 'link', 'video', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      board_id:["", [Validators.required, Validators.maxLength(50)] ],
      group:["", [Validators.maxLength(1000)] ],
      board_name:["", [Validators.required, Validators.maxLength(100)] ],
      table_name:["", [Validators.required, Validators.maxLength(100)] ],
      comment_table_name:["", [] ],
      upload_table_name:["", [] ],
      recommend_table_name:["", [] ],
      opposite_table_name:["", [] ],
      board_type:["", [Validators.required] ],
      lvt_default:["L", [] ],
      lvt_list:[true, [] ],
      lvt_album:[false, [] ],
      lvt_gallery:[false, [] ],
      lvt_calendar:[false, [] ],
      lvt_group:[false, [] ],
      linecnt_list:["10/20/30/50", [Validators.maxLength(50)] ],
      linecnt_album:["3/5/10/20", [Validators.maxLength(50)] ],
      linecnt_gallery:["5/10/15/20", [Validators.maxLength(50)] ],
      auth_write:[80, [Validators.maxLength(2)] ],
      auth_modify:[80, [Validators.maxLength(2)] ],
      auth_del:[80, [Validators.maxLength(2)] ],
      auth_view:[0, [Validators.maxLength(2)] ],
      auth_reply:[80, [Validators.maxLength(2)] ],
      auth_comment:[-1, [Validators.maxLength(2)] ],
      auth_recommend:[-1, [Validators.maxLength(2)] ],
      auth_opposite:[-1, [Validators.maxLength(2)] ],
      auth_upload_file:[80, [Validators.maxLength(2)] ],
      auth_upload_image:[1, [Validators.maxLength(2)] ],
      notice_rows:["3", [Validators.maxLength(2)] ],
      upload_total_size:["50", [Validators.maxLength(50)] ],
      upload_dir_autocreate:["Y/m", [Validators.maxLength(50)] ],
      upload_file_type:["hwp|pdf|doc|docx|xls|xlsx|ppt|pptx", [Validators.maxLength(100)] ],
      upload_file_dir:["/upload/files", [Validators.maxLength(255)] ],
      upload_image_down:[false, [] ],
      upload_image_dir:["/upload/images", [Validators.maxLength(255)] ],
      upload_image_max_width:["1920", [Validators.maxLength(4)] ],
      upload_image_max_height:["1080", [Validators.maxLength(4)] ],
      upload_image_max_size:["10", [Validators.maxLength(11)] ],
      upload_image_type:["jpg|jpeg|png|gif|bmp", [Validators.maxLength(100)] ],
      is_thumbnail:[true, [] ],
      is_approval:[false, [] ],
      approval_default:[true, [] ],
      new_date:["7", [Validators.maxLength(2)] ],
      header:["", [] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private boardService: BoardService,
    public authService: AuthService,
    private commonService: CommonService,
    private systemAuthGroupService: SystemAuthGroupService
  ) {
    this.buildForm();

    this.addTableFunc = this.addTableBoard.bind(this);
    this.addTableCommentFunc = this.addTableComment.bind(this);
    this.addTableUploadFunc = this.addTableUpload.bind(this);
    this.addTableRecommendFunc = this.addTableRecommend.bind(this);
    this.addTableOppositeFunc = this.addTableOpposite.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

    let data = ['BOARD', 'COMMENT', 'UPLOAD', 'RECOMMEND', 'OPPOSITE'];

    // 게시판 타입 리스트 가져오기
    this.getTableTypeList();

    // 게시판 테이블 리스트 가져오기
    data.forEach( type => {
      this.getTableNameList( type );
    });

    this.getAuthGroupList();
  }

  /*******************************************************************************
    설  명 : 권한그룹 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAuthGroupList() {
    this.systemAuthGroupService.getAuthGroupList().then( response => {
      if( response.ResultCode ) {
        let auth = [
          {value: '-1', title: '사용안함'},
          {value: '0', title: '비회원'},
          {value: '1', title: '일반회원'}
        ];

        for(let item of response.data ) {
          auth.push({
            value: item.member_level,
            title: item.name
          });
        }

        this.authGroupList = auth;
      } else {
        this.authGroupList = [];
      }
    });
  }


  /*******************************************************************************
    설  명 : 표시 처리 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.seq == 0 ) {
      this.title = '추가';
    } else {
      this.title = '수정';

      // 게시판 정보 가져오기
      this.getBoardInfo();
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    if(this.formAdd.valid) {
      this.boardService.setBoardInfo(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo() {
    this.boardService.getBoardInfo( this.seq ).then( response => {
      if( response.ResultCode )
        this.formAdd.patchValue( response.data );
    });
  }

  /*******************************************************************************
    설  명 : 게시판 테이블 리스트 가져오기
    입력값 : type = BOARD, COMMENT, UPLOAD, RECOMMEND, OPPOSITE
    리턴값 : 없음
  *******************************************************************************/
  getTableNameList( type ) {
    this.boardService.getTableNameList( type ).then( response => {

      let boardList: any;

      boardList = [];

      for( let index in response.data ) {
        boardList.push({
          title: response.data[index].table_name,
          value: response.data[index].seq
        });
      }

      switch( type ) {
        case 'BOARD': this.boardTableList = boardList; break;
        case 'COMMENT': this.commentTableList = boardList; break;
        case 'UPLOAD': this.uploadTableList = boardList; break;
        case 'RECOMMEND': this.recommendTableList = boardList; break;
        case 'OPPOSITE': this.oppositeTableList = boardList; break;
        default: return false;
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시판 타입 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTableTypeList() {
    this.commonService.getCommonList("BRD").then( response => {
      this.boardTypeList = [];

      for( let index in response.data ) {
        this.boardTypeList.push({
          title: response.data[index].common_name,
          value: response.data[index].seq
        });
      }

      if( response.ResultCode && this.boardTypeList.length > 0 )
        this.formAdd.patchValue({board_type: this.boardTypeList[0].value});
    });
  }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("선택하신 게시판을 삭제하시겠습니까?") ) {
      this.boardService.deleteBoardInfo( this.formAdd ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 게시판 테이블 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addTable( type ) {
    const modalRef = this.modalService.open(AddTableComponent, options);

    modalRef.componentInstance.type = type;

    modalRef.result.then((result) => {
      this.getTableNameList( type );
    }, (reason) => {
    });
  }

  addTableBoard() { this.addTable( 'BOARD' ); }
  addTableComment() { this.addTable( 'COMMENT' ); }
  addTableUpload() { this.addTable( 'UPLOAD' ); }
  addTableRecommend() { this.addTable( 'RECOMMEND' ); }
  addTableOpposite() { this.addTable( 'OPPOSITE' ); }

}
