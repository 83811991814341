
/*******************************************************************************
  설  명 : 추천키워드 추가
  작성일 : 2023-10-20
  작성자 : 권세창
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ColorEvent } from 'ngx-color';

import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { AuthService } from '@app/service/auth.service';

@Component({
  selector: 'app-product-recommendkeyword-add',
  templateUrl: './product-recommendkeyword-add.component.html',
  styleUrls: ['./product-recommendkeyword-add.component.scss']
})
export class ProductRecommendkeywordAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: any = 0;
  public title: string = '추가';

  public formAdd: FormGroup;
  public formErrors = {};

  public isColorPicker: boolean = false;
  public bgcolor: any = '';

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formAdd = this.formBuilder.group({
      seq:["", [] ],
      keyword:["", [Validators.required, Validators.maxLength(50)] ],
      use_yn:["1", [] ],
    });

    this.formAdd.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formAdd, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 데이터가 넘어온 경우 데이터 설정
    if( this.seq !== 0 ) {
      this.title = '수정';

      this.getRecommendKeywordInfo();
    }
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formAdd, this.formErrors);

    let title = ( this.seq != 0 ) ? '수정' : '추가';

    if(this.formAdd.valid) {
      this.productService.setRecommendKeyword(this.formAdd).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '추천키워드 ' + title);

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '추천키워드 ' + title);
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '추천키워드 ' + title);
    }
  }

  /*******************************************************************************
    설  명 : 추천키워드 정보를 가져와 바인딩한다. 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRecommendKeywordInfo() {
    this.productService.getRecommendKeywordInfo( this.seq ).then( response => {
      if( response.ResultCode ) {
        let data: any = {
          seq: response.data.seq,
          keyword: response.data.keyword,
          use_yn: response.data.use_yn,
        }

        this.formAdd.patchValue( data );

        this.bgcolor = response.data.color;
      }
    });
  }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteRecommendKeyword() {
    if( confirm("선택하신 추천키워드를 삭제하시겠습니까?") ) {
      this.productService.deleteRecommendKeyword( this.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '색상 삭제');

          this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '색상 삭제');
        }
      });
    }
  }


}

