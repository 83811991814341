import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  // 일매출속보실적 가져오기
  getStatisticDaySales( params ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getStatisticDaySales'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 일매출속보실적 상세리스트 가져오기
  getStatisticDaySalesDetail( params ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getStatisticDaySalesDetail'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 일자별매출실적 가져오기
  getStatisticMonthSales( params ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getStatisticMonthSales'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 매출 목표 & 데이터 관리 리스트 가져오기
  getStatisticGoal( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getStatisticGoal'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 매출 목표 & 데이터 관리 수정
  setStatisticGoal( data ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'setStatisticGoal'
      }, {
        params: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매출 목표 & 데이터 관리 연도 선택박스
  getYearsSelect(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getYearsSelect'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 연도별 매출 정보 가져오기
  getTotalSales(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getTotalSales'
      }, {

      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // stm 정보 가져오기
  getStmCodeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getStmCodeList'
      }, {}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 전체 매출현황 (카테고리)
  getTotalSalesCategory( categoryCode: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getTotalSalesCategory'
      }, {
        category_code: categoryCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 전체 매출현황 (브랜드)
  getTotalSalesBrand( seq ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getTotalSalesBrand'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 제품군 매출현황
  getSalesCategoryYear( search ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSalesCategoryYear'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  };

  // 브랜드 매출현황
  getSalesBrandYear( search ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSalesBrandYear'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  };

  // 오픈마켓별 매출현황
  getSalesOpenMarket(search): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSalesOpenMarket'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  };

  // 오픈마켓별 매출현황
  getSalesStatusList(search): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSalesStatusList'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  };
  
  calcStatistic(date: any, index): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'calc',
        version: '1.0',
        action: 'calcStatistic'
      }, {
        date: date,
        index: index
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  };

  calcStatisticYear(year: any, index): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'calc',
        version: '1.0',
        action: 'calcStatisticYear'
      }, {
        year: year,
        index: index
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  };

  getSaleTotalSimple(params): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSaleTotalSimple'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise)
  }
  getSaleCategorySimple(params): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSaleCategorySimple'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise)
  }
  getSaleBrandSimple(params): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSaleBrandSimple'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise)
  }
  getSaleOpenmarketSimple(params): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSaleOpenmarketSimple'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise)
  }
  getSaleBusinessSimple(params): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getSaleBusinessSimple'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise)
  }

  getOrderMasterDiscountAmount(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'statistic',
        version: '1.0',
        action: 'getOrderMasterDiscountAmount'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise)
  }

};
