  <div class="mb5">
    <div class="fl w50p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getBrandBaseDiscountList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn==''}" (click)="search.use_yn='';getBrandBaseDiscountList()">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='1'}" (click)="search.use_yn='1';getBrandBaseDiscountList()">사용</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='0'}" (click)="search.use_yn='0';getBrandBaseDiscountList()">사용안함</button>
      </div>
  
      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.purchasing_agent_yn==''}" (click)="search.purchasing_agent_yn='';getBrandBaseDiscountList()">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.purchasing_agent_yn=='1'}" (click)="search.purchasing_agent_yn='1';getBrandBaseDiscountList()">구매대행</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.purchasing_agent_yn=='0'}" (click)="search.purchasing_agent_yn='0';getBrandBaseDiscountList()">대행아님</button>
      </div>
  
      <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="브랜드 검색" />
    </div>
    <div class="fr w50p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="e13dfbf5a3114ca38637054b794c45cd" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" title="할인율 일괄 저장" class="btn btn-primary btn-small ml5" (click)="setBrandBaseDiscountSave()">일괄 저장</button>
    </div>
    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 37px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="brandList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [components]="components"
      [singleClickEdit]="true"
      [stopEditingWhenGridLosesFocus]="true"

      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>