  <section class="form-inline" style="flex-direction: column; align-items: flex-start; position: relative;">
    <button type="button" class="btn btn-primary btn-small fr" style="position: absolute; right: 0; top: 0;" (click)="moveSimplePage()">매출현황상세</button>
    <div class="mt5">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getData()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select class="form-control form-control-small ml5" placeholder="검색년도" [(ngModel)]="search.year" (change)="getSalesCategoryYear()">
        <option *ngFor="let item of years" [value]="item.common_code">{{item.common_name}}</option>
      </select>
    </div>

    <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon" (click)="getData()">
      <i class="material-icons-outlined">search</i>
    </button> -->

    <!-- <l-select2
      [data]="selectCategoryList"
      (ngModelChange)="changedCategory($event, null)"
      [(ngModel)]="search.category_select"
      class="form-control form-control-small ml5"
      [options]="optionsCategory"
      style="width:200px; padding:0px !important;border:solid 0px #fff !important;"
    ></l-select2> -->

    <div class="btn-group di">
      <button *ngFor="let item of categoryList; let i = index;" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.category==item.category_code}" (click)="changedCategory($event, item)">{{i > 0 ? i+'.' : ''}} {{item.title}}</button>
    </div>

    <hr class="w100p db mt5 mb5" />
  </section>

  <section class="chart">
    <canvas
      *ngIf="barChartData.length > 0" width="100%" height="27" baseChart
      [datasets]="barChartData"
      [labels]="chartLabel"
      [chartType]="chartType"
      [options]="chartOptions"
    ></canvas>
  </section>

  <section style="height:calc(35% - 20px);" class="mt20">
    <ag-grid-angular
      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="dataList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </section>