<form [formGroup]="formAdd" (ngSubmit)="submit()">
    <input type="hidden" id="seq" formControlName="seq" />
  
    <div class="modal-header">
      <h4 class="modal-title bold" id="modal-common-code">추천키워드 {{title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
    </div>
  
    <div class="modal-body">
      <table class="table table-bordered table-small">
      <caption class="hidden">추천키워드 추가/수정</caption>
  
      <colgroup>
        <col style="width:30%;" />
        <col style="width:70%;" />
      </colgroup>
  
      <tbody>
      <tr>
        <th scope="row"><i class="material-icons cred f11">star</i> <label for="keyword" class="control-label">추천키워드</label></th>
        <td><inputEx type="text" name="keyword" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <!-- <tr>
        <th scope="row"><label for="color_ename" class="control-label">색상명(영문)</label></th>
        <td>
          <inputEx type="text" name="color_ename" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
        </td>
      </tr> -->
      <tr>
        <th scope="row"><label for="use_yn" class="control-label">사용여부</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.use_yn.value=='1'}" (click)="formAdd.patchValue({use_yn: '1'})">사용</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.use_yn.value=='0'}" (click)="formAdd.patchValue({use_yn: '0'})">사용안함</button>
          </div>
        </td>
      </tr>
      </tbody>
      </table>
    </div>
  
    <div class="modal-footer">
      <!-- <div class="fl w50p">
        <ng-container *ngIf="authService.currAuth['auth_delete']=='1'">
        <button *ngIf="formAdd.controls.seq.value !== 0 && formAdd.controls.seq.value !== ''" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
        </ng-container>
      </div> -->
      <div class="fr w50p right ml0 mr0">
        <ng-container *ngIf="seq != '0' && authService.currAuth['auth_modify']=='1'">
          <button type="submit" class="btn btn-primary btn-small mr10 f15">저장하기</button>
        </ng-container>
        <ng-container *ngIf="seq == '0' && authService.currAuth['auth_write']=='1'">
          <button type="submit" class="btn btn-primary btn-small mr10 f15">저장하기</button>
        </ng-container>
        <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
      </div>
      <div class="cb"></div>
    </div>
  </form>