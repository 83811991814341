<section class="search-condition mb5">
  <div class="fl form-inline asub-title">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getAccountCouponOrderList()">
      <i class="material-icons-outlined">search</i>
    </button>    
    <select [(ngModel)]="search.field" size="1" class="form-control form-control-small ml10" (change)="getAccountCouponOrderList()">
      <option value="order">주문일자</option>
      <option value="calc">정산일자</option>
    </select>

    <input type="text" size="20" readonly="readonly" [(ngModel)]="search.date" class="form-control form-control-small calendar-input ml5" placeholder="기준일자" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect)="selectDate($event)" />

    <div class="btn-group ml10">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{'active': search.pay_yn == ''}" (click)="setSearch('pay_yn', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{'active': search.pay_yn == '1'}" (click)="setSearch('pay_yn', '1')">정산</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{'active': search.pay_yn == '0'}" (click)="setSearch('pay_yn', '0')">미정산</button>
    </div>           
  </div>
  <div class="fr">
  </div>
  <div class="cb"></div>
</section>

<hr class="db mt5 mb5" />

<section class="contents-body" style="height:calc(100vh - 130px);">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="20" minSize="5" maxSize="25">
      <div class="mb5">
        <div class="fl">
          <h4 class="f14 pt5 mb0 fb">쿠폰목록</h4>
        </div>
        <div class="fr">
          <input type="text" id="quickFilter" (input)="onQuickFilterChanged($event)" size="30" class="form-control form-control-small" placeholder="쿠폰코드, 회원명, 아이디 검색" />
        </div>
        <div class="cb"></div>
      </div>

      <div style="height: calc(100% - 40px);">
        <ag-grid-angular
          #myGrid
  
          style="width:100%; height:100%"
          class="ag-theme-balham"
  
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="couponList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event)"
          (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="80" minSize="50" maxSize="95">
      
      <div class="mb5">
        <div class="fl form-inline asub-title">
          <span class="pr5 f12">주문건수</span><input type="text" class="form-control form-control-small right" [value]="couponOrderStatistic.orderCount || 0 | number" [readonly]="true" />
          <span class="pl10 pr5 f12">품목건수</span><input type="text" class="form-control form-control-small right" [value]="couponOrderStatistic.productCount || 0 | number" [readonly]="true" />
          <span class="pl10 pr5 f12">판매금액</span><input type="text" class="form-control form-control-small right" [value]="couponOrderStatistic.totalAmtSum || 0 | number" [readonly]="true" />
          <span class="pl10 pr5 f12">정산금액</span><input type="text" class="form-control form-control-small right" [value]="couponOrderStatistic.compensationAmtSum || 0 | number" [readonly]="true" />
        </div>
        <div class="fr">
          <button type="button" class="btn btn-default btn-small f12 ml5"><span>엑셀다운</span></button>
          <btn-ag-grid-save class="ml5" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-danger btn-small ml5" (click)="setAccountCouponCalcCancel()">정산취소</button>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="openAccountCouponCalc()">정산처리</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height: calc(100% - 40px);">
        <ag-grid-angular
          #myGrid
  
          style="width:100%; height:100%"
          class="ag-theme-balham"
  
          [columnDefs]="columnDefsOrder"
          [defaultColDef]="defaultColDef"
          [rowData]="couponOrderList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReadyOrder($event)"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>