<div>
  <form [formGroup]="form">
    <section class="document-btn">
      <div class="btn-area form-inline fl">
        <select class="form-control mr5" (change)="onChange($event)">
          <option *ngFor="let tmp of approvalTmp" [value]="tmp.value">{{tmp.title}}</option>
        </select>   
        <button type="button" class="btn btn-default p0 mr5" (click)="openTemp();">
          <i class="material-icons menu-icon micon-settings f15 mt3">&#xe8b8;</i>
        </button>
        <button type="button" class="btn btn-default mr20">
          <i class="material-icons fl mr5 mt3 micon-vertical_align_bottom f15">&#xe258;</i><span class="fl" (click)="approvalTmpSave()">임시저장</span>     
        </button>
        
        <div class="btn-group pl20 mr5">
          <div class="btn-group">
            <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.approval_type.value=='ON'}" (click)="form.patchValue({approval_type:'ON'})">전자결재</button>
            <button type="button" class="btn btn-default" [ngClass]="{active:form.controls.approval_type.value=='OFF'}" (click)="form.patchValue({approval_type:'OFF'})">수기결재</button>
          </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="type == '1' || type == '3'">
          <i class="material-icons fl mr5 mt3 color-white micon-check f15">&#xe5ca;</i><span class="fl">결재상신</span>     
        </button>
        <button type="button" class="btn btn-primary" (click)="updateApproval()" *ngIf="type == '2'">
          <i class="material-icons fl mr5 mt3 color-white micon-check f15">&#xe5ca;</i><span class="fl">결재수정</span>     
        </button>
      </div>
      <div class="fr ml5">
        <button type="button" class="btn btn-success" (click)="addLine(true)">
          <span class="fr">결재단계 수정</span>     
        </button>
      </div>
    </section>
    
    <div class="cb mb10"></div>
    <section class="approval-area">
      <ng-container *ngIf="approvalInfo == '1001'">
        <div class="document-area fl">
          <img src="/assets/images/logo.png" alt="로고" height="26" />
          <div>
            <div class="company-ceo">바이크마트</div>
            <div class="f15 mt20">
              대구광역시 동구 신천동 152-3번지 (도로명주소 : 대구광역시 동구 장등로 27) <br />
              전화 : 1544-5022 &nbsp;&nbsp; / &nbsp;&nbsp; 전송 053-289-3354 &nbsp;&nbsp;
              / &nbsp;&nbsp; http://www.bikemart.co.kr &nbsp;&nbsp; / &nbsp;&nbsp;
              <span ng-if="member.data.email">admin@o2oz.net</span>
             </div>
            <div class="mt10" style="border-top:solid 5px #000"></div>
          </div>
          <div class="fl w60p h320p">
            <div class="subject-area">
              <div class="lh450">
                <label for="receive" class="f18">수&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;신</label>
                <input
                  [value]="form.value.receive"
                  type="text" name="receive" formControlName="receive" id="receive" size="50" class="f18 ml20 input-bottom"
                  placeholder="수신 내용을 입력하세요."
                />
              </div>
              <div class="lh450">
                <label for="reference" class="f18">참&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조</label>
                <label style="width: 500px;" class="ml20" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                  <span *ngFor="let member of approvalMember.reference">{{member.dept_name}} {{member.name}}&nbsp;&nbsp;&nbsp;</span>
                </label>
                <input
                  *ngIf="approvalMember == '' || approvalMember.reference.length < 1"
                  readonly="true"
                  type="text"size="50" class="f18 ml20 input-bottom"
                  placeholder="참조"
                />
              </div>
              <div class="lh450" [ngClass]="{lh350: approvalMember != '' && approvalMember.reference.length > 0}">
                <label for="receive" class="f18">(경&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;유)</label>
                <input
                  [value]="form.value.via"
                  type="text" name="via" formControlName="via" id="via" size="50" class="f18 ml20 input-bottom"
                  placeholder="경유 내용을 입력하세요."
                />
              </div>
              <div class="lh450">
                <label for="receive" class="f18">제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</label>
                <input
                  [value]="form.value.subject"
                  type="text" name="subject" formControlName="subject" id="subject" size="50" class="f18 ml20 input-bottom"
                  placeholder="제목을 입력하세요."
                />
              </div>
            </div>
          </div>
    
          <div class="fr w40p h320p right" *ngIf="approvalMember.length < 1">
            <table class="approval-table fr table table-bordered mt30 mb5">
            <thead>
            <tr>
              <th scope="col">
                <span>기안자</span>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
            <tbody>
            <tr>
              <td [innerHtml]="member.user_dept | safe : 'html'"></td>
                <!-- {{member.user_dept}} -->
            </tr>
            </tbody>
            </table>
          </div> 
          <div class="fr w40p h320p right" *ngIf="approvalMember != '' ">
            <table class="approval-table fr table table-bordered mt30">
              <thead>
                <tr>
                  <th *ngFor="let member1 of approvalMember.approval">
                    <span>{{member1.user_dept == member.user_dept ? '기안자' : '결재자'}}</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
              <tr>
                <td *ngFor="let member of approvalMember.approval" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                </td>
              </tr>
              </tfoot>
              <tbody>
              <tr>
                <td *ngFor="let member of approvalMember.approval" [innerHtml]="member.user_dept | safe : 'html'">
                  <!-- {{member.user_dept}} -->
                </td>
              </tr>
              </tbody>
            </table>
            <div class="cb"></div>
            <table class="approval-table fr table table-bordered mb10">
              <thead>
                <tr>
                  <th *ngFor="let member of approvalMember.agreement">
                    <span>협조</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" [innerHtml]="member.user_dept | safe : 'html'">
                    <!-- {{member.user_dept}} -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>     
          <div class="cb"></div>
          
          <div class="pt20" style="border-top:solid 2px #000">
            <inputEx type="summernote" id="summernote" name="contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
          </div>
    
          <div class="append-file">
            <label for="file" class="fl">첨부파일 :</label>
            <input
              [value]="form.value.file"
              type="file" name="file" formControlName="file" id="file" size="50" class="fl ml20"
              ng2FileSelect (change)="handleUploadFileChanged($event)"
            />
            <div class="cb"></div>
    
            <span class="f12 mt5" style="color: red;" *ngIf="form.controls.approval_type.value=='OFF'">
              수기결재 스캔파일은 결재관리에서 처리해주세요.
            </span>
          </div>
          <div class="tl">
            <div class="mt5" style="font-size: 12px;" *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
              <ul class="lstn mb3 pl10 f12">
                <li class="pt3" *ngFor="let file of form.controls.upload.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
                <li class="pt3" *ngFor="let file of form.controls.files.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>
    
      <ng-container *ngIf="approvalInfo == '1002'">
        <div class="document-area fl">
          <img src="/assets/images/logo.png" alt="로고" height="26" />
          <div>
            <div class="company-ceo">바이크마트</div>
            <div class="f15 mt20">
              대구광역시 동구 신천동 152-3번지 (도로명주소 : 대구광역시 동구 장등로 27) <br />
              전화 : 1544-5022 &nbsp;&nbsp; / &nbsp;&nbsp; 전송 053-289-3354 &nbsp;&nbsp;
              / &nbsp;&nbsp; http://www.bikemart.co.kr &nbsp;&nbsp; / &nbsp;&nbsp;
              <span ng-if="member.data.email">admin@o2oz.net</span>
             </div>
            <div class="mt10" style="border-top:solid 5px #000"></div>
          </div>
          <div class="fl w60p h320p">
            <div class="document-header">
              <div class="company-nm">출장결의서</div>
            </div>
            <div class="subject-area">
              <div class="mt5 lh350" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                <label for="receive" class="f18">참&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조</label>
                <label style="width: 500px;" class="ml20" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                  <span *ngFor="let member of approvalMember.reference">{{member.dept_name}} {{member.name}}&nbsp;&nbsp;&nbsp;</span>
                </label>
              </div>
              <div class="pb25 pt181" [ngClass]="{pt120: approvalMember != '' && approvalMember.reference.length > 0}">
                <label for="receive" class="f18">제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</label>
                <input
                  [value]="form.value.subject"
                  type="text" name="subject" formControlName="subject" id="subject" size="50" class="f18 ml20 input-bottom"
                  placeholder="출장결의서를 상신합니다."
                />
              </div>
            </div>
          </div>
          <div class="fr w40p h320p right" *ngIf="approvalMember.length < 1">
            <table class="approval-table fr table table-bordered mt30 mb5">
            <thead>
            <tr>
              <th scope="col">
                <span>기안자</span>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
        
            <tbody>
            <tr>
              <td [innerHtml]="member.user_dept | safe : 'html'"></td>
            </tr>
            </tbody>
            </table>
          </div> 
          <div class="fr w40p h320p right" *ngIf="approvalMember != '' ">
            <table class="approval-table fr table table-bordered mt30">
              <thead>
                <tr>
                  <th *ngFor="let member1 of approvalMember.approval">
                    <span>{{member1.user_dept == this.member.user_dept ? '기안자' : '결재자'}}</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
              <tr>
                <td *ngFor="let member of approvalMember.approval" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                </td>
              </tr>
              </tfoot>
              <tbody>
              <tr>
                <td *ngFor="let member of approvalMember.approval" [innerHtml]="member.user_dept | safe : 'html'">
                </td>
              </tr>
              </tbody>
            </table>
            <div class="cb"></div>
            <table class="approval-table fr table table-bordered mb10">
              <thead>
                <tr>
                  <th *ngFor="let member of approvalMember.agreement">
                    <span>협조</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" [innerHtml]="member.user_dept | safe : 'html'">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>     
          <div class="cb"></div>
          
          <div class="pt20" style="border-top:solid 2px #000">
            <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="contents"></div>
          </div>
          <div class="append-file">
            <label for="file" class="fl">첨부파일 :</label>
            <input
              [value]="form.value.file"
              type="file" name="file" formControlName="file" id="file" size="50" class="fl ml20"
              ng2FileSelect (change)="handleUploadFileChanged($event)"
            />
            <div class="cb"></div>
        
          </div>
          <div class="tl">
            <div class="mt5" style="font-size: 12px;" *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
              <ul class="lstn mb3 pl10 f12">
                <li class="pt3" *ngFor="let file of form.controls.upload.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
                <li class="pt3" *ngFor="let file of form.controls.files.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <hr class="db" />
    
        </div>
      </ng-container>
    
      <ng-container *ngIf="approvalInfo == '1016'">
        <div class="document-area fl">
          <img src="/assets/images/logo.png" alt="로고" height="26" />
          <div>
            <div class="company-ceo">바이크마트</div>
            <div class="f15 mt20">
              대구광역시 동구 신천동 152-3번지 (도로명주소 : 대구광역시 동구 장등로 27) <br />
              전화 : 1544-5022 &nbsp;&nbsp; / &nbsp;&nbsp; 전송 053-289-3354 &nbsp;&nbsp;
              / &nbsp;&nbsp; http://www.bikemart.co.kr &nbsp;&nbsp; / &nbsp;&nbsp;
              <span ng-if="member.data.email">admin@o2oz.net</span>
             </div>
            <div class="mt10" style="border-top:solid 5px #000"></div>
          </div>
          <div class="fl w60p h320p">
            <div class="document-header">
              <div class="company-nm">지출결의서</div>
            </div>
            <div class="subject-area">
              <div class="mt5 lh350" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                <label for="receive" class="f18">참&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조</label>
                <label style="width: 500px;" class="ml20" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                  <span *ngFor="let member of approvalMember.reference">{{member.dept_name}} {{member.name}}&nbsp;&nbsp;&nbsp;</span>
                </label>
              </div>
              <div class="pb25 pt181" [ngClass]="{pt120: approvalMember != '' && approvalMember.reference.length > 0}">
                <label for="receive" class="f18">제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</label>
                <input
                  [value]="form.value.subject"
                  type="text" name="subject" formControlName="subject" id="subject" size="50" class="f18 ml20 input-bottom"
                  placeholder="제목을 입력하세요."
                />
              </div>
            </div>
          </div>
          <div class="fr w40p h320p right" *ngIf="approvalMember.length < 1">
            <table class="approval-table fr table table-bordered mt30 mb5">
            <thead>
            <tr>
              <th scope="col">
                <span>기안자</span>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
        
            <tbody>
            <tr>
              <td [innerHtml]="member.user_dept | safe : 'html'"></td>
            </tr>
            </tbody>
            </table>
          </div> 
          <div class="fr w40p h320p right" *ngIf="approvalMember != '' ">
            <table class="approval-table fr table table-bordered mt30">
              <thead>
                <tr>
                  <th *ngFor="let member1 of approvalMember.approval">
                    <span>{{member1.user_dept == this.member.user_dept ? '기안자' : '결재자'}}</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
              <tr>
                <td *ngFor="let member of approvalMember.approval" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                </td>
              </tr>
              </tfoot>
              <tbody>
              <tr>
                <td *ngFor="let member of approvalMember.approval" [innerHtml]="member.user_dept | safe : 'html'">
                </td>
              </tr>
              </tbody>
            </table>
            <div class="cb"></div>
            <table class="approval-table fr table table-bordered mb10">
              <thead>
                <tr>
                  <th *ngFor="let member of approvalMember.agreement">
                    <span>협조</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" [innerHtml]="member.user_dept | safe : 'html'">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>     
          <div class="cb"></div>  
          
          <div class="pt20" style="border-top:solid 2px #000">
            <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="contents"></div>
          </div>  
        
          <div class="append-file">
            <label for="file" class="fl">첨부파일 :</label>
            <input
              [value]="form.value.file"
              type="file" name="file" formControlName="file" id="file" size="50" class="fl ml20"
              ng2FileSelect (change)="handleUploadFileChanged($event)"
            />
            <div class="cb"></div>
        
          </div>
          <div class="tl">
            <div class="mt5" style="font-size: 12px;" *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
              <ul class="lstn mb3 pl10 f12">
                <li class="pt3" *ngFor="let file of form.controls.upload.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
                <li class="pt3" *ngFor="let file of form.controls.files.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <hr class="db" />
        </div>
      </ng-container>
    
      <ng-container *ngIf="approvalInfo == '1008'">
        <div class="document-area fl">
          <img src="/assets/images/logo.png" alt="로고" height="26" />
          <div>
            <div class="company-ceo">바이크마트</div>
            <div class="f15 mt20">
              대구광역시 동구 신천동 152-3번지 (도로명주소 : 대구광역시 동구 장등로 27) <br />
              전화 : 1544-5022 &nbsp;&nbsp; / &nbsp;&nbsp; 전송 053-289-3354 &nbsp;&nbsp;
              / &nbsp;&nbsp; http://www.bikemart.co.kr &nbsp;&nbsp; / &nbsp;&nbsp;
              <span ng-if="member.data.email">admin@o2oz.net</span>
             </div>
            <div class="mt10" style="border-top:solid 5px #000"></div>
          </div>
          <div class="fl w60p h320p">
            <div class="document-header">
              <div class="company-nm">연차/휴가 신청서</div>
            </div>
            <div class="subject-area">
              <div class="mt5 lh350" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                <label for="receive" class="f18">참&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조</label>
                <label style="width: 500px;" class="ml20" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                  <span *ngFor="let member of approvalMember.reference">{{member.dept_name}} {{member.name}}&nbsp;&nbsp;&nbsp;</span>
                </label>
              </div>
              <div class="pb25 pt181" [ngClass]="{pt120: approvalMember != '' && approvalMember.reference.length > 0}">
                <label for="receive" class="f18">제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</label>
                <input
                  [value]="form.value.subject"
                  type="text" name="subject" formControlName="subject" id="subject" size="50" class="f18 ml20 input-bottom"
                  placeholder="연차/휴가 신청서를 제출합니다."
                />
              </div>
            </div>
          </div>
          <div class="fr w40p h320p right" *ngIf="approvalMember.length < 1">
            <table class="approval-table fr table table-bordered mt30 mb5">
            <thead>
            <tr>
              <th scope="col">
                <span>기안자</span>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
        
            <tbody>
            <tr>
              <td [innerHtml]="member.user_dept | safe : 'html'"></td>
            </tr>
            </tbody>
            </table>
          </div> 
          <div class="fr w40p h320p right" *ngIf="approvalMember != '' ">
            <table class="approval-table fr table table-bordered mt30">
              <thead>
                <tr>
                  <th *ngFor="let member1 of approvalMember.approval">
                    <span>{{member1.user_dept == this.member.user_dept ? '기안자' : '결재자'}}</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
              <tr>
                <td *ngFor="let member of approvalMember.approval" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                </td>
              </tr>
              </tfoot>
              <tbody>
              <tr>
                <td *ngFor="let member of approvalMember.approval" [innerHtml]="member.user_dept | safe : 'html'">
                </td>
              </tr>
              </tbody>
            </table>
            <div class="cb"></div>
            <table class="approval-table fr table table-bordered mb10">
              <thead>
                <tr>
                  <th *ngFor="let member of approvalMember.agreement">
                    <span>협조</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" [innerHtml]="member.user_dept | safe : 'html'">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>     
          <div class="cb"></div>
          
          <div class="pt20" style="border-top:solid 2px #000">
            <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="contents"></div>
          </div>  
        
          <div class="append-file">
            <label for="file" class="fl">첨부파일 :</label>
            <input type="file" id="file" name="file" class="fl ml20" multiple="multiple" onchange="" />
        
            <div class="cb"></div>
        
            <hr class="db" />
        
          </div>
        </div>
      </ng-container>
    
      <ng-container *ngIf="approvalInfo == '1013'">
        <div class="document-area fl">
          <img src="/assets/images/logo.png" alt="로고" height="26" />
          <div>
            <div class="company-ceo">바이크마트</div>
            <div class="f15 mt20">
              대구광역시 동구 신천동 152-3번지 (도로명주소 : 대구광역시 동구 장등로 27) <br />
              전화 : 1544-5022 &nbsp;&nbsp; / &nbsp;&nbsp; 전송 053-289-3354 &nbsp;&nbsp;
              / &nbsp;&nbsp; http://www.bikemart.co.kr &nbsp;&nbsp; / &nbsp;&nbsp;
              <span ng-if="member.data.email">admin@o2oz.net</span>
             </div>
            <div class="mt10" style="border-top:solid 5px #000"></div>
          </div>
          <div class="fl w60p h320p">
            <div class="document-header">
              <div class="company-nm">외부회의록</div>
            </div>
            <div class="subject-area">
              <div class="mt5 lh350" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                <label for="receive" class="f18">참&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조</label>
                <label style="width: 500px;" class="ml20" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                  <span *ngFor="let member of approvalMember.reference">{{member.dept_name}} {{member.name}}&nbsp;&nbsp;&nbsp;</span>
                </label>
              </div>
              <div class="pb25 pt181" [ngClass]="{pt120: approvalMember != '' && approvalMember.reference.length > 0}">
                <label for="receive" class="f18">제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</label>
                <input
                  [value]="form.value.subject"
                  type="text" name="subject" formControlName="subject" id="subject" size="50" class="f18 ml20 input-bottom"
                  placeholder="업무계획을 보고드립니다."
                />
              </div>
            </div>
          </div>
          <div class="fr w40p h320p right" *ngIf="approvalMember.length < 1">
            <table class="approval-table fr table table-bordered mt30 mb5">
            <thead>
            <tr>
              <th scope="col">
                <span>기안자</span>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
        
            <tbody>
            <tr>
              <td [innerHtml]="member.user_dept | safe : 'html'"></td>
            </tr>
            </tbody>
            </table>
          </div> 
          <div class="fr w40p h320p right" *ngIf="approvalMember != '' ">
            <table class="approval-table fr table table-bordered mt30">
              <thead>
                <tr>
                  <th *ngFor="let member1 of approvalMember.approval">
                    <span>{{member1.user_dept == this.member.user_dept ? '기안자' : '결재자'}}</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
              <tr>
                <td *ngFor="let member of approvalMember.approval" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                </td>
              </tr>
              </tfoot>
              <tbody>
              <tr>
                <td *ngFor="let member of approvalMember.approval" [innerHtml]="member.user_dept | safe : 'html'">
                </td>
              </tr>
              </tbody>
            </table>
            <div class="cb"></div>
            <table class="approval-table fr table table-bordered mb10">
              <thead>
                <tr>
                  <th *ngFor="let member of approvalMember.agreement">
                    <span>협조</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" [innerHtml]="member.user_dept | safe : 'html'">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>     
          <div class="cb"></div>
          
          <div class="pt20" style="border-top:solid 2px #000">
            <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="contents"></div>
          </div>  
        
          <div class="append-file">
            <label for="file" class="fl">첨부파일 :</label>
            <input
              [value]="form.value.file"
              type="file" name="file" formControlName="file" id="file" size="50" class="fl ml20"
              ng2FileSelect (change)="handleUploadFileChanged($event)"
            />
            <div class="cb"></div>
        
          </div>
          <div class="tl">
            <div class="mt5" style="font-size: 12px;" *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
              <ul class="lstn mb3 pl10 f12">
                <li class="pt3" *ngFor="let file of form.controls.upload.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
                <li class="pt3" *ngFor="let file of form.controls.files.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <hr class="db" />
        </div>
      </ng-container>
    
      <ng-container *ngIf="approvalInfo == '1014'">
        <div class="document-area fl">
          <img src="/assets/images/logo.png" alt="로고" height="26" />
          <div>
            <div class="company-ceo">바이크마트</div>
            <div class="f15 mt20">
              대구광역시 동구 신천동 152-3번지 (도로명주소 : 대구광역시 동구 장등로 27) <br />
              전화 : 1544-5022 &nbsp;&nbsp; / &nbsp;&nbsp; 전송 053-289-3354 &nbsp;&nbsp;
              / &nbsp;&nbsp; http://www.bikemart.co.kr &nbsp;&nbsp; / &nbsp;&nbsp;
              <span ng-if="member.data.email">admin@o2oz.net</span>
             </div>
            <div class="mt10" style="border-top:solid 5px #000"></div>
          </div>
          <div class="fl w60p h320p">
            <div class="document-header">
              <div class="company-nm">업무성과/계획보고</div>
            </div>
            <div class="subject-area">
              <div class="mt5 lh350" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                <label for="receive" class="f18">참&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조</label>
                <label style="width: 500px;" class="ml20" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                  <span *ngFor="let member of approvalMember.reference">{{member.dept_name}} {{member.name}}&nbsp;&nbsp;&nbsp;</span>
                </label>
              </div>
              <div class="pb25 pt181" [ngClass]="{pt120: approvalMember != '' && approvalMember.reference.length > 0}">
                <label for="receive" class="f18">제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</label>
                <input
                  [value]="form.value.subject"
                  type="text" name="subject" formControlName="subject" id="subject" size="50" class="f18 ml20 input-bottom"
                  placeholder="업무계획을 보고드립니다."
                />
              </div>
            </div>
          </div>
          <div class="fr w40p h320p right" *ngIf="approvalMember.length < 1">
            <table class="approval-table fr table table-bordered mt30 mb5">
            <thead>
            <tr>
              <th scope="col">
                <span>기안자</span>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
        
            <tbody>
            <tr>
              <td [innerHtml]="member.user_dept | safe : 'html'"></td>
            </tr>
            </tbody>
            </table>
          </div> 
          <div class="fr w40p h320p right" *ngIf="approvalMember != '' ">
            <table class="approval-table fr table table-bordered mt30">
              <thead>
                <tr>
                  <th *ngFor="let member1 of approvalMember.approval">
                    <span>{{member1.user_dept == this.member.user_dept ? '기안자' : '결재자'}}</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
              <tr>
                <td *ngFor="let member of approvalMember.approval" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                </td>
              </tr>
              </tfoot>
              <tbody>
              <tr>
                <td *ngFor="let member of approvalMember.approval" [innerHtml]="member.user_dept | safe : 'html'">
                </td>
              </tr>
              </tbody>
            </table>
            <div class="cb"></div>
            <table class="approval-table fr table table-bordered mb10">
              <thead>
                <tr>
                  <th *ngFor="let member of approvalMember.agreement">
                    <span>협조</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" [innerHtml]="member.user_dept | safe : 'html'">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>     
          <div class="cb"></div>
          
          <div class="pt20" style="border-top:solid 2px #000">
            <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="contents"></div>
          </div>  
        
          <div class="append-file">
            <label for="file" class="fl">첨부파일 :</label>
            <input
              [value]="form.value.file"
              type="file" name="file" formControlName="file" id="file" size="50" class="fl ml20"
              ng2FileSelect (change)="handleUploadFileChanged($event)"
            />
            <div class="cb"></div>
        
          </div>
          <div class="tl">
            <div class="mt5" style="font-size: 12px;" *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
              <ul class="lstn mb3 pl10 f12">
                <li class="pt3" *ngFor="let file of form.controls.upload.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
                <li class="pt3" *ngFor="let file of form.controls.files.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <hr class="db" />
        </div>
      </ng-container>
    
      <ng-container *ngIf="approvalInfo == '1015'">
        <div class="document-area fl">
          <img src="/assets/images/logo.png" alt="로고" height="26" />
          <div>
            <div class="company-ceo">바이크마트</div>
            <div class="f15 mt20">
              대구광역시 동구 신천동 152-3번지 (도로명주소 : 대구광역시 동구 장등로 27) <br />
              전화 : 1544-5022 &nbsp;&nbsp; / &nbsp;&nbsp; 전송 053-289-3354 &nbsp;&nbsp;
              / &nbsp;&nbsp; http://www.bikemart.co.kr &nbsp;&nbsp; / &nbsp;&nbsp;
              <span ng-if="member.data.email">admin@o2oz.net</span>
             </div>
            <div class="mt10" style="border-top:solid 5px #000"></div>
          </div>
          <div class="fl w60p h320p">
            <div class="document-header">
              <div class="company-nm">회의실 사용신청서</div>
            </div>
            <div class="subject-area">
              <div class="mt5 lh350" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                <label for="receive" class="f18">참&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조</label>
                <label style="width: 500px;" class="ml20" *ngIf="approvalMember != '' && approvalMember.reference.length > 0">
                  <span *ngFor="let member of approvalMember.reference">{{member.dept_name}} {{member.name}}&nbsp;&nbsp;&nbsp;</span>
                </label>
              </div>
              <div class="pb25 pt181" [ngClass]="{pt120: approvalMember != '' && approvalMember.reference.length > 0}">
                <label for="receive" class="f18">제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</label>
                <input
                  [value]="form.value.subject"
                  type="text" name="subject" formControlName="subject" id="subject" size="50" class="f18 ml20 input-bottom"
                  placeholder="회의실 사용을 신청합니다."
                />
              </div>
            </div>
          </div>
          <div class="fr w40p h320p right" *ngIf="approvalMember.length < 1">
            <table class="approval-table fr table table-bordered mt30 mb5">
            <thead>
            <tr>
              <th scope="col">
                <span>기안자</span>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <td></td>
            </tr>
            </tfoot>
        
            <tbody>
            <tr>
              <td [innerHtml]="member.user_dept | safe : 'html'"></td>
            </tr>
            </tbody>
            </table>
          </div> 
          <div class="fr w40p h320p right" *ngIf="approvalMember != '' ">
            <table class="approval-table fr table table-bordered mt30">
              <thead>
                <tr>
                  <th *ngFor="let member1 of approvalMember.approval">
                    <span>{{member1.user_dept == this.member.user_dept ? '기안자' : '결재자'}}</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
              <tr>
                <td *ngFor="let member of approvalMember.approval" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                </td>
              </tr>
              </tfoot>
              <tbody>
              <tr>
                <td *ngFor="let member of approvalMember.approval" [innerHtml]="member.user_dept | safe : 'html'">
                </td>
              </tr>
              </tbody>
            </table>
            <div class="cb"></div>
            <table class="approval-table fr table table-bordered mb10">
              <thead>
                <tr>
                  <th *ngFor="let member of approvalMember.agreement">
                    <span>협조</span>
                  </th>
                </tr> 
              </thead>
              <tfoot>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" class="center p5 f11" style="height:20px" ng-repeat="item in info.approval">
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td *ngFor="let member of approvalMember.agreement" [innerHtml]="member.user_dept | safe : 'html'">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>     
          <div class="cb"></div>  
          
          <div class="pt20" style="border-top:solid 2px #000">
            <div id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="contents"></div>
          </div>  
        
          <div class="append-file">
            <label for="file" class="fl">첨부파일 :</label>
            <input
              [value]="form.value.file"
              type="file" name="file" formControlName="file" id="file" size="50" class="fl ml20"
              ng2FileSelect (change)="handleUploadFileChanged($event)"
            />
            <div class="cb"></div>
        
          </div>
          <div class="tl">
            <div class="mt5" style="font-size: 12px;" *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0">
              <ul class="lstn mb3 pl10 f12">
                <li class="pt3" *ngFor="let file of form.controls.upload.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
                <li class="pt3" *ngFor="let file of form.controls.files.value; let i = index">
                  {{i+1}} : <a href="{{file.path}}" target="_blank" title="{{file.upload_file}}">{{file.upload_file}} ({{getComma(file.file_size)}} KB) </a>
                  <button type="button" class="btn btn-default btn-grid2" (click)="deleteFile(file, i)">
                    <i class="material-icons micon-close f12">&#xe5cd;</i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <hr class="db" />
        </div>
      </ng-container>
    
      <div class="approval-kind fl" *ngIf="type != '2'">
        <div class="document-select">
          <h4>결재문서 선택</h4>
          <ul>
            <li *ngFor="let approval of approvalList">
              <a class="cp" [ngClass]="{active: approvalInfo === approval.common_code}" (click)="onClickApproval(approval.common_code)">
                <i class="material-icons fl mr10 micon-description">&#xe873;</i>                  
                <span>{{approval.common_name}}</span>
              </a>  
            </li>     
          </ul>
        </div>
        
        <div class="approval-step">
          <h4 class="fl">결재단계 설정</h4>
          <div class="fr add-btn">
            <i class="material-icons cp micon-add_circle" (click)="addLine(false);">&#xe147;</i>
          </div>      
          <div class="cb"></div>
          <div class="approval-step-div" *ngFor="let step of approvalStepList">
            <a class="approval-step-a cp" (click)="addApprovalStep(step.seq)">
              <p class="title">{{step.subject}}</p>
              <p class="item">결재 : {{step.approval}}</p>
              <p class="item">협조 : {{step.agreement}}</p> 
              <p class="item">참조 : {{step.reference}}</p>
            </a>
          </div>
        </div>   
        
        <!-- <div class="approval-step">
          <h4 class="fl">결재단계 설정</h4>
          <div class="fr add-btn">
            <i class="material-icons cp micon-add_circle">&#xe147;</i>
          </div>      
          <div class="cb"></div>
          
          <div class="approval-step-div">
            <a class="approval-step-a">
              <p class="title">출장명령서</p>
              <p class="item">결재 : 사원 관리자→부장 홍길동→대표이사 최이사</p>
              <p class="item">협조 : 부장 박길동</p> 
              <p class="item">참조 : </p>
            </a>
            <a class="approval-step-a active">
              <p class="title">기안서</p>
              <p class="item">결재 : 사원 관리자→부장 홍길동→대표이사 최이사</p>
              <p class="item">협조 :</p> 
              <p class="item">참조 :  부장 박길동</p>
            </a>
            <a class="approval-step-a">
              <p class="title">연차/휴가 신청서</p>
              <p class="item">결재 : 사원 관리자→부장 홍길동→대표이사 최이사</p>
              <p class="item">협조 : 사원 박길동</p> 
              <p class="item">참조 : </p>
            </a>
          </div>
    
        </div>     -->
        
      </div>
    
    </section>
  </form>
</div>