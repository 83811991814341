<section id="email">
  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" (click)="searchInit()" class="btn btn-default btn-small-icon">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" (click)="getEmailFormList()" class="btn btn-default btn-small-icon ml5">
        <i class="material-icons-outlined">search</i>
      </button>
      <input type="text" [(ngModel)]="search.searchText" size="20" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="제목 or 내용 검색" />
      <select class="form-control form-control-small ml5" [(ngModel)]="search.auto_seq" (change)="getEmailFormList()">
        <option *ngFor="let item of autoList" [ngValue]="item.value">{{item.title}}</option>
      </select>
    </div>
    <div class="fr w30p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="7c6f8ccec9794948928d80385fb3ad76" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="addEmailForm()">등록하기</button>
    </div>
    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 35px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="emailList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [rowSelection]="rowSelection"

      (rowClicked)="onRowClicked($event)"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
    >
    </ag-grid-angular>
  </div>
</section>
