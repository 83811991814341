import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

import { UtilService } from '@app/service/util.service';
import { EmailService } from '@app/service/email.service';
import { AuthService } from '@app/service/auth.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-email',
  templateUrl: './modal-email.component.html',
  styleUrls: ['./modal-email.component.scss']
})
export class ModalEmailComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 발송 이메일 리스트
  public sendList: any;
  public commonCode: any;
  public mode: any;

  public form: FormGroup;
  public formErrors = {
    message: ''
  };

  public isSend: boolean = false;
  public linkHTML: any = '';
  public formHTML: any = [];

  // 그리드 관련 선언
  selectedRows = 0;
  memberList: any = [];

  public autoList: any = [];

  gridApiModalEmail: any;
  gridColumnApiModalEmail: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  public summernoteConfig = {
    placeholder: '',
    tabsize: 2,
    height: 370,
    uploadImagePath: '',
    toolbar: [
        // [groupName, [list of button]]
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'link', 'video', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  public MAXIMUM = 10; // 메일 동시 발송 최대치

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      sendCount: [10, [Validators.required] ],
      subject: [ '', [Validators.required, Validators.maxLength(100)] ],
      contents: [ '', [Validators.required] ],
      from: [ '바이크마트', [Validators.required] ],
      members: [ [], [] ],
      commonCode: [ '', [] ],
      autoSeq: [ '', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private emailService: EmailService,
    private domSanitizer: DomSanitizer,
    public authService: AuthService,
  ) {
    this.buildForm();

    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'mem_no', cellClass: 'cp right', width: 40, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      // {headerName: "Num", valueGetter: "node.rowIndex + 1", width: 55 },
      {headerName: '회원명', field: 'name', cellClass: 'cp', width: 75, flex: 1 },
      {headerName: '이메일', field: 'email', cellClass: 'cp', width: 100, flex: 1 },
      {headerName: '아이디', field: 'id', cellClass: 'cp', width: 80, flex: 1 },
      // {headerName: '회사명', field: 'comName', cellClass: 'cp', width: 80 },
      // {headerName: '구분', field: 'memberType', cellClass: 'cp', width: 80 },
      // {headerName: '만료일', field: 'eDay', hide: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 이메일 자동발송 폼 가져오기
    this.getAutoEmail();

    if( this.mode == 'pay' || this.mode == 'dormant' ) {
      this.form.patchValue({
        sendCount: 10
      });
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApiModalEmail = params.api;
    this.gridColumnApiModalEmail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApiModalEmail.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    /*
    this.formSMS.patchValue({
      title: $event.data.title,
      message: $event.data.contents
    });
    */
  }

  /*******************************************************************************
    설  명 : 자동 발송 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getAutoEmail() {
    let params: any = {
      searchText: '',
      auto_seq: ''
    }

    await this.emailService.getEmailFormList( params ).then( response => {
      if( response.ResultCode ) {
        for( let item of response.data ) {
          if( typeof this.commonCode != 'undefined' ) {
            if( this.commonCode != item.commonCode ) continue;
          }

          this.autoList.push({
            title: item.title,
            value: item.commonCode,
            contents: item.contents,
            contents_origin: item.contents_origin,
            autoSeq: item.auto_seq
          });
        }
      }
    });

    if( typeof this.commonCode != 'undefined' ) {
      this.form.patchValue({
        commonCode: this.commonCode
      });

      this.changeForm();
    }
  }

  /*******************************************************************************
    설  명 : 선택된 발송 회원 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteMember(auto = false) {
    const nodes: any = this.gridApiModalEmail.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.error('발송 삭제할 회원을 선택하세요.');

      return false;
    }

    if (!auto && ! confirm('선택하신 회원을 삭제하시겠습니까?') ) {
      return false;
    }

    for (let node of nodes ) {
      this.gridApiModalEmail.updateRowData({remove: [node]});

      const idx = this.sendList.indexOf(node);
      if (idx > -1) {
        this.sendList.splice(idx, 1);
      }
    }
  }

  /*******************************************************************************
    설  명 : 자동 발송 폼 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeForm() {
    for( let item of this.autoList ) {
      if( item.value == this.form.controls.commonCode.value ) {
        this.form.patchValue({
          subject: item.title,
          contents: item.contents_origin
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 전체 이메일 발송하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  sendEmail(auto = false) {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    let nodes = this.gridApiModalEmail.getSelectedRows();

    if ( ! this.form.valid ) {
      this.toastrService.error('필수 항목을 체크하시기 바랍니다.', '이메일 발송');
      return false;
    }
    // if( nodes.length < 1 ) {
    //   if( confirm( '전체 회원을 선택하시겠습니까?' ) ) {
    //     this.gridApiModalEmail.selectAll();

    //     nodes = this.gridApiModalEmail.getSelectedRows();
    //   }
    // }

    else if( nodes.length < 1 ) {
      this.toastrService.error( '발송할 회원을 선택하세요.' );

      return false;

    } else if(nodes.length > this.MAXIMUM) {
      this.toastrService.warning(`${this.MAXIMUM} 건까지만 순차적으로 발송합니다.`);
      this.gridApiModalEmail.forEachNode((e,i) => {
        e.setSelected(i < this.MAXIMUM);
      });
      nodes = this.gridApiModalEmail.getSelectedRows();
    }

    // let index = 0;
    // let tmpData: any = nodes.slice();
    // let emailList: any = '';

    // for(let item of tmpData) {
    //   if( index >= this.form.controls.sendCount.value ) break;

    //   if( item.email != '' && item.email != null && this.isEmail(item.email) ) {
    //     if( emailList != '' ) emailList += ',';
    //     emailList += item.email;
    //     index++;
    //   }
    //   this.gridApiModalEmail.updateRowData({remove: [item]});
    // }

    // 발송 폼 표시
    // this.isSend = true;

    // let contents = encodeURIComponent( this.form.controls.contents.value );

    // this.formHTML.push(this.domSanitizer.bypassSecurityTrustHtml(`
    //   <form name="sendEmail" id="sendEmail" accept-charset="utf-8" method="post" action="http://www.ref.or.kr/api/email/sendEmail.php">
    //     <input type="hidden" name="from_email" value="${this.form.controls.from.value}">
    //     <input type="hidden" name="subject" value="${this.form.controls.subject.value}">
    //     <input type="hidden" name="contents" value="${contents}">
    //     <input type="hidden" name="to_email" value="${emailList}">
    //   </form>
    //   <script>
    //     document.getElementById("sendEmail").submit();
    //   </script>
    // `));

    this.authService.getLoginInfo.subscribe(e => {

      const params = {
        subject : this.form.controls.subject.value,
        contents : encodeURIComponent( this.form.controls.contents.value ),
        from : this.form.controls.from.value,
        to : nodes.filter(e => this.isEmail(e['email'])).map(e => e['email']).join(','),
        mem_no : e['mem_no']
      };

      this.emailService.setSendEmail(params).then( response => {
        if ( response.ResultCode ) {
          this.deleteMember(true);
          this.toastrService.success( response.ResultMessage );
          if(auto) {
            this.gridApiModalEmail.selectAll();
            this.sendEmail(this.gridApiModalEmail.getSelectedRows().length);
          }
        } else {
          this.toastrService.error( response.ResultMessage );
        }
      });

    }).unsubscribe();

  }

  /*******************************************************************************
    설  명 : 대량 자동 발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  autoSend() {
    if(!confirm('Warning! 회원 전체에게 메일을 보냅니다.')) return;
    this.gridApiModalEmail.selectAll();
    this.sendEmail(true);
  }

  /*******************************************************************************
    설  명 : 개별 이메일 발송하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  sendEmailOnce() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( ! this.form.valid ) {
      this.toastrService.error('필수 항목을 체크하시기 바랍니다.', '이메일 발송');
      return false;
    }

    let nodes = this.gridApiModalEmail.getSelectedRows();

    if( nodes.length < 1 ) {
      if( confirm( '전체 회원을 선택하시겠습니까?' ) ) {
        this.gridApiModalEmail.selectAll();

        nodes = this.gridApiModalEmail.getSelectedRows();
      }
    }

    if( nodes.length < 1 ) {
      this.toastrService.error( '발송할 회원을 선택하세요.' );

      return false;

    } else {

      let index = 0;
      let tmpData: any = nodes.slice();
      let memberEmail: any = '';
      let replaceContents: any;

      let memberName: any;      // #회원명
      let memberId: any;        // #회원아이디
      let effectiveEdate: any;  // #유료회원기간
      let effectiveDays: any;   // #잔여일

      for(let item of tmpData) {
        if( index >= this.form.controls.sendCount.value ) break;

        memberName = item.name;
        memberId = item.id;
        // effectiveEdate = item.eSdate;
        // effectiveDays = ( item.eDay === null ) ? 0 : item.eDay;
        effectiveEdate = '';
        effectiveDays = '';

        // if( item.email != '' && item.email != null && this.isEmail(item.email) ) {
        //   memberEmail = 'ydg807@o2oz.net';
        // }

        // let i = this.sendList.findIndex( x => x.seq == item.seq );
        // this.sendList.splice( i, 1 );

        this.gridApiModalEmail.updateRowData({remove: [item]});

        // 발송 폼 표시
        this.isSend = true;

        replaceContents = this.form.controls.contents.value;

        if( replaceContents.indexOf('#회원명') !== -1 ) replaceContents = replaceContents.replace(/#회원명/gi, memberName);
        if( replaceContents.indexOf('#아이디') !== -1 ) replaceContents = replaceContents.replace(/#아이디/gi, memberId);
        if( replaceContents.indexOf('#유료회원기간') !== -1 ) replaceContents = replaceContents.replace(/#유료회원기간/gi, effectiveEdate);
        if( replaceContents.indexOf('#잔여일') !== -1 ) replaceContents = replaceContents.replace(/#잔여일/gi, effectiveDays + ' 일');
        if( replaceContents.indexOf('#시행일') !== -1 ) replaceContents = replaceContents.replace(/#시행일/gi, '2020년 9월 1일');

        let contents = encodeURIComponent( replaceContents );

        this.formHTML.push(this.domSanitizer.bypassSecurityTrustHtml(`
          <form name="sendEmail" id="sendEmail" accept-charset="utf-8" method="post" action="http://www.ref.or.kr/api/email/sendEmail.php">
            <input type="hidden" name="from_email" value="${this.form.controls.from.value}">
            <input type="hidden" name="subject" value="${this.form.controls.subject.value}">
            <input type="hidden" name="contents" value="${contents}">
            <input type="hidden" name="to_email" value="${memberEmail}">
          </form>
          <script>
            document.getElementById("sendEmail").submit();
          </script>
        `));

        index++;
      }
    }
  }

  /*******************************************************************************
    설  명 : 이메일 정규식 검사
    입력값 : 이메일
    리턴값 : true / false
  *******************************************************************************/
  isEmail(asValue) {
    var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
  }
}
