<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">품절<span class="cnt">{{statistic?.total | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <!-- <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체<span class="cnt">{{statistic?.total | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">판매중<span class="cnt">{{statistic?.sales | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">대기중<span class="cnt">{{statistic?.standby | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">품절<span class="cnt">{{statistic?.soldout | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">색상 미등록<span class="cnt">{{statistic?.notcolor | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">크기 미등록<span class="cnt">{{statistic?.notsize | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div> -->

  <div class="cb"></div>
</section>

<div>
  <div class="fl form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit();">
      <i class="material-icons-outlined">refresh</i>
    </button>

    <select name="searchField" id="searchField" [(ngModel)]="params.searchField" class="form-control form-control-small ml5">
      <option value="soldoutName">품절처리자</option>
      <option value="product_seq">통합상품번호</option>
      <option value="seq">자식상품번호</option>
      <option value="product_name">상품명</option>
      <option value="brand_name">브랜드</option>
      <option value="category_name">카테고리</option>
      <option value="customer_name">매입업체명</option>
      <option value="provider_name">공급업체명</option>
      <option value="search_keyword">검색키</option>
      <option value="comment">설명</option>
      <option value="name">등록자</option>
      <option value="barcode">바코드</option>
    </select>

    <input type="text" [(ngModel)]="params.searchText" size="50" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="검색어를 입력하세요" />

    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5 f12" (click)="getProductList()">검색</button>

    <input type="text" size="13" readonly="readonly" id="sdate" [(ngModel)]="params.soldoutSdate" (dateSelect)="getProductList()" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
    <ng-template #footerTemplate1>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
    </ng-template>
    <span class="pr5 pl5">~</span>
    <input type="text" size="13" readonly="readonly" id="edate" [(ngModel)]="params.soldoutEdate" (dateSelect)="getProductList()" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
    <ng-template #footerTemplate2>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
    </ng-template>
    <div class="btn-group ml5">
      <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active: quickDate == item}" (click)="setQuickDate(item)">{{item > 0 ? item + '일' : (item ? '전체' : '오늘')}}</button>
    </div>

    <!-- <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == ''}" (click)="searchParams('status', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '0'}" (click)="searchParams('status', '0')">판매중</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '1'}" (click)="searchParams('status', '1')">임시품절</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status == '2'}" (click)="searchParams('status', '2')">영구품절</button>
    </div> -->

  </div>
  <div class="fr w30p right">
    <!-- <button type="button" class="btn btn-success btn-small ml5" (click)="goLink('/product/margin')">상품마진 관리</button>
    <button type="button" class="btn btn-success btn-small ml5" (click)="goLink('/product/property')">상품색상 및 사이즈 관리</button>
    <button type="button" class="btn btn-success btn-small ml5" (click)="salesStatus()">판매현황</button>
    <ng-container *ngIf="authService.currAuth['auth_modify']=='1'">
      <button type="button" class="btn btn-success btn-small ml5" (click)="soldOutProduct()">품절체크</button>
    </ng-container> -->
  </div>
  <div class="cb"></div>

  <div class="fl form-inline mt5">
    <select name="pageRow" [(ngModel)]="params.pageRow" class="form-control form-control-small" (change)="getProductList()">
      <option value="10">10줄</option>
      <option value="20">20줄</option>
      <option value="50">50줄</option>
      <option value="100">100줄</option>
      <option value="200">200줄</option>
      <option value="500">500줄</option>
    </select>

    <select [(ngModel)]="params.category_code" class="form-control form-control-small ml5" (change)="getProductList()">
      <option value="">카테고리 선택</option>
      <option *ngFor="let item of categoryList" [value]="item.category_code">{{item.category_nm}}</option>
    </select>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_card_installment == ''}" (click)="searchParams('is_card_installment', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_card_installment == '0'}" (click)="searchParams('is_card_installment', '0')">없음</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_card_installment == '1'}" (click)="searchParams('is_card_installment', '1')">무이자할부</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.mileage == ''}" (click)="searchParams('mileage', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.mileage == '0'}" (click)="searchParams('mileage', '0')">없음</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.mileage == '1'}" (click)="searchParams('mileage', '1')">마일리지</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.safe_stock == ''}" (click)="searchParams('safe_stock', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.safe_stock == '1'}" (click)="searchParams('safe_stock', '1')">안전재고위험</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == ''}" (click)="searchParams('is_display', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == '1'}" (click)="searchParams('is_display', '1')">표시</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.is_display == '0'}" (click)="searchParams('is_display', '0')">미표시</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == ''}" (click)="searchParams('approval_yn', '')">전체</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == '1'}" (click)="searchParams('approval_yn', '1')">승인</button>
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.approval_yn == '0'}" (click)="searchParams('approval_yn', '0')">미승인</button>
    </div>

    <!-- <button type="button" class="btn btn-info btn-small ml5" (click)="setProductApproveSave()">승인처리</button>
    <button type="button" class="btn btn-secondary btn-small ml5" (click)="setProductUnApproveSave()">미승인처리</button> -->
  </div>
  <div class="fr right mt5">
    <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi" gridId="59f6a2a1aeb14ab4aee2c46ed0144d5a" [btnGroup]="1"></btn-ag-grid-save>

    <ng-container *ngIf="authService.currAuth['auth_excel']=='1'">
      <ag-grid-excel [gridApi]="gridApi" selected="true" filename="상품리스트" title="상품리스트"></ag-grid-excel>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="상품리스트" title="상품리스트"></ag-grid-excel>
    </ng-container>

    <!-- <ng-container *ngIf="authService.currAuth['auth_delete']=='1'">
      <button type="button" class="btn btn-danger btn-small ml5" (click)="deleteProductList()">상품삭제</button>
    </ng-container>

    <ng-container *ngIf="authService.currAuth['auth_write']=='1'">
      <button type="button" class="btn btn-primary btn-small ml5" (click)="productAdd(0)">상품등록</button>
    </ng-container> -->
  </div>
  <div class="cb"></div>
</div>

<div class="mt5" style="height:calc(100% - 170px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="productList"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [getRowHeight]="getRowHeight"
    [pagination]="false"
    [rowMultiSelectWithClick]="true"

    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)"
    >
  </ag-grid-angular>
  <section class="pagination mt10" *ngIf="params.totalCount">
    <ngb-pagination
      [(page)]="params.pageNo"
      [pageSize]="params.pageRow"
      [collectionSize]="params.totalCount"
      [maxSize]="20"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>
</div>