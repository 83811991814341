/*******************************************************************************
  설  명 : 기초관리 > 기본정보설정 > 기본정보
  작성일 : 2020-10-29
  작성자 : 송영석
  접속URL : /basic/company/info
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { SiteConfigService } from '@app/service/site.config.service';

import { ToastrService } from 'ngx-toastr';

import * as $ from 'jquery';

const URL = config.apiImageUploadUrl;
const CONFIG_KEY = 'company';

@Component({
  selector: 'app-basic-company',
  templateUrl: './basic-company.component.html',
  styleUrls: ['./basic-company.component.scss']
})
export class BasicCompanyComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};
  
  public baseURL = config.baseUrl;

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12', 'mr3']
  };  

  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });
  public uploadProgress: any = 0;

  isLoading:boolean = false;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      company_name: ['', [Validators.maxLength(50)] ],
      company_ename: ['', [Validators.maxLength(50)] ],
      domain: ['', [Validators.maxLength(50)] ],
      homepage: ['', [Validators.maxLength(50)] ],
      admin_page: ['', [Validators.maxLength(50)] ],
      scm_page: ['', [Validators.maxLength(50)] ],
      shop_name: ['', [Validators.maxLength(50)] ],
      ceo_name: ['', [Validators.maxLength(50)] ],
      trans_bizno: ['', [Validators.maxLength(20)] ],
      biz_no: ['', [Validators.maxLength(15)] ],
      biz_type: ['', [Validators.maxLength(50)] ],
      biz_category: ['', [Validators.maxLength(50)] ],
      zipcode: ['', [Validators.maxLength(5)] ],
      address: ['', [Validators.maxLength(255)] ],
      address_detail: ['', [Validators.maxLength(255)] ],
      tel: ['', [Validators.maxLength(20)] ],
      fax: ['', [Validators.maxLength(20)] ],
      email: ['', [Validators.maxLength(50)] ],
      cs_tel1: ['', [Validators.maxLength(20)] ],
      cs_tel2: ['', [Validators.maxLength(20)] ],
      contant_manager: ['', [Validators.maxLength(20)] ],
      privacy_manager: ['', [Validators.maxLength(20)] ],
      privacy_manager_tel: ['', [Validators.maxLength(20)] ],
      privacy_manager_email: ['', [Validators.maxLength(50)] ],
      privacy_charge: ['', [Validators.maxLength(20)] ],
      privacy_charge_tel: ['', [Validators.maxLength(20)] ],
      privacy_charge_email: ['', [Validators.maxLength(50)] ],
      stamp: ['', [Validators.maxLength(255)] ],
      stamp_url: ['', [Validators.maxLength(255)] ],
      stamp_origin: ['', [Validators.maxLength(255)] ],
      estimate: ['', [] ],
      spectifications: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private siteConfigService: SiteConfigService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 업로드 허용 파일 설정
    const filetype: string[] = ['image'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 5 * (1024 * 1024), // 최대 업로드 허용 5MB 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if ( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if ( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if ( response.result ) {
        this.toastrService.success( response.message, '');

        this.form.patchValue( {
          stamp_url: this.baseURL + response.url,
          stamp_origin: response.origin_filename
        });

      } else {
        this.toastrService.error( response.message, '');
      }
    };

    // 데이터 가져오기
    this.getConfig();
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for (const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;
    this.uploader.addToQueue(filteredFiles, options, filters);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 이미지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteImage() {
    this.form.patchValue({
      stamp: '',
      stamp_url: '/assets/images/no_image.png',
      stamp_origin: ''
    });
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.isLoading = false;

    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          company_name:           response.data.company_name || '',
          company_ename:          response.data.company_ename || '',
          domain:                 response.data.domain || '',
          homepage:               response.data.homepage || '',
          admin_page:             response.data.admin_page || '',
          scm_page:               response.data.scm_page || '',
          shop_name:              response.data.shop_name || '',
          ceo_name:               response.data.ceo_name || '',
          trans_bizno:            response.data.trans_bizno || '',
          biz_no:                 response.data.biz_no || '',
          biz_type:               response.data.biz_type || '',
          biz_category:           response.data.biz_category || '',
          zipcode:                response.data.zipcode || '',
          address:                response.data.address || '',
          address_detail:         response.data.address_detail || '',
          tel:                    response.data.tel || '',
          fax:                    response.data.fax || '',
          email:                  response.data.email || '',
          cs_tel1:                response.data.cs_tel1 || '',
          cs_tel2:                response.data.cs_tel2 || '',
          contant_manager:        response.data.contant_manager || '',
          privacy_manager:        response.data.privacy_manager || '',
          privacy_manager_tel:    response.data.privacy_manager_tel || '',
          privacy_manager_email:  response.data.privacy_manager_email || '',
          privacy_charge:         response.data.privacy_charge || '',
          privacy_charge_tel:     response.data.privacy_charge_tel || '',
          privacy_charge_email:   response.data.privacy_charge_email || '',
          stamp_url:              response.data.stamp_url || '',
          stamp_origin:           response.data.stamp_origin || '',
          estimate:               response.data.estimate || '',
          spectifications:        response.data.spectifications || '',
        });

        this.isLoading = true;
      }
    });
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr
    });

    $("#address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.siteConfigService.setConfig(CONFIG_KEY, this.form).then( response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, '기본정보 저장');
        else this.toastrService.error( response.ResultMessage, '기본정보 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '기본정보 저장');
    }
  }

}
