import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '@app/service/common.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass:'modal-600'
};

@Component({
  selector: 'app-approval-note',
  templateUrl: './approval-note.component.html',
  styleUrls: ['./approval-note.component.scss']
})
export class ApprovalNoteComponent implements OnInit {
  public form: FormGroup;
  public formErrors = {};

  public subject: any ='';

  public title: any = '';
 
  public commonList: any = [];
  public documentList: any = [{
    title: '문서구분 선택',
    value: ''
  }];

  public selectedCommon: any;

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      subject: ['', [] ],
      add_note: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private commonCodeService: CommonService,
    private toastrService: ToastrService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    if( this.subject != '' ) {
      this.form.patchValue({
        subject: this.subject
      })
    }

    this.getCommonList();
  }

   /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.commonCodeService.getCommonListCode('RWG').then( response => {
      if( response.ResultCode ) {
        this.commonList.push({
          title: '문서그룹 선택',
          value: ''
        });

        response.data.forEach( row => {
          this.commonList.push({
            title: row.common_name,
            value: row.common_code,
          });
        });

      } else {
        this.commonList = [];
      }
    })

  }


  onChange($event) {
    this.form.patchValue({
      reportTypeD: $event.target.value
    })
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.activeModal.close(this.form.value)
  }
}
