import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'app-workarragement-print',
  templateUrl: './workarragement-print.component.html',
  styleUrls: ['./workarragement-print.component.scss']
})
export class WorkarragementPrintComponent implements OnInit {

  calendarList: any = [];

  calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  }

  constructor(
  ) { }


  ngOnInit(): void {
    this.initCalendar();
  }

  /*******************************************************************************
    설  명 : 캘린더 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCalendar() {

  }

  /*******************************************************************************
    설  명 : 달력 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initCalendar() {
    let sweek: any = moment(this.calendarInfo.sdate).isoWeekday();
    this.calendarInfo.start_day = moment(this.calendarInfo.sdate).add(sweek * -1, 'day').format('YYYY-MM-DD');

    let eweek: any = moment(this.calendarInfo.edate).isoWeekday();
    this.calendarInfo.end_day = moment(this.calendarInfo.edate).add(6 - eweek, 'day').format('YYYY-MM-DD');
  }

  /*******************************************************************************
    설  명 : 달력 루프
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  range( i : number ) {
    return Array(i);
  }

  /*******************************************************************************
    설  명 : 달력 루프의 현재일 계산
    입력값 : i = 행, j = 열
    리턴값 : 현재 일(월-일)
  *******************************************************************************/
  getCurrDay(i, j) {
    let term = ( i * 7 ) + j;
    let date: any = moment(this.calendarInfo.start_day).add(term, 'day').format('DD');
    if( date == '01' )
      date = moment(this.calendarInfo.start_day).add(term, 'day').format('MM-DD');

    return date;
  }

  /*******************************************************************************
    설  명 : Moment
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMoment( type: any ) {
    return moment().format( type );
  }

}
