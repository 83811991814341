<div class="modal-header">
  <h4 class="modal-title fb mb0">사방넷 배송목록 업로드</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">


    <table class="table table-bordered table-small mb0">
    <caption class="none">업로드 정보</caption>
    <colgroup>
      <col style="width:20%;" />
      <col style="width:80%;" />
    </colgroup>

    <tbody>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="2">
          <!-- <h4>※ 등록전 다음 사항을 확인해 주세요</h4>
          <ol>
            <li>오픈마켓에서 받은 엑셀 파일을 그대로 올릴 것.
            <br/>(첫행 첫필드 0 삽입 금지, .txt 변환 금지, 파일명 / 시트명 무관)</li>
            <li>만일, 데이터 외에 안내문구 같은 것이 있다면 지울 것.</li>
            <li>지마켓/옥션 은(는) 3번째 필드 주문번호, 4번째 필드 상품번호</li>
            <li>네이버 은(는) 3번째 필드 주문번호, 2번째 필드 상품주문번호</li>
            <li>스토어팜 은(는) 2번째 필드 주문번호, 1번째 필드 상품주문번호</li>
            <li>11번가 은(는) 4번째 필드 주문번호, 31번째 필드 주문순번</li>
            <li>쿠팡 은(는) 3번째 필드 주문번호, 2번째 필드 묶음배송번호</li>
            <li>톡스토어 은(는) 지원안함.</li>
          </ol> -->
        </td>
      </tr>
    </tfoot>
    </table>


</div>

<div class="modal-footer">
  <div class="fl w50p">
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    <!-- <button type="button" class="btn btn-primary btn-small ml5" (click)="setOpenmarketConsignSave()">저장하기</button> -->
    <button type="button" class="btn btn-primary btn-small ml5" (click)="setOpenmarketConsignSave()">다운로드</button>
  </div>
</div>
