import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { FormBuilder, FormGroup } from '@angular/forms';

import * as moment from 'moment';
import * as numeral from 'numeral';

import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';

@Component( {
  selector: 'app-member-sis-statistic-tab1',
  templateUrl: './member-sis-statistic-tab1.component.html',
  styleUrls: ['./member-sis-statistic-tab1.component.scss']
} )
export class MemberSisStatisticTab1Component implements OnInit {
  @ViewChild('canvasA', { static: true } ) canvasA: any;
  @ViewChild('canvasB', { static: true } ) canvasB: any;

  private chartColors = [
    '#fc5c65', '#45aaf2', '#fd9644', '#4b7bec', '#fed330', '#a55eea', '#26de81', '#d1d8e0', '#2bcbba', '#778ca3',
    '#eb3b5a', '#2d98da', '#fa8231', '#3867d6', '#f7b731', '#8854d0', '#20bf6b', '#a5b1c2', '#0fb9b1', '#4b6584'
  ];

  public form: FormGroup = this.formBuilder.group({
    year:[moment().format('YYYY'), [] ],
  });
  public formErrors = {};

  public yearData = [
    {title: '2022', value: 2022},
    {title: '2021', value: 2021},
    {title: '2020', value: 2020}
  ];

  private chartA: any;
  private chartB: any;

  public retrieveOptions: any = {
    startDate: null,
    endDate: null,
  };

  public gridAOptions: any;
  public gridBOptions: any;

  constructor(
    private restfulService: RestfulService,
    private formBuilder: FormBuilder,
    private utilService: UtilService
  ) {
    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });

    this.yearData = [];
    for(let i = parseInt(moment().format('YYYY')); i >= 2004; i-- ) {
      this.yearData.push({
        title: i.toString(),
        value: i
      });
    }
  }

  ngOnInit(): void {
    function currencyFormatter(parameters: any) {
      return parameters.value ? numeral(parameters.value).format('0,0') : null;
    }

    function percentFormatter(parameters: any) {
      return parameters.value ? numeral(parameters.value).format('0,0.00%') : null;
    }

    //this.retrieveOptions.startDate = moment().subtract(11, 'months').startOf('month').toDate();
    //this.retrieveOptions.endDate = moment().endOf('month').toDate();
    this.changeYear({target: {value: moment().format('YYYY')}});

    this.chartA = new Chart(this.canvasA.nativeElement, {
      type: 'line',
      options: {
        plugins: {
          datalabels: { align: 'end', anchor: 'end', formatter: (value: number) => numeral(value).format('0,0') }
        },
        responsive: true,
        scales: {
          yAxes: [ {
            ticks: { callback: (value: number) => numeral(value).format('0,0') }
          } ],
        },
      }
    } );

    this.gridAOptions = {
      columnDefs: [
        { field: 'type_description', headerName: '항목', width: 120 },
        ...( () => {
          const result = [];
          const date = moment(this.retrieveOptions.startDate);

          while (date.isBefore(this.retrieveOptions.endDate) ) {
            result.push( {
              field: 'total_' + moment(date).format('MM-DD'),
              headerName: moment(date).format('MM') + '월',
              type: 'numericColumn',
              valueFormatter: (parameters: any) => parameters.data.type === 'D' ? percentFormatter(parameters) : currencyFormatter(parameters),
              width: 120,
            } );

            date.add(1, 'months');
          }

          return result;
        } )(),
      ],
      defaultColDef: { filter: false, resizable: true, sortable: true },
      overlayNoRowsTemplate: '데이터가 없습니다.',
      rowData: [],
      rowSelection: 'single',
      suppressCellSelection: true,
    };

    this.chartB = new Chart(this.canvasB.nativeElement, {
      type: 'pie',
      options: {
        plugins: {
          datalabels: { formatter: (value: number) => numeral(value).format('0,0') },
        },
        responsive: true,
      }
    } );

    this.gridBOptions = {
      columnDefs: [
        { field: 'rank', headerName: '순위', type: 'numericColumn', width: 80 },
        { field: 'category_name', headerName: '상품구분', width: 200 },
        { field: 'sales_total', headerName: '판매금액', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'cost_total', headerName: '구입원가', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'margin_total', headerName: '판매마진', type: 'numericColumn', width: 120, valueFormatter: currencyFormatter },
        { field: 'percent_margin', headerName: '마진율', type: 'numericColumn', width: 120, valueFormatter: percentFormatter },
        { field: 'rank_', headerName: '전월대비 순위', width: 120 },
      ],
      defaultColDef: { filter: false, resizable: true, sortable: true },
      overlayNoRowsTemplate: '데이터가 없습니다.',
      rowData: [],
      rowSelection: 'single',
      suppressCellSelection: true,
    };

    this.retrieve();
  }

  changeYear($event) {
    this.retrieveOptions.startDate = moment($event.target.value + '-01-01 00:00:00').toDate();
    this.retrieveOptions.endDate = moment($event.target.value + '-12-31 23:59:59').toDate();

    this.retrieve();
  }

  retrieve() {
    //// 협력사 매출
    this.restfulService.get( {
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getPartnerSalesTotal'
    }, {
      shop: '0',
      grade: '0004',
      type: 'A',
      order_date_a: moment(this.retrieveOptions.startDate).format('YYYY-MM-DD'),
      order_date_b: moment(this.retrieveOptions.endDate).format('YYYY-MM-DD'),
    } ).then(response => {
      if (response.result) {
        //// chartA
        this.chartA.data = response.result.reduce( (result: any, row: any, index: number) => {
          result.labels.push(moment(row.order_date).format('YYYY-MM') );
          result.datasets[0].data.push(Number(row.sales_total) / 1000);
          result.datasets[1].data.push(Number(row.cost_total) / 1000);

          return result;
        }, {
          labels: [],
          datasets: [
            { borderColor: this.chartColors[0], data: [], fill: false, label: '매출' },
            { borderColor: this.chartColors[1], data: [], fill: false, label: '매출마진' }
          ]
        } );

        this.chartA.update();

        const data = response.result.reduce(
          (result: any, row: any) => {
            const key = `total_${moment(row.order_date).format('MM-DD')}`;

            result[0][key] = Number(row.sales_total) / 1000;
            result[1][key] = Number(row.cost_total) / 1000;
            result[2][key] = (Number(row.sales_total) - Number(row.cost_total) ) / 1000;
            result[3][key] = 1 - Number(row.cost_total) / Number(row.sales_total);

            return result;
          },
          [
            { type: 'A', type_description: '매출' },
            { type: 'B', type_description: '구입원가' },
            { type: 'C', type_description: '판매마진' },
            { type: 'D', type_description: '마진율' },
          ]
        );

        this.gridAOptions.api.setRowData(data);
      }
    } );

    //// 카테코리별 협력사 매출
    this.restfulService.get( {
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getPartnerSalesTotalPerCategory'
    }, {
      shop: '0',
      grade: '0004',
      type: 'A',
      order_date_a: moment(this.retrieveOptions.startDate).format('YYYY-MM-DD'),
      order_date_b: moment(this.retrieveOptions.endDate).format('YYYY-MM-DD'),
    } ).then(response => {
      if (response.result) {
        //// chartB
        this.chartB.data = response.result.reduce( (result: any, row: any, index: number) => {
          result.labels.push(row.category_name);
          result.datasets[0].data.push(Number(row.sales_total) );
          result.datasets[0].backgroundColor.push(this.chartColors[index] );

          return result;
        }, {
          labels: [],
          datasets: [ {
            data: [],
            backgroundColor: [],
          } ]
        } );

        this.chartB.update();

        //// gridB
        this.gridBOptions.api.setRowData(response.result.map( (row: any, index: number) => ( {
          rank: index + 1,
          category_name: row.category_name,
          sales_total: row.sales_total / 1000,
          cost_total: row.cost_total / 1000,
          margin_total:  (row.sales_total - row.cost_total) / 1000,
          percent_margin: 1 - row.cost_total / row.sales_total,
        } ) ) );
      }
    } );
  }
}
