<section class="widget-div">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">검색 <span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">직거래 <span class="cnt">{{statistic?.directCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">경매 <span class="cnt">{{statistic?.auctionCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">역경매 <span class="cnt">{{statistic?.reverseAuctionCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<div class="fl w100p form-inline">
  <!-- <div class="btn-group mr5">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.sellGbn == ''}" (click)="search.sellGbn='';getBikeSellList();">전체</button>
    <button *ngFor="let item of sellGbnList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.sellGbn == item.common_code}" (click)="search.sellGbn = item.common_code;getBikeSellList();">{{item.common_name}}</button>
  </div> -->

  <div class="btn-group mr5">
    <button *ngFor="let item of product_gbn_list" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.product_gbn == item.value}" (click)="search.product_gbn = item.value; getBikeSellList();">{{item.label}}</button>
  </div>

  <div class="btn-group">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status == ''}" (click)="search.status='';getBikeSellList();">전체</button>
    <button *ngFor="let item of sellStatusList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status == item.common_code}" (click)="search.status = item.common_code;getBikeSellList();">{{item.common_name}}</button>
  </div>
</div>

<div class="cb"></div>

<hr class="db mt5 mb5" />

<div class="fl w100p form-inline">
  <div class="btn-group">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.bikeStyle == ''}" (click)="search.bikeStyle='';getBikeSellList();">전체</button>
    <button *ngFor="let item of bikeStyleList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.bikeStyle == item.common_code}" (click)="search.bikeStyle = item.common_code;getBikeSellList();">{{item.common_name}}</button>
  </div>
</div>

<div class="cb"></div>

<hr class="db mt5 mb5" />

<div class="fl w100p form-inline">
  <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
  <ng-template #footerTemplate1>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 'sdate')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
  </ng-template>
  <span class="ml5 mr5">~</span>
  <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
  <ng-template #footerTemplate2>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 'edate')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
  </ng-template>

  <div class="btn-group ml5">
    <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value);getBikeSellList();">{{item.title}}</button>
  </div>
</div>

<div class="cb"></div>

<hr class="db mt5 mb5" />

<div class="fl w65p form-inline">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small" (click)="searchInit()">
    <!-- <i class="material-icons-outlined">refresh</i> -->
    초기화
  </button>

  <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getBikeSellList()">
    <option value="10">10줄</option>
    <option value="20">20줄</option>
    <option value="500">50줄</option>
    <option value="100">100줄</option>
    <option value="200">200줄</option>
    <option value="500">500줄</option>
  </select>
  
  <!-- <select id="searchField" [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
    <option value=""></option>
  </select> -->

  <input type="text" id="searchText" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="100" class="form-control form-control-small ml5" placeholder="매물번호, 제목, 브랜드명, 모델명, 판매자아이디 검색" />

  <button type="button" title="검색" class="btn btn-default btn-small ml5" (click)="getBikeSellList()">
    <!-- <i class="material-icons-outlined">search</i> -->
    검색
  </button>

</div>

<div class="fr w35p right">
  <button type="button" class="btn btn-default btn-small ml5" (click)="setExcelOrder()">엑셀 다운로드</button>
  <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi" gridId="ea3efca534c34c3181ea0238aed612df" [btnGroup]="1"></btn-ag-grid-save>
  <!-- <button type="button" class="btn btn-warning btn-small ml5" (click)="setOrderCancel()">주문취소</button> -->
  <button type="button" class="btn btn-primary btn-small ml5" (click)="goDetail(0, '')">매물등록</button>
</div>

<div class="cb"></div>

<div class="mt5" style="height:calc(100% - 290px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="sellList"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [isRowSelectable]="isRowSelectable"
    [rowClassRules]="rowClassRules"
    [pagination]="false"
    [stopEditingWhenGridLosesFocus]="true"

    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>

  <section class="pagination mt10">
    <ngb-pagination
      [(page)]="search.pageNo"
      [pageSize]="search.pageRow"
      [collectionSize]="search.totalCount"
      [maxSize]="20"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>
</div>
