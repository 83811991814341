import { AuthGroupAddComponent } from '@page/system/auth-group/add/add.component';
import { Component, OnInit, AfterViewInit } from '@angular/core';

import { CommonService } from '@app/service/common.service';
import { SystemAuthGroupService } from '@app/service/auth.group.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { IPioneerTreeConfiguration } from '@pioneer-code/pioneer-tree';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';
import { AgGridHeaderCheckboxComponent } from '@app/components/ag-grid-header-checkbox/ag-grid-header-checkbox.component';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-auth-group',
  templateUrl: './auth-group.component.html',
  styleUrls: ['./auth-group.component.scss']
})
export class AuthGroupComponent implements OnInit, AfterViewInit {
  public authGroupList: any = [];
  public selectedNode: any = {};
  public authGroupInfo: any = [];
  public siteList: any = [];
  public siteSeq: any = 0;
  public commonCode = '';

  configuration = {
    "childPropertyName" : "children",
    "sortPropertyName" : "id",
    "collapseAllOnLoad" : false
  } as IPioneerTreeConfiguration;

  // 그리드 관련 선언
  selectedRows: number = 0;
  customerList: any = [];
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate = "검색된 데이터가 없습니다.";

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
    agGridHeaderCheckboxComponent: AgGridHeaderCheckboxComponent
  };

  constructor(
    private commonService: CommonService,
    private systemAuthGroupService: SystemAuthGroupService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '페이지코드', field: 'seq', hide:true },
      {headerName: '페이지코드', field: 'page_cd', hide:true },
      {headerName: '메뉴코드', field: 'menu_seq', hide:true },
      {headerName: '메뉴권한코드', field: 'authgroup_seq', hide:true },
      {headerName: '선택', field: 'checkrow', cellClass: 'cp center', width:80, cellRenderer: 'agGridHtmlComponent', suppressMenu: true,
        valueGetter: function(params) {
          let checked = ( params.data.checkrow ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.checkrow ) ? 'ag-hidden' : '';

          return `<div class="ag-selection-checkbox seq seq${params.data.seq}" unselectable="on">
                    <div class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></div>
                    <div class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></div>
                  </div>`;
        }
      },
      {headerName: '메뉴명', field: 'page_menu_name', cellClass: 'cp', width:300, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let l = params.data.page_cd.length;
          let len = ( l / 3 - 1 ) * 16;
          return `<span style="padding-left:${len}px">${params.data.page_menu_name}</span>`;
        }
      },
      {headerName: '조회', field: 'auth_view', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_view ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_view ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_view auth_view${params.data.seq}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_view" }
      },
      {headerName: '등록', field: 'auth_write', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_write ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_write ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_write auth_write${params.data.seq}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_write" }
      },
      {headerName: '수정', field: 'auth_modify', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_modify ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_modify ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_modify auth_modify${params.data.seq}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_modify" }
      },
      {headerName: '삭제', field: 'auth_delete', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_delete ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_delete ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_delete auth_delete${params.data.seq}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_delete" }
      },
      {headerName: '엑셀', field: 'auth_excel', cellClass: 'cp center', width:100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_excel ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_excel ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_excel auth_excel${params.data.seq}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_excel" }
      },
      {headerName: 'SMS발송', field: 'auth_sms', cellClass: 'cp center', width:120, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_sms ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_sms ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_sms auth_sms${params.data.seq}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_sms" }
      },
      {headerName: 'Email발송', field: 'auth_email', cellClass: 'cp center', width:120, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let checked = ( params.data.auth_email ) ? '' : 'ag-hidden';
          let unchecked = ( params.data.auth_email ) ? 'ag-hidden' : '';

          return `<span class="ag-selection-checkbox auth_email auth_email${params.data.seq}" unselectable="on">
                    <span class="ag-icon ag-icon-checkbox-checked ${checked}" unselectable="on"></span>
                    <span class="ag-icon ag-icon-checkbox-unchecked ${unchecked}" unselectable="on"></span>
                  </span>`;
        },
        suppressMenu: true,
        headerComponent: 'agGridHeaderCheckboxComponent',
        headerComponentParams: { field: "auth_email" }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: false
    };

    this.rowSelection = "multiple";
  }


  ngOnInit(): void {

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {
    this.getSiteList().then(() => {
      this.getAuthGroupList();
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAuthGroupList() {
    this.systemAuthGroupService.getAuthGroupList().then( response => {
      if( response.ResultCode ) {
        this.authGroupList = response.data;
        if( this.authGroupList.length > 0 ) {

          this.selectedNode.seq = this.authGroupList[0].seq;
          this.getAuthGroupInfo( this.authGroupList[0].seq );
        }
      } else {
        this.authGroupList = [];
        this.selectedNode.seq = null;
      }
    });

    this.configuration = { "childPropertyName" : "children", "sortPropertyName" : "id", "collapseAllOnLoad" : false };
  }

  /*******************************************************************************
    설  명 : 권한그룹 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addAuthGroup(item) {
    const modalRef = this.modalService.open(AuthGroupAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      // 권한그룹 리스트 가져오기
      this.getAuthGroupList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field == 'checkrow' ) {
      this.rowChecked( $event );

    } else if( $event.colDef.field == 'page_menu_name' ) {

    } else {
      let checked = $("."  + $event.colDef.field + $event.data.seq).children(".ag-icon-checkbox-checked");
      let unchecked = $("." + $event.colDef.field + $event.data.seq).children(".ag-icon-checkbox-unchecked");

      if( checked.hasClass("ag-hidden") ) {
        $event.node.setDataValue( $event.colDef.field, true);

      } else {
        $event.node.setDataValue( $event.colDef.field, false);
      }
    }
  }

  /*******************************************************************************
    설  명 : ag-grid checkbox 행 선택/해제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  rowChecked( $event ) {
    let data = [ 'checkrow', 'auth_view', 'auth_write', 'auth_modify', 'auth_delete', 'auth_excel', 'auth_sms', 'auth_email' ];
    let checked = $(".seq"+ $event.data.seq).children(".ag-icon-checkbox-checked");
    let unchecked = $(".seq"+ $event.data.seq).children(".ag-icon-checkbox-unchecked");

    if( checked.hasClass("ag-hidden") ) {
      data.forEach( val => {
        $event.node.setDataValue( val, true);
      });

    } else {
      data.forEach( val => {
        $event.node.setDataValue( val, false);
      });
    }
  }

  /*******************************************************************************
    설  명 : 권한그룹 노드 선택시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAuthGroupInfo( seq ) {
    this.systemAuthGroupService.getAuthGroupInfo(seq, this.commonCode).then( response => {

      this.authGroupInfo = response.data;

      response.data.forEach( row => {
        row.checkrow = false;
        row.authgroup_seq = this.selectedNode.seq;
        row.auth_view = ( row.auth_view == '1' ) ? true : false;
        row.auth_write = ( row.auth_write == '1' ) ? true : false;
        row.auth_modify = ( row.auth_modify == '1' ) ? true : false;
        row.auth_delete = ( row.auth_delete == '1' ) ? true : false;
        row.auth_excel = ( row.auth_excel == '1' ) ? true : false;
        row.auth_sms = ( row.auth_sms == '1' ) ? true : false;
        row.auth_email = ( row.auth_email == '1' ) ? true : false;
      });
    });

  }

  /*******************************************************************************
    설  명 : 권한그룹 노드 선택시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedAuthGroup(node) {
    this.selectedNode = node;
    this.getAuthGroupInfo( node.seq );
  }

  /*******************************************************************************
    설  명 : 권한그룹 노드 이동 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onNodeDropped( $event ) {
  }

  /*******************************************************************************
    설  명 : 권한그룹 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    let data = JSON.stringify( this.authGroupInfo );

    this.systemAuthGroupService.setAuthGroupList({data: data}).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '권한그룹 저장');

        this.getAuthGroupInfo( this.selectedNode.seq );

      } else {
        this.toastrService.error( response.ResultMessage, '권한그룹 저장');
      }
    });
  }

  /*******************************************************************************
    설  명 : 권한그룹 전체 선택/해제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectAll( checked ) {
    this.gridApi.forEachNode( node => {
      node.setDataValue( 'checkrow', checked );
      node.setDataValue( 'auth_view', checked );
      node.setDataValue( 'auth_write', checked );
      node.setDataValue( 'auth_modify', checked );
      node.setDataValue( 'auth_delete', checked );
      node.setDataValue( 'auth_excel', checked );
      node.setDataValue( 'auth_sms', checked );
      node.setDataValue( 'auth_email', checked );
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 - 사이트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSiteList() {
    return this.commonService.getCommonListCode('SITE').then( response => {
      if( response.ResultCode ) {
        this.siteList = response.data;
        this.siteSeq = this.siteList[0].seq;
        this.commonCode = this.siteList[0].common_code;
      } else {
        this.siteList = [];
      }
    });
  }

}
