  <div class="modal-header">
    <h4 class="modal-title fb mb0">{{title}}요청 상세보기</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <table class="table table-bordered table-small mt50 mb50">
    <colgroup>
      <col style="width:12%" />
      <col style="width:46%" />
      <col style="width:12%" />
      <col style="width:30%" />
    </colgroup>

    <tbody>
    <tr>
      <th>신청일자</th>
      <td>{{cancelList.req_date}}</td>
      <th>주문번호</th>
      <td>{{cancelList.order_seq}}</td>
    </tr>    
    <tr>
      <th>고객명</th>
      <td>{{cancelList.o_name}}</td>
      <th>연락처</th>
      <td>{{cancelList.o_hp}}</td>
    </tr>    
    <tr>
      <th>상품</th>
      <td class="goods-info" colspan="3">
        <div style="display: flex;" *ngFor="let detail of canceldetail;let i = index;">
          <img [src]="detail.thumbnail_url" class="fl">
          <div class="fl">
            <p class="fb">{{detail.product_name}}</p>
            <p>색상: {{detail.display_color_name}} / 사이즈: {{detail.display_size_name}} / 주문수량: {{detail.order_qty}}개 / {{title}}수량: {{detail.req_qty}}개</p>
            <p class="fb">{{detail.req_amt | number}}원</p>  
          </div>
        </div>
      </td>
    </tr>    
    <tr>
      <th>{{title}}사유</th>
      <td>
        <span *ngIf="cancelList.req_reason == '1'">배송오류</span>
        <span *ngIf="cancelList.req_reason == '2'">상품불량</span>
        <span *ngIf="cancelList.req_reason == '3'">단순변심</span>
        <span *ngIf="cancelList.req_reason == '4'">기타</span>
      </td>
      <th>메세지</th>
      <td>{{cancelList.comment}}</td>
    </tr>
    <tr>
      <th>차감금액</th>
      <td>{{cancelList.deduction_amt | number}} 원</td>
      <th>동봉배송비</th>
      <td>{{cancelList.enclose_amt | number}} 원</td>
    </tr>
    <tr>
      <th>환불금액</th>
      <td>{{cancelList.real_ret_amt | number}} 원</td>
      <th></th>
      <td></td>
    </tr>
    <tr>
      <th>처리방식</th>
      <td>{{cancelList.req_type_name}}</td>
      <th>배송비지급</th>
      <td>{{cancelList.req_pay_type_name}}</td>
    </tr>
    <ng-container *ngIf="reg_gbn == '2000'">
    <tr>
      <th>은행</th>
      <td>{{cancelList.bank}}</td>
      <th>계좌번호</th>
      <td>{{cancelList.account}}</td>
    </tr>
    <tr>
      <th>예금주</th>
      <td>{{cancelList.depositor}}</td>
      <th></th>
      <td></td>      
    </tr>
    </ng-container>
    <ng-container *ngIf="reg_gbn == '3000'">
    <tr>
      <th>고객명</th>
      <td>{{cancelList.o_name}}</td>
      <th>연락처</th>
      <td>{{cancelList.o_hp}}</td>
    </tr>
    <tr>
      <th>주소</th>
      <td colspan="3">{{cancelList.address}} {{cancelList.address_detail}}</td>
    </tr>    
    </ng-container>
    <tr>
      <th>첨부이미지</th>
      <td colspan="3">
        <div class="fl mr10" *ngFor="let image of cancelList.image" >
          <a [href]="baseURL + '/api/file/commonfile_download.php?return_exchange_seq=' + image.seq" target="_blank">
            <p><img [src]="image.url" class="fl"></p>
            <p class="ellipsis">{{image.origin_filename}}</p>
          </a>
        </div>
      </td>
    </tr>
    <tr>   
      <th>답변작성</th>
      <td colspan="3">
        <inputEx type="textarea" name="message" [formGroup]="form" [formErrors]="formErrors" rows="5"></inputEx>
      </td>
    </tr>     
    </tbody>
    </table>

  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button type="button" class="btn btn-secondary btn-small mr5" (click)="cancel()">철회</button>
      <button type="button" class="btn btn-danger btn-small" (click)="approval('2')">거절</button>
    </div>  
    <div class="fr w50p right">
      <button *ngIf="reg_gbn == '2000'" type="button" class="btn btn-default btn-small mr5" (click)="changeReq_gbn()">교환으로 변경</button>
      <button *ngIf="reg_gbn == '3000'" type="button" class="btn btn-default btn-small mr5" (click)="changeReq_gbn()">반품으로 변경</button>
      <button type="button" class="btn btn-primary btn-small mr5" (click)="approval('1')">승인</button>
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">창 닫기</button>
    </div>
  </div>

