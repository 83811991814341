<form [formGroup]="form">

<section id="customer-member-info">
    
  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">이벤트정보</h4>
    </div>
    <div class="fr w50p right">
      <button *ngIf="eventSeq!=0" type="button" class="btn btn-danger btn-small" (click)="setEventDelete()">삭제하기</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="setEventSave()">저장하기</button>
    </div>
    <div class="cb"></div>
  </div>

  <table class="table table-bordered table-small1 mb0">
  <caption class="none">이벤트정보</caption>
  <colgroup>
    <col style="width:8%;" />
    <col style="width:26%;" />
    <col style="width:8%;" />
    <col style="width:25%;" />
    <col style="width:8%;" />
    <col style="width:25%;" />
  </colgroup>

  <tbody class="table table-bordered table-small1 mb0">
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="">이벤트 진행</label></th>
    <td>
      <div class="btn-group">
        <button type="button" [ngClass]="{active: form.controls.isDisplay.value=='1'}" (click)="form.get('isDisplay').setValue('1')" class="btn btn-default btn-small">표시</button>
        <button type="button" [ngClass]="{active: form.controls.isDisplay.value=='0'}" (click)="form.get('isDisplay').setValue('0')" class="btn btn-default btn-small">숨김</button>
      </div>
    </td>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="event_type">이벤트 타입</label></th>
    <td>
      <div class="btn-group">
        <button *ngFor="let item of eventTypeList" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.event_type.value == item.common_code}" (click)="setEventType(item.common_code)">{{item.common_name}}</button>
      </div>
      <inputEx type="hidden" name="event_type" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="">회원등급할인적용</label></th>
    <td>
      <div class="btn-group">
        <button type="button" [ngClass]="{active: form.controls.is_memberDiscount.value=='1'}" (click)="form.get('is_memberDiscount').setValue('1')" class="btn btn-default btn-small">적용</button>
        <button type="button" [ngClass]="{active: form.controls.is_memberDiscount.value=='0'}" (click)="form.get('is_memberDiscount').setValue('0')" class="btn btn-default btn-small">미적용</button>
      </div>
      <span *ngIf="form.controls.is_memberDiscount.value=='1'" class="cblue f12 ml10">※ 이벤트가격 설정 필요없음</span>
      <span *ngIf="form.controls.is_memberDiscount.value=='0'" class="cred f12 ml10">※ 이벤트가격 설정 필요</span>
    </td>
  </tr>
  <tr *ngIf="form.controls.event_type.value == '1001'">
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="supersale_seq">보상세일 이벤트 선택</label></th>
    <td colspan="5">
      <inputEx type="select2" name="supersale_seq" [formGroup]="form" [formErrors]="formErrors" [data]="eventSupersaleList" [small]="true" [options]="{multiple: false}" [onChanged]="onChangedSupersaleFunc"></inputEx>
    </td>
  </tr>
  <tr *ngIf="form.controls.event_type.value == '3000'">
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="coupon_type">쿠폰 종류</label></th>
    <td>
      <div class="btn-group">
        <button *ngFor="let item of couponTypeList" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.coupon_type.value == item.common_code}" (click)="setCouponType(item.common_code)">{{item.common_name}}</button>
      </div>
      <inputEx type="hidden" name="coupon_type" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="coupon_seq">적용 쿠폰 선택</label></th>
    <td colspan="3">
      <inputEx type="select2" name="coupon_seq" [formGroup]="form" [formErrors]="formErrors" [data]="eventCouponList" [small]="true" [options]="{multiple: false}" [onChanged]="onChangedCouponFunc"></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="event_name">이벤트 제목</label></th>
    <td>
      <inputEx type="text" name="event_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
    <th scope="row"><label for="event_display_type">이벤트 표시</label></th>
    <td colspan="3">
      <inputEx type="select2" name="event_display_type" [formGroup]="form" [formErrors]="formErrors" [data]="eventDisplayTypeList" [small]="true" [options]="{multiple: true}" [onChanged]="onChangedDisplayFunc"></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="target">이벤트 대상</label></th>
    <td>
      <inputEx type="select2" name="target" [formGroup]="form" [formErrors]="formErrors" [data]="targetList" [small]="true" [options]="{multiple: true}" [onChanged]="onChangedTargetFunc"></inputEx>
    </td>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="sDate">시작일시</label></th>
    <td><dateTimePicker name="start_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="end_date">종료일시</label></th>
    <td><dateTimePicker name="end_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
  </tr>
  <tr>
    <th scope="row"><label for="test_id">테스트아이디</label></th>
    <td>
      <inputEx type="text" name="test_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
    <th scope="row"><label for="sDate">테스트 시작일시</label></th>
    <td><dateTimePicker name="test_start_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
    <th scope="row"><label for="end_date">테스트 종료일시</label></th>
    <td><dateTimePicker name="test_end_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
  </tr>
  <!-- <tr>
    <th scope="row"><label for="keyword">이벤트 키워드</label></th>
    <td colspan="5">
      <inputEx type="text" name="keyword" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr> -->
  <tr>
    <th scope="row"><label for="event_link">이벤트 링크</label></th>
    <td colspan="5">
      <inputEx type="text" name="event_link" [formGroup]="form" [formErrors]="formErrors" placeholder="클릭시 이동할 링크를 입력하세요. 유튜브 선택시에는 유튜브 영상 아이디를 입력하세요."></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="isRecommend">추천여부</label></th>
    <td>
      <div class="btn-group">
        <button type="button" [ngClass]="{active: form.controls.isRecommend.value=='1'}" (click)="form.get('isRecommend').setValue('1')" class="btn btn-default btn-small">추천</button>
        <button type="button" [ngClass]="{active: form.controls.isRecommend.value=='0'}" (click)="form.get('isRecommend').setValue('0')" class="btn btn-default btn-small">비추천</button>
      </div>
    </td>
    <th scope="row"><label for="isRecommend">쿠폰이벤트URL</label></th>
    <td colspan="3">
      <div *ngIf="form.controls.event_type.value == '3000'" >
        <inputEx type="text" name="coupon_URL" [formGroup]="form" [formErrors]="formErrors" readonly="true" ></inputEx>
      </div>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="memo">메모</label></th>
    <td colspan="5">
      <inputEx type="textarea" name="memo" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="contents">이벤트 내용</label></th>
    <td colspan="5">
      <inputEx type="summernote" name="contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
    </td>
  </tr>
  </tbody>
  </table>
</section>

<section class="mt15">
  <div class="mb5">
    <div class="fl w50p fb pt5">이벤트 이미지</div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small ml5" (click)="setEventSave()">저장하기</button>
    </div>
    <div class="cb"></div>
  </div>

  <table class="table table-bordered table-small">
  <caption class="hidden">이벤트 이미지정보</caption>

  <colgroup>
    <col style="width: 10%" />
    <col style="width: 90%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row" class="active"><i class="material-icons cred f11 lh20">star</i> <label for="file">PC 이미지</label></th>
    <td>
      <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
      <div class="progress mt5 mb5">
        <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
      </div>
      <!-- <div class="mt5">
      ※ 파일별 50MB 까지, 최대 1개까지 첨부 가능, 전체 첨부용량 50MB 제한
      </div> -->
      <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
        <ul class="file-ul">
          <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
            <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
              <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertUploadImage(file)">
                <img [src]="file.url" />
              </div>
              <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
              <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
              <div class="upload-size">
                ({{getComma(file.size)}} KB)
                <a class="cp ml5" (click)="setDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
              </div>
            </div>
          </li>
          <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
            <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
              <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertUploadImage(file)">
                <img [src]="file.url" />
              </div>
              <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
              <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
              <div class="upload-size">
                ({{getComma(file.size)}} KB)
                <a class="cp ml5" (click)="deleteFile( file, i )">
                  <img src="/assets/images/del_ico.png" />
                </a>
              </div>
            </div>
          </li>
          <li class="cb"></li>
        </ul>
      </div>
    </td>
  </tr>
  <tr>
    <th scope="row" class="active"><i class="material-icons cred f11 lh20">star</i> <label for="file">모바일 대표 이미지</label></th>
    <td>
      <inputEx type="file" name="mFile" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="mHandleUploadFileChanged($event)"></inputEx>
      <div class="progress mt5 mb5">
        <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
      </div>
      <!-- <div class="mt5">
      ※ 파일별 50MB 까지, 최대 1개까지 첨부 가능, 전체 첨부용량 50MB 제한
      </div> -->
      <div *ngIf="form.controls.mFiles.value.length > 0 || form.controls.mUpload.value.length > 0" class="file-div">
        <ul class="file-ul">
          <li *ngFor="let file of form.controls.mUpload.value; let i = index" class="fl">
            <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
              <div *ngIf="checkImage(getExt(file.origin))" class="upload-image">
                <img [src]="file.url" />
              </div>
              <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
              <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
              <div class="upload-size">
                ({{getComma(file.size)}} KB)
                <a class="cp ml5" (click)="mSetDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
              </div>
            </div>
          </li>
          <li *ngFor="let file of form.controls.mFiles.value; let i = index" class="fl">
            <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
              <div *ngIf="checkImage(getExt(file.origin))" class="upload-image">
                <img [src]="file.url" />
              </div>
              <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
              <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
              <div class="upload-size">
                ({{getComma(file.size)}} KB)
                <a class="cp ml5" (click)="mDeleteFile( file, i )">
                  <img src="/assets/images/del_ico.png" />
                </a>
              </div>
            </div>
          </li>
          <li class="cb"></li>
        </ul>
      </div>
    </td>
  </tr>
  </tbody>
  </table>
</section>

</form>
