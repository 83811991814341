<section class="search-condition mb5">
  <div class="fl form-inline asub-title">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="reset()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getStockIn()">
      <i class="material-icons-outlined">search</i>
    </button> -->

    <div class="btn-group ml5">
      <button *ngFor="let item of ['전체','발주입고','기타']; index as idx" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.value.inout_gbn == idx}" (click)="btnClick('inout_gbn', idx)">{{item}}</button>
    </div>

    <div class="btn-group ml5">
      <button *ngFor="let item of ['전체','결재','미결재']; index as idx" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.value.approval == idx}" (click)="btnClick('approval', idx)">{{item}}</button>
    </div>

    <div class="input-group ml5" (click)="addProductModal()">
      <input type="text" class="form-control form-control-small readonly" readonly="readonly" placeholder="상품 검색" [value]="form.value.product_seq.length ? form.value.product_seq.length + ' 건 검색중' : ''" />
      <div class="input-group-append">
        <button type="button" class="btn btn-default btn-small f12">상품찾기</button>
      </div>
    </div>

    <!-- <div class="input-group ml5">
      <input type="text" class="form-control form-control-small readonly" readonly="readonly" placeholder="거래처 검색" />
      <div class="input-group-append">
        <button type="button" class="btn btn-default btn-small f12">거래처찾기</button>
      </div>
    </div> -->

    <!-- <input type="text" style="width:110px;" class="form-control form-control-small calendar-input ml5" [readonly]="true" placeholder="검색 시작일" /> -->
    <input type="text" size="13" readonly="readonly" [value]="form.value.s_date" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect)="selectDate('s_date', $event)" />
    <span class="pl5 pr5">~</span>
    <input type="text" size="13" readonly="readonly" [value]="form.value.e_date" class="form-control form-control-small calendar-input " placeholder="검색 종료일" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect)="selectDate('e_date', $event)" />

  </div>
  <div class="fr">
    <button type="button" class="btn btn-default btn-small f12" (click)="stockInBase()">기타 입고</button>
    <button type="button" class="btn btn-default btn-small f12 ml5" (click)="stockInPurchase()">발주입고</button>
    <!-- <button type="button" class="btn btn-success btn-small f12 ml5" (click)="approval()">결재상신</button> -->
  </div>
  <div class="cb"></div>
</section>

<section style="height:calc(100% - 40px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="form.value.stockOrderList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [rowClassRules]="rowClassRules"

    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</section>