import { Component, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-ag-grid-tooltip',
  templateUrl: './ag-grid-tooltip.component.html',
  styleUrls: ['./ag-grid-tooltip.component.scss'],
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0}),
        animate(100, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(100, style({ opacity: 0 })),
        
      ]),
      
    ]),
  ],
})
export class AgGridTooltipComponent {
  @Input() image = '';
  @Input() text = '';
  @Input() index = '';
}
