import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { ProductService } from '@app/service/product.service';

import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-product-find',
  templateUrl: './product-find.component.html',
  styleUrls: ['./product-find.component.scss']
})
export class ProductFindComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
    category_code: '',
    brand_seq: ''
  };

  public categoryList: any = [];
  public brandList: any = [];
  public productList: any = [];
  public selectedList: any = [];

  public baseURL: any = config.baseUrl;

  leftGridApi: any;
  leftGridColumnApi: any;
  leftColumnDefs: any;

  rightGridApi: any;
  rightGridColumnApi: any;
  rightColumnDefs: any;

  defaultColDef: any;

  leftGridNoRowsTemplate: string;
  rightGridNoRowsTemplate: string;

  // 그리드 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private productService: ProductService,
  ) {
    this.leftColumnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품번호', field: 'seq', cellClass: 'cp center', width: 100 },
      { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center', width: 90, cellRenderer: 'agGridImageComponent' },
      { headerName: '브랜드', field: 'brand_name', cellClass: 'cp center', width: 120 },
      { headerName: '상품명', field: 'product_name', cellClass: 'cp left', width: 300 },
      { headerName: '선택', field: '', cellClass: 'center', width: 65, rowDrag: true, suppressMenu: true,
        rowDragText: function(params, dragItemCount) {
          if (dragItemCount > 1) {
              return dragItemCount + ' 상품';
          }
          return params.rowNode.data.product_name;
        }
      },
    ];

    this.rightColumnDefs = [
      { headerName: '상품번호', field: 'seq', cellClass: 'cp center', width: 100 },
      { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center', width: 90, cellRenderer: 'agGridImageComponent' },
      { headerName: '브랜드', field: 'brand_name', cellClass: 'cp center', width: 120 },
      { headerName: '상품명', field: 'product_name', cellClass: 'cp left', width: 300 },
      {
        field: '삭제',
        width: 65,
        cellRenderer: function(params) {
          const button = document.createElement('i');

          button.addEventListener('click', function() {
              params.api.updateRowData({ remove: [params.node.data] });
          });

          button.classList.add('material-icons');
          button.style.cursor = 'pointer';
          button.textContent = 'delete_forever';

          return button;
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    // 메시지 표시 선언
    this.leftGridNoRowsTemplate = '카테고리 또는 브랜드 선택하거나 검색어를 입력하시기 바랍니다.';
    this.rightGridNoRowsTemplate = '추가할 상품을 왼쪽 그리드에서 드래그해주세요.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCategoryList();
    this.getBrandList();

    // 상품 리스트 가져오기
    // this.getProductListForSort();
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
        this.activeModal.close();
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      this.brandList = [];
      if( response.ResultCode ) {
        // this.brandList = response.data;

        this.brandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.brandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.brandList = [];
        this.activeModal.close();
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductListForSort() {
    if( typeof this.search.brand_seq == 'object' ) {
      if( this.search.brand_seq.id != '0' ) {
        this.search.brand_seq = this.search.brand_seq.id;
      } else {
        this.search.brand_seq = '';
      }
    }

    this.productService.getProductListForSort( this.search ).then( response => {
      if( response.ResultCode ) {
        this.productList = response.data;
      } else {
        this.productList = [];

        this.toastrService.error( response.ResultMessage, '상품 가져오기');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( '서버와의 통신에 실패하였습니다 : ' + error, '상품 가져오기');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onLeftGridReady(params) {
    this.leftGridApi = params.api;
    this.leftGridColumnApi = params.columnApi;
  }

  onRightGridReady(params) {
    this.rightGridApi = params.api;
    
    this.addGridDropZone();
  }

  // 그리드 노드 아이디 가져오기
  getRowNodeId = data => data.seq;

  // 그리드 드랍 시
  addGridDropZone() {
    const dropZoneParams = this.rightGridApi.getRowDropZoneParams({
      onDragStop: params => {
        var nodes = params.nodes;

        nodes.forEach(function(node) {
          node.setSelected(false);
        });
        // this.leftGridApi.applyTransaction({
        //   remove: nodes.map(function(node) { return node.data; })
        // });
      }
    });

    this.leftGridApi.addRowDropZone(dropZoneParams);
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.leftGridApi.setQuickFilter(searchText.value);
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked( event: any ) {
    let newData: any = [];
    let duplicate: boolean = false;

    this.rightGridApi.forEachNodeAfterFilterAndSort(node => {
      if( event.data.seq == node.data.seq ) duplicate = true;

      newData.push(node.data);
    });

    if( duplicate === false ) {
      newData.push( event.data );
    }

    this.rightGridApi.setRowData( newData );
  }
  
  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectBrandChanged( event: any ) {
    if( event.id != '0' ) {
      this.search.brand_seq = event.id;
    } else {
      this.search.brand_seq = '';
    }

    this.getProductListForSort();
  }

  /*******************************************************************************
    설  명 : 상품 선택 시 결과 돌려주기
    입력값 : 상품 정보
    리턴값 : 없음
  *******************************************************************************/
  selectProduct() {
    const rowData = [];
    this.rightGridApi.forEachNode(node => rowData.push(node.data));

    this.activeModal.close( rowData );
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      category_code: '',
      brand_seq: {id: '0'}
    };
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductListForSort();
    }
  }

  /*******************************************************************************
    설  명 : 창닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDismiss() {
    this.activeModal.dismiss();
  }

}
