<div class="admin-contents">

  <section class="contents-area">
    <form [formGroup]="form">

      <section class="">
      </section>

    </form>
  </section>
</div>
