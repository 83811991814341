import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class BasicService {
  
  private messageCnt: BehaviorSubject<any> = new BehaviorSubject(0);

  get getMessageCnt() {
    return this.messageCnt.asObservable();
  }

  setMessageCnt(value: any) {
    this.messageCnt.next(value);
  }

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 창고 리스트 가져오기
  getWarehouseList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getWarehouseList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 상세정보 및 로케이션 리스트 가져오기
  getWarehouseDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getWarehouseDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 로케이션 상세정보 가져오기
  getWarehouseLocationDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getWarehouseLocationDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 정보 저장
  setWarehouseSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setWarehouseSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 삭제
  setWarehouseDelete( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setWarehouseDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 담당자 검색
  getWarehouseChargeDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getWarehouseChargeDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 담당자 저장
  setWarehouseChargeSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setWarehouseChargeSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 창고 담당자 삭제
  setWarehouseChargeDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setWarehouseChargeDelete'
      }, {
        params: array
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 위치 정보 저장
  setWarehouseLocationSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setWarehouseLocationSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 위치 삭제
  setWarehouseLocationDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setWarehouseLocationDelete'
      }, {
        params: array
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매장 리스트 가져오기
  getStoreList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getStoreList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // OFF 매장 리스트 가져오기
  getStoreListOff( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getStoreListOff'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매장 상세정보 가져오기
  getStoreDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getStoreDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매장 정보 저장
  setStoreSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setStoreSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매장 삭제
  setStoreDelete( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setStoreDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매장 담당자 정보
  getStoreChargeDetail( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getStoreChargeDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매장 담당자 저장
  setStoreChargeSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setStoreChargeSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매장 담당자 삭제
  setStoreChargeDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setStoreChargeDelete'
      }, {
        params: array
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 리스트 가져오기
  getCustomerList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getCustomerList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 상세정보 가져오기
  getCustomerDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getCustomerDetail'
      }, {
        customerSeq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 회원정보 가져오기
  getCustomerMemberInfo( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getCustomerMemberInfo'
      }, {
        customerSeq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 정보 저장
  setCustomerInfoSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setCustomerInfoSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 회원정보 저장
  setCustomerMemberInfoSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setCustomerMemberInfoSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 메모 리스트 가져오기
  getCustomerMemoList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getCustomerMemoList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 메모 저장
  setCustomerMemoSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setCustomerMemoSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 메모 삭제
  setCustomerMemoDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setCustomerMemoDelete'
      }, {
        params: array
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 담당자 리스트 가져오기
  getCustomerChargeList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getCustomerChargeList'
      }, {
        customerSeq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 담당자 추가
  setCustomerChargeSave( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setCustomerChargeSave'
      }, data
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 담당자 삭제
  setCustomerChargeDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setCustomerChargeDelete'
      }, {
        params: array
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 계좌정보 리스트 가져오기
  getCompanyAccountList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getCompanyAccountList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 계좌정보 저장
  setCompanyAccountSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setCompanyAccountSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 계좌정보 삭제
  setCompanyAccountDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setCompanyAccountDelete'
      }, {
        params: array
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 알람관리 표시안함 일수 변경
  setDisplayMessageDays( days: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'message',
      version: '1.0',
      action: 'setDisplayMessageDays'
    }, {
      days
    }
  ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 알람관리 표시안함 일수 변경
  setMessageChargeAll( messageType: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'message',
      version: '1.0',
      action: 'setMessageChargeAll'
    }, {
      messageType
    }
  ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 코드별 담당자 정보 가져오기
  getMessageChargeList( messageType: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'message',
      version: '1.0',
      action: 'getMessageChargeList'
    }, {
      messageType
    }
  ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 알람관리 표시안함 일수 변경
  setMessageCharge( messageType: any, array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'message',
      version: '1.0',
      action: 'setMessageCharge'
    }, {
      messageType,
      params: array
    }
  ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  setMessageChargeDelete( messageType: any, array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'message',
      version: '1.0',
      action: 'setMessageChargeDelete'
    }, {
      messageType,
      params: array
    }
  ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 담당자별 메세지 정보 가져오기
  getMessageList(): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'message',
      version: '1.0',
      action: 'getMessageList'
    }, {}
  ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 메세지 확인
  setMessageView( seq: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'message',
      version: '1.0',
      action: 'setMessageView'
    }, {
      seq: seq
    }
  ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }
  
  // 바마쿠폰리스트 가져오기
  getBamaCouponList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getBamaCouponList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사용가능쿠폰만가져오기
  getAvailableBamaCouponList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getAvailableBamaCouponList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 바마쿠폰 상세정보 가져오기
  getBamaCouponDetail( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getBamaCouponInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 쿠폰(지급적용) 카테고리 리스트
  getCouponGiveCategoryList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getCouponGiveCategoryList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 쿠폰(판매적용) 카테고리 리스트
  getCouponSaleCategoryList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'getCouponSaleCategoryList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


    // 쿠폰(지급적용) 브랜드 리스트
    getCouponGiveBrandList(params): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'basic',
          version: '1.0',
          action: 'getCouponGiveBrandList'
        }, params
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }
  
    // 쿠폰(판매적용) 브랜드 리스트
    getCouponSaleBrandList(params): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'basic',
          version: '1.0',
          action: 'getCouponSaleBrandList'
        }, params
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }
  
    // 쿠폰(지급적용) 검색상품 리스트
    getCouponGiveSearchProductList(params): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'basic',
          version: '1.0',
          action: 'getCouponGiveSearchProductList'
        }, params
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }

    // 쿠폰(판매적용) 검색상품 리스트
    getCouponSaleSearchProductList(params): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'basic',
          version: '1.0',
          action: 'getCouponSaleSearchProductList'
        }, params
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }

    // 쿠폰(지급적용) 브랜드 상품 리스트
    getCouponGiveBrandProductList(params): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'basic',
          version: '1.0',
          action: 'getCouponGiveBrandProductList'
        }, params
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }

    // 쿠폰(판매적용) 브랜드 상품 리스트
    getCouponSaleBrandProductList(params): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'basic',
          version: '1.0',
          action: 'getCouponSaleBrandProductList'
        }, params
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }

    // 쿠폰(지급적용) 카테고리 상품 리스트
    getCouponGiveCategoryProductList(params): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'basic',
          version: '1.0',
          action: 'getCouponGiveCategoryProductList'
        }, params
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }

    // 쿠폰(판매적용) 카테고리 상품 리스트
    getCouponSaleCategoryProductList(params): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'basic',
          version: '1.0',
          action: 'getCouponSaleCategoryProductList'
        }, params
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }

  // 쿠폰 정보 저장
  setBamaCouponSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'basic',
        version: '1.0',
        action: 'setBamaCouponSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }    

  // 바마쿠폰 지급항목 품목 설정 삭제
  setBamaCouponGiveProductDelete( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'basic',
      version: '1.0',
      action: 'setBamaCouponGiveProductDelete'
    }, {
    params: array
     }
    
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }
  
  
  // 바마쿠폰 지급항목 품목 설정 저장
  setBamaCouponGiveProductSave( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'basic',
      version: '1.0',
      action: 'setBamaCouponGiveProductSave'
    }, {
      params: array
    }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 바마쿠폰 판매항목 품목 설정 삭제
  setBamaCouponSaleProductDelete(  array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'basic',
      version: '1.0',
      action: 'setBamaCouponSaleProductDelete'
    }, {
      params: array
       }
     ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }
  
  
  // 바마쿠폰 판매항목 품목 설정 저장
  setBamaCouponSaleProductSave( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'basic',
      version: '1.0',
      action: 'setBamaCouponSaleProductSave'
    }, {
      params: array
    }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  


  }  




