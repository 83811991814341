/*******************************************************************************
  설  명 : 슈퍼세일 설정 - 상품가격 설정
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';

import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-supersale-product',
  templateUrl: './supersale-product.component.html',
  styleUrls: ['./supersale-product.component.scss']
})
export class SupersaleProductComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() superSaleSeq: any;

  public search: any = {
    searchText: ''
  }

  // 그리드 관련 선언
  superSaleProductList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  selectedRows: number = 0;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'storeSeq', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '카테고리', field: 'storeName', width: 200, cellClass: 'cp' },
      {headerName: '브랜드', field: 'storeName', width: 150, cellClass: 'cp' },
      {headerName: '모델', field: 'storeName', width: 200, cellClass: 'cp' },
      {headerName: '판매가', field: 'storeShortName', width: 100, cellClass: 'cp right' },
      {headerName: '할인율', field: 'storeShortName', width: 80, cellClass: 'cp right' },
      {headerName: '적립율', field: 'storeShortName', width: 80, cellClass: 'cp right' },
      {headerName: '세일단가', field: 'storeShortName', width: 100, cellClass: 'cp right' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
  }


}
