/*******************************************************************************
  설  명 : 폐기/분실
  작성일 : 2019-12-12
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';

import * as moment from 'moment';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-stock-loss',
  templateUrl: './stock-loss.component.html',
  styleUrls: ['./stock-loss.component.scss']
})
export class StockLossComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq: number; // 0 = 추가, else = 수정
  public product_seq: number;
  public product_name: string = '';

  public title: string = '추가';

  private warehouseList: any = [];
  public out_warehouse: any = []; // 출고창고
  public inout_gbn: any = [];

  public formLoss: FormGroup;
  public formErrors = {};

  public addProductFunc: any;         // 상품검색
  public changeOutWarehouseFunc: any;     // 출고창고 변경시 실행 함수

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let date: NgbDateStruct = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date()
    };

    this.formLoss = this.formBuilder.group({
      seq: ["", [] ],
      inout_gbn: ["", [Validators.required] ],
      inout_code: ["", [Validators.required] ],
      warehouse_seq_out: ["", [Validators.required] ],
      product_seq: ["", [Validators.required] ],
      product_name: ["", [Validators.required] ],
      inout_date: [date, [Validators.required] ],
      max_qty:["", [] ],
      input_qty: ["", [Validators.required] ],
      memo: ["", [] ],
    });

    this.formLoss.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formLoss, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();

    this.addProductFunc = this.searchProduct.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

  }

  /*******************************************************************************
    설  명 : 상품 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchProduct() {
    /*
    const modalRef = this.modalService.open(ACProductFindComponent, options);

    modalRef.componentInstance.set_yn = false;

    modalRef.result.then((result) => {
      this.formLoss.patchValue({
        product_seq: result.product_seq,
        product_name: result.name
      });

      // 상품 선택 후 입고창고, 출고창고 정보를 표시
      this.out_warehouse = this.warehouseList;
    }, (reason) => {
    });
    */
  }

}
