import { Component, OnInit } from '@angular/core';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@app/components/ag-grid-excomponent/ag-grid-excomponent';

import { EmployeeService } from '@app/service/employee.service';
import * as moment from 'moment';


@Component({
  selector: 'app-workmanagement-workstatus',
  templateUrl: './workmanagement-workstatus.component.html',
  styleUrls: ['./workmanagement-workstatus.component.scss']
})
export class WorkmanagementWorkstatusComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    mem_no: '',
    year: parseInt( moment().format('YYYY'), 10 )
  };
  public employeeList: any = [];
  public yearList: any = [];
  public TNAStatisticsList: any = [];
  
  // 그리드 관련 선언

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private employeeService: EmployeeService,
    private agGridExComponent: AgGridExComponent,

  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 30, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '부서', field: 'dept_name', width: 200, cellClass: 'cp center' },
      { headerName: '직위', field: 'position_name', width: 200, cellClass: 'cp' },
      { headerName: '사원명', field: 'name', width: 200, cellClass: 'cp' },
      { headerName: '연차총일수', field: 'vacation_day', width: 100, cellClass: 'cp' },
      { headerName: '연차', field: 'annual', width: 100, cellClass: 'cp' },
      { headerName: '반차', field: 'half_day', width: 100, cellClass: 'cp' },
      { headerName: '조퇴', field: 'early', width: 100, cellClass: 'cp' },
      { headerName: '지각', field: 'lateness', width: 100, cellClass: 'cp' },
      { headerName: '경조', field: 'congratulations', width: 100, cellClass: 'cp' },
      { headerName: '병가', field: 'sick', width: 100, cellClass: 'cp' },
      { headerName: '외출', field: 'outing', width: 100, cellClass: 'cp' },
      { headerName: '잔여일수', field: 'total', width: 100, cellClass: 'cp' }                  
    ];  
    
    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';  

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getEmployee();
    this.getTNAStatisticsList();
    this.getTNAStatisticsYearList();
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      mem_no: '',
      year: ''
    };

    this.getTNAStatisticsList();
  }

  /*******************************************************************************
    설  명 : 사원 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMember(event) {
    this.search.mem_no = event.target.value;
    this.getTNAStatisticsList();
  }

  /*******************************************************************************
    설  명 : 사원 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setYear(event) {
    this.search.year = event.target.value;
    this.getTNAStatisticsList();
  }

  /*******************************************************************************
    설  명 : 부서 리스트 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployee() {
    this.employeeService.getGroupEmployeeListForSelect().then( response => {
      if( response.ResultCode ) {
        this.employeeList = response.data;
      } else {
        this.employeeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 근태 현황 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTNAStatisticsList() {
    this.employeeService.getTNAStatisticsList( this.search.mem_no, this.search.year ).then( response => {
      if( response.ResultCode ) {
        this.TNAStatisticsList = response.data;
      } else {
        this.TNAStatisticsList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 연도 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTNAStatisticsYearList() {
    this.employeeService.getTNAStatisticsYearList().then( response => {
      if( response.ResultCode ) {
        this.yearList = response.data;
      } else {
        this.yearList = [];
      }
    });
  }

}
