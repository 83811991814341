<form>
  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">무통장</h4>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small">저장</button>
    </div>
    <div class="cb"></div>
  </div>  
  
  <table class="table table-bordered table-small1 mb0">
  <caption class="none">무통장</caption>
  <colgroup>
    <col style="width:15%" />
    <col style="width:85%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="">아이디</label></th>
    <td><inputEx type="text" name="" size="40" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="">비밀번호</label></th>
    <td><inputEx type="password" name="" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="">해시키</label></th>
    <td><inputEx type="text" name="" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="">리턴값</label></th>
    <td><inputEx type="text" name="" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="">동기화시간</label></th>
    <td>
      <div class="input-group">
	      <inputEx type="text" name="" [formGroup]="form" [formErrors]="formErrors" style="width:10%"></inputEx>
	      <span style="width:80%" class="pl5">분</span>
	      <button type="button" class="btn btn-primary btn-small" style="width:10%">연동테스트</button>
      </div>
    </td>
  </tr>
  </tbody>
  </table>
</form>  
