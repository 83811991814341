import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { CompanyService } from '@app/service/company.service';
import { CommonService } from '@app/service/common.service';
import { MemberService } from '@app/service/member.service';


import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { MemberSocietyHistoryAddComponent } from '@page/member/society/member-society-history/member-society-history-add/member-society-history-add.component';


const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-coupon',
  templateUrl: './member-coupon.component.html',
  styleUrls: ['./member-coupon.component.scss']
})
export class MemberCouponComponent implements OnInit {


  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
    use_yn:'',
  };

  public search_detail: any = {
    is_use:'',
  };

  public month: any = moment().format('MM');

  public typeList: any = [];
  public couponList: any = [];
  public historyList: any = [];
  public memoList: any = [];



  public societySelected: any = {};

  public selectedSeq: any = null;

  public statistic: any = {
    totalCoupon: 0,
    useCoupon: 0,
    unuseCoupon: 0,
    discardCoupon: 0
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridDetailApi: any;
  gridDetailColumnApi: any;
  columnDetailDefs: any;

  gridMemoApi: any;
  gridMemoColumnApi: any;
  columnMemoDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private companyService: CompanyService,
    private commonService: CommonService,
    private agGridExComponent: AgGridExComponent,
    private modalService: NgbModal,
    private memberService: MemberService  
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'rowIndex', width: 50, cellClass: 'cp center', 
      valueGetter: function(params) {
        let rowData = params.node.gridOptionsWrapper.gridOptions.rowData.length;
        let rowIndex = params.node.rowIndex;
        return parseInt(rowData) - rowIndex;
      }
      },      
      { headerName: '쿠폰구분', field: 'coupon_kind_name', width: 110, cellClass: 'cp left' },
      { headerName: '쿠폰명', field: 'coupon_name', width: 140, cellClass: 'cp left' },
      { headerName: '유효개월', field: 'valid_month', width: 75, cellClass: 'cp right' },
      { headerName: '할인구분', field: 'apply_type_name', width: 80, cellClass: 'cp left' },
      { headerName: '할인(%/원)', field: 'apply_number', width: 85, cellClass: 'cp right' , valueFormatter: this.agGridExComponent.currencyFormatter},
      { headerName: '총발행수', field: 'totalmemberCoupon', width: 80, cellClass: 'cp right' },
      { headerName: '사용가능수', field: 'availableCoupon', width: 85, cellClass: 'cp right' },
    ];

    this.columnDetailDefs = [
   //   { headerName: '순번', field: 'seq', width: 50, cellClass: 'cp center' },
      { headerName: '순번', field: 'rowIndex', width: 50, cellClass: 'cp center', 
      valueGetter: function(params) {
        let rowData = params.node.gridOptionsWrapper.gridOptions.rowData.length;
        let rowIndex = params.node.rowIndex;
        return parseInt(rowData) - rowIndex;
      }
      // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },      
      { headerName: '지급일시', field: 'issue_date', width: 150, cellClass: 'cp left' },
      { headerName: '아이디', field: 'member_id', width: 110, cellClass: 'cp left' },
      { headerName: '회원명', field: 'member_name', width: 130, cellClass: 'cp left' },
      { headerName: '종료일시', field: 'expiration_date', width: 150, cellClass: 'cp right' },
      { headerName: '상태', field: 'is_use', width: 83, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.is_use == '0' ) {
            return '<span class="badge badge-success f12">미사용</span>';
          } else if( params.data.is_use == '1' ) {
            return '<span class="badge badge-primary f12">사용</span>';
          } else {
            return '<span class="badge badge-danger f12">폐기</span>';
          }
        }
      },     
      { headerName: '지급주문', field: 'give_order_seq', width: 110, cellClass: 'cp left' },
      { headerName: '사용주문', field: 'use_order_seq', width: 110, cellClass: 'cp left' },
      { headerName: '사유', field: 'reason', width: 190, cellClass: 'cp left' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true
    };

    this.rowSelection = 'single';
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  getRowHeight = () => 380;

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getMemberCouponWidget()
    this.getCouponList();

    
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridDetailReady(params) {
    this.gridDetailApi = params.api;
    this.gridDetailColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridMemoReady(params) {
    this.gridMemoApi = params.api;
    this.gridMemoColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked(event: any) {
      this.getHistoryList(event.data.seq,'');
      this.getMemberCouponWidget();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      use_yn:'',
    };

    this.search_detail = {
      coupon_seq :'',
      is_use: '',      
    };

    this.getCouponList();
  }

  getCouponList() {
    this.memberService.getCouponList({...this.search}).then( response => {
      if( response.ResultCode ) {
        this.couponList = response.data;
        // 데이터가 있으면 첫번째 열의 디테일을 뿌린다. 
        if (this.couponList.length > 0 ) {

        // 리스트 첫번째 행 선택
          setTimeout( () => {
            var index = 0;
            this.gridApi.forEachNode( (node) => {
              if( this.selectedSeq == null && index == 0 ) {
                node.setSelected(true);
                this.selectedSeq = node.data.seq;
                this.gridApi.ensureIndexVisible( index, 'top' );

              } else if( this.selectedSeq !== null && node.data.seq == this.selectedSeq ) {
                node.setSelected(true);
                this.selectedSeq = node.data.seq;
                this.gridApi.ensureIndexVisible( index, 'middle' );
              }
              index++;
            });

            this.getHistoryList(this.couponList[0].seq, ''); 
          }, 100);
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 회원쿠폰리스트 조회 
    입력값 : seq :any , is_use:any
    리턴값 : 없음
  *******************************************************************************/
  
  getHistoryList(seq :any , is_use:any) {
      this.memberService.getCouponMemberList(seq , is_use).then( response => {
        if( response.ResultCode ) {
          this.historyList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCouponList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 조건 버튼 클릭 시
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  setSearchValue( field: any, value: any ) {
    this.search[ field ] = value;

    this.societySelected = {};

    this.getCouponList();
  }

    /*******************************************************************************
    설  명 : 검색 조건 버튼 클릭 시
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
    setSearchDetailValue( field: any, value: any ) {

      this.search_detail[ field ] = value;

      let is_use = value;
      let nodes = this.gridApi.getSelectedRows();
      let coupon_seq = nodes[0].seq;
  
      this.getHistoryList(coupon_seq , is_use);
    }

  /*******************************************************************************
    설  명 : 삭제 처리
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  delContact() {
    if( this.societySelected === undefined || this.societySelected.seq === undefined ) {
      this.toastrService.error('삭제할 내역을 선택하시기 바랍니다.', '삭제');
    } else {

      if( !confirm("선택한 내역을 삭제하시겠습니까?") ) return;

      let params: any = {
        seq: this.societySelected.seq
      };

      this.companyService.delContact(params).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.societySelected = {};

          this.getCouponList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 셀 에디트 변경 시
  *******************************************************************************/
  onCellEditingStopped(event: any) {
    if( event.data.memo !== undefined ) {
      if( this.societySelected.memo != event.data.memo ) {
        this.companyService.setContactMemo( event.data ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.getCouponList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }


  /*******************************************************************************
    설  명 : 동호회이력 추가
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  addSocietyHistory(seq: any= null) {

    let nodes = this.gridApi.getSelectedRows();
    if( nodes.length < 1 ) {
      this.toastrService.info( '이력을 등록할 동호회를 선택해 주세요.', '');
    } else {
    const modalRef = this.modalService.open(MemberSocietyHistoryAddComponent, options);
    modalRef.componentInstance.societySeq = nodes[0].seq;
    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      if( result ) {
        this.getHistoryList(nodes[0].seq , '');

        this.getMemberCouponWidget();

      }
    }, (reason) => {
    });
    }
  }  

  /*******************************************************************************
    설  명 : 바마쿠폰 위젯 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getMemberCouponWidget() {
      this.memberService.getMemberCouponWidget().then( response => {
        if( response.ResultCode ) {
          this.statistic.totalCoupon = response.data.totalCoupon;
          this.statistic.useCoupon  = response.data.useCoupon;
          this.statistic.unuseCoupon  = response.data.unuseCoupon;
          this.statistic.discardCoupon  = response.data.discardCoupon;
        } else {
          this.toastrService.error( response.ResultMessage, '');
          this.statistic.totalCoupon = "0";
          this.statistic.useCoupon  = "0";
          this.statistic.unuseCoupon  = "0";
          this.statistic.discardCoupon  = "0";          
        }
      }, error => {
        this.toastrService.error( error, '');
        this.statistic.totalCoupon = "0";
        this.statistic.useCoupon  = "0";
        this.statistic.unuseCoupon  = "0";
        this.statistic.discardCoupon  = "0";           
      });
    }  

/*******************************************************************************
    설  명 : ag grid 행 더블 클릭 시 처리 - 동호회 정보수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellClicked($event) {
      if( $event.colDef.field === 'give_order_seq' ) {
        this.goDetail( $event.data.give_order_seq );
      }
      if( $event.colDef.field === 'use_order_seq' ) {
        this.goDetail( $event.data.use_order_seq );
      }      
    }

  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    goDetail( seq: any ) {
      const url = '/order/detail?seq=' + seq;
      window.open("about:blank").location.href = url;
    }    

}
