import { Component, OnInit, Injectable, } from '@angular/core';
import { NgbInputDatepicker ,NgbModal, NgbDateStruct, NgbModalOptions, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { DocumentReceiveAddComponent } from '@page/approval/document-receive/document-receive-add/document-receive-add.component';
import { ApprovalDocumentService } from '@app/service/approval-documents.service';
import { ToastrService } from 'ngx-toastr';
import { config } from '@app/service/config.service';
import * as moment from 'moment';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  fromModel(value: string | null): NgbDateStruct | null {
    const date = value ? value.split('-') : null;
    return date ? { year: parseInt( date[0], 10 ), month: parseInt( date[1], 10 ), day: parseInt( date[2], 10 ) } : null;
  }

  toModel(value: NgbDateStruct | null): string | null {
    return value ?
      value.year.toString() + '-' + (value.month).toString().padStart(2, '0') + '-' + value.day.toString().padStart(2, '0') : null;
  }
}

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-document-receive',
  templateUrl: './document-receive.component.html',
  styleUrls: ['./document-receive.component.scss'],
  providers: [ {provide: NgbDateAdapter, useClass: CustomAdapter} ],
})
export class DocumentReceiveComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public baseURL = config.baseUrl;

  public rowData: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  public receiveList: any = [];

  public search: any = {
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().format('YYYY-MM-DD'),
    outgoing: '',
    title: ''
  };


  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private approvalDocService: ApprovalDocumentService,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '번호', field: 'seq', width: 50, cellClass: 'cp center' },
      { headerName: '접수일자', field: 'receiving_date', width: 100, cellClass: 'cp'},
      { headerName: '발신처', field: 'outgoing_company', width: 250, cellClass: 'cp' },
      { headerName: '문서번호', field: 'document_no', width: 200, cellClass: 'cp' },
      { headerName: '제목', field: 'title', width: 380, cellClass: 'cp' },
      { headerName: '담당자', field: 'name', width: 150, cellClass: 'cp' },
      { headerName: '첨부파일', field: 'path', width: 350, cellClass: 'cp',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'approval_download'
        }
      },
      { headerName: '수정', field: 'modify', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<button type="button" class="btn btn-default btn-grid2 mr5"><i class="material-icons menu-icon fl micon-create f12 ml3">&#xe150;</i></button>`
        }
      },
      { headerName: '삭제', field: 'delete', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<button type="button" class="btn btn-default btn-grid2 mr5"><i class="material-icons menu-icon fl micon-create f12 ml3">&#xe5cd;</i></button>`;
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  ngOnInit(): void {
    this.getReceiveList();
  }


  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };


  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {

    // 수정 시
    if( $event.colDef.field === 'modify' ) {
      this.addDocument( $event.data );
    }

    // 삭제 시
    if ($event.colDef.field === 'delete') {
      if( confirm("선택하신 항목을 삭제하시겠습니까?") ) {
        this.approvalDocService.setDeleteDocumentReceive( $event.node.data.uid ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( '공문서접수대장을 성공적으로 삭제하였습니다.', '');
            this.getReceiveList();
          } else {
            this.toastrService.error( '공문서접수대장을 삭제하는데 실패하였습니다.', '');
          }
      });
      }
    }

  }


  /*******************************************************************************
    설  명 : 공문서접수 등록
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  addDocument( item: any ) {

    const modalRef = this.modalService.open(DocumentReceiveAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      if( result ) {
        this.getReceiveList();
      }
    }, (reason) => {
    });
  }


  /*******************************************************************************
    설  명 : 공문서접수 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getReceiveList() {
    this.approvalDocService.getReceiveList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.receiveList = response.data;
      } else {
        this.receiveList = [];
      }
    });
  }


  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = moment().format('YYYY-MM-DD');
    } else {
      this.search.edate = moment().format('YYYY-MM-DD');
    }
    obj.close();

    }


  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      sdate: moment().format('YYYY-MM-01'),
      edate: moment().format('YYYY-MM-DD'),
      outgoing: '',
      title: ''
    };
    this.getReceiveList();
  }


  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
    searchList( event ) {
      if( event.key == 'Enter' ) {
        this.getReceiveList();
      }
    }


}
