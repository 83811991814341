import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-order-delivery',
  templateUrl: './order-delivery.component.html',
  styleUrls: ['./order-delivery.component.scss']
})
export class OrderDeliveryComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    sdate: '',
    edate: '',
    odate: '',
    state: '',
    state1: '',
    state2: ''
  };

  public orderList: any = [];
  public orderDetailList: any = [];
  public orderDetail1: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  detailGridApi: any;
  detailGridColumnApi: any;
  columnDefsDetail: any;

  detail1GridApi: any;
  detail1GridColumnApi: any;
  columnDefsDetail1: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private utilService: UtilService,
    private orderService: OrderService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '주문번호', field: 'seq', width: 90, cellClass: 'cp center' },
      { headerName: '주문일자', field: 'order_date', width: 150, cellClass: 'cp center' },
      { headerName: '주문자', field: 'o_name', width: 130, cellClass: 'cp' },
      { headerName: '출고지시수량', field: 'total_qty', width: 100, cellClass: 'cp center' }
    ];

    this.columnDefsDetail = [
      { headerName: '상품구분', field: '', width: 90, cellClass: 'cp center' },
      { headerName: '모델명', field: 'product_name', width: 280, cellClass: 'cp' },
      { headerName: '색상', field: 'color', cellClass: 'cp center', width: 50, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<span style="display:inline-block;margin-top:6px;width:16px;height:16px;border:solid 1px #ccc;background-color:${params.data.color}">&nbsp;</span>`;
        }
      },
      { headerName: '색상명', field: 'color_name', width: 100, cellClass: 'cp center',
        valueGetter: function(params) {
          return params.data.color_name + ( params.data.display_color_name ? '<br/>(' + params.data.display_color_name + ')' : '' );
        }
      },
      { headerName: '사이즈', field: 'size_name', width: 100, cellClass: 'cp center' },
      { headerName: '재고수량', field: 'stock_qty', width: 100, cellClass: 'cp right' },
      { headerName: '주문수량', field: 'qty', width: 100, cellClass: 'cp right' },
      { headerName: '출고수량', field: 'delivery_qty', width: 100, cellClass: 'cp right' },
      { headerName: '미출고수량', field: 'not_delivery_qty', width: 100, cellClass: 'cp right' },
      { headerName: '출고지시수량', field: 'order_delivery_qty', width: 100, cellClass: 'cp right' }
    ];

    this.columnDefsDetail1 = [
      {
        headerName: '',
        field: '',
        width: 40,
        cellClass: 'cp center',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      { headerName: '주문번호', field: '', width: 100, cellClass: 'cp' },
      { headerName: '출고지시일자', field: '', width: 100, cellClass: 'cp' },
      { headerName: '모델명', field: '', width: 200, cellClass: 'cp' },
      { headerName: '사이즈', field: '', width: 150, cellClass: 'cp' },
      { headerName: '칼라', field: '', width: 150, cellClass: 'cp' },
      { headerName: '지시수량', field: '', width: 100, cellClass: 'cp' },
      { headerName: '출고수량', field: '', width: 100, cellClass: 'cp' },
      { headerName: '상태', field: 'status', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status == '0' ) {
            return '<span class="badge badge-success f12" style="width:58px">출고지시</span>';
          } else if ( params.data.status == '1' ) {
            return '<span class="badge badge-primary f12" style="width:58px">출고</span>';
          } else if ( params.data.status == '2' ) {
            return '<span class="badge badge-danger f12" style="width:58px">취소</span>';
          }
        }
      },
      { headerName: '택배사', field: '', width: 150, cellClass: 'cp' },
      { headerName: '운송장번호', field: '', width: 150, cellClass: 'cp' }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 출고지시 주문 리스트 가져오기
    this.getOrderDeliveryList();
  }

  /*******************************************************************************
    설  명 : 출고지시 주문 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderDeliveryList() {
    this.orderService.getOrderDeliveryList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.orderList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 출고지시 주문 상세 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderDeliveryDetailList( seq: number ) {
    this.orderService.getOrderDeliveryDetailList( seq ).then( response => {
      if ( response.ResultCode ) {
        this.orderDetailList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked( event: any ) {
    this.getOrderDeliveryDetailList( event.data.seq );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onDetailGridReady(params) {
    this.detailGridApi = params.api;
    this.detailGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

}
