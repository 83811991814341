<div class="modal-header">
  <h4 class="modal-title fb mb0">동호회 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <table class="table table-bordered table-small1 mb0">
    <caption class="none">동호회정보</caption>
    <colgroup>
      <col style="width:15%;" />
      <col style="width:35%;" />
      <col style="width:15%;" />
      <col style="width:35%;" />
    </colgroup>

    <tbody>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="society_name">동호회명</label></th>
        <td colspan="3">
          <inputEx type="text" name="seq" [formGroup]="form" [formErrors]="formErrors"  hidden="true"></inputEx>
          <inputEx type="text" name="society_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>      
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="rep_mem_no">대표자회원번호</label></th>
        <td>
          <inputEx type="text" name="rep_mem_no" [formGroup]="form" [formErrors]="formErrors" [addOn]="searchMemberFunc" addOnText="회원 검색"></inputEx>
        </td>
        <th scope="row"><label for="searchName">대표자명</label></th>
        <td>
          <inputEx type="text" name="searchName" [formGroup]="form" [formErrors]="formErrors" placeholder="성명을 입력하고 검색하세요."></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i><label for="application_date">신청일자</label></th>
        <td>
          <inputEx type="date" name="application_date" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx>
        </td>
        <th scope="row"><label for="is_approval">승인여부</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_approval.value=='1'}" (click)="form.patchValue({is_approval: '1'});changeApproval()">승인</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.is_approval.value=='0'}" (click)="form.patchValue({is_approval: '0'});changeApproval()">미승인</button>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="approval_date">승인일자</label></th>
        <td>
          <inputEx type="date" name="approval_date" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx>
        </td>
        <th scope="row"><label for="memberCount">회원수</label></th>
        <td>
          <inputEx type="text" name="memberCount" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="region_cd">지역</label></th>
        <td>
          <inputEx type="select" name="region_cd" [formGroup]="form" [formErrors]="formErrors" [data]="regionList" (change)="changeRegion()"></inputEx>
        </td>
        <th scope="row"><label for=""></label></th>
        <td>
        </td>

      </tr>
      <tr>
      <th scope="row"><label for="society_link">커뮤니티링크</label></th>
      <td colspan="3">
        <inputEx type="text" name="society_link" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="society_description">동호회설명</label></th>
      <td colspan="3">
        <inputEx type="textarea" name="society_description" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
      <ng-container *ngIf="form.controls.seq.value">
      <tr>
        <th scope="row"><label for="society_description">엠블럼</label></th>
        <td  colspan="3">
          <ng-container *ngIf="form.controls.file_count.value < 1">
            <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
          </ng-container>
          <div class="progress mt5 mb5">
            <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
          </div>
          <div class="mt5">
          ※ 파일별 10MB 까지, 엠블럼1개만 등록, 전체 첨부용량 10MB 제한, 가로*세로 : 600*600픽셀 (필히 등록)
          </div>
          <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
            <ul class="file-ul">
              <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
                <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                  <div *ngIf="checkImage(getExt(file.origin_filename))" class="upload-image">
                    <img [src]="file.url" alt="{{file.origin_filename}}" style="max-width: 100%; width: 100%;" />
                  </div>
                  <div *ngIf="!checkImage(getExt(file.origin_filename))" class="upload-image1">{{getExt(file.origin_filename)}}</div>
                  <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin_filename}}">{{file.origin_filename}}</a></div>
                  <div class="upload-size">
                    ({{getComma(file.filesize)}} KB)
                    <a class="cp ml5" (click)="setDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                  </div>
                </div>
              </li>
              <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
                  <div *ngIf="checkImage(getExt(file.origin))" class="upload-image">
                    <img [src]="file.url" alt="{{file.origin}}" style="max-width: 100%; width: 100%;" />
                  </div>
                  <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                  <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                  <div class="upload-size">
                    ({{getComma(file.size)}} KB)
                    <a class="cp ml5" (click)="deleteFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                  </div>
                </div>
              </li>
              <li class="cb"></li>
            </ul>
          </div>
        </td>
      </tr>
      </ng-container>
    </tbody>
    </table>
  </form>
</div>

<div class="modal-footer">
  <div class="fl w50p">
    <button *ngIf="seq != '0'" type="button" class="btn btn-danger btn-small" (click)="delSociety()">삭제</button>
  </div>
  <div class="fr w50p right">
    <button type="button" class="btn btn-primary btn-small" (click)="submit()">저장</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
  </div>
</div>

