<section id="event-management">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">진행중<span class="cnt">{{statistic?.processCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">진행예정<span class="cnt">{{statistic?.standbyCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon5">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">종료<span class="cnt">{{statistic?.completeCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>


  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getBikeAdvertisementList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status==''}" (click)="search.status='';getBikeAdvertisementList()">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='1'}" (click)="search.status='1';getBikeAdvertisementList()">진행중</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='2'}" (click)="search.status='2';getBikeAdvertisementList()">진행예정</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.status=='0'}" (click)="search.status='0';getBikeAdvertisementList()">종료</button>
      </div>

      <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="광고명 검색" />

      <input type="text" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" size="12" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" readonly="readonly" id="edate" [(ngModel)]="search.edate" size="12" class="form-control form-control-small calendar-input m15" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>
    </div>
    <div class="fr w30p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="356b4905677546fab63231e874f99213" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="advertisementAdd()">광고 추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <as-split unit="percent" restrictMove="true" gutterSize="10">
    <as-split-area size="50" minSize="40" maxSize="60">
      <div style="height:calc(100% - 90px)">
        <ag-grid-angular
          #myGrid
    
          style="width: 100%;height:100%;"
          class="ag-theme-balham"
    
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="advertisementList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
          [rowDragManaged]="true"
          [enableMultiRowDragging]="true"
          [animateRows]="true"

          (gridReady)="onGridReady($event)"
          (rowClicked)="onRowClicked($event)"
          (rowDragEnd)="onRowDragEnd($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="50" minSize="40" maxSize="60">
      <form [formGroup]="form">

        <section id="customer-member-info">
          <div class="mt5">
            <div class="fl w50p">
              <h4 class="fb mt5 mb6">광고정보</h4>
            </div>
            <div class="fr w50p right">
              <button *ngIf="form.controls.seq.value != ''" type="button" class="btn btn-danger btn-small ml5" (click)="setBikeAdvertisementDelete()">삭제하기</button>
              <button type="button" class="btn btn-success btn-small ml5" (click)="setBikeAdvertisementSave()">저장하기</button>
            </div>
            <div class="cb"></div>
          </div>
        
          <table class="table table-bordered table-small1 mb0">
          <caption class="none">광고정보</caption>
          <colgroup>
            <col style="width:20%;" />
            <col style="width:80%;" />
          </colgroup>
        
          <tbody>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="advertisement_name">광고 제목</label></th>
            <td>
              <inputEx type="text" name="advertisement_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="advertisement_sdate">시작일시</label></th>
            <td><dateTimePicker name="advertisement_sdate" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="advertisement_edate">종료일시</label></th>
            <td><dateTimePicker name="advertisement_edate" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
          </tr>
          <tr>
            <th scope="row"><label for="advertisement_link">광고 링크</label></th>
            <td>
              <inputEx type="text" name="advertisement_link" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="advertisement_summary">광고 요약</label></th>
            <td>
              <inputEx type="text" name="advertisement_summary" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="advertisement_contents">광고 내용</label></th>
            <td>
              <inputEx type="summernote" name="advertisement_contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><label for="sort_order">정렬순서</label></th>
            <td>
              <inputEx type="text" name="sort_order" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr>
          </tbody>
          </table>
        </section>
        
        <section class="mt15">
          <div class="mb5">
            <div class="fl w50p fb pt5">광고 이미지</div>
            <div class="fr w50p right">
            </div>
            <div class="cb"></div>
          </div>
        
          <table class="table table-bordered table-small">
          <caption class="hidden">광고 이미지정보</caption>
        
          <colgroup>
            <col style="width:20%" />
            <col style="width:80%" />
          </colgroup>
        
          <tbody>
          <tr>
            <th scope="row" class="active"><i class="material-icons cred f11 lh20">star</i> <label for="file">이미지</label></th>
            <td>
              <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
              <div class="progress mt5 mb5">
                <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
              </div>
              <!-- <div class="mt5">
              ※ 파일별 50MB 까지, 최대 1개까지 첨부 가능, 전체 첨부용량 50MB 제한
              </div> -->
              <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
                <ul class="file-ul">
                  <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
                    <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                      <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="setUploadImageDefault(file)">
                        <img [src]="file.url" />
                      </div>
                      <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                      <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                      <div class="upload-size">
                        ({{getComma(file.size)}} KB)
                        <a class="cp ml5" (click)="setDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                      </div>
                    </div>
                  </li>
                  <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                    <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
                      <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="setFileImageDefault(file)">
                        <img [src]="file.url" />
                      </div>
                      <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                      <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                      <div class="upload-size">
                        ({{getComma(file.size)}} KB)
                        <a class="cp ml5" (click)="deleteFile( file, i )">
                          <img src="/assets/images/del_ico.png" />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="cb"></li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
          </table>
        </section>
        
      </form>
    </as-split-area>
  </as-split>
</section>
