import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { EventService } from '@app/service/event.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { ProductFindComponent } from '@components/product-find/product-find.component';

const optionsXG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-event-product',
  templateUrl: './event-product.component.html',
  styleUrls: ['./event-product.component.scss']
})
export class EventProductComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() eventSeq: any;

  public quickFilter: any;

  // 그리드 관련 선언
  productList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent,
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private eventService: EventService,
    private modalService: NgbModal,
    private agGridExComponent: AgGridExComponent
  ) {
    this.columnDefs = [
      { headerName: '이동', field: '', cellClass: 'center ag-cell80h', width: 50, suppressMenu: true,
        rowDrag(params: any) {
          if( params.data.seq === null ) {
            return false;
          } else {
            return true;
          }
        }
      },
      { headerName: '순서', field: 'sort_order', cellClass: 'cp center ag-cell80h ag-cell-edit', width: 70, editable: true,
        valueGetter: this.agGridExComponent.numberGetter
      },
      { headerName: '', field: '', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '상품번호', field: 'product_seq', width: 100, cellClass: 'cp center ag-cell80h' },
      // { headerName: '상품명', field: 'product_name', width: 800, cellClass: 'cp' },
      { headerName: '이미지', field: 'thumbnail_url', width: 150, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품정보', field: 'category_name', width: 1000, cellClass: 'ag-cell80h-br cell-wrap-text', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.category_name + '<br />[' + params.data.brand_name + ']<br />' + params.data.product_name;
        }
      },
      { headerName: '판매가격', field: 'sale_price', width: 120, cellClass: 'cp right ag-cell80h', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '구매가격', field: 'buy_price', width: 90, cellClass: 'cp right ag-cell80h', 
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: this.agGridExComponent.numberGetter
      },
      // { headerName: '할인가격', field: 'discount_amt', width: 90, cellClass: 'cp right ag-cell-edit', cellEditor: 'numericCellEditor', editable: true,
      //   valueFormatter: currencyFormatter,
      //   valueGetter: numberGetter
      // },
      // { headerName: '할인율', field: 'discount_rate', width: 90, cellClass: 'cp right ag-cell-edit', cellEditor: 'numericCellEditor', editable: true },
      // { headerName: '적립금', field: 'mileage_amt', width: 90, cellClass: 'cp right ag-cell-edit' , cellEditor: 'numericCellEditor', editable: true,
      //   valueFormatter: currencyFormatter,
      //   valueGetter: numberGetter
      // },
      // { headerName: '적립율', field: 'mileage_rate', width: 90, cellClass: 'cp right ag-cell-edit', cellEditor: 'numericCellEditor', editable: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };
  
  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getEventProductList();
  }

  /*******************************************************************************
    설  명 : 이벤트 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventProductList() {
    this.eventService.getEventProductList( {eventSeq: this.eventSeq} ).then( response => {
      if ( response.ResultCode ) {
        this.productList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEventProductDelete() {
    const params = {
      eventSeq: this.eventSeq,
      nodes: this.gridApi.getSelectedRows()
    };

    if ( params.nodes.length < 1 ) {
      this.toastrService.error( '삭제할 항목을 선택하세요', '' );
      return false;
    }

    this.eventService.setEventProductDelete( params ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getEventProductList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 상품 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEventProductSave() {
    const modalRef = this.modalService.open(ProductFindComponent, optionsXXL);

    modalRef.result.then((result) => {
      if ( typeof result !== 'undefined' ) {

        const newResult: any = [];
        result.forEach(obj => {
          const duplicate = this.productList.filter( (item) => {
            return item.product_seq === obj.seq;
          });

          if ( duplicate.length < 1 ) {
            newResult.push(obj.seq);
          }
        });

        const params = {
          eventSeq: this.eventSeq,
          nodes: JSON.stringify(newResult)
        };

        this.eventService.setEventProductSave( params ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getEventProductList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 상품 가격 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEventProductAmountSave() {
    const params = {
      eventSeq: this.eventSeq,
      nodes: this.gridApi.getSelectedRows()
    };

    if ( params.nodes.length < 1 ) {
      this.toastrService.error( '저장할 항목을 선택하세요', '' );
      return false;
    }

    this.eventService.setEventProductAmountSave( params ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getEventProductList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 상품 순서 저장
  *******************************************************************************/
  setEventProductSortSave() {
    const data: any = [];
    this.gridApi.forEachNodeAfterFilterAndSort(node => {
      if( node.data.seq !== null ) {
        data.push({
          seq: node.data.seq,
          sort_order: node.data.sort_order
        });
      }
    });

    if( data.length < 1 ) {
      this.toastrService.error( '저장할 상품리스트가 없습니다.', '');
    } else {
      if( confirm( '순서를 저장하시겠습니까?') ) {
        this.eventService.setEventProductSortSave( JSON.stringify(data) ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            // this.getEventSuperSaleProductList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApi.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화
  *******************************************************************************/
  searchInit() {
    this.quickFilter = '';

    this.gridApi.setQuickFilter(this.quickFilter);
  }
  
}
