import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { WorkmanagementWorkarragementAddComponent } from '@page/workmanagement/workmanagement-workarragement/workmanagement-workarragement-add/workmanagement-workarragement-add.component';
import { WorkArrangement } from '@app/service/work-arrangement.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-workmanagement-workarragement',
  templateUrl: './workmanagement-workarragement.component.html',
  styleUrls: ['./workmanagement-workarragement.component.scss'],
})
export class WorkmanagementWorkarragementComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/

  calendarList: any = [];

  calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  }

  workSheet = [];

  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private workArrangement: WorkArrangement,
  ) {
    this.setPrint(true);
  }

  ngOnInit(): void {
    this.initCalendar();

    this._getWorker();

  }

  /*******************************************************************************
    설  명 : 근무자 리스트 가져오기
  *******************************************************************************/
  _getWorker() {
    // seq, work_date, mem_no, writer
    this.workArrangement.getWorker({
      // work_date: moment().format('YYYY-MM-DD')
      work_date: ''
    }).then(response => {
      if(response.ResultCode) {
        this.workSheet = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 근무자 리스트 데이터 처리
  *******************************************************************************/
  _getDayWorker(month, day) {
    return this.workSheet.filter(e => 
      e['work_date'] == moment(this.calendarInfo.start_day).add(( month * 7 ) + day, 'day').format('YYYY-MM-DD')
    )//.map(e => e['name']);
  }

  /*******************************************************************************
    설  명 : 근무자 추가
  *******************************************************************************/
  _postWorker(work_date) {
    this.openModal(work_date, null);
  }

  /*******************************************************************************
    설  명 : 근무자 수정
  *******************************************************************************/
  _putWorker(seq) {
    this.openModal(null, seq);
  }

  /*******************************************************************************
    설  명 : 근무자 삭제
  *******************************************************************************/
  _deleteWorker(seq) {
    this.workArrangement.deleteWorker({
      seq: seq
    }).then(response => {
      if(response.ResultCode) this.toastrService.success( response.ResultMessage );
      this._getWorker();
    });
  }

  /*******************************************************************************
    설  명 : 달력 루프의 현재일 계산
    입력값 : i = 행, j = 열
    리턴값 : YYYY-MM-DD
  *******************************************************************************/
  getYMD = (i, j) => moment(this.calendarInfo.start_day).add( ( i * 7 ) + j, 'day').format('YYYY-MM-DD');

  /*******************************************************************************
    설  명 : 캘린더 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCalendar() {

  }  
  
  /*******************************************************************************
    설  명 : 달력 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initCalendar() {
    let sweek: any = moment(this.calendarInfo.sdate).isoWeekday();
    this.calendarInfo.start_day = moment(this.calendarInfo.sdate).add(sweek * -1, 'day').format('YYYY-MM-DD');

    let eweek: any = moment(this.calendarInfo.edate).isoWeekday();
    this.calendarInfo.end_day = moment(this.calendarInfo.edate).add(6 - eweek, 'day').format('YYYY-MM-DD');
  }

  /*******************************************************************************
    설  명 : 달력 루프
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  range( i : number ) {
    return Array(i);
  }

  /*******************************************************************************
    설  명 : 달력 루프의 현재일 계산
    입력값 : i = 행, j = 열
    리턴값 : 현재 일(월-일)
  *******************************************************************************/
  getCurrDay(i, j) {
    let term = ( i * 7 ) + j;
    let date: any = moment(this.calendarInfo.start_day).add(term, 'day').format('DD');
    if( date == '01' )
      date = moment(this.calendarInfo.start_day).add(term, 'day').format('MM-DD');

    return date;
  }

  /*******************************************************************************
    설  명 : Moment
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMoment( type: any ) {
    return moment().format( type );
  }

  /*******************************************************************************
    설  명 : 다음달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nextMonth() {
    this.calendarInfo.year = moment(this.calendarInfo.sdate).add(1, 'months').format('YYYY');
    this.calendarInfo.month = moment(this.calendarInfo.sdate).add(1, 'months').format('MM');
    
    let date = this.calendarInfo.year + '-' + this.calendarInfo.month;

    this.calendarInfo.sdate = moment( date ).format('YYYY-MM-01');
    this.calendarInfo.edate = moment( date ).add(1, 'month').date(0).format('YYYY-MM-DD');

    this.initCalendar();
    this.getCalendar();
  }

  /*******************************************************************************
    설  명 : 현재월
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  currMonth() {
    this.calendarInfo.year = moment().format('YYYY');
    this.calendarInfo.month = moment().format('MM');
    
    let date = this.calendarInfo.year + '-' + this.calendarInfo.month;

    this.calendarInfo.sdate = moment( date ).format('YYYY-MM-01');
    this.calendarInfo.edate = moment( date ).add(1, 'month').date(0).format('YYYY-MM-DD');

    this.initCalendar();
    this.getCalendar();
  }

  /*******************************************************************************
    설  명 : 이전달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  prevMonth() {
    this.calendarInfo.year = moment(this.calendarInfo.sdate).subtract(1, 'months').format('YYYY');
    this.calendarInfo.month = moment(this.calendarInfo.sdate).subtract(1, 'months').format('MM');
    
    let date = this.calendarInfo.year + '-' + this.calendarInfo.month;

    this.calendarInfo.sdate = moment( date ).format('YYYY-MM-01');
    this.calendarInfo.edate = moment( date ).add(1, 'month').date(0).format('YYYY-MM-DD');

    this.initCalendar();
    this.getCalendar();
  }
 
  /*******************************************************************************
    설  명 : 근무자추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openModal(day = 'YYYY-MM-DD', seq = '') {
    const modalRef = this.modalService.open(WorkmanagementWorkarragementAddComponent, options);
    modalRef.componentInstance.DAY = day;
    modalRef.componentInstance.SEQ = seq;

    modalRef.result.then(result => {
      this._getWorker();
    }, (reason) => {
    });

  }

  /*******************************************************************************
    설  명 : 월당직근무표 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printWorkArragement() {
    const div = document.createElement('div');
    div.style.cssText = 'width: 100%; height: 100%; position: absolute; z-index: 999; top: 0; left: 0; background-color: #fff;';
    div.appendChild(document.getElementById('member-company').cloneNode(true));
    div.innerHTML = div.innerHTML.replace(/<(button|i)[^>]+>[^<]+<[^>]+>/gi, '');
    document.body.prepend(div);
    window.onafterprint = () => document.body.removeChild(div);
    window.print();
    // let url = '/workmanagement/workarragement/print';
    // window.open(url, 'inspectFacilityPrintChecklist', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1122, height=793, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 프린트 설정
    입력값 : landscape(가로 출력 여부)
    리턴값 : 없음
  *******************************************************************************/
  setPrint(landscape: boolean) {
    const mode = landscape ? 'landscape' : 'portrait';
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';
    style.appendChild(document.createTextNode(`@page { size: ${mode}; }`));
    head.appendChild(style);
  }

}
