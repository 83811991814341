import { Component, OnInit } from '@angular/core';
import { NgbDateParserFormatter, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';

import { ApprovalTempComponent } from '@page/approval/approval-temp/approval-temp.component';
import { ApprovalLineAddComponent } from '@page/approval/approval-line/approval-line-add/approval-line-add.component';
import * as moment from 'moment';
import { Globals } from '@app/service/globals.service';
import { config } from '@app/service/config.service';
import { CommonService } from '@app/service/common.service';
import { ApprovalService } from '@app/service/approval.service';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { RestfulService } from '@app/service/restful.service';
import * as $ from 'jquery';
import { ApprovalNoteComponent } from './approval-note/approval-note.component';
import { ApprovalBusinessComponent } from './approval-business/approval-business.component';
import { ApprovalMeetingComponent } from './approval-meeting/approval-meeting.component';
import { ApprovalAnnualComponent } from './approval-annual/approval-annual.component';

const URL = config.apiFileUploadUrl;

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-600'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-admin modal-a830'
};

const options2: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-admin modal-a850'
};

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {

  public approvalList: any = [];
  public approvalStepList: any = [];
  public approvalTmp: any = [{title: '임시저장문서불러오기', value: ''}];

  public approvalTmpInfo: any;

  public approvalMember: any = [];
  public member = {
    seq: '',
    mem_no: '',
    username: '',
    dept_name: '',
    position: '',
    position_name: '',
    user_dept: ''
  };
  public seq = '';
  public routeParams = '';

  public approvalInfo: any = '1001';
  public type = '1';

  public form: FormGroup;
  public formErrors = {};

  public list = [];

  public businessList = {
    ymd: "",
    sdate: "",
    edate: "",
    dest: "",
    message: "",
    stime: "",
    etime: "",
    mem_no: [],
    nation_group: "",
    work_group: "",
    name: [],
    remk: "",
    service_name: "",
    subject_name: "",
    total_amt: "",
    fee_Detail: "",
    place_name: ""
  };

  public annualList = {
    type: '',
    sdateTime: '',
    edateTime: '',
    sdate: '',
    edate: '',
    stime: '',
    etime: '',
    dept_name: '',
    position: '',
    username: ''
  };

  public commodity = {
    detail_note: '',
    standard: '',
    qty: '',
    unit_price: '',
    amt: '',
    comment: '',
  }

  public uploader: FileUploader;

  public baseURL = config.baseUrl;

  public upLoadData: any = [];

  public summernoteConfig = {
    placeholder: '',
    tabsize: 2,
    height: 520,
    maxHeight: 580,
    //width: 450,
    uploadImagePath: '',
    toolbar: [
        // [groupName, [list of button]]
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'link', 'video', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      receive: ['', [Validators.maxLength(255)]],
      via: ['', [Validators.maxLength(255)]],
      title: ['기안서', [Validators.required]],
      subject: ['', [Validators.required, Validators.maxLength(255)]],
      add_note: ['', []] ,
      receiver: ['', [] ],
      documentNumber: ['', [] ],
      receipt_date: ['', [] ],
      enforcement_date: ['', [] ],
      document_type: ['', []],
      seq: ['', []],
      sort_order: ['', [] ],
      approval_type: ['ON', []],
      status: ['', []],
      contents: ['', [Validators.required]],
      approval: [[], [Validators.required]],
      agreement: [[], []],
      reference: [[], []],
      name: ['', []],
      file: ['', [] ],
      upload: [[], [] ],
      files: [[], [] ],
      sdate: ['', [] ],
      edate: ['', [] ],
      stime: ['', [] ],
      etime: ['', [] ],
      type: ['', [] ],
      username: [[], ],
      ymd: ['', [] ],
      service_name: ['', [] ],
      subject_name: ['', [] ],
      total_amt: ['', [] ],
      fee_Detail: ['', [] ],
      place_name: [[], ],
      detail_note: ['', [] ],
      standard: ['', [] ],
      qty: ['', [] ],
      unit_price: ['', [] ],
      amt: ['', [] ],
      comment: ['', [] ],
      reportTypeM: ['', [] ],
      reportTypeD: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  constructor(
    private modalService: NgbModal,
    private commonCodeService: CommonService,
    private approvalService: ApprovalService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private restful: RestfulService,
    private router: Router,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private globals: Globals
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( async params => {
      this.approvalInfo = ( typeof params.document_type == 'undefined' || params.document_type == '' ) ? '1001' : params.document_type,
      this.routeParams = ( typeof params.routeParams == 'undefined' || params.routeParams == '' ) ? '' : params.routeParams,
      this.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '1' : params.type,
      this.form.patchValue({
        document_type: ( typeof params.document_type == 'undefined' || params.document_type == '' ) ? '' : params.document_type,
        seq: ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq
      });
    });


    this.getCommonList();
    this.getApprovalStep();
    this.getApprovalTmpList();
    this.getApprovalInfo();
    this.getMemberApprovalInfo();

    if ( this.type == '1' ) {
      this.getModalOpen(this.approvalInfo);
      this.getContents(this.approvalInfo);  
    }

    var filetype: string[] = ['image', 'doc', 'pdf', 'xls', 'ppt'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        let files = this.form.controls.files.value;

        files.push({
          path: this.baseURL + response.url,
          upload_file: response.origin_filename,
          file_size: response.size,
          org_upload_file: response.filename
        });

        this.form.patchValue( {files: files});

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }


  getApprovalInfo() {
    if( this.form.value.seq != '' ) {
      // indicator 표시
      setTimeout (() => {
        this.globals.indicator.next(true);
      });

      this.approvalService.getApprovalInfo(this.form.value.seq).then(response => {
        if( response.ResultCode ) {
          this.form.patchValue(response.data);
          this.approvalMember = response.data;

          let approval = [];
          let agreement = [];

          if( this.type == '3' ) {
            for( let list of response.data.approval ) {
              list.add_note = '';
              approval.push(list);
              
              this.form.patchValue({
                approval: approval
              });
            }

            if( response.data.agreement ) {
              for( let list of response.data.agreement ) {
                list.add_note = '';
                agreement.push(list);

                this.form.patchValue({
                  agreement: agreement
                })
              }
            }
          }
        }
        this.globals.indicator.next(false);
      })
    }
  }
  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : code
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {

    // indicator 표시
    setTimeout (() => {
      this.globals.indicator.next(true);
    });

    this.commonCodeService.getCommonListCode('C14').then(response => {
      if ( response.ResultCode ) {
        this.approvalList = response.data;
      }

      this.globals.indicator.next(false);
    })
  }

  /*******************************************************************************
    설  명 : 결제단계 결재양식 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onValueSelectChange($event) {
    this.form.patchValue({
      document_type: $event.target.value
    });
  }

  /*******************************************************************************
    설  명 : 결재 임시저장 리스트 가져오기
    입력값 : code
    리턴값 : 없음
  *******************************************************************************/
  getApprovalTmpList() {
    this.approvalService.getApprovalTmpList().then(response => {
      if( response.ResultCode ) {
        for( let item of response.data ) {
          this.approvalTmp.push({
            title: `[${item.write_date}]${item.subject}`,
            value: item.seq
          })
        }
      } else {
        this.approvalTmp = [];
      }
    });
  }


  /*******************************************************************************
    설  명 : 결제단계 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getApprovalStep() {
    this.approvalService.getApprovalStep().then( response => {
      if( response.ResultCode ) {
          this.approvalStepList = response.data;
      } else {
        this.approvalStepList = [];
      }

    });
  }

  /*******************************************************************************
    설  명 : 선택된 임시 결제 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChange(e) {
    if( e.target.value != '' ) {
      if( window.confirm('작성중인 결재문서를 취소하고 임시저장된 문서를 불러오시겠습니까?') ) {
        this.approvalService.getDocumentTmp(e.target.value).then(response => {
          if( response.ResultCode ) {
            this.form.patchValue({
              receive: response.data.receive,
              via: response.data.via,
              subject: response.data.subject,
              contents: response.data.contents,
              document_type: response.data.documentType
            });
            this.approvalInfo = response.data.documentType;
            this.approvalMember = response.data;
          } 
        });
      } else {
        return false;
      }
    }
  }


  /*******************************************************************************
    설  명 : 결제문서 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onClickApproval(code) {
    if( window.confirm('현재 작성중인 결재문서를 취소하시고 새로 작성하시겠습니까?') ) {
      this.form.patchValue({
        subject: '',
        receive: '',
        via: ''
      });
      this.approvalInfo = code;
      this.approvalService.getApprovalStepDetail(this.seq).then(response => {
        if( response.ResultCode ) {
          this.approvalMember = response.data;
        }
      });
      this.getContents(code);
      this.getModalOpen(code);
    } else {
      return false;
    }
    
  }

  

  /*******************************************************************************
    설  명 : 결제문서 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getContents(code) {
    let contents1016 = `
      <div class="ml10" margin-bottom: 10px; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 24px;">
        <span style="line-height: 2.5;">기안일자 :</span><br>
        <span style="line-height: 2.5;">(연구용역명 : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span><br>
        <span>건명 :</span><br><br>
        <span style="line-height: 2.5;">금액 :</span><br>
        <span style="line-height: 2.5;">지출사항 :</span><br>
      </div>
    `

    let contents1013 = `
      <ol class="ml50" style="letter-spacing: -0.5px; margin-bottom: 10px; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 20px;">
        <li style="letter-spacing: -0.5px; line-height: 1.4;">회의명 :</li>
        <li style="letter-spacing: -0.5px; line-height: 1.4;">일　시 :</li>
        <li style="letter-spacing: -0.5px; line-height: 1.4;">장　소 :</li>
      </ol>
      <span style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 20px; letter-spacing: -0.5px;">* 붙임 :</span>
    `

    let contents1007 = `
      <span style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 20px; letter-spacing: -0.5px;">
        아래와 같이 청구하오니 결재하여 주시기 바랍니다.
      </span>
      <br>
      <br>
      <b style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 20px; letter-spacing: -0.5px;">※ 청구내용 :</b>
      <br>
      <br>
      <table id="table_id">
    <colgroup>
      <col style="width:25%">
      <col style="width:15%">
      <col style="width:15%">
      <col style="width:15%">
      <col style="width:15%">
      <col style="width:15%">
    </colgroup>
    
    <thead>
    <tr>
      <th class="bg-color" scope="col">내용</th>
      <th class="bg-color" scope="col">규격</th>
      <th class="bg-color" scope="col">수량</th>
      <th class="bg-color" scope="col">단가(원)</th>
      <th class="bg-color" scope="col">금액(원)</th>
      <th class="bg-color" scope="col">비고</th>
    </tr>
    </thead>
    
    <tbody>
    <tr id="tr1">
      <td class='td'></td>
      <td class='td'></td>
      <td class='td'></td>
      <td class='td'></td>
      <td class='td'></td>
      <td class='td'></td>
    </tr>
    <tr>
      <td class='td' colspan="2" >합계</td>
      <td class='td'></td>
      <td class='td'></td>
      <td class='td'></td>
      <td class='td'></td>
    </tr>
    </tbody>
    </table>
    <br>
      끝.
    `

    let contents1002 = `
      <span style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 20px; letter-spacing: -0.5px;">
        아래와 같이 출장 계획을 상신하오니 승인하여 주시기 바랍니다.
      </span>
      <br>
      <br>
      <table class="list-table-business">
        <colgroup>
          <col style="width:20%">
          <col style="width:10%">
          <col style="width:30%">
          <col style="width:10%">
          <col style="width:30%">
        </colgroup>
        
        <tbody>
        <tr>
          <td colspan="5" class="center bold">출장명령사항</td>
        </tr>
        <tr id="tr1">
          <td>기안일자 : <span class="order_date">${this.businessList.ymd}</span></td>
          <td scope="row" class="center">장소</td>
          <td colspan="3" class="left d1002-dest">${this.businessList.place_name}</td>
        </tr>
        <tr id="tr2">
          <td scope="row" rowspan="5" class="center">출장자 <span class="d1002-inwon">${this.businessList.mem_no.length}</span>인</td>
          <td class="center">연구용역명</td>
          <td colspan="3" class="left">${this.businessList.service_name}</td>
        </tr>
        <tr id="tr3">
          <td class="center">건명</td>
          <td colspan="3" class="left d1002-sdate">${this.businessList.subject_name}</td>
        </tr>
        <tr id="tr4">
          <td class="center">금액</td>
          <td colspan="3" class="left d1002-sdate">${this.businessList.total_amt}</td>
        </tr>
        <tr id="tr5">
          <td class="center">시작일</td>
          <td class="left d1002-sdate">${this.businessList.sdate}</td>
          <td class="center">종료일</td>
          <td class="left d1002-edate">${this.businessList.edate}</td>
        </tr>
        <tr id="tr7">
          <td class="center">출장자</td>
          <td colspan="4" class="left d1002-comment">
          ${this.businessList.name}
          </td>
        </tr>
        <tr>
          <td colspan="5" class="center">비용상세내역</td>
        </tr>
        <tr id="tr8">
          <td colspan="5" style="height:200px;" class="left pl20 d1002-remk">
            ${this.businessList.fee_Detail}
          </td>
        </tr>
        </tbody>
        </table>
        끝.
      `
    let contents1008 = `
    <span style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 20px; letter-spacing: -0.5px;">
      아래와 같이 연차/휴가를 상신하오니 결재하여 주시기 바랍니다.
    </span>
      <br>
      <br>
      <table class="list-table radio">
      <colgroup>
        <col style="width:15%">
        <col style="width:35%">
        <col style="width:15%">
        <col style="width:35%">
      </colgroup>

      <tbody>
      <tr id="annual1">
        <th class="bg-color" scope="col">구분</th>
        <td class='td'>
          <input type="radio" name="d1008-type" id="d1008-type10" value="10" ${this.annualList.type == '10' ? `checked` : ``}><label for="d1008-type10">연차</label>
          <input type="radio" name="d1008-type" id="d1008-type20" value="20" ${this.annualList.type == '20' ? `checked` : ``}><label for="d1008-type20">반차</label>
          <input type="radio" name="d1008-type" id="d1008-type60" value="60" ${this.annualList.type == '60' ? `checked` : ``}><label for="d1008-type60">경조</label>
          <input type="radio" name="d1008-type" id="d1008-type70" value="70" ${this.annualList.type == '70' ? `checked` : ``}><label for="d1008-type70">병가</label>
          <input type="radio" name="d1008-type" id="d1008-type80" value="80" ${this.annualList.type == '80' ? `checked` : ``}><label for="d1008-type80">외출</label>
        </td>
        <th class="bg-color" scope="col">부서</th>
        <td class='td'>${this.annualList.dept_name}</td>
      </tr>
      <tr id="annual2">
        <th class="bg-color" scope="col">직위</th>
        <td class='td'>${this.annualList.position}</td>
        <th class="bg-color"" scope="col">성명</th>
        <td class='td'>${this.annualList.username}</td>
      </tr>
      <tr id="annual3">
        <th class="bg-color" scope="col">시작일시</th>
        <td class='td'>${this.annualList.sdateTime}</td>
        <th class="bg-color" scope="col">종료일시</th>
        <td class='td'>${this.annualList.edateTime}</td>
      </tr>
      <tr>
        <th class="bg-color" scope="col">사유</th>
        <td class='td' colspan="3"></td>
      </tr>
      </tbody>
      </table>
      <br class="ng-scope">
      끝.
    `

    let contents1015 = `
    <span style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 20px; letter-spacing: -0.5px;">
      아래와 같이 회의실 사용 신청서를 상신하오니 결재하여 주시기 바랍니다.
    </span>
    <br>
    <br>
    <ol class="ml50" style="letter-spacing: -0.5px; margin-bottom: 10px; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 20px;">
      <li style="letter-spacing: -0.5px; line-height: 1.4;">
        회의제목 :
      </li>
      <li style="letter-spacing: -0.5px; line-height: 1.4;">
        회 의 실 :
      </li>
      <li style="letter-spacing: -0.5px; line-height: 1.4;">
        일　 시 :
      </li>
      <li style="letter-spacing: -0.5px; line-height: 1.4;">
        참석인원 :
      </li>
    </ol>
    `

    // this.commodity.detail_note = $('#tr1').find('td:eq(0)').text();
    // this.commodity.standard = $('#tr1').find('td:eq(1)').text();
    // this.commodity.qty = $('#tr1').find('td:eq(2)').text();
    // this.commodity.unit_price = $('#tr1').find('td:eq(2)').text();
    // this.commodity.amt = $('#tr1').find('td:eq(2)').text();
    // this.commodity.comment = $('#tr1').find('td:eq(2)').text();

    this.businessList.place_name = $('#tr1').find('td:eq(2)').text();
    this.businessList.service_name = $('#tr2').find('td:eq(2)').text();
    this.businessList.subject_name = $('#tr3').find('td:eq(1)').text();
    this.businessList.total_amt = $('#tr4').find('td:eq(1)').text();
    this.businessList.sdate = $('#tr5').find('td:eq(1)').text();
    this.businessList.edate = $('#tr5').find('td:eq(3)').text();
    let name = $('#tr7').find('td:eq(1)').text().trim();
    this.businessList.fee_Detail = $('#tr8').find('td:eq(0)').text();

    let arr = [];
    arr = name.split(',');

    this.businessList.name = arr;

    let type = null;
    type = $('#annual1').find('input[type="radio"]:checked').val();
    this.annualList.dept_name = $('#annual1').find('td:eq(1)').text();
    this.annualList.username = $('#annual2').find('td:eq(1)').text();
    this.annualList.sdateTime = $('#annual3').find('td:eq(0)').text();
    this.annualList.edateTime = $('#annual3').find('td:eq(1)').text();
    this.annualList.sdate = moment(this.annualList.sdateTime).format('YYYY-MM-DD');
    this.annualList.edate = moment(this.annualList.edateTime).format('YYYY-MM-DD');
    this.annualList.stime = moment(this.annualList.sdateTime).format('HH:mm');
    this.annualList.etime = moment(this.annualList.edateTime).format('HH:mm');

    if( type == '1' ) {
      this.annualList.type = '10';
    } else if( type == '2' ) {
      this.annualList.type = '20';
    } else if( type == '3' ) {
      this.annualList.type = '60';
    } else if( type == '4' ) {
      this.annualList.type = '70';
    } else if ( type == '5' ) {
      this.annualList.type = '80';
    }

    switch( code ) {
      case '1001': this.form.patchValue({title: '기안서', contents: ''}); break;
      case '1002': this.form.patchValue({title: '출장결의서', contents: contents1002}); break;
      case '1007': this.form.patchValue({title: '물품자금 청구서', contents: contents1007}); break;
      case '1008': this.form.patchValue({title: '연차/휴가 신청서', contents: contents1008}); break;
      case '1013': this.form.patchValue({title: '외부회의록', contents: contents1013}); break;
      case '1014': this.form.patchValue({title: '업무성과/계획보고', contents: ''}); break;
      case '1015': this.form.patchValue({title: '회의실 사용신청서', contents: contents1015}); break;
      case '1016': this.form.patchValue({title: '지출결의서', contents: contents1016}); break;
      default: return false;
    }
  }

  /*******************************************************************************
    설  명 : 결제문서 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getModalOpen(code) {
    switch( code ) {
      case '1001': this.form.patchValue({title: '기안서'}); break;
      case '1002': this.form.patchValue({title: '출장결의서'}), this.openBusinessList(); break;
      case '1007': this.form.patchValue({title: '물품자금 청구서'}); break;
      case '1008': this.form.patchValue({title: '연차/휴가 신청서'}), this.openAnnualList(); break;
      case '1013': this.form.patchValue({title: '외부회의록'}); break;
      case '1014': this.form.patchValue({title: '업무성과/계획보고'}); break;
      case '1015': this.form.patchValue({title: '회의실 사용신청서'}); break;
      case '1016': this.form.patchValue({title: '지출결의서'}); break;
      default: return false;
  }
}

  
  /*******************************************************************************
    설  명 : 결재단계 설정 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addApprovalStep(seq) {
    this.seq = seq;
    if( window.confirm('선택하신 결재단계로 재설정 하시겠습니까?') ) {
      this.approvalService.getApprovalStepDetail(this.seq).then(response => {
        if( response.ResultCode ) {
          this.approvalMember = response.data;
        }
      });
    } else {
      return false;
    }
  }

  approvalTmpSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    this.form.patchValue({
      document_type: this.approvalInfo,
      approval: this.approvalMember.approval,
      agreement: this.approvalMember.agreement,
      reference: this.approvalMember.reference
    });

    if( this.form.valid ) {
      this.approvalService.approvalTmpSave(this.form).then(response => {
        if(response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '저장하였습니다.');
          this.getApprovalTmpList();
        } else {
          this.toastrService.error( response.ResultMessage, '저장 오류');
        }
      })
    }

  }

  getMemberApprovalInfo() {
    let tmp = [];
    this.approvalService.getMemberApprovalInfo().then(response => {
      if( response.ResultCode ) {
        tmp.push({
          seq: response.data.uid,
          mem_no: response.data.mem_no,
          username: response.data.username,
          dept_name: response.data.dept_name,
          position: response.data.position,
          position_name: response.data.position,
          user_dept: response.data.name
        })

        this.member = tmp[0];
        this.approvalMember.approval = tmp;
      }
    });
  }

  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    this.getContents('');

    let sdate = '';
    let edate = '';
    let stime = '';
    let etime = '';

    if( this.approvalInfo == '1008' ) {
      sdate = this.annualList.sdate;
      edate = this.annualList.edate;
      stime = this.annualList.stime;
      etime = this.annualList.etime;
    } else if( this.approvalInfo == '1002' ) {
      sdate = this.businessList.sdate;
      edate = this.businessList.edate;
    }

    let commentArr = [];

    for( let list of this.approvalMember.approval ) {
      commentArr.push(list.user_dept);
    }

    this.form.patchValue({
      status: 'P',
      document_type: this.approvalInfo,
      approval: this.approvalMember.approval,
      agreement: this.approvalMember.agreement,
      reference: this.approvalMember.reference,
      sdate: sdate,
      edate: edate,
      stime: stime,
      etime: etime,
      type: this.annualList.type,
      username: this.businessList.name,
      ymd: this.businessList.ymd,
      place_name: this.businessList.place_name,
      service_name: this.businessList.service_name,
      subject_name: this.businessList.subject_name,
      total_amt: this.businessList.total_amt,
      fee_Detail: this.businessList.fee_Detail,
      comment: commentArr.join(',')
    });

    if( this.approvalMember.approval.length <= 1 ) {
      this.toastrService.error('결재자를 확인해주세요.', '결재자 확인');
      return false;
    }

    if( this.form.valid ) {
      const modalRef = this.modalService.open(ApprovalNoteComponent, options);

      modalRef.componentInstance.subject = this.form.value.subject;
      modalRef.componentInstance.title = '결재상신';

      modalRef.result.then((result) => {
        if ( result ) {
          this.form.patchValue({
            add_note: result.add_note,
          });

          this.approvalService.approvalSave(this.form).then(response => {
            if(response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '저장하였습니다.');
              this.router.navigate(
                ['/approval/list'],
                {
                  relativeTo: this.activatedRoute,
                  queryParamsHandling: '', // remove to replace all query params by provided
                });
            } else {
              this.toastrService.error( response.ResultMessage, '저장 오류');
            }
          })
        }
      }, (reason) => {
      });

      
    } else {
      this.toastrService.error('필수 입력을 확인해 주세요', '결재문서작성');
    }

  }

  updateApproval() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    this.getContents('');

    let sdate = '';
    let edate = '';
    let stime = '';
    let etime = '';

    if( this.approvalInfo == '1008' ) {
      sdate = this.annualList.sdate;
      edate = this.annualList.edate;
      stime = this.annualList.stime;
      etime = this.annualList.etime;
    } else if( this.approvalInfo == '1002' ) {
      sdate = this.businessList.sdate;
      edate = this.businessList.edate;
      stime = this.businessList.stime;
      etime = this.businessList.etime;
    }

    let commentArr = [];

    for( let list of this.approvalMember.approval ) {
      commentArr.push(list.user_dept);
    }

    this.form.patchValue({
      status: 'P',
      document_type: this.approvalInfo,
      approval: this.approvalMember.approval,
      agreement: this.approvalMember.agreement,
      reference: this.approvalMember.reference,
      sdate: sdate,
      edate: edate,
      stime: stime,
      etime: etime,
      type: this.annualList.type,
      username: this.businessList.name,
      ymd: this.businessList.ymd,
      place_name: this.businessList.place_name,
      service_name: this.businessList.service_name,
      subject_name: this.businessList.subject_name,
      total_amt: this.businessList.total_amt,
      fee_Detail: this.businessList.fee_Detail,
      comment: commentArr.join(','),
      // detail_note: this.commodity.detail_note,
      // standard: this.commodity.standard,
      // qty: this.commodity.qty,
      // unit_price: this.commodity.unit_price,
      // amt: this.commodity.amt,
      // comment: this.commodity.comment,
    });

    if( this.form.valid ) {
      this.approvalService.updateApproval(this.form).then(response => {
        if(response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '결재문서작성');
          this.router.navigate(
            ['/admin/approval/list'],
            {
              relativeTo: this.activatedRoute,
              queryParamsHandling: '', // remove to replace all query params by provided
            });
        } else {
          this.toastrService.error( response.ResultMessage, '결재문서작성');
        }
      })
    } else {
      this.toastrService.error('필수 입력을 확인해 주세요', '결재문서작성');
    }
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;


    // 전체 허용 용량 검증
    const files:File[] = event.target.files;
    const filteredFiles:File[] = [];
    for(const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }


  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }


  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 임시저장 결재문서관리
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  openTemp() {
    const modalRef = this.modalService.open(ApprovalTempComponent, options);

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 결재단계추가
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  addLine(type) {
    const modalRef = this.modalService.open(ApprovalLineAddComponent, options1);

    modalRef.componentInstance.type = type;
    modalRef.componentInstance.approvalMember = this.approvalMember;

    modalRef.result.then((result) => {
      if( typeof result == 'object' ) {
        this.approvalMember = result;

      } else if( typeof result == 'boolean' ) {
        this.getApprovalStep();
      }
    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 상세보기
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  openMeetingList() {
    const modalRef = this.modalService.open(ApprovalMeetingComponent, options);

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 상세보기
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  openBusinessList() {
    const modalRef = this.modalService.open(ApprovalBusinessComponent, options2);

    modalRef.result.then((result) => {
      
      if (result) {
        result.sdate = this.ngbDateParserFormatter.format( result.sdate );
        result.edate = this.ngbDateParserFormatter.format( result.edate );
        result.ymd = this.ngbDateParserFormatter.format( result.ymd );
        result.fee_Detail = result.fee_Detail.replace(/(?:\r\n|\r|\n)/g, '<br /><br />');
        this.businessList = result;
        this.getContents(this.approvalInfo);
      }
      
    }, (reason) => {
    });
  }

 

  /*******************************************************************************
    설  명 : 상세보기
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  openAnnualList() {
    const modalRef = this.modalService.open(ApprovalAnnualComponent, options);

    modalRef.result.then((result) => {
      this.annualList = result;
      this.getContents(this.approvalInfo);
    }, (reason) => {
    });
  }
}
