<section>
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="65" minSize="65" maxSize="65">
      <div class="mb5">
        <div class="fl w82p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getOrderReviewList()">
            <i class="material-icons-outlined">search</i>
          </button>

          <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
          <ng-template #footerTemplate1>
            <hr class="my-0">
            <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 'sdate')">오늘</button>
            <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
          </ng-template>
          <span class="ml5 mr5">~</span>
          <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
          <ng-template #footerTemplate2>
            <hr class="my-0">
            <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 'edate')">오늘</button>
            <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
          </ng-template>          

          <input type="text" size="40" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" placeholder="주문번호, 상품번호, 상품명 회원ID" (keypress)="searchList($event)" />

          <div class="btn-group ml5">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.best_yn==''}" (click)="setSearch('best_yn','')">전체</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.best_yn=='1'}" (click)="setSearch('best_yn','1')">BEST</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.best_yn=='0'}" (click)="setSearch('best_yn','0')">일반</button>
          </div>
        </div>
        <div class="fr w18p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="867e61697f6d417d8a2b32244126fe6f" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-danger btn-small ml5" (click)="setDeleteOrderReview()">후기삭제</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100vh - 175px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="reviewList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (cellClicked)="onCellClicked($event)"
          (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
      </div>
      <section class="pagination mt10" *ngIf="totalCount">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="totalCount"
          [maxSize]="15"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
        >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </section>      
    </as-split-area>
    <as-split-area size="35" minSize="35" maxSize="35">
      <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
        <mat-tab label="상세보기">
          <app-order-review-tab-info *ngIf="activeTab==0" [data]="reviewData" [emitList]="emitListFunc"></app-order-review-tab-info>
        </mat-tab>
        <mat-tab label="신고내역">
          <app-order-review-tab-history *ngIf="activeTab==1" [data]="reviewData"></app-order-review-tab-history>
        </mat-tab>
      </mat-tab-group>
    </as-split-area>
  </as-split>
</section>

