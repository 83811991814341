<form [formGroup]="form" class="h100">
  <section class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">Email 발송</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </section>

  <section class="modal-body p10" style="height:550px;">
    <div class="member-email-list">
      <div>
        <div class="fl">
          <h5 class="f12 bold pt5">발송리스트</h5>
        </div>
        <div class="fr right">
          <button type="button" class="btn btn-danger btn-small" (click)="deleteMember();">선택삭제</button>
        </div>
        <div class="cb"></div>
      </div>

      <div class="mt5" style="height:calc(100% - 5px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="sendList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged($event)"
        >
        </ag-grid-angular>
      </div>
    </div>

    <div class="email-form">
      <div class="">
        <table class="list-table-form">
        <colgroup>
          <col style="width:15%" />
          <col style="width:35%" />
          <col style="width:15%" />
          <col style="width:35%" />
        </colgroup>

        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> 이메일 발송폼</th>
          <td><inputEx type="select" name="commonCode" [formGroup]="form" [formErrors]="formErrors" placeholder="이메일 발송폼 선택" [data]="autoList" (change)="changeForm()"></inputEx></td>
          <th scope="row"><i class="material-icons cred f11">star</i> 발송이메일</th>
          <td><inputEx type="text" name="from" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        <tr>
          <th scope="row"><i class="material-icons cred f11">star</i> 제목</th>
          <td colspan="3"><inputEx type="text" name="subject" [formGroup]="form" [formErrors]="formErrors" placeholder="이메일 제목"></inputEx></td>
        </tr>
        </tbody>
        </table>

        <div class="mt5">
          <inputEx type="summernote" name="contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
        </div>
      </div>
    </div>
  </section>

  <section class="modal-footer">
    <div class="fl w50p">
      <div class="form-inline">
        <div class="fl mr10">전체 : {{sendList.length | comma}}건 / </div>
        <!-- <inputEx *ngIf="! mode" type="text" size="3" maxlength="4" name="sendCount" [formGroup]="form" [formErrors]="formErrors"></inputEx> -->
        <!-- <inputEx *ngIf="mode == 'pay' || mode == 'dormant'" type="text" size="3" maxlength="4" name="sendCount" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx> -->
        <div class="fl mr10">최대 {{MAXIMUM | comma}} 건까지 동시 발송</div>
        <button *ngIf="! mode" type="button" class="fl btn btn-primary btn-small mr10 f15" (click)="sendEmail()">발송하기</button>
        <!-- <button *ngIf="mode == 'pay' || mode == 'dormant'" type="button" class="fl btn btn-primary btn-small mr10 f15" (click)="sendEmailOnce()">발송하기</button> -->
        <button *ngIf="! mode" type="button" class="fl btn btn-danger btn-small mr10 f15" (click)="autoSend()">일괄자동발송</button>
      </div>
    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
    <div class="cb"></div>
  </section>
</form>

<section class="send" [ngClass]="{'send-on': isSend}">
  <iframe *ngFor="let html of formHTML; index as i" name="iframe{{i}}" [srcdoc]="html" width="100%" height="125" border="0"></iframe>
  <div class="mt5 mb5 mr10 right">
    <button type="button" class="btn btn-default btn-small" (click)="isSend=false">창닫기</button>
  </div>
</section>
