<section id="customer-member-info" *ngIf="memberInfo !== null">
  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">회원정보</h4>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="setCustomerMemberInfoSave()">저장하기</button>
    </div>
    <div class="cb"></div>
  </div>

  <form [formGroup]="form">
  <inputEx type="hidden" name="mem_no" [formGroup]="form" [formErrors]="formErrors"></inputEx>

  <table class="table table-bordered table-small1 mb0">
  <caption class="none">회원정보</caption>
  <colgroup>
    <col style="width:15%;" />
    <col style="width:35%;" />
    <col style="width:15%;" />
    <col style="width:35%;" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="">한글이름</label></th>
    <td><inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="">아이디</label></th>
    <td><inputEx type="text" name="id" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">생년월일</label></th>
    <td><inputEx type="date" name="birthdate" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
    <th scope="row"><label for="">성별</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gender.value=='M'}" (click)="form.get('gender').setValue('M')">남</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gender.value=='F'}" (click)="form.get('gender').setValue('F')">여</button>
      </div>
      <inputEx type="hidden" name="gender" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="">이메일</label></th>
    <td colspan="3"><inputEx type="text" name="email" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">이메일수신</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.email_yn.value=='1'}" (click)="form.get('email_yn').setValue('1')">예</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.email_yn.value=='0'}" (click)="form.get('email_yn').setValue('0')">아니오</button>
      </div>
      <inputEx type="hidden" name="email_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
    <th scope="row"><label for="">SMS수신</label></th>
    <td>
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value=='1'}" (click)="form.get('sms_yn').setValue('1')">예</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value=='0'}" (click)="form.get('sms_yn').setValue('0')">아니오</button>
      </div>
      <inputEx type="hidden" name="sms_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="">Email수신거부</label></th>
    <td><inputEx type="date" name="email_reject_date" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
    <th scope="row"><label for="">SMS신거부</label></th>
    <td><inputEx type="date" name="sms_reject_date" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
  </tr>
  </tbody>
  </table>

  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">주소정보</h4>
    </div>
    <div class="fr w50p right">
    </div>
    <div class="cb"></div>
  </div>

  <table class="table table-bordered table-small1 mb0">
  <caption class="none">회원정보</caption>
  <colgroup>
    <col style="width:15%;" />
    <col style="width:35%;" />
    <col style="width:15%;" />
    <col style="width:35%;" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="">전화번호</label></th>
    <td><inputEx type="text" name="hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="">우편번호</label></th>
    <td>
      <div class="input-group">
        <inputEx name="zipcode" class="input_zipcode" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
        <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
      </div>
    </td>
  </tr>
  <tr>
    <th scope="row"><label for="">주소</label></th>
    <td><inputEx type="text" name="address" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
    <th scope="row"><label for="">상세주소</label></th>
    <td><inputEx type="text" name="address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">상사명</label></th>
    <td colspan="3"><inputEx type="text" name="shop_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">홈페이지주소</label></th>
    <td colspan="3"><inputEx type="text" name="homepage" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">상사전화</label></th>
    <td><inputEx type="text" name="shop_tel" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="">상사팩스</label></th>
    <td><inputEx type="text" name="shop_fax" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">사업자번호</label></th>
    <td><inputEx type="text" name="business_no" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    <th scope="row"><label for="">계좌번호</label></th>
    <td><inputEx type="text" name="bank_account" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
  </tr>

  </tbody>
  </table>


  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">기타정보</h4>
    </div>
    <div class="fr w50p right">
    </div>
    <div class="cb"></div>
  </div>

  <table class="table table-bordered table-small1 mb0">
  <caption class="none">회원정보</caption>
  <colgroup>
    <col style="width:15%;" />
    <col style="width:35%;" />
    <col style="width:15%;" />
    <col style="width:35%;" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="">가입일자</label></th>
    <td><inputEx type="text" name="write_date" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
    <th scope="row"><label for="">사용자등급</label></th>
    <td><inputEx type="text" name="grade" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">최종IP</label></th>
    <td><inputEx type="text" name="write_ip" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
    <th scope="row"><label for="">최종접속</label></th>
    <td><inputEx type="text" name="last_logindate" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
  </tr>
  <tr>
    <th scope="row"><label for="">쇼핑등급</label></th>
    <td><inputEx type="text" name="buyclass" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
    <th scope="row"><label for="">구매성향</label></th>
    <td><inputEx type="text" name="member_group" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
  </tr>
  </tbody>
  </table>

  </form>

  <div class="mt5">
    <h4 class="fb mt5 mb6">개인별 문의내용(전화문의, 영구삭제, 특이사항 등등)</h4>
  </div>

  <form [formGroup]="form2">
  <inputEx type="hidden" name="memberNo" [formGroup]="form2" [formErrors]="formErrors2"></inputEx>

  <div class="mt5">
    <div class="input-group">
      <inputEx type="text" name="message" class="form-control form-control-small" [formGroup]="form2" [formErrors]="formErrors2" placeholder="문의내용"></inputEx>
      <div class="input-group-append">
        <button type="button" class="btn btn-default btn-small" (click)="setMemberMemoSave()">문의등록</button>
      </div>
    </div>
  </div>

</form>

  <div class="mt5" style="height:300px">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="memberMemoList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (rowClicked)="onRowClicked($event)"
      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>
