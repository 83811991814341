<form [formGroup]="form" style="height:calc(100% - 50px)">
  <header class="mt5 mb5">
    <div class="fl">
      <h4 class="modal-title fb mb0">발주 입고</h4>
    </div>
    <div class="fr right">
      <button type="button" class="btn btn-default btn-small" (click)="goList()">목록으로</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="submit()">저장하기</button>
    </div>
    <div class="cb"></div>
  </header>

  <section class="h100">
    <table class="table table-bordered table-small mb0">
    <caption class="hidden">구매입고</caption>

    <colgroup>
      <col style="width:10%;" />
      <col style="width:23%;" />
      <col style="width:10%;" />
      <col style="width:23%;" />
      <col style="width:10%;" />
      <col style="width:23%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="purchase_seq" class="control-label">발주서 번호</label></th>
      <td>
        <inputEx type="text" name="purchase_seq" [formGroup]="form" [formErrors]="formErrors" readonly="true" [addOn]="addPurchaseFunc" addOnText="발주서검색"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="gbn_name" class="control-label">발주구분</label></th>
      <td>
        <inputEx type="text" name="gbn" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="date" class="control-label">발주일자</label></th>
      <td>
        <inputEx type="text" name="date" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
    </tr>
    <tr>
      <!-- <th scope="row"><label for="customer_name" class="control-label">거래처</label></th>
      <td>
        <inputEx type="text" name="customer_name" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
      </td> -->
      <th scope="row"><label for="qty" class="control-label">발주수량</label></th>
      <td>
        <inputEx type="text" name="qty" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
      <th scope="row"><label for="amt" class="control-label">발주금액</label></th>
      <td>
        <inputEx type="text" name="amt" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>
      <th scope="row"><label for="title" class="control-label">발주서 제목</label></th>
      <td>
        <inputEx type="text" name="title" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx>
      </td>   
    </tr>
    <tr>
      <th scope="row"><label for="memo" class="control-label">메모</label></th>
      <td colspan="5">
        <inputEx type="textarea" name="memo" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>

    <div class="mt5" style="height:calc(100% - 155px)">
      <as-split unit="percent" restrictMove="true" gutterSize="10">
        <!-- 발주서 상품정보 -->
        <as-split-area size="48" minSize="30" maxSize="70">
          <header class="mt5 mb5">
            <div class="fl">
              <h4 class="f14 pt5 mb0">발주서 상품정보</h4>
            </div>
            <div class="fr right">
              <div class="form-inline">
                <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="7dfa4944f4934a97966d0ccaed000831" [btnGroup]="1"></btn-ag-grid-save>
                <button type="button" class="btn btn-success btn-small f12 ml5" (click)="autoInput()">입고수량 일괄자동입력</button>
                <input type="text" [(ngModel)]="stockInDate2" [ngModelOptions]="{standalone: true}" size="12" style="width:110px;" class="form-control form-control-small calendar-input ml5" readonly="readonly" placeholder="입고일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
                <ng-template #footerTemplate2>
                  <hr class="my-0">
                  <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday2(d2, true)">오늘</button>
                  <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
                </ng-template>
                <button type="button" class="btn btn-success btn-small f12 ml5" (click)="autoInDate()">입고일자 일괄적용</button>
              </div>
            </div>
            <div class="cb"></div>
          </header>

          <ag-grid-angular
            #myGrid

            style="width:100%;height:calc(100% - 45px);"
            class="ag-theme-balham"

            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="form.value.productList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [components]="components"
            [stopEditingWhenGridLosesFocus]="true"
            [singleClickEdit]="true"

            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked_product($event)"
            (cellEditingStopped)="onCellEditingStopped($event)"
            >
          </ag-grid-angular>
        </as-split-area>
        <!-- 발주서 상품정보 -->

        <!-- 상품별 색상 및 사이즈 데이터 입력 -->
        <as-split-area size="52" minSize="30" maxSize="70">
          <header class="mt5 mb5">
            <div class="fl">
              <h4 class="f14 pt5 mb0">상품별 색상 및 사이즈 입고정보</h4>
            </div> 
            <div class="fr right">
              <div class="form-inline">
                <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="3305c62b57c149f699cf768c4a50cb2d" [btnGroup]="1"></btn-ag-grid-save>
                <!-- <select name="purchase_seq" class="form-control form-control-small ml5">
                  <option value="">창고선택</option>
                </select>
                <button type="button" class="btn btn-default btn-small f12 ml5">창고 일괄선택</button>
                <button type="button" class="btn btn-default btn-small f12 ml5">입고위치 자동입력</button> -->
                <!-- <button type="button" class="btn btn-success btn-small f12 ml5" (click)="autoInput()">입고수량 자동입력</button> -->

                <input type="text" [(ngModel)]="stockInDate" [ngModelOptions]="{standalone: true}" size="12" style="width:110px;" class="form-control form-control-small calendar-input ml5" readonly="readonly" placeholder="입고일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
                <ng-template #footerTemplate1>
                  <hr class="my-0">
                  <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
                  <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
                </ng-template>
                <button type="button" class="btn btn-success btn-small f12 ml5" (click)="setInDate()">입고일자 선택적용</button>
              </div>
            </div>
            <div class="cb"></div>
          </header>

          <ag-grid-angular
            #myGrid

            style="width:100%;height:calc(100% - 45px);"
            class="ag-theme-balham"

            rowSelection="multiple"
            [columnDefs]="columnDefsProperty"
            [defaultColDef]="defaultColDef"
            [rowData]="form.value.propertyList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [components]="components"
            [stopEditingWhenGridLosesFocus]="true"
            [singleClickEdit]="true"

            (gridReady)="onGridReadyProperty($event)"
            >
          </ag-grid-angular>
        </as-split-area>
        <!-- 상품별 색상 및 사이즈 데이터 입력 -->
      </as-split>
    </div>
  </section>
</form>