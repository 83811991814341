  <section class="search-condition mb5">
    <div class="fl form-inline asub-title">
      <!-- <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" >
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click) = "getList()">
        <i class="material-icons-outlined">search</i>
      </button> -->

      <div class="btn-group pt5">
        <button *ngFor = "let item of type; index as idx" type="button" class="btn btn-default btn-small" [ngClass] = "{active: form.value.gbn == idx}" (click) = "btnClick('gbn', idx)">{{item}}</button>
      </div>

      <!-- <div class="btn-group ml10">
        <button *ngFor = "let obj of [
          {idx: 0, item: '오늘'}, {idx: 1, item: '어제'}, {idx: 3, item: '3일'}, {idx: 7, item: '7일'}, {idx: 15, item: '15일'}, {idx: 30, item: '30일'}, {idx: 90, item: '90일'}, {idx: 180, item: '180일'}
        ]" type="button" class="btn btn-default btn-small" [ngClass] = "{active: form.controls.date_btn.value == obj.idx}" (click) = "quickDate(obj.idx, this)">{{obj.item}}</button>
      </div> -->
    </div>
    <div class="fr">   
    </div>
    <div class="cb"></div>
  </section>

  <hr class="db mt5 mb5" />

  <div style="height: calc(100% - 40px);" *ngIf = "form.value.gbn == 0">
    <div class="fl form-inline asub-title mb5">
      
      <button type="button" title="검색" class="btn btn-default btn-small-icon mr5" (click) = "getList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="13" readonly="readonly" [value]="form.value.date" class="form-control form-control-small calendar-input" placeholder="기준일자" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate('date', $event)" />
      <!-- <button type="button" class="btn btn-primary btn-small f12 ml5" >Excel 즉시 다운로드</button> -->
    </div>
    <div class="fr">
      <button type="button" class="btn btn-primary btn-small f12 ml5" (click) = "printExcel()">엑셀 다운로드</button>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="{{type[form.value.gbn]}}" title="{{type[form.value.gbn]}}"></ag-grid-excel> 
      <!-- <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="5e7ecb041ef645bd9882c476292f77cb" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-danger btn-small ml5" ></button>
      <button type="button" class="btn btn-success btn-small f12 ml5"></button>
      <button type="button" class="btn btn-primary btn-small f12 ml5"></button> -->
    </div>
    <div class="cb"></div>

    <ag-grid-angular
      #myGrid

      style="width: 100%; height:calc(100% - 50px);"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="form.value.rowData"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
  
  <div style="height: calc(100% - 40px);" *ngIf = "form.value.gbn == 1">
    <div class="fl form-inline asub-title mb5">
      
      <button type="button" title="검색" class="btn btn-default btn-small-icon mr5" (click) = "getList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="13" readonly="readonly" [value]="form.value.date" class="form-control form-control-small calendar-input" placeholder="기준일자" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate('date', $event)" />
      <!-- <button type="button" class="btn btn-primary btn-small f12 ml5" >Excel 즉시 다운로드</button> -->
    </div>
    <div class="fr">
      <button type="button" class="btn btn-primary btn-small f12 ml5" (click) = "printExcel(1)">엑셀 다운로드</button>
      <!-- <ag-grid-excel [gridApi]="gridApi" selected="false" filename="{{type[form.value.gbn]}}" title="{{type[form.value.gbn]}}"></ag-grid-excel> -->
    </div>
    <div class="cb"></div>

    <ag-grid-angular
      #myGrid

      style="width: 100%; height:calc(100% - 50px);"
      class="ag-theme-balham"

      [columnDefs]="columnDefs_A"
      [defaultColDef]="defaultColDef"
      [rowData]="form.value.rowData"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [rowClassRules]="rowClassRules"

      (gridReady)="onGridReady_A($event)"
      (cellClicked)="onCellClicked($event)"
      >
    </ag-grid-angular>
  </div>
  
  <div style="height: calc(100% - 50px);" *ngIf = "form.value.gbn == 2">
    <div class="fl form-inline asub-title mb5">
      
      <button type="button" title="검색" class="btn btn-default btn-small-icon mr5" (click) = "getList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="13" readonly="readonly" [value]="form.value.date" class="form-control form-control-small calendar-input" placeholder="기준일자" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate('date', $event)" />
      <input type="text" size="20" class="form-control form-control-small ml5" [value]="form.value.searchText" (keypress)="searchList($event)" placeholder="SCM ID" (change) = "btnClick('searchText', $event.target.value)" />
      <select id="searchField" class="form-control form-control-small ml5" (change)="setValue($event)">
        <option>정렬 기준</option>
        <option value="delivery">배송일</option>
        <option value="order">주문일</option>
      </select>
      <!-- <button type="button" class="btn btn-primary btn-small f12 ml5" >Excel 즉시 다운로드</button> -->
    </div>
    <div class="fr">
      <button type="button" class="btn btn-primary btn-small f12 ml5" (click) = "printExcel(2)">엑셀 다운로드</button>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="{{type[form.value.gbn]}}" title="{{type[form.value.gbn]}}"></ag-grid-excel>
    </div>
    <div class="cb"></div>

    <ag-grid-angular
      #myGrid

      style="width: 100%; height:calc(100% - 40px);"
      class="ag-theme-balham"

      [columnDefs]="columnDefs_B"
      [defaultColDef]="defaultColDef"
      [rowData]="form.value.rowData"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady_B($event)"
      >
    </ag-grid-angular>
  </div>