import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker ,NgbModal, NgbDateStruct, NgbModalOptions, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { ApprovalDetailComponent } from '@page/approval/approval-detail/approval-detail.component';
import { ApprovalService } from '@app/service/approval.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/service/common.service';
import { EmployeeService } from '@app/service/employee.service';
import { Globals } from '@app/service/globals.service';
import { AgGridButtonComponent } from '@app/components/ag-grid-button/ag-grid-button.component';
import { ApprovalOfflineComponent } from '../approval-offline/approval-offline.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-900'
};

@Component({
  selector: 'app-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.scss']
})
export class ApprovalListComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
    public rowData: any = [];

    public selectedItem: any;
  
    public detail: any;
    public seq: any;
  
    public member: any;
  
    public approvalList: any = [];
    public count: any;
  
    public employeeList: any = [];
  
    public approvalDocument: any = [{title: '결재문서종류', value: ''}];
  
    public approvalInfo: any = [];
  
    search: any = {
      step_status: '',
      document_type: '',
      subject: '',
      writer: '',
      approval_group: '',
      sdate: '',
      edate: '',
      mem_no1: ''
    };
  
    // 그리드 관련 선언
    gridApi: any;
    gridColumnApi: any;
    columnDefs: any;
  
    defaultColDef: any;
    domLayout: any;
    rowSelection: any;
  
    noRowsTemplate: string;
  
    // 그리드 이미지 처리
    frameworkComponents = {
      agGridHtmlComponent: AgGridHtmlComponent
    };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private approvalService: ApprovalService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonCodeService: CommonService,
    private employeeService: EmployeeService,
    private globals: Globals
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '문서구분', field: 'approval_type', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.approval_type == 'ON' ) {
            return '<span class="badge badge-secondary f12">전자결재</span>';
          } else {
            return '<span class="badge badge-info f12">수기결재</span>';
          }
        }
      },
      { headerName: '문서번호', field: 'document_number', width: 130, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.execute_number ) {
            return `<span class="f13">${params.data.execute_number}</span><br><span class="document">${params.data.document_number}</span>`
          } else {
            return `<span class="document">${params.data.document_number}</span>`
          }
        }
      },
      { headerName: '문서종류', field: 'common_name', width: 100, cellClass: 'cp' },
      { headerName: '작성일자', field: 'write_date', width: 100, cellClass: 'cp' },
      { headerName: '작성자', field: 'writer_mem_nm', width: 100, cellClass: 'cp' },
      { headerName: '제목', field: 'subject', width: 300, cellClass: 'cp' },
      { headerName: '수신', field: 'receive', width: 100, cellClass: 'cp ' },
      { headerName: '결재단계', field: 'approval', width: 330, cellClass: 'cp',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          let approval = '';
          let agreement = '';
          let reference = '';

          if( params.data.approval != null || params.data.approval != undefined ) {
            approval = `결재: ${params.data.approval}`
          }

          if( params.data.agreement != null || params.data.agreement != undefined ) {
            agreement = `<br/> 협조: ${params.data.agreement}`
          }

          if( params.data.reference != null || params.data.reference != undefined ) {
            reference = `<br/> 참조: ${params.data.reference}`
          }
          return (
            `${approval} ${agreement} ${reference}`
          )
        }
      },
      { headerName: '진행상태', field: 'step_status', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.step_status == 'P' ) {
            return '<span class="badge badge-success f12">진행</span>';
          } else if( params.data.step_status == 'C' ) {
            return '<span class="badge badge-secondary f12">완료</span>';
          } else if( params.data.step_status == 'R' ) {
            return '<span class="badge badge-danger f12">반려</span>';
          } else if( params.data.step_status == 'CA' ) {
            return '<span class="badge badge-warning f12">취소</span>';
          } else {
            return '<span class="badge badge-warning f12">임시저장</span>';
          }
        }
      },
      { headerName: '', field: 'approval_print', width: 250, cellClass: 'cp',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'approval_modify',  
          modifyClicked: this.modifyRow.bind(this),
          reApprovalClicked: this.reApprovalRow.bind(this),
          approvalClicked: this.processRow.bind(this),
          cancelClicked: this.cancelRow.bind(this),
          printClicked: this.printRow.bind(this),
          offClicked: this.approvalRow.bind(this),
          executeClicked: this.approvalExecuteRow.bind(this),
          approvalPrintClicked: this.approvalPrintRow.bind(this)
        }
      },
    ];

     // default 컬럼 옵션
     this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( async params => {
      this.detail = ( typeof params.detail == 'undefined' || params.detail == '') ? false : true,
      this.seq = ( typeof params.seq == 'undefined' || params.seq == '') ? '' : params.seq
     });
 
     this.getApprovalList();
     this.getCommonList();
     this.getGroupEmployeeListForSelect();
 
     if( this.detail ) {
       this.openList(this.seq)
     }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
    
  /*******************************************************************************
    설  명 : 전자결재 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getApprovalGroup(group) {
    this.search.approval_group = group;

    this.getApprovalList();
  }

  /*******************************************************************************
    설  명 : 진행상태 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStepStatus(step) {
    this.search.step_status = step;

    this.getApprovalList();
  }

  /*******************************************************************************
    설  명 : 작성자 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getWriter(val) {
    this.search.writer = val;

    this.getApprovalList();
  }

  /*******************************************************************************
    설  명 : 결제문서 종류 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getChange(e) {
    this.search.document_type = e.target.value;

    this.getApprovalList();
  }

  /*******************************************************************************
    설  명 : 사원 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMember(event) {
    this.search.mem_no1 = event.target.value;
    this.getApprovalList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : code
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.commonCodeService.getCommonListCode('C14').then(response => {
      if( response.ResultCode ) {
        for( let item of response.data ) {
          this.approvalDocument.push({
            title: item.common_name,
            value: item.common_code
          })
        }
      } else {
        this.approvalDocument = [];
      }
    })
  }

  /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeListForSelect() {
    this.employeeService.getGroupEmployeeListForSelect().then( response => {
      if ( response.ResultCode ) {
        this.employeeList = response.data;
      }
    });
  }

  modifyRow( params ) {
    let param = {
      seq: params.data.seq,
      type: '2',
      document_type: params.data.document_type
    };

    this.router.navigate(
      ['/approval'],
      {
        relativeTo: this.activatedRoute,
        queryParams: param,
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  reApprovalRow( params ) {
    let param = {
      seq: params.data.seq,
      type: '3',
      document_type: params.data.document_type
    };

    this.router.navigate(
      ['/approval'],
      {
        relativeTo: this.activatedRoute,
        queryParams: param,
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  processRow( params ) {
    this.approvalService.getApprovalInfo(params.data.seq).then(response => {
      if( response.ResultCode ) {
        const modalRef = this.modalService.open(ApprovalDetailComponent, options);
        this.selectedItem = response.data;
  
        modalRef.componentInstance.selectedItem = response.data;
        modalRef.componentInstance.item = params.data;
  
        modalRef.result.then((result) => {
          if( result ) {
            this.getApprovalList();
          }
        }, (reason) => {
        });
      }
    })
  }

  cancelRow( params ) {
    let data = {
      doctype: params.data.document_type,
      seq: params.data.seq
    }
    if( window.confirm('취소하시겠습니까?' ) ) {
      this.approvalService.cancelApproval(data).then(response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '내결재관리');
          this.getApprovalList();
        } else {
          this.toastrService.error( response.ResultMessage, '내결재관리');
        }
      })
    }
  }

  printRow( params ) {
    this.approvalService.getApprovalInfo(params.data.seq).then(response => {
      const modalRef = this.modalService.open(ApprovalDetailComponent, options);

      modalRef.componentInstance.selectedItem = response.data;
      modalRef.componentInstance.item = params.data;
      modalRef.componentInstance.type = "1";

      modalRef.result.then((result) => {
        if( result ) {
          this.getApprovalList();
        }
      }, (reason) => {
      });
    })
  }

  approvalRow( params ) {
    this.offLineDetail(params.data.seq);
  }

  approvalExecuteRow( params ) {
    if( params.data.step_status == 'C' ) {
      if( window.confirm('공문서 시행하시겠습니까?') ) {
        this.approvalService.documentExecute(params.data.seq).then(response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '내결재관리');
            this.getApprovalList();
          } else {
            this.toastrService.error( response.ResultMessage, '내결재관리');
          }
        })
      }
    } else {
      this.toastrService.error( '결재가 완료된 후 시행이 가능합니다.', '내결재관리');
    }
    
  }

  approvalPrintRow( params ) {
    this.approvalService.getApprovalInfo(params.data.seq).then(response => {
      const modalRef = this.modalService.open(ApprovalDetailComponent, options);

      modalRef.componentInstance.selectedItem = response.data;
      modalRef.componentInstance.item = params.data;
      modalRef.componentInstance.type = "2";

      modalRef.result.then((result) => {
        if( result ) {
          this.getApprovalList();
        }
      }, (reason) => {
      });
    })
  }
      
  
  /*******************************************************************************
    설  명 : 내 결제관리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getApprovalList() {
    // indicator 표시
    setTimeout (() => {
      this.globals.indicator.next(true);
    });

    this.approvalService.getApprovalList(this.search).then( response => {
      if( response.ResultCode ) {
        this.approvalList = response.data;
        this.count = response.count
        
      } else {
        this.approvalList = [];
      }

      this.globals.indicator.next(false);

    });
  }

  setApprovalView(step_seq) {
    this.approvalService.setApprovalView(step_seq).then(response => {
    })
  }

/*******************************************************************************
  설  명 : 상세보기
  입력값 :
  리턴값 : 없음
*******************************************************************************/
  openList(event) {
    let seq = '';
    if( this.seq ) {
      seq = this.seq
    } else {
      seq =event.data.seq
    }

    if( event.colDef.field != 'approval_print' ) {
      this.approvalService.getApprovalInfo(seq).then(response => {
        if( response.ResultCode ) {
          this.setApprovalView(event.data.step_seq);
          const modalRef = this.modalService.open(ApprovalDetailComponent, options);
          this.selectedItem = response.data;
    
          modalRef.componentInstance.selectedItem = response.data;
          modalRef.componentInstance.item = event.data;
    
          modalRef.result.then((result) => {
            if( result ) {
              this.getApprovalList();
            }
          }, (reason) => {
          });
        }
      })
    }
  }


  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = moment().format('YYYY-MM-DD');
    } else {
      this.search.edate = moment().format('YYYY-MM-DD');
    }
    obj.close();

  }

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      step_status: '',
      document_type: '',
      subject: '',
      writer: '',
      approval_group: '',
      sdate: '',
      edate: '',
      mem_no1: ''
    };
    this.getApprovalList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getApprovalList();
    }
  }


  /*******************************************************************************
    설  명 : 상세보기
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  offLineDetail(event) {
    const modalRef = this.modalService.open(ApprovalOfflineComponent, options);


    modalRef.componentInstance.seq = event;

    modalRef.result.then((result) => {
      if( result ) {
        this.getApprovalList();
      }
    }, (reason) => {
    });
  }

}
