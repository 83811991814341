import { Component, OnInit, Injectable } from '@angular/core';
import { NgbInputDatepicker ,NgbModal, NgbDateStruct, NgbModalOptions, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { DocumentExecuteAddComponent } from '@page/approval/document-execute/document-execute-add/document-execute-add.component';
import { ApprovalDocumentService } from '@app/service/approval-documents.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  fromModel(value: string | null): NgbDateStruct | null {
    const date = value ? value.split('-') : null;
    return date ? { year: parseInt( date[0], 10 ), month: parseInt( date[1], 10 ), day: parseInt( date[2], 10 ) } : null;
  }

  toModel(value: NgbDateStruct | null): string | null {
    return value ?
      value.year.toString() + '-' + (value.month).toString().padStart(2, '0') + '-' + value.day.toString().padStart(2, '0') : null;
  }
}

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-document-execute',
  templateUrl: './document-execute.component.html',
  styleUrls: ['./document-execute.component.scss'],
  providers: [ {provide: NgbDateAdapter, useClass: CustomAdapter} ],
})
export class DocumentExecuteComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public rowData: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  public executeList: any = [];


  public search: any = {
    sdate: '',
    edate: '',
    receiving: '',
    title: ''
  };


  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private approvalDocService: ApprovalDocumentService,
    private toastrService: ToastrService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '번호', field: 'uid', width: 50, cellClass: 'cp center' },
      { headerName: '시행번호', field: 'approval_seq', width: 150, cellClass: 'cp' },
      { headerName: '결재일자', field: 'authorization_date', width: 100, cellClass: 'cp' },
      { headerName: '제목', field: 'title', width: 500, cellClass: 'cp' },
      { headerName: '시행일자', field: 'execution_date', width: 100, cellClass: 'cp' },
      { headerName: '수신처', field: 'receiving_comapny', width: 400, cellClass: 'cp' },
      { headerName: '확인자', field: 'name', width: 200, cellClass: 'cp' },
      { headerName: '수정', field: 'modify', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<button type="button" class="btn btn-default btn-grid2 mr5" (click)="addDocument()"><i class="material-icons menu-icon fl micon-create f12 ml3">&#xe150;</i></button>`;
        }
      },
      { headerName: '삭제', field: 'delete', width: 70, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<button type="button" class="btn btn-default btn-grid2 mr5"><i class="material-icons menu-icon fl micon-create f12 ml3">&#xe5cd;</i></button>`;
        }
      },
    ];


    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  ngOnInit(): void {
    this.searchInit();
    this.getExcuteList();
  }


  /*******************************************************************************
    설  명 : 셀 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellClicked($event) {

      // 수정 시
      if( $event.colDef.field === 'modify' ) {
        this.addDocument( $event.data );
      }

      // 삭제 시
      if ($event.colDef.field === 'delete') {
        if( confirm("선택하신 항목을 삭제하시겠습니까?") ) {
          this.approvalDocService.setDeleteDocumentExcute( $event.node.data.uid ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');
              this.getExcuteList();
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
        });
       }
      }
    }

  /*******************************************************************************
    설  명 : 공문서시행 수정등록
    입력값 :
     리턴값 : 없음
  *******************************************************************************/
  addDocument( item: any ) {

    const modalRef = this.modalService.open(DocumentExecuteAddComponent, options);

    modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
      if( result ) {
        this.getExcuteList();
      }
    }, (reason) => {
    });
  }


  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = moment().format('YYYY-MM-DD');
    } else {
      this.search.edate = moment().format('YYYY-MM-DD');
    }
    obj.close();

    }


  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      sdate: moment().format('YYYY-MM-01'),
      edate: moment().format('YYYY-MM-DD'),
      receiving: '',
      title: ''
    };
    this.getExcuteList();
  }


  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getExcuteList();
    }
  }

  /*******************************************************************************
    설  명 : 공문서시행 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getExcuteList() {
    this.approvalDocService.getExcuteList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.executeList = response.data;
      } else {
        this.executeList = [];
      }
    });
  }


}
