<div class="mt5">
  <div class="fl w70p form-inline">
    <h4 class="fb mt5 mb6">이벤트 품목</h4>
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon ml30" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <input type="text" [(ngModel)]="quickFilter" id="quickFilter" size="50" (input)="onQuickFilterChanged($event)" class="form-control form-control-small ml5" placeholder="그리드 내 모든 필드 검색 필터링" />
  </div>
  <div class="fr w30p right">
    <!-- <button type="button" class="btn btn-primary btn-small" (click)="setEventProductAmountSave()">저장하기</button> -->
    <button type="button" class="btn btn-primary btn-small ml10" (click)="setEventProductSortSave()">순서 저장</button>
    <button type="button" class="btn btn-danger btn-small ml10" (click)="setEventProductDelete()">상품삭제</button>
    <button type="button" class="btn btn-success btn-small ml10" (click)="setEventProductSave()">상품추가</button>
  </div>
  <div class="cb"></div>
</div>

<div style="height:calc(100% - 40px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="productList"
    [pagination]="false"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    [singleClickEdit]="true"
    [rowDragManaged]="true"
    [enableMultiRowDragging]="true"
    [animateRows]="true"
    [stopEditingWhenGridLosesFocus]="true"
    [getRowHeight]="getRowHeight"

    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</div>

