import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { EmailService } from '@app/service/email.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-form-add',
  templateUrl: './email-form-add.component.html',
  styleUrls: ['./email-form-add.component.scss']
})
export class EmailFormAddComponent implements OnInit {

  public title = '추가';

  public form: FormGroup;
  public formErrors = {};

  private titleDef = {title: '자동발송 안함', value: '0'};
  public autoList: any = [];

  public params: any = {
    seq: '',
    searchText: '',
    auto_seq: '',
  };

  public summernoteConfig = config.summernoteConfig;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private commonService: CommonService,
    private emailService: EmailService,
    private toastrService: ToastrService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', []],
      title: ['', [Validators.required, Validators.maxLength(100)]],
      auto_seq: ['', [] ],
      contents: ['', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 가져오기
    this.getAutoEmail();

    this.activatedRoute.queryParams.subscribe( params => {
      this.params = {
        seq: params.seq,
        searchText: params.searchText,
        auto_seq: params.auto_seq,
      };

      if ( params.seq !== undefined ) {
        this.title = '수정';
        this.getEmailFormDetail();
      }
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 리스트 페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  list() {
    this.router.navigate(
      ['/basic/email'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 자동 발송 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAutoEmail() {
    this.autoList = [];
    this.autoList.push(this.titleDef);

    this.commonService.getCommonListCode('mail').then( response => {
      for ( const item of response.data ) {
        this.autoList.push({
          title: item.common_name,
          value: item.seq
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 발송양식 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmailFormDetail() {
    this.emailService.getEmailFormDetail(this.params).then(response => {
      // response.data.auto_seq = response.data.auto_seq ?? '';
      this.form.patchValue(response.data);
    });
  }

  /*******************************************************************************
    설  명 : Email 발송양식 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.emailService.setEmailForm(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '메일양식 저장');
          this.list();
        } else {
          this.toastrService.error( response.ResultMessage, '메일양식 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : Email 발송양식 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if (this.form.controls.seq.value === '' || this.form.controls.seq.value === null) {
      return false;
    }

    if (confirm('선택하신 Email 발송 양식을 삭제하시겠습니까?') ) {
      this.emailService.delEmailForm( this.form ).then( response => {
        if (response.ResultCode) {
          this.toastrService.success( response.ResultMessage, '메일양식 삭제');
          this.list();
        } else {
          this.toastrService.error( response.ResultMessage, '메일양식 삭제');
        }
      });
    }
  }
}
