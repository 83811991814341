<section class="widget-div">
    <div class="fl widget cp" style="width:350px !important">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">총발행쿠폰수<span class="cnt">{{statistic.totalCoupon | number}}</span>장</p>
      </div>
      <div class="cb"></div>
    </div>
  
    <div class="fl widget cp" style="width:350px !important">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">사용쿠폰수<span class="cnt">{{statistic.useCoupon | number}}</span>장</p>
      </div>
      <div class="cb"></div>
    </div>
  
    <div class="fl widget cp" style="width:350px !important">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">미사용쿠폰<span class="cnt">{{statistic.unuseCoupon | number}}</span>장</p>
      </div>
      <div class="cb"></div>
    </div>
  
    <div class="fl widget cp" style="width:350px !important">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">폐기쿠폰<span class="cnt">{{statistic.discardCoupon | number}}</span>장</p>
      </div>
      <div class="cb"></div>
    </div>
  
    <div class="cb"></div>
  </section>
  
  <section id="member-coupon">
    <as-split unit="percent" restrictMove="true" gutterSize="10" style="height: calc(100vh - 150px);background-color:#fff;">
      <as-split-area size="45" minSize="30" maxSize="55">
        <div class="mb10">
          <div class="fl w10p">
            <h4 class="mb0 fb">쿠폰명</h4>
          </div>
          <div class="mb5">
            <div class="fl w70p form-inline">
              <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
                <i class="material-icons-outlined">refresh</i>
              </button>
              <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getCouponList()">
                <i class="material-icons-outlined">search</i>
              </button>
        
              <input type="text" size="30" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="쿠폰명" />
          
              <div class="btn-group ml10">
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn==''}" (click)="setSearchValue('use_yn','')">전체</button>
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='1'}" (click)="setSearchValue('use_yn','1')">사용</button>
                  <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='0'}" (click)="setSearchValue('use_yn','0')">미사용</button>
              </div>
            
            </div>
            <div class="cb"></div>
          </div>          
        </div>
        <ag-grid-angular
          #myGrid
  
          style="width: 100%;height:calc(100% - 39px);"
          class="ag-theme-balham"
  
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="couponList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
  
          (gridReady)="onGridReady($event)"
          (rowClicked)="onRowClicked($event)"
          >
        </ag-grid-angular>
      </as-split-area>
      <as-split-area size="55" minSize="45" maxSize="65" style="height:105% !important">
        <div class="mb10">
          <div class="fl w20p">
            <h4 class="mb0 fb">회원쿠폰리스트</h4>
          </div>
          <div class="btn-group ml10">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search_detail.is_use==''}" (click)="setSearchDetailValue('is_use','')">전체</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search_detail.is_use=='0'}" (click)="setSearchDetailValue('is_use','0')">미사용</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search_detail.is_use=='1'}" (click)="setSearchDetailValue('is_use','1')">사용</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search_detail.is_use=='2'}" (click)="setSearchDetailValue('is_use','2')">폐기</button>
          </div>
<!--           
          <div class="fr w30p right">
            <button type="button" class="btn btn-primary btn-small ml5" (click)="addSocietyHistory()">이력추가</button>
          </div> -->
          <div class="cb"></div>
        </div>
        <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 150px);background-color:#fff;">
          <ag-grid-angular
            #myGrid
  
            style="width: 100%;height:calc(100% - 39px);"
            class="ag-theme-balham"
  
            [columnDefs]="columnDetailDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="historyList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
  
            (cellClicked)="onCellClicked($event)"
            (gridReady)="onGridDetailReady($event)"
            >
          </ag-grid-angular>
        </as-split>
      </as-split-area>
    </as-split>
  </section>
  
  