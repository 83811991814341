  <div class="modal-header">
    <h4 class="modal-title fb mb0">가격등록</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

    <form [formGroup]="form">

    <table class="table table-bordered table-small1 mb50">
    <colgroup>
      <col style="width:20%" />
      <col style="width:30%" />
      <col style="width:20%" />
      <col style="width:30%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="col1" class="control-label"><i class="material-icons cred f11 lh20">star</i> 적용시작일</label></th>
      <td colspan="3"><inputEx type="date" name="date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="col1" class="control-label"><i class="material-icons cred f11 lh20">star</i> 소비자가</label></th>
      <td><inputEx type="text" name="consumer_price" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="col1" class="control-label"><i class="material-icons cred f11 lh20">star</i> 구매가</label></th>
      <td><inputEx type="text" name="buy_price" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr *ngIf="showEditFraPrice">
      <th scope="row"><label for="col1" class="control-label"><i class="material-icons cred f11 lh20">star</i> 프랜차이즈가</label></th>
      <td><inputEx type="text" name="fra_price" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      <th colspan="2"></th>
    </tr>
    <tr>
      <th scope="row"><label for="col1" class="control-label"><i class="material-icons cred f11 lh20">star</i> 변경방법</label></th>
      <td colspan="3">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.option.value == '1'}" (click)="form.get('option').setValue('1')">개별적용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.option.value == '2'}" (click)="form.get('option').setValue('2')">모든옵션 공통적용</button>
        </div>
      </td>
    </tr>
    </tbody>
    </table>

    </form>


  </div>

  <div class="modal-footer">
    <div class="fr w50p right">
      <button type="button" class="btn btn-default btn-small mr5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
      <button type="button" class="btn btn-primary btn-small" (click)="setProductMarginAdd()">가격등록</button>
    </div>
  </div>

