import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '@app/service/common.service';
import { ProductService } from '@app/service/product.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';

import { ProductSenaProcessComponent } from '@page/product/product-sena/product-sena-process/product-sena-process.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-sena',
  templateUrl: './product-sena.component.html',
  styleUrls: ['./product-sena.component.scss']
})
export class ProductSenaComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public senaList: any = [];
  public statusList: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    pageNo: 1,
    pageRow: 100,
    totalCount: 0,
    searchField: 'sn1',
    searchText: '',
    sena_status: ''
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private productService: ProductService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '입고일', field: 'inout_date', width: 100, cellClass: 'cp center ag-cell80h' },
      { headerName: '상품번호', field: 'product_seq', width: 100, cellClass: 'cp center ag-cell80h' },
      { headerName: '상품이미지', field: 'thumbnail_url', width: 150, cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'product_name', width: 360, cellClass: 'cp left ag-cell80h' },
      { headerName: 'SN1(상세보기)', field: 'sn1', width: 200, cellClass: 'cp center ag-cell80h' },
      { headerName: 'SN2(상세보기)', field: 'sn2', width: 200, cellClass: 'cp center ag-cell80h' },
      { headerName: '현재상태', field: 'sena_status_name', width: 110, cellClass: 'cp center cell-wrap-text', autoHeight: true },
      { headerName: '구매자', field: 'buyer_name', width: 150, cellClass: 'cp center ag-cell80h' },
      { headerName: '판매처', field: 'shop', width: 100, cellClass: 'cp center ag-cell80h' },
      { headerName: '처리', field: '', width: 100, cellClass: 'cp center ag-cell80h',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'process',
          btnName: '처리',
          clickEvent: this.openSenaProcess.bind(this)
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    this.getProductSenaList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 세나 상태
    this.commonService.getCommonListCode('SNT').then( response => {
      if ( response.ResultCode ) {
        this.statusList = response.data;
      } else {
        this.statusList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 세나 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductSenaList() {
    this.productService.getProductSenaList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.senaList = response.data;
        this.search.totalCount = response.total;
      } else {
        this.senaList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = () => 80;

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked( event: any ) {
    let eventField: any = event.colDef.field;

    if( eventField == 'sn1' || eventField == 'sn2' || eventField == 'buyer_name' ) {
      if( event.data[ eventField ] !== '' && event.data[ eventField ] !== null ) {
        this.search.searchField = eventField;
        this.search.searchText = event.data[ eventField ];

        this.getProductSenaList();
      }
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 100,
      searchField: 'sn1',
      searchText: '',
      sena_status: ''
    }

    this.getProductSenaList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductSenaList();
    }
  }

  /*******************************************************************************
    설  명 : 사용여부 검색
    입력값 : use_yn
    리턴값 : 없음
  *******************************************************************************/
  searchUseYn( use_yn ) {
    this.search.use_yn = use_yn;

    this.getProductSenaList();
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    // this.params.pageNo = page;
    this.getProductSenaList();
  }

  /*******************************************************************************
    설  명 : 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openSenaProcess( params: any ) {
    const modalRef = this.modalService.open(ProductSenaProcessComponent, options);

    modalRef.componentInstance.params = params;

    modalRef.result.then((result) => {
      if( result ) {
        this.getProductSenaList();
      }
    }, (reason) => {
    });
  }

}
