import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { CategoryService } from '@app/service/category.service';
import { BrandService } from '@app/service/brand.service';
import { ProductService } from '@app/service/product.service';

import { ToastrService } from 'ngx-toastr';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-product-find-openmarket',
  templateUrl: './product-find-openmarket.component.html',
  styleUrls: ['./product-find-openmarket.component.scss']
})
export class ProductFindOpenmarketComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() mem_no: any;
  @Input() rowData: any;

  public search: any = {
    searchText: '',
    productSeq: ''
  };

  public productList: any = [];
  public propertyList: any = [];

  public baseURL: any = config.baseUrl;

  leftGridApi: any;
  leftGridColumnApi: any;
  leftColumnDefs: any;

  rightGridApi: any;
  rightGridColumnApi: any;
  rightColumnDefs: any;

  defaultColDef: any;

  leftGridNoRowsTemplate: string;
  rightGridNoRowsTemplate: string;

  // 그리드 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private productService: ProductService,
  ) {
    this.leftColumnDefs = [
      { headerName: '대표상품번호', field: 'seq', cellClass: 'cp center ag-cell80h', width: 115 },
      { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center ag-cell80h', width: 100, cellRenderer: 'agGridImageComponent' },
      { headerName: '카테고리', field: 'category_name', cellClass: 'cp left ag-cell80h-br cell-wrap-text', width: 200 },
      { headerName: '상품정보 (브랜드/상품명/상품금액)', field: 'product_info', cellClass: 'cp left ag-cell80h-br cell-wrap-text', autoHeight: true, width: 250, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          const brandName = ( params.data.brand_name ) ? params.data.brand_name + '<br />' : '';
          const productName = ( params.data.product_name ) ? '<span class="fb cgreen">' + params.data.product_name + '</span>' : '';
          const consumerPrice = '<br /><span class="fb">' + setComma(params.data.consumer_price) + ' 원</span>';

          return brandName + productName + consumerPrice;
        }
      },
      { headerName: '품절여부', field: 'status', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status === '0' ) {
            return '<span class="badge badge-success f12">판매중</span>';
          } else if ( params.data.status === '1' ) {
            return '<span class="badge badge-warning f12">임시품절</span>';
          } else if ( params.data.status === '2' ) {
            return '<span class="badge badge-danger f12">영구품절</span>';
          } else {
            return '';
          }
        }
      },
    ];

    this.rightColumnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center ag-cell80h', checkboxSelection: true },      
      { headerName: '자식상품번호', field: 'property_seq', cellClass: 'cp center ag-cell80h', width: 120 },
      { headerName: '이미지', field: 'thumbnail_url', cellClass: 'cp center ag-cell80h', width: 100, cellRenderer: 'agGridImageComponent' },
      { headerName: '색상', field: 'display_color_name', cellClass: 'cp left ag-cell80h', width: 195, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return '<span class="fb">' + params.data.display_color_name + '</span>';
        }
      },
      { headerName: '사이즈', field: 'display_size_name', cellClass: 'cp left ag-cell80h', width: 195, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return '<span class="fb">' + params.data.display_size_name + '</span>';
        }
      },
      { headerName: '품절여부', field: 'status', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status === '0' ) {
            return '<span class="badge badge-success f12">판매중</span>';
          } else if ( params.data.status === '1' ) {
            return '<span class="badge badge-warning f12">임시품절</span>';
          } else if ( params.data.status === '2' ) {
            return '<span class="badge badge-danger f12">영구품절</span>';
          } else {
            return '';
          }
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    // 메시지 표시 선언
    this.leftGridNoRowsTemplate = '카테고리 또는 브랜드 선택하거나 검색어를 입력하시기 바랍니다.';
    this.rightGridNoRowsTemplate = '추가할 상품을 왼쪽에서 [선택]영역을 클릭해 드래그하거나 더블클릭 하시기 바랍니다.';

    // 콤마 넣기
    function setComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 오픈마켓 상품찾기
    if( this.rowData !== undefined ) {
      if( this.rowData.p_code == undefined || this.rowData.p_code == '' ) {
        this.search.searchText = this.rowData.product_seq;
      } else {
        this.search.searchText = this.rowData.p_code;
      }

      this.getProductListForFind();
    }
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductListForFind() {
    this.productService.getProductListForFind( this.search ).then( response => {
      if( response.ResultCode ) {
        this.productList = response.data;
      } else {
        this.productList = [];

        this.toastrService.error( response.ResultMessage, '상품 가져오기');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( '서버와의 통신에 실패하였습니다 : ' + error, '상품 가져오기');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 속성 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPropertyListForFind() {
    this.productService.getPropertyListForFind( this.search.productSeq ).then( response => {
      if( response.ResultCode ) {
        this.propertyList = response.data;
      } else {
        this.propertyList = [];

        this.toastrService.error( response.ResultMessage, '속성 가져오기');
        this.activeModal.close();
      }
    }, error => {
      this.toastrService.error( '서버와의 통신에 실패하였습니다 : ' + error, '속성 가져오기');
      this.activeModal.close();
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onLeftGridReady(params) {
    this.leftGridApi = params.api;
    this.leftGridColumnApi = params.columnApi;
  }

  onRightGridReady(params) {
    this.rightGridApi = params.api;
    this.rightGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked( event: any ) {
    this.search.productSeq = event.data.seq;

    this.getPropertyListForFind();
  }
  
  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.leftGridApi.setQuickFilter(searchText.value);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 상품 선택 시 결과 돌려주기
    입력값 : 상품 정보
    리턴값 : 없음
  *******************************************************************************/
  selectProduct() {
    const rows = this.rightGridApi.getSelectedRows();

    // 선택된 상품에 한해서 가격정보 가져오기
    this.productService.getProductListForPropertyPrice( this.mem_no, [rows[0].property_seq] ).then( response => {

      if( response.ResultCode ) {

        response.data.forEach(item => {
          rows.forEach(row => {
            if( item.property_seq == row.property_seq ) {
              row.unit_price = item.unit_price;
              row.consumer_price = item.consumer_price;
              row.buy_price = item.buy_price;
              row.mileage = item.mileage;
              row.display_price = item.display_price;
              row.event_seq = item.event_seq;
              row.event_name = item.event_name;
            }
          });
        });

        this.activeModal.close( rows );

      } else {
        this.toastrService.error( response.ResultMessage, '상품 가격정보 가져오기');
        this.activeModal.close();
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      productSeq: ''
    };

    const quickFilter: any = document.getElementById('quickFilter');
    quickFilter.value = '';
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductListForFind();
    }
  }

  /*******************************************************************************
    설  명 : 창닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDismiss() {
    this.activeModal.dismiss();
  }

}
  