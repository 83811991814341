<section class="search-condition mb5">
  <div class="fl form-inline">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getReceiveList()">
      <i class="material-icons-outlined">search</i>
    </button>
    <input type="text" id="sdate" [(ngModel)]="search.sdate" size="12" style="width:110px;" class="form-control form-control-small calendar-input ml5" readonly="readonly" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
    <ng-template #footerTemplate1>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
      <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
    </ng-template>
    <span class="pl5 pr5">~</span>
    <input type="text" id="edate" [(ngModel)]="search.edate" size="12" style="width:110px;" class="form-control form-control-small calendar-input" readonly="readonly" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
    <ng-template #footerTemplate2>
      <hr class="my-0">
      <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
       <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
    </ng-template>
    <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.outgoing" (keypress)="searchList($event)" placeholder="발신처를 입력하세요." />
    <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.title" (keypress)="searchList($event)" placeholder="제목을 입력하세요." />
  </div>
  
  <div class="fr right">
    <!-- <button type="button" class="btn btn-default btn-small">엑셀다운</button> -->
    <ag-grid-excel [gridApi]="gridApi" selected="false" filename="공문서접수대장" title="공문서접수대장"></ag-grid-excel>
    <button type="button" class="btn btn-primary btn-small ml5" (click)="addDocument(0)">공문서 접수</button>
  </div>  
  
  <div class="cb"></div>  
</section>

<div class="mt5" style="height:calc(100vh - 130px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="receiveList"
    [pagination]="false"
    [domLayout]="domLayout"
    [getRowHeight]="getRowHeight"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"
    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</div>