import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { BasicService } from '@app/service/basic.service';
import { ProductService } from '@app/service/product.service';

import { ProductFindComponent } from '@components/product-find/product-find.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-product-sena-process',
  templateUrl: './product-sena-process.component.html',
  styleUrls: ['./product-sena-process.component.scss']
})
export class ProductSenaProcessComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() params: any;

  public statusList: any = [];
  public storeList: any = [];

  public senaInfo: any = {};

  public form: FormGroup;
  public formErrors = {};

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  public searchProductFunc: any; // 상품검색

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [Validators.required] ],
      sena_status: ['', [Validators.required] ],
      product_seq: ['', [] ],
      product_name: ['', [] ],
      serial: [[], [] ],
      memo: ['', [] ],
      sn1: ['', [] ],
      sn2: ['', [] ],
      buyer_id: ['', [] ],
      buyer_name: ['', [] ],
      buyer_hphone: ['', [] ],
      order_seq: ['', [] ],
      store_seq: ['', [] ],
      shop: ['', [] ],
      as_sn1: ['', [] ],
      as_sn2: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  
  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private basicService: BasicService,
    private productService: ProductService
  ) {
    this.searchProductFunc = this.openSearchProduct.bind(this);

    this.buildForm();

    this.columnDefs = [
      { headerName: 'SN1', field: 'sn1', width: 483, cellClass: 'cp left ag-cell-edit', editable: true },
      { headerName: 'SN2', field: 'sn2', width: 483, cellClass: 'cp left ag-cell-edit', editable: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.senaInfo = this.params.data;

    this.form.patchValue({
      seq: this.senaInfo.seq,
      sn1: this.senaInfo.sn1,
      sn2: this.senaInfo.sn2,
      buyer_id: this.senaInfo.buyer_id,
      // buyer_name: this.senaInfo.buyer_name,
      // buyer_hphone: this.senaInfo.buyer_hphone,
      buyer_name: ( this.senaInfo.r_name ? this.senaInfo.r_name : this.senaInfo.buyer_name ),
      buyer_hphone: ( this.senaInfo.r_hp ? this.senaInfo.r_hp : this.senaInfo.buyer_hphone ),
      order_seq: this.senaInfo.order_seq,
      store_seq: this.senaInfo.store_seq,
      shop: this.senaInfo.shop,
      as_sn1: this.senaInfo.as_sn1,
      as_sn2: this.senaInfo.as_sn2
    });

    this.getCommonList();

    this.getStoreList();

    this.addSerialRows( 10 );
  }

  /*******************************************************************************
    설  명 : 시리얼 입력 그리드 ROw 추가
  *******************************************************************************/
  addSerialRows( value: any ) {
    let serial = this.form.controls.serial.value;
    
    let tmp = [...serial];
    for(let i = 0; i < value; i++ ) {
      tmp.push({sn1: '', sn2: ''});
    }

    this.form.get('serial').setValue(tmp);
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
  *******************************************************************************/
  getCommonList() {
    // 세나 상태
    this.commonService.getCommonListCode('SNT').then( response => {
      if ( response.ResultCode ) {
        // this.statusList = response.data;

        response.data.forEach( row => {
          if( row.common_code != '0016' && row.common_code != '0059' ) {
            this.statusList.push(row);
          }
        });
      } else {
        this.statusList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 매장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStoreList() {
    this.basicService.getStoreList({}).then( response => {
      if( response.ResultCode ) {
        this.storeList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }
  
  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductSenaProcess() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.productService.setProductSenaProcess(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 상품 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openSearchProduct() {
    const modalRef = this.modalService.open(ProductFindComponent, options);

    modalRef.result.then((result) => {
      if( typeof result != 'undefined' ) {
        if( result.length > 1 ) {
          this.toastrService.error( '상품을 하나만 선택하시기 바랍니다.', '');
        } else {
          this.form.patchValue({
            product_seq: result[0].seq,
            product_name: result[0].product_name
          });
        }
      }
    }, (reason) => {
    });
  }
  
  /*******************************************************************************
    설  명 : 세나 상태 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSenaStatus( code: any ) {
    this.form.get('sena_status').setValue( code );

    if( code == '0011' || code == '0012' ) {
      this.form.get('product_seq').setValidators( [Validators.required] );
      this.form.get('product_seq').updateValueAndValidity();
      this.form.get('product_name').setValidators( [Validators.required] );
      this.form.get('product_name').updateValueAndValidity();
      this.form.get('serial').setValidators( [Validators.required] );
      this.form.get('serial').updateValueAndValidity();
    } else if( code == '0013' || code == '0014' || code == '0053' ) {
      this.form.get('serial').setValidators( [Validators.required] );
      this.form.get('serial').updateValueAndValidity();
      this.form.get('buyer_name').setValidators( [Validators.required] );
      this.form.get('buyer_name').updateValueAndValidity();
      this.form.get('order_seq').setValidators( [Validators.required] );
      this.form.get('order_seq').updateValueAndValidity();
    } else if( code == '0015' || code == '0052' || code == '0055' ) {
      this.form.get('serial').setValidators( [Validators.required] );
      this.form.get('serial').updateValueAndValidity();
    } else if( code == '0051' ) {
      this.form.get('serial').setValidators( [Validators.required] );
      this.form.get('serial').updateValueAndValidity();
      this.form.get('buyer_name').setValidators( [Validators.required] );
      this.form.get('buyer_name').updateValueAndValidity();
      this.form.get('order_seq').setValidators( [Validators.required] );
      this.form.get('order_seq').updateValueAndValidity();
      this.form.get('store_seq').setValidators( [Validators.required] );
      this.form.get('store_seq').updateValueAndValidity();
    } else if( code == '0054' ) {
      this.form.get('sn1').setValidators( [Validators.required] );
      this.form.get('sn1').updateValueAndValidity();
      this.form.get('sn2').setValidators( [Validators.required] );
      this.form.get('sn2').updateValueAndValidity();
      this.form.get('as_sn1').setValidators( [Validators.required] );
      this.form.get('as_sn1').updateValueAndValidity();
      this.form.get('as_sn2').setValidators( [Validators.required] );
      this.form.get('as_sn2').updateValueAndValidity();
      this.form.get('buyer_name').setValidators( [Validators.required] );
      this.form.get('buyer_name').updateValueAndValidity();
      this.form.get('order_seq').setValidators( [Validators.required] );
      this.form.get('order_seq').updateValueAndValidity();
    } else {
      this.form.get('product_seq').setValidators( [] );
      this.form.get('product_seq').updateValueAndValidity();
      this.form.get('product_name').setValidators( [] );
      this.form.get('product_name').updateValueAndValidity();
      this.form.get('sn1').setValidators( [] );
      this.form.get('sn1').updateValueAndValidity();
      this.form.get('sn2').setValidators( [] );
      this.form.get('sn2').updateValueAndValidity();
      this.form.get('as_sn1').setValidators( [] );
      this.form.get('as_sn1').updateValueAndValidity();
      this.form.get('as_sn2').setValidators( [] );
      this.form.get('as_sn2').updateValueAndValidity();
      this.form.get('buyer_name').setValidators( [] );
      this.form.get('buyer_name').updateValueAndValidity();
      this.form.get('order_seq').setValidators( [] );
      this.form.get('order_seq').updateValueAndValidity();
      this.form.get('serial').setValidators( [] );
      this.form.get('serial').updateValueAndValidity();
      this.form.get('store_seq').setValidators( [] );
      this.form.get('store_seq').updateValueAndValidity();
    }

    if( code == 'modify' ) {
      this.form.patchValue({
        product_seq: this.senaInfo.product_seq,
        product_name: this.senaInfo.product_name,
      });
    } else {
      this.form.patchValue({
        product_seq: '',
        product_name: '',
      });
    }
  }

  /*******************************************************************************
    설  명 : 폼 출력 체크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  trViewCheck( index: any ) {
    let senaStatus = this.form.controls.sena_status.value;

    let check: boolean;
    switch( index ) {
      case '1':
        if( senaStatus == '0011' || senaStatus == '0012' || senaStatus == 'modify' ) check = true;
        else check = false;
        break;
      case '2':
        if( senaStatus.substr(-1) == '1' || senaStatus.substr(-1) == '2' || senaStatus.substr(-1) == '5' ) check = true;
        else check = false;
        break;
      case '3':
        if( senaStatus != '' ) check = true;
        else check = false;
        break;
      case '4':
        if( senaStatus.substr(-1) == '3' || senaStatus.substr(-1) == '4' || senaStatus == 'modify' ) check = true;
        else check = false;
        break;        
      case '5':
      case '6':
        if( senaStatus == '0051' || senaStatus.substr(-1) == '3' || senaStatus.substr(-1) == '4' || senaStatus == 'modify' ) check = true;
        else check = false;
        break;
      case '7':
        if( senaStatus == '0051' ) check = true;
        else check = false;
        break;
      case '8':
        if( senaStatus == '0054' || senaStatus == 'modify' ) check = true;
        else check = false;
        break;
      default:
    }

    return check;
  }
  
}
