<form [formGroup]="formAdd">
  <input type="hidden" id="seq" formControlName="seq" />

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">브랜드 {{ groupUpdate == '1' ? '거래처별 일괄' : '' }}{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small">
    <caption class="hidden">브랜드 {{title}}</caption>

    <colgroup>
      <col style="width: 15%;" />
      <col style="width: 35%;">
      <col style="width: 15%;" />
      <col>
    </colgroup>

    <tbody>
      <tr>
        <th scope="row"><label for="customer_name" class="control-label">거래처명</label></th>
        <td colspan="3">
          <inputEx type="text" name="customer_name" [formGroup]="formAdd" [formErrors]="formErrors" [readonly]="true"></inputEx>
        </td>
      </tr>
    <tr>
      <th scope="row"><label for="brand_name" class="control-label"><i class="material-icons cred f11 lh20">star</i>브랜드명</label></th>
      <td colspan="3">
        <inputEx type="text" name="brand_name" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="brand_ename" class="control-label">브랜드명(영문)</label></th>
      <td colspan="3">
        <inputEx type="text" name="brand_ename" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="contents" class="control-label">브랜드설명</label></th>
      <td colspan="3">
        <inputEx type="textarea" name="contents" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="use_yn" class="control-label"><i class="material-icons cred f11 lh20">star</i>사용여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.use_yn.value=='1'}" (click)="formAdd.patchValue({use_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.use_yn.value=='0'}" (click)="formAdd.patchValue({use_yn: '0'})">사용안함</button>
        </div>
      </td>
      <th scope="row"><label for="purchasing_agent_yn" class="control-label"><i class="material-icons cred f11 lh20">star</i>구매대행 브랜드 여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.purchasing_agent_yn.value=='1'}" (click)="formAdd.patchValue({purchasing_agent_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.purchasing_agent_yn.value=='0'}" (click)="formAdd.patchValue({purchasing_agent_yn: '0'})">사용안함</button>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="rma_yn" class="control-label"><i class="material-icons cred f11 lh20">star</i>RMA관리 여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.rma_yn.value=='1'}" (click)="formAdd.patchValue({rma_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.rma_yn.value=='0'}" (click)="formAdd.patchValue({rma_yn: '0'})">사용안함</button>
        </div>
      </td>
      <th scope="row"><label for="rma_yn" class="control-label"><i class="material-icons cred f11 lh20">star</i>자사/타사 여부</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.subsidiary_yn.value=='1'}" (click)="formAdd.patchValue({subsidiary_yn: '1'})">자사</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.subsidiary_yn.value=='0'}" (click)="formAdd.patchValue({subsidiary_yn: '0'})">타사</button>
        </div>
      </td>
    </tr>
    <tr>
    </tr>
    <tr>
      <th scope="row"><label for="isRecommend" class="control-label"><i class="material-icons cred f11 lh20">star</i>추천브랜드</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.isRecommend.value=='1'}" (click)="formAdd.patchValue({isRecommend: '1'})">추천</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.isRecommend.value=='0'}" (click)="formAdd.patchValue({isRecommend: '0'})">비추천</button>
        </div>
      </td>
      <th scope="row"><label for="isPremium" class="control-label"><i class="material-icons cred f11 lh20">star</i>프리미엄브랜드</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.isPremium.value=='1'}" (click)="formAdd.patchValue({isPremium: '1'})">프리미엄</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.isPremium.value=='0'}" (click)="formAdd.patchValue({isPremium: '0'})">일반</button>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="atock_mng_yn" class="control-label"><i class="material-icons cred f11 lh20">star</i>재고자동반영 여부</label></th>
      <td  colspan="3">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.atock_mng_yn.value=='1'}" (click)="formAdd.patchValue({atock_mng_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.atock_mng_yn.value=='0'}" (click)="formAdd.patchValue({atock_mng_yn: '0'})">사용안함</button>
        </div>
      </td>
    </tr>
    <ng-container *ngIf="formAdd.controls.customer_brand_seq.value">
      <tr>
        <th scope="row"><label for="caution_position" class="control-label"><i class="material-icons cred f11 lh20">star</i>주의사항 표시위치</label></th>
        <td  colspan="3">
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.caution_position.value=='U'}" (click)="formAdd.patchValue({caution_position: 'U'})">상단</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: formAdd.controls.caution_position.value=='D'}" (click)="formAdd.patchValue({caution_position: 'D'})">하단</button>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="file">이미지</label></th>
        <td  colspan="3">
          <inputEx type="file" name="file" [formGroup]="formAdd" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
          <div class="progress mt5 mb5">
            <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
          </div>
          <div class="mt5">
          ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 50MB 제한, 가로*세로 : 1000*1000픽셀 (필히 등록)
          </div>
          <div *ngIf="formAdd.controls.files.value.length > 0 || formAdd.controls.upload.value.length > 0" class="file-div">
            <ul class="file-ul">
              <li *ngFor="let file of formAdd.controls.upload.value; let i = index" class="fl">
                <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                  <div *ngIf="checkImage(getExt(file.origin_filename))" class="upload-image">
                    <img [src]="file.url" alt="{{file.origin_filename}}" style="max-width: 100%; width: 100%;" />
                  </div>
                  <div *ngIf="!checkImage(getExt(file.origin_filename))" class="upload-image1">{{getExt(file.origin_filename)}}</div>
                  <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin_filename}}">{{file.origin_filename}}</a></div>
                  <div class="upload-size">
                    ({{getComma(file.filesize)}} KB)
                    <a class="cp ml5" (click)="setDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                  </div>
                </div>
              </li>
              <li *ngFor="let file of formAdd.controls.files.value; let i = index" class="fl">
                <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
                  <div *ngIf="checkImage(getExt(file.origin))" class="upload-image">
                    <img [src]="file.url" alt="{{file.origin}}" style="max-width: 100%; width: 100%;" />
                  </div>
                  <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                  <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                  <div class="upload-size">
                    ({{getComma(file.size)}} KB)
                    <a class="cp ml5" (click)="deleteFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                  </div>
                </div>
              </li>
              <li class="cb"></li>
            </ul>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="caution">주의사항</label></th>
        <td  colspan="3">
          <inputEx type="summernote" name="caution" [formGroup]="formAdd" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"></th>
        <td  colspan="3"><span class="cred f16">※ 에디터에 이미지를 첨부하려면 위에서 이미지 업로드 한 후에 첨부하려는 이미지를 드래그 해서 에디터 원하는곳에 놓아주세요.</span></td>
      </tr>
    </ng-container>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fr w50p right ml0 mr0">
      <ng-container>
        <button type="submit" class="btn btn-primary btn-small mr10 f15" (click)="submit()">저장하기</button>
      </ng-container>
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>