/*******************************************************************************
  설  명 : 회원정보 - 환불/교환/반품내역
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-member-refund',
  templateUrl: './member-refund.component.html',
  styleUrls: ['./member-refund.component.scss']
})
export class MemberRefundComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() dormant: any;

  search: any = {
    sdate: '',
    edate: ''
  }

  // 그리드 관련 선언
  memberRefundList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  selectedRows: number = 0;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'storeSeq', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '이미지', field: 'storeName', width: 130, cellClass: 'cp center' },
      {headerName: '모델', field: 'storeTypeName', width: 80, cellClass: 'cp' },
      {headerName: '주문자', field: 'storeTypeName', width: 100, cellClass: 'cp center' },
      {headerName: '등록일', field: 'storeTypeName', width: 500, cellClass: 'cp center' },
      {headerName: '요청일', field: 'storeTypeName', width: 100, cellClass: 'cp center' },
      {headerName: '환불사유', field: 'storeTypeName', width: 100, cellClass: 'cp' },
      {headerName: '동봉배송비', field: 'storeTypeName', width: 100, cellClass: 'cp right' },
      {headerName: '수량', field: 'storeTypeName', width: 100, cellClass: 'cp right' },
      {headerName: '환불금액', field: 'storeTypeName', width: 100, cellClass: 'cp right' },
      {headerName: '환불방법', field: 'storeTypeName', width: 100, cellClass: 'cp center' },
      {headerName: '진행상태', field: 'storeTypeName', width: 100, cellClass: 'cp center' },
      {headerName: '완료일', field: 'storeTypeName', width: 100, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }
}
