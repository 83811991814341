import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { CompanyService } from '@app/service/company.service';
import { CommonService } from '@app/service/common.service';
import { MemberService } from '@app/service/member.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { MemberSocietyAddComponent } from '@page/member/society/member-society/member-society-add/member-society-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-society',
  templateUrl: './member-society.component.html',
  styleUrls: ['./member-society.component.scss']
})
export class MemberSocietyComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
    isApproval: '',
    region_cd: '',
  };

  public month: any = moment().format('MM');
  public societyList: any = [];
  public societyMemberList: any = [];  
  public memberList: any = [];
  public regionList: any = [];
  public applicationMemo: any = [];
  public counselingMemo: any = [];

  public societySelected: any = {};

  public statistic: any = {
    totalCount: '0',
    approvalCount: '0'
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridDetailApi: any;
  gridDetailColumnApi: any;
  columnDetailDefs: any;

  gridMemoApi: any;
  gridMemoColumnApi: any;
  columnMemoDefs: any;

  gridMemo1Api: any;
  gridMemo1ColumnApi: any;
  columnMemo1Defs: any;


  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private memberService: MemberService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
        { headerName: '순번', field: 'rowIndex', width: 50, cellClass: 'cp center', 
        valueGetter: function(params) {
          let rowData = params.node.gridOptionsWrapper.gridOptions.rowData.length;
          let rowIndex = params.node.rowIndex;
          return parseInt(rowData) - rowIndex;
        }
        // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
        },
      { headerName: '동호회명', field: 'society_name', width: 200, cellClass: 'cp left' },
      { headerName: '지역', field: 'region_name', width: 100, cellClass: 'cp left' },
      { headerName: '대표자', field: 'rep_name', width: 120, cellClass: 'cp left' },
      { headerName: '신청일자', field: 'application_date', width: 150, cellClass: 'cp left' },
      { headerName: '승인여부', field: 'approvalText', width: 100, cellClass: 'cp left', cellRenderer: 'agGridHtmlComponent',
      valueGetter: function(params) {
        if( params.data.is_approval == '1' ) {
          return '<span class="badge badge-primary f12">' + params.data.approvalText + '</span>';
        } else  {
          return '<span class="badge badge-secondary f12">' + params.data.approvalText + '</span>';
        } 
      }
      },

      { headerName: '승인일자', field: 'approval_date', width: 150, cellClass: 'cp left'},
      { headerName: '회원수', field: 'memberCount', width: 90, cellClass: 'cp right' }
    ];

    this.columnDetailDefs = [
      { headerName: '순번', field: 'rowIndex', width: 50, cellClass: 'cp center', 
      valueGetter: function(params) {
        let rowData = params.node.gridOptionsWrapper.gridOptions.rowData.length;
        let rowIndex = params.node.rowIndex;
        return parseInt(rowData) - rowIndex;
      }
      // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
    { headerName: '구분', field: 'repGbn', width: 60, cellClass: 'cp left' , cellRenderer: 'agGridHtmlComponent',
      valueGetter: function(params) {
        if( params.data.repGbn == '대표자' ) {
          return '<span class="badge badge-primary f12">' + params.data.repGbn + '</span>';
        } else  {
          return '<span class="badge badge-secondary f12">' + params.data.repGbn + '</span>';
        } 
       }
      },
      { headerName: '회원번호', field: 'mem_no', width: 80, cellClass: 'cp left' },
      { headerName: '아이디', field: 'id', width: 120, cellClass: 'cp left' },
      { headerName: '회원명', field: 'member_name', width: 130, cellClass: 'cp left' },

      // { headerName: '승인여부', field: 'approvalText', width: 80, cellClass: 'cp left' , cellRenderer: 'agGridHtmlComponent',
      // valueGetter: function(params) {
      //   if( params.data.approvalText == '승인' ) {
      //     return '<span class="badge badge-primary f12">' + params.data.approvalText + '</span>';
      //   } else  {
      //     return '<span class="badge badge-secondary f12">' + params.data.approvalText + '</span>';
      //   } 
      //  }
      // },
  
      { headerName: '매출금액', field: 'saleAmt', width: 120, cellClass: 'cp right' },
    ];

    this.columnMemoDefs = [
      { headerName: '문의내용', field: 'application_memo', width: 460, cellClass: 'cp left cell-wrap-text', cellRenderer: 'agGridHtmlComponent' },
    ];

    this.columnMemo1Defs = [
      { headerName: '관리자메모 (더블클릭 = 수정모드, Enter Key 입력 = 자동 저장, 줄바꿈 = Shift + Enter Key)', field: 'counseling_memo', width: 900, cellClass: 'cp left ag-cell-edit cell-wrap-text', 
        autoHeight: true, 
        editable: true, 
        // singleClickEdit: true,
        // cellEditorPopup: true,
        cellEditor: 'agLargeTextCellEditor' ,
        cellEditorParams: function(params: any) {
          let counseling_memo = params.data.counseling_memo;
          return {
            cols: 200,
            rows: 20,
            value : counseling_memo ? counseling_memo.replace(/<br>/g, '\n') : ''
          }
        },
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params: any) {
          let counseling_memo = params.data.counseling_memo;
          return counseling_memo ? counseling_memo.replace(/\n/g, '<br>') : '';
        }
      },
    ];


    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true
    };

    this.rowSelection = 'single';
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  getRowHeight = () => 380;

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getSocietyWidget();
    this.getRegionList();
    this.getList();

  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridDetailReady(params) {
    this.gridDetailApi = params.api;
    this.gridDetailColumnApi = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
    onGridMemoReady(params) {
      this.gridMemoApi = params.api;
      this.gridMemoColumnApi = params.columnApi;
    }

   /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
    onGridMemo1Ready(params) {
      this.gridMemo1Api = params.api;
      this.gridMemo1ColumnApi = params.columnApi;
    }   
    

    /*******************************************************************************
    설  명 : 지역코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getRegionList() {
      // 지역구분
      this.commonService.getCommonListCode('ADDR').then( response => {
        if ( response.ResultCode ) {
          this.regionList = response.data;
        } else {
          this.regionList = [];
        }
      });
    }

    
  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      isApproval: '' , 
      region_cd : '',
    };

    this.getSocietyWidget();
    this.getList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getSocietyWidget();
      this.getList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 조건 버튼 클릭 시
    입력값 : field, value
    리턴값 : 없음
  *******************************************************************************/
  setSearchValue( field: any, value: any ) {

    this.search[ field ] = value;

    this.getSocietyWidget();
    this.getList();
  }

  /*******************************************************************************
    설  명 : 지역 조건 버튼 클릭 시
    입력값 : event
    리턴값 : 없음
  *******************************************************************************/
    setRegionValue(e) {
      this.search.region_cd =  e.target.value;
      this.getSocietyWidget();
      this.getList();
    }  

  /*******************************************************************************
    설  명 : 동호회리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getList() {
      this.memberService.getSocietyList({...this.search}).then( response => {
        if( response.ResultCode ) {
          this.societyList = response.data;

          this.applicationMemo = [];
          this.counselingMemo = [];


          // 데이터가 있으면 첫번째 열의 디테일을 뿌린다. 
          if (this.societyList.length > 0 ) {

            if( this.societySelected.seq === undefined ) {
              let index = 0;
              setTimeout(() => {
                this.gridApi.forEachNodeAfterFilter(function(node: any) {
                  // select the node
                  if( index++ == 0 ) {
                    node.setSelected(true);
                  }
                });
              }, 0);
  
              this.societySelected = this.societyList[0];
  
              this.applicationMemo = [{
                content: this.societyList[0].application_memo
              }];
              
              this.counselingMemo = [{
                seq: this.societyList[0].seq,
                counseling_memo: this.societyList[0].counseling_memo
              }];
  

            } else {
              const selectedSeq = this.societySelected.seq;
              setTimeout(() => {
                this.gridApi.forEachNodeAfterFilter(function(node: any) {
                  // select the node
                  if( node.data.seq == selectedSeq ) {
                    node.setSelected(true);
                  }
                });
              }, 0);
  
              this.societySelected = this.societyList.filter((obj: any) => obj.seq === this.societySelected.seq)[0];
              if( this.societySelected.length > 0 ) {
                this.societyList = [{
                  content: this.societySelected.content
                }];
                
                this.counselingMemo = [{
                  seq: this.societySelected.seq,
                  counseling_memo: this.societySelected.counseling_memo
                }];
  
              }
            }

            this.getMemberList(this.societyList[0].seq);  

          }



        } else {
          this.toastrService.error(response.ResultMessage || '동호회 상담 정보 가져오기 실패 ');
        }
      }).catch(e => this.toastrService.error(e));
    }

    /*******************************************************************************
    설  명 : 동호회 회원리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getMemberList(seq:any) {
      this.memberService.getSocietyMemberList(seq).then( response => {
        if( response.ResultCode ) {
          this.societyMemberList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }  

  /*******************************************************************************
    설  명 : 동호회 위젯 정복 가져오기 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getSocietyWidget() {
      this.memberService.getSocietyWidget().then( response => {
        if( response.ResultCode ) {
          this.statistic.totalCount  = response.data.totalCount;
          this.statistic.approvalCount  = response.data.approvalCount;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
    




  /*******************************************************************************
    설  명 : 동호회 추가
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
  addSociety(seq: any) {
    const modalRef = this.modalService.open(MemberSocietyAddComponent, options);
    modalRef.componentInstance.seq = seq;
    modalRef.result.then((result) => {
      if( result ) {
         this.getList();
      }
    }, (reason) => {
    });
  }  
  /*******************************************************************************
    설  명 : ag grid 행 더블 클릭 시 처리 - 동호회 정보수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellDoubleClicked($event) {
      if( $event.colDef.field !== 'seq' ) {
        this.addSociety( $event.data.seq );
      } else {
  
      }
    }
  
    /*******************************************************************************
    설  명 : ag grid 행  클릭 시 처리 - 동호회 회원정보
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onCellClicked(event) {
      if( event.colDef.field !== 'seq' ) {

        this.societySelected = event.data;

        this.applicationMemo = [];
        this.counselingMemo = [];

        this.applicationMemo.push({
          application_memo: event.data.application_memo
        });        
        this.counselingMemo.push({
          seq: event.data.seq,
          counseling_memo: event.data.counseling_memo
        });

        console.log(this.counselingMemo);
       
        this.getMemberList(event.data.seq);
      } else {
  
      }
    }  

   /*******************************************************************************
    설  명 : 동호회 회원삭제추가
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
    delSocietyMember() {
      let node:any = this.gridDetailApi.getSelectedRows();
      let nodeMaster:any = this.gridApi.getSelectedRows();
      
      console.log(`선택자 확인 ${node[0].repGbn}`);
      
      if (node.length == 0) {
        this.toastrService.error( '삭제할 회원을 선택하세요.', '동호회 회원삭제');
      } else if (node[0].repGbn ==='대표자') {
        this.toastrService.error( '대표자는 삭제 할 수 없습니다.', '동호회 회원삭제');
      } else {
        this.memberService.delSocietyMember(node[0].seq).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '동호회 회원삭제');
           this.getMemberList(nodeMaster[0].seq);  
            // 
          } else {
            this.toastrService.error( response.ResultMessage, '동호회 회원삭제');
          }
        }, error => {
          this.toastrService.error( error, '');
        });
      }

    }    

   /*******************************************************************************
    설  명 : 동호회 회원 승인/미승인처리
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
    appSocietyMember() {
      let node:any = this.gridDetailApi.getSelectedRows();
      let nodeMaster:any = this.gridApi.getSelectedRows();
      
      
      if (node.length == 0) {
        this.toastrService.error( '승인 처리할 회원을 선택하세요.', '동호회 회원승인처리');
      } else if (node[0].repGbn ==='대표자' && node[0].approvalText ==='승인') {
        this.toastrService.error( '대표자는  승인상태를 유지해야합니다.', '동호회 회원승인처리');
      } else {
        this.memberService.appSocietyMember(node[0].seq).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '동호회 회원 승인처리');
           this.getMemberList(nodeMaster[0].seq);  
            // 
          } else {
            this.toastrService.error( response.ResultMessage, '동호회 회원 승인처리');
          }
        }, error => {
          this.toastrService.error( error, '');
        });
      }

    }    



   /*******************************************************************************
    설  명 : 셀 에디트 변경 시
  *******************************************************************************/
    onCellEditingStopped(event: any) {
      
      console.log('이벤트 데이터 확인');
      console.log(event.data);
      if( event.data.counseling_memo !== undefined ) {
        if( this.societySelected.counseling_memo != event.data.counseling_memo ) {
          this.memberService.setSocietyCounselingMemo( event.data ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');
              this.getList();
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });
        }
      }
      
    }
       
  
}
  