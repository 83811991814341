import { Component, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { RestfulService } from "@app/service/restful.service";

@Component( {
  selector: "btn-ag-grid-save",
  templateUrl: "./ag-grid-save.component.html",
  styleUrls: ["./ag-grid-save.component.scss"]
} )

export class AgGridSaveComponent {
  _gridColumnsApi: any;

  @Input()
  set gridColumnsApi(value: any) {
    this._gridColumnsApi = value;

    this.loadConfig();
  }

  @Input() gridId: String;
  @Input() btnGroup: number;

  constructor(
    private toastrService: ToastrService,
    private restful: RestfulService,
  ) {
  }

  loadConfig() {
    if (!this._gridColumnsApi || !this.gridId) {
      return false;
    }

    this.restful.get( {
      program: "admin",
      service: "grid",
      version: "1.0",
      action: "getConfigGrids"
    }, {
      grid_id: this.gridId,
    } ).then(response => {
      if (response.ResultCode) {
        this._gridColumnsApi.moveColumns(
          response.data.columns.map(column => column.column_id), 0
        );

        this._gridColumnsApi.setColumnWidths(
          response.data.columns.map(column => ( {
            key: column.column_id,
            newWidth: column.width
          } ) ),
        );
      }
    } );
  }

  saveConfig() {
    if (!this._gridColumnsApi || !this.gridId) {
      return false;
    }

    this.restful.post( {
      program: 'admin',
      service: 'grid',
      version: '1.0',
      action: 'setConfigGrids'
    }, {
      grid_id: this.gridId,
      columns: this._gridColumnsApi.columnController.allDisplayedColumns.map(column => ( {
        column_id: column.colId,
        width: column.actualWidth,
      } ) ),
    } ).then(response => {
      if (response.ResultCode) {
        this.toastrService.success(response.ResultMessage);
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    } );
  }
}
