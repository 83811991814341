import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { WorkmanagementUsevacationLatenessComponent } from '@page/workmanagement/workmanagement-usevacation/workmanagement-usevacation-lateness/workmanagement-usevacation-lateness.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemGroupService } from '@app/service/system.group.service';
import { EmployeeService } from '@app/service/employee.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-workmanagement-usevacation',
  templateUrl: './workmanagement-usevacation.component.html',
  styleUrls: ['./workmanagement-usevacation.component.scss']
})
export class WorkmanagementUsevacationComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
    @ViewChild('tree') tree: any;

    public search: any = {
      type: 'M',
      dept_seq: '',
      mem_no: '',
      year: parseInt( moment().format('YYYY'), 10 )
    };
  
    public vacationList: any = [];
    public nodeData: any = [];
  
    public vacationDay: any = [];
  
    public year = 0;
  
    public term: any = {
      term1: 0,
      term2: 0,
      term3: 0,
      term4: 0,
      term5: 0,
      term6: 0,
      term7: 0,
      total: 0
    };
  
  
    // 그리드 관련 선언
  
    gridApi: any;
    gridColumnApi: any;
    columnDefs: any;
  
    defaultColDef: any;
    domLayout: any;
    rowSelection: any;
  
    noRowsTemplate: string;
  
    // 그리드 이미지 처리
    frameworkComponents = {
      agGridHtmlComponent: AgGridHtmlComponent
    };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private systemGroupService: SystemGroupService,
    private employeeService: EmployeeService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '기간', field: 'sdate_edate', width: 300, cellClass: 'cp center' },
      { headerName: '근태구분', field: 'common_name', width: 120, cellClass: 'cp center' },
      { headerName: '사용일수', field: 'term', width: 100, cellClass: 'cp' },
      { headerName: '내용', field: 'subject', width: 350, cellClass: 'cp' },
      { headerName: '연차적용여부', field: 'annual_check', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.annual_check == 'Y') return '<span class="badge badge-success f12 fn">적용</span>';
          else return '<span class="badge badge-secondary f12 fn">미적용</span>';
        }
      },
      { headerName: '승인여부', field: 'approval_check', width: 150, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.approval_check == 'Y') return '<span class="badge badge-success f12 fn">승인</span>';
          else return '<span class="badge badge-secondary f12 fn">미승인</span>';
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }
  ngOnInit(): void {
    this.getGroupEmployeeList();

    this.getAnnualEmployeeList();
    this.getAnnualEmployeeVacationDay();
    this.getPlus();
  }

  onBtnClick() {
    let param = {
      seq: '',
      document_type: '1008',
      routeParams: '1'
    }
    this.router.navigate(
      ['/approval'],
      {
        relativeTo: this.activatedRoute,
        queryParams: param,
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 연도 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setYear(event) {
    this.search.year = event.target.value;
    
    this.getAnnualEmployeeList();
    this.getAnnualEmployeeVacationDay();
    this.getPlus();
  }

  /*******************************************************************************
    설  명 : 노드선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onSelectNode(event: any) {
    let data: any = event;

    this.search.mem_no = data.mem_no;
    this.search.dept_seq = data.seq;

    if( data.mem_no == null || data.mem_no == undefined ) {
      this.search.type = 'D';
    } else {
      this.search.type = 'M';
    }
    
    this.getAnnualEmployeeList();
    this.getAnnualEmployeeVacationDay();
    this.getPlus();
  }


  /*******************************************************************************
    설  명 : 사용자 포함 조직도 리스트 가져오기 (TreeView)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeList() {
    this.systemGroupService.getGroupEmployeeList().then( response => {
      if ( response.ResultCode ) {
        this.nodeData = response.data;
      }
    });
  }

  getAnnualEmployeeList() {
    this.employeeService.getAnnualEmployeeList(this.search).then(response => {
      if( response.ResultCode ) {
        this.vacationList = response.data;
        this.getPlus();

      } else {
        this.vacationList = [];
      }
    });
  }

  getAnnualEmployeeVacationDay() {
    this.employeeService.getAnnualEmployeeVacationDay(this.search).then(response => {
      if(response.ResultCode ) {
        this.vacationDay = response.data;
        this.getPlus();
      } else {
        this.vacationDay = [];
      }
    })
  }

  getPlus() {
    let total = 0;
    let term = {
      term1: 0,
      term2: 0,
      term3: 0,
      term4: 0,
      term5: 0,
      term6: 0,
      term7: 0,
      total: 0
    }

    let totalSum = 0;

    for( let i of this.vacationList ) {
      if( i.approval_check == 'Y' ) {
        if( i.type == '10' ) {
          term.term1 += parseInt(i.term);
        } else if( i.type == '20' ) {
          term.term2 += parseInt(i.term) / 2;
        } else if( i.type == '30' ) {
          term.term3 += parseInt(i.term);
        } else if( i.type == '40' ) {
          term.term4 += parseInt(i.term);
        } else if( i.type == '60' ) {
          term.term5 += parseInt(i.term);
        } else if( i.type == '70' ) {
          term.term6 = parseInt(i.term);
        } else if( i.type == '80' ) {
          term.term7 += parseInt(i.term) / 4;
        }

        if( i.c1 == 'Y' ) {
          totalSum += parseFloat(i.d1) * parseInt(i.term);
        }
        
      }
    }

    if ( this.vacationDay.vacation_day ) {
      total = this.vacationDay.vacation_day;
    }

    term.total = total - totalSum;

    this.term.term1 = term.term1;
    this.term.term2 = term.term2;
    this.term.term3 = term.term3;
    this.term.term4 = term.term4;
    this.term.term5 = term.term5;
    this.term.term6 = term.term6;
    this.term.term7 = term.term7;
    this.term.total = term.total;

  }

  /*******************************************************************************
    설  명 : 지각추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  latenessAdd() {
    const modalRef = this.modalService.open(WorkmanagementUsevacationLatenessComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
        this.getAnnualEmployeeList();
        this.getAnnualEmployeeVacationDay();
      }
    }, (reason) => {
    });
  }  

  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      type: 'M',
      dept_seq: '',
      mem_no: '',
      year: parseInt( moment().format('YYYY'), 10 )
    };

    this.getAnnualEmployeeList();
  }

}
