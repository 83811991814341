import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    const body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // SMS 발송양식 리스트 가져오기
  getSMSFormList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'getSMSFormList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // SMS 발송양식 저장
  setSMSForm( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'setSMSForm'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 정보 삭제하기
  delSMSForm( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'delSMSForm'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // SMS 발송내역
  getSMSSendList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'getSMSSendList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // SMS 발송통계
  getStatistic( search: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'sms',
      version: '1.0',
      action: 'getStatistic'
    }, search
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  /* SMS 전송 */
  setSendSMS( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'setSendSMS'
      }, {
        seq: params.seq,
        title: params.title,
        message: params.message,
        members: params.members,
        callback: params.callback,
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  // SMS 전송 directsend
  sendSMS_directsend( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'sendSMS_directsend'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 반품/교환 승인시 sms 자동 발송
  send_smsConfirmExchange( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'send_smsConfirmExchange'
      }, {
        params
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 마이페이지 회원 주문 확정 
  send_smsPaymentConfirm( seq: any, hp: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'send_smsPaymentConfirm'
      }, {
        seq: seq,
        hp: hp
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 1:1 문의 답변 sms 자동 발송
  replyQuestion( board_seq: any, hp: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'sms',
        version: '1.0',
        action: 'replyQuestion'
      }, {
        board_seq: board_seq,
        hp: hp
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
}
