<section id="warehouse">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="40" minSize="30" maxSize="50">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getStoreList()">
            <i class="material-icons-outlined">search</i>
          </button>

          <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="매장명 검색" />
        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="71377317094a4876a1d987bf7dc7f9ee" [btnGroup]="1"></btn-ag-grid-save>

          <button type="button" title="창고추가" class="btn btn-primary btn-small-icon ml5" (click)="setStoreAdd()">
            <i class="material-icons-outlined">add</i>
          </button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="storeList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="60" minSize="50" maxSize="70">
      <section class="store-info">

        <form [formGroup]="form">

        <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

        <div class="">
          <div class="fl w50p">
            <h4 class="fb mt5 mb6">매장 정보 - {{storeInfo.store_name}}</h4>
          </div>
          <div class="fr w50p right">
            <button *ngIf="form.controls.seq.value!=null" type="button" class="btn btn-danger btn-small mr5" (click)="setStoreDelete()">삭제하기</button>
            <button type="button" class="btn btn-primary btn-small" (click)="setStoreSave()">저장하기</button>
          </div>
          <div class="cb"></div>
        </div>

        <table class="table table-bordered table-small">
        <caption class="none">매장정보</caption>
        <colgroup>
          <col style="width:15%;" />
          <col style="width:35%;" />
          <col style="width:15%;" />
          <col style="width:35%;" />
        </colgroup>

        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="store_name">매장명</label></th>
          <td><inputEx type="text" name="store_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="onoff_gbn">매장구분</label></th>
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.onoff_gbn.value=='ONLINE'}" (click)="form.get('onoff_gbn').setValue('ONLINE')">온라인매장</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.onoff_gbn.value=='OFFLINE'}" (click)="form.get('onoff_gbn').setValue('OFFLINE')">오프라인매장</button>
            </div>
            <inputEx type="hidden" name="onoff_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="store_gbn">사업장구분</label></th>
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.store_gbn.value=='D'}" (click)="form.get('store_gbn').setValue('D')">직영점</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.store_gbn.value=='F'}" (click)="form.get('store_gbn').setValue('F')">가맹점</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.store_gbn.value=='A'}" (click)="form.get('store_gbn').setValue('A')">대리점</button>
            </div>
            <inputEx type="hidden" name="store_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
          <th scope="row"><label for="store_zipcode">우편번호</label></th>
          <td>
            <div class="input-group">
              <inputEx name="store_zipcode" class="mr3" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
              <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="store_address">주소</label></th>
          <td><inputEx type="text" name="store_address" [formGroup]="form" [formErrors]="formErrors" readonly="true" placeholder="우편번호 찾기를 이용하세요"></inputEx></td>
          <th scope="row"><label for="store_address_detail">상세주소</label></th>
          <td><inputEx type="text" name="store_address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        <tr>
          <th scope="row"><label for="latitude">위도</label></th>
          <td><inputEx type="text" name="latitude" [formGroup]="form" [formErrors]="formErrors" placeholder="우편번호 찾기를 이용하세요" readonly="true"></inputEx></td>
          <th scope="row"><label for="longitude">경도</label></th>
          <td><inputEx type="text" name="longitude" [formGroup]="form" [formErrors]="formErrors" placeholder="우편번호 찾기를 이용하세요" readonly="true"></inputEx></td>
        </tr>
        </tbody>
        </table>

        <div class="">
          <h4 class="fb mt5 mb6">사용창고 정보 정보</h4>
        </div>

        <table class="table table-bordered table-small">
        <caption class="none">담당자 정보</caption>
        <colgroup>
          <col style="width:15%;" />
          <col style="width:35%;" />
          <col style="width:15%;" />
          <col style="width:35%;" />
        </colgroup>

        <tbody>
        <tr>
          <th scope="row"><label for="wh_basic">기본창고</label></th>
          <td>
            <inputEx type="select" name="wh_basic" [formGroup]="form" [formErrors]="formErrors" [data]="warehouseList"></inputEx>
          </td>
          <th scope="row"><label for="wh_output">출고창고</label></th>
          <td>
            <inputEx type="select" name="wh_output" [formGroup]="form" [formErrors]="formErrors" [data]="warehouseList"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="wh_return">반품창고</label></th>
          <td>
            <inputEx type="select" name="wh_return" [formGroup]="form" [formErrors]="formErrors" [data]="warehouseList"></inputEx>
          </td>
          <th scope="row"><label for="wh_RMA">RMA창고</label></th>
          <td>
            <inputEx type="select" name="wh_RMA" [formGroup]="form" [formErrors]="formErrors" [data]="warehouseList"></inputEx>
          </td>
        </tr>
        </tbody>
        </table>

        <div class="">
          <h4 class="fb mt5 mb6">매장 기본 정보</h4>
        </div>

        <table class="table table-bordered table-small">
        <caption class="none">매장 기본 정보</caption>
        <colgroup>
          <col style="width:100%;" />
        </colgroup>

        <tbody>
        <tr>
          <td>
            <inputEx type="textarea" name="store_info" [rows]="4" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        </tbody>
        </table>

        <div class="mt10">
          <div class="fl w50p">
            <h4 class="fb mt5 mb6">담당자 정보</h4>
          </div>
          <div class="fr w50p right">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApiCharge" gridId="16f3f9aa9ee242399a611c2538777bde" [btnGroup]="1"></btn-ag-grid-save>
            <button type="button" class="btn btn-danger btn-small ml5" (click)="setStoreChargeDelete()">담당자삭제</button>
            <button type="button" class="btn btn-primary btn-small ml5" (click)="setStoreChargeAdd(0)">담당자추가</button>
          </div>
          <div class="cb"></div>
        </div>

        <div style="height:200px">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefsCharge"
            [defaultColDef]="defaultColDef"
            [rowData]="chargeList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"

            (rowDoubleClicked)="setStoreChargeAdd($event.data.seq)"
            (gridReady)="onGridReadyCharge($event)"
            >
          </ag-grid-angular>
        </div>



        <div class="">
          <h4 class="fb mt5 mb6">매장 컨텐츠 정보</h4>
        </div>

        <inputEx type="summernote" name="store_contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfigDefault"></inputEx>

        </form>

      </section>
    </as-split-area>
  </as-split>
</section>
