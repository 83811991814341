<section id="supersale">
  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" (click)="getSuperSaleList('')" class="btn btn-default btn-small-icon">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" (click)="getSuperSaleList(search.status)" class="btn btn-default btn-small-icon ml5">
        <i class="material-icons-outlined">search</i>
      </button>

      <div class="btn-group ml5">
        <button type="button" [ngClass]="{active: search.status==''}" (click)="getSuperSaleList('')" class="btn btn-default btn-small">전체</button>
        <button type="button" [ngClass]="{active: search.status=='1'}" (click)="getSuperSaleList('1')" class="btn btn-default btn-small">진행중</button>
        <button type="button" [ngClass]="{active: search.status=='2'}" (click)="getSuperSaleList('2')" class="btn btn-default btn-small">진행예정</button>
        <button type="button" [ngClass]="{active: search.status=='3'}" (click)="getSuperSaleList('3')" class="btn btn-default btn-small">종료</button>
      </div>
    </div>
    <div class="fr w30p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="dd589f94552347bd9c39482a9bdfd289" [btnGroup]="1"></btn-ag-grid-save>

      <button type="button" title="슈퍼세일 추가" class="btn btn-primary btn-small ml5" (click)="supersaleAdd(0)">슈퍼세일 추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 35px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="superSaleList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
      >
    </ag-grid-angular>
  </div>
</section>
