import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { MemberService } from '@app/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.scss']
})
export class MemberInfoComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() orderSeq: any;

  public isPopup: boolean = true;
  public dormant: boolean = false;
  public memberGrade: any;

  public activeTab: any = 0;

  public gradeList: any = [];
  public stateList: any = [];
  public enteredStatusList: any = [];

  public memberInfo: any = {};

  public form: FormGroup;
  public formErrors = {};

  public title: any;

  public summernoteConfig: any = config.summernoteConfig;

  private checkIdDup: boolean = false;

  public addOncheckIdDuplicateFunc: any;  // 중복아이디 검사
  public addPwChangeFunc: any;            // 패스워드 변경

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      mem_no: ['', [] ],
      grade: ['0001', [Validators.required] ],
      entered_status: ['9000', [Validators.required] ],
      name: ['', [Validators.required] ],
      id: ['', [Validators.required] ],
      password: ['', [] ],
      //birthdate: ['', [Validators.required] ],
      //birth_sec: ['S', [] ],
      email: ['', [] ],
      email_yn: ['1', [] ],
      kakao_yn: ['1', [] ],
      bill_yn: ['1', [] ],
      player_discount_yn: ['0', [] ],
      //email_reject_date: [null, [] ],
      gender: ['M', [] ],
      hp: ['', [] ],
      sms_yn: ['1', [] ],
      //sms_reject_date: [null, [] ],

      shop_seq: ['', [] ],
      shop_zipcode: ['', [] ],
      shop_address: ['', [Validators.maxLength(100)] ],
      shop_address_detail: ['', [Validators.maxLength(100)] ],
      shop_name: ['', [] ],
      shop_phone: ['', [Validators.maxLength(20)] ],
      shop_email: ['', [Validators.maxLength(50)] ],
      shop_fax: ['', [Validators.maxLength(20)] ],
      shop_homepage: ['', [Validators.maxLength(60)] ],
      license: ['', [Validators.maxLength(10)] ],
      shop_sena_seller_yn: ['0', [] ],

      agency_seq: ['', [] ],
      agency_name: ['', [] ],
      agency_phone: ['', [Validators.maxLength(20)] ],
      agency_hphone1: ['', [Validators.maxLength(20)] ],
      ceo_name: ['', [] ],
      charge_name: ['', [] ],
      charge_hp: ['', [] ],
      agency_display: ['1', [] ],
      agency_ali_date: [null, [] ],
      agency_ali_end_date: [null, [] ],
      agency_state: ['', [] ],
      agency_discount_rate: ['', [] ],
      agency_zipcode: ['', [Validators.maxLength(5)] ],
      agency_addr: ['', [Validators.maxLength(100)] ],
      agency_addr_detail: ['', [Validators.maxLength(100)] ],
      agency_coordinate: ['', [] ],
      agency_sale: ['0', [Validators.maxLength(10)] ],
      agency_opentime: ['', [Validators.maxLength(255)] ],
      agency_howtocounsel: ['', [Validators.maxLength(255)] ],
      agency_howtoorder: ['', [Validators.maxLength(255)] ],
      agency_info: ['', [] ],
      agency_content: ['', [] ],
      agency_sena_seller_yn: ['0', [] ],

      bank_seq: ['', [] ],
      depositor: ['', [] ],
      bank: ['', [] ],
      account: ['', [] ],

      shop_discount_rate: ['', [] ],
      use_yn: ['0', [] ],

      joindate: ['', [] ],
      write_ip: ['', [] ],
      last_logindate: ['', [] ],
      buyclass: ['', [] ],
      member_group: ['', [] ],
      total_mileage: ['', [] ],
      use_mileage: ['', [] ],
      buy_count: ['', [] ],
      buy_sum: ['', [] ],
      margin_sum: ['', [] ],
      society_name: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    this.buildForm();

    this.addOncheckIdDuplicateFunc = this.checkIdDuplicateAddOn.bind(this);
    this.addPwChangeFunc = this.pwChangeAddOn.bind(this);

    this.summernoteConfig.height = 150;
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      mem_no: this.memberNo,
      grade: this.memberGrade
    });

    this.getCommonList();

    if( this.memberNo !== '0' && this.memberNo !== undefined ) {
      this.getMemberDetail();
    }
  }

  /*******************************************************************************
    설  명 : 데이터 변경 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnChanges(): void {
    if( this.memberNo !== undefined ) {
      this.form.patchValue({
        mem_no: this.memberNo,
        grade: this.memberGrade
      });

      this.isPopup = false;

      if( this.memberNo !== '0' && this.memberNo !== undefined ) {
        this.getMemberDetail();
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 회원등급
    this.commonService.getCommonListCode('MBG').then( response => {
      if ( response.ResultCode ) {
        if( window.location.pathname == '/member/company' ) {
          response.data.forEach(row => {
            if( row.common_code == '0002' ) {
              this.gradeList.push(row)
            }
          });
        } else if ( window.location.pathname == '/member/sis' ) {
          response.data.forEach(row => {
            if( row.common_code == '0004' || row.common_code == '0007' ) {
              this.gradeList.push(row)
            }
          });
        } else if ( window.location.pathname == '/member/list' ) {
          response.data.forEach(row => {
            if( row.common_code !== '0000' ) {
              this.gradeList.push(row)
            }
          });
        } else {
          this.gradeList = response.data;
        }

      } else {
        this.gradeList = [];
      }

    });

    // 주소
    this.commonService.getCommonListCode('ADDR').then( response => {
      if ( response.ResultCode ) {
        this.stateList = [];
        for(let item of response.data ) {
          this.stateList.push({
            value: item.common_code,
            title: item.common_name
          });
        }
      } else {
        this.stateList = [];
      }
    });

    // 입점상태
    this.commonService.getCommonListCode('ETS').then( response => {
      if ( response.ResultCode ) {
        this.enteredStatusList = response.data;

      } else {
        this.enteredStatusList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberDetail() {
    this.memberService.getMemberDetail( this.memberNo, this.dormant ).then( response => {
      if( response.ResultCode ) {
        this.memberInfo = response.data;

        this.memberInfo.agency_ali_date = this.utilService.getDate( this.memberInfo.agency_ali_date );
        this.memberInfo.agency_ali_end_date = this.utilService.getDate( this.memberInfo.agency_ali_end_date );

        this.form.patchValue( this.memberInfo );

        if( this.memberInfo.id && this.memberNo != 0 ) {
          this.checkIdDup = true;
        } else {
          this.checkIdDup = false;
        }

      } else {
        this.toastrService.error( response.ResultMessage, '회원 상세정보' );
        this.activeModal.close( false );
        this.memberInfo = {};
      }
    });
  }

  /*******************************************************************************
    설  명 : 아이디 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkIdDuplicateAddOn() {
    if ( this.form.controls.id.value.length < 1 ) {
      this.toastrService.error( '중복 검사할 아이디를 입력하세요.', '');

      return false;
    }

    this.memberService.checkIdDuplicate( this.form.controls.id.value ).then( response => {
      if( response.ResultCode ) {
        this.checkIdDup = true;
        this.toastrService.success( response.ResultMessage, '');

      } else {
        this.checkIdDup = false;
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 비밀번호 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  pwChangeAddOn() {
    if( ! this.form.controls.password.value ) {
      this.toastrService.error( '변경하고자 하는 비밀번호를 입력하세요.', '');
      return false;
    } else {
      this.utilService.makeFieldDirtyAndUpdateErrors(this.form, this.formErrors, 'password');

      if( this.form.get('password').valid ) {

        if( confirm("비밀번호를 변경하시겠습니까?") ) {
          this.memberService.changePassword( this.memberNo, this.form.value.password ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '비밀번호 변경');
            } else {
              this.toastrService.error( response.ResultMessage, '비밀번호 변경');
            }
          }, error => {
            this.toastrService.error( error, '비밀번호 변경');
          });
        }

      }
    }
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      shop_zipcode: data.zip,
      shop_address: data.addr
    });

    $("#shop_address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApiAgency(data) {

    let state_name = data.addr.split( ' ' );
    let state = '';
    for(let item of this.stateList ) {
      if( state_name[0].indexOf(item.title) > -1 ) {
        state = item.value;
        break;
      }
    }

    // 여기로 주소값이 반환
    this.form.patchValue({
      agency_zipcode: data.zip,
      agency_addr: data.addr,
      agency_coordinate: data.latitude + ',' + data.longitude,
      agency_state: state
    });

    $("#agency_addr_detail").focus();
  }


  checkAsterisk() {
    const memberId: any = document.getElementById('id');
    const memberIdCheck = memberId.value;

    if( this.memberNo != 0 && memberIdCheck == this.memberInfo.id ) {
      this.checkIdDup = true;
    } else {
      this.checkIdDup = false;
    }

  }

  /*******************************************************************************
    설  명 : 회원 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.memberService.setMemberSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '회원정보 저장');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '회원정보 저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '기본정보 저장');
    }

  }

  /*******************************************************************************
    설  명 : 회원정보 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberDelete() {
    if ( confirm('탈퇴처리하시겠습니까?') ) {
      this.memberService.setMemberDelete( this.memberNo ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '회원탈퇴');
          this.activeModal.close( false );
        } else {
          this.toastrService.error( response.ResultMessage, '회원탈퇴');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onTabChange(value: any) {
    this.activeTab = value;
  }

  /*******************************************************************************
    설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

}
