/*******************************************************************************
  설  명 : 회원정보 - 메모내역
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { OrderService } from '@app/service/order.service';

@Component({
  selector: 'app-order-change',
  templateUrl: './order-change.component.html',
  styleUrls: ['./order-change.component.scss']
})
export class OrderChangeComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() OrderSeq: any;
  @Input() dormant: any;


  // 그리드 관련 선언

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  orderSumGridApi: any;
  orderSumGridColumnApi: any;
  orderSumColumnDefs: any;

  rowData: any = [];
  sumData: any = [];

  components: any;
  noRowsTemplate: string;
  rowClassRules: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public setMemberMemoSaveFunc: any;
  public setMemberHPEmailFunc: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private orderService: OrderService,
    private agGridExComponent: AgGridExComponent
  ) {

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center ag-cell80h',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '주문번호', field: 'master_seq', width: 100, cellClass: 'cp center ag-cell80h'},
      { headerName: '주문일자', field: 'order_date', width: 100, cellClass: 'cp center ag-cell80h'},
      { headerName: '이미지', field: 'thumbnail_url', width: 120, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent'},
      { headerName: '상품명', field: 'product_name', width: 250, cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          // 상품홍보문구
          let comment = ( params.data.comment ? params.data.comment : '' );
          return comment + params.data.product_name;
        }
      },
      { headerName: '이벤트', field: 'event_name', width: 150, cellClass: 'cp center ag-cell80h'},
      { headerName: '색상', field: 'color', cellClass: 'cp center ag-cell80h', width: 50, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return `<span style="display:inline-block;margin-top:32px;width:16px;height:16px;border:solid 1px #ccc;background-color:${params.data.color}">&nbsp;</span>`;
        }
      },
      { headerName: '색상명', field: 'color_name', cellClass: 'cp center ag-cell80h', width: 100, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          return params.data.color_name + ( params.data.display_color_name ? '<br/>(' + params.data.display_color_name + ')' : '' );
        }
      },
      { headerName: '사이즈', field: 'size_name', width: 100, cellClass: 'cp center ag-cell80h' },
      { headerName: '수량', field: 'qty', width: 70, cellClass: 'cp right ag-cell80h' },
      { headerName: '단가', field: 'unit_price', width: 100, cellClass: 'cp right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter, },
      { headerName: '할인금액', field: 'discount_amt', width: 100, cellClass: 'cp right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter, },
      { headerName: '판매금액', field: 'unit_price', width: 125, cellClass: 'cp center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let unit_price = getComma(params.data.unit_price);
          let amt = getComma(params.data.amt);
          let mileage = getComma(params.data.mileage);
          return ( unit_price != amt ? '<span style="color:#898989;">' + unit_price + '</span><br/>' : '' ) + amt + '<br/>' + mileage;
        }
      },
      { headerName: '판매금액', field: 'amt', width: 100, cellClass: 'cp right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter, },
      { headerName: '구매금액', field: 'buy_price', width: 100, cellClass: 'cp right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter, },
      { headerName: '마일리지', field: 'mileage', width: 100, cellClass: 'cp right ag-cell80h', valueFormatter: this.agGridExComponent.currencyFormatter, },
      // 헷갈려서 안보이게 주석처리
      // { headerName: '상태', field: 'order_status', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter(params: any) {
      //     if ( params.data.order_status == '0' ) {
      //       return '<span class="badge badge-secondary f12">주문접수</span>';
      //     } else if ( params.data.order_status == '1' ) {
      //       return '<span class="badge badge-secondary f12">결제확인</span>';
      //     } else if ( params.data.order_status == '2' ) {
      //       return '<span class="badge badge-success f12">배송준비</span>';
      //     } else if ( params.data.order_status == '3' ) {
      //       return '<span class="badge badge-danger f12">전체반품</span>';
      //     } else if ( params.data.order_status == '4' ) {
      //       return '<span class="badge badge-warning f12">일부반품</span>';
      //     } else if ( params.data.order_status == '5' ) {
      //       return '<span class="badge badge-danger f12">전체교환</span>';
      //     } else if ( params.data.order_status == '6' ) {
      //       return '<span class="badge badge-warning f12">일부교환</span>';
      //     } else if ( params.data.order_status == '7' ) {
      //       return '<span class="badge badge-danger f12">주문취소</span>';
      //     } else if ( params.data.order_status == '8' ) {
      //       return '<span class="badge badge-primary f12">발송완료</span>';
      //     } else if ( params.data.order_status == '9' ) {
      //       return '<span class="badge badge-info f12">주문완료</span>';
      //     } else {
      //       return '<span class="badge badge-light f12">문의</span>';
      //     }
      //   }
      // },
    ];


    // ag grid 컬럼 선언
    this.orderSumColumnDefs = [
      { headerName: '', field: '', width: 920, cellClass: 'cp center' },
      { headerName: '수량합계', field: 'sum_qty', width: 120, cellClass: 'cp center', hide: true },
      { headerName: '판매합계', field: 'sum_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '할인합계', field: 'sum_discount_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '구매합계', field: 'sum_buy_price', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '마일리지합계', field: 'sum_mileage', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '합계', field: 'sum_total_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '', field: '', width: 100, cellClass: 'cp center' },
      { headerName: '', field: '', width: 100, cellClass: 'cp center' }
    ];


    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    this.components = {
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

    // 콤마 표시
    function getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.rowClassRules = {
      'status7': function (params) {
        return true;
      },
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getChangeRefundList();
  }



  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onOrderSumGridReady(params) {
      this.orderSumGridApi = params.api;
      this.orderSumGridColumnApi = params.columnApi;
    }




  /*******************************************************************************
    설  명 : 교환/환불 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getChangeRefundList() {
    this.orderService.getChangeRefundList( this.OrderSeq ).then( response => {
      if( response.ResultCode ) {
        this.rowData = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }


  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getRowHeight = function(params) {
      return 120;
    };

  /*******************************************************************************
    설  명 : 주문내역 총 합계 구하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onRowDataChanged( event: any ) {
      let sumQty = 0;
      let sumAmt = 0;
      let sumDiscountAmt = 0;
      let sumBuyPrice = 0;
      let sumMileage = 0;
      let sumTotalAmt = 0;

      event.api.forEachNodeAfterFilter( function(node) {
        sumQty += parseInt(node.data.qty);
        sumAmt += parseInt(node.data.amt);
        sumDiscountAmt += parseInt(node.data.discount_amt);
        sumBuyPrice += parseInt(node.data.buy_price);
        sumMileage += parseInt(node.data.mileage);
        sumTotalAmt += parseInt(node.data.total_amt);
      });


      let tmp: any = [];
      tmp.push({
        sum_qty: sumQty,
        sum_amt: sumAmt,
        sum_discount_amt: sumDiscountAmt,
        sum_buy_price: sumBuyPrice,
        sum_mileage: sumMileage,
        sum_total_amt: sumTotalAmt,
      });

      this.sumData = tmp;
    }

}
