<div class="modal-header">
  <h4 class="modal-title fl">거래내역서 출력</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <section id="print_area">
    <div class="fr w50p right">
    </div>
    <h4 class="modal-title f24 fb center">결제도우미 거래내역서</h4>
    <div class="mb35">
      <div class="fl">거래번호 : {{helperInfo.seq}}</div>
      <div class="fr">거래완료일시 : {{helperInfo.end_date}}</div>
    </div>

    <table class="list">
      <caption class="sr-only">매물 정보</caption>
      <colgroup>
        <col style="width:15%">
        <col style="width:35%">
        <col style="width:15%">
        <col style="width:35%">
      </colgroup>
      <tbody>
        <tr>
          <th colspan="4" class="center fb">매물 정보</th>
        </tr>
        <tr>
          <th>거래구분</th>
          <td colspan="3" >{{helperInfo.sell_gbn_name}}</td>
        </tr>
        <tr>
          <th>구분</th>
          <td colspan="3" >{{helperInfo.old_new_gbn == 'OLD' ? '중고차' : '신차'}}</td>
        </tr>
        <tr>
          <th>매물제목</th>
          <td colspan="3" >{{helperInfo.title}}</td>
        </tr>
        <tr>
          <th>브랜드</th>
          <td>{{helperInfo.brand_name}}</td>
          <th>모델</th>
          <td>{{helperInfo.model}}</td>
        </tr>
        <tr>
          <th>종류</th>
          <td>{{helperInfo.bike_style_name}}</td>
          <th>연식</th>
          <td>{{helperInfo.manufacture_year}}</td>
        </tr>
        <tr>
          <th>주행거리</th>
          <td>{{helperInfo.mileage | number}}</td>
          <th>배기량</th>
          <td>{{helperInfo.piston | number}}</td>
        </tr>
        <tr>
          <th>판매지역</th>
          <td>{{helperInfo.region1}} {{helperInfo.region2}}</td>
          <th>튜닝여부</th>
          <td>{{helperInfo.is_tuning == '1' ? '튜닝함' : '튜닝안함'}}</td>
        </tr>
        <tr>
          <th>판매자</th>
          <td>{{helperInfo.seller_name}}</td>
          <th>판매자 연락처</th>
          <td>{{setHyphen(helperInfo.hp)}}</td>
        </tr>
        <tr>
          <th>판매자 이메일</th>
          <td colspan="3">{{helperInfo.email}}</td>
        </tr>
        <tr>
          <th>구매자</th>
          <td>{{helperInfo.buyer_name}}</td>
          <th>구매자 연락처</th>
          <td>{{setHyphen(helperInfo.buyer_hp)}}</td>
        </tr>
        <tr>
          <th>구매자 이메일</th>
          <td colspan="3">{{helperInfo.buyer_email}}</td>
        </tr>
        <tr>
          <th>판매가격</th>
          <td colspan="3">{{helperInfo.sale_price | number}} 원</td>
        </tr>
      </tbody>
    </table>
    
    <ng-container *ngFor="let row of helperPayList">
      <table class="list">
        <caption class="sr-only">결제 정보</caption>
        <colgroup>
          <col style="width:15%">
          <col style="width:35%">
          <col style="width:15%">
          <col style="width:35%">
        </colgroup>
        <tbody>
          <tr>
            <th colspan="4" class="center fb">{{row.pay_method == '0001' ? '카드' : '현금'}} 결제 정보</th>
          </tr>
          <tr>
            <th>상태</th>
            <td colspan="3">{{row.app_gbn == '1' ? '정상' : '취소'}}</td>
          </tr>
          <tr>
            <th>결제구분</th>
            <td>{{row.pay_gbn}}</td>
            <th>PG사</th>
            <td>{{row.pg_group_name}}</td>
          </tr>
          <tr>
            <th>결제수단</th>
            <td>{{row.pay_method_name}}</td>
            <th>승인번호</th>
            <td>{{row.app_no}}</td>
          </tr>
          <tr>
            <th>결제일시</th>
            <td>{{row.pay_date}}</td>
            <th>결제금액</th>
            <td>{{row.pay_amt | number}}</td>
          </tr>
          <tr>
            <th>상점아이디</th>
            <td>{{row.mid}}</td>
            <th>주문번호</th>
            <td>{{row.oid}}</td>
          </tr>
          <tr>
            <th>거래번호</th>
            <td colspan="3">{{row.tid}}</td>
          </tr>
          <tr>
            <th>카드(계좌)번호</th>
            <td>{{row.card_no}}</td>
            <th>할부기간</th>
            <td>{{row.installment_period}}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <table class="list">
      <caption class="sr-only">정산 정보</caption>
      <colgroup>
        <col style="width:15%">
        <col style="width:35%">
        <col style="width:15%">
        <col style="width:35%">
      </colgroup>
      <tbody>
        <tr>
          <th colspan="4" class="center fb">정산 정보</th>
        </tr>
        <tr>
          <th>현금수수료</th>
          <td>{{helperInfo.cash_fee || 0 | number}} 원</td>
          <th>카드수수료</th>
          <td>{{helperInfo.card_fee || 0 | number}} 원</td>
        </tr>
        <tr>
          <th>수수료합계</th>
          <td>{{helperInfo.total_fee || 0 | number}} 원</td>
          <th>정산금액</th>
          <td class="fb">{{helperInfo.calculate_price || 0 | number}} 원</td>
        </tr>
      </tbody>
    </table>

    <table class="list">
      <caption class="sr-only">판매자 계좌 정보</caption>
      <colgroup>
        <col style="width:15%">
        <col style="width:35%">
        <col style="width:15%">
        <col style="width:35%">
      </colgroup>
      <tbody>
        <tr>
          <th colspan="4" class="center fb">판매자 계좌 정보</th>
        </tr>
        <tr>
          <th>은행</th>
          <td colspan="3">{{memberInfo.bank}}</td>
        </tr>
        <tr>
          <th>계좌번호</th>
          <td colspan="3">{{memberInfo.account}}</td>
        </tr>
        <tr>
          <th>예금주</th>
          <td colspan="3">{{memberInfo.depositor}}</td>
        </tr>
      </tbody>
    </table>
    
  </section>

  <div class="btn-area">
    <button class="btn1 mr5" (click)="print()">출력</button>
    <button class="btn1" (click)="activeModal.dismiss()">닫기</button>
  </div>
 
</div>
