import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { StockOrder } from '@app/service/stockOrder.service';

@Component({
  selector: 'app-modal-purchase-quick',
  templateUrl: './modal-purchase-quick.component.html',
  styleUrls: ['./modal-purchase-quick.component.scss']
})
export class ModalPurchaseQuickComponent implements OnInit {

  @Input() data
  @Input() getToday
  @Input() getUser
  @Input() dateFormat

  product_property_seq = ''
  purchase_cnt = ''

  constructor(
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private stockOrder: StockOrder
  ) { }

  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 저장하기
  *******************************************************************************/
  submit() {
    this.stockOrder.quickPurchase({
      mem_no : this.getUser,
      purchase_cnt : parseInt(this.purchase_cnt),
      product_property_seq : this.product_property_seq
    }).then(response => {
      if(response.ResultCode) {
        this.toastrService.success(response.ResultMessage)
        this.activeModal.close(true)
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 수량변경
  *******************************************************************************/
  changeCnt($event, which) {
    this[which] = $event.target.value
  }

}
