import { Component, OnInit, Input } from '@angular/core';
import  {NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteConfigService } from '@app/service/site.config.service';

@Component({
  selector: 'app-specification-transaction',
  templateUrl: './specification-transaction.component.html',
  styleUrls: ['./specification-transaction.component.scss']
})
export class SpecificationTransactionComponent implements OnInit {

  @Input() data
  CONFIG_KEY = 'company';
  company_info = {
    biz_no : '',
    company_name : '',
    ceo_name : '',
    address : '',
    cs_tel1 : '',
    fax : '',
    biz_type : '',
    biz_category : ''
  }

  constructor(
    public activeModal: NgbActiveModal,
    private siteConfigService: SiteConfigService,
  ) { }

  ngOnInit(): void {
    this.siteConfigService.getConfig(this.CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.company_info = response.data
      }
    })
  }

  _print() {
    let mode = 'portrait'
    if(confirm('가로 방향으로 출력하시겠습니까?')) mode = 'landscape'
    const Maindiv = document.createElement('div')
    Maindiv.style.cssText = 'width: 100%; height: 100%; position: absolute; z-index: 9999; top: 0; left: 0; background-color: #fff;'

    const item = document.getElementById('print_area').cloneNode(true)
    Maindiv.appendChild(item)
    const app_root = document.querySelector<HTMLElement>('app-root') // as HTMLCollectionOf<HTMLElement>
    app_root.style.cssText = "display: none;"

    document.body.prepend(Maindiv);
    window.onafterprint = () => {
      document.body.removeChild(Maindiv)
      app_root.style.cssText = "display: initial;"
    }

    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';
    style.appendChild(document.createTextNode(`@page { size: ${mode}; }`));
    head.appendChild(style);

    window.print()
  }

}
