/*******************************************************************************
  설  명 : 상품관리 - 상품 순서관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { SupersaleService } from '@app/service/supersale.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-supersale-detail',
  templateUrl: './supersale-detail.component.html',
  styleUrls: ['./supersale-detail.component.scss']
})
export class SupersaleDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public superSaleSeq: any;
  public search: any = {
    status: '',
  };

  public productList: any = [];
  public categoryList: any = [];
  public brandList: any = [];

  public params: any = {
    category_code: '',
    brand_seq: '',
    searchField: 'product_name',
    searchText: '',
    category_status: 'W',
    brand_status: 'W',
  };

  public saleType: any = {
    discount: true,
    rate: false,
    value: ''
  };

  public activeTab: any;

  // 그리드 관련 선언
  gridApiCategory: any;
  gridColumnApiCategory: any;
  columnDefsCategory: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiBrand: any;
  gridColumnApiBrand: any;
  columnDefsBrand: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;
  components: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public statistic: any = {
    supersale_name: '',
    start_date: '',
    end_date: '',
    productCnt : 0,
    waitCnt: 0,
    completeCnt: 0,
    rate: 0
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private supersaleService: SupersaleService,
    private agGridExComponent: AgGridExComponent
  ) {
    this.activatedRoute.queryParams.subscribe( params => {
      this.superSaleSeq = ( params.superSaleSeq == undefined ) ? 0 : params.superSaleSeq;
      this.search = {
        status: params.search,
      };
    }).unsubscribe();

    // ag grid 컬럼 선언
    this.columnDefsCategory = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상태', field: 'status', width: 67, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status === '대기' )
            return '<span class="badge badge-secondary f11">대기</span>';
          else if ( params.data.status === '진행중' )
            return '<span class="badge badge-success f11">진행중</span>';
          else if ( params.data.status === '완료' )
            return '<span class="badge badge-primary f11">완료</span>';
        }
      },
      { headerName: '카테고리 코드', field: 'category_code', cellClass: 'cp', hide: true },
      { headerName: '카테고리명', field: 'category_name', width: 136, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 76, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '완료수', field: 'complete_total', width: 76, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
    ];

    // ag grid 컬럼 선언
    this.columnDefsBrand = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상태', field: 'status', width: 67, cellClass: 'cp', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status === '대기' )
            return '<span class="badge badge-secondary f11">대기</span>';
          else if ( params.data.status === '진행중' )
            return '<span class="badge badge-success f11">진행중</span>';
          else if ( params.data.status === '완료' )
            return '<span class="badge badge-primary f11">완료</span>';
        }
      },
      { headerName: '브랜드명', field: 'brand_name', width: 136, cellClass: 'cp' },
      { headerName: '상품수', field: 'product_total', width: 76, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '완료수', field: 'complete_total', width: 76, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
    ];

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 40, cellClass: 'cp center ag-cell80h', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상태', field: 'status', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status === '0' ) {
            return '<span class="badge badge-secondary f12">대기상품</span>';
          } else if ( params.data.status === '1' ) {
            return '<span class="badge badge-success f12">판매중</span>';
          } else if ( params.data.status === '2' ) {
            return '<span class="badge badge-warning f12">재고위험</span>';
          } else if ( params.data.status === '3' ) {
            return '<span class="badge badge-danger f12">재고없음</span>';
          } else {
            return '';
          }
        }
      },
      { headerName: 'product seq', field: 'product_seq', width: 150, cellClass: 'cp center ag-cell80h', hide: true },
      { headerName: '이미지', field: 'thumbnail_url', width: 130, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품정보', field: 'category_name', width: 500, cellClass: 'cp ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.category_name + '<br />[' + params.data.brand_name + ']<br />' + params.data.product_name;
        }
      },
      /*
      { headerName: '메이커', field: 'maker', width: 150, cellClass: 'cp ag-cell80h' },
      { headerName: '모델명', field: 'product_name', width: 200, cellClass: 'cp ag-cell80h' },
      */
      { headerName: '판매가격', field: 'sale_price', width: 90, cellClass: 'cp right ag-cell80h' },
      { headerName: '할인가격', field: 'discount_amt', width: 90, cellClass: 'cp right ag-cell80h', cellEditor: 'numericCellEditor', editable: true,
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: this.agGridExComponent.numberGetter
      },
      { headerName: '할인율', field: 'discount_rate', width: 90, cellClass: 'cp right ag-cell80h', cellEditor: 'numericCellEditor', editable: true },
      { headerName: '적립금', field: 'mileage_amt', width: 90, cellClass: 'cp right ag-cell80h' , cellEditor: 'numericCellEditor', editable: true,
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter: this.agGridExComponent.numberGetter
      },
      { headerName: '적립율', field: 'mileage_rate', width: 90, cellClass: 'cp right ag-cell80h', cellEditor: 'numericCellEditor', editable: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = { numericCellEditor: this.agGridExComponent.getNumericCellEditor() };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getSuperSaleWidget();
    this.getSuperSaleCategoryList();
    this.getSuperSaleBrandList();
  }

  getSuperSaleWidget() {
    this.supersaleService.getSuperSaleWidget(this.superSaleSeq).then( response => {
      if (response.ResultCode) {
        this.statistic.supersale_name = response.data.supersale_name;
        this.statistic.start_date = response.data.start_date;
        this.statistic.end_date = response.data.end_date;
        this.statistic.productCnt = response.data.productCnt;
        this.statistic.waitCnt = response.data.productCnt - response.data.completeCnt;
        this.statistic.completeCnt = response.data.completeCnt;
        this.statistic.rate = parseInt(response.data.completeCnt, 10) / parseInt(response.data.productCnt, 10) * 100;
      }
    });
  }

  getSuperSaleCategoryList() {
    const params = {
      supersale_seq:  this.superSaleSeq,
      category_status: this.params.category_status
    };

    this.supersaleService.getSuperSaleCategoryListN(params).then( response => {
      if (response.resultCode) {
        const dataList = [];
        const setList = (tabChar, currentNode: any) => {
          dataList.push({
            seq: currentNode.seq,
            status: currentNode.status,
            category_code: currentNode.category_code,
            category_name: tabChar + currentNode.name,
            product_total: currentNode.product_total,
            complete_total: currentNode.complete_total
          });

          if (typeof currentNode.children === 'undefined') {
            return;
          } else {
            for (const children of currentNode.children) {
              setList('　', children);
            }
          }
        };

        for (const item of response.data) {
          setList('', item);
        }

        this.categoryList = dataList;

        setTimeout( () => {
          let index = 0;
          this.gridApiCategory.forEachNode( (node) => {
            if( index == 0 ) {
              node.setSelected(true);
              this.gridApi.ensureIndexVisible( index, 'top' );

              this.params.category_code = node.data.category_code;
              this.getSuperSaleCategoryProductList();
            }

            index++;
          });
        }, 100);

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }

    });
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 80;
  };

  setSuperSaleCategorySave() {
    const params = {
      supersale_seq: this.superSaleSeq,
      status: this.params.category_status,
      nodes: this.gridApiCategory.getSelectedRows()
    };

    if ( params.nodes.length < 1 ) {
      this.toastrService.error( '저장할 항목을 선택하세요', '' );
      return false;
    }

    this.supersaleService.setSuperSaleCategorySave( params ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getSuperSaleCategoryList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  getSuperSaleCategoryProductList() {
    const params = {
      supersale_seq: this.superSaleSeq,
      category_code: this.params.category_code
    };

    this.supersaleService.getSuperSaleCategoryProductList(params).then( response => {
      if (response.ResultCode) {
        this.productList = response.data;
      } else {
        this.productList = [];
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  getSuperSaleBrandList() {
    const params = {
      supersale_seq:  this.superSaleSeq,
    };

    this.supersaleService.getSuperSaleBrandList(params).then( response => {
      if ( response.ResultCode ) {
        this.brandList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  setSuperSaleBrandSave() {
    const params = {
      supersale_seq: this.superSaleSeq,
      status: this.params.brand_status,
      nodes: this.gridApiBrand.getSelectedRows()
    };

    if ( params.nodes.length < 1 ) {
      this.toastrService.error( '변경할 항목을 선택하세요', '' );
      return false;
    }

    this.supersaleService.setSuperSaleBrandSave( params ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getSuperSaleBrandList();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  setSuperSaleProductSave() {
    const nodes = this.gridApi.getSelectedRows();
    const params = [];

    this.gridApi.forEachNode( (node) => {
      if ( nodes.length < 1 || node.selected ) {
        params.push({
          supersale_seq: this.superSaleSeq,
          seq: node.data.seq ?? '0',
          product_seq: node.data.product_seq,
          sale_price: node.data.sale_price,
          discount_amt: node.data.discount_amt,
          discount_rate: node.data.discount_rate,
          mileage_amt: node.data.mileage_amt,
          mileage_rate: node.data.mileage_rate,
        });
      }
    });

    this.supersaleService.setSuperSaleProductSave(params).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '');
        this.getSuperSaleWidget();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  getSuperSaleBrandProductList() {
    const params = {
      supersale_seq: this.superSaleSeq,
      brand_seq: this.params.brand_seq
    };

    this.supersaleService.getSuperSaleBrandProductList(params).then( response => {
      if (response.ResultCode) {
        this.productList = response.data;
      } else {
        this.productList = [];
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  applyAll() {
    const nodes = this.gridApi.getSelectedRows();

    if ( this.saleType.value === '') {
      this.toastrService.error( '금액 or 비율을 입력하세요.', '' );
      return false;
    }

    this.gridApi.forEachNode( function(node) {
      if ( nodes.length < 1 || node.selected ) {
        const inputValue = parseInt(this.saleType.value, 10);
        const type = this.saleType;
        const originPrice = node.data.sale_price;
        const result = {
          discount_amt: (type.discount && !type.rate) ? inputValue : !type.discount ? 0 : this.amountCalc(originPrice, inputValue),
          discount_rate: (type.discount && type.rate) ? inputValue : !type.discount ? 0 : this.rateCalc(originPrice, inputValue),
          mileage_amt: (!type.discount && !type.rate) ? inputValue : type.discount ? 0 : this.amountCalc(originPrice, inputValue),
          mileage_rate: (!type.discount && type.rate) ? inputValue : type.discount ? 0 : this.rateCalc(originPrice, inputValue),
        };

        for (const [key, value] of Object.entries(result)) {
          if (value > 0) {
            node.setDataValue( key, value );
          }
        }

      }
    }.bind(this));
  }

  amountCalc(originPrice, inputValue) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? originPrice * (inputValue / 100) : 0;
  }

  rateCalc(originPrice, inputValue) {
    originPrice = parseInt(originPrice.replace(/,/g, ''), 10);
    return originPrice > 0 ? inputValue / originPrice * 100 : 0;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /*******************************************************************************
    설  명 : 목록보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
    ['/basic/supersale'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  onTabChange( $event ) {
    if ($event === 0) {
      this.getSuperSaleCategoryProductList();
    } else {
      this.getSuperSaleBrandProductList();
    }
  }

  onGridReadyCategory( $event ) {
    this.gridApiCategory = $event.api;
    this.gridColumnApiCategory = $event.columnApi;
  }

  onCategoryGridRowClicked( $event ) {
    this.params.category_code = $event.data.category_code;
    this.getSuperSaleCategoryProductList();
  }

  onGridReadyBrand( $event ) {
    this.gridApiBrand = $event.api;
    this.gridColumnApiBrand = $event.columnApi;
  }

  onBrandGridRowClicked( $event ) {
    this.params.brand_seq = $event.data.seq;
    this.getSuperSaleBrandProductList();
  }

  onGridReady( $event ) {
    this.gridApi = $event.api;
    this.gridColumnApi = $event.columnApi;
  }

  onCellEditingStopped($event) {
    const originPrice = $event.node.data.sale_price;
    switch ($event.colDef.field) {
      case 'discount_amt':
        $event.node.setDataValue( 'discount_rate', this.rateCalc(originPrice, $event.value) );
        break;
      case 'discount_rate':
        $event.node.setDataValue( 'discount_amt', this.amountCalc(originPrice, $event.value) );
        break;
      case 'mileage_amt':
        $event.node.setDataValue( 'mileage_rate', this.rateCalc(originPrice, $event.value) );
        break;
      case 'mileage_rate':
        $event.node.setDataValue( 'mileage_amt', this.amountCalc(originPrice, $event.value) );
        break;
    }
  }
}
