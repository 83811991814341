  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">품절메모전체<span class="cnt">{{statistic?.totalCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">품절메모검색<span class="cnt">{{statistic?.searchCount | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">요청전체<span class="cnt">{{statistic?.totalCount1 | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">요청검색<span class="cnt">{{statistic?.searchCount1 | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="mt5" style="height:calc(100% - 55px)">
    <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="onTabChange($event)">
      
      <mat-tab label="업체요청사항">
        <div class="mt5 mb5">
          <div class="fl w70p form-inline">
            <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
              <i class="material-icons-outlined">refresh</i>
            </button>
            <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getSCMMemoList();">
              <i class="material-icons-outlined">search</i>
            </button>

            <select name="pageRow" [(ngModel)]="search.pageRow1" class="form-control form-control-small ml5" (change)="getSCMMemoList()">
              <option value="25">25줄</option>
              <option value="100">100줄</option>
              <option value="500">500줄</option>
              <option value="1000">1000줄</option>
              <option value="5000">5000줄</option>
            </select>

            <input type="text" size="30" id="searchText" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="요청사항, 거래처명 검색" />

            <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
            <ng-template #footerTemplate1>
              <hr class="my-0">
              <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
              <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
            </ng-template>
            <span class="ml5 mr5">~</span>
            <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
            <ng-template #footerTemplate2>
              <hr class="my-0">
              <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
              <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
            </ng-template>

            <div class="btn-group ml5">
              <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value)">{{item.title}}</button>
            </div>
          </div>

          <div class="fr right w30p">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="922c0cce8671463ebd0476a885898959" [btnGroup]="1"></btn-ag-grid-save>
          </div>

          <div class="cb"></div>
        </div>

        <div style="height:calc(100% - 85px)">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefsSCM"
            [defaultColDef]="defaultColDef"
            [rowData]="scmMemoList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowClassRules]="rowClassRules"
            [suppressRowClickSelection]="true"

            (rowClicked)="onRowClicked($event)"
            (gridReady)="onGridReadySCM($event)"
            >
          </ag-grid-angular>
        </div>
        <section class="pagination mt10" *ngIf="statistic.searchCount1">
          <ngb-pagination
            [(page)]="search.pageNo1"
            [pageSize]="search.pageRow1"
            [collectionSize]="statistic.searchCount1"
            [maxSize]="20"
            [rotate]="true"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
            >
            <ng-template ngbPaginationFirst>처음</ng-template>
            <ng-template ngbPaginationLast>마지막</ng-template>
            <ng-template ngbPaginationPrevious>이전</ng-template>
            <ng-template ngbPaginationNext>다음</ng-template>
          </ngb-pagination>
        </section>
      </mat-tab>

      <mat-tab label="업체품절메모">
        <div class="mt5 mb5">
          <div class="fl w70p form-inline">
            <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
              <i class="material-icons-outlined">refresh</i>
            </button>
            <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getOutofstockMemoList();">
              <i class="material-icons-outlined">search</i>
            </button>

            <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small ml5" (change)="getOutofstockMemoList()">
              <option value="25">25줄</option>
              <option value="100">100줄</option>
              <option value="500">500줄</option>
              <option value="1000">1000줄</option>
              <option value="5000">5000줄</option>
            </select>

            <input type="text" id="searchText" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="검색어" />

            <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
            <ng-template #footerTemplate1>
              <hr class="my-0">
              <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
              <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
            </ng-template>
            <span class="ml5 mr5">~</span>
            <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
            <ng-template #footerTemplate2>
              <hr class="my-0">
              <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
              <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
            </ng-template>

            <div class="btn-group ml5">
              <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value)">{{item.title}}</button>
            </div>
          </div>

          <div class="fr right w30p">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="74f86d0029f64ec6b9a92e2e088f38b3" [btnGroup]="1"></btn-ag-grid-save>
          </div>

          <div class="cb"></div>
        </div>

        <div style="height:calc(100% - 85px)">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="outofStockList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"

            (cellClicked)="onCellClicked($event)"
            (gridReady)="onGridReady($event)"
            >
          </ag-grid-angular>
        </div>
        <section class="pagination mt10" *ngIf="statistic.searchCount">
          <ngb-pagination
            [(page)]="search.pageNo"
            [pageSize]="search.pageRow"
            [collectionSize]="statistic.searchCount"
            [maxSize]="20"
            [rotate]="true"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
            >
            <ng-template ngbPaginationFirst>처음</ng-template>
            <ng-template ngbPaginationLast>마지막</ng-template>
            <ng-template ngbPaginationPrevious>이전</ng-template>
            <ng-template ngbPaginationNext>다음</ng-template>
          </ngb-pagination>
        </section>
      </mat-tab>
      
    </mat-tab-group>
  </div>
