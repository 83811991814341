import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { BikeService } from '@app/service/bike.service';
import { CommonService } from '@app/service/common.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { MemberInfoComponent } from '@page/member/member-info/member-info.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-bike-payhelper',
  templateUrl: './bike-payhelper.component.html',
  styleUrls: ['./bike-payhelper.component.scss']
})
export class BikePayhelperComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 20,
    seq: '',
    searchField: 'sell_seq',
    searchText: '',
    sdate: this.utilService.getDate(moment().subtract(90, 'day')),
    edate: this.utilService.getDate(''),
    searchTerm: this.getDays(90),
    totalCount: 0,
    status: '',
    payMethod: '',
    product_gbn: '',
  };

  public termList: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
    {title: '150일', value: this.getDays(150)},
    {title: '200일', value: this.getDays(200)},
    {title: '365일', value: this.getDays(365)},
  ];

  public statistic: any = {
    totalCount: 0,
    searchCount: 0,
    processCount: 0,
    standbyCount: 0,
    completeCount: 0
  }

  public payhelperList: any = [];
  public statusList: any = [];
  product_gbn_list = [{value: '', label: '전체'}, {value: 'BIKE', label: '오토바이'}, {value: 'SUPPLIES', label: '오토바이용품'}]

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private bikeService: BikeService,
    private restful: RestfulService,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private commonService: CommonService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'seq', width: 60, cellClass: 'cp center' },
      { headerName: '매물번호', field: 'sell_seq', width: 90, cellClass: 'cp center' },
      // { headerName: '거래구분', field: 'sell_gbn', width: 72, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter(params: any) {
      //     let className: any = '';
      //     switch( params.data.sell_gbn ) {
      //       case '1000':
      //         className = 'badge badge-success f12';
      //         break;
      //       case '2000':
      //         className = 'badge cborange f12';
      //         break;
      //       case '3000':
      //         className = 'badge cbpurple f12';
      //         break;
      //       default:
      //         className = '';
      //     }

      //     return '<span class="' + className + '">' + params.data.sell_gbn_name + '</span>';
      //   }
      // },
      { headerName: '상품종류', field: 'product_gbn', width: 110, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.product_gbn == 'SUPPLIES' ? 
          '<span class="badge cborange f12">오토바이용품</span>' : 
          '<span class="badge badge-success f12">오토바이</span>'
        }
      },
      { headerName: '판매자(ID)', field: 'seller', width: 181, cellClass: 'cp left', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          return `${params.data.seller_name}(${params.data.seller_id})`;
        }
      },
      { headerName: '구매자(ID)', field: 'buyer', width: 181, cellClass: 'cp left', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          return `${params.data.buyer_name}(${params.data.buyer_id})`;
        }
      },
      { headerName: '등록일시', field: 'write_date', width: 142, cellClass: 'cp center' },
      { headerName: '발송일시', field: 'consign_date', width: 92, cellClass: 'cp center' },
      { headerName: '수령일시', field: 'receive_date', width: 92, cellClass: 'cp center' },
      // { headerName: '취소일시', field: 'cancel_date', width: 142, cellClass: 'cp center' },
      { headerName: '거래금액', field: 'sale_price', width: 90, cellClass: 'cp right', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '수수료', field: 'total_fee', width: 80, cellClass: 'cp right', 
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '정산금액', field: 'calculate_price', width: 90, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '완료일시', field: 'end_date', width: 142, cellClass: 'cp center' },
      // { headerName: '송금일시', field: 'pay_date', width: 142, cellClass: 'cp center' },
      { headerName: '정산예정일', field: 'calculate_date', width: 142, cellClass: 'cp center' },
      { headerName: '결제수단', field: 'pay_method', width: 90, cellClass: 'cp center' },
      { headerName: '상태', field: 'status', width: 95, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let className: any = '';
          switch( params.data.status ) {
            case '1000': className = 'badge badge-secondary f12'; break;
            case '2000': className = 'badge cborange f12'; break;
            case '3000': className = 'badge badge-success f12'; break;
            case '4000': className = 'badge badge-primary f12'; break;
            case '5000': className = 'badge badge-primary f12'; break;
            case '6000': className = 'badge badge-info f12'; break;
            case '7000': className = 'badge cbpurple f12'; break;
            case '7010': className = 'badge badge-warning f12'; break;
            case '8000': className = 'badge badge-dark f12'; break;
            case '9010': className = 'badge badge-danger f12'; break;
            case '9020': className = 'badge badge-danger f12'; break;
            case '9030': className = 'badge badge-danger f12'; break;
            default: className = 'badge badge-secondary f12';
          }

          return '<span class="' + className + '">' + params.data.status_name + '</span>';
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();

    this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.search.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? 1 : params.pageNo;
        this.search.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? 10 : params.pageRow;
        this.search.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? 'sell_seq' : params.searchField;
        this.search.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
        this.search.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? this.utilService.getDate(moment().subtract(90, 'day')) : params.sdate;
        this.search.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? this.utilService.getDate('') : params.edate;
        this.search.searchTerm = ( typeof params.searchTerm == 'undefined' ) ? this.getDays(90) : params.searchTerm;
        this.search.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;
        this.search.status = ( typeof params.status == 'undefined' ) ? '' : params.status;
        this.search.payMethod = ( typeof params.payMethod == 'undefined' ) ? '' : params.payMethod;

        if( this.search.sdate != '' ) this.search.sdate = this.utilService.getDate(this.search.sdate);
        if( this.search.edate != '' ) this.search.edate = this.utilService.getDate(this.search.edate);
      }

      // 이벤트 리스트 가져오기
      this.getBikePayhelperList();
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 20,
      seq: '',
      searchField: 'sell_seq',
      searchText: '',
      sdate: this.utilService.getDate(moment().subtract(90, 'day')),
      edate: this.utilService.getDate(''),
      searchTerm: this.getDays(90),
      totalCount: 0,
      status: '',
      payMethod: ''
    };

    this.getBikePayhelperList();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 상태
    this.commonService.getCommonListCode('BHS').then( response => {
      if ( response.ResultCode ) {
        this.statusList = response.data;
      } else {
        this.statusList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 결제도우미 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBikePayhelperList() {
    this.bikeService.getBikePayhelperList(this.search).then( response => {
      if( response.ResultCode ) {
        this.payhelperList = response.data;
        this.statistic = response.statistic;
      } else {
        this.toastrService.error( response.ResultMessage, '이벤트리스트');

        this.statistic = {
          totalCount: 0,
          searchCount: 0,
          processCount: 0,
          standbyCount: 0,
          completeCount: 0
        }
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field === 'seq' ) {
      this.goDetail($event.data.seq);
    }

    if( $event.colDef.field === 'seller' || $event.colDef.field === 'buyer' ) {
      this.openMemberInfo($event.data[$event.colDef.field]);
    }

    if( $event.colDef.field === 'sell_seq' ) {
      let seq = $event.data.sell_seq;
      let gbn = $event.data.sell_gbn;
      const url = 'http://bikeweb.bikemart.co.kr/transaction/detail?page=1&gbn=' + gbn + '&seq=' + seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 결제확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBikePayhelperConfirm() {
    const rows = this.gridApi.getSelectedRows();
    if( rows.length < 1 ) {
      this.toastrService.error('결제확인할 내역을 선택하시기 바랍니다.');
    } else {
      const params = [];

      let check: boolean;
      rows.forEach(item => {
        if(item.status !== '3000') check = true;

        params.push(item.seq);
      });

      if( check === true ) {
        this.toastrService.error('[결제확인중] 상태의 내역만 처리가 가능합니다.');
      } else {
        if ( confirm('선택하신 내역을 결제확인하시겠습니까?') ) {
          this.bikeService.setBikePayhelperConfirm( JSON.stringify(params) ).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success(response.ResultMessage);

              this.getBikePayhelperList();
            } else {
              this.toastrService.error(response.ResultMessage);
            }
          });
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 회원 정보 모달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openMemberInfo( memNo: number ) {
    const modalRef = this.modalService.open(MemberInfoComponent, options);

    modalRef.componentInstance.memberNo = memNo;
    modalRef.componentInstance.dormant = false;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 상세보기 링크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( seq: any ) {
    this.search.seq = seq;

    this.search.sdate = this.utilService.getDateStr(this.search.sdate);
    this.search.edate = this.utilService.getDateStr(this.search.edate);

    this.router.navigate(
    ['/bike/payhelper/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 날짜 선택 시 처리
    입력값 : obj = NgbInputDatepicker, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = moment().format('YYYY-MM-DD');
    } else {
      this.search.edate = moment().format('YYYY-MM-DD');
    }
    obj.close();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getBikePayhelperList();
    }
  }
  
  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate: any;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
  }
  
}
