import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { SmsService } from '@app/service/sms.service';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss']
})
export class OrderPaymentComponent implements OnInit {

  public search = {}
  public productList: any = [];

  public form: FormGroup;
  public formErrors = {};
  
  @Input() order_seq: any;
  @Input() o_hp: any;

  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      pay_method: ['0002', [Validators.required] ],
      date_search: ['0', [] ],
      pay_date: [ null, [Validators.required] ],
      sms_yn: ['0', [] ],
      o_hp: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService,
    private smsService: SmsService,

  ) {
    this.buildForm();
   }

  ngOnInit(): void {
    this.form.patchValue({
      seq: this.order_seq,
      pay_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      o_hp: this.o_hp
    });
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( value: any ) {
    const dateString = moment().subtract( value, 'day' ).format('YYYY-MM-DD HH:mm:ss');

    this.form.patchValue({
      date_search: value,
      pay_date: dateString
    });
  }

  /*******************************************************************************
    설  명 : 결제 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderPayComplete() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.orderService.setOrderPayComplete(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(this.form);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
