  <div class="modal-header">
    <h4 class="modal-title fb mb0">프랜차이즈 신청정보</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">

	  <form [formGroup]="form">
    <inputEx type="hidden" name="agency_seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
	
    <mat-tab-group>
      <mat-tab label="추가정보">
        <table class="table table-bordered table-small1 mb0">
        <caption class="none">추가정보</caption>
        <colgroup>
          <col style="width:10%;" />
          <col style="width:23%;" />
          <col style="width:8%;" />
          <col style="width:25%;" />
          <col style="width:8%;" />
          <col style="width:25%;" />
        </colgroup>

        <tbody>
        <tr>
          <th scope="row"><label for="agency_name"><i class="material-icons cred f11 lh20">star</i> 회사명</label></th>
          <td><inputEx type="text" name="agency_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          <th scope="row"><label for="agency_phone">연락처</label></th>
          <td><inputEx type="text" name="agency_phone" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          <th scope="row"><label for="agency_hphone1">휴대폰</label></th>
          <td><inputEx type="text" name="agency_hphone1" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        <tr>
          <th scope="row"><label for="agency_display">표시여부</label></th>
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.agency_display.value=='1'}" (click)="form.get('agency_display').setValue('1')">표시</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.agency_display.value=='0'}" (click)="form.get('agency_display').setValue('0')">표시안함</button>
            </div>
            <inputEx type="hidden" name="agency_display" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
          <th scope="row"><label for="agency_ali_date">계약일</label></th>
          <td><inputEx type="date" name="agency_ali_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          <th scope="row"><label for="agency_ali_end_date">계약만료일</label></th>
          <td><inputEx type="date" name="agency_ali_end_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        <tr>
          <th scope="row"><label for="agency_zipcode">우편번호</label></th>
          <td>
            <div class="input-group">
              <inputEx name="agency_zipcode" class="mr3" style="width:60%" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
              <btn-daum-address (result)="setDaumAddressApiAgency($event)" [options]="daumAddressOptions"></btn-daum-address>              
            </div>
          </td>
          <th scope="row"><label for="agency_addr">주소</label></th>
          <td><inputEx type="text" name="agency_addr" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
          <th scope="row"><label for="agency_addr_detail">상세주소</label></th>
          <td><inputEx type="text" name="agency_addr_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        <tr>
          <th scope="row"><label for="agency_state">지역</label></th>
          <td><inputEx type="select" name="agency_state" [formGroup]="form" [formErrors]="formErrors" [data]="stateList"></inputEx></td>
          <th scope="row"><label for="agency_sale">매출액</label></th>
          <td><inputEx type="text" name="agency_sale" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          <th scope="row"><label for="agency_opentime">오픈시간</label></th>
          <td><inputEx type="text" name="agency_opentime" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        <tr>
          <th scope="row"><label for="agency_howtocounsel">구매 상담정보</label></th>
          <td colspan="5"><inputEx type="text" name="agency_howtocounsel" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        <tr>
          <th scope="row"><label for="agency_howtoorder">주문방법안내</label></th>
          <td colspan="5"><inputEx type="text" name="agency_howtoorder" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        </tr>
        </tbody>
        </table>
      </mat-tab>
      <mat-tab label="업체 정보">
        <inputEx type="summernote" name="agency_info" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
      </mat-tab>
      <mat-tab label="업체 상세정보">
        <inputEx type="summernote" name="agency_content" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
      </mat-tab>
    </mat-tab-group>

	  </form>


  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button type="button" class="btn btn-danger btn-small" (click)="setMemberTransitionApplicationRefuse()">거절하기</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small mr5" (click)="setMemberTransitionApplicationApprove()">승인하기</button>
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
  </div>
