<ng-container *ngIf="isPopup">
  <div class="modal-header">
    <h4 *ngIf="memberNo!=0" class="modal-title fb mb0"><span *ngIf="dormant===true">휴면</span>회원 상세정보</h4>
    <h4 *ngIf="memberNo==0" class="modal-title fb mb0">회원 추가</h4>
    <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
  </div>
  </ng-container>

  <div class="modal-body p0">
    <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="onTabChange($event)">

      <mat-tab label="회원정보">
        <div *ngIf="activeTab==0" id="member-info" class="p10">

          <form [formGroup]="form">
          <inputEx type="hidden" name="mem_no" [formGroup]="form" [formErrors]="formErrors"></inputEx>

          <div class="">
            <div class="fl w50p">
              <h4 class="fb mt5 mb6">회원정보</h4>
            </div>
            <div class="fr w50p right">
              <ng-container *ngIf="dormant===false">
                <button type="button" class="btn btn-primary btn-small" (click)="setMemberSave()">저장하기</button>
              </ng-container>
            </div>
            <div class="cb"></div>
          </div>

          <table class="table table-bordered table-small1 mb0">
          <caption class="none">회원정보</caption>
          <colgroup>
            <col style="width:10%;" />
            <col style="width:23%;" />
            <col style="width:10%;" />
            <col style="width:23%;" />
            <col style="width:10%;" />
            <col style="width:23%;" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="grade">회원유형</label></th>
            <td colspan="3">
              <div class="btn-group">
                <button *ngFor="let item of gradeList" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.grade.value==item.common_code}" (click)="form.get('grade').setValue(item.common_code)">{{item.common_name}}</button>
              </div>
              <inputEx type="hidden" name="grade" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="entered_status">업체구분</label></th>
            <td>
              <div class="btn-group">
                <button *ngFor="let item of enteredStatusList" type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.entered_status.value==item.common_code}" (click)="form.get('entered_status').setValue(item.common_code)">{{item.common_name}}</button>
              </div>
              <inputEx type="hidden" name="entered_status" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="name">한글이름</label></th>
            <td>
              <inputEx type="text" name="name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="id">아이디</label></th>
            <td><inputEx type="text" name="id" [formGroup]="form" [formErrors]="formErrors" (keyup)="checkAsterisk()" [addOn]="addOncheckIdDuplicateFunc" addOnText="중복검사"></inputEx></td>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="password">패스워드</label></th>
            <td>
              <inputEx *ngIf="memberNo!=0 && dormant===false" type="password" name="password" [formGroup]="form" [formErrors]="formErrors" [addOn]="addPwChangeFunc" addOnText="비밀번호 변경"></inputEx>
              <inputEx *ngIf="memberNo==0 || dormant===true" type="password" name="password" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="gender">성별</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gender.value=='M'}" (click)="form.get('gender').setValue('M')">남</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.gender.value=='F'}" (click)="form.get('gender').setValue('F')">여</button>
              </div>
              <inputEx type="hidden" name="gender" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            <th scope="row"><label for="email">이메일</label></th>
            <td><inputEx type="text" name="email" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="">전화번호</label></th>
            <td><inputEx type="text" name="hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="email_yn">이메일수신</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.email_yn.value=='1'}" (click)="form.get('email_yn').setValue('1')">예</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.email_yn.value=='0'}" (click)="form.get('email_yn').setValue('0')">아니오</button>
              </div>
              <inputEx type="hidden" name="email_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            <th scope="row"><label for="sms_yn">SMS수신</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value=='1'}" (click)="form.get('sms_yn').setValue('1')">예</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.sms_yn.value=='0'}" (click)="form.get('sms_yn').setValue('0')">아니오</button>
              </div>
              <inputEx type="hidden" name="sms_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            <th scope="row"><label for="player_discount_yn">선수할인적용여부</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.player_discount_yn.value=='1'}" (click)="form.get('player_discount_yn').setValue('1')">예</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.player_discount_yn.value=='0'}" (click)="form.get('player_discount_yn').setValue('0')">아니오</button>
              </div>
              <inputEx type="hidden" name="player_discount_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            <!-- <th scope="row"><label for="kakao_yn">KAKAO수신</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.kakao_yn.value=='1'}" (click)="form.get('kakao_yn').setValue('1')">예</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.kakao_yn.value=='0'}" (click)="form.get('kakao_yn').setValue('0')">아니오</button>
              </div>
              <inputEx type="hidden" name="kakao_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td> -->
          </tr>
          <tr>
            <th scope="row"><label for="bill_yn">현금영수증발행여부</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.bill_yn.value=='1'}" (click)="form.get('bill_yn').setValue('1')">예</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.bill_yn.value=='0'}" (click)="form.get('bill_yn').setValue('0')">아니오</button>
              </div>
              <inputEx type="hidden" name="bill_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            <th scope="row">동호회명</th>
            <td colspan="3"><inputEx type="text" readonly="true" name="society_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <!-- <th scope="row"></th>
            <td></td> -->
            <!-- <th scope="row"><label for="kakao_yn">KAKAO수신</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.kakao_yn.value=='1'}" (click)="form.get('kakao_yn').setValue('1')">예</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.kakao_yn.value=='0'}" (click)="form.get('kakao_yn').setValue('0')">아니오</button>
              </div>
              <inputEx type="hidden" name="kakao_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td> -->
          </tr>
          <tr>
            <!--
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="birthdate">생년월일</label></th>
            <td><inputEx type="date" name="birthdate" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
            <th scope="row"><label for="birth_sec">양력/음력</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.birth_sec.value=='S'}" (click)="form.get('birth_sec').setValue('S')">양력</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.birth_sec.value=='L'}" (click)="form.get('birth_sec').setValue('L')">음력</button>
              </div>
              <inputEx type="hidden" name="birth_sec" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
            -->
            <!--
            <th scope="row"><label for="email_reject_date">Email수신거부일</label></th>
            <td><inputEx type="text" name="email_reject_date" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
            -->
           <!--
            <th scope="row"><label for="sms_reject_date">SMS수신거부일</label></th>
            <td><inputEx type="text" name="sms_reject_date" [formGroup]="form" [formErrors]="formErrors"  readonly="true"></inputEx></td>
            -->
          </tr>
          </tbody>
          </table>

          <ng-container *ngIf="form.controls.grade.value=='0002'">
          <div class="mt5">
            <div class="fl w50p">
              <h4 class="fb mt5 mb6">상사정보</h4>
            </div>
            <div class="fr w50p right">
            </div>
            <div class="cb"></div>
          </div>

          <table class="table table-bordered table-small1 mb0">
          <caption class="none">상사정보</caption>
          <colgroup>
            <col style="width:10%;" />
            <col style="width:23%;" />
            <col style="width:10%;" />
            <col style="width:23%;" />
            <col style="width:10%;" />
            <col style="width:23%;" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row"><label for="shop_zipcode">우편번호</label></th>
            <td>
              <div class="input-group">
                <inputEx name="shop_zipcode" class="mr3" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
                <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
              </div>
            </td>
            <th scope="row"><label for="shop_address">주소</label></th>
            <td><inputEx type="text" name="shop_address" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
            <th scope="row"><label for="shop_address_detail">상세주소</label></th>
            <td><inputEx type="text" name="shop_address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="shop_name">상사명</label></th>
            <td><inputEx type="text" name="shop_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="license">사업자번호</label></th>
            <td><inputEx type="text" name="license" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="shop_homepage">홈페이지주소</label></th>
            <td><inputEx type="text" name="shop_homepage" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="shop_phone">상사전화</label></th>
            <td><inputEx type="text" name="shop_phone" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="shop_fax">상사팩스</label></th>
            <td><inputEx type="text" name="shop_fax" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="shop_email">상사이메일</label></th>
            <td><inputEx type="text" name="shop_email" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="bank">은행</label></th>
            <td><inputEx type="text" name="bank" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="account">계좌번호</label></th>
            <td><inputEx type="text" name="account" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="depositor">예금주</label></th>
            <td><inputEx type="text" name="depositor" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="shop_discount_rate">할인요율</label></th>
            <td><inputEx type="text" name="shop_discount_rate" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="use_yn">사용여부</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='1'}" (click)="form.get('use_yn').setValue('1')">예</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='0'}" (click)="form.get('use_yn').setValue('0')">아니오</button>
              </div>
            </td>
            <th scope="row"><label for="shop_sena_seller_yn">세나 판매점 구분</label></th>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.shop_sena_seller_yn.value=='1'}" (click)="form.get('shop_sena_seller_yn').setValue('1')">예</button>
                <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.shop_sena_seller_yn.value=='0'}" (click)="form.get('shop_sena_seller_yn').setValue('0')">아니오</button>
              </div>
            </td>
          </tr>

          </tbody>
          </table>
          </ng-container>

          <ng-container *ngIf="form.controls.grade.value != '0000' && form.controls.grade.value != '0001' && form.controls.grade.value != '0002'">
            <mat-tab-group class="member-info">
              <mat-tab label="추가정보">
                <table class="table table-bordered table-small1 mb0">
                <caption class="none">추가정보</caption>
                <colgroup>
                  <col style="width:10%;" />
                  <col style="width:23%;" />
                  <col style="width:10%;" />
                  <col style="width:23%;" />
                  <col style="width:10%;" />
                  <col style="width:23%;" />
                </colgroup>

                <tbody>
                <tr>
                  <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="agency_name">회사명</label></th>
                  <td><inputEx type="text" name="agency_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                  <th scope="row"><label for="agency_phone">전화번호</label></th>
                  <td><inputEx type="text" name="agency_phone" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                  <th scope="row"><label for="ceo_name">대표자명</label></th>
                  <td><inputEx type="text" name="ceo_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                </tr>
                <tr>
                  <th scope="row"><label for="agency_name">대표자휴대폰</label></th>
                  <td><inputEx type="text" name="agency_hphone1" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                  <th scope="row"><label for="charge_name">담당자명</label></th>
                  <td><inputEx type="text" name="charge_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                  <th scope="row"><label for="charge_hp">담당자휴대폰</label></th>
                  <td><inputEx type="text" name="charge_hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                </tr>
                <tr>
                  <th scope="row"><label for="agency_display">표시여부</label></th>
                  <td>
                    <div class="btn-group">
                      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.agency_display.value=='1'}" (click)="form.get('agency_display').setValue('1')">표시</button>
                      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.agency_display.value=='0'}" (click)="form.get('agency_display').setValue('0')">표시안함</button>
                    </div>
                  </td>
                  <th scope="row"><label for="agency_ali_date">계약일</label></th>
                  <td><inputEx type="date" name="agency_ali_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                  <th scope="row"><label for="agency_ali_end_date">계약만료일</label></th>
                  <td><inputEx type="date" name="agency_ali_end_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                </tr>
                <tr>
                  <th scope="row"><label for="agency_zipcode">우편번호</label></th>
                  <td>
                    <div class="input-group">
                      <inputEx name="agency_zipcode" style="width:60%" class="mr3" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
                      <btn-daum-address (result)="setDaumAddressApiAgency($event)" [options]="daumAddressOptions"></btn-daum-address>
                    </div>
                  </td>
                  <th scope="row"><label for="agency_addr">주소</label></th>
                  <td><inputEx type="text" name="agency_addr" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
                  <th scope="row"><label for="agency_addr_detail">상세주소</label></th>
                  <td><inputEx type="text" name="agency_addr_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                </tr>
                <tr>
                  <th scope="row"><label for="agency_state">지역</label></th>
                  <td><inputEx type="select" name="agency_state" [formGroup]="form" [formErrors]="formErrors" [data]="stateList"></inputEx></td>
                  <th scope="row"><label for="agency_discount_rate">할인요율</label></th>
                  <td><inputEx type="text" name="agency_discount_rate" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                  <th scope="row"><label for="agency_opentime">오픈시간</label></th>
                  <td><inputEx type="text" name="agency_opentime" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                </tr>
                <tr>
                  <th scope="row"><label for="shop_sena_seller_yn">세나 판매점 구분</label></th>
                  <td>
                    <div class="btn-group">
                      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.agency_sena_seller_yn.value=='1'}" (click)="form.get('agency_sena_seller_yn').setValue('1')">예</button>
                      <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.agency_sena_seller_yn.value=='0'}" (click)="form.get('agency_sena_seller_yn').setValue('0')">아니오</button>
                    </div>
                  </td>
                  <th scope="row"></th>
                  <td></td>
                  <th scope="row"></th>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row"><label for="agency_howtocounsel">구매 상담정보</label></th>
                  <td colspan="5"><inputEx type="text" name="agency_howtocounsel" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                </tr>
                <tr>
                  <th scope="row"><label for="agency_howtoorder">주문방법안내</label></th>
                  <td colspan="5"><inputEx type="text" name="agency_howtoorder" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
                </tr>
                </tbody>
                </table>
              </mat-tab>
              <!-- <mat-tab label="{{title}} 정보">
                <inputEx type="summernote" name="agency_info" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
              </mat-tab> -->
              <mat-tab label="협력점 정보" *ngIf="form.controls.grade.value == '0004'">
                <inputEx type="summernote" name="agency_info" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
              </mat-tab>
              <mat-tab label="프렌차이즈 정보" *ngIf="form.controls.grade.value == '0007'">
                <inputEx type="summernote" name="agency_info" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
              </mat-tab>
              <mat-tab label="협럭점 상세정보" *ngIf="form.controls.grade.value == '0004'">
                <inputEx type="summernote" name="agency_content" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
              </mat-tab>
              <mat-tab label="프렌차이즈 상세정보" *ngIf="form.controls.grade.value == '0007'">
                <inputEx type="summernote" name="agency_content" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
              </mat-tab>
            </mat-tab-group>

          </ng-container>

          <ng-container *ngIf="memberNo!=0">
          <div class="mt5">
            <div class="fl w50p">
              <h4 class="fb mt5 mb6">기타정보</h4>
            </div>
            <div class="fr w50p right">
            </div>
            <div class="cb"></div>
          </div>

          <table class="table table-bordered table-small1 mb0">
          <caption class="none">기타정보</caption>
          <colgroup>
            <col style="width:10%;" />
            <col style="width:23%;" />
            <col style="width:10%;" />
            <col style="width:23%;" />
            <col style="width:10%;" />
            <col style="width:23%;" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row"><label for="joindate">가입일자</label></th>
            <td><inputEx type="text" name="joindate" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
            <!-- <th scope="row"><label for="write_ip">최종접속IP</label></th>
            <td><inputEx type="text" name="write_ip" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td> -->
            <th scope="row"><label for="last_logindate">최종접속</label></th>
            <td><inputEx type="text" name="last_logindate" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
            <th scope="row"><label for="buyclass">쇼핑등급</label></th>
            <td><inputEx type="text" name="buyclass" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
          </tr>
          <tr>
            <!-- <th scope="row"><label for="member_group">구매성향</label></th>
            <td><inputEx type="text" name="member_group" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
            <th scope="row"><label for="total_mileage">마일리지</label></th>
            <td><inputEx type="numeric" name="total_mileage" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td> -->
            <th scope="row"><label for="bank">은행명</label></th>
            <td><inputEx type="numeric" name="bank" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
            <th scope="row"><label for="account">계좌번호</label></th>
            <td><inputEx type="numeric" name="account" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
            <th scope="row"><label for="depositor">예금주</label></th>
            <td><inputEx type="numeric" name="depositor" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="buy_count">총구매횟수</label></th>
            <td><inputEx type="numeric" name="buy_count" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
            <th scope="row"><label for="buy_sum">총구매금액</label></th>
            <td><inputEx type="numeric" name="buy_sum" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
            <th scope="row"><label for="margin_sum">공헌도</label></th>
            <td><inputEx type="numeric" name="margin_sum" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
          </tr>
          </tbody>
          </table>
          </ng-container>

          </form>

        </div>
      </mat-tab>
      <mat-tab *ngIf="memberNo!=0" label="주소록">
        <app-member-address *ngIf="activeTab==1" [memberNo]="memberNo" [dormant]="dormant"></app-member-address>
      </mat-tab>
      <mat-tab *ngIf="memberNo!=0" label="주문내역">
        <app-member-order *ngIf="activeTab==2" [memberNo]="memberNo" [dormant]="dormant"></app-member-order>
      </mat-tab>
      <mat-tab *ngIf="memberNo!=0" label="메모내역">
        <app-member-memo *ngIf="activeTab==3" [memberNo]="memberNo" [dormant]="dormant"></app-member-memo>
      </mat-tab>
      <!-- <mat-tab *ngIf="memberNo!=0" label="문의내역">
        <app-member-qna *ngIf="activeTab==3" [memberNo]="memberNo" [dormant]="dormant"></app-member-qna>
      </mat-tab> -->
      <mat-tab *ngIf="memberNo!=0" label="환불/교환/반품내역">
        <app-member-refund *ngIf="activeTab==4" [memberNo]="memberNo" [dormant]="dormant"></app-member-refund>
      </mat-tab>
      <mat-tab *ngIf="memberNo!=0" label="마일리지">
        <app-member-mileage *ngIf="activeTab==5" [memberNo]="memberNo" [dormant]="dormant" [orderSeq]="orderSeq"></app-member-mileage>
      </mat-tab>
      <mat-tab *ngIf="memberNo!=0" label="바마쿠폰">
        <app-member-coupon-list *ngIf="activeTab==6" [memberNo]="memberNo" [dormant]="dormant" [orderSeq]="orderSeq"></app-member-coupon-list>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-container *ngIf="isPopup">
  <div class="modal-footer">
    <div class="fl w50p">
      <ng-container *ngIf="dormant===false">
        <button *ngIf="memberNo!=0" type="button" class="btn btn-danger btn-small" (click)="setMemberDelete()">회원탈퇴</button>
      </ng-container>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="setModalDismiss()">창닫기</button>
    </div>
  </div>
  </ng-container>
