import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '@app/service/product.service';
import calcPrice, { isSena } from '@components/calcPrice';

@Component({
  selector: 'app-margin-price-add',
  templateUrl: './margin-price-add.component.html',
  styleUrls: ['./margin-price-add.component.scss']
})
export class ProductMarginAddComponent implements OnInit {

  private date: NgbDateStruct = this.utilService.getDate('');

  public search = {}
  public productList: any = [];
  public form: FormGroup;
  public formErrors = {};
  
  public selectedItem: any;
  showEditFraPrice = false

  /*******************************************************************************
    설  명 : 폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      date: [this.date, [Validators.required] ],
      consumer_price: ['', [Validators.required] ],
      buy_price: ['', [Validators.required] ],
      fra_price: [''],
      option: ['2', [] ],
      product_seq: ['', [] ],
      property_seq: ['', [] ],
      product_code: ['', [] ],
      product_gbn: ['']
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private productService: ProductService
  ) {
    this.buildForm();
   }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue(this.selectedItem[0]);
    this.showEditFraPrice = isSena(this.selectedItem[0]?.brand_seq)
  }

  /*******************************************************************************
    설  명 : 가격 등록 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductMarginAdd() {
    if(this.form.valid) {
      const price = calcPrice(this.form.value.consumer_price, this.form.value.product_gbn, this.form.value.buy_price, this.selectedItem[0]?.brand_seq)
      const fraPrice = isSena(this.selectedItem[0]?.brand_seq) ? parseInt(this.form.value.fra_price) : price.fra

      if(!fraPrice) {
        this.toastrService.error('프랜차이즈가를 입력하세요.')
        return
      }

      this.productService.setProductMarginAdd({value: {
        ...this.form.value,
        b2b_price: price.b2b,
        com_price: price.com,
        fra_price: fraPrice
      } }).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
