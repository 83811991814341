/*******************************************************************************
  설  명 : 기초관리 > 기본정보설정 > SNS연동 탭
  작성일 : 2021-05-17
  작성자 : 송영석
  접속URL : /basic/company/info
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { SiteConfigService } from '@app/service/site.config.service';

const CONFIG_KEY = 'SNS';

@Component({
  selector: 'app-basic-sns',
  templateUrl: './basic-sns.component.html',
  styleUrls: ['./basic-sns.component.scss']
})
export class BasicSnsComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      naver_use_yn: ['', [] ],
      naver_client_id: ['', [Validators.maxLength(50)] ],
      naver_callback_url: ['', [Validators.maxLength(100)] ],
      naver_client_secret: ['', [Validators.maxLength(100)] ],
      kakao_use_yn: ['', [] ],
      kakao_id: ['', [Validators.maxLength(50)] ],
      kakao_app_key: ['', [Validators.maxLength(100)] ],
      facebook_use_yn: ['', [] ],
      facebook_id: ['', [Validators.maxLength(50)] ],
      facebook_secret_key: ['', [Validators.maxLength(100)] ],
      facebook_version: ['', [Validators.maxLength(20)] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private siteConfigService: SiteConfigService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getConfig();
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          naver_use_yn:      response.data.naver_use_yn || '',
          naver_client_id: response.data.naver_client_id || '',
          naver_callback_url:      response.data.naver_callback_url || '',
          kakao_use_yn:    response.data.kakao_use_yn || '',
          kakao_id:      response.data.kakao_id || '',
          kakao_app_key:    response.data.kakao_app_key || '',
          facebook_use_yn:    response.data.facebook_use_yn || '',
          facebook_id:    response.data.facebook_id || '',
          facebook_secret_key:    response.data.facebook_secret_key || '',
          facebook_version:    response.data.facebook_version || '',
        });
      }
    });
  }  

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.siteConfigService.setConfig(CONFIG_KEY, this.form).then( response => {
        if ( response.ResultCode ) this.toastrService.success( response.ResultMessage, 'SNS 연동 저장');
        else this.toastrService.error( response.ResultMessage, 'SNS 연동 저장');
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', 'SNS 연동 저장');
    }
  }
}
