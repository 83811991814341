import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SystemGroupService } from '@app/service/system.group.service';
import { BasicService } from '@app/service/basic.service';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';


@Component({
  selector: 'app-alarmmanager-add',
  templateUrl: './alarmmanager-add.component.html',
  styleUrls: ['./alarmmanager-add.component.scss'],
})

export class AlarmmanagerAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };
  public nodeData: any = [];
  public messageType: any = '';
  public employeeList: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  rowSelection: any;

  defaultColDef: any;
  domLayout: any;

  noRowsTemplate: string;



  /*******************************************************************************
     설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private systemGroupService: SystemGroupService,
    private basicService: BasicService
  ) {

    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
       headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '이름', width: 150, field: 'name', cellClass: 'cp left' },
      { headerName: '삭제', field: 'delete', width: 80, cellClass: 'cp center',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'delete',  
          btnName: 'row 삭제', 
          listaction: this.selectDelete.bind(this)
        }
      }
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

  }

  /*******************************************************************************
     설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getGroupEmployeeList();
  }

  /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getGroupEmployeeList() {
    this.systemGroupService.getGroupEmployeeList().then( response => {
      if ( response.ResultCode ) {
        this.nodeData = response.data;
      } else {
        this.nodeData = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 선택된 열 제거
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectDelete( params ) {

    let obj: any;
    obj = this.employeeList;
    obj = obj.filter(row => row.mem_no !== params.data.mem_no);
    this.employeeList = obj;

  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 담당자 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMessageCharge() {
    const params = this.employeeList.map(row => row.mem_no);

    this.basicService.setMessageCharge( this.messageType, params ). then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success( response.ResultMessage, '저장을 성공하였습니다.');
        
        this.activeModal.close(true);
        
      } else {
          this.toastrService.error( response.ResultMessage, '저장에 실패하였습니다.');
      }
    })
  }

  /*******************************************************************************
    설  명 : 노드 선택시 이벤트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async onSelectNode($event) {
    if ( $event.mem_no !== null ) {
      const tmp = [...this.employeeList];

      const dupleCheck = this.employeeList.filter( row => {
        return row.mem_no === $event.mem_no;
      });
      
      if( dupleCheck.length < 1 ) {
        tmp.push($event);
      }

      this.employeeList = tmp;
    }
  }

}
