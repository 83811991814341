<section class="apage-title">
  <div class="title-bar"></div>
  <h3>상품문의</h3>
</section>

<section class="adata-list-area">
  <div class="asub-title">
    <h4>글보기</h4>
  </div>

  <section class="data-list">

    <table class="list-table">
    <colgroup>
      <col style="width:10%" />
      <col style="width:60%" />
      <col style="width:30%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="col">제목</th>
      <td [innerHTML]="form.value.comment"></td>
      <td class="right">
        {{form.value.name}}
        <span class="f10">({{form.value.question_date}})</span>
      </td>
    </tr>
    <tr>
      <th scope="col">상품명</th>
      <td colspan="2">[{{form.value.product_seq}}] {{form.value.product_name}}</td>
    </tr>
    <tr style="border-bottom:none;">
      <td colspan="3">
        <div class="board-contents" [innerHTML]="form.value.comment | safe : 'html'"></div>
        <div class="board-contents" style="color:red" [innerHTML]="form.value.answer | safe : 'html'"></div>
      </td>
    </tr>
    <tr style="border-top:none;">
      <td colspan="3"><span *ngIf="form.value.tag != null">#</span>{{form.value.tag}}</td>
    </tr>
    </tbody>
    </table>

    <div class="mt10 mb10">
      <div class="fl">
        <a class="btn btn-default" (click)="list()">목록</a>
      </div>

      <div class="fr">
        <button *ngIf="authCheck()" type="button" class="btn btn-danger mr10" (click)="delete()">삭제</button>
        <button class="btn btn-default" *ngIf="form.value.answer == null" (click)="reply()">답변쓰기</button>
        <button class="btn btn-default" *ngIf="form.value.answer !== null" (click)="reply()">수정</button>
      </div>

      <div class="cb"></div>
    </div>
  </section>

</section>