import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { BikeService } from '@app/service/bike.service';
import { SmsService } from '@app/service/sms.service';

@Component({
  selector: 'app-bike-payhelper-detail-date',
  templateUrl: './bike-payhelper-detail-date.component.html',
  styleUrls: ['./bike-payhelper-detail-date.component.scss']
})
export class BikePayhelperDetailDateComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() helperSeq: any;
  
  public form: FormGroup;
  public formErrors = {};
  
  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      calculate_date: [ null, [Validators.required] ],
      sms_yn: ['0', [] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private bikeService: BikeService,
    private smsService: SmsService,
  ) {
    this.buildForm();
   }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      seq: this.helperSeq,
      calculate_date: moment().format('YYYY-MM-DD')
    });
  }

  /*******************************************************************************
    설  명 : 정산예정일 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPayHelperCalculateDate() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.bikeService.setPayHelperCalculateDate(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(this.form);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
