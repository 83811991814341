<section id="member-sis-statistic">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">가맹점수<span class="cnt">{{summary?.partner_count | Numeral: "0,0"}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">{{summary?.today | Moment: "D"}}일 매출<span class="cnt">{{summary?.sales_total_day | Numeral: "0,0"}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">{{summary?.today | Moment: "M"}}월 매출<span class="cnt">{{summary?.sales_total_month | Numeral: "0,0"}}</span>원</p>
      </div>
      <div class="cb"></div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">{{summary?.today | Moment: "M"}}월 마진<span class="cnt">{{summary?.margin_total_month | Numeral: "0,0"}}</span>원({{summary?.margin_rate_month | Numeral: "0,0.00%"}})</p>
      </div>
      <div class="cb"></div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">
          {{summary?.today | Moment: "YYYY"}}년 매출
          <span class="cnt">{{summary?.sales_total_year | Numeral: "0,0"}}</span>원
        </p>
      </div>
      <div class="cb"></div>
    </div>
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">
          {{summary?.today | Moment: "YYYY"}}년 마진
          <span class="cnt">{{summary?.margin_total_year | Numeral: "0,0"}}</span>원({{summary?.margin_rate_year | Numeral: "0,0.00%"}})
        </p>
      </div>
      <div class="cb"></div>
    </div>
    <div class="cb"></div>
  </section>
  <mat-tab-group class="mt10" style="height:100%;">
    <mat-tab style="min-width:100px;" label="프렌차이즈 매출 통계">
      <app-member-sis-frastatistic-tab1></app-member-sis-frastatistic-tab1>
    </mat-tab>
    <mat-tab style="min-width:100px;height:100%;" label="프렌차이즈별 월별매출상세">
      <app-member-sis-frastatistic-tab2 style="height:100%;"></app-member-sis-frastatistic-tab2>
    </mat-tab>
    <mat-tab style="min-width:100px;" label="상품군 프렌차이즈별 매출상세">
      <app-member-sis-frastatistic-tab3></app-member-sis-frastatistic-tab3>
    </mat-tab>
    <mat-tab style="min-width:100px;" label="브랜드 프렌차이즈별 매출상세">
      <app-member-sis-frastatistic-tab4></app-member-sis-frastatistic-tab4>
    </mat-tab>
  </mat-tab-group>
</section>
