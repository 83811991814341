import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SiteConfigService {

  constructor(
    private restful: RestfulService
  ) {
  }

  // 그룹별 환경설정 가져오기
  async getConfig( group: any ): Promise<any> {
    try {
      const res = await this.restful.get({
        program: 'admin',
        service: 'config',
        version: '1.0',
        action: 'getConfig'
      }, {
        group: group
      }
      );
      return this.restful.extractData(res);
    } catch (error) {
      return this.restful.handleErrorPromise(error);
    }
  }

  // 모든 환경설정 가져오기
  async getConfigAll( group: any ): Promise<any> {
    try {
      const res = await this.restful.get({
        program: 'admin',
        service: 'config',
        version: '1.0',
        action: 'getConfigAll'
      }, {}
      );
      return this.restful.extractData(res);
    } catch (error) {
      return this.restful.handleErrorPromise(error);
    }
  }

  // 환경설정 저장하기
  async setConfig(group: any, form: any): Promise<any> {
    try {
      const res = await this.restful.post({
        program: 'admin',
        service: 'config',
        version: '1.0',
        action: 'setConfig'
      }, {
        group: group,
        data: form.value
      }
      );
      return this.restful.extractData(res);
    } catch (error) {
      return this.restful.handleErrorPromise(error);
    }
  }
  
  // 회원등급 설정 가져오기
  async getConfigMemberGrade(): Promise<any> {
    try {
      const res = await this.restful.get({
        program: 'admin',
        service: 'config',
        version: '1.0',
        action: 'getConfigMemberGrade'
      }, {}
      );
      return this.restful.extractData(res);
    } catch (error) {
      return this.restful.handleErrorPromise(error);
    }
  }

  // 회원등급 설정 저장하기
  async setConfigMemberGrade(data: any): Promise<any> {
    try {
      const res = await this.restful.post({
        program: 'admin',
        service: 'config',
        version: '1.0',
        action: 'setConfigMemberGrade'
      }, data
      );
      return this.restful.extractData(res);
    } catch (error) {
      return this.restful.handleErrorPromise(error);
    }
  }
  
}