/*******************************************************************************
  설  명 : 현황관리 - 일매출 속보실적
  작성일 : 2021.07.19
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';

import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ActivatedRoute, Router } from '@angular/router';
import { StockOrderComponent } from '@app/page/stock/stock-order/stock-order.component';
import { StatisticService } from '@app/service/statistic.service';

@Component({
  selector: 'app-statistic-day',
  templateUrl: './statistic-day.component.html',
  styleUrls: ['./statistic-day.component.scss'],
  styles: ['.ag-header-group-cell-label {justify-content: center !important;}']
})
export class StatisticDayComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    date: moment().format('YYYY-MM-DD')
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  fraGridApi: any;
  fraGridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;
  rowClassRules: any
  rowHeight: any = 30

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  dayList: any = [];
  fraDayList: any = [];
  search_time: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private statisticService: StatisticService,
    private stockOrderComponent: StockOrderComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '구분', field: '', width: 220, cellClass: 'cp center', children: [
        {headerName: '', field: 'gbn_name', width: 220, cellClass: 'cp center f16 lh30'}
      ]},
      { headerName: '온라인', field: '', cellClass: 'cp center',
       children:[
          { headerName: '매출', field: 'SALE_ONLINE', width: 200, cellClass: 'cp right f16 lh30', valueFormatter: this.agGridExComponent.currencyFormatter },
          { headerName: '취소/환불', field: 'RETURN_ONLINE', width: 200, cellClass: 'cp right f16 lh30', valueFormatter: this.agGridExComponent.currencyFormatter },
          { headerName: '합계', field: 'TOTAL_ONLINE', width: 200, cellClass: 'cp right f16 lh30',
            valueFormatter: this.agGridExComponent.currencyFormatter,
            valueGetter: function(params) {
              let saleOnline = parseInt(params.data.SALE_ONLINE);
              let returnOnline = parseInt(params.data.RETURN_ONLINE);

              return saleOnline + returnOnline;
            }
          },
        ]
      },
      { headerName: '오프라인', field: '', cellClass: 'cp center',
        children:[
          { headerName: '매출', field: 'SALE_OFFLINE', width: 200, cellClass: 'cp right f16 lh30', valueFormatter: this.agGridExComponent.currencyFormatter },
          { headerName: '취소/환불', field: 'RETURN_OFFLINE', width: 200, cellClass: 'cp right f16 lh30', valueFormatter: this.agGridExComponent.currencyFormatter },
          { headerName: '합계', field: 'TOTAL_OFFLINE', width: 200, cellClass: 'cp right f16 lh30',
            valueFormatter: this.agGridExComponent.currencyFormatter,
            valueGetter: function(params) {
              let saleOffline = parseInt(params.data.SALE_OFFLINE);
              let returnOffline = parseInt(params.data.RETURN_OFFLINE);

              return saleOffline + returnOffline;
            }
          },
        ]
      },
      { headerName: '순매출', field: '', width: 200, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter, children: [
        {headerName: '', field: 'REAL_TOTAL', width: 200, cellClass: 'cp right f16 lh30', valueFormatter: this.agGridExComponent.currencyFormatter}
      ]},
    ];

    this.defaultColDef = this.stockOrderComponent.defaultColDef
    this.rowSelection = this.stockOrderComponent.rowSelection
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate
    this.rowClassRules = {
      'status8': params => params.data.gbn_name == '합계'
      // 'status1': params => params.data.pay_date.indexOf('일') > -1
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.getStatisticDaySales();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onFraGridReady(params) {
    this.fraGridApi = params.api;
    this.fraGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 검색 초기화
  *******************************************************************************/
  searchInit() {
    this.search = {
      date: moment().format('YYYY-MM-DD')
    };

    this.getStatisticDaySales();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
  *******************************************************************************/
  // getToday( obj: NgbInputDatepicker ) {
  //   this.search.date = this.utilService.getDate( '' );
  //   obj.close();
  // }

  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate( value: any ) {
    this.search.date = this.utilService.getDateStr(value);
    this.getStatisticDaySales();
  }

  /*******************************************************************************
    설  명 : 일매출속보실적 가져오기
  *******************************************************************************/
  getStatisticDaySales() {
    this.statisticService.getStatisticDaySales({
      date : this.search.date
    }).then(response => {
      this.search_time = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
      if(response.ResultCode) {
        let total: any = {
          gbn: 9999,
          gbn_name: '합계',
          SALE_ONLINE: 0,
          SALE_OFFLINE: 0,
          SALE_TOTAL: 0,
          RETURN_ONLINE: 0,
          RETURN_OFFLINE: 0,
          RETURN_TOTAL: 0,
          REAL_TOTAL: 0
        };

        response.data.forEach(item => {
          total.SALE_ONLINE += parseInt(item.SALE_ONLINE);
          total.SALE_OFFLINE += parseInt(item.SALE_OFFLINE);
          total.SALE_TOTAL += parseInt(item.SALE_TOTAL);
          total.RETURN_ONLINE += parseInt(item.RETURN_ONLINE);
          total.RETURN_OFFLINE += parseInt(item.RETURN_OFFLINE);
          total.RETURN_TOTAL += parseInt(item.RETURN_TOTAL);
          total.REAL_TOTAL += parseInt(item.REAL_TOTAL);
        });

        response.data.push(total);

        this.dayList = response.data;
        this.fraDayList = response.fra_data.concat({
          gbn: 9999,
          gbn_name: '합계',
          SALE_ONLINE: response.fra_data.reduce((a,c) => a + parseInt(c.SALE_ONLINE), 0),
          SALE_OFFLINE: response.fra_data.reduce((a,c) => a + parseInt(c.SALE_OFFLINE), 0),
          SALE_TOTAL: response.fra_data.reduce((a,c) => a + parseInt(c.SALE_TOTAL), 0),
          RETURN_ONLINE: response.fra_data.reduce((a,c) => a + parseInt(c.RETURN_ONLINE), 0),
          RETURN_OFFLINE: response.fra_data.reduce((a,c) => a + parseInt(c.RETURN_OFFLINE), 0),
          RETURN_TOTAL: response.fra_data.reduce((a,c) => a + parseInt(c.RETURN_TOTAL), 0),
          REAL_TOTAL: response.fra_data.reduce((a,c) => a + parseInt(c.REAL_TOTAL), 0),
        });
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 일매출속보실적 상세리스트 페이지 이동
  *******************************************************************************/
  onCellClicked($event) {
    /*
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(
      ['/statistic/day/detail'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {date: this.search.date, type: $event.data.gbn},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    */
    window.location.href = '/statistic/day/detail?date=' + this.search.date + '&type=' + $event.data.gbn;
  }
}
