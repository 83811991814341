import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { OpenmarketService } from '@app/service/openmarket.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import * as $ from 'jquery';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-order-openmarket-delivery-upload',
  templateUrl: './order-openmarket-delivery-upload.component.html',
  styleUrls: ['./order-openmarket-delivery-upload.component.scss']
})
export class OrderOpenmarketDeliveryUploadComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public type: any;

  @Input() openmarketTypeList
  @Input() exportExcelComponent
  excelData = []
  extractData = []

  // public marketList: any = ['지마켓/옥션', '네이버', '스토어팜', '11번가', '쿠팡', '톡스토어'];
  public marketList = []
  public typeList: any = [];

  public baseURL = config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      type: ['0', [Validators.required] ],
      file: ['', []],
      files: [[], []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private utilService: UtilService,
    private restful: RestfulService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private openmarketService: OpenmarketService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.marketList = this.openmarketTypeList

    this.form.patchValue({
      type: this.type
    });

    const tmp = [];
    this.marketList.forEach((data, i) => {
      tmp.push({
        value: i,
        title: data
      });
    });

    this.typeList = tmp;

    // 업로드 허용 파일 설정(application/image/video/audio/pdf/compress/doc/xls/ppt)
    var filetype: string[] = ['doc'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        let files = this.form.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          url: this.baseURL + response.url,
          thumbnail_org: response.thumbnail_org,
        });

        this.form.patchValue( {files: files} );

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {

    this.exportExcelComponent.excelToJson(event).then(item => this.excelData = item)

    // this.uploader.clearQueue();

    // // 파일 없을 경우 return false;
    // if( event.target.files.length < 1 ) return false;

    // // 전체 허용 용량 검증

    // const files: File[] = event.target.files;
    // const filteredFiles: File[] = [];
    // for(const f of files) {
    //   filteredFiles.push(f);
    // }

    // const options = null;
    // const filters = null;

    // this.uploader.addToQueue(filteredFiles, options, filters);

    // // indicator 표시
    // this.restful.indicator.next(true);

    // this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 저장하기
  *******************************************************************************/
  async setOpenmarketConsignSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {

      if(this.excelData.length) {

        this.excelData.shift()
        let _index // 업로드하는 엑셀 파일에서 칼럼 인덱스
        let _field // 각 오픈마켓별 DB 테이블에서 칼럼 명칭
        let _sub_index
        let _sub_field
        switch(parseInt(this.form.value.type)) {
          case 0: // 지마켓/옥션
            _index = 2
            _field = 'order_serial'
            _sub_index = 3
            _sub_field = 'p_serial'
            break
          case 1: // 네이버
            _index = 2
            _field = 'd'
            _sub_index = 1
            _sub_field = 'c'
            break
          case 2: // 스토어팜
            _index = 1
            _field = 'b'
            _sub_index = 0
            _sub_field = 'a'
            break
          case 3: // 11번가
            _index = 3
            _field = 'c'
            _sub_index = 30
            _sub_field = 'd'
            break
          case 4: // 쿠팡
            _index = 2
            _field = 'c'
            _sub_index = 1
            _sub_field = 'b'
            break
          case 5: // 톡스토어
            // _index = 
            // _field = 'c'
            this.toastrService.error('지원하지 않습니다.')
            return
            break
        }

        // this.excelData.forEach(e => {
        //   this.getOpenmarketSearch(_field, String(e[_index]))
        // })
        for(let i = 0; i < this.excelData.length; i++) await this.getOpenmarketSearch(_field, String(this.excelData[i][_index]), _sub_field, String(this.excelData[i][_sub_index]))
        
        this.excelOpenmarketList(this.form.value.type, this.extractData)

      } else this.toastrService.warning('파일이 선택되지 않았거나 내용이 없는 파일입니다.')

      // this.openmarketService.setOpenmarketConsignSave(this.form).then( response => {
      //   if ( response.ResultCode ) {
      //     this.toastrService.success( response.ResultMessage, '배송목록 저장');

      //     this.activeModal.close(true);
      //   } else {
      //     this.toastrService.error( response.ResultMessage, '배송목록 저장');
      //   }
      // });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  async getOpenmarketSearch(searchGroup: string, searchText: string, sub_searchGroup = '', sub_searchText = '') {
    const _search = {
      pageNo: 1,
      pageRow: 9999999999,
      type: String(this.form.value.type),
      deliveryYN: '',
      searchGroup: searchGroup,
      searchText: searchText,
      sdate: '',
      edate: '',
      searchTerm: 'A',
      sub_searchGroup: sub_searchGroup,
      sub_searchText: sub_searchText,
    }

    await this.openmarketService.getOpenmarketList(_search).then( response => {
      if( response.ResultCode ) {
        // this.extractData.push( ...response.data )
        this.extractData.push( response.data.shift() )
      } else this.toastrService.error(response.ResultMessage)
    })
  }
  
  excelOpenmarketList(type, data) {
    const exceling = data => data.length ? 
    this.exportExcelComponent.exportAsExcelFileWithWidth(data, `${this.marketList[type]} 배송목록`, '발송처리', [], {title: type == 3 ? '11번가' : '', space: false}) : 
    this.toastrService.warning('출력할 데이터가 없습니다.')
    const cooking = data => {
      switch( type ) {
        case '0':   // 지마켓/옥션
          exceling( 
            data.filter(e => e?.consign_num).map(e => ({
              '계정': e.id.replace('지마켓','').replace('옥션','').replace('(','').replace(')',''),
              '주문번호': e.order_serial,
              '택배사': e.consign_co,
              '운송장/등기번호': e.consign_num
            })) 
          )
          break
        case '1':   // 네이버
          exceling( 
            data.filter(e => e?.consign_num).map(e => ({
              '상품주문번호': e.p_serial,
              '배송방법': e.k,
              '택배사': e.consign_co,
              '송장번호': e.consign_num
            })) 
          )
          break
        case '2':   // 스토어팜
          exceling( 
            data.filter(e => e?.consign_num).map(e => ({
              '상품주문번호': e.order_serial,
              '배송방법': e.d,
              '택배사': e.consign_co,
              '송장번호': e.consign_num
            })) 
          )
          break
        case '3':   // 11번가
          exceling( 
            data.filter(e => e?.consign_num).map(e => ({
              '번호': e.a,
              '주문일시': e.aw,
              '결제완료일시': e.e,
              '주문번호': e.c,
              '주문상태': e.b,
              '배송번호': e.f,
              '택배사코드': ( e.consign_co.replace(/\s/g,'') == 'CJ대한통운' ) ? '00034' : ( e.consign_co.replace(/\s/g,'') == '로젠택배' ? '00002' : e.consign_co.replace(/\s/g,'') ),
              '송장/등기번호': e.consign_num.replace(/-/g,''), // e.p,
              '배송방법': '01', // e.n,
              '상품번호': e.am,
              '상품명': e.g,
              '옵션/추가구성': e.h,
              '판매자상품코드': e.an,
              '판매단가': e.ao,
              '옵션가': e.ap,
              '수량': e.k,
              '주문금액': e.l,
              '배송비결제방식': e.x,
              '배송비': e.y,
              '도서산간배송비결제방식': e.x,
              '도서산간배송비': e.z,
              '구매자': e.aj,
              '구매자ID': e.ak,
              '수취인': e.m,
              '전화번호': e.ad,
              '핸드폰': e.ac,
              '우편번호': e.ae,
              '배송지주소': e.af,
              '배송시요구사항': e.ag,
              '판매방식': e.al,
              '주문상세번호': e.d,
              '전세계배송': '',
              '바코드': e.i,
              '바코드 출력여부': '',
              '전세계배송 수취인': '',
              '전세계배송 연락처1': '',
              '전세계배송 연락처2': '',
              '전세계배송 Zip Code': '',
              '전세계배송 Address': ''
            })) 
          )
          break
        case '4':   // 쿠팡
          exceling( 
            data.filter(e => e?.consign_num).map(e => ({
              '번호': e.a,
              '묶음배송번호': e.b,
              '주문번호': e.c,
              '택배사': e.consign_co,
              '운송장번호': e.consign_num, // e.e,
              '분리배송 Y/N': e.f,
              '분리배송 출고예정일': e.g,
              '주문시 출고예정일': e.h,
              '출고일(발송일)': e.i,
              '주문일': e.j,
              '등록상품명': e.k,
              '등록옵션명': e.l,
              '노출상품명(옵션명)': e.m,
              '노출상품ID': e.n,
              '옵션ID': e.o,
              '최초등록옵션명': e.p,
              '업체상품코드': e.q,
              '바코드': e.r,
              '결제액': e.s,
              '배송비구분': e.t,
              '배송비': e.u,
              '도서산간 추가배송비': e.v,
              '구매수(수량)': e.w,
              '옵션판매가(판매단가)': e.x,
              '구매자': e.y,
              '구매자전화번호': e.z,
              '수취인이름': e.aa,
              '수취인전화번호': e.ab,
              '우편번호': e.ac,
              '수취인 주소': e.ad,
              '배송메세지': e.ae,
              '상품별 추가메시지': e.af,
              '주문자 추가메시지': e.ag,
              '배송완료일': e.ah,
              '구매확정일자': e.ai,
              '개인통관번호(PCCC)': e.aj,
              '통관용구매자전화번호': e.ak,
              '기타': e.al,
              '결제위치': e.am
            })) 
          )
          break
        case '5':   // 톡스토어
          exceling( 
            data.filter(e => e?.consign_num).map(e => ({
              '주문번호': e.c,
              '배송방법': e.h,
              '택배사코드': e.consign_co,
              '송장번호': e.consign_num, // e.j,
              '수령인명': e.k
            })) 
          )
          break
      }
    }
    cooking(data)
  }

}
