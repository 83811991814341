<section id="category">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="20" minSize="20" maxSize="40">
      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">카테고리</h4>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <app-tree-view [useSort]='false' [useAddNode]='true' [nodeData]='nodeData'
          (onSelected)='onSelectNode($event)'
          (onAddNode)='onAddNode($event)'
          (onDropNode)='onDropNode($event)'></app-tree-view>
      </div>
    </as-split-area>

    <as-split-area size="80" minSize="60" maxSize="80">
      <ng-container *ngIf="categoryForm.controls.category_code.value == ''">
        <div class="message-box">
          카테고리를 선택하거나 카테고리 추가 메뉴를 선택하세요.
        </div>
      </ng-container>

      <ng-container *ngIf="categoryForm.controls.category_code.value != ''">
        <div *ngIf="categoryForm.controls.seq.value == ''" class="category-add-message">
          신규 카테고리 추가
        </div>

        <div *ngIf="categoryForm.controls.seq.value != ''" class="category-edit-message">
          카테고리 수정 - 쇼핑몰 전체 카테고리가 수정되므로 신중하게 수정하시기 바랍니다.
        </div>

        <form [formGroup]="categoryForm" (ngSubmit)="submit()">
          <div class="">
            <div class="fl w50p">
              <h4 class="fb mt5 mb6">카테고리 정보 - 선택 카테고리명</h4>
            </div>
            <div class="fr w50p right">
              <button *ngIf="checkDeleteCategory()" type="button" class="btn btn-danger btn-small ml5" (click)="deleteCategory()">삭제</button>
              <button type="submit" class="btn btn-primary btn-small ml5">저장하기</button>
            </div>
            <div class="cb"></div>
          </div>

          <div style="height:calc(100% - 35px)">
            <div class="">
              <table class="table table-bordered table-small">
              <colgroup>
                <col style="width:20%" />
                <col style="width:80%" />
              </colgroup>

              <tbody>
              <tr>
                <th scope="row" class="active"><label for="category_code">카테고리 코드</label></th>
                <td>
                  <inputEx type="text" name="category_code" readonly="true" [formGroup]="categoryForm" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="category_name">카테고리 명</label></th>
                <td>
                  <inputEx type="text" name="category_name" [formGroup]="categoryForm" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="memo">설명</label></th>
                <td>
                  <inputEx type="text" name="memo" [formGroup]="categoryForm" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="mall_display">쇼핑몰 표시여부</label></th>
                <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:categoryForm.controls.mall_display.value=='1'}" (click)="categoryForm.patchValue({mall_display:'1'})">사용</button>
                    <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:categoryForm.controls.mall_display.value=='0'}" (click)="categoryForm.patchValue({mall_display:'0'})">사용안함</button>
                  </div>
                  <inputEx type="hidden" name="mall_display" [formGroup]="categoryForm" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="category_type">카테고리종류</label></th>
                <td>
                  <div class="btn-group">
                    <button
                      type="button"
                      *ngFor="let item of typeList"
                      class="btn btn-default btn-small f12"
                      [ngClass]="{active:categoryForm.controls.category_type.value==item.common_code}"
                      (click)="categoryForm.patchValue({category_type:item.common_code})">{{item.common_name}}
                    </button>
                  </div>
                  <inputEx type="hidden" name="category_type" [formGroup]="categoryForm" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="use_yn">사용여부</label></th>
                <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:categoryForm.controls.use_yn.value==true}" (click)="categoryForm.patchValue({use_yn:true})">사용</button>
                    <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:categoryForm.controls.use_yn.value==false}" (click)="categoryForm.patchValue({use_yn:false})">사용안함</button>
                  </div>
                  <inputEx type="hidden" name="use_yn" [formGroup]="categoryForm" [formErrors]="formErrors"></inputEx>
                </td>
              </tr>
              <tr>
                <th scope="row" class="active"><label for="file">이미지</label></th>
                <td>
                  <inputEx type="file" name="file" [formGroup]="categoryForm" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
                  <div class="progress mt5 mb5">
                    <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
                  </div>
                  <div class="mt5">
                  ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 50MB 제한, 가로*세로 : 1000*1000픽셀 (필히 등록)
                  </div>
                  <div *ngIf="categoryForm.controls.files.value.length > 0 || categoryForm.controls.upload.value.length > 0" class="file-div">
                    <ul class="file-ul">
                      <li *ngFor="let file of categoryForm.controls.upload.value; let i = index" class="fl">
                        <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                          <div *ngIf="checkImage(getExt(file.origin_filename))" class="upload-image" (click)="insertUploadImage(file)">
                            <img [src]="file.url" [alt]="file.origin_filename" />
                          </div>
                          <div *ngIf="!checkImage(getExt(file.origin_filename))" class="upload-image1">{{getExt(file.origin_filename)}}</div>
                          <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin_filename}}">{{file.origin_filename}}</a></div>
                          <div class="upload-size">
                            ({{getComma(file.filesize)}} KB)
                            <a class="cp ml5" (click)="setDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                          </div>
                        </div>
                      </li>
                      <li *ngFor="let file of categoryForm.controls.files.value; let i = index" class="fl">
                        <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
                          <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="insertFilesImage(file)">
                            <img [src]="file.url" [alt]="file.origin" />
                          </div>
                          <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                          <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                          <div class="upload-size">
                            ({{getComma(file.size)}} KB)
                            <a class="cp ml5" (click)="deleteFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                          </div>
                        </div>
                      </li>
                      <li class="cb"></li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row"><label for="caution">제품소개</label></th>
                <td colspan="2">
                  <inputEx type="summernote" name="caution" [formGroup]="categoryForm" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
                </td>
              </tr>
              </tbody>
            </table>

            </div>
            <div class="cb"></div>
          </div>
        </form>
      </ng-container>
    </as-split-area>
  </as-split>
</section>
