<div class="modal-header">
    <h4 class="modal-title fb mb0">영업상담내역 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <form [formGroup]="form">
      <table class="table table-bordered table-small1 mb0">
      <caption class="none">회원정보</caption>
      <colgroup>
        <col style="width:20%;" />
        <col style="width:80%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="hp">영업상담내용</label></th>
        <td>
            <inputEx type="textarea" name="consult_memo" [formGroup]="form" [formErrors]="formErrors" rows="10"></inputEx>
        </td>
      </tr>
      </tbody>
      </table>
    </form>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button *ngIf="seq != '0'" type="button" class="btn btn-danger btn-small" (click)="delMemberShopConsultation()">영업상담 삭제</button>
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="submit()">영업상담 저장</button>
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
  </div>
