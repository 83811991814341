<section class="modal-header">
  <h4 class="modal-title fb mb0">담당자 추가</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</section>

<section class="modal-body">
  <div class="groupTree">
    <app-tree-view
      [useSort]='false'
      [useAddNode]='false' 
      [nodeData]='nodeData'
      (onSelected)='onSelectNode($event)'
      >
    </app-tree-view>
  </div>

  <div class="selectedemployee">

    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="employeeList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
      >
    </ag-grid-angular>
  </div>
</section>

<section class="modal-footer right">
  <button type="button" class="btn btn-primary btn-small" (click)="setMessageCharge()">저장하기</button>
  <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
</section>
