import { Component } from '@angular/core';
import { DomSanitizer } from  '@angular/platform-browser';

import { config } from '@app/service/config.service';

@Component({
  selector: 'app-ag-grid-image',
  templateUrl: './ag-grid-image.component.html',
  styleUrls: ['./ag-grid-image.component.scss']
})
export class AgGridImageComponent {

  public src: any;
  public imageCheck: boolean = false;
  public tooltipCheck: boolean = false;
  public rowIndex: any;

  constructor(
    public sanitizer: DomSanitizer,
  ) {

  }

  agInit(params: any): void {
    let url: any;

    this.rowIndex = params.rowIndex;
    this.tooltipCheck = ( typeof params.isTooltip !== 'undefined' ) ? params.isTooltip : false;

    if( typeof params.value != 'undefined' && params.value != null && params.value.length > 0 ) {
      url = ( params.value.indexOf('http') > -1 ) ? params.value : config.baseUrl + params.value;
      this.imageCheck = true;
    } else {
      url = '';
    }

    this.src = this.sanitizer.bypassSecurityTrustUrl( url );
  }

}
