import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';

import { ApprovalDocumentService } from '@app/service/approval-documents.service';
import { EmployeeService } from '@app/service/employee.service';
import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { RestfulService } from '@app/service/restful.service';
import * as $ from 'jquery';
import * as moment from 'moment';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-document-receive-add',
  templateUrl: './document-receive-add.component.html',
  styleUrls: ['./document-receive-add.component.scss']
})
export class DocumentReceiveAddComponent implements OnInit {

  public form: FormGroup;
  public formErrors = {};

  public selectedItem: any = {
    seq: "",
  };

  public upLoadData: any = [];

  public receiving_date: any;

  public uploader: FileUploader;

  public baseURL = config.baseUrl;

  public title: string = '추가';

  public employeeList: any = [];


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      uid: ['', [] ],
      receiving_no: ['', [] ],
      receiving_date: ['', [] ],
      outgoing_company: ['', [Validators.required] ],
      document_no: ['', [Validators.required] ],
      title: ['', [Validators.required] ],
      mem_no: ['', [Validators.required] ],
      file: ['', [] ],
      upload: [[], [] ],
      files: [[], [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private approvalDocService: ApprovalDocumentService,
    private employeeService: EmployeeService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private restful: RestfulService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.getEmployee();

    var filetype: string[] = ['image', 'doc', 'pdf', 'xls', 'ppt'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김d
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };


    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        let files = this.form.controls.files.value;

        files.push({
          path: this.baseURL + response.url,
          upload_file: response.origin_filename,
          file_size: response.size,
          org_upload_file: response.filename
        });

        this.form.patchValue( {files: files});

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    }

  }

    /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    handleUploadFileChanged(event) {
      this.uploader.clearQueue();
  
      // 파일 없을 경우 return false;
      if( event.target.files.length < 1 ) return false;
  
  
      // 전체 허용 용량 검증
      const files:File[] = event.target.files;
      const filteredFiles:File[] = [];
      for(const f of files) {
        filteredFiles.push(f);
      }
  
      const options = null;
      const filters = null;
  
      this.uploader.addToQueue(filteredFiles, options, filters);
  
      // indicator 표시
      this.restful.indicator.next(true);
  
      this.uploader.uploadAll();
    }
  
  
    /*******************************************************************************
      설  명 : 확장명 가져오기
      입력값 : filename
      리턴값 : ext
    *******************************************************************************/
    getExt( filename ) {
      if( typeof filename == 'undefined' ) return '';
      else return filename.substr(filename.lastIndexOf('.') + 1);
    }
  
  
    /*******************************************************************************
      설  명 : 파일 삭제
      입력값 : 파일
      리턴값 : 없음
    *******************************************************************************/
    deleteFile( file: any, index ) {
      if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
        let tmp = this.form.controls.files.value;
  
        tmp.splice( index, 1 );
  
        this.form.patchValue({files: tmp});
      }
    }

    /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.approvalDocService.setDeleteUploadFile( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.form.controls.upload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.toastrService.success( '첨부파일을 성공적으로 삭제하였습니다.', '');
        } else {
          this.toastrService.error( '첨부파일을 삭제하는데 실패하였습니다.', '');
        }
      });
    }
  }
    
  

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterContentInit() {
    if( this.selectedItem == 0 ) {
      this.title = '추가';
      this.form.controls.receiving_date.setValue(moment().format('YYYY-MM-DD'));
    } else {
      this.title = '수정';
      this.form.controls.receiving_date.setValue(moment().format('YYYY-MM-DD'));
      // 데이터 로딩 처리
      this.form.patchValue( this.selectedItem );
    }
  }
  
  /*******************************************************************************
    설  명 : 공문서접수 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  editClick() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.approvalDocService.setEditDocumentReceive(this.form).then( response => {

        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 담당자 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployee() {
    this.employeeService.getGroupEmployeeListForSelect().then( response => {
      if( response.ResultCode ) {
        // this.employeeList = response.data;

        this.employeeList.push({
          title: '담당자',
          value: ''
        });

        response.data.forEach( row => {
          this.employeeList.push({
            title: row.name,
            value: row.mem_no,
          });
        });

      } else {
        this.employeeList = [];
      }
    });
  }

   /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 공문서접수 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.approvalDocService.setAddDocumentReceive(this.form).then( response => {

        if( response.ResultCode ) {
          this.toastrService.success( "공문서접수대장을 성공적으로 저장하였습니다.", '');
          this.activeModal.close(true);

        } else {
          this.toastrService.error("공문서접수대장을 저장하는데 실패하였습니다.", '');
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
  

  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }


}
