import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { AgGridImageComponent } from '@app/components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@app/components/ag-grid-excomponent/ag-grid-excomponent';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { CommonService } from '@app/service/common.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { StockOut } from '@app/service/stockOut.service';
import { StockOrder } from '@app/service/stockOrder.service';
import { StockOutTodayComponent } from '@page/stock/stock-out-management/stock-out-today/stock-out-today.component';
import { StockOutDeliveryOrderComponent } from '@page/stock/stock-out-management/stock-out-delivery-order/stock-out-delivery-order.component';
import { AccountService } from '@app/service/account.service';

@Component({
  selector: 'app-account-enter',
  templateUrl: './account-enter.component.html',
  styleUrls: ['./account-enter.component.scss']
})
export class AccountEnterComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup
  public formErrors = {}

  gridApi_A: any
  gridColumnApi_A: any
  columnDefs_A: any
  gridApi_B: any
  gridColumnApi_B: any
  columnDefs_B: any

  defaultColDef: any
  domLayout: any
  rowSelection: any
  noRowsTemplate: string

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  }
  components: any

  enter = {
    seq : '',
    coustomer_id : '',
    comname : ''
  }
  statistic = {
    cnt_order : 0,
    cnt_product : 0,
    sum_price : 0,
    sum_delivery : 0
  }

  rowHeight = 100

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private stockOrderComponent: StockOrderComponent,
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
      this.columnDefs_A = [
        { headerName: '업체명', field: 'comname', width: 200, cellClass: 'cp left', cellRenderer: 'agGridHtmlComponent',
          valueGetter: (params: any) => `${params.data.comname}(${params.data.customer_id})`
        },
        { headerName: '건수', field: 'total', width: 70, cellClass: 'cp right',
          valueGetter : (params: any) => this.stockOrderComponent.valueGetter_num(params)
        },
        // { headerName: '아이디', field: 'customer_id', width: 100, flex: 0, cellClass: 'cp' },
        // {headerName: '주문', field: '', cellClass: 'cp right',
        //   valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        // {headerName: '품목', field: '', cellClass: 'cp right',
        //   valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        // {headerName: '금액합계', field: 'amt', cellClass: 'cp right',
        //   valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        // {headerName: '배송비', field: '', cellClass: 'cp right',
        //   valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) }
      ]

      this.columnDefs_B = [
        {headerName: '정산구분', field: 'gbn_name', cellClass: 'cp center ag-cell60h', width: 80, flex: 0 },
        {headerName: '주문번호', field: 'master_seq', cellClass: 'cp center ag-cell60h', width: 80, flex: 0 },
        // {headerName: '정산키', field: '', cellClass: 'cp center' },
        // {headerName: '주문일', field: 'order_date', cellClass: 'cp center ag-cell60h', width: 100 },
        // {headerName: '입금일', field: 'pay_date', cellClass: 'cp center ag-cell60h', width: 100 },
        // {headerName: '배송일', field: 'consign_date', cellClass: 'cp center ag-cell60h', width: 100 },
        {headerName: '주문/입금/배송일', field: '', cellClass: 'cp center ag-cell60h-br', width: 120, sortable: false,
          cellRenderer: 'agGridHtmlComponent',
          valueGetter(params: any) {
            let orderDate: any = params.data.order_date || '-';
            let payDate: any = params.data.pay_date || '-';
            let consignDate: any = params.data.consign_date || '-';

            return `${orderDate}<br/>${payDate}<br/>${consignDate}`;
          }
        },
        {headerName: '반품일', field: 'return_date', cellClass: 'cp center ag-cell60h', width: 100 },
        {headerName: '수신자', field: 'r_name', cellClass: 'cp cell-wrap-text', width: 120 },
        {headerName: '상품명', field: 'product_name', cellClass: 'cp cell-wrap-text', width: 220 },
        {headerName: '수량', field: 'qty', cellClass: 'cp right ag-cell60h', width: 50, flex: 0, 
          valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        {headerName: '상태', field: 'order_status_name', cellClass: 'cp center ag-cell60h', width: 90, cellRenderer: 'agGridHtmlComponent',
          valueGetter: (params) => {
            let _S
            switch(params.data.order_status_name) {
              case '발송완료': _S = 'primary'; break
              case '전체반품': _S = 'danger'; break
              case '일부반품': _S = 'danger'; break
              case '전체교환': _S = 'warning'; break
              case '일부교환': _S = 'warning'; break
              case '구매확정': _S = 'info'; break
              case '결제확인': _S = 'dark'; break
              default: _S = 'secondary'
            }
            return `<span class="badge badge-${_S} f11">${params.data.order_status_name}</span>`
          }
        },
        {headerName: '판매가격', field: 'total_amt', cellClass: 'cp right ag-cell60h', width: 80,
          valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        {headerName: '공급가격', field: 'buy_price', cellClass: 'cp right ag-cell60h', width: 80,
          valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        // {headerName: '이벤트가', field: '', cellClass: 'cp right',
        //   valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        {headerName: '합계금액', field: 'buy_amt', cellClass: 'cp right ag-cell60h', width: 80,
          valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        // {headerName: '이벤트합계', field: '', cellClass: 'cp right',
        //   valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        {headerName: '배송비', field: 'consign_fee', cellClass: 'cp right ag-cell60h', width: 70, flex: 0,
          valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
        {headerName: '메모', field: 'memo', 
          cellClass: (params: any) => {
            if( params.data.order_detail_seq != "" ) return 'cp ag-cell-edit cell-wrap-text';
            else return '';
          },
          width: 165, 
          cellEditor: 'agLargeTextCellEditor',
          editable: (params: any) => {
            if( params.data.order_detail_seq != "" ) return true;
            else return false;
          }, singleClickEdit: true, cellEditorPopup: true,
        }
      ]

      this.defaultColDef = this.stockOrderComponent.defaultColDef
      this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate
      this.rowSelection = this.stockOrderComponent.rowSelection
      this.components = this.stockOrderComponent.components
    }

  ngOnInit(): void {
    this.buildForm()
    this.getEnterList()

    this.toastrService.info('주문번호를 클릭하면 주문상세로 이동합니다.')
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      date : [moment().format('YYYY-MM-DD'), []],

      rowData_enter : [[]],
      rowData_order : [[]]
    })

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors )
    });
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 60;
  };
  
  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReady_A(params) {
    this.gridApi_A = params.api
    this.gridColumnApi_A = params.columnApi
  }
  onGridReady_B(params) {
    this.gridApi_B = params.api
    this.gridColumnApi_B = params.columnApi
  }

  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(which, value) {
    this.stockOrderComponent.selectDate(which, value, this)
  }
  /*******************************************************************************
    설  명 : 검색조건 버튼 클릭
  *******************************************************************************/
  btnClick(which, value) {
    this.form.patchValue({[which] : value})
    this.getEnterAccountList()
  }
  /*******************************************************************************
    설  명 : 날짜선택 바로가기 버튼 클릭
  *******************************************************************************/
  quickDate(quickTime) {
    this.stockOrderComponent.quickDate(quickTime, this)
  }

  /*******************************************************************************
    설  명 : row click
  *******************************************************************************/
  onRowClicked_A($event) {
    this.enter = $event.data
    this.getEnterAccountList()
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApi_A.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : cell click
  *******************************************************************************/
  cellClick_B($event) {
    if( $event.colDef.field === 'master_seq' ) {
      let seq = $event.data.master_seq;
      const url = '/order/detail?seq=' + seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 업체목록 가져오기
  *******************************************************************************/
  getEnterList() {
    this.accountService.getEnterList({
      customer_seq : this.enter['seq'],
      date : this.form.value['date'].slice(0,7)
    }).then(response => {
      if(response.ResultCode) this.form.patchValue({rowData_enter : response.data })
      else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 정산내역 가져오기
  *******************************************************************************/
  getEnterAccountList() {
    if(!this.form.value['date']) {
      this.toastrService.warning('날짜를 선택하세요.')
      return
    } else if(!this.enter['seq']) {
      this.toastrService.warning('업체를 선택하세요.')
      return
    }

    this.accountService.getEnterAccountList({
      customer_seq : this.enter['seq'],
      date : this.form.value['date'].slice(0,7)
    }).then(response => {
      if(response.ResultCode) {
        if(!response.data.length) this.toastrService.warning('데이터가 없습니다.')

        const _data = response.data //.filter(e => e['order_status_name'] == '발송완료')
        const _set = new Set()
        _data.forEach((e,i,a) => {
          if(e['master_seq']) {
            _set.add(e['master_seq'])
            // 동일 주문번호는 배송비를 하나로 처리한다.
            i == a.findIndex(_e => _e['master_seq'] == e['master_seq']) ? '' : e.consign_fee = 0
          }
          // 에러 처리
          for(const key in e) e[key] = String(e[key] || '')
        })
        this.form.patchValue({rowData_order : _data })
        this.statistic = {
          cnt_order : _set.size,
          cnt_product : _data.length,
          sum_price : _data.reduce((a,c) => a + parseInt(c['buy_amt'] || 0), 0),
          sum_delivery : _data.reduce((a,c) => a + parseInt(c['consign_fee'] || 0), 0)
        }
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  /*******************************************************************************
    설  명 : 셀 내용 변경 시
  *******************************************************************************/
  onCellValueChanged(params: any) {
    const colId = params.column.getId();
    if (colId === 'memo') {
      if( !params.data.memo || params.data.memo == '' ) return;

      let data: any = {
        seq: params.data.order_detail_seq,
        memo: params.data.memo
      };

      this.accountService.setEnterAccountMemo(data).then( response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage);
        } else {
          this.toastrService.error(response.ResultMessage);
        };
      });
    }
  }

  /*******************************************************************************
    설  명 : 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelOrder() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>${this.enter.comname} 입점업체 정산내역</strong></caption>
        <colgroup>
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:300px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
          <col style="width:70px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>정산구분</td>
          <td>주문번호</td>
          <td>주문일</td>
          <td>입금일</td>
          <td>배송일</td>
          <td>반품일</td>
          <td>수신자</td>
          <td>상품명</td>
          <td>수량</td>
          <td>상태</td>
          <td>판매가격</td>
          <td>공급가격</td>
          <td>합계금액</td>
          <td>배송비</td>
          <td>메모</td>
        </tr>
    `];

    let data = this.form.value.rowData_order;

    if( data.length < 1 ) {
      this.toastrService.error('엑셀로 다운 받을 데이터가 없습니다.');
      return false;
    }
    

    data.forEach(element => {

      let productName = element.product_name;
      productName = productName.replace(/<br>/g,'');
      productName = productName.replace(/<br\/>/g,'');
      productName = productName.replace(/<BR>/g,'');
      productName = productName.replace(/<BR\/>/g,'');

      contents.push(`
        <tr style="height:20px;">
          <td style="text-align:center;">${element.gbn_name}</td>
          <td style="text-align:center;">${element.master_seq}</td>
          <td style="text-align:center;">${element.order_date}</td>
          <td style="text-align:center;">${element.pay_date}</td>
          <td style="text-align:center;">${element.consign_date}</td>
          <td style="text-align:center;">${element.return_date}</td>
          <td style="text-align:center;">${element.r_name}</td>
          <td style="text-align:left;">${productName}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.qty}</td>
          <td style="text-align:center;">${element.order_status_name}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.total_amt}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.buy_price}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.buy_amt}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.consign_fee}</td>
          <td style="text-align:center;">${element.memo}</td>
        </tr>
        </tbody>
      `)
    })
    contents.push(`</table>`);

    let today = new Date();

    const fileName = `bikemart_accountEnterList_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`

    this.printExcel(fileName, contents.join(''))
  }
  
  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
  
}
