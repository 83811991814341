/*******************************************************************************
  설  명 : 게시판설정
  작성일 : 2019-09-30
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { BoardService } from '@app/service/board.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { BoardInfoAddComponent } from './add/add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-amanagement',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardList = [];

  // 그리드 관련 선언
  selectedRows: number = 0;
  customerList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private boardService: BoardService,
    public authService: AuthService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '게시판코드', field: 'seq', cellClass: 'cp center', width: 80},
      {headerName: '게시판아이디', field: 'board_id', cellClass: 'cp left', width: 120 },
      {headerName: '게시판명', field: 'board_name', cellClass: 'cp left', width: 150 },
      {headerName: '그룹', field: 'group', cellClass: 'cp left', width: 150 },
      {headerName: '테이블명', field: 'tablename', cellClass: 'cp', width: 150 },
      {headerName: '의견테이블명', field: 'comment_table', cellClass: 'cp', width: 150 },
      {headerName: '게시판타입', field: 'board_type_name', cellClass: 'cp', width: 100 },
      {headerName: '기본표시', field: 'lvt_default', cellClass: 'cp', width: 80,
        valueGetter: function(params) {
          if( params.data.lvt_default == 'L' ) return '리스트';
          else if( params.data.lvt_default == 'A' ) return '앨범';
          else if( params.data.lvt_default == 'G' ) return '갤러리';
          else return '달력';
        }
      },
      {headerName: '리스트', field: 'lvt_list', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.lvt_list ) return '<span class="badge badge-success f11">사용</span>';
          else return '<span class="badge badge-secondary f11">사용안함</span>';
        }
      },
      {headerName: '앨범', field: 'lvt_album', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.lvt_album ) return '<span class="badge badge-success f11">사용</span>';
          else return '<span class="badge badge-secondar f11">사용안함</span>';
        }
      },
      {headerName: '갤러리', field: 'lvt_gallery', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.lvt_gallery ) return '<span class="badge badge-success f11">사용</span>';
          else return '<span class="badge badge-secondary f11">사용안함</span>';
        }
      },
      {headerName: '캘린더', field: 'lvt_calendar', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.lvt_calendar ) return '<span class="badge badge-success f11">사용</span>';
          else return '<span class="badge badge-secondary f11">사용안함</span>';
        }
      },
      {headerName: '그룹사용', field: 'lvt_group', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.lvt_group ) return '<span class="badge badge-success f11">사용</span>';
          else return '<span class="badge badge-secondary f11">사용안함</span>';
        }
      },
      {headerName: '리스트줄수', field: 'linecnt_list', cellClass: 'cp center', width: 80 },
      {headerName: '앨범줄수', field: 'linecnt_album', cellClass: 'cp center', width: 80 },
      {headerName: '갤러리줄수', field: 'linecnt_gallery', cellClass: 'cp center', width: 80 },
      {headerName: '글쓰기', field: 'auth_write_name', cellClass: 'cp', width: 80 },
      {headerName: '수정', field: 'auth_modify_name', cellClass: 'cp', width: 80 },
      {headerName: '삭제', field: 'auth_del_name', cellClass: 'cp', width: 80 },
      {headerName: '보기', field: 'auth_view_name', cellClass: 'cp', width: 80 },
      {headerName: '답변', field: 'auth_reply_name', cellClass: 'cp', width: 80 },
      {headerName: '의견', field: 'auth_comment_name', cellClass: 'cp', width: 80 },
      {headerName: '추천', field: 'auth_recommend_name', cellClass: 'cp', width: 80 },
      {headerName: '반대', field: 'auth_opposite_name', cellClass: 'cp', width: 80 },
      {headerName: '공지', field: 'notice_rows', cellClass: 'cp', width: 80 },
      {headerName: '신규', field: 'new_date', cellClass: 'cp', width: 80 },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 게시판 정보 가져오기
    this.getBoardInfoList();
  }

  /*******************************************************************************
    설  명 : 게시판 정보 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfoList() {
    this.boardService.getBoardInfoList().then( response => {
      if (response.ResultCode) {
        this.boardList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.addBoardInfo( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 게시판 정보 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addBoardInfo( seq ) {
    const modalRef = this.modalService.open(BoardInfoAddComponent, options);

    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      this.getBoardInfoList();
    }, (reason) => {
      console.error( reason );
    });
  }
}
