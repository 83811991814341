import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private restful: RestfulService,
  ) {
  }
  // 주문 리스트 가져오기
  getOrderList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 상세정보 가져오기
  getOrderDetail( orderSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderDetail'
      }, {
        seq: orderSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 외상거래 마스터 리스트 가져오기
  getCreditMasterList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getCreditMasterList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 외상거래 리스트 가져오기
  getCreditList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getCreditList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 매장판매 주문 리스트 가져오기
  getOrderStoreSalesList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderStoreSalesList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 메모 리스트 가져오기
  getOrderMemoList( orderSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderMemoList'
      }, {
        seq: orderSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 메모 리스트 가져오기
  getOrderMemoCustomerList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderMemoCustomerList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 메모 저장
  setOrderMemoSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderMemoSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 메모 삭제
  setOrderMemoDelete( seq: number ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderMemoDelete'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문관리 상세 매입/입점 변경
  setOrderProductProvider( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderProductProvider'
      }, {
        params: data
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 초도 주문 리스트 가져오기
  getOrderInitialList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderInitialList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 초도 주문 저장
  setInitialOrderNameSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setInitialOrderNameSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // PG결제 신용카드 및 은행코드 가져오기
  getPaymentGatewayCodeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getPaymentGatewayCodeList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문정보 저장
  setOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품추가 저장(결제이후)
  setOrderProductAdd( seq: any, array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderProductAdd'
      }, {
        order_seq: seq,
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 출고지시 주문 리스트 가져오기
  getOrderDeliveryList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderDeliveryList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 출고지시 주문 상세 리스트 가져오기
  getOrderDeliveryDetailList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderDeliveryDetailList'
      }, {
        order_seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  setInitialOrderNameEdit( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setInitialOrderNameEdit'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  delOrderInitialList( array: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'delOrderInitialList'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.extractData);
  }

  setInitialOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setInitialOrderSave'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  getOrderDetailInitialList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderDetailInitialList'
      }, {
        iniOrderSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 배송전 취소
  setOrderDetailCancel( seq: number, array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderDetailCancel'
      }, {
        orderSeq: seq,
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.extractData);
  }

  // 환불/취소 저장
  setOrderDetailRefund( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderDetailRefund'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 반품/교환 저장
  setOrderDetailReturn( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderDetailReturn'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 환불 데이터 생성
  setCreateOrderRefund( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setCreateOrderRefund'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  getChangeRefundList( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getChangeRefundList'
      }, {seq: seq}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 결제내역 리스트 가져오기
  getOrderPayList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderPayList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 마일리지내역 리스트 가져오기
  getOrderMileageList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderMileageList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 결제내역 저장
  setOrderPaySave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderPaySave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문금액정보 저장
  setOrderAmtSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderAmtSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 마일리지 결제내역 취소 처리
  setMileagePayCancel( orderSeq: any, seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setMileagePayCancel'
      }, {
        orderSeq: orderSeq,
        paySeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 결제 확인 처리
  setOrderPayComplete( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderPayComplete'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문취소일 때 환불데이터 생성
  setCreateRefund( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setCreateRefund'
      }, {
        orderSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 여신 대체 처리
  setOrderPayCreditReplace( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderPayCreditReplace'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 출고지시
  getOrderStock(seq): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'order',
      version: '1.0',
      action: 'getOrderStock'
    }, {seq: seq}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 주문 리스트 가져오기
  getCancelOrder( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getCancelOrder'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 반품/교환신청관리 승인/미승인
  setApprovalSave( seq: any, num: any, form: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setApprovalSave'
      }, {
        seq: seq,
        num: num,
        message: form.value
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 반품/교환신청관리 교환/반품 관리자 변경
  setReqChange( req_seq:any, req_gbn: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setReqChange'
      }, {
        req_seq: req_seq,
        req_gbn: req_gbn
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 주문취소 취소
  setCancelCancel( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setCancelCancel'
      }, {
        seq:seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 주문취소 상세가져오기
  getCancelDetailOrder( req_seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getCancelDetailOrder'
      }, {
        req_seq: req_seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  setChangeMember( mem_no: any, order_seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setChangeMember'
      }, {
        mem_no: mem_no,
        order_seq: order_seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 도선료 리스트 가져오기
  getShipDeliveryList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getShipDeliveryList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  
  // 도선료 리스트 추가/수정
  setShipDeliverySave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setShipDeliverySave'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  
  // 도선료 리스트 삭제
  setShipDeliveryDelete( form: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setShipDeliveryDelete'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 주문취소
  setOrderCancel( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderCancel'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 주문통합
  setOrderCombine( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderCombine'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 구매후기 리스트 가져오기
  getOrderReviewList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderReviewList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 구매후기 이미지 리스트 가져오기
  getOrderReviewImageList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderReviewImageList'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 구매후기 신고 리스트 가져오기
  getOrderReviewReportList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'getOrderReviewReportList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 구매후기등록
  setOrderReview( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderReview'
      }, form.value 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  
  // 구매후기 삭제
  setDeleteOrderReview( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setDeleteOrderReview'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 마이페이지 구매후기 이미지 삭제
  setOrderReviewImageDelete( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setOrderReviewImageDelete'
      }, {
        seq: seq
      } 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 배송비 추가
  setDeliveryChargeSave( formData: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setDeliveryChargeSave'
      }, formData
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 배송비 내역 삭제
  setDeliveryChargeDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'order',
        version: '1.0',
        action: 'setDeliveryChargeDelete'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

}
