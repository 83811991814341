import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';

import { ProductService } from '@app/service/product.service';

@Component({
  selector: 'app-product-sell-graph',
  templateUrl: './product-sell-graph.component.html',
  styleUrls: ['./product-sell-graph.component.scss']
})
export class ProductSellGraphComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() productSeq: any;
  @Input() productName: any;

  public chartLabels: Label[] = [];
  public chartType: ChartType = 'line';
  public chartLegend = true;
  public chartPlugins = [pluginDataLabels];
  public chartColors: any[] = [
    { backgroundColor: '#007bff' },
    { backgroundColor: '#dc3545' },
    { backgroundColor: '#28a745' },
  ];

  public chartData: ChartDataSets[] = [
    { data: [], fill: false, label: '매출액' },
    { data: [], fill: false, label: '구매액' },
    { data: [], fill: false, label: '마진' },
  ];

  public chartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private productService: ProductService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getProductSellGraphInfo();
  }

  /*******************************************************************************
    설  명 : 판매현황 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductSellGraphInfo() {
    this.productService.getProductSellGraphInfo( this.productSeq ).then( response => {
      if( response.ResultCode ) {
        response.data.forEach(item => {
          this.chartLabels.push(item.date);
          this.chartData[0].data.push(item.sale);
          this.chartData[1].data.push(item.buy);
          this.chartData[2].data.push(item.margin);
        });
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 창닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDismiss() {
    this.activeModal.dismiss();
  }

}
