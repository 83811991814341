/*******************************************************************************
  설  명 : 슈퍼세일 추가
  작성일 : 2020-11-06
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { SupersaleService } from '@app/service/supersale.service';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-supersale-add',
  templateUrl: './supersale-add.component.html',
  styleUrls: ['./supersale-add.component.scss']
})
export class SupersaleAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public seq: any = 0;

  public supersaleDetail: any = {};
  public title: any = '추가';

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      supersale_name: ['', [Validators.required] ],
      start_date: ['', [Validators.required] ],
      end_date: ['', [Validators.required] ],
      start_date_test: [ '', [] ],
      end_date_test: [ '', [] ],
      test_mem_no: [ '', [] ],
      discount_type: [ '', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private supersaleService: SupersaleService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {

    this.form.patchValue({
      seq: this.seq,
    });

    if ( this.seq === 0 ) {
      this.title = '추가';
    } else {
      this.title = '수정';
      this.getSuperSaleList();
    }
  }

  getSuperSaleList(): Promise<any> {
    return this.supersaleService.getSuperSaleList('', this.seq).then( response => {
      if (response.ResultCode) {
        this.form.patchValue({
          seq: this.seq,
          supersale_name: response.data[0].supersale_name,
          start_date: response.data[0].start_date,
          end_date: response.data[0].end_date,
          start_date_test: response.data[0].start_date_test,
          end_date_test: response.data[0].end_date_test,
          test_mem_no: response.data[0].test_mem_no,
          discount_type: response.data[0].discount_type,
        });
      }
    });
  }

  setSuperSaleSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.supersaleService.setSuperSaleSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  setSuperSaleDelete() {
    if ( confirm('슈퍼세일 정보를 삭제하시겠습니까?') ) {
      this.supersaleService.setSuperSaleDelete( this.seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
}
