<section id="member-mileage-statistic">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">전체 마일리지</p>
        <p class="subtxt">{{mileageTotalRow?.mileage_total | Numeral: "0,0"}}</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">사용 마일리지</p>
        <p class="subtxt">{{mileageTotalRow?.mileage_used | Numeral: "0,0"}}</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">가용 마일리지</p>
        <p class="subtxt">{{mileageTotalRow?.mileage_remining | Numeral: "0,0"}}</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon5">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">마일리지 획득 회원수</p>
        <p class="subtxt">{{mileageTotalRow?.member_count | Numeral: "0,0"}}</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon5">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">평균 마일리지</p>
        <p class="subtxt">{{mileageTotalRow?.mileage_total_avg | Numeral: "0,0"}}</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon5">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">회원최고 마일리지</p>
        <p class="subtxt">{{mileageTotalRow?.mileage_total_max | Numeral: "0,0"}}</p>
      </div>
      <div class="cb"></div>
    </div>
    <div class="fl widget widget-last cp">
      <div class="fl widget-icon widget-icon5">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt4">회원최저 마일리지</p>
        <p class="subtxt">{{mileageTotalRow?.mileage_total_min | Numeral: "0,0"}}</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <section class="chart">
    <canvas #chart height="60"></canvas>
  </section>

  <section class="mt10" style="height: calc(55% - 20px);">
    <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
      <as-split-area size="50" minSize="30" maxSize="70" direction="horizontal">
        <div class="mb5">
          <div class="fl">
            <h4 class="fb mb5">상위 마일리지 50명의 마일리지와 구매금액</h4>
          </div>

          <!-- <div class="fr right">
            <btn-ag-grid-save gridId="8e1047764c814cc5a0c9ff8f2239167e" [btnGroup]="1"></btn-ag-grid-save>
          </div> -->

          <div class="cb"></div>
        </div>

        <ag-grid-angular
          style="width: 100%;height:calc(100% - 40px);"
          class="ag-theme-balham"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          #gridA
        >
        </ag-grid-angular>
      </as-split-area>
      <as-split-area size="50" minSize="30" maxSize="70">
        <div class="mb5">
          <div class="fl">
            <h4 class="fb mb5">상위 결제자 50명의 결제금액과 마일리지</h4>
          </div>

          <!-- <div class="fr right">
            <btn-ag-grid-save gridId="ddeaa1bc167f4e7ab60b1aedd8de0884" [btnGroup]="1"></btn-ag-grid-save>
          </div> -->

          <div class="cb"></div>
        </div>

        <ag-grid-angular
          style="width: 100%;height:calc(100% - 40px);"
          class="ag-theme-balham"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          #gridB
        >
        </ag-grid-angular>
      </as-split-area>
    </as-split>
  </section>
</section>
