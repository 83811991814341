/*******************************************************************************
  설  명 : 재고관리 > 출고관리 > 주문리스트 탭
  작성일 : 2020-08-14
  작성자 : 송영석
  접속URL : /stock/out/management

  수정일 : 2021.05.31
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AgGridImageComponent } from '@app/components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@app/components/ag-grid-excomponent/ag-grid-excomponent';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { StockOut } from '@app/service/stockOut.service';
import { StockOrder } from '@app/service/stockOrder.service';

@Component({
  selector: 'app-stock-out-order',
  templateUrl: './stock-out-order.component.html',
  styleUrls: ['./stock-out-order.component.scss']
})
export class StockOutOrderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  // public search: any = {
  //   sdate: '',
  //   edate: '',
  //   order_status: 1,

  //   manage: true,
  //   pageNo: 1,
  //   pageRow: 20,
  //   seq: '',
  //   searchField: 'r_name',
  //   searchText: '',
  //   searchField2: 'o_name',
  //   searchText2: '',
  //   shop_gbn: '',
  //   searchType: '',
  //   onoff_gbn: '',
  //   pay_method: '',
  //   topSearchText: ''
  // };

  // selectedRows: number = 0;

  // orderList: any = [];
  // orderDetailList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;
  rowClassRules: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public components: any;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private formBuilder: FormBuilder,
    private stockOrderComponent: StockOrderComponent,
    private stockOut: StockOut,
    private authService: AuthService,
    private stockOrder: StockOrder,
  ) {
    this.columnDefs = [
      {headerName: '', field: '', width: 50, checkboxSelection: true},
      {headerName: '주문번호', field: 'order_seq', width: 80, cellClass: 'cp center' },
      {headerName: '구분', width: 90, field: 'product_type_name', cellClass: 'center' },
      {headerName: '주문일자', width: 150, field: 'order_date', cellClass: 'center' },
      {headerName: '주문자', width: 160, field: 'o_name', cellClass: 'center' },
      {headerName: '수신자', width: 160, field: 'r_name', cellClass: 'center' },
      {headerName: '배송주소', width: 400, field: 'r_address', cellClass: '' },
      {headerName: '주문수량', width: 100, field: 'order_qty', cellClass: 'right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '주문금액', width: 100, field: 'order_amt', cellClass: 'right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '결제금액', width: 100, field: 'pay_amt', cellClass: 'right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      // {headerName: '요청사항', width: 300, field: 'r_comment', cellClass: '' },
      {headerName: '출고지시 수정 이슈', width: 200, field: 'mng_memo', cellClass: '' }
    ];

    this.columnDefsDetail = [
      {headerName: '구분', width: 60, field: 'product_type_name', cellClass: 'cp center' }, // 매입, 입점
      {headerName: '카테고리', width: 110, field: 'category_name', cellClass: 'cp center' },
      {headerName: '이미지', width: 70, field: 'thumbnail_url', cellClass: 'cp center', cellRenderer: 'agGridImageComponent' },
      {headerName: '공급자명', width: 130, field: 'provider_name', cellClass: 'cp center' },
      {headerName: '상품명', width: 250, field: 'product_name', cellClass: 'cp' },
      {headerName: '상품코드', width: 90, field: 'product_seq', cellClass: 'cp' },
      {headerName: '오늘출발', width: 90, field: 'is_mach', cellClass: 'cp center' , cellRenderer: 'agGridHtmlComponent',
      valueGetter: function(params) {
        if( params.data.is_mach == '오늘출발' ) {
          return '<span class="badge badge-primary f12">오늘출발</span>';
        } else {
          return '<span></span>';
        }
      }},
      {headerName: '색상', width: 110, field: 'display_color_name', cellClass: 'cp center' },
      {headerName: '사이즈', width: 90, field: 'display_size_name', cellClass: 'cp center' },
      {headerName: '주문수량', width: 80, field: 'qty', cellClass: 'cp right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '판매단가', width: 80, field: 'amt', cellClass: 'cp right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '판매금액', width: 80, field: 'total_amt', cellClass: 'cp right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '대구재고', width: 80, field: 'daegu_stock', cellClass: 'cp right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '서울재고', width: 80, field: 'seoul_stock', cellClass: 'cp right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '출고지시수량', field: 'outOrderQty', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, cellEditor: "numericCellEditor", cellRenderer: 'agGridHtmlComponent',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '기출고지시수량', width: 110, field: 'incompleteOutOrderQty', cellClass: 'cp right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '출고수량', width: 80, field: 'outQty', cellClass: 'cp right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '미출고수량', width: 90, field: 'incompleteOutQty', cellClass: 'cp right',
        valueGetter : (params) => this.stockOrderComponent.valueGetter_num(params) },
    ];

    this.defaultColDef = {...this.stockOrderComponent.defaultColDef};
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate;
    this.rowSelection = this.stockOrderComponent.rowSelection;

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

    this.rowClassRules = {
      'status3': function (params) {
        return params.data.mng_memo !== null;
      },
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();
    this.getOrderList();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({

      orderList : [[]],
      orderDetail : [[]],

      search : [''],
      buyType : [0],
      date_btn : [30],
      s_date : [this.stockOrderComponent.dateFormat( new Date(new Date().getTime() - 2592000000) )],
      e_date : [this.stockOrderComponent.dateFormat( new Date() )]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReady_detail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
  *******************************************************************************/
  // getCommonList() {
  //   // 주문상태
  //   this.commonService.getCommonListCode('ORS').then( response => {
  //     if ( response.ResultCode ) {
  //       this.orderTypeList = response.data;
  //     } else {
  //       this.orderTypeList = [];
  //     }
  //   });
  // 주문상태(공통코드)
  // commong_group = ORS
  // 0 = 주문접수
  // 1 = 결제확인
  // 2 = 배송준비
  // 3 = 전체반품
  // 4 = 일부반품
  // 5 = 전체교환
  // 6 = 일부교환
  // 7 = 주문취소
  // 8 = 발송완료
  // 9 = 주문완료(확정)
  //   // SHOP
  //   this.commonService.getCommonListCode('SHP').then( response => {
  //     if ( response.ResultCode ) {
  //       this.orderShopList = response.data;
  //     } else {
  //       this.orderShopList = [];
  //     }
  //   });

  //   // 결제수단
  //   this.commonService.getCommonListCode('PMD').then( response => {
  //     if ( response.ResultCode ) {
  //       this.payMethodList = response.data;
  //     } else {
  //       this.payMethodList = [];
  //     }
  //   });
  // }

  /*******************************************************************************
    설  명 : 주문 리스트 가져오기
  *******************************************************************************/
  getOrderList() {
    this.getOrderDetail();
    // this.orderService.getOrderList({
    //   sdate: this.form.value.s_date,
    //   edate: this.form.value.e_date,
    //   pageNo: 1,
    //   pageRow: 9999,
    //   order_status: 1 // 결제확인
    // }).then( response => {
    //   if(response.ResultCode)
    //     response.data.length
    //     ? this.getOrderDetail(response.data)
    //     : this.form.patchValue({orderList : []})
    //   else this.toastrService.error(response.ResultMessage);
    // });
  }
  /*******************************************************************************
    설  명 : 주문 상세정보 가져오기
  *******************************************************************************/
  getOrderDetail() {
    this.stockOut.getStockOut({
      // seq : res.map(e => e['seq']),
      s_date: this.form.value.s_date,
      e_date: this.form.value.e_date,
      search : this.form.value.search,
      buyType : this.form.value.buyType
    }).then(response => {
      if(response.ResultCode) {
        const _RD = response.data;
        const _A = [];
        _RD.forEach(e => {
          const _index = _A.findIndex(_e => _e['order_seq'] == e['order_seq']);
          const _detail = {
            order_detail_seq : e['order_detail_seq'],
            product_type_name : e['product_type_name'],
            provider_seq : e['provider_seq'],
            provider_name : e['provider_name'],
            customer_seq : e['customer_seq'],
            category_name : e['category_name'],
            thumbnail_url : e['thumbnail_url'],
            product_name : e['product_name'],
            is_mach : e['is_mach'],
            display_color_name : e['display_color_name'],
            color_name : e['color_name'],
            display_size_name : e['display_size_name'],
            size_name : e['size_name'],
            qty : parseInt(e['qty']),
            unit_price : parseInt(e['unit_price']),
            amt : parseInt(e['amt']),
            total_amt : parseInt(e['total_amt']),
            daegu_stock : parseInt(e['daegu_stock']),
            seoul_stock : parseInt(e['seoul_stock']),
            outQty : parseInt(e['outQty'] || 0),
            incompleteOutQty : parseInt(e['incompleteOutQty'] || 0),
            incompleteOutOrderQty : parseInt(e['incompleteOutOrderQty'] || 0),
            outOrderQty : parseInt(e['qty'] || 0) - parseInt(e['incompleteOutOrderQty'] || 0) - parseInt(e['outQty'] || 0),

            // 직발시 사용
            product_seq : e['product_seq'],
            product_property_seq : e['product_property_seq'],
            order_seq : e['order_seq'],
            real_order_seq : e['order_PK']
          };

          _index < 0
          ? _A.push({
            order_seq : e['order_seq'],
            real_order_seq : e['order_PK'],
            product_type_name : '',
            order_date : e['order_date'],
            o_name : e['o_name'],
            r_name : e['r_name'],
            r_address : e['r_address'] + e['r_address_detail'],
            order_qty : 0,
            // order_amt : parseInt(e['order_amt']),
            order_amt : 0,
            pay_gbn : e['pay_gbn'],
            pay_amt : parseInt(e['pay_amt']),
            pay_date : e['pay_date'],
            r_comment : e['r_comment'],
            mng_memo : e['mng_memo'],

            _detail : [_detail]
          })
          : _A[_index]['_detail'].push(_detail)
        });

        _A.forEach(e => {
          const __A = [];
          ['매입', '입점', '직발'].forEach(_e => {
            if( e['_detail'].filter(__e => __e['product_type_name'] == _e).length ) __A.push(_e)
          })
          e['product_type_name'] = __A.join('+')

          e['order_qty'] = e['_detail'].reduce((a,c) => a + c['qty'], 0);
          e['order_amt'] = e['_detail'].reduce((a,c) => a + c['total_amt'], 0);
        })

        this.form.patchValue({
          orderList : _A,
          orderDetail : []
        });
      } else this.toastrService.error(response.ResultMessage);
    })
  }

  rowData_reCalc(_A) {
    _A.forEach(e => {
      const __A = [];
      ['매입', '입점', '직발'].forEach(_e => {
        if( e['_detail'].filter(__e => __e['product_type_name'] == _e).length ) __A.push(_e)
      })
      e['product_type_name'] = __A.join('+')

      e['order_qty'] = e['_detail'].reduce((a,c) => a + c['qty'], 0);
      e['order_amt'] = e['_detail'].reduce((a,c) => a + c['total_amt'], 0);
    })
  }

  /*******************************************************************************
    설  명 : 발주상세 클릭시 색상사이즈 보여주기
  *******************************************************************************/
  onCellClicked_order($event) {
    this.form.patchValue({
      orderDetail : $event.data._detail
    });

    if( $event.colDef.field === 'order_seq' ) {
      const url = '/order/detail?seq=' + $event.data.order_seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(which, value) {
    this.stockOrderComponent.selectDate(which, value, this);
  }
  /*******************************************************************************
    설  명 : 검색조건 버튼 클릭
  *******************************************************************************/
  btnClick(which, value) {
    this.form.patchValue({[which] : value});
    this.getOrderList();
  }
  /*******************************************************************************
    설  명 : 날짜선택 바로가기 버튼 클릭
  *******************************************************************************/
  quickDate(quickTime) {
    this.stockOrderComponent.quickDate(quickTime, this);
  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  reset() {
    this.ngOnInit();
  }
  softReset() {
    this.getOrderList()
  }

  /*******************************************************************************
    설  명 : 출고지시
    입력값 :
            {
              mem_no,

              _orderList : [{
                order_seq,
                order_status,
                mng_status,
                mng_out_type,
                purchase_seq,

                _detail : [{
                  order_detail_seq,
                  status,
                  out_order_qty, // 출고지시수량
                  out_qty, // 출고수량
                  customer_seq
                }]
              }]
            }
  *******************************************************************************/
  setStockOut() {
    const _SR = this.gridApi.getSelectedRows();
    if(!_SR.length) {
      this.toastrService.warning('선택한 데이터가 없습니다.', '');
      return;
    }

    let stockOutCheck: boolean;
    const _orderList = [
      []
      // 매입 : 첫번째 인덱스 고정
      // 입점 : provider_seq 로 구분하여 동적배열할당
    ]
    _SR.forEach(e => {
      e['_detail'].filter(_e => parseInt(_e['outOrderQty'])).forEach(_e => {

        if( parseInt(_e.outOrderQty) > _e.incompleteOutQty ) {
          stockOutCheck = true;
        }

        let _index
        let mng_out_type
        const _productType = _e['product_type_name']
        switch(_productType) {
          case '매입': _index = 0; mng_out_type = 1000; break
          case '입점':
            // _index = 1
            mng_out_type = 2000

            _index = _orderList.findIndex(__e => __e.length ? __e[0]['_detail'][0]['customer_seq'] == _e['provider_seq'] : false)
            if(_index < 0) {
              _orderList.push([])
              _index = _orderList.length - 1
            }
            break
          case '직발': return
        }
        const __index = _orderList[_index].findIndex(__e => __e['order_seq'] == e['order_seq'] )
        const _detail = {
          order_detail_seq : _e['order_detail_seq'],
          status : 1000,
          out_order_qty : _e['outOrderQty'],
          // out_qty : _e['outQty'] || 0,
          customer_seq : _productType == '입점' ? _e['provider_seq'] : ''
        }
        __index < 0
        ? _orderList[_index].push({
            order_seq : e['order_seq'],
            order_status : e['_detail'].length == e['_detail'].filter(_e => _e['incompleteOutQty'] == _e['outOrderQty']).length ? 2 : 1, // 2 : 배송준비, 1 : 결제확인
            mng_status : 1000, // e['_detail'].length == e['_detail'].filter(_e => _e['incompleteOutQty'] == _e['outOrderQty']).length ? 1000 : 0, // 1000 : 출고지시
            mng_out_type : mng_out_type,
            purchase_seq : '',

            _detail : [_detail]
          })
        : _orderList[_index][__index]['_detail'].push(_detail)

      })
    })

    if( stockOutCheck === true ) {
      this.toastrService.error('출고지시수량이 미출고수량보다 많은 내역이 있습니다.');
      return;
    } else {
      this.authService.getLoginInfo.subscribe(__e => {

        _orderList.filter(e => e.length).forEach(e => {
          this.stockOut.setStockOut({
            mem_no : __e['mem_no'],
            _orderList : JSON.stringify(e)
          }).then(response => {
            if(response.ResultCode) this.toastrService.success(response.ResultMessage);
            else this.toastrService.error(response.ResultMessage);
            this.softReset()
          })
        })

      }).unsubscribe();
    }
  }

  /*******************************************************************************
    설  명 : 매입/입점변경, 직발처리 버튼 클릭
  *******************************************************************************/
  changeType(type = 0) {
    const _SR = this.gridApiDetail.getSelectedRows()
    if(!_SR.length) {
      this.toastrService.warning('선택한 데이터가 없습니다.')
      return
    } else if(_SR.length > 1) {
      this.toastrService.warning('선택한 데이터가 너무 많습니다.')
      return
    }

    if(!type) {
      const _afterSetBuyState = () => {
        this.gridApiDetail.refreshCells()
        this.rowData_reCalc(this.gridApi.getSelectedRows())
        // this.gridApi.refreshCells()
      }
      _SR.forEach(e => {
        // e['product_type_name'] = (e['product_type_name'] == '매입') ? '입점' : '매입'
        switch(e['product_type_name']) {
          case '매입':
            if(!e['customer_seq']) {
              this.toastrService.error('공급업체정보가 없으므로 변경이 불가능합니다.')
              return
            } else if(!confirm('입점으로 변경하시겠습니까?')) return

            this.authService.getLoginInfo.subscribe(__e => {
              this.stockOut.setBuyState({
                mem_no : __e['mem_no'],
                order_detail_seq : e['order_detail_seq'],
                customer_seq : e['customer_seq']
              }).then(response => {
                if(response.ResultCode) {
                  e['provider_seq'] = e['customer_seq']
                  e['product_type_name'] = '입점'
                  _afterSetBuyState()
                  this.toastrService.success(response.ResultMessage)
                } else this.toastrService.error(response.ResultMessage)
              })
            }).unsubscribe()
            break
          case '입점':
            if(!confirm('매입으로 변경하시겠습니까?')) return

            this.authService.getLoginInfo.subscribe(__e => {
              this.stockOut.setBuyState({
                mem_no : __e['mem_no'],
                order_detail_seq : e['order_detail_seq'],
                customer_seq : ''
              }).then(response => {
                if(response.ResultCode) {
                  e['provider_seq'] = ''
                  e['product_type_name'] = '매입'
                  _afterSetBuyState()
                  this.toastrService.success(response.ResultMessage)
                } else this.toastrService.error(response.ResultMessage)
              })
            }).unsubscribe()
            break
        }
      })
      // onbeforeunload = () => true

    } else {
      if( _SR.filter(e => !e['outOrderQty']).length ) {
        this.toastrService.warning('출고지시수량이 없으므로 직발이 불가능합니다.')
        return
      }
      // else if( _SR.filter(e => e['product_type_name'] == '입점').length ) {
      //   this.toastrService.warning('입점 상품은 직발이 불가능합니다.')
      //   return
      // }
      else if( _SR.filter(e => !e['customer_seq']).length ) {
        this.toastrService.warning('거래처가 없으므로 직발이 불가능합니다.')
        return
      }
      else if(!confirm('직발 발주서를 생성하고 출고지시 단계로 변경합니다. \r\n진행합니까?')) return

      _SR.forEach(e => {
        e['product_type_name'] = '직발'
      })
      this.gridApiDetail.refreshCells()

      this.authService.getLoginInfo.subscribe(__e => {

        _SR.forEach(e => {
          this.setDirect({
            order_seq : e['order_seq'],
            gbn : 2,
            date : this.stockOrderComponent.dateFormat(new Date()),
            title : `주문번호 ${e['order_seq']} 직발 자동생성`,
            memo : `주문번호 ${e['order_seq']} 직발 자동생성`,
            mem_no : __e['mem_no'],
            customer_seq : e['customer_seq'],
            product : [{
              product_seq : e['product_seq'],
              // qty : e['qty'],
              qty : e['outOrderQty'],
              unit_amt : e['unit_price'],
              // amt : e['amt'] * e['qty'],
              amt : e['amt'] * e['outOrderQty'],
              total_amt : e['total_amt'],
              product_property : [{
                product_property_seq : e['product_property_seq'],
                warehouse_seq : '',
                warehouse_location_seq : '',
                purchase_cnt : e['outOrderQty']
              }]
            }]
          }, {
            mem_no : __e['mem_no'],

            _orderList : [{
              order_seq : e['order_seq'],
              order_status : 2,
              mng_status : 1000,
              mng_out_type : 3000,
              // purchase_seq : ,

              _detail : [{
                order_detail_seq : e['order_detail_seq'],
                status : 1000,
                out_order_qty : e['outOrderQty'], // 출고지시수량
                out_qty : e['outQty'] || 0, // 출고수량
                customer_seq : e['provider_seq']
              }]
            }]
          })
        })

      }).unsubscribe()

    }
  }

  /*******************************************************************************
    설  명 : 직발처리
    입력값 : {
              // 발주마스터
              gbn,
              title,
              date,
              memo,
              mem_no,
              customer_seq,

              // 발주 상품 내역
              product : [{
                product_seq,
                qty,
                unit_amt,
                amt,

                // 발주 상품별 색상 및 사이즈
                product_property : [{
                  product_property_seq,
                  warehouse_seq,
                  warehouse_location_seq,
                  purchase_cnt,
                }],

              }],
            }

    // mng
    입력값 : {
              mem_no,

              _orderList : {
                order_seq,
                order_status,
                mng_status,
                mng_out_type,
                purchase_seq,

                _detail : [{
                  order_detail_seq,
                  status,
                  out_order_qty, // 출고지시수량
                  out_qty, // 출고수량
                  customer_seq
                }]
              }
            }
  *******************************************************************************/
  setDirect(params_purchase, params_mng) {
    this.stockOrder.setStockOrder(params_purchase).then(response => {
      if(response.ResultCode) {
        this.toastrService.success(response.ResultMessage, '발주')

        this.stockOut.setStockOut({
          ...params_mng,
          _orderList : JSON.stringify(params_mng['_orderList'].map(e => ({
            ...e,
            purchase_seq : response.data.purchase_seq
          })))
        }).then(response => {
          if(response.ResultCode) this.toastrService.success(response.ResultMessage, '출고지시');
          else this.toastrService.error(response.ResultMessage, '출고지시');
          this.softReset()
        });
      } else this.toastrService.error(response.ResultMessage, '발주');
    })
  }

  /*******************************************************************************
    설  명 : 출고지시수량 셀 클릭시 경고
  *******************************************************************************/
  cellClick_detail($event) {
    // if($event.colDef.field == 'outOrderQty') this.toastrService.warning('출고지시수량 변경은 신중하게 하세요.');
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
  *******************************************************************************/
  keypress( $event ) {
    if( $event.key == 'Enter' ) this.softReset()
  }
}
