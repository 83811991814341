/*******************************************************************************
  설  명 : 현황관리 - 전체 매출현황
  작성일 : 2020-12-14
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

import { CommonService } from '@app/service/common.service';
import { StatisticService } from '@app/service/statistic.service';
import { BrandService } from '@app/service/brand.service';
import { CategoryService } from '@app/service/category.service';
import { UtilService } from '@app/service/util.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-statistic-home',
  templateUrl: './statistic-home.component.html',
  styleUrls: ['./statistic-home.component.scss']
})
export class StatisticHomeComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public commoncode = [{
    common_code: '',
    common_name: '',
    common_ename: ''
  }];

  public search: any = {
    group: '',
    category_select: '',
    category: '',
    brand_select: '',
    brand: ''
  };

  public selectCategory: any = {
    category_code: '',
    title: '',
  };

  public optionsCategory: any = {
    multiple: false,
    placeholder: '카테고리 검색'
  }

  public optionsBrand: any = {
    multiple: false,
    placeholder: '브랜드 검색'
  }

  public categoryDataList: any = [];
  public brandDataList: any = [];
  public stmDataList: any = [];
  public year: any = '2020';
  
  public selectCategoryList: any = [];
  public selectBrandList: any = [];
  public categoryList: any = [];
  public brandList: any = [];

  // 차트 관련 변수
  public barChartData: any = [];
  public chartType = 'bar';
  public chartLabel: string[] = [];

  // 차트 옵션
  public chartOptions = {
    tooltips: {
      display: true,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: 'chocolate',
      bodyFontColor: '#000',
      bodyFontSize: 12,
      displayColors: false,
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipItem.value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') + ' 원';
        }
      }
    },
    scaleShowVerticalLines: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuad'
    },
    responsive: true,
    legend: {
      display: true
    },
    hover: {
      mode: 'arest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data',
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: false,
          labelString: 'Data Value'
        },
        ticks: {
          beginAtZero: true,
          callback : value => value.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        }
      }]
    }
  };

  // 그리드 관련 선언
  public dataList: any = [];
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [{ headerName: '구분', field: 'common_name', width: 120, cellClass: 'cp center' },]
  defaultColDef: any = {
    sortable: true,
    filter: false,
    resizable: true
  };
  domLayout: any;
  rowSelection: any = 'single'
  paginationPageSize: any = 100;
  noRowsTemplate: string = '검색된 데이터가 없습니다.'
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private agGridExComponent: AgGridExComponent,
    private commonService: CommonService,
    private statisticService: StatisticService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.commonService.getCommonListCode('STG').then(response => {
      if (response.ResultCode) {
        this.commoncode = response.data;
        
        // chart
        const tmp = [];
        this.commoncode.map(data => {
          tmp.push({type: 'bar', data: [], label: data.common_name, fill: false, lineTension: 0});
        });
        this.barChartData.push(tmp[0]);
        this.barChartData.push(tmp[1]);
        this.barChartData.push(tmp[2]);
        this.barChartData.push(tmp[3]);

        this.getTotalSales();
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });

    this.categoryService.getTopCategory().then( response => {
      if (response.ResultCode) {
        this.categoryList = [{category_code: '', title: '전체'}, ...response.data];
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });

    this.brandService.getTopBrand().then( response => {
      if (response.ResultCode) {
        this.brandList = [{key: '', title: '전체'}, ...response.data];
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });

    this.getStmCodeList();
    this.getCategoryList2();
    this.getBrandList();

    for( let i = 0; i < 10; i++ ) {
      this.columnDefs.push({
        headerName: parseInt( moment().format('YYYY'), 10 ) - 9 + i + '년',
        field: (parseInt( moment().format('YYYY'), 10 ) - 9 + i).toString(),
        width: 150,
        cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter 
      });
      this.chartLabel[i] = parseInt( moment().format('YYYY'), 10 ) - 9 + i + '년';
    };
/*
    this.columnDefs.push({
      headerName: '합계',
      field: 'total',
      width: 140,
      cellClass: 'cp right',
      valueFormatter: this.agGridExComponent.currencyFormatter 
    });
    */
  };

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /*******************************************************************************
    설  명 : 검색 설정 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      group: '',
      category_select: '',
      category: '',
      brand_select: '',
      brand: ''
    };

    this.getTotalSales();
  };

  /*******************************************************************************
    설  명 : select2 카테고리 검색 이벤트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedCategory( $event, item ) {   
    const key = $event.id || (item.category_code === '' ? '0' : item.category_code);
    
    if ( key != '0') {
      const product = this.categoryList.find(f => f.category_code == key) || { category_code: '0' };
      this.search.category_select = key;
      this.search.category = product.category_code;
      this.search.brand_select = '0';
      this.search.brand = '';
    } else {
      this.search.category_select = '0';
      this.search.category = '';
    };

    this.getTotalSalesCategory();
  };

  /*******************************************************************************
    설  명 : select 브랜드 검색 이벤트 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changedBrand( $event, item ) {
    const key = $event.id || (item.key === '' ? '0' : item.key);
    
    if ( key != '0') {
      const brand = this.brandList.find(f => f.key == key) || { key: '0' };
      this.search.brand_select = key;
      this.search.brand = brand.key;
      this.search.category_select = '0';
      this.search.category = '';
    } else {
      this.search.brand_select = '0';
      this.search.brand = '';
    };

    this.getTotalSalesBrand();
  };

  /*******************************************************************************
    설  명 : 년도별 매출 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getTotalSales() {
    this.statisticService.getTotalSales().then( response => {
      let tmp0 = [];
      let tmp1 = [];
      let tmp2 = [];
      let tmp3 = [];

      if( response.ResultCode ) {
        this.dataList = response.data;
      }

      tmp0 = Object.values(this.dataList[0]);
      this.barChartData[0].data = tmp0.slice(0);
      tmp1 = Object.values(this.dataList[1]);
      this.barChartData[1].data = tmp1.slice(0);
      tmp2 = Object.values(this.dataList[2]);
      this.barChartData[2].data = tmp2.slice(0);
      tmp3 = Object.values(this.dataList[3]);
      this.barChartData[3].data = tmp3.slice(0);
    });
  };

  onClickButton() {
    const category = this.search.category_select !== '0' ? this.search.category_select : this.search.category;
    const brand = this.search.brand_select !== '0' ? this.search.brand_select : this.search.brand;
    if (category === '' && brand === '') {
      this.getTotalSales();
      return;
    };

    if (category !== '')
      return this.getTotalSalesCategory();

    if (brand !== '') 
      return this.getTotalSalesBrand();
  };

  // 전체 매출현황 (카테고리)
  getTotalSalesCategory() {
    const category = this.search.category_select !== '0' ? this.search.category_select : this.search.category;
    const brand = this.search.brand_select !== '0' ? this.search.brand_select : this.search.brand;
    if (category === '' && brand === '') {
      this.getTotalSales();
      return;
    };

    this.statisticService.getTotalSalesCategory(category).then(response => {
      const data = [];
      this.barChartData.map(chart => {
        return chart.data = [];
      });

      if (response.ResultCode && response.data.length > 0) {
        this.commoncode.map(item => {
          data.push(this.utilService.saleDataPivot(item.common_code, item.common_name, item.common_ename, 'period', response.data));
        });

        const param = {
          common_code: undefined,
          common_name: undefined,
          total: undefined,
        };
        this.barChartData[0].data = Object.values({...data.find(f => f.common_code === '1000'), ...param}).filter(f => f != null);
        this.barChartData[1].data = Object.values({...data.find(f => f.common_code === '2000'), ...param}).filter(f => f != null);
        this.barChartData[2].data = Object.values({...data.find(f => f.common_code === '3000'), ...param}).filter(f => f != null);
        this.barChartData[3].data = Object.values({...data.find(f => f.common_code === '4000'), ...param}).filter(f => f != null);
      };

      this.dataList = data;
    });
  };

  // 전체 매출현황 (브랜드)
  getTotalSalesBrand() {
    const category = this.search.category_select !== '0' ? this.search.category_select : this.search.category;
    const brand = this.search.brand_select !== '0' ? this.search.brand_select : this.search.brand;
    if (category === '' && brand === '') {
      this.getTotalSales();
      return;
    };

    this.statisticService.getTotalSalesBrand(brand).then(response => {
      const data = [];
      this.barChartData.map( chart => {
        return chart.data = [];
      });

      if (response.ResultCode && response.data.length > 0) {
        this.commoncode.map(item => {
          data.push(this.utilService.saleDataPivot(item.common_code, item.common_name, item.common_ename, 'period', response.data));
        });

        const param = {
          common_code: undefined,
          common_name: undefined,
          total: undefined,
        };
        this.barChartData[0].data = Object.values({...data.find(f => f.common_code === '1000'), ...param}).filter(f => f != null);
        this.barChartData[1].data = Object.values({...data.find(f => f.common_code === '2000'), ...param}).filter(f => f != null);
        this.barChartData[2].data = Object.values({...data.find(f => f.common_code === '3000'), ...param}).filter(f => f != null);
        this.barChartData[3].data = Object.values({...data.find(f => f.common_code === '4000'), ...param}).filter(f => f != null);
      };

      this.dataList = data;
    });
  };  

  /*******************************************************************************
    설  명 : stm 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getStmCodeList() {
    this.statisticService.getStmCodeList().then( response => {
      if( response.ResultCode ) {
        this.stmDataList = response.data;
      }
    });
  };

  /*******************************************************************************
    설  명 : select 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList2() {
    this.categoryService.getCategoryList2().then( response => {
      this.selectCategoryList = [];
      if (response.ResultCode) {
        this.selectCategoryList.push({id: '0', text: '카테고리 선택'});

        response.data.forEach( row => {
          this.selectCategoryList.push({
            id: row.seq,
            text: row.category_name
          });
        });
      } else {
        this.selectCategoryList = [];
      }
    });
  };

  /*******************************************************************************
    설  명 : select 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      this.selectBrandList = [];
      if( response.ResultCode ) {
        this.selectBrandList.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          this.selectBrandList.push({
            id: row.seq,
            text: row.brand_name
          });
        });

      } else {
        this.selectBrandList = [];
      }
    });
  };

  moveSimplePage() {
    this.router.navigate(['/statistic/simple'], {
      relativeTo: this.activatedRoute,
      queryParams: { type : 0 },
    })
  }
};
