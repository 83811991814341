  <section class="chart">
    <canvas
      *ngIf="barChartData.length > 0" width="100%" height="23" baseChart
      [datasets]="barChartData"
      [labels]="chartLabel"
      [chartType]="chartType"
      [options]="chartOptions"
    ></canvas>
  </section>

  <section style="height:40%;" class="mt10">
    <ag-grid-angular
      style="width: 100%;height:calc(100% - 40px);"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="dataList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </section>