import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { BasicService } from '@app/service/basic.service';
import { EmployeeService } from '@app/service/employee.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-warehouse-detail',
  templateUrl: './warehouse-detail.component.html',
  styleUrls: ['./warehouse-detail.component.scss']
})
export class WarehouseDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  originEmployeeList: any = [];
  employeeList: any = [];

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      warehouse_name: ['', [Validators.required] ],
      stock_yn: ['', [Validators.required] ],
      wh_zipcode: ['', [] ],
      wh_address: ['', [] ],
      wh_address_detail: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private basicService: BasicService,
    private employeeService: EmployeeService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getEmployeeList();
  }

  /*******************************************************************************
    설  명 : 직원 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployeeList() {
    this.employeeService.getEmployeeList().then( response => {
      if( response.ResultCode ) {
        this.originEmployeeList = response.data;

        this.employeeList.push({
          title: '선택',
          value: 0
        });

        this.originEmployeeList.forEach(item => {
          this.employeeList.push({
            title: item.name,
            value: item.mem_no
          });
        });

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 창고 정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setWarehouseSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.basicService.setWarehouseSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close( true );
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      wh_zipcode: data.zip,
      wh_address: data.addr
    });

    $("#wh_address_detail").focus();
  }

  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }

}
