import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import { PushService } from '@app/service/push.service';
import { CommonService } from '@app/service/common.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-push-form',
  templateUrl: './push-form.component.html',
  styleUrls: ['./push-form.component.scss']
})
export class PushFormComponent implements OnInit {

  // 검색
  public search = {
    searchText: '',
  };

  public form: FormGroup;
  public formErrors = {
    contents: ''
  };

  private titleDef = {title: '자동발송 안함', value: ''};
  public autoList: any = [];
  public pushList: any = [];

  // grid
  private selectedRows = 0;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowSelection = 'single';
  public noRowsTemplate = '검색된 데이터가 없습니다.';
  public defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };
  public columnDefs = [
    {headerName: '번호', field: 'seq', cellClass: 'cp center', width: 70 },
    {headerName: '자동발송', field: 'common_name', cellClass: 'cp center', width: 200 },
    {headerName: '제목', field: 'title', cellClass: 'cp', width: 200 },
    {headerName: '발송내용', field: 'contents', cellClass: 'cp', width: 400 },
    {headerName: '링크', field: 'link', cellClass: 'cp', width:150 },
    {headerName: '파라미터', field: 'query_params', cellClass: 'cp', width:150 },
    {headerName: '등록일', field: 'write_date', cellClass: 'cp center', width: 150 },
    {headerName: '자동발송 코드', field: 'auto_seq', hide: true }
  ];

  constructor(
    private utilService: UtilService,
    private pushService: PushService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : PUSH 입력폼 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', []],
      title: ['', [Validators.required, Validators.maxLength(100)]],
      link: ['', [Validators.maxLength(255)]],
      query_params: ['', [Validators.maxLength(255)]],
      auto_seq: ['', [] ],
      contents: ['', [Validators.required, Validators.maxLength(80)] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  ngOnInit(): void {
    this.getAutoSMS();
    this.getPushFormList();
  }

  /*******************************************************************************
    설  명 : 자동 발송 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAutoSMS() {
    this.autoList = [];
    this.autoList.push(this.titleDef);

    this.commonService.getCommonListCode('PUS').then( response => {
      for (const item of response.data) {
        this.autoList.push({
          title: item.common_name,
          value: item.common_code
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : PUSH 발송양식 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPushFormList() {
    this.pushService.getPushFormList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.pushList = response.data;
      } else {
        this.pushList = [];
      }

    });
  }

  /*******************************************************************************
    설  명 : 한글 포함 문자열 갯수 처리
    입력값 : 문자열
    리턴값 : 문자수
  *******************************************************************************/
  getTextLength(str) {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length === 6) {
            len++;
        }
        len++;
    }
    return len;
  }

  /*******************************************************************************
    설  명 : PUSH 발송양식 저장 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.pushService.setPushForm(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getPushFormList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : PUSH 발송양식 삭제 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delPushForm() {
    if ( this.form.controls.seq.value === '' || this.form.controls.seq.value == null ) {
      return false;
    }

    if ( confirm('선택하신 SMS 발송 양식을 삭제하시겠습니까?') ) {
      this.pushService.delPushForm( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success('PUSH 메시지를 성공적으로 삭제하였습니다', 'PUSH');

          this.getPushFormList();
          this.addSMSForm();
        } else {
          this.toastrService.error( 'PUSH 메시지를 삭제하는데 실패하였습니다.', 'PUSH');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : PUSH 발송양식 등록 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addSMSForm() {
    this.form.reset({
      seq: '',
      title: '',
      link: '',
      query_params: '',
      auto_seq: '',
      contents: ''
    });

    document.getElementById('title').focus();
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked($event) {
    this.form.patchValue({
      seq: $event.data.seq,
      title: $event.data.title,
      link: $event.data.link,
      query_params: $event.data.query_params,
      auto_seq: $event.data.auto_seq ?? '',
      contents: $event.data.contents
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 검색 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search.searchText = '';
    this.getPushFormList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if ( event.key === 'Enter' ) {
      this.getPushFormList();
    }
  }

}
