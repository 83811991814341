  <div class="modal-header">
    <h4 class="modal-title fb mb0">환불 수정</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <form [formGroup]="form">
      <table class="table table-bordered table-small1 mb0">
      <caption class="none">회원정보</caption>
      <colgroup>
        <col style="width:15%;" />
        <col style="width:35%;" />
        <col style="width:15%;" />
        <col style="width:35%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="product_name">모델</label></th>
        <td><inputEx type="text" name="product_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
        <th scope="row"><label for="id">ID</label></th>
        <td><inputEx type="text" name="id" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="mem_name">주문자명</label></th>
        <td><inputEx type="text" name="mem_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="return_status">환불사유</label></th>
        <td><inputEx type="select" name="return_status" [formGroup]="form" [formErrors]="formErrors" [data]="returnList"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="write_date">등록일</label></th>
        <td><inputEx type="text" name="write_date" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx></td>
        <th scope="row"><label for="return_date">반납일</label></th>
        <td><inputEx type="date" name="return_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="consign_pay">정산배송비</label></th>
        <td><inputEx type="text" name="consign_pay" [formGroup]="form" [formErrors]="formErrors" (change)="onRefundChanged()"></inputEx></td>
        <th scope="row"><label for="get_consign_pay">수령배송비</label></th>
        <td><inputEx type="text" name="get_consign_pay" [formGroup]="form" [formErrors]="formErrors" placeholder="반품에 동봉된 배송비" (change)="onRefundChanged()"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="return_invoice">수령송장</label></th>
        <td><inputEx type="text" name="return_invoice" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="pay_method">환불방법</label></th>
        <td><inputEx type="select" name="pay_method" [formGroup]="form" [formErrors]="formErrors" [data]="payMethodList"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="return_qty">환불수량</label></th>
        <td><inputEx type="text" name="return_qty" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="return_amt">환불금액</label></th>
        <td><inputEx type="text" name="return_amt" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="ask_mem_no">환불요청담당</label></th>
        <td colspan="3">
          <inputEx type="text" name="ask_id" [formGroup]="form" [formErrors]="formErrors" [readonly]="form.value.process_status !== '0' && form.value.process_status !== '33' ? true : false"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label>환불요청결재</label></th>
        <td colspan="3">
          <div [ngClass]="{'input-group': form.value.process_status == '55' || form.value.process_status == '77'}">
            <div *ngIf="form.value.process_status == '55'">
              <inputEx type="text" name="first_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </div>
            <div *ngIf="form.value.process_status == '77'">
              <inputEx type="text" name="return_id" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </div>
            <div *ngIf="form.value.process_status == '99'">
              <inputEx type="text" name="manager_id" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
            </div>
            <div class="btn-group ml5" *ngIf="form.value.process_status == '55'">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.approve.value == '1'}" (click)="form.patchValue({approve: '1'})">지급승인</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.approve.value == '0'}" (click)="form.patchValue({approve: '0'})">지급보류</button>
            </div>
            <div class="btn-group ml5" *ngIf="form.value.process_status == '55'">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.diary.value == '1'}" (click)="form.patchValue({diary: '1'})">환불일지 업데이트</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.diary.value == '0'}" (click)="form.patchValue({diary: '0'})">NO</button>
            </div>
            <label class="f12 form-inline mb0 ml10" *ngIf="form.value.process_status == '77'">지급승인
            <div class="btn-group ml5" *ngIf="form.value.process_status == '77'">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_result.value == '1'}" (click)="form.patchValue({pay_result: '1'})">환불완료</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.pay_result.value == '0'}" (click)="form.patchValue({pay_result: '0'})">환불보류</button>
            </div>
            </label>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="memo">메모</label></th>
        <td colspan="3"><inputEx type="textarea" name="memo" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      </tbody>
      </table>

    </form>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
      <!-- <button type="button" class="btn btn-danger btn-small">삭제</button> -->
    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-primary btn-small" (click)="setRefundSave()">저장하기</button>
    </div>
  </div>
