<form name="form" [formGroup]="form" id="form">
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title bold">출장정보 입력</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
  <!-- Modal header -->

  <!-- Modal body -->
  <div class="modal-body">
    <section id="print-contents">

      <!-- 내용부분 //-->
      <div id="print-contents-body" class="pt20">
        <table class="list-table table table-bordered mt30 ng-scope">
        <colgroup>
          <col style="width:20%">
          <col style="width:30%">
          <col style="width:20%">
          <col style="width:30%">
        </colgroup>
        
        <tbody>
        <tr>
          <th class="active" scope="row">기안일자</th>
          <td colspan="3">
            <inputEx type="date" name="ymd" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th class="active" scope="row">연구용역명</th>
          <td colspan="3">
            <inputEx type="text" name="service_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th class="active" scope="row">건명</th>
          <td colspan="3">
            <inputEx type="text" name="subject_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th class="active" scope="row">금액</th>
          <td colspan="3">
            <inputEx type="text" name="total_amt" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th class="active" scope="row">출장자</th>
          <td colspan="3">
            <inputEx type="select2" name="mem_no" [formGroup]="form" [formErrors]="formErrors" [data]="employeeList" [small]="true" [options]="{multiple: true}" [onChanged]="employeeChangedFunc"></inputEx>
          </td>
        </tr>
        <tr>
          <th class="active" scope="row">출장시작일</th>
          <td class="position1008">
            <inputEx type="date" name="sdate" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
          <th class="active" scope="row">출장종료일</th>
          <td class="position1008">
            <inputEx type="date" name="edate" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th class="active" scope="row">장소</th>
          <td colspan="3">
            <inputEx type="text" name="place_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th class="active" scope="row">비용상세내역</th>
          <td colspan="3">
            <textarea
              formControlName="fee_Detail"
              class="form-control form-control-small"
              (change)="onChange($event)"
              [rows]="7"
              [cols]="80"
              [ngClass]="{formErrors:formErrors['fee_Detail']}"
            >
- 일비 :

- 식비 :

- 교통비 :

- 통행료 :

- 숙박비 :
          </textarea>
            <!-- <inputEx type="textarea" name="fee_Detail" [formGroup]="form" [formErrors]="formErrors">
            </inputEx> -->
          </td>
        </tr>
        </tbody>
        </table>            
      
      </div>
      
      <!-- 내용부분 //-->

    </section>


  </div>
  <!-- Modal body -->

  <!-- Modal footer -->
  <div class="modal-footer clearfix">
    <div class="fl w30p left">
    </div>
    <div class="fr w70p right">
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="submit()">입력완료</button>
    </div>
  </div>
  <!-- Modal footer -->
</form>

