import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbActiveModal, NgbDateStruct, NgbDatepickerI18n, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { AngularSplitModule } from 'angular-split';
import { NgxSummernoteModule } from 'ngx-summernote';
import { LSelect2Module } from 'ngx-select2';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartsModule } from 'ng2-charts';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPhotoshopModule } from 'ngx-color/photoshop'; // <color-photoshop></color-photoshop>

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

/* 공용 파이프 모듈 */
import { PipesModule } from '@app/pipes/pipes.module';
import { AutoFocusDirective } from '@app/directive/auto-focus.directive';
import { ImageTooltipDirective } from '@app/directive/tooltip.directive';

import { AuthGuard } from '@app/service/auth.guard';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { DaumAddressComponent } from '@app/components/daum-address/daum-address.component';
import { SendBtnComponent } from '@app/components/send-btn/send-btn.component';

import { HomeLayoutComponent } from '@page/layout/home.layout.component';
import { LoginComponent } from '@page/login/login.component';
import { DashboardComponent } from '@page/dashboard/dashboard.component';

import { InputExComponent } from '@components/input-ex/input-ex.component';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHeaderCheckboxComponent } from '@components/ag-grid-header-checkbox/ag-grid-header-checkbox.component';
import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { AgGridTooltipComponent } from '@components/ag-grid-tooltip/ag-grid-tooltip.component';
import { MemberFindComponent } from '@components/member-find/member-find.component';
import { BrandFindComponent } from '@components/brand-find/brand-find.component';
import { ProductFindComponent } from '@components/product-find/product-find.component';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { BarcodeInputComponent } from '@components/barcode-input/barcode-input.component';
import { PrintExcelComponent } from '@components/print-excel/print-excel.component';
import { GnbComponent } from '@page/layout/gnb/gnb.component';
import { HeaderComponent } from '@page/layout/header/header.component';
import { ExchangeRateComponent } from '@page/basic/exchange-rate/exchange-rate.component';
import { CompanyInfoComponent } from '@page/basic/company-info/company-info.component';
import { WarehouseComponent } from '@page/basic/warehouse/warehouse.component';
import { WarehouseDetailComponent } from '@page/basic/warehouse/warehouse-detail/warehouse-detail.component';
import { WarehouseLocationComponent } from '@page/basic/warehouse/location/location.component';
import { StoreComponent } from '@page/basic/store/store.component';
import { StoreDetailComponent } from '@page/basic/store/store-detail/store-detail.component';
import { CustomerComponent } from '@page/basic/customer/customer.component';
import { CustomerAddComponent } from '@page/basic/customer/customer-add/customer-add.component';
import { EtcaddComponent } from '@page/basic/etc-account/add/etcAccount-add.component';
import { PosManagementComponent } from '@page/system/pos-management/pos-management.component';
import { PosAuthgroupComponent } from '@page/system/pos-authgroup/pos-authgroup.component';
import { MenuComponent } from '@page/system/menu/menu.component';
import { CustomerTabMemberComponent } from '@page/basic/customer/customer-tab-member/customer-tab-member.component';
import { CustomerTabChargeComponent } from '@page/basic/customer/customer-tab-charge/customer-tab-charge.component';
import { CustomerTabMemoComponent } from '@page/basic/customer/customer-tab-memo/customer-tab-memo.component';
import { CustomerTabIngoodsComponent } from '@page/basic/customer/customer-tab-ingoods/customer-tab-ingoods.component';
import { CustomerTabInfoComponent } from '@page/basic/customer/customer-tab-info/customer-tab-info.component';
import { SupersaleComponent } from '@page/basic/supersale/supersale.component';
import { SupersaleDetailComponent } from '@page/basic/supersale/supersale-detail/supersale-detail.component';
import { SupersaleBrandComponent } from '@page/basic/supersale/supersale-brand/supersale-brand.component';
import { SupersaleCategoryComponent } from '@page/basic/supersale/supersale-category/supersale-category.component';
import { SupersaleProductComponent } from '@page/basic/supersale/supersale-product/supersale-product.component';
import { MemberListComponent } from '@page/member/member-list/member-list.component';
import { MemberInfoComponent } from '@page/member/member-info/member-info.component';
import { MemberAddressComponent } from '@page/member/member-info/member-address/member-address.component';
import { MemberAddressAddComponent } from '@page/member/member-info/member-address/member-address-add/member-address-add.component';
import { MemberOrderComponent } from '@page/member/member-info/member-order/member-order.component';
import { MemberQnaComponent } from '@page/member/member-info/member-qna/member-qna.component';
import { MemberRefundComponent } from '@page/member/member-info/member-refund/member-refund.component';
import { MemberMileageComponent } from '@page/member/member-info/member-mileage/member-mileage.component';
import { MemberMemoComponent } from '@page/member/member-info/member-memo/member-memo.component';
import { MemberBenefitComponent } from '@page/member/member-benefit/member-benefit.component';
import { MemberMileageStatisticComponent } from '@page/member/member-mileage-statistic/member-mileage-statistic.component';
import { MemberRefusalComponent } from '@page/member/member-refusal/member-refusal.component';
import { MemberRefusalAddComponent } from '@page/member/member-refusal/add/add.component';
import { MemberCompanyComponent } from '@page/member/member-company/member-company.component';
import { MemberSisComponent } from '@page/member/member-sis/member-sis.component';
import { MemberSisStatisticComponent } from '@page/member/member-sis-statistic/member-sis-statistic.component';
import { EventComponent } from '@page/basic/event/event.component';
import { EventStatisticComponent } from '@page/basic/event/event-statistic/event-statistic.component';
import { EventDayStatisticComponent } from '@page/basic/event/event-day-statistic/event-day-statistic.component';
import { EventInfoComponent } from '@page/basic/event/event-info/event-info.component';
import { EventConsonantComponent } from '@page/basic/event/event-consonant/event-consonant.component';
import { EventConsonantAddComponent } from '@page/basic/event/event-consonant/add/event-consonant-add.component';
import { ConfigComponent } from '@page/system/config/config.component';
import { CommonCodeComponent } from '@page/system/common/common.component';
import { CommonCodeAddComponent } from '@page/system/common/add/add.component';
import { CommonCodeGroupAddComponent } from '@page/system/common/group-add/group-add.component';
import { AuthGroupComponent } from '@page/system/auth-group/auth-group.component';
import { AuthGroupAddComponent } from '@page/system/auth-group/add/add.component';
import { GroupComponent } from '@page/system/group/group.component';
import { EmployeeComponent } from '@page/system/employee/employee.component';
import { PioneerTreeModule } from '@pioneer-code/pioneer-tree';
import { PosGroupAddComponent } from '@page/system/pos-authgroup/add/add.component';
import { PosAddComponent } from '@page/system/pos-management/add/add.component';
import { PosAuthAddComponent } from '@page/system/pos-management/auth-add/auth-add.component';

import { BoardComponent } from '@page/board/board/board.component';
import { BoardViewComponent } from '@page/board/board/view/view.component';
import { BoardAddComponent } from '@page/board/board/add/add.component';
import { BoardReplyComponent } from '@page/board/board/reply/reply.component';
import { BoardListComponent } from '@page/board/board/board-list/board-list.component';
import { BoardGalleryComponent } from '@page/board/board/board-gallery/board-gallery.component';
import { BoardAlbumComponent } from '@page/board/board/board-album/board-album.component';
import { BoardProductComponent } from '@app/page/board/board-product/board-product.component';
import { ProductReplyComponent } from '@app/page/board/board-product/reply/reply.component';

import { ManagementComponent } from '@page/board/management/management.component';
import { BoardInfoAddComponent } from '@page/board/management/add/add.component';
import { AddTableComponent } from '@page/board/management/add-table/add-table.component';
import { MemberSisStatisticTab1Component } from '@page/member/member-sis-statistic/member-sis-statistic-tab1/member-sis-statistic-tab1.component';
import { MemberSisStatisticTab2Component } from '@page/member/member-sis-statistic/member-sis-statistic-tab2/member-sis-statistic-tab2.component';
import { MemberSisStatisticTab3Component } from '@page/member/member-sis-statistic/member-sis-statistic-tab3/member-sis-statistic-tab3.component';
import { MemberSisStatisticTab4Component } from '@page/member/member-sis-statistic/member-sis-statistic-tab4/member-sis-statistic-tab4.component';
import { MemberCompanyApplicationComponent } from '@page/member/member-company-application/member-company-application.component';
import { MemberCompanyApplicationProcess1Component } from '@page/member/member-company-application/member-company-application-process1/member-company-application-process1.component';
import { MemberCompanyApplicationProcess2Component } from '@page/member/member-company-application/member-company-application-process2/member-company-application-process2.component';
import { MemberSisFrastatisticComponent } from '@page/member/member-sis-frastatistic/member-sis-frastatistic.component';

import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { StockOrderAddComponent } from '@page/stock/stock-order/stock-order-add/stock-order-add.component';
import { StockManagementComponent } from '@page/stock/stock-management/stock-management.component';
import { StockInManagementComponent } from '@page/stock/stock-in-management/stock-in-management.component';
import { StockOutManagementComponent } from '@page/stock/stock-out-management/stock-out-management.component';
import { StockWarehouseMoveComponent } from '@page/stock/stock-warehouse-move/stock-warehouse-move.component';
import { StockWarehouseStatisticComponent } from '@page/stock/stock-warehouse-statistic/stock-warehouse-statistic.component';
import { StockStatisticComponent } from '@page/stock/stock-statistic/stock-statistic.component';
import { StockInventoryCheckComponent } from '@page/stock/stock-inventory-check/stock-inventory-check.component';
import { StockInBaseComponent } from '@page/stock/stock-in-management/stock-in-base/stock-in-base.component';
import { StockInPurchaseComponent } from '@page/stock/stock-in-management/stock-in-purchase/stock-in-purchase.component';

import { StockImportComponent } from '@page/stock/stock-management/stock-import/stock-import.component';
import { StockLossComponent } from '@page/stock/stock-management/stock-loss/stock-loss.component';
import { StockMoveComponent } from '@page/stock/stock-management/stock-move/stock-move.component';
import { StockPurchaseComponent } from '@page/stock/stock-management/stock-purchase/stock-purchase.component';
import { ProductComponent } from '@page/product/product.component';
import { BrandBaseDiscountComponent } from '@app/page/basic/brand-base-discount/brand-base-discount.component';
import { CategoryComponent } from '@page/product/category/category.component';
import { ProductAddComponent } from '@page/product/product-add/product-add.component';
import { ProductMarginComponent } from '@page/product/product-margin/product-margin.component';
import { ProductMarginPriceComponent } from '@page/product/product-margin/product-margin-price/product-margin-price.component';
import { ProductMarginAddComponent } from '@page/product/product-margin/margin-price-add/margin-price-add.component';
import { ProductPropertyComponent } from '@page/product/product-property/product-property.component';
import { ProductPropertyAddComponent } from '@page/product/product-property/product-property-add/product-property-add.component';
import { ProductSortComponent } from '@page/product/product-sort/product-sort.component';
import { ProductSellGraphComponent } from '@page/product/product-sell-graph/product-sell-graph.component';
import { ProductSoldoutComponent } from '@page/product/product-soldout/product-soldout.component';
import { OrderComponent } from '@page/order/order.component';
import { OrderOpenmarketComponent } from '@page/order/order-openmarket/order-openmarket.component';
import { OrderOpenmarketUploadComponent } from '@page/order/order-openmarket/order-openmarket-upload/order-openmarket-upload.component';
import { OrderOpenmarketFindComponent } from '@page/order/order-openmarket/order-openmarket-find/order-openmarket-find.component';
import { OrderSabangnetComponent } from '@page/order/order-sabangnet/order-sabangnet.component';
import { OrderSabangnetUploadComponent } from '@page/order/order-sabangnet/order-sabangnet-upload/order-sabangnet-upload.component';
import { OrderSabangnetFindComponent } from '@page/order/order-sabangnet/order-sabangnet-find/order-sabangnet-find.component';
import { OrderSabangnetDeliveryUploadComponent } from '@page/order/order-sabangnet/order-sabangnet-delivery-upload/order-sabangnet-delivery-upload.component';
import { OrderStoreSalesComponent } from '@page/order/order-store-sales/order-store-sales.component';
import { OrderRefundComponent } from '@page/order/order-refund/order-refund.component';
import { OrderRefundEditComponent } from '@page/order/order-refund/order-refund-edit/order-refund-edit.component';
import { OrderOutofstockMemoComponent } from '@page/order/order-outofstock-memo/order-outofstock-memo.component';
import { OrderIniorderComponent } from '@page/order/order-iniorder/order-iniorder.component';
import { OrderIniorderAddComponent } from '@page/order/order-iniorder/order-iniorder-add/order-iniorder-add.component';
import { OrderPaymentComponent } from '@app/page/order/order-payment/order-payment.component';
import { OrderIniorderPriceComponent } from '@page/order/order-iniorder/order-iniorder-price/order-iniorder-price.component';
import { OrderDeliveryComponent } from '@page/order/order-delivery/order-delivery.component';
import { OrderReturnreqComponent } from '@page/order/order-returnreq/order-returnreq.component';
import { OrderReturnreqDetailComponent } from '@page/order/order-returnreq/order-returnreq-detail/order-returnreq-detail.component';
import { OrderReturnreqDetailImgComponent } from '@page/order/order-returnreq/order-returnreq-detail/order-returnreq-detail-img/order-returnreq-detail-img.component';
import { OrderChangeComponent } from '@page/order/order-history/order-change/order-change.component';
import { OrderOutDeliberyComponent } from '@page/order/order-history/order-out-delivery/order-out-delivery.component';
import { OrderStatementComponent } from '@page/order/order-detail/order-statement/order-statement.component';

import { StatisticDayComponent } from '@page/statistic/statistic-day/statistic-day.component';
import { HermesSalesComponent } from '@page/statistic/hermes-sales/hermes-sales.component';
import { BasicCompanyComponent } from '@page/basic/company-info/basic-company/basic-company.component';
import { BasicExchangeComponent } from '@page/basic/company-info/basic-exchange/basic-exchange.component';
import { EtcAccountComponent } from '@page/basic/etc-account/etc-account.component';
import { TreeViewComponent } from '@app/components/tree-view/tree-view.component';
import { SmsFormComponent } from '@page/basic/sms/sms-form/sms-form.component';
import { PushFormComponent } from '@page/basic/push/push-form/push-form.component';
import { EmailFormComponent } from '@page/basic/email/email-form/email-form.component';
import { EmailFormAddComponent } from '@page/basic/email/email-form-add/email-form-add.component';
import { MemberSmsComponent } from '@page/member/member-sms/member-sms.component';
import { MemberPushComponent } from '@page/member/member-push/member-push.component';
import { MemberEmailComponent } from '@page/member/member-email/member-email.component';
import { SupersaleAddComponent } from '@page/basic/supersale/supersale-add/supersale-add.component';
import { ProductColorManagementComponent } from '@page/product/product-color-management/product-color-management.component';
import { ProductSizeManagementComponent } from '@page/product/product-size-management/product-size-management.component';
import { ProductColorManagementAddComponent } from '@page/product/product-color-management/product-color-management-add/product-color-management-add.component';
import { ProductSizeManagementAddComponent } from '@page/product/product-size-management/product-size-management-add/product-size-management-add.component';
import { ProductBrandManagementComponent } from '@page/product/product-brand-management/product-brand-management.component';
import { ProductBrandManagementAddComponent } from '@page/product/product-brand-management/product-brand-management-add/product-brand-management-add.component';
import { WarehouseChargeAddComponent } from '@page/basic/warehouse/warehouse-charge-add/warehouse-charge-add.component';
import { StoreChargeAddComponent } from '@page/basic/store/store-charge-add/store-charge-add.component';
import { PositionComponent } from '@page/system/position/position.component';
import { DateTimePickerComponent } from '@app/components/date-time-picker/date-time-picker.component';
import { EventDetailComponent } from '@page/basic/event/event-detail/event-detail.component';


import { ModalSmsComponent } from '@components/modal-sms/modal-sms.component';
import { ModalPushComponent } from '@components/modal-push/modal-push.component';
import { ModalEmailComponent } from '@components/modal-email/modal-email.component';
import { MemberEmailDetailComponent } from '@page/member/member-email/member-email-detail/member-email-detail.component';
import { EventProductComponent } from '@page/basic/event/event-product/event-product.component';
import { MemberCompanyAddComponent } from '@page/member/member-company/member-company-add/member-company-add.component';
import { OrderDetailComponent } from '@page/order/order-detail/order-detail.component';
import { OrderPaymentAddComponent } from '@page/order/order-detail/order-payment-add/order-payment-add.component';
import { StatisticHomeComponent } from '@page/statistic/statistic-home/statistic-home.component';
import { StatisticProductComponent } from '@page/statistic/statistic-product/statistic-product.component';
import { StatisticBrandComponent } from '@page/statistic/statistic-brand/statistic-brand.component';
import { StatisticSalesCostComponent } from '@page/statistic/statistic-sales-cost/statistic-sales-cost.component';
import { StatisticSalesGoalComponent } from '@page/statistic/statistic-sales-goal/statistic-sales-goal.component';
import { StatisticSalesEditComponent } from '@page/statistic/statistic-sales-edit/statistic-sales-edit.component';
import { StatisticHomeDetailComponent } from '@page/statistic/statistic-home/statistic-home-detail/statistic-home-detail.component';
import { StatisticOpenmarketComponent } from '@page/statistic/statistic-openmarket/statistic-openmarket.component';

import { ModalFavoriteMenuComponent } from '@page/layout/modal-favorite-menu/modal-favorite-menu.component';
import { ModalFavoriteMenuGroupComponent } from '@page/layout/modal-favorite-menu-group/modal-favorite-menu-group.component';

import { ApprovalComponent } from '@page/approval/approval.component';
import { ApprovalListComponent } from '@page/approval/approval-list/approval-list.component';
import { ApprovalDetailComponent } from '@page/approval/approval-detail/approval-detail.component';
import { ApprovalCommentComponent } from '@page/approval/approval-detail/approval-comment/approval-comment.component';
import { ApprovalLineComponent } from '@page/approval/approval-line/approval-line.component';
import { ApprovalLineAddComponent } from '@page/approval/approval-line/approval-line-add/approval-line-add.component';
import { DocumentReceiveComponent } from '@page/approval/document-receive/document-receive.component';
import { DocumentReceiveAddComponent } from '@page/approval/document-receive/document-receive-add/document-receive-add.component';
import { DocumentExecuteComponent } from '@page/approval/document-execute/document-execute.component';
import { DocumentExecuteAddComponent } from '@page/approval/document-execute/document-execute-add/document-execute-add.component';
import { ApprovalTempComponent } from '@page/approval/approval-temp/approval-temp.component';
import { ApprovalBusinessComponent } from './page/approval/approval-business/approval-business.component';
import { ApprovalAnnualComponent } from './page/approval/approval-annual/approval-annual.component';
import { ApprovalNoteComponent } from './page/approval/approval-note/approval-note.component';
import { ApprovalOfflineComponent } from './page/approval/approval-offline/approval-offline.component';

import { WorkmanagementWorkstatusComponent } from '@page/workmanagement/workmanagement-workstatus/workmanagement-workstatus.component';
import { WorkmanagementUsevacationComponent } from '@page/workmanagement/workmanagement-usevacation/workmanagement-usevacation.component';
import { MemberSisFrastatisticTab1Component } from '@page/member/member-sis-frastatistic/member-sis-frastatistic-tab1/member-sis-frastatistic-tab1.component';
import { MemberSisFrastatisticTab2Component } from '@page/member/member-sis-frastatistic/member-sis-frastatistic-tab2/member-sis-frastatistic-tab2.component';
import { MemberSisFrastatisticTab3Component } from '@page/member/member-sis-frastatistic/member-sis-frastatistic-tab3/member-sis-frastatistic-tab3.component';
import { MemberSisFrastatisticTab4Component } from '@page/member/member-sis-frastatistic/member-sis-frastatistic-tab4/member-sis-frastatistic-tab4.component';
import { WorkmanagementUsevacationLatenessComponent } from '@page/workmanagement/workmanagement-usevacation/workmanagement-usevacation-lateness/workmanagement-usevacation-lateness.component';
import { WorkmanagementWorkarragementComponent } from '@page/workmanagement/workmanagement-workarragement/workmanagement-workarragement.component';
import { WorkmanagementWorkarragementAddComponent } from '@page/workmanagement/workmanagement-workarragement/workmanagement-workarragement-add/workmanagement-workarragement-add.component';
import { WorkarragementPrintComponent } from '@page/workmanagement/workmanagement-workarragement/workarragement-print/workarragement-print.component';
import { MemberCompanyStatisticComponent } from '@page/member/member-company-statistic/member-company-statistic.component';
import { MemberCompanyStatisticTab1Component } from '@page/member/member-company-statistic/member-company-statistic-tab1/member-company-statistic-tab1.component';
import { MemberCompanyStatisticTab2Component } from '@page/member/member-company-statistic/member-company-statistic-tab2/member-company-statistic-tab2.component';
import { MemberCompanyStatisticTab3Component } from '@page/member/member-company-statistic/member-company-statistic-tab3/member-company-statistic-tab3.component';
import { MemberCompanyStatisticTab4Component } from '@page/member/member-company-statistic/member-company-statistic-tab4/member-company-statistic-tab4.component';
import { BasicEngineComponent } from '@page/basic/company-info/basic-engine/basic-engine.component';
import { BasicSnsComponent } from '@page/basic/company-info/basic-sns/basic-sns.component';
import { BasicPaymentComponent } from '@page/basic/company-info/basic-payment/basic-payment.component';
import { BasicBankbookComponent } from '@page/basic/company-info/basic-bankbook/basic-bankbook.component';
import { BasicAccountComponent } from '@page/basic/company-info/basic-account/basic-account.component';
import { BasicAccountAddComponent } from '@page/basic/company-info/basic-account/basic-account-add/basic-account-add.component';
import { MatMenuTriggerComponent } from '@components/mat-menu-trigger/mat-menu-trigger.component';
import { MatTooltipComponent } from '@components/mat-tooltip/mat-tooltip.component';
import { ModifyMyinfoComponent } from '@components/modify-myinfo/modify-myinfo.component';
import { BasicAlarmmanagerComponent } from '@page/basic/company-info/basic-alarmmanager/basic-alarmmanager.component';
import { AlarmmanagerAddComponent } from '@page/basic/company-info/basic-alarmmanager/alarmmanager-add/alarmmanager-add.component';
import { MemberDormantComponent } from '@page/member/member-dormant/member-dormant.component';
import { BasicMemberComponent } from '@page/basic/company-info/basic-member/basic-member.component';
import { ProductBarcodeComponent } from '@page/product/product-barcode/product-barcode.component';
import { BasicDeliveryComponent } from '@page/basic/company-info/basic-delivery/basic-delivery.component';
import { StockOutOrderComponent } from '@page/stock/stock-out-management/stock-out-order/stock-out-order.component';
import { StockOutTodayComponent } from '@page/stock/stock-out-management/stock-out-today/stock-out-today.component';
import { StockOutDeliveryOrderComponent } from '@page/stock/stock-out-management/stock-out-delivery-order/stock-out-delivery-order.component';
import { PurchaseFindComponent } from '@components/purchase-find/purchase-find.component';
import { OrderStatusChangeComponent } from '@page/order/order-detail/order-status-change/order-status-change.component';
import { EventSupersaleProductComponent } from '@page/basic/event/event-supersale-product/event-supersale-product.component';
import { ExportExcelComponent } from '@components/export-excel/export-excel.component';
import { AccountBackComponent } from '@page/account/account-back/account-back.component';
import { AccountInfoComponent } from '@page/account/account-info/account-info.component';
import { AccountEnterComponent } from '@page/account/account-enter/account-enter.component';
import { ModalDeliveryComponent } from '@page/stock/stock-out-management/modal-delivery/modal-delivery.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BasicDeliverycompanyComponent } from '@page/basic/company-info/basic-deliverycompany/basic-deliverycompany.component';
import { ManagerMileageComponent } from '@page/member/member-info/member-mileage/manager-mileage/manager-mileage.component';
import { ProductSenaComponent } from '@page/product/product-sena/product-sena.component';
import { ProductSenaTrackingComponent } from '@page/product/product-sena-tracking/product-sena-tracking.component';
import { ProductSenaProcessComponent } from '@page/product/product-sena/product-sena-process/product-sena-process.component';
import { OrderOpenmarketDeliveryUploadComponent } from '@page/order/order-openmarket/order-openmarket-delivery-upload/order-openmarket-delivery-upload.component';
import { StatisticMonthComponent } from '@page/statistic/statistic-month/statistic-month.component';
import { SpecificationTransactionComponent } from '@page/stock/stock-order/specification-transaction/specification-transaction.component';
import { ModalPurchaseCompleteComponent } from '@page/stock/stock-order/modal-purchase-complete/modal-purchase-complete.component';
import { ModalPurchaseQtyComponent } from '@page/stock/stock-order/modal-purchase-qty/modal-purchase-qty.component';
import { ModalPurchaseQuickComponent } from '@page/stock/stock-order/modal-purchase-quick/modal-purchase-quick.component';
import { ViewUpdateComponent } from '@page/board/board/view/view-update/view-update.component';
import { ProductViewComponent } from '@page/board/board-product/view/view.component';
import { AccountOpenmarketComponent } from '@page/account/account-openmarket/account-openmarket.component';
import { ProductSalesStatusComponent } from '@page/product/product-sales-status/product-sales-status.component';
import { OrderCreditlistComponent } from '@page/order/order-creditlist/order-creditlist.component';
import { StatisticSalesComponent } from '@page/statistic/statistic-sales/statistic-sales.component';
import { ProductFindOpenmarketComponent } from '@components/product-find-openmarket/product-find-openmarket.component';
import { ProductRelationComponent } from '@page/product/product-relation/product-relation.component';
import { OrderPayComponent } from '@page/order/order-pay/order-pay.component';
import { StatisticDayDetailComponent } from '@page/statistic/statistic-day-detail/statistic-day-detail.component';
import { OrderMileageComponent } from '@page/order/order-mileage/order-mileage.component';
import { OrderCreditReplaceComponent } from '@page/order/order-credit-replace/order-credit-replace.component';
import { MemberJoinInquiryComponent } from '@page/member/member-join-inquiry/member-join-inquiry.component';
import { InventorySchedulePostAndPutComponent } from '@page/stock/stock-inventory-check/inventory-schedule-post-and-put/inventory-schedule-post-and-put.component';
import { BasicDeliveryAddComponent } from '@page/basic/company-info/basic-delivery/basic-delivery-add/basic-delivery-add.component';
import { SystemStatisticComponent } from '@page/system/system-statistic/system-statistic.component';
import { StatisticSimpleComponent } from '@page/statistic/statistic-simple/statistic-simple.component';
import { BikeEventComponent } from '@page/bike/bike-event/bike-event.component';
import { BikePushComponent } from '@page/bike/bike-push/bike-push.component';
import { BikeAdvertisementComponent } from '@page/bike/bike-advertisement/bike-advertisement.component';
import { BikeBrandComponent } from '@page/bike/bike-brand/bike-brand.component';
import { BikePayhelperComponent } from '@page/bike/bike-payhelper/bike-payhelper.component';
import { BikeSellComponent } from '@page/bike/bike-sell/bike-sell.component';
import { StatisticBikeAppReleaseComponent } from '@page/statistic/statistic-bike-app-release/statistic-bike-app-release.component';
import { StatisticBikeSellComponent } from '@page/statistic/statistic-bike-sell/statistic-bike-sell.component';
import { StatisticBikeOrderComponent } from '@page/statistic/statistic-bike-order/statistic-bike-order.component';
import { BikeSellDetailComponent } from '@page/bike/bike-sell-detail/bike-sell-detail.component';
import { BrandFindBikeComponent } from '@components/brand-find-bike/brand-find-bike.component';
import { StockOrderMemoComponent } from '@page/stock/stock-order/stock-order-memo/stock-order-memo.component';
import { BikePayhelperDetailComponent } from '@page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail.component';
import { NavComponent } from '@page/layout/nav/nav.component';
import { OrderReviewReportComponent } from '@page/order/order-review-report/order-review-report.component';
import { OrderReviewComponent } from '@page/order/order-review/order-review.component';
import { OrderRefund2Component } from './page/order/order-refund2/order-refund2.component';
import { OrderReviewTabInfoComponent } from './page/order/order-review/order-review-tab-info/order-review-tab-info.component';
import { OrderReviewTabHistoryComponent } from './page/order/order-review/order-review-tab-history/order-review-tab-history.component';
import { OrderDeliveryChargeAddComponent } from './page/order/order-detail/order-delivery-charge-add/order-delivery-charge-add.component';
import { ProductSoldoutManagementComponent } from './page/product/product-soldout-management/product-soldout-management.component';
import { ProductSalesStatusOrdinaryComponent } from './page/product/product-sales-status/product-sales-status-ordinary/product-sales-status-ordinary.component';
import { ProductSalesStatusChannelComponent } from './page/product/product-sales-status/product-sales-status-channel/product-sales-status-channel.component';
import { OrderAmtEditComponent } from './page/order/order-detail/order-amt-edit/order-amt-edit.component';
import { ProductMasterComponent } from './page/product/product-master/product-master.component';
import { BikeModelComponent } from './page/bike/bike-model/bike-model.component';
import { BikeModelAddComponent } from './page/bike/bike-model/bike-model-add/bike-model-add.component';
import { CouponComponent } from './page/basic/coupon/coupon.component';
import { CouponAddComponent } from './page/basic/coupon/coupon-add/coupon-add.component';
import { AccountCouponComponent } from './page/account/account-coupon/account-coupon.component';
import { AccountCouponAddComponent } from './page/account/account-coupon/account-coupon-add/account-coupon-add.component';
import { BikePayhelperDetailStatusComponent } from './page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail-status/bike-payhelper-detail-status.component';
import { BikePayhelperDetailCalcComponent } from './page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail-calc/bike-payhelper-detail-calc.component';
import { BikePayhelperDetailPrintComponent } from './page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail-print/bike-payhelper-detail-print.component';
import { EventCouponProductComponent } from './page/basic/event/event-coupon-product/event-coupon-product.component';
import { BikePayhelperDetailDateComponent } from './page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail-date/bike-payhelper-detail-date.component';
import { BikeDetailSuppliesComponent } from './page/bike/bike-detail-supplies/bike-detail-supplies.component';
import { ProductKeywordComponent } from './page/product/product-keyword/product-keyword.component';
import { ProductRecommendkeywordComponent } from './page/product/product-recommendkeyword/product-recommendkeyword.component';
import { ProductRecommendkeywordAddComponent } from './page/product/product-recommendkeyword/product-recommendkeyword-add/product-recommendkeyword-add.component';
import { SenaRegistrationComponent } from './page/product/sena-registration/sena-registration.component';
import { MemberSocietyComponent } from './page/member/society/member-society/member-society.component';
import { MemberSocietyHistoryComponent } from './page/member/society/member-society-history/member-society-history.component';
import { MemberSocietyStatisticComponent } from './page/member/society/member-society-statistic/member-society-statistic.component';
import { MemberSocietyAddComponent } from './page/member/society/member-society/member-society-add/member-society-add.component';
import { MemberSocietyHistoryAddComponent } from './page/member/society/member-society-history/member-society-history-add/member-society-history-add.component';
import { BamaCouponComponent } from './page/basic/bama-coupon/bama-coupon.component';
import { BamaCouponAddComponent } from './page/basic/bama-coupon/bama-coupon-add/bama-coupon-add.component';
import { MemberCouponComponent } from './page/member/member-coupon/member-coupon.component';
import { MemberCouponListComponent } from './page/member/member-info/member-coupon-list/member-coupon-list.component';
import { ManagerCouponComponent } from './page/member/member-info/member-coupon-list/manager-coupon/manager-coupon.component';
import { MemberCompanyConsultationComponent } from './page/member/member-company/member-company-consultation/member-company-consultation.component';

/* 달력 컴포넌트 한글*/
const I18N_VALUES = {
  ko: {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

/* 공지 기능 설정 */
const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'right',
			distance: 12
		},
		vertical: {
			position: 'top',
			distance: 12,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: false,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 200
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}


@NgModule({
  declarations: [
    AppComponent,

    HomeLayoutComponent,

    LoginComponent,
    DashboardComponent,
    GnbComponent,
    HeaderComponent,
    ExchangeRateComponent,
    CompanyInfoComponent,
    WarehouseComponent,
    WarehouseDetailComponent,
    WarehouseLocationComponent,
    StoreComponent,
    StoreDetailComponent,
    CustomerComponent,
    CustomerAddComponent,
    EtcaddComponent,

    AutoFocusDirective,
    ImageTooltipDirective,

    DaumAddressComponent,
    SendBtnComponent,

    InputExComponent,
    AgGridImageComponent,
    AgGridHtmlComponent,
    AgGridSaveComponent,
    AgGridExcelComponent,
    AgGridHeaderCheckboxComponent,
    AgGridButtonComponent,
    AgGridTooltipComponent,
    PosManagementComponent,
    PosAuthgroupComponent,
    CustomerTabMemberComponent,
    CustomerTabChargeComponent,
    CustomerTabMemoComponent,
    CustomerTabIngoodsComponent,
    CustomerTabInfoComponent,
    SupersaleComponent,
    SupersaleBrandComponent,
    SupersaleCategoryComponent,
    SupersaleProductComponent,
    MemberListComponent,
    MemberInfoComponent,
    MemberAddressComponent,
    MemberAddressAddComponent,
    MemberOrderComponent,
    MemberQnaComponent,
    MemberRefundComponent,
    MemberMileageComponent,
    MemberMemoComponent,
    MemberBenefitComponent,
    MemberMileageStatisticComponent,
    MemberRefusalComponent,
    MemberCompanyComponent,
    MemberSisComponent,
    MemberSisStatisticComponent,
    EventComponent,
    EventStatisticComponent,
    EventDayStatisticComponent,
    EventInfoComponent,
    EventConsonantComponent,
    EventConsonantAddComponent,    
    ConfigComponent,
    AuthGroupComponent,
    AuthGroupAddComponent,
    GroupComponent,
    EmployeeComponent,
    CommonCodeComponent,
    CommonCodeAddComponent,
    CommonCodeGroupAddComponent,
    BoardComponent,
    BoardViewComponent,
    BoardAddComponent,
    BoardReplyComponent,
    BoardListComponent,
    BoardGalleryComponent,
    BoardAlbumComponent,
    BoardProductComponent,
    ProductReplyComponent,
    ManagementComponent,
    BoardInfoAddComponent,
    AddTableComponent,
    MemberFindComponent,
    BrandFindComponent,
    ProductFindComponent,
    ProductFindPropertyComponent,
    MemberRefusalAddComponent,
    PosGroupAddComponent,
    PosAddComponent,
    PosAuthAddComponent,
    MemberSisStatisticTab1Component,
    MemberSisStatisticTab2Component,
    MemberSisStatisticTab3Component,
    MemberSisStatisticTab4Component,
    StockOrderComponent,
    StockManagementComponent,
    StockOutManagementComponent,
    StockWarehouseMoveComponent,
    StockWarehouseStatisticComponent,
    StockStatisticComponent,
    StockOrderAddComponent,
    StockImportComponent,
    StockLossComponent,
    StockMoveComponent,
    StockPurchaseComponent,
    ProductComponent,
    BrandBaseDiscountComponent,
    CategoryComponent,
    ProductAddComponent,
    ProductMarginComponent,
    ProductMarginPriceComponent,
    ProductMarginAddComponent,
    ProductPropertyComponent,
    ProductPropertyAddComponent,
    ProductSortComponent,
    ProductSellGraphComponent,
    ProductSoldoutComponent,
    OrderComponent,
    OrderOpenmarketComponent,
    OrderOpenmarketUploadComponent,
    OrderOpenmarketFindComponent,
    OrderSabangnetComponent,
    OrderSabangnetUploadComponent,
    OrderSabangnetFindComponent,
    OrderSabangnetDeliveryUploadComponent,
    OrderStoreSalesComponent,
    OrderRefundComponent,
    OrderRefundEditComponent,
    OrderOutofstockMemoComponent,
    StatisticDayComponent,
    HermesSalesComponent,
    BasicCompanyComponent,
    BasicExchangeComponent,
    TreeViewComponent,
    SmsFormComponent,
    PushFormComponent,
    EmailFormComponent,
    EmailFormAddComponent,
    MemberSmsComponent,
    MemberPushComponent,
    MemberEmailComponent,
    SupersaleAddComponent,
    ProductColorManagementComponent,
    ProductSizeManagementComponent,
    ProductColorManagementAddComponent,
    ProductSizeManagementAddComponent,
    WarehouseChargeAddComponent,
    SupersaleDetailComponent,
    StoreChargeAddComponent,
    PositionComponent,
    DateTimePickerComponent,
    EventDetailComponent,
    ModalSmsComponent,
    ModalPushComponent,
    ModalEmailComponent,
    MemberEmailDetailComponent,
    EventProductComponent,
    MemberCompanyAddComponent,
    OrderDetailComponent,
    OrderStatementComponent,
    StatisticHomeComponent,
    StatisticProductComponent,
    StatisticBrandComponent,
    StatisticSalesCostComponent,
    StatisticSalesGoalComponent,
    StatisticSalesEditComponent,
    StatisticHomeDetailComponent,
    StatisticOpenmarketComponent,
    ModalFavoriteMenuComponent,
    ModalFavoriteMenuGroupComponent,
    BasicDeliveryAddComponent,
    ApprovalComponent,
    ApprovalListComponent,
    ApprovalDetailComponent,
    ApprovalCommentComponent,
    ApprovalLineComponent,
    ApprovalLineAddComponent,
    DocumentReceiveComponent,
    DocumentReceiveAddComponent,
    DocumentExecuteComponent,
    DocumentExecuteAddComponent,
    ApprovalTempComponent,
    ApprovalBusinessComponent,
    ApprovalAnnualComponent,
    ApprovalNoteComponent,
    ApprovalOfflineComponent,
    ProductBrandManagementComponent,
    ProductBrandManagementAddComponent,
    WorkmanagementWorkstatusComponent,
    WorkmanagementUsevacationComponent,
    MemberCompanyApplicationComponent,
    MemberCompanyApplicationProcess1Component,
    MemberCompanyApplicationProcess2Component,
    MemberSisFrastatisticComponent,
    MemberSisFrastatisticTab1Component,
    MemberSisFrastatisticTab2Component,
    MemberSisFrastatisticTab3Component,
    MemberSisFrastatisticTab4Component,
    WorkmanagementUsevacationLatenessComponent,
    WorkmanagementWorkarragementComponent,
    WorkmanagementWorkarragementAddComponent,
    OrderIniorderComponent,
    OrderIniorderAddComponent,
    OrderPaymentComponent,
    OrderIniorderPriceComponent,
    WorkarragementPrintComponent,
    MemberCompanyStatisticComponent,
    MemberCompanyStatisticTab1Component,
    MemberCompanyStatisticTab2Component,
    MemberCompanyStatisticTab3Component,
    MemberCompanyStatisticTab4Component,
    OrderDeliveryComponent,
    OrderReturnreqComponent,
    OrderChangeComponent,
    OrderOutDeliberyComponent,
    OrderReturnreqDetailComponent,
    OrderReturnreqDetailImgComponent,
    BasicEngineComponent,
    BasicSnsComponent,
    BasicPaymentComponent,
    BasicBankbookComponent,
    BarcodeInputComponent,
    BasicAccountComponent,
    BasicAccountAddComponent,
    OrderPaymentAddComponent,
    MenuComponent,
    EtcAccountComponent,
    MatMenuTriggerComponent,
    ModifyMyinfoComponent,
    BasicAlarmmanagerComponent,
    AlarmmanagerAddComponent,
    MemberDormantComponent,
    BasicMemberComponent,
    ProductBarcodeComponent,
    BasicDeliveryComponent,
    StockInManagementComponent,
    StockInventoryCheckComponent,
    MatTooltipComponent,
    StockInBaseComponent,
    StockOutOrderComponent,
    StockOutTodayComponent,
    StockOutDeliveryOrderComponent,
    StockInPurchaseComponent,
    PrintExcelComponent,
    PurchaseFindComponent,
    OrderStatusChangeComponent,
    EventSupersaleProductComponent,
    ExportExcelComponent,
    AccountBackComponent,
    AccountInfoComponent,
    AccountEnterComponent,
    ModalDeliveryComponent,
    BasicDeliverycompanyComponent,
    ManagerMileageComponent,
    ProductSenaComponent,
    ProductSenaTrackingComponent,
    ProductSenaProcessComponent,
    OrderOpenmarketDeliveryUploadComponent,
    StatisticMonthComponent,
    SpecificationTransactionComponent,
    ModalPurchaseCompleteComponent,
    ModalPurchaseQtyComponent,
    ModalPurchaseQuickComponent,
    ViewUpdateComponent,
    ProductViewComponent,
    AccountOpenmarketComponent,
    ProductSalesStatusComponent,
    OrderCreditlistComponent,
    StatisticSalesComponent,
    ProductFindOpenmarketComponent,
    ProductRelationComponent,
    OrderPayComponent,
    StatisticDayDetailComponent,
    OrderMileageComponent,
    OrderCreditReplaceComponent,
    MemberJoinInquiryComponent,
    InventorySchedulePostAndPutComponent,
    SystemStatisticComponent,
    StatisticSimpleComponent,
    BikeEventComponent,
    BikePushComponent,
    BikeAdvertisementComponent,
    BikeBrandComponent,
    BikePayhelperComponent,
    BikeSellComponent,
    StatisticBikeAppReleaseComponent,
    StatisticBikeSellComponent,
    StatisticBikeOrderComponent,
    BikeSellDetailComponent,
    BrandFindBikeComponent,
    StockOrderMemoComponent,
    BikePayhelperDetailComponent,
    NavComponent,
    OrderReviewReportComponent,
    OrderReviewComponent,
    OrderRefund2Component,
    OrderReviewTabInfoComponent,
    OrderReviewTabHistoryComponent,
    OrderDeliveryChargeAddComponent,
    ProductSoldoutManagementComponent,
    ProductSalesStatusOrdinaryComponent,
    ProductSalesStatusChannelComponent,
    OrderAmtEditComponent,
    ProductMasterComponent,
    BikeModelComponent,
    BikeModelAddComponent,
    CouponComponent,
    CouponAddComponent,
    AccountCouponComponent,
    AccountCouponAddComponent,
    BikePayhelperDetailStatusComponent,
    BikePayhelperDetailCalcComponent,
    BikePayhelperDetailPrintComponent,
    EventCouponProductComponent,
    BikePayhelperDetailDateComponent,
    BikeDetailSuppliesComponent,
    ProductKeywordComponent,
    ProductRecommendkeywordComponent,
    ProductRecommendkeywordAddComponent,
    SenaRegistrationComponent,
    MemberSocietyComponent,
    MemberSocietyHistoryComponent,
    MemberSocietyStatisticComponent,
    MemberSocietyAddComponent,
    MemberSocietyHistoryAddComponent,
    BamaCouponComponent,
    BamaCouponAddComponent,
    MemberCouponComponent,
    MemberCouponListComponent,
    ManagerCouponComponent,
    MemberCompanyConsultationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    ChartsModule,
    AngularSplitModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right'
    }),
    AgGridModule.withComponents([
      AgGridImageComponent,
      AgGridHtmlComponent,
      AgGridHeaderCheckboxComponent,
      AgGridButtonComponent,
      AgGridTooltipComponent,
    ]),
    PipesModule,
    LSelect2Module,
    HttpClientModule,
    DragDropModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatDividerModule,
    MatTreeModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    PioneerTreeModule,
    ColorPhotoshopModule,
    NotifierModule.withConfig(customNotifierOptions),
    NgxBarcodeModule
  ],
  providers: [
    RestfulService,
    UtilService,
    Globals,
    AuthGuard,

    AgGridImageComponent,
    AgGridHtmlComponent,
    AgGridSaveComponent,
    AgGridExcelComponent,

    NgbActiveModal,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: MAT_DATE_LOCALE, useValue: 'ko-KR'},
    NgbDatepickerConfig,
    StockOrderComponent,
    StockOutTodayComponent,
    StockOutDeliveryOrderComponent,
    ExportExcelComponent,
    StatisticDayComponent,
    AccountInfoComponent,
  ],
  entryComponents: [
    ModalSmsComponent,
    ModalEmailComponent,
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
