<section class="h100 pt5">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="30" minSize="25" maxSize="40">
      <h4 class="fb mt5 mb6">알림관리</h4>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="msgList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="70" minSize="60" maxSize="75">
      <div class="mb5">
        <div class="fl w60p">
          <h4 class="fl fb mb0 pt3">담당자 정보</h4>

          <form [formGroup]="form">
            <div class="fl form-inline ml10">
              <span class="mr5">- 미확인 알림 표시제한(일)</span>
              <inputEx type="number" size="4" name="alarm_over_day" [formGroup]="form" [formErrors]="formErrors" maxlength="3"></inputEx>
              <span class="ml5">일</span>
              <button type="submit" class="btn btn-primary btn-small ml5" (click)="submit()">설정</button>
            </div>
          </form>

          <div class="cb"></div>
        </div>
        <div class="fr w40p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApiCharge" gridId="a89490d03ec84a33938bebb3aed92d7f" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-danger btn-small ml5" (click)="setMessageChargeDelete()">담당자삭제</button>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="setMessageChargeAll()">전체담당자추가</button>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="setAdd()">담당자추가</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height: calc(100% - 36px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsCharge"
          [defaultColDef]="defaultColDef"
          [rowData]="chargeList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelectionCharge"

          (gridReady)="onGridReadyCharge($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>