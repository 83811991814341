/*******************************************************************************
  설  명 : 재고관리 - 발주관리
  작성일 : 2020-09-05
  작성자 : 송영석
  접속URL : /stock/order

  수정일 : 2021.05.14
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AgGridImageComponent } from '@app/components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@app/components/ag-grid-excomponent/ag-grid-excomponent';

import { StockOrder } from '@app/service/stockOrder.service';
import { AuthService } from '@app/service/auth.service';
import { OrderService } from '@app/service/order.service';

import { SpecificationTransactionComponent } from './specification-transaction/specification-transaction.component';
import { ModalPurchaseCompleteComponent } from './modal-purchase-complete/modal-purchase-complete.component';
import { ModalPurchaseQtyComponent } from './modal-purchase-qty/modal-purchase-qty.component';
import { ModalPurchaseQuickComponent } from './modal-purchase-quick/modal-purchase-quick.component';
import { StockOrderMemoComponent } from '@page/stock/stock-order/stock-order-memo/stock-order-memo.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-stock-order',
  templateUrl: './stock-order.component.html',
  styleUrls: ['./stock-order.component.scss']
})

export class StockOrderComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  // stockOrderList: any = [];
  // stockOrderDetailList: any = [];
  // stockOrderProperyList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;

  gridApiProperty: any;
  gridColumnApiProperty: any;
  columnDefsProperty: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;
  rowClassRules: any;

  user: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  firstColumn = {
    headerName: '',
    field: '',
    width: 50,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    sortable: false,
    filter: false,
    resizable: false,
    flex: 0
  }
  valueGetter_num = params => parseInt(String(params.data[params.colDef.field] || 0)).toLocaleString();
  components = {
    numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
    datePicker: this.agGridExComponent.getDatePicker(),
    selectCellEditor: this.agGridExComponent.getSelectCellEditor()
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private stockOrder: StockOrder,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private agGridExComponent: AgGridExComponent,
    private modalService: NgbModal,
    private orderService: OrderService,
  ) {

    // 발주서 grid
    this.columnDefs = [
      this.firstColumn,
      { headerName: '발주번호', field: 'purchase_seq', cellClass: 'cp', width: 80, flex: 0 },
      { headerName: '진행상태', field: 'progress_status_name', width: 80, cellClass: 'cp center' },
      { headerName: '제목', field: 'title', width: 220, cellClass: 'cp' },
      { headerName: '거래처', field: 'comname', width: 200, cellClass: 'cp' },
      { headerName: '발주구분', field: 'gbn', width: 80, cellClass: 'cp center',
        valueGetter: params => {
          const _D = params.data.gbn;
          return _D == 1 ? '매입' : _D == 2 ? '직발' : _D
        }
      }, // 매입, 직발
      { headerName: '발주일자', field: 'purchase_date', width: 100, cellClass: 'cp center' },
      { headerName: '발주금액', field: 'purchase_amt', width: 90, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '발주수량', field: 'purchase_qty', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '입고수량', field: 'complete_inQty_purchase', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '결재', field: 'approval_seq', width: 80, cellClass: 'cp center',
        valueGetter: params => {
          const _D = params.data.approval_seq;
          return _D == 0 ? '미결재' : _D == 1 ? '결재상신' : _D == 2 ? '결재완료' : _D
        }
      }, // 미결재, 결재상신, 결재완료
      { headerName: '비고', field: 'memo', width: 150, cellClass: 'cp' },
      { headerName: '등록자', field: 'writer', width: 80, cellClass: 'cp center' },
      { headerName: '등록일시', field: 'write_date', width: 150, cellClass: 'cp center' },
      // { headerName: '수정자', field: 'changer', width: 80, cellClass: 'cp center' },
      // { headerName: '수정일시', field: 'change_date', width: 120, cellClass: 'cp center' },
    ];

    // 발주상세
    this.columnDefsDetail = [
      this.firstColumn,
      { headerName: '주문번호', field: 'order_seq', cellClass: 'cp', width: 90, flex: 0 },
      { headerName: '상품코드', field: 'product_seq', cellClass: 'cp', width: 90, flex: 0 },
      { headerName: '카테고리', field: 'category_name', width: 100, cellClass: 'cp' },
      { headerName: '상품명', field: 'product_name', width: 250, cellClass: 'cp' },
      // { headerName: '거래처', field: 'comname', width: 150, cellClass: 'cp' },
      { headerName: '발주수량', field: 'qty', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '금액', field: 'amt', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '입고수량', field: 'complete_inQty_product', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
    ];

    // 색상사이즈
    this.columnDefsProperty = [
      this.firstColumn,
      { headerName: '상품코드', field: 'product_property_seq', cellClass: 'cp', width: 80, flex: 0 },
      { headerName: '색상', field: 'display_color_name', width: 90, cellClass: 'cp' },
      { headerName: '사이즈', field: 'display_size_name', width: 90, cellClass: 'cp' },
      { headerName: '입고창고', field: 'warehouse_name', width: 80, cellClass: 'cp' },
      { headerName: '입고위치', field: 'location_cd', width: 90, cellClass: 'cp' },
      { headerName: '대구재고', field: 'stock', width: 75, cellClass: 'cp',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '서울재고', field: 'sStock', width: 75, cellClass: 'cp',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '발주수량', field: 'purchase_cnt', width: 75, cellClass: 'cp',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      // { headerName: '발주수량', field: 'outOrderQty', width: 100, cellClass: 'cp right ag-cell-edit', editable: true, cellEditor: "numericCellEditor", cellRenderer: 'agGridHtmlComponent',
      //   valueGetter : (params) => this.valueGetter_num(params) },

      { headerName: '입고수량', field: 'complete_inQty', width: 75, cellClass: 'cp right',
        valueGetter: (params) => this.valueGetter_num(params)
      },
      { headerName: '입고일자', field: 'complete_inDate', width: 90, cellClass: 'cp center' },
    ];

    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      flex: 0
    };
    this.rowSelection = 'multiple';
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.rowClassRules = {
      // 'status1': params => params.data.progress_status_name == '미입고',
      // 'status2': params => params.data.progress_status_name == '입고완료'
      // 'status3':
      // 'status4':
      'status8': params => params.data.purchase_date
      // 'gridBorderBottom':
    }
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(_this): void {
    _this.form = this.formBuilder.group({

      stockOrderList: [[]],
      stockOrderDetailList: [[]],
      stockOrderProperyList: [[]],

      gbn: [0],
      in_warehouse: [0],
      approval: [0],
      dealer: [''],
      date_btn : [30],
      s_date: [this.dateFormat( new Date(new Date().getTime() - 2592000000) )],
      e_date: [this.dateFormat(new Date())],
      search : ['']
    });

    _this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( _this.form, _this.formErrors );
    });
  }
  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = () => 50;

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(e => {
      this.user = e['mem_no'];
    }).unsubscribe();

    this.buildForm(this);
    this.getStockOrder(this);
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params, _this) {
    _this.gridApi = params.api;
    _this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyDetail(params, _this) {
    _this.gridApiDetail = params.api;
    _this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReadyProperty(params, _this) {
    _this.gridApiProperty = params.api;
    _this.gridColumnApiProperty = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 발주등록
  *******************************************************************************/
  addStockOrder() {
    this.router.navigate(
      ['/stock/order/add'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 발주목록 가져오기
  *******************************************************************************/
  getStockOrder(_this) {
    const _FV = _this.form.value;
    this.stockOrder.getStockOrder({
      gbn: _FV['gbn'],
      in_warehouse: _FV['in_warehouse'],
      approval: _FV['approval'],
      dealer: _FV['dealer'],
      s_date : _FV['s_date'] || this.dateFormat(new Date()),
      e_date : _FV['e_date'] || this.dateFormat(new Date()),
      search : _FV['search']
    }).then(response => {
      if(response.ResultCode) {

        _this.form.patchValue({
          stockOrderList : this.cookingData(response.data),
          stockOrderDetailList: [],
          stockOrderProperyList: []
        });

        if(!response.data.length) this.toastrService.warning('데이터가 없습니다.');
      } else this.toastrService.error('데이터 불러오기 실패.', '');
    });
  }

  /*******************************************************************************
    설  명 : 발주목록 가공
  *******************************************************************************/
  cookingData(data) {
    const cooking = [];
    data.forEach(e => {

      // 색상사이즈
      const _property = {
        property_parent : e['property_parent'], // 상위노드
        product_property_seq : e['product_property_seq'], // 상품 property 코드
        purchase_property_seq : e['purchase_property_seq'], // 발주서 property 코드
        color_name : e['color_name'], // 색상
        display_color_name : e['display_color_name'], // 표시색상
        size_name : e['size_name'], // 사이즈
        display_size_name : e['display_size_name'], // 표시사이즈
        warehouse_name : e['warehouse_name'], // 입고창고
        location_cd : e['location_cd'], // 입고위치
        warehouse_seq : e['warehouse_seq'], // 입고창고 seq
        warehouse_location_seq : e['warehouse_location_seq'], // 입고위치 seq
        stock : parseInt(e['stock']) || 0, // 대구재고수량
        sStock : parseInt(e['sStock']) || 0, // 서울재고수량
        purchase_cnt : parseInt(e['purchase_cnt']) || 0, // 발주수량
        complete_inQty : parseInt(e['complete_inQty']) || 0, // 입고수량
        complete_inDate : e['complete_inDate'], // 입고일자
        purchase_product_seq : e['purchase_product_seq'], // 발주서 product 코드
        parent_status : e['parent_status'], // 부모 품절여부
        status : e['status'], // 자식 품절여부
        customer_seq : e['customer_seq'], // 거래처 코드
      };

      // 발주상세
      const _product = {
        product_parent : e['product_parent'], // 상위노드
        order_seq : e['order_seq'], // 주문번호
        product_seq : e['product_seq'], // 상품 product 코드
        purchase_product_seq : e['purchase_product_seq'], // 발주서 product 코드
        category_name : e['category_name'], // 카테고리
        product_name : e['product_name'], // 상품명
        qty : parseInt(e['qty']), // 발주수량
        unit_amt : parseInt(e['unit_amt']), // 개당금액
        amt : parseInt(e['amt']), // 금액
        complete_inQty_product : 0, // 입고수량
        r_name : e['r_name'], // 주문자명
        r_hp : e['r_hp'], // 주문자연락처
        r_address : e['r_address'], // 주소
        r_address_detail : e['r_address_detail'], // 상세주소
        parent_status : e['parent_status'], // 부모 품절여부
        status : e['status'], // 자식 품절여부

        _property : [_property]
      };

      const _index = cooking.findIndex(_e => _e['purchase_seq'] === e['purchase_seq']);
      if(_index < 0)
        cooking.push({
          purchase_seq : e['purchase_seq'],
          // approval_seq : e['approval_seq'], // 결제
          gbn : e['gbn'], // 발주구분
          title : e['title'], // 제목
          customer_seq : e['customer_seq'], // 거래처 순번
          comname : e['comname'], // 거래처
          purchase_date : e['purchase_date'], // 발주일자
          purchase_qty : 0, // 발주수량
          complete_inQty_purchase : 0, // 입고수량
          purchase_amt : 0, // 발주금액
          memo : e['memo'], // 비고
          writer : e['writer'], // 등록자
          write_date : e['write_date'], // 등록일시
          changer : e['changer'], // 수정자
          change_date : e['change_date'], // 수정일시
          // 결재상신일 : '', // 결재상신일
          // approvaler : e['approvaler'], // 결재상신자
          progress_status_name : e['progress_status_name'],

          _product : [_product]
        });
      else {
        const __index = cooking[_index]['_product'].findIndex(_e => _e['purchase_product_seq'] === e['purchase_product_seq']);
        __index < 0 ? cooking[_index]['_product'].push(_product) : cooking[_index]['_product'][__index]['_property'].push(_property);
      }
    });
    cooking.forEach(e => {
      e['_product'].forEach(_e => { _e['complete_inQty_product'] = _e['_property'].reduce((a,c) => a + c['complete_inQty'], 0) });
      e['complete_inQty_purchase'] = e['_product'].reduce((a,c) => a + c['complete_inQty_product'], 0);
      e['purchase_qty'] = e['_product'].reduce((a,c) => a + c['qty'], 0);
      e['purchase_amt'] = e['_product'].reduce((a,c) => a + c['amt'], 0);
    });

    return cooking;
  }

  /*******************************************************************************
    설  명 : 발주리스트 row 선택
  *******************************************************************************/
  onSelectionChanged_purchase($event) {
    // this.rowSelectPurchase = [];
    // this.gridApi.getSelectedRows().forEach(e => {
    //   this.rowSelectPurchase.push({seq : e['seq']});
    // });
  }
  /*******************************************************************************
    설  명 : 발주리스트 클릭시 상세내역 보여주기
  *******************************************************************************/
  onRowClicked_purchase($event, _this) {
    _this.form.patchValue({
      stockOrderDetailList : $event.data._product,
      stockOrderProperyList : []
    });
  }
  /*******************************************************************************
    설  명 : 발주상세 클릭시 색상사이즈 보여주기
  *******************************************************************************/
  onCellClicked_product($event, _this) {
    _this.form.patchValue({
      stockOrderProperyList : $event.data._property
    });

    if( $event.colDef.field === 'order_seq' ) {
      let seq = $event.data.order_seq;
      if( seq !== '0' ) {
        const url = '/order/detail?seq=' + seq;
        window.open("about:blank").location.href = url;
      }
    }
  }
  /*******************************************************************************
    설  명 : 발주리스트 row 삭제
  *******************************************************************************/
  delete_purchase() {
    if(this.gridApi.getSelectedRows().length > 1) {this.toastrService.warning('하나의 발주서만 선택하시기 바랍니다.'); return}
    
    if( confirm('선택하신 발주서를 삭제하시겠습니까?') ) {
      this.authService.getLoginInfo.subscribe(e => {
        this.stockOrder.deleteStockOrder({
          deleteList : this.gridApi.getSelectedRows().map(e => ({purchase_seq : e['purchase_seq']})),
          writer : e['mem_no']
        }).then(response => {
          if(response.ResultCode) {
            this.toastrService.success('데이터 삭제 성공.', '');
            this.softReset()
          } else this.toastrService.error('데이터 삭제 실패.', '');
        });
      }).unsubscribe();
    }
  }
  /*******************************************************************************
    설  명 : 발주상세리스트 row 삭제
  *******************************************************************************/
  delete_purchase_order() {
    let rows = this.gridApiDetail.getSelectedRows();
    if( rows.length < 1 ) {
      this.toastrService.error('삭제할 내역을 선택하시기 바랍니다.', '');
    } else {
      this.authService.getLoginInfo.subscribe(e => {
        this.stockOrder.deletePurchaseOrder({
          deleteList : rows,
          writer : e['mem_no']
        }).then(response => {
          if(response.ResultCode) {
            this.toastrService.success('데이터 삭제 성공.', '');
            this.softReset()
          } else this.toastrService.error('데이터 삭제 실패.', '');
        });
      }).unsubscribe();
    }
  }


  /*******************************************************************************
    설  명 : 발주상세리스트 row 삭제
  *******************************************************************************/
    delete_purchase_order_property() {
      let rows = this.gridApiProperty.getSelectedRows();
      if( rows.length < 1 ) {
        this.toastrService.error('삭제할 내역을 선택하시기 바랍니다.', '');
      } else {
        this.authService.getLoginInfo.subscribe(e => {
          this.stockOrder.deletePurchaseOrderProperty({
            deleteList : rows,
            writer : e['mem_no']
          }).then(response => {
            if(response.ResultCode) {
              this.toastrService.success('데이터 삭제 성공.', '');
              this.softReset()
            } else this.toastrService.error('데이터 삭제 실패.', '');
          });
        }).unsubscribe();
      }
    }  

  /*******************************************************************************
    설  명 : 주문 메모 등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderMemo() {
    const rows = this.gridApiDetail.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('메모를 등록할 내역을 선택하시기 바랍니다.');
    } else if( rows.length > 1 ) {
      this.toastrService.error('하나의 내역만 선택하시기 바랍니다.');
    } else {
      if( rows[0].order_seq == '' || rows[0].order_seq == '0' ) {
        this.toastrService.error('직발건만 메모 등록이 가능합니다.');
      } else {
        const modalRef = this.modalService.open(StockOrderMemoComponent, optionsLG);

        modalRef.componentInstance.rows = rows[0];

        modalRef.result.then((result) => {
        }, (reason) => {
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 날짜 format
  *******************************************************************************/
  dateFormat(date) {
    const _2 = number => (`0${String(number)}`).slice(-2);
    try {date.getFullYear()} catch {return null}
    return [
      date.getFullYear(),
      _2(date.getMonth() + 1),
      _2(date.getDate())
    ].join('-');
  }

  /*******************************************************************************
    설  명 : 검색조건 초기화
  *******************************************************************************/
  reset(_this) {
    _this.ngOnInit();
  }
  softReset() {
    this.getStockOrder(this)
  }

  /*******************************************************************************
    설  명 : 검색조건 버튼 클릭
  *******************************************************************************/
  btnClick(which, value, _this) {
    _this.form.patchValue({[which] : value});
    this.getStockOrder(_this);
  }
  /*******************************************************************************
    설  명 : 날짜선택 바로가기 버튼 클릭
  *******************************************************************************/
  quickDate(quickTime, _this) {
    const _S = quickTime < 0 ? '' : this.dateFormat(new Date(new Date().getTime() - 86400000 * quickTime));
    _this.form.patchValue({
      s_date : _S,
      e_date : this.dateFormat(new Date())
    });
    _this.btnClick('date_btn', quickTime, _this);
  }
  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(which, value, _this) {
    const _V = [];
    for(const key in value) _V.push(value[key]);
    _this.btnClick( which, this.dateFormat( new Date( _V.join('-') ) ), _this );
  }

  /*******************************************************************************
    설  명 : 사용자 회원번호 가져오기
  *******************************************************************************/
  getUser() {
    let user: any;
    this.authService.getLoginInfo.subscribe(e => {
      user = e['mem_no'];

      return user;
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 오늘 날짜
  *******************************************************************************/
  _2D = any => `0${any}`.slice(-2)
  getToday = () => {
    const today = new Date();
    return [today.getFullYear(), this._2D(today.getMonth() + 1), this._2D(today.getDate())].join('-')
  }

  /*******************************************************************************
    설  명 : 거래명세표 출력
  *******************************************************************************/
  printSpec() {
    let _data = this.gridApi.getSelectedRows();
    if(!_data.length) {this.toastrService.warning('발주서를 선택하세요.'); return}
    if(_data.length > 1) {this.toastrService.warning('하나의 발주서만 선택하시기 바랍니다.'); return}

    _data = _data.map(e => ({
      ...e,
      _product : e['_product'].map(_e => ({
        ..._e,
        tax : Math.floor(_e['amt'] / 11),
        supply : _e['amt'] - Math.floor(_e['amt'] / 11)
      })),
      tax : Math.floor(e['purchase_amt'] / 11),
      supply : e['purchase_amt'] - Math.floor(e['purchase_amt'] / 11)
    }))[0]

    const modalRef = this.modalService.open(SpecificationTransactionComponent, optionsXG);
    modalRef.componentInstance.data = _data

    modalRef.result.then((result) => {
      if( result ) {
      } else {
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
  *******************************************************************************/
  keypress( $event, _this = this ) {
    if( $event.key == 'Enter' ) _this.softReset()
  }

  searchInput($event) {
    this.form.patchValue({
      search : $event.target.value
    })
  }

  /*******************************************************************************
    설  명 : 발주서 통합
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPurchaseCombine() {
    const rows = this.gridApi.getSelectedRows();
    if( rows.length <= 1 ) {
      this.toastrService.error('통합할 내역을 선택하시기 바랍니다.');
    } else {
      const params = [];

      let check: boolean;
      let check_customer: boolean;
      let prev_customer: any = '';
      let check_gbn: boolean;
      rows.forEach(item => {
        if( item.purchase_date ) check = true;
        if( prev_customer != '' && prev_customer != item.customer_seq ) check_customer = true;
        if( item.gbn !== '1' ) check_gbn = true;

        params.push(item.purchase_seq);

        prev_customer = item.customer_seq;
      });

      if( check === true ) {
        this.toastrService.error('발주일자가 없는 상태에서만 통합이 가능합니다.');
      } else if( check_customer === true ) {
        this.toastrService.error('같은 거래처 내역만 통합이 가능합니다.');
      } else if( check_gbn === true ) {
        this.toastrService.error('매입끼리만 통합이 가능합니다.');
      } else {
        if ( confirm('선택하신 내역을 통합하시겠습니까?\r가장 최근 발주를 기준으로 통합됩니다.') ) {
          this.stockOrder.setPurchaseCombine( JSON.stringify(params) ).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success(response.ResultMessage);

              this.getStockOrder(this);
            } else {
              this.toastrService.error(response.ResultMessage);
            }
          });
        }
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 발주처리 (발주일자등록)
  *******************************************************************************/
  updatePurchaseComplete() {
    const rows = this.gridApi.getSelectedRows();
    if(!rows.length) {this.toastrService.warning('발주서를 선택하세요.'); return}
    else if(rows.length > 1) {this.toastrService.warning('하나의 발주서만 선택하시기 바랍니다.'); return}
    else if(rows.filter(e => e['purchase_date']).length && !confirm('발주일자를 수정하시겠습니까?') ) return

    const modalRef = this.modalService.open(ModalPurchaseCompleteComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'lg',
      centered: true,
      windowClass: 'modal-fadeInDown'
    })
    modalRef.componentInstance.data = rows
    modalRef.componentInstance.getToday = this.getToday
    modalRef.componentInstance.dateFormat = this.dateFormat

    modalRef.result.then((result) => {
      if( result ) {
        this.softReset()
      }
    }, (reason) => {
    })
  }

  /*******************************************************************************
    설  명 : 수량변경
  *******************************************************************************/
  setPurchaseQty() {
    const rows = this.gridApiProperty.getSelectedRows()
    if(!rows.length) {this.toastrService.warning('수량을 변경할 항목을 선택하세요.'); return}
    else if(rows.length > 1) {this.toastrService.warning('하나의 항목만 선택하세요.'); return}
    else if(this.gridApi.getSelectedRows()[0]['gbn'] != 1) {this.toastrService.warning('매입 발주만 수량을 변경할 수 있습니다.'); return}

    const modalRef = this.modalService.open(ModalPurchaseQtyComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'lg',
      centered: true,
      windowClass: 'modal-fadeInDown'
    })
    modalRef.componentInstance.data = rows
    modalRef.componentInstance.getToday = this.getToday
    modalRef.componentInstance.getUser = this.user
    modalRef.componentInstance.dateFormat = this.dateFormat

    modalRef.result.then((result) => {
      if( result ) {
        this.softReset()
      }
    }, (reason) => {
    })
  }

  /*******************************************************************************
    설  명 : 빠른발주
  *******************************************************************************/
  quickPurchase() {
    const modalRef = this.modalService.open(ModalPurchaseQuickComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'lg',
      centered: true,
      windowClass: 'modal-fadeInDown'
    })

    modalRef.componentInstance.getToday = this.getToday
    modalRef.componentInstance.getUser = this.user
    modalRef.componentInstance.dateFormat = this.dateFormat

    modalRef.result.then((result) => {
      if( result ) {
        this.softReset()
      }
    }, (reason) => {
    })
  }

  /*******************************************************************************
    설  명 : 발주서 출력
  *******************************************************************************/
  printPurchase() {
    const rows = this.gridApi.getSelectedRows()
    if(!rows.length) {this.toastrService.warning('발주서를 선택하세요.'); return}
    if(rows.length > 1) {this.toastrService.warning('하나의 발주서만 선택하시기 바랍니다.'); return}

    const row = rows[0]
    if(row['title'].split(' ')[0] !== '주문번호') {this.toastrService.error('주문번호가 없습니다.'); return}

    const exportTable = [`
    <html xmlns:x="urn:schemas-microsoft-com:office:excel">
    <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
    <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    <x:Name>Test Sheet</x:Name>
    <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>
    </x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>
    
    <table width="660" border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
      <tr align="center"> 
        <td colspan=7 height="40" style="font-size:14pt;"><strong> 바이크마트 발주내역서 </strong></td>
      </tr>
      <tr>
        <td colspan=3 height="20">업체명 : ${row.comname}</td><td colspan=4 height="20">발주일시 : ${new Date().toLocaleString()} </td>
      </tr>
      <tr>
        <td colspan=7 height="20">바이크마트 담당자 : 신우철 010-4028-2550 </td>
      </tr>
    </table>
    <table>
      <tr>
        <td>
        <br><br>
      </td>
      </tr>
    </table>
    <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
      <tr align="center" bgcolor="#CCCCCC"> 
        <td width="40" height="30">번호</td>
        <td width="200" height="30">제품명</td>
        <td width="60"><font color="#333333" size="2">수량</font></td>
        <td width="360" colspan="4"><font color="#333333" size="2">배송정보</font></td>
      </tr>
    `]

      let index = 0
      let totalQty = 0
      row['_product'].forEach(e => {
        e['_property'].forEach(_e => {
          totalQty += _e.purchase_cnt
          exportTable.push(`
          <tr>
            <td align="center" height="22">&nbsp;<font size="2">${++index}</font></td>
            <td align="left" height="22">&nbsp;<font size="2">${e.product_name} <br> 색상 : ${_e.display_color_name || ''} <br> 사이즈 : ${_e.display_size_name || ''}</font></td>	
            <td align="center">&nbsp;<font size="2">${_e.purchase_cnt}</font></td>
            <td align="left" colspan="4"><font size="2">${e.r_name} <br> ${e.r_hp} <br> ${e.r_address} ${e.r_address_detail}</font></td>
          </tr>
          `)
        })
      })
      exportTable.push(`
        <tr> 
          <td align="center" height="22">&nbsp;합계</td>
          <td align="center">&nbsp;</td>
          <td align="center">&nbsp;<font size="2">${totalQty}</font></td>
          <td align="right" colspan="4">&nbsp;</td>
        </tr>
        <tr align="left"> 
          <td height="20" colspan="7"></td>
        </tr>
        <tr> 
          <td align="left" height="100" colspan="7">
            <font size="2">
            &nbsp; * 택배 발송 후 송장번호는 FAX. 053-289-3354 또는 bikemart@hanmail.net으로 회신 부탁드립니다. 
            <br> 
            &nbsp; 감사합니다. 오늘 하루도 좋은 날 되세요.^^
            </font>
          </td>
        </tr>

      </table>
      </body>
      </html>
      `)

      const fileName = `${row.comname}_직발발주서내역_${new Date().toLocaleString()}.xls`
      const blob = new Blob([exportTable.join('')], {
        type: "application/csv;charset=utf-8;"
      });
      const elem = document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = fileName;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);

  }
  
  /*******************************************************************************
    설  명 : 엑셀 출력 컴포넌트
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }

}
