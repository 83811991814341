
<section class="contents-top">
  <div class="fl path">
    <ul>
      <li><a routerLink="/dashboard">홈</a></li>
      <li *ngIf="(currMenu$ | async)['parentNode'] != null">{{(currMenu$ | async)['parentNode']['menu_name']}}</li>
      <li>{{(currMenu$ | async)['menu_name']}}</li>
    </ul>
  </div>
  <div class="fr right">
    <!-- <span class="board-instructions">지시사항</span>
    <span class="board-instructions-title"></span>
    <span class="board-instructions-date"></span> -->
  </div>
  <div class="cb"></div>
</section>