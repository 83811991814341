<section id="member-qna">
  <div class="mb5">
    <div class="fl w70p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" class="form-control form-control-small ml5" placeholder="제목,내용 검색" />

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small active">전체</button>
        <button type="button" class="btn btn-default btn-small">완료</button>
        <button type="button" class="btn btn-default btn-small">진행중</button>
      </div>

      <input type="text" readonly="readonly" [(ngModel)]="search.sdate" size="13" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, true)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" readonly="readonly" [(ngModel)]="search.edate" size="13" class="form-control form-control-small calendar-input m15" placeholder="검색 종료일" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, false)">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>
    </div>
    <div class="fr w30p right">
      <ng-container *ngIf="dormant===false">
        <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="793b977653ae4615a53ce71025029531" [btnGroup]="1"></btn-ag-grid-save>
        <button type="button" class="btn btn-danger btn-small ml5">문의삭제</button>
        <button type="button" class="btn btn-primary btn-small ml5">문의등록</button>
      </ng-container>
    </div>
    <div class="cb"></div>
  </div>

  <div class="mt5" style="height:calc(100% - 37px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="memberQnaList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"

      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
      >
    </ag-grid-angular>
  </div>
</section>