<ng-container *ngIf="modal==true">
  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">회원검색</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>
</ng-container>

<div [ngClass]="{'modal-body': modal}">
  <section id="customer-member-info">
    <div class="mt5">
      <div class="fl w50p">
        <h4 class="fb mt5 mb6">거래처정보</h4>
      </div>
      <div class="fr w50p right">
        <button type="button" class="btn btn-primary btn-small" (click)="setCustomerInfoSave()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>

    <form [formGroup]="form">

      <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>

      <table class="table table-bordered table-small1 mb0">
      <caption class="none">거래처정보</caption>
      <colgroup>
        <col style="width:15%;" />
        <col style="width:35%;" />
        <col style="width:15%;" />
        <col style="width:35%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="nation_gbn"><i class="material-icons cred f11 lh20">star</i> 국내외구분</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.nation_gbn.value=='D'}" (click)="form.get('nation_gbn').setValue('D')">국내</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.nation_gbn.value=='O'}" (click)="form.get('nation_gbn').setValue('O')">국외</button>
          </div>
          <inputEx type="hidden" name="nation_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="customer_id">SCM 아이디</label></th>
        <td><inputEx name="customer_id" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" [addOn]="addMemberFunc" addOnText="회원검색"></inputEx></td>
      </tr>
      <tr>
        <td><inputEx type="hidden" name="password" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="customer_type">거래형태</label></th>
        <td colspan="3">
          <div class="btn-group">
            <button *ngFor="let item of dealTypeList" type="button" class="btn btn-default btn-small" [ngClass]="{active:form.controls.customer_type.value==item.common_code}" (click)="form.get('customer_type').setValue(item.common_code)">{{item.common_name}}</button>
          </div>
          <inputEx type="hidden" name="customer_type" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="comname"><i class="material-icons cred f11 lh20">star</i> 회사명</label></th>
        <td><inputEx type="text" name="comname" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="product_gbn"><i class="material-icons cred f11 lh20">star</i> 매입상품구분</label></th>
        <td>
          <div class="btn-group">
            <button *ngFor="let item of productGbnList" type="button" class="btn btn-default btn-small" [ngClass]="{active:form.controls.product_gbn.value==item.common_code}" (click)="form.get('product_gbn').setValue(item.common_code)">{{item.common_name}}</button>
          </div>
          <inputEx type="hidden" name="product_gbn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="brand">브랜드</label></th>
        <td colspan="3">
          <inputEx type="select2" name="brand" [formGroup]="form" [formErrors]="formErrors" [data]="brandList" [small]="true" [options]="{multiple: true}" [onChanged]="onChangedFunc"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="ceoname"><i class="material-icons cred f11 lh20">star</i> 대표</label></th>
        <td><inputEx type="text" name="ceoname" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="ownerhp">대표자전화</label></th>
        <td><inputEx type="text" name="ownerhp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="worker">업체담당자</label></th>
        <td><inputEx type="text" name="worker" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="workerhp">담당자전화</label></th>
        <td><inputEx type="text" name="workerhp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="tel">거래처전화</label></th>
        <td><inputEx type="text" name="tel" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="fax">거래처팩스</label></th>
        <td><inputEx type="text" name="fax" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="zipcode">우편번호</label></th>
        <td>
          <div class="input-group">
            <inputEx name="zipcode" class="input_zipcode" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
            <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
          </div>
        </td>
        <th scope="row"><label for="address">주소</label></th>
        <td><inputEx type="text" name="address" [formGroup]="form" [formErrors]="formErrors" readonly="true"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="address_detail">상세주소</label></th>
        <td colspan="3"><inputEx type="text" name="address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="busi_num">사업자번호</label></th>
        <td><inputEx type="text" name="busi_num" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="order_sms">주문접수문자</label></th>
        <td>
          <mat-checkbox class="pl10" indeterminate="false" labelPosition="after" formControlName="order_sms">
            주문접수시 오후에 문자를 보냄
          </mat-checkbox>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="bank">거래은행</label></th>
        <td><inputEx type="text" name="bank" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="account_holder">계좌주</label></th>
        <td><inputEx type="text" name="account_holder" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
       <th scope="row"><label for="bank_account">계좌번호</label></th>
        <td><inputEx type="text" name="bank_account" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="get_page">페이지 수집</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.get_page.value=='1'}" (click)="form.get('get_page').setValue('1')">수집함</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.get_page.value=='0'}" (click)="form.get('get_page').setValue('0')">수집안함</button>
          </div>
          <inputEx type="hidden" name="get_page" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="homepage">홈페이지</label></th>
        <td><inputEx type="text" name="homepage" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="use_yn">사용여부</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='Y'}" (click)="form.get('use_yn').setValue('Y')">사용</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='N'}" (click)="form.get('use_yn').setValue('N')">미사용</button>
          </div>
          <inputEx type="hidden" name="use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="descr">부가적설명</label></th>
        <td colspan="3"><textarea id="summernote" #summernote [ngxSummernote]="summernoteConfig" formControlName="descr"></textarea></td>
      </tr>
 <!--
     <tr>
        <th scope="row"><label for="jungsan_date">정산일자</label></th>
        <td><inputEx type="text" name="jungsan_date" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="margin">기본마진</label></th>
        <td><inputEx type="text" name="margin" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="">할인공급가</label></th>
        <td colspan="3">
          <inputEx type="text" name="event_discount" [formGroup]="form" [formErrors]="formErrors" placeholder="예) 3+1=0|supersale=0"></inputEx>
        </td>
      </tr>
     -->
      <tr>
        <th scope="row"><label for="key_index">검색어</label></th>
        <td colspan="3"><inputEx type="text" name="key_index" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      </tbody>
      </table>

      <div class="mt5">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">계약사항</h4>
        </div>
        <div class="fr w50p right">
        </div>
        <div class="cb"></div>
      </div>

      <table class="table table-bordered table-small1 mb0">
      <caption class="none">거래처정보</caption>
      <colgroup>
        <col style="width:15%;" />
        <col style="width:35%;" />
        <col style="width:15%;" />
        <col style="width:35%;" />
      </colgroup>

      <tbody>
      <tr>
        <th scope="row"><label for="contact_date">계약일자</label></th>
        <td><inputEx type="date" name="contact_date" [formGroup]="form" [formErrors]="formErrors" placeholder="YYYY-MM-DD"></inputEx></td>
        <th scope="row"><label for="contact_emp">본사담당자</label></th>
        <td><inputEx type="text" name="contact_emp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="contact_margin_rate">기본마진율</label></th>
        <td><inputEx type="text" name="contact_margin_rate" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="product_upload">상품등록</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.product_upload.value=='1'}" (click)="form.get('product_upload').setValue('1')">당사등록</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.product_upload.value=='2'}" (click)="form.get('product_upload').setValue('2')">업체등록</button>
          </div>
          <inputEx type="hidden" name="product_upload" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="consign_type">배송비부담</label></th>
        <td>
          <div class="btn-group">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.consign_type.value=='1'}" (click)="form.get('consign_type').setValue('1')">당사부담</button>
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.consign_type.value=='0'}" (click)="form.get('consign_type').setValue('0')">업체부담</button>
          </div>
          <inputEx type="hidden" name="consign_type" [formGroup]="form" [formErrors]="formErrors"></inputEx>
        </td>
        <th scope="row"><label for="consign_com">배송업체</label></th>
        <td><inputEx type="select" name="consign_com" [formGroup]="form" [formErrors]="formErrors" [data]="deliveryList" (change)="changeDelivery()"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="consign_fee">배송비</label></th>
        <td><inputEx type="text" name="consign_fee" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
        <th scope="row"><label for="consign_limit_amt">배송비한도</label></th>
        <td><inputEx type="text" name="consign_limit_amt" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="contact_exchange_cond">교환조건</label></th>
        <td colspan="3"><inputEx type="text" name="contact_exchange_cond" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      <tr>
        <th scope="row"><label for="contact_return_cond">반품조건</label></th>
        <td colspan="3"><inputEx type="text" name="contact_return_cond" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
      </tr>
      </tbody>
      </table>

    </form>

  </section>
</div>
