<section class="mt10" style="height:745px;">
  <form [formGroup]="form">
    <div class="fl">
      <div class="mb5 form-inline">
        <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
          <i class="material-icons-outlined">refresh</i>
        </button>
        <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="retrieve()">
          <i class="material-icons-outlined">search</i>
        </button>
        <select class="form-control form-control-small ml5">
          <option value="">협력점 검색</option>
        </select>
        <input type="text" class="form-control form-control-small ml5" [(ngModel)]="retrieveOptions.partnerName" (keyup.enter)="retrieve()" placeholder="협력점명 검색" />
      </div>
    </div>
    <div class="fr form-inline">
      <label for="year">년도</label>
      <inputEx type="select" name="year" class="ml5" [formGroup]="form" [formErrors]="formErrors" [data]="yearData" (change)="changeYear($event)"></inputEx>
      <button type="submit" class="btn btn-primary btn-small ml5">검색</button>
    </div>
    <div class="cb"></div>
  </form>

  <ag-grid-angular #grid class="ag-theme-balham" style="width: 100%;height:calc(100% - 35px);" [gridOptions]="gridOptions"></ag-grid-angular>
</section>
