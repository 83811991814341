import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import * as moment from 'moment';
import { ApprovalService } from '@app/service/approval.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass:'modal-admin'
};

@Component({
  selector: 'app-approval-annual',
  templateUrl: './approval-annual.component.html',
  styleUrls: ['./approval-annual.component.scss']
})
export class ApprovalAnnualComponent implements OnInit {
  public type = '10';

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      type: ['', [] ],
      half_day: ['1', [] ],
      stime: ['', [] ],
      etime: ['', [] ],
      sdate: ['', [] ],
      edate: ['', [] ],
      sdateTime: ['', [] ],
      edateTime: ['', [] ],
      dept_name: ['', [] ],
      position: ['', [] ],
      name: ['', [] ],
      username: ['', [] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private approvalService: ApprovalService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.getMemberApprovalInfo();
  }

  onClicked(type) {
    this.type = type
  }

  getMemberApprovalInfo() {
    this.approvalService.getMemberApprovalInfo().then(response => {
      if( response.ResultCode ) {
        this.form.patchValue({
          name: response.data.name,
          dept_name: response.data.dept_name,
          username: response.data.username,
          position: response.data.position
        })
        // this.member = response.data;
      }
    })
  }


  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {

    let today = moment().format('YYYY-MM-DD');

    this.form.patchValue({
      type: this.type,
      sdate: moment(today).add(1,'days').format('YYYY-MM-DD'),
      edate: moment(today).add(1,'days').format('YYYY-MM-DD'),
      sdateTime: moment(today).add(1,'days').format('YYYY-MM-DD'),
      edateTime: moment(today).add(1,'days').format('YYYY-MM-DD')
    })


    let stime = this.form.value.stime.replace(':','');

    if( this.form.value.type == '20' ) {
      if( this.form.value.half_day == '1' ) {
        this.form.patchValue({
          sdateTime: this.form.value.sdate + ' ' + '09:00',
          edateTime: this.form.value.edate + ' ' + '13:00',
          stime: '09:00',
          etime: '13:00'
        })
      } else if (this.form.value.half_day == '2' ) {
        this.form.patchValue({
          sdateTime: this.form.value.sdate + ' ' + '13:00',
          edateTime: this.form.value.edate + ' ' + '18:00',
          stime: '13:00 ',
          etime: '18:00'
        })
      }
    } else if (this.form.value.type == '80' ) {
      if( this.form.value.stime != '' ) {
        this.form.patchValue({
          etime: parseInt(stime) + 200
        })
      } 

      this.form.value.stime = this.form.value.stime.replace(/([0-9]{2})([0-9]+)/,"$1:$2");
      this.form.value.etime = this.form.value.etime.toString().replace(/([0-9]{2})([0-9]+)/,"$1:$2");
      
      this.form.patchValue({
        sdateTime: this.form.value.sdate + ' ' + this.form.value.stime,
        edateTime: this.form.value.edate + ' ' + this.form.value.etime,
        stime: this.form.value.stime,
        etime: this.form.value.etime
      })
    }

    this.activeModal.close( this.form.value );
  }
}
