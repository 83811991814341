/*******************************************************************************
  설  명 : 게시판 내용 보기
  작성일 : 2019-10-10
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { BoardService } from '@app/service/board.service';
import { ViewUpdateComponent } from '@page/board/board/view/view-update/view-update.component';

import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};
@Component({
  selector: 'app-board-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class BoardViewComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public boardData: any = {};
  public memberInfo: any = {};
  public replyList: any = [];

  public form: FormGroup;
  public formErrors = {
    contents: ''
  };

  public params: any = {
    type: 'list',
    board_id: 'notice',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: '',
    year: ''
  };

  public baseURL = config.baseUrl;

  public is_popup_preview: boolean = false;

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      board_seq: [ '0', [Validators.required] ],
      board_id: [ 'notice', [Validators.required] ],
      board_index: [ '', [Validators.required] ],
      email: [ '', [Validators.required] ],
      contents: [ '', [Validators.required] ],
      user_id: [ '', [Validators.required] ],
      user_name: [ '', [Validators.required] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService,
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private utilService: UtilService
  ) {
    this.buildForm();

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    this.form.patchValue({
      email: this.memberInfo.email,
      user_id: this.memberInfo.id,
      user_name: this.memberInfo.name
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.board_seq = ( typeof params.board_seq == 'undefined' || params.board_seq == '' ) ? '' : params.board_seq;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;
      this.params.year = ( typeof params.year == 'undefined' || params.year == '' ) ? '' : params.year;

      if( this.params.board_seq == '' ) {
        this.toastrService.error( '게시물이 존재하지 않습니다.', '게시판' );

        this.router.navigate(
        ['/board/list'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

        return false;
      }

      this.getBoardInfo( this.params.board_id );
    }).unsubscribe();

    this.authReplyCheck();
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 게시판 아이디
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo( board_id ) {
    this.boardService.getBoardInfoId( board_id ).then( response => {
      this.boardInfo = response.data;
      this.getBoardView( board_id, this.params.board_seq );
    });
  }

  /*******************************************************************************
    설  명 : 게시글 정보 가져오기
    입력값 : 게시판 아이디, 게시판 코드
    리턴값 : 없음
  *******************************************************************************/
  getBoardView( board_id, board_seq ) {
    let hitCookie = this.cookieService.get( board_id + board_seq );

    if( hitCookie == '' ) {
      this.cookieService.set( board_id + board_seq, 'true' );
      this.params.viewCheck = 1;
    } else {
      this.params.viewCheck = 0;
    }

    this.boardService.getBoardView( board_id, board_seq, this.params.viewCheck ).then( response => {
      if( response.ResultCode ) {
        this.boardData = response.data;
        this.boardData.popup_height = parseInt( this.boardData.popup_height );
        this.boardData.upload = [];
        this.boardData.upload = response.upload;
        this.getComment();

        this.form.patchValue({
          board_seq: this.boardData.board_seq,
          board_index: this.boardData.board_index
        });

        // 그룹 표시
        if( this.boardData.group_id == '전체' || this.boardData.group_id == '' ) {
        } else {
          this.boardData.subject = '[' + this.boardData.group_id + '] ' + this.boardData.subject;
        }

        // new 표시
        if( this.boardData.new_check_date <= this.boardInfo.new_date ) {
          this.boardData.subject = this.boardData.subject;
        }

      } else {
        this.toastrService.error( response.ResultMessage, '게시글 조회' );

        this.router.navigate(
        ['/board/list'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  list() {
    this.params.type = 'list';

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 삭제 가능 여부 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authCheck() {
    // 관리자 권한일 경우 삭제 가능
    if( this.memberInfo.level >= 80 ) {
      return true;
    }

    // 작성자의 경우
    if( this.memberInfo.mem_no == this.boardData.writer ) return true;

    return false;
  }

  /*******************************************************************************
    설  명 : 답변 가능 여부 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authReplyCheck() {
    // 관리자 권한일 경우 삭제 가능
    if( this.memberInfo.level >= 80 ) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 게시글 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("게시글을 삭제하시겠습니까?") ) {
      this.boardService.deleteBoard( this.boardInfo.board_id, this.params.board_seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '게시글 삭제' );

          this.router.navigate(
          ['/board/list'],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.params,
            queryParamsHandling: '', // remove to replace all query params by provided
          });

        } else {
          this.toastrService.error( response.ResultMessage, '게시글 삭제' );
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 글 저장/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  write() {
    this.router.navigate(
    ['/board/add'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 글 답변 쓰기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  reply() {
    this.router.navigate(
    ['/board/reply'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 팝업 미리 보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  popup_preview() {
    if( this.boardData.layer_popup == true ) {
      this.is_popup_preview = true;

    } else {
      let url = 'http://www.thefirstpro.co.kr/api/popup/popup.php?board_id=' + this.boardData.board_id + '&board_seq=' + this.boardData.board_seq;

      window.open(url, '', 'resizable=no, toolbar=no, scrollbars=no, menubar=no, directories=no, location=no, width='+this.boardData.popup_width+', height='+(this.boardData.popup_height+60)+', left='+this.boardData.screen_x+', top=' + this.boardData.screen_y );

    }
  }

  /*******************************************************************************
    설  명 : 댓글 입력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setComment() {
    this.boardService.setComment( this.form ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success('댓글이 입력되었습니다.');
        this.getComment();
      } else {
        this.toastrService.error('댓글이 입력을 실패했습니다.');

      }
    });
  }

  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getSafeHtml( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  /*******************************************************************************
    설  명 : 댓글 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getComment() {
    this.boardService.getComment( this.boardData.board_index ).then( response => {
      if ( response.ResultCode ) {
        this.replyList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 댓글 수정하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  updateComment(row) {
    const modalRef = this.modalService.open(ViewUpdateComponent, options);

    modalRef.componentInstance.rowData = row;
    
    modalRef.result.then((result) => {
      this.getComment();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 댓글 삭제하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteComment(row) {
    this.boardService.deleteComment( row.comment_index ).then( response => {
      if( confirm('댓글을 삭제하시겠습니까?') ) {
        if ( response.ResultCode ) {
          this.toastrService.success('댓글을 삭제하였습니다.');
          this.getComment();
        } else {
          this.toastrService.error('댓글 삭제를 실패하였습니다.');
        }
      }
    });
  }
}
