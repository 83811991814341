<section id="customer">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="58" minSize="50" maxSize="70">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getEtcAccountList()">
            <i class="material-icons-outlined">search</i>
          </button>

          <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="계정명 검색" />

          <div class="btn-group ml5">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.account_type==''}" (click)="searchBtn('account_type', '')">전체</button>
            <button *ngFor="let item of commonType" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.account_type==item.common_code}" (click)="searchBtn('account_type',item.common_code)">{{item.common_name}}</button>
          </div>

        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="0d5adbaaa48345769b44f3438eaaa754" [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="addAccount(0)">등록하기</button>
          <!-- <button type="button" title="기타 입출금 계정추가" class="btn btn-primary btn-small-icon ml5" (click)="addAccount(0)">
            <i class="material-icons-outlined">add</i>
          </button> -->
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="etcAccountList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
          (cellClicked)="onCellClicked($event)"
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    
  </as-split>
</section>
