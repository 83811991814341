<section class="widget-div">
  <div class="widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">전체<span class="cnt">{{totalCount | number}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>
</section>

<div class="fl w70p form-inline">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
    <i class="material-icons-outlined">refresh</i>
  </button>
  <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getBikeBrandList()">
    <i class="material-icons-outlined">search</i>
  </button>

  <div class="btn-group ml5">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn==''}" (click)="setUseYn('')">전체</button>
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='1'}" (click)="setUseYn('1')">사용</button>
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.use_yn=='0'}" (click)="setUseYn('0')">사용안함</button>
  </div>

  <input type="text" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="브랜드명 검색" />
</div>

<div class="fr w30p right">
  <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="0f69fa7db6b644db8edc677e8a79aee3" [btnGroup]="1"></btn-ag-grid-save>
  <button type="button" class="btn btn-primary btn-small ml5" (click)="brandAdd()">브랜드등록</button>
</div>

<div class="cb"></div>

<as-split unit="percent" restrictMove="true" gutterSize="10">
  <as-split-area size="35" minSize="35" maxSize="55">
    <div class="mt5" style="height:calc(100% - 95px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="brandList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (gridReady)="onGridReady($event)"
        (rowClicked)="onRowClicked($event)"
        >
      </ag-grid-angular>
    </div>
  </as-split-area>
  <as-split-area size="65" minSize="45" maxSize="65">
    <form [formGroup]="form">
      <section id="customer-member-info">
        <div class="mt5">
          <div class="fl w50p">
            <h4 class="fb mt5 mb6">브랜드 정보</h4>
          </div>
          <div class="fr w50p right">
            <button *ngIf="form.controls.seq.value != ''" type="button" class="btn btn-danger btn-small ml5" (click)="setBikeBrandDelete()">삭제하기</button>
            <button type="button" class="btn btn-success btn-small ml5" (click)="setBikeBrandSave()">저장하기</button>
          </div>
          <div class="cb"></div>
        </div>
      
        <table class="table table-bordered table-small1 mb0">
        <caption class="none">브랜드 정보</caption>
        <colgroup>
          <col style="width:20%;" />
          <col style="width:80%;" />
        </colgroup>
      
        <tbody>
        <tr>
          <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="brand_name">브랜드명</label></th>
          <td>
            <inputEx type="text" name="brand_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="brand_ename">브랜드명(영문)</label></th>
          <td>
            <inputEx type="text" name="brand_ename" [formGroup]="form" [formErrors]="formErrors"></inputEx>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="use_yn" class="control-label"><i class="material-icons cred f11 lh20">star</i>사용여부</label></th>
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='1'}" (click)="form.patchValue({use_yn: '1'})">사용</button>
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.use_yn.value=='0'}" (click)="form.patchValue({use_yn: '0'})">사용안함</button>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="contents">브랜드 내용</label></th>
          <td colspan="3">
            <inputEx type="summernote" name="contents" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx>
          </td>
        </tr>
        </tbody>
        </table>
      </section>
      
      <section class="mt15">
        <div class="mb5">
          <div class="fl w50p fb pt5">브랜드 이미지</div>
          <div class="fr w50p right">
          </div>
          <div class="cb"></div>
        </div>
      
        <table class="table table-bordered table-small">
        <caption class="hidden">브랜드 이미지정보</caption>
      
        <colgroup>
          <col style="width:20%" />
          <col style="width:80%" />
        </colgroup>
      
        <tbody>
        <tr>
          <th scope="row" class="active"><i class="material-icons cred f11 lh20">star</i> <label for="file">이미지</label></th>
          <td>
            <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx>
            <div class="progress mt5 mb5">
              <div class="progress-bar" [ngStyle]="{'width': uploadProgress}"></div>
            </div>
            <!-- <div class="mt5">
            ※ 파일별 50MB 까지, 최대 1개까지 첨부 가능, 전체 첨부용량 50MB 제한
            </div> -->
            <div *ngIf="form.controls.files.value.length > 0 || form.controls.upload.value.length > 0" class="file-div">
              <ul class="file-ul">
                <li *ngFor="let file of form.controls.upload.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin_filename)), active: file.is_default == '1'}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="setUploadImageDefault(file)">
                      <img [src]="file.url" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="setDeleteUploadFile( file, i )"><img src="/assets/images/del_ico.png" /></a>
                    </div>
                  </div>
                </li>
                <li *ngFor="let file of form.controls.files.value; let i = index" class="fl">
                  <div class="upload-item" [ngClass]="{uploadImage: checkImage(getExt(file.origin)), active: file.is_default == '1'}">
                    <div *ngIf="checkImage(getExt(file.origin))" class="upload-image" (click)="setFileImageDefault(file)">
                      <img [src]="file.url" />
                    </div>
                    <div *ngIf="!checkImage(getExt(file.origin))" class="upload-image1">{{getExt(file.origin)}}</div>
                    <div class="upload-filename"><a href="{{file.url}}" target="_blank" title="{{file.origin}}">{{file.origin}}</a></div>
                    <div class="upload-size">
                      ({{getComma(file.size)}} KB)
                      <a class="cp ml5" (click)="deleteFile( file, i )">
                        <img src="/assets/images/del_ico.png" />
                      </a>
                    </div>
                  </div>
                </li>
                <li class="cb"></li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
        </table>
      </section>
      
    </form>
  </as-split-area>
</as-split>