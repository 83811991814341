/*******************************************************************************
  설  명 : 쿠폰관리
  작성일 : 
  작성자 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { EventService } from '@app/service/event.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { CouponAddComponent } from '@page/basic/coupon/coupon-add/coupon-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    isUse: '',
    couponType: '',
    searchText: ''
  };

  public couponTypeList: any = [];
  public couponList: any = [];
  public baminCodeList: any = [];

  
  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiBaminCode: any;
  gridColumnApiBaminCode: any;
  columnDefsBaminCode: any;
 
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  deleteColumnDefs: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private eventService: EventService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '순번', field: 'seq', width: 60, cellClass: 'cp center'},
      { headerName: '쿠폰구분', field: 'coupon_type_name', width: 125, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          let couponTypeName: any = params.data.coupon_type_name;
          let badgeClass: any = params.data.coupon_type_c1 || 'badge-secondary';

          return `<span class="badge ${badgeClass} f12">${couponTypeName}</span>`;
        }
      },
      { headerName: '쿠폰코드', field: 'coupon_code', width: 130, cellClass: 'cp left' },
      { headerName: '쿠폰수량', field: 'coupon_count', width: 80, cellClass: 'cp right' },
      { headerName: '관리회원아이디', field: 'id', width: 110, cellClass: 'cp left' },
      { headerName: '관리회원명', field: 'name', width: 100, cellClass: 'cp center' },
      { headerName: '시작일자', field: 'start_date', width: 145, cellClass: 'cp left' },
      { headerName: '종료일자', field: 'end_date', width: 145, cellClass: 'cp left' },
      { headerName: '등록일자', field: 'write_date', width: 145, cellClass: 'cp left' },
      { headerName: '등록자', field: 'writer_name', width: 90, cellClass: 'cp center' },
      { headerName: '사용여부', field: 'use_yn', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( params.data.use_yn === '1' ) {
            return '<span class="badge badge-success f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">미사용</span>';
          }
        }
      },
    ];

      // ag grid 컬럼 선언
      this.columnDefsBaminCode = [
        /*
        { headerName: '순번', field: 'rowIndex', width: 80, cellClass: 'cp center', 
          valueGetter: function(params) {
            let rowData = params.node.gridOptionsWrapper.gridOptions.rowData.length;
            let rowIndex = params.node.rowIndex;
            return parseInt(rowData) - rowIndex;
          }
          // headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
        },*/
        { headerName: '쿠폰코드', field: 'bamin_code', width: 250, cellClass: 'cp center' },
        { headerName: '사용여부', field: 'is_use', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => {
          if ( parseInt(params.data.is_use) > 0 ) {
            return '<span class="badge badge-success f12">사용</span>';
          } else {
            return '<span class="badge badge-secondary f12">미사용</span>';
          }
        }
      },

      ];
    

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    this.activatedRoute.queryParams.subscribe( params => {
      this.search.isUse = params.isUse === undefined ? '' : params.isUse;
      this.search.couponType = params.couponType === undefined ? '' : params.couponType;
      this.search.searchText = params.searchText === undefined ? '' : params.searchText;

      this.getEventCouponList();
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    this.commonService.getCommonListCode('CPT').then( response => {
      if ( response.ResultCode ) {
        this.couponTypeList = response.data;
      } else {
        this.couponTypeList = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 쿠폰 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventCouponList() {
    this.eventService.getEventCouponList(this.search).then( response => {
      if (response.ResultCode) {
        this.couponList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '쿠폰관리');
      }

    });
  }

  /*******************************************************************************
    설  명 : 배민 쿠폰 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getBaminCouponList(eventCouponSeq:any) {
      this.eventService.getBaminCodeList(eventCouponSeq).then( response => {
        if (response.ResultCode) {
          this.baminCodeList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '쿠폰관리');
        }
  

      });
    }  
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked(event: any) {
    this.addCoupon(event.data);
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList(event: any) {
    if( event.key == 'Enter' ) {
      this.getEventCouponList();
    }
  }
  
  /*******************************************************************************
    설  명 : 검색 버튼 클릭 시 검색
    입력값 : key = 값을 넣을 변수명, value = 값
    리턴값 : 없음
  *******************************************************************************/
  searchBtn( key: any, value: any ) {
    this.search[key] = value;
    this.getEventCouponList();
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      isUse: '',
      couponType: '',
      searchText: '',
    };

    this.getEventCouponList();
  }


  /*******************************************************************************
    설  명 : 행 클릭 시 처리
  *******************************************************************************/
    onRowClicked(event: any) {
      this.getBaminCouponList( event.data.seq);
    }
  
  
  /*******************************************************************************
    설  명 : 쿠폰등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCoupon( data: any ) {
    const modalRef = this.modalService.open(CouponAddComponent, options);

    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if( result ) this.getEventCouponList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 쿠폰 삭제하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delCoupon() {
    const nodes: any = this.gridApi.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.info('삭제할 내역을 선택하시기 바랍니다.');
      return;
    }

    let seq: any = nodes.map((row: any) => row.seq);

    if( confirm("선택한 내역을 삭제하시겠습니까?")) {
      this.eventService.setEventCouponDelete(seq).then( response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage);
          this.getEventCouponList();
        } else {
          this.toastrService.error(response.ResultMessage);
        };
      });
    }
  }

  /*******************************************************************************
    설  명 : 생성된 배민 코드 삭제하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    delBaminCode() {
      const nodes: any = this.gridApi.getSelectedRows();
  
      if ( nodes.length < 1 ) {
        this.toastrService.info('삭제할 내역을 선택하시기 바랍니다.');
        return;
      }
  
      let seq: any = nodes.map((row: any) => row.seq);
      let firstValue = seq[0];
  
      if( confirm("선택한 내역을 삭제하시겠습니까?")) {
        this.eventService.setBaminCodeDelete(firstValue).then( response => {
          if (response.ResultCode) {
            this.toastrService.success(response.ResultMessage);
            this.getBaminCouponList( firstValue);
          } else {
            this.toastrService.error(response.ResultMessage);
          };
        });
      }
    }  

  /*******************************************************************************
    설  명 : 배민코드 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    addBaminCode() {
      const nodes: any = this.gridApi.getSelectedRows();
  
      if ( nodes.length < 1 ) {
        this.toastrService.info('코드를 생성할 내역을 선택하세요.');
        return;
      }
      let coupon_type  = nodes.map((row: any) => row.coupon_type);
      if (coupon_type[0] !== '4000') {
        this.toastrService.info('배민쿠폰이 아닌 경우 쿠폰코드를 생성 할 수 없습니다.');
        return;       
      }
  
      let seq  = nodes.map((row: any) => row.seq);
      let firstValue = seq[0];
  
      if( confirm("배민쿠폰 코드를 생성하시겠습니까?")) {
        this.eventService.setBaminCode(firstValue).then( response => {
          if (response.ResultCode) {
            this.toastrService.success(response.ResultMessage);
            this.getBaminCouponList( firstValue);
          } else {
            this.toastrService.error(response.ResultMessage);
          };
        });
      }
    }


  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
    onQuickFilterChanged( event: any ) {
      const searchText: any = document.getElementById( event.target.id );
      this.gridApiBaminCode.setQuickFilter(searchText.value);
    }
 
  /*******************************************************************************
    설  명 : 그리드 초기화
  *******************************************************************************/
    onGridReadyBaminCode(params) {
      this.gridApiBaminCode = params.api;
      this.gridColumnApiBaminCode = params.columnApi;
    }    
  
 
}
