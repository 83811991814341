<form [formGroup]="formAdd" (ngSubmit)="submit()">
  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">게시판 {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb5">
    <caption class="hidden">게시판 추가/수정</caption>

    <colgroup>
      <col style="width:15%;" />
      <col style="width:35%;" />
      <col style="width:15%;" />
      <col style="width:35%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="seq" class="control-label">게시판코드</label></th>
      <td><inputEx type="text" name="seq" [formGroup]="formAdd" [formErrors]="formErrors" readonly="true" placeholder="자동생성"></inputEx></td>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="board_id" class="control-label">게시판아이디</label></th>
      <td><inputEx type="text" name="board_id" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="board_name" class="control-label">게시판명</label></th>
      <td><inputEx type="text" name="board_name" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="board_type" class="control-label">게시판타입</label></th>
      <td><inputEx type="select" name="board_type" [formGroup]="formAdd" [formErrors]="formErrors" [data]="boardTypeList"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><i class="material-icons cred f11">star</i> <label for="table_name" class="control-label">테이블명</label></th>
      <td><inputEx type="select" name="table_name" [formGroup]="formAdd" [formErrors]="formErrors" [data]="boardTableList" [addOn]="addTableFunc" addOnText="테이블추가"></inputEx></td>
      <th scope="row"><label for="comment_table_name" class="control-label">댓글 테이블</label></th>
      <td><inputEx type="select" name="comment_table_name" [formGroup]="formAdd" [formErrors]="formErrors" [data]="commentTableList" [addOn]="addTableCommentFunc" addOnText="테이블추가"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="recommend_table_name" class="control-label">추천 테이블명</label></th>
      <td><inputEx type="select" name="recommend_table_name" [formGroup]="formAdd" [formErrors]="formErrors" [data]="recommendTableList" [addOn]="addTableRecommendFunc" addOnText="테이블추가"></inputEx></td>
      <th scope="row"><label for="opposite_table_name" class="control-label">반대 테이블명</label></th>
      <td><inputEx type="select" name="opposite_table_name" [formGroup]="formAdd" [formErrors]="formErrors" [data]="oppositeTableList" [addOn]="addTableOppositeFunc" addOnText="테이블추가"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="notice_rows" class="control-label">공지사항개수</label></th>
      <td><inputEx type="text" name="notice_rows" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx></td>
      <th scope="row"><label for="new_date" class="control-label">신규표시(day)</label></th>
      <td><inputEx type="text" name="new_date" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="day"></inputEx></td>
    </tr>
    </tbody>
    </table>

    <table class="table table-bordered table-small mb5">
    <caption class="hidden">게시판 추가/수정</caption>

    <colgroup>
      <col style="width:16%;" />
      <col style="width:16%;" />
      <col style="width:16%;" />
      <col style="width:16%;" />
      <col style="width:16%;" />
      <col style="width:16%;" />
    </colgroup>

    <thead>
    <tr class="">
      <th scope="col" class="center active">기본</th>
      <th scope="col" class="center active">리스트</th>
      <th scope="col" class="center active">앨범</th>
      <th scope="col" class="center active">갤러리</th>
      <th scope="col" class="center active">달력</th>
      <th scope="col" class="center active">그룹</th>
    </tr>
    </thead>

    <tbody>
    <tr>
      <td rowspan="2" class="center">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_default.value=='L'}" (click)="formAdd.patchValue({lvt_default:'L'})">리스트</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_default.value=='A'}" (click)="formAdd.patchValue({lvt_default:'A'})">앨범</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_default.value=='G'}" (click)="formAdd.patchValue({lvt_default:'G'})">갤러리</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_default.value=='C'}" (click)="formAdd.patchValue({lvt_default:'C'})">달력</button>
        </div>
        <inputEx type="hidden" name="lvt_default" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
      <td class="center">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_list.value==true}" (click)="formAdd.patchValue({lvt_list:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_list.value==false}" (click)="formAdd.patchValue({lvt_list:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="lvt_list" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
      <td class="center">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_album.value==true}" (click)="formAdd.patchValue({lvt_album:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_album.value==false}" (click)="formAdd.patchValue({lvt_album:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="lvt_album" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
      <td class="center">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_gallery.value==true}" (click)="formAdd.patchValue({lvt_gallery:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_gallery.value==false}" (click)="formAdd.patchValue({lvt_gallery:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="lvt_gallery" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
      <td class="center">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_calendar.value==true}" (click)="formAdd.patchValue({lvt_calendar:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_calendar.value==false}" (click)="formAdd.patchValue({lvt_calendar:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="lvt_calendar" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
      <td class="center">
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_group.value==true}" (click)="formAdd.patchValue({lvt_group:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.lvt_group.value==false}" (click)="formAdd.patchValue({lvt_group:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="lvt_group" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <td><inputEx type="text" name="linecnt_list" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="표시줄수 10/20/30/50"></inputEx></td>
      <td><inputEx type="text" name="linecnt_album" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="표시줄수 3/5/10/20"></inputEx></td>
      <td><inputEx type="text" name="linecnt_gallery" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="표시줄수 5/10/15/20"></inputEx></td>
      <td></td>
      <td><inputEx type="text" name="group" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="| 구분자로 입력"></inputEx></td>
    </tr>
    </tbody>
    </table>

    <table class="table table-bordered table-small mb0">
    <caption class="hidden">게시판 추가/수정</caption>

    <colgroup>
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
      <col style="width:10%;" />
    </colgroup>

    <thead>
    <tr class="">
      <th scope="col" class="center active">보기권한</th>
      <th scope="col" class="center active">쓰기권한</th>
      <th scope="col" class="center active">수정권한</th>
      <th scope="col" class="center active">삭제권한</th>
      <th scope="col" class="center active">답변권한</th>
      <th scope="col" class="center active">댓글권한</th>
      <th scope="col" class="center active">추천권한</th>
      <th scope="col" class="center active">반대권한</th>
      <th scope="col" class="center active">파일권한</th>
      <th scope="col" class="center active">이미지권한</th>
    </tr>
    </thead>

    <tbody>
    <tr>
      <td><inputEx type="select" name="auth_view" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_write" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_modify" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_del" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_reply" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_comment" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_recommend" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_opposite" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_upload_file" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
      <td><inputEx type="select" name="auth_upload_image" [formGroup]="formAdd" [formErrors]="formErrors" [data]="authGroupList"></inputEx></td>
    </tr>
    </tbody>
    </table>

    <p class="pt5 pb5">※ 0 : 비회원, 1~79까지 일반회원 레벨, 80~98까지 관리자 권한, 99는 시스템 관리자 권한, -1 사용안함</p>

    <table class="table table-bordered table-small mb0">
    <caption class="hidden">게시판 추가/수정</caption>

    <colgroup>
      <col style="width:15%;" />
      <col style="width:35%;" />
      <col style="width:15%;" />
      <col style="width:35%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="upload_total_size" class="control-label">업로드최대크기(MB)</label></th>
      <td><inputEx type="text" name="upload_total_size" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="MB"></inputEx></td>
      <th scope="row"><label for="upload_dir_autocreate" class="control-label">폴더 자동생성</label></th>
      <td><inputEx type="text" name="upload_dir_autocreate" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="Y/m"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="upload_file_type" class="control-label">파일타입</label></th>
      <td><inputEx type="text" name="upload_file_type" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="| 구분 확장명"></inputEx></td>
      <th scope="row"><label for="upload_file_dir" class="control-label">파일업로드폴더</label></th>
      <td><inputEx type="text" name="upload_file_dir" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="/upload/files"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="upload_image_type" class="control-label">이미지 타입</label></th>
      <td><inputEx type="text" name="upload_image_type" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="| 구분 jpg|gif|png"></inputEx></td>
      <th scope="row"><label for="upload_image_dir" class="control-label">이미지업로드폴더</label></th>
      <td><inputEx type="text" name="upload_image_dir" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="/upload/images"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="upload_image_max_width" class="control-label">가로 최대사이즈</label></th>
      <td><inputEx type="text" name="upload_image_max_width" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="pixel"></inputEx></td>
      <th scope="row"><label for="upload_image_max_height" class="control-label">세로 최대사이즈</label></th>
      <td><inputEx type="text" name="upload_image_max_height" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="pixel"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="upload_image_max_size" class="control-label">이미지업로드용량(MB)</label></th>
      <td><inputEx type="text" name="upload_image_max_size" [formGroup]="formAdd" [formErrors]="formErrors" placeholder="MB"></inputEx></td>
      <th scope="row"><label for="upload_table_name" class="control-label">업로드 테이블명</label></th>
      <td><inputEx type="select" name="upload_table_name" [formGroup]="formAdd" [formErrors]="formErrors" [data]="uploadTableList" [addOn]="addTableUploadFunc" addOnText="테이블추가"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="is_thumbnail" class="control-label">섬네일사용</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.is_thumbnail.value==true}" (click)="formAdd.patchValue({is_thumbnail:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.is_thumbnail.value==false}" (click)="formAdd.patchValue({is_thumbnail:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="is_thumbnail" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="upload_image_down" class="control-label">이미지다운</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.upload_image_down.value==true}" (click)="formAdd.patchValue({upload_image_down:true})">사용</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.upload_image_down.value==false}" (click)="formAdd.patchValue({upload_image_down:false})">사용안함</button>
        </div>
        <inputEx type="hidden" name="upload_image_down" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="is_approval" class="control-label">글 승인사용</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.is_approval.value==true}" (click)="formAdd.patchValue({is_approval:true})">승인</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.is_approval.value==false}" (click)="formAdd.patchValue({is_approval:false})">미승인</button>
        </div>
        <inputEx type="hidden" name="is_approval" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="approval_default" class="control-label">승인 기본값</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.approval_default.value==true}" (click)="formAdd.patchValue({approval_default:true})">승인</button>
          <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:formAdd.controls.approval_default.value==false}" (click)="formAdd.patchValue({approval_default:false})">미승인</button>
        </div>
        <inputEx type="hidden" name="approval_default" [formGroup]="formAdd" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="new_date" class="control-label">게시판 헤더HTML</label></th>
      <td colspan="3">
        <textarea [ngxSummernote]="config" formControlName="header"></textarea>
      </td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
      <ng-container *ngIf="authService.currAuth['auth_delete']=='1'">
        <button *ngIf="formAdd.controls.seq.value !== 0 && formAdd.controls.seq.value !== ''" type="button" class="btn btn-danger btn-small f15" (click)="delete()">삭제하기</button>
      </ng-container>
    </div>
    <div class="fr w50p right ml0 mr0">
      <ng-container *ngIf="(formAdd.controls.seq.value!=0 && formAdd.controls.seq.value!='') && authService.currAuth['auth_modify']=='1'">
        <button type="submit" class="btn btn-primary btn-small f15">저장하기</button>
      </ng-container>
      <ng-container *ngIf="(formAdd.controls.seq.value==0 || formAdd.controls.seq.value=='') && authService.currAuth['auth_write']=='1'">
        <button type="submit" class="btn btn-primary btn-small f15">저장하기</button>
      </ng-container>
      <button type="button" class="btn btn-default btn-small ml5 f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
