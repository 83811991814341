<div [formGroup]="formGroup" ngbDropdown>
  <input type="text" readonly="readonly" formControlName="{{name}}"
    [id]="name"
    [ngClass]="{formErrors:formErrors[name]}"
    class="form-control form-control-small calendar-input" ngbDropdownToggle />
  <div ngbDropdownMenu >
    <ngb-datepicker #dp [(ngModel)]="date" (dateSelect)="getDatetime()" [ngModelOptions]="{standalone:true}" ></ngb-datepicker>
    <div style="justify-content: center">
      <ngb-timepicker [(ngModel)]="time" [seconds]="true" (ngModelChange)="time=$event;getDatetime()" [ngModelOptions]="{standalone:true}"></ngb-timepicker>
    </div>
  </div>
</div>
