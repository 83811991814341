import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

import { RestfulService } from '@app/service/restful.service';

@Component({
  selector: 'app-member-sis-frastatistic',
  templateUrl: './member-sis-frastatistic.component.html',
  styleUrls: ['./member-sis-frastatistic.component.scss']
})
export class MemberSisFrastatisticComponent implements OnInit {

  public summary: any;

  constructor(
    private restfulService: RestfulService,
  ) { }

  ngOnInit(): void {
    this.retrieve();
  }

  retrieve() {
    //// 협력사 매출요약
    this.restfulService.get( {
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getPartnerSalesSummary'
    }, {
      shop: '0',
      grade: '0007',
    } ).then(response => {
      if (response.result) {
        this.summary = {
          today: moment().toDate(),
          ...response.result,
          margin_total_month: response.result.sales_total_month - response.result.cost_total_month,
          margin_total_year: response.result.sales_total_year - response.result.cost_total_year,
          margin_rate_month: response.result.sales_total_month !== 0 ? 1 - (response.result.cost_total_month / response.result.sales_total_month) : 0,
          margin_rate_year: response.result.sales_total_year !== 0 ? 1 - (response.result.cost_total_year / response.result.sales_total_year) : 0,
        };
      }
    } );
  }
}
