<form [formGroup]="form">
  <header class="mt10 mb5">
    <div class="fl w50p">
      <h4 class="fb mb0">배송비 설정</h4>
    </div>
    <div class="fr w50p right">
      <button type="submit" class="btn btn-primary btn-small" (click)="submit()">저장</button>
    </div>
    <div class="cb"></div>
  </header>  
  
  <section>
    <table class="table table-bordered table-small1 mb0">
    <caption class="none">배송비 설정</caption>
    <colgroup>
      <col style="width:10%" />
      <col style="width:15%" />
      <col style="width:10%" />
      <col style="width:15%" />
      <col style="width:10%" />
      <col style="width:15%" />
      <col style="width:10%" />
      <col style="width:15%" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="delivery_use_yn">통합배송 사용여부</label></th>
      <td colspan="7">
        <div class="btn-group">
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.delivery_use_yn.value=='1'}" (click)="form.get('delivery_use_yn').setValue('1')" >사용</button>
          <button type="button" class="btn btn-small btn-default" [ngClass]="{active: form.controls.delivery_use_yn.value=='0'}" (click)="form.get('delivery_use_yn').setValue('0')">입점사별 개별배송</button>
        </div>
        <inputEx type="hidden" name="delivery_use_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    <tr>
      <th scope="row"><label for="delivery_amt">통합 배송비</label></th>
      <td>
        <inputEx type="text" name="delivery_amt" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="delivery_overcharge">추가 배송비</label></th>
      <td>
        <inputEx type="text" name="delivery_overcharge" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="delivery_fra_amt">협력점/프렌차이즈배송비</label></th>
      <td>
        <inputEx type="text" name="delivery_fra_amt" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="delivery_free_condition">무료 배송 조건</label></th>
      <td>
        <inputEx type="text" name="delivery_free_condition" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
    </tr>
    </tbody>
    </table>
  </section>
</form>
  
<section class="mt15" >
  <div class="mt5">
    <div class="fl">
      <h4 class="fb mt5 mb6 mr10">도선료 정보</h4>
    </div>
  </div>
  <div class="mt5">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getShipDeliveryList()">
      <i class="material-icons-outlined">search</i>
    </button>
    <input type="text" size="30" class="form-control form-control-small ml5 delivery" [(ngModel)]="search.zipcode" (keypress)="searchList($event)" placeholder="우편번호 검색" />
    <input type="text" size="30" class="form-control form-control-small ml5 delivery" [(ngModel)]="search.address" (keypress)="searchList($event)" placeholder="주소 검색" />
    <input type="text" size="30" class="form-control form-control-small ml5 delivery" [(ngModel)]="search.add_delivery_amt" (keypress)="searchList($event)" placeholder="도선료 검색" />
    <div class="fr w50p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="6a98f5ca1c054b59b212b4984077b8a9" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-primary btn-small ml10" (click)="shipDeliveryAdd({seq:0})">등록하기</button>
    </div>
    <div class="cb"></div>
  </div>
</section>

<section style="height: calc(85% - 50px)">
  <ag-grid-angular
    #myGrid

    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="shipDeliveryList"
    [pagination]="false"
    [domLayout]="domLayout"

    (rowDoubleClicked)="onRowDoubleClicked($event)"
    (gridReady)="onGridReady($event)"
    >
  </ag-grid-angular>
</section>
    
    <!-- [frameworkComponents]="frameworkComponents" -->
    <!-- (firstDataRendered)="onFirstDataRendered($event)" -->