/*******************************************************************************
  설  명 : 현황관리 - 일매출 속보실적 상세리스트
  작성일 : 2021.10.06
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';

import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { StockOrderComponent } from '@app/page/stock/stock-order/stock-order.component';
import { StatisticService } from '@app/service/statistic.service';

@Component({
  selector: 'app-statistic-day-detail',
  templateUrl: './statistic-day-detail.component.html',
  styleUrls: ['./statistic-day-detail.component.scss']
})
export class StatisticDayDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    date : moment().format('YYYY-MM-DD'),
    type : '쇼핑몰'
  };

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  fraGridApi: any;
  fraGridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;
  rowClassRules: any

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  rowData: any = []

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent,
    private statisticService: StatisticService,
    private stockOrderComponent: StockOrderComponent,
    private activatedRoute: ActivatedRoute,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '온/오프라인', field: 'onoff_gbn_name', width: 90, cellClass: 'cp center' },
      { headerName: '구분', field: 'order_type_name', width: 70, cellClass: 'cp center' },
      { headerName: '주문번호', field: 'order_master_seq', width: 80, cellClass: 'cp center' },
      { headerName: '주문자', field: 'o_name', width: 110, cellClass: 'cp center' },
      { headerName: '결제구분', field: 'bank', width: 110, cellClass: 'cp center' },
      { headerName: '결제일자', field: 'pay_date', width: 145, cellClass: 'cp center' },
      { headerName: '환불/취소일자', field: 'return_date', width: 145, cellClass: 'cp center' },
      { headerName: '상품', field: 'product_name', width: 400, cellClass: 'cp left' },
      { headerName: '색상/사이즈', field: '', width: 130, cellClass: 'cp center',
        valueGetter: params => `${params.data.color_name || ''} / ${params.data.size_name || ''}` },
        { headerName: '수량', field: 'qty', width: 70, cellClass: 'cp right' , valueFormatter: this.agGridExComponent.currencyFormatter },
        { headerName: '단가', field: 'unit_price', width: 90, cellClass: 'cp right' , valueFormatter: this.agGridExComponent.currencyFormatter },
        { headerName: '금액', field: 'amt', width: 90, cellClass: 'cp right' , valueFormatter: this.agGridExComponent.currencyFormatter },
        // { headerName: '수량', field: 'qty', width: 70, cellClass: 'cp center',
      //   valueFormatter: this.agGridExComponent.currencyFormatter,
      //   valueGetter(params: any) {
      //     let orderStatus: any = params.data.order_status;
      //     let qty: any = params.data.qty;

      //     switch(orderStatus) {
      //       case '3':
      //       case '4':
      //       case '5':
      //       case '6':
      //       case '7':
      //       case '10':
      //         return `-${qty}`;
      //         break;
      //       default:
      //         return qty;
      //     }

      //   }
      // },
      // { headerName: '단가', field: 'unit_price', width: 90, cellClass: 'cp right',
      //   valueFormatter: this.agGridExComponent.currencyFormatter,
      //   valueGetter(params: any) {
      //     let orderStatus: any = params.data.order_status;
      //     let unitPrice: any = params.data.unit_price;

      //     switch(orderStatus) {
      //       case '3':
      //       case '4':
      //       case '5':
      //       case '6':
      //       case '7':
      //       case '10':
      //         return `-${unitPrice}`;
      //         break;
      //       default:
      //         return unitPrice;
      //     }

      //   }
      // },
      // { headerName: '금액', field: 'amt', width: 90, cellClass: 'cp right',
      //   valueFormatter: this.agGridExComponent.currencyFormatter,
      //   valueGetter(params: any) {
      //     let orderStatus: any = params.data.order_status;
      //     let amt: any = params.data.amt;

      //     switch(orderStatus) {
      //       case '3':
      //       case '4':
      //       case '5':
      //       case '6':
      //       case '7':
      //       case '10':
      //         return `-${amt}`;
      //         break;
      //       default:
      //         return amt;
      //     }
      //   }
      // },
      { headerName: '상태', field: 'order_status_name', width: 110, cellClass: 'cp center' }
    ];

    this.defaultColDef = this.stockOrderComponent.defaultColDef
    this.rowSelection = this.stockOrderComponent.rowSelection
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate
    this.rowClassRules = {
      'status1': params => params.data.order_type == '4000'
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute
      .queryParams
      .subscribe( async params => {
        this.getStatisticDaySalesDetail(params.date, params.type)
      })
      .unsubscribe();

    this.activatedRoute
      .params
      .subscribe( async params => {
        this.getStatisticDaySalesDetail(params.date, params.type)
      })
      .unsubscribe();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 일매출속보실적 상세리스트 가져오기
  *******************************************************************************/
  getStatisticDaySalesDetail(date, type) {
    this.statisticService.getStatisticDaySalesDetail({
      date : date,
      type : type
    }).then(response => {
      if(response.ResultCode) {
        this.rowData = response.data
      } else this.toastrService.error(response.ResultMessage)
    })
  }

}
