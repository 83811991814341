import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class BikeService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 이벤트 조회
  getBikeEventList( search: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'getBikeEventList'
    }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 조회
  getBikeEventDetail( seq: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'getBikeEventDetail'
    }, {
      seq: seq
    }).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 이벤트 등록/수정
  setBikeEventSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'setBikeEventSave'
    }, form.value
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 이벤트 삭제
  setBikeEventDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeEventDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 이벤트 이미지 삭제
  setBikeEventImageDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeEventImageDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 대표이미지 설정
  setBikeEventImageDefault( file: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeEventImageDefault'
      }, file
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 광고 조회
  getBikeAdvertisementList( search: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'getBikeAdvertisementList'
    }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 광고 조회
  getBikeAdvertisementDetail( seq: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'getBikeAdvertisementDetail'
    }, {
      seq: seq
    }).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 광고 등록/수정
  setBikeAdvertisementSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'setBikeAdvertisementSave'
    }, form.value
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 광고 삭제
  setBikeAdvertisementDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeAdvertisementDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 광고 이미지 삭제
  setBikeAdvertisementImageDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeAdvertisementImageDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 대표이미지 설정
  setBikeAdvertisementImageDefault( file: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeAdvertisementImageDefault'
      }, file
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 광고 순서 변경
  setBikeAdvertisementSort( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeAdvertisementSort'
      }, {
        params: params
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // PUSH 발송양식 리스트 가져오기
  getBikePushFormList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'getBikePushFormList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // PUSH 발송양식 저장
  setBikePushForm( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikePushForm'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // PUSH 발송양식 정보 삭제하기
  delBikePushForm( form: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'delBikePushForm'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 브랜드 리스트 가져오기
  getBikeBrandList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'getBikeBrandList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드 정보 가져오기
  getBikeBrandInfo( brandSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'getBikeBrandInfo'
      }, {
        seq: brandSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드 등록 및 수정
  setBikeBrandSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeBrandSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 이미지 삭제
  setBikeBrandImageDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeBrandImageDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 브랜드 삭제
  setBikeBrandDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeBrandDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 대표이미지 설정
  setBikeBrandImageDefault( file: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeBrandImageDefault'
      }, file
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 오토바이 매물 조회
  getBikeSellList( search: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'getBikeSellList'
    }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 매물 상세정보 조회
  getBikeSellDetail( seq: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'getBikeSellDetail'
    }, {
      seq: seq
    }).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 오토바이 제품 목록 가져오기
  getBikeProductList( search: any ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'getBikeProductList'
    }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 오토바이 제품 등록/수정
  setBikeProductSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'setBikeProductSave'
    }, form.value
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 오토바이 제품 삭제
  setBikeProductDelete( seq: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'setBikeProductDelete'
    }, {
      seq: seq
    }
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 매물 등록/수정
  setBikeSellSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'setBikeSellSave'
    }, form.value
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 매물 삭제
  setBikeSellDelete( seq: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'setBikeSellDelete'
    }, {
      seq: seq
    }
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 매물 이미지 삭제
  setBikeSellImageDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeSellImageDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매물 대표이미지 설정
  setBikeSellImageDefault( file: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikeSellImageDefault'
      }, file
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드 리스트 가져오기
  getBikeBrandListForComponent( form: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'getBikeBrandListForComponent'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 지역 리스트 가져오기
  getMngRegionList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'getMngRegionList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 결제도우미 리스트 가져오기
  getBikePayhelperList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'getBikePayhelperList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 결제도우미 상세정보 가져오기
  getBikePayhelperDetail( helperSeq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'getBikePayhelperDetail'
      }, {
        seq: helperSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 매물 대표이미지 설정
  setUploadImageDefault( file: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setUploadImageDefault'
      }, file
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 이미지 삭제
  setDeleteUploadFile( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setDeleteUploadFile'
      }, {
        imageSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 결제도우미 거래완료
  setBikePayhelperComplete( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikePayhelperComplete'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결제도우미 결제확인
  setBikePayhelperConfirm( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikePayhelperConfirm'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결제도우미 결제완료 → 정산승인
  setBikePayhelperStatusChange( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikePayhelperStatusChange'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결제도우미 거래취소
  setBikePayhelperCancle( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikePayhelperCancle'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  
  // 결제도우미 수수료 재정산
  setBikePayHelperCalc( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikePayHelperCalc'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결제도우미 수수료 수정
  setBikePayHelperCalcEdit( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setBikePayHelperCalcEdit'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결제도우미 정산예정일 저장
  setPayHelperCalculateDate( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'bike',
        version: '1.0',
        action: 'setPayHelperCalculateDate'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결제도우미 코멘트 저장
  setBikeHelperCommentSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'setBikeHelperCommentSave'
    }, form.value
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 결제도우미 코멘트 삭제
  setBikeHelperCommentDelete( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'bike',
      version: '1.0',
      action: 'setBikeHelperCommentDelete'
    }, {
      params: array
    }
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

}
