<section class="search-condition mb5 form-inline">
  <div class="fl w50p">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getProductQuestion();">
      <i class="material-icons-outlined">search</i>
    </button>


    <ng-container>
      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status===''}" (click)="searchBtn('status','')">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status===0}" (click)="searchBtn('status',0)">진행</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: params.status===1}" (click)="searchBtn('status',1)">완료</button>
      </div>
    </ng-container>

    <select [(ngModel)]="params.group" class="page-row ml5 form-control form-control-small">
      <option value="comment" [selected]="params.group==='comment'">제목</option>
      <option value="id" [selected]="params.group==='id'">아이디</option>
      <option value="name" [selected]="params.group==='name'">작성자</option>
    </select>

    <input type="text" [(ngModel)]="params.text" class="form-control form-control-small search-input ml5" (keyup)="searchService($event)" />

    <span class="search-cnt ml10 pt8">
      총 <span class="search-cnt">{{totalCount}}</span> 개의 글
    </span>
  </div>

  <div class="fr w50p right">
    <!-- <a *ngIf="authWriteCheck()" class="btn btn-default btn-small" (click)="write()">글쓰기</a> -->
  </div>

  <div class="cb"></div>
</section>


<table class="list-table">
<colgroup>
  <col style="width:10%" />
  <col style="width:25%" />
  <col style="width:35%" />
  <col style="width:15%" />
  <col style="width:15%" />
 </colgroup>
<thead>
<tr>
   <th scope="col">진행사항</th>
   <th scope="col">상품명</th>
   <th scope="col">제목</th>
   <th scope="col">작성자</th>
   <th scope="col">등록일</th>
</tr>
</thead>
<tbody>
  <tr *ngFor="let row of productQna">
    <td>
      <span class="badge badge-success" *ngIf="row.state == '0'">진행</span>
      <span class="badge badge-primary" *ngIf="row.state == '1'">완료</span>
    </td>
    <td class="left cp" (click)="productDetail(row.product_seq)">[{{row.product_seq}}] {{row.product_name}}</td>
    <td class="tl form-inline">
      <a *ngIf="row.secret == '1'" class="lock fl title cp" [innerHTML]="row.comment" (click)="view(row)"></a>
      <a *ngIf="row.secret == '0'" class="fl title cp" [innerHTML]="row.comment" (click)="view(row)"></a>
      <div class="lock"></div>
    </td>
    <td>{{row.name}}</td>
    <td>{{row.question_date}}</td>
  </tr>
  <tr *ngIf="productQna.length < 1">
    <td colspan="5" class="center">검색된 내용이 없습니다.</td>
  </tr>
</tbody>
</table>

<section class="pagination mt30 fl" *ngIf="totalCount">
  <ngb-pagination
    [(page)]="params.no"
    [pageSize]="params.row"
    [maxSize]="maxSize"
    [collectionSize]="totalCount"
    [boundaryLinks]="true"
    (pageChange)="loadPage($event)"
    >
    <ng-template ngbPaginationFirst>처음</ng-template>
    <ng-template ngbPaginationLast>마지막</ng-template>
    <ng-template ngbPaginationPrevious>이전</ng-template>
    <ng-template ngbPaginationNext>다음</ng-template>
  </ngb-pagination>
</section>