import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';

@Component({
  selector: 'app-stock-order-memo',
  templateUrl: './stock-order-memo.component.html',
  styleUrls: ['./stock-order-memo.component.scss']
})
export class StockOrderMemoComponent implements OnInit {

  @Input() rows: any;

  public search: any = {};

  public form: FormGroup;
  public formErrors = {};
  
  buildForm(): void {
    this.form = this.formBuilder.group({
      orderSeq: ['', [Validators.required] ],
      customer_seq: ['0', [] ],
      malltype: ['BAMA', [] ],
      comment: ['', [Validators.required] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private orderService: OrderService,
  ) {
    this.buildForm();
   }

  ngOnInit(): void {
    this.form.patchValue({
      orderSeq: this.rows.order_seq
    });
  }

  /*******************************************************************************
    설  명 : 메모 등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderMemoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.orderService.setOrderMemoSave( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);

          this.activeModal.close();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }
}
