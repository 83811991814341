<section id="group">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="20" minSize="20" maxSize="40">
      <div class="">
        <div class="fl w50p">
          <h4 class="fb mt5 mb6">조직도</h4>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 50px)">
        <app-tree-view
          [useSort]='true' [useAddNode]='true' [nodeData]='nodeData'
          (onSelected)='onSelectNode($event)'
          (onAddNode)='onAddNode($event)'
          (onDropNode)='onDropNode($event)'>
        </app-tree-view>
      </div>
    </as-split-area>

    <as-split-area size="80" minSize="60" maxSize="80">
      <form [formGroup]="groupForm" (ngSubmit)="submit()">
        <div class="">
          <div class="fl w50p">
            <h4 class="fb mt5 mb6">그룹 정보 - 선택 그룹명</h4>
          </div>
          <div class="fr w50p right">
            <button *ngIf="checkDeleteGroup()" type="button" class="btn btn-danger btn-small ml5" (click)="deleteGroup()">삭제</button>
            <button type="submit" class="btn btn-primary btn-small ml5">저장하기</button>
          </div>
          <div class="cb"></div>
        </div>

        <div style="height:calc(100% - 35px)">
          <div class="">
            <table class="table table-bordered table-small">
            <colgroup>
              <col style="width:20%" />
              <col style="width:80%" />
            </colgroup>

            <tbody>
            <tr>
              <th scope="row" class="active"><label for="dept_cd">조직코드</label></th>
              <td>
                <inputEx type="text" name="dept_cd" readonly="true" [formGroup]="groupForm" [formErrors]="formErrors"></inputEx>
              </td>
            </tr>
            <tr>
              <th scope="row" class="active"><label for="dept_name">조직명</label></th>
              <td>
                <inputEx type="text" name="dept_name" [formGroup]="groupForm" [formErrors]="formErrors"></inputEx>
              </td>
            </tr>
            <tr>
              <th scope="row" class="active"><label for="use_yn">사용여부</label></th>
              <td>
                <div class="btn-group">
                  <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:groupForm.controls.use_yn.value=='1'}" (click)="groupForm.patchValue({use_yn:'1'})">사용</button>
                  <button type="button" class="btn btn-default btn-small f12" [ngClass]="{active:groupForm.controls.use_yn.value=='0'}" (click)="groupForm.patchValue({use_yn:'0'})">사용안함</button>
                </div>
                <inputEx type="hidden" name="use_yn" [formGroup]="groupForm" [formErrors]="formErrors"></inputEx>
              </td>
            </tr>
            </tbody>
            </table>

          </div>
          <div class="cb"></div>
        </div>
      </form>
    </as-split-area>
  </as-split>
</section>
