<section id="member-company">

  <div class="mb5">
    <div class="fl w50p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberTransitionApplicationList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <input type="text" size="30" [(ngModel)]="search.searchText" (keypress)="searchList($event)" class="form-control form-control-small ml5" placeholder="신청자ID, 신청자, 상호, 사업자번호" />

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.status==''}" (click)="search.status='';getMemberTransitionApplicationList()">전체</button>
        <button *ngFor="let item of statusList" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.status==item.common_code}" (click)="search.status=item.common_code;getMemberTransitionApplicationList()">{{item.common_name}}</button>
      </div>
    </div>
    <div class="cb"></div>
  </div>

  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 130px);background-color:#fff;">
    <as-split-area size="60" minSize="40" maxSize="80">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="applicationList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"
        
        (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (rowDoubleClicked)="onRowDoubleClicked($event)"
        >
      </ag-grid-angular>
    </as-split-area>

  </as-split>
</section>
