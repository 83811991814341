import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { InventoryService } from '@app/service/inventory.service';

@Component({
  selector: 'app-inventory-schedule-post-and-put',
  templateUrl: './inventory-schedule-post-and-put.component.html',
  styleUrls: ['./inventory-schedule-post-and-put.component.scss']
})
export class InventorySchedulePostAndPutComponent implements OnInit {

  @Input() data: any

  constructor(
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private inventoryService: InventoryService
  ) { }

  ngOnInit(): void {
    this.data = {
      ...this.data, 
      year : this.data.pi_yyyy,
      title : this.data.pi_title,
      sdate : this.data.pi_sdate || '',
      edate : this.data.pi_edate || '',
      memo : this.data.pi_memo
    }
  }

  /*******************************************************************************
    설  명 : 저장하기
  *******************************************************************************/
  submit() {
    if(!this.checkValid()) return
    this.inventoryService[this.data.seq ? 'putSchedule' : 'postSchedule'](
      this.data
      ).then(response => {
      if(response.ResultCode) {
        this.toastrService.success(response.ResultMessage)
        this.activeModal.close(true)
      } else this.toastrService.error(response.ResultMessage)
    })
  }

  selectDate(which, value) {
    const _V = []
    for(const key in value) _V.push(key == 'year' ? value[key] : `0${value[key]}`.slice(-2))
    this.data[which] = _V.join('-')
  }

  checkValid() {
    const wrongPoint = 
    !this.data.year ?
    '연도' :
    !this.data.title ?
    '제목' :
    ''

    if(wrongPoint) {
      this.toastrService.warning(`${wrongPoint} 을(를) 입력하세요.`)
      return false
    } else return true
  }

}
