<section id="customer">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="70" minSize="55" maxSize="80">
      <div class="mt5 mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getEventCouponList()">
            <i class="material-icons-outlined">search</i>
          </button>

          <div class="btn-group ml5">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.isUse==''}" (click)="search.isUse='';getEventCouponList()">전체</button>
            <button type="button" class="btn btn-default btn-small"  [ngClass]="{active: search.isUse=='1'}" (click)="search.isUse='1';getEventCouponList()">사용</button>
            <button type="button" class="btn btn-default btn-small"  [ngClass]="{active: search.isUse=='0'}" (click)="search.isUse='0';getEventCouponList()">미사용</button>
          </div>

          <div class="btn-group ml5">
            <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.couponType==''}" (click)="searchBtn('couponType', '')">전체</button>
            <ng-container *ngFor="let item of couponTypeList">
              <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.couponType==item.common_code}" (click)="searchBtn('couponType', item.common_code)">{{item.common_name}}</button>
            </ng-container>
          </div>

          <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="쿠폰코드,아이디,회원명 " />
        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [btnGroup]="1"></btn-ag-grid-save>
          <button type="button" class="btn btn-danger btn-small ml5" (click)="delCoupon()">쿠폰삭제</button>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="addCoupon(null)">쿠폰등록</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100vh - 140px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="couponList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReady($event)"
          (rowDoubleClicked)="onRowDoubleClicked($event)"
          (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area  size="30" minSize="25" maxSize="45">
      <div class="mt5 mb5">
        <div class="fl w40p form-inline">
          <input type="text" id="quickFilter" size="20" (input)="onQuickFilterChanged($event)" class="form-control form-control-small" placeholder="필드 검색 필터링" />
        </div>
        <div class="fr w60p right">
          <button type="button" class="btn btn-danger btn-small ml5" (click)="delBaminCode()">코드삭제</button>
          <button type="button" class="btn btn-primary btn-small ml5" (click)="addBaminCode()">코드등록</button>          
          <ag-grid-excel [gridApi]="gridApiBaminCode" selected="false" filename="쿠폰코드목록" title="쿠폰코드목록"></ag-grid-excel>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 50px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsBaminCode"
          [defaultColDef]="defaultColDef"
          [rowData]="baminCodeList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (gridReady)="onGridReadyBaminCode($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    
  </as-split>
</section>

