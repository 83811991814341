<section>
  <form [formGroup]="form">
    <div class="mt5">
      <div class="fl w50p">
        <h4 class="fb mt5 mb6">입금대사 설정</h4>
      </div>
      <div class="fr w50p right">
        <button type="submit" class="btn btn-primary btn-small" (click)="submit()">저장하기</button>
      </div>
      <div class="cb"></div>
    </div>  

    <table class="table table-bordered table-small1 mb0">
    <caption class="none">입금대사 설정</caption>
    <colgroup>
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
      <col style="width:10%" />
      <col style="width:23%" />
    </colgroup>
  
    <tbody>
    <tr>
      <th scope="row"><label for="auto_account_yn">입금대사 사용</label></th>
      <td>
        <div class="btn-group">
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.auto_account_yn.value=='1'}" (click)="form.patchValue({auto_account_yn: '1'})">사용</button>
          <button type="button" class="btn btn-default btn-small" [ngClass]="{active: form.controls.auto_account_yn.value=='0'}" (click)="form.patchValue({auto_account_yn: '0'})">사용안함</button>
        </div>
        <inputEx type="hidden" name="auto_account_yn" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      </td>
      <th scope="row"><label for="auto_account_certkey">입금대사 키값</label></th>
      <td><inputEx type="text" name="auto_account_certkey" [formGroup]="form" [formErrors]="formErrors" maxlength="50" placeholder="바로빌 키값"></inputEx></td>
      <th scope="row"><label for="auto_account_id">입금대사 아이디</label></th>
      <td><inputEx type="text" name="auto_account_id" [formGroup]="form" [formErrors]="formErrors" maxlength="50" placeholder="바로빌 아이디"></inputEx></td>
    </tr>
    <tr>
      <th scope="row"><label for="auto_account_sync">입금대사 동기화(분)</label></th>
      <td><inputEx type="number" name="auto_account_sync" [formGroup]="form" [formErrors]="formErrors" maxlength="3" placeholder="입금대사 동기화 시간(분)"></inputEx></td>
      <th scope="row"><label for="auto_account_no">입금대사 계좌번호</label></th>
      <td><inputEx type="text" name="auto_account_no" [formGroup]="form" [formErrors]="formErrors" maxlength="20" placeholder="입금대사에 사용할 계좌번호"></inputEx></td>
      <th scope="row"><label for="auto_account_bank">입금대사 은행명</label></th>
      <td><inputEx type="text" name="auto_account_bank" [formGroup]="form" [formErrors]="formErrors" maxlength="20" placeholder="입금대사에 사용할 은행명"></inputEx></td>
    </tr>
    </tbody>
    </table>
  </form>
</section>

<section class="mt10" style="height: calc(100% - 115px)">
  <div class="mt5">
    <div class="fl w50p">
      <h4 class="fb mt5 mb6">계좌정보</h4>
    </div>
    <div class="fr w50p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="d8a45ef27f254c12832ed70e500c20f3" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-primary btn-small ml10" (click)="accountAdd({seq:0})">등록하기</button>
    </div>
    <div class="cb"></div>
  </div>

  <section style="height: calc(100% - 35px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="accountList"
      [pagination]="false"
      [domLayout]="domLayout"
      [frameworkComponents]="frameworkComponents"

      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
      >
    </ag-grid-angular>
  </section>
</section>
