<section class="widget-div mt5">
  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">{{statistic.event_name}}</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon1">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">{{statistic.start_date}} 부터</p>
      <p class="txt4">{{statistic.end_date}} 까지</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon2">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">판매중인 상품수</p>
      <p class="txt4"><span class="cnt">{{statistic?.productCnt | Numeral: "0,0"}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon3">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">금액설정 대기중</p>
      <p class="txt4"><span class="cnt">{{statistic?.waitCnt | Numeral: "0,0"}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon4">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt4">금액설정 완료</p>
      <p class="txt4"><span class="cnt">{{statistic?.completeCnt | Numeral: "0,0"}}</span>개</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="fl widget cp">
    <div class="fl widget-icon widget-icon5">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p class="txt1">진행률<span class="cnt">{{statistic?.rate | Numeral: "0.00"}}</span>%</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;height: calc(100% - 60px);">
  <as-split-area size="23" minSize="15" maxSize="35">
    <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="onTabChange($event)">
      <mat-tab label="카테고리">
        <div class="mt5">
          <div class="fl w70p">
            <!-- <span class="">선택항목을 : </span>
            <div class="btn-group">
              <button type="button" [ngClass]="{active: params.category_status=='W'}" (click)="params.category_status = 'W'" class="btn btn-default f12">대기</button>
              <button type="button" [ngClass]="{active: params.category_status=='P'}" (click)="params.category_status = 'P'" class="btn btn-default f12">진행중</button>
              <button type="button" [ngClass]="{active: params.category_status=='C'}" (click)="params.category_status = 'C'" class="btn btn-default f12">완료</button>
            </div>
            <button type="button" class="btn btn-primary btn-small ml5" (click)="setEventSuperSaleCategorySave()">저장</button> -->
            <input type="text" id="categoryQuickFilter" size="30" (input)="onCategoryQuickFilterChanged($event)" class="form-control form-control-small ml5" placeholder="모든 필드 검색 필터링" />
          </div>
          <div class="fr w30p right">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApiCategory" gridId="fa63605c20b54f99818e7b4cd98fb55c" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <div class="mt5" style="height:calc(100% - 50px)">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefsCategory"
            [defaultColDef]="defaultColDef"
            [rowData]="categoryList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [getRowNodeId]="getRowNodeId"

            (gridReady)="onGridReadyCategory($event)"
            (rowClicked)="onCategoryGridRowClicked($event)"
            >
          </ag-grid-angular>
        </div>
      </mat-tab>
      <mat-tab label="브랜드">
        <div class="mt5">
          <div class="fl w70p">
            <!-- <span class="">선택항목을 : </span>
            <div class="btn-group">
              <button type="button" [ngClass]="{active: params.brand_status=='W'}" (click)="params.brand_status = 'W'" class="btn btn-default f12">대기</button>
              <button type="button" [ngClass]="{active: params.brand_status=='P'}" (click)="params.brand_status = 'P'" class="btn btn-default f12">진행중</button>
              <button type="button" [ngClass]="{active: params.brand_status=='C'}" (click)="params.brand_status = 'C'" class="btn btn-default f12">완료</button>
            </div>
            <button type="button" class="btn btn-primary btn-small ml5" (click)="setEventSuperSaleBrandSave()">저장</button> -->
            <input type="text" id="brandQuickFilter" size="30" (input)="onBrandQuickFilterChanged($event)" class="form-control form-control-small ml5" placeholder="모든 필드 검색 필터링" />
          </div>
          <div class="fr w30p right">
            <btn-ag-grid-save [gridColumnsApi]="gridColumnApiBrand" gridId="c968e0d101ca47a288591102b1c1f92b" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <div class="mt5" style="height:calc(100% - 50px)">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefsBrand"
            [defaultColDef]="defaultColDef"
            [rowData]="brandList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [getRowNodeId]="getRowNodeId"

            (gridReady)="onGridReadyBrand($event)"
            (rowClicked)="onBrandGridRowClicked($event)"
            >
          </ag-grid-angular>
        </div>
      </mat-tab>
      <mat-tab label="전체상품">
      </mat-tab>
      <mat-tab label="메인표시 순서 업로드">
        <ng-container *ngIf="eventInfo.event_type=='1000'; else notSuperSale;">

          <form [formGroup]="form">

            <table class="table table-bordered table-small mt10 mb0">
            <caption class="none">메인표시 순서 업로드</caption>
            <colgroup>
              <col style="width:30%;" />
              <col style="width:70%;" />
            </colgroup>
        
            <tbody>
              <tr>
                <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="file">엑셀양식</label></th>
                <td>
                  <a [href]="baseURL + '/api/file/file_download.php?seq=1'" class="btn btn-default btn-small f12 ml10" target="_blank">다운로드</a>
                </td>
              </tr>
            <tr>
              <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="file">파일등록</label></th>
              <td>
                <!-- <inputEx type="file" name="file" [formGroup]="form" [formErrors]="formErrors" ng2FileSelect (change)="handleUploadFileChanged($event)"></inputEx> -->
                <input type="file" name="file" [(ngModel)]="file" [ngModelOptions]="{standalone: true}" class="form-control form-control-small" (change)="handleUploadFileChanged($event)" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"> 
              </td>
            </tr>
            </tbody>
            </table>
        
          </form>
        
        </ng-container>
        <ng-template #notSuperSale>
          슈퍼세일 전용 기능입니다.
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </as-split-area>
  <as-split-area size="77" minSize="65" maxSize="85">
    <div class="right mt5">
      <div class="fl form-inline">
        <input type="text" [(ngModel)]="sortOrder" (keypress)="numberOnly($event)" size="15" class="form-control form-control-small" placeholder="순서 번호" />

        <button type="button" class="btn btn-success btn-small ml5" (click)="sortApplyAll('')" >순서일괄적용</button>
        <button type="button" class="btn btn-success btn-small ml5" (click)="sortApplyAll('desc')" >내림차순적용</button>
      </div>
      <div class="fr right">
        <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="a117b70c05db4a0b93a73c9cfebf373b" [btnGroup]="1"></btn-ag-grid-save>
        <button type="button" class="btn btn-success btn-small ml5" (click)="setEventPriceChange()" >이벤트가격 일괄변경</button>
        <!-- <ng-container *ngIf="params.isEventSave == '1'"> -->
          <button type="button" class="btn btn-primary btn-small ml5" (click)="setEventProductSortSave()">순서 저장</button>
        <!-- </ng-container> -->
        <button type="button" class="btn btn-danger btn-small ml5" (click)="setEventProductDiscountDelete()">할인정보 삭제</button>
        <button type="button" class="btn btn-primary btn-small ml5" (click)="setEventProductDiscountSave()">할인정보 저장</button>
      </div>
      <div class="cb"></div>
    </div>
    <div class="right mt5">
      <div class="fl form-inline">
        <div class="btn-group">
          <button type="button" [ngClass]="{active: saleType.discount}" (click)="saleType.discount = true" class="btn btn-default btn-small">할인</button>
          <button type="button" [ngClass]="{active: !saleType.discount}" (click)="saleType.discount = false" class="btn btn-default btn-small">적립</button>
        </div>

        <div class="btn-group ml5">
          <button type="button" [ngClass]="{active: !saleType.rate}" (click)="saleType.rate = false" class="btn btn-default btn-small">금액</button>
          <button type="button" [ngClass]="{active: saleType.rate}" (click)="saleType.rate = true" class="btn btn-default btn-small">비율</button>
        </div>

        <input type="text" [(ngModel)]="saleType.value" (keypress)="numberOnly($event)" class="form-control form-control-small ml5" placeholder="금액 or 비율" />{{!saleType.rate ? '원' : '%'}}

        <button type="button" class="btn btn-success btn-small ml5" (click)="applyDiscountAll()" >할인/적립 일괄적용</button>
        <button type="button" class="btn btn-warning btn-small ml5" (click)="applyCompensationAll()" >보상금액 일괄적용</button>

        <div class="btn-group ml5">
          <button type="button" [ngClass]="{active: params.isEventSave==''}" (click)="setIsEventSave('')" class="btn btn-default f12">전체</button>
          <button type="button" [ngClass]="{active: params.isEventSave=='0'}" (click)="setIsEventSave('0')" class="btn btn-default f12">대기</button>
          <button type="button" [ngClass]="{active: params.isEventSave=='1'}" (click)="setIsEventSave('1')" class="btn btn-default f12">완료</button>
        </div>

        <input type="text" size="35" [(ngModel)]="params.searchText" (keypress)="setSearchList($event)" class="form-control form-control-small ml5" placeholder="상품번호, 상품명, 카테고리, 브랜드 검색" />

        <button type="button" class="btn btn-default btn-small f12 ml5" (click)="getEventSuperSaleProductList()">검색</button>

      </div>
      <div class="cb"></div>
    </div>

    <div class="mt5" style="height:calc(100% - 85px)">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="productList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelectionProduct"
        [rowDragManaged]="true"
        [enableMultiRowDragging]="true"
        [animateRows]="true"
        [components]="components"
        [rowHeight]="getRowHeight"
        [singleClickEdit]="true"
        [stopEditingWhenGridLosesFocus]="true"
        [rowClassRules]="rowClassRules"

        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        >
      </ag-grid-angular>
    </div>
  </as-split-area>
</as-split>
