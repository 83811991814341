import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { MemberService } from '@app/service/member.service';

@Component({
  selector: 'app-bike-payhelper-detail-print',
  templateUrl: './bike-payhelper-detail-print.component.html',
  styleUrls: ['./bike-payhelper-detail-print.component.scss']
})
export class BikePayhelperDetailPrintComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() helperInfo: any;
  @Input() helperPayList: any;
  
  public baseURL = config.baseUrl;

  public memberInfo: any = {};

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private memberService: MemberService
  ) {
    var afterPrint = function() {
      window.location.reload();
    };

    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener((mql: any) => {
        if (mql.matches) {
        } else {
          window.location.reload();
        }
      });
    }

    window.onafterprint = afterPrint;
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    if( this.helperInfo !== undefined ) {
      this.getMemberDetail();
    }
  }

  /*******************************************************************************
    설  명 : 휴대폰번호 하이픈 추가
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  setHyphen(value: any) {
    if( !value || value === null ) return null;
    if( value.indexOf('-') > -1 ) return value;

    return `${value.substr(0,3)}-${value.substr(3,4)}-${value.substr(7,4)}`;
  }

  /*******************************************************************************
    설  명 : 판매자 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberDetail() {
    this.memberService.getMemberDetail( this.helperInfo.seller, false ).then( response => {
      if( response.ResultCode ) {
        this.memberInfo = response.data;
      } else {
        this.memberInfo = {};
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 출력하기
  *******************************************************************************/
  print() {
    let mode = 'portrait'
    const Maindiv = document.createElement('div')
    Maindiv.style.cssText = 'width: 100%; height: 100%; position: absolute; z-index: 9999; top: 0; left: 0; background-color: #fff; padding: 10px;'

    const item = document.getElementById('print_area').cloneNode(true)
    Maindiv.appendChild(item)
    const app_root = document.querySelector<HTMLElement>('app-root') // as HTMLCollectionOf<HTMLElement>
    app_root.style.cssText = "display: none;"
    const modal_dialog = document.querySelector<HTMLElement>('.modal-dialog')
    modal_dialog.style.cssText = "display: none;"

    document.body.prepend(Maindiv);
    window.onafterprint = () => {
      document.body.removeChild(Maindiv)
      app_root.style.cssText = "display: initial;"
      modal_dialog.style.cssText = "display: flex;"
    }

    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';
    style.appendChild(document.createTextNode(`@page { size: ${mode}; }`));
    head.appendChild(style);
    window.print()
  }
}
