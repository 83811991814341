<section style="height: 100%;">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
            <i class="material-icons-outlined">refresh</i>
          </button>

          <select id="why" class="form-control form-control-small ml5" (change)="setValue($event)">
            <option>반품사유 선택</option>
            <option value="11">[11] 사이즈교환 반품</option>
            <option value="12">[12] 색상교환 반품</option>
            <option value="13">[13] 단순변심 반품</option>
            <option value="14">[14] 업체배송 오류</option>
            <option value="15">[15] 제품불량 반품</option>
            <option value="18">[18] 단순변심 교환</option>
            <option value="19">[19] 재고부족 교환</option>
            <option value="21">[21] 재고부족 취소</option>
            <option value="22">[22] 입고지연 취소</option>
            <option value="23">[23] 단순변심 취소</option>
            <option value="31">[31] 차액환불</option>
            <option value="98">[98] 기타 반품</option>
            <option value="99">[99] 기타 취소</option>
          </select>
          
          <select id="searchField" class="form-control form-control-small ml5" (change)="setValue($event)">
            <option>검색조건 선택</option>
            <option value="mem_name">주문자 이름</option>
            <option value="id">주문자 ID</option>
            <option value="product_name">상품명</option>
            <option value="return_invoice">수령송장</option>
            <option value="send_invoice">반납송장</option>
          </select>
          <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="form.value.searchText" (keypress)="searchList($event)" placeholder="검색어 입력" />
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getReturnProduct()">
            <i class="material-icons-outlined">search</i>
          </button>

        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="87bdb84a009943d99580591dcbca9eae" [btnGroup]="1"></btn-ag-grid-save>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 80px)">
        <ag-grid-angular
          #myGrid
          
          style="width: 100%; height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="form.value.rowData"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"
          [getRowHeight]="getRowHeight"

          (cellClicked)="onCellClicked($event)"
          (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
      
  <section class="pagination mt10">
    <ngb-pagination
      [(page)] = "search.index"
      [pageSize] = "1000"
      [collectionSize] = "search.count"
      [maxSize] = "10"
      [rotate] = "true"
      [boundaryLinks] = "true"
      (pageChange) = "pageChange($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section>

</section>
