/*******************************************************************************
  설  명 : 쿠폰실적정산
  작성일 : 
  작성자 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { UtilService } from '@app/service/util.service';
import { AccountService } from '@app/service/account.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { AccountCouponAddComponent } from '@page/account/account-coupon/account-coupon-add/account-coupon-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-account-coupon',
  templateUrl: './account-coupon.component.html',
  styleUrls: ['./account-coupon.component.scss']
})
export class AccountCouponComponent implements OnInit {
  
  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  private subscription: Subscription = new Subscription;

  public couponList: any = [];
  public couponOrderList: any = [];
  public couponOrderStatistic: any = {
    orderCount: 0,
    productCount: 0,
    totalAmtSum: 0,
    compensationAmtSum: 0
  };

  public couponSelected: any = {};

  public search: any = {
    coupon_seq: '',
    field: 'order',
    date: null,
    pay_yn: ''
  };  

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  gridApiOrder: any;
  gridColumnApiOrder: any;  
  columnDefsOrder: any;
  
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  deleteColumnDefs: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private accountService: AccountService,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '쿠폰코드', field: 'coupon_code', width: 150, cellClass: 'cp left'},
      { headerName: '아이디', field: 'id', width: 150, cellClass: 'cp left',
        valueGetter: function(params: any) {
          return `${params.data.name}(${params.data.id})`;
        }      
      },
    ];

    this.columnDefsOrder = [
      { headerName: '', field: '', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true 
      },
      { headerName: '상태', field: 'order_status', width: 90, cellClass: 'cp center',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params: any) => {
          let className: any;
          switch(params.data.order_status) {
            case '8': className = 'primary'; break;
            case '3': className = 'danger'; break;
            case '4': className = 'danger'; break;
            case '5': className = 'warning'; break;
            case '6': className = 'warning'; break;
            case '9': className = 'info'; break;
            case '1': className = 'dark'; break;
            default: className = 'secondary';
          }
          return `<span class="badge badge-${className} f11">${params.data.order_status_name}</span>`;
        }
      },
      { headerName: '주문번호', field: 'master_seq', width: 92, cellClass: 'cp center' },
      { headerName: '주문일시', field: 'order_date', width: 142, cellClass: 'cp center' },
      { headerName: '입금일자', field: '', width: 92, cellClass: 'cp center' },
      { headerName: '배송일자', field: '', width: 92, cellClass: 'cp center' },
      { headerName: '반품일자', field: '', width: 92, cellClass: 'cp center' },
      { headerName: '상품번호', field: 'product_seq', width: 92, cellClass: 'cp center' },
      { headerName: '수량', field: 'qty', width: 68, cellClass: 'cp right' },
      { headerName: '판매금액', field: 'amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '총금액', field: 'total_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '보상금액', field: 'compensation_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '정산여부', field: 'pay_yn', width: 92, cellClass: 'cp center',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if( params.data.pay_yn == '1' ) return `<span class="badge badge-success f12">정산완료</span>`;
          else return `<span class="badge badge-secondary f12">미정산</span>`;
        }      
      },
      { headerName: '정산일자', field: 'calculate_date', width: 92, cellClass: 'cp center' },
    ];


    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
   }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.subscription = this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.search.field = ( typeof params.field == 'undefined' || params.field == '' ) ? '' : params.field;
        this.search.date = ( typeof params.date == 'undefined' || params.date == '' ) ? this.utilService.getDate('') : params.date;
        this.search.pay_yn = ( typeof params.pay_yn == 'undefined' || params.pay_yn == '' ) ? '' : params.pay_yn;

        if( this.search.date != '' ) this.search.date = this.utilService.getDate(this.search.date);
      }

      // 쿠폰목록 리스트 가져오기
      this.getAccountCouponCodeList();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 쿠폰목록 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAccountCouponCodeList() {
    this.accountService.getAccountCouponCodeList(this.search).then( response => {
      if( response.ResultCode ) {
        this.couponList = response.data;

        if( this.couponList.length > 0 ) {
          this.couponSelected = this.couponList[0];

          if( this.couponSelected.seq === undefined ) {
            let index = 0;
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter(function(node: any) {
                // select the node
                if( index++ == 0 ) {
                  node.setSelected(true);
                }
              });
            }, 0);
          } else {
            const selectedSeq = this.couponSelected.seq;
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter(function(node: any) {
                // select the node
                if( node.data.seq == selectedSeq ) {
                  node.setSelected(true);
                }
              });
            }, 0);
          }

          this.search.coupon_seq = this.couponSelected.seq;
          this.getAccountCouponOrderList();
        }
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 쿠폰 적용 주문목록 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getAccountCouponOrderList() {
    this.accountService.getAccountCouponOrderList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.couponOrderList = response.data;
        this.couponOrderStatistic = response.statistic;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowClicked( event: any ) {
    this.couponSelected = event.data;

    this.search.coupon_seq = this.couponSelected.seq;
    this.getAccountCouponOrderList();
  }
  
  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.gridApi.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReadyOrder(params: any) {
    this.gridApiOrder = params.api;
    this.gridColumnApiOrder = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 정산처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openAccountCouponCalc() {
    const nodes: any = this.gridApiOrder.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.info('정산 처리할 내역을 선택하시기 바랍니다.');
      return;
    }
    
    const seq = nodes.map((row: any) => row.seq);

    const modalRef = this.modalService.open(AccountCouponAddComponent, options);

    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      if( result ) this.getAccountCouponOrderList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 정산 취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setAccountCouponCalcCancel() {
    const nodes: any = this.gridApiOrder.getSelectedRows();

    if ( nodes.length < 1 ) {
      this.toastrService.info('정산 취소할 내역을 선택하시기 바랍니다.');
      return;
    }

    let isCalcCheck: boolean = false;
    let seq: any = [];
    nodes.map((row: any) => {
      if( row.pay_yn != '1' ) isCalcCheck = true;

      seq.push(row.seq);
    });

    if( isCalcCheck ) {
      this.toastrService.info('미정산인 내역이 포함되어 있습니다.');
    } else {
        if( confirm("선택한 내역들을 정산 취소하시겠습니까?")) {
        this.accountService.setAccountCouponCalcCancel(seq).then( response => {
          if (response.ResultCode) {
            this.toastrService.success(response.ResultMessage);
            this.getAccountCouponOrderList();
          } else {
            this.toastrService.error(response.ResultMessage);
          };
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 날짜 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectDate(date: any) {
    this.search.date = date;

    this.getAccountCouponOrderList();
  }

  /*******************************************************************************
    설  명 : 검색조건 변경 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearch(key: any, value: any) {
    this.search[ key ] = value;

    this.getAccountCouponOrderList();
  }
  
  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      coupon_seq: '',
      field: 'order',
      date: null,
      pay_yn: ''
    };

    this.search.coupon_seq = this.couponSelected.seq;
    this.getAccountCouponOrderList();
  }
  
}
