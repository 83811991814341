/*******************************************************************************
  설  명 : SMS/이메일 거부 관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { MemberService } from '@app/service/member.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

import { MemberRefusalAddComponent } from '@page/member/member-refusal/add/add.component';

import * as moment from 'moment';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-refusal',
  templateUrl: './member-refusal.component.html',
  styleUrls: ['./member-refusal.component.scss']
})
export class MemberRefusalComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    searchText: '',
    searchTerm: 'A',
    sdate: '',
    edate: ''
  };

  public statistic: any = {
    total_count: 0,
    search_count: 0,
    sms_count: 0,
    email_count: 0
  }

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '이번달', value: this.getDays(0)},
    {title: '1개월', value: this.getDays(1)},
    {title: '2개월', value: this.getDays(2)},
    {title: '3개월', value: this.getDays(3)},
    {title: '6개월', value: this.getDays(6)},
  ];

  public components: any;

  // 그리드 관련 선언
  memberRefusalList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent,
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private memberService: MemberService,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '구분', field: 'member_memo_seq', width: 100, cellClass: 'cp', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.member_memo_seq != null ) {
            return '<span class="badge badge-primary f12">상담등록</span>';
          } else {
            return '<span class="badge badge-secondary f12">직접등록</span>';
          }
        }
      },
      { headerName: '아이디', field: 'id', width: 100, cellClass: 'cp' },
      { headerName: '이름', field: 'name', width: 100, cellClass: 'cp' },
      { headerName: '휴대폰', field: 'hp', width: 120, cellClass: 'cp' },
      { headerName: '이메일', field: 'email', width: 150, cellClass: 'cp' },
      { headerName: '메모', field: 'memo', width: 500, cellClass: 'cp' },
      { headerName: '등록자', field: 'writer', width: 150, cellClass: 'cp center' },
      { headerName: '등록일시', field: 'write_date', width: 150, cellClass: 'cp center' },
      { headerName: '수정자', field: 'writer', width: 150, cellClass: 'cp center' },
      { headerName: '수정일시', field: 'change_date', width: 150, cellClass: 'cp center' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      editable: false
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      numericCellEditor: this.agGridExComponent.getNumericCellEditor(),
      datePicker: this.agGridExComponent.getDatePicker(),
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getMemberRefusalList();
  }

  /*******************************************************************************
    설  명 : SMS/이메일거부 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberRefusalList() {
    this.memberService.getMemberRefusalList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.memberRefusalList = response.data;
        this.statistic = response.count;
      } else {
        this.toastrService.error( response.ResultMessage, 'SMS/이메일 수신거부');
      }
    }, error => {
      this.toastrService.error( error, 'SMS/이메일 수신거부');
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked(event) {
    this.addRefusal( event.data.seq );
  }

  /*******************************************************************************
    설  명 : 데이터 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addRefusal( refusalSeq: number ) {
    const modalRef = this.modalService.open(MemberRefusalAddComponent, options);

    modalRef.componentInstance.seq = refusalSeq;

    modalRef.result.then((result) => {
      if( result ) {
        this.getMemberRefusalList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      searchTerm: 'A',
      sdate: '',
      edate: ''
    };

    this.getMemberRefusalList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberRefusalList();
    }
  }

  /*******************************************************************************
    설  명 : input date 선택 시 처리
    입력값 : obj, check = true sdate, false edate
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }

    obj.close();
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(month: number) {
    const todaysDate = moment();
    let toDate;
    if (month === 0) {
      toDate = moment().startOf('month');
    } else {
      toDate = moment().add(month * -1, 'months');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
    this.getMemberRefusalList();
  }

}
