<section id="member-company">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체<span class="cnt">{{statistic.total_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{statistic.search_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">상사<span class="cnt">{{statistic.company_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">오일협력점<span class="cnt">{{statistic.oil_count | number}}</span>개</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="mb5">
    <div class="fl w60p form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberShopList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
        <option value="shop_name">상호명</option>
        <option value="license">사업자번호</option>
        <option value="shop_email">이메일주소</option>
        <option value="shop_fax">팩스</option>
        <option value="shop_phone">연락처</option>
        <option value="shop_hp">휴대폰</option>
        <option value="address">주소</option>
      </select>

      <input type="text" size="30" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="검색어 입력" />

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.oilCheck==''}" (click)="search.oilCheck='';getMemberShopList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.oilCheck=='Y'}" (click)="search.oilCheck='Y';getMemberShopList();">오일협력점</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.oilCheck=='N'}" (click)="search.oilCheck='N';getMemberShopList();">일반</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.search_use==''}" (click)="search.search_use='';getMemberShopList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.search_use=='1'}" (click)="search.search_use='1';getMemberShopList();">사용</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.search_use=='0'}" (click)="search.search_use='0';getMemberShopList();">미사용</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.sale_year==''}" (click)="search.sale_year='';getMemberShopList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.sale_year=='1'}" (click)="search.sale_year='1';getMemberShopList();">1년</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.sale_year=='2'}" (click)="search.sale_year='2';getMemberShopList();">2년</button>
        <span class="ml5 mt5">이내 매출</span>
      </div>


    </div>
    <div class="fr w40p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="d9349a54f77a4c88be4fbdf043c0ead3" [btnGroup]="1"></btn-ag-grid-save>
      <ag-grid-excel [gridApi]="gridApi" type="" selected="false" filename="상사회원 리스트" title="상사회원 리스트"></ag-grid-excel>
      <button type="button" class="btn btn-success btn-small ml5" (click)="setOilCheckChange()">오일협력점 전환</button>
      <button type="button" class="btn btn-danger btn-small ml5" (click)="setMemberNormalChange()">일반전환</button>
      <button type="button" title="상사추가" class="btn btn-primary btn-small ml5" (click)="addMember(0)">상사추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100% - 70px);background-color:#fff;">
    <as-split-area size="60" minSize="40" maxSize="80">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="companyList"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (rowDoubleClicked)="onRowDoubleClicked($event)"
        (rowClicked)="onRowClicked($event)"
        (gridReady)="onGridReady($event)"
        >
      </ag-grid-angular>
    </as-split-area>
    <as-split-area size="40" minSize="20" maxSize="60">
      <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
        <as-split-area size="60" minSize="50" maxSize="80">
          <div class="mb5">
            <div class="fl w50p">
              <h4 class="mb0 fb">영업상담 이력</h4>
            </div>
            <div class="fr w50p right">
              <button type="button" title="영업상담등록" class="btn btn-primary btn-small ml5" (click)="addConsultation(0)">영업상담등록</button>
            </div>
            <div class="cb"></div>
          </div>
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:calc(100% - 55px);"
            class="ag-theme-balham"

            [columnDefs]="columnDefsConsultation"
            [defaultColDef]="defaultColDef"
            [rowData]="consultationList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [rowHeight]="150"

            (gridReady)="onGridReadyCharge($event)"
            (rowDoubleClicked)="onRowDoubleClickedConsultation($event)"
            >
          </ag-grid-angular>
        </as-split-area>
        <as-split-area size="40" minSize="20" maxSize="50">
          <div class="mb5">
            <div class="fl w50p">
              <h4 class="mb0 fb">상사 담당자</h4>
            </div>
            <div class="fr w50p right">
              <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="ae4056dd90854bf09718ee9e4f972a0a" [btnGroup]="1"></btn-ag-grid-save>
              <button type="button" title="상사담당자추가" class="btn btn-primary btn-small ml5" (click)="addCharge(0)">상사 담당자 추가</button>
            </div>
            <div class="cb"></div>
          </div>
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:calc(100% - 55px);"
            class="ag-theme-balham"

            [columnDefs]="columnDefsCharge"
            [defaultColDef]="defaultColDef"
            [rowData]="chargeList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"

            (gridReady)="onGridReadyCharge($event)"
            (rowDoubleClicked)="onRowDoubleClickedCharge($event)"
            >
          </ag-grid-angular>
        </as-split-area>
      </as-split>   
    </as-split-area>   
  </as-split>  
</section>
