import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class PosService {

  public isSetup: boolean = false;

  constructor(
    private restful: RestfulService,
  ) {
  }

  // POS 정보 저장하기
  setPos( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'setPos'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 정보 삭제
  setPosDelete( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'setPosDelete'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 권한 저장
  savePosAuthMember(form): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'savePosAuthMember'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 권한 삭제
  setPosAuthMemberDelete(form): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'setPosAuthMemberDelete'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 권한 사용자 리스트
  getPosAuthMemberList(pos_id: string): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'getPosAuthMemberList'
      }, {
        pos_id: pos_id
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 정보 리스트 가져오기
  getPosList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'getPosList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 정보 리스트 가져오기
  getPosAuthGroupList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'getPosAuthGroupList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 권한그룹 정보 저장하기
  setPosAuthGroup( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'setPosAuthGroup'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 권한그룹 정보 저장하기
  setPosAuthGroupDelete( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'setPosAuthGroupDelete'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 권한그룹 리스트 가져오기
  getPosAuthList(auth_group_id, site_id): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'getPosAuthList'
      }, {
        auth_group_id: auth_group_id,
        site_id: site_id
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // POS 권한 정보 저장하기
   savePosMenuAuths( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'pos',
        version: '1.0',
        action: 'savePosMenuAuths'
      }, form
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
}
