  <section class="search-condition mb5">
    <div class="search-cnt fl pt8">
      게시판 : {{boardList.length}}개
    </div>

    <div class="fr">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="8d26e4b4c40c4f18a6fed1cf4da5e35d" [btnGroup]="1"></btn-ag-grid-save>
      <!-- <ng-container *ngIf="authService.currAuth['auth_write']=='1'">
        <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="addBoardInfo(0)">게시판 추가</button>
      </ng-container> -->
      <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="addBoardInfo(0)">게시판 추가</button>
    </div>

    <div class="cb"></div>
  </section>

  <section class="contents-body" style="height:calc(100% - 35px);">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="boardList"
      [pagination]="false"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [rowSelection]="rowSelection"
      [frameworkComponents]="frameworkComponents"

      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
    >
    </ag-grid-angular>
  </section>