<form [formGroup]="formTable" (ngSubmit)="submit()">
  <inputEx type="hidden" name="type" [formGroup]="formTable" [formErrors]="formErrors"></inputEx>

  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table class="table table-bordered table-small mb5">
    <caption class="hidden">{{title}}</caption>

    <colgroup>
      <col style="width:30%;" />
      <col style="width:70%;" />
    </colgroup>

    <tbody>
    <tr>
      <th scope="row"><label for="table_name" class="control-label">테이블명</label></th>
      <td><inputEx type="text" name="table_name" [formGroup]="formTable" [formErrors]="formErrors"></inputEx></td>
    </tr>
    </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">
    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="submit" class="btn btn-primary btn-small mr10 f15">저장하기</button>
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">취소하기</button>
    </div>
    <div class="cb"></div>
  </div>
</form>