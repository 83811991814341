<div class="modal-header">
  <h4 class="modal-title fb mb0">실사계획 {{data.seq ? '수정' : '추가'}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <table class="table table-bordered table-small mb0">
  <caption class="hidden"></caption>

  <colgroup>
    <col style="width:25%;" />
    <col style="width:75%;" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label class="control-label">실사연도</label></th>
    <td>
      <input type="text" class="form-control form-control-small" [(ngModel)]="data.year" placeholder="YYYY 형식">
    </td>
  </tr>
  <tr>
    <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label class="control-label">실사제목</label></th>
    <td>
      <input type="text" class="form-control form-control-small" [(ngModel)]="data.title">
    </td>
  </tr>
  <tr>
    <th scope="row"><label class="control-label">실사시작일</label></th>
    <td>
      <input type="text" size="13" readonly="readonly" [value]="data.sdate" class="form-control form-control-small calendar-input" placeholder="" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect) = "selectDate('sdate', $event)" />
    </td>
  </tr>
  <tr>
    <th scope="row"><label class="control-label">실사종료일</label></th>
    <td>
      <input type="text" size="13" readonly="readonly" [value]="data.edate" class="form-control form-control-small calendar-input" placeholder="" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect) = "selectDate('edate', $event)" />
    </td>
  </tr>
  <tr>
    <th scope="row"><label class="control-label">실사메모</label></th>
    <td>
      <input type="text" class="form-control form-control-small" [(ngModel)]="data.memo">
    </td>
  </tr>
  </tbody>
  </table>
</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="button" class="btn btn-small blue-btn" (click)="submit()">저장</button>
    <button type="button" class="btn btn-default btn-small ml5" aria-label="Close" (click)="activeModal.dismiss()">취소</button>
  </div>
</div>