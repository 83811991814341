<section class="widget-div">
  <div class="fl widget cp" style="height: 70px; width: 30%; margin-bottom: 5px;">
    <div class="fl widget-icon widget-icon{{ (0 % 3) + 1 }}">
      <i class="material-icons">view_list</i>
    </div>
    <div class="fr widget-txt">
      <p>주문에누리</p>
      <p class="txt1">합계 <span class="cnt">{{orderMasterDiscountAmount | number}}</span>원</p>
    </div>
    <div class="cb"></div>
  </div>

  <div class="cb"></div>
</section>

<div class="form-inline mb5" style="position: relative;">
  <button type="button" class="btn btn-primary btn-small fr" style="position: absolute; right: 0; top: 0;" (click)="moveSimplePage()">매출현황상세</button>

  <div class="btn-group">
    <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.storeSeq==''}" (click)="search.storeSeq='';getSalesStatusList();">전체</button>
    <button type="button" *ngFor="let item of storeList" class="btn btn-default btn-small" [ngClass]="{active: search.storeSeq==item.seq}" (click)="search.storeSeq=item.seq;getSalesStatusList();">{{item.store_name}}</button>
  </div>

  <l-select2
    [data]="selectCategoryList"
    (ngModelChange)="onSelectCategoryChanged($event)"
    [(ngModel)]="search.category_select"
    class="form-control form-control-small ml5"
    style="width:200px; padding:0px !important;border:solid 0px #fff !important;"
  ></l-select2>

  <l-select2
    [data]="selectBrandList"
    (ngModelChange)="onSelectBrandChanged($event)"
    [(ngModel)]="search.brand_select"
    class="form-control form-control-small ml5"
    style="width:200px; padding:0px !important;border:solid 0px #fff !important;"
  ></l-select2>

</div>

<div class="fl w80p form-inline mb5">
  <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
    <i class="material-icons-outlined">refresh</i>
  </button>
  <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getSalesStatusList()">
    <i class="material-icons-outlined">search</i>
  </button>

  <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
  <ng-template #footerTemplate1>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 'sdate')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
  </ng-template>
  <span class="ml5 mr5">~</span>
  <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
  <ng-template #footerTemplate2>
    <hr class="my-0">
    <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 'edate')">오늘</button>
    <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
  </ng-template>

  <div class="btn-group ml5">
    <button *ngFor="let item of term" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchTerm==item.value}" (click)="onSearchClick(item.value);getSalesStatusList();">{{item.title}}</button>
  </div>

  <select id="searchField" [(ngModel)]="search.searchField" class="form-control form-control-small ml5">
    <option value="product_name">상품명</option>
  </select>

  <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="검색어" />

  <select id="sort_order" [(ngModel)]="search.sort_order" class="form-control form-control-small ml5" (ngModelChange)="sortChanged($event)">
    <option value="amt">판매금액 기준정렬</option>
    <option value="qty">판매수량 기준정렬</option>
    <option value="buy_price">구매금액 기준정렬</option>
  </select>
</div>

<div class="fr">
  <button type="button" class="btn btn-primary btn-small" (click)="excelDownload()">엑셀 다운로드</button>
</div>

<div class="fl w100p" style="min-width:100px;">
  <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="onTabChange($event)">
    <mat-tab label="상품별" style="min-width:100px;">
      <div class="mt5">
        <ag-grid-angular
          #myGrid
    
          style="width:100%; height:680px;"
          class="ag-theme-balham"
    
          [columnDefs]="productColumnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="salesStatusList"
          [pagination]="false"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (gridReady)="onGridReady($event)"
          [pinnedBottomRowData]="pinnedBottomRowData"
          >
        </ag-grid-angular>
      </div>

      <!-- <section class="pagination mt10" *ngIf="totalCount">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="totalCount"
          [maxSize]="20"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </section> -->
      
    </mat-tab>

    <mat-tab label="옵션별" style="min-width:100px;">
      <div class="mt5">
        <ag-grid-angular
          #myGrid
    
          style="width:100%; height:680px;"
          class="ag-theme-balham"
    
          [columnDefs]="optionColumnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="salesStatusList"
          [pagination]="false"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (gridReady)="onGridReady($event)"
          [pinnedBottomRowData]="pinnedBottomRowData"
          >
        </ag-grid-angular>
      </div>

      <!-- <section class="pagination mt10" *ngIf="totalCount">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="totalCount"
          [maxSize]="20"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </section> -->

    </mat-tab>

    <mat-tab label="브랜드별" style="min-width:100px;">
      <div class="mt5">
        <ag-grid-angular
          #myGrid
    
          style="width:100%; height:680px;"
          class="ag-theme-balham"
    
          [columnDefs]="brandColumnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="salesStatusList"
          [pagination]="false"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (gridReady)="onGridReady($event)"
          [pinnedBottomRowData]="pinnedBottomRowData"
          >
        </ag-grid-angular>
      </div>

      <!-- <section class="pagination mt10" *ngIf="totalCount">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="totalCount"
          [maxSize]="20"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </section> -->
      
    </mat-tab>
    
  </mat-tab-group>
</div>

