/*******************************************************************************
  설  명 : 재고관리 > 출고관리 > 출고지시서 탭
  작성일 : 2020-08-14
  작성자 : 송영석
  접속URL : /stock/out/management

  수정일 : 2021.06.02
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { StockOut } from '@app/service/stockOut.service';
import { ModalDeliveryComponent } from '../modal-delivery/modal-delivery.component';

@Component({
  selector: 'app-stock-out-delivery-order',
  templateUrl: './stock-out-delivery-order.component.html',
  styleUrls: ['./stock-out-delivery-order.component.scss']
})
export class StockOutDeliveryOrderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;

  domLayout: any;
  frameworkComponents = this.stockOrderComponent.frameworkComponents;
  defaultColDef = {...this.stockOrderComponent.defaultColDef};
  rowSelection = this.stockOrderComponent.rowSelection;
  noRowsTemplate = this.stockOrderComponent.noRowsTemplate;
  components = this.stockOrderComponent.components;

  public memberInfo: any = {};

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private stockOrderComponent: StockOrderComponent,
    private stockOut: StockOut,
    private authService: AuthService,
    private modalService: NgbModal,
  ) {
    this.buildForm();

    this.columnDefs = [
      this.stockOrderComponent.firstColumn,
      {headerName: '출고번호', field: 'mng_out_seq', width: 100, cellClass: 'center' },
      {headerName: '주문번호', field: 'order_master_seq', width: 100, cellClass: 'cp center' },
      {headerName: '매입구분', field: 'product_gbn', width: 100, cellClass: 'center' },
      {headerName: '출고상태', field: 'mng_out_status_name', cellClass: 'center', cellRenderer: 'agGridHtmlComponent', width: 100,
        valueGetter: (params) => params.data.mng_out_status_name == '출고완료' ? '<span class="badge badge-success f11">출고완료</span>' : '<span class="badge badge-secondary f11">출고대기</span>'
      },
      {headerName: '주문일자', field: 'order_date', width: 145, cellClass: 'center' },
      {headerName: '주문자', field: 'o_name', width: 160, cellClass: 'center' },
      {headerName: '수신자', field: 'r_name', width: 110, cellClass: 'center' },
      {headerName: '주문수량', field: 'qty', width: 100, cellClass: 'right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '출고지시일', field: 'out_order_date', width: 100, cellClass: 'center' },
      // {headerName: '출고일자', field: 'out_date', width: 120, cellClass: 'center ag-cell-edit', editable: true, cellEditor: "datePicker" },
      {headerName: '출고일자', field: 'out_date', width: 145, cellClass: 'center' },
      {headerName: '출고지시수량', field: 'out_order_qty', width: 100, cellClass: 'right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
      // {headerName: '출고수량', field: 'out_qty', width: 100, cellClass: 'right ag-cell-edit', editable: true, cellRenderer: 'agGridHtmlComponent',
      {headerName: '출고수량', field: 'out_qty', width: 100, cellClass: 'right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '출고담당자', field: 'charger', width: 100, cellClass: 'center' },
      {headerName: '입점처리', field: '', width: 100, cellClass: 'center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => (params.data['product_gbn'] == '입점' || params.data['product_gbn'] == '직발') ? `<button type="button" class="btn btn-default btn-xsmall" style="margin-top: -5px">완료처리</button>` : '' }

    ]

    this.columnDefsDetail = [
      this.stockOrderComponent.firstColumn,
      {headerName: '출고상세번호', field: 'mng_out_order_seq', cellClass: 'cp center', width:100 },
      {headerName: '매입구분', field: 'product_gbn', width: 120, cellClass: 'cp center'},
      {headerName: '출고상태', field: 'mng_out_order_status_name', width: 80, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: (params) => params.data.mng_out_order_status_name == '출고완료' ? '<span class="badge badge-success f11">출고완료</span>' : '<span class="badge badge-secondary f11">출고대기</span>'
      },
      {headerName: '카테고리', field: 'category_name', width: 100, cellClass: 'cp center' },
      {headerName: '상품명', field: 'product_name', width: 100, cellClass: 'cp', flex: 1 },
      {headerName: '색상', field: 'display_color_name', width: 100, cellClass: 'cp center' },
      {headerName: '사이즈', field: 'display_size_name', width: 100, cellClass: 'cp center' },
      {headerName: '금액', field: 'total_amt', width: 100, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
      // {headerName: '주문일자', field: 'order_date', width: 120, cellClass: 'cp center' },
      // {headerName: '주문자', field: 'o_name', width: 100, cellClass: 'cp' },
      // {headerName: '수신자', field: 'r_name', width: 100, cellClass: 'cp' },
      // {headerName: '출고지시일자', field: 'out_order_date', width: 120, cellClass: 'cp center' },
      // {headerName: '출고일자', field: 'out_date', width: 120, cellClass: 'cp center' },
      {headerName: '주문수량', field: 'qty', width: 120, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)  },
      {headerName: '출고지시수량', field: 'out_order_qty', width: 120, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)  },
      {headerName: '출고수량', field: 'out_qty', width: 100, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
      // {headerName: '주소', field: 'r_address', width: 100, cellClass: 'cp' },
      // {headerName: '상세주소', field: 'r_address_detail', width: 100, cellClass: 'cp' }
      // {headerName: '입점처리', cellRenderer: 'agGridHtmlComponent',
      //   valueGetter: (params) => (params.data['product_gbn'] == '입점' && params.data['mng_out_order_status_name'] == '출고대기') ? `<button type="button" class="btn btn-default btn-xsmall" style="margin-top: -5px">완료처리</button>` : '' }

      {headerName: '관리', field: '', cellRenderer: 'agGridHtmlComponent', width: 120, cellClass: 'center',
        valueGetter: (params) => (params.data['mng_out_order_status_name'] != '출고완료') ? `<button type="button" class="btn btn-danger btn-xsmall" style="margin-top: -5px">출고지시취소</button>` : '' }
    ]
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    // this.getStockOutOrder();
    this.quickDate(30);
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      stockOutOrderList : [[]],
      stockOutOrderDetailList : [[]],

      buyType : [0],
      statusType : [0],
      s_date : [''],
      e_date : [''],
      date_btn : [0],
      search : ['']
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 출고지시서 가져오기
  *******************************************************************************/
  getStockOutOrder() {
    const _FV = this.form.value
    this.stockOut.getStockOutOrder({
      buyType : _FV['buyType'],
      statusType : _FV['statusType'],
      s_date : _FV['s_date'],
      e_date : _FV['e_date'],
      search : _FV['search']
    }).then(response => {
      this.form.patchValue({ stockOutOrderDetailList : [], search : _FV['search'] })
      if(response.ResultCode) this.form.patchValue({ stockOutOrderList : this.cooking(response.data), search : _FV['search'] })
      else this.toastrService.error(response.ResultMessage)
    });
  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  reset() {
    this.ngOnInit()
  }
  softReset() {
    this.getStockOutOrder()
  }

  /*******************************************************************************
    설  명 : 데이터 가공
  *******************************************************************************/
  cooking(data) {
    const _A = []
    data.forEach(e => {
      const _detail = {
        product_gbn : e['product_gbn'],
        mng_out_order_seq : e['mng_out_order_seq'],
        order_date : e['order_date'],
        o_name : e['o_name'],
        r_name : e['r_name'],
        qty : parseInt(e['qty']),
        total_amt : parseInt(e['total_amt']),
        r_address : e['r_address'],
        r_address_detail : e['r_address_detail'],
        category_name : e['category_name'],
        product_name : e['product_name'],
        display_color_name : e['display_color_name'],
        display_size_name : e['display_size_name'],
        color_name : e['color_name'],
        size_name : e['size_name'],
        thumbnail_url : e['thumbnail_url'],
        mng_out_order_status: e['mng_out_order_status'],
        mng_out_order_status_name : e['mng_out_order_status_name'],
        out_order_date : e['out_order_date'],
        out_date : e['out_date'],
        out_order_qty : parseInt(e['out_order_qty']),
        out_qty : parseInt(e['out_qty']),

        warehouse_seq : e['warehouse_seq'],
        warehouse_location_seq : e['warehouse_location_seq'],
        product_seq : e['product_seq'],
        product_property_seq : e['product_property_seq'],
        unit_price : e['unit_price'],
        order_master_seq : e['order_master_seq'],
        order_detail_seq : e['order_detail_seq'],
        mng_out_seq : e['mng_out_seq'],
        mng_out_delivery_seq : e['mng_out_delivery_seq']
      }
      const _index = _A.findIndex(_e => _e['mng_out_seq'] == e['mng_out_seq'])
      _index < 0
      ? _A.push({
        mng_out_seq : e['mng_out_seq'],
        mng_out_status_name : e['mng_out_status_name'],
        out_order_date : e['out_order_date'],
        out_date : e['out_date'],
        charger : e['charger'],
        order_master_seq : e['order_master_seq'],
        product_gbn : '',
        mng_out_delivery_seq : e['mng_out_delivery_seq'],

        order_date : e['order_date'],
        o_name : e['o_name'],
        r_name : e['r_name'],

        qty : 0,
        out_order_qty : 0,
        out_qty : 0,

        _detail : [_detail]
      })
      : _A[_index]['_detail'].push(_detail)
    })

    _A.forEach(e => {
      ['qty', 'out_order_qty', 'out_qty'].forEach(_e => {
        e[_e] = e['_detail'].reduce((a,c) => a + c[_e], 0)
      })

      e['product_gbn'] = '';
      ['입점','매입','직발'].forEach(__e => {
        if(e['_detail'].filter(_e => _e['product_gbn'] == __e).length) e['product_gbn'] += __e
      })
    })

    return _A
  }

  /*******************************************************************************
    설  명 : 데이터 선택시 상세 보여주기
  *******************************************************************************/
  onRowClicked($event) {
    this.form.patchValue({ stockOutOrderDetailList : $event.data['_detail'] });
  }

  /*******************************************************************************
    설  명 : 완료처리 버튼 클릭 (SCM 미사용 입점업체)
  *******************************************************************************/
  cellClick($event) {
    if( $event.colDef.headerName == '입점처리' ) {
      if( $event.data['product_gbn'] != '입점' && $event.data['product_gbn'] != '직발' ) return
      else if($event.data['mng_out_delivery_seq'] && !confirm('이미 완료처리하였습니다. \r\n그래도 실행하시겠습니까?')) return
      // else if(!$event.data['mng_out_delivery_seq'] && !confirm('입점 출고지시를 출고완료 상태로 변경합니다. \r\n(참고: 직발처리는 주문리스트에서 할 수 있습니다.) \r\n실행하시겠습니까?')) return
      this.outComplete($event.data)
    }

    if( $event.colDef.field === 'order_master_seq' ) {
      const url = '/order/detail?seq=' + $event.data.order_master_seq;
      window.open("about:blank").location.href = url;
    }
  }
  
  outComplete(data) {
    const options: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: '',
      centered: true,
      windowClass: 'modal-fadeInDown'
    }
    const modalRef = this.modalService.open(ModalDeliveryComponent, options);
    modalRef.componentInstance.mng_out_seq = data.mng_out_seq;
    modalRef.componentInstance.order_master_seq = data.order_master_seq;
    modalRef.componentInstance.mng_out_order_seq = data.mng_out_order_seq;
    modalRef.componentInstance.mng_out_delivery_seq = data.mng_out_delivery_seq;
    modalRef.result.then(result => {
      this.reset();
    }, (reason) => {})
  }

  /*******************************************************************************
    설  명 : 출고취소
  *******************************************************************************/
  setCancelDelivery() {
    const rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('출고취소할 내역을 선택하시기 바랍니다.');
    } else if( rows.length > 1 ) {
      this.toastrService.error('출고취소는 한 건씩 처리가 가능합니다.');
    } else {
      if( rows[0].product_gbn != '입점' && rows[0].product_gbn != '직발' ) {
        this.toastrService.error('입점 또는 직발인 내역만 출고취소가 가능합니다.');
      } else if ( rows[0].mng_out_status_name != '출고완료' ) {
        this.toastrService.error('출고완료된 내역만 출고취소가 가능합니다.');
      } else {
        if( confirm('출고취소하시겠습니까?') ) {
          this.stockOut.setCancelDelivery({
            mng_out_seq : rows[0].mng_out_seq, 
            mng_out_delivery_seq : rows[0].mng_out_delivery_seq, 
            order_master_seq : rows[0].order_master_seq
            }).then(res => {
              if(res.ResultCode) {
                this.toastrService.success('출고취소하였습니다.');
                this.reset();
              } else this.toastrService.error(res.ResultMessage);
            }).catch(e => this.toastrService.error(e))
        }
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 검색조건 버튼 클릭
  *******************************************************************************/
  btnClick(which, value) {
    this.form.patchValue({[which] : value});
    this.getStockOutOrder();
  }
  
  /*******************************************************************************
    설  명 : 날짜선택 바로가기 버튼 클릭
  *******************************************************************************/
  quickDate(quickTime) {
    this.stockOrderComponent.quickDate(quickTime, this);
  }
  
  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(which, value) {
    this.stockOrderComponent.selectDate(which, value, this);
  }

  cellClick_detail($event: any) {
    if($event.colDef.headerName == '관리') {
      if( $event.data['mng_out_order_status'] == '1000') {
        this.setOutException($event.data);
      }
    }
  }

  /*******************************************************************************
    설  명 : 출고지시취소
  *******************************************************************************/
  setOutException(data) {
    if(!confirm('출고지시를 취소하시겠습니까?')) return;

    this.authService.getLoginInfo.subscribe(e => {
      this.stockOut.setOutException({
        mem_no : e['mem_no'],
        mng_out_seq : data['mng_out_seq'],
        order_master_seq : data['order_master_seq'],
        mng_out_order_seq : data['mng_out_order_seq'],
        order_detail_seq : data['order_detail_seq']
      }).then(response => {
        response.ResultCode ? this.toastrService.success(response.ResultMessage) : this.toastrService.error(response.ResultMessage);
        this.ngOnInit();
      })
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
  *******************************************************************************/
  keypress( $event ) {
    if( $event.key == 'Enter' ) this.softReset()
  }

}
