<section id="member-company">

  <div class="mb5">
    <div class="fl form-inline">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getOrderReviewReportList()">
        <i class="material-icons-outlined">search</i>
      </button>

      <!-- <input type="text" id="searchText" [(ngModel)]="search.searchText" (keypress)="searchList($event)" size="30" class="form-control form-control-small ml5" placeholder="검색어" /> -->

      <input type="text" size="15" readonly="readonly" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small calendar-input ml5" placeholder="시작일자" ngbDatepicker [footerTemplate]="footerTemplate1" #d1="ngbDatepicker" (click)="d1.toggle()" />
      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 'sdate')">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
      </ng-template>
      <span class="ml5 mr5">~</span>
      <input type="text" size="15" readonly="readonly" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small calendar-input" placeholder="종료일자" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" (click)="d2.toggle()" />
      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 'edate')">오늘</button>
        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
      </ng-template>
      
      <div class="btn-group ml10">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active: search.reason==''}" (click)="setReason('')">전체</button>
        <button *ngFor="let row of commonList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.reason==row.common_code}" (click)="setReason(row.common_code)">{{row.common_name}}</button>
      </div>
    </div>
    <div class="fr right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="da69f673097e45acaa5eb475a949a2aa" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" class="btn btn-danger btn-small ml5" (click)="setDeleteOrderReview()">구매후기 삭제처리</button>
    </div>    
    <div class="cb"></div>
  </div>

  <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10" style="height: calc(100vh - 170px);background-color:#fff;">
    <as-split-area size="60" minSize="40" maxSize="80">
      <ag-grid-angular
        #myGrid

        style="width: 100%;height:100%;"
        class="ag-theme-balham"

        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="reportList"
        [singleClickEdit]="true"
        [pagination]="false"
        [domLayout]="domLayout"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [rowSelection]="rowSelection"

        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked($event)"
        >
      </ag-grid-angular>
    </as-split-area>

  </as-split>
</section>
<section class="pagination mt10">
  <ngb-pagination
    [(page)]="search.pageNo"
    [pageSize]="search.pageRow"
    [collectionSize]="totalCount"
    [maxSize]="20"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="loadPage($event)"
    >
    <ng-template ngbPaginationFirst>처음</ng-template>
    <ng-template ngbPaginationLast>마지막</ng-template>
    <ng-template ngbPaginationPrevious>이전</ng-template>
    <ng-template ngbPaginationNext>다음</ng-template>
  </ngb-pagination>
</section>


