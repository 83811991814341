import { NgModule } from "@angular/core";
import {
  Router, Routes, RouterModule,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

import { AuthGuard } from '@app/service/auth.guard';

import { HomeLayoutComponent } from "@page/layout/home.layout.component";

import { LoginComponent } from "@page/login/login.component";
import { DashboardComponent } from "@page/dashboard/dashboard.component";
import { ExchangeRateComponent } from "@page/basic/exchange-rate/exchange-rate.component";
import { CompanyInfoComponent } from "@page/basic/company-info/company-info.component"
import { WarehouseComponent } from "@page/basic/warehouse/warehouse.component";
import { StoreComponent } from "@page/basic/store/store.component";
import { CustomerComponent } from "@page/basic/customer/customer.component";
import { SupersaleComponent } from "@page/basic/supersale/supersale.component";
import { SupersaleDetailComponent } from '@page/basic/supersale/supersale-detail/supersale-detail.component';
import { EventComponent } from "@page/basic/event/event.component";
import { EventDetailComponent } from '@page/basic/event/event-detail/event-detail.component';
import { EventConsonantComponent } from '@page/basic/event/event-consonant/event-consonant.component';
import { EtcAccountComponent } from '@page/basic/etc-account/etc-account.component';
import { CouponComponent } from '@page/basic/coupon/coupon.component';
import { BamaCouponComponent } from '@page/basic/bama-coupon/bama-coupon.component';

import { ConfigComponent } from '@page/system/config/config.component';
import { GroupComponent } from '@page/system/group/group.component';
import { EmployeeComponent } from '@page/system/employee/employee.component';
import { AuthGroupComponent } from '@page/system/auth-group/auth-group.component';
import { CommonCodeComponent } from '@page/system/common/common.component';
import { PosManagementComponent } from "@page/system/pos-management/pos-management.component";
import { PosAuthgroupComponent } from "@page/system/pos-authgroup/pos-authgroup.component";
import { MenuComponent } from "@page/system/menu/menu.component";

import { MemberListComponent } from "@page/member/member-list/member-list.component";
import { MemberDormantComponent } from '@page/member/member-dormant/member-dormant.component';
import { MemberBenefitComponent } from "@page/member/member-benefit/member-benefit.component";
import { MemberMileageStatisticComponent } from "@page/member/member-mileage-statistic/member-mileage-statistic.component";
import { MemberRefusalComponent } from "@page/member/member-refusal/member-refusal.component";
import { MemberCompanyComponent } from "@page/member/member-company/member-company.component";
import { MemberSisComponent } from "@page/member/member-sis/member-sis.component";
import { MemberSisStatisticComponent } from "@page/member/member-sis-statistic/member-sis-statistic.component";
import { MemberCompanyApplicationComponent } from '@page/member/member-company-application/member-company-application.component';
import { MemberSisFrastatisticComponent } from '@page/member/member-sis-frastatistic/member-sis-frastatistic.component';
import { MemberCompanyStatisticComponent } from '@page/member/member-company-statistic/member-company-statistic.component';
import { MemberJoinInquiryComponent } from "@page/member/member-join-inquiry/member-join-inquiry.component";
import { MemberCouponComponent } from "@page/member/member-coupon/member-coupon.component";

import { ManagementComponent } from '@page/board/management/management.component';
import { BoardComponent } from '@page/board/board/board.component';
import { BoardViewComponent } from '@page/board/board/view/view.component';
import { BoardAddComponent } from '@page/board/board/add/add.component';
import { BoardReplyComponent } from '@page/board/board/reply/reply.component';
import { BoardProductComponent } from '@app/page/board/board-product/board-product.component';
import { ProductViewComponent } from '@app/page/board/board-product/view/view.component';
import { ProductReplyComponent } from '@app/page/board/board-product/reply/reply.component';

import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { StockOrderAddComponent } from '@page/stock/stock-order/stock-order-add/stock-order-add.component';
import { StockManagementComponent } from '@page/stock/stock-management/stock-management.component';
import { StockInManagementComponent } from '@page/stock/stock-in-management/stock-in-management.component';
import { StockOutManagementComponent } from '@page/stock/stock-out-management/stock-out-management.component';
import { StockWarehouseMoveComponent } from '@page/stock/stock-warehouse-move/stock-warehouse-move.component';
import { StockWarehouseStatisticComponent } from '@page/stock/stock-warehouse-statistic/stock-warehouse-statistic.component';
import { StockStatisticComponent } from '@page/stock/stock-statistic/stock-statistic.component';
import { StockInventoryCheckComponent } from '@page/stock/stock-inventory-check/stock-inventory-check.component';
import { StockInBaseComponent } from '@page/stock/stock-in-management/stock-in-base/stock-in-base.component';
import { StockInPurchaseComponent } from '@page/stock/stock-in-management/stock-in-purchase/stock-in-purchase.component';

import { ProductComponent } from '@page/product/product.component';
import { ProductMasterComponent } from '@page/product/product-master/product-master.component';
import { ProductAddComponent } from '@page/product/product-add/product-add.component';
import { ProductMarginComponent } from '@page/product/product-margin/product-margin.component';
import { ProductPropertyComponent } from '@page/product/product-property/product-property.component';
import { ProductSortComponent } from '@page/product/product-sort/product-sort.component';
import { BrandBaseDiscountComponent } from '@app/page/basic/brand-base-discount/brand-base-discount.component';
import { ProductRelationComponent } from '@page/product/product-relation/product-relation.component';
import { ProductBarcodeComponent } from '@page/product/product-barcode/product-barcode.component';
import { CategoryComponent } from '@page/product/category/category.component';
import { ProductColorManagementComponent } from '@page/product/product-color-management/product-color-management.component';
import { ProductSizeManagementComponent } from '@page/product/product-size-management/product-size-management.component';
import { ProductBrandManagementComponent } from '@page/product/product-brand-management/product-brand-management.component';
import { ProductSoldoutComponent } from '@page/product/product-soldout/product-soldout.component';
import { ProductSenaComponent } from '@page/product/product-sena/product-sena.component';
import { ProductSenaTrackingComponent } from '@page/product/product-sena-tracking/product-sena-tracking.component';
import { ProductSalesStatusComponent } from '@page/product/product-sales-status/product-sales-status.component';
import { ProductKeywordComponent } from './page/product/product-keyword/product-keyword.component';
import { ProductRecommendkeywordComponent } from './page/product/product-recommendkeyword/product-recommendkeyword.component';


import { OrderComponent } from '@page/order/order.component';
import { OrderDetailComponent } from '@page/order/order-detail/order-detail.component';
import { OrderOpenmarketComponent } from '@page/order/order-openmarket/order-openmarket.component';
import { OrderSabangnetComponent } from '@page/order/order-sabangnet/order-sabangnet.component';
import { OrderStoreSalesComponent } from '@page/order/order-store-sales/order-store-sales.component';
import { OrderRefundComponent } from '@page/order/order-refund/order-refund.component';
import { OrderRefund2Component } from '@page/order/order-refund2/order-refund2.component';
import { OrderOutofstockMemoComponent } from '@page/order/order-outofstock-memo/order-outofstock-memo.component';
import { OrderIniorderComponent } from '@page/order/order-iniorder/order-iniorder.component';
import { OrderDeliveryComponent } from '@page/order/order-delivery/order-delivery.component';
import { OrderReturnreqComponent } from '@page/order/order-returnreq/order-returnreq.component';
import { OrderCreditlistComponent } from '@page/order/order-creditlist/order-creditlist.component';
import { OrderPayComponent } from '@page/order/order-pay/order-pay.component';
import { OrderMileageComponent } from '@page/order/order-mileage/order-mileage.component';

import { StatisticDayComponent } from '@page/statistic/statistic-day/statistic-day.component';
import { StatisticMonthComponent } from '@page/statistic/statistic-month/statistic-month.component';
import { HermesSalesComponent } from '@page/statistic/hermes-sales/hermes-sales.component';
import { StatisticHomeComponent } from '@page/statistic/statistic-home/statistic-home.component';
import { StatisticProductComponent } from '@page/statistic/statistic-product/statistic-product.component';
import { StatisticBrandComponent } from '@page/statistic/statistic-brand/statistic-brand.component';
import { StatisticSalesCostComponent } from '@page/statistic/statistic-sales-cost/statistic-sales-cost.component';
import { StatisticSalesGoalComponent } from '@page/statistic/statistic-sales-goal/statistic-sales-goal.component';
import { StatisticSalesEditComponent } from '@page/statistic/statistic-sales-edit/statistic-sales-edit.component';
import { StatisticOpenmarketComponent } from '@page/statistic/statistic-openmarket/statistic-openmarket.component';
import { StatisticSalesComponent } from '@page/statistic/statistic-sales/statistic-sales.component';
import { StatisticDayDetailComponent } from "@page/statistic/statistic-day-detail/statistic-day-detail.component";
import { StatisticSimpleComponent } from "@page/statistic/statistic-simple/statistic-simple.component";

import { AuthService } from '@app/service/auth.service';
import { BasicService } from '@app/service/basic.service';

import { SmsFormComponent } from '@page/basic/sms/sms-form/sms-form.component';
import { PushFormComponent } from '@page/basic/push/push-form/push-form.component';
import { EmailFormComponent } from '@page/basic/email/email-form/email-form.component';
import { EmailFormAddComponent } from '@page/basic/email/email-form-add/email-form-add.component';
import { MemberSmsComponent } from '@page/member/member-sms/member-sms.component';
import { MemberPushComponent } from '@page/member/member-push/member-push.component';
import { MemberEmailComponent } from '@page/member/member-email/member-email.component';
import { PositionComponent } from '@page/system/position/position.component';

import { ApprovalComponent } from '@page/approval/approval.component';
import { ApprovalListComponent } from '@page/approval/approval-list/approval-list.component';
import { ApprovalLineComponent } from '@page/approval/approval-line/approval-line.component';
import { DocumentReceiveComponent } from '@page/approval/document-receive/document-receive.component';
import { DocumentExecuteComponent } from '@page/approval/document-execute/document-execute.component';

import { WorkmanagementWorkstatusComponent } from '@page/workmanagement/workmanagement-workstatus/workmanagement-workstatus.component';
import { WorkmanagementUsevacationComponent } from '@page/workmanagement/workmanagement-usevacation/workmanagement-usevacation.component';
import { WorkmanagementWorkarragementComponent } from '@page/workmanagement/workmanagement-workarragement/workmanagement-workarragement.component';
import { WorkarragementPrintComponent } from '@page/workmanagement/workmanagement-workarragement/workarragement-print/workarragement-print.component';

import { AccountBackComponent } from '@page/account/account-back/account-back.component'
import { AccountInfoComponent } from '@page/account/account-info/account-info.component'
import { AccountEnterComponent } from '@page/account/account-enter/account-enter.component'
import { AccountOpenmarketComponent } from "@page/account/account-openmarket/account-openmarket.component";
import { AccountCouponComponent } from '@page/account/account-coupon/account-coupon.component';

import { OrderStatementComponent } from "@page/order/order-detail/order-statement/order-statement.component";
import { SystemStatisticComponent } from '@page/system/system-statistic/system-statistic.component';

import { BikeEventComponent } from '@page/bike/bike-event/bike-event.component';
import { BikePushComponent } from '@page/bike/bike-push/bike-push.component';
import { BikeAdvertisementComponent } from '@page/bike/bike-advertisement/bike-advertisement.component';
import { BikeBrandComponent } from '@page/bike/bike-brand/bike-brand.component';
import { BikeModelComponent } from '@page/bike/bike-model/bike-model.component';
import { BikePayhelperComponent } from '@page/bike/bike-payhelper/bike-payhelper.component';
import { BikePayhelperDetailComponent } from '@page/bike/bike-payhelper/bike-payhelper-detail/bike-payhelper-detail.component';
import { BikeSellComponent } from '@page/bike/bike-sell/bike-sell.component';
import { BikeSellDetailComponent } from '@page/bike/bike-sell-detail/bike-sell-detail.component';
import { StatisticBikeAppReleaseComponent } from '@page/statistic/statistic-bike-app-release/statistic-bike-app-release.component';
import { StatisticBikeSellComponent } from '@page/statistic/statistic-bike-sell/statistic-bike-sell.component';
import { StatisticBikeOrderComponent } from '@page/statistic/statistic-bike-order/statistic-bike-order.component';
import { OrderReviewReportComponent } from '@page/order/order-review-report/order-review-report.component';
import { OrderReviewComponent } from '@page/order/order-review/order-review.component';
import { ProductSoldoutManagementComponent } from "./page/product/product-soldout-management/product-soldout-management.component";
import { BikeDetailSuppliesComponent } from "./page/bike/bike-detail-supplies/bike-detail-supplies.component";
import { SenaRegistrationComponent } from './page/product/sena-registration/sena-registration.component';

import { MemberSocietyComponent } from './page/member/society/member-society/member-society.component';
import { MemberSocietyHistoryComponent } from './page/member/society/member-society-history/member-society-history.component';
import { MemberSocietyStatisticComponent } from './page/member/society/member-society-statistic/member-society-statistic.component';



const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: "",
    children: [
      { path: "login", component: LoginComponent },
    ],
  },
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
      {
        path: "member", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "list", component: MemberListComponent },
          { path: "dormant", component: MemberDormantComponent },
          { path: "benefit", component: MemberBenefitComponent },
          { path: "mileage/statistic", component: MemberMileageStatisticComponent },
          { path: "refusal", component: MemberRefusalComponent },
          { path: "company", component: MemberCompanyComponent },
          { path: "sis", component: MemberSisComponent },
          { path: "sis/statistic", component: MemberSisStatisticComponent },
          { path: 'sms', component: MemberSmsComponent },
          { path: 'push', component: MemberPushComponent },
          { path: 'email', component: MemberEmailComponent },
          { path: 'company_application', component: MemberCompanyApplicationComponent },
          { path: 'sis/fra_statistic', component: MemberSisFrastatisticComponent },
          { path: 'company/statistic', component: MemberCompanyStatisticComponent },
          { path: 'join_inquiry', component: MemberJoinInquiryComponent },
          { path: 'society', component: MemberSocietyComponent },
          { path: 'society', component: MemberSocietyComponent },
          { path: 'society_history', component: MemberSocietyHistoryComponent },
          { path: 'society_statistic', component: MemberSocietyStatisticComponent },
          { path: 'coupon', component: MemberCouponComponent },
        ]
      },
      {
        path: "basic", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "company/info", component: CompanyInfoComponent },
          { path: "exchange/rate", component: ExchangeRateComponent },
          { path: "warehouse", component: WarehouseComponent },
          { path: "store", component: StoreComponent },
          { path: "customer", component: CustomerComponent },
          { path: "supersale", component: SupersaleComponent },
          { path: "supersale/detail", component: SupersaleDetailComponent },
          { path: "event", component: EventComponent },
          { path: "event/detail", component: EventDetailComponent },
          { path: "event/consonant", component: EventConsonantComponent },
          { path: 'sms', component: SmsFormComponent },
          { path: 'push', component: PushFormComponent },
          { path: 'email', component: EmailFormComponent },
          { path: 'email/add', component: EmailFormAddComponent },
          { path: 'account', component: EtcAccountComponent },
          { path: "discount/brand", component: BrandBaseDiscountComponent },
          { path: 'coupon', component: CouponComponent },        
          { path: 'bama-coupon', component: BamaCouponComponent },                    
        ]
      },
      {
        path: "product", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "category", component: CategoryComponent },
          { path: "list", component: ProductComponent },
          { path: "master", component: ProductMasterComponent },
          { path: "list/add", component: ProductAddComponent },
          { path: "margin", component: ProductMarginComponent },
          { path: "property", component: ProductPropertyComponent },
          { path: "size/management", component: ProductSizeManagementComponent },
          { path: "color/management", component: ProductColorManagementComponent },
          { path: "brand/management", component: ProductBrandManagementComponent },
          { path: "sort", component: ProductSortComponent },
          { path: "relation", component: ProductRelationComponent },
          { path: "barcode", component: ProductBarcodeComponent },
          { path: "list/soldout", component: ProductSoldoutComponent },
          { path: "keyword", component: ProductKeywordComponent },
          { path: "recommendkeyword", component: ProductRecommendkeywordComponent },
          { path: "sena/list", component: ProductSenaComponent },
          { path: "sena/tracking", component: ProductSenaTrackingComponent },
          { path: "salesstatus", component: ProductSalesStatusComponent },
          { path: "soldoutmanagement", component: ProductSoldoutManagementComponent },
          { path: "sena/registration", component: SenaRegistrationComponent },
        ]
      },
      {
        path: "stock", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "order", component: StockOrderComponent },
          { path: "order/add", component: StockOrderAddComponent },
          { path: "management", component: StockManagementComponent },
          { path: "out/management", component: StockOutManagementComponent },
          { path: "in/management", component: StockInManagementComponent },
          { path: "in/management/base", component: StockInBaseComponent },
          { path: "in/management/purchase", component: StockInPurchaseComponent },
          { path: "warehouse/move", component: StockWarehouseMoveComponent },
          { path: "warehouse/statistic", component: StockWarehouseStatisticComponent },
          { path: "statistic", component: StockStatisticComponent },
          { path: "inventory/check", component: StockInventoryCheckComponent },
        ]
      },
      {
        path: "order", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "list", component: OrderComponent },
          { path: "detail", component: OrderDetailComponent },
          { path: "openmarket", component: OrderOpenmarketComponent },
          { path: "sabangnet", component: OrderSabangnetComponent },
          { path: "store/sales", component: OrderStoreSalesComponent },
          { path: "refund", component: OrderRefundComponent },
          { path: "refund2", component: OrderRefund2Component },
          { path: "outofstock/memo", component: OrderOutofstockMemoComponent },
          { path: "iniorder", component: OrderIniorderComponent },
          { path: "delivery", component: OrderDeliveryComponent },
          { path: "returnreq", component: OrderReturnreqComponent },
          { path: "detail/statement", component: OrderStatementComponent },
          { path: "creditlist", component: OrderCreditlistComponent },
          { path: "pay", component: OrderPayComponent },
          { path: "mileage", component: OrderMileageComponent },
          { path: "review", component: OrderReviewComponent },
          { path: "review/report", component: OrderReviewReportComponent },
        ]
      },
      {
        path: "statistic", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: "day", component: StatisticDayComponent },
          { path: "month", component: StatisticMonthComponent },
          { path: "hermes", component: HermesSalesComponent },
          { path: "home", component: StatisticHomeComponent },
          { path: "product", component: StatisticProductComponent },
          { path: "brand", component: StatisticBrandComponent },
          { path: "openmarket", component: StatisticOpenmarketComponent },
          { path: "sales", component: StatisticSalesComponent },
          { path: "sales/cost", component: StatisticSalesCostComponent },
          { path: "sales/goal", component: StatisticSalesGoalComponent },
          { path: "sales/edit", component: StatisticSalesEditComponent },
          { path: "day/detail", component: StatisticDayDetailComponent },
          { path: "simple", component: StatisticSimpleComponent },
          { path: "bike/app/release", component: StatisticBikeAppReleaseComponent },
          { path: "bike/sell", component: StatisticBikeSellComponent },
          { path: "bike/order", component: StatisticBikeOrderComponent },
        ]
      },
      {
        path: "system", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: 'config', component: ConfigComponent },
          { path: 'group', component: GroupComponent },
          { path: 'employee', component: EmployeeComponent },
          { path: 'auth/group', component: AuthGroupComponent },
          { path: 'common', component: CommonCodeComponent },
          { path: 'pos/management', component: PosManagementComponent },
          { path: 'pos/authgroup', component: PosAuthgroupComponent },
          { path: 'position', component: PositionComponent },
          { path: 'menu', component: MenuComponent },
          { path: 'statistic', component: SystemStatisticComponent },
        ]
      },
      {
        path: "board", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: 'management', component: ManagementComponent },
          { path: 'list', component: BoardComponent },
          { path: 'view', component: BoardViewComponent },
          { path: 'add', component: BoardAddComponent },
          { path: 'reply', component: BoardReplyComponent },
          { path: 'productQna', component: BoardProductComponent },
          { path: 'productView', component: ProductViewComponent },
          { path: 'productReply', component: ProductReplyComponent },
        ]
      },
      {
        path: "approval", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: '', component: ApprovalComponent },
          { path: 'list', component: ApprovalListComponent },
          { path: 'line', component: ApprovalLineComponent },
          { path: 'receive', component: DocumentReceiveComponent },
          { path: 'execute', component: DocumentExecuteComponent },
        ]
      },
      {
        path: "workmanagement", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: 'workstatus', component: WorkmanagementWorkstatusComponent },
          { path: 'usevacation', component: WorkmanagementUsevacationComponent },
          { path: 'workarragement', component: WorkmanagementWorkarragementComponent },
          { path: 'workarragement/print', component: WorkarragementPrintComponent },
        ]
      },
      {
        path: "account", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: 'enter', component: AccountEnterComponent },
          { path: 'back', component: AccountBackComponent },
          { path: 'info', component: AccountInfoComponent },
          { path: 'openmarket', component: AccountOpenmarketComponent },
          { path: 'coupon', component: AccountCouponComponent },
        ]
      },
      {
        path: "bike", canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
        children: [
          { path: 'event', component: BikeEventComponent },
          { path: 'push', component: BikePushComponent },
          { path: 'advertisement', component: BikeAdvertisementComponent },
          { path: 'brand', component: BikeBrandComponent },
          { path: 'payhelper', component: BikePayhelperComponent },
          { path: 'payhelper/detail', component: BikePayhelperDetailComponent },
          { path: 'sell', component: BikeSellComponent },
          { path: 'sell/detail', component: BikeSellDetailComponent },
          { path: 'detail-supplies', component: BikeDetailSuppliesComponent },
          { path: 'model', component: BikeModelComponent }
        ]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private authService: AuthService,
    private basicService: BasicService,
  ) {
    // 로딩 처리
    this.router.events.subscribe(async (event: Event) => {
      switch( true ) {
        case event instanceof NavigationStart:
          break;

        case event instanceof NavigationEnd:
          await this.authService.getPageAuth();

          // 알림 메세지 카운트 가져오기
          this.basicService.getMessageList().then( response => {
            if ( response.ResultCode ) {
              this.basicService.setMessageCnt(response.data.length);
            } else {
              this.basicService.setMessageCnt(0);
            }
          });

          // 페이지 전환 시 최 상단으로 자동 스크롤
          window.scrollTo(0, 0);
          break;

        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          break;

        default: {
          break;
        }
      }
    });
  };
}