/*******************************************************************************
  설  명 : 재고관리 - 재고관리
  작성일 : 2020-09-05
  작성자 : 송영석

  수정일 : 2021.05.28
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { BrandService } from '@app/service/brand.service';
import { BasicService } from '@app/service/basic.service';
import { CategoryService } from '@app/service/category.service';
import { StockManagement } from '@app/service/stockManagement.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';
import { ExportExcelComponent } from '@components/export-excel/export-excel.component'

import { StockImportComponent } from '@page/stock/stock-management/stock-import/stock-import.component';
import { StockMoveComponent } from '@page/stock/stock-management/stock-move/stock-move.component';
import { StockPurchaseComponent } from '@page/stock/stock-management/stock-purchase/stock-purchase.component';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-stock-management',
  templateUrl: './stock-management.component.html',
  styleUrls: ['./stock-management.component.scss']
})

export class StockManagementComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  stockList: any = [];
  stockDetailList: any = [];
  whStockList: any = [];
  whStockPosList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;

  gridApi_byHistory: any;
  gridColumnApi_byHistory: any;
  columnDefs_byHistory: any;

  gridApi_byWarehouse: any;
  gridColumnApi_byWarehouse: any;
  columnDefs_byWarehouse: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };
  optionsXXL: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'xxl',
    centered: true,
    windowClass: 'modal-fadeInDown'
  }
  rowClassRules

  global_product_seq: any;
  global_product_property_seq: any;
  warehouse_list: any = [];
  category_list: any = [];
  brand_list: any = [];

  search_protoType = {index : 1, count : 1}
  search = { ...this.search_protoType }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public utilService: UtilService,
    private modalService: NgbModal,
    private stockManagement: StockManagement,
    private stockOrderComponent: StockOrderComponent,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private basicService: BasicService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private exportExcelComponent: ExportExcelComponent

  ) {
    this.columnDefs = [
      { headerName: '상품번호', field: 'product_seq', width: 92, cellClass: 'cp left ag-cell60h' },
      { headerName: '옵션번호', field: 'product_property_seq', width: 92, cellClass: 'cp left ag-cell60h' },
      { headerName: '품절여부', field: 'property_status', width: 82, cellClass: 'center ag-cell60h', sortable: false,
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let propertyStatus: any = params.data.property_status;

          if ( params.data.property_status == '0' ) propertyStatus = '<span class="badge badge-success f12 mt5">판매중</span>';
          else if ( params.data.property_status == '1' ) propertyStatus = '<span class="badge badge-warning f12 mt5">일시품절</span>';
          else if ( params.data.property_status == '2' ) propertyStatus = '<span class="badge badge-danger f12 mt5">영구품절</span>';
          else propertyStatus = '';

          return propertyStatus;
        }
      },
      { headerName: '표시여부', field: 'property_is_display', width: 82, cellClass: 'center ag-cell60h', sortable: false,
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let propertyIsDisplay = params.data.property_is_display;
          let propertyDisplayBadge = ( propertyIsDisplay == 1 ) ? '<span class="badge badge-success f12 mt5">표시</span>' : '<span class="badge badge-danger f12 mt5">미표시</span>';

          return propertyDisplayBadge;
        }
      },
      { headerName: '카테고리', field: 'category_name', width: 125, cellClass: 'cp left ag-cell60h', hide: true },
      { headerName: '카테고리/상품명', field: 'product_name', width: 340, cellClass: 'cp left ag-cell60h-br cell-wrap-text',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let categoryName = ( params.data.category_name ) ? params.data.category_name + '<br/>' : '';
          let brandName = ( params.data.brand_name ) ? '[ ' + params.data.brand_name + ' ]<br/>' : '';
          let productName = ( params.data.product_name ) ? params.data.product_name : '';

          return categoryName + brandName + productName;
        }
      },
      { headerName: '색상/사이즈', field: 'display_color_name', width: 165, cellClass: 'cp left ag-cell60h-br',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let displayColorName = params.data.display_color_name || '';
          let displaySizeName = params.data.display_size_name || '';

          return displayColorName + '<br/>' + displaySizeName;
        }        
      },
      { headerName: '사이즈', field: 'display_size_name', width: 100, cellClass: 'cp left ag-cell60h', hide: true },
      { headerName: '총재고수량', field: 'stock', width: 105, cellClass: 'cp right ag-cell60h',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)
      },
      { headerName: '안전재고', field: 'safe_stock', width: 100, cellClass: 'cp right ag-cell60h', hide: true,
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params)
      },
    ];

    this.columnDefs_byHistory = [
      {headerName: '일자', field: 'inout_date', width: 107, cellClass: 'cp center' },
      {headerName: '입출고구분', field: 'inout_gbn_name', width: 122, cellClass: 'cp left' },
      {headerName: '주문번호', field: 'order_master_seq', width: 93, cellClass: 'cp left' },
      {headerName: '창고', field: 'warehouse_name', width: 96, cellClass: 'cp left' },
      {headerName: '수량', field: 'inout_qty', width: 68, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
      {headerName: '메모', field: 'memo', width: 150, cellClass: 'cp left' },
    ];

    this.columnDefs_byWarehouse = [
      {headerName: '창고', field: 'warehouse_name', width: 150, cellClass: 'cp left' },
      {headerName: '수량', field: 'stock', width: 80, cellClass: 'cp right',
        valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
    ];

    // this.columnDefsWarehousePos = [
    //   {headerName: '창고', field: 'warehouse_name', width: 150, cellClass: 'cp left' },
    //   {headerName: '보관위치', field: 'location_cd', width: 200, cellClass: 'cp left' },
    //   {headerName: '수량', field: '수량', width: 80, cellClass: 'cp right',
    //     valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
    //   {headerName: '예정출고수량', field: '', width: 80, cellClass: 'cp right',
    //     valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
    //   {headerName: '예정입고수량', field: '', width: 80, cellClass: 'cp right',
    //     valueGetter: (params) => this.stockOrderComponent.valueGetter_num(params) },
    // ];

    this.defaultColDef = {...this.stockOrderComponent.defaultColDef};
    this.rowSelection = this.stockOrderComponent.rowSelection;
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate;
    this.domLayout = '';

    this.rowClassRules = {
      'status1': params => !(parseInt(params.data.inout_gbn) < 2000),
      'status2': params => parseInt(params.data.inout_gbn) < 2000
    }
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      warehouse_seq : [''],
      category_seq: ['0'],
      category_code : [''],
      brand_seq : ['0'],
      product_seq : [[]],
      base_date: [''],

      s_date : [''],
      e_date : [''],
      inout_gbn : [0],

      stockList : [[]],
      stockByHistory : [[]],
      stockByWarehouse : [[]],

      showExist: 1
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
  *******************************************************************************/
  getRowHeight = () => 60;

  /*******************************************************************************
    설  명 : 데이터 로딩
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();
    this.getWarehouseList();
    this.getCategoryList();
    this.getBrandList();
    this.search = { ...this.search_protoType };

    // 전체 검색 시 너무 오래 걸리므로 초기 자동 검색을 막음

//    this.getStockManagement();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReady_byHistory(params) {
    this.gridApi_byHistory = params.api;
    this.gridColumnApi_byHistory = params.columnApi;
  }
  onGridReady_byWarehouse(params) {
    this.gridApi_byWarehouse = params.api;
    this.gridColumnApi_byWarehouse = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 입고 처리
  *******************************************************************************/
  importAdd( seq ) {
    const modalRef = this.modalService.open(StockImportComponent, options);

    /*
    modalRef.componentInstance.seq = seq;

    let nodes = this.gridApi.getSelectedRows();
    if( nodes.length > 0 ) {
      modalRef.componentInstance.product_seq = nodes[0].seq;
      modalRef.componentInstance.product_name = nodes[0].name;
    } else {
      modalRef.componentInstance.product_seq = null;
      modalRef.componentInstance.product_name = '';
    }
    */

    modalRef.result.then( (result) => {
      /*
      this.getStockMaster();

      this.stockDetailList = [];
      */
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 폐기 / 분실 처리
  *******************************************************************************/
  lossAdd(loss_mode) {
    this.moveAdd(loss_mode)

    // const modalRef = this.modalService.open(StockLossComponent, options);
    // modalRef.componentInstance.seq = seq;
    // let nodes = this.gridApi.getSelectedRows();
    // if( nodes.length > 0 ) {
    //   modalRef.componentInstance.product_seq = nodes[0].seq;
    //   modalRef.componentInstance.product_name = nodes[0].name;
    // } else {
    //   modalRef.componentInstance.product_seq = null;
    //   modalRef.componentInstance.product_name = '';
    // }
    // modalRef.result.then((result) => {
    //   this.getStockMaster();
    //   this.stockDetailList = [];
    // }, (reason) => {
    // });
  }

  /*******************************************************************************
    설  명 : 창고이동, 폐기/분실
  *******************************************************************************/
  moveAdd(loss_mode = 0) {
    const data_productProperty = this.gridApi.getSelectedRows()
    const data_warehouse = this.form.value.stockByWarehouse.map(e => ({
      ...e,
      _detail : []
    }))
    if(!data_productProperty.length) {this.toastrService.warning('재고현황에서 상품을 선택하세요.'); return}
    else if(!data_warehouse.filter(e => e['stock'] > 0).length) {this.toastrService.warning('재고가 없습니다.'); return}

    this.warehouse_list.forEach(e => {
      const _index = data_warehouse.findIndex(_e => _e['warehouse_seq'] == e['warehouse_seq'])
      if(_index != -1) data_warehouse[_index]['_detail'].push(e['_detail'])
    })

    const last_history = this.form.value.stockByHistory.filter(e => e['warehouse_location_seq'])[0]

    const modalRef = this.modalService.open(StockMoveComponent, options)
    modalRef.componentInstance.data_productProperty = data_productProperty.shift()
    modalRef.componentInstance.data_warehouse_all = this.warehouse_list
    modalRef.componentInstance.data_warehouse = data_warehouse
    modalRef.componentInstance.loss_mode = loss_mode
    modalRef.componentInstance.last_history = {
      warehouse_seq : last_history['warehouse_seq'],
      warehouse_location_seq : last_history['warehouse_location_seq']
    }


    modalRef.result.then((result) => {
      this.ngOnInit()
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 구매입고
  *******************************************************************************/
  purchaseAdd( seq ) {
    const modalRef = this.modalService.open(StockPurchaseComponent, optionsLG);
    /*
    modalRef.componentInstance.seq = seq;
    modalRef.componentInstance.check = 'S';
    modalRef.componentInstance.warehouseList = this.warehouseList;
    */

    modalRef.result.then((result) => {
      /*
      this.getStockMaster();

      this.stockDetailList = [];
      */
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 재고현황 가져오기
  *******************************************************************************/
  getStockManagement() {
    const _FV = this.form.value;
    this.stockManagement.getStockManagement({
      product_seq : _FV['product_seq'],
      warehouse_seq : _FV['warehouse_seq'],
      category_code : _FV['category_code'],
      brand_seq : _FV['brand_seq'],
      index : this.search.index - 1,
      base_date: _FV['base_date'],
      showExist: _FV['showExist'],
      unlimit : true
    }).then(response => {
      if(response.ResultCode) {
        if(!response.data.length) this.toastrService.warning('데이터가 없습니다.')
        this.form.patchValue({ stockList : response.data })
        this.search.count = response.count
      }
    });
  }

  /*******************************************************************************
    설  명 : 재고이력 가져오기
  *******************************************************************************/
  getStockManagement_byHistory(product_seq, product_property_seq) {
    const _FV = this.form.value;
    this.stockManagement.getStockManagement_byHistory({
      product_seq : product_seq,
      product_property_seq : product_property_seq,

      s_date : _FV['s_date'],
      e_date : _FV['e_date'],
      inout_gbn : _FV['inout_gbn'],
      warehouse_seq : _FV['warehouse_seq']
    }).then(response => {
      if(response.ResultCode) {
        if(!response.data.length) this.toastrService.warning('데이터가 없습니다.');
        this.form.patchValue({ stockByHistory : response.data });
      }
    });
  }

  /*******************************************************************************
    설  명 : 창고별재고 가져오기
  *******************************************************************************/
  getStockManagement_byWarehouse(product_seq, product_property_seq) {
    const _FV = this.form.value;
    this.stockManagement.getStockManagement_byWarehouse({
      product_seq : product_seq,
      product_property_seq : product_property_seq
    }).then(response => {
      if(response.ResultCode) {
        if(!response.data.length) this.toastrService.warning('데이터가 없습니다.');
        this.form.patchValue({ stockByWarehouse : response.data });
      }
    });
  }

  /*******************************************************************************
    설  명 : 발주리스트 클릭시 상세내역 보여주기
  *******************************************************************************/
  onCellClicked_stockList($event) {
    if( $event.colDef.field === 'product_seq' ) {
      const url = '/product/list/add?seq=' + $event.data.product_seq;
      window.open("about:blank").location.href = url;
    } else {
      const global_product_seq = $event.data.product_seq;
      const global_product_property_seq = $event.data.product_property_seq;
      if(!global_product_seq && !global_product_property_seq) {
        this.toastrService.warning('이 데이터는 유효하지 않습니다.');
        this.form.patchValue({ stockByWarehouse : [], stockByHistory : [] });
        return;
      }

      this.global_product_seq = global_product_seq;
      this.global_product_property_seq = global_product_property_seq;

      this.getStockManagement_byHistory(global_product_seq, global_product_property_seq);
      this.getStockManagement_byWarehouse(global_product_seq, global_product_property_seq);
    }
  }

  /*******************************************************************************
    설  명 : 검색조건 버튼 클릭
  *******************************************************************************/
  btnClick(which, value, type = 0) {
    this.form.patchValue({[which] : value});
    if(type) {
      if(this.global_product_seq && this.global_product_property_seq) 
      this.getStockManagement_byHistory(this.global_product_seq, this.global_product_property_seq)
    } else {
      this.getStockManagement()
    }
  }
  /*******************************************************************************
    설  명 : 달력에서 날짜선택
  *******************************************************************************/
  selectDate(which, value) {
    const _V = [];
    for(const key in value) _V.push(value[key]);
    const _VV = this.stockOrderComponent.dateFormat( new Date( _V.join('-') ) );
    this.form.patchValue({ [which] : _VV });

    if(this.form.value['s_date'] && this.form.value['e_date'] && this.global_product_seq && this.global_product_property_seq) 
    this.getStockManagement_byHistory(this.global_product_seq, this.global_product_property_seq);
    if(this.form.value['base_date']) this.getStockManagement()
  }


  /*******************************************************************************
    설  명 : 상품 검색 모달 띄우기
  *******************************************************************************/
  addProductModal() {
    const modalRef = this.modalService.open(ProductFindPropertyComponent, this.optionsXXL);
    modalRef.result.then((result) => {
      this.searchProduct(result);
    }, (reason) => {
    });
  }
  /*******************************************************************************
    설  명 : 상품검색에 따른 검색 실행
  *******************************************************************************/
  searchProduct(data) {
    this.form.patchValue({ product_seq : data.map(e => e['seq']) });
    this.getStockManagement();
  }

  /*******************************************************************************
    설  명 : 창고 리스트 가져오기
  *******************************************************************************/
  getWarehouseList() {
    this.basicService.getWarehouseList({
      searchText: ''
    }).then( response => {
      if(response.ResultCode) {
        this.warehouse_list = response.data.map(e => ({
          warehouse_seq : e['seq'],
          warehouse_name : e['warehouse_name'],
          _detail : []
        }))
        this.warehouse_list.forEach(e => {
          this.basicService.getWarehouseDetail(e['warehouse_seq']).then(response => {
            e['_detail'] = response.location
          })
        })
      }
    }, error => this.toastrService.error( error ));
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
  *******************************************************************************/
  getCategoryList() {
    this.categoryService.getCategoryList2().then( response => {
      if( response.ResultCode ) {
        // this.category_list = response.data.map(e => ({
        //   seq : e['seq'],
        //   category_code : e['category_code'],
        //   category_nm : e['category_nm']
        // }));
        let tmp: any = [];
        tmp.push({id: '0', text: '카테고리 전체', code: ''});

        response.data.forEach( row => {
          tmp.push({
            id: row.seq,
            text: row.category_nm,
            code: row.category_code
          });
        });

        this.category_list = tmp;
      } else {
        this.category_list = []
      }
    });
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBrandList() {
    this.brandService.getBrandList({}).then( response => {
      if( response.ResultCode ) {
        // this.brandList = response.data;
        let tmp: any = [];
        tmp.push({id: '0', text: '브랜드 전체'});

        response.data.forEach( row => {
          tmp.push({
            id: row.seq,
            text: row.brand_name
          });
        });

        this.brand_list = tmp;
      } else {
        this.brand_list = [];
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 창고 선택
  *******************************************************************************/
  selectWarehouse($event) {
    this.form.patchValue({
      warehouse_seq : $event.target.value
    })
    this.getStockManagement()
  }
  /*******************************************************************************
    설  명 : 카테고리 선택
  *******************************************************************************/
  selectCategory(event: any) {
    this.form.patchValue({
      category_seq: ( event.id != '0' ) ? event.id : '',
      category_code : ( event.id != '0' ) ? event.code : ''
    });

    this.getStockManagement()
  }

  /*******************************************************************************
    설  명 : 브랜드 선택 시 처리
  *******************************************************************************/
  selectBrand( event: any ) {
    this.form.patchValue({
      brand_seq : ( event.id != '0' ) ? event.id : ''
    });

    this.getStockManagement();
  }
    
  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  reset() {
    this.buildForm();

    this.getStockManagement();
  }
  /*******************************************************************************
    설  명 : 인덱스 변경
  *******************************************************************************/
  pageChange($event) {
    this.search.index = $event
    this.getStockManagement()
  }

  /*******************************************************************************
    설  명 : 엑셀다운
  *******************************************************************************/
  allDataExcelExport() {
    if(!confirm('모든 데이터를 다운로드합니다. \r\n이 작업은 다소 시간이 걸릴 수 있습니다. \r\n진행하시겠습니까?')) return
    this.toastrService.show('엑셀 생성 중..');

    const _FV = this.form.value;
    this.stockManagement.getStockManagement({
      product_seq : _FV['product_seq'],
      warehouse_seq : _FV['warehouse_seq'],
      category_code : _FV['category_code'],
      brand_seq : _FV['brand_seq'],
      base_date: _FV['base_date'],
      showExist: _FV['showExist'],
      unlimit : true
    }).then(response => {
      if(response.ResultCode) {
        this.toastrService.show('엑셀 다운로드 중..')
        this.exportExcelComponent.exportAsExcelFile(response.data, '재고현황')
        this.toastrService.success('엑셀 다운로드 완료')
      } else this.toastrService.error(response.ResultMessage)
    })
  }

}
