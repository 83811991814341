<div class="modal-header">
  <h4 class="modal-title fb mb0">초도 주문 {{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="setDismiss()">&times;</button>
</div>

<div class="modal-body">

  <form [formGroup]="form">

  <table class="table table-bordered table-small mt50 mb50">
  <colgroup>
    <col style="width:25%" />
    <col style="width:75%" />
  </colgroup>

  <tbody>
  <tr>
    <th scope="row"><label for="order_name" class="control-label"><i class="material-icons cred f11">star</i> 초도 주문명</label></th>
    <td>
      <inputEx type="text" name="order_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
    </td>
  </tr>
  </tbody>
  </table>

  </form>

</div>

<div class="modal-footer">
  <div class="fr w50p right">
    <button type="button" *ngIf="form.controls.seq.value == ''" class="btn btn-primary btn-small mr5" (click)="setInitialOrderNameSave()">저장하기</button>
    <button type="button" *ngIf="form.controls.seq.value != ''" class="btn btn-primary btn-small mr5" (click)="setInitialOrderNameEdit()">저장하기</button>
    <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="setDismiss()">취소하기</button>
  </div>
</div>
