import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class EtcAccountService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    return Promise.reject(error.message || error);
  }


  // 기타 입출금 계정관리 리스트 가져오기
  getEtcAccountList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'etc_account',
        version: '1.0',
        action: 'getEtcAccountList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 기타 입출금 계정관리 저장
  setEtcAccount( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'etc_account',
        version: '1.0',
        action: 'setEtcAccount'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  delAccount( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'etc_account',
        version: '1.0',
        action: 'delAccountItem'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


}
