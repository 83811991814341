import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { AccountService } from '@app/service/account.service';

@Component({
  selector: 'app-account-coupon-add',
  templateUrl: './account-coupon-add.component.html',
  styleUrls: ['./account-coupon-add.component.scss']
})
export class AccountCouponAddComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() seq: any;

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private utilService: UtilService,
    private accountService: AccountService,
    private toastrService: ToastrService,
  ) {
    this.form = this.formBuilder.group({
      seq: [[], [Validators.required]],
      calculate_date: [null, [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      seq: this.seq,
      calculate_date: this.utilService.getDate(moment().format('YYYY-MM-DD'))
    });
  }

  /*******************************************************************************
    설  명 : 정산 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setAccountCouponOrderCalc() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
        this.accountService.setAccountCouponOrderCalc(this.form).then( response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage);
          this.activeModal.close(true);
        } else {
          this.toastrService.error(response.ResultMessage);
        };
      });
    }
  }
  
  /*******************************************************************************
     설  명 : 모달창 닫기(리턴 없음)
  *******************************************************************************/
  setModalDismiss() {
    this.activeModal.dismiss();
  }  

}
