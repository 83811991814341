import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class StockOrder {

  constructor(
    private restful: RestfulService,
  ) {
  }
  
  // 발주관리 get
  getStockOrder( params ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'stockOrder',
      version: '1.0',
      action: 'getStockOrder'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 발주관리 post
  setStockOrder( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOrder',
      version: '1.0',
      action: 'setStockOrder'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 발주관리 delete
  deleteStockOrder( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOrder',
      version: '1.0',
      action: 'deleteStockOrder'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
  
  // 발주관리 상세내역 delete
  deletePurchaseOrder( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOrder',
      version: '1.0',
      action: 'deletePurchaseOrder'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 발주관리 옵션 내역 delete
  deletePurchaseOrderProperty( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOrder',
      version: '1.0',
      action: 'deletePurchaseOrderProperty'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }  
  
  // 발주처리
  updatePurchaseComplete( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOrder',
      version: '1.0',
      action: 'updatePurchaseComplete'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 발주수량변경
  setPurchaseQty( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOrder',
      version: '1.0',
      action: 'setPurchaseQty'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 빠른발주
  quickPurchase( params ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'stockOrder',
      version: '1.0',
      action: 'quickPurchase'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 발주서 통합
  setPurchaseCombine( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'stockOrder',
        version: '1.0',
        action: 'setPurchaseCombine'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

}
