import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class WorkArrangement {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 근무자 리스트 가져오기
  getWorker( params ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'workArrangement',
        version: '1.0',
        action: 'getWorker'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 해당날짜 근무자 추가하기
  postWorker( params ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'workArrangement',
        version: '1.0',
        action: 'postWorker'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 해당날짜 근무자 수정하기
  putWorker( params ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'workArrangement',
        version: '1.0',
        action: 'putWorker'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 해당날짜 근무자 삭제하기
  deleteWorker( params ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'workArrangement',
        version: '1.0',
        action: 'deleteWorker'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


}
