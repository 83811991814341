import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SupersaleService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 슈퍼세일 리스트
  getSuperSaleList( type: string, seq?: string ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'supersale',
      version: '1.0',
      action: 'getSuperSaleList'
    }, {
      searchType: type,
      seq: seq
    }).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 등록/수정
  setSuperSaleSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'supersale',
      version: '1.0',
      action: 'setSuperSaleSave'
    }, form.value
    ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 삭제
  setSuperSaleDelete( seq: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'supersale',
        version: '1.0',
        action: 'setSuperSaleDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 위젯
  getSuperSaleWidget(seq: string): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'supersale',
      version: '1.0',
      action: 'getSuperSaleWidget'
    }, {
      seq: seq
    }).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 품목 저장
  setSuperSaleProductSave(array): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'supersale',
      version: '1.0',
      action: 'setSuperSaleProductSave'
    }, {
      params: array
    }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 카테고리 리스트
  getSuperSaleCategoryListN(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'supersale',
        version: '1.0',
        action: 'getSuperSaleCategoryListN'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 카테고리 품목 리스트
  getSuperSaleCategoryProductList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'supersale',
        version: '1.0',
        action: 'getSuperSaleCategoryProductList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 슈퍼세일 카테고리 등록/수정
  setSuperSaleCategorySave(params): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'supersale',
      version: '1.0',
      action: 'setSuperSaleCategorySave'
    }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }


  // 슈퍼세일 브랜드 리스트
  getSuperSaleBrandList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'supersale',
        version: '1.0',
        action: 'getSuperSaleBrandList'
      }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 슈퍼세일 브랜드 등록/수정
  setSuperSaleBrandSave(params): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'supersale',
      version: '1.0',
      action: 'setSuperSaleBrandSave'
    }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 슈퍼세일 브랜드 품목 리스트
  getSuperSaleBrandProductList(params): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'supersale',
        version: '1.0',
        action: 'getSuperSaleBrandProductList'
      }, params
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

}
