<section class="form-inline" style="position: relative;">
  <!-- <button type="button" title="검색" class="btn btn-default btn-small-icon" (click)="onClickButton()">
    <i class="material-icons-outlined">search</i>
  </button> -->
  
  <!-- <l-select2
    [data]="selectCategoryList"
    (ngModelChange)="changedCategory($event, null)"
    [(ngModel)]="search.category_select"
    class="form-control form-control-small ml5"
    [options]="optionsCategory"
    style="width:200px; padding:0px !important;border:solid 0px #fff !important;"
  ></l-select2> -->

  <div class="btn-group di" style="width: calc(100% - 35px);">
    <button *ngFor="let item of categoryList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.category==item.category_code}" (click)="changedCategory($event, item)">{{item.title}}</button>
  </div>

  <button type="button" class="btn btn-primary btn-small fr" style="position: absolute; right: 0; top: 0;" (click)="moveSimplePage()">매출현황상세</button>

  <div class="mt5">
    <button type="button" title="검색" class="btn btn-default btn-small-icon" (click)="onClickButton()">
      <i class="material-icons-outlined">search</i>
    </button>

    <l-select2
      [data]="selectBrandList"
      (ngModelChange)="changedBrand($event, null)"
      [(ngModel)]="search.brand_select"
      class="form-control form-control-small ml5"
      [options]="optionsBrand"
      style="width:200px; padding:0px !important;border:solid 0px #fff !important;"
    ></l-select2>

    <div class="btn-group ml5">
      <button *ngFor="let item of brandList" type="button" class="btn btn-default btn-small" [ngClass]="{active: search.brand==item.key}" (click)="changedBrand($event, item)">{{item.title}}</button>
    </div>
  </div>

  <hr class="w100p db mt5 mb5" />
</section>

<section class="chart">
  <canvas
    *ngIf="barChartData.length > 0" width="100%" height="27" baseChart
    [datasets]="barChartData"
    [labels]="chartLabel"
    [chartType]="chartType"
    [options]="chartOptions"
  ></canvas>
</section>

<section style="height:calc(35% - 20px);" class="mt20">
  <ag-grid-angular
    style="width: 100%;height:100%;"
    class="ag-theme-balham"

    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="dataList"
    [domLayout]="domLayout"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [frameworkComponents]="frameworkComponents"
    [rowSelection]="rowSelection"

    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</section>