import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { MemberService } from '@app/service/member.service';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { MemberAddressAddComponent } from '@page/member/member-info/member-address/member-address-add/member-address-add.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-member-address',
  templateUrl: './member-address.component.html',
  styleUrls: ['./member-address.component.scss']
})
export class MemberAddressComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() memberNo: any;
  @Input() dormant: any;

  public search: any = {
    memberNo: '',
    searchText: '',
    sdate: '',
    edate: ''
  };

  // 그리드 관련 선언
  memberAddressList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
     설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private memberService: MemberService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: 'seq', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '기본주소여부', field: 'isDefault', width: 100, cellClass: 'cp center',
        valueGetter: function(params) {
          if( params.data.isDefault == '1' ) {
            return '기본';
          } else {
            return '';
          }
        }
      },
      { headerName: '우편번호', field: 'zipcode', width: 100, cellClass: 'cp center' },
      { headerName: '주소', field: 'address', width: 470, cellClass: 'cp' },
      { headerName: '상세주소', field: 'address_detail', width: 500, cellClass: 'cp' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
     설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.search.memberNo = this.memberNo;

    this.getMemberAddressList();
  }

  /*******************************************************************************
     설  명 : 주문내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMemberAddressList() {
    this.memberService.getMemberAddressList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.memberAddressList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 주소 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addMemberAddress( seq: number ) {
    const modalRef = this.modalService.open(MemberAddressAddComponent, optionsLG);

    modalRef.componentInstance.seq = seq;
    modalRef.componentInstance.memberNo = this.memberNo;

    modalRef.result.then((result) => {
      if( result ) {
        this.getMemberAddressList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
     설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
     설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked(event: any) {
    this.addMemberAddress( event.data.seq );
  }

  /*******************************************************************************
     설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      memberNo: this.memberNo,
      searchText: '',
      sdate: '',
      edate: ''
    };

    this.getMemberAddressList();
  }

  /*******************************************************************************
     설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getMemberAddressList();
    }
  }

}
