import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass:'modal-admin'
};

@Component({
  selector: 'app-approval-meeting',
  templateUrl: './approval-meeting.component.html',
  styleUrls: ['./approval-meeting.component.scss']
})
export class ApprovalMeetingComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
