import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@app/service/util.service';
import { EventService } from '@app/service/event.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-event-consonant-add',
  templateUrl: './event-consonant-add.component.html',
  styleUrls: ['./event-consonant-add.component.scss']
})
export class EventConsonantAddComponent implements OnInit {
  @Input() params: any;

  public form: FormGroup;
  public formErrors = {};
  public title: string; 
  public startDate: Date;
  public endDate: Date;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private eventService: EventService,
    private toastrService: ToastrService,
  ) {
    this.form = this.formBuilder.group({
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]],
      hint: ['', [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  }

  ngOnInit(): void {
    if( typeof this.params !== undefined && this.params !== null ) {
      if( this.params.hint ) {
        this.params.hint = this.params.hint.replace(/<br \/>/g, '');
      }
      
      this.form.patchValue(this.params);

      this.startDate = this.utilService.getDate(moment(this.setDate(this.params.start_date)).toDate());
      if (this.params.end_date) {
        this.endDate = this.utilService.getDate(this.setDate(this.params.end_date));
      } else {
        this.endDate = this.utilService.getDate(moment(this.setDate(this.params.end_date)).add(1, 'days').toDate());
      };
    }
  }

  setDate(date?: Date) {
    return date || new Date();
  }

  setModalDismiss() {
    this.activeModal.dismiss();
  }

  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if (this.form.valid) {
  
      const start_date = moment(this.startDate).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss');
      const end_date = moment(this.endDate).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss');

      this.eventService.setEventQuiz(this.params.seq, start_date, end_date, this.form).then( response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage);
          this.activeModal.close(true);
        } else {
          this.toastrService.error(response.ResultMessage);
        };
      });
    }
  }

  delete() {
    this.eventService.setEventQuizDelete(this.params.seq).then( response => {
      if (response.ResultCode) {
        this.toastrService.success(response.ResultMessage);
        this.activeModal.close(true);
      } else {
        this.toastrService.error(response.ResultMessage);
      };
    });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.startDate = this.utilService.getDate( '' );
    } else {
      this.endDate = this.utilService.getDate( '' );
    }
    obj.close();
  }
}
