<form [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title fb mb0">정산일자</h4>
    <button type="button" class="close" aria-label="Close" (click)="setModalDismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <inputEx type="date" name="calculate_date" [formGroup]="form" [formErrors]="formErrors" placeholder="정산일자를 선택하세요"></inputEx>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">

    </div>
    <div class="fr w50p right">
      <button type="button" class="btn btn-default btn-small" aria-label="Close" (click)="setModalDismiss()">취소</button>
      <button type="button" class="btn btn-primary btn-small ml5" (click)="setAccountCouponOrderCalc()">확인</button>
    </div>
  </div>
</form>
