import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@app/service/util.service';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { SmsService } from '@app/service/sms.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

const PAGEROW = 100;

@Component({
  selector: 'app-member-sms',
  templateUrl: './member-sms.component.html',
  styleUrls: ['./member-sms.component.scss']
})
export class MemberSmsComponent implements OnInit {

  public statistic: any = {
    total_count: 0,
    search_count: 0,
    month_count: 0
  };

  // 검색
  public search: any = {
    pageNo: 1,
    pageRow: PAGEROW,
    seq: '',
    searchText: '',
    sdate: '',
    edate: ''
  };

  public term: any = [
    {title: '이번달', value: this.getDays(0)},
    {title: '1개월', value: this.getDays(1)},
    {title: '2개월', value: this.getDays(2)},
    {title: '3개월', value: this.getDays(3)},
    {title: '6개월', value: this.getDays(6)},
  ];

  public searchTerm: any;

  public sendList: any = [];

  // grid
  private selectedRows = 0;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowSelection = 'multiple';
  public noRowsTemplate = '검색된 데이터가 없습니다.';

  public defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  public columnDefs = [
    {headerName: '선택', field: 'seq', width: 120, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    {headerName: '발송일시', field: 'sendDate', cellClass: 'cp center', width: 140  },
    {headerName: '구분', field: 'messageGbn', cellClass: 'cp center', width: 60, cellRenderer: 'agGridHtmlComponent',
      valueGetter: function(params) {
        if( params.data.messageGbn == 'sms' ) {
          return '<span class="badge badge-success f12">SMS</span>';
        } else {
          return '<span class="badge badge-danger f12">LMS</span>';
        }
      }
    },
    {headerName: '제목', field: 'title', cellClass: 'cp left', width: 200  },
    {headerName: '내용', field: 'msg', cellClass: 'cp left', width: 400  },
    {headerName: '발송번호', field: 'sendTel', cellClass: 'cp left', width: 100  },
    {headerName: '수신번호', field: 'receiptTel', cellClass: 'cp left', width: 200  },
    {headerName: '상태', field: 'result', cellClass: 'cp left', width: 150  },
    {headerName: '발송자', field: 'writer', cellClass: 'cp', width: 100  },
  ];

  public frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  constructor(
    private toastrService: ToastrService,
    private utilService: UtilService,
    private smsService: SmsService,
  ) {
  }

  ngOnInit(): void {
    this.getStatistic();
    this.onSearchClick(this.getDays(0));
  }

  initSearch() {
    // 검색
    this.search = {
      pageNo: 1,
      pageRow: PAGEROW,
      seq: '',
      searchText: '',
      sdate: '',
      edate: ''
    };

    this.search.searchText = '';

    this.onSearchClick(this.getDays(0));
  }

  getDays(month: number) {
    const todaysDate = moment();
    let toDate;
    if (month === 0) {
      toDate = moment().startOf('month');
    } else {
      toDate = moment().add(month * -1, 'months');
    }

    return toDate.diff(todaysDate, 'days');
  }

  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if ( check ) {
      this.search.sdate = this.utilService.getDate( '' );
    } else {
      this.search.edate = this.utilService.getDate( '' );
    }
    obj.close();
  }

  onSearchClick( val ) {
    this.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
    this.getSMSSendList();
  }

  getSMSSendList() {
    this.smsService.getSMSSendList(this.search).then(response => {
      this.sendList = response.data;
      this.statistic.search_count = response.search_count;
      }, error => {
      this.toastrService.error(error, 'SMS발송내역') ;
    });
  }

  getStatistic() {
    this.smsService.getStatistic(this.search).then( response => {
      if ( response.ResultCode ) {
        this.statistic.total_count = response.data.total_count;
        this.statistic.month_count = response.data.month_count;

      } else {
        this.statistic = {
          total_count: 0,
          month_count: 0,
          search_count: 0
        };
      }
    }, error => {
      this.toastrService.error( '발송내역 통계를 가져오는데 실패하였습니다.', '발송내역');
    });
  }

  searchList( event ) {
    if ( event.key === 'Enter' ) {
      this.getSMSSendList();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( (node) => {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getSMSSendList();
  }
}
