  <section class="search-condition mb5">
    <div class="fl form-inline asub-title">
      <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="reset()">
        <i class="material-icons-outlined">refresh</i>
      </button>
      <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getStockManagement()">
        <i class="material-icons-outlined">search</i>
      </button>

      <select class="form-control form-control-small ml5" [value]="form.value.warehouse_seq" (change)="selectWarehouse($event)">
        <option value="">창고 전체</option>
        <option *ngFor="let item of warehouse_list" value="{{item.warehouse_seq}}">{{item.warehouse_name}}</option>
      </select>

      <!-- <select class="form-control form-control-small ml5" [value]="form.value.category_code" (change)="selectCategory($event)">
        <option value="">카테고리 선택</option>
        <option *ngFor="let item of category_list" value="{{item.category_code}}">{{item.category_nm}}</option>
      </select> -->
      <l-select2
        [data]="category_list"
        (ngModelChange)="selectCategory($event)"
        [(ngModel)]="form.value.category_seq"
        class="form-control form-control-small ml5"
        [options]="{multiple: false}"
        style="width: 250px; padding:0px !important;border:solid 0px #fff !important;"
      ></l-select2>

      <l-select2
        [data]="brand_list"
        (ngModelChange)="selectBrand($event)"
        [(ngModel)]="form.value.brand_seq"
        class="form-control form-control-small ml5"
        [options]="{multiple: false}"
        style="width: 250px; padding:0px !important;border:solid 0px #fff !important;"
      ></l-select2>

      <input type="text" size="13" readonly="readonly" [value]="form.value.base_date" class="form-control form-control-small calendar-input ml5" placeholder="기준일" ngbDatepicker #d0="ngbDatepicker" (click)="d0.toggle()" (dateSelect)="selectDate('base_date', $event)" />

      <div class="input-group ml5" (click)="addProductModal()">
        <input type="text" class="form-control form-control-small readonly" placeholder="상품명 검색" readonly="readonly" [value]="form.value.product_seq.length ? form.value.product_seq.length + ' 건 검색중' : ''" />
        <div class="input-group-append">
          <button type="button" class="btn btn-default btn-small f12">상품검색</button>
        </div>
      </div>
      <div class="btn-group ml5">
        <button *ngFor="let item of ['전체', '재고 0개 아닌 것']; index as idx" type="button" class="btn btn-default btn-small f12" [ngClass]="{active: form.value.showExist == idx}" (click)="btnClick('showExist', idx)">{{item}}</button>
      </div>
    </div>
    <div class="fr">
      <!-- <button type="button" class="btn btn-success btn-small f12" (click)="importAdd(0)">기초/기타 입고</button>
      <button type="button" class="btn btn-success btn-small f12 ml5" (click)="purchaseAdd(0);">입고처리</button> -->
      <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="moveAdd()">창고이동</button>
      <!-- <button type="button" class="btn btn-danger btn-small f12 ml5" (click)="lossAdd()">폐기/분실</button> -->
      <button type="button" class="btn btn-danger btn-small f12 ml5" (click)="lossAdd(1)">폐기출고</button>
      <button type="button" class="btn btn-danger btn-small f12 ml5" (click)="lossAdd(2)">분실출고</button>
    </div>
    <div class="cb"></div>
  </section>

  <hr class="db mt5 mb5" />

  <section class="contents-body" style="height:calc(100% - 40px);">
    <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
      <as-split-area size="60" minSize="40" maxSize="70">
        <div class="mb5">
          <div class="fl">
            <h4 class="f14 pt5 mb0 fb">재고현황</h4>
          </div>
          <div class="fr">
            <!-- <ag-grid-excel [gridApi]="gridApi" selected="false" filename="재고현황" title="재고현황"></ag-grid-excel> -->
            <ng-container>
              <button type="button" class="btn btn-default btn-small f12 ml5" (click)="allDataExcelExport()"><span>엑셀다운</span></button>
            </ng-container>
            <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi" gridId="986c873e600b45b8b410ca7328fa2329" [btnGroup]="1"></btn-ag-grid-save>
          </div>
          <div class="cb"></div>
        </div>

        <div style="height:calc(100% - 40px)">
          <ag-grid-angular
            #myGrid

            style="width: 100%;height:100%;"
            class="ag-theme-balham"

            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="form.value.stockList"
            [pagination]="false"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [rowSelection]="rowSelection"
            [getRowHeight]="getRowHeight"

            (gridReady)="onGridReady($event)"
            (cellClicked)="onCellClicked_stockList($event)"
            >
          </ag-grid-angular>
        </div>
      </as-split-area>
      <as-split-area size="40" minSize="30" maxSize="60">
        <mat-tab-group>
          <mat-tab style="min-width:100px;" label="재고이력">
            <div class="mt10 mb5">
              <div class="fl form-inline">
                <!-- <input type="text" readonly="readonly" style="width:120px;" class="form-control form-control-small calendar-input" placeholder="시작일" />
                <span class="ml5 mr5">~</span>
                <input type="text" readonly="readonly" style="width:120px;" class="form-control form-control-small calendar-input" placeholder="종료일" /> -->
                <input type="text" size="13" readonly="readonly" [value]="form.value.s_date" class="form-control form-control-small calendar-input ml5" placeholder="검색 시작일" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" (dateSelect)="selectDate('s_date', $event)" />
                <span class="pl5 pr5">~</span>
                <input type="text" size="13" readonly="readonly" [value]="form.value.e_date" class="form-control form-control-small calendar-input " placeholder="검색 종료일" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" (dateSelect)="selectDate('e_date', $event)" />

                <div class="btn-group ml5">
                  <button *ngFor="let item of ['전체','입고','출고']; index as idx" type="button" class="btn btn-default btn-small f12" [ngClass]="{active: form.controls.inout_gbn.value == idx}" (click)="btnClick('inout_gbn', idx, 1)">{{item}}</button>
                </div>
              </div>
              <div class="fr right">
                <ag-grid-excel [gridApi]="gridApi_byHistory" selected="false" filename="재고이력" title="재고이력"></ag-grid-excel>
                <btn-ag-grid-save class="ml5" [gridColumnsApi]="gridColumnApi_byHistory" gridId="c83eb0da5e4643d688c236e5e360a3a1" [btnGroup]="1"></btn-ag-grid-save>
              </div>
              <div class="cb"></div>
            </div>

            <div style="height:calc(100% - 50px)">
              <ag-grid-angular
                #myGrid

                style="width:100%;height:100%;"
                class="ag-theme-balham"

                [columnDefs]="columnDefs_byHistory"
                [defaultColDef]="defaultColDef"
                [rowData]="form.value.stockByHistory"
                [pagination]="false"
                [domLayout]="domLayout"
                [overlayNoRowsTemplate]="noRowsTemplate"
                [frameworkComponents]="frameworkComponents"
                [rowSelection]="rowSelection"
                [rowClassRules]="rowClassRules"

                (gridReady)="onGridReady_byHistory($event)"
                >
              </ag-grid-angular>
            </div>
          </mat-tab>

          <mat-tab style="min-width:100px;" label="창고별 재고">
            <div class="mt10 mb5">
              <div class="fl"></div>
              <div class="fr right">
                <btn-ag-grid-save [gridColumnsApi]="gridColumnApi_byWarehouse" gridId="20f3a36a0d68411c9737a0f553b3f23f" [btnGroup]="1"></btn-ag-grid-save>
              </div>
              <div class="cb"></div>
            </div>

            <ag-grid-angular
              #myGrid

              style="width:100%;height:calc(100% - 50px);"
              class="ag-theme-balham"

              [columnDefs]="columnDefs_byWarehouse"
              [defaultColDef]="defaultColDef"
              [rowData]="form.value.stockByWarehouse"
              [pagination]="false"
              [domLayout]="domLayout"
              [overlayNoRowsTemplate]="noRowsTemplate"
              [frameworkComponents]="frameworkComponents"
              [rowSelection]="rowSelection"

              (gridReady)="onGridReady_byWarehouse($event)"
              >
            </ag-grid-angular>
          </mat-tab>

          <!-- <mat-tab style="min-width:100px;" label="창고별 재고위치">
            <div class="mt10 mb5">
              <div class="fl"></div>
              <div class="fr right">
                <btn-ag-grid-save [gridColumnsApi]="gridColumnApiWarehousePos" gridId="5b399afb8ff6429382344b89bb1acaba" [btnGroup]="1"></btn-ag-grid-save>
              </div>
              <div class="cb"></div>
            </div>

            <ag-grid-angular
              #myGrid

              style="width:100%;height:calc(100% - 50px);"
              class="ag-theme-balham"

              [columnDefs]="columnDefsWarehousePos"
              [defaultColDef]="defaultColDef"
              [rowData]="whStockPosList"
              [pagination]="false"
              [domLayout]="domLayout"
              [overlayNoRowsTemplate]="noRowsTemplate"
              [frameworkComponents]="frameworkComponents"
              [rowSelection]="rowSelection"

              (gridReady)="onGridReadyWarehousePos($event)"
              >
            </ag-grid-angular>
          </mat-tab> -->
        </mat-tab-group>
      </as-split-area>
    </as-split>
  </section>
  
  <!-- <section class="pagination mt10">
    <ngb-pagination
      [(page)]="search.index"
      [pageSize]="1000"
      [collectionSize]="search.count"
      [maxSize]="10"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="pageChange($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </section> -->
