import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-sena-tracking',
  templateUrl: './product-sena-tracking.component.html',
  styleUrls: ['./product-sena-tracking.component.scss']
})
export class ProductSenaTrackingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
