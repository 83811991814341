<section id="pos-authgroup">
  <as-split unit="percent" restrictMove="true" gutterSize="10" style="background-color:#fff;">
    <as-split-area size="30" minSize="20" maxSize="40">
      <div class="mb5">
        <div class="fl w70p form-inline">
          <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon">
            <i class="material-icons-outlined">refresh</i>
          </button>
          <button type="button" title="검색" class="btn btn-default btn-small-icon ml5">
            <i class="material-icons-outlined">search</i>
          </button>

          <input type="text" class="form-control form-control-small ml5" placeholder="매장명, 포스명 검색" />
        </div>
        <div class="fr w30p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="f790b95b130c4c33bbc605e8e9f96b5a" [btnGroup]="1"></btn-ag-grid-save>

          <button type="button" title="그룹추가" class="btn btn-primary btn-small-icon ml5" (click)="addPosAuthGroup({auth_group_id:''})">
            <i class="material-icons-outlined">add</i>
          </button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="posAuthGroupList"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"
          [rowSelection]="rowSelection"

          (cellClicked)="onRowClicked($event)"
          (rowDoubleClicked)="onRowDoubleClicked($event)"
          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
    <as-split-area size="70" minSize="60" maxSize="80">
      <div class="mb5">
        <div class="fl w70p form-inline">
        </div>
        <div class="fr w50p right">
          <btn-ag-grid-save [gridColumnsApi]="gridColumnApiDetail" gridId="8548ad51c3084afe8c5e3b32687ad0b0" [btnGroup]="1" class="mr5"></btn-ag-grid-save>
          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="selectAll(true)">전체선택</button>
          <button type="button" class="btn btn-default btn-small f12 ml5" (click)="selectAll(false)">전체해제</button>
          <button type="button" class="btn btn-primary btn-small f12 ml5" (click)="submit()">저장하기</button>
        </div>
        <div class="cb"></div>
      </div>

      <div style="height:calc(100% - 35px)">
        <ag-grid-angular
          #myGrid

          style="width: 100%;height:100%;"
          class="ag-theme-balham"

          [columnDefs]="columnDefsDetail"
          [defaultColDef]="defaultColDef"
          [rowData]="posAuthGroupDetail"
          [pagination]="false"
          [domLayout]="domLayout"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [frameworkComponents]="frameworkComponents"

          (cellClicked)="onCellClickedDetail($event)"
          (gridReady)="onGridReadyDetail($event)"
          (filterChanged)="onFilterChangedDetail($event)"
          >
        </ag-grid-angular>
      </div>
    </as-split-area>
  </as-split>
</section>
