/*******************************************************************************
  설  명 : 공통코드 관리
  작성일 : 2021.07.16
  작성자 : 서기정
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '@app/service/common.service';
import { AuthService } from '@app/service/auth.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { CommonCodeAddComponent } from '@app/page/system/common/add/add.component';
import { StockOrderComponent } from '@app/page/stock/stock-order/stock-order.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-basic-deliverycompany',
  templateUrl: './basic-deliverycompany.component.html',
  styleUrls: ['./basic-deliverycompany.component.scss']
})
export class BasicDeliverycompanyComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  selectedItem: any = {
    seq: '113',
    code: 'DLC',
    name: '택배사코드'
  };

  selectedItemShop: any = {
    seq: '131',
    code: 'SHP',
    name: 'SHOP'
  };

  // 공통코드 그룹 그리드 관련 선언
  selectedRows: number = 0;
  commonGroupList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  columnDefs1: any;
  defaultColDef1: any;

  noRowsTemplate: string;

  // 공통코드 그리드 관련 선언
  selectedRowsCommon: number = 0;
  commonList: any = [];

  commonListShop: any = [];

  gridApiCommon: any;
  gridColumnApiCommon: any;
  columnDefsCommon: any;
  defaultColDefCommon: any;
  domLayoutCommon: any;


  gridApiCommonShop: any;
  gridColumnApiCommonShop: any;
  domLayoutCommonShop: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  // 그리드 이미지 처리
  frameworkComponentsCommon = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    public authService: AuthService,
    private stockOrderComponent: StockOrderComponent,
  ) {

    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '코드', field: 'seq', hide: true },
      {headerName: '그룹코드', field: 'code', hide: true },
      {headerName: '그룹코드명', field: 'name', hide: true },
      {headerName: '코드', field: 'common_code', cellClass: 'cp left' },
      {headerName: '코드명', field: 'common_name', cellClass: 'cp left'},
      {headerName: '코드명(영문)', field: 'common_ename', cellClass: 'cp left'},
      {headerName: '사방넷 택배사코드', field: 'c1', cellClass: 'cp left'},
      {headerName: '사용여부', field: 'use_yn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: params => (params.data.use_yn == 1) ? '<span class="badge badge-primary f12 fn">사용</span>' : '<span class="badge badge-secondary f12 fn">사용안함</span>'
      },
      {headerName: '작성자', field: 'writer', cellClass: 'cp left' },
      {headerName: '작성일시', field: 'write_date', cellClass: 'cp left' },
    ];

    // ag grid 컬럼 선언
    this.columnDefs1 = [
      {headerName: '코드', field: 'seq', hide: true },
      {headerName: '그룹코드', field: 'code', hide: true },
      {headerName: '그룹코드명', field: 'name', hide: true },
      {headerName: '코드', field: 'common_code', cellClass: 'cp left' },
      {headerName: '코드명', field: 'common_name', cellClass: 'cp left'},
      {headerName: '코드명(영문)', field: 'common_ename', cellClass: 'cp left'},
      {headerName: '사방넷 SHOP코드', field: 'c2', cellClass: 'cp left'},
      {headerName: '오픈마켓여부', field: 'c3', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: params => (params.data.c3 == 1) ? '<span class="badge badge-primary f12 fn">사용</span>' : '<span class="badge badge-secondary f12 fn">사용안함</span>'
      },
      {headerName: '사용여부', field: 'use_yn', cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: params => (params.data.use_yn == 1) ? '<span class="badge badge-primary f12 fn">사용</span>' : '<span class="badge badge-secondary f12 fn">사용안함</span>'
      },
      {headerName: '작성자', field: 'writer', cellClass: 'cp left' },
      {headerName: '작성일시', field: 'write_date', cellClass: 'cp left' },
    ];

    this.defaultColDef = {...this.stockOrderComponent.defaultColDef}
    this.rowSelection = this.stockOrderComponent.rowSelection
    this.noRowsTemplate = this.stockOrderComponent.noRowsTemplate
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getCommonList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCommon(params) {
    this.gridApiCommon = params.api;
    this.gridColumnApiCommon = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCommonShop(params) {
    this.gridApiCommonShop = params.api;
    this.gridColumnApiCommonShop = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 공통코드 수정
  *******************************************************************************/
  onRowClickedCommon($event, type) {
    this.addCommon( $event.data, type );
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
  *******************************************************************************/
  getCommonList() {
    this.commonService.getCommonList( this.selectedItem.code ).then( response => {
      this.commonList = response.data
    })

    this.commonService.getCommonList( this.selectedItemShop.code ).then( response => {
      this.commonListShop = response.data
    })
  }

  /*******************************************************************************
    설  명 : 코드 추가/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCommon( item, type ) {
    const modalRef = this.modalService.open(CommonCodeAddComponent, options);

    if( type == 'DLC' ) {
      item.group_seq = this.selectedItem.seq;
      item.code = this.selectedItem.code;
      item.name = this.selectedItem.name;
      item.type = type;
  
    } else   {
      item.group_seq = this.selectedItemShop.seq;
      item.code = this.selectedItemShop.code;
      item.name = this.selectedItemShop.name;
      item.type = type;
    }

    modalRef.componentInstance.selectedItem = item;
    
    modalRef.result.then((result) => {
      this.getCommonList();
    }, (reason) => {
    });
  }

}
