<div class="modal-body p0 order-detail">
  <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="onTabChange($event)">
    <mat-tab label="주문내역">
      <ng-container *ngIf="activeTab==0">
        <div class="pt10 pb5 top-info">
          <div class="fl w45p form-inline" *ngIf="params.seq != '0'">
            <span class="mr5" style="background-color: #d1eff9">{{orderInfo.o_name}}</span>
            <span>[ {{orderInfo.store_name}} ]</span>
            <span *ngIf="orderInfo.openmarket_yn == '1' ">[ 오픈마켓 ]</span>
            <span id="textDiv" class="ml5">{{params.seq}}</span>
            <button type="button" class="btn btn-default btn-small ml5" (click)="copyButton()">코드복사</button>
            <button type="button" class="btn btn-default btn-small ml5" (click)="statementBtn()">발송주문내역서</button>
            <button *ngIf="form.controls.seq.value != 0 && authService.currAuth['auth_modify']=='1'" type="button" class="btn btn-success btn-small ml5" (click)="setOrderProductProvider()">매입/입점 변경</button>
            <input type="text" id="quickFilter" size="20" (input)="onQuickFilterChanged($event)" class="form-control form-control-small ml5" placeholder="그리드 검색 필터링" />
          </div>
          <div class="fr w55p right">
            <button *ngIf="authService.currAuth['auth_excel']=='1'" type="button" class="btn btn-default btn-small f12 mr5" (click)="setExcelOrder()"><span>엑셀다운</span></button>
            <btn-ag-grid-save [gridColumnsApi]="orderGridColumnApi" gridId="889f9a03cb2b426da76af9c7874fa741" [btnGroup]="1"></btn-ag-grid-save>
            <button type="button" class="btn btn-default btn-small ml5" routerLink="/order/list">최신목록</button>
            <button type="button" class="btn btn-default btn-small ml5" (click)="goList()">목록으로</button>
            <button *ngIf="form.controls.seq.value == 0 && authService.currAuth['auth_write']=='1'" type="button" class="btn btn-success btn-small ml5" (click)="setOrderSave()">저장하기</button>
            <button *ngIf="form.controls.seq.value != 0 && authService.currAuth['auth_modify']=='1'" type="button" class="btn btn-success btn-small ml5" (click)="setOrderSave()">저장하기</button>
            <ng-container *ngIf="params.seq != '0'">
              <button type="button" class="btn btn-info btn-small ml5" (click)="soldOutProduct()">품절체크</button>
              <button type="button" class="btn btn-info btn-small ml5" (click)="salesStatus()">판매현황</button>
              <ng-container *ngIf="form.controls.order_status.value != '7'">
                <button *ngIf="authService.currAuth['auth_delete']=='1'" type="button" class="btn btn-danger btn-small ml5" (click)="setOrderDetailReturn()">반품 및 교환(입점)</button>
                <button *ngIf="authService.currAuth['auth_delete']=='1'" type="button" class="btn btn-danger btn-small ml5" (click)="setOrderDetailCancel()">입금전 취소</button>
                <button *ngIf="authService.currAuth['auth_delete']=='1'" type="button" class="btn btn-danger btn-small ml5" (click)="setOrderDetailRefund()">환불 및 취소</button>
              </ng-container>
              <ng-container *ngIf="form.controls.order_status.value == '7'">
                <button type="button" class="btn btn-danger btn-small ml5" (click)="setCreateRefund()">환불 데이터 생성</button>
              </ng-container>
            </ng-container>
            <button *ngIf="params.seq == '0'" type="button" class="btn btn-danger btn-small ml5" (click)="productDelete()">상품 삭제</button>
            <button *ngIf="form.controls.seq.value == 0 && authService.currAuth['auth_write']=='1'" type="button" class="btn btn-primary btn-small ml5" (click)="productAdd()">상품 추가</button>
            <button *ngIf="form.controls.seq.value != 0 && authService.currAuth['auth_modify']=='1'" type="button" class="btn btn-primary btn-small ml5" (click)="productAdd()">상품 추가</button>
          </div>
          <div class="cb"></div>
        </div>

        <div class="mt5">
          <ag-grid-angular
            #myGrid
      
            style="width:100%; height:100%;"
            class="ag-theme-balham"
      
            rowSelection="multiple"
            [columnDefs]="orderColumnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="form.value.detail"
            [domLayout]="domLayout"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            [isRowSelectable]="isRowSelectable"
            [pagination]="false"
            [singleClickEdit]="true"
            [stopEditingWhenGridLosesFocus]="true"
            [suppressRowClickSelection]="true"
            [components]="components"
            [rowClassRules]="rowClassRules"
            [rowHeight]="rowHeight"

            (cellClicked)="onCellClicked($event)"
            (rowDoubleClicked)="onRowDoubleClicked($event)"
            (gridReady)="onOrderGridReady($event)"
            (rowDataChanged)="onRowDataChanged($event)"
            (rowDataUpdated)="onRowDataChanged($event)"
            (cellEditingStopped)="onCellEditingStopped($event)"
            (columnResized)="onColumnResized($event)"
            (columnVisible)="onColumnVisible($event)"
            >
          </ag-grid-angular>
        </div>
      
        <div class="cb"></div>

        <table class="table table-bordered table-small1 mb0" style="height: 40px;">
          <caption class="none">주문내역 합계</caption>
          <colgroup>
            <col style="width:45%;" />
            <col style="width:7%;" />
            <col style="width:5%;" />
            <col style="width:7%;" />
            <col style="width:5%;" />
            <col style="width:7%;" />
            <col style="width:5%;" />
            <col style="width:7%;" />
            <col style="width:5%;" />
            <col style="width:7%;" />
          </colgroup>
          <tbody>
          <tr>
            <th scope="row"><label for="">수량합계</label></th>
            <td style="padding-top: 10px; text-align: center;" *ngFor="let i of this.form.value.sum">{{i.sum_qty | number}}</td>
            <th scope="row"><label for="">할인합계</label></th>
            <td style="padding-top: 10px; text-align: center;" *ngFor="let i of this.form.value.sum">{{i.sum_discount_amt | number}}</td>
            <th scope="row"><label for="">취소합계</label></th>
            <td style="padding-top: 10px; text-align: center;" *ngFor="let i of this.form.value.sum">{{i.sum_cancel | number}}</td>
            <th scope="row"><label for="">합계</label></th>
            <td style="padding-top: 10px; text-align: center; background-color: #d1eff9;" *ngFor="let i of this.form.value.sum">{{i.sum_total_amt | number}}</td>
            <th scope="row"><label for="">마일리지적립</label></th>
            <td style="padding-top: 10px; text-align: center;" *ngFor="let i of this.form.value.sum">{{i.sum_mileage | number}}</td>
          </tr>
          </tbody>
        </table>
      
        <table class="table table-bordered table-small1 mb0" style="height: 40px;">
          <caption class="none">결제내역 합계</caption>
          <colgroup>
            <col style="width:45%;" />
            <col style="width:7%;" />
            <col style="width:5%;" />
            <col style="width:7%;" />
            <col style="width:5%;" />
            <col style="width:7%;" />
            <col style="width:5%;" />
            <col style="width:7%;" />
            <col style="width:5%;" />
            <col style="width:7%;" />
          </colgroup>
          <tbody>
          <tr>
            <th scope="row"><label for="">주문 에누리</label></th>
            <td style="padding-top: 10px; text-align: center;">{{orderInfo.discount_amt | number}}</td>
            <th scope="row"><label for="">최초결제</label></th>
            <td style="padding-top: 10px; text-align: center;">{{( orderInfo.org_order_amt ? orderInfo.org_order_amt : 0 ) | number}}</td>
            <th scope="row"><label for="">취소합계</label></th>
            <td style="padding-top: 10px; text-align: center;">{{paySum.cancelSum | number}}</td>
            <th scope="row"><label for="">현재결제</label></th>
            <td style="padding-top: 10px; text-align: center;">{{paySum.otherSum | number}}</td>
            <th scope="row"><label for="">마일리지사용</label></th>
            <td style="padding-top: 10px; text-align: center;">{{paySum.mileageSum | number}}</td>
          </tr>
          </tbody>
        </table>

        <!-- <div class="" style="height:65px">
          <ag-grid-angular
            #myGrid
      
            style="width: 100%;height:100%;"
            class="ag-theme-balham"
      
            [columnDefs]="orderSumColumnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="form.controls.sum.value"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [frameworkComponents]="frameworkComponents"
      
            (gridReady)="onOrderSumGridReady($event)"
            >
          </ag-grid-angular>
        </div> -->
      </ng-container>
    </mat-tab>
    <!-- <mat-tab *ngIf="params.seq!=0" label="교환/환불">
      <app-order-change *ngIf="activeTab==1" [OrderSeq]="params.seq" [dormant]="dormant"></app-order-change>
    </mat-tab> -->
    <mat-tab *ngIf="params.seq != 0" label="출고지시">
      <app-order-out-delivery *ngIf="activeTab==1" [OrderSeq]="params.seq" [dormant]="dormant"></app-order-out-delivery>
    </mat-tab>
  </mat-tab-group>
</div>

<as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10">
  <as-split-area size="{{form.controls.seq.value != 0 ? 60 : 0}}" minSize="0" maxSize="100">    
    <div class="mt15" style="height: calc(100% - 0px);" *ngIf="form.controls.seq.value != 0">
      <as-split unit="percent" restrictMove="true" gutterSize="10">
        <as-split-area size="60" minSize="35" maxSize="65">
          <ng-container>
            <div class="">
              <div class="fl w70p">
                <div class="fb mt5">MEMO</div>
              </div>
              <div class="fr w30p right">
                <btn-ag-grid-save [gridColumnsApi]="memoGridColumnApi" gridId="ec208a411dda443a83f8814baef51de3" [btnGroup]="1"></btn-ag-grid-save>
              </div>
            </div>

            <form [formGroup]="memoForm">
              <inputEx type="hidden" name="orderSeq" [formGroup]="memoForm" [formErrors]="memoFormErrors"></inputEx>
              <div class="">
                <div class="fl form-inline">
                  <!-- <inputEx type="select" name="customer_seq" class="mr10 mb5 select" [formGroup]="memoForm" [formErrors]="memoFormErrors" [data]="customerList" selected="customerListColor[0]"></inputEx> -->
                  <select class="form-control form-control-small mr10 mb5 select" (change)="onValueSelectChange($event)">
                    <option *ngFor="let item of customerList" [value]="item.value" [ngClass]="{'selected':item.bgcolor == true}">
                      {{item.title}}
                    </option>
                  </select>
                  <inputEx type="select" name="malltype" class="mr10 mb5" [formGroup]="memoForm" [formErrors]="memoFormErrors" [data]="mallTypeList"></inputEx>
                </div>
                <div class="w100p">
                  <inputEx type="text" name="comment" [formGroup]="memoForm" [formErrors]="memoFormErrors" [rows]="1" placeholder="코멘트 작성(최대 255Byte, 한글 띄어쓰기 없이 최대 85자)" [addOn]="setOrderMemoSaveFunc" addOnText="등록" (keypress)="setKeyPressMemo($event)"></inputEx>
                </div>
              </div>
            </form>
          
            <div class="fr mt5" style="width:100%; height:100%;">
              <ag-grid-angular
                #myGrid
          
                style="width:100%; height:calc(100% - 110px);"
                class="ag-theme-balham"
          
                [columnDefs]="memoColumnDefs"
                [defaultColDef]="defaultColDef"
                [rowData]="memoList"
                [overlayNoRowsTemplate]="noRowsTemplate"
                [frameworkComponents]="frameworkComponents"
                [rowSelection]="rowSelection"
                [pagination]="false"
          
                (gridReady)="onMemoGridReady($event)"
                >
              </ag-grid-angular>
            </div>
          </ng-container>
        </as-split-area>
        <as-split-area size="40" minSize="35" maxSize="65">
          <as-split unit="percent" direction="vertical" restrictMove="true" gutterSize="10">
            <as-split-area size="50" minSize="30" maxSize="70">
              <div class="">
                <div class="fl">
                  <div class="fb mt5">결제내역</div>
                </div>
                <div class="fr right">
                  <btn-ag-grid-save [gridColumnsApi]="payGridColumnApi" gridId="7d2c148eb25c49e79359ded28c8c5f3c" [btnGroup]="1"></btn-ag-grid-save>
                  <button *ngIf="authService.currAuth['auth_delete']=='1'" type="button" class="btn btn-danger btn-small ml5" (click)="setMileagePayCancel()">마일리지 취소</button>
                  <button *ngIf="form.controls.seq.value == 0 && authService.currAuth['auth_write']=='1'" type="button" class="btn btn-primary btn-small ml5" (click)="paymentAdd(null)">결제내역 추가</button>
                  <button *ngIf="form.controls.seq.value != 0 && authService.currAuth['auth_modify']=='1'" type="button" class="btn btn-primary btn-small ml5" (click)="paymentAdd(null)">결제내역 추가</button>
                </div>
              </div>
            
              <div class="fl mt5" style="width:100%; height:100%;">
                <ag-grid-angular
                  #myGrid
            
                  style="width:100%; height:calc(100% - 40px);"
                  class="ag-theme-balham"
            
                  [columnDefs]="payColumnDefs"
                  [defaultColDef]="defaultColDef"
                  [rowData]="form.controls.pay.value"
                  [rowSelection]="rowSelection"
                  [suppressRowClickSelection]="true"
                  [overlayNoRowsTemplate]="noRowsTemplate"
                  [frameworkComponents]="frameworkComponents"
                  [isRowSelectable]="isRowSelectablePay"
                  [pagination]="false"
                  [stopEditingWhenGridLosesFocus]="true"
                  
                  (gridReady)="onPayGridReady($event)"
                  (cellClicked)="onPayGridCellClicked($event)"
                  (cellEditingStopped)="onPayGridCellEditingStopped($event)"
                  >
                </ag-grid-angular>
              </div>
            </as-split-area>
            <as-split-area size="50" minSize="30" maxSize="70">
              <div class="">
                <div class="fl">
                  <div class="fb mt5">업체별 배송비 내역</div>
                </div>
                <div class="fr right">
                  <button *ngIf="authService.currAuth['auth_delete']=='1'" type="button" class="btn btn-danger btn-small ml5" (click)="setDeliveryChargeDelete()">배송비 취소</button>
                  <button *ngIf="form.controls.seq.value == 0 && authService.currAuth['auth_write']=='1'" type="button" class="btn btn-primary btn-small ml5" (click)="deliveryChargeAdd(null)">배송비 추가</button>
                  <button *ngIf="form.controls.seq.value != 0 && authService.currAuth['auth_modify']=='1'" type="button" class="btn btn-primary btn-small ml5" (click)="deliveryChargeAdd(null)">배송비 추가</button>
                </div>
              </div>
            
              <div class="fl mt5" style="width:100%; height:100%;">
                <ag-grid-angular
                  #myGrid
            
                  style="width:100%; height:calc(100% - 52px);"
                  class="ag-theme-balham"
            
                  [columnDefs]="deliveryColumnDefs"
                  [defaultColDef]="defaultColDef"
                  [rowData]="form.controls.delivery.value"
                  [rowSelection]="'multiple'"
                  [suppressRowClickSelection]="true"
                  [overlayNoRowsTemplate]="noRowsTemplate"
                  [frameworkComponents]="frameworkComponents"
                  [isRowSelectable]="isRowSelectableDelivery"
                  [pagination]="false"
                  
                  (gridReady)="onDeliveryGridReady($event)"
                  (rowDoubleClicked)="onDeliveryGridRowDoubleClicked($event)"
                  >
                </ag-grid-angular>
              </div>
            </as-split-area>
          </as-split>
        </as-split-area>
      </as-split>
    </div>
  </as-split-area>

  <!-- <div class="cb"></div> -->
  <as-split-area size="{{form.controls.seq.value != 0 ? 40 : 100}}" minSize="0" maxSize="100">
    <form [formGroup]="form">
      <inputEx type="hidden" name="seq" [formGroup]="form" [formErrors]="formErrors"></inputEx>
      <as-split unit="percent" restrictMove="true" gutterSize="10" [ngClass]="{'mb50':this.form.controls.seq.value == 0}">
        <as-split-area size="60" minSize="50" maxSize="70">
          <div class="fl w20p">
            <h4 class="fb mb6 mt10">상품 수취인 정보</h4>
          </div>
          <div class="fr w50p right mt10 mb5">
            <button type="button" class="btn btn-primary btn-small ml5" (click)="orderInfoBinding()">주문자 정보와 동일</button>
          </div>
          <div class="fr w100p right form-inline mb5" style="display:block;">
            <select name="consign_info" class="form-control form-control-small" (change)="consign_infoSelectChange($event)">
              <option value="">보내실 내용을 선택하세요.</option>
              <option value="원하시는 사은품 사이즈 문자주세요.">원하시는 사은품 사이즈 문자주세요</option>
              <option value="원하시는 사은품 색상 문자주세요.">원하시는 사은품 색상 문자주세요</option>
              <option value="오늘배송 예정입니다.">오늘배송 예정입니다</option>
              <option value="내일 배송예정입니다">내일 배송예정입니다</option>
              <option value="내일모레 배송예정입니다.">내일모레 배송예정입니다</option>
              <option value="입고지연으로 3일 후 배송예정입니다.">입고지연으로 3일후 배송예정입니다</option>
              <option value="입고지연으로 4일 후 배송예정입니다.">입고지연으로 4일후 배송예정입니다</option>
              <option value="입고지연으로 5일 후 배송예정입니다.">입고지연으로 5일후 배송예정입니다</option>
              <option value="완판입니다. 다른 상품으로 변경하시겠습니까?">완판입니다. 다른 상품으로 변경하시겠습니까?</option>
              <option value="입고미정으로 환불예정입니다.">입고미정으로 환불예정입니다</option>
              <option value="환불받으실 계좌를 문자부탁드립니다.">환불받으실 계좌를 문자부탁드립니다</option>
              <option value="일본직구제품은 최장 10일 후 배송예정입니다.">일본직구제품은 최장 10일후 배송예정입니다</option>
              <option value="유럽직구제품은 약 14일 후 배송예정입니다.">유럽직구제품은 약 14일후 배송예정입니다</option>
              <option value="미국직구제품은 최장 30일 후 배송예정입니다.">미국직구제품은 최장 30일후 배송예정입니다</option>
            </select>
            <button type="button" class="btn btn-success btn-small ml5" (click)="sendInfoSms();">SMS 발송</button>
            <select name="send_bank" class="form-control form-control-small ml5" (change)="send_bankSelectChange($event)">
              <option *ngFor="let row of bankList" value="{{row.title}}">{{row.title}}</option>
            </select>
            <button type="button" class="btn btn-success btn-small ml5" (click)="sendInfoSms1();">SMS 발송</button>
            <btn-send [type]="'orderSMS'" [sendList]="sendList"></btn-send>
          </div>
          <div class="cb"></div>
      
          <table class="table table-bordered table-small1 mb0 ">
          <caption class="none">상품 수취인 정보</caption>
          <colgroup>
            <col style="width:14%;" />
            <col style="width:36%;" />
            <col style="width:14%;" />
            <col style="width:36%;" />
          </colgroup>
      
          <tbody>
          <tr>
            <!-- <th scope="row"><label for="send_letter">등기번호</label></th>
            <td><inputEx type="text" name="send_letter" [formGroup]="form" [formErrors]="formErrors"></inputEx></td> -->
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="r_name">수취인 성명</label></th>
            <td><inputEx type="text" name="r_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="r_hp">수취인 휴대폰</label></th>
            <td><inputEx type="text" name="r_hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="r_zipcode">우편번호</label></th>
            <td>
              <div class="input-group">
                <inputEx name="r_zipcode" class="mr3" [formGroup]="form" [formErrors]="formErrors" style="width: 139px;" [readonly]="false"></inputEx>
                <btn-daum-address (result)="setDaumAddressApi($event)" [options]="daumAddressOptions"></btn-daum-address>
              </div>
            </td>
            <th scope="row"><label for="r_hp2">전화</label></th>
            <td><inputEx type="text" name="r_hp2" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <!-- <tr>
            <th scope="row"><label for="unde_flag">주문상태</label></th>
            <td>
              <div class="btn-group">
                <button type="class" class="btn btn-default btn-small" [ngClass]="{active: form.controls.unde_flag.value=='1'}" (click)="form.get('unde_flag').setValue('1')">완결</button>
                <button type="class" class="btn btn-default btn-small" [ngClass]="{active: form.controls.unde_flag.value=='2'}" (click)="form.get('unde_flag').setValue('2')">미결</button>
              </div>
              <inputEx type="hidden" name="unde_flag" [formGroup]="form" [formErrors]="formErrors"></inputEx>
            </td>
          </tr> -->
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="r_address">주소</label></th>
            <td colspan="3"><inputEx type="text" name="r_address" [formGroup]="form" [formErrors]="formErrors" [readonly]="false"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="r_address_detail">상세주소</label></th>
            <td colspan="3"><inputEx type="text" name="r_address_detail" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row">
              <label for="r_comment">상품관련<br />세부 선택사항</label><br/>
            </th>
            <td colspan="3"><inputEx type="summernote" name="r_comment" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx></td>
          </tr>
          </tbody>
          </table>
        </as-split-area>
        <as-split-area size="40" minSize="30" maxSize="50">
          <div class="fl w50p mt10">
            <h4 class="fb mb6">주문자 정보</h4>
          </div>
          <div class="fr w50p right mb5 mt10">
            <button type="button" class="btn btn-default btn-small" routerLink="/order/list">최신목록</button>
            <button type="button" class="btn btn-default btn-small ml5" (click)="goList()">목록으로</button>
            <button *ngIf="form.controls.seq.value == 0 && authService.currAuth['auth_write']=='1'" type="button" class="btn btn-success btn-small ml5" (click)="setOrderSave()">저장하기</button>
            <button *ngIf="form.controls.seq.value != 0 && authService.currAuth['auth_modify']=='1'" type="button" class="btn btn-success btn-small ml5" (click)="setOrderSave()">저장하기</button>
          </div>
          <div class="cb"></div>
      
          <table class="table table-bordered table-small1 mb0">
          <caption class="none">주문자 정보</caption>
          <colgroup>
            <col style="width:14%;" />
            <col style="width:36%;" />
            <col style="width:14%;" />
            <col style="width:36%;" />
          </colgroup>
          <tbody>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="order_status_name">주문상태</label></th>
            <td>
              <inputEx type="text" name="order_status_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
            </td>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="order_date">주문일시</label></th>
            <td><dateTimePicker name="order_date" [formGroup]="form" [formErrors]="formErrors"></dateTimePicker></td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="o_name">주문자</label></th>
            <td>
              <div class="btn-group">
                <inputEx type="text" name="o_name" [formGroup]="form" [formErrors]="formErrors"></inputEx>
                <span *ngIf="form.controls.member_memo_cnt.value > 0" class="cred">＊메모</span>
              </div>
            </td>
            <th scope="row"><label for="id">아이디</label></th>
            <td><inputEx name="id" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" [addOn]="addMemberFunc" addOnText="회원검색"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="grade_name">회원구분</label></th>
            <td>
              <ng-container *ngIf="form.controls.mem_no.value != '' && form.controls.id.value == ''">
                <inputEx type="text" name="grade_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true" [addOn]="changeMemberFunc" addOnText="회원전환"></inputEx>
              </ng-container>
              <ng-container *ngIf="form.controls.mem_no.value != '' && form.controls.id.value != ''">
                <inputEx type="text" name="grade_name" [formGroup]="form" [formErrors]="formErrors" [readonly]="true"></inputEx>
              </ng-container>
            </td>
            <th scope="row"><label for="o_email">이메일</label></th>
            <td><inputEx type="text" name="o_email" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="o_hp">휴대폰</label></th>
            <td><inputEx type="text" name="o_hp" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
            <th scope="row"><label for="o_hp2">전화</label></th>
            <td><inputEx type="text" name="o_hp2" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><i class="material-icons cred f11 lh20">star</i> <label for="account_seq">송금하실 은행</label></th>
            <td><inputEx type="select" name="account_seq" [formGroup]="form" [formErrors]="formErrors" [data]="bankList"></inputEx></td>
            <th scope="row"><label for="receipt_name">입금자명</label></th>
            <td><inputEx type="text" name="receipt_name" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="saler">판매처</label></th>
            <td><inputEx type="select" name="saler" [formGroup]="form" [formErrors]="formErrors" [data]="salerList"></inputEx></td>
            <th scope="row"><label for="shop_gbn">SHOP</label></th>
            <td><inputEx type="select" name="shop_gbn" [formGroup]="form" [formErrors]="formErrors" [data]="shopList"></inputEx></td>
          </tr>
          <tr>
            <th scope="row"><label for="delivery_price">배송비</label></th>
            <td>
              <ng-container *ngIf="form.controls.delivery_price.value != '0'">
                <inputEx type="text" name="delivery_price" [formGroup]="form" [formErrors]="formErrors" [addOn]="setDeliveryFreeFunc" addOnText="무료배송변경"></inputEx>
              </ng-container>
              <ng-container *ngIf="form.controls.delivery_price.value == '0'">
                <inputEx type="text" name="delivery_price" [formGroup]="form" [formErrors]="formErrors" [addOn]="setDeliveryChargeFunc" addOnText="배송비부과"></inputEx>
              </ng-container>
            </td>
            <th scope="row"><label for="org_delivery_price">최초 배송비</label></th>
            <td><inputEx type="text" name="org_delivery_price" [formGroup]="form" [formErrors]="formErrors"></inputEx></td>
          </tr>
          </tbody>
          </table>
        </as-split-area>
      </as-split>

    <!-- <table class="table table-bordered table-small1 mb0">
      <caption class="none">상품 세부 선택사항</caption>
      <colgroup>
        <col style="width:10%;" />
        <col style="width:90%;" />
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">
          <label for="r_comment">상품관련<br />세부 선택사항</label><br/>
        </th>
        <td><inputEx type="summernote" name="r_comment" [formGroup]="form" [formErrors]="formErrors" [summernoteConfig]="summernoteConfig"></inputEx></td>
      </tr>
      </tbody>
      </table> -->

    </form>
  </as-split-area>

</as-split>

<ng-container *ngIf="orderInfo.mem_no">
  <div class="mt20">
    <app-member-info *ngIf="orderInfo.mem_no !== '0'" [memberNo]="orderInfo.mem_no" [orderSeq]="orderInfo.seq"></app-member-info>
  </div>
</ng-container>
