/*******************************************************************************
  설  명 : 추천키워드관리
  작성일 : 
  작성자 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';

import { ProductRecommendkeywordAddComponent } from '@page/product/product-recommendkeyword/product-recommendkeyword-add/product-recommendkeyword-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-product-recommendkeyword',
  templateUrl: './product-recommendkeyword.component.html',
  styleUrls: ['./product-recommendkeyword.component.scss']
})
export class ProductRecommendkeywordComponent implements OnInit {

/*******************************************************************************
  전역 선언부
*******************************************************************************/
  public colorList: any = [];

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  paginationPageSize: any = 100;
  selectedRows: number = 0;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  search: any = {
    searchText: '',
    use_yn: ''
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private productService: ProductService
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '이동', field: '', width: 50, cellClass: 'cp center', rowDrag: true, filter: false },
      { headerName: '추천키워드', field: 'keyword', width: 300, cellClass: 'cp left' },
      { headerName: '정렬순서', field: 'sort_order', width: 100, cellClass: 'cp center' },
      { headerName: '사용여부', field: 'use_yn', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.use_yn == '1') return '<span class="badge badge-success f12 fn">사용</span>';
          else return '<span class="badge badge-secondary f12 fn">사용안함</span>';
        }
      }, 
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.searchInit();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      //색상관리 수정금지. 기본 색상 18가지 고정. 쇼핑몰에 표시할 색상은 따로 입력처리.
      this.addRecommendKeyword( $event.data.seq );
    } else {

    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchText: '',
      use_yn: '1'
    }

    this.getRecommendKeywordList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getRecommendKeywordList();
    }
  }

  /*******************************************************************************
    설  명 : 추천검색어 리스트를 가져온다.
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRecommendKeywordList() {
    this.productService.getRecommendKeywordList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.colorList = response.data;
      } else {
        this.colorList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 추천키워드 추가 
    입력값 : seq = 0 추가, 0 이외의 값 수정
    리턴값 : 없음
  *******************************************************************************/
    addRecommendKeyword( seq: any ) {
      const modalRef = this.modalService.open(ProductRecommendkeywordAddComponent, options);
  
      modalRef.componentInstance.seq = seq;
  
      modalRef.result.then((result) => {
        this.getRecommendKeywordList();
      }, (reason) => {
      });
    }

  /*******************************************************************************
    설  명 : 추천키워드 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    setRecommendKeywordDelete() {
      const nodes = this.gridApi.getSelectedRows();
  
      if( nodes.length < 1 ) {
        this.toastrService.error( '삭제할 추천키워드를 선택하세요', '' );
        return false;
      }
  
      const data: any = [];
      for(let node of nodes) {
        data.push( node.seq );
      }
  
      if ( confirm('선택한 추천키워드를 삭제하시겠습니까?') ) {
        this.productService.deleteRecommendKeyword( data ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
  
            this.getRecommendKeywordList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  /*******************************************************************************
    설  명 : 순서 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    setRecommendKeywordSortOrderSave() {
      const data: any = [];
      this.gridApi.forEachNodeAfterFilterAndSort(function(node) {
        data.push( node.data.seq );
      });
      if ( confirm('해당 순서로 저장하시겠습니까?') ) {
        this.productService.setRecommendKeywordSortOrderSave( JSON.stringify(data) ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
  
            this.getRecommendKeywordList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
    

  /*******************************************************************************
    설  명 : 사용여부 검색
    입력값 : use_yn
    리턴값 : 없음
  *******************************************************************************/
  searchUseYn( use_yn ) {
    this.search.use_yn = use_yn;

    this.getRecommendKeywordList();
  }

}
  