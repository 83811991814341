import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    public restful: RestfulService,
  ) { }

  // color 리스트 가져오기
  getColorList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getColorList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // color 정보를 가져온다
  getColorInfo( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getColorInfo'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 색상을 삭제한다.
  deleteColor( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteColor'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 색상 정보를 저장한다.
  setColor( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setColor'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 사이즈 리스트를 가져온다.
  getSizeList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getSizeList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 사이즈 카테고리 리스트를 가져온다.
  getSizeCategoryList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getSizeCategoryList'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 사이즈 정보를 가져온다.
  getSizeInfo( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getSizeInfo'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 색상 정보를 저장한다.
  setSize( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setSize'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 사이즈를 삭제한다.
  deleteSize( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteSize'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 사이즈를 복사해 온다.
  copySize( sour_category_seq, dest_category_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'copySize'
      }, {
        sour_category_seq: sour_category_seq,
        dest_category_seq: dest_category_seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 사이즈 리스트를 삭제한다.
  deleteSizeList( data ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteSizeList'
      }, {
        data: data
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 대표상품관리 위젯 데이터 가져오기
  getProductMasterWidget(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductMasterWidget'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 대표상품 리스트 가져오기
  getProductMasterList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductMasterList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품관리 위젯 데이터 가져오기
  getProductWidget(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductWidget'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 리스트 가져오기
  getProductList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 상세정보 가져오기
  getProductDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductDetail'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품순서관리 - 상품 리스트 가져오기
  getProductListForSort( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductListForSort'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품추가 - 속성이 포함된 상품 리스트 가져오기
  getProductListForProperty( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductListForProperty'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품추가 - 선택된 상품의 가격 정보 가져오기
  getProductListForPropertyPrice( memNo: any, data: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductListForPropertyPrice'
      }, {
        mem_no: memNo,
        params: data
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품찾기 - 상품 리스트 가져오기
  getProductListForFind( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductListForFind'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품찾기 - 속성 리스트 가져오기
  getPropertyListForFind( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getPropertyListForFind'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 메모 리스트 가져오기
  getProductMemoList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductMemoList'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 속성 리스트 가져오기
  getProductPropertyList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductPropertyList'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 바코드 리스트 가져오기
  getProductBarcodeList( seq: any, property_seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductBarcodeList'
      }, {
        productSeq: seq,
        propertySeq: property_seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  
  // 사은품 리스트 가져오기
  getProductGiftList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductGiftList'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 연관상품 리스트 가져오기
  getProductRelationList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductRelationList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 세트상품 리스트 가져오기
  getProductSetList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductSetList'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품마진 리스트 가져오기
  getProductMarginList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductMarginList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 세나 리스트 가져오기
  getProductSenaList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductSenaList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  getPriceHistory( seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getPriceHistory'
      }, {
        propertySeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 판매현황 데이터 가져오기
  getProductSellGraphInfo( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductSellGraphInfo'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 선택된 상품 리스트 삭제
  deleteProductList( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteProductList'
      }, {
        data: data
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 선택된 상품 리스트 품절 처리
  soldoutProductList( data: any, isSoldOut: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'soldoutProductList'
      }, {
        data: data,
        isSoldOut: isSoldOut
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 품절체크 일괄저장
  setProductSoldOutSave( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductSoldOutSave'
      }, {
        params: data
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  
  // 속성 추가
  setPropertySave( seqArray: any, colorNodes: any, sizeNodes: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setPropertySave'
      }, {
        seq: seqArray,
        color: colorNodes,
        size: sizeNodes
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 속성 삭제
  setPropertyDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setPropertyDelete'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 바코드 저장
  setBarcodeSave( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setBarcodeSave'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 바코드 삭제
  setBarcodeDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setBarcodeDelete'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 순서 저장
  setSortOrderSaveAll( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setSortOrderSaveAll'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 연관상품 순서 저장
  setRelationSortOrderSaveAll( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setRelationSortOrderSaveAll'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사이즈 순서 저장
  setSizeSortOrderSave( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setSizeSortOrderSave'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 상품 정렬번호 저장
  setSortOrderSave( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setSortOrderSave'
      }, data
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 저장
  setProductSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 승인 처리
  setProductApproveSave( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductApproveSave'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 미승인 처리
  setProductUnApproveSave( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductUnApproveSave'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 메모 저장
  setProductMemoSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductMemoSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 상품 메모 삭제
  deleteProductMemo( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteProductMemo'
      }, {seq : seq}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사은품 정보 저장
  setProductGiftInfoSave( gridData: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductGiftInfoSave'
      }, {
        params: gridData
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 연관상품 정보 저장
  setProductRelationInfoSave( gridData: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductRelationInfoSave'
      }, {
        params: gridData
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 세트상품 정보 저장
  setProductSetInfoSave( gridData: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductSetInfoSave'
      }, {
        params: gridData
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 이미지 삭제
  setDeleteUploadFile( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setDeleteUploadFile'
      }, {
        imageSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사은품 저장
  setProductGiftSave( seq: number, array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductGiftSave'
      }, {
        parentProductSeq: seq,
        productArray: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사은품 삭제
  setProductGiftDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductGiftDelete'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 연관상품 저장
  setProductRelationSave( seq: number, array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductRelationSave'
      }, {
        categorySeq: seq,
        productArray: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 연관상품 삭제
  setProductRelationDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductRelationDelete'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 세트상품 저장
  setProductSetSave( seq: number, array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductSetSave'
      }, {
        parentProductSeq: seq,
        productArray: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 세트상품 삭제
  setProductSetDelete( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductSetDelete'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품마진 저장
  setProductMarginSave( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductMarginSave'
      }, data
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품마진 가격 추가
  setProductMarginAdd( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductMarginAdd'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품마진 가격 삭제
  setProductMarginDelete( seq: number ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductMarginDelete'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 상품 색상, 사이즈 통계 가져오기
  getProductPropertyStatistic(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductPropertyStatistic'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  getProductSoldOutCheckList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductSoldOutCheckList'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 세나 입고/출고 처리
  setProductSenaProcess( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductSenaProcess'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 대표이미지 설정
  setProductImageDefault( file: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductImageDefault'
      }, file
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품별 판매현황 불러오기
  getProductSalesStatusList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductSalesStatusList'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 채널별월별판매실적
  getProductSalesChannelList( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductSalesChannelList'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 복사
  setProductCopy( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setProductCopy'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 품절관리 가져오기
  getProductSoldoutList(params: Object): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductSoldoutList'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 마진관리 엑셀 업로드
  uploadProductMarginList(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'uploadProductMarginList'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 추천 키워드 리스트 가져오기
  getRecommendKeywordList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getRecommendKeywordList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

 // 추천키워드 정보를 가져온다
 getRecommendKeywordInfo( seq ): Promise<any> {
  return this.restful.get({
      program: 'admin',
      service: 'product',
      version: '1.0',
      action: 'getRecommendKeywordInfo'
    }, {
      seq: seq
    }
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
}

  // 추천키워드를 삭제한다.
  deleteRecommendKeyword( data ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'deleteRecommendKeyword'
      }, {
        data: data
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  
  // 추천키워드를 저장한다.
  setRecommendKeyword( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'setRecommendKeyword'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

    // 추천키워드 순서 저장
    setRecommendKeywordSortOrderSave( array: any ): Promise<any> {
      return this.restful.post({
          program: 'admin',
          service: 'product',
          version: '1.0',
          action: 'setRecommendKeywordSortOrderSave'
        }, {
          params: array
        }
      ).then(this.restful.extractData)
       .catch(this.restful.handleErrorPromise);
    }
  
  // 상품키워드관리 리스트  가져오기
  getProductKeywordList(params: Object): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getProductKeywordList'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }


  // 세나정품등록 리스트 가져오기
  getRegistrationSenaList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'product',
        version: '1.0',
        action: 'getRegistrationSenaList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

    // 세나정품등록 정보를 삭제한다.
    deleteRegistrationSena( data ): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'product',
          version: '1.0',
          action: 'deleteRegistrationSena'
        }, {
          data: data
        }
      ).then(this.restful.extractData)
        .catch(this.restful.handleErrorPromise);
    }


    // 세나정품등록 등록 취소 처리를 한다.
    setUnregistrationSena( data ): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'product',
          version: '1.0',
          action: 'setUnregistrationSena'
        }, {
          data: data
        }
      ).then(this.restful.extractData)
        .catch(this.restful.handleErrorPromise);
    }
    
    // 세나정품등록 등록 취소 처리를 한다.
    setRegistrationSena( data ): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'product',
          version: '1.0',
          action: 'setRegistrationSena'
        }, {
          data: data
        }
      ).then(this.restful.extractData)
        .catch(this.restful.handleErrorPromise);
    }
    

}
