import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from '@app/service/employee.service';
import { UtilService } from '@app/service/util.service';
import { NgbActiveModal, NgbModalOptions, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';


const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  windowClass:'modal-admin'
};

@Component({
  selector: 'app-approval-business',
  templateUrl: './approval-business.component.html',
  styleUrls: ['./approval-business.component.scss']
})
export class ApprovalBusinessComponent implements OnInit {
  private dateModel: NgbDateStruct;

  public form: FormGroup;
  public formErrors = {};
  public employeeChangedFunc: any;
  
  public employeeList: any = [];

  public type = '1';

  public member = [];

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      ymd: [moment().format('YYYY-MM-DD'), [Validators.required]],
      sdate: [moment().format('YYYY-MM-DD'), [Validators.required] ],
      edate: [moment().format('YYYY-MM-DD'), [Validators.required] ],
      dest: ['', [] ],
      place_name: ['', [] ],
      message: ['', [] ],
      mem_no: ['', [] ],
      name: ['', [] ],
      service_name: ['', [] ],
      subject_name: ['', [] ],
      total_amt: ['', [] ],
      fee_Detail: ['- 일비 : \n - 식비 : \n - 교통비 : \n - 통행료 : \n - 숙박비 :', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private employeeService: EmployeeService,
    private toastrService: ToastrService
  ) {
    this.buildForm();

    this.employeeChangedFunc = this.employeeChanged.bind(this);
  }

  ngOnInit(): void {
    this.getEmployee();

    this.dateModel = {
      year: parseInt( this.form.value.ymd.substr( 0, 4 ), 10 ),
      month: parseInt( this.form.value.ymd.substr( 5, 2 ), 10 ),
      day: parseInt( this.form.value.ymd.substr( 8, 2 ), 10 )
    };

    this.form.patchValue({
      ymd: this.dateModel
    });

    this.dateModel = {
      year: parseInt( this.form.value.sdate.substr( 0, 4 ), 10 ),
      month: parseInt( this.form.value.sdate.substr( 5, 2 ), 10 ),
      day: parseInt( this.form.value.sdate.substr( 8, 2 ), 10 )
    };

    this.form.patchValue({
      sdate: this.dateModel
    });

    this.dateModel = {
      year: parseInt( this.form.value.edate.substr( 0, 4 ), 10 ),
      month: parseInt( this.form.value.edate.substr( 5, 2 ), 10 ),
      day: parseInt( this.form.value.edate.substr( 8, 2 ), 10 )
    };

    this.form.patchValue({
      edate: this.dateModel
    });
  }

  getMomentStruct( data ) {
    if( data !== false ) {
      return {
        year: parseInt( moment(data).format('YYYY') ),
        month: parseInt( moment(data).format('MM') ),
        day: parseInt( moment(data).format('DD') )
      };
    } else {
      return {
        year: parseInt( moment().format('YYYY') ),
        month: parseInt( moment().format('MM') ),
        day: parseInt( moment().format('DD') )
      };
    }

  }

   /*******************************************************************************
    설  명 : 사원 포함 조직도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEmployee() {
    this.employeeService.getGroupEmployeeListForSelect().then( response => {
      if( response.ResultCode ) {
        const data: any = [];

        for( let item of response.data ) {
          data.push({
            id: item.mem_no,
            text: item.name
          });
        }

        this.employeeList = data;
      } else {
        this.employeeList = [];
      }
    });
  }


  /*********************************************s**********************************
    설  명 : 카테고리 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  employeeChanged( e: any ): void {
    // console.log(e)
    // for( let i of e) {
    //   this.member.push({
    //     name: i.text
    //   })
    // }
  
  }


  /*********************************************s**********************************
    설  명 : textarea 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChange(e) {
    this.form.patchValue({
      fee_Detail: e.target.value
    })
  }

  /*******************************************************************************
    설  명 : 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    for(let i of this.form.value.mem_no) {
      this.member.push(i.text.trim())
    }

    this.form.patchValue({
      name: this.member
    });

    if( this.form.valid ) {
      this.activeModal.close( this.form.value );

    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
      
    }
  }

}
