/*******************************************************************************
  설  명 : 슈퍼세일 설정
  작성일 : 2020-09-04
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { SupersaleService } from '@app/service/supersale.service';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { SupersaleAddComponent } from '@page/basic/supersale/supersale-add/supersale-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-supersale',
  templateUrl: './supersale.component.html',
  styleUrls: ['./supersale.component.scss']
})
export class SupersaleComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public search: any = {
    status: '',
  };

  // 그리드 관련 선언
  superSaleList: any = [];

  public superSaleSeq: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  selectedRows: number = 0;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private supersaleService: SupersaleService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '', field: 'seq', width: 50, cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      {headerName: '상태', field: 'status', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.status === '0' ) {
            return '<span class="badge badge-secondary f12">종료</span>';
          } else if ( params.data.status === '1' ) {
            return '<span class="badge badge-primary f12">진행예정</span>';
          } else if ( params.data.status === '2' ) {
            return '<span class="badge badge-success f12">진행중</span>';
          } else {
            return '';
          }
        }
      },
      {headerName: '슈퍼세일명', field: 'supersale_name', width: 300, cellClass: 'cp' },
      {headerName: '시작일시', field: 'start_date', width: 180, cellClass: 'cp center' },
      {headerName: '종료일시', field: 'end_date', width: 180, cellClass: 'cp center' },
      {headerName: '시작일시(테스트)', field: 'start_date_test', width: 180, cellClass: 'cp center' },
      {headerName: '종료일시(테스트)', field: 'end_date_test', width: 180, cellClass: 'cp center' },
      {headerName: '테스트아이디', field: 'test_id', width: 130, cellClass: 'cp' },
      {headerName: '수정', field: 'modify', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params) {
          return `<button type="button" class="btn btn-danger btn-grid">수정</button>`;
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  onModifyClick() {
    alert('1');
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe( search => {
      this.search = {
        status: search.status
      };

      this.search.status = search.status === undefined ? '' : search.status;

      this.getSuperSaleList(this.search.status);

    }).unsubscribe();

  }

  getSuperSaleList(type: string) {
    this.search.status = type;
    this.supersaleService.getSuperSaleList(type).then( response => {
      if (response.ResultCode) {
        this.superSaleList = response.data;
      } else {
        this.superSaleList = [];
      }
    });

  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 셀 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.superSaleSeq = $event.data.seq;
    this.goDetail();
  }

  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if ($event.colDef.field === 'modify') {
      this.supersaleAdd($event.node.data.seq);
    }
  }

  /*******************************************************************************
    설  명 : 상세보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail() {
    this.router.navigate(
    ['/basic/supersale/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {
        superSaleSeq: this.superSaleSeq,
        search: this.search.status
      },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 매장 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  supersaleAdd( seq: any ) {
    const modalRef = this.modalService.open(SupersaleAddComponent, options);
    modalRef.componentInstance.seq = seq;

    modalRef.result.then((result) => {
      if ( result ) {
        this.getSuperSaleList(this.search.status);
      }
    }, (reason) => {
    });
  }
}
